import React, {useEffect, useState, useRef, useCallback} from "react";
import {makeStyles, Backdrop} from "@material-ui/core";
import GoogleMapReact from "google-map-react";
import {
    GET_AVAILABLE_SHIPMENTS,
    GET_DRIVER_LOADED_SHIPMENTS,
    GET_SERVER_DRIVER_LOADED_SHIPMENTS,
    GET_DRIVER_CLAIMED_SHIPMENTS,
    GET_DRIVER_DELIVERED_SHIPMENTS,
    GET_SERVER_DRIVER_DELIVERED_SHIPMENTS,
    GOOGLE_GEOCODE,
} from "../../Utils/apiUrl";
import axios from "axios";
import {connect} from "react-redux";
import {getTokenFromCookie} from "../../Utils/doToken";
import MapList from "./MapList";
import MapMarker from "./MapMarker";
import LoadingCircle from "../LoadingCircle";

const useStyles = makeStyles((theme) => ({
    MapPageWrapper: {
        position: "relative",
    },
    geoLoadingCircle: {
        position: "absolute",
        transform: "translateX(-50%)",
        zIndex: "1001",
    },
    backdrop: {
        zIndex: "1000",
        color: "#fff",
        width: "500%",
        height: "100vh",
        position: "absolute",
        left: "-50%",
        top: "-25%",
    },
}));

export const ShipmentMapLayout = ({type, searchOptions}) => {
    const classes = useStyles();
    const [latitude, setLatitude] = useState(0);
    const [mapLoaded, setMapLoaded] = useState(false);
    const [longtitude, setLongtitude] = useState(0);
    const [GoogleAPI, setGoogleAPI] = useState();
    const [page, setPage] = useState(1);
    const [shipments, setShipments] = useState([]);
    const [addresses, setAddresses] = useState([]);
    const [geoLoading, setGeoLoading] = useState(false);
    const [latLngs, setLatLngs] = useState([]); //for making markers
    const preSelectedCard = useRef("");
    const preSelectedMarker = useRef("");
    const updateFilter = useRef(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [lastPage, setLastPage] = useState(0);
    const [pageNumber, setPagenumber] = useState(1);

    //filter near address in one group
    const [sNumToGropuId, setSNumToGropuId] = useState({});

    //for clicked marker to display near tasks
    const header = {
        headers: {
            Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
        },
    };

    useEffect(() => {
        updateFilter.current = true;
    }, [searchOptions]);

    useEffect(() => {
        //fetch available shipments
        switch (type) {
            case "available_shipment_tasks":
                setLoading(true);
                if (updateFilter.current && pageNumber === 1) {
                    axios
                        .post(
                            GET_AVAILABLE_SHIPMENTS,
                            {
                                page: pageNumber,
                                // min_unit_price: searchOptions.unitPrice[0],
                                // max_unit_price: searchOptions.unitPrice[1],
                                // min_total_price: searchOptions.totalPrice[0],
                                // max_total_price: searchOptions.totalPrice[1],
                                // shipment_size: searchOptions.shipmentSize,
                                pickupCities: searchOptions.pickupCity,
                                deliverCities: searchOptions.deliverCity,
                                pickupDateFrom: searchOptions.pickupDateFrom,
                                pickupDateTo: searchOptions.pickupDateTo,
                                extraOptions: {
                                    optionPickupLoadingDock: searchOptions.pickupLoadingDock
                                        ? "y"
                                        : "",
                                    optionDeliverLoadingDock: searchOptions.deliverLoadingDock
                                        ? "y"
                                        : "",
                                    optionDeliverAppointment: searchOptions.deliverAppointment
                                        ? "y"
                                        : "",
                                },
                            },
                            {
                                // params: {
                                //   page: pageNumber,
                                //   // min_unit_price: searchOptions.unitPrice[0],
                                //   // max_unit_price: searchOptions.unitPrice[1],
                                //   // min_total_price: searchOptions.totalPrice[0],
                                //   // max_total_price: searchOptions.totalPrice[1],
                                //   shipment_size: searchOptions.shipmentSize,
                                //   pickup_cities: searchOptions.pickupCity,
                                //   deliver_cities: searchOptions.deliverCity,
                                //   pickup_date_from: searchOptions.pickupDateFrom,
                                //   pickup_date_to: searchOptions.pickupDateTo,
                                //   option_pickup_loading_dock: searchOptions.pickupLoadingDock
                                //     ? "y"
                                //     : "",
                                //   option_deliver_loading_dock: searchOptions.deliverLoadingDock
                                //     ? "y"
                                //     : "",
                                //   option_deliver_appointment: searchOptions.deliverAppointment
                                //     ? "y"
                                //     : "",
                                // },
                                ...header,
                            }
                        )
                        .then((res) => {
                            console.log("shipment tasks in map layout", res.data);
                            // setShipments(res.data.data);
                            console.log(res.data?.taskList);
                            setShipments(res.data?.taskList);
                            setLastPage(res.data.lastPage);
                            setHasMore(res.data?.taskList?.length > 0);
                            if (res.data?.taskList?.length > 0) {
                                getAddresses(res.data?.taskList);
                            } else {
                                //no shipments, clean the location for markers
                                setLatLngs([]);
                            }
                            setLoading(false);
                        })
                        .catch((err) => {
                            setError(true);
                        });
                    updateFilter.current = false;
                } else if (!updateFilter.current) {
                    //normal changing page
                    preSelectedCard.current = "";
                    preSelectedMarker.current = "";
                    setLatLngs([]);
                    setSNumToGropuId({});
                    console.log("map page change, page: ", pageNumber);
                    axios
                        .post(
                            GET_AVAILABLE_SHIPMENTS,
                            {
                                page: pageNumber,
                                // min_unit_price: searchOptions.unitPrice[0],
                                // max_unit_price: searchOptions.unitPrice[1],
                                // min_total_price: searchOptions.totalPrice[0],
                                // max_total_price: searchOptions.totalPrice[1],
                                // shipment_size: searchOptions.shipmentSize,
                                pickupCities: searchOptions.pickupCity,
                                deliverCities: searchOptions.deliverCity,
                                pickupDateFrom: searchOptions.pickupDateFrom,
                                pickupDateTo: searchOptions.pickupDateTo,
                                extraOptions: {
                                    optionPickupLoadingDock: searchOptions.pickupLoadingDock
                                        ? "y"
                                        : "",
                                    optionDeliverLoadingDock: searchOptions.deliverLoadingDock
                                        ? "y"
                                        : "",
                                    optionDeliverAppointment: searchOptions.deliverAppointment
                                        ? "y"
                                        : "",
                                },
                            },
                            {
                                // params: {
                                //   page: pageNumber,
                                //   // min_unit_price: searchOptions.unitPrice[0],
                                //   // max_unit_price: searchOptions.unitPrice[1],
                                //   // min_total_price: searchOptions.totalPrice[0],
                                //   // max_total_price: searchOptions.totalPrice[1],
                                //   shipment_size: searchOptions.shipmentSize,
                                //   pickup_cities: searchOptions.pickupCity,
                                //   deliver_cities: searchOptions.deliverCity,
                                //   pickup_date_from: searchOptions.pickupDateFrom,
                                //   pickup_date_to: searchOptions.pickupDateTo,
                                //   option_pickup_loading_dock: searchOptions.pickupLoadingDock
                                //     ? "y"
                                //     : "",
                                //   option_deliver_loading_dock: searchOptions.deliverLoadingDock
                                //     ? "y"
                                //     : "",
                                //   option_deliver_appointment: searchOptions.deliverAppointment
                                //     ? "y"
                                //     : "",
                                // },
                                ...header,
                            }
                        )
                        .then((res) => {
                            setShipments(res.data?.taskList);
                            setLastPage(res.data.lastPage);
                            setHasMore(res.data?.taskList?.length > 0);

                            // setShipments(res.data.data);
                            // setLastPage(res.data.last_page);
                            // setHasMore(res.data.data.length > 0);
                            if (res.data?.taskList?.length > 0) {
                                getAddresses(res.data?.taskList);
                            } else {
                                //no shipments, clean the location for markers
                                setLatLngs([]);
                            }
                            setLoading(false);
                        })
                        .catch((err) => {
                            setError(true);
                        });
                }
                break;
            case "claimed_shipment_tasks":
                setLoading(true);
                preSelectedCard.current = "";
                preSelectedMarker.current = "";
                axios
                    .get(GET_DRIVER_CLAIMED_SHIPMENTS, {
                        params: {
                            page: pageNumber,
                        },
                        ...header,
                    })
                    .then((res) => {
                        setShipments(res.data.results);
                        setLastPage(res.data.lastPage);
                        setHasMore(res.data.results.length > 0);
                        if (res.data.results.length > 0) {
                            getAddresses(res.data.results);
                        } else {
                            //no shipments, clean the location for markers
                            setLatLngs([]);
                        }
                        setLoading(false);
                    })
                    .catch((err) => {
                        setError(true);
                    });
                break;
            case "pickedup_shipment_tasks":
                setLoading(true);
                preSelectedCard.current = "";
                preSelectedMarker.current = "";
                axios
                    .get(GET_SERVER_DRIVER_LOADED_SHIPMENTS, {
                        params: {
                            page: pageNumber,
                        },
                        ...header,
                    })
                    .then((res) => {
                        // setShipments(res.data.data);
                        setShipments(res.data.results);
                        setLastPage(res.data.last_page);
                        // setHasMore(res.data.data.length > 0);
                        setHasMore(res.data.results.length > 0);
                        if (res.data.results.length > 0) {
                            getAddresses(res.data.results);
                        } else {
                            //no shipments, clean the location for markers
                            setLatLngs([]);
                        }
                        setLoading(false);
                    })
                    .catch((err) => {
                        setError(true);
                    });
                break;
            case "delivered_shipment_tasks":
                setLoading(true);
                preSelectedCard.current = "";
                preSelectedMarker.current = "";
                axios
                    .get(GET_SERVER_DRIVER_DELIVERED_SHIPMENTS, {
                        params: {
                            page: pageNumber,
                        },
                        ...header,
                    })
                    .then((res) => {
                        setShipments(res.data.results);
                        setLastPage(res.data.last_page);
                        setHasMore(res.data.results.length > 0);
                        if (res.data.results.length > 0) {
                            getAddresses(res.data.results);
                        } else {
                            //no shipments, clean the location for markers
                            setLatLngs([]);
                        }
                        setLoading(false);
                    })
                    .catch((err) => {
                        setError(true);
                    });
                break;
            default:
                return;
        }
    }, [pageNumber, searchOptions]);

    useEffect(() => {
        if (mapLoaded) {
            getGeocode();
        }
    }, [addresses, mapLoaded]);

    useEffect(() => {
        if (latLngs.length === 0) return;

        pushLatLngIntoShipment();
        changeCenter(latLngs[0].lat, latLngs[0].lng);
    }, [latLngs]);

    const getAddresses = (shipments) => {
        const tempAddress = [];
        shipments.forEach((s) => {
            tempAddress.push({
                key: `address-${s.shipment_number}`,
                shipment_task_number: s.shipment_number,
                address: s.pickup_address,
            });
        });
        setAddresses([...tempAddress]);
    };

    const pushLatLngIntoShipment = () => {
        if (latLngs.length === 0) return;
        const tempAvailableShipments = shipments;
        for (let i = 0; i < tempAvailableShipments.length; i++) {
            tempAvailableShipments[i].latitude = latLngs[i].lat;
            tempAvailableShipments[i].longtitude = latLngs[i].lng;
        }
        setShipments(tempAvailableShipments);
    };

    /**
     * get lat and lng by using address
     * @param {object} _addresses [{shipment_task_number, address},..]
     */
    const getLatLng = async (_addresses) => {
        const result = await axios.post(GOOGLE_GEOCODE, {
            address_list: _addresses,
        });
        return result;
    };

    const createNewAddressGroup = (_groupId, _lat, _lng, _shipment_number) => {
        return {
            groupId: _groupId,
            latRange: {
                max: _lat + 0.005,
                min: _lat - 0.005,
            },
            lngRange: {
                max: _lng + 0.005,
                min: _lng - 0.005,
            },
            shipment_tasks: [_shipment_number],
        };
    };

    const getGeocode = async () => {
        setGeoLoading(true);
        if (GoogleAPI === undefined) {
            console.log("GoogleAPI is not ready.");
            setGeoLoading(false);
            return;
        }
        let tempLocation = [];
        let tempAddressGroups = [];
        let tempSNumToGroupID = {};
        let groupId = 0; //address group id

        const latLngs = await getLatLng(addresses);
        console.log("get latlng: ", latLngs);

        if (latLngs?.data?.result?.length === 0) {
            setGeoLoading(false);
            return;
        }

        //filter lat lngs into group
        for (const a of latLngs.data.result) {
            let addedIntoGroup = false;

            let location = {};

            //make markers
            location = {
                shipment_task_number: a.shipment_task_number,
                lat: Number(a.location.lat),
                lng: Number(a.location.lng),
                nearTaskCount: null,
            };

            //start from the second location
            //compare lat and lng to see if new location inside any groups
            for (let i = 0; i < tempAddressGroups.length; i++) {
                if (
                    a.location.lat > tempAddressGroups[i].latRange.min &&
                    a.location.lat <= tempAddressGroups[i].latRange.max &&
                    a.location.lng > tempAddressGroups[i].lngRange.min &&
                    a.location.lng <= tempAddressGroups[i].lngRange.max
                ) {
                    console.log("found group fits location", a.shipment_task_number);

                    //location in the group
                    tempAddressGroups[i].shipment_tasks.push(a.shipment_task_number);
                    tempSNumToGroupID[a.shipment_task_number] =
                        tempAddressGroups[i].groupId;
                    addedIntoGroup = true;
                    break;
                }
            }
            //location not in the existed groups
            if (!addedIntoGroup) {
                console.log("NOT found group fits location, creating new group");
                //create new group
                groupId += 1;
                tempAddressGroups.push(
                    createNewAddressGroup(
                        groupId,
                        Number(a.location.lat),
                        Number(a.location.lng),
                        a.shipment_task_number
                    )
                );
                tempSNumToGroupID[a.shipment_task_number] = groupId;

                console.log("new address group id:", groupId);
            }

            tempLocation.push(location);
        }
        console.log("address group", tempAddressGroups);
        console.log("tempSNumToGroupID", tempSNumToGroupID);

        //loop through locations and put amount of task near by into it
        for (const l of tempLocation) {
            console.log("each location", typeof l.shipment_task_number);
            const targetGroupId = tempSNumToGroupID[l.shipment_task_number];
            console.log("targetGroupId", targetGroupId);
            if (targetGroupId) {
                let taskAmountNear = 0;
                for (let i = 0; i < tempAddressGroups.length; i++) {
                    if (tempAddressGroups[i].groupId === targetGroupId) {
                        taskAmountNear = tempAddressGroups[i].shipment_tasks.length;
                        break;
                    }
                }
                console.log(taskAmountNear);
                l.nearTaskCount = taskAmountNear;
            }
        }
        console.log(tempLocation);
        setSNumToGropuId(tempSNumToGroupID);
        setLatLngs(tempLocation);
        setGeoLoading(false);
    };

    const changeCenter = (lat, lng) => {
        setLatitude(lat);
        setLongtitude(lng);
        GoogleAPI.map.panTo({lat: lat, lng: lng});
    };

    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            setLatitude(position.coords.latitude);
            setLongtitude(position.coords.longitude);
        });

        //setMapList ref
        const observer = new MutationObserver((mutations, obs) => {
            const mapList = document.getElementById("mapList");
            if (mapList) {
                mapList.addEventListener("wheel", (event) => {
                    event.preventDefault();
                    mapList.scrollLeft += event.deltaY;
                });
                obs.disconnect();
                return;
            }
        });

        observer.observe(document, {
            childList: true,
            subtree: true,
        });
    }, []);

    const handleCardClick = (lat, lng, sNum) => {
        if (!geoLoading) {
            changeCenter(lat, lng);

            selectCardItem(sNum);
            selectMarker(sNum);
        }
    };

    const handleMarkerClick = (event, sNum, _lat, _lng) => {
        console.log("clicked:", sNum);
        console.log("snum to group id", sNumToGropuId);
        event.stopPropagation();
        changeCenter(_lat, _lng);
        selectMarker(sNum);
    };

    const selectCardItem = (sNum) => {
        const element = document.querySelector(`#cardItem-${sNum}`);
        console.log("selected card element", element);
        if (!element) {
            console.log("no found card item by id");
            return;
        }

        element.style.border = "2px solid lightsalmon";
        if (preSelectedCard.current === "") {
            preSelectedCard.current = `#cardItem-${sNum}`;
        } else if (preSelectedCard.current !== `#cardItem-${sNum}`) {
            const targetEle = document.querySelector(preSelectedCard.current);
            if (targetEle) {
                console.log("pre selected ele", targetEle);
                targetEle.style.border = "";
            }
            preSelectedCard.current = `#cardItem-${sNum}`;
        }

        element.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "center",
        });
    };

    const selectMarker = (_STNum) => {
        //display all shipment tasks in the same group
        console.log("current sNumToGropuId", sNumToGropuId);

        const marker = document.getElementById(`markerIcon-${_STNum}`);
        marker.style.color = "orangered";
        marker.parentElement.parentElement.style.zIndex = "1100";
        if (preSelectedMarker.current === "") {
            preSelectedMarker.current = `markerIcon-${_STNum}`;
        } else if (preSelectedMarker.current !== `markerIcon-${_STNum}`) {
            document.getElementById(preSelectedMarker.current).style.color = "orange";
            document.getElementById(
                preSelectedMarker.current
            ).parentElement.parentElement.style.zIndex = "-1";
            preSelectedMarker.current = `markerIcon-${_STNum}`;
        }

        selectCardItem(_STNum);
    };

    return (
        <div className={classes.MapPageWrapper}>
            <div style={{height: "50vh", width: "100%"}}>
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: "AIzaSyDxbjdueUQWI1WFfiEviIkvspt8S95L-bQ",
                    }}
                    center={{
                        lat: latitude,
                        lng: longtitude,
                    }}
                    defaultZoom={11}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({map, maps}) => {
                        console.log("googleAPI loaded");
                        setGoogleAPI({map, maps});
                        setMapLoaded(true);
                    }}
                >
                    {geoLoading ? (
                        <>
                            <div className={classes.geoLoadingCircle}>
                                <LoadingCircle/>
                            </div>
                            <Backdrop
                                className={classes.backdrop}
                                open={geoLoading}
                            ></Backdrop>
                        </>
                    ) : (
                        latLngs.map((location, index) => {
                            return (
                                <MapMarker
                                    key={index}
                                    info={location}
                                    title={""}
                                    lat={location.lat}
                                    lng={location.lng}
                                    handleMarkerClick={(e) =>
                                        handleMarkerClick(
                                            e,
                                            location.shipment_task_number,
                                            location.lat,
                                            location.lng
                                        )
                                    }
                                />
                            );
                        })
                    )}
                </GoogleMapReact>
            </div>
            {loading ? (
                <LoadingCircle/>
            ) : (
                <>
                    <MapList
                        shipments={shipments}
                        handleCardClick={(lat, lng, sNum) =>
                            handleCardClick(lat, lng, sNum)
                        }
                        page={pageNumber}
                        lastPage={lastPage}
                        pageChange={(newPage) => {
                            setPagenumber(newPage);
                        }}
                    />
                </>
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        searchOptions: state.searchFilter,
    };
};

export default connect(mapStateToProps)(ShipmentMapLayout);
