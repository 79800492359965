import Grid from "@mui/material/Grid";
import * as React from "react";
import {useState} from "react";
import Box from "@mui/material/Box";
import {ReactComponent as ShipmentOverviewIcon} from "../../images/NewDesignIcon/ShipmentOverview.svg";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import {MenuItem, Select} from "@mui/material";
import {MovingOrderDetailsCreditCard} from "./MovingOrderDetailsCreditCard";
import {ShippingOrderDetailsWeChat} from "../ShippingOrderDetails/NewDesigin/ShippingOrderDetailsWeChat";
import {MovingOrderDetailsWeChatPay} from "./MovingOrderDetailsWeChatPay";
import {MovingOrderDetailsAliPay} from "./MovingOrderDetailsAliPay";
import {useSelector} from "react-redux";

const styles = {
    ShippingPackageCreateLabelOrderDetailsRoot: {
        backgroundColor: '#FFFFFF',
        paddingBottom: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0'
    },
    ShippingPackageCreateLabelOrderDetailsHeading: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsSubHeading: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsTitle: {
        fontSize: '16px',
        textAlign: 'center'
    },
    ShippingPackageCreateLabelOrderDetailsTextLeft: {
        fontSize: '16px',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsTextRight: {
        fontSize: '16px',
        textAlign: 'right'
    },
    ShippingPackageCreateLabelOrderDetailsTitleRight: {
        fontSize: '18px',
        textAlign: 'right',
        fontWeight: '600'
    },
    ShippingPackageCreateLabelOrderDetailsSection: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #A9A9A9',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelOrderDetailsSectionPrice: {
        // backgroundColor: '#FFFFFF',
        padding: '20px',
        // borderRadius: '10px 10px 0 0',
        // border: '2px solid #609966',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelOrderDetailsOtherBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsOtherBoxRight: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsOtherBoxCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsImageBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingLeft: '20px',
        gap: '10px'
    },
    ShippingOrderDetailsPayment: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'column',
        gap: '20px'
    },
}

export const MovingOrderDetailsPayment = ({orderDetails, orderId}) => {

    const [selectPayment, setSelectPayment] = useState('credit');
    let basicRateInfo = useSelector(state => state?.movingService?.movingServiceBasicRateInfo);

    const handleSelectPayment = (event) => {
        setSelectPayment(event?.target?.value);
        // cbHandlePayment(method);
    }

    console.log('[MovingOrderDetailsPayment] orderDetails', orderDetails);

    return (
        <Box sx={styles.ShippingPackageCreateLabelOrderDetailsRoot}>
            <Box sx={{
                display: 'flex',
                gap: '10px',
                padding: '20px 20px 0 20px'
            }}>
                <ShipmentOverviewIcon width={25} height={25}/>
                <Typography style={styles.ShippingPackageCreateLabelOrderDetailsHeading}>
                    {
                        orderDetails?.order_sub_type?.toUpperCase() === 'EXPRESS' ? 'Payment Methods' : 'Deposit'
                    }
                </Typography>
            </Box>
            <Divider/>
            <Box sx={{
                backgroundColor: '#FFFFFF',
                padding: '0 40px',
                gap: '40px',
                display: 'flex',
            }}>
                <Grid container spacing={1}>
                    {
                        basicRateInfo?.totalCharge > 0 && (
                            <>
                                <Grid item xs={12}>
                                    <Typography sx={{fontWeight: '600'}}>
                                        {
                                            orderDetails?.order_sub_type?.toUpperCase() === 'EXPRESS' ? `Proceed to checkout to pay $ ${(+orderDetails?.orderPrice?.final_total)?.toFixed(2)} CAD.` : `Proceed to checkout to pay $ 50 CAD deposit and secure your order.`

                                        }
                                    </Typography>
                                    <Typography sx={{fontWeight: '600'}}>
                                        The payment is fully refundable before the order is picked up for moving.
                                    </Typography>
                                </Grid>
                            </>
                        )
                    }
                    {/*<Grid item xs={12}>*/}
                    {/*    <Typography sx={{fontWeight: '600'}}>*/}
                    {/*        {*/}
                    {/*            orderDetails?.order_sub_type?.toUpperCase() === 'EXPRESS' ? `Proceed to checkout to pay $ ${(+orderDetails?.orderPrice?.final_total)?.toFixed(2)} CAD.` : `Proceed to checkout to pay $ 50 CAD deposit and secure your order.`*/}

                    {/*        }*/}
                    {/*    </Typography>*/}
                    {/*    <Typography sx={{fontWeight: '600'}}>*/}
                    {/*        The payment is fully refundable before the order is picked up for moving.*/}
                    {/*    </Typography>*/}
                    {/*</Grid>*/}
                    <Grid item xs={12}>
                        <Typography sx={{fontWeight: '600'}}>
                            Select your payment method
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <Select
                                value={selectPayment}
                                size='small'
                                onChange={handleSelectPayment}
                            >
                                <MenuItem value='credit'>
                                    Credit Card
                                </MenuItem>
                                <MenuItem value='wechatPay'>
                                    WeChat Pay
                                </MenuItem>
                                <MenuItem value='aliPay'>
                                    Ali Pay
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={styles.ShippingOrderDetailsPayment}>
                            {
                                selectPayment === 'credit' &&
                                <MovingOrderDetailsCreditCard orderDetails={orderDetails} orderId={orderId}/>
                            }
                            {
                                selectPayment === 'wechatPay' &&
                                <MovingOrderDetailsWeChatPay orderDetails={orderDetails} orderId={orderId}/>
                            }
                            {
                                selectPayment === 'aliPay' &&
                                <MovingOrderDetailsAliPay orderDetails={orderDetails} orderId={orderId}/>
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}