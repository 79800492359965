import React, { useEffect, useState } from "react";
import ShipmentDetailLayout from "../Layouts/ShipmentDetailLayout";
import { useParams } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import { driverClearAllShipmentsFromSummary } from "../../actions/driverClaimShipmentAction";
import { makeStyles } from "@material-ui/core";
import LoadingCircle from "../LoadingCircle";
import axios from "axios";
import { getTokenFromCookie } from "../../Utils/doToken";
import {GET_SHIPMENTS_BY_ID_API, NODE_ROUTE_URI} from "../../Utils/apiUrl";
import {SummarySection} from "../Shipments/SummarySection";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#111",
    fontFamily: "Helvetica Neue, sans-serif",
    fontSize: "30px",
    fontWeight: "bold",
    letterSpacing: "-1px",
    lineHeight: 1,
    margin: "5px 2%",
  },
}));

const ShipmentTaskDetailPage = ({ claimSummary }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loadShipments, setloadShipments] = useState(true);
  const [displaySummary, setDisplaySummary] = useState(false);

  const [shipmentInfo, setShipmentInfo] = useState({});

  const shipment_number = useParams().id;
  const stage = useParams().stage;

  const reflashCallback = (action) => {
    if (action === true) {
      fetchShipmentInfo();
    }
  };

  useEffect(() => {
    console.log("Hello Shipment Task Detail Page!");
  }, [])

  const fetchShipmentInfo = () => {
    //fetch shipment and its package
    setloadShipments(true);
    (async () => {
      try {
        // axios
        //   .get(`${NODE_ROUTE_URI}/shipment_task/getShipmentTaskInfoByTaskIdOrShipmentNumberStageId`, {
        //     params: {
        //       shipmentNumber: shipment_number,
        //       stage: stage,
        //     },
        //     headers: {
        //       Accept: "application/json",
        //       Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
        //     },
        //   })
        //   .then((res) => {
        //     console.log(res);
        //     setShipmentInfo(res.data);
        //     //setOrderNumber(res.data.order_number)
        //   })
        //     .finally(() => {
        //       setloadShipments(false);
        //     });
        const { data } = await axios.get(`${NODE_ROUTE_URI}/shipment_task/getShipmentTaskInfoByTaskIdOrShipmentNumberStageId`, {
          params: {
            shipmentNumber: shipment_number,
            stage: stage,
          },
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
          },
        });

        const availableCount = (await axios.post(`${NODE_ROUTE_URI}/shipment_task/getAvailableShipmentTaskCount`, {
          shipmentNumber: shipment_number,
          stageId: stage
        }, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
          },
        })).data.result;
        data.available_count = availableCount;
        setShipmentInfo(data);

      } catch (error) {
        setShipmentInfo({});
        setloadShipments(false);
      } finally {
        setloadShipments(false);
      }
    })();

  };

  useEffect(() => {
    fetchShipmentInfo();

    //clean up summary
    return () => {
      dispatch(driverClearAllShipmentsFromSummary());
    };
  }, [dispatch, shipment_number]);

  return (
    <>
      {!loadShipments && shipmentInfo?.id ? (
        <ShipmentDetailLayout {...shipmentInfo} />
      ) : (
        <LoadingCircle />
      )}
      {/* {
      (!loadOtherShipments && otherShipments.length > 0) && <Typography className={classes.title}>Other Shipments</Typography>
    }
    {
      !loadOtherShipments ?
        otherShipments.map(shipment => <ShipmentDetailLayout packageInfo={shipment.package} shipmentInfo={shipment}/>)
      :
        <LoadingCircle/>
    }   */}
      {claimSummary.driver_claimed_shipments.length > 0 && (
        <SummarySection
          sectionTitle={"Summary"}
          packages={claimSummary.driver_claimed_shipments}
          reflash={(action) => reflashCallback(action)}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    claimSummary: state.driver_claim_summary,
  };
};

export default connect(mapStateToProps)(ShipmentTaskDetailPage);
