import {useForm} from "react-hook-form";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useEffect, useRef, useState} from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {Checkbox, Container, MenuItem, Select, Snackbar} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import {BrokerageItem} from "./BrokerageItem";
import {BrokerageItemEdit} from "./BrokerageItemEdit";
import {
    americaProvinceCodeToNameMap,
    canadaProvinceCodeToNameMap,
    internationalShipmentTypeList,
    transportationListToCodeMap
} from "../../shared";
import axios from "axios";
import {BrokerageEstimate} from "./BrokerageEstimate";
import MuiAlert from '@mui/material/Alert';
import * as React from 'react';
import {getCountryCode} from "../../../Utils/getCountryCode";
import {BusinessMyBoxesSelect} from "../../MyBoxes/Business/BusinessMyBoxesSelect";
import PackingListIcon from "../../../images/NewDesignIcon/PackageDetails.svg";
import IconButton from "@mui/material/IconButton";
import {useHistory} from "react-router-dom";
import {styled} from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {useMediaQuery} from "@material-ui/core";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import packageIcon from "../../../images/homepageIcon/Package.svg";
import ltlIcon from "../../../images/homepageIcon/Pallet.svg";
import brokerageIcon from "../../../images/homepageIcon/CustomBrokerage.svg";
import PublicIcon from "@mui/icons-material/Public";
import intl from "react-intl-universal";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import {getAccessToken} from "../../../Utils/doToken";

const useStyles = makeStyles((theme) => ({}))

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{children: <span className="MuiTabs-indicatorSpan"/>}}
    />
))({
    border: '1px solid #609966',
    borderRadius: '25px',
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        // maxWidth: 40,
        width: '100%',
        backgroundColor: 'transparent',
    },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({theme}) => ({
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        color: '#000000',
        // border: '1px solid #609966',
        // borderRadius: '25px',
        '&.Mui-selected': {
            color: '#ffffff',
            backgroundColor: '#609966',
            borderRadius: '25px'
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
    }),
);

export const Brokerage = () => {
    const {register, handleSubmit, errors} = useForm();

    const [edit, setEdit] = useState(false)

    const [boxInformation, setBoxInformation] = useState({})

    const CanadianProvinceList = Object.entries(canadaProvinceCodeToNameMap).map(([key, value]) => [key, value]);

    const AmericanStateList = Object.entries(americaProvinceCodeToNameMap).map(([key, value]) => [key, value]);

    const TransportationModeList = Object.entries(transportationListToCodeMap).map(([key, value]) => [key, value]);

    const [brokerageEstimate, setBrokerageEstimate] = useState([])

    const [updateIndex, setUpdateIndex] = useState(null)

    const [open, setOpen] = useState(false);

    const [itemId, setItemId] = useState(0);

    const [message, setMessage] = useState('');

    const [submit, setSubmit] = useState(false);

    const [shipmentItems, setShipmentItems] = useState([]);

    const [section, setSection] = useState('CostSection');

    const [toastOpen, setToastOpen] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");

    const [successMessage, setSuccessMessage] = useState("");

    const [show, setShow] = useState('hidden');

    const [tabValue, setTabValue] = useState('brokerage');

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleOpenShow = () => setShow('visible');

    const handleCloseShow = () => setShow('hidden');

    const history = useHistory();

    const isMobile = useMediaQuery('(max-width: 600px)');

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false)
    }

    const [brokerageData, setBrokerageData] = useState({
        importCountryCode: "CA",
        importProvince: "BC",
        exportCountryCode: "CN",
        transModes: "INT_OCEAN",
        shipmentType: "COMMERCIAL",
        shipmentItems: []
    })

    const resetData = {
        importCountryCode: "CA",
        importProvince: "BC",
        exportCountryCode: "CN",
        transModes: "INT_OCEAN",
        shipmentType: "COMMERCIAL",
        shipmentItems: []
    }

    const handleSuccessMessage = () => {
        setToastOpen(true);
        setSuccessMessage('Successfully get brokerage estimate cost.');
        setErrorMessage('');
    }

    const handleErrorMessage = (errors) => {
        setToastOpen(true);
        let errorMessage = '';
        let generalMessage = '';
        let currencyMessage = '';

        // error code 105.001
        const generalError = errors.filter(item => item.code === "105.001");
        if (generalError[0]?.message) generalMessage = generalError[0].message + '.';

        // error code 105.300
        const currencyError = errors.filter(item => item.code === "105.300");
        if (currencyError[0]?.message) {
            currencyMessage = currencyError[0].message + '.'
                + 'Please ensure that the currency field contains the same currency code in all items. ' +
                'Otherwise, we will be unable to provide you with an accurate cost estimate.';
            setBrokerageEstimate([]);
        }

        if (errors.length > 0) {
            for (let i = 0; i < errors.length; i++) {
                if ('index' in errors[i]) {
                    errorMessage += ` ${errors[i].commodityId}: ${errors[i].message}. `;
                }
            }
        }
        setErrorMessage(`${currencyMessage} ${generalMessage} ${errorMessage}`)
    }

    const getLandedCost = async (data) => {
        let requestURI = `${PARTNER_URI}/brokerage/landedCost`;
        // let data = JSON.stringify(testData)
        const storedToken = getAccessToken("access_token");

        console.log('data before call api', data)

        try {
            const result = await axios({
                method: 'post',
                url: requestURI,
                data: data,
                headers: {
                    authorization: `Bearer ${storedToken}`
                }
            })
            const {error} = result.data[0];
            setBrokerageEstimate(result.data);
            error === null ? handleSuccessMessage() : handleErrorMessage(error?.errors);
            setSubmit(true)
        } catch (e) {
            setErrorMessage(e?.response?.data?.error || 'Fail to get rating.');
            setToastOpen(true)
            // console.log(e?.response?.data?.message || e?.response?.data?.error?.message || 'Error')
            console.log(e?.response);
        }
    }

    const handleRemoveSection = (itemID) => {
        console.log(itemID)
        setShipmentItems(prevState => (prevState.filter(value => value.itemID !== itemID)))
    };

    const handleUpdatedSection = (itemNum) => {
        // console.log('item id',itemNum)
        setUpdateIndex(itemNum)
    }

    const getDataFromItem = (dataFromItem) => {
        console.log(dataFromItem)
        dataFromItem.itemID = itemId;
        setItemId(itemId + 1)
        setShipmentItems(prevState => ([
            ...prevState, dataFromItem
        ]))
    }

    const updateDataFromItem = (dataFromItem) => {
        console.log('data from child', dataFromItem)

        const updateShipmentItems = shipmentItems.map(value => {
            if (value.itemID === dataFromItem.itemID) {
                return dataFromItem
            } else {
                return value
            }
        })
        setShipmentItems(updateShipmentItems)
    }

    const retake = () => {
        setBrokerageData(resetData)
        setShipmentItems([])
        setSubmit(false)
    }

    const onSubmit = () => {
        if (brokerageData.exportCountryCode === brokerageData.importCountryCode) {
            setErrorMessage("Import and Export Country can not be same!")
            setToastOpen(true)

        } else if (shipmentItems?.length === 0) {
            setErrorMessage("You need add item before submit!")
            setToastOpen(true)
        } else {
            console.log("before", shipmentItems)
            let apiData = {
                importCountryCode: brokerageData.importCountryCode,
                importProvince: brokerageData.importProvince,
                exportCountryCode: brokerageData.exportCountryCode,
                transModes: brokerageData.transModes,
                shipmentType: brokerageData.shipmentType,
                shipmentItems: shipmentItems
            }

            console.log("api data", apiData)
            getLandedCost(apiData);

            setBrokerageData(apiData);
        }

    }

    console.log('brokerage data', brokerageData)

    console.log('shipment item', shipmentItems)

    const getBoxInformation = (data) => {
        // console.log('box data', data)
        setBoxInformation(data)
    }

    useEffect(() => {
        if (tabValue === 'ltl') {
            history.push('/LTL-quick-quote')
        } else if (tabValue === 'package') {
            history.push('/shipping-package/quick-quote')
        } else {
            return;
        }
    }, [tabValue])

    // console.log('box information', boxInformation)

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box>
                {/*<Box>*/}
                <Box sx={{
                    backgroundColor: '#FFFFFF',
                    padding: '20px',
                    marginBottom: '20px',
                    borderRadius: '10px',
                    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                    gap: '10px',
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <Typography style={{
                        fontSize: '20px',
                        fontWeight: '600',
                        textAlign: 'left'
                    }}>
                        Select Your Packaging Type / Service
                    </Typography>
                    <Box sx={{
                        width: isMobile ? '300px' : '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        gap: '10px'
                    }}>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Checkbox
                                icon={<RadioButtonUncheckedIcon/>}
                                checkedIcon={<CheckCircleIcon/>}
                                color="success"
                                onClick={() => history.push('/business/shipping-package/quick-quote')}
                            />
                            <img src={packageIcon} alt="packageIcon" width='40px'/>
                            <Typography style={{color: '#545454', padding: '0 5px'}}>
                                {intl.get('QUICK_QUOTE_NAV.PACKAGE')}
                            </Typography>
                        </Box>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Checkbox
                                icon={<RadioButtonUncheckedIcon/>}
                                checkedIcon={<CheckCircleIcon/>}
                                color="success"
                                onClick={() => history.push('/business/shipping-package/international-shipment')}
                            />
                            <PublicIcon sx={{
                                fontSize: '40px'
                            }}/>
                            <Typography style={{color: '#545454', padding: '0 5px'}}>
                                {intl.get('QUICK_QUOTE_NAV.INTERNATION')}
                            </Typography>
                        </Box>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Checkbox
                                icon={<RadioButtonUncheckedIcon/>}
                                checkedIcon={<CheckCircleIcon/>}
                                color="success"
                                onClick={() => history.push('/business/shipping-ltl/quick-quote')}
                            />
                            <img src={ltlIcon} alt="ltlIcon" width='40px'/>
                            <Typography style={{color: '#545454', padding: '0 5px'}}>
                                {intl.get('QUICK_QUOTE_NAV.LTL')}
                            </Typography>
                        </Box>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Checkbox
                                icon={<RadioButtonUncheckedIcon/>}
                                checkedIcon={<CheckCircleIcon/>}
                                color="success"
                                checked
                            />
                            <img src={brokerageIcon} alt="brokerageIcon" width='40px'/>
                            <Typography style={{color: '#545454', padding: '0 5px'}}>
                                {intl.get('QUICK_QUOTE_NAV.BROKERAGE')}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Typography variant="h5" style={{textAlign: 'left', marginTop: '20px', fontWeight: '600'}}>
                    Brokerage Estimate Form
                </Typography>
                <Box>
                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 1}}>
                        <Typography variant="h6" sx={{textAlign: "left"}}>
                            Goods and Commodity List
                        </Typography>
                        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Box sx={{display: 'flex', justifyContent: 'flex-end', position: 'relative'}}>
                                <IconButton
                                    onClick={() => {
                                        history.push("/business/brokerage/packing-list");
                                    }}
                                    onMouseEnter={handleOpenShow}
                                    onMouseLeave={handleCloseShow}
                                >
                                    <img src={PackingListIcon} alt="box" width='30px'/>
                                </IconButton>
                                <Typography
                                    sx={{
                                        visibility: `${show}`,
                                        backgroundColor: '#424242',
                                        width: '150px',
                                        height: '20px',
                                        borderRadius: '5px',
                                        color: '#ffffff',
                                        fontSize: '12px',
                                        position: 'absolute',
                                        top: '-25%',
                                        right: '5%'
                                    }}
                                >From My Packing List
                                </Typography>
                            </Box>
                            <BusinessMyBoxesSelect boxInformation={getBoxInformation}/>
                        </Box>
                    </Box>
                    <BrokerageItemEdit getDataFromItem={getDataFromItem} boxInformation={boxInformation}/>
                </Box>
                <hr/>
                <Box>
                    <Typography sx={{textAlign: 'left'}}>Add Item</Typography>
                    {shipmentItems.length > 0 && shipmentItems?.map((value, index) => {
                        return <BrokerageItem
                            key={index}
                            itemInfo={value}
                            count={index}
                            // addItem={() => handleAddSection()}
                            removeItem={handleRemoveSection}
                            updateItem={() => handleUpdatedSection(index)}
                            updateDataFromItem={updateDataFromItem}
                            // submitForm={submit}
                        />
                    })}
                </Box>
                <hr/>
                {/*<BrokerageDelivery getDataFromDelivery={getDataFromDelivery}/>*/}
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '50px'}}>
                    <Box sx={{backgroundColor: '#ffffff', borderRadius: '10px', width: '100%'}}>
                        <Box sx={{display: 'flex', margin: '20px 10px'}}>
                            <Typography variant="h6" sx={{textAlign: "left", minWidth: '200px'}}>
                                Import From
                            </Typography>
                            <FormControl variant="standard" fullWidth>
                                {/*<InputLabel>Export Country*</InputLabel>*/}
                                <Select
                                    value={brokerageData?.exportCountryCode}
                                    label="export country"
                                    onChange={e => setBrokerageData(prevState => ({
                                        ...prevState,
                                        exportCountryCode: e.target.value
                                    }))}
                                >
                                    {
                                        getCountryCode().map(v => {
                                            return <MenuItem value={v.code}>{v.name}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                        <Box Box sx={{display: 'flex', margin: '20px 10px'}}>
                            <Typography variant="h6" sx={{textAlign: "left", minWidth: '200px'}}>
                                Export To
                            </Typography>
                            <FormControl variant="standard" fullWidth>
                                {/*<InputLabel>Import province*</InputLabel>*/}
                                <Select
                                    value={brokerageData?.importProvince}
                                    label="import province or state"
                                    onChange={e => setBrokerageData(prevState => ({
                                        ...prevState,
                                        importProvince: e.target.value
                                    }))}
                                >
                                    {brokerageData?.importCountryCode === 'CA' ? (
                                        CanadianProvinceList.map(value => {
                                            return <MenuItem value={value[0]}>{value[1]}</MenuItem>
                                        })) : (AmericanStateList.map(value => {
                                        return <MenuItem value={value[0]}>{value[1]}</MenuItem>
                                    }))
                                    }
                                </Select>
                            </FormControl>
                            <FormControl variant="standard" fullWidth>
                                {/*<InputLabel>Import Country*</InputLabel>*/}
                                <Select
                                    value={brokerageData?.importCountryCode}
                                    label="import province"
                                    onChange={e => setBrokerageData(prevState => ({
                                        ...prevState,
                                        importCountryCode: e.target.value
                                    }))}
                                >
                                    <MenuItem value={'CA'}>Canada</MenuItem>
                                    <MenuItem value={'US'}>USA</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{display: 'flex', margin: '20px 10px'}}>
                            <Typography variant="h6" sx={{textAlign: "left", minWidth: '200px'}}>
                                Trans Mode
                            </Typography>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel>Tran Mode*</InputLabel>
                                <Select
                                    value={brokerageData?.transModes}
                                    label="trasn mode"
                                    onChange={e => setBrokerageData(prevState => ({
                                        ...prevState,
                                        transModes: e.target.value
                                    }))}
                                >
                                    {
                                        TransportationModeList.map(value => {
                                            return <MenuItem value={value[0]}>{value[1]}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel>Shipment Type*</InputLabel>
                                <Select
                                    value={brokerageData?.shipmentType}
                                    label="import province"
                                    onChange={e => setBrokerageData(prevState => ({
                                        ...prevState,
                                        shipmentType: e.target.value
                                    }))}
                                >
                                    {internationalShipmentTypeList.map(value => {
                                        return <MenuItem value={value}>{value}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                </Box>
                {submit ?
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Button
                            variant="contained"
                            type="submit"
                            onClick={handleSubmit(onSubmit)}
                            sx={{margin: '20px'}}
                        >Resubmit
                        </Button>
                        <Button
                            variant="contained"
                            type="submit"
                            sx={{margin: '20px'}}
                            onClick={retake}
                        >
                            Retake
                        </Button>
                    </Box>

                    :
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Button
                            variant="contained"
                            type="submit"
                            onClick={handleSubmit(onSubmit)}
                            sx={{margin: '20px'}}
                        >Submit
                        </Button>
                    </Box>
                }
                {brokerageEstimate?.length > 0 &&
                    <BrokerageEstimate brokerageEstimate={brokerageEstimate} brokerageData={brokerageData}/>}
            </Box>
        </Container>
    )
}