import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import React from "react";
import {MovingOrderDashboardPayment} from "./MovingOrderDashboardPayment";
import {Button} from "@mui/material";
import {useHistory} from "react-router-dom";
import {Fragment} from "react";

export const MovingOrderDashboardApprovedPrice = ({orderDetails, downPayment, paidPayment}) => {

    const history = useHistory();

    console.log('[MovingOrderDashboardApprovedPrice] paidPayment', paidPayment);

    return (
        <Box sx={{
            border: '1px solid #B2B2B2',
            borderRadius: '10px',
            padding: '10px 0'
        }}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '20px',
                        borderBottom: '1px solid #B2B2B2',
                        alignItems: 'center'
                    }}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            Approved Price
                        </Typography>
                    </Box>
                </Grid>
                {
                    +orderDetails?.orderPrice?.moving_freight_charge > 0 &&
                    <>
                        <Grid item xs={7}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                justifyContent: 'flex-start',
                                padding: '0 20px'
                            }}>
                                <Typography style={{fontSize: '14px'}}>
                                    Freight Charge
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={5}>
                            <Box sx={{
                                padding: '0 20px'
                            }}>
                                <Typography style={{textAlign: 'right', fontSize: '14px'}}>
                                    $ {(+orderDetails?.orderPrice?.moving_freight_charge)?.toFixed(2)}
                                </Typography>
                            </Box>
                        </Grid>
                    </>
                }
                {
                    +orderDetails?.orderPrice?.moving_pickup_charge > 0 &&
                    <>
                        <Grid item xs={7}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                justifyContent: 'flex-start',
                                padding: '0 20px'
                            }}>
                                <Typography style={{fontSize: '14px'}}>
                                    Pickup Charge
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={5}>
                            <Box sx={{
                                padding: '0 20px'
                            }}>
                                <Typography style={{textAlign: 'right', fontSize: '14px'}}>
                                    $ {(+orderDetails?.orderPrice?.moving_pickup_charge)?.toFixed(2)}
                                </Typography>
                            </Box>
                        </Grid>
                    </>
                }
                {
                    +orderDetails?.orderPrice?.moving_deliver_charge > 0 &&
                    <>
                        <Grid item xs={7}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                justifyContent: 'flex-start',
                                padding: '0 20px'
                            }}>
                                <Typography style={{fontSize: '14px'}}>
                                    {orderDetails?.deliver_addr_type === 'SELF' ? 'Operation Charge' : 'Delivery Charge'}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={5}>
                            <Box sx={{
                                padding: '0 20px'
                            }}>
                                <Typography style={{textAlign: 'right', fontSize: '14px'}}>
                                    $ {(+orderDetails?.orderPrice?.moving_deliver_charge)?.toFixed(2)}
                                </Typography>
                            </Box>
                        </Grid>
                    </>
                }
                {
                    +orderDetails?.orderPrice?.moving_storage_charge > 0 &&
                    <>
                        <Grid item xs={7}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                justifyContent: 'flex-start',
                                padding: '0 20px'
                            }}>
                                <Typography style={{fontSize: '14px'}}>
                                    Storage Charge
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={5}>
                            <Box sx={{
                                padding: '0 20px'
                            }}>
                                <Typography style={{textAlign: 'right', fontSize: '14px'}}>
                                    $ {(+orderDetails?.orderPrice?.moving_storage_charge)?.toFixed(2)}
                                </Typography>
                            </Box>
                        </Grid>
                    </>
                }
                {
                    +orderDetails?.orderPrice?.moving_palleting_charge > 0 &&
                    <>
                        <Grid item xs={7}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                justifyContent: 'flex-start',
                                padding: '0 20px'
                            }}>
                                <Typography style={{fontSize: '14px'}}>
                                    Pallet Charge
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={5}>
                            <Box sx={{
                                padding: '0 20px'
                            }}>
                                <Typography style={{textAlign: 'right', fontSize: '14px'}}>
                                    $ {(+orderDetails?.orderPrice?.moving_palleting_charge)?.toFixed(2)}
                                </Typography>
                            </Box>
                        </Grid>
                    </>
                }
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={7}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-start',
                        padding: '0 20px'
                    }}>
                        <Typography style={{fontSize: '14px'}}>
                            Subtotal
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    <Box sx={{
                        padding: '0 20px'
                    }}>
                        <Typography style={{textAlign: 'right', fontSize: '14px'}}>
                            $ {(+orderDetails?.orderPrice?.total)?.toFixed(2)}
                        </Typography>
                    </Box>
                </Grid>
                {
                    (+orderDetails?.taskTotalCharge - +orderDetails?.orderPrice?.total) !== 0 &&
                    <>
                        <Grid item xs={7}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                justifyContent: 'flex-start',
                                padding: '0 20px'
                            }}>
                                <Typography style={{fontSize: '14px'}}>
                                    Adjustment Amount
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={5}>
                            <Box sx={{
                                padding: '0 20px'
                            }}>
                                <Typography style={{textAlign: 'right', fontSize: '14px'}}>
                                    $ {(+orderDetails?.taskTotalCharge - +orderDetails?.orderPrice?.total)?.toFixed(2)}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={7}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                justifyContent: 'flex-start',
                                padding: '0 20px'
                            }}>
                                <Typography style={{fontSize: '14px'}}>
                                    Adjusted Subtotal
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={5}>
                            <Box sx={{
                                padding: '0 20px'
                            }}>
                                <Typography style={{textAlign: 'right', fontSize: '14px'}}>
                                    $ {(+orderDetails?.taskTotalCharge)?.toFixed(2)}
                                </Typography>
                            </Box>
                        </Grid>
                    </>
                }
                <Grid item xs={7}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-start',
                        padding: '0 20px'
                    }}>
                        <Typography style={{fontSize: '14px'}}>
                            Tax
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    <Box sx={{
                        padding: '0 20px'
                    }}>
                        <Typography style={{textAlign: 'right', fontSize: '14px'}}>
                            $ {(+orderDetails?.taskTaxCharge)?.toFixed(2)}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={7}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-start',
                        padding: '0 20px'
                    }}>
                        <Typography style={{fontSize: '14px'}}>
                            Total
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    <Box sx={{
                        padding: '0 20px'
                    }}>
                        <Typography style={{textAlign: 'right', fontSize: '14px'}}>
                            $ {(+orderDetails?.taskFinalTotal)?.toFixed(2)}
                        </Typography>
                    </Box>
                </Grid>
                {
                    paidPayment?.map((payment, index) => (
                        <Fragment key={index}>
                            <Grid item xs={7}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '100%',
                                    justifyContent: 'flex-start',
                                    padding: '0 20px'
                                }}>
                                    <Typography style={{fontSize: '14px'}}>
                                        {payment?.remark_notes === "Down_Payment" ? "Prepaid Amount" : "Payment"}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={5}>
                                <Box sx={{
                                    padding: '0 20px'
                                }}>
                                    <Typography style={{textAlign: 'right', fontSize: '14px'}}>
                                        - $ {payment?.amount.toFixed(2)}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Fragment>
                    ))
                }
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={7}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-start',
                        padding: '0 20px'
                    }}>
                        <Typography style={{fontSize: '14px'}}>
                            Amount To Be Paid
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    <Box sx={{
                        padding: '0 20px'
                    }}>
                        <Typography style={{textAlign: 'right', fontSize: '14px'}}>
                            $ {orderDetails?.task_unpaid_amount?.toFixed(2)}
                        </Typography>
                    </Box>
                </Grid>
                {/*<MovingOrderDashboardPayment paymentModalOpen={true}/>*/}
                {
                    (orderDetails?.task_unpaid_amount > 0 && (
                        orderDetails?.shipping_status_id === 25 ||
                        orderDetails?.shipping_status_id === 3 ||
                        orderDetails?.shipping_status_id === 40 ||
                        orderDetails?.shipping_status_id === 4
                    )) &&
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            height: '100%',
                            justifyContent: 'flex-end',
                            padding: '0 20px'
                        }}>
                            <Button
                                variant='contained'
                                sx={{
                                    backgroundColor: '#1D8B45',
                                    "&:hover": {
                                        backgroundColor: '#1D8B45',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                size='small'
                                onClick={() => history.push(`/transportation/orderPay/${orderDetails?.order_id}`)}
                            >
                                <Typography style={{textTransform: 'none'}}>
                                    Pay
                                </Typography>
                            </Button>
                        </Box>
                    </Grid>
                }
            </Grid>
        </Box>
    )
}