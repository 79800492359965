import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addLoadedShipments } from "../../actions/driverLoadAndDeliverShipmentsAction";
import {
  makeStyles,
  Paper,
  Grid,
  Typography,
  Button,
  Backdrop,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  ButtonBase,
} from "@material-ui/core";
import {
  GET_SHIPMENTS_BY_ID_API,
  DRIVER_UNCLAIM_SHIPMENT_BY_SHIPMENT_ID, NODE_ROUTE_URI,
} from "../../Utils/apiUrl";
import LoadingCircle from "../LoadingCircle";
import { getTokenFromCookie } from "../../Utils/doToken";
import axios from "axios";
import ShipmentOptionsLayout from "../Layouts/ShipmentOptionsLayout";
import { pageTitle, general_info_label } from "../../css/MUIStyle";
import { shipmentTypesDetails } from "../shared";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: pageTitle,
  paper: {
    margin: "5px auto",
    width: "96%",
    maxWidth: "700px",
    backgroundColor: "#fdf5e8",
    padding: "3px",
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
  },
  shipmentWrapper: {
    width: "100%",
    height: "fit-content",
    marginTop: "5px",
    padding: "10px",
    display: "flex",
    flexDirection: "column",
  },
  blocks: {
    backgroundColor: "#fbfbf8",
    border: "1px solid #ded47c",
    borderRadius: "5px",
    padding: "5px",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
  },
  backDrop: {
    zIndex: 1,
    color: "#fff",
  },
  trackingNumbersWrapper: {
    width: "100%",
    margin: "5px auto",
    listStyleType: "none",
    border: "1px solid #ded47c",
    borderRadius: "5px",
    backgroundColor: "#fbfbf8",
    padding: "5px",
  },
  trackingNumberList: {
    display: "flex",
    width: "100%",
    height: "fit-content",
    flexWrap: "wrap",
    gap: "5px",
  },
  pictureWrapper: {
    width: "fit-content",
    maxWidth: "50%",
    height: "fit-content",
    maxHeight: "250px",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  imageContainer: {
    margin: "0px auto",
  },
  shipmentInfo: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    gap: "5px",
    height: "fit-content",
    width: "100%",
    maxWidth: "650px",
    margin: "0px auto",
  },
  shippingInfo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "5px auto",
    height: "fit-content",
    width: "100%",
  },
}));

const ClaimedShipmentDetailPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [fineAmount, setFineAmount] = useState(null);
  const [unclaimSuccess, setUnclaimSuccess] = useState(false);
  const [shipmentInfo, setShipmentInfo] = useState([]);
  const [open, setOpen] = useState(false);
  const cardActive = useSelector((state) => {
    return state.user.activeCard === "active";
  });

  let { id, stage } = useParams();
  const history = useHistory();

  const getShipmentInfo = async () => {
    setLoading(true);
    try {
      // const result = await axios.get(GET_SHIPMENTS_BY_ID_API, {
      //   headers: {
      //     Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
      //     "Content-Type": "application/json",
      //   },
      //   params: {
      //     shipment_number: id,
      //     stage: stage,
      //   },
      // });
      const { data } = await axios.get(`${NODE_ROUTE_URI}/shipment_task/getShipmentTaskInfoByTaskIdOrShipmentNumberStageId`, {
        params: {
          shipmentNumber: id,
          stage: stage,
        },
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
        },
      });
      console.log("claimed shipment: ", data);
      setShipmentInfo(data);
      setLoading(false);
    } catch (error) {
      console.log("Error when trying to get shipment/package data", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getShipmentInfo();
  }, []);

  const handlePickupShipment = () => {
    // const trackingNumbers = [];
    // const src = shipmentInfo.tracking_numbers;
    // if (src.length > 0) {
    //   src.forEach((t) => {
    //     trackingNumbers.push(t.tracking_number);
    //   });
    //   console.log("tracking numbers", trackingNumbers);
    // }
    const payload = {
      package_number: shipmentInfo.package_number,
      package_id: shipmentInfo.id,
      item_name: shipmentInfo.item_name,
      cover_image: shipmentInfo.cover_image,
      shipment_number: shipmentInfo.shipment_number,
      tracking_number: shipmentInfo.tracking_numbers,
      stage: shipmentInfo.stage,
      tracking_id: shipmentInfo.id,
      taskId: shipmentInfo.id
    };
    dispatch(addLoadedShipments(payload));
    history.push("/driver/pickup_shipment_tasks");
  };

  const handleCancelClaimClicked = () => {
    setOpenBackDrop(true);
    setOpenDialog(true);
  };

  const handleClickBackDrop = () => {
    setOpenBackDrop(false);
  };

  const handleDialogOnClose = () => {};

  const getPickupDate = (_schedule_pickup) => {
    return _schedule_pickup.split(" ")[0];
  };

  //first request unclaim to get response
  const handleRequestUnclaim = () => {
    let message = "";
    axios
      .post(
          `${NODE_ROUTE_URI}/shipment_task/unclaim`,
        {
          shipmentNumber: shipmentInfo.shipment_number,
          stageId: shipmentInfo.stage,
          taskId: shipmentInfo.id,
        },
        {
          headers: {
            Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(`unclaim shipment result`, res);
          //successfully unclaimed shipment
          // if (res.fine_amount) {
          //   message = "You are able to unclaim this shipment with a fine.";
          //   setErrorMessage(message);
          //   setFineAmount(res.fine_amount);
          // } else {
          //   message = "You are able to unclaim this shipment without a fine.";
          //   setErrorMessage(message);
          //   setFineAmount(null);
          // }
        message = "You have successfully unclaim this shipment.";
        setFineAmount(null);
        setUnclaimSuccess(true);
        setFeedbackMessage(message);
        setOpenConfirmDialog(false);
        setOpenSuccessDialog(true);
      })
      .catch((error) => {
        if (error.response) {
          const response = error.response.data;

          if (response.code === "2004") {
            //driver unclaim with fine
            if (response.fine_amount) {
              setFineAmount(response.fine_amount);
            } else {
              setFineAmount(null);
            }
            setErrorMessage(response.message);
          } else if (response.code === "2000") {
            //unable to unclaim coz shipment not found or order of that shipment is paused
            message = "You are not able to unclaim this shipment. Due to ";
            message += response.message;
            setErrorMessage(message);
          }

          setOpenConfirmDialog(true);
          setOpenDialog(false);
        } else {
          setErrorMessage(
            "Unknown error when unclaim the shipment task, please contact our support."
          );
        }
      });
  };

  const handleCancel = () => {
    setFineAmount(null);
    setOpenDialog(false);
    setOpenBackDrop(false);
    setOpenConfirmDialog(false);
  };

  const handleErrorDialogOnClose = () => {};

  //second request to unclaim
  const handleConfirmUnclaim = () => {
    let message = "";
    axios
      .put(
        DRIVER_UNCLAIM_SHIPMENT_BY_SHIPMENT_ID,
        {},
        {
          headers: {
            Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
            "Content-Type": "application/json",
          },
          params: {
            shipment_number: shipmentInfo.shipment_number,
            stage: shipmentInfo.stage,
            confirmed: 1,
          },
        }
      )
      .then((res) => {
        console.log("confirm unclaim info", res);
        //check status code, if it is 200, unclaim successfully
        if (res.status === 200) {
          message = "You have successfully unclaim this shipment.";
          setFineAmount(null);
          setUnclaimSuccess(true);
          setFeedbackMessage(message);
          setOpenSuccessDialog(true);
          setOpenConfirmDialog(false);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log("confirm unclaim request error", error.request);
          setFeedbackMessage(error.response.data.message);
          setFineAmount(null);
          setUnclaimSuccess(false);
          setOpenSuccessDialog(true);
          setOpenConfirmDialog(false);
        }
      });
  };

  const handleSuccessConfirm = () => {
    setOpenConfirmDialog(false);
    setOpenDialog(false);
    setOpenSuccessDialog(false);
    setOpenBackDrop(false);
    if (unclaimSuccess) {
      history.push("/driver/claimed_shipment_tasks");
    } else {
      //got error when finishing last step of unclaim
      //should stay on current detail page
    }
  };

  const handleSuccessDialogOnClose = () => {};

  const handleImageClick = () => {
    if (shipmentInfo.images.data.length > 0) {
      setOpen(true);
      //setOpenImageView(true);
    }
  };

  return (
    <>
      {!loading ? (
        <>
          <Paper className={classes.paper}>
            {!shipmentInfo?.length > 0 ? (
              <LoadingCircle />
            ) : (
              <>
                <Typography className={classes.title} align="center">
                  {"Shipping Task Detail"}
                </Typography>
                <div
                  className={[classes.blocks, classes.shipmentWrapper].join(
                    " "
                  )}
                >
                  <div>
                    <span style={{ fontWeight: "650", fontSize: "1.2rem" }}>
                      Shipment Number:{" "}
                    </span>
                    <span style={{ textDecoration: "underLine" }}>
                      {shipmentInfo.shipment_number}
                    </span>
                  </div>
                  <div className={classes.pictureWrapper}>
                    <div style={{ display: "contents" }}>
                      <ButtonBase
                        className={classes.imageContainer}
                        onClick={() => handleImageClick()}
                      >
                        <img
                          className={classes.img}
                          alt="shipment"
                          src={shipmentInfo.cover_image}
                        />
                      </ButtonBase>
                      <span style={{ textAlign: "center", fontWeight: "650" }}>
                        {shipmentInfo.item_name}
                      </span>
                    </div>
                  </div>
                  <div
                    className={[classes.shipmentInfo, classes.blocks].join(" ")}
                  >
                    <div style={{ whiteSpace: "nowrap" }}>
                      <span style={general_info_label}>Length: </span>
                      <span>
                        {shipmentInfo.length}
                        {
                          shipmentTypesDetails[shipmentInfo.shipment_type_id]
                            .sizeUnit
                        }
                      </span>
                    </div>
                    <div style={{ whiteSpace: "nowrap" }}>
                      <span style={general_info_label}>Width: </span>
                      <span>
                        {shipmentInfo.width}
                        {
                          shipmentTypesDetails[shipmentInfo.shipment_type_id]
                            .sizeUnit
                        }
                      </span>
                    </div>
                    <div style={{ whiteSpace: "nowrap" }}>
                      <span style={general_info_label}>Height: </span>
                      <span>
                        {shipmentInfo.height}
                        {
                          shipmentTypesDetails[shipmentInfo.shipment_type_id]
                            .sizeUnit
                        }
                      </span>
                    </div>
                    <div>
                      <span style={general_info_label}>Weight: </span>
                      <span>
                        {shipmentInfo.weight}
                        {
                          shipmentTypesDetails[shipmentInfo.shipment_type_id]
                            .weightUnit
                        }
                      </span>
                    </div>
                    <span style={{ textDecoration: "underline" }}>
                      {
                        shipmentTypesDetails[shipmentInfo.shipment_type_id]
                          .label
                      }
                      {"(" +
                        shipmentTypesDetails[shipmentInfo.shipment_type_id]
                          .type_name +
                        ")"}{" "}
                      x {shipmentInfo.tracking_numbers.length}
                    </span>
                  </div>
                  <div
                    className={[classes.shippingInfo, classes.blocks].join(" ")}
                  >
                    <div>
                      <h6 style={{ padding: "2px" }}>
                        <strong>Pick-up Address:</strong>{" "}
                        {cardActive
                          ? shipmentInfo.pickup_address
                          : shipmentInfo.pickup_city}
                      </h6>
                      <h6 style={{ padding: "2px" }}>
                        <strong>Delivery Address:</strong>{" "}
                        {cardActive
                          ? shipmentInfo.deliver_address
                          : shipmentInfo.deliver_city}
                      </h6>
                      <h6 style={{ padding: "2px" }}>
                        <strong>Pickup date:</strong>{" "}
                        {getPickupDate(shipmentInfo.schedule_pickup)}
                      </h6>
                      {shipmentInfo.option_list && (
                        <ShipmentOptionsLayout
                          optionList={shipmentInfo.option_list}
                        />
                      )}
                    </div>
                  </div>
                  <div className={classes.trackingNumbersWrapper}>
                    <Typography variant="body1">
                      <b>Tracking Number(s):</b>
                    </Typography>
                    <div className={classes.trackingNumberList}>
                      {/*{shipmentInfo?.tracking_numbers?.length > 0 &&*/}
                      {/*  shipmentInfo.tracking_numbers.map((t, index) => {*/}
                      {/*    return <li key={index}>{t.tracking_number}</li>;*/}
                      {/*  })}*/}
                      <h5>{shipmentInfo.tracking_numbers}</h5>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Paper>
          <Grid
            container
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "8px",
            }}
          >
            <Grid item>
              <Box mr={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handlePickupShipment}
                >
                  Pickup shipment
                </Button>
              </Box>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => handleCancelClaimClicked()}
              >
                Cancel Claim
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <LoadingCircle />
      )}
      {
        <>
          <Backdrop
            className={classes.backDrop}
            open={openBackDrop}
            onClick={() => handleClickBackDrop()}
          ></Backdrop>
          <Dialog
            open={openDialog}
            onClose={() => handleDialogOnClose()}
            fullWidth
          >
            <DialogTitle>{"Unclaim shipment"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Unclaim shipment with shipment number below?
                <div>
                  <b>
                    <i>{`${id}`}</i>
                  </b>
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleRequestUnclaim} variant="contained">
                Yes
              </Button>
              <Button
                onClick={handleCancel}
                variant="contained"
                color="primary"
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openConfirmDialog}
            onClose={() => handleErrorDialogOnClose()}
            fullWidth
          >
            <DialogTitle>{"Attention"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <div>
                  <b>
                    <i>{`${id}`}</i>
                  </b>
                </div>
                <div>
                  <Typography>{errorMessage}</Typography>
                </div>
                {fineAmount ? (
                  <>
                    <Typography>
                      <b>
                        To unclaim this shipment you will be change a fine of $
                        {fineAmount}.
                      </b>
                    </Typography>
                  </>
                ) : (
                  ""
                )}
                <Typography>
                  <b>Continue unclaim this shipment?</b>
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirmUnclaim} variant="contained">
                Confirm
              </Button>
              <Button
                onClick={handleCancel}
                variant="contained"
                color="primary"
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openSuccessDialog}
            onClose={() => handleSuccessDialogOnClose()}
            fullWidth
          >
            <DialogTitle>{"Message"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Typography>{feedbackMessage}</Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={handleSuccessConfirm}>
                Okay
              </Button>
            </DialogActions>
          </Dialog>
        </>
      }
    </>
  );
};

export default ClaimedShipmentDetailPage;
