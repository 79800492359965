import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@mui/material/Grid";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {updateMovingServiceSummaryContactInfo} from "../../actions/movingServiceAction";
import {isValidateEmail} from "../../Utils/Helper";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import intl from "react-intl-universal";
import CurrencyFormat from 'react-currency-format';
import {Divider} from "@material-ui/core";
import {ReactComponent as ContactIcon} from "../../images/NewDesignIcon/AddressBook.svg";

const styles = {
    MovingServiceContactRoot: {
        backgroundColor: '#FFFFFF',
        // padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '10px',
        display: 'flex',
        flexDirection: 'column',
    },
    MovingServiceContactSection: {
        backgroundColor: '#FFFFFF',
        paddingBottom: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0'
    },
    MovingServiceContactHeading: {
        fontSize: '20px',
        fontWeight: '600'
    },
    MovingServiceContactErrorText: {
        fontSize: '12px',
        color: "#FF0303"
    }
}

export const MovingServiceContact = ({contactValidate}) => {

    let userInfo = useSelector(state => state?.user);

    let contactInfoReducer = useSelector(state => state?.movingService?.movingServiceSummaryContactInfo);

    let contactInfoMissing = useSelector(state => state?.movingService?.movingServiceSummaryContactInfoMissing);

    const [contactInfo, setContactInfo] = useState({
        pickupName: '',
        pickupPhone: '',
        pickupEmail: '',
        deliverName: '',
        deliverPhone: '',
        deliverEmail: ''
    });

    const dispatch = useDispatch();

    useEffect(() => {

        setContactInfo(prevState => ({
            ...prevState,
            pickupName: contactInfoReducer?.pickupName || userInfo?.legalName,
            pickupPhone: contactInfoReducer?.pickupPhone || userInfo?.phone?.replace(/\D/g, ""),
            pickupEmail: contactInfoReducer?.pickupEmail || userInfo?.email,
            deliverName: contactInfoReducer?.deliverName || userInfo?.legalName,
            deliverPhone: contactInfoReducer?.deliverPhone || userInfo?.phone?.replace(/\D/g, ""),
            deliverEmail: contactInfoReducer?.deliverEmail || userInfo?.email
        }))
    }, []);

    const handlePickupName = event => {
        setContactInfo(prevState => ({
            ...prevState,
            pickupName: event.target.value
        }))
    };

    const handlePickupPhone = event => {
        setContactInfo(prevState => ({
            ...prevState,
            pickupPhone: event.target.value
        }))
    };

    const handlePickupEmail = event => {
        setContactInfo(prevState => ({
            ...prevState,
            pickupEmail: event.target.value
        }))
    };

    const handleDeliverName = event => {
        setContactInfo(prevState => ({
            ...prevState,
            deliverName: event.target.value
        }))
    };

    const handleDeliverPhone = event => {
        setContactInfo(prevState => ({
            ...prevState,
            deliverPhone: event.target.value
        }))
    };

    const handleDeliverEmail = event => {
        setContactInfo(prevState => ({
            ...prevState,
            deliverEmail: event.target.value
        }))
    };

    useEffect(() => {
        dispatch(updateMovingServiceSummaryContactInfo(contactInfo));
    }, [contactInfo])

    // console.log(userInfo);

    console.log('[MovingServiceContact] contactValidate', contactValidate)
    console.log('[MovingServiceContact] contactInfo', contactInfo)

    return (
        <Box sx={styles.MovingServiceContactRoot}>
            {/*<Typography sx={styles.MovingServiceContactHeading}>*/}
            {/*    {intl.get('CONTACT.CONTACT_INFORMATION')}*/}
            {/*</Typography>*/}
            <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                    <Box sx={styles.MovingServiceContactSection}>
                        <Box sx={{
                            display: 'flex',
                            gap: '10px',
                            padding: '20px 20px 0 20px'
                        }}>
                            <ContactIcon width={25} height={25}/>
                            <Typography sx={{fontWeight: '600'}}>
                                {intl.get('CONTACT.PICKUP')}
                            </Typography>
                        </Box>
                        <Divider/>
                        <Box sx={{
                            backgroundColor: '#FFFFFF',
                            padding: '0 20px',
                            gap: '40px',
                            display: 'flex',
                        }}>
                            <Grid container spacing={2}>
                                <Grid item md={6} xs={12}>
                                    <FormControl fullWidth>
                                        <FormLabel required>
                                            {intl.get('CONTACT.NAME')}
                                        </FormLabel>
                                        <TextField
                                            value={contactInfo?.pickupName}
                                            onInput={handlePickupName}
                                            fullWidth
                                            variant="outlined"
                                            size='small'
                                            error={contactInfoMissing?.includes('pickupName')}
                                            helperText={contactInfoMissing?.includes('pickupName') && 'Required'}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <FormControl fullWidth>
                                        <FormLabel required>
                                            {intl.get('CONTACT.PHONE')}
                                        </FormLabel>
                                        <CurrencyFormat
                                            value={contactInfo?.pickupPhone}
                                            customInput={TextField}
                                            onInput={handlePickupPhone}
                                            format="+1 (###) ###-####"
                                            mask="_"
                                            fullWidth
                                            variant="outlined"
                                            size='small'
                                            error={contactInfoMissing?.includes('pickupPhone') || contactValidate?.pickupPhoneError}
                                            helperText={contactInfoMissing?.includes('pickupPhone') ? 'Required' : contactValidate?.pickupPhoneError && 'Invalid'}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <FormControl fullWidth>
                                        <FormLabel required>
                                            {intl.get('CONTACT.EMAIL')}
                                        </FormLabel>
                                        <TextField
                                            value={contactInfo?.pickupEmail}
                                            onInput={handlePickupEmail}
                                            fullWidth
                                            variant="outlined"
                                            size='small'
                                            error={contactInfoMissing?.includes('pickupEmail') || contactValidate?.pickupEmailError}
                                            helperText={contactInfoMissing?.includes('pickupEmail') ? 'Required' : contactValidate?.pickupEmailError && 'Invalid'}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Box sx={styles.MovingServiceContactSection}>
                        <Box sx={{
                            display: 'flex',
                            gap: '10px',
                            padding: '20px 20px 0 20px'
                        }}>
                            <ContactIcon width={25} height={25}/>
                            <Typography sx={{fontWeight: '600'}}>
                                {intl.get('CONTACT.DELIVER')}
                            </Typography>
                        </Box>
                        <Divider/>
                        <Box sx={{
                            backgroundColor: '#FFFFFF',
                            padding: '0 20px',
                            gap: '40px',
                            display: 'flex',
                        }}>
                            <Grid container spacing={2}>
                                <Grid item md={6} xs={12}>
                                    <Box>
                                        <FormLabel required>
                                            {intl.get('CONTACT.NAME')}
                                        </FormLabel>
                                        <TextField
                                            value={contactInfo?.deliverName}
                                            onInput={handleDeliverName}
                                            fullWidth
                                            variant="outlined"
                                            size='small'
                                            error={contactInfoMissing?.includes('deliverName')}
                                            helperText={contactInfoMissing?.includes('deliverName') && 'Required'}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Box>
                                        <FormLabel required>
                                            {intl.get('CONTACT.PHONE')}
                                        </FormLabel>
                                        <CurrencyFormat
                                            value={contactInfo?.deliverPhone}
                                            customInput={TextField}
                                            onInput={handleDeliverPhone}
                                            format="+1 (###) ###-####"
                                            mask="_"
                                            fullWidth
                                            variant="outlined"
                                            size='small'
                                            error={contactInfoMissing?.includes('deliverPhone') || contactValidate?.deliverPhoneError}
                                            helperText={contactInfoMissing?.includes('deliverPhone') ? 'Required' : contactValidate?.deliverPhoneError && 'Invalid'}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <Box>
                                        <FormLabel required>
                                            {intl.get('CONTACT.EMAIL')}
                                        </FormLabel>
                                        <TextField
                                            value={contactInfo?.deliverEmail}
                                            onInput={handleDeliverEmail}
                                            fullWidth
                                            variant="outlined"
                                            size='small'
                                            error={contactInfoMissing?.includes('pickupEmail') || contactValidate?.pickupEmailError}
                                            helperText={contactInfoMissing?.includes('pickupEmail') ? 'Required' : contactValidate?.pickupEmailError && 'Invalid'}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}