import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Divider, MenuItem, Select, Stack, Switch} from "@mui/material";
import {styled} from "@mui/material/styles";
import {ReactComponent as CrossBorderIcon} from "../../../images/NewDesignIcon/CustomsDeclaration.svg";
import intl from "react-intl-universal";
import IconButton from "@mui/material/IconButton";
import addPackage from "../../../images/Create Label.png";
import Grid from "@material-ui/core/Grid";
import * as React from "react";
import {useEffect, useState} from "react";
import {generateRandomString} from "../../../Utils/Helper";
import {
    addShippingLTLCrossBorderItemList, clearShippingLTLCrossBorderItemList,
    initShippingLTLCreateLabelPalletList, initShippingLTLCrossBorderItemList
} from "../../../actions/shippingLTLAction";
import {useDispatch, useSelector} from "react-redux";
import {LTLCrossBorderDashboardItemListEach} from "./LTLCrossBorderDashboardItemListEach";
import {ReactComponent as SuccessSubmitLogo} from "../../../images/crossborderIcon/SuccessSubmit.svg";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Button from "@mui/material/Button";
import {LTLCrossBorderDashboardPackingForm} from "./LTLCrossBorderDashboardPackingForm";

const styles = {
    ShippingPackageCrossBorderRoot: {
        backgroundColor: '#FFFFFF',
        // padding: '20px',
        // marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        // margin: '20px 0'
    },

    ShippingPackageCrossBorderHeading: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'left'
    },

    ShippingPackageCrossBorderSubHeading: {
        fontSize: '16px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelPackageSection: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '20px 20px 0 20px',
        alignItems: 'center'
    },
    ShippingPackageCreateLabelPackageSubSection: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    ShippingPackageCreateLabelRateListContent: {
        backgroundColor: '#FFFFFF',
        padding: '0 40px',
        marginBottom: '40px',
        borderRadius: '10px',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
}

const AntSwitch = styled(Switch)(({theme}) => ({
    width: 32,
    height: 20,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 20,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#609966' : '#609966',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 16,
        height: 16,
        borderRadius: 8,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 20 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

export const LTLCrossBorderDashboardItemList = ({
                                                    orderData,
                                                    isCreate,
                                                    closeCreatePacking,
                                                    getOrderData
                                                }) => {

    if (isCreate) {
        return (
            <LTLCrossBorderDashboardPackingForm
                closeCreatePacking={closeCreatePacking}
                orderData={orderData}
                getOrderData={getOrderData}
            />
        )
    }

    if (orderData?.packingList?.length > 0 || orderData?.packingListFiles?.length > 0) {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        minHeight: '200px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        flexDirection: 'column'
                    }}>
                        <CheckCircleOutlineIcon sx={{
                            fontSize: "100px",
                            color: '#1D8B45'
                        }}/>
                        <Typography>
                            Packing List Submitted Successfully
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
            </Grid>
        )
    }

    if (orderData?.packingList?.length === 0 && orderData?.packingListFiles?.length === 0) {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        minHeight: '200px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        flexDirection: 'column'
                    }}>
                        <img src={addPackage} width='100px'/>
                        <Typography>
                            Your packing list is empty now
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
            </Grid>
        )
    }

    return (
        <>
        </>
    )
}