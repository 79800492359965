import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {useEffect, useState} from "react";
import {getAccessToken} from "../../Utils/doToken";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {OrderDashBoardTransactionsEach} from "./OrderDashBoardTransactionsEach";
import {OrderDashBoardTransactionsTrackingEach} from "./OrderDashBoardTransactionsTrackingEach";

const headCells = [
    {
        id: "name",
        numeric: false,
        // disablePadding: true,
        label: "Name",
        width: '20%'
    },
    {
        id: "trackingNumber",
        numeric: false,
        // disablePadding: true,
        label: "Tracking #",
        width: '30%'
    },
    {
        id: "dimension",
        numeric: false,
        // disablePadding: false,
        label: "Dimension (LxWxH)",
        width: '10%'
    },
    {
        id: "weight",
        numeric: false,
        // disablePadding: false,
        label: "Weight",
        width: '10%'
    },
    {
        id: "other",
        numeric: false,
        // disablePadding: false,
        label: "HS Code / Price Each",
        width: '15%'
    },
    {
        id: "status",
        numeric: false,
        // disablePadding: false,
        label: "Status",
        width: '15%'
    },
];

export const OrderDashBoardTransactionsTracking = ({transactionsEach}) => {

    const accessToken = getAccessToken("access_token");

    const [trackingList, setTrackingList] = useState();

    const getValidTrackingListByTranId = async () => {
        let requestURL = `${NODE_ROUTE_URI}/shippingOrderDetailsDashboard/endUser/getValidTrackingListByTranId/${transactionsEach?.tran_id}`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            console.log('api tracking list', result.data)
            setTrackingList(result.data)

        } catch (e) {
            console.log(e.response)
        }
    }

    useEffect(() => {
        getValidTrackingListByTranId();
    },[])

    return (
        <Box>
            <Typography variant='h6'>
                Tracking
            </Typography>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => (
                                <TableCell
                                    key={headCell.id}
                                    align={headCell.numeric ? "right" : "left"}
                                    padding={headCell.disablePadding ? "none" : "normal"}
                                    style={{fontWeight: "bold", width: headCell.width}}
                                >
                                    {headCell.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            trackingList?.map((trackingEach, index) => <OrderDashBoardTransactionsTrackingEach trackingEach={trackingEach} key={index}/>)
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>

    )
}