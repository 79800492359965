import {useParams} from 'react-router-dom';
import {useEffect} from "react";
import {useHistory} from "react-router";
import {useDispatch} from "react-redux";
import {sendReferralCode} from "../../actions/referralPageAction";
import {deleteToken} from "../../Utils/doToken";

export const LandingReferralCodePage = () => {

    const {referralCode} = useParams();

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(sendReferralCode(referralCode));
        deleteToken('access_token');
        history.push('/sign-up');
    },[])


    return (
        <div>
            {referralCode}
        </div>
    )
}