import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from "@mui/material/AccordionDetails";
import {mapServiceLogo, serviceLogo} from "../../Utils/Helper";
import {Button, IconButton} from "@mui/material";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";

const style = {
    RatingEstimateEachRoot: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '40px'
    },
    RatingEstimateEachGrid: {
        width: '100%',
        marginBottom: '20px'
    },
    RatingEstimateEachSummary: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%'
    },
    RatingEstimateEachDetails: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    },
    RatingEstimateEachImage: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    RatingEstimateEachError: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    }
}

export const RatingEstimateEach = ({estimateEach}) => {

    let history = useHistory();

    return (
        <>
            <Box sx={style.RatingEstimateEachRoot}>
                <Grid container
                      sx={style.RatingEstimateEachGrid}
                      spacing={2}
                >
                    <Grid item xs={2}>
                        <Box sx={style.RatingEstimateEachImage}
                             onClick={() => {
                                 !!estimateEach?.crossBroaderFee?.error ? history.push('/rating') :
                                     history.push('/ship/summary', {data: estimateEach})
                             }}>
                            <img src={mapServiceLogo(estimateEach?.serviceName)} width="100px"/>
                            <Button
                                disabled={estimateEach?.crossBroaderFee?.error}
                            >
                                {estimateEach?.serviceName}
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography style={{fontSize: '20px', fontWeight: '600', textAlign: 'right'}}>
                            $ {estimateEach?.totalCharge?.value}
                            <Accordion sx={{width: 1}}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                                  sx={{width: 1}}>
                                    <Box sx={style.RatingEstimateEachSummary}>
                                        <Typography>
                                            Price Details
                                        </Typography>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {
                                        estimateEach?.shipmentCharges?.map((element, index) => <Box
                                            sx={style.RatingEstimateEachDetails} key={index}>
                                            <Typography style={{fontWeight: '600'}}>
                                                {element.name}:
                                            </Typography>
                                            <Typography>
                                                {element.value}
                                            </Typography>
                                        </Box>)
                                    }
                                    <Box sx={style.RatingEstimateEachDetails}>
                                        <Typography style={{fontWeight: '600'}}>
                                            Cross Border Fee (if applicable):
                                        </Typography>
                                        <Typography>
                                            {estimateEach?.isCrossBoarder ? "" : "N/A"}
                                        </Typography>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography sx={{textAlign: 'center'}}>
                            {estimateEach.expectedDate}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>
                            {estimateEach?.errorMessage}
                        </Typography>
                        <Typography style={{textAlign: 'left', color: 'red', fontSize: '14px'}}>
                            {estimateEach?.crossBroaderFee?.error}
                        </Typography>
                        {estimateEach?.crossBroaderFee?.error &&
                            <Button onClick={() => history.push('/rating')}>
                                Go Back Edit
                            < /Button>
                        }
                    </Grid>
                </Grid>
                <Divider sx={{width: '100%'}}/>
            </Box>
        </>
    )
}