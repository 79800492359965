import React, { useState, useEffect } from "react";
// import QuoteSummary from "../Shipping/quoteSummary";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  // TextField,
  // Snackbar,
  Dialog,
  // DialogTitle,
  // CircularProgress,
  Container,
  Box,
  IconButton,
} from "@material-ui/core";
// import orange from '@material-ui/core/colors/orange';
// import { Alert } from "@material-ui/lab";

// import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// import EventIcon from "@material-ui/icons/Event";

// import OrderDetailsHeader from "../shared/OrderDetail/orderDetailsHeader";
import ShipmentCheckDetails from "./Components/shipmentCheckDetails";
import ShipmentCheckDetailsTransaction from "./Components/shipmentCheckDetailsTransaction";

import "../../styles/myOrders.css";
// import TabShippingItem from "../Shipping/TabbedShippingComponents/tabShippingItem";
// import TabShippingDetails from "../Shipping/TabbedShippingComponents/tabShippingDetails";

// // import UpdateOrderTabs from "../shared/Tabs/UpdateOrderTabs";
// import PackageNoTabs from "../shared/Tabs/PackageNoTabs";
// // import { getOrderShipments } from "../../actions/orderShipmentAction";
// // import { shipmentTypesDetails } from "../shared/constInfo";
// import LoadingDock from "../Images/icons/LoadingDock.png";
// import NoLoadingDock from "../Images/icons/NoLoadingDock.png";
// // import InsuranceIcon from "../Images/icons/insuranceIcon";
// import AccessAlarmsIcon from "@material-ui/icons/AccessAlarms";
// import { errHandling, getURLParams, getHeaders } from "../../Utils/fetchUtil";
// import { CustomInput } from "../shared";

// import axios from "axios";

// import {
//   BACKEND_ORDERS_BY_ORDERNUMBER_API,
//   BACKEND_ORDER_PRICE_API,
//   BACKEND_ORDER_CANCEL_API,
//   BACKEND_GUEST_ORDER_BY_ID_API,
//   BACKEND_GUEST_ORDER_PRICE_API,
//   BACKEND_GUEST_ORDER_CANCEL_API,
//   ADD_SHIPMENTS_TIPS,
// } from "../../Utils/apiUrl";
// import LoadingContainer from "../shared/loadingContainer";
// import { redirectOrderList } from "../Utils/redirect";
// import PriceModule from "../shared/OrderDetail/priceModule";

// A custom hook that builds on useLocation to parse
// the query string for you.
// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }

const ShipmentDetails = ({ transaction = false }) => {
  // const matches = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const matches = useMediaQuery("(max-width:600px)");
  // const history = useHistory();
  // const location = useLocation();
  // const query = useQuery();
  const { id } = useParams();

  // const [packageList, setPackageList] = useState([]);
  // const [shipmentList, setShipmentList] = useState([]);

  // const [isWindowOpen, setWindowOpenState] = useState(false);
  // const [isGuestCancelWindowOpen, setGuestCancelWindowOpen] = useState(false);
  // const [isCancelWindowOpen, setCancelWindowOpenState] = useState(false);
  // const [isSignInRequestWindowOpen, setSignInRequestWindowOpenState] =
  //   useState(false);
  // const [isAllClaimedWindowOpen, setAllClaimedWindowOpenState] =
  //   useState(false);
  // const [fetchingPrice, setFetchingPriceState] = useState(true);
  // const [fetchingOrder, setFetchingOrder] = useState(true);
  // const [isCancelling, setCancellingStatus] = useState(false);

  // const [priceErrMsg, setPriceErrMsg] = useState("");

  // const [fetchingPackages, setFetchingPackagesState] = useState(true);

  // const [message, setMessage] = useState("");
  // const [openSnackBar, setOpenSnackBar] = useState(false);
  // const [saveMsgErr, setSaveMsgErr] = useState("");

  // const [openTipsSnackBar, setOpenTipsSnackBar] = useState(false);
  // const [tipsMsgErr, setTipsMsgErr] = useState("");

  // const [shippingPrice, setShippingPrice] = useState({});
  // const [addon, setAddon] = useState({});
  // const [cancelErrMsg, setCancelErrMsg] = useState("");
  // const [orderErrMsg, setOrderErrMsg] = useState("");
  // const [guestCancelErrMsg, setGuestCancelErrMsg] = useState("");
  // const [cancelStatus, setCancelStatus] = useState(false);
  // const [refetch, setRefetch] = useState(true);
  // const [refetchPrice, setRefetchPrice] = useState(true);
  // // const [pkgRefetch, setPkgRefetch] = useState(false);
  // const [accessCode, setAccessCode] = useState("");

  // const [fullAccess, setFullAccess] = useState(true);

  // const [addTips, setAddTipsStatus] = useState(false);
  // const [submittingTips, setSubmittingTipsStatus] = useState(false);
  // const [tips, setTips] = useState(0);
  // const [newTips, setNewTips] = useState(0);
  // const [tipsNumberError, setTipsNumberError] = useState(false);

  // const { id: order_num } = useParams();
  // const [orderInfo, setOrderInfo] = useState({});

  const classes = useStyles();

  return (
    <Container>
      <div className={classes.orderContainer}>
        <Box
          className={
            matches ? classes.smRootContainer : classes.largeRootContainer
          }
        >
          <br />
          <Box className={classes.summaryHeader} mb={2}>
            {transaction ? `CCN# ${id} Transactions` : "Cargo control Tracking"}
          </Box>
          {transaction ? (
            <ShipmentCheckDetailsTransaction />
          ) : (
            <ShipmentCheckDetails />
          )}
        </Box>
      </div>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  largeRootContainer: {
    margin: "0 auto",
    // width: '40vw',
    width: "800px",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  rootContainer: {
    margin: "0 auto",
    // width: '40vw',
    width: "600px",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  smRootContainer: {
    width: "100%",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  button: {
    fontSize: "12pt",
    backgroundColor: "#ffa726",
    margin: "7px",
  },
  fixWidthButton: {
    width: "8rem",
  },
  outLinedButton: {
    fontSize: "12pt",
    margin: "7px",
    marginRight: "24px",
  },
  textfield: {
    width: "100%",
  },
  calendar: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "70%",
  },
  backDrop: {
    timeout: 500,
    backdropFilter: "blur(3px)",
    backgroundColor: "rgba(0,0,30,0.4)",
  },
  getCodeButton: {
    display: "flex",
    justifyContent: "center",
  },
  dialogTitle: {
    padding: "1vh 1vw 0.5vh",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "rgb(141, 173, 155)",
  },
  orderContainer: {
    backgroundColor: "rgba(255, 255, 255, 0.788)",
    borderRadius: "5px",
  },
  feeWrapper: {
    // border: "1px solid rgba(0,0,0,0.3)",
    borderRadius: 5,
    marginBottom: theme.spacing(2),
    backgroundColor: "#FEEAAE",
  },
  inputBorder: {
    border: "1px solid black",
    borderRadius: "5px",
  },
  alertRoot: {
    width: "80%",
    minWidth: "200px",
    margin: "10px auto",
  },
  summaryHeader: {
    fontSize: "1rem",
    fontWeight: "600",
    color: "seagreen",
    fontFamily: "Montserrat",
    // borderBottom: '1px, solid rgb(141, 173, 155)',
  },
  sessionHeader: {
    fontSize: "1rem",
    fontWeight: "600",
    fontFamily: "Montserrat",
    // borderBottom: '1px, solid rgb(141, 173, 155)',
  },

  summaryBodyText: {
    fontSize: "13pt",
    color: "rgb(141, 173, 155)",
    fontFamily: "Montserrat",
  },
  priceBodyText: {
    fontSize: "1rem",
    // color: "seagreen",
    fontFamily: "Montserrat",
  },
}));

ShipmentDetails.propTypes = {};

export default ShipmentDetails;
