import {Button, Container} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {useState} from "react";
import {makeStyles} from "@material-ui/core";
import LoadingButton from "@mui/lab/LoadingButton";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../Utils/doToken";
import {TrackingItem} from "./TrackingItem";


export const Tracking = () => {

    const storedToken = getAccessToken("access_token");

    const [trackingNumbers, setTrackingNumbers] = useState("");
    const [loading, setLoading] = useState(false);
    const [trackedPackagesList, setTrackedPackagesList] = useState([]);
    const [isTrackEmpty, setIsTrackEmpty] = useState(false);


    const getTrackingPackageList = async () => {
        setLoading(true);
        try {
            const requestURL = `${NODE_ROUTE_URI}/looseItem/multipleTrackingForWeb`;
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`
                },
                params: {
                    trackingNumbers: trackingNumbers
                }
            })
            console.log('tracking result', result);
            const trackingData = result?.data;
            const trackingList = trackingData?.filter(v => v?.trackingResponse !== null);
            setIsTrackEmpty(trackingList?.length === 0);
            setTrackedPackagesList(trackingList);

        } catch (e) {
            console.log('error', e.response);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Container maxWidth='xl'>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px'
            }}>
                <Typography style={{
                    fontSize: '20px',
                    fontWeight: '600',
                }}>
                    Tracking
                </Typography>
                <Typography>Enter up to 25 tracking numbers, one per line.</Typography>
                <TextField
                    sx={{
                        width: "100%",
                        mb: 1,
                    }}
                    multiline
                    value={trackingNumbers}
                    onChange={v => {
                        setTrackingNumbers(v.target.value);
                    }}
                    rows={6}
                    placeholder="Enter your comma seperated tracking numbers here"
                />
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}>
                    <LoadingButton
                        variant='contained'
                        sx={{
                            backgroundColor: '#1D8B45',
                            color: '#FFFFFF',
                            height: '100%',
                            borderRadius: '5px',
                            padding: '5px 25px',
                            "&:hover": {
                                backgroundColor: '#1D8B45',
                                color: '#FFFFFF',
                                filter: 'brightness(0.9)'
                            },
                        }}
                        disabled={!trackingNumbers}
                        loading={loading}
                        onClick={getTrackingPackageList}
                    >
                        <Typography style={{textTransform: 'none'}}>
                            Track
                        </Typography>
                    </LoadingButton>
                </Box>
                {
                    isTrackEmpty ? <Box>
                        Tracking Results Not Found
                    </Box> : trackedPackagesList?.map((trackedPackage, index) => {
                        return (
                            <TrackingItem
                                key={index}
                                activity={trackedPackage.trackingResponse ? trackedPackage.trackingResponse.activity : undefined}
                                trackingNo={trackedPackage.trackingNumber}
                                courier={trackedPackage.accountBase}
                                deliverStatus={trackedPackage.trackingResponse.status}
                                transaction={trackedPackage.transaction}
                            />
                        )
                    })
                }
            </Box>
        </Container>
    )
}
