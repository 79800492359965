import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Visa from "../../images/cards/Visa.png";
import Mastercard from "../../images/cards/Mastercard.png";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import {StripeInput} from "./stripeInput";
import {CardCvcElement, CardExpiryElement, CardNumberElement} from "@stripe/react-stripe-js";

export const WelcomeCreditCardSection = ({isAddCard}) => {
    return (
        <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
                <Typography style={{fontSize: '14px', color: '#1D8B45', fontWeight: '600', textAlign: 'left'}}>
                    Credit Card
                </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
                <Box sx={{
                    display: 'flex',
                    gap: '5px',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                }}>
                    <Box sx={{borderRadius: '5px', backgroundColor: '#F79327', padding: '5px 10px'}}>
                        <Typography style={{fontSize: '10px', fontWeight: '600'}}>
                            Powered by Stripe
                        </Typography>
                    </Box>
                    <img src={Visa} alt="VisaLogo" height={20}/>
                    <img src={Mastercard} alt="MasterCardLogo" height={20}/>
                </Box>
            </Grid>
            <Grid item md={6} xs={12}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <InputLabel>
                        <Typography style={{fontSize: '14px', fontWeight: '600'}}>
                            Card Number
                        </Typography>
                    </InputLabel>
                    <TextField
                        name="ccnumber"
                        variant="outlined"
                        required
                        size='small'
                        fullWidth
                        disabled={isAddCard}
                        InputLabelProps={{shrink: true}}
                        InputProps={{
                            inputComponent: StripeInput,
                            inputProps: {
                                component: CardNumberElement
                            },
                        }}
                    />
                </Box>
            </Grid>
            <Grid item md={4} xs={8}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <InputLabel>
                        <Typography style={{fontSize: '14px', fontWeight: '600'}}>
                            Expiration Date
                        </Typography>
                    </InputLabel>
                    <TextField
                        name="ccexp"
                        variant="outlined"
                        required
                        fullWidth
                        size='small'
                        disabled={isAddCard}
                        InputLabelProps={{shrink: true}}
                        InputProps={{
                            inputComponent: StripeInput,
                            inputProps: {
                                component: CardExpiryElement
                            },
                        }}
                    />
                </Box>
            </Grid>
            <Grid item md={2} xs={4}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <InputLabel>
                        <Typography style={{fontSize: '14px', fontWeight: '600'}}>
                            CVV
                        </Typography>
                    </InputLabel>
                    <TextField
                        name="cvc"
                        variant="outlined"
                        required
                        fullWidth
                        size='small'
                        disabled={isAddCard}
                        InputLabelProps={{shrink: true}}
                        InputProps={{
                            inputComponent: StripeInput,
                            inputProps: {
                                component: CardCvcElement
                            },
                        }}
                    />
                </Box>
            </Grid>
        </Grid>
    )
}