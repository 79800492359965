import React from "react";
import {Box, Button} from "@material-ui/core";

import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";

import {ordinal_suffix_of} from "../../Utils/addOrdinalSuffix";
import {shipmentTypesDetails} from "../shared";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

const findUnit = (labelId, fieldName) => {
    const itemUnit = shipmentTypesDetails[labelId];
    return itemUnit ? itemUnit[fieldName] : "";
};

const ReviewOrderHeader = ({
                               orderNumber,
                               pickupName,
                               pickupPhone,
                               pickupAddress,
                               pickupAddressTwo,
                               pickupCity,
                               pickupProvince,
                               pickupPostalCode,
                               deliverName,
                               deliverPhone,
                               deliverAddress,
                               deliverAddressTwo,
                               deliverCity,
                               deliverProvince,
                               deliverPostalCode,
                               pallets,
                           }) => {
    const classes = useStyles();
    console.log('pallets', pallets)
    return (
        <Box>
            <Box className={classes.summaryHeader}>Order # {orderNumber}</Box>
            <Box className={classes.summaryAddress}>
                <Box className={classes.summaryAddressContainer}>
                    <Box display="flex" justifyContent="space-between">
                        <Box className={classes.summaryHeader}>Pickup</Box>
                    </Box>
                    <Grid container spacing={1} className={classes.summaryBodyText} mb={2}>
                        <Grid item xs={3} className={classes.summaryAddressSection}>
                            Name:
                        </Grid>
                        <Grid item xs={9}>
                            {pickupName}
                        </Grid>
                        <Grid item xs={3} className={classes.summaryAddressSection}>
                            Phone:
                        </Grid>
                        <Grid item xs={9}>
                            {pickupPhone}
                        </Grid>
                        <Grid item xs={3} className={classes.summaryAddressSection}>
                            Address:
                        </Grid>
                        <Grid item xs={9}>
                            {`${pickupAddress}`}, {pickupAddressTwo} <br/>
                            {`${pickupCity}, ${pickupProvince} ${pickupPostalCode}`}
                        </Grid>
                    </Grid>
                </Box>
                <Box className={classes.summaryAddressContainer}>
                    <Box display="flex" justifyContent="space-between">
                        <Box className={classes.summaryHeader}>Delivery</Box>
                    </Box>
                    <Grid container spacing={1} className={classes.summaryBodyText} mb={2}>
                        <Grid item xs={3} className={classes.summaryAddressSection}>
                            Name:
                        </Grid>
                        <Grid item xs={9}>
                            {deliverName}
                        </Grid>
                        <Grid item xs={3} className={classes.summaryAddressSection}>
                            Phone:
                        </Grid>
                        <Grid item xs={9}>
                            {deliverPhone}
                        </Grid>
                        <Grid item xs={3} className={classes.summaryAddressSection}>
                            Address:
                        </Grid>
                        <Grid item xs={9}>
                            {`${deliverAddress}`}, {deliverAddressTwo} <br/>
                            {`${deliverCity}, ${deliverProvince} ${deliverPostalCode}`}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box className={classes.summaryItems}>
                <Box display="flex" justifyContent="space-between">
                    <Box className={classes.summaryHeader}>Items</Box>
                </Box>
                <Box className={classes.summaryBodyText} mb={1}>
                    {pallets.map((item, key) => {
                        return (
                            <Box key={key} className={classes.summaryItemEachRoot}>
                                <Grid container spacing={2} className={classes.summaryItemEachItem}>
                                    <Grid item xs={9} >
                                        <Box className={classes.summaryItemEachItemDetail}>
                                            <Typography style={{wordWrap: 'break-word', whiteSpace: 'pre-line', fontSize: '25px', fontWeight: '600'}}>
                                                {item.description}
                                            </Typography>
                                            <Box>
                                                <Typography sx={{fontSize: '15px'}}>
                                                    Weight: {item.weight} {item.weightUnit}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography>
                                                    Dimension (L x W x
                                                    H): {item.length || 0} x {item.length || 0} x {item.length || 0} {item.lengthUnit}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        {/*{itemDetail.itemId}*/}
                                    </Grid>
                                    <Grid item xs={3} className={classes.summaryItemEachItemQuantity}>
                                        <Typography style={{fontSize: '25px', fontWeight: '600'}}>
                                            {item.looseUnit}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Divider/>
                            </Box>




                            // <div key={key}>
                            //     <p>
                            //         <Box component="span" fontWeight="700">
                            //             {ordinal_suffix_of(key + 1)} Package:
                            //         </Box>{" "}
                            //         <Box component="span" fontWeight="400">
                            //             {item.description} - {item.weight}{item.weightUnit} &times; {item.looseUnit}
                            //         </Box>
                            //     </p>
                            // </div>
                        );
                    })}
                </Box>
            </Box>
        </Box>
    );
};

const useStyles = makeStyles((theme) => ({
    alertRoot: {
        width: "80%",
        minWidth: "200px",
        margin: "10px auto",
    },
    summaryBodyText: {
        fontSize: "1.1rem",
        color: "rgba(0,0,0,0.8)",
        fontFamily: "Montserrat",
        fontWeight: "400",
    },
    summaryHeader: {
        fontSize: "16pt",
        color: "rgb(141, 173, 155)",
        fontFamily: "Montserrat",
        fontWeight: '600'
        // borderBottom: '1px, solid rgb(141, 173, 155)',
    },
    summaryAddress: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '20px'
    },
    summaryAddressContainer: {
        backgroundColor: '#FFFFFF',
        borderRadius: '5px',
        padding: '10px 20px',
        width: '50%'
    },
    summaryAddressSection: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    summaryItems: {
        backgroundColor: '#FFFFFF',
        borderRadius: '5px',
        padding: '10px 20px',
        marginTop: '20px',
        width: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    summaryItemEachRoot: {
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 10px',
    },
    summaryItemEachItem: {
        marginBottom: '10px',
    },
    summaryItemEachItemDetail: {
        display: 'flex',
        flexDirection: 'column'
    },
    summaryItemEachItemQuantity: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    summaryItemEachItemButton: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
    }


}));

ReviewOrderHeader.propTypes = {
    pickupEmpty: PropTypes.bool.isRequired,
    toFirst: PropTypes.func.isRequired,
    pickUpDetails: PropTypes.object.isRequired,
    toFourth: PropTypes.func.isRequired,
    pallets: PropTypes.array.isRequired,
};

export default ReviewOrderHeader;
