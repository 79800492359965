import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Button, Divider, IconButton} from "@mui/material";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {generateRandomString} from "../../Utils/Helper";
import {getAccessToken} from "../../Utils/doToken";
import {useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {Fragment} from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from '@mui/icons-material/Warning';
import DownloadIcon from '@mui/icons-material/Download';
import {CrossBorderDashboardTrackingItem} from "./CrossBorderDashboardTrackingItem";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export const CrossBorderDashboardTrackingList = ({shippingListData, trackingList, cbGetTrackingList}) => {

    console.log('tracking list', trackingList);

    const isMobile = useMediaQuery('(max-width: 480px)');

    return (
        <Box sx={{
            border: '1px solid #B2B2B2',
            borderRadius: '10px',
            padding: '20px'
        }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography style={{fontSize: '18px', fontWeight: '600'}}>
                        Package
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                {
                    !isMobile &&
                    <>
                        <Grid item xs={3}>
                            <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                                Information
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                                Dimension & Weight
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                                Invoice Status
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                        </Grid>
                        <Grid item xs={1}>
                        </Grid>
                        <Grid item xs={1}>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                    </>
                }
                <Grid item xs={12}>
                    <Grid container spacing={isMobile ? 0 : 2}>
                        {
                            trackingList?.map((element, index) => <CrossBorderDashboardTrackingItem key={index}
                                                                                                    trackingItem={element}
                                                                                                    cbGetTrackingList={cbGetTrackingList}/>)
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}