import {useParams} from "react-router-dom";
import {DialogContentText, Divider, Link, Snackbar, useMediaQuery} from "@mui/material";
import {useRef, useState} from "react";
import axios from "axios";
import {LABEL_DOWNLOAD_URL_PREFIX, PARTNER_URI} from "../../../Utils/apiUrl";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import DownloadIcon from "@mui/icons-material/Download";
import {Fragment} from "react";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import MuiAlert from "@mui/material/Alert";
import * as React from "react";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const BusinessCrossBorderDashboardTrackingItem = ({key, trackingItem, cbGetTrackingList}) => {

    const isMobile = useMediaQuery('(max-width: 480px)');

    const inputPDFFile = useRef(null);

    const {order_id} = useParams();

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [uploadPDFLoading, setUploadPDFLoading] = useState(false);
    const [confirmPDFLoading, setConfirmPDFLoading] = useState(false);

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleConfirmDialogOpen = () => {
        setConfirmDialogOpen(true);
    };

    const handleConfirmDialogClose = () => {
        setConfirmDialogOpen(false);
    };

    const uploadPDFToServer = async ({fileObj}) => {
        try {
            if (!fileObj) {
                return;
            }
            setUploadPDFLoading(true);
            const formData = new FormData();
            formData.append("tranId", trackingItem?.tran_id);
            formData.append("invoice_pdf", fileObj);
            formData.append("orderId", order_id);
            formData.append("trackingNumber", trackingItem?.tracking_no);
            formData.append("shippingIdNumber", trackingItem?.shipping_id_no);

            const {data} = await axios.post(`${PARTNER_URI}/commercialInvoice/uploadCommercialInvoiceViaTrackingNumber/ups`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data;",
                }
            })
            console.log(data.result);
        } catch (e) {
            console.log(e);
        } finally {
            setUploadPDFLoading(false);
        }
    }

    const onPDFDrop = async (e) => {
        if (!(e.target.files && e.target.files[0])) {
            return;
        }
        // setPdfFileObj(e.target.files[0]);
        // setPDFFileName(e.target.files[0].name);
        await uploadPDFToServer({fileObj: e.target.files[0]});
        cbGetTrackingList();
    };

    const confirmPDF = async () => {
        try {
            setConfirmPDFLoading(true);
            const requestData = {
                documentId: trackingItem?.ref_doc_id,
                shipmentIdentifier: trackingItem?.shipping_id_no,
                trackingNumber: trackingItem?.tracking_no,
                docId: trackingItem?.doc_id
            }
            const {data} = await axios.post(`${PARTNER_URI}/commercialInvoice/upsPaperlessPushImageToRepo`, requestData);
            console.log(data);
            cbGetTrackingList();
            handleConfirmDialogClose();
        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail to submit invoice.');
            handleErrorMessage();
        } finally {
            setConfirmPDFLoading(false);
        }
    }

    console.log('tacking item', trackingItem);

    return (
        <Fragment key={key}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Dialog
                open={confirmDialogOpen}
                onClose={handleConfirmDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Confirm Invoice PDF with Tracking Number
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This process will associate the tracking to this Document PDF <b>
                        <a href={trackingItem?.file_uri ? `${LABEL_DOWNLOAD_URL_PREFIX}${trackingItem?.file_uri}` : "#"}
                           target="_blank" rel="noopener noreferrer">{trackingItem?.file_uri ? "Download" : ""}</a>
                    </b>. Are you sure to confirm?
                        <hr/>
                        Click <b>Confirm</b> to confirm.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmDialogClose} variant="outlined" autoFocus type="button">No</Button>
                    <Button onClick={confirmPDF} variant="outlined" type="button" disabled={confirmPDFLoading}>
                        {confirmPDFLoading && <CircularProgress size={14}/>}
                        {!confirmPDFLoading && "Confirm"}
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid item md={3} xs={12}>
                <Typography style={{fontSize: '14px', textAlign: 'left', overflowWrap: 'break-word'}}>
                    {isMobile && 'Name: '}{trackingItem?.item_name}
                </Typography>
                <Typography style={{fontSize: '14px', textAlign: 'left', overflowWrap: 'break-word'}}>
                    {isMobile && 'Tracking No: '}{trackingItem?.tracking_no}
                </Typography>
            </Grid>
            <Grid item md={2} xs={12}>
                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                    {isMobile && 'Dimension: '}{trackingItem?.length}x{trackingItem?.width}x{trackingItem?.height} {trackingItem?.dimension_unit}
                </Typography>
                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                    {isMobile && 'Weight: '}{trackingItem?.weight} {trackingItem?.weight_unit}
                </Typography>
            </Grid>
            <Grid item md={4} xs={12}>
                <Box sx={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                    {trackingItem?.doc_status === 2 ? <CheckCircleIcon
                        style={{color: '#1D8B45', fontSize: '18px'}}/> : trackingItem?.doc_status === 1 ?
                        <WarningIcon style={{color: '#F2BE22', fontSize: '18px'}}/> :
                        <CheckCircleIcon style={{color: '#454545', fontSize: '18px'}}/>}
                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                        {trackingItem?.doc_status === 2 ? 'Invoice Submitted Successfully' : trackingItem?.doc_status === 1 ? 'Invoice uploaded. Please Submit' : 'Invoice Need to Upload'}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={1} xs={4}>
                <input type="file" style={{display: "none"}} ref={inputPDFFile} onChange={onPDFDrop} accept=".pdf"
                       name="file"/>
                <Box sx={{display: 'flex'}}>
                    <input type="file" style={{display: "none"}} ref={inputPDFFile} onChange={onPDFDrop} accept=".pdf"
                           name="file"/>
                    <LoadingButton
                        variant='contained'
                        sx={{
                            backgroundColor: trackingItem?.doc_status === 1 ? '#F2BE22' : '#454545',
                            "&:hover": {
                                backgroundColor: trackingItem?.doc_status === 1 ? '#F2BE22' : '#454545',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        onClick={() => {
                            inputPDFFile.current.click();
                        }}
                        loading={uploadPDFLoading}
                        disabled={trackingItem?.doc_status === 2}
                    >
                        <Typography style={{fontSize: '14px', textTransform: 'none'}}>
                            {trackingItem?.doc_status === 1 ? 'Replace' : 'Upload'}
                        </Typography>
                    </LoadingButton>
                </Box>
            </Grid>
            <Grid item md={1} sx={4}>
                <Box sx={{display: 'flex'}}>
                    <Button
                        variant='contained'
                        sx={{
                            backgroundColor: '#1D8B45',
                            "&:hover": {
                                backgroundColor: '#1D8B45',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        onClick={handleConfirmDialogOpen}
                        loading={confirmPDFLoading}
                        disabled={trackingItem?.doc_status !== 1}
                    >
                        <Typography style={{fontSize: '14px', textTransform: 'none'}}>
                            Submit
                        </Typography>
                    </Button>
                </Box>
            </Grid>
            <Grid item md={1} xs={4}>
                {
                    trackingItem?.file_uri &&
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Link href={trackingItem?.file_uri && `${LABEL_DOWNLOAD_URL_PREFIX}${trackingItem?.file_uri}`}
                              target="_blank" rel="noopener noreferrer"
                        >
                            <IconButton
                                disabled={!trackingItem?.doc_status}
                            >
                                <DownloadIcon/>
                            </IconButton>
                        </Link>
                    </Box>
                }
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
        </Fragment>
    )
}