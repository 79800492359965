import Box from "@mui/material/Box";
import {Button, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import {useHistory} from "react-router-dom";
import {getLogo} from "../../Utils/Helper";
import * as React from "react";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import {getAccessToken} from "../../Utils/doToken";
import axios from "axios";
import {useState} from "react";
import MuiAlert from "@mui/material/Alert";
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const tableRow = [
    'Order#',
    'Total Charge inc. tax',
    'Sender Information',
    'Deliver Information',
    'Service Type',
    'Order Date',
]

const styles = {
    tableHeadText: {
        fontSize: {
            fontSize: '14px',
            fontWeight: '600',
            padding: '0 5px'
        },
        '@media (max-width: 480px)': {
            fontSize: '10px'
        },
    },
    tableBodyText: {
        fontSize: {
            fontSize: '14px',
            padding: '0 5px'
        },
        '@media (max-width: 480px)': {
            fontSize: '10px'
        },
    },
    tableCell: {
        padding: '16px 0'
    },
    tableButton: {
        padding: 0
    },
    tableImage: {
        height: '15px',
        padding: '0 5px'
    },
}

export const MyOrdersNotPaid = ({orderList}) => {

    // console.log('order list', orderList);

    const accessToken = getAccessToken("access_token");

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }


    const history = useHistory();

    const cancelBackendOrders = async (orderId) => {
        let requestURL = `${NODE_ROUTE_URI}/looseItem/cancelBackendOrderByOrderId`;
        try {
            await axios({
                method: 'delete',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                params: {
                    backendOrderId: orderId
                }
            })
            console.log('success');
            setErrorMessage('');
            handleSuccessMessage();
            setSuccessMessage(`Order ${orderId} was successfully deleted.`);
            window.location.reload();
        } catch (e) {
            setErrorMessage(`Deletion of Order ${orderId} failed.`);
        }
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {tableRow.map((item, index) => {
                                return (
                                    <TableCell key={index} sx={styles.tableCell}>
                                        <Typography sx={styles.tableHeadText}>
                                            {item}
                                        </Typography>
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            orderList?.map((each, index) => {
                                return (
                                    <TableRow
                                        key={index}
                                    >
                                        <TableCell sx={styles.tableCell}>
                                            <Button
                                                sx={styles.tableButton}
                                                onClick={() => history.push(`/shippingOrderDetails/${each.id}`)}
                                            >
                                                <Typography sx={styles.tableBodyText}>
                                                    {each.order_number}
                                                </Typography>
                                            </Button>
                                        </TableCell>
                                        <TableCell sx={styles.tableCell}>
                                            <Typography sx={styles.tableBodyText}>
                                                {each?.currency_code} ${each?.final_total}
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={styles.tableCell}>
                                            <Box>
                                                <Typography sx={styles.tableBodyText}>
                                                    {each?.sender}
                                                </Typography>
                                                <Typography sx={styles.tableBodyText}>
                                                    {each.pickup_address}
                                                </Typography>
                                                <Typography sx={styles.tableBodyText}>
                                                    {each?.pickup_area}
                                                </Typography>
                                                <Typography sx={styles.tableBodyText}>
                                                    {each?.pickup_postal_code}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell sx={styles.tableCell}>
                                            <Box>
                                                <Typography sx={styles.tableBodyText}>
                                                    {each?.deliver_name}
                                                </Typography>
                                                <Typography sx={styles.tableBodyText}>
                                                    {each.deliver_address}
                                                </Typography>
                                                <Typography sx={styles.tableBodyText}>
                                                    {each?.deliver_area}
                                                </Typography>
                                                <Typography sx={styles.tableBodyText}>
                                                    {each?.deliver_postal_code}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell sx={styles.tableCell}>
                                            <img
                                                src={getLogo((each?.account_base_combine_name?.split("-")[0]).toLowerCase())}
                                                alt="logo"
                                                style={styles.tableImage}/>
                                            <Typography sx={styles.tableBodyText}>
                                                - {each.ship_service_type}
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={styles.tableCell}>
                                            <Typography sx={styles.tableBodyText}>
                                                {dayjs(each?.created_at).format("YYYY-MM-DD")}
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={styles.tableCell}>
                                            <Button onClick={() => cancelBackendOrders(each?.order_id)}>
                                                <Typography sx={styles.tableBodyText}>
                                                    cancel
                                                </Typography>
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}