import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LensIcon from '@mui/icons-material/Lens';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from "@mui/material/Grid";
import AccordionDetails from "@mui/material/AccordionDetails";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import {ListItem} from "@mui/material";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import dayjs from "dayjs";


const formatDate = (dateString) => {
    return dayjs(dateString).toString()
}

export const TrackingItem = ({
                                 activity,
                                 trackingNo,
                                 courier,
                                 deliverStatus,
                                 transaction: {
                                     send_to_addr,
                                     send_to_city,
                                     send_to_province,
                                     send_to_postal_code,
                                     send_to_country,
                                     send_to_name
                                 }
                             }) => {
    return (
        <Box
            sx={{
                width: "100%",
                display: 'flex',
                justifyContent: 'center'
            }}
        >
            <Accordion sx={{width: '100%'}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>{trackingNo}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid item xs={9}>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <Typography variant="h6" sx={{
                                    color: "secondary.600"
                                }}>Carrier: &nbsp;</Typography>
                                <Typography variant="h6">{courier?.toUpperCase()}</Typography>
                            </Box>
                            <Box
                                sx={{
                                    // mt: 1,
                                    display: "flex",
                                }}
                            >
                                <LocationOnIcon/>
                                <Typography sx={{
                                    ml: 1,
                                    color: "secondary.600"
                                }}>Deliver to: &nbsp;</Typography>
                                <Typography>
                                    {send_to_name}
                                    <br/>
                                    {send_to_addr}, {send_to_city}, {send_to_province}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    textAlign: "end",
                                    height: "100%",
                                    justifyContent: "center",
                                }}
                            >
                                <Typography sx={{
                                    // ml: 1,
                                    color: "secondary.600"
                                }}>Status</Typography>
                                <Typography>{deliverStatus}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Divider sx={{
                        mt: 1,
                        width: "100%",
                        color: "secondary.main"
                    }}/>
                    <List
                        sx={{width: "100%", maxWidth: 360, bgcolor: "background.paper"}}
                    >
                        {activity
                            ? activity.map((activityElement, index) => {
                                return (
                                    <ListItem key={index}>
                                        <ListItemAvatar>
                                            {/* <Avatar> */}
                                            <LensIcon sx={{
                                                // color: "primary.main",
                                            }}/>
                                            {/* </Avatar> */}
                                        </ListItemAvatar>
                                        <Box>
                                            <ListItemText
                                                primary={activityElement?.description}
                                            />
                                            <Typography>
                                                {activityElement?.address?.city && `${activityElement?.address?.city},`} {activityElement?.address?.province}
                                            </Typography>
                                            <ListItemText
                                                secondary={formatDate(activityElement?.date)}
                                            />
                                        </Box>
                                    </ListItem>
                                );
                            })
                            : undefined}
                    </List>
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}