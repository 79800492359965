import Grid from "@material-ui/core/Grid";
import Box from "@mui/material/Box";
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {TrackingLTLEach} from "./TrackingLTLEach";
import {mapLTLLogo} from "../../../Utils/Helper";

export const TrackingLTLMultiple = ({trackingData}) => {

    console.log(trackingData);

    return (
        <Box sx={{
            padding: '5px 0'
        }}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>{trackingData?.trackingNumber}</Typography>
                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        borderTop: '1px solid #B2B2B2',
                        padding: '0 0 25px',
                    }}
                >
                    <Box sx={{
                        padding: '8px 16px'
                    }}>
                        <Grid container spacing={2}>
                            {
                                trackingData?.code === 400 ? <Grid item xs={12}>
                                        <Typography>
                                            {trackingData?.error}
                                        </Typography>
                                    </Grid> :
                                    trackingData?.code === 401 ? <Grid item xs={12}>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'flex-start',
                                                padding: '10px',
                                                gap: '20px'
                                            }}>
                                                <img src={"https://storage.googleapis.com/uucargo-resource/image/icon/tracking_ready.png"} alt="tacking_logo" width={60}/>
                                                <Box>
                                                    <Typography
                                                        style={{
                                                            fontSize: '16px',
                                                            fontWeight: '600',
                                                            textAlign: 'left'
                                                        }}>
                                                        Ready for Shipping
                                                    </Typography>
                                                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                                        Your pallet has passed all checks and is awaiting pickup by the
                                                        courier.
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid> :
                                        trackingData?.trackingResponse?.statusCode === 2 ? <Grid item xs={12}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start',
                                                    padding: '10px',
                                                    gap: '20px'
                                                }}>
                                                    <img src={"https://storage.googleapis.com/uucargo-resource/image/icon/tracking_delivered.png"} alt="tacking_logo" width={60}/>
                                                    <Box>
                                                        <Typography
                                                            style={{
                                                                fontSize: '16px',
                                                                fontWeight: '600',
                                                                textAlign: 'left'
                                                            }}>
                                                            Delivered
                                                        </Typography>
                                                        <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                                            Your pallet has been successfully delivered at the destination.
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <img src={mapLTLLogo(trackingData?.accountBase?.toLowerCase())}
                                                             alt="logo"
                                                             width='60px'/>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            : trackingData?.trackingResponse?.statusCode === 1 ? <Grid item xs={12}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'flex-start',
                                                        padding: '10px',
                                                        gap: '20px'
                                                    }}>
                                                        <img src={"https://storage.googleapis.com/uucargo-resource/image/icon/tracking_in_transit.png"} alt="tacking_logo" width={60}/>
                                                        <Box>
                                                            <Typography
                                                                style={{
                                                                    fontSize: '16px',
                                                                    fontWeight: '600',
                                                                    textAlign: 'left'
                                                                }}>
                                                                In Transit
                                                            </Typography>
                                                            <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                                                Your pallet is currently on its way to the destination.
                                                            </Typography>
                                                        </Box>
                                                        <Box>
                                                            <img src={mapLTLLogo(trackingData?.accountBase?.toLowerCase())}
                                                                 alt="logo"
                                                                 width='60px'/>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                                :
                                                <Grid item xs={12}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'flex-start',
                                                        padding: '10px',
                                                        gap: '20px'
                                                    }}>
                                                        <img src={"https://storage.googleapis.com/uucargo-resource/image/icon/tracking_ready.png"} alt="tacking_logo" width={60}/>
                                                        <Box>
                                                            <Typography
                                                                style={{
                                                                    fontSize: '16px',
                                                                    fontWeight: '600',
                                                                    textAlign: 'left'
                                                                }}>
                                                                Ready for Shipping
                                                            </Typography>
                                                            <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                                                Your pallet has passed all checks and is awaiting pickup by
                                                                the
                                                                courier.
                                                            </Typography>
                                                        </Box>
                                                        <Box>
                                                            <img src={mapLTLLogo(trackingData?.accountBase?.toLowerCase())}
                                                                 alt="logo"
                                                                 width='60px'/>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                            }
                            <TrackingLTLEach trackingData={trackingData}/>
                        </Grid>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}