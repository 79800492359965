import {Button, Container, Tab, Tabs} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useState} from "react";
import {MyOrdersTable} from "./MyOrdersTable";
import * as React from "react";

const tabList = [
    {value: 'notPaid', label: 'not paid'},
    {value: 'active', label: 'active'},
    {value: 'cancelled', label: 'cancelled'},
    {value: 'refunded', label: 'refunded'},
]


const styles = {
    MyOrdersRoot: {
        display: 'flex',
        flexDirection: 'column'
    },
    MyOrdersHeading: {
        fontSize: '35px',
        fontWeight: '600',
        margin: '20px 0'
    },
    MyOrdersTabList: {
        backgroundColor: '#FFFFFF',
        borderRadius: '5px'
    },
    MyOrdersTab: {
        fontSize: '16px',
        fontWeight: '600',
        '@media (max-width: 480px)': {
            fontSize: '10px',
            fontWeight: '600',
        },
    },
    MyOrdersSearch: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '20px 0',
        gap: '10px',
    },
    MyOrdersSearchInput: {
        display: 'flex',
        gap: '10px',
        alignItems: 'flex-end',
        width: '100%'
    },
    MyOrdersSearchButtonBox: {
        backgroundColor: '#2192FF',
        borderRadius: '5px',
        ':hover': {
            opacity: '0.8',
        }


    },
    MyOrdersSearchButton: {
        color: '#FFFFFF'
    },
}

export const MyOrders = () => {

    const [tabs, setTabs] = useState('notPaid');

    const handleTabChange = (event, newValue) => {
        setTabs(newValue);
    }

    return (
        <Container>
            <Box sx={styles.MyOrdersRoot}>
                <Typography sx={styles.MyOrdersHeading}>
                    My Shipping Orders
                </Typography>

                <Box sx={styles.MyOrdersTabList}>
                    <Tabs
                        value={tabs}
                        onChange={handleTabChange}
                        centered
                        variant="fullWidth"
                    >
                        {
                            tabList.map((tab, index) => {
                                    return (
                                        <Tab key={index} value={tab.value} label={tab.label} sx={styles.MyOrdersTab}/>
                                    )
                                }
                            )
                        }
                    </Tabs>
                </Box>
                <MyOrdersTable tabs={tabs}/>
            </Box>
        </Container>
    )
}