import React, {Fragment, useRef, useState} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import {Button, Divider, IconButton, Link} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import axios from "axios";
import {LABEL_DOWNLOAD_URL_PREFIX, NODE_ROUTE_URI} from "../../Utils/apiUrl";
import {useParams} from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export const CrossBorderDashboardTrackingItem = ({key, trackingItem, cbGetTrackingList}) => {

    const isMobile = useMediaQuery('(max-width: 480px)');

    const inputPDFFile = useRef(null);

    const {order_id} = useParams();

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    const [uploadPDFLoading, setUploadPDFLoading] = useState(false);
    const [confirmPDFLoading, setConfirmPDFLoading] = useState(false);

    const handleConfirmDialogOpen = () => {
        setConfirmDialogOpen(true);
    };

    const handleConfirmDialogClose = () => {
        setConfirmDialogOpen(false);
    };

    const uploadPDFToServer = async ({fileObj}) => {
        try {
            if (!fileObj) {
                return;
            }
            setUploadPDFLoading(true);
            const formData = new FormData();
            formData.append("tranId", trackingItem?.tran_id);
            formData.append("invoice_pdf", fileObj);
            formData.append("orderId", order_id);
            formData.append("trackingNumber", trackingItem?.tracking_no);
            formData.append("shippingIdNumber", trackingItem?.shipping_id_no);

            const {data} = await axios.post(`${NODE_ROUTE_URI}/commercialInvoice/uploadCommercialInvoiceViaTrackingNumber/ups`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data;",
                }
            })
            console.log(data.result);
        } catch (e) {
            console.log(e);
        } finally {
            setUploadPDFLoading(false);
        }
    }

    const onPDFDrop = async (e) => {
        if (!(e.target.files && e.target.files[0])) {
            return;
        }
        // setPdfFileObj(e.target.files[0]);
        // setPDFFileName(e.target.files[0].name);
        await uploadPDFToServer({fileObj: e.target.files[0]});
        cbGetTrackingList();
    };

    const confirmPDF = async () => {
        try {
            setConfirmPDFLoading(true);
            // setConfirmErrorMessage("");
            const requestData = {
                documentId: trackingItem?.ref_doc_id,
                shipmentIdentifier: trackingItem?.shipping_id_no,
                trackingNumber: trackingItem?.tracking_no,
                docId: trackingItem?.doc_id
            }
            const {data} = await axios.post(`${NODE_ROUTE_URI}/commercialInvoice/upsPaperlessPushImageToRepo`, requestData);
            console.log(data);
            cbGetTrackingList();
            handleConfirmDialogClose();
        } catch (e) {
            console.log(e);
            // setConfirmErrorMessage(e?.message || "Error!");
        } finally {
            setConfirmPDFLoading(false);
        }
    }

    return (
        <Fragment key={key}>
            <Dialog
                open={confirmDialogOpen}
                onClose={handleConfirmDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Confirm Invoice PDF with Tracking Number
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This process will associate the tracking to this Document PDF <b>
                        <a href={trackingItem?.file_uri ? `${LABEL_DOWNLOAD_URL_PREFIX}${trackingItem?.file_uri}` : "#"}
                           target="_blank" rel="noopener noreferrer">{trackingItem?.file_uri ? "Download" : ""}</a>
                    </b>. Are you sure to confirm?
                        <hr/>
                        Click <b>Confirm</b> to confirm.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmDialogClose} variant="outlined" autoFocus type="button">No</Button>
                    <Button onClick={confirmPDF} variant="outlined" type="button" disabled={confirmPDFLoading}>
                        {confirmPDFLoading && <CircularProgress size={14}/>}
                        {!confirmPDFLoading && "Confirm"}
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid item md={3} xs={12}>
                <Typography style={{fontSize: '14px', textAlign: 'left', overflowWrap: 'break-word'}}>
                    {isMobile && 'Name: '}{trackingItem?.item_name}
                </Typography>
                <Typography style={{fontSize: '14px', textAlign: 'left', overflowWrap: 'break-word'}}>
                    {isMobile && 'Tracking No: '}{trackingItem?.tracking_no}
                </Typography>
            </Grid>
            <Grid item md={2} xs={12}>
                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                    {isMobile && 'Dimension: '}{trackingItem?.length}x{trackingItem?.width}x{trackingItem?.height} {trackingItem?.dimension_unit}
                </Typography>
                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                    {isMobile && 'Weight: '}{trackingItem?.weight} {trackingItem?.weight_unit}
                </Typography>
            </Grid>
            <Grid item md={4} xs={12}>
                <Box sx={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                    {trackingItem?.doc_status === 2 ? <CheckCircleIcon
                        style={{color: '#1D8B45', fontSize: '18px'}}/> : trackingItem?.doc_status === 1 ?
                        <WarningIcon style={{color: '#F2BE22', fontSize: '18px'}}/> :
                        <CheckCircleIcon style={{color: '#454545', fontSize: '18px'}}/>}
                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                        {trackingItem?.doc_status === 2 ? 'Invoice Submitted Successfully' : trackingItem?.doc_status === 1 ? 'Invoice uploaded. Please Submit' : 'Invoice Need to Upload'}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={1} xs={4}>
                <input type="file" style={{display: "none"}} ref={inputPDFFile} onChange={onPDFDrop} accept=".pdf"
                       name="file"/>
                <Box sx={{display: 'flex'}}>
                    <input type="file" style={{display: "none"}} ref={inputPDFFile} onChange={onPDFDrop} accept=".pdf"
                           name="file"/>
                    <LoadingButton
                        variant='contained'
                        sx={{
                            backgroundColor: trackingItem?.doc_status === 1 ? '#F2BE22' : '#454545',
                            "&:hover": {
                                backgroundColor: trackingItem?.doc_status === 1 ? '#F2BE22' : '#454545',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        onClick={() => {
                            inputPDFFile.current.click();
                        }}
                        loading={uploadPDFLoading}
                        disabled={trackingItem?.doc_status === 2}
                    >
                        <Typography style={{fontSize: '14px', textTransform: 'none'}}>
                            {trackingItem?.doc_status === 1 ? 'Replace' : 'Upload'}
                        </Typography>
                    </LoadingButton>
                </Box>
            </Grid>
            <Grid item md={1} sx={4}>
                <Box sx={{display: 'flex'}}>
                    <Button
                        variant='contained'
                        sx={{
                            backgroundColor: '#1D8B45',
                            "&:hover": {
                                backgroundColor: '#1D8B45',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        onClick={handleConfirmDialogOpen}
                        loading={confirmPDFLoading}
                        disabled={trackingItem?.doc_status !== 1}
                    >
                        <Typography style={{fontSize: '14px', textTransform: 'none'}}>
                            Submit
                        </Typography>
                    </Button>
                </Box>
            </Grid>
            <Grid item md={1} xs={4}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <Link href={trackingItem?.file_uri ? `${LABEL_DOWNLOAD_URL_PREFIX}${trackingItem?.file_uri}` : ""}
                          target="_blank" rel="noopener noreferrer"
                    >
                        <IconButton
                            disabled={!trackingItem?.doc_status}
                        >
                            <DownloadIcon/>
                        </IconButton>
                    </Link>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
        </Fragment>
    )
}