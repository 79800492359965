import {Checkbox} from "@mui/material";
import {Fragment, useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {getAccessToken} from "../../Utils/doToken";
import {CardImages} from "../../images/cards";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import Grid from "@mui/material/Grid";
import {ReactComponent as CreditCardEmptyIcon} from "../../images/icons/CreditCardEmpty.svg";
import {ReactComponent as CreditCardSampleIcon} from "../../images/icons/CreditCardSample.svg";
import CreditCard from "../../images/icons/CreditCardEmpty.svg";
import {useSelector} from "react-redux";

export const CreditCardList = ({cardList, cbSelectCardId}) => {

    const storedToken = getAccessToken("access_token");
    const userInfo = useSelector((state) => state.user);

    const [checkedId, setCheckedId] = useState('');

    console.log('card list', cardList);

    const firstLetterUpper = (_string) => {
        return _string?.replace(/^\S/, (s) => s?.toUpperCase());
    };

    const handleChange = (id) => {
        console.log('id', id);
        setCheckedId(id);
    };

    // todo: change enduser's api done
    const setDefaultCard = async () => {
        const requestURL = `${NODE_ROUTE_URI}/stripe/setDefaultCreditCardByCardId`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {Authorization: `Bearer ${storedToken}`},
                data: {
                    cardId: checkedId
                }
            })

            console.log(result);
        } catch (e) {
            console.log(e.response);
        }
    }

    useEffect(() => {
        const defaultCard = cardList?.find((card) => card.is_default === 1);
        setCheckedId(defaultCard?.id);
    }, [])

    useEffect(() => {
        cbSelectCardId(checkedId);
    }, [checkedId])

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Box
                    sx={{
                        backgroundImage: `url(${CreditCard})`,
                        width: '200px',
                        height: '125px',
                        backgroundSize: 'cover',
                        margin: 'auto',
                        position: 'relative'
                    }}
                >
                    <Typography sx={{
                        color: '#FFFFFF',
                        position: 'absolute',
                        top: '45%',
                        left: '5%'

                    }}>
                        XXXX XXXX XXXX {cardList?.[0]?.card_last_four_digits}
                    </Typography>
                    <Typography sx={{
                        color: '#FFFFFF',
                        position: 'absolute',
                        top: '60%',
                        left: '55%',
                        fontSize: '10px'

                    }}>
                        VALID DATES
                    </Typography>
                    <Typography sx={{
                        color: '#FFFFFF',
                        position: 'absolute',
                        top: '70%',
                        left: '65%',
                        fontSize: '10px'

                    }}>
                        {cardList?.[0]?.card_expiry}
                    </Typography>
                    <Typography sx={{
                        color: '#FFFFFF',
                        position: 'absolute',
                        top: '75%',
                        left: '10%',
                        fontSize: '12px'

                    }}>
                        {userInfo?.firstname} {userInfo?.lastname}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{
                    padding: '20px 20px 0 20px',
                }}>
                    <Grid container spacing={1}>
                        {
                            cardList?.map((card, index) => (
                                <Fragment key={index}>
                                    <Grid item xs={1}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                            height: '100%',
                                        }}>
                                            <Checkbox
                                                icon={<RadioButtonUncheckedIcon/>}
                                                checkedIcon={<CheckCircleIcon/>}
                                                color="success"
                                                checked={checkedId === card?.id ? true : false}
                                                onClick={() => handleChange(card?.id)}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: '100%',
                                        }}>
                                            <img
                                                src='https://storage.googleapis.com/uucargo-resource/image/icon/creditCardImage.png'
                                                alt={firstLetterUpper(card?.card_type)}
                                                width="50px"
                                                align="bottom"
                                                style={{padding: "0 5px"}}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-end',
                                            height: '100%',
                                        }}>
                                            <img
                                                src={CardImages[firstLetterUpper(card?.card_type)]}
                                                alt={firstLetterUpper(card?.card_type)}
                                                width="40px"
                                                align="bottom"
                                                style={{padding: "0 5px"}}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                            height: '100%',
                                        }}>
                                            <Typography sx={{fontSize: '14px'}}>
                                                end with {card?.card_last_four_digits}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-end',
                                            height: '100%',
                                        }}>
                                            <Typography sx={{fontSize: '14px'}}>
                                                {card?.card_expiry}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Fragment>
                            ))
                        }
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )
}
