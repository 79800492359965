import React from "react";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import intl from "react-intl-universal";
const useStyles = makeStyles((theme) => ({
  listItemWrapper: {
    width: "100%",
    margin: "3px auto",
    "&:hover": {
      cursor: "pointer",
      background: "aliceblue"
    },
    lineHeight: "2rem"
  }
}));

export default function BusinessTrackingInfoTableItem({
                                                accountBase,
                                                orderId,
                                                refProductNumber,
                                                trackingNumber,
                                                sendToName,
                                                sendToPhone,
                                                sendToAddress,
                                                sendToCity,
                                                sendToPostalCode,
                                                sendToProvince,
                                                sendToCountry,
                                                sendToEmail,
                                                date,
                                                labelURI,
                                                onClick
                                              }) {
  const classes = useStyles();

  return (
      <>
        <tr className={classes.listItemWrapper}>
          <td width="30%">
            <Link
                to={`/loose-item/3rd-party/shipping/order/${orderId}`}>
              {orderId}
            </Link> /{refProductNumber}
          </td>
          {/*<td>{refProductNumber}</td>*/}
          <td width="50%">
            {/*{`${send_to_name ? send_to_name : "N/A"} ${send_to_email ? send_to_email : "N/A"}, ${send_to_tel ? send_to_tel : "N/A"}`}*/}
            {sendToName ? sendToName : "N/A"}
            <br />
            {sendToPhone ? sendToPhone : "N/A"}, {sendToEmail ? sendToEmail : "N/A"}
            <br />
            {sendToAddress}
            <br />
            {sendToCity}, {sendToProvince}, {sendToCountry}, {sendToPostalCode}
          </td>
          <td>{accountBase}
            <br />
            <a href={labelURI || "#"} target="_blank" rel="noopener noreferrer">{trackingNumber}</a>

          </td>
          {/*<td>{accountBase}</td>*/}
          {/*<td>*/}
          {/*  <a href={labelURI} target="_blank" rel="noopener noreferrer">{labelURI ? "Download" : ""}</a>*/}
          {/*</td>*/}
          <td width="30%">{dayjs(date).format("YYYY-MM-DD")}</td>
          <td width="30%">
            <button onClick={onClick}>
                {intl.get('SCHEDULE_PICKUP.ADD')}
            </button>
          </td>
        </tr>
      </>
  );
}
