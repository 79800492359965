import {Container, Divider, Pagination} from "@mui/material";
import {getAccessToken} from "../../Utils/doToken";
import {PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import Grid from "@material-ui/core/Grid";
import {QuoteLTLEach} from "./QuoteLTLEach";
import Box from "@mui/material/Box";
import LoadingContainer from "../shared/loadingContainer";
import IconButton from "@mui/material/IconButton";
import AddIcon from '@mui/icons-material/Add';
import {useHistory} from "react-router-dom";
import {PAGE_SIZE} from "../../Utils/consInfo";

const styles = {
    QuoteLTLHeading: {
        fontSize: '35px',
        fontWeight: '600',
        textAlign: 'center'
    },
    QuoteLTLTitle: {
        fontSize: '18px',
        textAlign: 'left',
        fontWeight: '600'
    },
    QuoteLTLRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        margin: '0 40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    }
}

export const QuoteLTL = () => {

    const history = useHistory();

    const token = getAccessToken("access_token");

    const [quoteList, setQuoteList] = useState([]);
    const [pagination, setPagination] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [selectQuoteId, setSelectQuoteId] = useState(null);

    const handleLTLQuickQuote =( ) => {
        history.push('/LTL-quick-quote');
    }

    const getLTLQuotes = async (currentPage) => {
        let requestURL = `${PARTNER_URI}/shippingLTL/getLTLQuotes`;
        setLoading(true);

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    page: currentPage,
                    pageSize: PAGE_SIZE
                }
            })

            const quoteListRedux = result?.data?.data;
            const paginationRedux = result?.data?.pagination;
            setQuoteList(quoteListRedux);
            setPagination(paginationRedux);
            setLoading(false);

        } catch (e) {
            console.log('error', e.response);
            setLoading(false);
        }
    }

    const handleChangePagination = (event, value) => {
        console.log(value);
        getLTLQuotes(value);
        setCurrentPage(value);
    }

    useEffect(() => {
        getLTLQuotes(1);
    }, []);

    const handleSelectQuoteId = (id) => {
        setSelectQuoteId(id);
    }
    console.log('quote list', quoteList);
    console.log('pagination', pagination);

    return (
        <Container maxWidth='xl'>
            <Box sx={styles.QuoteLTLRoot}>
                <Typography style={styles.QuoteLTLHeading}>
                    My Shipping LTL Quotes
                </Typography>
                {loading ? <LoadingContainer/> :
                    (quoteList?.length > 0) ?
                        <>
                            <Divider/>
                            <Grid container spacing={4}>
                                <Grid item xs={5}>
                                    <Grid container spacing={0}>
                                        <Grid item md={4}>
                                            <Typography style={styles.QuoteLTLTitle}>
                                                Quote Number
                                            </Typography>
                                        </Grid>
                                        <Grid item md={4}>
                                            <Typography style={styles.QuoteLTLTitle}>
                                                Expiry Date
                                            </Typography>
                                        </Grid>
                                        <Grid item md={4}>
                                            <Typography style={styles.QuoteLTLTitle}>
                                                Carrier
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={7}>
                                    <Grid container spacing={0}>
                                        <Grid item md={4}>
                                            <Typography style={styles.QuoteLTLTitle}>
                                                Ship From
                                            </Typography>
                                        </Grid>
                                        <Grid item md={4}>
                                            <Typography style={styles.QuoteLTLTitle}>
                                                Ship To
                                            </Typography>
                                        </Grid>
                                        <Grid item md={3}>
                                            <Typography style={styles.QuoteLTLTitle}>
                                                Estimate Cost
                                            </Typography>
                                        </Grid>
                                        <Grid item md={1}>
                                            <Typography style={styles.QuoteLTLTitle}>
                                                Action
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Divider/>
                            {
                                quoteList?.map((quote, index) => <QuoteLTLEach
                                    key={index}
                                    quote={quote}
                                    handleSelectQuoteId={handleSelectQuoteId}
                                    selectQuoteId={selectQuoteId}
                                />)
                            }
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <Pagination count={pagination?.lastPage} page={currentPage}
                                                onChange={handleChangePagination}/>
                                </Grid>
                            </Grid>
                        </>
                        :
                        <Box sx={{
                            border: '2px solid #609966',
                            height: '50vh',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}>
                            <Typography style={{fontSize: '18px'}}>
                                Your Shipping LTL Quote is Empty
                            </Typography>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Typography style={{fontSize: '18px'}}>
                                    LTL Quick Quote
                                </Typography>
                                <IconButton onClick={handleLTLQuickQuote}>
                                    <AddIcon/>
                                </IconButton>
                            </Box>
                        </Box>
                }
            </Box>
        </Container>
    )
}