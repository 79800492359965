export const USER_CURRENT_ADDRESS_COOKIE_NAME = "user_current_address";
export const USER_CURRENT_CITY_COOKIE_NAME = "user_current_city";
export const USER_CURRENT_PROVINCE_COOKIE_NAME = "user_current_province";
export const USER_CURRENT_AREA_GROUP_COOKIE_NAME = "user_current_area_group";

export const USER_CURRENT_ADDRESS_COOKIE_TIME = 3600 * 1000;

export const GUEST_ORDER_PRICE_CHECK_COOKIE_NAME = "price_check";

export const GUEST_ORDER_PRICE_CHECK_COOKIE_TIME = 3600 * 1000;

export const GUEST_CARGO_CONTROL_TRACKING_COOKIE_NAME =
  "cargo_control_tracking";

export const GUEST_CARGO_CONTROL_TRACKING_COOKIE_TIME = 3600 * 1000;


export const CRM_INVOICE_TYPE_PAID = "PAID"; //green
export const CRM_INVOICE_TYPE_UNPAID = "UNPAID"; // red
export const CRM_INVOICE_TYPE_TOPUP = "TOP-UP"; // dark
export const CRM_INVOICE_TYPE_SPLIT = "SPLIT"; // dark
export const CRM_INVOICE_TYPE_PARTIAL_REFUNDED = "PARTIAL REFUNDED"; // dark
export const CRM_INVOICE_TYPE_FULLY_REFUNDED = "REFUNDED"; // dark
export const CRM_INVOICE_TYPE_CLOSED = "CLOSED"; // dark