import {Checkbox, Container, Divider, Snackbar} from "@mui/material";
import Box from "@mui/material/Box";
import intl from "react-intl-universal";
import {styled} from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import {useMediaQuery} from "@material-ui/core";
import Tab from "@mui/material/Tab";
import {useHistory} from "react-router-dom";
import {useEffect, useState, Fragment} from "react";
import {ShippingPackageImportShipmentShipFrom} from "./ShippingPackageImportShipmentShipFrom";
import {ShippingPackageImportShipmentUploadExcel} from "./ShippingPackageImportShipmentUploadExcel";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {getMissingKeysForCreateLabelLocation, isValidatePhone} from "../../Utils/Helper";
import axios from "axios";
import {PARTNER_URI} from "../../Utils/apiUrl";
import {getAccessToken} from "../../Utils/doToken";
import {ShippingPackageImportShipmentQuoteDetails} from "./ShippingPackageImportShipmentQuoteDetails";
import Grid from "@material-ui/core/Grid";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import packageIcon from "../../images/homepageIcon/Package.svg";
import PublicIcon from "@mui/icons-material/Public";
import ltlIcon from "../../images/homepageIcon/Pallet.svg";
import brokerageIcon from "../../images/homepageIcon/CustomBrokerage.svg";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingPackageCreateLabelRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        // margin: '100px 0'
    },
    ShippingPackageCreateLabelRow: {
        display: 'flex',
        alignItems: 'center'
        // justifyContent: 'flex-end',
    },
    ShippingPackageCreateLabelRowRight: {
        display: 'flex',
        justifyContent: 'flex-end'
        // justifyContent: 'flex-end',
    },
    ShippingPackageCreateLabelSection: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #A9A9A9',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelSectionBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
}

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{children: <span className="MuiTabs-indicatorSpan"/>}}
    />
))({
    border: '1px solid #609966',
    borderRadius: '25px',
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        // maxWidth: 40,
        width: '100%',
        backgroundColor: 'transparent',
    },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({theme}) => ({
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        color: '#000000',
        // border: '1px solid #609966',
        // borderRadius: '25px',
        '&.Mui-selected': {
            color: '#ffffff',
            backgroundColor: '#609966',
            borderRadius: '25px'
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
    }),
);

export const ShippingPackageImportShipment = () => {

    const history = useHistory();
    const storedToken = getAccessToken("access_token");
    const isMobile = useMediaQuery('(max-width: 600px)');

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [validationResult, setValidationResult] = useState(null);

    const [tabValue, setTabValue] = useState('uploadExcel');
    const [shipFrom, setShipFrom] = useState();
    const [carrier, setCarrier] = useState('');
    const [referenceOrderNumber, setReferenceOrderNumber] = useState('');
    const [returnLabel, setReturnLabel] = useState(false);
    const [uploadFile, setUploadFile] = useState(null);
    const [shipmentItems, setShipmentItems] = useState([]);
    const [errorItems, setErrorItems] = useState([]);

    const [loadingGetRate, setLoadingGetRate] = useState(false);
    const [loadingPlaceOrder, setLoadingPlaceOrder] = useState(false);

    const [shippingOrder, setShippingOrder] = useState();
    const [shippingOrderError, setShippingOrderError] = useState([]);
    const [finalTotal, setFinalTotal] = useState(0);
    const [messageLoading, setMessageLoading] = useState(false);

    const [returnLabelAccount, setReturnLabelAccount] = useState([])
    const [isAbleToReturn, setIsAbleToReturn] = useState(true);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    const cbGetShipFrom = (data) => {
        setShipFrom(data);
    }

    const cbGetCarrier = (data) => {
        setCarrier(data);
    }

    const cbGetReferenceOrderNumber = (data) => {
        setReferenceOrderNumber(data);
    }

    const cbGetReturnLabel = (data) => {
        setReturnLabel(data);
    }

    const cbGetUploadFile = (data) => {
        setUploadFile(data);
    }

    const getPackageAccountBaseList = async () => {
        const requestURL = `${PARTNER_URI}/shipping/list/getAccountBaseListInfo`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    canReturnShipment: 1
                }
            });
            console.log('[getPackageAccountBaseList] result', result);
            setReturnLabelAccount(result?.data);

        } catch (e) {
            console.log('[getPackageAccountBaseList] error', e?.response);
        }
    }

    const shippingRate = async () => {
        setLoadingGetRate(true);
        const bodyFormData = new FormData();
        const tmpFileObj = uploadFile?.fileObj || uploadFile?.contactFileObj;
        const requestURL = `${PARTNER_URI}/loose-item/3rd-party/excel/ratesFromService`;

        try {
            bodyFormData.append("xlsx_file", tmpFileObj.selectedFile);
            bodyFormData.append("accountBase", carrier);
            bodyFormData.append("shipFromAddress", shipFrom?.address);
            bodyFormData.append("shipFromAddressTwo", shipFrom?.address2);
            bodyFormData.append("shipFromCity", shipFrom?.city);
            bodyFormData.append("shipFromProvince", shipFrom?.province?.code);
            bodyFormData.append("shipFromPostalCode", shipFrom?.postalCode);
            bodyFormData.append("shipFromCountry", shipFrom?.country);
            bodyFormData.append("shipFromPhone", shipFrom?.phone);

            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                    "Content-Type": "multipart/form-data;",
                },
                data: bodyFormData
            })

            console.log('result', result);
            setErrorItems(result?.data?.errors);
            const mergedData = [...result?.data?.shipmentItems, ...result?.data?.errors];
            const sortedData = mergedData?.sort((a, b) => a.index - b.index);
            setShipmentItems(sortedData);
            setFinalTotal(result?.data?.finalTotalAmount?.value);
            // setErrorMessage('');
            // setSuccessMessage('Successfully get shipping rate');
            // handleSuccessMessage();

        } catch (e) {
            console.log('error', e.response);
            setErrorMessage('Fail to get shipping rate');
            handleErrorMessage();
        } finally {
            setLoadingGetRate(false);
        }
    }

    const placeOrder = () => {
        setLoadingPlaceOrder(true);
        const requestURL = `${PARTNER_URI}/loose-item/3rd-party/excel/shippingFromServiceViaFileName`;
        let data = {
            accountBase: carrier,
            shipFromAddress: shipFrom?.address,
            shipFromAddressTwo: shipFrom?.address2 || "",
            shipFromCity: shipFrom?.city,
            shipFromProvince: shipFrom?.province?.code,
            shipFromPostalCode: shipFrom?.postalCode,
            shipFromCountry: shipFrom?.country,
            shipFromPhone: shipFrom?.phone,
            shipFromName: shipFrom?.name,
            shipFromEmail: shipFrom?.email,
            refOrderNumber: referenceOrderNumber?.replace(/\s/g, ''),
            cargoControlNumber: '',
            fileName: uploadFile?.contactFileObj?.selectedFileName,
            shipmentSpecialServices: {
                returnShipment: returnLabel
            }
        };
        axios({
            method: 'post',
            url: requestURL,
            headers: {
                Authorization: `Bearer ${storedToken}`,
            },
            data: data,
        })
            .then((response) => {
                console.log('[ShippingPackageImportShipment] place order api result', response);
                setShippingOrder(response?.data?.shippingOrder);
                setShippingOrderError(response?.data?.errors);
                setLoadingPlaceOrder(false);
            })
            .catch((error) => {
                console.log('error', error);
                setErrorMessage('Fail to place order');
                handleErrorMessage();
                setLoadingPlaceOrder(false);
            })
    }

    const validation = () => {
        setErrorMessage('');
        setToastOpen(false);
        const shipFromMissing = getMissingKeysForCreateLabelLocation({
            name: shipFrom?.name,
            phone: shipFrom?.phone,
            address: shipFrom?.address,
            city: shipFrom?.city,
            province: shipFrom?.province?.code,
            country: shipFrom?.country,
            postalCode: shipFrom?.postalCode
        });
        const shipFromPhoneValidate = isValidatePhone(shipFrom?.phone);

        shipFromMissing?.length !== 0 && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.SHIP_FROM_MISSING'));
        !shipFromPhoneValidate && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.SHIP_FROM_PHONE'));
        !carrier && setErrorMessage(prevState => prevState + 'Carrier is missing.');
        // !referenceOrderNumber && setErrorMessage(prevState => prevState + 'Reference order number is missing.');
        (!uploadFile?.fileObj && !uploadFile?.contactFileObj) && setErrorMessage(prevState => prevState + 'Shipment file is missing.');
        !isAbleToReturn && setErrorMessage(prevState => prevState + 'The selected account does not have the ability to create a return label.');

        const validation = {
            shipFromMissing,
            shipFromPhoneValidate,
            carrier: !!carrier,
            // referenceOrderNumber: !!referenceOrderNumber,
            shipmentFile: !!(uploadFile?.fileObj || uploadFile?.contactFileObj)
        }

        setValidationResult(validation);

        if (shipFromMissing.length === 0 &&
            shipFromPhoneValidate &&
            carrier &&
            // referenceOrderNumber &&
            (uploadFile?.fileObj || uploadFile?.contactFileObj) &&
            isAbleToReturn
        ) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const handleGetRate = () => {
        const validationResult = validation();
        if (validationResult) {
            console.log('call api');
            setShippingOrderError([]);
            setShippingOrder(null);
            shippingRate();
        }
    }

    const handlePlaceOrder = () => {
        setShippingOrderError([]);
        setShippingOrder(null);
        placeOrder();
    }

    useEffect(() => {
        getPackageAccountBaseList();
    }, [])

    useEffect(() => {
        if (tabValue === 'createLabel') {
            history.push('/shipping-package/create-label');
        } else {
            return;
        }
    }, [tabValue]);

    useEffect(() => {
        // Set a timeout to show a message if loading takes more than 5 seconds
        const maxLoadingTime = 5000; // 5 seconds in milliseconds
        setTimeout(() => {
            loadingPlaceOrder ? setMessageLoading(true) : setMessageLoading(false);
        }, maxLoadingTime);
    }, [loadingPlaceOrder]);

    useEffect(() => {
        if (returnLabel && carrier) {
            const isAbleToReturn = returnLabelAccount?.some(obj => obj?.account_base?.toUpperCase() === carrier?.toUpperCase());
            setIsAbleToReturn(isAbleToReturn);
        } else {
            setIsAbleToReturn(true);
        }
    }, [carrier, returnLabel])

    // console.log('[ShippingPackageImportShipment] ship from', shipFrom);
    console.log('[ShippingPackageImportShipment] carrier', carrier);
    // console.log('[ShippingPackageImportShipment] reference order number', referenceOrderNumber);
    // console.log('[ShippingPackageImportShipment] upload file', uploadFile);
    // console.log('[ShippingPackageImportShipment] shipping order', shippingOrder);
    console.log('[ShippingPackageImportShipment] return label', returnLabel);
    console.log('[ShippingPackageImportShipment] return label account', returnLabelAccount);
    console.log('[ShippingPackageImportShipment] is able to return', isAbleToReturn);

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box style={styles.ShippingPackageCreateLabelRoot}>
                <Box sx={styles.ShippingPackageCreateLabelSection}>
                    <Typography style={styles.ShippingPackageCreateLabelHeading}>
                        {intl.get('QUICK_QUOTE_NAV.SELECT_TYPE')}
                    </Typography>
                    <Box sx={{
                        width: isMobile ? '300px' : '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        gap: '10px'
                    }}>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Checkbox
                                icon={<RadioButtonUncheckedIcon/>}
                                checkedIcon={<CheckCircleIcon/>}
                                color="success"
                                checked
                            />
                            <img src={packageIcon} alt="packageIcon" width='40px'/>
                            <Typography style={{color: '#545454', padding: '0 5px'}}>
                                {intl.get('QUICK_QUOTE_NAV.PACKAGE')}
                            </Typography>
                        </Box>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Checkbox
                                icon={<RadioButtonUncheckedIcon/>}
                                checkedIcon={<CheckCircleIcon/>}
                                color="success"
                                onClick={() => history.push('/business/shipping-package/international-shipment')}
                            />
                            <PublicIcon sx={{
                                fontSize: '40px'
                            }}/>
                            <Typography style={{color: '#545454', padding: '0 5px'}}>
                                {intl.get('QUICK_QUOTE_NAV.INTERNATION')}
                            </Typography>
                        </Box>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Checkbox
                                icon={<RadioButtonUncheckedIcon/>}
                                checkedIcon={<CheckCircleIcon/>}
                                color="success"
                                onClick={() => history.push('/business/shipping-ltl/quick-quote')}
                            />
                            <img src={ltlIcon} alt="ltlIcon" width='40px'/>
                            <Typography style={{color: '#545454', padding: '0 5px'}}>
                                {intl.get('QUICK_QUOTE_NAV.LTL')}
                            </Typography>
                        </Box>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Checkbox
                                icon={<RadioButtonUncheckedIcon/>}
                                checkedIcon={<CheckCircleIcon/>}
                                color="success"
                                onClick={() => history.push('/business/brokerage/estimate')}
                            />
                            <img src={brokerageIcon} alt="brokerageIcon" width='40px'/>
                            <Typography style={{color: '#545454', padding: '0 5px'}}>
                                {intl.get('QUICK_QUOTE_NAV.BROKERAGE')}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{borderBottom: '1px solid #609966', borderRadius: '0 25px'}}>
                    <Box sx={{width: isMobile ? '300px' : '600px'}}>
                        <StyledTabs
                            value={tabValue}
                            onChange={handleChangeTab}
                            variant='fullWidth'
                        >
                            <StyledTab label={intl.get('CREATE_LABEL_NAV.PACKAGE')} value="createLabel"/>
                            <StyledTab label={intl.get('CREATE_LABEL_NAV.EXCEL')} value="uploadExcel"/>
                        </StyledTabs>
                    </Box>
                </Box>
                <ShippingPackageImportShipmentShipFrom cbGetShipFrom={cbGetShipFrom}
                                                       validationResult={validationResult}/>
                <ShippingPackageImportShipmentUploadExcel cbGetCarrier={cbGetCarrier}
                                                          cbGetReferenceOrderNumber={cbGetReferenceOrderNumber}
                                                          cbGetUploadFile={cbGetUploadFile}
                                                          validationResult={validationResult}
                                                          cbGetReturnLabel={cbGetReturnLabel}
                                                          isAbleToReturn={isAbleToReturn}
                                                          returnLabelAccount={returnLabelAccount}/>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}>
                    <LoadingButton
                        variant='contained'
                        sx={{
                            backgroundColor: '#1D8B45',
                            "&:hover": {
                                backgroundColor: '#1D8B45',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        onClick={handleGetRate}
                        loading={loadingGetRate}
                        disabled={shippingOrder?.order_status === 1}
                    >
                        <Typography style={{
                            textTransform: 'none'
                        }}>
                            Get Shipping Rates
                        </Typography>
                    </LoadingButton>
                </Box>
                {
                    loadingGetRate ? <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <CircularProgress/>
                    </Box> : (
                        shipmentItems?.length > 0 &&
                        <>
                            <ShippingPackageImportShipmentQuoteDetails shipmentItems={shipmentItems}/>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                gap: '10px'
                            }}>
                                <Typography style={{fontWeight: '600', fontSize: '18px'}}>
                                    Total with Tax
                                </Typography>
                                <Typography style={{fontWeight: '600', fontSize: '18px'}}>
                                    $ {finalTotal?.toFixed(2)}
                                </Typography>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}>
                                <LoadingButton
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#1D8B45',
                                        "&:hover": {
                                            backgroundColor: '#1D8B45',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    onClick={handlePlaceOrder}
                                    loading={loadingPlaceOrder}
                                    disabled={errorItems?.length > 0 || shippingOrder?.order_status === 1}
                                >
                                    <Typography style={{
                                        textTransform: 'none'
                                    }}>
                                        Place Order
                                    </Typography>
                                </LoadingButton>
                            </Box>
                        </>
                    )
                }
                {
                    messageLoading &&
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <CircularProgress/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{fontSize: '16px'}}>
                                Your order is currently being processed. You can wait here to receive the results or
                                click the following button to go to the dashboard. Once processing is complete and
                                successful, you can
                                view your order under the order list on the dashboard page
                            </Typography>
                            <Grid item xs={12}>
                                <Button
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#1D8B45',
                                        "&:hover": {
                                            backgroundColor: '#1D8B45',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    onClick={() => history.push('/home')}
                                >
                                    <Typography style={{textTransform: 'none'}}>
                                        Dashboard
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                {
                    shippingOrderError?.length > 0 &&
                    <Box sx={{
                        backgroundColor: '#FFFFFF',
                        padding: '20px',
                        borderRadius: '10px',
                        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                        border: '1px solid #A9A9A9',
                        gap: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography style={{fontWeight: '600', fontSize: '20px'}}>
                                    Errors
                                </Typography>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Divider/>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography style={{textAlign: 'center'}}>
                                    Row
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography style={{textAlign: 'center'}}>
                                    Message
                                </Typography>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Divider/>
                            </Grid>
                            {
                                shippingOrderError?.map((each, index) => (
                                    <Fragment key={index}>
                                        <Grid item xs={4}>
                                            <Typography style={{textAlign: 'center'}}>
                                                # {each?.index + 1}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography style={{textAlign: 'center'}}>
                                                {each?.message}
                                            </Typography>
                                        </Grid>
                                    </Fragment>
                                ))
                            }
                        </Grid>
                    </Box>
                }
                {
                    shippingOrder && (
                        shippingOrder?.order_status === 1 ?
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography style={{fontWeight: '600', fontSize: '20px'}}>
                                        You have successfully placed an order.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={{fontWeight: '600', fontSize: '16px'}}>
                                        Order # {shippingOrder?.order_id}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant='outlined'
                                        sx={{
                                            border: '2px solid #1D8B45',
                                            "&:hover": {
                                                border: '2px solid #1D8B45',
                                                filter: 'brightness(0.9)'
                                            }
                                        }}
                                        onClick={() => history.push(`/package-order-dashboard/${shippingOrder?.order_id}`)}
                                    >
                                        <Typography style={{
                                            textTransform: 'none',
                                            color: '#1D8B45',
                                        }}>
                                            View Order Details
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid> :
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography style={{fontWeight: '600', fontSize: '20px'}}>
                                        You have fail to placed an order.
                                    </Typography>
                                </Grid>
                            </Grid>
                    )
                }
            </Box>
        </Container>
    )
}