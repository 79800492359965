import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Button, IconButton} from "@mui/material";
import Divider from "@mui/material/Divider";
import {RatingItemForm} from "./RatingItemForm";
import {useDispatch, useSelector} from "react-redux";
import {openRatingAddItemForm, toggleBoxList} from "../../actions/ratingAction";
import {RatingItemEach} from "./RatingItemEach";
import {RatingBoxList} from "./RatingBoxList";
import {RatingBoxListAdd} from "./RatingBoxListAdd";

const style = {
    RatingItemRoot: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        backgroundColor: '#F9F9F9',
        margin: '20px',
        borderRadius: '15px'
    },
    RatingItemSection: {
        display: 'flex',
        flexDirection: 'column',
        margin: '10px 20px'
    },
    RatingItemListHeader: {
        display: 'flex',
        justifyContent: 'space-between'

    }
}

export const RatingItem = () => {

    const dispatch = useDispatch();

    let openItemForm = useSelector(state => state?.rating?.openItemForm);

    let itemList = useSelector(state => state?.rating?.itemList);

    let isBoxListItem = useSelector(state => state?.rating?.isBoxListItem);

    // console.log('open add item form status', openItemForm);
    //
    // console.log('itemList', itemList);

    const handleOpenItemForm = () => {
        dispatch(openRatingAddItemForm(true));
    }

    const handleOpenBoxList = () => {
        dispatch(toggleBoxList(true));
    }

    return (

        <Box sx={style.RatingItemRoot}>
            <Box sx={style.RatingItemSection}>
                <Typography variant="h4" sx={{textAlign: 'center'}}>
                    Item You Want To Ship
                </Typography>
            </Box>
            <Box sx={style.RatingItemSection}>
                {openItemForm && <RatingItemForm/>}
            </Box>
            <Box sx={style.RatingItemSection}>
                {isBoxListItem && <RatingBoxListAdd/>}
            </Box>
            <Box sx={style.RatingItemSection}>
                <Box sx={style.RatingItemListHeader}>
                    <Typography sx={{fontSize: '25px', fontWeight: '600'}}>
                        Review your item list.
                    </Typography>
                    <Box>
                        <Button onClick={handleOpenItemForm}>
                            Add Item
                        </Button>
                        <IconButton onClick={handleOpenBoxList}>
                            <img src="/box1.png" alt="" width='25px'/>
                        </IconButton>
                    </Box>
                </Box>
                <Divider/>
                <Box>
                    {
                        itemList.length > 0 ? itemList?.map((item, index) => <RatingItemEach key={index}
                                                                                             itemDetail={item}/>) :
                            <Typography variant='h6' sx={{textAlign:'center'}}>
                                Your Item List is empty.
                            </Typography>
                    }
                </Box>
            </Box>
            <RatingBoxList/>
        </Box>

    )
}