import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
// import Slide from 'react-reveal';
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Container,
  // useMediaQuery,
  Button,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";
import produce from "immer";
// import LocalShippingIcon from "@material-ui/icons/LocalShipping";
// import { Alert } from "@material-ui/lab";
import axios from "axios";
import PropTypes from "prop-types";

import LoadingContainer from "../shared/loadingContainer";
import AddressInputCombo from "../shared/AddressInputComboBak/addressInputComboBak";
import { BACKEND_ADDRESSBOOK } from "../../Utils/apiUrl";
import { errHandling, getHeaders } from "../../Utils/fetchUtil";
import { getAddressBook } from "../../slices";
import { provinces } from "../shared";

// import {getAccessToken, getTokenFromCookie} from './Utils/doToken';
// import { useHistory } from 'react-router-dom'

const initForm = {
  address: "",
  aptInfo: "",
  zipCode: "",
  city: "",
  province: "",
  phone: "",
};

const AddressInput = ({ edit = false }) => {
  const { addressBook, addressBookLoading } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();
  // const role = useSelector(state => state.user.role);
  const classes = useStyles();
  const { id } = useParams();

  const [selectedAddress, setSelectedAddress] = useState({});

  useEffect(() => {
    const res = addressBook.find((ele) => parseInt(ele.id) === parseInt(id));
    if (res) setSelectedAddress(res);
  }, [addressBook, id]);

  // Form data to store all the information
  const [formatData, setFormatData] = useState(initForm);

  useEffect(() => {
    if (edit && selectedAddress.province) {
      setFormatData({
        ...initForm,
        address: selectedAddress.address,
        zipCode: selectedAddress.postal_code || "",
        city: selectedAddress.city,
        phone: selectedAddress.phone || "",
        aptInfo: selectedAddress.aptInfo || "",
        province: provinces.find(
          (ele) => ele.value === selectedAddress.province
        ).label,
      });
    }
  }, [edit, setFormatData, selectedAddress]);

  useEffect(() => {
    if (edit) {
      dispatch(getAddressBook());
    }
  }, [dispatch, edit]);

  const [addingAddress, setAddingStatus] = useState(false);

  const [addressAdded, setAddedStatus] = useState(false);

  const [addressAddErr, setAddErr] = useState("");
  // const matches = useMediaQuery(theme => theme.breakpoints.down('sm'));

  // Handle add or update
  const handleAdd = () => {
    setAddingStatus(true);
    const postData = {
      address: formatData.address,
      city: formatData.city,
      province: provinces.find((ele) => ele.label === formatData.province)
        .value,
      postalcode: formatData.zipCode || "",
      phone: formatData.phone,
      googleplace: "",
    };
    console.log(postData);
    if (edit) {
      if (selectedAddress.id) {
        axios({
          method: "put",
          // url: BACKEND_ADDRESSBOOK,
          url: "http://localhost:9000/address_book/",
          data: JSON.stringify({
            address_book: postData,
            id: selectedAddress.id,
          }),
          headers: {
            ...getHeaders(),
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
          },
        })
          .then((response) => {
            dispatch(getAddressBook(true));
            setAddingStatus(false);
            setAddedStatus(true);
          })
          .catch((err) => {
            errHandling(err, setAddErr);
            setAddingStatus(false);
          });
      }
    } else {
      axios({
        method: "post",
        url: BACKEND_ADDRESSBOOK,
        data: JSON.stringify({ address_book: [postData] }),
        headers: {
          ...getHeaders(),
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      })
        .then((response) => {
          dispatch(getAddressBook(true));
          setAddingStatus(false);
          setAddedStatus(true);
        })
        .catch((err) => {
          errHandling(err, setAddErr);
          setAddingStatus(false);
        });
    }
  };

  const handleChange = (fieldName) => (e) => {
    setFormatData(
      produce((draft) => {
        draft[fieldName] = e.target.value;
      })
    );
  };

  const handleChangeFromSelection = (fieldName) => (newValue) => {
    setFormatData(
      produce((draft) => {
        draft[fieldName] = newValue;
      })
    );
  };

  const handleCancel = (_history) => {
    _history.push("/address-book");
  };

  const getButtonText = (_edit) => {
    return _edit ? "Update" : "Add address";
  };

  // const token = getAccessToken("access_token");

  // const userInfo = useSelector((state) => state.user);
  // const dispatch = useDispatch();
  const history = useHistory();

  // const [latestShippingLoading, setLastestShippingLoading] = useState(true);
  // const [latestOrderData, setLatestOrderData] = useState(null);
  // const [errorMsg, setErrorMsg] = useState("");

  // const matches = useMediaQuery("(max-width:600px)");

  return addressAdded ? (
    <Box>
      <Box p={1}>
        <h1 className={classes.title}>
          {edit ? "Address has been updated." : "Address has been added."}
        </h1>
      </Box>
      <Box mt={6} display="flex" justifyContent="center">
        <Box mr={2}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => history.push("/address-book")}
            style={{
              textTransform: "unset",
            }}
          >
            Go back
          </Button>
        </Box>
        {!edit && (
          <Box ml={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setAddedStatus(false)}
              style={{
                textTransform: "unset",
              }}
            >
              Add another address
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  ) : (
    <Container>
      <h1 className={classes.title}>
        {edit ? "Edit this address" : "Add a new address"}
      </h1>
      {addressBookLoading ? (
        <LoadingContainer />
      ) : (
        <>
          {edit && (
            <Box className={classes.root} width="100%" mb={2}>
              <Box m={1} alignSelf="start">
                {selectedAddress.address} <br />
                {`${selectedAddress.city}, ${selectedAddress.province} ${selectedAddress.postal_code}`}
              </Box>
            </Box>
          )}
          <Box>
            <AddressInputCombo
              handleChange={handleChange}
              handleChangeFromSelection={handleChangeFromSelection}
              addressObject={formatData}
              userLocation={
                edit
                  ? {
                      street: selectedAddress.address,
                      city: selectedAddress.city,
                      zipCode: selectedAddress.postal_code,
                    }
                  : null
              }
            />
          </Box>
          <Box mt={6} display="flex" justifyContent="center">
            <Box mr={2}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleCancel(history)}
                style={{
                  textTransform: "unset",
                  width: "8rem",
                }}
              >
                Cancel
              </Button>
            </Box>
            <Box ml={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAdd}
                style={{
                  textTransform: "unset",
                  width: "8rem",
                }}
              >
                {addingAddress ? (
                  <CircularProgress size={24} style={{ color: "black" }} />
                ) : (
                  getButtonText(edit)
                )}
              </Button>
            </Box>
          </Box>
        </>
      )}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={Boolean(addressAddErr)}
        onClose={() => setAddErr("")}
        message={addressAddErr}
        autoHideDuration={3000}
      />
    </Container>
  );
};

const useStyles = makeStyles({
  title: {
    fontFamily: "Montserrat",
    fontSize: "2rem",
    letterSpacing: "2px",
    color: "green",
    marginTop: "2vh",
  },
  root: {
    backgroundColor: "white",
    borderRadius: 5,
  },
  lgRoot: {
    minHeight: 300,
    margin: 10,
    marginLeft: 0,
    marginTop: 20,
    // width: '90%',
    width: "100%",
  },
  pkgImg: {
    margin: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  pkgWrapper: {},
  orderNum: {
    fontSize: "1rem",
    color: "seagreen",
  },
});

AddressInput.propTypes = {
  userAddress: PropTypes.object,
  edit: PropTypes.bool,
  setEditObj: PropTypes.func,
};

export default AddressInput;
