import React, {useEffect, useState} from "react";
import {Redirect, Route, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";

import {deleteToken, getAccessToken} from "../Utils/doToken";
import axios from "axios";
import {BACKEND_ME_API} from "../Utils/apiUrl";
import {LoadingContainer} from "./shared";
import LandingPage from "./LandingPage/landingPage";
// import { render } from "@testing-library/react";

const UserRouter = ({
                        component: Component,
                        path: Path,
                        render,
                        landing = false,
                        ...rest
                    }) => {
    const dataLoaded = useSelector((state) => state.user.dataLoaded);
    const role = useSelector((state) => state.user.role);
    const userInfo = useSelector(state => state?.user);
    const ownerRole = useSelector(state => state?.user?.ownerRole);
    const {pathname} = useLocation();
    const startsWithBusiness = pathname.startsWith('/business');
    const guestUser = Boolean(getAccessToken("access_token")) === false;

    const isLandingPage = landing;

    const isUser = dataLoaded && role === "End User";

    const isDriver = dataLoaded && role === "Driver";

    const [loading, setLoading] = useState(true);

    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const token = getAccessToken("access_token") // or read token from cookie

        if (token) {
            // Make an API call to validate the token
            axios.get(BACKEND_ME_API, {
                headers: {Authorization: `Bearer ${token}`},
            })
                .then(() => {
                    // Token is valid, set the isAuthenticated state to true
                    setIsAuthenticated(true);
                })
                .catch((error) => {
                    if (error.response.status !== 401) {
                        deleteToken('access_token')
                    }
                    // Token is not valid, remove it from local storage and redirect to login page
                    setIsAuthenticated(false);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            // Token is not present, set the isAuthenticated state to false
            setIsAuthenticated(false);
            setLoading(false);
        }
    }, []);

    // const routerContent = (
    //     _guestUser,
    //     _isLandingPage,
    //     _isUser,
    //     _isDriver,
    //     _dataLoaded,
    //     _render,
    //     _props
    // ) => {
    //
    //     if (loading) {
    //         // Show a loading indicator while the token is being validated
    //         return <LoadingContainer/>
    //     }
    //
    //     if (_guestUser && !_isLandingPage) {
    //         return (
    //             <Redirect
    //                 to={{
    //                     pathname: "/sign-in",
    //                     state: {from: _props.location},
    //                 }}
    //             />
    //         );
    //     }
    //
    //     if (_isDriver && !_guestUser && _dataLoaded) {
    //         if (Path === "/profile/add-card") {
    //             return <Component {..._props} />;
    //         } else {
    //             return (
    //                 <Redirect
    //                     to={{
    //                         pathname: "/driver",
    //                     }}
    //                 />
    //             );
    //         }
    //     }
    //
    //     if (!_isDriver && !_isUser && !_guestUser && _dataLoaded) {
    //         return (
    //             <Redirect
    //                 to={{
    //                     // pathname: "/profile",
    //                     pathname: "/",
    //                 }}
    //             />
    //         );
    //     }
    //
    //     // if (!role && Boolean(getAccessToken("access_token"))) {
    //     //     return (
    //     //         <Redirect
    //     //             to={{
    //     //                 pathname: "/",
    //     //             }}
    //     //         />
    //     //     )
    //     // }
    //     return _render ? _render() : <Component {..._props} />;
    // };

    console.log('[UserRouter] userInfo', userInfo);
    console.log('[UserRouter] ownerRole', ownerRole);
    console.log('[UserRouter] pathname', pathname);
    console.log('[UserRouter] startsWithBusiness', startsWithBusiness);

    if (loading) {
        return <LoadingContainer/>
    }

    if ((ownerRole === 3 && !startsWithBusiness) || (ownerRole === 1 && startsWithBusiness)) {
        return <Redirect to={"/"}/>
    }

    return (
        <Route
            {...rest}
            path={Path}
            render={(props) =>
                isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/sign-in',
                            state: {from: props.location}
                        }}
                    />
                )
            }
        />
    );
};
export default UserRouter;
