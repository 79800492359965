import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UPSLogo from "../../images/UPS.png";
import CanadaPostLogo from "../../images/CanadaPost.png";
import CanparLogo from "../../images/Canpar.png";
import FedExLogo from "../../images/FedEx.png";
import {Button, IconButton, InputAdornment, MenuItem, Select} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {useHistory} from "react-router-dom";
import {LatestShippingOrderTable} from "./LatestShippingOrderTable";
import {makeStyles} from "@material-ui/core";
import Grid from "@mui/material/Grid";
import SearchIcon from "@material-ui/icons/Search";
import {getAccessToken} from "../../Utils/doToken";
import {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import {Pagination} from "@mui/lab";
import intl from "react-intl-universal";
import FormControl from "@mui/material/FormControl";
import {LatestMovingOrderTable} from "./LatestMovingOrderTable";
import welcomeDashboardImage from "../../images/WelcomeToDashboard.png";

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '35vh',
        height: '100%'
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        height: '100%',
    },
}

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
});

export const LatestShippingOrder = () => {

    const history = useHistory();
    const storedToken = getAccessToken("access_token");
    const classes = useStyles();

    const [type, setType] = useState('package');
    const [searchString, setSearchString] = useState(null);
    const [orderData, setOrderData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const handleChange = (event) => {
        console.log(event.target.value);
        setType(event.target.value);
    };

    const handleSearchString = (event) => {
        setSearchString(event.target.value);
    }

    const getPackageOrderList = async () => {
        setLoading(true);
        const requestURL = `${NODE_ROUTE_URI}/endUserShippingOrderList/searchEndUserBackendOrderShippingOrder`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                data: {
                    queryString: searchString,
                    page: 0,
                    pageSize: 20,
                    statusArray: [0, 1, 2, 3]
                }
            });
            console.log('[shipping order list package] - result', result);
            setOrderData(result?.data?.result);
            setCurrentPage(result?.data?.pagination?.currentPage + 1);
            setLastPage(result?.data?.pagination?.lastPage + 1);
            setError(false);
            setLoading(false);
        } catch (e) {
            console.log(e.response);
            setError(true);
            setLoading(false);
        }
    }

    const getPackageOrderListByPage = async (page) => {
        setLoading(true);
        const requestURL = `${NODE_ROUTE_URI}/endUserShippingOrderList/searchEndUserBackendOrderShippingOrder`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                data: {
                    queryString: searchString,
                    page: page,
                    pageSize: 20,
                    statusArray: [0, 1, 2, 3]
                }
            });
            console.log('[shipping order list package] - result', result);
            setOrderData(result?.data?.result);
            setError(false);
            setLoading(false);
        } catch (e) {
            console.log(e.response);
            setError(true);
            setLoading(false);
        }
    }

    const getMovingOrderList = async () => {
        setLoading(true);
        const requestURL = `${NODE_ROUTE_URI}/movingService/getMovingOrderList`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    queryString: searchString,
                    page: 0,
                    pageSize: 20,
                    statusId: [0, 100, 1],
                    // shippingStatusId
                }
            });
            console.log('[getMovingOrderList] - result', result);
            setOrderData(result?.data?.data);
            setError(false);
            setCurrentPage(result?.data?.pagination?.currentPage);
            setLastPage(result?.data?.pagination?.lastPage);
        } catch (e) {
            console.log('error', e?.response);
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    const getMovingOrderListByPage = async (page) => {
        setLoading(true);
        const requestURL = `${NODE_ROUTE_URI}/movingService/getMovingOrderList`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    queryString: searchString,
                    page: page,
                    pageSize: 20,
                    statusId: [0, 100, 1],
                    // shippingStatusId
                }
            });
            console.log('[shipping order list package] - result', result);
            setOrderData(result?.data?.data);
            setError(false);
            setLoading(false);
        } catch (e) {
            console.log(e.response);
            setError(true);
            setLoading(false);
        }
    }

    const handleSearchShippingOrder = () => {
        if (type === 'package') {
            getPackageOrderList();
        } else {
            getMovingOrderList();
        }
    }

    const handleSearchShippingOrderByKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (type === 'package') {
                getPackageOrderList();
            } else {
                getMovingOrderList();
            }
        }
    }

    const handlePageChange = (event, value) => {
        console.log('page change', value);
        setCurrentPage(value);
        const page = value - 1;
        if (type === 'package') {
            getPackageOrderListByPage(page);
        } else {
            getMovingOrderListByPage(page);
        }
    };

    useEffect(() => {
        if (type === 'package') {
            (async () => {
                await getPackageOrderList();
            })()
        } else {
            (async () => {
                await getMovingOrderList();
            })()
        }
    }, [type])

    console.log('order data', orderData);

    return (
        <Box sx={styles.ShippingOrderRoot}>
            <Typography style={{fontSize: '16px', fontWeight: 600, textAlign: 'left'}}>
                {intl.get('HOME_PAGE.SHIPPING_ORDER.SHIPPING_ORDER')}
            </Typography>
            <Box sx={styles.ShippingOrderContent}>
                <Grid container spacing={2}>
                    <Grid item md={9} xs={12}>
                        <Grid container spacing={0}>
                            <Grid item md={10} xs={8}>
                                <TextField
                                    value={searchString}
                                    fullWidth
                                    size='small'
                                    onInput={handleSearchString}
                                    onKeyDown={handleSearchShippingOrderByKeyDown}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon/>
                                            </InputAdornment>
                                        ),
                                    }}
                                    classes={{root: classes.customTextField}}
                                    // label='Order# / REF# / Tracking# / Name / Email / Address / Phone'
                                    placeholder={intl.get('HOME_PAGE.SHIPPING_ORDER.SEARCH_PLACEHOLDER')}
                                />
                            </Grid>
                            <Grid item md={2} xs={4}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '100%',
                                }}>
                                    <Button
                                        variant='contained'
                                        sx={{
                                            backgroundColor: '#1D8B45',
                                            color: '#FFFFFF',
                                            height: '100%',
                                            borderRadius: '5px',
                                            padding: '0 20px',
                                            "&:hover": {
                                                backgroundColor: '#1D8B45',
                                                color: '#FFFFFF',
                                                filter: 'brightness(0.9)'
                                            },
                                        }}
                                        // size='small'
                                        onClick={handleSearchShippingOrder}
                                    >
                                        <Typography
                                            style={{textTransform: 'none', fontSize: '16px'}}
                                        >
                                            {intl.get('HOME_PAGE.SHIPPING_ORDER.SEARCH')}
                                        </Typography>
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={3} xs={6}>
                        <FormControl fullWidth>
                            <Select
                                value={type}
                                size='small'
                                placeholder='Status'
                                onChange={handleChange}
                            >
                                <MenuItem disabled value='type' sx={{
                                    fontSize: '14px',    // Adjust the desired text size
                                }}>
                                    <em>Type</em>
                                </MenuItem>
                                <MenuItem value='package' sx={{
                                    fontSize: '14px',    // Adjust the desired text size
                                }}>Package</MenuItem>
                                <MenuItem value='transportation' sx={{
                                    fontSize: '14px',    // Adjust the desired text size
                                }}>Moving</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                {
                    loading ?
                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                            <CircularProgress/>
                        </Box>
                        :
                        error ?
                            <Box sx={styles.ShippingOrderContent}>
                                <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                    {intl.get('HOME_PAGE.ERROR')}
                                </Typography>
                            </Box>
                            :
                            orderData?.length === 0 ?
                                <Box sx={styles.ShippingOrderContent}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                                {intl.get('HOME_PAGE.SHIPPING_ORDER.NO_RESULTS')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'center'
                                            }}>
                                                <img src={welcomeDashboardImage} alt="welcome_image" width='300px'/>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}>
                                                <Typography sx={{
                                                    textAlign: 'center', width: '45%'
                                                }}>
                                                    {intl.get('HOME_PAGE.SHIPPING_ORDER.CONTENT')}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box sx={{
                                                display: 'center',
                                                justifyContent: 'center'
                                            }}>
                                                <Button
                                                    variant='contained'
                                                    sx={{
                                                        backgroundColor: '#1D8B45',
                                                        "&:hover": {
                                                            backgroundColor: '#1D8B45',
                                                            filter: 'brightness(0.9)'
                                                        }
                                                    }}
                                                    onClick={() => {
                                                        type === 'package' ? history.push('/shipping-package/create-label') :
                                                            history.push('/transportation/express')
                                                    }}
                                                >
                                                    <Typography sx={{textTransform: 'none'}}>
                                                        {type === 'package' ? intl.get('HOME_PAGE.SHIPPING_ORDER.SEND_PARCEL') : intl.get('HOME_PAGE.SHIPPING_ORDER.SCHEDULE_MOVING')}
                                                    </Typography>
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box> :
                                type === 'package' ?
                                    <Box sx={styles.ShippingOrderContent}>
                                        <LatestShippingOrderTable order={orderData}/>
                                        <Pagination
                                            count={lastPage}
                                            page={+currentPage}
                                            size='small'
                                            onChange={handlePageChange}
                                        />
                                    </Box>
                                    :
                                    <Box sx={styles.ShippingOrderContent}>
                                        <LatestMovingOrderTable order={orderData}
                                                                getMovingOrderList={getMovingOrderList}/>
                                        <Pagination
                                            count={lastPage}
                                            page={+currentPage}
                                            size='small'
                                            onChange={handlePageChange}
                                        />
                                    </Box>
                }
            </Box>
        </Box>
    )
}