// adder
export function add(num1, num2) {
  if (!num1 || !num2) return null;
  const num1Digits = (num1.split(".")[1] || "").length;
  const num2Digits = (num2.split(".")[1] || "").length;
  const baseNum = Math.pow(10, Math.max(num1Digits, num2Digits));
  return (
    (parseFloat(num1) * baseNum + parseFloat(num2) * baseNum) /
    baseNum
  ).toFixed(2);
}
