import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { makeStyles, Typography, Grid, Divider, Box } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import MarkunreadMailboxIcon from "@material-ui/icons/MarkunreadMailbox";
import WidgetsIcon from "@material-ui/icons/Widgets";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import FindInPageIcon from "@material-ui/icons/FindInPage";
// import { setNewLocation } from "../actions/locationActions";
import axios from "axios";
// import { connect } from "react-redux";
import ShipmentsLayout from "./Layouts/ShipmentsLayout";
import {
  GET_AVAILABLE_SHIPMENTS,
  GET_DRIVER_LOADED_SHIPMENTS,
} from "../Utils/apiUrl";
import {
  USER_CURRENT_CITY_COOKIE_NAME,
  USER_CURRENT_ADDRESS_COOKIE_TIME,
} from "../Utils/config";
import { getTokenFromCookie, getAccessToken } from "../Utils/doToken";
import Calculator from "./Images/icons/calculator";
import { getHeaders, getURLParams, errHandling } from "../Utils/fetchUtil";

const useStyles = makeStyles({
  driverLandingPage: {
    margin: "0 auto",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  cardWrapper: {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
    paddingLeft: "1%",
    justifyContent: "flex-start",
    "@media (max-width: 600px)": {
      justifyContent: "space-evenly;",
    },
  },
  cardContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: 150,
    height: "6rem",
    backgroundColor: "white",
    borderRadius: 5,
    cursor: "pointer",
    textAlign: "center",
    padding: "10px",
    "&:hover": {
      backgroundColor: "orange",
      color: "white",
    },
  },
  userName: {
    fontFamily: "Montserrat",
    fontSize: "2rem",
    letterSpacing: "2px",
    color: "green",
    marginTop: "2vh",
    marginLeft: "1%",
  },
  personalCardContent: {
    border: "1px solid rgba(0,0,0,0.3)",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "20px",
    padding: "7px",
    "&:hover": {
      color: "white",
      textDecoration: "none",
      transactionProperty: "color",
      transitionDuration: "0.5s",
    },
  },
  personalCard: {
    width: "250px",
    backgroundColor: "#FEEAAE",
    // minWidth: "155px",
    "&:hover": {
      backgroundColor: "#DFA801",
      transitionProperty: "background-color",
      transitionDuration: "0.5s",
    },
  },
  topWrapper: {
    width: "100%",
    margin: "0 auto",
    maxWidth: "700px",
  },
});

const DriverLandingPage = ({ location }) => {
  // const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const userName = useSelector((state) => state.user.firstname);
  const getCityTimer = useRef(null);
  const [shipments, setShipments] = useState([]);
  const fetchedRandomShipment = useRef(false);

  const [warnShipments, setWarnShipments] = useState([]);

  const [warnShipmentsLoading, setWarnShipmentsLoading] = useState(true);
  const [randomShipmentsLoading, setRandomShipmentsLoading] = useState(true);

  const [recommendErrorMsg, setRecommendErrorMsg] = useState("");
  const [warnShipmentsErrorMsg, setWarnShipmentsErrorMsg] = useState("");

  useEffect(() => {
    if (!getCityTimer.current) {
      getCityTimer.current = setTimeout(() => {
        //fetch shipments timeout, get random shipment task
        console.log("get city time is up, get random shipment tasks");
        getRandomShipments();
      }, 1000 * 5);
    }

    const setCookie = (name, value) => {
      let date = new Date();
      date.setTime(date.getTime() + USER_CURRENT_ADDRESS_COOKIE_TIME);
      const expires = "expires=" + date.toUTCString();
      document.cookie =
        name + "=" + JSON.stringify(value) + "; " + expires + "; path=/";
    };

    const successFunc = (position) => {
      localStorage["authorizedGeoLocation"] = 1;
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?&latlng=${position.coords.latitude},${position.coords.longitude}&key=AIzaSyDxbjdueUQWI1WFfiEviIkvspt8S95L-bQ&result_type=street_address`
        )
        .then((res) => {
          if (res.data?.results.length > 0) {
            const results = res.data.results;
            for (var i = 0; i < results[0].address_components.length; i++) {
              for (
                var b = 0;
                b < results[0].address_components[i].types.length;
                b++
              ) {
                //there are different types that might hold a city admin_area_lvl_1 usually does in come cases looking for sublocality type will be more appropriate
                if (results[0].address_components[i].types[b] === "locality") {
                  const city = results[0].address_components[i].long_name;

                  setCookie(USER_CURRENT_CITY_COOKIE_NAME, {
                    city,
                  });
                  if (getCityTimer.current) {
                    clearTimeout(getCityTimer.current);
                  }
                  getRandomShipments(city);

                  break;
                }
              }
            }
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    };

    const errFunc = () => {
      localStorage["authorizedGeoLocation"] = 0;
    };

    const current_city_cache = getAccessToken(USER_CURRENT_CITY_COOKIE_NAME);

    if (window.navigator.geolocation) {
      //should always get current city based on driver's location
      navigator.geolocation.getCurrentPosition(successFunc, errFunc);
    } else {
      //can not get location
      if (getCityTimer.current) {
        clearTimeout(getCityTimer.current);
      }
      if (current_city_cache) {
        const { city } = JSON.parse(current_city_cache);
        console.log(city);
        getRandomShipments(city);
      } else {
        getRandomShipments();
      }
    }

    return () => {
      if (getCityTimer.current) {
        clearTimeout(getCityTimer.current);
      }
    };
  }, []);

  const getRandomShipments = (city = "") => {
    console.log("city", city);
    setRandomShipmentsLoading(true);

    const insertData =
      city !== undefined && city !== ""
        ? {
            page: 1,
            pickup_cities: [city.toString()],
          }
        : {
            page: 1,
          };
    axios
      .post(GET_AVAILABLE_SHIPMENTS, insertData, {
        // params:
        //   city !== undefined && city !== ""
        //     ? {
        //         page: 1,
        //         pickup_cities: [city.toString()],
        //       }
        //     : {
        //         page: 1,
        //       },
        headers: {
          Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
        },
      })
      .then((res) => {
        // if (res.data?.data?.length === 0) {
        if (res.data?.taskList?.length === 0) {
          if (!fetchedRandomShipment.current) {
            fetchedRandomShipment.current = true;
            getRandomShipments();
          } else {
            setShipments([]);
            return setRandomShipmentsLoading(false);
          }
        }
        // setShipments(res.data.data);
        setShipments(res.data?.taskList || []);

        setRandomShipmentsLoading(false);
      })
      .catch((err) => {
        errHandling(err, setRecommendErrorMsg);
        setRandomShipmentsLoading(false);
      });
  };

  useEffect(() => {
    axios
      .get(
        getURLParams(GET_DRIVER_LOADED_SHIPMENTS, {
          deliver_appointment_date: "n",
          option_deliver_appointment: "y",
        }),
        { headers: getHeaders() }
      )
      .then((res) => {
        setWarnShipmentsLoading(false);
        setWarnShipments(res.data.data);
      })
      .catch((err) => {
        errHandling(err, setWarnShipmentsErrorMsg);
        setWarnShipmentsLoading(false);
      });
  }, []);

  return (
    <>
      <div className={classes.driverLandingPage}>
        <Box className={classes.topWrapper}>
          <Box ml={1} mr={1}>
            <h1 align="left" className={classes.userName}>
              Hello, {userName}
            </h1>
            <div className={classes.cardWrapper}>
              <Grid item xs={12}>
                <Box
                  className={classes.personalCard}
                  onClick={() => history.push("/profile")}
                >
                  <Box className={classes.personalCardContent}>
                    <AccountCircleIcon />
                    <Typography>My account</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  className={classes.personalCard}
                  onClick={() => history.push("/driver/bookkeeper")}
                >
                  <Box className={classes.personalCardContent}>
                    <Calculator width="26" fill="currentColor" />
                    <Typography>My Bookkeeper</Typography>
                  </Box>
                </Box>
              </Grid>
            </div>
          </Box>
        </Box>
        <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />

        <Grid container className={classes.gridContainer} alignItems="stretch">
          <Box className={classes.topWrapper}>
            <div className={classes.cardWrapper}>
              <Box
                className={classes.card}
                onClick={() => history.push("/driver/available_shipment_tasks")}
              >
                <Box className={classes.cardContent}>
                  <SearchIcon />
                  <Typography>Search Shipping Tasks</Typography>
                </Box>
              </Box>
              <Box
                className={classes.card}
                onClick={() => history.push("/driver/claimed_shipment_tasks")}
              >
                <Box className={classes.cardContent}>
                  <WidgetsIcon />
                  <Typography>My shipping task</Typography>
                </Box>
              </Box>
              <Box
                className={classes.card}
                onClick={() => history.push("/driver/pickup_shipment_tasks")}
              >
                <Box className={classes.cardContent}>
                  <LocalShippingIcon />
                  <Typography>Go to Pickup</Typography>
                </Box>
              </Box>
              <Box
                className={classes.card}
                onClick={() => history.push("/driver/deliver_shipment_tasks")}
              >
                <Box className={classes.cardContent}>
                  <MarkunreadMailboxIcon />
                  <Typography>Go to Deliver</Typography>
                </Box>
              </Box>
              <Box
                className={classes.card}
                onClick={() => history.push("/cargo-control-tracking/query")}
              >
                <Box className={classes.cardContent}>
                  <FindInPageIcon />
                  <Typography>Cargo Control Tracking</Typography>
                </Box>
              </Box>
            </div>
            <Grid item xs={12}>
              <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
              <ShipmentsLayout
                shipments={warnShipments}
                loading={warnShipmentsLoading}
                title="Need appointment reminder:"
                noResult="-- No shipment needs appointment --"
                errMsg={warnShipmentsErrorMsg}
                reminder
              />
            </Grid>
            <Grid item xs={12}>
              <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
              <ShipmentsLayout
                loading={randomShipmentsLoading}
                shipments={shipments}
                errMsg={recommendErrorMsg}
                title="Recommended shipments for you:"
                noResult="-- No nearby shipment found --"
              />
            </Grid>
          </Box>
        </Grid>
      </div>
    </>
  );
};

export default DriverLandingPage;
