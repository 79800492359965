import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import {styled} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import WechatPaymentInvoiceDialog from "./WechatPaymentInvoiceDialog";
import {CreditCardPaymentInvoiceDialog} from "./CreditCardPaymentInvoiceDialog";

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const {children, onClose, ...other} = props;

    return (
        <DialogTitle sx={{m: 0, p: 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                ></IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function PaymentDialog({
                                          open,
                                          handleClose,
                                          actionType,
                                          singleRowInvoice,
                                      }) {
    {
        switch (actionType) {
            case "E-transfer":
                return (
                    <BootstrapDialog
                        onClose={handleClose}
                        aria-labelledby="customized-dialog-title"
                        open={open}
                    >
                        <BootstrapDialogTitle
                            id="customized-dialog-title"
                            onClose={handleClose}
                        >
                            {actionType}
                        </BootstrapDialogTitle>
                        <DialogContent dividers>
                            <Typography>Please e-Transfer to pay@uucargo.ca</Typography>
                            <Typography>{`Message should be: #${singleRowInvoice.InvoiceNumber}`}</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={handleClose}>
                                Done
                            </Button>
                        </DialogActions>
                    </BootstrapDialog>
                );
            case "WeChat":
                return (
                    <WechatPaymentInvoiceDialog open={open} handleClose={handleClose}
                                                crmInvoiceNumber={singleRowInvoice.invoiceId}
                                                crmRefNumber={singleRowInvoice.InvoiceNumber}/>
                );
            case "Card":
                return (
                    <CreditCardPaymentInvoiceDialog open={open} handleClose={handleClose}
                                                    crmInvoiceNumber={singleRowInvoice.invoiceId}
                                                    crmRefNumber={singleRowInvoice.InvoiceNumber}/>
                );
            default:
                return <BootstrapDialog></BootstrapDialog>;
        }
    }
}
