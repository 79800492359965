import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
    Button,
    IconButton,
    Link,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel
} from "@mui/material";
import Grid from "@mui/material/Grid";
import {getAccessToken} from "../../Utils/doToken";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {useEffect, useState} from "react";
import Divider from "@mui/material/Divider";

import {OrderDashBoardTransactionsEach} from "./OrderDashBoardTransactionsEach";

const style = {
    OrderDashBoardTransactionsRoot: {
        width: '47vw',
        backgroundColor: '#FFFFFF',
        height: '400px',
        overflow: 'scroll'
    },
    OrderDashBoardTransactionsTable: {
        minWidth: '800px'
    }
}

const headCells = [
    {
        id: "icon",
        numeric: false,
        // disablePadding: true,
        label: "",
        width: '5%'
    },
    {
        id: "tranId",
        numeric: false,
        // disablePadding: true,
        label: "Tran Id",
        width: '15%'
    },
    {
        id: "sendTo",
        numeric: false,
        // disablePadding: false,
        label: "Send To Info",
        width: '25%'
    },
    {
        id: "price",
        numeric: false,
        // disablePadding: false,
        label: "Price",
        width: '25%'
    },
    {
        id: "trackingNumbers",
        numeric: false,
        // disablePadding: false,
        label: "Tracking #",
        width: '30%'
    },
];

export const OrderDashBoardTransactions = ({orderId}) => {

    const accessToken = getAccessToken("access_token");
    const [transactions, setTransactions] = useState();

    const getTransactionsListByOrderId = async () => {
        let requestURL = `${NODE_ROUTE_URI}/shippingOrderDetailsDashboard/endUser/getTransactionsByOrderId`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                params: {
                    orderId: orderId
                }
            })
            setTransactions(result.data.result);
        } catch (e) {
            console.log(e.response)
        }
    }



    useEffect(() => {
        getTransactionsListByOrderId();
    }, [])



    console.log('transactions', transactions);
    // console.log('tracking list', trackingList);

    return (

        <Box sx={style.OrderDashBoardTransactionsRoot}>
            <Typography variant="h5" style={{textAlign: 'center'}}>Shipping Transactions</Typography>
            <TableContainer sx={style.OrderDashBoardTransactionsTable}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => (
                                <TableCell
                                    key={headCell.id}
                                    align={headCell.numeric ? "right" : "left"}
                                    padding={headCell.disablePadding ? "none" : "normal"}
                                    style={{fontWeight: "bold", width: headCell.width}}
                                >
                                    {headCell.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            transactions?.map((transactionsEach, index) => <OrderDashBoardTransactionsEach transactionsEach={transactionsEach} key={index}/>)
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>

    )
}