import {
    UPDATE_SEARCH_FILTER,
    FETCH_SEARCH_FILTER_STATIC, REFRESH_ORDERS_IN_SEARCH_PAGE,
} from "../actions/types";
import produce from "immer";

const initialState = {
    area: "",
    localFreight: "y",
    remoteFreight: "n",
    pickupDateFrom: "",
    pickupDateTo: "",
    unitPrice: [null, null],
    totalPrice: [null, null],
    pickupCity: [],
    deliverCity: [],
    shipmentSize: [0],
    static: null,
    pickupLoadingDock: null,
    deliverLoadingDock: null,
    deliverAppointment: null,
    isRefresh: false,
};

export const searchFilterSettingReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_SEARCH_FILTER:
            return {
                ...state,
                area: action.payload.area,
                localFreight: action.payload.localFreight,
                remoteFreight: action.payload.remoteFreight,
                unitPrice: action.payload.unitPrice,
                totalPrice: action.payload.totalPrice,
                shipmentSize: action.payload.shipmentSize,
                pickupCity: action.payload.pickupCity,
                deliverCity: action.payload.deliverCity,
                pickupDateFrom: action.payload.pickupDateFrom,
                pickupDateTo: action.payload.pickupDateTo,
                pickupLoadingDock: action.payload.pickupLoadingDock,
                deliverLoadingDock: action.payload.deliverLoadingDock,
                deliverAppointment: action.payload.deliverAppointment,
            };
        case FETCH_SEARCH_FILTER_STATIC:
            return produce(state, (draft) => {
                //initial unit price, total price values
                draft.unitPrice = [
                    action.payload.min_unit_price,
                    action.payload.max_unit_price,
                ];
                draft.totalPrice = [
                    action.payload.min_total_price,
                    action.payload.max_total_price,
                ];
                //set static
                draft.static = action.payload;
            });

        case REFRESH_ORDERS_IN_SEARCH_PAGE:
            return {...state, isRefresh: action?.payload}
        default:
            return state;
    }
};
