import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {
    TextField,
    Button,
    Box,
    CircularProgress,
    Dialog,
    IconButton,
    Switch,
    RadioGroup,
    FormControlLabel,
    Radio,
    Collapse,
    Snackbar,
    Input, DialogTitle, DialogContent, DialogContentText, DialogActions,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Alert} from "@material-ui/lab";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ClearIcon from "@material-ui/icons/Clear";
import axios from "axios";
import {
    LIST_CREDIT_CARD_API,
    NODE_ROUTE_URI,
} from "../../Utils/apiUrl";
import CurrencyFormat from "react-currency-format";
import ReviewOrderHeader from "./reviewOrderHeader";
import {getAccessToken} from "../../Utils/doToken";
import PaymentsAccordion from "./PaymentsAccordion";
import ShippingOrderListTable from "./ShippingOrderListTable";
import {useInterval} from "usehooks-ts";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import {errHandling, getHeaders} from "../../Utils/fetchUtil";
import AddCreditCardSection from "./AddCreditCardSection";

const numberExceed = (num, limit) => {
    return parseFloat(num) > limit;
};

// Different Text for different place order condition
const placeOrderText = (error) => {
    return error ? "Submit" : "Confirm Order";
};

const accountOptionsValue = {
    "UPS-Richmond-BC": "ups-richmond-bc",
    "UPS-Toronto-ON": "ups-toronto-on",
    "UPS-Montreal-QC": "ups-montreal-qc",
    "FEDEX-Richmond-BC": "fedex-richmond-bc",
    "FEDEX-Toronto-ON": "fedex-toronto-on",
    "FEDEX-Montreal-QC": "fedex-montreal-qc",
    "FEDEX-Calgary-AB": "fedex-calgary-ab",
    "UPS-Calgary-AB": "ups-calgary-ab",
    "UPS-UUC-Brokerage": "ups-uuc-brokerage"
};

const useQuery = () => {
    const {search} = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ShippingOrderDetailsPage = ({
                                      // pallets,
                                      // pickUpDetails,
                                      // isLooseItem,
                                      // selectedLooseItem,
                                      // companyName,
                                      // serviceName,
                                      // accountBaseCombinedName,
                                      // priceText,
                                      // cargoControlNumber,
                                      // refOrderNumber,
                                      // shippingItems,
                                      // ratingPrice,
                                      // selectedAccountBaseCombinedName,
                                      // serviceType
                                  }) => {
    const dispatch = useDispatch();
    // const pallets = useSelector((state) => state.shipping.shippingItems);
    // const pickUpDetails = useSelector((state) => state.shipping.pickUpDetails);
    const userEmail = useSelector((state) => state.user.email);
    const id = useSelector((state) => state.user.id);


    const {orderId} = useParams();

    const [sampleLabel, setSampleLabel] = useState("");
    const [creatingOrder, setCreatingStatus] = useState(false);

    const [cardLoading, setCardLoading] = useState(true);
    const [cardInfo, setCardInfo] = useState(null);

    const [cardErrMsg, setCardErrMsg] = useState("");
    const cardStatus = useSelector((state) => state.user.activeCard);
    const [selectedCard, setSelectedCard] = useState({});


    const [dialogOpen, setDialogOpen] = useState(false);
    const [reload, setReload] = useState(false);
    // const [sendingEmail, setSendingStatus] = useState(false);

    const [emailSentSuccess, setEmailSentSuccessState] = useState(false);
    const [emailSentFail, setEmailSentFailState] = useState(false);

    const [toastOpen, setToastOpen] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [cancelDiaglogOpen, setCancelDialogOpen] = useState(false);
    const [placeOrderLoading, setPlaceOrderLoading] = useState(false);
    const [cancelOrderLoading, setCancelOrderLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [orderNumber, setOrderNumber] = useState("");
    const [pickupName, setpickupName] = useState("");
    const [pickupPhone, setPickupPhone] = useState("");
    const [pickupAddress, setPickupAddress] = useState("");
    const [pickupAddressTwo, setPickupAddressTwo] = useState("");
    const [pickupCity, setPickupCity] = useState("");
    const [pickupProvince, setPickupProvince] = useState("");
    const [pickupPostalCode, setPickupPostalCode] = useState("");
    const [deliverAddress, setDeliverAddress] = useState("");
    const [deliverAddressTwo, setDeliverAddressTwo] = useState("");
    const [deliverProvince, setDeliverProvince] = useState("");
    const [deliverPhone, setDeliverPhone] = useState("");
    const [deliverName, setDeliverName] = useState("");
    const [deliverCity, setDeliverCity] = useState("");
    const [deliverPostalCode, setDeliverPostalCode] = useState("");
    const [pallets, setPallets] = useState([]);
    const [companyName, setCompanyName] = useState("");
    const [serviceName, setServiceName] = useState("");
    const [finalTotalAmount, setFinalTotalAmount] = useState(0);
    const [shippingFee, setShippingFee] = useState(0);
    const [serviceFeeTax, setServiceFeeTax] = useState(0);
    const [crossBorderFee, setCrossBorderFee] = useState(0);
    const [delay, setDelay] = useState(2000);

    const [backendOrderStatus, setBackendOrderStatus] = useState(-1);

    // Shipping Order Details
    const [shippingOrderInfo, setShippingOrderInfo] = useState({
        transactions: []
    });

    const getOrderStatus = async () => {
        try {
            const accessToken = getAccessToken("access_token");
            const {data} = await axios.get(`${NODE_ROUTE_URI}/looseItem/getBackendOrderStatusByOrderId/${orderId}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            console.log(data);
            setBackendOrderStatus(data.orderStatus);
        } catch (e) {
            setBackendOrderStatus(0);
            console.log(e);
        }
    }

    const getShippingOrderInfo = async () => {
        try {
            const accessToken = getAccessToken("access_token");
            const {data} = await axios.get(`${NODE_ROUTE_URI}/looseItem/getShippingOrderInfoByBackendOrderId/${orderId}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            console.log("shipping order info: ", data);
            const result = data.result;
            if (!result) {
                setShippingOrderInfo({
                    transactions: []
                })
            } else {
                setShippingOrderInfo(result);
            }
        } catch (e) {
            console.log(e);
        }
    }


    const fetchCards = () => {
        axios
            .get(LIST_CREDIT_CARD_API, { headers: getHeaders() })
            .then((response) => {
                console.log("fetch cards: ", response);
                setCardLoading(false);
                const inUseCard = [];
                for (const card of response.data.cardInfo) {
                    if (card.card_last_four_digits) {
                        setSelectedCard(card);
                        inUseCard.push(card);
                    }
                }
                setCardInfo(inUseCard);
            })
            .catch((err) => {
                errHandling(err, setCardErrMsg);
                setCardLoading(false);
            }).finally(() => {
            setCardLoading(false);
        });
    }

    useEffect(() => {
        fetchCards();
    }, [])

    useEffect(() => {
        if (reload) {
            fetchCards();
        }
    }, [cardStatus, reload]);

    useEffect(() => {
        if (orderId) {
            (async () => {
                const accessToken = getAccessToken("access_token");
                const {data} = await axios.get(`${NODE_ROUTE_URI}/looseItem/getBackendOrderWithPackagesByOrderId/${orderId}`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });
                console.log("Order Details: ", data);
                const {
                    order_number,
                    sender,
                    pickup_phone,
                    pickup_address,
                    pickup_area,
                    deliver_area,
                    pickup_postal_code,
                    deliver_address,
                    deliver_phone,
                    deliver_name,
                    deliver_postal_code,
                    ship_service_type,
                    account_base_combine_name,
                    packages,
                    prices,
                    status_id
                } = data;
                const [pickup_city, pickup_province] = pickup_area.split(", ");
                const [deliver_city, deliver_province] = deliver_area.split(", ");
                const account_base = account_base_combine_name.split("-")[0];
                const {taxes, final_total, service_fee, shipping_fee, residential_charge, total} = prices;

                setOrderNumber(order_number);
                setpickupName(sender);
                setPickupPhone(pickup_phone);
                setPickupAddress(pickup_address);
                setPickupCity(pickup_city);
                setPickupProvince(pickup_province);
                setPickupPostalCode(pickup_postal_code);
                setDeliverAddress(deliver_address);
                setDeliverProvince(deliver_province);
                setDeliverPhone(deliver_phone);
                setDeliverName(deliver_name);
                setDeliverCity(deliver_city);
                setDeliverPostalCode(deliver_postal_code);
                setPallets(packages.map(v => {
                    return {
                        description: v.item_description,
                        looseUnit: v.number,
                        weightUnit: v.weight_unit,
                        lengthUnit: v.dimension_unit,
                        ...v
                    }
                }));
                setCompanyName(account_base);
                setServiceName(ship_service_type);
                setFinalTotalAmount(final_total);
                setShippingFee(+shipping_fee + +service_fee + +residential_charge);
                setServiceFeeTax(taxes);
                setBackendOrderStatus(status_id);
                setCrossBorderFee(+final_total - +total);
            })();
        }
    }, [orderId])

    useInterval(
        () => {
            if (orderId) {
                console.log('call get order status');
                getOrderStatus();
            }
        },
        // Delay in milliseconds or null to stop it
        backendOrderStatus === 0 ? delay : null,
    )

    useEffect(() => {
        if (backendOrderStatus === 1) {
            (async () => {
                await getShippingOrderInfo();
            })()
        }
    }, [backendOrderStatus])

    const handleToastClick = () => {
        setToastOpen(true);
    };

    const handleToastClose = (e, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setToastOpen(false);
    };

    const handleConfirmDialogOpen = () => {
        setConfirmDialogOpen(true);
    };

    const handleConfirmDialogClose = () => {
        setConfirmDialogOpen(false);
    };

    const handleCancelDialogOpen = () => {
        setCancelDialogOpen(true);
    };

    const handleCancelDialogClose = () => {
        setCancelDialogOpen(false);
    };

    const history = useHistory();
    // const location = useLocation();

    const classes = useStyles();
    const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));


    return (
        <Box width={"1000px"} margin="0 auto" sx={{
            display: "flex", flexDirection: "column", justifyContent: "space-evenly", gap: "2rem",
            alignItems: "center", alignContent: "center"
        }}>
            {/*Pop-up Toast*/}
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={3000}
                message="Cancel Order">
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert onClose={handleToastClose} severity="error" sx={{width: '100%'}}>
                                Place Order Failed!
                                <hr/>
                                Error: {errorMessage}
                            </Alert>
                        )
                    }
                    return (
                        <Alert onClose={handleToastClose} severity="success" sx={{width: '100%'}}>
                            Place Order Success!
                        </Alert>
                    )
                })()}
            </Snackbar>
            {/*Pop-up Toast*/}
            {/*Confirm Place Order Dialog*/}
            <Dialog
                open={confirmDialogOpen}
                onClose={handleConfirmDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Place Order
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This process will place your Shipping Order. Are you sure to continue?
                        <hr/>
                        Click <b>Confirm</b> to place an order
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmDialogClose} variant="outlined" autoFocus type="button">No</Button>
                    <Button onClick={() => {
                    }} variant="outlined" type="button" disabled={placeOrderLoading}>
                        {placeOrderLoading && <CircularProgress size={14}/>}
                        {!placeOrderLoading && "Confirm"}
                    </Button>
                </DialogActions>
            </Dialog>
            {/*Confirm Place Order Dialog*/}
            {/*Cancel Place Order Dialog*/}
            <Dialog
                open={cancelDiaglogOpen}
                onClose={handleCancelDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Cancel Order
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This process will cancel your Shipping Order. Are you sure to continue?
                        <hr/>
                        Click <b>Confirm</b> to cancel this order
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDialogClose} variant="outlined" autoFocus type="button">No</Button>
                    <Button onClick={() => {
                    }} variant="outlined" type="button" disabled={cancelOrderLoading}>
                        {cancelOrderLoading && <CircularProgress size={14}/>}
                        {!cancelOrderLoading && "Confirm"}
                    </Button>
                </DialogActions>
            </Dialog>
            {/*Cancel Place Order Dialog*/}
            <Box width={"1000px"} margin="0 auto">
                <h2 className="orange-title">Shipping Order Details</h2>
                <ReviewOrderHeader
                    pickupAddress={pickupAddress}
                    pickupAddressTwo={pickupAddressTwo}
                    pickupPostalCode={pickupPostalCode}
                    pickupProvince={pickupProvince}
                    deliverAddress={deliverAddress}
                    deliverPhone={deliverPhone}
                    pickupName={pickupName}
                    pickupPhone={pickupPhone}
                    deliverAddressTwo={deliverAddressTwo}
                    deliverProvince={deliverProvince}
                    deliverName={deliverName}
                    deliverCity={deliverCity}
                    pickupCity={pickupCity}
                    deliverPostalCode={deliverPostalCode}
                    pallets={pallets}
                    orderNumber={orderNumber}
                />
                <Box className={classes.summaryService}>
                    <Box className={classes.summaryHeader}>
                        Service
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            Service Type:
                        </Grid>
                        <Grid item xs={6} sx={{textAlign: 'right'}}>
                            {companyName} {serviceName}
                        </Grid>
                        <Grid item xs={6}>
                            Subtotal:
                        </Grid>
                        <Grid item xs={6} sx={{textAlign: 'right'}}>
                            <CurrencyFormat value={shippingFee - serviceFeeTax || 0} displayType={'text'}
                                            thousandSeparator={true} prefix={'$'}/>
                        </Grid>
                        <Grid item xs={6}>
                            Taxes:
                        </Grid>
                        <Grid item xs={6} sx={{textAlign: 'right'}}>
                            <CurrencyFormat value={serviceFeeTax || 0} displayType={'text'}
                                            thousandSeparator={true} prefix={'$'}/>
                        </Grid>
                        <Grid item xs={6}>
                            Cross Border Fee:
                        </Grid>
                        <Grid item xs={6} sx={{textAlign: 'right'}}>
                            <CurrencyFormat value={crossBorderFee} displayType={'text'}
                                            thousandSeparator={true} prefix={'$'}/>
                        </Grid>
                        <Divider sx={{width: '100%', margin: '10px'}}/>
                        <Grid item xs={6} sx={{fontSize: '18px', fontWeight: '600'}}>
                            Total
                        </Grid>
                        <Grid item xs={6} sx={{textAlign: 'right', fontSize: '18px', fontWeight: '600'}}>
                            <CurrencyFormat value={finalTotalAmount || 0} displayType={'text'}
                                            thousandSeparator={true} prefix={'$'}/>
                        </Grid>
                    </Grid>
                </Box>
                <hr/>
                {/*{*/}
                {/*    backendOrderStatus === 0 && (*/}
                {/*        <>*/}
                {/*            <h2 className="orange-title">Payments</h2>*/}
                {/*            <PaymentsAccordion orderId={orderId} orderNumber={orderNumber}/>*/}
                {/*        </>*/}
                {/*    )*/}
                {/*}*/}
                {
                    (() => {
                        if (backendOrderStatus === 0 && cardInfo?.length === 0) {
                            return (
                                <>
                                    <p>
                                        <h2 className="orange-title">Credit Cards</h2>
                                        <AddCreditCardSection
                                            cardInfo={cardInfo}
                                            setCardInfo={setCardInfo}
                                            selectedCard={selectedCard}
                                            setCardLoading={setCardLoading}
                                            setSelectedCard={setSelectedCard}
                                            cardLoading={cardLoading}
                                            setReload={setReload}
                                            cardStatus={cardStatus}
                                        />
                                    </p>
                                    <br />
                                </>
                            )
                        }
                    })()
                }
                {
                    (() => {
                        if (backendOrderStatus === 0 && cardInfo?.length > 0) {
                            return (
                                <>
                                    <p>
                                        <h2 className="orange-title">Payments</h2>
                                        <PaymentsAccordion orderId={orderId} orderNumber={orderNumber} cardInfo={cardInfo} />
                                    </p>
                                </>
                            )
                        }
                    })()
                }
                <Dialog
                    maxWidth="sm"
                    fullWidth
                    open={dialogOpen}
                    onClose={() => {
                        setReload(true);
                        setDialogOpen(false);
                    }}
                >
                    <div className={classes.dialogTitle}>
                        <Box fontSize="1.5rem"></Box>
                        <IconButton
                            // styles={{icon: {color: 'rgb(141, 173, 155)', fontSize: 36}}}
                            style={{fill: "green"}}
                            className={classes.closeBtn}
                            onClick={() => {
                                setReload(true);
                                setDialogOpen(false);
                            }}
                        >
                            <ClearIcon/>
                        </IconButton>
                    </div>
                    {/*<AddCreditCardPage addCard={true} popUp={true} />*/}
                </Dialog>
            </Box>
            {
                backendOrderStatus === 1 && (
                    <>
                        <h3 className="orange-title">Thank You! You Already Paid this Order</h3>
                        <Box width={"100%"} margin="0 auto">
                            <Box className={classes.OrderDashBoardSection}>
                                <Button
                                    className={classes.OrderDashBoardButton}
                                    onClick={() => history.push(`/order-dash-board/${shippingOrderInfo.order_id}`)}
                                >
                                    See Details of Order
                                </Button>
                            </Box>
                            <Box>
                                <ShippingOrderListTable shippingListData={shippingOrderInfo?.transactions}
                                                        backendOrderId={orderId}/>
                            </Box>
                        </Box>
                    </>
                )
            }
            <Snackbar
                anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                open={emailSentSuccess}
                onClose={() => setEmailSentSuccessState(false)}
                message="Send email successfully"
                autoHideDuration={3000}
            />
            <Snackbar
                anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                open={emailSentFail}
                onClose={() => setEmailSentFailState(false)}
                message="Failed to send email"
                autoHideDuration={3000}
            />
        </Box>
    );
};

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    rootContainer: {
        // display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'center',
        // flexDirection: 'column',
        margin: "0 auto",
        width: "40vw",
        minWidth: "500px",
        paddingLeft: "3vw",
        paddingRight: "3vw",
        textAlign: "left"
    },
    smRootContainer: {
        width: "100%",
        paddingLeft: "3vw",
        paddingRight: "3vw",
        textAlign: "left"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    button: {
        borderRadius: 5,
        fontSize: "1.2rem",
        backgroundColor: "#ffa726",
        width: "14rem",
    },
    smButton: {
        borderRadius: 5,
        fontSize: "1rem",
        backgroundColor: "#ffa726",
        width: "12rem",
    },
    textfield: {
        width: "100%",
        backgroundColor: "white",
        // border: "1px solid white",
    },
    dockerFee: {
        width: "4rem",
    },
    feeWrapper: {
        // border: "1px solid rgba(0,0,0,0.3)",
        borderRadius: 5,
        marginBottom: theme.spacing(2),
        // backgroundColor: "#FEEAAE",
        backgroundColor: "white",
    },

    infoWrapper: {
        // border: "1px solid rgba(0,0,0,0.3)",
        borderRadius: 5,
        marginBottom: theme.spacing(2),
        backgroundColor: "#FEEAAE",
    },
    summaryHeader: {
        fontSize: "16pt",
        color: "rgb(141, 173, 155)",
        fontFamily: "Montserrat",
        fontWeight: '600'
        // borderBottom: '1px, solid rgb(141, 173, 155)',
    },
    summaryBodyText: {
        fontSize: "13pt",
        color: "seagreen",
        fontFamily: "Montserrat",
    },
    summaryService: {
        backgroundColor: '#FFFFFF',
        borderRadius: '5px',
        padding: '10px 20px',
        marginTop: '20px',
        width: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
    },
    textButton: {
        color: "blue",
    },
    bodyText: {
        fontSize: "1rem",
        color: "seagreen",
        fontFamily: "Montserrat",
    },
    dialogTitle: {
        padding: "1vh 1vw 0.5vh",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        color: "rgb(141, 173, 155)",
    },
    timePicker: {
        width: 160,
    },
    divider: {
        borderBottom: "2px solid rgba(0,0,0,0.3)",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    alertRoot: {
        width: "80%",
        minWidth: "200px",
        margin: "10px auto",
    },
    radioWrapper: {
        border: "1px solid red",
        paddingLeft: theme.spacing(1),
        borderRadius: "5px",
    },
    OrderDashBoardSection: {
        backgroundColor: '#2F58CD',
        width: '200px',
        height: '60px',
        borderRadius: '15px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: '0.2s ease-out',
        ':hover': {
            opacity: 0.9
        }
    },
    OrderDashBoardButton: {
        width: '100%',
        height: '100%',
        color: '#FFFFFF',
        fontSize: '14px',
        fontWeight: '600',
    }
}));

// const mapStateToProps = state => ({
//     shippingItems: state.shippingItems
// })

// export default connect(mapStateToProps, {getShipmentItems, getPickUpDetails})(ReviewAndConfirm);
export default ShippingOrderDetailsPage;
