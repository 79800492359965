import React from 'react';
import {Box, makeStyles} from "@material-ui/core";
import {LABEL_DOWNLOAD_URL_PREFIX} from "../../Utils/apiUrl";
import ShippingListTrackingTable from "./ShippingListTrackingTable";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  listItemWrapper: {
    width: "100%",
    margin: "3px auto",
    "&:hover": {
      cursor: "pointer",
      background: "aliceblue"
    },
    lineHeight: "2rem",
    textAlign: "left"
  },
  buttonFilesWrapper: {
    border: "2px dashed #d3d3d3",
    borderRadius: "2px",
    background: "#fdf5e8",
    padding: "5px",
    wordBreak: "break-word",
  },
  hscodeListWrapper: {
    border: "2px solid lightgray",
    borderRadius: "5px",
    width: "98%",
    maxHeight: "45vh",
    height: "fit-content",
    margin: "10px auto",
    overflowY: "auto",
    background: "#fff",
  },
  table: {
    width: "100%",
  },
  tbody: {
    "& tr:nth-child(odd)": {
      backgroundColor: "rgba(222, 222, 222, 0.4)",
    },
  },
  nameTH: {
    width: "100px",
  },
  idTH: {
    width: "100px",
  },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
    display: "flex",
    justifyContent: "center",
  },
}));

const ShippingOrderListTable = ({ shippingListData, backendOrderId }) => {

  const classes = useStyles();

  return (
      <Box>
        <Box mt={2}>
          <table className={classes.table}>
            <thead>
            <tr className={classes.listItemWrapper}>
              <th className={classes.idTH}>Tran Id</th>
              {/*<th className={classes.idTH}>Order ID</th>*/}
              {/*<th className={classes.nameTH}>Ref Order Number</th>*/}
              {/*<th className={classes.nameTH}>Ref Product ID</th>*/}
              {/*<th className={classes.nameTH}>Ref Other Number</th>*/}
              <th className={classes.nameTH}>Ref #</th>
              <th className={classes.nameTH}>Send To</th>
              {/*<th className={classes.nameTH}>Send To Name/Email/Tel</th>*/}
              {/*<th className={classes.nameTH}>Send To Address/City/Province/Country/Postal Code</th>*/}
              <th className={classes.nameTH}>Service</th>
              {/*<th className={classes.nameTH}>Exclude Tax</th>*/}
              {/*<th className={classes.nameTH}>Include Tax</th>*/}
              <th className={classes.nameTH}>Price</th>
              <th className={classes.nameTH}>Tracking #</th>
              <th className={classes.nameTH}>Labels</th>
              {/*<th className={classes.nameTH}>ZIP Label URI</th>*/}
              {/*<th className={classes.nameTH}>Label PDF URI</th>*/}
              <th className={classes.nameTH}>Date</th>
              <th className={classes.nameTH}>Status</th>
            </tr>
            </thead>
            <tbody className={classes.tbody}>
            {shippingListData?.map((v, index) => {
              return <ShippingListTrackingTable key={index}
                                                account_base={v.account_base}
                                                date={v.tranacted_at}
                                                gross_price={v?.amount_gross}
                                                net_price={v?.amount}
                                                order_id={v?.order_id}
                                                ref_product_no={v.ref_product_no}
                                                ref_other_no={v?.ref_other_no}
                                                ref_order_no={v?.ref_order_no}
                                                tran_id={v.tran_id}
                                                label_pdf_uri={v?.label_pdf_uri ? `${LABEL_DOWNLOAD_URL_PREFIX}${v.label_pdf_uri}` : ""}
                                                zip_label_uri={v?.label_zip_uri ? `${LABEL_DOWNLOAD_URL_PREFIX}${v.label_zip_uri}` : ""}
                                                send_to_addr={v.send_to_addr}
                                                send_to_city={v.send_to_city}
                                                send_to_province={v.send_to_province}
                                                send_to_country={v.send_to_country}
                                                send_to_postal_code={v.send_to_postal_code}
                                                send_to_name={v.send_to_name}
                                                send_to_tel={v.send_to_tel}
                                                send_to_email={v.send_to_email}
                                                currency_code={v?.currency_code || "CAD"}
                                                transactionNumbers={v.tran_tracking_nos}
                                                tran_status={v.tran_status}
                                                shipping_id_no={v.shipping_id_no}
                                                backendOrderId={backendOrderId}
              />;
            })}
            </tbody>
          </table>
        </Box>
      </Box>
  );
};

export default ShippingOrderListTable;
