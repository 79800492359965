import React from "react";
import { Box } from "@material-ui/core";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";

import LoadingContainer from "../loadingContainer";
import { add } from "../../../Utils/decimalOperation";

const PriceModule = ({
  fetchingPrice,
  shippingPrice,
  priceErrMsg,
  addon = {},
  detailPage = false,
}) => {
  const classes = useStyles();

  let priceComponent = <LoadingContainer />;

  const numberCheck = (num) => {
    let newNum = num;
    if (typeof num === "string") {
      newNum = num.parseFloat();
    }
    return newNum.toFixed(2);
  };

  const getFinalPrice = () => {
    let finalPrice = shippingPrice.total;
    if (addon.pickupDockFee) finalPrice = add(finalPrice, addon.pickupDockFee);
    if (addon.deliverDockFee)
      finalPrice = add(finalPrice, addon.deliverDockFee);
    if (addon.insuranceFee) finalPrice = add(finalPrice, addon.insuranceFee);
    if (addon.appointmentFee)
      finalPrice = add(finalPrice, addon.appointmentFee);
    return finalPrice;
  };

  const showPickupDockFee = (_addon) => {
    if (_addon.pickupDockFee && !detailPage) return true;
    if (_addon.pickupDockFee !== "0.00" && detailPage) return true;
    return false;
  };

  const showDeliverDockFee = (_addon) => {
    if (_addon.deliverDockFee && !detailPage) return true;
    if (_addon.deliverDockFee !== "0.00" && detailPage) return true;
    return false;
  };

  const showAppointmentFee = (_addon) => {
    if (_addon.appointmentFee && !detailPage) return true;
    if (_addon.appointmentFee !== "0.00" && detailPage) return true;
    return false;
  };

  if (!fetchingPrice)
    priceComponent = (
      <Box>
        <Box display="flex" justifyContent="space-between">
          <Box className={classes.priceBodyText}>Shipping fee</Box>
          <Box ml={1}>
            <p className={classes.priceBodyText}>{`C$ ${numberCheck(
              shippingPrice.shippingFee
            )}`}</p>
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Box className={classes.priceBodyText}>Service fee</Box>
          <Box ml={1}>
            <p className={classes.priceBodyText}>{`C$ ${numberCheck(
              shippingPrice.serviceFee
            )}`}</p>
          </Box>
        </Box>

        {addon.insuranceFee && (
          <Box display="flex" justifyContent="space-between">
            <Box className={classes.priceBodyText}>Insurance fee</Box>
            <Box ml={1}>
              <p
                className={classes.priceBodyText}
              >{`C$ ${addon.insuranceFee}`}</p>
            </Box>
          </Box>
        )}

        {showPickupDockFee(addon) && (
          <Box display="flex" justifyContent="space-between">
            <Box className={classes.priceBodyText} flexBasis="200px">
              Pickup dock usage fee
            </Box>
            <Box ml={1}>
              <p className={classes.priceBodyText}>{`C$ ${numberCheck(
                addon.pickupDockFee
              )}`}</p>
            </Box>
          </Box>
        )}

        {showDeliverDockFee(addon) && (
          <Box display="flex" justifyContent="space-between">
            <Box className={classes.priceBodyText} flexBasis="200px">
              Deliver dock usage fee
            </Box>
            <Box ml={1}>
              <p className={classes.priceBodyText}>{`C$ ${numberCheck(
                addon.deliverDockFee
              )}`}</p>
            </Box>
          </Box>
        )}

        {showAppointmentFee(addon) && (
          <Box display="flex" justifyContent="space-between">
            <Box className={classes.priceBodyText} flexBasis="200px">
              Appointment fee
            </Box>
            <Box ml={1}>
              <p className={classes.priceBodyText}>{`C$ ${numberCheck(
                addon.appointmentFee
              )}`}</p>
            </Box>
          </Box>
        )}

        {shippingPrice.taxes && (
          <Box display="flex" justifyContent="space-between">
            <Box className={classes.priceBodyText}>Taxes</Box>
            <div>
              <p
                className={classes.priceBodyText}
              >{`C$ ${shippingPrice.taxes}`}</p>
            </div>
          </Box>
        )}
        {shippingPrice.tips && shippingPrice.tips !== "0.00" && (
          <Box display="flex" justifyContent="space-between">
            <Box className={classes.priceBodyText}>Tips</Box>
            <div>
              <Box
                className={classes.priceBodyText}
              >{`C$ ${shippingPrice.tips}`}</Box>
            </div>
          </Box>
        )}
        <hr />
        <Box display="flex" justifyContent="space-between" fontWeight="700">
          <p className={classes.priceBodyText}>Final price</p>
          <div>
            <p className={classes.priceBodyText}>{`C$ ${
              detailPage ? shippingPrice.total : getFinalPrice()
            }`}</p>
          </div>
        </Box>
      </Box>
    );

  return priceErrMsg && !fetchingPrice ? (
    <Box pt={1} width="100%">
      <Alert severity="error">{priceErrMsg}</Alert>
    </Box>
  ) : (
    priceComponent
  );
};

const useStyles = makeStyles((theme) => ({
  priceBodyText: {
    fontSize: "12pt",
    color: "seagreen",
    fontFamily: "Montserrat",
  },
}));

PriceModule.propTypes = {
  fetchingPrice: PropTypes.bool.isRequired,
  shippingPrice: PropTypes.object.isRequired,
  priceErrMsg: PropTypes.string.isRequired,
  addon: PropTypes.object,
  detailPage: PropTypes.bool,
};

export default PriceModule;
