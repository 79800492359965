import React from "react";
// import Slide from 'react-reveal';
// import "../styles/styles.css";
import { makeStyles } from "@material-ui/core/styles";

import { Box, Checkbox } from "@material-ui/core";

const getDate = (date) => {
  return date ? date.split(" ")[0] : "";
};

const ShipmentLists = ({ shipmentTask, handleCheckboxChange }) => {
  const classes = useStyles();

  return (
    <>
      <Box
        // classes={{ root: classes.claimRoot }}
        className={`${classes.pkgWrapper} ${classes.claimRoot}`}
        key={shipmentTask.shipment_number}
      >
        <Box
          className={classes.cardContent}
          display="flex"
          alignItems="center"
          // width="100%"
        >
          <Box mr={2}>
            <Checkbox
              color="primary"
              onChange={handleCheckboxChange(
                shipmentTask.shipment_number,
                shipmentTask.id
              )}
            />
          </Box>
          <Box display="flex" flexDirection="column" flexGrow="1">
            <Box color="seagreen" mb={0.5} fontSize="0.9rem">
              {shipmentTask.shipment_number}
            </Box>
            <Box component="span" flexBasis="60%" fontWeight="700">
              {shipmentTask.item_description}
            </Box>
            <Box>
              Pickup date:{" "}
              <span style={{ fontStyle: "italic" }}>
                {getDate(shipmentTask.pickedup_at)}
              </span>
            </Box>
            <Box>
              Deliver date:{" "}
              <span style={{ fontStyle: "italic" }}>
                {getDate(shipmentTask.delivered_at)}
              </span>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  claimRoot: {
    // minHeight: 70,
    margin: 10,
  },
  pkgWrapper: {
    backgroundColor: "#fdf5e8",
    padding: "3px",
  },
  cardContent: {
    backgroundColor: "#fbfbf8",
    border: "1px solid #ded47c",
    borderRadius: "5px",
  },
}));

export default ShipmentLists;
