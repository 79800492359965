import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import aliPay from "../../../images/alipay.svg";
import Typography from "@mui/material/Typography";
import {Link} from "@mui/material";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import {useEffect, useState} from "react";
import {getAccessToken} from "../../../Utils/doToken";
import Button from "@mui/material/Button";
import {useHistory} from "react-router-dom";
import intl from "react-intl-universal";

const styles = {
    BillingPaymentAliPayRoot: {
        border: '1px solid #000000',
        display: 'flex',
        borderRadius: '10px',
        width: '100%',
        paddingBottom: '10px'
    }
}

export const BusinessBillingPaymentAliPay = ({selectInvoice, totalAmount}) => {

    const history = useHistory();

    const storedToken = getAccessToken("access_token");

    const [isLoading, setIsLoading] = useState(true);
    const [amount, setAmount] = useState(0);
    const [payCheckLink, setPayCheckLink] = useState("#");

    const payInvoicesByAliPay = async () => {
        const requestURL = `${PARTNER_URI}/generalWechatAlipay/initPartnerPayCRMCRMInvoiceList`;

        const invoiceList = selectInvoice?.map(item => {
            return {
                invoiceId: item.id
            }
        })

        const data = {
            payType: "ALPHAPAY-ALIPAY",
            invoiceList: invoiceList
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                data: data
            })
            console.log('result', result);
            setPayCheckLink(result?.data?.pay_url);
            setAmount((+(result?.data?.amount) / 100));

        } catch (e) {
            console.log('error', e.response);
            setPayCheckLink("#");
            setAmount(0);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        selectInvoice?.length > 0 && payInvoicesByAliPay();
    }, [])

    if (isLoading) {
        return <Box sx={{display: 'flex', justifyContent: 'center', padding: '20px 0'}}>
            <CircularProgress/>
        </Box>
    }

    if (payCheckLink === "#" && !isLoading) {
        return <Box sx={{maxWidth: '450px'}}>
            <Typography style={{fontWeight: '600'}}>
                Sorry We currently encounter some issues, please try again later.
            </Typography>
        </Box>
    }

    return (
        <Box sx={styles.BillingPaymentAliPayRoot}>
            <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                    <Box sx={{
                        padding: '5px 15px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        height: '100%',
                        gap: '5px'
                    }}>
                        <Typography style={{fontSize: '14px'}}>
                            {intl.getHTML('BILLING_CENTER.PAYMENT.ALI.LINK', {payCheckLink})}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={8} xs={12}>
                    <Box sx={{
                        padding: '5px 15px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        height: '100%',
                        gap: '5px'
                    }}>
                        {/*<Box sx={{display: 'flex', alignItems: 'center', gap: '5px'}}>*/}
                        <Link
                            sx={{display: 'flex', alignItems: 'center', gap: '5px', padding: '0px'}}
                            underline="none"
                            href={payCheckLink}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={aliPay} alt="wechatLogo" width={35}/>
                            <Typography style={{fontSize: '16px', textDecoration: 'none', color: '#000000'}}>
                                {intl.get('BILLING_CENTER.PAYMENT.ALI.ALI_PAY')}
                            </Typography>
                        </Link>
                        {/*</Box>*/}
                        <Grid container spacing={0}>
                            <Grid item xs={6}>
                                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                    {intl.get('BILLING_CENTER.PAYMENT.ALI.TOTAL_AMOUNT')}:
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                    $ {amount.toFixed(2)}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                    {intl.get('BILLING_CENTER.PAYMENT.ALI.RECEIVER')}:
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                    UU CARGO LTD.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}