import React, { useState } from "react";
import { useHistory, Link, useParams } from "react-router-dom";
import { Button, Box, makeStyles, Divider } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useSelector } from "react-redux";

// import Slide from 'react-reveal';
// import axios from "axios";
// import { CustomInput } from "../shared";
import { redirectHome } from "../../Utils/redirect";

const getInfo = (tab) => {
  if (tab === "no-repeat") {
    return "This confirmation link is no longer working.";
  }
  if (tab === "no-record") {
    return "Can't find this shipment record.";
  }
  if (tab === "success") {
    return "The shipment has been claimed.";
  }
  return "Some problems occurred during the confirmation process, please try again later.";
};

const getType = (tab, title = false) => {
  if (tab === "no-repeat") {
    return "warning";
  }
  if (tab === "no-record") {
    return "info";
  }
  if (tab === "success") {
    return "success";
  }
  return "error";
};

const getTitle = (tab, title = false) => {
  if (tab === "no-repeat") {
    return "Warning";
  }
  if (tab === "no-record") {
    return "Info";
  }
  if (tab === "success") {
    return "Success";
  }
  return "Error";
};

const ConfirmInfo = () => {
  const { tab } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const { signIn } = useSelector((state) => state.user);

  //   const [error, setError] = useState("");

  return (
    // <Slide down duration ={500}>
    <Box
      display="flex"
      flexDirection="column"
      fontFamily="Montserrat"
      justifyContent="center"
      alignItems="center"
      mt="18vh"
      letterSpacing="1.5px"
    >
      <Box width="100%">
        <Alert severity={getType(tab)} classes={{ root: classes.alertRoot }}>
          <Box fontSize="2rem">
            <AlertTitle>{getTitle(tab)}</AlertTitle>
          </Box>
          {getInfo(tab)}
        </Alert>
        <Box display="flex" justifyContent="center" mt={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              redirectHome(history);
            }}
          >
            <Box display="flex">
              <Box ml={1} fontSize="0.9rem" textAlign="center">
                Go to homepage
              </Box>
            </Box>
          </Button>
        </Box>
        {!signIn && (
          <>
            <Box m={1} mt={2} mb={4}>
              <Divider style={{ color: "black" }} />
            </Box>
            <Box display="flex" justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  history.push("/sign-in");
                }}
                style={{ width: "14rem" }}
              >
                Sign in
              </Button>
            </Box>
            <Box textAlign="center" mt={3}>
              Don't have an Account? <Link to="/sign-up">Sign up</Link>
            </Box>
          </>
        )}
      </Box>
    </Box>
    // </Slide>
  );
};

const useStyles = makeStyles({
  alertRoot: {
    width: "80%",
    minWidth: "200px",
    maxWidth: "500px",
    margin: "10px auto",
    fontSize: "1.2rem",
    "& .MuiTypography-body1": {
      fontSize: "1.5rem",
    },
    "& .MuiAlert-icon": {
      padding: "12px 0",
    },
  },
});

export default ConfirmInfo;
