import React, { useState, useEffect } from "react";
// import Slide from 'react-reveal';
// import "../styles/styles.css";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Alert,
  // Pagination
} from "@material-ui/lab";

import axios from "axios";
import produce from "immer";

import { getHeaders, errHandling } from "../../Utils/fetchUtil";
import LoadingContainer from "../shared/loadingContainer";

import ShipmentLists from "./components/shipmentLists";

import {
  GET_UNCONFIRMED_DELIVERED_SHIPMENTS,
  GET_UNCONFIRMED_PICKEDUP_SHIPMENTS,
  CONFIRM_SHIPMENT_TASKS,
} from "../../Utils/apiUrl";

import {
  Box,
  Container,
  Card,
  Button,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";

const ConfirmShipments = () => {
  // const token = getAccessToken("access_token");

  // const dispatch = useDispatch();
  const classes = useStyles();

  const [claimedShipmentsLoading, setClaimedShipmentsLoading] = useState(true);

  const [confirming, setConfirmingState] = useState(false);
  const [reload, setReload] = useState(false);

  const [claimedShipments, setClaimedShipments] = useState([]);

  const [selectedShipments, setSelectedShipments] = useState([]);

  const [claimedErrorMsg, setClaimedErrorMsg] = useState("");

  const [confirmErrorMsg, setConfirmErrorMsg] = useState("");

  const { state } = useParams();

  const receiving = !state || state === "receipt";

  // const matches = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    const url = receiving
      ? GET_UNCONFIRMED_DELIVERED_SHIPMENTS
      : GET_UNCONFIRMED_PICKEDUP_SHIPMENTS;
    axios
      .get(url, {
        headers: getHeaders(),
        params: {
          page: 1,
          perPage: 10,
        },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data) setClaimedShipments(response.data.taskList);
        // // setLoadingState(false);
        setClaimedShipmentsLoading(false);
      })
      .catch((err) => {
        setClaimedShipmentsLoading(false);
        errHandling(err, setClaimedErrorMsg);
      });
  }, []);

  useEffect(() => {
    if (reload) {
      const url = receiving
        ? GET_UNCONFIRMED_DELIVERED_SHIPMENTS
        : GET_UNCONFIRMED_PICKEDUP_SHIPMENTS;
      axios
        .get(url, {
          headers: getHeaders(),
          params: {
            page: 1,
            perPage: 10,
          },
        })
        .then((response) => {
          if (response.data) setClaimedShipments(response.data.taskList);
          // // setLoadingState(false);
          setClaimedShipmentsLoading(false);
          setReload(false);
        })
        .catch((err) => {
          setClaimedShipmentsLoading(false);
          errHandling(err, setClaimedErrorMsg);
          setReload(false);
        });
    }
  }, [reload, receiving]);

  const getDate = (date) => {
    return date.split(" ")[0];
  };

  const handleCheckboxChange = (shipmentNum, stageNum) => (e) => {
    if (e.target.checked) {
      const checkObj = {
        shipmentNumber: shipmentNum,
        stage: stageNum,
      };
      setSelectedShipments(
        produce((draft) => {
          draft = draft.push(checkObj);
        })
      );
    } else {
      setSelectedShipments(
        produce((draft) => {
          const index = draft.findIndex(
            (item) => item.shipment_number === shipmentNum
          );
          if (index !== -1) draft.splice(index, 1);
        })
      );
    }
  };

  const handleConfirmShipments = () => {
    setConfirmingState(true);
    axios({
      method: "put",
      // url: receiving ? CONFIRM_SHIPMENTS_DELIVERY : CONFIRM_SHIPMENTS_PICKUP,
      url: CONFIRM_SHIPMENT_TASKS,
      data: JSON.stringify({
        shipmentTasks: selectedShipments,
        pickedUp: receiving ? false : true,
      }),
      headers: {
        ...getHeaders(),
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    })
      .then((res) => {
        // console.log(res);
        // if (res?.data) {
        //   setClaimedShipments(res.data);
        // }
        setReload(true);
        setConfirmingState(false);
      })
      .catch((error) => {
        // if (error.response) {
        //   // Request made and server responded
        //   console.log(error.response.data.message);
        // } else if (error.request) {
        //   // The request was made but no response was received
        //   console.log(error.request);
        // } else {
        //   // Something happened in setting up the request that triggered an Error
        //   console.log(error.message);
        // }
        errHandling(error, setConfirmErrorMsg);
        setConfirmingState(false);
      });
  };

  const comingPickup = (_claimedShipments, _claimedShipmentsLoading) => {
    let lastestItem = <LoadingContainer />;

    if (!_claimedShipmentsLoading && _claimedShipments.length === 0)
      lastestItem = (
        <Box fontSize="1.5rem" m={1} textAlign="center">
          No claimed shipping task found
        </Box>
      );

    if (!_claimedShipmentsLoading && _claimedShipments.length !== 0)
      lastestItem = (
        <>
          {_claimedShipments.map((item) => {
            return (
              <Card classes={{ root: classes.myRoot }} key={item.order_number}>
                <Box fontSize="1rem" pl={1} pr={1} pt={1}>
                  Order #: <b>{item.order_number}</b> <br />
                  {/* Create date: <b>{getDate(item.schedule_pickup)}</b> */}
                </Box>
                <ShipmentLists
                  shipmentTask={item}
                  handleCheckboxChange={handleCheckboxChange}
                />
              </Card>
            );
          })}
        </>
      );
    return lastestItem;
  };

  return (
    <Container>
      <Box>
        <Box fontSize="1.4rem" mt={1} mb={2} fontWeight="600">
          {receiving
            ? "Confirm shipments receipt:"
            : "Confirm shipments pickup:"}
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt={1}
          mb={3}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirmShipments}
            style={{
              textTransform: "unset",
              fontSize: "1rem",
              width: "7rem",
            }}
            disabled={claimedShipmentsLoading || confirming}
          >
            {confirming ? <CircularProgress size={23} /> : "Confirm"}
          </Button>
        </Box>

        {claimedErrorMsg ? (
          <Card classes={{ root: classes.smRoot }}>
            <Box pt={1} width="100%">
              <Alert severity="error">{claimedErrorMsg}</Alert>
            </Box>
          </Card>
        ) : (
          ""
        )}
        {!claimedErrorMsg &&
          comingPickup(claimedShipments, claimedShipmentsLoading)}
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        mt={1}
        mb={3}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleConfirmShipments}
          style={{
            textTransform: "unset",
            fontSize: "1rem",
            width: "7rem",
          }}
          disabled={claimedShipmentsLoading || confirming}
        >
          {confirming ? <CircularProgress size={23} /> : "Confirm"}
        </Button>
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={Boolean(confirmErrorMsg)}
        onClose={() => setConfirmErrorMsg("")}
        message={confirmErrorMsg}
        autoHideDuration={3000}
      />
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  myRoot: {
    width: "100%",
    margin: "15px auto",
  },
  smRoot: {
    minHeight: 100,
    margin: 10,
    marginLeft: 0,
    marginTop: 10,
    // width: '90%',
    width: "100%",
  },
}));

export default ConfirmShipments;
