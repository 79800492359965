import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { IconButton, Typography, Button, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";
import {
  driverUploadImages,
  driverRemoveImages,
  driverSetImageFetchStatus,
} from "../../actions/driverDeliverUploadImagesAction";
import { useDispatch, connect, useSelector } from "react-redux";
import {
  DRIVER_UPLOAD_SHIPMENTS_IMAGES,
  DRIVER_FETCH_UPLOADED_IMAGES,
  DRIVER_REMOVE_UPLOADED_IMAGES,
} from "../../Utils/apiUrl";
import { getTokenFromCookie } from "../../Utils/doToken";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "5px 2%",
    width: "96%",
    maxWidth: "700px",
    padding: "3px",
  },
  imgeInput: {
    display: "none",
  },
  uploadButton: {
    marginLeft: "11px",
  },
  uploadedImage: {
    border: "2px solid #D3D3D3",
    borderRadius: "2%",
    width: "80vw",
    height: "50vh",
    maxHeight: "200px",
    maxWidth: "250px",
    "&:hover": {
      border: "2px solid #FFC300",
    },
  },
  uploadImagesContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "8px",
    justifyContent: "center",
    marginTop: "10px",
  },
  cancelIcon: {
    position: "absolute",
    right: "-10%",
    top: "-12%",
    zIndex: 1,
  },
  imageListItem: {
    position: "relative",
  },
  uploadButtonWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  buttonAndImagesWrapper: {
    border: "2px dashed #d3d3d3",
    borderRadius: "2px",
    background: "#fdf5e8",
    padding: "5px",
  },
  uploadInfo: {
    border: "1px solid #f50057",
    margin: "5px",
    borderRadius: "5px",
    color: "white",
    background: "#F50058",
    padding: "5px",
  },
}));

const DriverUploadImages = ({ driverUploadedImages, type }) => {
  let imageData;
  const selectedShipments = useSelector((state) => {
    if (type === "load") {
      return state.driver_loaded_packages.loaded_shipment_numbers;
    } else if (type === "deliver") {
      return state.driver_deliver_packages.selectedShipmentNumber;
    }
  });

  const fetched = useSelector((state) => {
    if (type === "load") {
      return driverUploadedImages.fetched.loaded;
    } else if (type === "deliver") {
      return driverUploadedImages.fetched.delivered;
    }
  });

  if (type === "load") {
    imageData = driverUploadedImages.loadShipments;
  } else if (type === "deliver") {
    imageData = driverUploadedImages.deliverShipments;
  }

  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (fetched) return;
  //   let tempImgs = [];
  //   //fetch uploaded images
  //   try {
  //     for (let i = 0; i < selectedShipments.length; i++) {
  //       axios
  //         .get(
  //           DRIVER_FETCH_UPLOADED_IMAGES(
  //             selectedShipments[i].shipment_number,
  //             type + "ed"
  //           ),
  //           {
  //             headers: {
  //               Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
  //               "Content-Type": "multipart/form-data;",
  //             },
  //           }
  //         )
  //         // eslint-disable-next-line no-loop-func
  //         .then((result) => {
  //           console.log("fetch image result: ", result);
  //
  //           result.data.data.forEach((img) => {
  //             let imgObj = {
  //               id: img.image_id,
  //               shipmentNumber: img.shipment_number,
  //               name: img.image_name,
  //               preview: img.image_url,
  //               raw: "",
  //             };
  //             tempImgs.push(imgObj);
  //           });
  //
  //           if (i === selectedShipments.length - 1) {
  //             console.log("temp images (fetched): ", [...tempImgs]);
  //             dispatch(driverUploadImages([...tempImgs], type));
  //             dispatch(driverSetImageFetchStatus(true, type));
  //           }
  //         });
  //     }
  //   } catch (error) {}
  // }, []);

  const MAX_IMAGES_UPLOAD = 3;
  const classes = useStyles();
  const [uploadInfo, setUploadInfo] = useState("");

  const storeImagesToRedux = async (_sNum, _imageFile, shipmentNumber, index) => {
    console.log("Upload Image File: ", _imageFile);

    try {
      // console.log("upload image result", res);
      const imgObj = {
        name: _imageFile.name,
        preview: URL.createObjectURL(_imageFile),
        raw: _imageFile,
        id: index,
        shipmentNumber: shipmentNumber
        // id: imgId,
        // shipmentNumber: shipmentNumber,
      }

      //update image redux
      // const imgId = res.data.images[0].image_id;
      // const shipmentNumber = res.data.images[0].shipment_number;
      // let imgObj = {
      // name: _imageFile.name,
      // preview: URL.createObjectURL(_imageFile),
      // raw: _imageFile,
      // id: imgId,
      // shipmentNumber: shipmentNumber,
      // };

      dispatch(driverUploadImages([imgObj], type));
    } catch (error) {
      console.log("upload image error", error);
    }
  };

  const uploadImagesToDB = async (_sNum, _imageFile) => {
    console.log("Upload Image File: ", _imageFile);
    let formData = new FormData();
    formData.append("file", _imageFile);

    formData.append("shipments[]", _sNum);

    try {
      let res = await axios({
        method: "post",
        url: DRIVER_UPLOAD_SHIPMENTS_IMAGES,
        data: formData,
        headers: {
          Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
          "Content-Type": "multipart/form-data;",
        },
      });

      if (res.status === 200) {
        console.log("upload image result", res);

        //update image redux
        const imgId = res.data.images[0].image_id;
        const shipmentNumber = res.data.images[0].shipment_number;
        let imgObj = {
          name: _imageFile.name,
          preview: URL.createObjectURL(_imageFile),
          raw: _imageFile,
          id: imgId,
          shipmentNumber: shipmentNumber,
        };

        dispatch(driverUploadImages([imgObj], type));
      }
    } catch (error) {
      console.log("upload image error", error);
    }
  };

  const handleImageSelect = async (_sNum, e) => {
    console.log("selected imgs: ", e.target.files);
    //maximum 5 images
    if (imageData.length + e.target.files?.length <= MAX_IMAGES_UPLOAD) {
      //imge files
      for (let i = 0; i < e.target.files.length; i++) {
        //upload images

        // uploadImagesToDB(_sNum, e.target.files[i]);
        storeImagesToRedux(_sNum, e.target.files[i], _sNum, i);
      }
    } else {
      //more than 5 images are selected
      if (MAX_IMAGES_UPLOAD - imageData.length > 0) {
        for (let i = 0; i < MAX_IMAGES_UPLOAD - imageData.length; i++) {
          storeImagesToRedux(_sNum, e.target.files[i], _sNum, i);
        }
      }
      setUploadInfo(
        "You have reached maximum uploading 3 images. Please only uploading 3 images."
      );
      setTimeout(() => {
        setUploadInfo("");
      }, 1000 * 10);
    }
  };

  const removeImageFromDB = async (_sNum, _imgId, _index) => {
    try {
      // const result = await axios.post(
      //   DRIVER_REMOVE_UPLOADED_IMAGES(_sNum, _imgId),
      //   "",
      //   {
      //     headers: {
      //       Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
      //       "Content-Type": "multipart/form-data;",
      //     },
      //   }
      // );
      //
      // if (result.status === 200) {
      //   console.log("remove img res: ", result);
      //   dispatch(driverRemoveImages(_index, type));
      // }
      dispatch(driverRemoveImages(_index, type));
    } catch (error) {
      console.log("remove image error", error);
    }
  };

  const removeImage = (sNum, index) => {
    const imageId = imageData[index].id;
    console.log("image dataSource[index]: ", index, imageData[index]);
    console.log("image id in redux: ", imageId);
    //remove image that in the data base
    removeImageFromDB(sNum, imageId, index);
  };

  const countImages = (sNum) => {
    let count = 0;
    imageData.forEach((i) => {
      if (i.shipmentNumber === sNum) {
        count++;
      }
    });
    return count;
  };

  return (
    selectedShipments.length > 0 &&
    selectedShipments.map((obj) => {
      return (
        <Paper className={classes.root} key={obj.shipment_number}>
          <div className={classes.buttonAndImagesWrapper}>
            <div className={classes.uploadButtonWrapper}>
              <div>
                <span style={{ fontWeight: "650", fontSize: "1.2rem" }}>
                  Shipment Number:{" "}
                </span>
                <span style={{ textDecoration: "underLine" }}>
                  {obj.shipment_number}
                </span>
              </div>
              <Typography variant="caption">
                File Formats Accepted <b>PNG, JPG</b>
              </Typography>
              <Typography variant="caption" align="center">
                Max. file size <b>10MB</b> each, Max. file upload{" "}
                <b>{MAX_IMAGES_UPLOAD} images</b>
              </Typography>
              <label htmlFor="contained-button-file">
                <Button variant="contained" color="primary" component="span">
                  Upload Image
                </Button>
              </label>
              <Typography variant="caption">
                <i>(Optional)</i>
              </Typography>
              {imageData.length > 0 && (
                <Typography variant="caption">
                  <b>
                    ({countImages(obj.shipment_number)} / {MAX_IMAGES_UPLOAD})
                    images uploaded
                  </b>
                </Typography>
              )}
              {uploadInfo !== "" && (
                <div className={classes.uploadInfo}>
                  <Typography variant="body2" align="center">
                    {uploadInfo}
                  </Typography>
                </div>
              )}
            </div>
            <div className={classes.uploadImagesContainer}>
              {imageData.length > 0 &&
                imageData.map((img, index) => {
                  if (img.shipmentNumber === obj.shipment_number) {
                    return (
                      <div className={classes.imageListItem} key={index}>
                        <IconButton
                          color="secondary"
                          className={classes.cancelIcon}
                          onClick={() =>
                            removeImage(obj.shipment_number, index)
                          }
                        >
                          <CancelIcon />
                        </IconButton>
                        <img
                          src={imageData[index].preview}
                          alt="shipment"
                          width="300"
                          height="300"
                          className={classes.uploadedImage}
                        />
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
            </div>
          </div>
          <div className="uploadButton">
            <input
              accept=".jpg, .png"
              className={classes.imgeInput}
              id="contained-button-file"
              multiple
              type="file"
              onChange={(e) => handleImageSelect(obj.shipment_number, e)}
            />
          </div>
        </Paper>
      );
    })
  );
};

const mapStateToProps = (state) => {
  return {
    driverUploadedImages: state.driver_uploaded_images,
  };
};

export default connect(mapStateToProps)(DriverUploadImages);
