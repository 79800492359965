import {getAccessToken} from "../../Utils/doToken";
import {useHistory} from "react-router-dom";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    addShippingListToAddress,
    toggleShippingListTo
} from "../../actions/ratingAction";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {Button, Modal} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

const style = {
    RatingShippingListToRoot: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '600px',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflow: 'auto',
        maxHeight: '80vh'
    },
    RatingShippingListToList: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        margin: '10px 0'
    },
    RatingShippingListToListEachButton: {
        display: 'flex',
        alignItems: 'center'
    }
};

export const RatingShippingListTo = () => {

    const accessToken = getAccessToken("access_token");

    const history = useHistory();

    const [addressList, setAddressList] = useState([]);

    let isShippingListTo = useSelector(state => state?.rating?.isShippingListTo);

    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(toggleShippingListTo(false));
    }

    const getDeliverAddress = async () => {
        let requestURL = `${NODE_ROUTE_URI}/addressBookEndUser/endUser/addressbooks/deliver`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            // console.log(result.data)
            setAddressList(result.data.result)
        } catch (e) {
            console.log(e.response)
        }
    }

    useEffect(() => {
        getDeliverAddress();
    }, [])

    const handleUseIt = (address) => {

        const formattedAddress = {
            address: address.address,
            address2: address.address_2,
            city: address.city,
            company: address.company_name,
            country: address.country,
            email: address.email,
            emailAddress: address.address_email,
            name: address.name,
            phone: address.phone,
            postalCode: address.postal_code,
            province: address.province
        }

        dispatch(addShippingListToAddress(formattedAddress));
        dispatch(toggleShippingListTo(false));
    }

    const handleEdit = () => {
        history.push('/address-book');
    }

    return (
        <div>
            <Modal
                open={isShippingListTo}
                onClose={handleClose}
            >
                <Box sx={style.RatingShippingListToRoot}>
                    <Typography variant='h5'>
                        Shipping To List
                    </Typography>
                    <Box sx={style.RatingShippingListToList}>
                        {
                            addressList?.length === 0 ?
                                <Box>
                                    <Typography>
                                        Your address list is empty.
                                        Please add an address by going to the address book.
                                        Press the following button to go to the address book.
                                    </Typography>
                                    <Button onClick={() => history.push('/address-book')}>Address Book</Button>
                                </Box>
                                :
                                addressList.map((address, index) => {
                                    return (
                                        <Box key={index} sx={style.RatingShippingListToEach}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={8}>
                                                    <Box>
                                                        <Typography>
                                                            {address?.name}
                                                        </Typography>
                                                        <Typography>
                                                            {address?.phone}
                                                        </Typography>
                                                        <Typography>
                                                            {address?.address}
                                                        </Typography>
                                                        <Typography>
                                                            {address?.city},{address?.province},{address?.country},{address?.postal_code}
                                                        </Typography>
                                                        <Typography>
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={4} sx={style.RatingShippingListToListEachButton}>
                                                    {/*<Box sx={style.RatingShippingListToListEachButton}>*/}
                                                    <Button onClick={() => handleUseIt(address)}>
                                                        Use It
                                                    </Button>
                                                    <Button color="warning" onClick={handleEdit}>
                                                        Edit
                                                    </Button>
                                                    {/*</Box>*/}
                                                </Grid>
                                            </Grid>
                                            <Divider/>
                                        </Box>

                                    )
                                })
                        }
                    </Box>
                </Box>
            </Modal>
        </div>
    )

}