import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {Button, Snackbar} from "@mui/material";
import {mapCarrierLogo} from "../../Utils/Helper";
import LoadingButton from "@mui/lab/LoadingButton";
import {useState} from "react";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../Utils/doToken";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const MySchedulePickupItem = ({pickupInfo, cbGetSchedulePickup}) => {
    const accessToken = getAccessToken("access_token");

    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [dialogOpen, setDialogOpen] = useState(false);
    const [cancelLoading, setCancelLoading] = useState(false);

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const cancelPickup = async () => {

        setLoading(true);
        let requestURL = `${NODE_ROUTE_URI}/packagesPickupAppointment/cancelPickupAppointmentEndUser`;

        try {
            const result = await axios({
                method: 'delete',
                url: requestURL,
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
                params: {
                    appointmentNumber: pickupInfo?.confirm_appoint_no
                }
            })
            console.log('result', result);
            setErrorMessage('');
            setSuccessMessage('Successfully Cancel Pickup.');
            handleSuccessMessage();
            handleDialogClose();
            setTimeout(() => {
                cbGetSchedulePickup();
            }, 2000);
        } catch (e) {
            console.log('error', e.response);
            setErrorMessage('Failed to Cancel Pickup. Try again another time.');
            handleErrorMessage();
        } finally {
            setLoading(false)
        }
    }

    const handleCancel = () => {
        handleDialogOpen()
    }

    console.log('pickinfo', pickupInfo);

    return (
        <Box sx={{
            border: '1px solid #D1D1D1',
            borderRadius: '5px',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px'
        }}>
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Cancel Pickup
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This process will cancel the pickup with confirm # <b>{pickupInfo?.confirm_appoint_no}</b>. Are you sure to cancel?
                        <hr />
                        Click <b>Confirm</b> to cancel this pickup.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} variant="outlined" autoFocus type="button" >No</Button>
                    <Button onClick={cancelPickup} variant="outlined" type="button" disabled={cancelLoading} >
                        {cancelLoading && <CircularProgress size={14} />}
                        {!cancelLoading && "Confirm"}
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Box>
                        <img src={mapCarrierLogo(pickupInfo?.account_base?.toUpperCase())} alt="logo"
                             width='50px'/>
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box>
                        <Typography style={{color: '#B2B2B2'}}>
                            Confirmation #
                        </Typography>
                        <Typography>
                            {pickupInfo?.confirm_appoint_no}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={1}>
                    <Box>
                        <Typography style={{color: '#B2B2B2'}}>
                            Cost
                        </Typography>
                        <Typography>
                            {pickupInfo?.uuc_order_id ? `$ ${pickupInfo?.final_total}` : 'Free'}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box>
                        <Typography style={{color: '#B2B2B2'}}>
                            Appointment Date
                        </Typography>
                        <Typography>
                            {pickupInfo?.appoint_date}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Box>
                                <Typography style={{color: '#B2B2B2'}}>
                                    State Time
                                </Typography>
                                <Typography>
                                    {pickupInfo?.appoint_time_start?.substring(3)}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box>
                                <Typography style={{color: '#B2B2B2'}}>
                                    End Time
                                </Typography>
                                <Typography>
                                    {pickupInfo?.appoint_time_end?.substring(3)}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <Box>
                        <Typography style={{color: '#B2B2B2'}}>
                            Status
                        </Typography>
                        <Typography style={{color: pickupInfo?.status === 1 ? '#1D8B45' : pickupInfo?.status === -1 ? '#D71313' : '#454545'}}>
                            {pickupInfo?.status === 1 ? 'Submitted' : pickupInfo?.status === -1 ? 'Cancelled' : 'Initiated'}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={4}>
                    <Box>
                        <Typography style={{color: '#B2B2B2'}}>
                            Address
                        </Typography>
                        <Typography>
                            {pickupInfo?.pickup_addr}
                        </Typography>
                        <Typography>
                            {pickupInfo?.pickup_city}, {pickupInfo?.pickup_province}
                        </Typography>
                        <Typography>
                            {pickupInfo?.pickup_country}, {pickupInfo?.pickup_postal_code}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box>
                        <Typography style={{color: '#B2B2B2'}}>
                            Contact
                        </Typography>
                        <Typography>
                            {pickupInfo?.pickup_name}
                        </Typography>
                        <Typography>
                            {pickupInfo?.pickup_tel}
                        </Typography>
                        <Typography>
                            {pickupInfo?.pickup_email}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box>
                        <Typography style={{color: '#B2B2B2'}}>
                            Package Information
                        </Typography>
                        <Typography>
                            Estimate Volume: {pickupInfo?.estimate_volume} {pickupInfo?.volume_unit}
                        </Typography>
                        <Typography>
                            Estimate Weight: {pickupInfo?.estimate_weight} {pickupInfo?.weight_unit}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box>
                        <Typography style={{color: '#B2B2B2'}}>
                            Tracking #
                        </Typography>
                        <Typography>
                            {pickupInfo?.tracking_nos}
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={3}>
                    <Box>
                        <Typography style={{color: '#B2B2B2'}}>
                            Pickup Location
                        </Typography>
                        <Typography>
                            {pickupInfo?.pickup_location}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box>
                        <Typography style={{color: '#B2B2B2'}}>
                            Remark Notes
                        </Typography>
                        <Typography>
                            {pickupInfo?.remark_notes || 'N/A'}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={1}>
                    {pickupInfo?.status === 1 &&
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            height: '100%'
                        }}>
                            <LoadingButton
                                variant='outlined'
                                color='error'
                                loading={loading}
                                onClick={handleCancel}
                            >
                                <Typography style={{textTransform: 'none'}}>
                                    Cancel
                                </Typography>
                            </LoadingButton>
                        </Box>
                    }
                </Grid>
            </Grid>
        </Box>
    )
}