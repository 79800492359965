import {useState} from "react";
import {makeStyles, Button} from "@material-ui/core";
import {Link} from "react-router-dom";
import {Box} from "@material-ui/core";
import CurrencyFormat from "react-currency-format";
import dayjs from "dayjs";
import Typography from "@mui/material/Typography";
import {NODE_ROUTE_URI} from "../../../../Utils/apiUrl";
import axios from "axios";
import {Snackbar} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import * as React from "react";
import {getAccessToken} from "../../../../Utils/doToken";
import {useDispatch} from "react-redux";
import {refreshOrderInSearchPage} from "../../../../actions/searchFilterAction";
import {getLogo} from "../../../../Utils/Helper";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
    listItemWrapper: {
        width: "100%",
        margin: "3px auto",
        "&:hover": {
            cursor: "pointer",
            background: "aliceblue",
        },
        lineHeight: "2rem",
    },
    link: {
        textDecoration: "none",
    },
    idTD: {
        width: "100px",
        fontSize: '14px',

    },
    actionButton: {
        color: 'green'
    }

}));

const styles = {
    idText: {
        fontSize: '14px'
    },
    idTextBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    tableImage: {
        height: '15px',
        padding: '0 5px'
    },
}

const OrderPaymentListItem = ({orderEach}) => {
    const classes = useStyles();
    const accessToken = getAccessToken("access_token");

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const dispatch = useDispatch();

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const cancelBackendOrders = async () => {

        console.log('order id',orderEach?.order_id);

        let requestURL = `${NODE_ROUTE_URI}/looseItem/cancelBackendOrderByOrderId`;
        try {
            await axios({
                method: 'delete',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                params: {
                    backendOrderId: orderEach?.order_id
                }
            })
            console.log('success');
            setErrorMessage('');
            handleSuccessMessage();
            setSuccessMessage(`Order ${orderEach?.order_number} was successfully deleted.`);
            dispatch(refreshOrderInSearchPage(true));
        } catch (e) {
            setErrorMessage(`Deletion of Order ${orderEach?.order_number} failed.`);
            dispatch(refreshOrderInSearchPage(false));
        }
    }

    const customLink = () => {
        if (orderEach?.status_id === "0") {
            return <Link to={`/shippingOrderDetails/${orderEach?.order_id}`}>
                <Typography sx={styles.idText}>
                    {orderEach?.order_number}
                </Typography>
            </Link>
        } else if (orderEach?.status_id === "1") {
            return <Link to={`/order-dash-board/${orderEach?.shipping_order_id}`}>
                <Typography sx={styles.idText}>
                    {orderEach?.order_number}
                </Typography>
            </Link>
        } else {
            return <Typography sx={styles.idText}>
                {orderEach?.order_number}
            </Typography>
        }
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <tr className={classes.listItemWrapper}>
                <td className={classes.idTD}>
                    {customLink()}
                </td>
                <td className={classes.idTD}>
                    <Typography sx={styles.idText}>
                        {orderEach?.ref_order_no}
                    </Typography>
                </td>
                <td className={classes.idTD}>
                    <img
                        src={getLogo((orderEach?.account_base_combine_name?.split("-")[0]).toLowerCase())}
                        alt="logo"
                        style={styles.tableImage}
                    />
                    <Typography sx={styles.idText}>
                        {orderEach?.ship_service_type}
                    </Typography>
                </td>
                <td className={classes.idTD}>
                    <Typography sx={styles.idText}>
                        <CurrencyFormat value={(orderEach?.final_total || 0)} displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={`CAD $`}/>
                    </Typography>
                </td>
                <td className={classes.idTD}>
                    <Box sx={styles.idTextBox}>
                        <Typography sx={styles.idText}>
                            {orderEach?.sender}
                        </Typography>
                        <Typography sx={styles.idText}>
                            {orderEach?.sender_email}
                        </Typography>
                        <Typography sx={styles.idText}>
                            {orderEach?.pickup_phone}
                        </Typography>
                        <Typography sx={styles.idText}>
                            {orderEach?.pickup_address}
                        </Typography>
                        <Typography sx={styles.idText}>
                            {orderEach?.pickup_area}
                        </Typography>
                        <Typography sx={styles.idText}>
                            {orderEach?.pickup_postal_code}
                        </Typography>
                    </Box>
                </td>
                <td className={classes.idTD}>
                    <Box sx={styles.idTextBox}>
                        <Typography sx={styles.idText}>
                            {orderEach?.deliver_name}
                        </Typography>
                        <Typography sx={styles.idText}>
                            {orderEach?.deliver_email}
                        </Typography>
                        <Typography sx={styles.idText}>
                            {orderEach?.deliver_phone}
                        </Typography>
                        <Typography sx={styles.idText}>
                            {orderEach?.deliver_address}
                        </Typography>
                        <Typography sx={styles.idText}>
                            {orderEach?.deliver_area}
                        </Typography>
                        <Typography sx={styles.idText}>
                            {orderEach?.deliver_postal_code}
                        </Typography>
                    </Box>
                </td>
                <td className={classes.idTD}>
                    <Typography sx={styles.idText}>
                        {orderEach?.status_id === "-1" ? 'Cancelled' :
                            orderEach?.status_id === "0" ? 'Not Paid' :
                                orderEach?.status_id === "1" ? 'Paid' :
                                    (orderEach?.status_id === "3" || orderEach?.status_id === "4") && 'Refunded'
                        }
                    </Typography>
                </td>
                <td className={classes.idTD}>
                    {orderEach?.status_id === "0" &&
                        <Button
                            className={classes.actionButton}
                            onClick={cancelBackendOrders}
                        >
                            <Typography Typography sx={styles.idText}>
                                Cancel
                            </Typography>
                        </Button>
                    }
                </td>
            </tr>
        </>
    );
};

export default OrderPaymentListItem;
