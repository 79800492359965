import React, { useState, useEffect } from "react";
// import { getShipmentItems, getPickUpDetails } from '../../actions/shipmentAction';
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Button,
  Box,
  CircularProgress,
  Input,
  Snackbar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

// import PropTypes from "prop-types";
import axios from "axios";
import {
  BACKEND_GUEST_PRICE_ESTIMATE_API,
  CRM_ORDER_WITHOUT_PRICE,
} from "../../Utils/apiUrl";
// import { shipmentTypesDetails } from "./shared/constInfo";
// import LoadingContainer from "../shared/loadingContainer";
// import { add } from "../Utils/decimalOperation";
import { redirectHome } from "../../Utils/redirect";

import { shipmentTypesDetails } from "../shared/constInfo";
// import EditView from "../shared/editView";
import { errHandling } from "../../Utils/fetchUtil";
// import { getAccessToken } from "../../Utils/doToken";
// import {
//   GUEST_ORDER_PRICE_CHECK_COOKIE_NAME,
//   GUEST_ORDER_PRICE_CHECK_COOKIE_TIME,
// } from "../../Utils/config";
import LoadingContainer from "../shared/loadingContainer";
import PriceModule from "../shared/OrderDetail/priceModule";
import { provinces } from "../shared/constInfo";
import { setInitOrder } from "../../slices";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

// const getInitProvinceFullname = (initProv) => {
//   if (!initProv) return "";
//   return provinces.find((ele) => ele.value === initProv)?.label || "";
// };

const FFGuestCheckout = () => {
  const query = useQuery();
  // const dispatch = useDispatch();

  const initEmail = query.get("contact-email");
  const initName = query.get("contact-name");

  // const [userEmail, setUserEmail] = useState(initEmail || "");
  // const [phone, setPhone] = useState("");
  const initOriginPlace = query.get("origi_country_and_port");
  const initDestPlace = query.get("dest_country_and_port");
  // const initPickUpProvince = query.get("pickup_province");
  // const initDeliverProvince = query.get("deliver_province");

  // const [distance, setDistance] = useState("0");

  // const [creatingCrm, setCreatingStatus] = useState(false);
  // const [creatingCrmSuccess, setCrmSuccessState] = useState(false);

  const [routeError, setRouteError] = useState(false);

  const shipmentTypeID = query.get("shipment_type_id");
  const quantity = query.get("quantity");
  const shippingDays = query.get("shipping_days");
  const stops = query.get("stops");
  // const selectedDate = query.get("selectedDate");
  // const [selectedDate, setSelectDate] = useState(
  //   new Date(Date.now() + 1000 * 60 * 60 * 24)
  // );
  // const [fullName, setFullName] = useState(initName || "");
  // const [personalEmtpy, setPersonalEmpty] = useState(false);

  const [submitting, setSubmitStatus] = useState(true);

  const [submitCrmMsg, setCrmMsg] = useState("");

  // const [crmSuccess, setCrmSuccess] = useState(false);

  // const [fetchingPrice, setFetchingPriceState] = useState(false);
  // const [shippingPrice, setShippingPrice] = useState({});

  // const [priceErrMsg, setPriceErrMsg] = useState("");

  // const [checked, setChecked] = useState(false);

  const history = useHistory();

  // Get the estimate price using parameter from URL
  useEffect(() => {
    const postData = {
      pickup_area: initOriginPlace,
      deliver_area: initDestPlace,
      package_data: [
        {
          shipmentType: shipmentTypeID || "1",
          quantity: quantity,
        },
      ],
      message: `Shipping Days: ${shippingDays}, stops: ${stops}`,
    };

    console.log(postData);

    axios({
      method: "post",
      // url: BACKEND_ORDERS_API,
      url: `${CRM_ORDER_WITHOUT_PRICE}?name=${initName}&email=${initEmail}&phone=${""}`,
      // url: `http://localhost:9000/crm/lead?name=${fullName}&email=${userEmail}&phone=${phone}`,
      data: JSON.stringify(postData),
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    })
      .then((res) => {
        // const orderNum = orderResponse.data.success.order_number;
        // setCrmSuccess(true);
        setSubmitStatus(false);
      })
      .catch((error) => {
        setSubmitStatus(false);
        errHandling(error, setCrmMsg);
      });
  }, []);

  // const handleSubmitToOrm = async () => {
  //   setSubmitStatus(true);

  //   const postData = {
  //     pickup_area: `${initPickUpCity}, ${initPickUpProvince}`,
  //     deliver_area: `${initDeliverCity}, ${initDeliverProvince}`,
  //     package_data: [
  //       {
  //         shipmentType: shipmentTypeLabel,
  //         quantity: quantity,
  //       },
  //     ],
  //   };

  //   console.log(postData);

  //   try {
  //     await axios({
  //       method: "post",
  //       // url: BACKEND_ORDERS_API,
  //       url: `${CRM_ORDER_WITHOUT_PRICE}?name=${fullName}&email=${userEmail}&phone=${phone}`,
  //       // url: `http://localhost:9000/crm/lead?name=${fullName}&email=${userEmail}&phone=${phone}`,
  //       data: JSON.stringify(postData),
  //       headers: {
  //         "Content-Type": "application/json",
  //         "X-Requested-With": "XMLHttpRequest",
  //       },
  //     });
  //     // const orderNum = orderResponse.data.success.order_number;
  //     setCrmSuccess(true);
  //     if (!fetchingPrice) setSubmitStatus(false);
  //   } catch (error) {
  //     setSubmitStatus(false);
  //     errHandling(error, setCrmMsg);
  //   }
  // };

  const classes = useStyles();
  const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  // const handleRedirection = () => {
  //   dispatch(
  //     setInitOrder({
  //       shippingData: {
  //         estimateDetails: {
  //           pickupCity: initPickUpCity,
  //           pickupProvince: getInitProvinceFullname(initPickUpProvince),
  //           deliverCity: initDeliverCity,
  //           deliverProvince: getInitProvinceFullname(initDeliverProvince),
  //         },
  //         itemInfo: {
  //           selectedTypeID: shipmentTypeLabel,
  //           quantity: quantity,
  //         },
  //       },
  //     })
  //   );
  //   history.push({
  //     pathname: "/sign-in",
  //     state: { from: { pathname: "/shipping/start-a-quote" } },
  //   });
  // };

  return (
    <Box width="100%">
      <div
        className={matches ? classes.smRootContainer : classes.smRootContainer}
      >
        <Box width="100%" maxWidth="720px">
          <Box>
            <h2 className="orange-title">Quote Summary:</h2>
            <Box display="flex">
              <Box>
                <div className="subtitle">
                  <Box
                    className={classes.summaryHeader}
                    pb={1}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Box>Item</Box>
                  </Box>
                  <Box pb={1} pl={1} fontSize="1rem" color="rgba(0,0,0,0.8)">
                    {`${quantity} ${
                      shipmentTypesDetails[shipmentTypeID || "1"]?.label ===
                      "pallet"
                        ? "pallet(s)"
                        : "unit(s)"
                    } | ${
                      shipmentTypesDetails[shipmentTypeID || "1"]?.type_name ||
                      ""
                    }`}
                  </Box>

                  <Box
                    className={classes.summaryHeader}
                    pb={1}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Box>Original Country/Port</Box>
                  </Box>
                  <Box pb={1} pl={1} fontSize="1rem" color="rgba(0,0,0,0.8)">
                    {initOriginPlace}
                  </Box>

                  <Box
                    className={classes.summaryHeader}
                    pb={1}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Box>Destination Country/Port</Box>
                  </Box>
                  <Box pb={1} pl={1} fontSize="1rem" color="rgba(0,0,0,0.8)">
                    {initDestPlace}
                  </Box>
                </div>
              </Box>

              <Box ml={4}>
                <div className="subtitle">
                  <Box
                    className={classes.summaryHeader}
                    pb={1}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Box>Shipping Days</Box>
                  </Box>
                  <Box pb={1} pl={1} fontSize="1rem" color="rgba(0,0,0,0.8)">
                    {shippingDays}
                  </Box>

                  <Box
                    className={classes.summaryHeader}
                    pb={1}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Box>Stops</Box>
                  </Box>
                  <Box pb={1} pl={1} fontSize="1rem" color="rgba(0,0,0,0.8)">
                    {stops}
                  </Box>
                </div>
              </Box>
            </Box>
          </Box>
          <hr />

          {submitting && <LoadingContainer />}

          {!submitting &&
            (submitCrmMsg ? (
              <Box textAlign="center" fontSize="1.2rem">
                <CheckCircleOutlineIcon
                  style={{ fontSize: "4rem", color: "green" }}
                />
                <br />
                {submitCrmMsg}
              </Box>
            ) : (
              <>
                <Box textAlign="center" fontSize="1.2rem">
                  <CheckCircleOutlineIcon
                    style={{ fontSize: "4rem", color: "green" }}
                  />
                  <br />
                  Your request has been submitted.
                  <br /> We'll contact you later
                </Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  ml={2}
                  mr={2}
                  mt={5}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      redirectHome(history);
                    }}
                    style={{ width: "12rem", fontSize: "1rem" }}
                  >
                    Go to home page
                  </Button>
                </Box>
              </>
            ))}
        </Box>
      </div>

      {/* <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={submitCrmMsg}
        onClose={() => setCrmMsg("")}
        message="Failed to submit the order"
        autoHideDuration={3000}
      /> */}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    borderRadius: 5,
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    // backgroundColor: "#FEEAAE",
    backgroundColor: "white",
  },
  rootContainer: {
    margin: "0 auto",
    width: "550px",
    minWidth: "500px",
    paddingLeft: "3vw",
    paddingRight: "3vw",
  },
  smRootContainer: {
    width: "100%",
    paddingLeft: "3vw",
    paddingRight: "3vw",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  alertRoot: {
    width: "100%",
    minWidth: "200px",
    margin: "10px auto",
  },
  fixWidthButton: {
    width: "15rem",
  },
  fixWidthBigButton: {
    width: "20rem",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "20px",
    // padding: theme.spacing(2, 4, 3),
    margin: "0 auto",
    overflow: "hidden",
    width: "100%",
  },
  bigButton: {
    borderRadius: 20,
    fontSize: "1.2rem",
    backgroundColor: "#ffa726",
    color: "white",
    width: "18rem",
  },
  button: {
    borderRadius: 20,
    fontSize: "1.2rem",
    backgroundColor: "#ffa726",
    color: "white",
    width: "14rem",
  },
  smButton: {
    borderRadius: 20,
    fontSize: "1rem",
    backgroundColor: "#ffa726",
    color: "white",
    width: "12rem",
  },
  addButton: {
    borderRadius: 20,
    fontSize: "1rem",
    backgroundColor: "#ffa726",
    color: "white",
    width: "8rem",
    height: "2.4rem",
  },
  textfield: {
    width: "100%",
  },
  summaryHeader: {
    fontSize: "16pt",
    color: "seagreen",
    fontFamily: "Montserrat",
    // borderBottom: '1px, solid rgb(141, 173, 155)',
  },
  textButton: {
    color: "blue",
  },
  bodyText: {
    fontSize: "1rem",
    color: "seagreen",
    fontFamily: "Montserrat",
  },
  editBorder: {
    borderRadius: "5px",
    border: "1px solid rgba(0,0,0,0.5)",
    borderBottom: "2px solid rgba(0,0,0,0.5)",
    padding: theme.spacing(1),
  },
  dialogTitle: {
    padding: "1vh 1vw 0.5vh",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "rgb(141, 173, 155)",
  },
  fixHeightButton: {
    height: "2.4rem",
  },
  errorColor: {
    color: "red",
  },
}));

export default FFGuestCheckout;
