import Typography from "@mui/material/Typography";
import {Button, IconButton, MenuItem, Select, Snackbar} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Divider from "@mui/material/Divider";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {countryListAllIsoData, getMissingKeys, getMissingKeysForMyBoxes, UOMList} from "../../Utils/Helper";
import {useState} from "react";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {toggleAddAddress} from "../../actions/addressBookAction";
import {toggleAddMyBoxes} from "../../actions/myBoxesAction";
import {useDispatch} from "react-redux";
import {getAccessToken} from "../../Utils/doToken";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
    MyBoxesAddRoot: {
        backgroundColor: '#FFFFFF',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        borderRadius: '15px'
    },
    MyBoxesAddContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    MyBoxesAddSection: {
        display: 'flex',
        flexWrap: 'none',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '20px',
        margin: '15px'
    },
    MyBoxesAddSectionItem: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    MyBoxesAddCrossBorder: {
        display: 'flex',
        flexDirection: 'column',
        margin: '15px',
        gap: '20px',
    },
    MyBoxesAddSubmit: {
        display: 'flex',
        justifyContent: 'center'
    },
    MyBoxesAddCloseButton: {
        position: 'absolute',
        right: 0
    },
    MyBoxesAddErrorText: {
        fontSize: '12px',
        color: "#FF0303"
    },
}

export const MyBoxesAdd = () => {

    const accessToken = getAccessToken("access_token");

    const [boxInfo, setBoxInfo] = useState({
        currency: 'CAD',
        dimensionUnit: 'cm',
        weightUnit: 'kg',
        uom: 'BOX',
        originCountry: 'CA',
        boxName: '',
        itemName: '',
        unitPrice: '',
        length: '',
        width: '',
        height: '',
        weight: '',
        hsCode: '',
    });
    const [HScodeList, setHScodeList] = useState([]);
    const [missingValue, setMissingValue] = useState([]);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const dispatch = useDispatch();

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const getHSCode = async (queryString) => {

        let requestURL = `${NODE_ROUTE_URI}/elasticsearch/search`;
        console.log('query String', queryString);
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    query_string: queryString
                }
            })
            const {data: {hits}} = result;
            const hsCodeList = hits?.map(item => {
                // console.log(item["_source"])
                return {
                    hscode: item["_source"].hscode,
                    desc: item["_source"].desc
                }
            })
            console.log('hs code list', hsCodeList);
            setHScodeList(hsCodeList);
        } catch (e) {
            console.log(e)
        }
    }

    const createMyBoxForEndUser = async () => {

        const data = {
            currency: boxInfo?.currency,
            dimensionUnit: boxInfo?.dimensionUnit,
            weightUnit: boxInfo?.weightUnit,
            uom: boxInfo?.uom,
            originalCountry: boxInfo?.originCountry,
            boxName: boxInfo?.boxName,
            itemName: boxInfo?.itemName,
            unitPrice: boxInfo?.unitPrice || '0',
            length: boxInfo?.length || '0',
            width: boxInfo?.width || '0',
            height: boxInfo?.height || '0',
            weight: boxInfo?.weight || '0',
            hsCode: boxInfo?.hsCode,
        }

        let requestURL = `${NODE_ROUTE_URI}/myBoxes/createMyBoxForEndUser`;
        try {
            await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: data
            })
            setErrorMessage('');
            setSuccessMessage('Successfully Add Box');
            handleSuccessMessage();
            setTimeout(() => dispatch(toggleAddMyBoxes(false)), 2000);

        } catch (e) {
            console.log(e.response)
            setErrorMessage('Failed to add box. Please ensure that the name of your box is unique from your list of boxes.');
            handleErrorMessage();

        }
    }

    const validateMissingValue = () => {
        const listOfMissingValue = getMissingKeysForMyBoxes(boxInfo);
        // console.log('list of missing value', listOfMissingValue);
        setMissingValue(listOfMissingValue);
        if (listOfMissingValue.length === 0) {
            return true
        } else {
            return false
        }
    }

    const handleCloseIcon = () => {
        dispatch(toggleAddMyBoxes(false));
    }

    const handleAddBox = () => {
        validateMissingValue();
        if (validateMissingValue()) {
            // console.log('call api create box')
            createMyBoxForEndUser();
        }
    }

    return (
        <Box sx={style.MyBoxesAddRoot}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Typography variant='h5' sx={{textAlign: 'center'}}>
                Add Box
            </Typography>
            <IconButton
                onClick={handleCloseIcon}
                sx={style.MyBoxesAddCloseButton}>
                <CloseIcon/>
            </IconButton>
            <Box sx={style.MyBoxesAddContainer}>
                <Box sx={style.MyBoxesAddSection}>
                    <Box sx={style.MyBoxesAddSectionItem}>
                        <TextField
                            value={boxInfo.boxName}
                            label="Box Name"
                            required
                            fullWidth
                            variant="standard"
                            onInput={e => setBoxInfo(prevState => ({
                                ...prevState, boxName: e.target.value
                            }))}
                        />
                        {missingValue.includes('boxName') ?
                            <p style={style.MyBoxesAddErrorText}>This is a required field.</p> : ""}
                    </Box>
                    <Box sx={style.MyBoxesAddSectionItem}>
                        <TextField
                            value={boxInfo.itemName}
                            label="Item Name"
                            fullWidth
                            variant="standard"
                            onInput={e => setBoxInfo(prevState => ({
                                ...prevState, itemName: e.target.value
                            }))}
                        />
                    </Box>
                </Box>
                <Box sx={style.MyBoxesAddSection}>
                    <Box sx={style.MyBoxesAddSectionItem}>
                        <TextField
                            value={boxInfo?.length}
                            label="Length"
                            fullWidth
                            variant="standard"
                            onInput={e => setBoxInfo(prevState => ({
                                ...prevState, length: e.target.value
                            }))}
                            sx={{marginTop: '24px'}}
                        />
                    </Box>
                    <Box sx={style.MyBoxesAddSectionItem}>
                        <TextField
                            value={boxInfo?.width}
                            label="Width"
                            variant="standard"
                            fullWidth
                            onInput={e => setBoxInfo(prevState => ({
                                ...prevState, width: e.target.value
                            }))}
                            sx={{marginTop: '24px'}}
                        />
                    </Box>
                    <Box sx={style.MyBoxesAddSectionItem}>
                        <TextField
                            value={boxInfo?.height}
                            label="Height"
                            fullWidth
                            variant="standard"
                            onInput={e => setBoxInfo(prevState => ({
                                ...prevState, height: e.target.value
                            }))}
                            sx={{marginTop: '24px'}}
                        />
                    </Box>
                    <FormControl variant="standard" fullWidth>
                        <InputLabel>Length Unit</InputLabel>
                        <Select
                            value={boxInfo?.dimensionUnit}
                            label="length unit"
                            onChange={e => setBoxInfo(prevState => ({
                                ...prevState, dimensionUnit: e.target.value
                            }))}
                        >
                            <MenuItem value={'cm'}>cm</MenuItem>
                            <MenuItem value={'in'}>in</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={style.MyBoxesAddSection}>
                    <Box sx={style.MyBoxesAddSectionItem}>
                        <TextField
                            value={boxInfo?.weight}
                            label="Weight"
                            fullWidth
                            variant="standard"
                            onInput={e => setBoxInfo(prevState => ({
                                ...prevState, weight: e.target.value
                            }))}
                            sx={{marginTop: '24px'}}
                        />
                    </Box>
                    <FormControl variant="standard" fullWidth>
                        <InputLabel>Weight Unit</InputLabel>
                        <Select
                            value={boxInfo?.weightUnit}
                            label="weight unit"
                            onChange={e => setBoxInfo(prevState => ({
                                ...prevState, weightUnit: e.target.value
                            }))}
                        >
                            <MenuItem value={'lb'}>lb</MenuItem>
                            <MenuItem value={'kg'}>kg</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                {/*<Box sx={style.MyBoxesAddCrossBorder}>*/}
                {/*    <Typography sx={{fontWeight: '600', fontStyle: 'italic'}}>*/}
                {/*        Cross Border Use Only*/}
                {/*    </Typography>*/}
                {/*    <Divider/>*/}
                {/*</Box>*/}
                {/*<Box sx={style.MyBoxesAddSection}>*/}
                {/*    <Autocomplete*/}
                {/*        disablePortal*/}
                {/*        options={HScodeList}*/}
                {/*        fullWidth*/}
                {/*        freeSolo*/}
                {/*        filterOptions={(options, state) => options}*/}
                {/*        getOptionLabel={(option) => `${option.hscode}`}*/}
                {/*        renderOption={(option) => <span><b>{option?.hscode}</b> | {option.desc}</span>}*/}
                {/*        onInputChange={async (e, value) => {*/}
                {/*            setBoxInfo(prevState => ({*/}
                {/*                ...prevState, hsCode: value*/}
                {/*            }))*/}
                {/*            getHSCode(value);*/}

                {/*        }}*/}
                {/*        renderInput={(params) => <TextField*/}
                {/*            multiline*/}
                {/*            id="outlined-basic"*/}
                {/*            label='Serach HSCode'*/}
                {/*            variant="standard"*/}
                {/*            fullWidth*/}
                {/*            required*/}
                {/*            {...params}*/}
                {/*        />}*/}
                {/*    />*/}
                {/*    <TextField*/}
                {/*        value={boxInfo?.unitPrice}*/}
                {/*        required*/}
                {/*        fullWidth*/}
                {/*        label="Unit Price"*/}
                {/*        variant="standard"*/}
                {/*        onInput={e => setBoxInfo(prevState => ({*/}
                {/*            ...prevState, unitPrice: e.target.value*/}
                {/*        }))}*/}
                {/*        // sx={{paddingTop: '24px'}}*/}
                {/*    />*/}
                {/*</Box>*/}
                {/*<Box sx={style.MyBoxesAddSection}>*/}
                {/*    <FormControl fullWidth variant="standard">*/}
                {/*        <InputLabel>Origin Country*</InputLabel>*/}
                {/*        <Select*/}
                {/*            value={boxInfo?.originCountry}*/}
                {/*            label="origin country"*/}
                {/*            onChange={e => setBoxInfo(prevState => ({*/}
                {/*                ...prevState, originCountry: e.target.value*/}
                {/*            }))}*/}
                {/*        >*/}
                {/*            {*/}
                {/*                countryListAllIsoData.map(v => {*/}
                {/*                    return <MenuItem value={v.code} key={v.code}>{v.name}</MenuItem>*/}
                {/*                })*/}
                {/*            }*/}
                {/*        </Select>*/}
                {/*    </FormControl>*/}
                {/*    <FormControl fullWidth variant="standard">*/}
                {/*        <InputLabel>Unit of Measure*</InputLabel>*/}
                {/*        <Select*/}
                {/*            value={boxInfo?.uom}*/}
                {/*            label="unit of measure"*/}
                {/*            onChange={e => setBoxInfo(prevState => ({*/}
                {/*                ...prevState, uom: e.target.value*/}
                {/*            }))}*/}
                {/*        >*/}
                {/*            {UOMList.map(v => {*/}
                {/*                return <MenuItem value={v} key={v}>{v}</MenuItem>*/}
                {/*            })}*/}
                {/*        </Select>*/}
                {/*    </FormControl>*/}
                {/*</Box>*/}
                <Box sx={style.MyBoxesAddSubmit}>
                    <Button
                        onClick={handleAddBox}
                    >
                        Add
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}