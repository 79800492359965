import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {InputAdornment, Link} from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import SearchIcon from "@material-ui/icons/Search";
import CircularProgress from "@mui/material/CircularProgress";
import {useState} from "react";
import {getAccessToken} from "../../Utils/doToken";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {makeStyles} from "@material-ui/core";
import {TrackingItem} from "./TrackingItem";
import LoadingButton from "@mui/lab/LoadingButton";
import intl from "react-intl-universal";

const styles = {
    TrackingRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '25vh',
        height: '100%'
    },
    TrackingContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        height: '100%'
    },
}

const useStyles = makeStyles({
    endAdornment: {
        paddingRight: 1
    },
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
    Button: {
        backgroundColor: '#1D8B45',
        color: '#FFFFFF',
        height: '100%',
        borderRadius: '5px',
        padding: '5px 20px',
        "&:hover": {
            backgroundColor: '#1D8B45',
            color: '#FFFFFF',
            filter: 'brightness(0.9)'
        },
    }
});

export const TrackingSinglePackage = () => {

    const classes = useStyles();

    const storedToken = getAccessToken("access_token");

    const [trackingNumbers, setTrackingNumbers] = useState("");
    const [loading, setLoading] = useState(false);
    const [isTrackEmpty, setIsTrackEmpty] = useState(false);
    const [trackedPackagesList, setTrackedPackagesList] = useState([]);
    const [error, setError] = useState(false);

    const getTrackingPackageList = async () => {
        setLoading(true);
        try {
            const requestURL = `${NODE_ROUTE_URI}/looseItem/multipleTrackingForWeb`;
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`
                },
                params: {
                    trackingNumbers: trackingNumbers
                }
            })
            console.log('tracking result', result);
            const trackingData = result?.data;
            const trackingList = trackingData?.filter(v => v?.trackingResponse !== null);
            setIsTrackEmpty(trackingList?.length === 0);
            setTrackedPackagesList(trackingList);

        } catch (e) {
            console.log('error', e.response);
        } finally {
            setLoading(false);
        }
    }

    const handleTrackingNumber = (event) => {
        setTrackingNumbers(event.target.value);
    }

    const handleTrackingNumberByKeyDown = (event) => {
        if (event.key === 'Enter') {
            // setOrderData([]);
            getTrackingPackageList();
        }
    }

    const handleTacking = () => {
        getTrackingPackageList();
    }

    return (
        <Box sx={styles.TrackingRoot}>
            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Typography style={{fontSize: '16px', fontWeight: 600, textAlign: 'left'}}>
                    {intl.get('HOME_PAGE.TRACKING.TRACKING')}
                </Typography>
                <Link
                    href='/tracking'
                    style={{
                        textDecoration: 'none'
                    }}
                >
                    <Typography style={{
                        fontSize: '12px', fontWeight: 600, textAlign: 'right', color: '#1D8B45'
                    }}>
                        {intl.get('HOME_PAGE.TRACKING.BATCH_TRACKING')}
                    </Typography>
                </Link>
            </Box>
            <Box sx={styles.TrackingContent}>
                <Grid container spacing={0}>
                    <Grid item xs={9}>
                        <TextField
                            value={trackingNumbers}
                            fullWidth
                            size='small'
                            onInput={handleTrackingNumber}
                            onKeyDown={handleTrackingNumberByKeyDown}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon/>
                                    </InputAdornment>
                                ),
                            }}
                            classes={{root: classes.customTextField}}
                            placeholder={intl.get('HOME_PAGE.TRACKING.TRACKING_PLACEHOLDER')}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            height: '100%'
                        }}>
                            <LoadingButton
                                variant='contained'
                                sx={{
                                    backgroundColor: '#1D8B45',
                                    color: '#FFFFFF',
                                    height: '100%',
                                    borderRadius: '5px',
                                    padding: '5px 20px',
                                    "&:hover": {
                                        backgroundColor: '#1D8B45',
                                        color: '#FFFFFF',
                                        filter: 'brightness(0.9)'
                                    },
                                }}
                                // size='small'
                                onClick={handleTacking}
                                disabled={!trackingNumbers}
                                loading={loading}
                            >
                                <Typography
                                    style={{textTransform: 'none', fontSize: '16px'}}
                                >
                                    {intl.get('HOME_PAGE.TRACKING.TRACK')}
                                </Typography>
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
                {
                    loading ?
                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                            <CircularProgress/>
                        </Box>
                        :
                        error ?
                            <Box sx={styles.ShippingOrderContent}>
                                <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                    {intl.get('HOME_PAGE.ERROR')}
                                </Typography>
                            </Box>
                            :
                            isTrackEmpty ?
                                <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                    {intl.get('HOME_PAGE.TRACKING.NO_RESULTS')}
                                </Typography> : trackedPackagesList?.map((trackedPackage, index) => {
                                    return (
                                        <TrackingItem key={index}
                                                      activity={trackedPackage.trackingResponse ? trackedPackage.trackingResponse.activity : undefined}
                                                      trackingNo={trackedPackage.trackingNumber}
                                                      courier={trackedPackage.accountBase}
                                                      deliverStatus={trackedPackage.trackingResponse.status}
                                                      transaction={trackedPackage.transaction}
                                        />
                                    )
                                })
                }
            </Box>
        </Box>
    )
}
