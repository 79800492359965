import React, { useState } from "react";
import {
  Grid,
  Paper,
  makeStyles,
  Typography,
  Button,
  Divider,
} from "@material-ui/core";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import ShipmentOptionLayout from "./ShipmentOptionsLayout";
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    width: "100%",
    maxWidth: "700px",
    margin: "5px auto",
    backgroundColor: "#fdf5e8",
    padding: "3px",
    cursor: "pointer",
    "&:hover": {
      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    },
  },
  img: {
    margin: "auto",
    display: "block",
    maxHeight: "150px",
    maxWidth: "200px",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
  },
  container: {
    width: "80%",
    textAlign: "center",
    backgroundColor: "#fbfbf8",
    border: "1px solid #ded47c",
    borderRadius: "5px",
    margin: "0px auto",
  },
  horiDivider: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  description: {
    display: "flex",
    flexDirection: "column",
  },
}));

const LoadedShipmentListItem = ({ shipment }) => {
  console.log("loaded shipments", shipment);
  const classes = useStyles();
  const history = useHistory();
  const imageSrc = shipment.cover_image;
  const itemName = shipment.package.item_name;
  const pickUpAddress = shipment.pickup_address;
  const pickupCity = shipment.pickup_city;
  const deliverCity = shipment.deliver_city;
  const deliverAddress = shipment.deliver_address;
  const pickupDate = shipment.schedule_pickup.split(" ")[0];
  const shipmentType = shipment.shipment_type;
  const trackingNumberCount =
    shipment.tracking_numbers?.length > 1
      ? ` x ${shipment.tracking_numbers.length}`
      : "";
  const cardActive = useSelector(state => {
    return state.user.activeCard === "active";
  });
  const [open, setOpen] = useState(false);

  const handleGoToShipmentDetailPage = () => {
    if (cardActive) {
      if (shipment.shipment_number) {
        history.push(`/driver/loaded_shipments/${shipment.shipment_number}`);
      }
    } else {
      //show dialog let user bind credit card
      setOpen(true);
    }
  };

  const goToBindCreditCard = () => {
    history.push("/profile/add-card");
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Paper className={classes.paper} onClick={handleGoToShipmentDetailPage}>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <img alt="package" src={imageSrc} className={classes.img} />
            <Typography variant="h6">{itemName}</Typography>
            <Divider className={classes.horiDivider} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <div className={classes.description}>
              <Typography variant="caption" align="left">
                Pickup from: {cardActive ? pickUpAddress : pickupCity}
              </Typography>
              <Typography variant="caption" align="left">
                Deliver to: {cardActive ? deliverAddress : deliverCity}
              </Typography>
              <Typography variant="caption" align="left">
                Pickup Date: {pickupDate}
              </Typography>
              <Typography variant="caption" align="left">
                {shipmentType}
                {trackingNumberCount}
              </Typography>
            </div>
            <div>
              <ShipmentOptionLayout optionList={shipment.option_list} />
            </div>
            <Divider />
          </Grid>
        </Grid>
      </Paper>
      {
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">
            {"Please bind your credit card."}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Binding a credit card allow you to access more information of
              shipments.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={goToBindCreditCard}
              variant="contained"
            >
              Bind my credit card
            </Button>
            <Button onClick={handleClose} variant="contained">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      }
    </>
  );
};

export default LoadedShipmentListItem;
