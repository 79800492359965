import {useParams} from "react-router-dom";
import {getAccessToken} from "../../../Utils/doToken";
import {Container} from "@mui/material";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import {useEffect, useState} from "react";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {BusinessReturnLabelRating} from "./BusinessReturnLabelRating";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import {generateRandomString} from "../../../Utils/Helper";
import {initShippingPackageCrossBorderItemList} from "../../../actions/BusinessShippingPackageAction";
import {useDispatch} from "react-redux";

const styles = {
    ShippingPackageCreateLabelRateRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelRateTitle: {
        fontSize: '24px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelRateHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelRateSubHeading: {
        fontSize: '16px',
        fontWeight: '600',
        paddingLeft: '10px'
    },
    ShippingPackageCreateLabelRateOtherBox: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        height: '100%',
        flexDirection: 'column'
    },
    ShippingPackageCreateLabelRateImageBox: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        paddingLeft: '20px',
        gap: '10px',
        flexDirection: 'column'
    },
    ShippingPackageCreateLabelRateOtherBoxMobile: {
        display: 'flex',
        alignItems: 'flex-start',
        paddingLeft: '20px',
        justifyContent: 'center',
        height: '100%',
        flexDirection: 'column'
    },
}

export const BusinessReturnLabel = () => {

    const storedToken = getAccessToken("access_token");
    const {tranId} = useParams();

    const [loadingOrder, setLoadingOrder] = useState(false);
    const [orderData, setOrderData] = useState(null);
    const [orderError, setOrderError] = useState(false);

    console.log('[BusinessReturnLabel] tran id', tranId);

    const getOrderData = async () => {

        setLoadingOrder(true);

        try {

            const requestURI = `${PARTNER_URI}/shipping/list/getReturnShipmentInfoByTranId`;
            const result = await axios({
                method: 'get',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    tranId: tranId
                }
            })

            setOrderData(result.data);
            setOrderError(false);

        } catch (e) {
            console.log('[BusinessReturnLabel] getOrderData api error', e?.response);
            setOrderError(true);
        } finally {
            setLoadingOrder(false)
        }
    }

    useEffect(() => {
        (async () => {
                await getOrderData();
            }
        )()
    }, []);

    console.log('[BusinessReturnLabel] order data', orderData);

    if (loadingOrder) {
        return (
            <Container maxWidth='xl'>
                <Grid container spacing={4}>
                    <Grid item md={12} xs={12}>
                        <Typography style={styles.ShippingPackageCreateLabelRateTitle}>
                            Return Label
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <CircularProgress/>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        )
    }

    if (orderError) {
        return (
            <Container maxWidth='xl'>
                <Grid container spacing={4}>
                    <Grid item md={12} xs={12}>
                        <Typography style={styles.ShippingPackageCreateLabelRateTitle}>
                            Return Label
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <Typography style={styles.ShippingPackageCreateLabelRateHeading}>
                                Sorry We currently encounter some issues, please try again later.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        )
    }

    return (
        <Container maxWidth='xl'>
            <Grid container spacing={4}>
                <Grid item md={12} xs={12}>
                    <Typography style={styles.ShippingPackageCreateLabelRateTitle}>
                        Return Label
                    </Typography>
                </Grid>
                <Grid item md={12} xs={12}>
                    {orderData && <BusinessReturnLabelRating orderData={orderData} tranId={tranId}/>}
                </Grid>
            </Grid>
        </Container>
    )
}