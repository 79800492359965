import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {Fragment, useEffect, useState} from "react";
import Divider from "@mui/material/Divider";
import {getAccessToken} from "../../Utils/doToken";
import {LABEL_DOWNLOAD_URL_PREFIX, LABEL_DOWNLOAD_URL_PREFIX_ALTERNATE, NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {useHistory, useParams} from "react-router-dom";
import {MovingServiceCreditCard} from "../Moving/MovingServiceCreditCard";
import {Button, Snackbar} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import MuiAlert from "@mui/material/Alert";
import {ReactComponent as ShipmentOverviewIcon} from "../../images/NewDesignIcon/ShipmentOverview.svg";
import {ReactComponent as ShippingFromAndToIcon} from "../../images/NewDesignIcon/ShippingLocation.svg";
import intl from "react-intl-universal";
import {ReactComponent as ContactIcon} from "../../images/NewDesignIcon/AddressBook.svg";
import {ReactComponent as SchedulePickupIcon} from "../../images/NewDesignIcon/SchedulePickup.svg";
import dayjs from "dayjs";
import ImageIcon from "@mui/icons-material/Image";
import Carousel from "react-material-ui-carousel";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Paper from "@mui/material/Paper";
import {ReactComponent as PackageDetailIcon} from "../../images/NewDesignIcon/Package.svg";
import {ReactComponent as PackageTypeIcon} from "../../images/NewDesignIcon/AdditionalDetails.svg";
import {ReactComponent as PaidRuleIcon} from "../../images/NewDesignIcon/ChooseCarrierAndServices.svg";
import {formatCurrency} from "../../Utils/Helper";
import {ReactComponent as RateDetailsIcon} from "../../images/NewDesignIcon/RateDetails.svg";
import {ReactComponent as NoteIcon} from "../../images/NewDesignIcon/OtherInfo.svg";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingPackageCreateLabelOrderDetailsRoot: {
        backgroundColor: '#FFFFFF',
        paddingBottom: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0'
    },
    ShippingPackageCreateLabelOrderDetailsHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsSubHeading: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsTitle: {
        fontSize: '16px',
        textAlign: 'center'
    },
    ShippingPackageCreateLabelOrderDetailsTextLeft: {
        fontSize: '16px',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsTextRight: {
        fontSize: '16px',
        textAlign: 'right'
    },
    ShippingPackageCreateLabelOrderDetailsTitleRight: {
        fontSize: '18px',
        textAlign: 'right',
        fontWeight: '600'
    },
    ShippingPackageCreateLabelOrderDetailsSection: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #A9A9A9',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelOrderDetailsSectionPrice: {
        // backgroundColor: '#FFFFFF',
        padding: '20px',
        // borderRadius: '10px 10px 0 0',
        // border: '2px solid #609966',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelOrderDetailsOtherBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsOtherBoxRight: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsOtherBoxCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsImageBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingLeft: '20px',
        gap: '10px'
    },
    MovingServiceAdditionalFeeSectionHeadingCost: {
        fontWeight: '600',
        textAlign: 'right'
    },
}

export const MovingOrderDetailsContent = ({orderDetails}) => {

    const {orderId} = useParams();

    const [additionalData, setAdditionalData] = useState(null);
    const [additionalService, setAdditionalService] = useState([]);

    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [creditCard, setCreditCard] = useState(null);
    const [additionalServiceFee, setAdditionalServiceFee] = useState();

    const getAdditionServiceFee = async () => {
        let requestURL = `${NODE_ROUTE_URI}/movingService/getMovingCategoryAreaPriceByCategoriesAndAreas`;

        const cateIdArray = [];

        orderDetails?.backendPackages?.forEach(item => {
            cateIdArray.push(item?.cate_id);
        })

        const data = {
            cateIdArray,
            pickupCity: orderDetails?.pickup_area?.split(',')[0].trim(),
            pickupProvince: orderDetails?.pickup_area?.split(',')[1].trim(),
            deliverCity: orderDetails?.deliver_area?.split(',')[0].trim(),
            deliverProvince: orderDetails?.deliver_area?.split(',')[1].trim()
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })

            console.log('addition service', result);
            setAdditionalServiceFee(result.data);
        } catch (e) {
            console.log(e.response);
        }
    }

    const history = useHistory();

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const cbGetCreditCard = (cardId) => {
        setCreditCard(cardId);
    }

    const isValidJSON = (jsonString) => {
        try {
            JSON.parse(jsonString);
            return true;
        } catch (error) {
            return false;
        }
    }

    const handleError = (errorCode) => {
        switch (errorCode) {
            case 400:
                setErrorMessage("Bad Request: The server cannot process the request.");
                break;
            case 401:
                setErrorMessage("Unauthorized: Authentication is required to access the resource.");
                break;
            case 404:
                setErrorMessage("Not Found: The requested resource could not be found.");
                break;
            case 500:
                setErrorMessage("Internal Server Error: The server encountered an unexpected condition.");
                break;
            default:
                setErrorMessage("Unknown Error: An unknown error occurred.");
                break;
        }
    }

    const payWithCreditCard = async () => {
        setLoading(true);
        const accessToken = getAccessToken("access_token");
        let requestURL = `${NODE_ROUTE_URI}/movingService/payMovingDownPaymentViaCreditCard`;

        try {
            const result = await axios({
                method: 'post',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                url: requestURL,
                data: {
                    backendOrderId: orderId
                }
            });

            console.log(result);
            setLoading(false);
            setDisabled(true);
            setErrorMessage('');
            handleSuccessMessage();
            setSuccessMessage('Successfully pay deposit and place order.');
            setTimeout(() => {
                history.push(`/transportation/orderDashboard/${orderId}`);
            }, 2000);

        } catch (e) {
            setLoading(false);
            console.log(e.response.error);
            const errorCode = e?.response?.data?.error?.status;
            console.log('error code', errorCode);
            handleErrorMessage();
            handleError(errorCode);

        }
    }

    const handlePayment = () => {
        payWithCreditCard();
    }

    useEffect(() => {
        getAdditionServiceFee();

        if (orderDetails?.messages) {
            const jsonString = orderDetails?.messages
            if (isValidJSON(jsonString)) {
                const jsonData = JSON.parse(jsonString);
                setAdditionalData(jsonData);
                const additionalServicesArray = Object.keys(jsonData?.additionalService)
                    .filter(service => jsonData?.additionalService[service] === true)
                    .map(service => {
                        const capitalizedService = service.charAt(0).toUpperCase() + service.slice(1);
                        return capitalizedService.replace(/([a-z])([A-Z])/g, '$1 $2');
                    });
                console.log('[MovingOrderDashboardTransaction] additionalServicesArray', additionalServicesArray);
                setAdditionalService(additionalServicesArray);
            }
        }
    }, [orderDetails]);

    console.log('[MovingOrderDetailsContent] orderDetails', orderDetails);

    console.log('[MovingOrderDetailsContent] additionalData', additionalData);

    return (
        <Box sx={styles.ShippingPackageCreateLabelOrderDetailsRoot}>
            <Box sx={{
                display: 'flex',
                gap: '10px',
                padding: '20px 20px 0 20px'
            }}>
                <ShipmentOverviewIcon width={25} height={25}/>
                <Typography style={styles.ShippingPackageCreateLabelOrderDetailsSubHeading}>
                    Place Order
                </Typography>
            </Box>
            <Divider/>
            <Box sx={{
                backgroundColor: '#FFFFFF',
                padding: '0 40px',
                gap: '40px',
                display: 'flex',
            }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Box sx={{display: 'flex', width: '100%', flexDirection: 'column', gap: '60px'}}>
                            <Grid container spacing={1}>
                                <Grid item xs={1}>
                                    <ShippingFromAndToIcon width={20} height={20}/>
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography sx={{fontWeight: '600'}}>
                                        Moving From
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>
                                <Grid item xs={12} md={orderDetails?.pickup_addr_type === 'self' ? 12 : 6}>
                                    <Typography>
                                        {orderDetails?.pickup_addr_type?.toLowerCase() === 'self' ? intl.get('MOVING.SELF_DROP_OFF') : intl.get('MOVING.SCHEDULED_PICKUP')}
                                    </Typography>
                                    <Typography>
                                        {orderDetails?.pickup_company}
                                    </Typography>
                                    <Typography>
                                        {orderDetails?.pickup_address}
                                    </Typography>
                                    <Typography>
                                        {orderDetails?.pickup_area}
                                    </Typography>
                                    <Typography>
                                        {orderDetails?.send_from_country}, {orderDetails?.pickup_postal_code}
                                    </Typography>
                                </Grid>
                                {
                                    orderDetails?.pickup_addr_type !== 'self' &&
                                    <Grid item xs={12} md={6}>
                                        {/*<Typography>*/}
                                        {/*    Property Type: {additionalData?.movingFromAddress?.property?.propertyType}*/}
                                        {/*</Typography>*/}
                                        <Typography>
                                            House Type: {additionalData?.movingFromAddress?.propertyType?.houseType}
                                        </Typography>
                                        <Typography>
                                            Floor Level: {additionalData?.movingFromAddress?.floorLevel}
                                        </Typography>
                                        <Typography>
                                            Lift: {additionalData?.movingFromAddress?.lift ? 'Yes' : 'No'}
                                        </Typography>
                                        {/*<Typography>*/}
                                        {/*    Distance from Truck to*/}
                                        {/*    Door: {additionalData?.movingFromAddress?.distanceFromTruckToDoor}*/}
                                        {/*</Typography>*/}
                                        {/*<Typography>*/}
                                        {/*    Staircase Number: {additionalData?.movingFromAddress?.staircaseNumber}*/}
                                        {/*</Typography>*/}
                                        {/*<Typography>*/}
                                        {/*    Narrow*/}
                                        {/*    Street: {additionalData?.movingFromAddress?.isNarrowStreet ? "YES" : "NO"}*/}
                                        {/*</Typography>*/}
                                        {/*<Typography>*/}
                                        {/*    Steep*/}
                                        {/*    Driveway: {additionalData?.movingFromAddress?.isSteepDriveway ? "YES" : "NO"}*/}
                                        {/*</Typography>*/}
                                    </Grid>
                                }
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={1}>
                                    <ShippingFromAndToIcon width={20} height={20}/>
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography sx={{fontWeight: '600'}}>
                                        Moving To
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>
                                <Grid item xs={12} md={orderDetails?.deliver_addr_type === 'self' ? 12 : 6}>
                                    <Typography>
                                        {orderDetails?.deliver_addr_type?.toLowerCase() === 'self' ? intl.get('MOVING.SELF_DROP_OFF') : intl.get('MOVING.SCHEDULED_DELIVER')}
                                    </Typography>
                                    <Typography>
                                        {orderDetails?.deliver_company}
                                    </Typography>
                                    <Typography>
                                        {orderDetails?.deliver_address_line2 ? `${orderDetails?.deliver_address_line2} - ${orderDetails?.deliver_address}` : orderDetails?.deliver_address}
                                    </Typography>
                                    <Typography>
                                        {orderDetails?.deliver_area}
                                    </Typography>
                                    <Typography>
                                        {orderDetails?.send_to_country}, {orderDetails?.deliver_postal_code}
                                    </Typography>
                                </Grid>
                                {
                                    orderDetails?.deliver_addr_type !== 'self' &&
                                    <Grid item xs={12} md={6}>
                                        <Typography>
                                            House Type: {additionalData?.movingToAddress?.propertyType?.houseType}
                                        </Typography>
                                        <Typography>
                                            Floor Level: {additionalData?.movingToAddress?.floorLevel}
                                        </Typography>
                                        <Typography>
                                            Lift: {additionalData?.movingToAddress?.lift ? 'Yes' : 'No'}
                                        </Typography>
                                        {/*<Typography>*/}
                                        {/*    Distance from Truck to*/}
                                        {/*    Door: {additionalData?.movingToAddress?.distanceFromTruckToDoor}*/}
                                        {/*</Typography>*/}
                                        {/*<Typography>*/}
                                        {/*    Staircase Number: {additionalData?.movingToAddress?.staircaseNumber}*/}
                                        {/*</Typography>*/}
                                        {/*<Typography>*/}
                                        {/*    Narrow*/}
                                        {/*    Street: {additionalData?.movingToAddress?.isNarrowStreet ? "YES" : "NO"}*/}
                                        {/*</Typography>*/}
                                        {/*<Typography>*/}
                                        {/*    Steep*/}
                                        {/*    Driveway: {additionalData?.movingToAddress?.isSteepDriveway ? "YES" : "NO"}*/}
                                        {/*</Typography>*/}
                                    </Grid>
                                }
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={1}>
                                    <PackageDetailIcon width={20} height={20}/>
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography sx={{fontWeight: '600'}}>
                                        Items Details
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>
                                {
                                    orderDetails?.backendPackages?.length > 0 && orderDetails?.backendPackages?.map((each, index) => (
                                        <Fragment key={index}>
                                            <Grid item xs={2}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                    {`#${index + 1}`}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                    {each?.length} x {each?.width} x {each?.height} {each?.dimension_unit}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                    {each?.weight} {each?.weight_unit}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                    x {each?.available_count}
                                                </Typography>
                                            </Grid>
                                        </Fragment>
                                    ))
                                }
                            </Grid>
                            {
                                orderDetails?.images?.length > 0 &&
                                <Grid container spacing={1}>
                                    <Grid item xs={1}>
                                        <ImageIcon width={20} height={20}/>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Typography sx={{fontWeight: '600'}}>
                                            {intl.get('IMAGE.MOVING_IMAGE')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Carousel
                                            navButtonsAlwaysVisible={true}
                                            // indicators={false}
                                            fullHeightHover={false}     // We want the nav buttons wrapper to only be as big as the button element is
                                            NextIcon={<NavigateNextIcon/>}
                                            PrevIcon={<NavigateBeforeIcon/>}
                                            autoPlay={false}
                                        >
                                            {
                                                orderDetails?.images?.map((image, index) => (
                                                    <Paper key={index} sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        height: 300
                                                    }}>
                                                        <img
                                                            src={`https://storage.googleapis.com/${image?.file_location_uri}`}
                                                            alt={`image${index}`}
                                                            style={{
                                                                maxWidth: '100%',
                                                                maxHeight: '100%',
                                                                objectFit: 'contain',
                                                                display: 'block'
                                                            }}
                                                        />
                                                    </Paper>
                                                ))
                                            }
                                        </Carousel>
                                    </Grid>
                                </Grid>
                            }
                            {
                                additionalData?.movingRemarkNotes &&
                                <Grid container spacing={1}>
                                    <Grid item xs={1}>
                                        <NoteIcon width={20} height={20}/>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Typography sx={{fontWeight: '600'}}>
                                            Remark Notes
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>
                                            {additionalData?.movingRemarkNotes}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{display: 'flex', width: '100%', flexDirection: 'column', gap: '60px'}}>
                            <Grid container spacing={1}>
                                <Grid item xs={1}>
                                    <SchedulePickupIcon width={20} height={20}/>
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography sx={{fontWeight: '600'}}>
                                        {intl.get('DATE.MOVING_DATE_AND_TIME')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>
                                        {orderDetails?.pickup_addr_type?.toLowerCase() === 'self' ? intl.get('DATE.SELF_DROP_OFF_DATE') : intl.get('DATE.SCHEDULED_PICKUP_DATE')}: {dayjs(orderDetails?.scheduled_pickup_date)?.format('MMMM D, YYYY')}
                                    </Typography>
                                    <Typography>
                                        {orderDetails?.deliver_addr_type?.toLowerCase() === 'self' ? intl.get('DATE.SELF_DROP_OFF_TIME') : intl.get('DATE.SCHEDULED_PICKUP_TIME')}: {dayjs(orderDetails?.scheduled_pickup_date)?.format('h:mm A') || 'N/A'}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={1}>
                                    <ContactIcon width={20} height={20}/>
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography sx={{fontWeight: '600'}}>
                                        {intl.get('CONTACT.PICKUP')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>
                                        {orderDetails?.sender}
                                    </Typography>
                                    <Typography>
                                        {orderDetails?.pickup_phone}
                                    </Typography>
                                    <Typography>
                                        {orderDetails?.pickup_email}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={1}>
                                    <ContactIcon width={20} height={20}/>
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography sx={{fontWeight: '600'}}>
                                        {intl.get('CONTACT.DELIVER')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>
                                        {orderDetails?.deliver_name}
                                    </Typography>
                                    <Typography>
                                        {orderDetails?.deliver_phone}
                                    </Typography>
                                    <Typography>
                                        {orderDetails?.deliver_email}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {/*<Grid container spacing={1}>*/}
                            {/*    <Grid item xs={1}>*/}
                            {/*        <PackageTypeIcon width={20} height={20}/>*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={11}>*/}
                            {/*        <Typography sx={{fontWeight: '600'}}>*/}
                            {/*            Included Services*/}
                            {/*        </Typography>*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={12}>*/}
                            {/*        <Divider/>*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item md={12} xs={12}>*/}
                            {/*        <Typography>*/}
                            {/*            {intl.get('ADDITIONAL_SERVICE.STORAGE_SERVICE')}*/}
                            {/*        </Typography>*/}
                            {/*    </Grid>*/}
                            {/*</Grid>*/}
                            {/*<Grid container spacing={1}>*/}
                            {/*    <Grid item xs={1}>*/}
                            {/*        <PaidRuleIcon width={20} height={20}/>*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={11}>*/}
                            {/*        <Typography sx={{fontWeight: '600'}}>*/}
                            {/*            {intl.get('COST.FEE_RULE')}*/}
                            {/*        </Typography>*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={12}>*/}
                            {/*        <Divider/>*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={12}>*/}
                            {/*        <Grid container spacing={0}>*/}
                            {/*            <Grid item md={6} xs={6}>*/}
                            {/*                <Typography>*/}
                            {/*                    {intl.get('COST.LABOUR_HOUR_COST')}*/}
                            {/*                </Typography>*/}
                            {/*            </Grid>*/}
                            {/*            <Grid item md={6} xs={6}>*/}
                            {/*                <Typography*/}
                            {/*                    sx={styles.MovingServiceAdditionalFeeSectionHeadingCost}>*/}
                            {/*                    {formatCurrency(additionalServiceFee?.labour_hour_cost || 0, 'USD')}*/}
                            {/*                </Typography>*/}
                            {/*            </Grid>*/}
                            {/*            <Grid item md={6} xs={6}>*/}
                            {/*                <Typography>*/}
                            {/*                    {intl.get('COST.PACKING_UNIT_COST')}*/}
                            {/*                </Typography>*/}
                            {/*            </Grid>*/}
                            {/*            <Grid item md={6} xs={6}>*/}
                            {/*                <Typography*/}
                            {/*                    sx={styles.MovingServiceAdditionalFeeSectionHeadingCost}>*/}
                            {/*                    {formatCurrency(additionalServiceFee?.packing_unit_cost || 0, 'USD')}*/}
                            {/*                </Typography>*/}
                            {/*            </Grid>*/}
                            {/*            <Grid item md={6} xs={6}>*/}
                            {/*                <Typography>*/}
                            {/*                    {intl.get('COST.WRAPPING_UNIT_COST')}*/}
                            {/*                </Typography>*/}
                            {/*            </Grid>*/}
                            {/*            <Grid item md={6} xs={6}>*/}
                            {/*                <Typography*/}
                            {/*                    sx={styles.MovingServiceAdditionalFeeSectionHeadingCost}>*/}
                            {/*                    {formatCurrency(additionalServiceFee?.wrapping_unit_cost || 0, 'USD')}*/}
                            {/*                </Typography>*/}
                            {/*            </Grid>*/}
                            {/*            <Grid item md={6} xs={6}>*/}
                            {/*                <Typography>*/}
                            {/*                    {intl.get('COST.SELF_PICK_UP_CHARGE')}*/}
                            {/*                </Typography>*/}
                            {/*            </Grid>*/}
                            {/*            <Grid item md={6} xs={6}>*/}
                            {/*                <Typography*/}
                            {/*                    sx={styles.MovingServiceAdditionalFeeSectionHeadingCost}>*/}
                            {/*                    {formatCurrency(additionalServiceFee?.self_pickup_charge || 0, 'USD')}*/}
                            {/*                </Typography>*/}
                            {/*            </Grid>*/}
                            {/*            <Grid item md={6} xs={6}>*/}
                            {/*                <Typography>*/}
                            {/*                    {intl.get('COST.PICK_UP_MINIMUM_CHARGE')}*/}
                            {/*                </Typography>*/}
                            {/*            </Grid>*/}
                            {/*            <Grid item md={6} xs={6}>*/}
                            {/*                <Typography*/}
                            {/*                    sx={styles.MovingServiceAdditionalFeeSectionHeadingCost}>*/}
                            {/*                    {formatCurrency(additionalServiceFee?.pickup_min_charge || 0, 'USD')}*/}
                            {/*                </Typography>*/}
                            {/*            </Grid>*/}
                            {/*            <Grid item md={6} xs={6}>*/}
                            {/*                <Typography>*/}
                            {/*                    {intl.get('COST.DELIVER_MINIMUM_CHARGE')}*/}
                            {/*                </Typography>*/}
                            {/*            </Grid>*/}
                            {/*            <Grid item md={6} xs={6}>*/}
                            {/*                <Typography*/}
                            {/*                    sx={styles.MovingServiceAdditionalFeeSectionHeadingCost}>*/}
                            {/*                    {formatCurrency(additionalServiceFee?.deliver_min_charge || 0, 'USD')}*/}
                            {/*                </Typography>*/}
                            {/*            </Grid>*/}
                            {/*            <Grid item md={6} xs={6}>*/}
                            {/*                <Typography>*/}
                            {/*                    {intl.get('COST.STORAGE_DAILY_COST')}*/}
                            {/*                </Typography>*/}
                            {/*            </Grid>*/}
                            {/*            <Grid item md={6} xs={6}>*/}
                            {/*                <Typography*/}
                            {/*                    sx={styles.MovingServiceAdditionalFeeSectionHeadingCost}>*/}
                            {/*                    {formatCurrency(additionalServiceFee?.storage_daily_unit_cost || 0, 'USD')}*/}
                            {/*                </Typography>*/}
                            {/*            </Grid>*/}
                            {/*            <Grid item md={6} xs={6}>*/}
                            {/*                <Typography>*/}
                            {/*                    {intl.get('COST.STORAGE_WEEKLY_COST')}*/}
                            {/*                </Typography>*/}
                            {/*            </Grid>*/}
                            {/*            <Grid item md={6} xs={6}>*/}
                            {/*                <Typography*/}
                            {/*                    sx={styles.MovingServiceAdditionalFeeSectionHeadingCost}>*/}
                            {/*                    {formatCurrency(additionalServiceFee?.storage_weekly_unit_cost || 0, 'USD')}*/}
                            {/*                </Typography>*/}
                            {/*            </Grid>*/}
                            {/*            <Grid item md={6} xs={6}>*/}
                            {/*                <Typography>*/}
                            {/*                    {intl.get('COST.STORAGE_MONTHLY_COST')}*/}
                            {/*                </Typography>*/}
                            {/*            </Grid>*/}
                            {/*            <Grid item md={6} xs={6}>*/}
                            {/*                <Typography*/}
                            {/*                    sx={styles.MovingServiceAdditionalFeeSectionHeadingCost}>*/}
                            {/*                    {formatCurrency(additionalServiceFee?.storage_monthly_unit_cost || 0, 'USD')}*/}
                            {/*                </Typography>*/}
                            {/*            </Grid>*/}
                            {/*        </Grid>*/}
                            {/*    </Grid>*/}
                            {/*</Grid>*/}
                            <Grid container spacing={1}>
                                <Grid item xs={1}>
                                    <RateDetailsIcon width={20} height={20}/>
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography sx={{fontWeight: '600'}}>
                                        Rate Details
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={8}>
                                            <Typography>
                                                Freight Charge
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography style={{textAlign: 'right'}}>
                                                $ {(+orderDetails?.orderPrice?.moving_freight_charge)?.toFixed(2)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography>
                                                Pickup Charge
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography style={{textAlign: 'right'}}>
                                                $ {(+orderDetails?.orderPrice?.moving_pickup_charge)?.toFixed(2)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography>
                                                Deliver Charge
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography style={{textAlign: 'right'}}>
                                                $ {(+orderDetails?.orderPrice?.moving_deliver_charge)?.toFixed(2)}
                                            </Typography>
                                        </Grid>
                                        {
                                            +orderDetails?.orderPrice?.moving_storage_charge > 0 &&
                                            <>
                                                <Grid item xs={8}>
                                                    <Typography>
                                                        Storage Charge
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography style={{textAlign: 'right'}}>
                                                        $ {(+orderDetails?.orderPrice?.moving_storage_charge)?.toFixed(2)}
                                                    </Typography>
                                                </Grid>
                                            </>
                                        }
                                        {
                                            +orderDetails?.orderPrice?.moving_palleting_charge > 0 &&
                                            <>
                                                <Grid item xs={8}>
                                                    <Typography>
                                                        Pallet Charge
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography style={{textAlign: 'right'}}>
                                                        $ {(+orderDetails?.orderPrice?.moving_palleting_charge)?.toFixed(2)}
                                                    </Typography>
                                                </Grid>
                                            </>
                                        }
                                        <Grid item xs={12}>
                                            <Divider/>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography>
                                                Subtotal
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography style={{textAlign: 'right'}}>
                                                $ {(orderDetails?.orderPrice?.total)?.toFixed(2)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography>
                                                Tax
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography style={{textAlign: 'right'}}>
                                                $ {(orderDetails?.orderPrice?.taxes)?.toFixed(2)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider/>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography>
                                                Total
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography
                                                style={{fontSize: '20px', fontWeight: '600', textAlign: 'right'}}>
                                                $ {(orderDetails?.orderPrice?.final_total)?.toFixed(2)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}