import React from 'react'
import PropTypes from 'prop-types'
import {BrowserRouter as Link} from 'react-router-dom';
import { withRouter } from 'react-router'
import {Button} from '@material-ui/core'

const LinkButton = (props) => {
  const {
    history,
    location,
    match,
    staticContext,
    to,
    onClick,
    ...rest
  } = props
  return (
    <Link
      {...rest} 
      onClick={(event) => {
        onClick && onClick(event)
        history.push(to)
      }}
    />
  )
}

LinkButton.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default withRouter(LinkButton)