import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {CRM_INVOICE_TYPE_PAID, CRM_INVOICE_TYPE_UNPAID} from "../../Utils/config";
import {green, grey, red} from "../../Utils/styleConfig";


const styles = {
    BillingInvoicesListEachTextLeft: {
        fontSize: '14px',
        textAlign: 'left'
    },
    BillingInvoicesListTextCenter: {
        fontSize: '14px',
        textAlign: 'center'
    },
    BillingInvoicesListTextRight: {
        fontSize: '14px',
        textAlign: 'right'
    }
}

export const BillingInvoicesListEach = ({invoice, index, cbSelectInvoice, selectInvoice}) => {
    const handleChange = (invoice) => {
        cbSelectInvoice(invoice);
    }

    return (
        <Box sx={{backgroundColor: index % 2 === 1 && '#E5E5E5'}}>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <Box sx={{display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'flex-start'}}>
                        <Checkbox
                            checked={selectInvoice?.some(invoiceEach => invoiceEach?.id === invoice?.id)}
                            icon={<CheckBoxOutlineBlankIcon sx={{width: '20px'}}/>}
                            checkedIcon={<CheckCircleIcon sx={{width: '20px'}}/>}
                            color="success"
                            onChange={() => handleChange(invoice)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                        <Typography style={styles.BillingInvoicesListEachTextLeft}>
                            {invoice?.invoiceRefNumber}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box sx={{display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'flex-start', height: '100%'}}>
                        <Typography style={styles.BillingInvoicesListEachTextLeft}>
                            {invoice?.invoiceDate}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                        <Typography style={styles.BillingInvoicesListEachTextLeft}>
                            $ {(+invoice?.totalChargeIncludeTax).toFixed(2)}
                        </Typography>
                        <Typography style={styles.BillingInvoicesListEachTextLeft}>
                            {((invoice?.invoiceType?.toUpperCase() === CRM_INVOICE_TYPE_UNPAID) && (+invoice?.totalChargeIncludeTax).toFixed(2) !== (+invoice?.remainToPay).toFixed(2) && `/ $ ${(+invoice?.remainToPay).toFixed(2)}`)}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box sx={{display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                        <Typography style={{
                            fontSize: '14px',
                            fontWeight: '600',
                            color: invoice?.invoiceType?.toUpperCase() === CRM_INVOICE_TYPE_PAID ? green : invoice?.invoiceType?.toUpperCase() === CRM_INVOICE_TYPE_UNPAID ? red : grey
                        }}>
                            {invoice?.invoiceType}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>

    )
}