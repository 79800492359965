import {
    LANDING_ESTIMATE_CLEAR_BOX_COST,
    LANDING_ESTIMATE_CLEAR_PACKAGE_COST,
    LANDING_ESTIMATE_CLEAR_PALLET_COST,
    LANDING_ESTIMATE_CLEAR_TYPE_SERVICE, LANDING_ESTIMATE_UPDATE_BOX_COST,
    LANDING_ESTIMATE_UPDATE_BOX_DATA, LANDING_ESTIMATE_UPDATE_BOX_MISSING,
    LANDING_ESTIMATE_UPDATE_CLICK_ESTIMATE,
    LANDING_ESTIMATE_UPDATE_COST_ERROR,
    LANDING_ESTIMATE_UPDATE_COST_LOADING,
    LANDING_ESTIMATE_UPDATE_DELIVER_POSTAL_CODE_VALIDATION,
    LANDING_ESTIMATE_UPDATE_PACKAGE_COST,
    LANDING_ESTIMATE_UPDATE_PACKAGE_DATA,
    LANDING_ESTIMATE_UPDATE_PACKAGE_MISSING,
    LANDING_ESTIMATE_UPDATE_PALLET_COST,
    LANDING_ESTIMATE_UPDATE_PALLET_DATA,
    LANDING_ESTIMATE_UPDATE_PALLET_MISSING,
    LANDING_ESTIMATE_UPDATE_PICKUP_POSTAL_CODE_VALIDATION,
    LANDING_ESTIMATE_UPDATE_TYPE_SERVICE
} from "./types";

export const updateLandingEstimatePackage = (packageData) => ({
    type: LANDING_ESTIMATE_UPDATE_PACKAGE_DATA,
    payload: packageData
})

export const updateLandingEstimatePallet = (palletData) => ({
    type: LANDING_ESTIMATE_UPDATE_PALLET_DATA,
    payload: palletData
})

export const updateLandingEstimateBox = (boxData) => ({
    type: LANDING_ESTIMATE_UPDATE_BOX_DATA,
    payload: boxData
})

export const updateLandingEstimatePackageMissing = (packageMissing) => ({
    type: LANDING_ESTIMATE_UPDATE_PACKAGE_MISSING,
    payload: packageMissing
})

export const updateLandingEstimatePalletMissing = (palletMissing) => ({
    type: LANDING_ESTIMATE_UPDATE_PALLET_MISSING,
    payload: palletMissing
})

export const updateLandingEstimateBoxMissing = (boxMissing) => ({
    type: LANDING_ESTIMATE_UPDATE_BOX_MISSING,
    payload: boxMissing
})

export const updateLandingEstimatePackageCost = (packageCost) => ({
    type: LANDING_ESTIMATE_UPDATE_PACKAGE_COST,
    payload: packageCost
})

export const updateLandingEstimatePalletCost = (palletCost) => ({
    type: LANDING_ESTIMATE_UPDATE_PALLET_COST,
    payload: palletCost
})

export const updateLandingEstimateBoxCost = (boxCost) => ({
    type: LANDING_ESTIMATE_UPDATE_BOX_COST,
    payload: boxCost
})

export const clearLandingEstimatePackageCost = () => ({
    type: LANDING_ESTIMATE_CLEAR_PACKAGE_COST
})

export const clearLandingEstimatePalletCost = () => ({
    type: LANDING_ESTIMATE_CLEAR_PALLET_COST
})

export const clearLandingEstimateBoxCost = () => ({
    type: LANDING_ESTIMATE_CLEAR_BOX_COST
})

export const updateLandingEstimatePickupPostalCodeValid = (isValid) => ({
    type: LANDING_ESTIMATE_UPDATE_PICKUP_POSTAL_CODE_VALIDATION,
    payload: isValid
})

export const updateLandingEstimateDeliverPostalCodeValid = (isValid) => ({
    type: LANDING_ESTIMATE_UPDATE_DELIVER_POSTAL_CODE_VALIDATION,
    payload: isValid
})

export const updateLandingEstimateTypeService = (type) => ({
    type: LANDING_ESTIMATE_UPDATE_TYPE_SERVICE,
    payload: type
})

export const updateLandingEstimateCostError = (isError) => ({
    type: LANDING_ESTIMATE_UPDATE_COST_ERROR,
    payload: isError
})

export const updateLandingEstimateCostLoading = (isLoading) => ({
    type: LANDING_ESTIMATE_UPDATE_COST_LOADING,
    payload: isLoading
})

export const updateLandingClickEstimate = (isClick) => ({
    type: LANDING_ESTIMATE_UPDATE_CLICK_ESTIMATE,
    payload: isClick
})

export const clearLandingEstimateTypeService = () => ({
    type: LANDING_ESTIMATE_CLEAR_TYPE_SERVICE
})