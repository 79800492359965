import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import GooglePlaceInput from "../shared/AddressInputComboBak/googlePlacesAutoComplete";
import {
  Slider,
  Typography,
  makeStyles,
  FormGroup,
  FormControlLabel,
  Checkbox,
  IconButton,
  Chip,
  TextField,
  Button,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  FormHelperText, Box,
} from "@material-ui/core";
import store from "../../store";
import { updateSearchFilterSetting as updateFilter } from "../../actions/searchFilterAction";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import produce from "immer";
import {
  USER_CURRENT_CITY_COOKIE_NAME,
  USER_CURRENT_PROVINCE_COOKIE_NAME,
  USER_CURRENT_ADDRESS_COOKIE_TIME,
  USER_CURRENT_AREA_GROUP_COOKIE_NAME,
} from "../../Utils/config";
import { getTokenFromCookie } from "../../Utils/doToken";
import {AREA_GROUP, SERVICE_API} from "../../Utils/apiUrl";
import { getAccessToken } from "../../Utils/doToken";
import "../../styles/searchFilter.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateRangeCalendar from "../Calendar/DateRangeCalendar";
import dayjs from "dayjs";

const useStyle = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    position: "fixed",
    top: "0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2,
  },
  dialogHeader: {
    display: "flex",
  },
  cityInput: {
    width: "100%",
  },
  cityChips: {
    marginTop: "5px",
    display: "flex",
    flexWrap: "wrap",
    gap: "5px",
  },
  textField: {
    width: 200,
  },
  datePickerContainer: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
  },
  inputFieldsContainer: {
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  saveButton: {
    width: "200px",
    color: "white",
    // margin: "5px auto",
    // transform: "translate(-50%)",
    // left: "50%",
    // bottom: "5px",
  },
});

const sliderStyle = makeStyles({
  root: {
    width: "90%",
    "margin-left": "5%",
    "margin-top": "4%",
  },
});

const checkBoxStyle = makeStyles({
  root: {
    flexDirection: "row",
    "margin-left": "20px",
  },
});

const priceValueText = (value) => {
  return `$${value}`;
};

const defaultCityInfo = {
  "id": "",
  "area_type": "1",
  "area_id": "",
  "area_name": "",
  "cn_area_name": "",
  "belongto_group_id": "",
  "belongto_group_name": "",
  "province": "",
  "province_shortname": "",
  "country": "",
  "group_id": "",
  "group_name": "",
  "cn_group_name": ""
}

const SearchFilter = ({ display, searchFilter }) => {
  const classes = useStyle();
  const sliderStyleClasses = sliderStyle();
  const checkBoxStyleClasses = checkBoxStyle();
  const [cityValue, setCityValue] = useState("");
  const [inputPickupCity, setInputPickupCity] = useState("default city");
  const [pickupCityList, setPickupCityList] = useState([]);
  const [pickupCityInfo, setPickupCityInfo] = useState(defaultCityInfo);
  const [deliverCityList, setDeliverCityList] = useState([]);
  const [deliverCityInfo, setDeliverCityInfo] = useState(defaultCityInfo);

  const initialShipmentSizeCheckboxes = () => {
    let arr, checkCount;
    if (searchFilter.shipmentSize.length !== 0) {
      arr = new Array(8).fill(false);
      for (let i = 0; i < searchFilter.shipmentSize.length; i++) {
        arr[searchFilter.shipmentSize[i]] = true;
      }
      checkCount = searchFilter.shipmentSize.length;
    } else {
      arr = new Array(8).fill(false);
      arr[0] = true;
      checkCount = 1;
    }
    return {
      shipmentSizeArray: arr,
      checkCount: checkCount,
    };
  };

  const getPickupDateFrom = () => {
    //check if the state of pickup date existed
    if (searchFilter.pickupDateFrom === "") {
      return getLastWeek();
    } else {
      return searchFilter.pickupDateFrom;
    }
  };

  const getPickupDateTo = () => {
    if (searchFilter.pickupDateTo === "") {
      return getNextWeek();
    } else {
      return searchFilter.pickupDateTo;
    }
  };

  const getToday = () => {
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();

    return (today = yyyy + "-" + mm + "-" + dd);
  };

  const getLastWeek = () => {
    var today = new Date();
    var nextweek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 7
    );
    const dd = String(nextweek.getDate()).padStart(2, "0");
    const mm = String(nextweek.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = nextweek.getFullYear();
    return (nextweek = yyyy + "-" + mm + "-" + dd);
  };

  const getNextWeek = () => {
    var today = new Date();
    var nextweek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 7
    );
    console.log("next ", nextweek);
    const dd = String(nextweek.getDate()).padStart(2, "0");
    const mm = String(nextweek.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = nextweek.getFullYear();
    return (nextweek = yyyy + "-" + mm + "-" + dd);
  };

  const [unitPrice, setUnitPrice] = useState([
    searchFilter.unitPrice[0],
    searchFilter.unitPrice[1],
  ]);
  const [totalPrice, setTotalPrice] = useState([
    searchFilter.totalPrice[0],
    searchFilter.totalPrice[1],
  ]);
  const [shipmentSize, setShipmentSize] = useState("");
  const [checkBoxState, setCheckBoxState] = useState(
    initialShipmentSizeCheckboxes()
  );
  const [pickupCity, setPickupCity] = useState(searchFilter.pickupCity);
  const [deliverCity, setDeliverCity] = useState(searchFilter.deliverCity);
  const [pickupDateFrom, setPickupDateFrom] = useState(getPickupDateFrom());
  const [pickupDateTo, setPickupDateTo] = useState(getPickupDateTo());

  const [dateRange, setDateRange] = useState([
        {
          startDate: dayjs(pickupDateFrom || new Date()).toDate(),
          endDate: dayjs(pickupDateTo || new Date()).toDate(),
          key: 'selection'
        }
      ]
  );

  const [optionCheckBox, setOptionCheckBox] = useState({
    pickupLoadingDock: searchFilter.pickupLoadingDock
      ? searchFilter.pickupLoadingDock
      : false,
    deliverLoadingDock: searchFilter.deliverLoadingDock
      ? searchFilter.deliverLoadingDock
      : false,
    deliverAppointment: searchFilter.deliverAppointment
      ? searchFilter.deliverAppointment
      : false,
  });

  const [freightCheckBox, setFreightCheckBox] = useState({
    local: searchFilter.localFreight === "y",
    remote: searchFilter.remoteFreight === "y",
  });

  const [areaGroups, setAreaGroups] = useState([]);

  useEffect(() => {
    console.log("Pick up date from:", pickupDateFrom);
    console.log("Pick up date to:", pickupDateTo);
  }, [pickupDateFrom, pickupDateTo])

  // const initialArea = () => {
  //   let area = "";
  //   area = getAccessToken(USER_CURRENT_AREA_GROUP_COOKIE_NAME);
  //   return area;
  // };

  const [areaId, setAreaId] = useState({ id: "" });

  const getAreaGroups = async () => {
    const getAreaGroupTimer = setTimeout(() => {
      console.log("[filter] - get area groups timeout");
      setAreaId(getAccessToken(USER_CURRENT_AREA_GROUP_COOKIE_NAME));
    }, 1000 * 10);
    try {
      const res = await axios.get(AREA_GROUP, {
        headers: {
          Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
        },
      });

      console.log("[filter] - Area groups: ", res.data);
      if (res.data?.length > 0) {
        setAreaGroups(res.data);
        if (getAreaGroupTimer) {
          clearTimeout(getAreaGroupTimer);
        }
        let areaGroup = window.localStorage.getItem(
          USER_CURRENT_AREA_GROUP_COOKIE_NAME
        );
        if (areaGroup) {
          const selectedArea = JSON.parse(areaGroup);
          setAreaId(selectedArea.id);
        } else {
          setAreaId("");
        }
      }
    } catch (err) {
      console.log("[filter] - get area groups failed", err);
    }
  };

  const getCitySearchResult = async (areaName) => {
    try {
      const { data } = await axios.get(`${SERVICE_API}/elasticsearch/areasAndAreaGroups?query_string=${areaName}&area_type=1`);
      const result = [];
      for (const hit of data?.hits) {
        const source = hit._source;
        const areaData = {
          label: source?.cn_area_name ? `${source?.area_name} | ${source?.cn_area_name} | ${source?.province_shortname}` : `${source?.area_name} | ${source?.province_shortname}`,
          value: source
        }
        // setStartPortCode(source?.port_code);
        result.push(areaData);
      }
      return result;
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    //get All area groups
    getAreaGroups();

    // const getCachedCity = () => {
    //   return getAccessToken(USER_CURRENT_CITY_COOKIE_NAME);
    // };

    // const getCachedProvince = () => {
    //   return getAccessToken(USER_CURRENT_PROVINCE_COOKIE_NAME);
    // };

    //get current city using location, returns city name | ""
    // const getCity = async (position) => {
    //   let city = null,
    //     province = null;
    //   localStorage["authorizedGeoLocation"] = 1;
    //   try {
    //     const res = await axios.get(
    //       `https://maps.googleapis.com/maps/api/geocode/json?&latlng=${position.coords.latitude},${position.coords.longitude}&key=AIzaSyDxbjdueUQWI1WFfiEviIkvspt8S95L-bQ&result_type=street_address`
    //     );

    //     if (res.data?.results.length > 0) {
    //       const results = res.data.results;

    //       for (var i = 0; i < results[0].address_components.length; i++) {
    //         for (
    //           var b = 0;
    //           b < results[0].address_components[i].types.length;
    //           b++
    //         ) {
    //           //there are different types that might hold a city admin_area_lvl_1 usually does in come cases looking for sublocality type will be more appropriate
    //           if (results[0].address_components[i].types[b] === "locality") {
    //             city = results[0].address_components[i].long_name;
    //             if (getAreaGroupTimer) {
    //               clearTimeout(getAreaGroupTimer);
    //             }
    //             setCookie(USER_CURRENT_CITY_COOKIE_NAME, { city });
    //           }

    //           //get province
    //           if (
    //             results[0].address_components[i].types[b] ===
    //             "administrative_area_level_1"
    //           ) {
    //             currentProvince = results[0].address_components[i].short_name;

    //             setCookie(USER_CURRENT_PROVINCE_COOKIE_NAME, { province });
    //           }
    //         }
    //       }
    //     }
    //     return city;
    //   } catch (err) {
    //     console.log(err.message);
    //     if (getAreaGroupTimer) {
    //       clearTimeout(getAreaGroupTimer);
    //     }
    //     return city;
    //   }
    // };
  }, []);

  const setCookie = (name, value) => {
    let date = new Date();
    date.setTime(date.getTime() + USER_CURRENT_ADDRESS_COOKIE_TIME);
    const expires = "expires=" + date.toUTCString();
    document.cookie =
      name + "=" + JSON.stringify(value) + "; " + expires + "; path=/";
  };

  const generateMarks = (type, name) => {
    const priceMarks = [];

    //get unit price range
    const min = searchFilter.static[`min_${name}`];
    const max = searchFilter.static[`max_${name}`];
    const range = max - min;
    const gap = Math.floor(range / 5);
    priceMarks.push({
      //push min unit price
      value: min,
      label: type === "price" ? `$${min}` : `${min}lb`,
    });
    for (let i = 1; i < 5; i++) {
      let v = min + gap * i;
      priceMarks.push({
        //push max unit price
        value: v,
        label: type === "price" ? `$${v}` : `${v}lb`,
      });
    }
    priceMarks.push({
      //push max unit price
      value: max,
      label: type === "price" ? `$${max}` : `${max}lb`,
    });
    return priceMarks;
  };

  const changeUnitPrice = (event, newValue) => {
    setUnitPrice(newValue);
  };

  const changeTotalPrice = (event, newValue) => {
    setTotalPrice(newValue);
  };

  const handleFreightChange = (target) => {
    if (target === "localFreight") {
      if (freightCheckBox.remote) {
        return setFreightCheckBox((preState) => ({
          ...preState,
          local: !preState.local,
        }));
      }
    }

    if (target === "remoteFreight") {
      if (freightCheckBox.local) {
        return setFreightCheckBox((preState) => ({
          ...preState,
          remote: !preState.remote,
        }));
      }
    }
  };

  const handleOptionsChange = (target) => {
    setOptionCheckBox((preState) => {
      return {
        ...preState,
        [target]: !preState[target],
      };
    });
  };

  const handleChange = (position) => {
    if (position === 0) {
      setCheckBoxState(
        produce((draft) => {
          if (draft.shipmentSizeArray[position] === false) {
            draft.shipmentSizeArray[position] = true;
            draft.shipmentSizeArray.fill(false, 1);
            draft.checkCount = 1;
          }
        })
      );
    } else {
      setCheckBoxState(
        produce((draft) => {
          if (draft.shipmentSizeArray[position] === false) {
            draft.shipmentSizeArray[position] = true;
            draft.checkCount = draft.checkCount + 1;
            if (draft.shipmentSizeArray[0] === true) {
              draft.shipmentSizeArray[0] = false;
              draft.checkCount = 1;
            }
          } else if (draft.shipmentSizeArray[position] === true) {
            draft.shipmentSizeArray[position] = false;
            draft.checkCount = draft.checkCount - 1;
            if (draft.checkCount === 0) {
              draft.shipmentSizeArray[0] = true;
              draft.checkCount = 1;
            }
          }
        })
      );
    }
  };

  const convertShipmentSizeArray = (array) => {
    let res = [];
    for (let i = 0; i < array.length; i++) {
      if (array[i] === true) {
        res.push(i);
      }
    }
    return res;
  };

  //submite filter setting
  const saveSearchFilterSetting = () => {
    const setting = {
      unitPrice: unitPrice,
      totalPrice: totalPrice,
      shipmentSize: convertShipmentSizeArray(checkBoxState.shipmentSizeArray),
      pickupCity: pickupCity,
      deliverCity: deliverCity,
      pickupDateFrom: pickupDateFrom,
      pickupDateTo: pickupDateTo,
      pickupLoadingDock: optionCheckBox.pickupLoadingDock,
      deliverLoadingDock: optionCheckBox.deliverLoadingDock,
      deliverAppointment: optionCheckBox.deliverAppointment,
      localFreight: freightCheckBox.local ? "y" : "n",
      remoteFreight: freightCheckBox.remote ? "y" : "n",
      area: areaId,
    };
    store.dispatch(updateFilter(setting));

    //passing to parent that dont display the filter
    display(false);
  };

  const handleChangePickupCity = (value) => {
    if (value !== "") {
      console.log(value);
      let currentCities = [...pickupCity];
      currentCities.push(value);
      currentCities = [...new Set(currentCities)];
      setPickupCity(currentCities);
    }
  };

  const handleDeletePickupCity = (index) => {
    const temp = [...pickupCity];
    temp.splice(index, 1);
    setPickupCity(temp);
  };

  const handleChangeDeliverCity = (value) => {
    if (value !== "") {
      let currentCities = [...deliverCity];
      currentCities.push(value);
      currentCities = [...new Set(currentCities)];
      setDeliverCity(currentCities);
    }
  };

  const handleDeleteDeliverCity = (index) => {
    const temp = [...deliverCity];
    temp.splice(index, 1);
    setDeliverCity(temp);
  };

  const handlePickupDateChange = (event) => {
    setPickupDateFrom(event.target.value);
  };

  const handleDeliverDateChange = (event) => {
    setPickupDateTo(event.target.value);
  };

  const handleSelectArea = (e) => {
    setAreaId(e.target.value);
    //save the selection into local Storage
    window.localStorage.setItem(
      USER_CURRENT_AREA_GROUP_COOKIE_NAME,
      JSON.stringify({
        id: e.target.value,
      })
    );
  };

  return (
    <div className={classes.root}>
      <div className="search-filter-container">
        <div className={classes.dialogHeader}>
          <Typography
            variant="h5"
            style={{ marginTop: "5px", textAlign: "center", flexGrow: "1" }}
          >
            Search filter
          </Typography>
          <IconButton
            color="secondary"
            aria-label="close filter dialog"
            onClick={() => display(false)}
          >
            <CloseRoundedIcon />
          </IconButton>
        </div>
        <div className={classes.inputFieldsContainer}>
          <div className="filter-field">
            <Typography
              variant="h6"
              gutterBottom
              style={{ marginTop: "2px", marginLeft: "5px" }}
            >
              Area
            </Typography>

            <FormGroup classes={{ root: checkBoxStyleClasses.root }}>
              {/*<FormControl*/}
              {/*  sx={{ minWidth: 50 }}*/}
              {/*  style={{ marginTop: "-10px", marginRight: "5px" }}*/}
              {/*>*/}
              {/*  <InputLabel id="areaInput">Local area</InputLabel>*/}
              {/*  <Select value={areaId} label="Area" onChange={handleSelectArea}>*/}
              {/*    <MenuItem value={""}>None</MenuItem>*/}
              {/*    {areaGroups.map((area) => {*/}
              {/*      return (*/}
              {/*        <MenuItem key={area.id} value={area.id}>*/}
              {/*          {area.name}*/}
              {/*        </MenuItem>*/}
              {/*      );*/}
              {/*    })}*/}
              {/*  </Select>*/}
              {/*  <FormHelperText>Select one area as local area</FormHelperText>*/}
              {/*</FormControl>*/}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={freightCheckBox.local}
                    onChange={() => handleFreightChange("localFreight")}
                    name="local Freight"
                  />
                }
                label="Local Freight"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={freightCheckBox.remote}
                    onChange={() => handleFreightChange("remoteFreight")}
                    name="remote freight"
                  />
                }
                label="Remote Freight"
              />
            </FormGroup>
          </div>

          <div className="filter-field">
            <Typography
              variant="h6"
              gutterBottom
              style={{ marginTop: "2px", marginLeft: "5px" }}
            >
              Pick-up City
            </Typography>
            <div className={classes.cityInput}>
              {/*<GooglePlaceInput*/}
              {/*  type="city"*/}
              {/*  handleChange={(value) => handleChangePickupCity(value)}*/}
              {/*/>*/}
              <Autocomplete
                  disablePortal
                  options={pickupCityList}
                  fullWidth
                  filterOptions={(options, state) => options}
                  getOptionLabel={(option) => option.label}
                  onChange={(e, value) => {
                    console.log("On Change Value: ", value);
                    setPickupCityInfo(value.value);
                    handleChangePickupCity(value.value.area_name);
                  }}
                  onInputChange={async (e, value) => {
                    // setStartPort("");
                    setPickupCityInfo(defaultCityInfo);
                    const result = await getCitySearchResult(value);
                    console.log("Pickup City:", result);
                    setPickupCityList(result);
                  }}
                  renderInput={(params) => <TextField id="outlined-basic" label="e.g. Vancouver" fullWidth {...params} required />}
              />
            </div>
            <div className={classes.cityChips}>
              {pickupCity.length > 0 &&
                pickupCity.map((city, index) => {
                  return (
                    <div key={index}>
                      <Chip
                        variant="outlined"
                        size="small"
                        label={city}
                        color="secondary"
                        onDelete={() => handleDeletePickupCity(index)}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="filter-field">
            <Typography
              variant="h6"
              gutterBottom
              style={{ marginTop: "2px", marginLeft: "5px" }}
            >
              Deliver City
            </Typography>
            <div className={classes.cityInput}>
              {/*<GooglePlaceInput*/}
              {/*  type="city"*/}
              {/*  handleChange={(value) => handleChangeDeliverCity(value)}*/}
              {/*  cityValue={cityValue}*/}
              {/*  filter={true}*/}
              {/*/>*/}
              <Autocomplete
                  disablePortal
                  options={deliverCityList}
                  fullWidth
                  filterOptions={(options, state) => options}
                  getOptionLabel={(option) => option.label}
                  onChange={(e, value) => {
                    console.log("On Change Value: ", value);
                    setDeliverCityInfo(value.value);
                    handleChangeDeliverCity(value.value.area_name);
                  }}
                  onInputChange={async (e, value) => {
                    // setStartPort("");
                    setDeliverCityInfo(defaultCityInfo);
                    const result = await getCitySearchResult(value);
                    console.log("Deliver City:", result);
                    setDeliverCityList(result);
                  }}
                  renderInput={(params) => <TextField id="outlined-basic" label="e.g. Vancouver" fullWidth {...params} required />}
              />
            </div>
            <div className={classes.cityChips}>
              {deliverCity.length > 0 &&
                deliverCity.map((city, index) => {
                  return (
                    <div key={index}>
                      <Chip
                        variant="outlined"
                        size="small"
                        label={city}
                        color="secondary"
                        onDelete={() => handleDeleteDeliverCity(index)}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="filter-field">
            <Typography
              variant="h6"
              gutterBottom
              style={{ marginTop: "2px", marginLeft: "5px" }}
            >
              Pickup Date Range:
            </Typography>
            <div className={classes.datePickerContainer}>
              {/*<form className={classes.container} noValidate>*/}
              {/*  <TextField*/}
              {/*    id="date"*/}
              {/*    label="From:"*/}
              {/*    type="date"*/}
              {/*    defaultValue={pickupDateFrom}*/}
              {/*    onChange={handlePickupDateChange}*/}
              {/*    className={classes.textField}*/}
              {/*    InputLabelProps={{*/}
              {/*      shrink: true,*/}
              {/*    }}*/}
              {/*  />*/}
              {/*</form>*/}
              {/*<form className={classes.container} noValidate>*/}
              {/*  <TextField*/}
              {/*    id="date"*/}
              {/*    label="To:"*/}
              {/*    type="date"*/}
              {/*    defaultValue={pickupDateTo}*/}
              {/*    onChange={handleDeliverDateChange}*/}
              {/*    className={classes.textField}*/}
              {/*    InputLabelProps={{*/}
              {/*      shrink: true,*/}
              {/*    }}*/}
              {/*  />*/}
              {/*</form>*/}
              <Box width="100%" display="flex" justifyContent="center" alignContent="center">
                <DateRangeCalendar dateRange={dateRange} setDateRange={setDateRange} afterDateChange={(startDate, endDate) => {
                  setPickupDateFrom(dayjs(startDate).format("YYYY-MM-DD"))
                  setPickupDateTo(dayjs(endDate).format("YYYY-MM-DD"))
                }} availableDates={[]} />
              </Box>
            </div>
          </div>
          {/* <div className='filter-field'>
          <Typography variant='h6' gutterBottom style={{'marginTop':'2px', 'marginLeft':'5px'}}>
            Unit Price
          </Typography>
          <Slider
            classes={{root:sliderStyleClasses.root}}
            value={unitPrice}
            onChange={changeUnitPrice}
            valueLabelDisplay="on"
            aria-labelledby="range-slider"
            getAriaValueText={priceValueText}
            min={searchFilter.static === null ? 0 : searchFilter.static.min_unit_price}
            max={searchFilter.static === null ? 1000 : searchFilter.static.max_unit_price}
            marks={generateMarks('price','unit_price')}
          />
        </div>
        <div className='filter-field'>
          <Typography variant='h6' gutterBottom style={{'marginTop':'2px', 'marginLeft':'5px'}}>
            Total Price
          </Typography>
          <Slider
            classes={{root:sliderStyleClasses.root}}
            value={totalPrice}
            onChange={changeTotalPrice}
            valueLabelDisplay="on"
            aria-labelledby="range-slider"
            getAriaValueText={priceValueText}
            min={searchFilter.static === null ? 0 : searchFilter.static.min_total_price}
            max={searchFilter.static === null ? 1000 : searchFilter.static.max_total_price}
            marks={generateMarks('price','total_price')}
          />
        </div> */}
          <div className="filter-field">
            <Typography
              variant="h6"
              gutterBottom
              style={{ marginTop: "2px", marginLeft: "5px" }}
            >
              Shipment Types
            </Typography>
            <FormGroup classes={{ root: checkBoxStyleClasses.root }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkBoxState.shipmentSizeArray[0]}
                    onChange={() => handleChange(0)}
                    name="all_shipment_size"
                  />
                }
                label="All Size Pallet"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkBoxState.shipmentSizeArray[1]}
                    onChange={() => handleChange(1)}
                    name="pallet_48_x_40"
                  />
                }
                label="Pallet(48''x40'')"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkBoxState.shipmentSizeArray[2]}
                    onChange={() => handleChange(2)}
                    name="pallet_48_x_48"
                  />
                }
                label="Pallet(48''x48'')"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkBoxState.shipmentSizeArray[3]}
                    onChange={() => handleChange(3)}
                    name="Oversize Pallet"
                  />
                }
                label="Oversize Pallet"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkBoxState.shipmentSizeArray[4]}
                    onChange={() => handleChange(4)}
                    name="Overweight Pallet"
                  />
                }
                label="Overweight Pallet"
              />
              {/* <FormControlLabel
              control={<Checkbox checked={checkBoxState[5]} onChange={() => handleChange(5)} name="Oversize & Overweight Pallet" />}
              label="Oversize & Overweight Pallet"
            /> */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkBoxState.shipmentSizeArray[6]}
                    onChange={() => handleChange(6)}
                    name="Full Truck(5 ton)"
                  />
                }
                label="Full Truck(5 ton)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkBoxState.shipmentSizeArray[7]}
                    onChange={() => handleChange(7)}
                    name="Full Trailer (FTL)"
                  />
                }
                label="Full Trailer (FTL)"
              />
            </FormGroup>
          </div>
        </div>
        <div className="filter-field">
          <Typography
            variant="h6"
            gutterBottom
            style={{ marginTop: "2px", marginLeft: "5px" }}
          >
            Options
          </Typography>
          <FormGroup classes={{ root: checkBoxStyleClasses.root }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={optionCheckBox.pickupLoadingDock}
                  onChange={() => handleOptionsChange("pickupLoadingDock")}
                  name="pickup Loading Dock"
                />
              }
              label="Pickup Loading Dock"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={optionCheckBox.deliverLoadingDock}
                  onChange={() => handleOptionsChange("deliverLoadingDock")}
                  name="deliver loading dock"
                />
              }
              label="Deliver Loading Dock"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={optionCheckBox.deliverAppointment}
                  onChange={() => handleOptionsChange("deliverAppointment")}
                  name="deliver appointment"
                />
              }
              label="Deliver Appointment"
            />
          </FormGroup>
        </div>
        <Box width="100%" display="flex" justifyContent="center" alignContent="center">
          <Button
              className={classes.saveButton}
              variant="contained"
              color="primary"
              onClick={saveSearchFilterSetting}
          >
            Save
          </Button>
        </Box>

      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    searchFilter: state.searchFilter,
  };
};

export default connect(mapStateToProps)(SearchFilter);
