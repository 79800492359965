import {
    LANDING_ESTIMATE_CLEAR_BOX_COST,
    LANDING_ESTIMATE_CLEAR_PACKAGE_COST,
    LANDING_ESTIMATE_CLEAR_PALLET_COST,
    LANDING_ESTIMATE_CLEAR_TYPE_SERVICE, LANDING_ESTIMATE_UPDATE_BOX_COST,
    LANDING_ESTIMATE_UPDATE_BOX_DATA, LANDING_ESTIMATE_UPDATE_BOX_MISSING,
    LANDING_ESTIMATE_UPDATE_CLICK_ESTIMATE,
    LANDING_ESTIMATE_UPDATE_COST_ERROR,
    LANDING_ESTIMATE_UPDATE_COST_LOADING,
    LANDING_ESTIMATE_UPDATE_DELIVER_POSTAL_CODE_VALIDATION,
    LANDING_ESTIMATE_UPDATE_PACKAGE_COST,
    LANDING_ESTIMATE_UPDATE_PACKAGE_DATA,
    LANDING_ESTIMATE_UPDATE_PACKAGE_MISSING,
    LANDING_ESTIMATE_UPDATE_PALLET_COST,
    LANDING_ESTIMATE_UPDATE_PALLET_DATA,
    LANDING_ESTIMATE_UPDATE_PALLET_MISSING,
    LANDING_ESTIMATE_UPDATE_PICKUP_POSTAL_CODE_VALIDATION,
    LANDING_ESTIMATE_UPDATE_TYPE_SERVICE
} from "../actions/types";

const initialState = {
    landingEstimatePackage: {},
    landingEstimatePallet: {},
    landingEstimateBox: {},
    landingEstimatePackageMissing: [],
    landingEstimatePalletMissing: [],
    landingEstimateBoxMissing: [],
    landingEstimatePackageCost: [],
    landingEstimatePalletCost: {},
    landingEstimateBoxCost: {},
    landingEstimatePickupPostalCodeValid: true,
    landingEstimateDeliverPostalCodeValid: true,
    landingEstimateTypeService: null,
    landingEstimateCostError: false,
    landingEstimateCostLoading: false,
    landingEstimateIsClickEstimate: false,

}

export const landingEstimateReducer = (state = initialState, action) => {

    switch (action.type) {
        case LANDING_ESTIMATE_UPDATE_PACKAGE_DATA:
            return {...state, landingEstimatePackage: action?.payload}
        case LANDING_ESTIMATE_UPDATE_PALLET_DATA:
            return {...state, landingEstimatePallet: action?.payload}
        case LANDING_ESTIMATE_UPDATE_BOX_DATA:
            return {...state, landingEstimateBox: action?.payload}
        case LANDING_ESTIMATE_UPDATE_PACKAGE_MISSING:
            return {...state, landingEstimatePackageMissing: action?.payload}
        case LANDING_ESTIMATE_UPDATE_PALLET_MISSING:
            return {...state, landingEstimatePalletMissing: action?.payload}
        case LANDING_ESTIMATE_UPDATE_BOX_MISSING:
            return {...state, landingEstimateBoxMissing: action?.payload}
        case LANDING_ESTIMATE_UPDATE_PACKAGE_COST:
            let copyState = state.landingEstimatePackageCost;
            const payloadState = action?.payload;
            payloadState.forEach(item => {
                if (!item.hasOwnProperty('errorMessage')) {
                    copyState = [...copyState, item]
                }
            })

            return {...state, landingEstimatePackageCost: copyState}
        case LANDING_ESTIMATE_UPDATE_PALLET_COST:
            return {...state, landingEstimatePalletCost: action?.payload}
        case LANDING_ESTIMATE_UPDATE_BOX_COST:
            return {...state, landingEstimateBoxCost: action?.payload}
        case LANDING_ESTIMATE_CLEAR_PACKAGE_COST:
            return {...state, landingEstimatePackageCost: []}
        case LANDING_ESTIMATE_CLEAR_PALLET_COST:
            return {...state, landingEstimatePalletCost: {}}
        case LANDING_ESTIMATE_CLEAR_BOX_COST:
            return {...state, landingEstimateBoxCost: {}}
        case LANDING_ESTIMATE_UPDATE_PICKUP_POSTAL_CODE_VALIDATION:
            return {...state, landingEstimatePickupPostalCodeValid: action?.payload}
        case LANDING_ESTIMATE_UPDATE_DELIVER_POSTAL_CODE_VALIDATION:
            return {...state, landingEstimateDeliverPostalCodeValid: action?.payload}
        case LANDING_ESTIMATE_UPDATE_TYPE_SERVICE:
            return {...state, landingEstimateTypeService: action?.payload}
        case LANDING_ESTIMATE_UPDATE_COST_ERROR:
            return {...state, landingEstimateCostError: action?.payload}
        case LANDING_ESTIMATE_UPDATE_COST_LOADING:
            return {...state, landingEstimateCostLoading: action?.payload}
        case LANDING_ESTIMATE_UPDATE_CLICK_ESTIMATE:
            // console.log('is click estimate', action?.payload)
            return {...state, landingEstimateIsClickEstimate: action?.payload}
        case LANDING_ESTIMATE_CLEAR_TYPE_SERVICE:
            return {...state, landingEstimateTypeService: null}
        default:
            return state

    }

}