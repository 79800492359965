import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import {MenuItem, Select} from "@mui/material";
import {
    extractFullAddress,
    isValidPostalCode,
    isValidZipCode,
    property_type,
    provinces_states,
    UOMList
} from "../../Utils/Helper";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import {useEffect, useState} from "react";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import {makeStyles} from "@material-ui/core/styles";
import {
    updateMovingServiceAccountBase,
    updateMovingServiceMoveFromAddress,
    updateMovingServiceMoveToAddress
} from "../../actions/movingServiceAction";
import {useDispatch, useSelector} from "react-redux";
import intl from "react-intl-universal";

const styles = {
    MovingServiceAddressRoot: {
        display: 'flex',
        flexDirection: 'column',
        gap: '30px'
    },

    MovingServiceAddressSection: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '10px',
        display: 'flex',
        flexDirection: 'column',
    },

    MovingServiceAddressContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        height: '100%'
    },

    MovingServiceAddressHeading: {
        fontSize: '20px',
        fontWeight: '600'
    },
    MovingServiceAddressErrorText: {
        fontSize: '12px',
        color: "#FF0303"
    },
    MovingServiceExpressHeading: {
        fontSize: '24px',
        fontWeight: '600'
    },
}

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "4px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

const Lift = ['yes', 'no']

const Storey = ['1', '2', '3']

export const MovingServiceExpress = ({movingFromWareHouseMissing, movingToWareHouseMissing}) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const ownerRole = useSelector(state => state?.user?.ownerRole);
    let movingFromAddress = useSelector(state => state?.movingService?.movingFromAddress);
    let movingToAddress = useSelector(state => state?.movingService?.movingToAddress);
    let movingFromAddressMissing = useSelector(state => state?.movingService?.movingFromAddressMissing);
    let movingToAddressMissing = useSelector(state => state?.movingService?.movingToAddressMissing);
    let movingSelectAccountBase = useSelector(state => state?.movingService?.movingSelectAccountBase);

    const [movingFromAddressPredictions, setMovingFromAddressPredictions] = useState([]);
    const [movingToAddressPredictions, setMovingToAddressPredictions] = useState([]);
    const [movingFrom, setMovingFrom] = useState({
        streetAddress: null,
        apt: null,
        city: null,
        province: null,
        postalCode: null,
        country: null,
        propertyType: null,
        lift: 'no',
        storey: '1',
        displayAddress: {
            description: null,
            placeId: null,
            text: null
        },
        option: 'self',
        warehouse: null,
    });
    const [movingTo, setMovingTo] = useState({
        streetAddress: null,
        apt: null,
        city: null,
        province: null,
        postalCode: null,
        country: null,
        propertyType: null,
        lift: 'no',
        storey: '1',
        displayAddress: {
            description: null,
            placeId: null,
            text: null
        },
        option: 'self',
        warehouse: null,
    });
    const [wareHouseLocation, setWareHouseLocation] = useState([]);
    const [movingAccountBase, setMovingAccountBase] = useState("");

    const getWareHouseLocation = async () => {

        let requestURL = ownerRole === 1 ? `${NODE_ROUTE_URI}/movingExpressService/getWarehousesList` :
            `${PARTNER_URI}/movingExpressService/getWarehousesList`

        try {
            const result = await axios({
                method: 'get',
                url: requestURL
            })
            console.log('[MovingServiceExpress] - getWareHouseLocation - result', result?.data);
            setWareHouseLocation(result?.data);
        } catch (e) {
            console.log('[MovingServiceExpress] - getWareHouseLocation - error', e?.response);
        }
    }

    const googleMapsPlaceAutocomplete = async (inputValue, type) => {
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log('prediction', predictions);
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            console.log('updated prediction', updatedPredictions);

            return updatedPredictions;

        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const handleSelectMovingFromService = (event) => {
        // setMovingFromOption(event.target.value);
        setMovingFrom(prevState => ({
            ...prevState,
            option: event.target.value,
            streetAddress: null,
            apt: null,
            city: null,
            province: null,
            postalCode: null,
            country: null,
            propertyType: null,
            lift: 'no',
            storey: '1',
            displayAddress: {
                description: null,
                placeId: null,
                text: null
            },
            warehouse: null
        }));
    }

    const handleSelectMovingFromWareHouse = (event, value) => {
        console.log('[handleSelectMovingFromWareHouse] wareHouseInfo', value);
        setMovingFrom(prevState => ({
            ...prevState,
            warehouse: value,
            city: value?.city,
            country: value?.country,
            province: {
                ...prevState.province,
                code: value?.province,
                name: value?.province
            },
            postalCode: value?.postal_code
        }));
    }

    const handleSelectMovingToService = (event) => {
        // setMovingToOption(event.target.value);
        setMovingTo(prevState => ({
            ...prevState,
            option: event.target.value,
            streetAddress: null,
            apt: null,
            city: null,
            province: null,
            postalCode: null,
            country: null,
            propertyType: null,
            lift: 'no',
            storey: '1',
            displayAddress: {
                description: null,
                placeId: null,
                text: null
            },
            warehouse: null
        }));
    }

    const handleSelectMovingToWareHouse = (event, value) => {
        console.log('[handleSelectMovingFromWareHouse] wareHouseInfo', value);
        setMovingTo(prevState => ({
            ...prevState,
            warehouse: value,
            city: value?.city,
            country: value?.country,
            province: {
                ...prevState.province,
                code: value?.province,
                name: value?.province
            },
            postalCode: value?.postal_code
        }));
    }

    const handleMovingFromApt = (event) => {
        setMovingFrom(prevState => ({
            ...prevState, apt: event.target.value
        }))
    }

    const handleMovingFromCity = (event) => {
        setMovingFrom(prevState => ({
            ...prevState, city: event.target.value
        }))
    }

    const handleMovingFromProvince = (event, value) => {
        console.log(value);
        if (value) {
            setMovingFrom(prevState => ({
                ...prevState,
                province: {
                    ...prevState.province,
                    code: value?.code,
                    name: value?.name
                },
                country: value?.countryCode
            }))
        } else {
            setMovingFrom(prevState => ({
                ...prevState,
                province: null
            }))
        }
    }

    const handleMovingFromCountry = event => {
        setMovingFrom(prevState => ({
            ...prevState,
            country: event?.target?.value
        }))
    }

    const handleMovingFromPostalCode = (event) => {
        setMovingFrom(prevState => ({
            ...prevState, postalCode: event.target.value
        }))
    }

    const handleMovingFromPropertyType = (event, value) => {
        setMovingFrom(prevState => ({
            ...prevState, propertyType: value
        }))
    }

    const handleMovingFromLift = (event, value) => {
        setMovingFrom(prevState => ({
            ...prevState, lift: value
        }))
    }

    const handleMovingFromStorey = (event, value) => {
        setMovingFrom(prevState => ({
            ...prevState, storey: value
        }))
    }

    const handleMovingToApt = (event) => {
        setMovingTo(prevState => ({
            ...prevState, apt: event.target.value
        }))
    }

    const handleMovingToCity = (event) => {
        setMovingTo(prevState => ({
            ...prevState, city: event.target.value
        }))
    }

    const handleMovingToProvince = (event, value) => {
        if (value) {
            setMovingTo(prevState => ({
                ...prevState,
                province: {
                    ...prevState.province,
                    code: value?.code,
                    name: value?.name
                },
                country: value?.countryCode
            }))
        } else {
            setMovingTo(prevState => ({
                ...prevState,
                province: null
            }))
        }
    }

    const handleMovingToCountry = event => {
        setMovingTo(prevState => ({
            ...prevState,
            country: event?.target?.value
        }))
    }

    const handleMovingToPostalCode = (event) => {
        setMovingTo(prevState => ({
            ...prevState, postalCode: event.target.value
        }))
    }

    const handleMovingToPropertyType = (event, value) => {
        setMovingTo(prevState => ({
            ...prevState, propertyType: value
        }))
    }

    const handleMovingToLift = (event, value) => {
        setMovingTo(prevState => ({
            ...prevState, lift: value
        }))
    }

    const handleMovingToStorey = (event, value) => {
        setMovingTo(prevState => ({
            ...prevState, storey: value
        }))
    }

    const validateMovingFromPostalCode = () => {
        if (movingFrom?.postalCode) {
            if (movingFrom?.country === 'CA') {
                const result = isValidPostalCode(movingFrom?.postalCode);
                return result;
            } else if (movingFrom?.province?.countryCode === 'US') {
                const result = isValidZipCode(movingFrom?.postalCode);
                return result;
            } else {
                return true
            }
        } else {
            return true
        }
    }

    const validateMovingToPostalCode = () => {
        if (movingTo?.postalCode) {
            if (movingTo?.country === 'CA') {
                const result = isValidPostalCode(movingTo?.postalCode);
                return result;
            } else if (movingTo?.province?.countryCode === 'US') {
                const result = isValidZipCode(movingTo?.postalCode);
                return result;
            } else {
                return true
            }
        } else {
            return true
        }
    }

    const handleInputChangeMovingFromAddress = async (event, value) => {
        setMovingFrom(prevState => ({
            ...prevState,
            streetAddress: value,
            displayAddress: {
                description: '',
                placeId: '',
                text: value
            }
        }));
        const predictionsAddress = await googleMapsPlaceAutocomplete(value, 'address');
        setMovingFromAddressPredictions(predictionsAddress);
    }

    const handleChangeMovingFromAddress = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            setMovingFrom(prevState => ({
                ...prevState,
                postalCode: address?.zip,
                city: address?.city,
                province: address?.state,
                country: address?.country?.code,
                displayAddress: value
            }))
        } else {
            setMovingFrom(prevState => ({
                ...prevState,
                streetAddress: '',
                displayAddress: {
                    description: '',
                    placeId: '',
                    text: ''
                },
            }))
        }
    }

    const handleInputChangeMovingToAddress = async (event, value) => {
        setMovingTo(prevState => ({
            ...prevState,
            streetAddress: value,
            displayAddress: {
                description: '',
                placeId: '',
                text: value
            }
        }));
        const predictionsAddress = await googleMapsPlaceAutocomplete(value, 'address');
        setMovingToAddressPredictions(predictionsAddress);
    }

    const handleChangeMovingToAddress = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            setMovingTo(prevState => ({
                ...prevState,
                postalCode: address?.zip,
                city: address?.city,
                province: address?.state,
                country: address?.country?.code,
                displayAddress: value
            }))
        } else {
            setMovingTo(prevState => ({
                ...prevState,
                streetAddress: '',
                displayAddress: {
                    description: '',
                    placeId: '',
                    text: ''
                },
            }))
        }
    }

    useEffect(() => {
        if (movingFromAddress?.city) {
            setMovingFrom(movingFromAddress);
        }
        if (movingToAddress?.city) {
            setMovingTo(movingToAddress);
        }
        if (movingSelectAccountBase) {
            setMovingAccountBase(movingSelectAccountBase);
        }
        getWareHouseLocation();
    }, [])

    useEffect(() => {
        dispatch(updateMovingServiceMoveFromAddress(movingFrom));
    }, [movingFrom]);

    useEffect(() => {
        dispatch(updateMovingServiceMoveToAddress(movingTo));
    }, [movingTo]);

    useEffect(() => {
        dispatch(updateMovingServiceAccountBase(movingAccountBase));
    }, [movingAccountBase])

    console.log('[MovingServiceExpress] moving from location', movingFrom);
    console.log('[MovingServiceExpress] moving select account base', movingAccountBase);
    console.log('[MovingServiceExpress] owner role', ownerRole);

    return (
        <Box sx={styles.MovingServiceAddressRoot}>
            <Typography style={styles.MovingServiceExpressHeading}>
                {intl.get('TRANSPORTATION_PAGE.EXPRESS_SERVICE_TITLE')}
            </Typography>
            <Box sx={styles.MovingServiceAddressSection}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography style={styles.MovingServiceAddressHeading}>
                            {intl.get('MOVING.MOVING_FROM')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                            <FormLabel required>{intl.get('MOVING.SERVICE_TYPE')}</FormLabel>
                            <Select
                                value={movingFrom?.option}
                                size='small'
                                onChange={handleSelectMovingFromService}
                            >
                                <MenuItem value='self'>{intl.get('MOVING.SELF_DROP_OFF')}</MenuItem>
                                <MenuItem value='schedule'>{intl.get('MOVING.SCHEDULED_PICKUP')}</MenuItem>
                            </Select>
                            <FormHelperText
                                sx={{
                                    color: "error.main",
                                }}
                            >
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    {
                        movingFrom?.option === 'self' ?
                            <Grid item xs={12} md={8}>
                                <FormControl fullWidth>
                                    <FormLabel required>
                                        {intl.get('MOVING.WAREHOUSE_LOCATION')}
                                    </FormLabel>
                                    <Autocomplete
                                        value={movingFrom.warehouse}
                                        fullWidth
                                        options={wareHouseLocation}
                                        getOptionLabel={(option) => (`${option?.name}, ${option?.city}, ${option?.province}, ${option?.postal_code}, ${option?.country}`)}
                                        onChange={handleSelectMovingFromWareHouse}
                                        renderInput={(params) => <TextField
                                            variant="outlined"
                                            className={classes.smallInput}
                                            {...params}
                                            error={movingFromWareHouseMissing}
                                            helperText={movingFromWareHouseMissing && 'Required'}
                                        />}
                                        renderOption={(option) => (
                                            <div>
                                                {option?.name}, {option?.city}, {option?.province}, {option?.postal_code}, {option?.country}
                                            </div>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            :
                            <Grid item xs={12} md={12}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                {intl.get('MOVING.STREET_ADDRESS')}
                                            </FormLabel>
                                            <Autocomplete
                                                freeSolo
                                                value={movingFrom?.displayAddress}
                                                fullWidth
                                                options={movingFromAddressPredictions}
                                                filterOptions={(options, state) => options}
                                                getOptionLabel={option => option?.text}
                                                onInputChange={handleInputChangeMovingFromAddress}
                                                onChange={handleChangeMovingFromAddress}
                                                PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                                renderInput={params => <TextField
                                                    {...params}
                                                    className={classes.smallInput}
                                                    variant="outlined"
                                                    error={movingFromAddressMissing.includes('streetAddress')}
                                                    helperText={movingFromAddressMissing.includes('streetAddress') && 'Required'}
                                                />}
                                                renderOption={option => (
                                                    <Typography style={{fontSize: '12px'}}>
                                                        {option.description}
                                                    </Typography>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth>
                                            <FormLabel>
                                                {intl.get('MOVING.APT')}
                                            </FormLabel>
                                            <TextField
                                                value={movingFrom.apt}
                                                onInput={handleMovingFromApt}
                                                fullWidth
                                                variant="outlined"
                                                size='small'
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                {intl.get('MOVING.CITY')}
                                            </FormLabel>
                                            <TextField
                                                value={movingFrom.city}
                                                onInput={handleMovingFromCity}
                                                fullWidth
                                                variant="outlined"
                                                InputProps={{
                                                    style: {padding: '2px 0'},
                                                }}
                                                size='small'
                                                error={movingFromAddressMissing.includes('city')}
                                                helperText={movingFromAddressMissing.includes('city') && 'Required'}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                {intl.get('MOVING.PROVINCE')}
                                            </FormLabel>
                                            <Autocomplete
                                                value={movingFrom.province}
                                                groupBy={(option) => option.country}
                                                fullWidth
                                                options={provinces_states}
                                                getOptionLabel={(option) => option.code}
                                                onChange={handleMovingFromProvince}
                                                renderInput={(params) => <TextField
                                                    variant="outlined"
                                                    className={classes.smallInput}
                                                    {...params}
                                                    error={movingFromAddressMissing.includes('province')}
                                                    helperText={movingFromAddressMissing.includes('province') && 'Required'}
                                                />}
                                                renderOption={(option) => (
                                                    <div>
                                                        {option.name}
                                                    </div>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                {intl.get('MOVING.COUNTRY')}
                                            </FormLabel>
                                            <FormControl fullWidth>
                                                <Select
                                                    value={movingFrom?.country}
                                                    size='small'
                                                    onChange={handleMovingFromCountry}
                                                    error={movingFromAddressMissing.includes('country')}
                                                >
                                                    <MenuItem value='CA'>Canada</MenuItem>
                                                    <MenuItem value='US'>Unites States</MenuItem>
                                                </Select>
                                                <FormHelperText
                                                    sx={{
                                                        color: "error.main",
                                                    }}
                                                >
                                                    {movingFromAddressMissing.includes('country') && 'Required'}
                                                </FormHelperText>
                                            </FormControl>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                {intl.get('MOVING.POSTAL_CODE')}
                                            </FormLabel>
                                            <TextField
                                                value={movingFrom.postalCode}
                                                onInput={handleMovingFromPostalCode}
                                                fullWidth
                                                variant="outlined"
                                                InputProps={{
                                                    style: {padding: '2px 0'},
                                                }}
                                                size='small'
                                                error={movingFromAddressMissing.includes('postalCode') || !validateMovingFromPostalCode()}
                                                helperText={movingFromAddressMissing.includes('postalCode') ? 'Required' : !validateMovingFromPostalCode() && "Invalid"}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                {intl.get('MOVING.PROPERTY_TYPE')}
                                            </FormLabel>
                                            <Autocomplete
                                                value={movingFrom.propertyType}
                                                fullWidth
                                                groupBy={(option) => option.propertyType}
                                                options={property_type}
                                                getOptionLabel={(option) => option.houseType}
                                                onChange={handleMovingFromPropertyType}
                                                renderInput={(params) => <TextField
                                                    variant="outlined"
                                                    className={classes.smallInput}
                                                    {...params}
                                                    error={movingFromAddressMissing.includes('propertyType')}
                                                    helperText={movingFromAddressMissing.includes('propertyType') && 'Required'}
                                                />}
                                                renderOption={(option) => (
                                                    <div>
                                                        {option.houseType}
                                                    </div>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                {intl.get('MOVING.LIFT')}
                                            </FormLabel>
                                            <Autocomplete
                                                value={movingFrom.lift}
                                                fullWidth
                                                options={Lift}
                                                onChange={handleMovingFromLift}
                                                renderInput={(params) => <TextField
                                                    variant="outlined"
                                                    className={classes.smallInput}
                                                    {...params}
                                                    error={movingFromAddressMissing.includes('lift')}
                                                    helperText={movingFromAddressMissing.includes('lift') && 'Required'}
                                                />}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                {intl.get('MOVING.STOREY')}
                                            </FormLabel>
                                            <Autocomplete
                                                value={movingFrom.storey}
                                                fullWidth
                                                freeSolo
                                                options={Storey}
                                                onChange={handleMovingFromStorey}
                                                onInputChange={handleMovingFromStorey}
                                                renderInput={(params) => <TextField
                                                    variant="outlined"
                                                    className={classes.smallInput}
                                                    {...params}
                                                    error={movingFromAddressMissing.includes('storey')}
                                                    helperText={movingFromAddressMissing.includes('storey') && 'Required'}
                                                />}
                                            />
                                        </FormControl>
                                    </Grid>
                                    {
                                        (movingFrom?.lift === 'no' && +movingFrom.storey > 1) &&
                                        <Grid item xs={12} md={12}>
                                            <Typography style={{fontSize: '14px'}}>
                                                {intl.get('COST.LIFT_FEE')}
                                            </Typography>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                    }
                </Grid>
            </Box>
            <Box sx={styles.MovingServiceAddressSection}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography style={styles.MovingServiceAddressHeading}>
                            {intl.get('MOVING.MOVING_TO')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                            <FormLabel required>
                                {intl.get('MOVING.SERVICE_TYPE')}
                            </FormLabel>
                            <Select
                                value={movingTo?.option}
                                size='small'
                                onChange={handleSelectMovingToService}
                            >
                                <MenuItem value='self'>{intl.get('MOVING.SELF_PICKUP')}</MenuItem>
                                <MenuItem value='schedule'>{intl.get('MOVING.SCHEDULED_DELIVER')}</MenuItem>
                            </Select>
                            <FormHelperText
                                sx={{
                                    color: "error.main",
                                }}
                            >
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    {
                        movingTo?.option === 'self' ?
                            <Grid item xs={12} md={8}>
                                <FormControl fullWidth>
                                    <FormLabel required>
                                        {intl.get('MOVING.WAREHOUSE_LOCATION')}
                                    </FormLabel>
                                    <Autocomplete
                                        value={movingTo.warehouse}
                                        fullWidth
                                        options={wareHouseLocation}
                                        getOptionLabel={(option) => (`${option?.name}, ${option?.city}, ${option?.province}, ${option?.postal_code}, ${option?.country}`)}
                                        onChange={handleSelectMovingToWareHouse}
                                        renderInput={(params) => <TextField
                                            variant="outlined"
                                            className={classes.smallInput}
                                            {...params}
                                            error={movingToWareHouseMissing}
                                            helperText={movingToWareHouseMissing && 'Required'}
                                        />}
                                        renderOption={(option) => (
                                            <div>
                                                {option?.name}, {option?.city}, {option?.province}, {option?.postal_code}, {option?.country}
                                            </div>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            :
                            <Grid item xs={12}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                {intl.get('MOVING.STREET_ADDRESS')}
                                            </FormLabel>
                                            <Autocomplete
                                                freeSolo
                                                value={movingTo?.displayAddress}
                                                fullWidth
                                                options={movingToAddressPredictions}
                                                filterOptions={(options, state) => options}
                                                getOptionLabel={option => option?.text}
                                                onInputChange={handleInputChangeMovingToAddress}
                                                onChange={handleChangeMovingToAddress}
                                                PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                                renderInput={params => <TextField
                                                    {...params}
                                                    className={classes.smallInput}
                                                    variant="outlined"
                                                    error={movingToAddressMissing.includes('streetAddress')}
                                                    helperText={movingToAddressMissing.includes('streetAddress') && 'Required'}
                                                />}
                                                renderOption={option => (
                                                    <Typography style={{fontSize: '12px'}}>
                                                        {option.description}
                                                    </Typography>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth>
                                            <FormLabel>
                                                {intl.get('MOVING.APT')}
                                            </FormLabel>
                                            <TextField
                                                value={movingTo.apt}
                                                onInput={handleMovingToApt}
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                {intl.get('MOVING.CITY')}
                                            </FormLabel>
                                            <TextField
                                                value={movingTo.city}
                                                onInput={handleMovingToCity}
                                                fullWidth
                                                variant="outlined"
                                                InputProps={{
                                                    style: {padding: '2px 0'},
                                                }}
                                                size="small"
                                                error={movingToAddressMissing.includes('city')}
                                                helperText={movingToAddressMissing.includes('city') && 'Required'}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                {intl.get('MOVING.PROVINCE')}
                                            </FormLabel>
                                            <Autocomplete
                                                value={movingTo.province}
                                                groupBy={(option) => option.country}
                                                fullWidth
                                                options={provinces_states}
                                                getOptionLabel={(option) => option.code}
                                                onChange={handleMovingToProvince}
                                                renderInput={(params) => <TextField
                                                    variant="outlined"
                                                    className={classes.smallInput}
                                                    {...params}
                                                    error={movingToAddressMissing.includes('province')}
                                                    helperText={movingToAddressMissing.includes('province') && 'Required'}
                                                />}
                                                renderOption={(option) => (
                                                    <div>
                                                        {option.name}
                                                    </div>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                {intl.get('MOVING.COUNTRY')}
                                            </FormLabel>
                                            <FormControl fullWidth>
                                                <Select
                                                    value={movingTo?.country}
                                                    size='small'
                                                    onChange={handleMovingToCountry}
                                                    error={movingToAddressMissing.includes('country')}
                                                    helperText={movingToAddressMissing.includes('country') && 'Required'}
                                                >
                                                    <MenuItem value='CA'>Canada</MenuItem>
                                                    <MenuItem value='US'>Unites States</MenuItem>
                                                </Select>
                                                <FormHelperText
                                                    sx={{
                                                        color: "error.main",
                                                    }}
                                                >
                                                    {movingToAddressMissing.includes('country') && 'Required'}
                                                </FormHelperText>
                                            </FormControl>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                {intl.get('MOVING.POSTAL_CODE')}
                                            </FormLabel>
                                            <TextField
                                                value={movingTo.postalCode}
                                                onInput={handleMovingToPostalCode}
                                                fullWidth
                                                variant="outlined"
                                                InputProps={{
                                                    style: {padding: '2px 0'},
                                                }}
                                                size='small'
                                                error={movingToAddressMissing.includes('postalCode') || !validateMovingToPostalCode()}
                                                helperText={movingToAddressMissing.includes('postalCode') ? 'Required' : !validateMovingToPostalCode() && "Invalid"}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                {intl.get('MOVING.PROPERTY_TYPE')}
                                            </FormLabel>
                                            <Autocomplete
                                                value={movingTo.propertyType}
                                                fullWidth
                                                groupBy={(option) => option.propertyType}
                                                options={property_type}
                                                getOptionLabel={(option) => option.houseType}
                                                onChange={handleMovingToPropertyType}
                                                renderInput={(params) => <TextField
                                                    variant="outlined"
                                                    className={classes.smallInput}
                                                    {...params}
                                                    error={movingToAddressMissing.includes('propertyType')}
                                                    helperText={movingToAddressMissing.includes('propertyType') && 'Required'}
                                                />}
                                                renderOption={(option) => (
                                                    <div>
                                                        {option.houseType}
                                                    </div>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                {intl.get('MOVING.LIFT')}
                                            </FormLabel>
                                            <Autocomplete
                                                value={movingTo.lift}
                                                fullWidth
                                                options={Lift}
                                                onChange={handleMovingToLift}
                                                renderInput={(params) => <TextField
                                                    variant="outlined"
                                                    className={classes.smallInput}
                                                    {...params}
                                                    error={movingToAddressMissing.includes('lift')}
                                                    helperText={movingToAddressMissing.includes('lift') && 'Required'}
                                                />}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                {intl.get('MOVING.STOREY')}
                                            </FormLabel>
                                            <Autocomplete
                                                value={movingTo.storey}
                                                fullWidth
                                                freeSolo
                                                options={Storey}
                                                onChange={handleMovingToStorey}
                                                onInputChange={handleMovingToStorey}
                                                renderInput={(params) => <TextField
                                                    variant="outlined"
                                                    className={classes.smallInput}
                                                    {...params}
                                                    error={movingToAddressMissing.includes('storey')}
                                                    helperText={movingToAddressMissing.includes('storey') && 'Required'}
                                                />}
                                            />
                                        </FormControl>
                                    </Grid>
                                    {
                                        (movingTo?.lift === 'no' && +movingTo.storey > 1) &&
                                        <Grid item xs={12} md={12}>
                                            <Typography style={{fontSize: '14px'}}>
                                                {intl.get('COST.LIFT_FEE')}
                                            </Typography>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                    }
                </Grid>
            </Box>
        </Box>
    )

}