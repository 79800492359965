import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import WechatPaymentInvoiceDialog from "./WechatPaymentInvoiceDialog";
import {Accordion, AccordionDetails, AccordionSummary, Box, FormControlLabel, FormLabel} from "@mui/material";
import {FormControl, makeStyles, Radio, RadioGroup} from "@material-ui/core";
import LoadingButton from "@mui/lab/LoadingButton";
import {getAccessToken} from "../../Utils/doToken";
import axios from "axios";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import CurrencyFormat from "react-currency-format";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WechatTopUpPaymentInvoiceDialog from "./WechatTopUpPaymentInvoiceDialog";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
            ></IconButton>
        ) : null}
      </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const paymentAmountArray = [100, 200, 500, 1000];

const paymentAmountArrayForAdmin = [0.5, 100, 200, 500, 1000];

const useStyles = makeStyles((theme) => ({
  rootExpanded: {
    background: "blue",
    flexGrow: 1
  },
  accordion: {
    width: "100%"
  }
}));

export default function TopUpDialog({
                                      open,
                                      handleClose,
                                      isForAdmin=false
                                      }) {

  const [amount, setAmount] = useState(100);
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceId, setInvoiceId] = useState(0);
  const [crmInvoiceInfo, setCRMInvoiceInfo] = useState({});
  const classes = useStyles();

  const initDownPayment = async () => {
    try {
      setIsLoading(true);
      const storedToken = getAccessToken("access_token");
      const { data } = await axios.post(`${NODE_ROUTE_URI}/billingCenter/initTopUpDownPaymentInvoice`, {
        amount: amount
      }, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      setInvoiceId(data.invoiceId);
      console.log(data);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }

  const getCRMInvoiceInfoById = async ({ invoiceId }) => {
    try {
      const storedToken = getAccessToken("access_token");
      const { data } = await axios.get(`${NODE_ROUTE_URI}/billingCenter/getInvoiceInfoByInvoiceId?invoiceId=${invoiceId}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(data);
      setCRMInvoiceInfo(data);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (invoiceId !== 0) {
      try {
        (async () => {
          const { data } = await getCRMInvoiceInfoById({
            invoiceId: invoiceId
          });
          setCRMInvoiceInfo(data);
        })()
      } catch (e) {
        console.log(e);
      }
    }
  }, [invoiceId])

  if (invoiceId !== 0 && crmInvoiceInfo?.id !== 0) {
    return (
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
          <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleClose}
          >
            Top-Up
          </BootstrapDialogTitle>
          <DialogContent
              dividers
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "start",
                minWidth: "540px",
                fontSize: "20px"
              }}
          >
            <Box display="flex" flexDirection="column" justifyContent="flex-start" alignContent="flex-start" width="100%">
              <Box className="currency_container" display="flex" justifyContent="space-between" alignContent="center">
                Ref #: {crmInvoiceInfo?.invoiceRefNumber}
              </Box>
              <Box className="currency_container" display="flex">
                Amount: &nbsp;<CurrencyFormat value={(+crmInvoiceInfo?.totalChargeIncludeTax).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix="$" />
              </Box>
              <Accordion className={classes.accordion} defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                  <Typography>WECHAT PAY</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {/*<PayWithPayCheckWechat orderId={orderId} orderNumber={orderNumber} />*/}
                    <WechatTopUpPaymentInvoiceDialog crmInvoiceNumber={invoiceId} />
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className={classes.accordion} defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                >
                  <Typography>E-TRANSFER</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Typography>Please e-Transfer to pay@uucargo.ca</Typography>
                    <Typography>{`Message should be: #${crmInvoiceInfo?.invoiceRefNumber}`}</Typography>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </DialogContent>
          <DialogActions>
            <Box display="flex" width="100%" justifyContent="space-between" >
              <Button variant="contained" color="success" onClick={() => {
                window.location.reload()
              }
              }>
                I already paid, refresh page
              </Button>
              <Button autoFocus onClick={handleClose}>
                Cancel
              </Button>
            </Box>

          </DialogActions>
        </BootstrapDialog>
    )
  }

  return (
      <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
      >
        <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
        >
          Top-Up
        </BootstrapDialogTitle>
        <DialogContent
            dividers
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "540px",
              fontSize: "20px"
        }}
        >
          {/*<Typography>Please e-Transfer to pay@uucargo.ca</Typography>*/}
          {/*<Typography>{`Message should be: #${singleRowInvoice.InvoiceNumber}`}</Typography>*/}
          <Box display="flex" flexDirection="column" justifyContent="center" alignContent="center">
            <Box>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">Amount</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={amount}
                    onChange={e => {
                      setAmount(+e.target.value)
                    }
                    }
                >
                  {/*<FormControlLabel value="female" control={<Radio />} label="Female" />*/}
                  {/*<FormControlLabel value="male" control={<Radio />} label="Male" />*/}
                  {/*<FormControlLabel value="other" control={<Radio />} label="Other" />*/}
                  {
                    (() => {
                      if (isForAdmin) {
                        return (
                            paymentAmountArrayForAdmin.map(value => {
                              return (
                                  <FormControlLabel value={+value} control={<Radio />} label={`$ ${value}`} />
                              )
                            })
                        )
                      } else {
                        return paymentAmountArray.map(value => {
                          return (
                              <FormControlLabel value={+value} control={<Radio />} label={`$ ${value}`} />
                          )
                        })
                      }
                    })()
                  }
                </RadioGroup>
              </FormControl>
            </Box>
            < br/>
            <LoadingButton
                variant="contained"
                color="primary"
                style={{
                  textTransform: "unset",
                  fontSize: "1rem",
                  width: "100%",
                }}
                loading={isLoading}
                // type="submit"
                onClick={initDownPayment}
            >
              Confirm
            </LoadingButton>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>Done</Button></DialogActions>
      </BootstrapDialog>
  )
}
