import Box from "@mui/material/Box";
import {useDispatch, useSelector} from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {makeStyles} from "@material-ui/core/styles";
import {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@mui/material/TextField";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button, Divider,
    IconButton,
    InputAdornment,
    MenuItem,
    Select,
    Tooltip, tooltipClasses,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {
    duplicateQuickQuoteShippingPackagePackageList,
    removeQuickQuoteShippingPackagePackageList,
    updateQuickQuoteShippingPackagePackageList
} from "../../actions/shippingPackageAction";
import {generateRandomString, generateUniqueName, getCountryCode, UOMList} from "../../Utils/Helper";
import FormControl from "@mui/material/FormControl";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Autocomplete from "@material-ui/lab/Autocomplete";
import HelpIcon from "@material-ui/icons/Help";
import axios from "axios";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import duplicate from "../../images/Duplicate.png";
import remove from "../../images/Delete.png";
import intl from "react-intl-universal";
import {styled} from "@mui/material/styles";
import CloseIcon from "@material-ui/icons/Close";

const styles = {
    ShippingPackageQuickQuotePackageEachSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    ShippingPackageQuickQuotePackageEachSectionRow: {
        display: 'flex',
        alignItems: 'center'
        // justifyContent: 'flex-end',
    },
    ShippingPackageQuickQuotePackageEachWarningText: {
        fontSize: '12px',
        color: "#FF0303"
    },
}

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "0px", // Adjust the height as per your preference
            padding: "1px", // Adjust the padding as per your preference
            fontSize: "14px", // Adjust the font size as per your preference
        }
    }
});

const GreenTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#1D8B45',
        color: '#FFFFFF',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

export const ShippingPackageQuickQuotePackageEach = ({
                                                         packageInfo,
                                                         cbHandleOpen,
                                                         cbHandleUpdatePackage,
                                                         updatedPackage,
                                                         index
                                                     }) => {

    const isMobile = useMediaQuery('(max-width: 600px)');

    const classes = useStyles();

    let shipFrom = useSelector(state => state?.shippingPackage?.quickQuoteShipFrom);
    let shipTo = useSelector(state => state?.shippingPackage?.quickQuoteShipTo);
    let isGetRate = useSelector(state => state?.shippingPackage?.quickQuoteIsGetRate);
    let packageListRedux = useSelector(state => state?.shippingPackage?.quickQuotePackageList);

    const dispatch = useDispatch();

    const metric = useSelector(state => state?.shippingPackage?.quickQuoteMetricUnit);
    const [packageDetail, setPackageDetail] = useState(packageInfo);
    const [HSCodeList, setHSCodeList] = useState([]);
    const [accordionOpen, setAccordionOpen] = useState(false);
    const [commonBoxesList, setCommonBoxesList] = useState([]);

    const toggleOpen = () => {
        setAccordionOpen(!accordionOpen);
    }

    const getCommonBoxesList = async () => {
        let requestURL = `${NODE_ROUTE_URI}/myBoxes/commonBoxes`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL
            })
            console.log('result', result);
            setCommonBoxesList(result?.data);
        } catch (e) {
            console.log(e.response);
        }
    }

    const searchHSCode = async (value) => {

        let requestURL = `${NODE_ROUTE_URI}/elasticsearch/search`

        try {
            const {data} = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    query_string: value
                }
            })
            console.log('data', data);
            const hits = data.hits;
            const levelSixRecords = hits.filter(hit => {
                return hit["_source"].level === "6";
            })
            const filterResult = levelSixRecords?.map(record => {
                return record["_source"]
            })
            setHSCodeList(filterResult);
        } catch (e) {
            console.log(e);
        }
    }

    const handleSelect = (event) => {
        const type = event.target.value;
        if (type === 'myBox' || type === 'customBox') {
            setPackageDetail(prevState => ({
                ...prevState,
                packageInfoType: event.target.value
            }));
        } else {
            const find = commonBoxesList?.find((item) => item?.box_name?.replace(/\s+/g, '') === type);
            console.log('find', find);
            setPackageDetail(prevState => ({
                ...prevState,
                description: find?.item_name,
                UOM: find?.uom,
                currency: find?.currency_code,
                height: find?.height,
                length: find?.length,
                width: find?.width,
                lengthUnit: find?.dimension_unit,
                originCountryCode: find?.origi_country,
                weight: find?.weight,
                weightUnit: find?.weight_unit,
                hscode: find?.hs_code,
                priceEach: find?.unit_price,
                packageInfoType: type
            }))
        }

        if (event.target.value === 'myBox') {
            cbHandleOpen();
            cbHandleUpdatePackage(packageDetail);
        }
    };

    const handleMenuItemClick = (event) => {
        // console.log('menu click', event.target.getAttribute('data-value'));
        if (event.target.getAttribute('data-value') === 'myBox') {
            cbHandleOpen();
            cbHandleUpdatePackage(packageDetail);
        }
    }

    const handleDescription = event => {
        if (event.target.value) {
            setPackageDetail(prevState => ({
                ...prevState,
                description: event.target.value
            }))
        }
    }

    const handleLength = event => {
        const {value} = event.target;

        if (/^\d*\.?\d*$/.test(value)) {
            setPackageDetail(prevState => ({
                ...prevState,
                length: value
            }))
        }
    }

    const handleWidth = event => {
        const {value} = event.target;

        if (/^\d*\.?\d*$/.test(value)) {
            setPackageDetail(prevState => ({
                ...prevState,
                width: value
            }))
        }
    }

    const handleHeight = event => {
        const {value} = event.target;

        if (/^\d*\.?\d*$/.test(value)) {
            setPackageDetail(prevState => ({
                ...prevState,
                height: value
            }))
        }
    }

    const handleWeight = event => {
        const {value} = event.target;

        if (/^\d*\.?\d*$/.test(value)) {
            setPackageDetail(prevState => ({
                ...prevState,
                weight: value
            }))
        }
    }

    const handleQuantity = event => {
        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            setPackageDetail(prevState => ({
                ...prevState,
                quantity: value
            }))
        }
    }

    const handleInsurance = event => {
        setPackageDetail(prevState => ({
            ...prevState,
            packageSpecialServices: {
                declaredValue: {
                    value: event.target.value,
                    currency: 'CAD'
                }
            }
        }))
    }

    const handlePrice = event => {
        setPackageDetail(prevState => ({
            ...prevState,
            priceEach: event.target.value < 1 ? '1' : event.target.value
        }))
    }

    const handleCurrency = event => {
        setPackageDetail(prevState => ({
            ...prevState,
            currency: event.target.value
        }))
    }

    const handleCountryCode = event => {
        setPackageDetail(prevState => ({
            ...prevState,
            originCountryCode: event.target.value
        }))
    }

    const handleUOM = event => {
        setPackageDetail(prevState => ({
            ...prevState,
            UOM: event.target.value
        }))
    }

    const handleOnInputChangeHSCode = async (event, value) => {
        await searchHSCode(value);
        setPackageDetail(prevState => ({
            ...prevState,
            hscode: value
        }))
    }

    const handleRemove = () => {
        dispatch(removeQuickQuoteShippingPackagePackageList(packageDetail));
    }

    const handleDuplicate = () => {
        const packageId = generateRandomString(8);
        const duplicatedPackage = {...packageDetail};
        duplicatedPackage.packageId = packageId;
        const description = generateUniqueName(duplicatedPackage.description, packageListRedux);
        duplicatedPackage.description = description;
        dispatch(duplicateQuickQuoteShippingPackagePackageList(duplicatedPackage));
    }

    useEffect(() => {
        setPackageDetail(prevState => ({
            ...prevState,
            lengthUnit: metric ? 'cm' : 'in',
            weightUnit: metric ? 'kg' : 'lb'
        }))
    }, [metric]);

    useEffect(() => {
        setPackageDetail(packageInfo);
    }, [packageInfo?.packageId]);

    useEffect(() => {
        dispatch(updateQuickQuoteShippingPackagePackageList(packageDetail));
    }, [packageDetail])

    useEffect(() => {
        if (packageDetail?.packageId === updatedPackage?.packageId) {
            setPackageDetail(updatedPackage)
        }
    }, [updatedPackage]);

    useEffect(() => {
        if (shipFrom && shipTo) {
            if (shipFrom?.country?.code === shipTo?.country?.code) {
                setAccordionOpen(false);
            } else {
                setAccordionOpen(true);
            }
        }
    }, [shipFrom, shipTo])

    useEffect(() => {
        getCommonBoxesList();
    }, [])

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '15px'
        }}>
            <Box>
                <Typography style={{fontSize: '16px', textAlign: 'left'}}>
                    # {index + 1}
                </Typography>
            </Box>
            <Grid container spacing={1}>
                <Grid item md={3} xs={6}>
                    <Box sx={styles.ShippingPackageQuickQuotePackageEachSection}>
                        <InputLabel required style={{fontSize: '14px'}}>Packaging Info</InputLabel>
                        <Select
                            disabled={isGetRate}
                            value={packageDetail?.packageInfoType}
                            fullWidth
                            onChange={handleSelect}
                            size='small'
                            MenuProps={{
                                variant: 'menu',
                                MenuListProps: {
                                    'aria-labelledby': 'select-label',
                                },
                                onClick: handleMenuItemClick,
                            }}
                            sx={{fontSize: 14}}
                        >
                            <MenuItem value='customBox' data-value='customBox'>Custom</MenuItem>
                            <MenuItem value='myBox' data-value='muBox'>From My Box</MenuItem>
                            {
                                commonBoxesList?.length > 0 && commonBoxesList?.map((each, index) => <MenuItem
                                    key={index}
                                    value={each?.box_name?.replace(/\s+/g, '')}
                                >{each?.box_name}</MenuItem>)
                            }
                        </Select>
                    </Box>
                </Grid>
                <Grid item md={2} xs={6}>
                    <Box sx={styles.ShippingPackageQuickQuotePackageEachSection}>
                        <InputLabel required style={{fontSize: '14px'}}>Length</InputLabel>
                        <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                            <TextField
                                disabled={isGetRate}
                                value={packageDetail?.length}
                                onInput={handleLength}
                                fullWidth
                                variant='outlined'
                                type='number'
                                placeholder='length'
                                size='small'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {packageDetail?.lengthUnit}
                                        </InputAdornment>
                                    ),
                                    inputProps: {
                                        min: 1
                                    },
                                    style: {fontSize: 14} // font size of input text
                                }}
                            />
                            <Typography style={styles.ShippingPackageQuickQuotePackageEachWarningText}>
                                {
                                    packageDetail?.length > 0 ? "" : "Missing or Invalid"
                                }
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={2} xs={6}>
                    <Box sx={styles.ShippingPackageQuickQuotePackageEachSection}>
                        <InputLabel required style={{fontSize: '14px'}}>Width</InputLabel>
                        <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                            <TextField
                                disabled={isGetRate}
                                value={packageDetail?.width}
                                onInput={handleWidth}
                                fullWidth
                                variant='outlined'
                                type='number'
                                placeholder='width'
                                size='small'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {packageDetail?.lengthUnit}
                                        </InputAdornment>
                                    ),
                                    inputProps: {
                                        min: 1
                                    },
                                    style: {fontSize: 14} // font size of input text
                                }}
                            />
                            <Typography style={styles.ShippingPackageQuickQuotePackageEachWarningText}>
                                {
                                    packageDetail?.width > 0 ? "" : "Missing or Invalid"
                                }
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={2} xs={6}>
                    <Box sx={styles.ShippingPackageQuickQuotePackageEachSection}>
                        <InputLabel required style={{fontSize: '14px'}}>Height</InputLabel>
                        <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                            <TextField
                                disabled={isGetRate}
                                value={packageDetail?.height}
                                onInput={handleHeight}
                                fullWidth
                                variant='outlined'
                                type='number'
                                placeholder='height'
                                size='small'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {packageDetail?.lengthUnit}
                                        </InputAdornment>
                                    ),
                                    inputProps: {
                                        min: 1
                                    },
                                    style: {fontSize: 14} // font size of input text
                                }}
                            />
                            <Typography style={styles.ShippingPackageQuickQuotePackageEachWarningText}>
                                {
                                    packageDetail?.height > 0 ? "" : "Missing or Invalid"
                                }
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={2} xs={6}>
                    <Box sx={styles.ShippingPackageQuickQuotePackageEachSection}>
                        <InputLabel required style={{fontSize: '14px'}}>Weight</InputLabel>
                        <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                            <TextField
                                disabled={isGetRate}
                                value={packageDetail?.weight}
                                onInput={handleWeight}
                                fullWidth
                                variant='outlined'
                                type='number'
                                placeholder='weight'
                                size='small'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {packageDetail?.weightUnit}
                                        </InputAdornment>
                                    ),
                                    inputProps: {
                                        min: 1
                                    },
                                    style: {fontSize: 14} // font size of input text
                                }}
                            />
                            <Typography style={styles.ShippingPackageQuickQuotePackageEachWarningText}>
                                {
                                    packageDetail?.weight > 0 ? "" : "Missing or Invalid"
                                }
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={3} xs={6}>
                    <Box sx={styles.ShippingPackageQuickQuotePackageEachSection}>
                        <Box sx={{display: 'flex', alignItems: 'flex-start', gap: '5px'}}>
                            <InputLabel required style={{fontSize: '14px'}}>
                                Declared Value
                            </InputLabel>
                            <GreenTooltip
                                placement="right-start"
                                title='The declared value you enter is for EACH individual package, not the total for all packages. Ensure accurate declaration for proper carrier insurance coverage.'
                            >
                                <IconButton sx={{
                                    padding: '0',
                                    margin: '0'
                                }}>
                                    <HelpIcon style={{fontSize: '16px', color: '#1D8B45'}}/>
                                </IconButton>
                            </GreenTooltip>
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                            <TextField
                                disabled={isGetRate}
                                value={packageDetail?.packageSpecialServices?.declaredValue?.value}
                                onInput={handleInsurance}
                                fullWidth
                                variant='outlined'
                                type='number'
                                placeholder={intl.get('ADD_PACKAGE.INSURANCE')}
                                size='small'
                                InputProps={{
                                    inputProps: {
                                        min: 0
                                    },
                                    style: {fontSize: 14}
                                }}
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Box sx={styles.ShippingPackageQuickQuotePackageEachSection}>
                        <InputLabel required style={{fontSize: '14px'}}>Description</InputLabel>
                        <TextField
                            disabled={isGetRate}
                            value={packageDetail?.description}
                            onInput={handleDescription}
                            fullWidth
                            variant='outlined'
                            placeholder='description'
                            size='small'
                            inputProps={{style: {fontSize: 14}}} // font size of input text
                        />
                    </Box>
                </Grid>
                <Grid item md={1} xs={1}>
                    <Box sx={{
                        display: 'flex',
                        height: '100%',
                        alignItems: 'flex-end',
                        justifyContent: 'center'
                    }}>
                        <CloseIcon style={{
                            fontSize: '35px',
                            color: '#1D8B45'
                        }}/>
                    </Box>
                </Grid>
                <Grid item md={3} xs={11}>
                    <Box sx={styles.ShippingPackageQuickQuotePackageEachSection}>
                        <InputLabel required style={{fontSize: '14px'}}>Quantity</InputLabel>
                        <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                            <TextField
                                disabled={isGetRate}
                                value={packageDetail?.quantity}
                                onInput={handleQuantity}
                                fullWidth
                                variant='outlined'
                                type='number'
                                placeholder='quantity'
                                size='small'
                                InputProps={{
                                    inputProps: {
                                        min: 1
                                    },
                                    style: {fontSize: 14} // font size of input text
                                }}
                            />
                            <Typography style={styles.ShippingPackageQuickQuotePackageEachWarningText}>
                                {
                                    packageDetail?.quantity > 0 ? "" : "Missing or Invalid"
                                }
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={1} xs={12}>
                    <Box sx={{
                        display: 'flex',
                        // flexDirection: isMobile ? 'row' : 'eow',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-end',
                        height: '100%',
                        width: '100%',
                        gap: '10px'
                    }}>
                        <IconButton
                            color='success'
                            onClick={handleDuplicate}
                            sx={{padding: 0}}
                            disabled={isGetRate}
                        >
                            <img src={duplicate} width='30px'/>
                        </IconButton>
                        <IconButton
                            color='error'
                            onClick={handleRemove}
                            sx={{padding: 0}}
                            disabled={isGetRate}
                        >
                            <img src={remove} width='30px'/>
                        </IconButton>
                    </Box>
                </Grid>
                {/*<Grid item md={12}>*/}
                {/*    <Accordion*/}
                {/*        expanded={accordionOpen}*/}
                {/*        onChange={toggleOpen}*/}
                {/*        sx={{width: '100%'}}*/}
                {/*    >*/}
                {/*        <AccordionSummary*/}
                {/*            expandIcon={<ExpandMoreIcon/>}*/}
                {/*        >*/}
                {/*            <Box sx={styles.ShippingPackageQuickQuotePackageEachSectionRow}>*/}
                {/*                <IconButton*/}
                {/*                    onClick={() => window.location.href = 'https://uucargo.ca/support/cross-border-shipping-parcels/'}>*/}
                {/*                    <HelpIcon color="info" sx={{fontSize: 25}}/>*/}
                {/*                </IconButton>*/}
                {/*                <Typography>*/}
                {/*                    Cross Border Only (Mandatory for shipping Different Countries)<b>Click Here To*/}
                {/*                    Expand</b>*/}
                {/*                </Typography>*/}
                {/*            </Box>*/}
                {/*        </AccordionSummary>*/}
                {/*        <AccordionDetails>*/}
                {/*            <Grid container spacing={1}>*/}
                {/*                <Grid item md={2} xs={6}>*/}
                {/*                    <Box sx={styles.ShippingPackageQuickQuotePackageEachSection}>*/}
                {/*                        <InputLabel required style={{fontSize: '14px'}}>Price</InputLabel>*/}
                {/*                        <TextField*/}
                {/*                            disabled={isGetRate}*/}
                {/*                            value={packageDetail?.priceEach}*/}
                {/*                            onInput={handlePrice}*/}
                {/*                            fullWidth*/}
                {/*                            variant='outlined'*/}
                {/*                            type='number'*/}
                {/*                            size='small'*/}
                {/*                            InputProps={{*/}
                {/*                                inputProps: {*/}
                {/*                                    min: 1*/}
                {/*                                },*/}
                {/*                                style: {fontSize: 14} // font size of input text*/}
                {/*                            }}*/}
                {/*                        />*/}
                {/*                    </Box>*/}
                {/*                </Grid>*/}
                {/*                <Grid item md={2} xs={6}>*/}
                {/*                    <Box sx={styles.ShippingPackageQuickQuotePackageEachSection}>*/}
                {/*                        <InputLabel required style={{fontSize: '14px'}}>Currency</InputLabel>*/}
                {/*                        <FormControl fullWidth>*/}
                {/*                            <Select*/}
                {/*                                value={packageDetail?.currency}*/}
                {/*                                onChange={handleCurrency}*/}
                {/*                                size='small'*/}
                {/*                                disabled={isGetRate}*/}
                {/*                                sx={{fontSize: 14}}*/}
                {/*                            >*/}
                {/*                                <MenuItem value={'CAD'}>CAD</MenuItem>*/}
                {/*                                <MenuItem value={'USD'}>USD</MenuItem>*/}
                {/*                            </Select>*/}
                {/*                        </FormControl>*/}
                {/*                    </Box>*/}
                {/*                </Grid>*/}
                {/*                <Grid item md={2} xs={6}>*/}
                {/*                    <Box sx={styles.ShippingPackageQuickQuotePackageEachSection}>*/}
                {/*                        <InputLabel required style={{fontSize: '14px'}}>Origin Country</InputLabel>*/}
                {/*                        <FormControl fullWidth>*/}
                {/*                            <Select*/}
                {/*                                value={packageDetail?.originCountryCode}*/}
                {/*                                onChange={handleCountryCode}*/}
                {/*                                size='small'*/}
                {/*                                disabled={isGetRate}*/}
                {/*                                sx={{fontSize: 14}}*/}
                {/*                            >*/}
                {/*                                {*/}
                {/*                                    getCountryCode().map(v => {*/}
                {/*                                        return <MenuItem value={v.code}>{v.name}</MenuItem>*/}
                {/*                                    })*/}
                {/*                                }*/}
                {/*                            </Select>*/}
                {/*                        </FormControl>*/}
                {/*                    </Box>*/}
                {/*                </Grid>*/}
                {/*                <Grid item md={2} xs={6}>*/}
                {/*                    <Box sx={styles.ShippingPackageQuickQuotePackageEachSection}>*/}
                {/*                        <InputLabel required style={{fontSize: '14px'}}>Unit Of Measure</InputLabel>*/}
                {/*                        <FormControl fullWidth>*/}
                {/*                            <Select*/}
                {/*                                value={packageDetail?.UOM}*/}
                {/*                                onChange={handleUOM}*/}
                {/*                                size='small'*/}
                {/*                                disabled={isGetRate}*/}
                {/*                                sx={{fontSize: 14}}*/}
                {/*                            >*/}
                {/*                                {UOMList.map(v => {*/}
                {/*                                    return <MenuItem value={v}>{v}</MenuItem>*/}
                {/*                                })}*/}
                {/*                            </Select>*/}
                {/*                        </FormControl>*/}
                {/*                    </Box>*/}
                {/*                </Grid>*/}
                {/*                <Grid item md={4} xs={12}>*/}
                {/*                    <Box sx={styles.ShippingPackageQuickQuotePackageEachSection}>*/}
                {/*                        <InputLabel required style={{fontSize: '14px'}}>HS Code</InputLabel>*/}
                {/*                        <Autocomplete*/}
                {/*                            disabled={isGetRate}*/}
                {/*                            disablePortal*/}
                {/*                            freeSolo*/}
                {/*                            options={HSCodeList}*/}
                {/*                            getOptionLabel={option => option.hscode}*/}
                {/*                            filterOptions={(options, state) => options}*/}
                {/*                            fullWidth*/}
                {/*                            onInputChange={handleOnInputChangeHSCode}*/}
                {/*                            className={classes.smallInput}*/}
                {/*                            renderInput={(params) => <TextField*/}
                {/*                                {...params}*/}
                {/*                                // multiline*/}
                {/*                                placeholder={packageDetail?.hscode}*/}
                {/*                            />}*/}
                {/*                            renderOption={(option) => (*/}
                {/*                                <Typography*/}
                {/*                                    style={{fontSize: '12px'}}*/}
                {/*                                >*/}
                {/*                                    /!*<Typography>*!/*/}
                {/*                                    {option.hscode} | {option.desc}*/}
                {/*                                    /!*</Typography>*!/*/}
                {/*                                </Typography>*/}
                {/*                            )}*/}
                {/*                        />*/}
                {/*                    </Box>*/}
                {/*                </Grid>*/}
                {/*            </Grid>*/}
                {/*        </AccordionDetails>*/}
                {/*    </Accordion>*/}
                {/*</Grid>*/}
            </Grid>
            <Divider/>
        </Box>
    )
}