import React, {useState} from "react";
import {
  Box, Button, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Snackbar, TextField
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {Alert} from "@material-ui/lab";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import CurrencyFormat from "react-currency-format"
import {setOceanOrder} from "../../../slices/oceanShippingSlice";
import dayjs from "dayjs";
import {PARTNER_URI} from "../../../Utils/apiUrl";
const useStyles = makeStyles((theme) => ({
  listItemWrapper: {
    width: "100%",
    margin: "3px auto",
    "&:hover": {
      cursor: "pointer",
      background: "aliceblue"
    },
    lineHeight: "2rem"
  },
  showMoreText: {
    color: "blue",
    textDecoration: "underline"
  }
}));

export default function OceanShippingTable({ startPort, startPortCode, endPort, endPortCode, twentyGP, fortyGP, fortyHQ, date, routeCode, shipCompany, surcharge, voyage }) {
  const classes = useStyles();

  const [isDisplayMore, setIsDisplayMore] = useState(false);

  const [twentyGPQuantity, setTwentyGPQuantity] = useState(0);
  const [fortyGPQuantity, setFortyGPQuantity] = useState(0);
  const [fortyHQQuantity, setFortyHQQuantity] = useState(0);

  const [toastOpen, setToastOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [placeOrderLoading, setPlaceOrderLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { id, firstname, lastname, email, phone } = useSelector((state) => state.user);

  const history = useHistory();
  const dispatch = useDispatch();

  const saveRawDataToRedux = () => {
    dispatch(setOceanOrder({
      startPort,
      endPort,
      startPortCode,
      endPortCode,
      routeCode,
      sailingDate: date,
      shipCompany,
      voyage,
      twentyGP,
      fortyGP,
      fortyHQ,
      surcharge
    }));
  }

  const goToBolListPage = ({ bookingId }) => {
    history.push(`/freight-forwarding/hs-code?booking_id=${bookingId}`);
  }

  const handleBooking = ({ bookingId }) => {
    saveRawDataToRedux();
    goToBolListPage({ bookingId });
  }

  const getSurchargesText = () => {
    let result = "";
    for (const oneSurcharge of surcharge) {
      if (oneSurcharge.price_unit !== null || (oneSurcharge.price_20 !== null && oneSurcharge.price_40 !== null && oneSurcharge.price_40hq !== null)) {
        let text = "";
        if (oneSurcharge.price_unit) {
          text = `${oneSurcharge.SurchargeNameEn || oneSurcharge.SurchargeName}: [${oneSurcharge.CurrencyName} $${oneSurcharge.price_unit}]\n`;
        } else {
          text = `${oneSurcharge.SurchargeNameEn || oneSurcharge.SurchargeName}: [${oneSurcharge.CurrencyName} $${oneSurcharge.price_20} | $${oneSurcharge.price_40} | $${oneSurcharge.price_40hq}]\n`;
        }
        result += text;
      }
    }
    return result;
  }

  const createOceanShipBookingAndReturnRecord = async () => {
    const requestBody = {
      partnerId: id,
      refNumber: "",
      startPortEnName: startPort,
      startPortCode: startPortCode,
      endPortEnName: endPort,
      endPortCode: endPortCode,
      shipCompany: shipCompany,
      shipCompanyRouteCode: routeCode,
      etdDate: date,
      etaDate: dayjs(date).add(voyage, "day").format("YYYY-MM-DD"),
      voyage: voyage,
      currencyCode: "USD",
      unitOfContainer20GP: twentyGPQuantity,
      unitPriceOfContainer20GP: twentyGP >= 88888 ? 0 : twentyGP, // if over 88888, unavailable
      unitOfContainer40GP: fortyGPQuantity,
      unitPriceOfContainer40GP: fortyGP >= 88888 ? 0 : fortyGP,
      unitOfContainer40HQ: fortyHQQuantity,
      unitPriceOfContainer40HQ: fortyHQ >= 88888 ? 0 : fortyHQ,
      surchargesText: getSurchargesText()
    }
    try {
      const { data } = await axios.post(`${PARTNER_URI}/ocean-freight/createOceanShipBooking`, requestBody);
      return data.result;
    } catch (e) {
      console.log(e);
    }
  }

  const handleToastClick = () => {
    setToastOpen(true);
  };

  const handleToastClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  const handleConfirmDialogOpen = () => {
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const getContainersInfo = (containerType, quantity, price, surcharges) => {
    if (quantity <= 0) {
      return null;
    }
    return {
      container_type: containerType,
      quantity: quantity,
      standard_unit_price: price,
      surcharges
    }
  }

  const createOceanShipBookingAndPlaceOrder = async (e) => {
    try {
      e.preventDefault();
      setPlaceOrderLoading(true);
      setErrorMessage("");
      const record = await createOceanShipBookingAndReturnRecord();
      const bookingId = record.id;
      await confirmPlaceOrder({ bookingId });
      handleToastClick();
      handleConfirmDialogClose();
      setTimeout(() => {
        handleBooking({ bookingId });
      }, 1500);
    } catch (e) {
      setErrorMessage(e?.response?.data?.error || "Error!");
      handleToastClick();
      console.log(e);
    } finally {
      setPlaceOrderLoading(false);
    }
  }

  const confirmPlaceOrder = async ({ bookingId }) => {
    const containerArray = [
      {
        containerType: "20GP",
        quantity: twentyGPQuantity,
        standard_unit_price: twentyGP
      },
      {
        containerType: "40GP",
        quantity: fortyGPQuantity,
        standard_unit_price: fortyGP
      },
      {
        containerType: "40HQ",
        quantity: fortyHQQuantity,
        standard_unit_price: fortyHQ
      }
    ];
    const requestBody = {
      booking_info: {
        booking_id: bookingId
      },
      partner_info: {
        partner_email: email,
        partner_id: id,
        firstname: firstname,
        lastname: lastname,
        phone: phone
      },
      route_info: {
        start_port: startPort,
        end_port: endPort,
        start_port_code: startPortCode,
        end_port_code: endPortCode,
        route_code: routeCode,
        sailing_date: date,
        shipping_company: shipCompany
      },
      shipping_containers: containerArray.map(v => {
        return getContainersInfo(v.containerType, v.quantity, v.standard_unit_price, surcharge);
      }).filter(v => v !== null)
    }
    console.log(requestBody);
    const { data } = await axios.post(`${PARTNER_URI}/ocean-freight/booking`, requestBody);
    console.log(data);
  }

  return (
      <>
        {/*Pop-up Toast*/}
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={toastOpen}
            onClose={handleToastClose}
            autoHideDuration={3000}
            message="Cancel Order">
          {(() => {
            if (errorMessage !== "") {
              return (
                  <Alert onClose={handleToastClose} severity="error" sx={{ width: '100%' }}>
                    Place Order Failed!
                    <hr />
                    Error: {errorMessage}
                  </Alert>
              )
            }
            return (
                <Alert onClose={handleToastClose} severity="success" sx={{ width: '100%' }}>
                  We got your request! Moving to the Bol Page now.
                </Alert>
            )
          })()}

        </Snackbar>
        {/*Pop-up Toast*/}


        {/*Confirm Place Order Dialog*/}
        <Dialog
            open={confirmDialogOpen}
            onClose={handleConfirmDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Booking Ocean Shipping
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {/*This process will place your Shipping Order. Are you sure to continue?*/}
              {/*<hr />*/}
              {/*Click <b>Confirm</b> to place an order*/}
              Please select the container number
              <hr />
              <br />
              <Box display="flex" flexDirection="column" sx={{ gap: "0.5rem" }}>
                  <TextField
                      id="outlined-basic"
                      label="20GP"
                      variant="outlined"
                      defaultValue={twentyGPQuantity}
                      onChange={e => {
                        setTwentyGPQuantity(+e.target.value);
                      }}
                      type="number"
                      value={twentyGPQuantity}
                      inputProps={{ min: 0 }}
                      disabled={twentyGP >= 88888}
                  />
                  <TextField
                      id="outlined-basic"
                      label="40GP"
                      variant="outlined"
                      defaultValue={fortyGPQuantity}
                      onChange={e => {
                        setFortyGPQuantity(+e.target.value);
                      }}
                      type="number"
                      inputProps={{ min: 0 }}
                      disabled={fortyGP >= 88888}
                  />
                  <TextField
                      id="outlined-basic"
                      label="40HQ"
                      variant="outlined"
                      defaultValue={fortyHQQuantity}
                      onChange={e => {
                        setFortyHQQuantity(+e.target.value);
                      }}
                      type="number"
                      inputProps={{ min: 0 }}
                      disabled={fortyHQ >= 88888}
                  />
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleConfirmDialogClose} variant="outlined" autoFocus type="button" >No</Button>
            <Button onClick={createOceanShipBookingAndPlaceOrder} variant="outlined" type="button" disabled={placeOrderLoading} >
              {placeOrderLoading && <CircularProgress size={14} />}
              {!placeOrderLoading && "Confirm"}
            </Button>
          </DialogActions>
        </Dialog>
        {/*Confirm Place Order Dialog*/}


        <tr className={classes.listItemWrapper}>
          {/*<td>{startPort}</td>*/}
          {/*<td>{endPort}</td>*/}
          {/*<td>{startPort} | {startPortCode}&nbsp;&nbsp;&nbsp; --> &nbsp;&nbsp;&nbsp; {endPort} | {endPortCode}</td>*/}
          <td>
            <Box display="flex" flexDirection="column" justifyContent="left"  sx={{ gap: "0.5rem" }}>
              <Box textAlign="left">{startPort} | {startPortCode}</Box>
              <Box textAlign="left">{endPort} | {endPortCode}</Box>
            </Box>
          </td>
          <td>
            <Box display="flex" flexDirection="column">
              {/*<span>${twentyGP}&nbsp; | ${fortyGP}&nbsp; | ${fortyHQ}</span>*/}
              <Box display="flex" flexDirection="row" justifyContent="left" sx={{ gap: "0.5rem" }}>
                <Box display="flex" flexDirection="column">
                  <span>20GP:</span>
                  <span>40GP:</span>
                  <span>40HQ:</span>
                </Box>
                <Box display="flex" flexDirection="column">
                  <span>{+twentyGP >= 88888 ? "Unavailable" : <CurrencyFormat value={(+twentyGP).toFixed(1)} displayType={'text'} thousandSeparator={true} prefix={'$'} />}</span>
                  <span>{+fortyGP >= 88888 ? "Unavailable" : <CurrencyFormat value={(+fortyGP).toFixed(1)} displayType={'text'} thousandSeparator={true} prefix={'$'} />}</span>
                  <span>{+fortyHQ >= 88888 ? "Unavailable" : <CurrencyFormat value={(+fortyHQ).toFixed(1)} displayType={'text'} thousandSeparator={true} prefix={'$'} />}</span>
                </Box>
              </Box>
              {
                (() => {
                  if (!surcharge || surcharge.length === 0) {
                   return <>
                   </>
                  }
                  return <Box textAlign="right" fontSize="small">
                <span className={classes.showMoreText} onClick={() => {
                  setIsDisplayMore(v => !v);
                }} hidden={isDisplayMore}>
                  Show More
                </span>
                    {
                      (() => {
                        if (isDisplayMore) {
                          return (
                              <Box display="flex" flexDirection="column">
                                {
                                  surcharge.filter(v => {
                                    return v.price_unit !== null || (v.price_20 !== null && v.price_40 !== null && v.price_40hq !== null)
                                  }).map(oneCharge => {
                                    return (
                                        <Box display="flex" sx={{ gap: "0.7rem" }} justifyContent="space-between">
                                     <span>
                                       <span>- <b>{oneCharge.SurchargeNameEn || oneCharge.SurchargeName}</b></span>
                                     </span>
                                          <span style={{ display: "flex", justifyContent: "space-between", gap: "0.5rem" }}>
                                       <span>{oneCharge.CurrencyName}</span>
                                       <span style={{ display: "flex", justifyContent: "space-between", gap: "0.5rem" }}>
                                         {
                                           (() => {
                                             if (oneCharge.price_unit) {
                                               return <span>${oneCharge.price_unit}</span>
                                             }
                                             return (
                                                 <>
                                                   <span>
                                                     <span><CurrencyFormat value={oneCharge.price_20} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span> |
                                                   </span>
                                                   <span>
                                                     <span><CurrencyFormat value={oneCharge.price_40} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span> |
                                                   </span>
                                                   <span>
                                                     <span><CurrencyFormat value={oneCharge.price_40hq} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span>
                                                   </span>
                                                 </>

                                             )
                                           })()
                                         }
                                       </span>

                                     </span>

                                        </Box>
                                    )

                                  })
                                }
                              </Box>
                          )
                        }
                      })()

                    }
                    <span className={classes.showMoreText} onClick={() => {
                      setIsDisplayMore(v => !v);
                    }} hidden={!isDisplayMore}>
                  Show Less
                </span>
                  </Box>
                })()
              }

            </Box>
          </td>
          <td>{voyage} days</td>
          <td>{shipCompany},&nbsp;&nbsp; {routeCode}</td>
          <td>{date}</td>
          <td>
            {/*<button onClick={() => {*/}
            {/*  handleConfirmDialogOpen();*/}
            {/*}} >Booking</button>*/}
            <button onClick={() => {
              handleConfirmDialogOpen()
            }} >Booking</button>
          </td>
        </tr>
      </>
  );
}
