import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {IconButton, Link, Tooltip} from "@mui/material";
import Grid from "@mui/material/Grid";
import {FACEBOOK, INSTAGRAM, LINKEDIN, PRIVACY_POLICY, TERMS_OF_USE} from "../Utils/apiUrl";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import {ReactComponent as AssemblyIcon} from "../images/movingService/assemblyService.svg";
import React from "react";

const styles = {
    FooterRoot: {
        // position: 'absolute',
        // bottom: 0,
        width: '100%',
        backgroundColor: '#FFFFFF',
        // marginTop: '100px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
        // zIndex: 5000
    },
    FooterContent: {
        display: 'flex',
        height: '50px',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 20px',
        flexWrap: 'wrap'
    },
    FooterText: {
        fontSize: '14px',
        color: '#000000'
    },
    FooterLink: {
        color: '#000000',
        '&:hover': {
            color: 'black',
            textDecoration: 'underline', // Optional, to show underline on hover
        }
    },
}

export const Footer = () => {

    const currentYear = new Date().getFullYear();

    return (
        <>
            <Box sx={styles.FooterRoot}>
                <Box sx={styles.FooterContent}>
                    <Typography sx={styles.FooterText}>
                        Copyright &copy; {currentYear} UU CARGO LTD. - All Rights Reserved. | <span><Link
                        underline="none" target="_blank" rel="noreferrer" href={PRIVACY_POLICY}
                        sx={styles.FooterLink}>Privacy Policy</Link></span> | <span><Link
                        underline="none" target="_blank" rel="noreferrer" href={TERMS_OF_USE}
                        sx={styles.FooterLink}>Terms of Use</Link></span>
                    </Typography>
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <Tooltip
                                    placement="top"
                                    arrow
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                bgcolor: 'white',
                                                color: 'black',
                                                boxShadow: 1,
                                                border: '1px solid #ddd',
                                                borderRadius: '8px', // Added border radius
                                            },
                                        },
                                        arrow: {
                                            sx: {
                                                color: 'white',
                                            },
                                        },
                                    }}
                                    title={
                                        <Box sx={{backgroundColor: "#ffffff"}}>
                                            <Typography sx={{fontSize: '12px'}}>
                                                (WeChat Number：uucargoco)
                                            </Typography>
                                        </Box>
                                    }>
                                    <img src={'https://storage.googleapis.com/uucargo-resource/image/icon/WeChat.svg'}
                                         alt="WeChat-icon" width={'20px'}/>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={3}>
                                <Link href={LINKEDIN} target="_blank" rel="noreferrer">
                                    <img
                                        src={'https://storage.googleapis.com/uucargo-resource/image/icon/Linkedin.svg'}
                                        alt="Linkedin-icon" width={'20px'}/>
                                </Link>
                            </Grid>
                            <Grid item xs={3}>
                                <Link href={FACEBOOK} target="_blank" rel="noreferrer">
                                    <img
                                        src={'https://storage.googleapis.com/uucargo-resource/image/icon/Facebook.svg'}
                                        alt="Facebook-icon" width={'20px'}/>
                                </Link>
                            </Grid>
                            <Grid item xs={3}>
                                <Link href={INSTAGRAM} target="_blank" rel="noreferrer">
                                    <img
                                        src={'https://storage.googleapis.com/uucargo-resource/image/icon/Instagram.svg'}
                                        alt="Instagram-icon" width={'20px'}/>
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

