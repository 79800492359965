import Grid from "@material-ui/core/Grid";
import {Fragment, useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import intl from "react-intl-universal";
import {LABEL_DOWNLOAD_URL_PREFIX, PARTNER_URI} from "../../../Utils/apiUrl";
import LoadingContainer from "../../shared/loadingContainer";
import Button from "@mui/material/Button";
import axios from "axios";
import {useSelector} from "react-redux";
import {deleteToken, getAccessToken} from "../../../Utils/doToken";
import MuiAlert from "@mui/material/Alert";
import {Snackbar} from "@mui/material";
import * as React from "react";
import {TIME_OUT} from "../../../Utils/consInfo";


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingLTLQuickQuoteRateHeading: {
        textAlign: 'center',
        fontWeight: '600',
        fontSize: '18px'
    },
    ShippingLTLQuickQuoteRateBox: {
        border: '1px solid #609966',
        padding: '20px 0',
        borderRadius: '5px'
    },
    ShippingLTLQuickQuoteRateText: {
        textAlign: 'left',
        fontSize: '16px',
    },
    ShippingLTLQuickQuoteRateCarrierBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100%',
        paddingLeft: '20px'
    }
}

export const ShippingLTLQuickQuoteOtherRate = ({isCallAllRate, allService, availableService, handleShowOtherRate}) => {
    const token = getAccessToken("access_token");

    let rate = useSelector(state => state?.shippingLTL?.quickQuoteRate);
    let moveFrom = useSelector(state => state?.shippingLTL?.quickQuoteMoveFrom);
    let moveTo = useSelector(state => state?.shippingLTL?.quickQuoteMoveTo);

    const [otherRate, setOtherRate] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sortedRate, setSortedRate] = useState([]);
    const [isCallOtherRate, setIsCallOtherRate] = useState(0);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const getLTLRatingByOneServiceWithMaxQuantity = async (serviceName) => {
        let requestURL = `${PARTNER_URI}/shippingLTL/LTLShippingAverageRatingByServiceName`;
        let data = {
            shipperCity: moveFrom?.parentCity || moveFrom?.region,
            shipperSubCity: moveFrom?.city,
            shipperProvince: moveFrom?.province?.code,
            shipperCountry: moveFrom?.province?.countryCode,
            shipperPostalCode: moveFrom?.postalCode,
            consigneeCity: moveTo?.parentCity || moveTo?.region,
            consigneeSubCity: moveTo?.city,
            consigneeProvince: moveTo?.province?.code,
            consigneeCountry: moveTo?.province?.countryCode,
            consigneePostalCode: moveTo?.postalCode,
            serviceName: serviceName,
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data,
                timeout: TIME_OUT
            })
            const LTLRate = result?.data;
            console.log('ltl rating', LTLRate);
            setOtherRate(prevState => {
                return prevState?.map(rateItem => {
                    if (rateItem?.serviceName === LTLRate?.serviceName) {
                        return LTLRate;
                    }
                    return rateItem;
                })
            })
            return true;
        } catch (e) {
            // setError(prevState => [...prevState, service?.account_base]);
            console.log('error', e.response);
            if (e.response.status === 440) {
                setErrorMessage('The authentication token has expired.');
                deleteToken("access_token");
                setTimeout(() => window.location.reload(), 2000);
                handleErrorMessage();
            } else {
                return false;
            }
        } finally {
            setIsCallOtherRate(prevState => prevState + 1);
            setOtherRate(prevRate => {
                return prevRate.map(rateItem => {
                    if (rateItem?.serviceName.toUpperCase() === serviceName?.toUpperCase()) {
                        return {...rateItem, loading: false};
                    }
                    return rateItem;
                });
            });
        }
    }

    useEffect(() => {
        if (rate?.length === isCallAllRate) {
            setIsCallOtherRate(0);
            console.log('[ShippingLTLQuickQuoteOtherRate] call all rate');
            const unCalledService = allService?.filter(carrier => !availableService?.some(availableCarrier => availableCarrier?.account_base === carrier?.account_base));
            const notRatingService = rate?.filter(carrier => carrier?.finalTotalBeforeTax === 0);
            const unavailableService = [...unCalledService, ...notRatingService];
            const updateRate = unavailableService.map(item => ({
                ...item,
                serviceName: item?.account_base?.toLowerCase(),
                loading: true,
                averageTotalCharge: 0
            }));
            setOtherRate(updateRate);
            setLoading(false);
            for (const service of unavailableService) {
                getLTLRatingByOneServiceWithMaxQuantity(service?.account_base);
            }
        }
    }, [isCallAllRate]);

    useEffect(() => {
        const sortedRate = [...otherRate].sort((a, b) => (a?.averageTotalCharge || Infinity) - (b?.averageTotalCharge || Infinity));
        // console.log('[ShippingLTLQuickQuoteOtherRate] sort rate', sortedRate);
        setSortedRate(sortedRate);
    }, [otherRate]);

    if (loading) {
        return (
            <Box sx={{
                backgroundColor: '#FFFFFF',
                padding: '20px',
                marginBottom: '40px',
                borderRadius: '10px',
                boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography sx={{textAlign: 'left', fontWeight: '600'}}>
                            Below is the average reference quotation for a standard pallet.
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography sx={{textAlign: 'left'}}>
                            From: {moveFrom?.city || moveFrom?.region}, {moveFrom?.province?.code}, {moveFrom?.province?.countryCode}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography sx={{textAlign: 'left'}}>
                            To: {moveTo?.city || moveTo?.region}, {moveTo?.province?.code}, {moveTo?.province?.countryCode}
                        </Typography>
                    </Grid>
                    <Grid>
                        <LoadingContainer/>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    if ((isCallOtherRate === sortedRate?.length) && (sortedRate?.length === 0 || sortedRate?.every(obj => obj?.averageTotalCharge === 0))) {
        return <></>
    }

    // console.log('[ShippingLTLQuickQuoteOtherRate] availableService', availableService);
    // console.log('[ShippingLTLQuickQuoteOtherRate] isCallAllRate', isCallAllRate);
    // console.log('[ShippingLTLQuickQuoteOtherRate] rate', rate);
    // console.log('[ShippingLTLQuickQuoteOtherRate] other rate', otherRate);
    // console.log('[ShippingLTLQuickQuoteOtherRate] sort rate', sortedRate);
    // console.log('[ShippingLTLQuickQuoteOtherRate] isCallOtherRate', isCallOtherRate);

    return (
        <Box sx={{
            backgroundColor: '#FFFFFF',
            padding: '20px',
            marginBottom: '40px',
            borderRadius: '10px',
            boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
        }}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography sx={{textAlign: 'left', fontWeight: '600'}}>
                        Below is the average reference quotation for a standard pallet.
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography sx={{textAlign: 'left'}}>
                        From: {moveFrom?.city || moveFrom?.region}, {moveFrom?.province?.code}, {moveFrom?.province?.countryCode}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography sx={{textAlign: 'left'}}>
                        To: {moveTo?.city || moveTo?.region}, {moveTo?.province?.code}, {moveTo?.province?.countryCode}
                    </Typography>
                </Grid>
                {
                    sortedRate?.map((each, index) => (
                        (each.loading || each.averageTotalCharge !== 0) && <Fragment key={index}>
                            <Grid item xs={1}>
                                <Box sx={styles.ShippingLTLQuickQuoteRateCarrierBox}>
                                    <img
                                        src={`${LABEL_DOWNLOAD_URL_PREFIX}${each?.icon_uri}`}
                                        alt="logo"
                                        width={60}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={5}>
                                <Box sx={styles.ShippingLTLQuickQuoteRateCarrierBox}>
                                    <Typography style={styles.ShippingLTLQuickQuoteRateText}>
                                        {
                                            each?.serviceName.toUpperCase() === "DAYANDROSS" ? "DAY && ROSS" : each?.serviceName.toUpperCase() === "TFORCE" ? "TForce" : each?.serviceName.toUpperCase()
                                        }
                                    </Typography>
                                </Box>
                            </Grid>
                            {each.loading ?
                                <Grid item xs={6}>
                                    <LoadingContainer/>
                                </Grid> : <>
                                    <Grid item md={6} xs={12}>
                                        <Box sx={styles.ShippingLTLQuickQuoteRateCarrierBox}>
                                            <Typography
                                                style={styles.ShippingLTLQuickQuoteRateHeading}>
                                                ${(each?.averageTotalCharge)?.toFixed(2)} / per standard pallet
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </>
                            }
                        </Fragment>
                    ))
                }
            </Grid>
        </Box>
    )

}