import {
  DRIVER_ADD_SHIPMENTS_TO_SUMMARY,
  DRIVER_REMOVE_SHIPMENTS_FROM_SUMMARY,
  DRIVER_UPDATE_SHIPMENTS_IN_SUMMARY,
  DRIVER_CLAIM_SHIPMENTS_FROM_SUMMARY,
  DRIVER_CLEAN_ALL_SHIPMENTS_FROM_SUMMARY,
} from "../actions/types";
import produce from "immer";
const initialState = {
  driver_claimed_shipments: [],
};

const driver_claimShipmentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case DRIVER_ADD_SHIPMENTS_TO_SUMMARY:
      const {
        shipmentId,
        packageId,
        count,
        itemName,
        unitPrice,
        availableCount,
        shipmentType,
        weight,
        stage,
      } = action.payload;
      if (state.driver_claimed_shipments.length > 0) {
        const index = state.driver_claimed_shipments.findIndex(
          (item) =>
            item.shipment_number === shipmentId &&
            item.packageId === packageId
        );
        state.driver_claimed_shipments[index] = {
          item_name: itemName,
          shipment_number: shipmentId,
          package_number: packageId,
          packageId: packageId,
          count: count,
          unit_price: unitPrice,
          availableCount: availableCount,
          shipmentType: shipmentType,
          weight: weight,
          stage: stage,
        };
        if (index !== -1) {
          //found
          if (count === 0) {
            return {
              ...state,
              driver_claimed_shipments: [
                ...state.driver_claimed_shipments.slice(0, index),
                ...state.driver_claimed_shipments.slice(index + 1),
              ],
            };
          } else {
            return {
              ...state,
              driver_claimed_shipments: [
                ...state.driver_claimed_shipments.slice(0, index),
                {
                  ...state.driver_claimed_shipments[index],
                  count: count,
                },
                ...state.driver_claimed_shipments.slice(index + 1),
              ],
            };
          }
        } else {
          //not found
          if (count === 0) {
            return { ...state };
          } else {
            return {
              ...state,
              driver_claimed_shipments: [
                ...state.driver_claimed_shipments,
                {
                  shipment_number: shipmentId,
                  package_number: packageId,
                  count: count,
                  item_name: itemName,
                  unit_price: unitPrice,
                },
              ],
            };
          }
        }
      } else if (count === 0) {
        return {
          ...state,
        };
      } else {
        return {
          ...state,
          driver_claimed_shipments: [
            {
              shipment_number: shipmentId,
              package_number: packageId,
              count: count,
              item_name: itemName,
              unit_price: unitPrice,
              availableCount: availableCount,
              shipmentType: shipmentType,
              weight: weight,
              stage: stage,
            },
          ],
        };
      }
    case DRIVER_REMOVE_SHIPMENTS_FROM_SUMMARY:
      return {};
    case DRIVER_UPDATE_SHIPMENTS_IN_SUMMARY:
      return produce(state, (draft) => {
        const targetIndex = state.driver_claimed_shipments.findIndex((s) => {
          return s.shipment_number === action.payload.shipmentId;
        });

        draft.driver_claimed_shipments[targetIndex] = {
          item_name: action.payload.itemName,
          shipment_number: action.payload.shipmentId,
          package_number: action.payload.packageId,
          count: action.payload.count,
          unit_price: action.payload.unitPrice,
          availableCount: action.payload.availableCount,
          weight: action.payload.weight,
          stage: action.payload.stage,
        };
      });
    case DRIVER_CLAIM_SHIPMENTS_FROM_SUMMARY:
      return {};
    case DRIVER_CLEAN_ALL_SHIPMENTS_FROM_SUMMARY:
      return {
        driver_claimed_shipments: [],
      };
    default:
      return state;
  }
};

export default driver_claimShipmentsReducer;
