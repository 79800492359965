import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import LoadingButton from "@mui/lab/LoadingButton";
import {getAccessToken} from "../../../Utils/doToken";
import {useEffect, useState} from "react";
import {CreditCardList} from "../../CreditCard/Business/CreditCardList";
import CreditCardIcon from "../../../images/creditCardIcon.png";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Modal from "@mui/material/Modal";
import {CreditCardSetup} from "../../CreditCard/Business/CreditCardSetup";
import intl from "react-intl-universal";

const styles = {
    BillingPaymentRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    BillingPaymentContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px'
    },
    BillingPaymentTitle: {
        fontWeight: '600',
        textAlign: 'left',
    },
    BillingPaymentSubTitle: {
        fontWeight: '600',
        textAlign: 'left',
        fontSize: '14px'
    }
}

export const BusinessBillingCreditCard = () => {

    const storedToken = getAccessToken("access_token");

    const [cardList, setCardList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [addNewCard, setAddNewCard] = useState(false);
    const [selectCardId, setSelectCardId] = useState(null);

    const handleClickOpen = () => {
        setAddNewCard(true);
    };

    const handleClose = () => {
        setAddNewCard(false);
    };

    const cbSelectCardId = (cardId) => {
        setSelectCardId(cardId);
    }

    const getValidCardList = async () => {
        const requestURL = `${PARTNER_URI}/stripe/validCreditCardList`;
        setIsLoading(true);
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {Authorization: `Bearer ${storedToken}`},
            })

            console.log('result', result);
            setCardList(result?.data);
            setIsLoading(false);
            setError(false);
        } catch (e) {
            console.log('error', e.response);
            setError(true);
        }
    }

    const cbGetValidCardList = () => {
        getValidCardList();
    }

    useEffect(() => {
        getValidCardList()
    }, []);

    return (
        <Box sx={styles.BillingPaymentRoot}>
            <Typography style={styles.BillingPaymentTitle}>
                {intl.get('BILLING_CENTER.CREDIT_CARD')}
            </Typography>
            {/*<Box sx={{*/}
            {/*    border: '1px solid #000000',*/}
            {/*    borderRadius: '5px',*/}
            {/*    padding: '10px',*/}
            {/*    display: 'flex',*/}
            {/*    flexDirection: 'column',*/}
            {/*    gap: '10px'*/}
            {/*}}>*/}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center'
                }}>
                {/*<Typography style={styles.BillingPaymentSubTitle}>*/}
                {/*    Credit Card*/}
                {/*</Typography>*/}
                {
                    cardList?.length > 0 ? <LoadingButton
                            variant='contained'
                            sx={{
                                backgroundColor: '#1D8B45',
                                "&:hover": {
                                    backgroundColor: '#1D8B45',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                            onClick={handleClickOpen}
                            disabled={!selectCardId}
                        >
                            <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                                {intl.get('BILLING_CENTER.UPDATE')}
                            </Typography>
                        </LoadingButton>
                        :
                        <LoadingButton
                            variant='contained'
                            sx={{
                                backgroundColor: '#1D8B45',
                                "&:hover": {
                                    backgroundColor: '#1D8B45',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                            onClick={handleClickOpen}
                        >
                            <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                                {intl.get('BILLING_CENTER.ADD_NEW')}
                            </Typography>
                        </LoadingButton>
                }
            </Box>
            {
                cardList?.length > 0 ? <CreditCardList cardList={cardList} cbSelectCardId={cbSelectCardId}/> :
                    <Box sx={{
                        display: 'flex',
                        border: '1px dashed #000000',
                        flexDirection: 'column',
                        borderRadius: '5px',
                        gap: '5px',
                        padding: '10px',
                        backgroundColor: '#EAEAEA'
                    }}>
                        <Box sx={{
                            position: 'relative',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}>
                            <img src={CreditCardIcon} alt="creditCardLogo" width={30}/>
                            <IconButton
                                sx={{
                                    position: 'absolute',
                                    left: '49%',
                                    top: '15%',
                                }}
                                onClick={handleClickOpen}
                            >
                                <AddCircleIcon
                                    sx={{
                                        backgroundColor: '#ffffff',
                                        color: '#1D8B45',
                                        borderRadius: '50%',
                                        fontSize: '12px'
                                    }}
                                />
                            </IconButton>
                            <Typography style={{fontSize: '14px', fontWeight: '600'}}>
                                {intl.get('BILLING_CENTER.ADD_NEW_CARD')}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography style={{fontSize: '12px'}}>
                                {intl.get('BILLING_CENTER.ADD_CARD_TEXT')}
                            </Typography>
                        </Box>
                        <Box sx={{display: 'flex', gap: '2px'}}>
                            <Typography style={{fontSize: '10px', textAlign: 'left', color: 'red'}}>
                                *
                            </Typography>
                            <Box>
                                <Typography style={{fontSize: '10px', textAlign: 'left'}}>
                                    {intl.get('BILLING_CENTER.ADD_CARD_NOTE')}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
            }
            <Modal onClose={handleClose} open={addNewCard}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: '1px solid #000000',
                    minWidth: 350,
                    boxShadow: 24,
                    backgroundColor: '#ffffff',
                    borderRadius: '10px',
                    zIndex: '7000'
                }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Box sx={{backgroundColor: '#1D8B45', borderRadius: '9px 9px 0 0', padding: '20px'}}>
                            <Typography style={{fontSize: '20px', color: '#ffffff'}}>
                                Add Credit Card
                            </Typography>
                        </Box>
                        <Box>
                            <CreditCardSetup handleClose={handleClose} cbGetValidCardList={cbGetValidCardList}
                                             selectCardId={selectCardId}/>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </Box>
    )
}