import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ShipmentOptionsLayout from "./ShipmentOptionsLayout";
import {
  makeStyles,
  withStyles,
  useTheme,
  Paper,
  Typography,
  ButtonBase,
  TextField,
  Tab,
  Tabs,
  Box,
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import {
  driverAddShipmentsToSummary,
  driverRemoveShipmentsFromSummary,
  driverUpdateShipmentsInSummary,
  driverClaimShipmentsFromSummary,
} from "../../actions/driverClaimShipmentAction";
import { useSelector } from "react-redux";
import store from "../../store/index";
import ViewImagesLayout from "./ViewImagesLayout";
import { pageTitle, general_info_label, success_tag } from "../../css/MUIStyle";
import { shipmentTypesDetails } from "../shared";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: "0px auto",
    fontFamily: "inherit",
  },
  title: pageTitle,
  availableTag: success_tag,
  paper: {
    width: "96%",
    maxWidth: "1000px",
    backgroundColor: "#fdf5e8",
    padding: "5px",
  },
  imageContainer: {
    margin: "0px auto",
  },
  img: {
    margin: "5px auto",
    width: "100%",
    display: "block",
    maxHeight: "150px",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
  },
  blocks: {
    backgroundColor: "#fbfbf8",
    border: "1px solid #ded47c",
    borderRadius: "5px",
    padding: "5px",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    flexDirection: "column",
  },
  backdrop: {
    zIndex: 99,
    color: "#fff",
  },
  shipmentWrapper: {
    width: "100%",
    height: "fit-content",
    marginTop: "5px",
    padding: "10px",
    display: "flex",
    flexDirection: "column",
  },
  tabsWrapper: {
    margin: "5px auto",
    color: "grey",
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
  panelWrapper: {
    backgroundColor: "white",
    padding: "5px",
    boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 10px",
    borderRadius: "5px",
  },
  pictureWrapper: {
    width: "fit-content",
    maxWidth: "50%",
    height: "fit-content",
    maxHeight: "250px",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  picture: {
    width: "100%",
    height: "80%",
  },
  shippingInfo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "5px auto",
    height: "fit-content",
    width: "100%",
  },
  shipmentInfo: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    gap: "5px",
    height: "fit-content",
    width: "100%",
    maxWidth: "650px",
    margin: "0px auto",
  },
  priceWrapper: {
    padding: "10px",
    width: "100%",
    height: "fit-content",
    margin: "auto",
    display: "flex",
    gap: "10px",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
  },
  priceAvailableWrapper: {
    display: "flex",
    flexDirection: "column",
  },
}));

const AntTabs = withStyles({
  root: {
    boxShadow: "rgba(0, 0, 0, 0.3) 0px 4px 12px",
    borderRadius: "5px",
  },
  indicator: {
    backgroundColor: "darkorange",
    height: "5px",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    font: "inherite",
    "&:hover": {
      color: "darkorange",
      opacity: 1,
    },
    "&$selected": {
      color: "darkorange",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "darkorange",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          <>{children}</>
        </div>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const ShipmentDetailLayout = ({ item_name,
                                length,
                                available_count,
                                height,
                                shipment_type,
                                unit_price,
                                weight,
                                width,
                                package_number,
                                package_id,
                                images,
                                cover_image,
                                shipment_number,
                                shipment_type_id,
                                shipment_id,
                                sender,
                                sender_name,
                                schedule_pickup,
                                deliver_address,
                                deliver_area,
                                deliver_city,
                                deliver_postal_code,
                                deliver_province,
                                driver_name,
                                order_number,
                                pickup_address,
                                pickup_area,
                                pickup_city,
                                pickup_postal_code,
                                pickup_province,
                                shipping_status,
                                status,
                                tracking_numbers,
                                option_list,
                                stage
}) => {


  const initialQuantity = () => {
    if (available_count > 0) {
      return 1;
    }
  };

  const classes = useStyles();
  const theme = useTheme();
  const cardActive = useSelector((state) => {
    return state.user.activeCard === "active";
  });

  const [quantity, setQuantity] = useState(initialQuantity());
  const [disableTextField, setDisableTextField] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [packageImages, setPackageImages] = useState([]);
  const [openImageView, setOpenImageView] = useState(false);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);

    //update shipment task in summary
    store.dispatch(
      driverUpdateShipmentsInSummary(
        shipment_number,
        package_number,
        quantity,
        item_name,
        unit_price,
        available_count,
        shipment_type,
        weight,
        stage
      )
    );
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const getPickupDate = (_schedule_pickup) => {
    return _schedule_pickup.split(" ")[0];
  };

  const blurQuantityTextField = () => {
    const target = document.getElementById("quanityTextField");
    if (target) {
      target.blur();
    }
  };

  useEffect(() => {
    setPackageImages(images);
  }, [images]);

  useEffect(() => {
    document.addEventListener("scroll", blurQuantityTextField);
    return () => {
      document.removeEventListener("scroll", blurQuantityTextField);
    };
  }, []);

  useEffect(() => {
    if (available_count === 0) {
      setDisableTextField(true);
    } else {
      setDisableTextField(false);
    }
  }, [available_count]);

  useEffect(() => {
    if (available_count > 0) {
      store.dispatch(
        driverAddShipmentsToSummary(
          shipment_number,
          package_number,
          package_id,
          1,
          item_name,
          unit_price,
          available_count,
          shipment_type,
          weight,
          stage
        )
      );
      setTotalPrice(unit_price * 1);
    }
  }, [available_count, item_name, package_number, shipment_number, shipment_type, stage, unit_price, weight]);

  const handleChange = (
    e,
    shipmentId,
    packageId,
    itemName,
    unitPrice,
    stage
  ) => {
    if (e.target.value === "") {
      setQuantity();
    }
    let number = Number(e.target.value);
    if (number > available_count) {
      number = available_count;
    } else if (number < 0) {
      number = 0;
    }

    setQuantity(number.toString());

    //calculate total price
    setTotalPrice(number * unit_price);

    store.dispatch(
      driverUpdateShipmentsInSummary(
        shipmentId,
        packageId,
        number,
        itemName,
        unitPrice,
        available_count,
        shipment_type,
        weight,
        stage
      )
    );
  };

  const handleImageClick = () => {
    if (images.data.length > 0) {
      setOpen(true);
      setOpenImageView(true);
    }
  };

  const handleCloseImageView = () => {
    setOpenImageView(false);
    setOpen(false);
  };

  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Typography className={classes.title} align="center">
            {"Shipping Task Detail"}
          </Typography>
          <div className={[classes.blocks, classes.shipmentWrapper].join(" ")}>
            <div>
              <span style={{ fontWeight: "650", fontSize: "1.2rem" }}>
                Shipment Number:{" "}
              </span>
              <span style={{ textDecoration: "underLine" }}>
                {shipment_number}
              </span>
            </div>
            <div className={classes.pictureWrapper}>
              <div style={{ display: "contents" }}>
                <ButtonBase
                  className={classes.imageContainer}
                  onClick={() => handleImageClick()}
                >
                  <img
                    className={classes.img}
                    alt="shipment"
                    src={cover_image}
                  />
                </ButtonBase>
                <span style={{ textAlign: "center", fontWeight: "650" }}>
                  {item_name}
                </span>
              </div>
            </div>
            <div className={[classes.shipmentInfo, classes.blocks].join(" ")}>
              <div style={{ whiteSpace: "nowrap" }}>
                <span style={general_info_label}>Length: </span>
                <span>
                  {length}
                  {shipmentTypesDetails[shipment_type_id].sizeUnit}
                </span>
              </div>
              <div style={{ whiteSpace: "nowrap" }}>
                <span style={general_info_label}>Width: </span>
                <span>
                  {width}
                  {shipmentTypesDetails[shipment_type_id].sizeUnit}
                </span>
              </div>
              <div style={{ whiteSpace: "nowrap" }}>
                <span style={general_info_label}>Height: </span>
                <span>
                  {height}
                  {shipmentTypesDetails[shipment_type_id].sizeUnit}
                </span>
              </div>
              <div>
                <span style={general_info_label}>Weight: </span>
                <span>
                  {weight}
                  {shipmentTypesDetails[shipment_type_id].weightUnit}
                </span>
              </div>
              <span style={{ textDecoration: "underline" }}>
                {shipmentTypesDetails[shipment_type_id].label}
                {"(" +
                  shipmentTypesDetails[shipment_type_id].type_name +
                  ")"} x {tracking_numbers.length}
              </span>
            </div>
            <div className={classes.tabsWrapper}>
              <AntTabs
                value={value}
                onChange={handleChangeTab}
                aria-label="protential shipment tabs"
              >
                <AntTab label={`Shipping Task 1`} />;
              </AntTabs>
              <span className={classes.padding} />
            </div>


            <TabPanel
              key={0}
              value={value}
              index={0}
              dir={theme.direction}
            >
              <div className={classes.panelWrapper}>
                <span
                  style={{
                    textAlign: "left",
                    padding: "3px 5px",
                    lineHeight: "1.1",
                    wordBreak: "break-all",
                    flex: "0 0 100%",
                  }}
                >
                  <span style={{ fontWeight: "650", fontSize: "1.2rem" }}>
                    Shipment Task Number:{" "}
                  </span>
                  <span style={{ textDecoration: "underLine" }}>
                    {shipment_id}
                  </span>
                </span>
                <div
                  className={[classes.shippingInfo, classes.blocks].join(
                    " "
                  )}
                >
                  <div>
                    <h6 style={{ padding: "2px" }}>
                      <strong>Pick-up Address:</strong>{" "}
                      {cardActive
                        ? pickup_address
                        : pickup_city}
                    </h6>
                    <h6 style={{ padding: "2px" }}>
                      <strong>Delivery Address:</strong>{" "}
                      {cardActive
                        ? deliver_address
                        : deliver_city}
                    </h6>
                    <h6 style={{ padding: "2px" }}>
                      <strong>Pickup date:</strong>{" "}
                      {getPickupDate(schedule_pickup)}
                    </h6>
                    {option_list && (
                      <ShipmentOptionsLayout
                        optionList={option_list}
                      />
                    )}
                  </div>
                </div>
                <div
                  className={[classes.priceWrapper, classes.blocks].join(
                    " "
                  )}
                >
                  <div className={classes.priceAvailableWrapper}>
                    <span
                      style={{
                        backgroundColor: "gold",
                        borderRadius: "10px",
                        padding: "2px 10px",
                        color: "white",
                        border: "2px solid gold",
                        width: "180px",
                        textAlign: "center",
                        margin: "5px 10px",
                      }}
                    >
                      Unit Price: ${unit_price}
                    </span>
                    <span className={classes.availableTag}>
                      Available: {available_count}
                    </span>
                  </div>
                  <div>
                    <TextField
                      id="quanityTextField"
                      label="Quantity"
                      variant="outlined"
                      type="number"
                      onChange={(e) =>
                        handleChange(
                          e,
                          shipment_number,
                          package_id,
                          item_name,
                          unit_price,
                          stage
                        )
                      }
                      value={quantity}
                      disabled={disableTextField}
                    />
                    <div>
                      <span style={general_info_label}>Total Price: </span>
                      <span>${totalPrice}</span>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>

          </div>
        </Paper>
      </div>
      {openImageView && (
        <ViewImagesLayout images={packageImages} close={handleCloseImageView} />
      )}
      {<Backdrop className={classes.backdrop} open={open}></Backdrop>}
    </>
  );
};

ShipmentDetailLayout.prototype = {
  shipmentInfo: PropTypes.arrayOf(PropTypes.object),
};

export default ShipmentDetailLayout;
