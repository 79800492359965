import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {getAccessToken} from "../../Utils/doToken";
import {useEffect, useState} from "react";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {OrderDashBoardAdjustmentsEach} from "./OrderDashBoardAdjustmentsEach";

const style = {
    OrderDashBoardAdjustmentsRoot: {
        width: '47vw',
        backgroundColor: '#FFFFFF',
        height: '400px',
        overflow: 'scroll'
    },
    OrderDashBoardAdjustmentsTable: {
        minWidth: '800px'
    }
}

const headCells = [
    {
        id: "invoiceNumber",
        numeric: false,
        // disablePadding: true,
        label: "Invoice #",
        width: '25%'
    },
    {
        id: "trackingNumber",
        numeric: false,
        // disablePadding: true,
        label: "Tracking #",
        width: '25%'
    },
    {
        id: "dimension",
        numeric: false,
        // disablePadding: false,
        label: "Dimension (LxWxH)",
        width: '10%'
    },
    {
        id: "weight",
        numeric: false,
        // disablePadding: false,
        label: "Weight",
        width: '10%'
    },
    {
        id: "price",
        numeric: false,
        // disablePadding: false,
        label: "Price",
        width: '20%'
    },
    {
        id: "reason",
        numeric: false,
        // disablePadding: false,
        label: "Reason",
        width: '10%'
    },
];

export const OrderDashBoardAdjustments = ({orderId}) => {
    const accessToken = getAccessToken("access_token");
    const [adjustments, setAdjustments] = useState();

    const getPartnerShippingTransAdjustViewListByOrderId = async () => {

        let requestURL = `${NODE_ROUTE_URI}/shippingOrderDetailsDashboard/getPartnerShippingTransAdjustViewListByOrderId`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                params: {
                    orderId: orderId
                }
            })
            console.log('adjustments', result.data)
            setAdjustments(result.data);

        } catch (e) {
            console.log(e.response)
        }
    }

    useEffect(() => {
        getPartnerShippingTransAdjustViewListByOrderId();
    }, [])

    return (
        <Box sx={style.OrderDashBoardAdjustmentsRoot}>
            <Typography variant='h5' style={{textAlign: 'center'}}>
                Adjustments
            </Typography>
            <TableContainer sx={style.OrderDashBoardAdjustmentsTable}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => (
                                <TableCell
                                    key={headCell.id}
                                    align={headCell.numeric ? "right" : "left"}
                                    padding={headCell.disablePadding ? "none" : "normal"}
                                    style={{fontWeight: "bold", width: headCell.width}}
                                >
                                    {headCell.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            adjustments?.map((adjustmentsEach, index) =>
                                <OrderDashBoardAdjustmentsEach adjustmentsEach={adjustmentsEach} key={index}/>)

                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {adjustments?.length === 0 && <Typography style={{textAlign: 'center', fontSize:'22px'}}>
                No available adjustments for this order
            </Typography>}
        </Box>
    )
}