import React, { useState, useEffect } from "react";
// import { useSelector } from "react-redux";
import {
  makeStyles,
  Container,
  Box,
  MobileStepper,
  Button,
} from "@material-ui/core";
import produce from "immer";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import axios from "axios";
import { useParams } from "react-router-dom";
import LoadingContainer from "../shared/loadingContainer";
// import { Alert } from '@material-ui/lab';
// import { defaultShippingItem } from "../../slices";
// import { shipmentTypesDetails } from "../shared/constInfo";
import HscodeAddressInput from "./Components/BolAddressInput/hscodeAddressInput";
import HscodeItems from "./Components/BolItems/bolItems";
import BolDetails from "./Components/BolDetails/bolDetails";
import { BOL_DETAILS } from "../../Utils/apiUrl";
import { getHeaders, errHandling } from "../../Utils/fetchUtil";

const defaultBolData = {
  shipperName: "",
  shipperAddress: "",
  carrierAgent: "",
  consigneeName: "",
  consigneeAddress: "",
  deliveryAgent: "",
  placeOfAcceptance: "",
  preCarriage: "",
  numberOfOriginal: null,
  payableAt: "",
  excessValueDeclaration: "",
  vessel: "",
  portOfLoading: "",
  portOfDischarge: "",
  placeOfDelivery: "",
  onCarriageTo: "",
  onBoardDate: "",
  freightDetails: "",
  issueDate: "",
  issuePlace: "",
  nofifyPartyName: "",
  nofifyPartyAddress: "",
};

const HscodePage = ({ edit = false }) => {
  // const dispatch = useDispatch();
  const classes = useStyles();
  const { number: bolNumber } = useParams();

  const [success, setSuccessState] = useState(false);
  // const shippingItems = useSelector((state) => state.shipping.shippingItems);

  const [shippingInfo, setShippingInfo] = useState(defaultBolData);

  const [itemList, updateItemList] = useState([]);
  const [fetchingState, setFetchingState] = useState(true);
  const [errMsg, setErrMsg] = useState("");
  // const classes = useStyles();

  const [userInputError, setUserInputError] = useState({
    emtpyError: false,
  });

  const [activeStep, setActiveStep] = React.useState(edit ? 2 : 0);

  useEffect(() => {
    if (edit) {
      axios
        .get(`${BOL_DETAILS}?ref_no=${bolNumber}`, {
          headers: getHeaders(),
        })
        .then((response) => {
          if (response.data) {
            const {
              shipper_name,
              shipper_address,
              carrier_agent,
              consignee_name,
              consignee_address,
              delivery_agent,
              place_of_acceptance,
              pre_carriage,
              number_of_original,
              payable_at,
              excess_value_declaration,
              vessel,
              port_of_loading,
              port_of_discharge,
              place_of_delivery,
              on_carriage_to,
              on_board_date,
              freight_details,
              issue_date,
              issue_place,
              nofify_party_name,
              nofify_party_address,
            } = response.data;
            setShippingInfo({
              shipperName: shipper_name,
              shipperAddress: shipper_address,
              carrierAgent: carrier_agent,
              consigneeName: consignee_name,
              consigneeAddress: consignee_address,
              deliveryAgent: delivery_agent,
              placeOfAcceptance: place_of_acceptance,
              preCarriage: pre_carriage,
              numberOfOriginal: number_of_original,
              payableAt: payable_at,
              excessValueDeclaration: excess_value_declaration,
              vessel: vessel,
              portOfLoading: port_of_loading,
              portOfDischarge: port_of_discharge,
              placeOfDelivery: place_of_delivery,
              onCarriageTo: on_carriage_to,
              onBoardDate: on_board_date,
              freightDetails: freight_details,
              issueDate: issue_date,
              issuePlace: issue_place,
              nofifyPartyName: nofify_party_name,
              nofifyPartyAddress: nofify_party_address,
            });
          }

          if (response.data?.item_list) {
            updateItemList(response.data?.item_list);
          }
          setFetchingState(false);
        })
        .catch((err) => {
          errHandling(err, setErrMsg);
          // setPriceErrMsg(err.response.data.message);
          setFetchingState(false);
        });
    }
  }, []);

  useEffect(() => {
    const emptyField =
      shippingInfo.shipperName === "" ||
      shippingInfo.shipperAddress === "" ||
      shippingInfo.carrierAgent === "" ||
      shippingInfo.consigneeName === "" ||
      shippingInfo.consigneeAddress === "" ||
      shippingInfo.deliveryAgent === "" ||
      shippingInfo.placeOfAcceptance === "" ||
      shippingInfo.preCarriage === "" ||
      shippingInfo.numberOfOriginal === null ||
      shippingInfo.payableAt === "" ||
      shippingInfo.onBoardDate === "";

    if (!emptyField) {
      setUserInputError((prev) => {
        return { ...prev, emtpyError: false };
      });
    }
  }, [shippingInfo]);

  const handleItemChange = (fieldName) => (e) => {
    let value = e.target.value;
    setShippingInfo(
      produce((draft) => {
        draft[fieldName] = value;
      })
    );
  };

  const clearData = () => {
    setShippingInfo(defaultBolData);
    updateItemList([]);
  };

  const handleNext = () => {
    // if (activeStep === 0) next(false);
    // if (activeStep === 1) {
    //   if (shippingItems.length !== 0) {
    //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //     handleSeventhPage();
    //   } else setEmtpyItemList(true);
    // }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Container maxWidth="md">
      {activeStep === 0 && (
        <HscodeAddressInput
          shippingInfo={shippingInfo}
          userInputError={userInputError}
          handleItemChange={handleItemChange}
        />
      )}

      {activeStep === 1 && (
        <HscodeItems itemList={itemList} updateItemList={updateItemList} />
      )}

      {activeStep === 2 &&
        (edit && fetchingState ? (
          <LoadingContainer />
        ) : (
          <BolDetails
            shippingInfo={shippingInfo}
            itemList={itemList}
            setActiveStep={setActiveStep}
            success={success}
            setSuccessState={setSuccessState}
            clearData={clearData}
            edit={edit}
            refNo={bolNumber || ""}
          />
        ))}

      {!success && (
        <Box className={classes.stepper} mt={4}>
          <MobileStepper
            variant="dots"
            steps={3}
            position="static"
            activeStep={activeStep}
            className={classes.root}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === 2}
              >
                Next
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                <KeyboardArrowLeft />
                Back
              </Button>
            }
          />
        </Box>
      )}
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "20px",
    padding: theme.spacing(2, 4, 3),
    overflow: "hidden",
  },
  input: {
    color: "grey",
    width: "90%",
  },
  label: {
    flexDirection: "column",
  },
  icon: {
    fontSize: "32px !important",
    marginBottom: theme.spacing(1),
  },
  list: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)",
  },
  backButton: {
    marginRight: theme.spacing(4),
    backgroundColor: "white",
  },
  subTitle: {
    color: "darkgreen",
    fontWeight: 600,
    fontSize: "1.2rem",
    fontFamily: "Montserrat",
  },
}));

HscodePage.propTypes = {};

export default HscodePage;
