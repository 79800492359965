import Visa from "./Visa.png";
import Mastercard from "./Mastercard.png";
import Amex from "./Amex.png";
import Discover from "./Discover.png";
import Jcb from "./Jcb.png";
import Unionpay from "./Unionpay.png";
import Diners from "./Diners.png";

export const CardImages = {
  Visa,
  Mastercard,
  Amex,
  Discover,
  Jcb,
  Unionpay,
  Diners,
};
