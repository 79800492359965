import React, { useState, useEffect } from "react";
import cn from "classnames";
import { useHistory } from "react-router-dom";
import {
  CardMedia,
  Card,
  CardHeader,
  IconButton,
  useMediaQuery,
  Box,
  // AppBar,
  Paper,
  Tabs,
  Tab,
  Snackbar,
  Button,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import LoadingContainer from "../loadingContainer";
import { Alert } from "@material-ui/lab";
import "../../../styles/tabs.css";

import axios from "axios";
import {
  BACKEND_ORDER_LABELS_API,
  BACKEND_REMOVE_PACKAGE_IMAGE_API,
  GET_PACKAGE_IMAGE,
  UPLOAD_PACKAGE_IMAGE,
} from "../../../Utils/apiUrl";
import { getHeaders, errHandling } from "../../../Utils/fetchUtil";
import { redirectShipmentDetails } from "../../../Utils/redirect";
import { shipmentTypesDetails } from "../constInfo";

const gcsUri = "https://storage.googleapis.com/uucargo-resource/";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2} pt={1}>
          {children}
        </Box>
      )}
    </Box>
  );
}

const PackageNoTabs = ({
  tabs,
  defaultIndex = 0,
  activeOrder,
  guest = false,
}) => {
  const history = useHistory();

  const [activeTabIndex, setActiveTabIndex] = useState(defaultIndex);
  const [images, setImages] = useState([
    // { image_url: tabs[defaultIndex]?.image_location || "" },
    { file_location_uri: "" },
    { file_location_uri: "" },
    { file_location_uri: "" },
  ]);

  console.log(images);
  const [currentPackageNumber, setCurrentPackageNumber] = useState(
    tabs[defaultIndex]?.package_number || ""
  );
  const bodyFormData = new FormData();
  const classes = useStyles();

  const matches = useMediaQuery("(max-width:600px)");
  const theme = useTheme();

  const [fetchImg, setFetchImg] = useState(false);
  const [value, setValue] = useState(defaultIndex || 0);
  const [imageLoading, setImageLoading] = useState(false);

  // Error message from the backend
  const [imageErrMsg, setImageErrMsg] = useState("");

  // Error message in the frontend
  const [addImageErr, setAddImageErrState] = useState(false);

  const [deleteImageErrMsg, setDeleteImageErrMsg] = useState("");

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }

  const downloadLabels = (number) => () => {
    const fileURL = `${BACKEND_ORDER_LABELS_API}${number}`;
    axios({
      url: fileURL, //your url
      method: "GET",
      headers: { ...getHeaders(), "Content-Type": "application/zip" },
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `shippingLabels_${number}.zip`); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  const handleChange = (event, newValue) => {
    setActiveTabIndex(newValue);
    setCurrentPackageNumber(tabs[newValue].package_number);
    setValue(newValue);
  };

  useEffect(() => {
    if (!guest && currentPackageNumber) {
      setImageLoading(true);
      axios
        .get(`${GET_PACKAGE_IMAGE}?packageNumber=${currentPackageNumber}`, {
          headers: {
            ...getHeaders(),
            "Content-Type": "multipart/form-data;",
          },
        })
        .then((response) => {
          if (response.data.imagesList) {
            const len = response.data.imagesList.length;
            if (len === 0)
              setImages([
                // {
                //   image_url: tabs[value]?.image_location || "",
                //   cover_image: true,
                // },
                { file_location_uri: "" },
                { file_location_uri: "" },
                { file_location_uri: "" },
              ]);
            if (len === 1)
              setImages([
                response.data.imagesList[0],
                { file_location_uri: "" },
                { file_location_uri: "" },
              ]);
            if (len === 2)
              setImages([
                response.data.imagesList[0],
                response.data.imagesList[1],
                { file_location_uri: "" },
              ]);
            if (len >= 3) setImages(response.data.imagesList.slice(0, 3));
          }
          setImageLoading(false);
        })
        .catch((err) => {
          setImageLoading(false);
          errHandling(err, setImageErrMsg);
          // console.log(err.response.data);
        });
    }
  }, [currentPackageNumber, guest]);

  useEffect(() => {
    if (fetchImg && currentPackageNumber) {
      setImageLoading(true);
      axios
        .get(`${GET_PACKAGE_IMAGE}?package_number=${currentPackageNumber}`, {
          headers: {
            ...getHeaders(),
            "Content-Type": "multipart/form-data;",
          },
        })
        .then((response) => {
          setFetchImg(false);
          console.log(response.data);
          if (response.data.imagesList) {
            const len = response.data.imagesList.length;
            if (len === 0)
              setImages([
                // {
                //   image_url: tabs[value]?.image_location || "",
                //   cover_image: true,
                // },
                { file_location_uri: "" },
                { file_location_uri: "" },
                { file_location_uri: "" },
              ]);
            if (len === 1)
              setImages([
                response.data.imagesList[0],
                { file_location_uri: "" },
                { file_location_uri: "" },
              ]);
            if (len === 2)
              setImages([
                response.data.imagesList[0],
                response.data.imagesList[1],
                { file_location_uri: "" },
              ]);
            if (len >= 3) setImages(response.data.imagesList.slice(0, 3));
          }
          setImageLoading(false);
        })
        .catch((err) => {
          setFetchImg(false);
          setImageLoading(false);
          errHandling(err, setImageErrMsg);
          // console.log(err.response.data);
        });
    }
  }, [fetchImg]);

  // const files = () => {
  //     images.map(item => {
  //         console.log(item.filename)
  //     })
  // }

  // Remove the image
  const handleRemove = (imageID, packageNum) => () => {
    setImages(images.filter((imageItem) => imageItem.image_id !== imageID));
    const bodyFormData = {
      package_number: packageNum,
      image_id: imageID,
    };
    axios({
      method: "post",
      url: BACKEND_REMOVE_PACKAGE_IMAGE_API,
      data: bodyFormData,
      headers: {
        ...getHeaders(),
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    })
      .then((res) => {
        // setFetchImg(true);
      })
      .catch((error) => {
        // console.log(error.response.data);
        errHandling(error, setDeleteImageErrMsg);
      });
  };

  const onDrop = (e) => {
    if (!(e.target.files && e.target.files[0])) return;
    if (e.target.files[0].size > 5242880) {
      setAddImageErrState(true);
      // this.value = "";
      return;
    }
    try {
      bodyFormData.append("img_file", e.target.files[0]);
    } finally {
      axios({
        method: "post",
        url: `${UPLOAD_PACKAGE_IMAGE}?packageNumber=${currentPackageNumber}`,
        data: bodyFormData,
        headers: {
          ...getHeaders(),
          "Content-Type": "multipart/form-data;",
          "X-Requested-With": "XMLHttpRequest",
        },
      })
        .then((res) => {
          setFetchImg(true);
        })
        .catch((error) => {
          errHandling(error, setDeleteImageErrMsg);
        });
    }
  };

  const handleCloseSnackBar = () => {
    setDeleteImageErrMsg("");
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={Boolean(deleteImageErrMsg)}
        onClose={handleCloseSnackBar}
        message={deleteImageErrMsg}
        autoHideDuration={3000}
      />
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={addImageErr}
        onClose={() => setAddImageErrState(false)}
        message="File is too big!"
        autoHideDuration={3000}
      />
      <Box
        // className={
        //   matches ? classes.smPackageTabContainer : classes.packageTabContainer
        // }
        width={matches ? "100%" : "600px"}
        margin="0 auto"
      >
        <Paper style={{ width: "100%", backgroundColor: "#f5f5f5" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            // centered
            // scrollButtons="auto"
          >
            {tabs.map((tab, index) => (
              <Tab
                label={`Package # ${tab.package_number}`}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
        </Paper>
      </Box>
      {tabs.map((tab, index) => {
        const shipmentTypeID = Object.keys(shipmentTypesDetails).find(
          (key) => shipmentTypesDetails[key].id === tab.shipment_type_id
        );
        const palletsNum =
          shipmentTypesDetails[shipmentTypeID || 1].unit_number * tab.number;
        return (
          <TabPanel
            value={value}
            index={index}
            dir={theme.direction}
            classes={{ root: classes.tabRoot }}
          >
            <div
              role="tabpanel"
              id={tab.id}
              key={tab.id}
              className={cn("tab-content", {
                active: activeTabIndex === index,
              })}
            >
              <div
                className={
                  matches
                    ? classes.smPackageTabContainer
                    : classes.packageTabContainer
                }
              >
                <Box display="flex" flexDirection="column" width="100%">
                  <Box textAlign="left">
                    <p className="bodytext">
                      <b>Package #:</b> {tab.package_number}
                    </p>
                  </Box>
                  <div fontSize="1.8rem">
                    <Box display="flex" justifyContent="start">
                      <Box mr={2}>
                        <b>Item: </b>
                      </Box>
                      <Box fontWeight="300" textAlign="start">
                        {tab.item_name}
                        <br />
                        {palletsNum} pallet(s), total {tab.weight}lbs
                      </Box>
                    </Box>
                  </div>
                  {activeOrder && !guest && (
                    <Box
                      width="100%"
                      mt={1}
                      mb={1}
                      className={classes.buttonAndImagesWrapper}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        width="300px"
                        margin="0 auto"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Box textAlign="center">
                          Max. file size: <b>5MB</b> <br />
                          Max. image number: <b>3</b> <br />
                          Accepted image types: <b>JPG, PNG</b>
                        </Box>
                        <input
                          accept="image/png, image/jpeg"
                          className={classes.input}
                          style={{ display: "none" }}
                          id="raised-button-file"
                          multiple
                          type="file"
                          onChange={onDrop}
                        />
                        <label htmlFor="raised-button-file">
                          <Button
                            variant="contained"
                            component="span"
                            color="primary"
                            className={classes.button}
                            style={{
                              textTransform: "unset",
                              fontSize: "1rem",
                            }}
                          >
                            Upload Image
                          </Button>
                        </label>
                      </Box>
                    </Box>
                  )}
                </Box>
              </div>
              {!guest &&
                (imageLoading ? (
                  <LoadingContainer />
                ) : imageErrMsg ? (
                  <Box pt="1vh" width="100%">
                    <Alert
                      severity="error"
                      classes={{ root: classes.alertRoot }}
                    >
                      {imageErrMsg}
                    </Alert>
                  </Box>
                ) : (
                  <Box
                    width="100%"
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="center"
                  >
                    {images
                      .filter((image) => image.file_location_uri !== "")
                      .map((item, key) => {
                        console.log(item);
                        return (
                          <Box key={item.image_id} mb={2}>
                            <Card className={classes.root}>
                              {!item.file_location_uri && activeOrder && (
                                <CardHeader
                                  classes={{ root: classes.headerMargin }}
                                  action={
                                    <IconButton
                                      onClick={handleRemove(
                                        item.image_id,
                                        tab.package_number
                                      )}
                                    >
                                      <Cancel style={{ color: "red" }} />
                                    </IconButton>
                                  }
                                />
                              )}
                              <CardMedia
                                className={classes.media}
                                image={`${gcsUri}${item.file_location_uri}`}
                              />
                            </Card>
                          </Box>
                        );
                      })}
                  </Box>
                ))}
              <div
                className={
                  matches
                    ? classes.smPackageTabContainer
                    : classes.packageTabContainer
                }
              >
                <Box display="flex" flexDirection="column" width="100%">
                  <hr />
                  <div id="package-tab-content5">
                    <p style={{ fontSize: "1.5rem" }}>
                      Total Shipments: {tab.filteredShipmentList.length}
                    </p>
                  </div>
                  <div id="package-tab-content6">
                    <Box className="bodytext">
                      {tab.filteredShipmentList.map((shipment, key) => {
                        return (
                          <Box
                            className={classes.pkgWrapper}
                            key={shipment.shipment_number}
                            mb="10px"
                          >
                            <Box
                              className={classes.cardContent}
                              display="flex"
                              alignItems="center"
                              role="button"
                              width="100%"
                              onClick={() => {
                                redirectShipmentDetails(
                                  history,
                                  shipment.shipment_number
                                );
                              }}
                            >
                              <Box
                                // color="seagreen"
                                textAlign="left"
                                flexGrow="1"
                              >
                                <span className={classes.bigBodyText}>
                                  Shipment #:{" "}
                                </span>
                                <span className={classes.smBodyText}>
                                  {shipment.shipment_number}
                                </span>
                                <br />
                                <span className={classes.bigBodyText}>
                                  Status:{" "}
                                </span>
                                <span className={classes.smBodyText}>
                                  {shipment.shipping_status}
                                </span>
                              </Box>
                            </Box>
                          </Box>
                          // <Box
                          //   key={key.id}
                          //   className="order-list-text"
                          //   display="flex"
                          //   justifyContent="space-between"
                          //   borderBottom="1px solid rgba(61, 21, 21, 0.3)"
                          //   pt="1.5vh"
                          //   flexDirection={matches ? "column" : "row"}
                          // >
                          //   <p>
                          //     Shipment Number: <br />{" "}
                          //     <span className={classes.smBodyText}>
                          //       {shipment.shipment_number}
                          //     </span>
                          //   </p>
                          //   <p>
                          //     Status: {matches ? "" : <br />}
                          //     <span className={classes.shipmentStatus}>
                          //       {shipment.shipping_status}
                          //     </span>
                          //   </p>
                          // </Box>
                        );
                      })}
                    </Box>
                  </div>
                  {activeOrder && (
                    <Box display="flex" justifyContent="center" pb={2} pt={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={downloadLabels(tab.package_number)}
                        style={{
                          textTransform: "unset",
                          fontSize: "1rem",
                        }}
                      >
                        Download Shipping Labels
                      </Button>
                    </Box>
                  )}
                </Box>
              </div>
            </div>
          </TabPanel>
        );
      })}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 200,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  media: {
    height: 140,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  button: {
    borderRadius: 5,
    fontSize: "14pt",
    backgroundColor: "#ffa726",
  },
  packageTabContainer: {
    backgroundColor: "rgba(255, 255, 255, 0.788)",
    borderRadius: "25px",
    display: "flex",
    justifyContent: "center",
    width: "600px",
    margin: "0 auto",
  },
  smPackageTabContainer: {
    backgroundColor: "rgba(255, 255, 255, 0.788)",
    borderRadius: "25px",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    // paddingLeft: "3vw",
    // paddingRight: "3vw",
    // width={matches ? '90vw' : '60vw'}
  },
  smBodyText: {
    fontSize: "1rem",
    // color: "seagreen",
    color: "rgba(0,0,0,0.8)",
    fontFamily: "Montserrat",
  },
  bigBodyText: {
    fontSize: "1rem",
    color: "rgba(0,0,0,0.8)",
    fontFamily: "Montserrat",
    fontWeight: "600",
  },
  shipmentStatus: {
    backgroundColor: "white",
    borderRadius: "5px",
    padding: "0.5rem",
  },
  headerMargin: {
    paddingTop: "5px !important",
    paddingBottom: "0px !important",
  },
  buttonAndImagesWrapper: {
    border: "2px dashed #d3d3d3",
    borderRadius: "2px",
    background: "#fdf5e8",
    padding: "5px",
  },
  alertRoot: {
    width: "80%",
    minWidth: "200px",
    margin: "10px auto",
  },
  pkgWrapper: {
    backgroundColor: "#fdf5e8",
    padding: "3px",
    cursor: "pointer",
    "&:hover": {
      // border: '1px solid rgba(0,0,0,0.3)',
      backgroundColor: "rgba(0,0,0,0.1)",
      borderRadius: "5px",
    },
  },
  cardContent: {
    backgroundColor: "#fbfbf8",
    border: "1px solid #ded47c",
    borderRadius: "5px",
  },
}));

export default PackageNoTabs;
