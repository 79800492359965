import {
    MOVING_SERVICE_OPEN_ITEM_INFO,
    MOVING_SERVICE_ADD_ITEM_LIST,
    MOVING_SERVICE_EDIT_ITEM_INFO,
    MOVING_SERVICE_UPDATE_ITEM_LIST,
    MOVING_SERVICE_REMOVE_ITEM_LIST,
    MOVING_SERVICE_UPDATE_MOVE_FROM_ADDRESS,
    MOVING_SERVICE_UPDATE_MOVE_TO_ADDRESS,
    MOVING_SERVICE_UPDATE_MOVE_FROM_ADDRESS_MISSING,
    MOVING_SERVICE_UPDATE_MOVE_TO_ADDRESS_MISSING,
    MOVING_SERVICE_SUMMARY_UPDATE_CONTACT_INFO,
    MOVING_SERVICE_SUMMARY_UPDATE_CONTACT_INFO_MISSING,
    MOVING_SERVICE_UPDATE_BASIC_RATE,
    MOVING_SERVICE_UPDATE_MOVING_DATE,
    MOVING_SERVICE_UPDATE_MOVING_TIME,
    MOVING_SERVICE_UPDATE_MOVING_IMAGE,
    MOVING_SERVICE_UPDATE_MOVING_IMAGE_ERROR,
    MOVING_SERVICE_UPDATE_ADDITIONAL_SERVICE,
    MOVING_SERVICE_UPDATE_DELIVER_DATE,
    MOVING_SERVICE_UPDATE_DELIVER_TIME,
    MOVING_SERVICE_EDIT_CAR_INFO,
    MOVING_SERVICE_OPEN_CAR_INFO,
    MOVING_SERVICE_ADD_CAR_LIST,
    MOVING_SERVICE_UPDATE_CAR_LIST,
    MOVING_SERVICE_REMOVE_CAR_LIST,
    MOVING_SERVICE_UPDATE_MOVE_FROM_CAR_ADDRESS,
    MOVING_SERVICE_UPDATE_MOVE_TO_CAR_ADDRESS,
    MOVING_SERVICE_UPDATE_MOVE_FROM_CAR_ADDRESS_MISSING,
    MOVING_SERVICE_UPDATE_MOVE_TO_CAR_ADDRESS_MISSING,
    MOVING_SERVICE_UPDATE_METRIC,
    MOVING_SERVICE_DUPLICATE_ITEM_LIST,
    MOVING_SERVICE_CLEAR_ITEM_LIST,
    MOVING_SERVICE_INIT_ITEM_LIST,
    MOVING_SERVICE_UPDATE_RATE,
    MOVING_SERVICE_ADD_RATE,
    MOVING_SERVICE_CLEAR_RATE,
    MOVING_SERVICE_UPDATE_ACCOUNT_BASE,
    MOVING_SERVICE_UPDATE_EXPRESS_PROPERTY,
    MOVING_SERVICE_ADD_FURNITURE_ITEM_LIST,
    MOVING_SERVICE_UPDATE_FURNITURE_ITEM_LIST,
    MOVING_SERVICE_REMOVE_FURNITURE_ITEM_LIST,
    MOVING_SERVICE_UPDATE_REMARK_NOTES
} from "../actions/types";

const initialState = {
    openItemForm: false,
    itemList: [],
    // [
    //     {
    //         "description": "APPLE",
    //         "category": "Sofa",
    //         "type": "item",
    //         "boxSize": null,
    //         "length": 80,
    //         "width": 70,
    //         "height": 50,
    //         "weight": 150,
    //         "lengthUnit": "in",
    //         "weightUnit": "lb",
    //         "quantity": 1,
    //         "cate_id": 6,
    //         "categoryInfo": {
    //             "cate_id": 6,
    //             "parent_cate_id": 0,
    //             "category_type": "MOVING",
    //             "category_name": "Sofa",
    //             "category_name_cn": "沙发",
    //             "category_name_fr": null,
    //             "category_desc": null,
    //             "standard_length_ins": 80,
    //             "standard_width_ins": 70,
    //             "standard_height_ins": 50,
    //             "standard_weight_lbs": 150,
    //             "created_at": null,
    //             "updated_at": null,
    //             "make": null,
    //             "model": null,
    //             "min_year_of_production": null,
    //             "max_year_of_production": 2023
    //         },
    //         "itemId": "d3ziKHG8"
    //     }
    //     ]
    movingFurnitureList: [],
    openEditForm: {
        status: false,
        itemId: null
    },
    movingFromAddress: {},
    movingToAddress: {},
    movingFromAddressMissing: [],
    movingToAddressMissing: [],
    movingServiceSummaryContactInfo: {},
    movingServiceSummaryContactInfoMissing: [],
    movingServiceBasicRateInfo: null,
    movingDate: null,
    movingTime: null,
    deliverDate: null,
    deliverTime: null,
    movingImage: [],
    movingImageError: null,
    additionalService: {
        scheduleDelivery: false,
        whiteGlove: false,
        packingService: false,
        wrappingService: false,
        assemblyService: false,
        disassemblyService: false,
        storageService: {
            cubicMeterSpace: 0,
            storageDays: 0
        }
    },
    openCarForm: false,
    carList: [],
    openEditCarForm: {
        status: false,
        itemId: null
    },
    movingFromCarAddress: {},
    movingToCarAddress: {},
    movingFromCarAddressMissing: [],
    movingToCarAddressMissing: [],
    movingServiceMetric: false,
    movingServiceRateList: [],
    movingSelectAccountBase: "swyft",
    movingExpress: {
        accountBase: "",
        shippingType: "PIECE",
        maxVolumeInCM: 0,
        maxWeightKG: 0
    },
    movingRemarkNotes: ''
}

export const movingServiceReducer = (state = initialState, action) => {
    switch (action.type) {
        case MOVING_SERVICE_OPEN_ITEM_INFO:
            return {...state, openItemForm: action?.payload}
        case MOVING_SERVICE_EDIT_ITEM_INFO:
            return {...state, openEditForm: action?.payload}
        case MOVING_SERVICE_INIT_ITEM_LIST:
            return {...state, itemList: action?.payload}
        case MOVING_SERVICE_ADD_ITEM_LIST:
            return {...state, itemList: [...state.itemList, action?.payload]}
        case MOVING_SERVICE_UPDATE_ITEM_LIST:
            const updateItem = action?.payload;
            const updateItemList = state?.itemList?.map(each => {
                if (each?.itemId === updateItem?.itemId) {
                    return {...updateItem}
                }
                return each
            })
            return {...state, itemList: updateItemList}
        case MOVING_SERVICE_REMOVE_ITEM_LIST:
            const itemIdToFind = action.payload?.itemId;
            const filteredItems = state.itemList.filter(item => item.itemId !== itemIdToFind);
            return {...state, itemList: filteredItems}
        case MOVING_SERVICE_DUPLICATE_ITEM_LIST:
            return {...state, itemList: [...state.itemList, action?.payload]}
        case MOVING_SERVICE_CLEAR_ITEM_LIST:
            return {...state, itemList: []}

        // For Furniture Items
        case MOVING_SERVICE_ADD_FURNITURE_ITEM_LIST:
            return {...state, movingFurnitureList: [...state.movingFurnitureList, action?.payload]}
        case MOVING_SERVICE_UPDATE_FURNITURE_ITEM_LIST:
            const updateFurnitureItem = action?.payload;
            const updateFurnitureItemList = state?.movingFurnitureList?.map(each => {
                if (each?.cate_id === updateFurnitureItem?.cate_id &&
                    each?.roomPlace === updateFurnitureItem?.roomPlace) {
                    return {...updateFurnitureItem}
                }
                return each
            })
            return {...state, movingFurnitureList: updateFurnitureItemList}
        case MOVING_SERVICE_REMOVE_FURNITURE_ITEM_LIST:
            const furnitureItemIdToFindCateId = action.payload?.cate_id;
            const furnitureItemIdToFindRoomPlace = action?.payload?.roomPlace
            const filteredFurnitureItems = state.movingFurnitureList.filter(item => item.cate_id !== furnitureItemIdToFindCateId || item.roomPlace !== furnitureItemIdToFindRoomPlace);
            return {...state, movingFurnitureList: filteredFurnitureItems}

        case MOVING_SERVICE_UPDATE_MOVE_FROM_ADDRESS:
            return {...state, movingFromAddress: action?.payload}
        case MOVING_SERVICE_UPDATE_MOVE_TO_ADDRESS:
            return {...state, movingToAddress: action?.payload}
        case MOVING_SERVICE_UPDATE_MOVE_FROM_ADDRESS_MISSING:
            return {...state, movingFromAddressMissing: action?.payload}
        case MOVING_SERVICE_UPDATE_MOVE_TO_ADDRESS_MISSING:
            return {...state, movingToAddressMissing: action?.payload}
        case MOVING_SERVICE_SUMMARY_UPDATE_CONTACT_INFO:
            return {...state, movingServiceSummaryContactInfo: action?.payload}
        case MOVING_SERVICE_SUMMARY_UPDATE_CONTACT_INFO_MISSING:
            return {...state, movingServiceSummaryContactInfoMissing: action?.payload}
        case MOVING_SERVICE_UPDATE_BASIC_RATE:
            return {...state, movingServiceBasicRateInfo: action?.payload}
        case MOVING_SERVICE_ADD_RATE:
            const updateRate = [...state.movingServiceRateList, action?.payload];
            return {...state, movingServiceRateList: updateRate}
        case MOVING_SERVICE_UPDATE_RATE:
            return {...state, movingServiceRateList: action?.payload}
        case MOVING_SERVICE_CLEAR_RATE:
            return {...state, movingServiceRateList: []}
        case MOVING_SERVICE_UPDATE_MOVING_DATE:
            return {...state, movingDate: action?.payload}
        case MOVING_SERVICE_UPDATE_MOVING_TIME:
            return {...state, movingTime: action?.payload}
        case MOVING_SERVICE_UPDATE_DELIVER_DATE:
            return {...state, deliverDate: action?.payload}
        case MOVING_SERVICE_UPDATE_DELIVER_TIME:
            return {...state, deliverTime: action?.payload}
        case MOVING_SERVICE_UPDATE_MOVING_IMAGE:
            return {...state, movingImage: action?.payload}
        case MOVING_SERVICE_UPDATE_MOVING_IMAGE_ERROR:
            return {...state, movingImageError: action?.payload}
        case MOVING_SERVICE_UPDATE_ADDITIONAL_SERVICE:
            return {...state, additionalService: action?.payload}
        case MOVING_SERVICE_OPEN_CAR_INFO:
            return {...state, openCarForm: action?.payload}
        case MOVING_SERVICE_EDIT_CAR_INFO:
            return {...state, openEditCarForm: action?.payload}
        case MOVING_SERVICE_ADD_CAR_LIST:
            return {...state, carList: [...state.carList, action?.payload]}
        case MOVING_SERVICE_UPDATE_CAR_LIST:
            const updatedCar = action.payload;
            console.log('updated car', updatedCar);
            const updatedCarList = state.carList.map(car => {
                if (car.carId === updatedCar.carId) {
                    return {...updatedCar};
                }
                return car;
            })
            return {...state, carList: updatedCarList}
        case MOVING_SERVICE_REMOVE_CAR_LIST:
            const carIdToFind = action.payload;
            const filteredCars = state.carList.filter(car => car.carId !== carIdToFind);
            return {...state, carList: filteredCars}
        case MOVING_SERVICE_UPDATE_MOVE_FROM_CAR_ADDRESS:
            return {...state, movingFromCarAddress: action?.payload}
        case MOVING_SERVICE_UPDATE_MOVE_TO_CAR_ADDRESS:
            return {...state, movingToCarAddress: action?.payload}
        case MOVING_SERVICE_UPDATE_MOVE_FROM_CAR_ADDRESS_MISSING:
            return {...state, movingFromCarAddressMissing: action?.payload}
        case MOVING_SERVICE_UPDATE_MOVE_TO_CAR_ADDRESS_MISSING:
            return {...state, movingToCarAddressMissing: action?.payload}
        case MOVING_SERVICE_UPDATE_METRIC:
            return {...state, movingServiceMetric: action?.payload}
        case MOVING_SERVICE_UPDATE_ACCOUNT_BASE:
            return {...state, movingSelectAccountBase: action?.payload}
        case MOVING_SERVICE_UPDATE_EXPRESS_PROPERTY:
            return {...state, movingExpress: action?.payload}
        case MOVING_SERVICE_UPDATE_REMARK_NOTES:
            return {...state, movingRemarkNotes: action?.payload}
        default:
            return state
    }
}