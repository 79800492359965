import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import {Button} from "@mui/material";
import {updateShipAddressError, updateShipFrom} from "../../actions/ratingAction";

const style = {
    ShipSummaryShipFromRoot: {
        width: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        backgroundColor: '#F9F9F9',
        padding: '10px 30px',
        borderRadius: '15px'
    },
    ShipSummaryShipFromAddressSection: {
        width: '100%'
    },
    ShipSummaryShipFromEditButton: {
        position: 'absolute',
        right: '3%',
        top: '3%'
    },
    ShipSummaryShipFromShipErrorText: {
        fontSize: '12px',
        color: "#FF0303"
    }
}

export const ShipSummaryShipFrom = () => {

    const [shippingAddress, setShippingAddress] = useState({});
    const [isDisabled, setIsDisabled] = useState(true);

    let shipError = useSelector(state => state?.rating?.shippingAddressErrors);
    let shipFromError = useSelector(state => state?.rating?.shippingFromErrors);

    const dispatch = useDispatch();

    const handleToggle = () => {
        setIsDisabled(!isDisabled);
    };

    let shipFromAddress = useSelector(state => state?.rating?.shippingFrom);

    const validateShipFromAddress = () => {
        if (!isNaN(shippingAddress.phone) && shippingAddress.phone.length > 9 && shippingAddress.phone.length < 16) {
            shippingAddress?.phone && dispatch(updateShipAddressError({
                errorType: "shipFromPhoneError",
                value: false
            }));
        } else {
            shippingAddress?.phone && dispatch(updateShipAddressError({
                errorType: "shipFromPhoneError",
                value: true
            }));
        }
    }

    useEffect(() => {
        dispatch(updateShipFrom(shippingAddress));
        validateShipFromAddress();
    },[shippingAddress])

    useEffect(() => {
        setShippingAddress(prevState => ({
            ...prevState,
            name: shipFromAddress?.name,
            company: shipFromAddress?.company,
            phone: shipFromAddress?.phone,
            emailAddress: shipFromAddress?.emailAddress,
            address: shipFromAddress?.address,
            address2: shipFromAddress?.address2,
            city: shipFromAddress?.city,
            province: shipFromAddress?.province,
            country: shipFromAddress?.country,
            postalCode: shipFromAddress?.postalCode
        }))
    }, [])

    return (
        <Box sx={style.ShipSummaryShipFromRoot}>
            <Typography variant="h5">
                Ship From
            </Typography>
            <Button
                sx={style.ShipSummaryShipFromEditButton}
                onClick={handleToggle}
            >
                Edit
            </Button>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <label htmlFor="name">Name*: </label>
                </Grid>
                <Grid item xs={4}>
                    <Box>
                        <input
                            disabled={isDisabled}
                            style={style.ShipSummaryShipFromAddressSection}
                            id="name"
                            value={shippingAddress.name}
                            onInput={e => setShippingAddress(prevState => ({
                                ...prevState, name: e.target.value
                            }))}
                        />
                        {shipFromError.includes('name') ?
                            <p style={style.ShipSummaryShipFromShipErrorText}>This is a required field.</p> : ""}
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <label htmlFor="company">Company: </label>
                </Grid>
                <Grid item xs={4}>
                    <Box>
                        <input
                            disabled={isDisabled}
                            style={style.ShipSummaryShipFromAddressSection}
                            id="company"
                            value={shippingAddress.company}
                            onInput={e => setShippingAddress(prevState => ({
                                ...prevState, company: e.target.value
                            }))}
                        />
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <label htmlFor="phone">Phone*:</label>
                </Grid>
                <Grid item xs={4}>
                    <Box>
                        <input
                            disabled={isDisabled}
                            style={style.ShipSummaryShipFromAddressSection}
                            id="phone"
                            type="tel"
                            value={shippingAddress.phone}
                            onInput={e => setShippingAddress(prevState => ({
                                ...prevState, phone: e.target.value
                            }))}
                        />
                        {shipFromError.includes('phone') ?
                            <p style={style.ShipSummaryShipFromShipErrorText}>This is a required field.</p> :
                            shipError.shipFromPhoneError ?
                                <p style={style.ShipSummaryShipFromShipErrorText}>Phone numbers should consist only of digits and be between 10 to 15 digits long.</p> :
                                ""
                        }
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <label htmlFor="email">Email:</label>
                </Grid>
                <Grid item xs={4}>
                    <Box>
                        <input
                            disabled={isDisabled}
                            style={style.ShipSummaryShipFromAddressSection}
                            id="email"
                            type="email"
                            placeholder="recommend"
                            value={shippingAddress.emailAddress}
                            onInput={e => setShippingAddress(prevState => ({
                                ...prevState, emailAddress: e.target.value
                            }))}
                        />
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <label htmlFor="address">Address*:</label>
                </Grid>
                <Grid item xs={10}>
                    <Box>
                        <input
                            disabled={true}
                            style={style.ShipSummaryShipFromAddressSection}
                            // ref={searchInput}
                            type="text"
                            value={shippingAddress.address}
                            onInput={e => setShippingAddress(prevState => ({
                                ...prevState, address: e.target.value
                            }))}
                        />
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <label htmlFor="address2">Address2:</label>
                </Grid>
                <Grid item xs={10}>
                    <Box>
                        <input
                            disabled={true}
                            style={style.ShipSummaryShipFromAddressSection}
                            type="text"
                            value={shippingAddress.address2}
                            onInput={e => setShippingAddress(prevState => ({
                                ...prevState, address2: e.target.value
                            }))}
                        />
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <label htmlFor="city">City*:</label>
                </Grid>
                <Grid item xs={4}>
                    <Box>
                        <input
                            disabled={true}
                            style={style.ShipSummaryShipFromAddressSection}
                            id="city"
                            type="text"
                            value={shippingAddress.city}
                            onInput={e => setShippingAddress(prevState => ({
                                ...prevState, city: e.target.value
                            }))}
                        />
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <label htmlFor="province">Province*:</label>
                </Grid>
                <Grid item xs={4}>
                    <Box>
                        <input
                            disabled={true}
                            style={style.ShipSummaryShipFromAddressSection}
                            id="province"
                            type="text"
                            value={shippingAddress.province}
                            onInput={e => setShippingAddress(prevState => ({
                                ...prevState, province: e.target.value
                            }))}
                        />
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <label htmlFor="province">Country*:</label>
                </Grid>
                <Grid item xs={4}>
                    <Box>
                        <input
                            disabled={true}
                            style={style.ShipSummaryShipFromAddressSection}
                            id="country"
                            type="text"
                            value={shippingAddress.country}
                            onInput={e => setShippingAddress(prevState => ({
                                ...prevState, country: e.target.value
                            }))}
                        />
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <label htmlFor="postalCode">Postal Code*:</label>
                </Grid>
                <Grid item xs={4}>
                    <Box>
                        <input
                            disabled={true}
                            style={style.ShipSummaryShipFromAddressSection}
                            id="postalCode"
                            type="text"
                            value={shippingAddress.postalCode}
                            onInput={e => setShippingAddress(prevState => ({
                                ...prevState, postalCode: e.target.value
                            }))}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}