import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, {useState} from "react";
import {formatCurrency} from "../../Utils/Helper";
import {useParams} from "react-router-dom";
import intl from "react-intl-universal";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";

const styles = {
    MovingOrderDashboardETransferRoot: {
        display: 'flex',
        flexDirection: 'column',
    },

    MovingOrderDashboardETransferText: {
        fontWeight: '600',
        textAlign: 'left'
    },

}

export const MovingOrderDashboardETransfer = ({orderDetails, cbHandleReferenceNumber}) => {
    const {orderId} = useParams();

    const [referenceNumber, setReferenceNumber] = useState();

    const handleReferenceNumber = (event) => {
        setReferenceNumber(event.target.value);
        cbHandleReferenceNumber(event.target.value);
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box sx={styles.MovingOrderDashboardETransferRoot}>
                    <Typography style={styles.MovingOrderDashboardETransferText}>
                        Email Transfer
                    </Typography>
                </Box>
            </Grid>
            {/*<Grid item md={2} xs={3}>*/}
            {/*    <Typography sx={{textAlign: 'left', color: '#1D8B45', fontWeight: '600'}}>*/}
            {/*        Step 1:*/}
            {/*    </Typography>*/}
            {/*</Grid>*/}
            <Grid item md={12} xs={12}>
                <Typography sx={{textAlign: 'left'}}>
                    Transfer the amount to the email address below.
                </Typography>
                <Typography sx={{textAlign: 'left'}}>
                    e-Transfer <span style={{
                    color: '#1D8B45',
                    fontWeight: '600'
                }}>${(+orderDetails?.task_unpaid_amount)?.toFixed(2)}</span> to <span
                    style={{color: '#1D8B45', fontWeight: '600'}}>pay@uucargo.ca</span>
                </Typography>
                <Typography sx={{textAlign: 'left'}}>
                    Message should include the following {orderDetails?.invoiceInfo?.invoiceRefNumber ? 'invoice #' : 'order #'}:
                </Typography>
                <Typography sx={{textAlign: 'left', color: '#1D8B45', fontWeight: '600'}}>
                    {orderDetails?.invoiceInfo?.invoiceRefNumber || orderDetails?.order_number}
                </Typography>
            </Grid>
            {/*<Grid item md={2} xs={3}>*/}
            {/*    <Typography sx={{textAlign: 'left', color: '#1D8B45', fontWeight: '600'}}>*/}
            {/*        Step 2:*/}
            {/*    </Typography>*/}
            {/*</Grid>*/}
            {/*<Grid item md={10} xs={9}>*/}
            {/*    <Typography sx={{textAlign: 'left'}}>*/}
            {/*        After making the e-Transfer, you will receive a confirmation email with a reference number.*/}
            {/*    </Typography>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={12}>*/}
            {/*    <FormControl>*/}
            {/*        <FormLabel>*/}
            {/*            <span style={{fontWeight: '600'}}>Please</span> input your Reference Number*/}
            {/*        </FormLabel>*/}
            {/*        <TextField*/}
            {/*            value={referenceNumber}*/}
            {/*            onInput={handleReferenceNumber}*/}
            {/*            fullWidth*/}
            {/*            variant="outlined"*/}
            {/*            size='small'*/}
            {/*        />*/}
            {/*    </FormControl>*/}
            {/*</Grid>*/}
        </Grid>
    )
}