import { CLEAN_LOCATION, SET_NEW_LOCATION, UPDATE_LOCATION } from '../actions/types'

const initialState = {
  city:'',
  latitude:'',
  longtitude:''
}

export default function locationReducer(state = initialState, action) {
  switch(action.type){
    case SET_NEW_LOCATION:
      return {
        city: action.payload.city,
        latitude: action.payload.latitude,
        longtitude: action.payload.longtitude
      }
    
    case UPDATE_LOCATION:
      return {
        city: action.payload.city,
        latitude: action.payload.latitude,
        longtitude:action.payload.longtitude
      }

    case CLEAN_LOCATION:
      return {
        city:'',
        latitude:'',
        longtitude:''
      }

    default:
      return {
        city:'',
        latitude:'',
        longtitude:''
      }
  }  
}
