import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Button} from "@mui/material";
import {RatingItemEdit} from "../Rating/RatingItemEdit";
import Divider from "@mui/material/Divider";

const style = {
    ShipSummaryItemEachRoot: {
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 10px',
    },
    ShipSummaryItemEachItem: {
        marginBottom: '10px',
    },
    ShipSummaryItemEachItemDetail: {
        display: 'flex',
        flexDirection: 'column'
    },
    ShipSummaryItemEachItemQuantity: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    ShipSummaryItemEachItemButton: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
    }
}

export const ShipSummaryItemEach = ({itemDetail}) => {

    return (
        <Box sx={style.ShipSummaryItemEachRoot}>
            <Grid container spacing={2} sx={style.ShipSummaryItemEachItem}>
                <Grid item xs={9} sx={style.ShipSummaryItemEachItemDetail}>
                    <Typography style={{wordWrap: 'break-word', whiteSpace: 'pre-line', fontSize: '25px', fontWeight: '600'}}>
                        {itemDetail.name}
                    </Typography>
                    <Box>
                        <Typography sx={{fontSize: '15px'}}>
                            Weight: {itemDetail.weight} {itemDetail.weightUnit}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography>
                            Dimension (L x W x
                            H): {itemDetail.length || 0} x {itemDetail.length || 0} x {itemDetail.length || 0} {itemDetail.dimensionUnit}
                        </Typography>
                    </Box>
                    {/*{itemDetail.itemId}*/}
                </Grid>
                <Grid item xs={3} sx={style.ShipSummaryItemEachItemQuantity}>
                    <Typography style={{fontSize: '25px', fontWeight: '600'}}>
                        {itemDetail.quantity}
                    </Typography>
                </Grid>
            </Grid>
            <Divider/>
        </Box>
    )
}