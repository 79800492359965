import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Cookies from "js-cookie";
import {ReactComponent as TopUpIcon} from "../../../images/welcome/Topup.svg";
import {ReactComponent as LimitIcon} from "../../../images/welcome/Limit.svg";
import {useMediaQuery} from "@mui/material";

export const WelcomeAccount = () => {

    const isMobile = useMediaQuery('(max-width: 480px)');

    const handleStarted = () => {
        Cookies.set('welcome', 'yes', {expires: 30});
        window.location.reload();
    }

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
            <Grid container spacing={isMobile ? 1 : 4}>
                {
                    !isMobile &&
                    <Grid item xs={3}>
                        <TopUpIcon width="75" height="75"/>
                    </Grid>
                }
                <Grid item md={9} xs={12}>
                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <Typography style={{
                            fontSize: '16px', textAlign: 'left', color: '#1D8B45', fontWeight: '600'
                        }}>
                            Top-Up Your Account
                        </Typography>
                        <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                            Make sure you have sufficient credits for smooth transactions. Visit our <span
                            style={{color: '#1D8B45', textDecoration: 'underline'}}>Billing Center</span> to add funds
                            to your account via Email transfer, Credit Card, or other methods.
                        </Typography>
                    </Box>
                </Grid>
                {
                    !isMobile &&
                    <Grid item xs={3}>
                        <LimitIcon width="75" height="75"/>
                    </Grid>
                }
                <Grid item md={9} xs={12}>
                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <Typography style={{
                            fontSize: '16px', textAlign: 'left', color: '#1D8B45', fontWeight: '600'
                        }}>
                            Increase Overdraft Limit
                        </Typography>
                        <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                            We've preset a $50 Overdraft Limit in your account for added flexibility.
                        </Typography>
                        <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                            For greater financial flexibility, contact us to discuss raising your Overdraft Limit. Email
                            us at <span style={{color: '#1D8B45', textDecoration: 'underline'}}>info@uucargo.ca</span>.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px'
            }}>
                <Button
                    variant='contained'
                    sx={{
                        backgroundColor: '#1D8B45',
                        "&:hover": {
                            backgroundColor: '#1D8B45',
                            filter: 'brightness(0.9)'
                        },
                        padding: '5px 25px',
                    }}
                    onClick={() => handleStarted()}
                >
                    <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                        Get Started
                    </Typography>
                </Button>
            </Box>
        </Box>

    )
}