import {Container, FormLabel, Snackbar} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@material-ui/core/Grid";
import {useSelector} from "react-redux";
import FormControl from "@mui/material/FormControl";
import intl from "react-intl-universal";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {
    getMissingKeysForProfileCompanyInfo,
    getMissingKeysForProfileUserInfo, isValidatePassword,
    isValidatePhone
} from "../../../Utils/Helper";
import {MyQRCodeDisplay} from "../../MyQRCode/MyQRCodeDisplay";
import {PrintComponent} from "../../MyQRCode/PrintComponent";
import {IconButton, InputAdornment} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import axios from "axios";
import {CHANGE_PASSWORD_API, PARTNER_URI} from "../../../Utils/apiUrl";
import {getAccessToken} from "../../../Utils/doToken";
import CircularProgress from "@mui/material/CircularProgress";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const BusinessAccountSetting = () => {

    const storedToken = getAccessToken("access_token");

    const email = useSelector((state) => state.user.email);

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [userInfoMissing, setUserInfoMissing] = useState([]);
    const [companyInfoMissing, setCompanyInfoMissing] = useState([]);
    const [isMatch, setIsMatch] = useState(true);
    const [validateOldPassword, setValidateOldPassword] = useState(true);
    const [validateNewPassword, setValidateNewPassword] = useState(true);
    const [samePassword, setSamePassword] = useState(false);
    const [oldPasswordWrong, setOldPasswordWrong] = useState(false);

    const [portfolio, setPortfolio] = useState({});

    const [loadingPassword, setLoadingPassword] = useState(false);
    const [loadingUseInfo, setLoadingUserInfo] = useState(false);
    const [loadingCompanyInfo, setLoadingCompanyInfo] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const getUserInfoAndCompany = async () => {
        setLoading(true);
        let requestURL = `${PARTNER_URI}/login_middleware/getUserInfoWithCompany`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`
                },
            })
            const {data} = result;
            console.log('result', data);
            setPortfolio(prevState => ({
                ...prevState,
                email: data?.email,
                firstName: data?.firstname,
                lastName: data?.lastname,
                phone: data?.phone,
                companyEmail: data?.companyEmail,
                companyName: data?.companyName,
                companyPhone: data?.companyPhone,
                companyType: data?.companyType,
                address: data?.address,
                city: data?.city,
                province: data?.province,
                postalCode: data?.postalCode,
                isAdmin: data?.isAdmin
            }))
            setError(false);
        } catch (e) {
            console.log('error', e?.response);
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    const updateUserInfo = async () => {
        setLoadingUserInfo(true);
        let requestURL = `${PARTNER_URI}/login_middleware/editUserInfo`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`
                },
                data: {
                    firstname: portfolio?.firstName,
                    lastname: portfolio?.lastName,
                    phone: portfolio?.phone
                }
            })
            console.log('result', result);
            setErrorMessage('');
            setSuccessMessage('Successfully update user information');
            handleSuccessMessage();
            getUserInfoAndCompany();
        } catch (e) {
            console.log('error', e?.response);
            setErrorMessage('Fail to update user information');
            handleErrorMessage();
        } finally {
            setLoadingUserInfo(false);
        }
    }

    const updateCompanyInfo = async () => {
        setLoadingCompanyInfo(true);
        let requestURL = `${PARTNER_URI}/login_middleware/editCompanyInfo`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`
                },
                data: {
                    companyName: portfolio?.companyName,
                    companyType: portfolio?.companyType,
                    phone: portfolio?.companyPhone,
                    address: portfolio?.address,
                    city: portfolio?.city,
                    province: portfolio?.province,
                    postalCode: portfolio?.postalCode
                }
            })
            console.log('result', result);
            setErrorMessage('');
            setSuccessMessage('Successfully update company information');
            handleSuccessMessage();
            getUserInfoAndCompany();
        } catch (e) {
            console.log('error', e?.response);
            setErrorMessage('Fail to update company information');
            handleErrorMessage();
        } finally {
            setLoadingCompanyInfo(false);
        }
    }

    const changePassword = async () => {
        setLoadingPassword(true);
        const requestedURL = CHANGE_PASSWORD_API;
        try {
            const result = await axios({
                method: 'put',
                url: requestedURL,
                headers: {
                    authorization: `Bearer ${storedToken}`,
                },
                data: {
                    old: oldPassword,
                    new: newPassword,
                    email: email
                }
            })
            console.log('result', result);
            setOldPassword('');
            setNewPassword('');
            setConfirmPassword('');
            setOldPasswordWrong(false);
            setErrorMessage('');
            setSuccessMessage('Successfully Update Password');
            handleSuccessMessage();
            getUserInfoAndCompany();
        } catch (e) {
            console.log('error', e.response);
            setOldPasswordWrong(true);
            setErrorMessage(e?.response?.data?.message);
            handleErrorMessage();
        } finally {
            setLoadingPassword(false);
        }
    }

    const handleChangeFirstName = event => {
        setPortfolio(prevState => ({
            ...prevState,
            firstName: event?.target?.value,
        }))
    }

    const handleChangeLastName = event => {
        setPortfolio(prevState => ({
            ...prevState,
            lastName: event?.target?.value,
        }))
    }

    const handleChangePhone = event => {
        setPortfolio(prevState => ({
            ...prevState,
            phone: event?.target?.value
        }))
    }

    const handleChangeCompanyName = event => {
        setPortfolio(prevState => ({
            ...prevState,
            companyName: event?.target?.value,
        }))
    }

    const handleChangeCompanyPhone = event => {
        setPortfolio(prevState => ({
            ...prevState,
            companyPhone: event?.target?.value,
        }))
    }

    const handleChangeCompanyType = event => {
        setPortfolio(prevState => ({
            ...prevState,
            companyType: event?.target?.value,
        }))
    }

    const handleChangeAddress = event => {
        setPortfolio(prevState => ({
            ...prevState,
            address: event?.target?.value,
        }))
    }

    const handleChangeCity = event => {
        setPortfolio(prevState => ({
            ...prevState,
            city: event?.target?.value,
        }))
    }

    const handleChangeProvince = event => {
        setPortfolio(prevState => ({
            ...prevState,
            province: event?.target?.value,
        }))
    }

    const handleChangePostalCode = event => {
        setPortfolio(prevState => ({
            ...prevState,
            postalCode: event?.target?.value,
        }))
    }

    const validateUserInfo = () => {
        setErrorMessage('');
        setToastOpen(false);

        const hasMissingValues = getMissingKeysForProfileUserInfo({
            firstName: portfolio?.firstName,
            lastName: portfolio?.lastName,
            phone: portfolio?.phone
        })
        const userInfoPhoneValidate = isValidatePhone(portfolio?.phone);

        hasMissingValues?.length !== 0 && setErrorMessage(prevState => prevState + "User information is missing one or more required fields.");
        !userInfoPhoneValidate && setErrorMessage(prevState => prevState + "User phone number is invalid.");

        setUserInfoMissing(hasMissingValues);

        // console.log(hasMissingValues);

        if (hasMissingValues?.length === 0 &&
            userInfoPhoneValidate) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const validateCompanyInfo = () => {
        setErrorMessage('');
        setToastOpen(false);

        const hasMissingValues = getMissingKeysForProfileCompanyInfo({
            companyName: portfolio?.companyName,
            companyType: portfolio?.companyType,
            companyPhone: portfolio?.companyPhone,
            address: portfolio?.address,
            city: portfolio?.city,
            province: portfolio?.province,
            postalCode: portfolio?.postalCode

        })
        const companyInfoPhoneValidate = isValidatePhone(portfolio?.companyPhone);

        hasMissingValues?.length !== 0 && setErrorMessage(prevState => prevState + "Company information is missing one or more required fields.");
        !companyInfoPhoneValidate && setErrorMessage(prevState => prevState + "Company phone number is invalid.");

        setCompanyInfoMissing(hasMissingValues);

        // console.log(hasMissingValues);

        if (hasMissingValues?.length === 0 &&
            companyInfoPhoneValidate) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const validateChangePassword = () => {
        setErrorMessage('');
        setToastOpen(false);

        const isMatch = confirmPassword === newPassword;
        const samePassword = oldPassword === newPassword;
        const validateOldPassword = isValidatePassword(oldPassword);
        const validateNewPassword = isValidatePassword(newPassword);

        !validateOldPassword && setErrorMessage(prevState => prevState + "Old password must be between 6-20 characters.")
        !validateNewPassword && setErrorMessage(prevState => prevState + "New password must be between 6-20 characters.")
        !isMatch && setErrorMessage(prevState => prevState + "Password does not match.");
        samePassword && setErrorMessage(prevState => prevState + "New password must be different.")

        setSamePassword(samePassword);
        setIsMatch(isMatch);
        setValidateNewPassword(validateNewPassword);
        setValidateOldPassword(validateOldPassword);

        if (isMatch && validateNewPassword && validateOldPassword && !samePassword) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const handleChaneUserInfo = () => {
        const validationResult = validateUserInfo();
        if (validationResult) {
            console.log('call api update user');
            updateUserInfo()
        }
    }

    const handleChangeCompanyInfo = () => {
        const validationResult = validateCompanyInfo();
        if (validationResult) {
            console.log('call api update company');
            updateCompanyInfo();
        }
    }

    const handleClickShowOldPassword = () => {
        setShowOldPassword(!showOldPassword);
    };

    const handleClickShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleChangeOldPassword = event => {
        setOldPassword(event?.target?.value);
    }

    const handleChangeNewPassword = event => {
        setNewPassword(event?.target?.value);
    }

    const handleChangeConfirmPassword = event => {
        setConfirmPassword(event?.target?.value);
    }

    const handleChanePassword = () => {
        setOldPasswordWrong(false);
        const validationResult = validateChangePassword();
        if (validationResult) {
            console.log('call api update password');
            changePassword();
        }
    }

    useEffect(() => {
        getUserInfoAndCompany();
    }, []);

    if (loading) {
        return (
            <Container maxWidth='xl'>
                <Box sx={{
                    gap: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'left'}}>
                        Account Settings
                    </Typography>
                    <Box sx={{
                        backgroundColor: '#FFFFFF',
                        padding: '20px',
                        marginBottom: '20px',
                        borderRadius: '10px',
                        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                        border: '1px solid #D1D1D1',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        alignItems: 'center'
                    }}>
                        <CircularProgress/>
                    </Box>
                </Box>
            </Container>
        )
    }

    if (!loading && error) {
        return (
            <Container maxWidth='xl'>
                <Box sx={{
                    gap: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'left'}}>
                        Account Settings
                    </Typography>
                    <Box sx={{
                        backgroundColor: '#FFFFFF',
                        padding: '20px',
                        marginBottom: '20px',
                        borderRadius: '10px',
                        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                        border: '1px solid #D1D1D1',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        alignItems: 'center'
                    }}>
                        <Typography style={{fontSize: '20px', fontWeight: 600}}>
                            Sorry We currently encounter some issues, please try again later.
                        </Typography>
                    </Box>
                </Box>
            </Container>
        )
    }

    console.log('portfolio', portfolio);

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box sx={{
                gap: '15px',
                display: 'flex',
                flexDirection: 'column',
            }}>
                <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'left'}}>
                    Account Settings
                </Typography>
                <Box sx={{
                    backgroundColor: '#FFFFFF',
                    padding: '20px',
                    marginBottom: '20px',
                    borderRadius: '10px',
                    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                    border: '1px solid #D1D1D1',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px'
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography style={{fontSize: '18px', fontWeight: 600, textAlign: 'left'}}>
                                User Information
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel sx={{fontSize: '14px', textAlign: 'left'}} required>
                                    Email Account
                                </FormLabel>
                                <TextField
                                    value={portfolio?.email}
                                    size='small'
                                    inputProps={{style: {fontSize: 14}}}
                                    disabled={true}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel sx={{fontSize: '14px', textAlign: 'left'}} required>
                                    Phone
                                </FormLabel>
                                <TextField
                                    value={portfolio?.phone}
                                    size='small'
                                    inputProps={{style: {fontSize: 14}}}
                                    onInput={handleChangePhone}
                                    error={userInfoMissing?.includes('phone') || !isValidatePhone(portfolio?.phone)}
                                    helperText={
                                        userInfoMissing?.includes('phone') ? "Required" : !isValidatePhone(portfolio?.phone) ? "Invalid" : ""
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel sx={{fontSize: '14px', textAlign: 'left'}} required>
                                    First Name
                                </FormLabel>
                                <TextField
                                    value={portfolio?.firstName}
                                    size='small'
                                    inputProps={{style: {fontSize: 14}}}
                                    onInput={handleChangeFirstName}
                                    error={userInfoMissing?.includes('firstName')}
                                    helperText={
                                        userInfoMissing?.includes('firstName') && "Required"
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel sx={{fontSize: '14px', textAlign: 'left'}} required>
                                    Last Name
                                </FormLabel>
                                <TextField
                                    value={portfolio?.lastName}
                                    size='small'
                                    inputProps={{style: {fontSize: 14}}}
                                    onInput={handleChangeLastName}
                                    error={userInfoMissing?.includes('lastName')}
                                    helperText={
                                        userInfoMissing?.includes('lastName') && "Required"
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}>
                                <LoadingButton
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#1D8B45',
                                        "&:hover": {
                                            backgroundColor: '#1D8B45',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    onClick={handleChaneUserInfo}
                                    loading={loadingUseInfo}
                                >
                                    <Typography style={{
                                        textTransform: 'none',
                                        fontSize: '14px'
                                    }}>
                                        Save Change
                                    </Typography>
                                </LoadingButton>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{fontSize: '18px', fontWeight: 600, textAlign: 'left'}}>
                                Change Password
                            </Typography>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel sx={{fontSize: '14px', textAlign: 'left'}} required>
                                    Old Password
                                </FormLabel>
                                <TextField
                                    value={oldPassword}
                                    size='small'
                                    inputProps={{style: {fontSize: 14}}}
                                    onChange={handleChangeOldPassword}
                                    type={showOldPassword ? "text" : "password"}
                                    InputProps={{
                                        disableUnderline: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowOldPassword}
                                                    // onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showOldPassword ? <Visibility/> : <VisibilityOff/>}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    error={!validateOldPassword || oldPasswordWrong}
                                    helperText={
                                        !validateOldPassword ? "Password must be between 6-20 characters" : oldPasswordWrong && 'Old password is incorrect'
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel sx={{fontSize: '14px', textAlign: 'left'}} required>
                                    New Password
                                </FormLabel>
                                <TextField
                                    value={newPassword}
                                    size='small'
                                    inputProps={{style: {fontSize: 14}}}
                                    onChange={handleChangeNewPassword}
                                    type={showNewPassword ? "text" : "password"}
                                    InputProps={{
                                        disableUnderline: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowNewPassword}
                                                    // onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showNewPassword ? <Visibility/> : <VisibilityOff/>}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    error={!validateNewPassword || samePassword}
                                    helperText={
                                        !validateNewPassword ? "Password must be between 6-20 characters" : samePassword && "New password must must be different"
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel sx={{fontSize: '14px', textAlign: 'left'}} required>
                                    Confirm Password
                                </FormLabel>
                                <TextField
                                    value={confirmPassword}
                                    size='small'
                                    inputProps={{style: {fontSize: 14}}}
                                    onChange={handleChangeConfirmPassword}
                                    type={showConfirmPassword ? "text" : "password"}
                                    InputProps={{
                                        disableUnderline: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowConfirmPassword}
                                                    // onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showConfirmPassword ? <Visibility/> : <VisibilityOff/>}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    error={!isMatch}
                                    helperText={
                                        !isMatch && "Passwords must match"
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}>
                                <LoadingButton
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#1D8B45',
                                        "&:hover": {
                                            backgroundColor: '#1D8B45',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    onClick={handleChanePassword}
                                    loading={loadingPassword}
                                >
                                    <Typography style={{
                                        textTransform: 'none',
                                        fontSize: '14px'
                                    }}>
                                        Save Change
                                    </Typography>
                                </LoadingButton>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{fontSize: '18px', fontWeight: 600, textAlign: 'left'}}>
                                Company Information
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel sx={{fontSize: '14px', textAlign: 'left'}} required>
                                    Company Email
                                </FormLabel>
                                <TextField
                                    value={portfolio?.companyEmail}
                                    size='small'
                                    inputProps={{style: {fontSize: 14}}}
                                    disabled={true}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel sx={{fontSize: '14px', textAlign: 'left'}} required>
                                    Company Name
                                </FormLabel>
                                <TextField
                                    value={portfolio?.companyName}
                                    size='small'
                                    inputProps={{style: {fontSize: 14}}}
                                    disabled={!portfolio?.isAdmin}
                                    onInput={handleChangeCompanyName}
                                    error={companyInfoMissing?.includes('companyName')}
                                    helperText={
                                        companyInfoMissing?.includes('companyName') && "Required"
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel sx={{fontSize: '14px', textAlign: 'left'}} required>
                                    Company Phone
                                </FormLabel>
                                <TextField
                                    value={portfolio?.companyPhone}
                                    size='small'
                                    inputProps={{style: {fontSize: 14}}}
                                    disabled={!portfolio?.isAdmin}
                                    onInput={handleChangeCompanyPhone}
                                    error={companyInfoMissing?.includes('companyPhone') || !isValidatePhone(portfolio?.companyPhone)}
                                    helperText={
                                        companyInfoMissing?.includes('companyPhone') ? "Required" : !isValidatePhone(portfolio?.companyPhone) ? "Invalid" : ""
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel sx={{fontSize: '14px', textAlign: 'left'}} required>
                                    Company Type
                                </FormLabel>
                                <TextField
                                    value={portfolio?.companyType}
                                    size='small'
                                    inputProps={{style: {fontSize: 14}}}
                                    disabled={!portfolio?.isAdmin}
                                    onInput={handleChangeCompanyType}
                                    error={companyInfoMissing?.includes('companyType')}
                                    helperText={
                                        companyInfoMissing?.includes('companyType') && "Required"
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel sx={{fontSize: '14px', textAlign: 'left'}} required>
                                    Address
                                </FormLabel>
                                <TextField
                                    value={portfolio?.address}
                                    size='small'
                                    inputProps={{style: {fontSize: 14}}}
                                    disabled={!portfolio?.isAdmin}
                                    onInput={handleChangeAddress}
                                    error={companyInfoMissing?.includes('address')}
                                    helperText={
                                        companyInfoMissing?.includes('address') && "Required"
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel sx={{fontSize: '14px', textAlign: 'left'}} required>
                                    City
                                </FormLabel>
                                <TextField
                                    value={portfolio?.city}
                                    size='small'
                                    inputProps={{style: {fontSize: 14}}}
                                    disabled={!portfolio?.isAdmin}
                                    onInput={handleChangeCity}
                                    error={companyInfoMissing?.includes('city')}
                                    helperText={
                                        companyInfoMissing?.includes('city') && "Required"
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel sx={{fontSize: '14px', textAlign: 'left'}} required>
                                    Province
                                </FormLabel>
                                <TextField
                                    value={portfolio?.province}
                                    size='small'
                                    inputProps={{style: {fontSize: 14}}}
                                    disabled={!portfolio?.isAdmin}
                                    onInput={handleChangeProvince}
                                    error={companyInfoMissing?.includes('province')}
                                    helperText={
                                        companyInfoMissing?.includes('province') && "Required"
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel sx={{fontSize: '14px', textAlign: 'left'}} required>
                                    Postal Code
                                </FormLabel>
                                <TextField
                                    value={portfolio?.postalCode}
                                    size='small'
                                    inputProps={{style: {fontSize: 14}}}
                                    disabled={!portfolio?.isAdmin}
                                    onInput={handleChangePostalCode}
                                    error={companyInfoMissing?.includes('postalCode')}
                                    helperText={
                                        companyInfoMissing?.includes('postalCode') && "Required"
                                    }
                                />
                            </FormControl>
                        </Grid>
                        {
                            portfolio?.isAdmin &&
                            <Grid item xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                }}>
                                    <LoadingButton
                                        variant='contained'
                                        sx={{
                                            backgroundColor: '#1D8B45',
                                            "&:hover": {
                                                backgroundColor: '#1D8B45',
                                                filter: 'brightness(0.9)'
                                            }
                                        }}
                                        onClick={handleChangeCompanyInfo}
                                        loading={loadingCompanyInfo}
                                    >
                                        <Typography style={{
                                            textTransform: 'none',
                                            fontSize: '14px'
                                        }}>
                                            Save Change
                                        </Typography>
                                    </LoadingButton>
                                </Box>
                            </Grid>
                        }
                    </Grid>
                </Box>
                <Box sx={{
                    backgroundColor: '#FFFFFF',
                    padding: '20px',
                    marginBottom: '20px',
                    borderRadius: '10px',
                    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                    border: '1px solid #D1D1D1',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px'
                }}>
                    <Grid item xs={12}>
                        <Typography style={{fontSize: '18px', fontWeight: 600, textAlign: 'left'}}>
                            QR Code
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            margin: 'auto',
                            justifyContent: 'center',
                            width: '50%'
                        }}>
                            <MyQRCodeDisplay/>
                            <PrintComponent/>
                        </Box>
                    </Grid>
                </Box>
            </Box>
        </Container>
    )
}