import React, {useState} from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {orange, red, green} from "@mui/material/colors";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";

import axios from "axios";
import {NODE_ROUTE_URI} from "../../../Utils/apiUrl";
import {getTokenFromCookie} from "../../../Utils/doToken";

const theme = createTheme({
    palette: {
        primary: orange,
        danger: red,
        success: green,
        warning: red,
        secondary: green,
    },
});

const AddMyBoxesItem = ({
                            setShowAddBoxItem,
                            getMySavedBoxes,
                            handleClose,
                        }) => {
    const [canEdit, setCanEdit] = useState(false);
    const [statusMessage, setStatusMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [requiredErrors, setRequiredErrors] = useState({});
    const [isSuccess, setIsSuccess] = useState(false);

    const [newBoxName, setNewBoxName] = useState("");
    const [newItemName, setNewItemName] = useState("");
    const [newWeightUnit, setNewWeightUnit] = useState("");
    const [newDimensionUnit, setNewDimensionUnit] = useState("");
    const [newLength, setNewLength] = useState("");
    const [newWidth, setNewWidth] = useState("");
    const [newHeight, setNewHeight] = useState("");
    const [newWeight, setNewWeight] = useState("");

    const clearStatusMessages = () => {
        setStatusMessage("");
        setErrorMessage("");
    };

    const saveBox = async () => {
        clearStatusMessages();

        console.log({
            newBoxName,
            newItemName,
            newWeightUnit,
            newDimensionUnit,
            newLength,
            newWidth,
            newHeight,
            newWeight,
        });

        const formErrors = {
            newBoxName: newBoxName.trim() === "",
            newWeightUnit: newWeightUnit.trim() === "",
            newDimensionUnit: newDimensionUnit.trim() === "",
            newLength:
                isNaN(newLength) ||
                (typeof newLength === "string" ? newLength.trim() === "" : false),
            newWidth:
                isNaN(newWidth) ||
                (typeof newWidth === "string" ? newWidth.trim() === "" : false),
            newHeight:
                isNaN(newHeight) ||
                (typeof newHeight === "string" ? newHeight.trim() === "" : false),
            newWeight:
                isNaN(newWeight) ||
                (typeof newWeight === "string" ? newWeight.trim() === "" : false),
        };
        setRequiredErrors(formErrors);
        console.log(formErrors);

        if (
            newBoxName.trim() === "" ||
            newWeightUnit.trim() === "" ||
            newDimensionUnit.trim() === "" ||
            isNaN(newLength) ||
            (typeof newLength === "string" ? newLength.trim() === "" : false) ||
            isNaN(newWidth) ||
            (typeof newWidth === "string" ? newWidth.trim() === "" : false) ||
            isNaN(newHeight) ||
            (typeof newHeight === "string" ? newHeight.trim() === "" : false) ||
            isNaN(newWeight) ||
            (typeof newWeight === "string" ? newWeight.trim() === "" : false)
        ) {
            // Some fields empty
            setErrorMessage("Missing Required Fields");
            return;
        }

        setIsLoading(true);
        console.log("Simulate Save Box");
        const body = {
            boxName: newBoxName,
            itemName: newItemName,
            weightUnit: newWeightUnit,
            dimensionUnit: newDimensionUnit,
            length: newLength,
            width: newWidth,
            height: newHeight,
            weight: newWeight,
        };
        try {
            const {data, status} = await axios({
                method: "post",
                url: `${NODE_ROUTE_URI}/myboxes/createMyBoxForEndUser`,
                data: body,
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
                },
            });
            if (status === 200) {
                setStatusMessage("Successfully saved box");
                setCanEdit(false);
                setIsSuccess(true);
                getMySavedBoxes(); // API Call for updated list
            } else {
                console.log("Error:", data);
                throw new Error();
            }
        } catch (e) {
            console.log(e);
            setErrorMessage("Failed to Save Box");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            {!isSuccess ? (
                <>
                    <ListItem>
                        <Box width="100%">
                            <Typography
                                sx={{
                                    color: "green",
                                }}
                            >
                                {statusMessage}
                            </Typography>
                            <Typography
                                sx={{
                                    color: "red",
                                }}
                            >
                                {errorMessage}
                            </Typography>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Box>
                                        <TextField
                                            label="My Box Name"
                                            variant="filled"
                                            defaultValue={newBoxName}
                                            onChange={(e) => setNewBoxName(e.target.value)}
                                            fullWidth
                                            sx={{mb: 2}}
                                            error={requiredErrors.newBoxName}
                                            helperText={
                                                requiredErrors.newBoxName ? "Required" : undefined
                                            }
                                        />
                                        <TextField
                                            label="Item Name (Optional)"
                                            variant="filled"
                                            defaultValue={newItemName}
                                            onChange={(e) => setNewItemName(e.target.value)}
                                            fullWidth
                                            sx={{mb: 2}}
                                        />
                                        <Grid container width="100%">
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="Length"
                                                    variant="filled"
                                                    defaultValue={newLength}
                                                    onChange={(e) => setNewLength(e.target.value)}
                                                    sx={{width: "100%"}}
                                                    error={isNaN(newLength) || requiredErrors.newLength}
                                                    helperText={
                                                        isNaN(newLength) ? "Requires Number" : (requiredErrors.newLength ? "Required" : undefined)
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="Width"
                                                    variant="filled"
                                                    defaultValue={newWidth}
                                                    onChange={(e) => setNewWidth(e.target.value)}
                                                    // fullWidth
                                                    sx={{
                                                        width: "100%",
                                                    }}
                                                    error={isNaN(newWidth) || requiredErrors.newWidth}
                                                    helperText={
                                                        isNaN(newWidth) ? "Requires Number" : (requiredErrors.newWidth ? "Required" : undefined)
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="Height"
                                                    variant="filled"
                                                    defaultValue={newHeight}
                                                    onChange={(e) => setNewHeight(e.target.value)}
                                                    // fullWidth
                                                    sx={{width: "100%"}}
                                                    error={isNaN(newHeight) || requiredErrors.newHeight}
                                                    helperText={
                                                        isNaN(newHeight) ? "Requires Number" : (requiredErrors.newHeight ? "Required" : undefined)
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={6} pl={8}>
                                                <FormControl
                                                    height="100%"
                                                    error={requiredErrors.newDimensionUnit}
                                                >
                                                    <FormLabel>Dimension Unit</FormLabel>
                                                    <RadioGroup
                                                        row
                                                        defaultValue={""}
                                                        onChange={(e) =>
                                                            setNewDimensionUnit(e.target.value)
                                                        }
                                                    >
                                                        <FormControlLabel
                                                            value="cm"
                                                            control={<Radio/>}
                                                            label="cm"
                                                        />
                                                        <FormControlLabel
                                                            value="in"
                                                            control={<Radio/>}
                                                            label="in"
                                                        />
                                                        <FormHelperText>
                                                            {requiredErrors.newDimensionUnit
                                                                ? "Required"
                                                                : ""}
                                                        </FormHelperText>
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="Weight"
                                                    variant="filled"
                                                    defaultValue={newWeight}
                                                    onChange={(e) => setNewWeight(e.target.value)}
                                                    // fullWidth
                                                    sx={{
                                                        width: "100%",
                                                    }}
                                                    error={isNaN(newWeight) || requiredErrors.newWeight}
                                                    helperText={
                                                        isNaN(newWeight) ? "Requires Number" : (requiredErrors.newWeight ? "Required" : undefined)
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={6} m="auto" pl={8}>
                                                <FormControl
                                                    height="100%"
                                                    error={requiredErrors.newWeightUnit}
                                                >
                                                    <FormLabel>Weight Unit</FormLabel>
                                                    <RadioGroup
                                                        row
                                                        defaultValue={""}
                                                        onChange={(e) => setNewWeightUnit(e.target.value)}
                                                    >
                                                        <FormControlLabel
                                                            value="kg"
                                                            control={<Radio/>}
                                                            label="kg"
                                                        />
                                                        <FormControlLabel
                                                            value="lb"
                                                            control={<Radio/>}
                                                            label="lb"
                                                        />
                                                        <FormHelperText>
                                                            {requiredErrors.newWeightUnit ? "Required" : ""}
                                                        </FormHelperText>
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </ListItem>
                    <Grid container>
                        <Grid item xs={6}>
                            <ListItemButton
                                width="50%"
                                onClick={() => setShowAddBoxItem(false)}
                                sx={{
                                    "&:hover .cancelText": {
                                        display: "block",
                                    },
                                }}
                            >
                                <Box width="100%" display={"flex"} justifyContent="center">
                                    <CloseIcon sx={{height: "25px", color: "warning.main"}}/>
                                    <Typography
                                        className="cancelText"
                                        ml={2}
                                        sx={{display: "none"}}
                                    >
                                        Close
                                    </Typography>
                                </Box>
                            </ListItemButton>
                        </Grid>
                        <Grid item xs={6}>
                            <ListItemButton
                                width="50%"
                                onClick={saveBox}
                                sx={{
                                    // "&:hover": {
                                    //   backgroundColor: "blue"
                                    // },
                                    "&:hover .saveText": {
                                        display: "block",
                                    },
                                    // "&:hover .saveBox": {
                                    //   backgroundColor: "brown",
                                    // },
                                }}
                            >
                                <Box
                                    width="100%"
                                    display={"flex"}
                                    justifyContent="center"
                                    className="saveBox"
                                >
                                    {isLoading ? (
                                        <CircularProgress/>
                                    ) : (
                                        <DoneIcon sx={{height: "25px", color: "success.main"}}/>
                                    )}
                                    <Typography
                                        className="saveText"
                                        ml={2}
                                        sx={{display: "none"}}
                                    >
                                        Save
                                    </Typography>
                                </Box>
                            </ListItemButton>
                        </Grid>
                    </Grid>
                </>
            ) : (
                <>
                    <Typography>New Box Sucessfully Created!</Typography>
                    <Typography
                        onClick={handleClose}
                        sx={{textDecoration: "underline", cursor: "pointer"}}
                    >
                        Click here to check it out
                    </Typography>
                </>
            )}
        </ThemeProvider>
    );
};

export default AddMyBoxesItem;
