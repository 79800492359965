import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useEffect, useState} from "react";
import {Button} from "@mui/material";
import {useHistory} from "react-router-dom";
import dayjs from "dayjs";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import {getAccessToken} from "../../Utils/doToken";
import {SchedulePickupList} from "./SchedulePickupList";
import intl from "react-intl-universal";

const styles = {
    ScheduledPickupRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '30vh',
        height: '100%'
    },
    ScheduledPickupContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        height: '100%'
    },
}

export const SchedulePickupDashboard = () => {

    const history = useHistory();
    const accessToken = getAccessToken("access_token");

    const [myPickups, setMyPickups] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const [accountBase, setAccountBase] = useState("");
    const [appointDateStart, setAppointDateStart] = useState();
    const [appointDateEnd, setAppointDateEnd] = useState();

    const handleStartDate = (date) => {
        // console.log('data', date);
        if (date) {
            setAppointDateStart(date);
        } else {
            const currentDate = dayjs();
            const defaultStartDate = currentDate.subtract(30, 'day');
            setAppointDateStart(defaultStartDate);
        }
    };

    const handleEndDate = (date) => {
        // console.log('data', date);
        if (date) {
            setAppointDateEnd(date);
        } else {
            const currentDate = dayjs();
            const defaultEndDate = currentDate.add(30, 'day');
            setAppointDateEnd(defaultEndDate);
        }
    };

    const getScheduledPickups = async () => {

        setLoading(true);
        let requestURL = `${NODE_ROUTE_URI}/packagesPickupAppointment/getMyPickupAppointmentList`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
                params: {
                    appointDateStart: appointDateStart?.format('YYYY-MM-DD'),
                    appointDateEnd: appointDateEnd?.format('YYYY-MM-DD')
                }
            })
            // console.log('home page schedule pickup', data);
            setMyPickups(result?.data);
            setError(false);
            setLoading(false);
        } catch (e) {
            console.log(e);
            setError(true);
            setLoading(false);
        }
    };

    useEffect(() => {
        const currentDate = dayjs();

        const defaultStartDate = currentDate.subtract(30, 'day');
        const defaultEndDate = currentDate.add(30, 'day');

        // console.log(defaultDate);
        setAppointDateStart(defaultStartDate);
        setAppointDateEnd(defaultEndDate);
    }, []);

    useEffect(() => {
        getScheduledPickups();
    }, [appointDateStart, appointDateEnd])

    return (
        <Box sx={styles.ScheduledPickupRoot}>
            <Typography style={{fontSize: '16px', fontWeight: 600, textAlign: 'left'}}>
                {intl.get('HOME_PAGE.SCHEDULED_PICKUPS.SCHEDULED_PICKUPS')}
            </Typography>
            <Box sx={styles.ScheduledPickupContent}>
                {
                    loading ?
                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                            <CircularProgress/>
                        </Box>
                        :
                        error ?
                            <Box sx={styles.ShippingOrderContent}>
                                <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                    {intl.get('HOME_PAGE.ERROR')}
                                </Typography>
                            </Box>
                            :
                            myPickups?.length > 0 ?
                                <SchedulePickupList myPickups={myPickups}/>
                                :
                                <Box sx={styles.ShippingOrderContent}>
                                    <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                        {intl.get('HOME_PAGE.SCHEDULED_PICKUPS.NO_RESULTS')}
                                    </Typography>
                                </Box>
                }
                <Button
                    sx={{width: '100%'}}
                    onClick={() => history.push('/MyScheduledPickups')}
                >
                    <Typography style={{
                        fontSize: '14px',
                        color: '#1D8B45',
                        fontWeight: '600',
                        textTransform: 'none',
                        width: '100%'
                    }}>
                        {intl.get('HOME_PAGE.SCHEDULED_PICKUPS.VIEW')}
                    </Typography>
                </Button>
            </Box>
        </Box>
    )
}