import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
// import store from '../../store/index'
import axios from "axios";
import { useParams } from "react-router-dom";

import { GET_SETTLEMENT_DETAIL } from "../../Utils/apiUrl";
import { getHeaders } from "../../Utils/fetchUtil";
import DisputeModule from "./components/disputeModule";
import LoadingContainer from "../shared/loadingContainer";

const settlement = {
  settlement_number: "12334",
  amount: "100",
  method: "Cheque",
  period: "Aug 01, 2021 to Agu 31, 2021",
};

const SettlementDetails = () => {
  //   const [page, setPage] = useState(1);
  //   const [lastPage, setLastPage] = useState(0);

  const classes = useStyles();
  const location = useLocation();

  const [detailData, setDetailData] = useState(null);
  const [loading, setLoading] = useState(true);

  const { id: settlement_num } = useParams();

  useEffect(() => {
    axios
      .get(GET_SETTLEMENT_DETAIL("sallie.kunze@example.org", settlement_num), {
        headers: getHeaders(),
      })
      .then((response) => {
        console.log(response.data);
        if (response.data) {
          setDetailData(response.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        // errHandling(err, setPriceErrMsg);
        // setPriceErrMsg(err.response.data.message);
        // setFetchingPriceState(false);
        console.log(err.message);
        setLoading(false);
      });
  }, [settlement_num]);

  console.log(detailData, "haha");

  // Render list view for the unsettlements
  const listView = (_data, type) => {
    const inType = type === "in";
    const types = inType
      ? _data.transactions?.direction_in.types
      : _data.transactions?.direction_out.types;
    if (types) {
      const length = types.length;
      return types.map((item, index) => {
        return item.subtotal_final_amount === "0.00" ? (
          ""
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box maxWidth="500px" width="100%">
              <Box className={classes.title}>{item.type}</Box>
              <DisputeModule settlementNum={settlement_num} type={item.type} />
              <Box className={classes.itemWrapper}>
                {item.data.map((shipment) => {
                  return (
                    <Box display="flex" justifyContent="space-between">
                      <Box>{shipment.shipment_number}</Box>
                      <Box>C$ {shipment.final_amount}</Box>
                    </Box>
                  );
                })}
                <hr />
                <Box display="flex" justifyContent="space-between">
                  <Box>Subtotal</Box>
                  <Box>C$ {item.subtotal_final_amount}</Box>
                </Box>
              </Box>
              {(length !== index + 1 || inType) && (
                <Box mt={4} mb={4}>
                  <hr />
                </Box>
              )}
            </Box>
          </Box>
        );
      });
    }
  };

  return (
    <Box ml={1} mr={1}>
      <Box display="flex" justifyContent="center" mb={2}>
        <Box
          key={settlement.settlement_number}
          className={classes.paper}
          width="100%"
        >
          <Box className={classes.container}>
            <Box fontWeight="600" fontSize="1.2rem">
              Settlement # {settlement_num}:
            </Box>
            <Box>
              -{" "}
              <Box component="span" fontWeight="600" color="rgba(0,0,0,0.6)">
                Amount:&nbsp;&nbsp;
              </Box>
              C$ {location.state?.finalAmount || ""}
            </Box>
            <Box>
              -{" "}
              <Box component="span" fontWeight="600" color="rgba(0,0,0,0.6)">
                Method:&nbsp;&nbsp;
              </Box>
              {location.state?.method || ""}
            </Box>
            <Box mb={2}>
              -{" "}
              <Box component="span" fontWeight="600" color="rgba(0,0,0,0.6)">
                Period:&nbsp;&nbsp;
              </Box>{" "}
              {location.state?.start || ""} to {location.state?.end || ""}
            </Box>
          </Box>
        </Box>
      </Box>

      {/* {data.transactions.direction_in.types.map((item) => {
        return item.subtotal_final_amount === "0.00" ? (
          ""
        ) : (
          <Box
            mt={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box maxWidth="500px" width="100%">
              <Box className={classes.title}>{item.type}</Box>
              <DisputeModule />
              <Box className={classes.itemWrapper}>
                {item.data.map((shipment) => {
                  return (
                    <Box display="flex" justifyContent="space-between">
                      <Box>{shipment.shipment_number}</Box>
                      <Box>C$ {shipment.final_amount}</Box>
                    </Box>
                  );
                })}
                <hr />
                <Box display="flex" justifyContent="space-between">
                  <Box>Subtotal</Box>
                  <Box>C$ {item.subtotal_final_amount}</Box>
                </Box>
              </Box>
            </Box>
          </Box>
        );
      })} */}
      {loading ? (
        <LoadingContainer />
      ) : (
        <>
          {detailData && listView(detailData, "in")}
          {detailData && listView(detailData, "out")}
        </>
      )}

      {/* {data.transactions.direction_out.types.map((item) => {
        return item.subtotal_final_amount === "0.00" ? (
          ""
        ) : (
          <Box
            mt={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box maxWidth="500px" width="100%">
              <Box className={classes.title}>{item.type}</Box>
              <DisputeModule />
              <Box className={classes.itemWrapper}>
                {item.data.map((shipment) => {
                  return (
                    <Box display="flex" justifyContent="space-between">
                      <Box>{shipment.shipment_number}</Box>
                      <Box>C$ {shipment.final_amount}</Box>
                    </Box>
                  );
                })}
                <hr />
                <Box display="flex" justifyContent="space-between">
                  <Box>Subtotal</Box>
                  <Box>C$ {item.subtotal_final_amount}</Box>
                </Box>
              </Box>
            </Box>
          </Box>
        );
      })} */}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: "360px",
    padding: theme.spacing(0.5),
    backgroundColor: "#fdf5e8",
  },
  container: {
    // textAlign: "center",
    backgroundColor: "#fbfbf8",
    border: "1px solid #ded47c",
    borderRadius: "5px",
    margin: "0px auto",
  },
  title: {
    fontSize: "1.3rem",
    fontWeight: "600",
    color: "seagreen",
    fontFamily: "Montserrat",
  },
  itemWrapper: {
    backgroundColor: "#fbfbf8",
    padding: theme.spacing(0.5),
    borderRadius: "5px",
  },
}));

// SettlementDetails.propTypes = {
//   guest: PropTypes.bool,
// };

// export default connect(mapStateToProps, {getOrders, getPackages, getOrderShipments})(OrderList)
export default SettlementDetails;
