import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {MenuItem, Select} from "@mui/material";
import Button from "@mui/material/Button";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {getCountryCode} from "../../../Utils/getCountryCode";
import {UOMList} from "../../shared/constInfo";
import axios from "axios";
import {HSCODE_SEARCH_PARTNER} from "../../../Utils/apiUrl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {Modal} from "@mui/joy";

const style = {
    // position: 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export const BrokerageItem = ({itemInfo, removeItem, count, updateItem, updateDataFromItem}) => {

    const [currency, setCurrency] = useState(itemInfo?.currency);

    const [lengthUnit, setLengthUnit] = useState(itemInfo?.lengthUnit);

    const [weightUnit, setWeightUnit] = useState(itemInfo?.weightUnit);

    const [originCountry, setOriginCountry] = useState(itemInfo?.originCountryCode);

    const [description, setDescription] = useState(itemInfo?.description);

    const [quantity, setQuantity] = useState(itemInfo?.quantity);

    const [priceEach, setPriceEach] = useState(itemInfo?.priceEach);

    const [length, setLength] = useState(itemInfo?.length);

    const [width, setWidth] = useState(itemInfo?.width);

    const [height, setHeight] = useState(itemInfo?.height);

    const [weight, setWeight] = useState(itemInfo?.weight);

    const [UOM, setUOM] = useState(itemInfo?.UOM);

    const [itemID, setItemId] = useState(itemInfo?.itemID)

    const {register, handleSubmit, errors} = useForm();

    const [HSCodeList, setHSCodeList] = useState([]);

    const [HScode, setHScode] = useState(itemInfo?.hscode);

    const [edit, setEdit] = useState(true);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        setDescription(itemInfo?.description);
        setQuantity(itemInfo?.quantity);
        setPriceEach(itemInfo?.priceEach);
        setCurrency(itemInfo?.currency);
        setLength(itemInfo?.length);
        setWidth(itemInfo?.width);
        setLengthUnit(itemInfo?.lengthUnit);
        setWeight(itemInfo?.weight);
        setWeightUnit(itemInfo?.weightUnit);
        setHeight(itemInfo?.height);
        setOriginCountry(itemInfo?.originCountryCode);
        setItemId(itemInfo.itemID);
        setHScode(itemInfo.hscode)
    }, [itemInfo])

    console.log('brokerage item', itemInfo)

    const onSubmit = data => {

        console.log('item id', itemInfo?.itemID)

        console.log('submit data', data)

        updateItem()

        let newData = {
            ...data,
            currency: currency,
            lengthUnit: lengthUnit,
            weightUnit: weightUnit,
            originCountryCode: originCountry,
            UOM: UOM,
            itemID: itemID,
            hscode: HScode

        }

        updateDataFromItem(newData)

        handleEditClose()

        // console.log(newData)
        // getDataFromItem(newData)
    }

    const handleEditOpen = () => {
        setEdit(false);
        // handleOpen()
    }

    const handleEditClose = () => {
        setEdit(true);
    }

    const handleCancel = () => {
        setDescription(itemInfo?.description);
        setQuantity(itemInfo?.quantity);
        setPriceEach(itemInfo?.priceEach);
        setCurrency(itemInfo?.currency);
        setLength(itemInfo?.length);
        setWidth(itemInfo?.width);
        setLengthUnit(itemInfo?.lengthUnit);
        setWeight(itemInfo?.weight);
        setWeightUnit(itemInfo?.weightUnit);
        setHeight(itemInfo?.height);
        // set(itemInfo?.priceEach);
        setOriginCountry(itemInfo?.originCountryCode);
        setUOM(itemInfo?.UOM);
        handleEditClose()
    }

    const searchHSCode = async (value) => {
        try {
            const {data} = await axios.get(`${HSCODE_SEARCH_PARTNER}?query_string=${value}`);
            // console.log("searchLevelSixSection", data);

            const hits = data.hits;
            const levelSixRecords = hits.filter(hit => {
                return hit["_source"].level === "6";
            })
            const filterResult = levelSixRecords?.map(record => {
                return record["_source"]
            })
            setHSCodeList(filterResult)
        } catch (e) {
            console.log(e);
        }
    }

    return (<>{
            edit ? (
                    <Box sx={{display: 'flex', justifyContent: 'flex-start', flexDirection: 'row'}}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: 1,
                            backgroundColor: '#ffffff',
                            borderRadius: '5px',
                            margin: '5px'
                        }}>
                            <Typography
                                sx={{margin: '10px'}}>item {count + 1} | {itemInfo?.description} X {itemInfo?.quantity} X
                                ${itemInfo?.priceEach}</Typography>
                            <Box>
                                <Button color="success" onClick={handleEditOpen}>Edit</Button>
                                <Button color="error" onClick={() => {removeItem(itemInfo?.itemID)}}>Delete</Button>
                            </Box>
                        </Box>
                    </Box>
                )
                :
                (
                    // <Modal
                    //     open={open}
                    //     onClose={handleClose}
                    //     aria-labelledby="modal-modal-title"
                    //     aria-describedby="modal-modal-description"
                    // >
                    <Box sx={style}>
                        <Typography variant="h8" sx={{textAlign: "left"}}>
                            Item {count + 1}
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'none',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}>
                            <TextField
                                sx={{margin: '10px'}}
                                label="Description"
                                value={description}
                                onInput={e => setDescription(e.target.value)}
                                required
                                variant="standard"
                                fullWidth
                                {...register("description", {required: true})}
                            />
                            <TextField
                                sx={{margin: '10px'}}
                                label="Quantity"
                                value={quantity}
                                onInput={e => setQuantity(e.target.value)}
                                required
                                variant="standard"
                                fullWidth
                                {...register("quantity", {required: true})}
                            />
                            <TextField
                                sx={{margin: '10px'}}
                                label="Price"
                                value={priceEach}
                                onInput={e => setPriceEach(e.target.value)}
                                required
                                variant="standard"
                                fullWidth
                                {...register("priceEach", {required: true})}
                            />
                            <FormControl variant="standard" sx={{margin: '10px'}} fullWidth>
                                <InputLabel>Currency*</InputLabel>
                                <Select
                                    value={currency}
                                    label="Currency"
                                    onChange={e => setCurrency(e.target.value)}
                                >
                                    <MenuItem value={'CAD'}>CAD</MenuItem>
                                    <MenuItem value={'USD'}>USD</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'none',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}>
                            <TextField
                                sx={{margin: '10px'}}
                                label="Length"
                                value={length}
                                fullWidth
                                variant="standard"
                                onInput={e => setLength(e.target.value)}
                                required
                                {...register("length", {required: true})}
                            />
                            <TextField
                                sx={{margin: '10px'}}
                                label="Width"
                                value={width}
                                fullWidth
                                variant="standard"
                                onInput={e => setWidth(e.target.value)}
                                required
                                {...register("width", {required: true})}
                            />
                            <TextField
                                sx={{margin: '10px'}}
                                label="Height"
                                value={height}
                                onInput={e => setHeight(e.target.value)}
                                fullWidth
                                required
                                variant="standard"
                                {...register("height", {required: true})}
                            />
                            <FormControl variant="standard" sx={{margin: '10px'}} fullWidth>
                                <InputLabel>Length Unit*</InputLabel>
                                <Select
                                    value={lengthUnit}
                                    label="length unit"
                                    onChange={e => setLengthUnit(e.target.value)}
                                >
                                    <MenuItem value={'cm'}>cm</MenuItem>
                                    <MenuItem value={'in'}>in</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'none',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}>
                            <TextField
                                sx={{margin: '10px'}}
                                label="weight"
                                value={weight}
                                onInput={e => setWeight(e.target.value)}
                                required
                                fullWidth
                                variant="standard"
                                {...register("weight", {required: true})}
                            />
                            <FormControl variant="standard" sx={{margin: '10px'}} fullWidth>
                                <InputLabel>Weight Unit*</InputLabel>
                                <Select
                                    value={weightUnit}
                                    label="weight unit"
                                    onChange={e => setWeightUnit(e.target.value)}
                                >
                                    <MenuItem value={'lb'}>lb</MenuItem>
                                    <MenuItem value={'kg'}>kg</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl sx={{margin: '10px'}} fullWidth variant="standard">
                                <InputLabel>Origin Country*</InputLabel>
                                <Select
                                    value={originCountry}
                                    label="origin country"
                                    onChange={e => setOriginCountry(e.target.value)}
                                >
                                    {
                                        getCountryCode().map(v => {
                                            return <MenuItem value={v.code}>{v.name}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                            <FormControl sx={{margin: '10px'}} fullWidth variant="standard">
                                <InputLabel>Unit of Measure*</InputLabel>
                                <Select
                                    value={UOM}
                                    label="unit of measure"
                                    onChange={e => setUOM(e.target.value)}
                                >
                                    {UOMList.map(v => {
                                        return <MenuItem value={v}>{v}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexWrap: 'none',
                            alignItems: 'center',
                            margin: '0 10px'
                        }}>
                            <Autocomplete
                                disablePortal
                                options={HSCodeList}
                                // options={itemInfo?.hscode}
                                fullWidth
                                freeSolo
                                // value={HScode && HScode}
                                filterOptions={(options, state) => options}
                                // getOptionLabel={(option) => option.label}
                                getOptionLabel={(option) => `${option.hscode}`}
                                renderOption={(option) => <span><b>{option.hscode}</b> | {option.desc}</span>}
                                onChange={(e, value) => {
                                    // setHScode(value)
                                }}
                                onInputChange={async (e, value) => {
                                    // handleItemChangeByValue("hscodeDescription")(value);
                                    // setSearchValue(value);
                                    await searchHSCode(value);
                                    setHScode(value)
                                    // console.log(value)

                                }}
                                renderInput={(params) => <TextField
                                    multiline
                                    id="outlined-basic"
                                    label={itemInfo?.hscode}
                                    variant="standard"
                                    fullWidth
                                    value={HScode}
                                    {...params}
                                    required
                                />}
                            />
                        </Box>
                        <Box>
                            <Button type="submit" sx={{margin: '10px'}} color="success"
                                    onClick={handleSubmit(onSubmit)}
                                    variant="contained">Confirm</Button>
                            <Button onClick={handleCancel} variant="contained" color="error">Cancel</Button>
                        </Box>
                    </Box>
                    // </Modal>
                )
        }
        </>
    )
}