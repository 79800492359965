import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import {getAccessToken} from "../../Utils/doToken";
import axios from "axios";
import {useEffect, useState} from "react";
import {Container, IconButton, MenuItem, Select, Tooltip} from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import HelpIcon from "@mui/icons-material/Help";
import * as React from "react";
import FormControl from "@mui/material/FormControl";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import {isValid} from "date-fns";
import FormLabel from "@mui/material/FormLabel";
import dayjs from "dayjs";
import {MySchedulePickupItem} from "./MySchedulePickupItem";

export const MySchedulePickup = () => {
    const accessToken = getAccessToken("access_token");
    const [accountBase, setAccountBase] = useState("all");
    const [appointDateStart, setAppointDateStart] = useState();
    const [appointDateEnd, setAppointDateEnd] = useState();
    const [scheduledPickupList, setScheduledPickupList] = useState([]);

    const handleChangeAccountBase = (event) => {
        setAccountBase(event?.target?.value)
    }

    const handleStartDate = (date) => {
        // console.log('data', date);
        if (date) {
            setAppointDateStart(date);
        } else {
            const currentDate = dayjs();
            const defaultStartDate = currentDate.subtract(30, 'day');
            setAppointDateStart(defaultStartDate);
        }
    };

    const handleEndDate = (date) => {
        // console.log('data', date);
        if (date) {
            setAppointDateEnd(date);
        } else {
            const currentDate = dayjs();
            const defaultEndDate = currentDate.add(30, 'day');
            setAppointDateEnd(defaultEndDate);
        }
    };

    const getSchedulePickup = async () => {
        let requestURL = `${NODE_ROUTE_URI}/packagesPickupAppointment/getMyPickupAppointmentList`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
                params: {
                    accountBase: accountBase === "all" ? "" : accountBase,
                    appointDateStart: appointDateStart?.format('YYYY-MM-DD'),
                    appointDateEnd: appointDateEnd?.format('YYYY-MM-DD')
                }
            })
            console.log('result', result);
            setScheduledPickupList(result?.data);

        } catch (e) {
            console.log('error', e.response);
        }
    }

    const cbGetSchedulePickup = () => {
        getSchedulePickup();
    }

    useEffect(() => {
        const currentDate = dayjs();

        const defaultStartDate = currentDate.subtract(30, 'day');
        const defaultEndDate = currentDate.add(30, 'day');

        // console.log(defaultDate);
        setAppointDateStart(defaultStartDate);
        setAppointDateEnd(defaultEndDate);
    }, []);

    useEffect(() => {
        getSchedulePickup();
    }, [accountBase, appointDateStart, appointDateEnd])

    return (
        <Container maxWidth='xl'>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Box sx={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            My Scheduled Pickups
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                    <FormControl fullWidth>
                        <FormLabel required>
                            Carrier
                        </FormLabel>
                        <Select
                            value={accountBase}
                            placeholder='Status'
                            onChange={handleChangeAccountBase}
                        >
                            <MenuItem disabled value='type' sx={{
                                fontSize: '14px',    // Adjust the desired text size
                            }}>
                                <em>Carrier</em>
                            </MenuItem>
                            <MenuItem value='all' sx={{
                                fontSize: '14px',    // Adjust the desired text size
                            }}>ALL</MenuItem>
                            <MenuItem value='ups' sx={{
                                fontSize: '14px',    // Adjust the desired text size
                            }}>UPS</MenuItem>
                            <MenuItem value='fedex' sx={{
                                fontSize: '14px',    // Adjust the desired text size
                            }}>FedEx</MenuItem>
                            <MenuItem value='canadapost' sx={{
                                fontSize: '14px',    // Adjust the desired text size
                            }}>CanadaPost</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item md={3} xs={6}>
                    <FormControl>
                        <FormLabel required>
                            Start Date
                        </FormLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                value={appointDateStart}
                                onChange={handleStartDate}
                                renderInput={(props) =>
                                    <TextField
                                        {...props}
                                        fullWidth
                                    />}
                                // slotProps={{
                                //     textField: {
                                //         helperText: !isValid(selectedDate?.$d) && 'Invalid Pickup Date',
                                //     },
                                // }}
                            />
                        </LocalizationProvider>
                    </FormControl>
                </Grid>
                <Grid item md={3} xs={6}>
                    <FormControl>
                        <FormLabel required>
                            End Date
                        </FormLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                value={appointDateEnd}
                                onChange={handleEndDate}
                                renderInput={(props) =>
                                    <TextField
                                        {...props}
                                        fullWidth
                                    />}
                                // slotProps={{
                                //     textField: {
                                //         helperText: !isValid(selectedDate?.$d) && 'Invalid Pickup Date',
                                //     },
                                // }}
                            />
                        </LocalizationProvider>
                    </FormControl>
                </Grid>
                {
                    scheduledPickupList?.length > 0 ? scheduledPickupList?.map((pickupInfo, index) =>
                            <Grid item xs={12} key={index}>
                                <MySchedulePickupItem pickupInfo={pickupInfo} cbGetSchedulePickup={cbGetSchedulePickup}/>
                            </Grid>
                        )
                        :
                        <Grid item xs={12}>
                            <Box sx={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                                <Typography style={{fontSize: '18px', fontWeight: '600'}}>
                                    No result. Your scheduled pickup list is empty.
                                </Typography>
                            </Box>
                        </Grid>
                }
            </Grid>
        </Container>
    )

}