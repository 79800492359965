import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
// import store from '../../store/index'
import { useHistory } from "react-router-dom";
import axios from "axios";
// import { useDispatch } from 'react-redux';

import { redirectSettlementDetails } from "../../Utils/redirect";
import { GET_SETTLEMENTS } from "../../Utils/apiUrl";
import { getHeaders } from "../../Utils/fetchUtil";
import LoadingContainer from "../shared/loadingContainer";

// const listData = [
//   {
//     settlement: "12334",
//     amount: "100",
//     method: "Cheque",
//     period: "Aug 01, 2021 to Agu 31, 2021",
//   },
//   {
//     settlement: "12335",
//     amount: "100",
//     method: "Cheque",
//     period: "July 01, 2021 to July 31, 2021",
//   },
// ];

const SettlementsList = () => {
  const history = useHistory();

  //   const [page, setPage] = useState(1);
  const [listData, setListData] = useState(null);

  const classes = useStyles();

  useEffect(() => {
    axios
      .get(GET_SETTLEMENTS("sallie.kunze@example.org"), {
        headers: getHeaders(),
      })
      .then((response) => {
        console.log(response);
        if (response.data) {
          setListData(response.data);
        }
      })
      .catch((err) => {
        // errHandling(err, setPriceErrMsg);
        // setPriceErrMsg(err.response.data.message);
        // setFetchingPriceState(false);
        console.log(err.message);
      });
  }, []);

  const handleItemClick = (item) => {
    history.push({
      pathname: redirectSettlementDetails(item.settlement_number),
      state: {
        finalAmount: item.final_amount,
        method: item.payment_method,
        start: item.period_start,
        end: item.period_end,
      },
    });
  };

  return (
    <Box>
      <Box textAlign="center" className={classes.title}>
        Settlements List
      </Box>
      {listData ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {listData.map((item, key) => {
            return (
              <Box key={item.settlement_number} className={classes.paper}>
                <Box
                  className={classes.container}
                  onClick={() => handleItemClick(item)}
                >
                  <Box fontWeight="600" fontSize="1.2rem">
                    Settlement # {item.settlement_number}:
                  </Box>
                  <Box>
                    -{" "}
                    <Box
                      component="span"
                      fontWeight="600"
                      color="rgba(0,0,0,0.6)"
                    >
                      Amount:&nbsp;&nbsp;
                    </Box>
                    C$ {item.final_amount}
                  </Box>
                  <Box>
                    -{" "}
                    <Box
                      component="span"
                      fontWeight="600"
                      color="rgba(0,0,0,0.6)"
                    >
                      Method:&nbsp;&nbsp;
                    </Box>
                    {item.payment_method}
                  </Box>
                  <Box mb={2}>
                    -{" "}
                    <Box
                      component="span"
                      fontWeight="600"
                      color="rgba(0,0,0,0.6)"
                    >
                      Period:&nbsp;&nbsp;
                    </Box>{" "}
                    {`${item.period_start} to ${item.period_end}`}
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
      ) : (
        <LoadingContainer />
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: "500px",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0.5),
    backgroundColor: "#fdf5e8",
    width: "100%",
  },
  container: {
    // textAlign: "center",
    backgroundColor: "#fbfbf8",
    border: "1px solid #ded47c",
    borderRadius: "5px",
    margin: "0px auto",
    cursor: "pointer",
    "&:hover": {
      // border: '1px solid rgba(0,0,0,0.3)',
      backgroundColor: "rgba(0,0,0,0.1)",
      borderRadius: "5px",
    },
  },
  title: {
    fontSize: "2rem",
    fontWeight: "600",
    color: "seagreen",
    fontFamily: "Montserrat",
  },
}));

// SettlementsList.propTypes = {
//   guest: PropTypes.bool,
// };

// export default connect(mapStateToProps, {getOrders, getPackages, getOrderShipments})(OrderList)
export default SettlementsList;
