import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@mui/material/Button";
import {useEffect, useState} from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LoadingButton from "@mui/lab/LoadingButton";
import EmailIcon from "../../images/emailTransferIcon.png";
import CreditIcon from "../../images/creditCardIcon.png";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {TopUpEmailTransfer} from "./TopUpEmailTransfer";
import {TopUpCreditCard} from "./TopUpCreditCard";
import {TopUpOther} from "./TopUpOther";
import {useSelector} from "react-redux";
import wechatPay from "../../images/wechatPay.svg";
import aliPay from "../../images/alipay.svg";
import {TopUpWeChatPay} from "./TopUpWeChatPay";
import {TopUpAliPay} from "./TopUpAliPay";
import {TopUpPayPal} from "./TopUpPayPal";
import {getAccessToken} from "../../Utils/doToken";
import axios from "axios";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import intl from "react-intl-universal";
import {FormLabel, MenuItem, Select, Slider} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import {styled} from "@mui/material/styles";
import {useThrottle} from "../../Utils/CustomHook";
import {greenButtonContained, greyButtonContained} from "../../Utils/styleConfig";

const styles = {
    TopUpContentRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '30px',
        display: 'flex',
        flexDirection: 'column',
    },
    TopUpContentContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '50px'
    },
    TopUpContentAmount: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    TopUpContentTitle: {
        fontWeight: '600',
        textAlign: 'left'
    },
    TopUpContentText: {
        textAlign: 'left',
        fontSize: '14px',
    },
    TopUpContentPayment: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'column',
        gap: '20px'
    },

}

const paymentAmountArrayForAdmin = [0.1];

const marks = [
    {
        value: 50,
        label: '$50',
    },
    {
        value: 1000,
        label: '$1000',
    },
    {
        value: 3000,
        label: '$3000',
    },
    {
        value: 5000,
        label: '$5000',
    },
];

const PrettoSlider = styled(Slider)({
    color: '#52af77',
    height: 8,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&::before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: '#52af77',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&::before': {display: 'none'},
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
});

export const TopUpContent = ({
                                 cbHandleAmount,
                                 cbHandlePayment,
                                 cbReferenceNumber,
                                 cbSelectCreditCard,
                                 cbSelectOther,
                                 coupon,
                                 cbIsConfirmAmount,
                                 startsWithBusiness
                             }) => {

    const {id: partnerId} = useSelector((state) => state.user);

    const [selectAmount, setSelectAmount] = useState(50);
    const [selectPayment, setSelectPayment] = useState('email');
    const [invoiceId, setInvoiceId] = useState(0);
    const [crmInvoiceInfo, setCRMInvoiceInfo] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [customize, setCustomize] = useState('');
    const [isConfirmAmount, setIsConfirmAmount] = useState(false);
    const [qrCodeImage, setQrCodeImage] = useState("");
    const [amount, setAmount] = useState(0);
    const [payCheckLink, setPayCheckLink] = useState("#");

    const throttledAmount = useThrottle(selectAmount, 1500);

    const handleSelectItem = (amount) => {
        if (amount === 'Customize') {
            setSelectAmount('50');
            cbHandleAmount(50);
            setCustomize(amount)
        } else {
            setSelectAmount(amount);
            cbHandleAmount(amount);
            setCustomize("");
        }
    }

    const handleSelectPayment = (method) => {
        setSelectPayment(method);
        cbHandlePayment(method);
    }

    const handleTopAmount = (event) => {
        const amount = event?.target?.value;
        if (/^\d*\.?\d{0,1}$/.test(amount)) {
            setSelectAmount(amount);
            console.log('[handleTopAmount] amount', amount);
            cbHandleAmount(+amount || 0);
        }
    }

    const handleConfirmAmount = () => {
        setIsConfirmAmount(true);
        cbIsConfirmAmount(true);
    }

    const handleEditAmount = () => {
        setIsConfirmAmount(false);
        cbIsConfirmAmount(false);
    }

    const initDownPaymentByAmount = async () => {
        try {
            setIsLoading(true);
            const storedToken = getAccessToken("access_token");
            const payType = selectPayment === 'wechatPay' ? 'ALPHAPAY-WECHATPAY' : 'ALPHAPAY-ALIPAY'

            const requestURL = startsWithBusiness ? `${PARTNER_URI}/generalWechatAlipay/initPartnerTopUpByAmount` : `${NODE_ROUTE_URI}/generalWechatAlipay/initEndUserTopUpByAmount`;

            const {data} = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                data: {
                    amount: throttledAmount,
                    payType: payType,
                    coupon: coupon?.coupon
                }
            })

            console.log('init down payment', data);
            const {qrcode_image, amount, pay_url} = data;
            setQrCodeImage(qrcode_image);
            setAmount((+amount / 100));
            setPayCheckLink(pay_url);
            setError(false);
        } catch (e) {
            console.log(e);
            setError(true);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (isConfirmAmount) {
            if (selectPayment === 'wechatPay' || selectPayment === 'aliPay') {
                // initDownPayment();
                initDownPaymentByAmount();
            }
        }
    }, [selectAmount, selectPayment, throttledAmount, isConfirmAmount]);

    // console.log('partner id', partnerId);
    //
    // console.log('select amount', selectAmount);
    //
    // console.log('throttled Amount', throttledAmount)

    return (
        <Box sx={styles.TopUpContentRoot}>
            <Box sx={styles.TopUpContentContent}>
                <Typography style={styles.TopUpContentTitle}>
                    {intl.get('BILLING_CENTER.TOP_UP_PAGE.CHOOSE_AMOUNT')}
                </Typography>
                <Box sx={styles.TopUpContentAmount}>
                    <Grid container spacing={6} alignItems="center" justifyContent="center">
                        <Grid item xs={12}>
                            <Grid container spacing={4} justifyContent="space-between">
                                {
                                    partnerId === 1 && paymentAmountArrayForAdmin?.map((each, index) => {
                                        return (
                                            <Grid item md={3} key={index} sx={{position: 'relative'}}>
                                                <Box sx={{
                                                    position: 'relative'
                                                }}>
                                                    {
                                                        (each === selectAmount || each === customize) &&
                                                        <CheckCircleIcon
                                                            sx={{
                                                                position: 'absolute',
                                                                right: '-10%',
                                                                top: '-20%',
                                                                zIndex: 1,
                                                                color: '#1D8B45',
                                                                backgroundColor: '#ffffff',
                                                                borderRadius: '50%'
                                                            }}
                                                        />
                                                    }
                                                    <Button
                                                        sx={{
                                                            border: (each === selectAmount || each === customize) ? '2px solid #1D8B45' : '2px solid #A6A6A6',
                                                            width: '100%',
                                                            height: '100%',
                                                            padding: '15px 0',
                                                            overflow: 'visible',
                                                            position: 'relative',
                                                            borderRadius: '5px',
                                                        }}
                                                        onClick={() => handleSelectItem(each)}
                                                    >
                                                        <Typography sx={{
                                                            color: '#000000',
                                                            fontSize: '18px',
                                                            textTransform: 'none'
                                                        }}>
                                                            {each}
                                                        </Typography>
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        )
                                    })
                                }
                                <Grid item md={8} xs={12}>
                                    <PrettoSlider
                                        value={selectAmount}
                                        disabled={isConfirmAmount}
                                        max={5000}
                                        min={50}
                                        size="medium"
                                        onChange={handleTopAmount}
                                        valueLabelDisplay="on"
                                        marks={marks}
                                    />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <FormControl fullWidth>
                                        <TextField
                                            value={selectAmount}
                                            type="number"
                                            onInput={handleTopAmount}
                                            size='small'
                                            placeholder='Customized Input Top Amount'
                                            disabled={isConfirmAmount}
                                            helperText={selectAmount < 50 && intl.get('BILLING_CENTER.TOP_UP_PAGE.MINIMUM_AMOUNT')}
                                            error={selectAmount < 50}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box sx={styles.TopUpContentContent}>
                <Box sx={{display: 'flex', flexDirection: 'column', gap: '5px'}}>
                    <Typography style={styles.TopUpContentTitle}>
                        {intl.get('BILLING_CENTER.TOP_UP_PAGE.CHOOSE_A_PAYMENT')}
                    </Typography>
                    <Typography style={styles.TopUpContentText}>
                        {intl.get('BILLING_CENTER.TOP_UP_PAGE.CHOOSE_A_PAYMENT_TEXT')}
                    </Typography>
                </Box>
                <Box sx={styles.TopUpContentPayment}>
                    <Grid container spacing={4} alignItems="center" justifyContent="center">
                        <Grid item md={4} xs={12}>
                            <Box sx={{
                                position: 'relative',
                                height: '100px'
                            }}>
                                {
                                    selectPayment === 'email' &&
                                    <CheckCircleIcon
                                        sx={{
                                            position: 'absolute',
                                            right: '-5%',
                                            top: '-10%',
                                            zIndex: 1,
                                            color: '#1D8B45',
                                            backgroundColor: '#ffffff',
                                            borderRadius: '50%'
                                        }}
                                    />
                                }
                                <LoadingButton
                                    sx={{
                                        border: selectPayment === 'email' ? '2px solid #1D8B45' : '2px solid #A6A6A6',
                                        width: '100%',
                                        height: '100%',
                                        padding: '15px 0px',
                                        overflow: 'visible',
                                        position: 'relative',
                                        borderRadius: '10px',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                    disabled={isConfirmAmount}
                                    onClick={() => handleSelectPayment('email')}
                                >
                                    <img src={EmailIcon} alt="email-icon" width={60}/>
                                    <Typography sx={{
                                        color: '#000000',
                                        fontSize: '12px',
                                        textTransform: 'none',
                                        fontWeight: '600'
                                    }}>
                                        {intl.get('BILLING_CENTER.PAYMENT.EMAIL_TRANSFER.EMAIL_TRANSFER')}
                                    </Typography>
                                </LoadingButton>
                            </Box>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Box sx={{
                                position: 'relative',
                                height: '100px'
                            }}>
                                {
                                    selectPayment === 'credit' &&
                                    <CheckCircleIcon
                                        sx={{
                                            position: 'absolute',
                                            right: '-5%',
                                            top: '-10%',
                                            zIndex: 1,
                                            color: '#1D8B45',
                                            backgroundColor: '#ffffff',
                                            borderRadius: '50%'
                                        }}
                                    />
                                }
                                <LoadingButton
                                    sx={{
                                        border: selectPayment === 'credit' ? '2px solid #1D8B45' : '2px solid #A6A6A6',
                                        width: '100%',
                                        height: '100%',
                                        padding: '15px 0px',
                                        overflow: 'visible',
                                        position: 'relative',
                                        borderRadius: '10px',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                    disabled={isConfirmAmount}
                                    onClick={() => handleSelectPayment('credit')}
                                >
                                    <img src={CreditIcon} alt="credit-icon" width={60}/>
                                    <Typography sx={{
                                        color: '#000000',
                                        fontSize: '12px',
                                        textTransform: 'none',
                                        fontWeight: '600'
                                    }}>
                                        {intl.get('BILLING_CENTER.PAYMENT.CREDIT_CARD.CREDIT_CARD')}
                                    </Typography>
                                </LoadingButton>
                            </Box>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Box sx={{
                                position: 'relative',
                                height: '100px'
                            }}>
                                {
                                    selectPayment === 'wechatPay' &&
                                    <CheckCircleIcon
                                        sx={{
                                            position: 'absolute',
                                            right: '-5%',
                                            top: '-10%',
                                            zIndex: 1,
                                            color: '#1D8B45',
                                            backgroundColor: '#ffffff',
                                            borderRadius: '50%'
                                        }}
                                    />
                                }
                                <LoadingButton
                                    sx={{
                                        border: selectPayment === 'wechatPay' ? '2px solid #1D8B45' : '2px solid #A6A6A6',
                                        width: '100%',
                                        height: '100%',
                                        padding: '15px 0px',
                                        overflow: 'visible',
                                        position: 'relative',
                                        borderRadius: '10px',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                    disabled={isConfirmAmount}
                                    onClick={() => handleSelectPayment('wechatPay')}
                                >
                                    <img src={wechatPay} alt="wechatPayLogo" width={50}/>
                                    <Typography sx={{
                                        color: '#000000',
                                        fontSize: '12px',
                                        textTransform: 'none',
                                        fontWeight: '600'
                                    }}>
                                        {intl.get('BILLING_CENTER.PAYMENT.WECHAT.WECHAT_PAY')}
                                    </Typography>
                                </LoadingButton>
                            </Box>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Box sx={{
                                position: 'relative',
                                height: '100px'
                            }}>
                                {
                                    selectPayment === 'aliPay' &&
                                    <CheckCircleIcon
                                        sx={{
                                            position: 'absolute',
                                            right: '-5%',
                                            top: '-10%',
                                            zIndex: 1,
                                            color: '#1D8B45',
                                            backgroundColor: '#ffffff',
                                            borderRadius: '50%'
                                        }}
                                    />
                                }
                                <LoadingButton
                                    sx={{
                                        border: selectPayment === 'aliPay' ? '2px solid #1D8B45' : '2px solid #A6A6A6',
                                        width: '100%',
                                        height: '100%',
                                        padding: '15px 0px',
                                        overflow: 'visible',
                                        position: 'relative',
                                        borderRadius: '10px',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                    disabled={isConfirmAmount}
                                    onClick={() => handleSelectPayment('aliPay')}
                                >
                                    <img src={aliPay} alt="aliPayLogo" width={50}/>
                                    <Typography sx={{
                                        color: '#000000',
                                        fontSize: '12px',
                                        textTransform: 'none',
                                        fontWeight: '600'
                                    }}>
                                        {intl.get('BILLING_CENTER.PAYMENT.ALI.ALI_PAY')}
                                    </Typography>
                                </LoadingButton>
                            </Box>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Box sx={{
                                position: 'relative',
                                height: '100px'
                            }}>
                                {
                                    selectPayment === 'paypal' &&
                                    <CheckCircleIcon
                                        sx={{
                                            position: 'absolute',
                                            right: '-5%',
                                            top: '-10%',
                                            zIndex: 1,
                                            color: '#1D8B45',
                                            backgroundColor: '#ffffff',
                                            borderRadius: '50%'
                                        }}
                                    />
                                }
                                <LoadingButton
                                    sx={{
                                        border: selectPayment === 'paypal' ? '2px solid #1D8B45' : '2px solid #A6A6A6',
                                        width: '100%',
                                        height: '100%',
                                        padding: '15px 0px',
                                        overflow: 'visible',
                                        position: 'relative',
                                        borderRadius: '10px',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                    disabled={isConfirmAmount}
                                    onClick={() => handleSelectPayment('paypal')}
                                >
                                    <img
                                        src={'https://storage.googleapis.com/uucargo-resource/image/icon/paypal.svg'}
                                        alt="PayPalLogo" width={50}/>
                                    <Typography sx={{
                                        color: '#000000',
                                        fontSize: '12px',
                                        textTransform: 'none',
                                        fontWeight: '600'
                                    }}>
                                        {intl.get('BILLING_CENTER.PAYMENT.PAY_PAL.PAY_PAL')}
                                    </Typography>
                                </LoadingButton>
                            </Box>
                        </Grid>
                    </Grid>
                    {
                        (selectAmount >= 50 || partnerId === 1) ?
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                {
                                    isConfirmAmount ?
                                        <Button
                                            variant='contained'
                                            sx={greyButtonContained}
                                            onClick={handleEditAmount}
                                        >
                                            <Typography sx={{fontSize: '14px', textTransform: 'none'}}>
                                                {intl.get('BILLING_CENTER.TOP_UP_PAGE.CANCEL')}
                                            </Typography>
                                        </Button>
                                        :
                                        <Button
                                            variant='contained'
                                            sx={greenButtonContained}
                                            onClick={handleConfirmAmount}
                                        >
                                            <Typography sx={{fontSize: '14px', textTransform: 'none'}}>
                                                {intl.get('BILLING_CENTER.TOP_UP_PAGE.CONFIRM')}
                                            </Typography>
                                        </Button>
                                }
                            </Box>
                            :
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                <h3>
                                    {intl.get('BILLING_CENTER.TOP_UP_PAGE.MINIMUM_AMOUNT')}
                                </h3>
                            </Box>

                    }
                    {
                        (isConfirmAmount && selectPayment) === 'email' &&
                        <TopUpEmailTransfer cbReferenceNumber={cbReferenceNumber}/>
                    }
                    {
                        (isConfirmAmount && selectPayment) === 'credit' &&
                        <TopUpCreditCard amount={selectAmount} cbSelectCreditCard={cbSelectCreditCard}
                                         startsWithBusiness={startsWithBusiness}/>
                    }
                    {
                        (isConfirmAmount && selectPayment) === 'wechatPay' &&
                        <TopUpWeChatPay qrCodeImage={qrCodeImage} amount={amount} payCheckLink={payCheckLink}
                                        isLoading={isLoading}
                        />
                    }
                    {
                        (isConfirmAmount && selectPayment) === 'aliPay' &&
                        <TopUpAliPay qrCodeImage={qrCodeImage} amount={amount} payCheckLink={payCheckLink}
                                     isLoading={isLoading}
                        />
                    }
                    {
                        (isConfirmAmount && selectPayment) === 'paypal' &&
                        <TopUpPayPal crmInvoiceNumber={invoiceId} crmInvoiceInfo={crmInvoiceInfo} coupon={coupon}/>
                    }
                </Box>
            </Box>
        </Box>
    )
}
