import {useSelector} from "react-redux";
import {Container} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {BillingAddress} from "./BillingAddress";
import {BillingInvoices} from "./BillingInvoices";
import {BillingCreditCard} from "./BillingCreditCard";
import {BackButton} from "../../Utils/CustomComponent";
import intl from "react-intl-universal";
import {BillingSummary} from "./BillingSummary";

const styles = {
    BillingRoot: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        marginBottom: '30px'
    },
    BillingHeading: {
        fontSize: '25px',
        fontWeight: '600',
        textAlign: 'left',
        marginTop: '10px'
    }
}

export const Billing = () => {

    const userInfo = useSelector((state) => state.user);

    console.log('user info', userInfo);
    return (
        <Container maxWidth='xl'>
            <Box sx={styles.BillingRoot}>
                <Typography style={styles.BillingHeading}>
                    <BackButton/>{intl.get('BILLING_CENTER.BILLING_INVOICES')}
                </Typography>
                <Grid container spacing={2}>
                    <Grid item md={7} xs={12}>
                        <BillingInvoices/>
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <BillingSummary/>
                            </Grid>
                            <Grid item xs={12}>
                                <BillingAddress userInfo={userInfo}/>
                            </Grid>
                            <Grid item xs={12}>
                                <BillingCreditCard/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}