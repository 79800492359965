import React, { useState, useCallback } from "react";
import {
  makeStyles,
  Paper,
  Grid,
  Typography,
  Button,
  Divider,
  IconButton,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  selectDeliverShipments,
  removeSelectedDeliverShipment,
  removeLoadedShipments,
  cleanLoadedShipmentsData,
} from "../../actions/driverLoadAndDeliverShipmentsAction";
import axios from "axios";
import { DRIVER_LOAD_SHIPMENTS_BY_SHIPMENT_ID } from "../../Utils/apiUrl";
import { getTokenFromCookie } from "../../Utils/doToken";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    color: "#111",
    fontFamily: "Helvetica Neue, sans-serif",
    "font-size": "30px",
    "font-weight": "bold",
    "letter-spacing": "-1px",
    "line-height": 1,
    margin: "5px",
  },
  paper: {
    margin: "5px 2%",
    width: "96%",
    maxWidth: "1000px",
    backgroundColor: "#fdf5e8",
    padding: "3px",
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "150px",
    maxHeight: "150px",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
  },
  blocks: {
    backgroundColor: "#fbfbf8",
    border: "1px solid #ded47c",
    borderRadius: "5px",
    margin: "5px",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    display: "flex",
    justifyContent: "space-around",
  },
  row: {
    borderBottom: "2px solid lightgrey",
    height: "2.2rem",
    width: "94%",
    margin: "5px auto",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const LoadAndDeliverShipmentsLayout = ({
  deletable,
  deliverPackages,
  loadedPackages,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dataFlow = deletable
    ? deliverPackages.selectedPackages
    : deliverPackages.deliverPackages;

  const deliveredTrackingNumbers = useSelector(
    (state) => state.driver_deliver_packages.deliveredTrackingNumbers
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
  const handleSelect = (
    pKey,
    sKey,
    t,
    item_name,
    cover_image,
    checked,
    _stage,
    taskId
  ) => {
    const payload = {
      item_name: item_name,
      cover_image: cover_image,
      package_number: pKey,
      shipment_number: sKey,
      tracking_number: t,
      checked: checked,
      stage: _stage,
      taskId
    };

    if (checked) {
      dispatch(selectDeliverShipments(payload));
    } else {
      dispatch(removeSelectedDeliverShipment(payload));
    }
  };

  //pKey, sKey, t are package_number, shipment_number and tracking_number
  const handleRemove = (pKey, sKey, t) => {
    console.log("clicked to remove tracking number", pKey, sKey, t);
    const payload = {
      package_number: pKey,
      shipment_number: sKey,
      tracking_number: t,
    };

    dispatch(removeSelectedDeliverShipment(payload));
  };

  const handleLoadShipments = () => {
    setOpenBackDrop(true);
    setOpenDialog(true);
  };

  const handleCloseBackDrop = () => {
    setOpenBackDrop(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const loadShipments = async (shipments) => {
    const header = {
      headers: {
        Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
      },
    };
    const requests = [];
    shipments.forEach((sid) => {
      requests.push(
        axios.put(DRIVER_LOAD_SHIPMENTS_BY_SHIPMENT_ID(sid), "", header)
      );
    });

    try {
      const responses = await axios.all(requests);
      if (responses.status === 200) {
        setOpenFeedbackDialog(true);
      }
    } catch (error) {
      setOpenFeedbackDialog(true);
      console.log("Error when load shipments", error);
    }
  };

  const handleConfirm = () => {
    //get all shipment numbers that will be loaded
    setOpenDialog(false);
    const shipmentToLoad = loadedPackages.loaded_shipment_numbers;
    console.log("shipment to load ", shipmentToLoad);

    //calling API
    loadShipments(shipmentToLoad);
  };

  const handleCancle = () => {
    setOpenDialog(false);
    setOpenBackDrop(false);
  };

  const handleFeedbackConfirm = () => {
    //redirection
    setOpenFeedbackDialog(false);
    setOpenBackDrop(false);
    dispatch(cleanLoadedShipmentsData());
  };

  const handleCloseFeedbackDialog = () => {
    setOpenBackDrop(false);
  };
  return (
    <>
      {dataFlow &&
        Object.keys(dataFlow).length > 0 &&
        Object.keys(dataFlow).map((pKey, index) => {
          const p = dataFlow[pKey];
          const s = p.shipment_numbers;
          let tracking_count = 0;
          for (let key in s) {
            tracking_count += Object.keys(s[key]).length;
          }
          return (
            <>
              <li
                key={p.package_number}
                style={{ listStyleType: "none" }}
                className={classes.center}
              >
                <Paper className={classes.paper}>
                  <Typography variant="subtitle1" align="center">
                    {p.item_name}
                  </Typography>
                  {p.cover_image !== "" && (
                    <img
                      className={classes.img}
                      alt="package"
                      src={p.cover_image}
                    />
                  )}
                  <Divider variant="middle" style={{ margin: "5px auto" }} />
                  <Grid container className={classes.container}>
                    {/*<Grid item>*/}
                    {/*  <Typography variant="subtitle2">*/}
                    {/*    Total Shipments: {Object.keys(s).length}*/}
                    {/*  </Typography>*/}
                    {/*</Grid>*/}
                    {/*<Grid item>*/}
                    {/*  <Typography variant="subtitle2" align="center">*/}
                    {/*    Total Tracking Number(s): {tracking_count}*/}
                    {/*  </Typography>*/}
                    {/*</Grid>*/}
                    <Grid item>
                      <Typography variant="subtitle2" align="center">
                        Task Id: {p.taskId}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle2" align="center">
                        Stage: {p.stage}
                      </Typography>
                    </Grid>
                    {Object.keys(s).map((sKey, index) => {
                      return (
                        <Grid
                          item
                          container
                          className={classes.blocks}
                          key={sKey}
                        >
                          <Grid item xs={12}>
                            <Typography variant="subtitle1" align="center">
                              Shipment #: <i>{sKey}</i>{" "}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="subtitle2" align="center">
                              Total Tracking Number(s):{" "}
                              {Object.keys(s[sKey]).length}
                            </Typography>
                          </Grid>
                          {Object.keys(s[sKey]).map((t) => {
                            return (
                              <Grid container className={classes.row} key={t}>
                                <Grid item xs={6} className={classes.center}>
                                  <Typography variant="subtitle2">
                                    {t}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  {deletable
                                    ? (() => {
                                        if (
                                          deliveredTrackingNumbers?.includes(t)
                                        ) {
                                          return <span>Delivered</span>;
                                        } else {
                                          return (
                                            <IconButton
                                              aria-label="delete"
                                              onClick={() =>
                                                handleRemove(pKey, sKey, t)
                                              }
                                            >
                                              <DeleteIcon fontSize="small" />
                                            </IconButton>
                                          );
                                        }
                                      })()
                                    : (() => {
                                        if (
                                          deliveredTrackingNumbers?.includes(t)
                                        ) {
                                          return <span>Delivered</span>;
                                        } else {
                                          return (
                                            <Checkbox
                                              checked={s[sKey][t]}
                                              onChange={(event) =>
                                                handleSelect(
                                                  pKey,
                                                  sKey,
                                                  t,
                                                  p.item_name,
                                                  p.cover_image,
                                                  event.target.checked,
                                                  p.stage,
                                                  p.taskId
                                                )
                                              }
                                              name={`checked-${t}`}
                                              color="primary"
                                            />
                                          );
                                        }
                                      })()}
                                </Grid>
                              </Grid>
                            );
                          })}
                        </Grid>
                      );
                    })}
                  </Grid>
                </Paper>
              </li>
            </>
          );
        })}

      {/* {
        <>
          <Backdrop
            className={classes.backdrop}
            open={openBackDrop}
            onClick={handleCloseBackDrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
          >
            <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <p>Confirm to load shipment(s) with shipment number:</p>
                {loadedPackages &&
                  loadedPackages.loaded_shipment_numbers &&
                  loadedPackages.loaded_shipment_numbers.map(sNum => {
                    return (
                      <p key={sNum}>
                        <i>{sNum}</i>
                      </p>
                    );
                  })}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirm} color="primary" autoFocus>
                Yes
              </Button>
              <Button onClick={handleCancle} color="primary">
                Cancle
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openFeedbackDialog}
            onClose={handleCloseFeedbackDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
          >
            <DialogTitle id="alert-dialog-title">{"Awesome"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <p>
                  You have successfully loaded the shipment(s) with shipment
                  number:
                </p>
                {loadedPackages &&
                  loadedPackages.loaded_shipment_numbers &&
                  loadedPackages.loaded_shipment_numbers.map(sNum => {
                    return (
                      <p key={sNum}>
                        <i>{sNum}</i>
                      </p>
                    );
                  })}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleFeedbackConfirm} color="primary" autoFocus>
                Okay
              </Button>
            </DialogActions>
          </Dialog>
        </>
      } */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    deliverPackages: state.driver_deliver_packages,
    loadedPackages: state.driver_loaded_packages,
  };
};
export default connect(mapStateToProps)(LoadAndDeliverShipmentsLayout);
