import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import {Button, Snackbar} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {getMissingKeysForMovingContact, isValidateEmail, isValidatePhone, movingOrderToEdit} from "../../Utils/Helper";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../Utils/doToken";
import MuiAlert from "@mui/material/Alert";
import {updateMovingServiceSummaryContactInfoMissing} from "../../actions/movingServiceAction";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const MovingOrderDashboardContact = ({orderDetails, getMovingOrderDetails}) => {

    console.log('[MovingOrderDashboardContact] orderDetails', orderDetails);

    const accessToken = getAccessToken("access_token");

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [contactInfo, setContactInfo] = useState({
        pickupName: '',
        pickupPhone: '',
        pickupEmail: '',
        deliverName: '',
        deliverPhone: '',
        deliverEmail: ''
    });
    const [originalContactInfo, setOriginalContactInfo] = useState({
        pickupName: '',
        pickupPhone: '',
        pickupEmail: '',
        deliverName: '',
        deliverPhone: '',
        deliverEmail: ''
    });
    const [validationResult, setValidationResult] = useState(null);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handlePickupName = event => {
        setContactInfo(prevState => ({
            ...prevState,
            pickupName: event.target.value
        }))
    };

    const handlePickupPhone = event => {
        setContactInfo(prevState => ({
            ...prevState,
            pickupPhone: event.target.value
        }))
    };

    const handlePickupEmail = event => {
        setContactInfo(prevState => ({
            ...prevState,
            pickupEmail: event.target.value
        }))
    };

    const handleDeliverName = event => {
        setContactInfo(prevState => ({
            ...prevState,
            deliverName: event.target.value
        }))
    };

    const handleDeliverPhone = event => {
        setContactInfo(prevState => ({
            ...prevState,
            deliverPhone: event.target.value
        }))
    };

    const handleDeliverEmail = event => {
        setContactInfo(prevState => ({
            ...prevState,
            deliverEmail: event.target.value
        }))
    };

    const validation = () => {
        setErrorMessage('');
        setToastOpen(false);

        const missingKeys = getMissingKeysForMovingContact(contactInfo);
        const pickupPhoneError = isValidatePhone(contactInfo?.pickupPhone);
        const pickupEmailError = isValidateEmail(contactInfo?.pickupEmail);
        const deliverPhoneError = isValidatePhone(contactInfo?.deliverPhone);
        const deliverEmailError = isValidateEmail(contactInfo?.deliverEmail);

        missingKeys?.length > 0 && setErrorMessage(prevState => prevState + 'Contact Information is missing some required fields.');
        !pickupPhoneError && setErrorMessage(prevState => prevState + 'Pickup phone number is invalid.');
        !pickupEmailError && setErrorMessage(prevState => prevState + 'Pickup email is invalid.');
        !deliverPhoneError && setErrorMessage(prevState => prevState + 'Delivery phone number is invalid.');
        !deliverEmailError && setErrorMessage(prevState => prevState + 'Delivery email is invalid.');
        // validateMoveDataAndTime() && setErrorMessage(prevState => prevState + 'Moving date or time is missing.');

        setValidationResult({
            missingKeys,
            pickupPhoneError: !pickupPhoneError,
            pickupEmailError: !pickupEmailError,
            deliverPhoneError: !deliverPhoneError,
            deliverEmailError: !deliverEmailError
        })

        if (missingKeys?.length > 0 ||
            !pickupPhoneError ||
            !pickupEmailError ||
            !deliverPhoneError ||
            !deliverEmailError
        ) {
            handleErrorMessage();
            return false
        } else {
            return true
        }
    }

    const edieOrder = async () => {
        setLoading(true);
        let requestURL = `${NODE_ROUTE_URI}/movingService/editMovingOrderDetails`;

        const data = {
            backendOrderId: orderDetails?.order_id,
            senderName: contactInfo?.pickupName,
            senderEmail: contactInfo?.pickupEmail,
            senderPhone: contactInfo?.pickupPhone,
            deliverPhone: contactInfo?.deliverPhone,
            deliverEmail: contactInfo?.deliverEmail,
            deliverName: contactInfo?.deliverName,
        }

        try {
            const result = await axios({
                method: 'patch',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: data
            });
            console.log('[MovingOrderDashboardContact] edieOrder - result data', result?.data);
            setErrorMessage("");
            setSuccessMessage('Successful to update contact details.');
            handleSuccessMessage();
            getMovingOrderDetails();
        } catch (e) {
            console.log('[MovingOrderDashboardContact] error - response', e?.response);
            setErrorMessage('Fail to update contact details.');
            handleErrorMessage();
        } finally {
            setLoading(false);
            setIsUpdate(false);
        }
    }

    const handleUpdate = () => {
        setIsUpdate(true);
    }

    const handleCancel = () => {
        setContactInfo(originalContactInfo);
        setIsUpdate(false);
        setValidationResult(null);
    }

    const handleConfirm = () => {
        if (validation()) {
            edieOrder();
        }
    }

    useEffect(() => {
        setContactInfo(prevState => ({
            ...prevState,
            pickupName: orderDetails?.sender,
            pickupPhone: orderDetails?.pickup_phone,
            pickupEmail: orderDetails?.pickup_email,
            deliverName: orderDetails?.deliver_name,
            deliverPhone: orderDetails?.deliver_phone,
            deliverEmail: orderDetails?.deliver_email
        }))
        setOriginalContactInfo(prevState => ({
            ...prevState,
            pickupName: orderDetails?.sender,
            pickupPhone: orderDetails?.pickup_phone,
            pickupEmail: orderDetails?.pickup_email,
            deliverName: orderDetails?.deliver_name,
            deliverPhone: orderDetails?.deliver_phone,
            deliverEmail: orderDetails?.deliver_email
        }))
    }, [orderDetails])

    return (
        <Box sx={{
            border: '1px solid #B2B2B2',
            borderRadius: '10px',
            padding: '10px 0'
        }}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '20px',
                        borderBottom: '1px solid #B2B2B2',
                        alignItems: 'center'
                    }}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            Contact Details
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{padding: '20px'}}>
                        <Grid container spacing={2}>
                            <Grid item md={12} xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                            Pickup Contact
                                        </Typography>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <FormControl fullWidth>
                                            <FormLabel required>Name</FormLabel>
                                            <TextField
                                                value={contactInfo?.pickupName}
                                                onInput={handlePickupName}
                                                fullWidth
                                                variant="outlined"
                                                size='small'
                                                disabled={!isUpdate}
                                                error={validationResult?.missingKeys.includes('pickupName')}
                                                helperText={validationResult?.missingKeys.includes('pickupName') && 'Required'}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <FormControl fullWidth>
                                            <FormLabel required>Phone</FormLabel>
                                            <TextField
                                                value={contactInfo?.pickupPhone}
                                                onInput={handlePickupPhone}
                                                fullWidth
                                                variant="outlined"
                                                size='small'
                                                disabled={!isUpdate}
                                                error={validationResult?.missingKeys.includes('pickupPhone') || validationResult?.pickupPhoneError}
                                                helperText={validationResult?.missingKeys.includes('pickupPhone') ? 'Required' : validationResult?.pickupPhoneError && 'Invalid'}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <FormLabel required>Email</FormLabel>
                                            <TextField
                                                value={contactInfo?.pickupEmail}
                                                onInput={handlePickupEmail}
                                                fullWidth
                                                variant="outlined"
                                                size='small'
                                                disabled={!isUpdate}
                                                error={validationResult?.missingKeys.includes('pickupEmail') || validationResult?.pickupEmailError}
                                                helperText={validationResult?.missingKeys.includes('pickupEmail') ? 'Required' : validationResult?.pickupEmailError && 'Invalid'}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                            Delivery Contact
                                        </Typography>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <FormControl fullWidth>
                                            <FormLabel required>Name</FormLabel>
                                            <TextField
                                                value={contactInfo?.deliverName}
                                                onInput={handleDeliverName}
                                                fullWidth
                                                variant="outlined"
                                                size='small'
                                                disabled={!isUpdate}
                                                error={validationResult?.missingKeys.includes('deliverName')}
                                                helperText={validationResult?.missingKeys.includes('deliverName') && 'Required'}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <FormControl fullWidth>
                                            <FormLabel required>Phone</FormLabel>
                                            <TextField
                                                value={contactInfo?.deliverPhone}
                                                onInput={handleDeliverPhone}
                                                fullWidth
                                                variant="outlined"
                                                size='small'
                                                disabled={!isUpdate}
                                                error={validationResult?.missingKeys.includes('deliverPhone') || validationResult?.deliverPhoneError}
                                                helperText={validationResult?.missingKeys.includes('deliverPhone') ? 'Required' : validationResult?.deliverPhoneError && 'Invalid'}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <FormLabel required>Email</FormLabel>
                                            <TextField
                                                value={contactInfo?.deliverEmail}
                                                onInput={handleDeliverEmail}
                                                fullWidth
                                                variant="outlined"
                                                size='small'
                                                disabled={!isUpdate}
                                                error={validationResult?.missingKeys.includes('deliverEmail') || validationResult?.deliverEmailError}
                                                helperText={validationResult?.missingKeys.includes('deliverEmail') ? 'Required' : validationResult?.deliverEmailError && 'Invalid'}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                (orderDetails?.order_sub_type !== 'EXPRESS' && movingOrderToEdit(orderDetails?.status_id, orderDetails?.shipping_status_id)) &&
                                <Grid item xs={12}>
                                    <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: '10px'}}>
                                        {
                                            isUpdate ? <>
                                                    <LoadingButton
                                                        variant='contained'
                                                        sx={{
                                                            backgroundColor: '#1D8B45',
                                                            "&:hover": {
                                                                backgroundColor: '#1D8B45',
                                                                filter: 'brightness(0.9)'
                                                            }
                                                        }}
                                                        onClick={handleConfirm}
                                                        loading={loading}
                                                    >
                                                        <Typography style={{textTransform: 'none'}}>
                                                            Confirm
                                                        </Typography>
                                                    </LoadingButton>
                                                    <Button
                                                        variant='contained'
                                                        sx={{
                                                            backgroundColor: '#F2BE22',
                                                            "&:hover": {
                                                                backgroundColor: '#F2BE22',
                                                                filter: 'brightness(0.9)'
                                                            }
                                                        }}
                                                        onClick={handleCancel}
                                                    >
                                                        <Typography style={{textTransform: 'none'}}>
                                                            Cancel
                                                        </Typography>
                                                    </Button>
                                                </>
                                                :
                                                <Button
                                                    variant='contained'
                                                    sx={{
                                                        backgroundColor: '#1D8B45',
                                                        "&:hover": {
                                                            backgroundColor: '#1D8B45',
                                                            filter: 'brightness(0.9)'
                                                        }
                                                    }}
                                                    onClick={handleUpdate}
                                                >
                                                    <Typography style={{textTransform: 'none'}}>
                                                        Update
                                                    </Typography>
                                                </Button>
                                        }
                                    </Box>
                                </Grid>
                            }
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}