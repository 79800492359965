import {
    LOADING_SHIP_RATE,
    RATING_ADD_ITEM,
    RATING_BOX_LIST_BOX_INFO, RATING_CLEAR_ADDRESS_FROM, RATING_CLEAR_ADDRESS_TO,
    RATING_CLEAR_ITEM,
    RATING_OPEN_ADD_ITEM_FORM,
    RATING_OPEN_BOX_LIST,
    RATING_OPEN_BOX_LIST_ITEM,
    RATING_OPEN_EDIT_ITEM_FORM,
    RATING_REMOVE_ITEM,
    RATING_SHIP_ADDRESS_ERRORS,
    RATING_SHIP_ADDRESS_FROM_DATA,
    RATING_SHIP_ADDRESS_LIST_FROM,
    RATING_SHIP_ADDRESS_LIST_TO, RATING_SHIP_ADDRESS_TO_DATA,
    RATING_SHIP_FROM_COUNTRY_AND_PROVINCE,
    RATING_SHIP_FROM_ERRORS,
    RATING_SHIP_RATE,
    RATING_SHIP_RATE_CLEAR,
    RATING_SHIP_TO_ERRORS,
    RATING_UPDATE_CROSS_BORDER,
    RATING_UPDATE_ITEM,
    RATING_UPDATE_SHIPPING_FROM,
    RATING_UPDATE_SHIPPING_TO,
} from "../actions/types";

const initialState = {
    openItemForm: false,
    itemList: [],
    openItemEdit: {
        status: false,
        id: ''
    },
    shippingFrom: {},
    shippingTo: {},
    shippingCrossBorder: {},
    shippingFromErrors: [],
    shippingToErrors: [],
    shippingAddressErrors: {},
    shipFromCountryAndProvince: null,
    shipRates: [],
    isLoadingShipRate: false,
    isBoxList: false,
    isBoxListItem: false,
    boxListBoxInfo: {},
    isShippingListFrom: false,
    isShippingListTo: false,
    shippingListFromAddress: {},
    shippingListToAddress: {}
}

export const ratingReducer = (state = initialState, action) => {
    switch (action.type) {
        case RATING_OPEN_ADD_ITEM_FORM:
            return {...state, openItemForm: action?.payload}
        case RATING_OPEN_EDIT_ITEM_FORM:
            return {...state, openItemEdit: action?.payload}
        case RATING_ADD_ITEM:
            return {...state, itemList: [...state.itemList, action.payload]}
        case RATING_REMOVE_ITEM:
            const itemIdToFind = action.payload;
            const filteredItems = state.itemList.filter(item => item.itemId !== itemIdToFind);
            return {...state, itemList: filteredItems}
        case RATING_UPDATE_ITEM:
            console.log('item list', state.itemList);
            console.log('update item', action.payload);
            const updatedItem = action.payload;
            const updatedItemList = state.itemList.map(item => {
                if (item.itemId === updatedItem.itemId) {
                    return {...updatedItem};
                }
                return item;
            })
            return {...state, itemList: updatedItemList}
        case RATING_CLEAR_ITEM:
            return {...state, itemList: []}
        case RATING_UPDATE_SHIPPING_FROM:
            return {...state, shippingFrom: action?.payload}
        case RATING_UPDATE_SHIPPING_TO:
            return {...state, shippingTo: action?.payload}
        case RATING_UPDATE_CROSS_BORDER:
            return {...state, shippingCrossBorder: action?.payload}
        case RATING_SHIP_FROM_ERRORS:
            return {...state, shippingFromErrors: action?.payload}
        case RATING_SHIP_TO_ERRORS:
            return {...state, shippingToErrors: action?.payload}
        case RATING_SHIP_ADDRESS_ERRORS:
            const {errorType, value} = action?.payload;
            return {...state, shippingAddressErrors: {...state.shippingAddressErrors, [errorType]: value}}
        case RATING_SHIP_FROM_COUNTRY_AND_PROVINCE:
            return {...state, shipFromCountryAndProvince: action?.payload}
        case RATING_SHIP_RATE:
            console.log('rate', action?.payload);
            const existingIndex = state.shipRates.findIndex(item => item.service === action.payload.service);
            if (existingIndex === -1) {
                // rateData does not exist, add it to the array
                return {...state, shipRates: [...state.shipRates, action?.payload]}
            } else {
                // rateData exists, update it in the array
                const updatedItems = [...state.shipRates];
                updatedItems[existingIndex] = action?.payload;
                return {...state, shipRates: updatedItems}
            }
        case RATING_SHIP_RATE_CLEAR:
            return {...state, shipRates: []}
        case  LOADING_SHIP_RATE:
            return {...state, isLoadingShipRate: action?.payload}
        case RATING_OPEN_BOX_LIST:
            return {...state, isBoxList: action?.payload}
        case RATING_OPEN_BOX_LIST_ITEM:
            return {...state, isBoxListItem: action?.payload}
        case RATING_BOX_LIST_BOX_INFO:
            return {...state, boxListBoxInfo: action?.payload}
        case RATING_SHIP_ADDRESS_LIST_FROM:
            return {...state, isShippingListFrom: action?.payload}
        case RATING_SHIP_ADDRESS_LIST_TO:
            return {...state, isShippingListTo: action?.payload}
        case RATING_SHIP_ADDRESS_FROM_DATA:
            return {...state, shippingListFromAddress: action?.payload}
        case RATING_SHIP_ADDRESS_TO_DATA:
            return {...state, shippingListToAddress: action?.payload}
        case RATING_CLEAR_ADDRESS_FROM:
            return {...state, shippingListFromAddress: {}}
        case RATING_CLEAR_ADDRESS_TO:
            return {...state, shippingListToAddress: {}}
        default:
            return state
    }
}