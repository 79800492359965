import React, {useEffect} from "react";
import { string, func, oneOfType, number, bool } from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  TextField,
  Select,
  MenuItem,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Autocomplete from "@material-ui/lab/Autocomplete";

const CustomInput = React.memo(
  ({
    disabled,
    value,
    onChange,
    label,
    placeholderValue,
    // error = false,
    // defaultValue,
    type,
    options = [],
    checkError = false,
    isNonNegativeNumber,
    multiline,
    minLength,
    maxLength,
    // rows,
  }) => {
    const classes = useStyles();

    const [values, setValues] = React.useState({
      showPassword: false,
    });

    const handleClickShowPassword = () => {
      setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    const classTypes = (_checkError, _value, _classes) => {
      let classes = "";
      classes += `${_classes.textfield}`;
      if (_checkError && !_value) classes += ` ${_classes.errorText}`;
      if (_checkError && _value && _value.length < minLength) {
        classes += ` ${_classes.errorText}`;
      }
      if (_checkError && _value && _value.length > maxLength) {
        classes += ` ${_classes.errorText}`;
      }
      return classes;
    };

    const classTypesAuto = (_error, _classes) => {
      let classes = "";
      classes += `${_classes.textfield}`;
      if (_error) classes += ` ${_classes.errorText}`;
      return classes;
    };

    useEffect(() => {
      console.log(value);

    }, [value])

    let inputBody = (
      <TextField
        className={classTypes(checkError, value, classes)}
        value={value || ""}
        disabled={disabled}
        onChange={onChange}
        placeholder={placeholderValue || ""}
        InputProps={{ disableUnderline: true }}
        variant="outlined"
        classes={{ root: classes.root }}
        multiline={multiline}
      />
    );

    if (type === "number") {
      inputBody = (
          <TextField
              className={classTypes(checkError, value, classes)}
              value={value || ""}
              disabled={disabled}
              onChange={onChange}
              placeholder={placeholderValue || ""}
              InputProps={{ disableUnderline: true }}
              variant="outlined"
              type="number"
              inputProps={{ min: isNonNegativeNumber ? 0 : null }}
              classes={{ root: classes.root }}
          />
      );

    }

    if (type === "date") {
      inputBody = (
        <TextField
          className={classes.textfield}
          type="date"
          value={value || ""}
          disabled={disabled}
          onChange={onChange}
          placeholder={placeholderValue || ""}
          InputProps={{ disableUnderline: true }}
          variant="outlined"
          classes={{ root: classes.root }}
        />
      );
    }

    if (type === "select") {
      inputBody = (
        <Select
          value={value || ""}
          onChange={onChange}
          className={classes.textfield}
          variant="outlined"
          disableUnderline={true}
          classes={{ root: classes.selectRoot }}
          MenuProps={{ classes: { paper: classes.menuPaper } }}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      );
    }

    if (type === "inputWithSelect") {
      inputBody = (
          <Autocomplete
              // defaultValue={defaultValue || ''}
              onChange={(event, newValue) => {
                onChange(newValue);
              }}
              disabled={disabled}
              value={value}
              getOptionSelected={(option, value) => option === value}
              options={options}
              // renderOption={(option) => {
              //   if (Boolean(option))
              //     return <Typography noWrap>hah {option}</Typography>;
              //   else return;
              // }}
              // renderInput={params => <TextField {...params} label={label} InputProps={{ ...params.InputProps, disableUnderline: true }} />}
              renderInput={(params) => {
                params.inputProps.autoComplete = "new-password";
                return (
                    <TextField
                        autoComplete="off"
                        {...params}
                        label={label}
                        className={classTypesAuto(checkError, classes)}
                        style={{ backgroundColor: "inherit" }}
                        InputProps={{ ...params.InputProps, autoComplete: "off" }}
                    />
                );
              }}
          />
      );
    }

    if (type === "password") {
      inputBody = (
        <TextField
          className={classes.textfield}
          value={value || ""}
          disabled={disabled}
          onChange={onChange}
          placeholder={placeholderValue || ""}
          type={values.showPassword ? "text" : "password"}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          variant="outlined"
          classes={{ root: classes.root }}
        />
      );
    }

    if (type === "inputWithSelect") {
      return (
          <Box textAlign="start" fontSize="0.8rem" pb={1} pl={1}>
            {inputBody}
          </Box>
      )

    }

    return (
      <Box>
        <Box textAlign="start" fontSize="0.8rem" pb={1} pl={1}>
          {label}
        </Box>
        {inputBody}
      </Box>
    );
  }
);

const useStyles = makeStyles((theme) => ({
  textfield: {
    width: "100%",
    backgroundColor: "white",
    borderRadius: "5px",
    // boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
    // paddingTop: "0.4vw",
    // paddingBottom: "0.4vw",
  },
  lineTextfield: {
    width: "50%",
    paddingRight: theme.spacing(1),
  },
  root: {
    "& input": {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  selectRoot: {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  disabled: {
    backgroundColor: grey[300],
    border: `1px solid ${grey[300]}`,
  },
  errorText: {
    border: "1px solid red",
  },
  menuPaper: {
    maxWidth: 150
  },
}));

CustomInput.propTypes = {
  onChange: func.isRequired,
  value: oneOfType([string, number]),
  //   error: object.isRequired,
  //   multiline: bool,
  //   type: string,
  label: string,
  disabled: bool,
  //   rows: number,
  placeholderValue: string,
  //   defaultValue: string,
};

export default CustomInput;
