import Box from "@mui/material/Box";
import {Button, Container, Modal, Snackbar} from "@mui/material";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import {MovingOrderDetailsWeChatPay} from "../MovingOrderDetails/MovingOrderDetailsWeChatPay";
import * as React from "react";
import {Fragment, useEffect, useState} from "react";
import {getAccessToken} from "../../Utils/doToken";
import {useHistory, useParams} from "react-router-dom";
import {LIST_CREDIT_CARD_API, NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {MovingOrderDashboardWeChatPay} from "./MovingOrderDashboardWeChatPay";
import {MovingOrderDashboardAliPay} from "./MovingOrderDashboardAliPay";
import {MovingOrderDashboardETransfer} from "./MovingOrderDashboardETransfer";
import {useSelector} from "react-redux";
import AddCreditCardSection from "../ShippingOrderDetails/AddCreditCardSection";
import {MovingOrderDashboardCreditCard} from "./MovingOrderDashboardCreditCard";
import {useInterval} from "usehooks-ts";
import {MovingOrderDashboardApprovedPrice} from "./MovingOrderDashboardApprovedPrice";
import Divider from "@mui/material/Divider";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {MovingOrderDashboardPayPartner} from "./MovingOrderDashboardPayPartner";
import {MovingOrderDashboardPayUUCargo} from "./MovingOrderDashboardPayUUCargo";
import LoadingButton from "@mui/lab/LoadingButton";
import {MovingOrderDashboardMakePayment} from "./MovingOrderDashboardMakePayment";
import MuiAlert from "@mui/material/Alert";

const styles = {
    MovingOrderDashboardPaymentRoot: {

        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 1200,
        border: '2px solid #000',
        backgroundColor: '#FFFFFF',
        boxShadow: 24,
        p: 4,
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 100px)'
    }
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const MovingOrderDashboardPayment = () => {

    const accessToken = getAccessToken("access_token");
    const history = useHistory();
    const {orderId} = useParams();

    const [orderDetails, setOrderDetails] = useState();
    const [backendOrderStatus, setBackendOrderStatus] = useState(100);
    const [delay] = useState(2000);

    const [payParty, setPayParty] = useState('uucargo');
    const [driverAmount, setDriverAmount] = useState(orderDetails?.task_unpaid_amount);
    const [driverMethod, setDriverMethod] = useState('cash');
    const [method, setMethod] = useState('email');
    const [referenceNumber, setReferenceNumber] = useState();
    const [creditCard, setCreditCard] = useState(null);
    const [paidPayment, setPaidPayment] = useState([]);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [loadingSend, setLoadingSend] = useState(false);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const cbHandleDriverAmount = (value) => {
        setDriverAmount(value);
    }

    const cbHandleDriverMethod = (value) => {
        setDriverMethod(value);
    }

    const cbHandleMethod = (value) => {
        setMethod(value);
    }

    const cbHandleReferenceNumber = (value) => {
        setReferenceNumber(value);
    }

    const cbGetCreditCard = (cardId) => {
        setCreditCard(cardId);
    }

    const getMovingOrderDetails = async () => {
        let requestURL = `${NODE_ROUTE_URI}/movingService/getMovingOrderDashBoardDetails`;

        try {
            const result = await axios({
                method: 'get',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                url: requestURL,
                params: {
                    backendOrderId: orderId
                }
            })

            console.log('order info', result);
            setOrderDetails(result.data);
            const paidPayment = result?.data?.backendPayments?.filter(payment => payment?.payment_status === "CAPTURED_SUCCEEDED" || payment?.payment_status === "UNDER_REVIEW");
            setPaidPayment(paidPayment);

        } catch (e) {
            console.log(e.response);
        }
    }

    const getMovingOrderStatus = async () => {
        let requestURL = `${NODE_ROUTE_URI}/looseItem/getBackendOrderStatusByOrderId/${orderId}`;
        try {
            const result = await axios({
                method: 'get',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                url: requestURL
            })

            const ordersStatus = result.data.orderStatus;
            console.log('order status', ordersStatus);
            setBackendOrderStatus(ordersStatus)

        } catch (e) {
            console.log(e.response);
        }
    }

    const handleSelectPayParty = (party) => {
        setPayParty(party)
    }

    const handleSendAmount = () => {
        sendAmount();
    }

    const sendAmount = async () => {
        let requestURL = `${NODE_ROUTE_URI}/movingService/payMovingRemainingAmountToPartner`;
        setLoadingSend(true);
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: {
                    backendOrderId: orderId,
                    payType: driverMethod,
                    amount: driverAmount
                }
            })
            setErrorMessage('');
            setSuccessMessage('Successfully pay order');
            handleSuccessMessage();
            getMovingOrderDetails();
        } catch (e) {
            console.log('error', e.response);
            handleErrorMessage();
            setErrorMessage(`Fail to pay order.`);
        } finally {
            setLoadingSend(false);
        }
    }

    useEffect(() => {
        getMovingOrderDetails();
    }, [])

    useEffect(() => {
        setDriverAmount(orderDetails?.task_unpaid_amount);
    }, [orderDetails]);

    useEffect(() => {
        backendOrderStatus === 1 && getMovingOrderDetails();
    }, [backendOrderStatus])

    useInterval(
        () => {
            if (orderId) {
                getMovingOrderStatus();
            }
        },
        backendOrderStatus === 100 ? delay : null,
    )

    console.log('[MovingOrderDashboardPayment] driver amount', driverAmount);
    console.log('[MovingOrderDashboardPayment] driver method', driverMethod);
    console.log('[MovingOrderDashboardPayment] method', method);
    console.log('[MovingOrderDashboardPayment] reference number', referenceNumber);

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                    <Box>
                        <Button
                            variant='contained'
                            sx={{
                                backgroundColor: '#454545',
                                "&:hover": {
                                    backgroundColor: '#454545',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                            onClick={() => history.push(`/transportation/orderDashboard/${orderId}`)}
                        >
                            <Typography style={{textTransform: 'none'}}>
                                Back To Order Dashboard
                            </Typography>
                        </Button>
                    </Box>
                </Grid>
                {
                    orderDetails?.task_unpaid_amount > 0 &&
                    <Grid item md={7} xs={12}>
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={6} sx={{position: 'relative'}}>
                                <Box sx={{
                                    position: 'relative',
                                }}>
                                    {
                                        payParty === 'uucargo' &&
                                        <CheckCircleIcon
                                            style={{
                                                position: 'absolute',
                                                right: '0%',
                                                top: '-20%',
                                                zIndex: 1,
                                                color: '#1D8B45',
                                                backgroundColor: '#ffffff',
                                                borderRadius: '50%'
                                            }}
                                        />
                                    }
                                    <Button
                                        sx={{
                                            border: payParty === 'uucargo' ? '2px solid #1D8B45' : '2px solid #A6A6A6',
                                            width: '100%',
                                            height: '100%',
                                            padding: '15px 0',
                                            overflow: 'visible',
                                            position: 'relative',
                                            borderRadius: '5px',
                                        }}
                                        onClick={() => handleSelectPayParty('uucargo')}
                                    >
                                        <Typography sx={{color: '#000000', fontSize: '18px', textTransform: 'none'}}>
                                            Pay To UUCargo
                                        </Typography>
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item md={6} xs={6} sx={{position: 'relative'}}>
                                <Box sx={{
                                    position: 'relative'
                                }}>
                                    {
                                        payParty === 'partner' &&
                                        <CheckCircleIcon
                                            style={{
                                                position: 'absolute',
                                                right: '0%',
                                                top: '-20%',
                                                zIndex: 1,
                                                color: '#1D8B45',
                                                backgroundColor: '#ffffff',
                                                borderRadius: '50%'
                                            }}
                                        />
                                    }
                                    <Button
                                        sx={{
                                            border: payParty === 'partner' ? '2px solid #1D8B45' : '2px solid #A6A6A6',
                                            width: '100%',
                                            height: '100%',
                                            padding: '15px 0',
                                            overflow: 'visible',
                                            position: 'relative',
                                            borderRadius: '5px',
                                        }}
                                        onClick={() => handleSelectPayParty('partner')}
                                    >
                                        <Typography sx={{color: '#000000', fontSize: '18px', textTransform: 'none'}}>
                                            Pay To Driver
                                        </Typography>
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    payParty === 'uucargo' ?
                                        <MovingOrderDashboardPayUUCargo
                                            orderDetails={orderDetails}
                                            cbHandleMethod={cbHandleMethod}
                                            cbHandleReferenceNumber={cbHandleReferenceNumber}
                                            cbGetCreditCard={cbGetCreditCard}
                                        /> :
                                        <MovingOrderDashboardPayPartner
                                            orderDetails={orderDetails}
                                            cbHandleDriverAmount={cbHandleDriverAmount}
                                            cbHandleDriverMethod={cbHandleDriverMethod}
                                        />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                }
                <Grid item md={orderDetails?.task_unpaid_amount === 0 ? 12 : 5} xs={12}>
                    <Box sx={{
                        border: '1px solid #B2B2B2',
                        borderRadius: '10px',
                        padding: '10px 0'
                    }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    padding: '20px',
                                    borderBottom: '1px solid #B2B2B2',
                                    alignItems: 'center'
                                }}>
                                    <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                                        Cost Details
                                    </Typography>
                                </Box>
                            </Grid>
                            {
                                +orderDetails?.orderPrice?.freight_charge > 0 &&
                                <>
                                    <Grid item xs={7}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            height: '100%',
                                            justifyContent: 'flex-start',
                                            padding: '0 20px'
                                        }}>
                                            <Typography>
                                                Freight Cost
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Box sx={{
                                            padding: '0 20px'
                                        }}>
                                            <Typography style={{textAlign: 'right'}}>
                                                $ {(+orderDetails?.orderPrice?.freight_charge)?.toFixed(2)}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </>
                            }
                            {
                                +orderDetails?.orderPrice?.moving_pickup_charge > 0 &&
                                <>
                                    <Grid item xs={7}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            height: '100%',
                                            justifyContent: 'flex-start',
                                            padding: '0 20px'
                                        }}>
                                            <Typography>
                                                Pickup Cost
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Box sx={{
                                            padding: '0 20px'
                                        }}>
                                            <Typography style={{textAlign: 'right'}}>
                                                $ {(+orderDetails?.orderPrice?.moving_pickup_charge)?.toFixed(2)}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </>
                            }
                            {
                                +orderDetails?.orderPrice?.moving_deliver_charge > 0 &&
                                <>
                                    <Grid item xs={7}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            height: '100%',
                                            justifyContent: 'flex-start',
                                            padding: '0 20px'
                                        }}>
                                            <Typography>
                                                {orderDetails?.deliver_addr_type === 'SELF' ? 'Operation Cost' : 'Delivery Cost'}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Box sx={{
                                            padding: '0 20px'
                                        }}>
                                            <Typography style={{textAlign: 'right'}}>
                                                $ {(+orderDetails?.orderPrice?.moving_deliver_charge)?.toFixed(2)}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </>
                            }
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                            <Grid item xs={7}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '100%',
                                    justifyContent: 'flex-start',
                                    padding: '0 20px'
                                }}>
                                    <Typography>
                                        Shipping Cost
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={5}>
                                <Box sx={{
                                    padding: '0 20px'
                                }}>
                                    <Typography style={{textAlign: 'right'}}>
                                        $ {(+orderDetails?.orderPrice?.shipping_fee)?.toFixed(2)}
                                    </Typography>
                                </Box>
                            </Grid>
                            {
                                (+orderDetails?.orderPrice?.moving_labour_charge) > 0 &&
                                <>
                                    <Grid item xs={7}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            height: '100%',
                                            justifyContent: 'flex-start',
                                            padding: '0 20px'
                                        }}>
                                            <Typography>
                                                Additional Service Cost
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Box sx={{
                                            padding: '0 20px'
                                        }}>
                                            <Typography style={{textAlign: 'right'}}>
                                                $ {(+orderDetails?.orderPrice?.moving_labour_charge).toFixed(2)}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </>
                            }
                            <Grid item xs={7}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '100%',
                                    justifyContent: 'flex-start',
                                    padding: '0 20px'
                                }}>
                                    <Typography>
                                        Tax
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={5}>
                                <Box sx={{
                                    padding: '0 20px'
                                }}>
                                    <Typography style={{textAlign: 'right'}}>
                                        $ {(+orderDetails?.orderPrice?.taxes)?.toFixed(2)}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                            <Grid item xs={7}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '100%',
                                    justifyContent: 'flex-start',
                                    padding: '0 20px'
                                }}>
                                    <Typography>
                                        Total
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={5}>
                                <Box sx={{
                                    padding: '0 20px'
                                }}>
                                    <Typography style={{textAlign: 'right'}}>
                                        $ {(+orderDetails?.orderPrice?.final_total)?.toFixed(2)}
                                    </Typography>
                                </Box>
                            </Grid>
                            {
                                (+orderDetails?.adjustAmount) !== 0 &&
                                <>
                                    <Grid item xs={7}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            height: '100%',
                                            justifyContent: 'flex-start',
                                            padding: '0 20px'
                                        }}>
                                            <Typography>
                                                Adjustment Amount
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Box sx={{
                                            padding: '0 20px'
                                        }}>
                                            <Typography style={{textAlign: 'right'}}>
                                                $ {(+orderDetails?.adjustAmount)?.toFixed(2)}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            height: '100%',
                                            justifyContent: 'flex-start',
                                            padding: '0 20px'
                                        }}>
                                            <Typography>
                                                Cost After Adjustment
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Box sx={{
                                            padding: '0 20px'
                                        }}>
                                            <Typography style={{textAlign: 'right'}}>
                                                $ {(+orderDetails?.price)?.toFixed(2)}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </>
                            }
                            {
                                paidPayment?.map((payment, index) => (
                                    <Fragment key={index}>
                                        <Grid item xs={7}>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                height: '100%',
                                                justifyContent: 'flex-start',
                                                padding: '0 20px'
                                            }}>
                                                <Typography>
                                                    {payment?.remark_notes === "Down_Payment" ? "Prepaid Amount" : "Payment"}
                                                    <span
                                                        style={{
                                                            textAlign: 'left',
                                                            fontWeight: '600',
                                                            fontSize: '12px'
                                                        }}>
                                                        {payment?.payment_status === 'UNDER_REVIEW' && ' Pending'}
                                                    </span>
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Box sx={{
                                                padding: '0 20px'
                                            }}>
                                                <Typography style={{textAlign: 'right'}}>
                                                    - $ {payment?.amount.toFixed(2)}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Fragment>
                                ))
                            }
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                            <Grid item xs={7}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '100%',
                                    justifyContent: 'flex-start',
                                    padding: '0 20px'
                                }}>
                                    <Typography>
                                        Amount To Be Paid
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={5}>
                                <Box sx={{
                                    padding: '0 20px'
                                }}>
                                    <Typography style={{textAlign: 'right'}}>
                                        $ {orderDetails?.task_unpaid_amount?.toFixed(2)}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                {
                    orderDetails?.task_unpaid_amount > 0 &&
                    (
                        payParty === 'uucargo' ?
                            <MovingOrderDashboardMakePayment method={method} referenceNumber={referenceNumber}
                                                             creditCard={creditCard}/> :
                            <Grid item md={12} xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}>
                                    <LoadingButton
                                        variant='contained'
                                        sx={{
                                            backgroundColor: '#1D8B45',
                                            "&:hover": {
                                                backgroundColor: '#1D8B45',
                                                filter: 'brightness(0.9)'
                                            }
                                        }}
                                        onClick={handleSendAmount}
                                        loading={loadingSend}
                                    >
                                        <Typography sx={{textTransform: 'none'}}>
                                            Send
                                        </Typography>
                                    </LoadingButton>
                                </Box>
                            </Grid>
                    )
                }
                {
                    orderDetails?.status_id === 1 ?
                        <Grid item md={12} xs={12}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                <Typography sx={{
                                    color: '#1D8B45',
                                    fontWeight: '600',
                                    fontSize: '18px'
                                }}>
                                    Payment for the order has been successfully completed.
                                </Typography>
                            </Box>
                        </Grid>
                        :
                        orderDetails?.task_unpaid_amount === 0 &&
                        <Grid item md={12} xs={12}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                <Typography sx={{
                                    color: '#1D8B45',
                                    fontWeight: '600',
                                    fontSize: '18px'
                                }}>
                                    The payment for the order is currently under review.
                                </Typography>
                            </Box>
                        </Grid>
                }
            </Grid>
        </Container>

    )

}