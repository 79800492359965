import List from "@mui/material/List";
import Box from "@mui/material/Box";
import {styled, useTheme} from "@mui/material/styles";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import {useEffect, useState} from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core";
import {ReactComponent as DashboardLogo} from "../../images/homepageIcon/Dashboard.svg";
import {ReactComponent as PackageLogo} from "../../images/homepageIcon/Package.svg";
import {ReactComponent as MovingLogo} from "../../images/homepageIcon/MovingService.svg";
import {ReactComponent as PalletLogo} from "../../images/homepageIcon/Pallet.svg";
import {ReactComponent as OceanLogo} from "../../images/homepageIcon/OceanFreight.svg";
import {ReactComponent as BrokerageLogo} from "../../images/homepageIcon/CustomBrokerage.svg";
import {ReactComponent as BillingLogo} from "../../images/homepageIcon/Billing.svg";
import {ReactComponent as SettingsLogo} from "../../images/homepageIcon/Settings.svg";
import {ReactComponent as LogoutLogo} from "../../images/homepageIcon/Logout.svg";
import {Collapse, IconButton} from "@mui/material";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {deleteToken, getAccessToken} from "../../Utils/doToken";
import {deleteUserInfo, updateUserInfo} from "../../slices";
import intl from "react-intl-universal";
import {GET_PARTNER_INFO_API} from "../../Utils/apiUrl";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const useStyles = makeStyles((theme) => ({
    position: {
        display: "flex",
        gap: '20px',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    listItemText: {
        fontSize: '10px', // You can adjust the font size here
    },
}));

const CustomNav = styled(List)({
    '& .MuiListItemButton-root': {
        padding: '4px 20px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    '& .MuiListItemIcon-root': {
        minWidth: 0,
        marginRight: 16,
    },
    '& .MuiSvgIcon-root': {
        fontSize: 20,
    },
});

export const SideBar = ({cbHandleOpenMenu}) => {

    const classes = useStyles();
    const ownerRole = useSelector(state => state?.user?.ownerRole);

    const isMobile = useMediaQuery('(max-width: 480px)');

    const [openTab, setOpenTab] = useState('dashboard');
    const [partnerInfo, setPartnerInfo] = useState(null);

    const history = useHistory();

    const dispatch = useDispatch();

    const signOut = (e) => {
        const storedToken = getAccessToken("access_token");
        e.preventDefault();
        try {
            axios.get(`https://admin.uucargo.ca/api/logout`, {
                headers: {Authorization: `Bearer ${storedToken}`},
            });
            //setHide(true)
        } finally {
            deleteToken("access_token");
            dispatch(deleteUserInfo());
            dispatch(updateUserInfo({field: "login", value: false}));
            dispatch(updateUserInfo({field: "signIn", value: false}));
            history.push("/");
        }
    };

    const handleDashboardClick = () => {
        setOpenTab('dashboard');
    }

    const handleShippingPackageClick = () => {
        openTab === 'package' ? setOpenTab(null) : setOpenTab('package');
    };

    const handleShippingLTLClick = () => {
        openTab === 'ltl' ? setOpenTab(null) : setOpenTab('ltl');
    }

    const handleTransportationClick = () => {
        openTab === 'transportation' ? setOpenTab(null) : setOpenTab('transportation');
    };

    const handleBillingCenter = () => {
        openTab === 'bill' ? setOpenTab(null) : setOpenTab('bill');
    }

    const handleSetting = () => {
        openTab === 'settings' ? setOpenTab(null) : setOpenTab('settings');
    }

    const handleOceanShippingClick = () => {
        openTab === 'ocean' ? setOpenTab(null) : setOpenTab('ocean');
    };

    const handleCustomsBrokerage = () => {
        openTab === 'brokerage' ? setOpenTab(null) : setOpenTab('brokerage');
    };

    useEffect(() => {
        if (ownerRole === 3) {
            (async () => {
                    const storedToken = getAccessToken("access_token");
                    try {
                        const result = await axios({
                            method: 'get',
                            url: GET_PARTNER_INFO_API,
                            headers: {
                                Authorization: `Bearer ${storedToken}`,
                            }
                        });
                        setPartnerInfo(result?.data);
                    } catch (e) {
                        console.log(e);
                    }
                }
            )();
        }
    }, [ownerRole])

    console.log('[SideBar] partner info', partnerInfo);

    return (
        <CustomNav sx={{
            maxWidth: 240,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%'
        }}
                   disablePadding>
            <Box>
                <ListItemButton
                    sx={{padding: '0', display: 'flex', justifyContent: 'space-between'}}
                    onClick={() => {
                        history.push(`${ownerRole === 1 ? '/' : '/business/'}home`);
                        handleDashboardClick();
                        isMobile && cbHandleOpenMenu();
                    }}
                >
                    <ListItemIcon className={classes.position}>
                        <DashboardLogo width="20" height="20" fill={openTab === 'dashboard' ? '#1D8B45' : '#454545'}/>
                        <ListItemText primary={<Typography variant="body2" style={{
                            color: openTab === 'dashboard' ? '#1D8B45' : '#454545',
                            fontSize: '14px'
                        }}>{intl.get('SIDEBAR.DASHBOARD')}</Typography>}/>
                    </ListItemIcon>
                </ListItemButton>
                <ListItemButton
                    onClick={handleShippingPackageClick}
                    sx={{padding: 0, display: 'flex', justifyContent: 'space-between'}}
                >
                    <ListItemIcon className={classes.position}>
                        <PackageLogo width="20" height="20" fill={openTab === 'package' ? '#1D8B45' : '#454545'}/>
                        <ListItemText primary={<Typography variant="body2" style={{
                            color: openTab === 'package' ? '#1D8B45' : '#454545',
                            fontSize: '14px'
                        }}>{intl.get('SIDEBAR.PACKAGE.PACKAGE')}</Typography>}/>
                    </ListItemIcon>
                    {openTab === 'package' ? <ExpandLess/> : <ExpandMore/>}
                </ListItemButton>
                <Collapse in={openTab === 'package' ? true : false} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton
                            sx={{padding: 0}}
                            onClick={() => {
                                history.push(`${ownerRole === 1 ? '/' : '/business/'}shipping-package/quick-quote`);
                                isMobile && cbHandleOpenMenu();
                            }}
                        >
                            <ListItemText primary={<Typography variant="body2" style={{
                                color: '#454545',
                                fontSize: '14px',
                                paddingLeft: '40px'
                            }}>{intl.get('SIDEBAR.PACKAGE.QUICK_QUOTE')}</Typography>}/>
                        </ListItemButton>
                        <ListItemButton
                            sx={{padding: 0}}
                            onClick={() => {
                                history.push(`${ownerRole === 1 ? '/' : '/business/'}shipping-package/create-label`);
                                isMobile && cbHandleOpenMenu();
                            }}
                        >
                            <ListItemText primary={<Typography variant="body2" style={{
                                color: '#454545',
                                fontSize: '14px',
                                paddingLeft: '40px'
                            }}>{intl.get('SIDEBAR.PACKAGE.CREATE_LABEL')}</Typography>}/>
                        </ListItemButton>
                        {
                            ownerRole === 3 &&
                            <>
                                {/*<ListItemButton*/}
                                {/*    sx={{padding: 0}}*/}
                                {/*    onClick={() => {*/}
                                {/*        history.push("/business/shipping-package/international-shipment");*/}
                                {/*        isMobile && cbHandleOpenMenu();*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    <ListItemText primary={<Typography variant="body2" style={{*/}
                                {/*        color: '#454545',*/}
                                {/*        fontSize: '14px',*/}
                                {/*        paddingLeft: '40px'*/}
                                {/*    }}>{intl.get('SIDEBAR.PACKAGE.INTERNATIONAL_SHIPMENT')}</Typography>}/>*/}
                                {/*</ListItemButton>*/}
                                <ListItemButton
                                    sx={{padding: 0}}
                                    onClick={() => {
                                        history.push("/business/shipping-package/import-shipment");
                                        isMobile && cbHandleOpenMenu();
                                    }}
                                >
                                    <ListItemText primary={<Typography variant="body2" style={{
                                        color: '#454545',
                                        fontSize: '14px',
                                        paddingLeft: '40px'
                                    }}>{intl.get('SIDEBAR.PACKAGE.UPLOAD_EXCEL')}</Typography>}/>
                                </ListItemButton>
                                <ListItemButton
                                    sx={{padding: 0}}
                                    onClick={() => {
                                        history.push("/business/loose-item/3rd-party/shipping/searchTransactions");
                                        isMobile && cbHandleOpenMenu();
                                    }}
                                >
                                    <ListItemText primary={<Typography variant="body2" style={{
                                        color: '#454545',
                                        fontSize: '14px',
                                        paddingLeft: '40px'
                                    }}>{intl.get('SIDEBAR.PACKAGE.SEARCH_TRACKING')}</Typography>}/>
                                </ListItemButton>
                                <ListItemButton
                                    sx={{padding: 0}}
                                    onClick={() => {
                                        history.push("/business/package/order-list");
                                        isMobile && cbHandleOpenMenu();
                                    }}
                                >
                                    <ListItemText primary={<Typography variant="body2" style={{
                                        color: '#454545',
                                        fontSize: '14px',
                                        paddingLeft: '40px'
                                    }}>{intl.get('SIDEBAR.PACKAGE.SHIPPING_ORDERS')}</Typography>}/>
                                </ListItemButton>
                                <ListItemButton
                                    sx={{padding: 0}}
                                    onClick={() => {
                                        history.push("/business/loose-item/3rd-party/shipping/exportsTransactions");
                                        isMobile && cbHandleOpenMenu();
                                    }}
                                >
                                    <ListItemText primary={<Typography variant="body2" style={{
                                        color: '#454545',
                                        fontSize: '14px',
                                        paddingLeft: '40px'
                                    }}>{intl.get('SIDEBAR.PACKAGE.EXPORT_TRANSACTIONS')}</Typography>}/>
                                </ListItemButton>
                            </>
                        }
                        <ListItemButton
                            sx={{padding: 0}}
                            onClick={() => {
                                history.push(`${ownerRole === 1 ? '/' : '/business/'}schedulepickup`);
                                isMobile && cbHandleOpenMenu();
                            }}
                        >
                            <ListItemText primary={<Typography variant="body2" style={{
                                color: '#454545',
                                fontSize: '14px',
                                paddingLeft: '40px'
                            }}>{intl.get('SIDEBAR.PACKAGE.SCHEDULE_PICKUP')}</Typography>}/>
                        </ListItemButton>
                        <ListItemButton
                            sx={{padding: 0}}
                            onClick={() => {
                                history.push(`${ownerRole === 1 ? '/' : '/business/'}MyScheduledPickups`);
                                isMobile && cbHandleOpenMenu();
                            }}
                        >
                            <ListItemText primary={<Typography variant="body2" style={{
                                color: '#454545',
                                fontSize: '14px',
                                paddingLeft: '40px'
                            }}>{intl.get('SIDEBAR.PACKAGE.SCHEDULE_LIST')}</Typography>}/>
                        </ListItemButton>
                        <ListItemButton
                            sx={{padding: 0}}
                            onClick={() => {
                                history.push(`${ownerRole === 1 ? '/' : '/business/'}tracking`);
                                isMobile && cbHandleOpenMenu();
                            }}
                        >
                            <ListItemText primary={<Typography variant="body2" style={{
                                color: '#454545',
                                fontSize: '14px',
                                paddingLeft: '40px'
                            }}>{intl.get('SIDEBAR.PACKAGE.TRACKING_PACKAGE')}</Typography>}/>
                        </ListItemButton>
                    </List>
                </Collapse>
                {
                    partnerInfo?.ltl_rating_status === 1 &&
                    <>
                        <ListItemButton
                            sx={{padding: 0, display: 'flex', justifyContent: 'space-between'}}
                            onClick={handleShippingLTLClick}>
                            <ListItemIcon className={classes.position}>
                                <PalletLogo width="20" height="20" fill={openTab === 'ltl' ? '#1D8B45' : '#454545'}/>
                                <ListItemText primary={<Typography variant="body2" style={{
                                    color: openTab === 'ltl' ? '#1D8B45' : '#454545',
                                    fontSize: '14px',
                                }}>{intl.get('SIDEBAR.LTL.LTL')}</Typography>}/>
                            </ListItemIcon>
                            {openTab === 'ltl' ? <ExpandLess/> : <ExpandMore/>}
                        </ListItemButton>
                        <Collapse in={openTab === 'ltl' ? true : false} timeout='auto' unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton
                                    sx={{padding: 0}}
                                    onClick={() => {
                                        // dispatch(updateShippingLTLQuickQuotePlaceOrderValidate(null));
                                        // dispatch(selectShippingLTLQuickQuoteRate(null));
                                        history.push(`${ownerRole === 1 ? '/' : '/business/'}shipping-ltl/quick-quote`);
                                        isMobile && cbHandleOpenMenu();
                                    }}
                                >
                                    <ListItemText primary={<Typography variant="body2" style={{
                                        color: '#454545',
                                        fontSize: '14px',
                                        paddingLeft: '40px'
                                    }}>{intl.get('SIDEBAR.LTL.QUICK_QUOTE')}</Typography>}/>
                                </ListItemButton>
                                <ListItemButton
                                    sx={{padding: 0}}
                                    onClick={() => {
                                        history.push(`${ownerRole === 1 ? '/' : '/business/'}shipping-ltl/create-label`);
                                        isMobile && cbHandleOpenMenu();
                                    }}
                                >
                                    <ListItemText primary={<Typography variant="body2" style={{
                                        color: '#454545',
                                        fontSize: '14px',
                                        paddingLeft: '40px'
                                    }}>{intl.get('SIDEBAR.LTL.CREATE_LABEL')}</Typography>}/>
                                </ListItemButton>
                                <ListItemButton
                                    sx={{padding: 0}}
                                    onClick={() => {
                                        history.push(`${ownerRole === 1 ? '/' : '/business/'}myLTLQuote`);
                                        isMobile && cbHandleOpenMenu();
                                    }}
                                >
                                    <ListItemText primary={<Typography variant="body2" style={{
                                        color: '#454545',
                                        fontSize: '14px',
                                        paddingLeft: '40px'
                                    }}>{intl.get('SIDEBAR.LTL.LTL_QUOTE')}</Typography>}/>
                                </ListItemButton>
                                <ListItemButton
                                    sx={{padding: 0}}
                                    onClick={() => {
                                        history.push(`${ownerRole === 1 ? '/' : '/business/'}myLTLs`);
                                        isMobile && cbHandleOpenMenu();
                                    }}
                                >
                                    <ListItemText primary={<Typography variant="body2" style={{
                                        color: '#454545',
                                        fontSize: '14px',
                                        paddingLeft: '40px'
                                    }}>{intl.get('SIDEBAR.LTL.LTL_ORDER')}</Typography>}/>
                                </ListItemButton>
                                <ListItemButton
                                    sx={{padding: 0}}
                                    onClick={() => {
                                        history.push(`${ownerRole === 1 ? '/' : '/business/'}tracking-ltl`);
                                        isMobile && cbHandleOpenMenu();
                                    }}
                                >
                                    <ListItemText primary={<Typography variant="body2" style={{
                                        color: '#454545',
                                        fontSize: '14px',
                                        paddingLeft: '40px'
                                    }}>{intl.get('SIDEBAR.LTL.TRACKING_LTL')}</Typography>}/>
                                </ListItemButton>
                            </List>
                        </Collapse>
                    </>
                }
                {
                    ownerRole === 1 &&
                    <>
                        <ListItemButton
                            onClick={handleTransportationClick}
                            sx={{padding: 0, display: 'flex', justifyContent: 'space-between'}}
                        >
                            <ListItemIcon className={classes.position}>
                                <MovingLogo width="20" height="20"
                                            fill={openTab === 'transportation' ? '#1D8B45' : '#454545'}/>
                                <ListItemText primary={<Typography variant="body2" style={{
                                    color: openTab === 'transportation' ? '#1D8B45' : '#454545',
                                    fontSize: '14px'
                                }}>{intl.get('SIDEBAR.MOVING.MOVING')}</Typography>}/>
                            </ListItemIcon>
                            {openTab === 'transportation' ? <ExpandLess/> : <ExpandMore/>}
                        </ListItemButton>
                        <Collapse in={openTab === 'transportation' ? true : false} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton
                                    sx={{padding: 0}}
                                    onClick={() => {
                                        history.push("/transportation/item");
                                        isMobile && cbHandleOpenMenu();
                                    }}
                                >
                                    <ListItemText primary={<Typography variant="body2" style={{
                                        color: '#454545',
                                        fontSize: '14px',
                                        paddingLeft: '40px'
                                    }}>{intl.get('SIDEBAR.MOVING.ITEM')}</Typography>}/>
                                </ListItemButton>
                                <ListItemButton
                                    sx={{padding: 0}}
                                    onClick={() => {
                                        history.push("/transportation/express");
                                        isMobile && cbHandleOpenMenu();
                                    }}
                                >
                                    <ListItemText primary={<Typography variant="body2" style={{
                                        color: '#454545',
                                        fontSize: '14px',
                                        paddingLeft: '40px'
                                    }}>{intl.get('SIDEBAR.MOVING.BOX')}</Typography>}/>
                                </ListItemButton>
                            </List>
                        </Collapse>
                    </>
                }
                {
                    ownerRole === 3 &&
                    <>
                        <ListItemButton sx={{padding: 0, display: 'flex', justifyContent: 'space-between'}}
                                        onClick={handleOceanShippingClick}>
                            <ListItemIcon className={classes.position}>
                                <OceanLogo width="20" height="20" fill={openTab === 'ocean' ? '#1D8B45' : '#454545'}/>
                                <ListItemText primary={<Typography variant="body2" style={{
                                    color: openTab === 'ocean' ? '#1D8B45' : '#454545',
                                    fontSize: '14px',
                                }}>{intl.get('SIDEBAR.OCEAN_SHIPPING.OCEAN_SHIPPING')}</Typography>}/>
                            </ListItemIcon>
                            {openTab === 'ocean' ? <ExpandLess/> : <ExpandMore/>}
                        </ListItemButton>
                        <Collapse in={openTab === 'ocean' ? true : false} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton
                                    sx={{padding: 0}}
                                    onClick={() => {
                                        history.push(`${ownerRole === 1 ? '/' : '/business/'}ocean-shipping`);
                                        isMobile && cbHandleOpenMenu();
                                    }}
                                >
                                    <ListItemText primary={<Typography variant="body2" style={{
                                        color: '#454545',
                                        fontSize: '14px',
                                        paddingLeft: '40px'
                                    }}>{intl.get('SIDEBAR.OCEAN_SHIPPING.OCEAN_SHIPPING')}</Typography>}/>
                                </ListItemButton>
                            </List>
                        </Collapse>
                        <ListItemButton sx={{padding: 0, display: 'flex', justifyContent: 'space-between'}}
                                        onClick={handleCustomsBrokerage}>
                            <ListItemIcon className={classes.position}>
                                <BrokerageLogo width="20" height="20"
                                               fill={openTab === 'brokerage' ? '#1D8B45' : '#454545'}/>
                                <ListItemText primary={<Typography variant="body2" style={{
                                    color: openTab === 'brokerage' ? '#1D8B45' : '#454545',
                                    fontSize: '14px',
                                }}>{intl.get('SIDEBAR.BROKERAGE.BROKERAGE')}
                                </Typography>}/>
                            </ListItemIcon>
                            {openTab === 'brokerage' ? <ExpandLess/> : <ExpandMore/>}
                        </ListItemButton>
                        <Collapse in={openTab === 'brokerage' ? true : false} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton
                                    sx={{padding: 0}}
                                    onClick={() => {
                                        history.push(`${ownerRole === 1 ? '/' : '/business/'}brokerage/estimate`);
                                        isMobile && cbHandleOpenMenu();
                                    }}
                                >
                                    <ListItemText primary={<Typography variant="body2" style={{
                                        color: '#454545',
                                        fontSize: '14px',
                                        paddingLeft: '40px'
                                    }}>{intl.get('SIDEBAR.BROKERAGE.ESTIMATE_COST')}</Typography>}/>
                                </ListItemButton>
                                <ListItemButton
                                    sx={{padding: 0}}
                                    onClick={() => {
                                        history.push(`${ownerRole === 1 ? '/' : '/business/'}brokerage/packing-list`);
                                        isMobile && cbHandleOpenMenu();
                                    }}
                                >
                                    <ListItemText primary={<Typography variant="body2" style={{
                                        color: '#454545',
                                        fontSize: '14px',
                                        paddingLeft: '40px'
                                    }}>{intl.get('SIDEBAR.BROKERAGE.PACKING_LIST')}</Typography>}/>
                                </ListItemButton>
                            </List>
                        </Collapse>
                    </>
                }
                {/*{*/}
                {/*    partnerInfo?.moving_op_status === 1 &&*/}
                {/*    <>*/}
                {/*        <ListItemButton sx={{padding: 0, display: 'flex', justifyContent: 'space-between'}}*/}
                {/*                        onClick={handleTransportationClick}>*/}
                {/*            <ListItemIcon className={classes.position}>*/}
                {/*                <OceanLogo width="20" height="20"*/}
                {/*                           fill={openTab === 'transportation' ? '#1D8B45' : '#454545'}/>*/}
                {/*                <ListItemText primary={<Typography variant="body2" style={{*/}
                {/*                    color: openTab === 'transportation' ? '#1D8B45' : '#454545',*/}
                {/*                    fontSize: '14px',*/}
                {/*                }}>Moving</Typography>}/>*/}
                {/*            </ListItemIcon>*/}
                {/*            {openTab === 'transportation' ? <ExpandLess/> : <ExpandMore/>}*/}
                {/*        </ListItemButton>*/}
                {/*        <Collapse in={openTab === 'transportation' ? true : false} timeout="auto" unmountOnExit>*/}
                {/*            <List component="div" disablePadding>*/}
                {/*                <ListItemButton*/}
                {/*                    sx={{padding: 0}}*/}
                {/*                    onClick={() => {*/}
                {/*                        history.push(`${ownerRole === 1 ? '/' : '/business/'}transportation/availableTasks`);*/}
                {/*                        isMobile && cbHandleOpenMenu();*/}
                {/*                    }}*/}
                {/*                >*/}
                {/*                    <ListItemText primary={<Typography variant="body2" style={{*/}
                {/*                        color: '#454545',*/}
                {/*                        fontSize: '14px',*/}
                {/*                        paddingLeft: '40px'*/}
                {/*                    }}>Available Orders</Typography>}/>*/}
                {/*                </ListItemButton>*/}
                {/*            </List>*/}
                {/*            <List component="div" disablePadding>*/}
                {/*                <ListItemButton*/}
                {/*                    sx={{padding: 0}}*/}
                {/*                    onClick={() => {*/}
                {/*                        history.push(`${ownerRole === 1 ? '/' : '/business/'}transportation/claimTasks`);*/}
                {/*                        isMobile && cbHandleOpenMenu();*/}
                {/*                    }}*/}
                {/*                >*/}
                {/*                    <ListItemText primary={<Typography variant="body2" style={{*/}
                {/*                        color: '#454545',*/}
                {/*                        fontSize: '14px',*/}
                {/*                        paddingLeft: '40px'*/}
                {/*                    }}>My Orders</Typography>}/>*/}
                {/*                </ListItemButton>*/}
                {/*            </List>*/}
                {/*        </Collapse>*/}
                {/*    </>*/}
                {/*}*/}
                <ListItemButton sx={{padding: 0, display: 'flex', justifyContent: 'space-between'}}
                                onClick={handleBillingCenter}>
                    <ListItemIcon className={classes.position}>
                        <BillingLogo width="20" height="20" fill={openTab === 'bill' ? '#1D8B45' : '#454545'}/>
                        <ListItemText primary={<Typography variant="body2" style={{
                            color: openTab === 'bill' ? '#1D8B45' : '#454545',
                            fontSize: '14px',
                        }}>{intl.get('SIDEBAR.BILLING_CENTER.BILLING_CENTER')}</Typography>}/>
                    </ListItemIcon>
                    {openTab === 'bill' ? <ExpandLess/> : <ExpandMore/>}
                </ListItemButton>
                <Collapse in={openTab === 'bill' ? true : false} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton
                            sx={{padding: 0}}
                            onClick={() => {
                                history.push(`${ownerRole === 1 ? '/' : '/business/'}billing-center`);
                                isMobile && cbHandleOpenMenu();
                            }}
                        >
                            <ListItemText primary={<Typography variant="body2" style={{
                                color: '#454545',
                                fontSize: '14px',
                                paddingLeft: '40px'
                            }}>{intl.get('SIDEBAR.BILLING_CENTER.INVOICES')}</Typography>}/>
                        </ListItemButton>
                        {/*{*/}
                        {/*    ownerRole === 3 &&*/}
                        <ListItemButton
                            sx={{padding: 0}}
                            onClick={() => {
                                history.push(`${ownerRole === 1 ? '/' : '/business/'}billing-center/top-up`);
                                isMobile && cbHandleOpenMenu();
                            }}
                        >
                            <ListItemText primary={<Typography variant="body2" style={{
                                color: '#454545',
                                fontSize: '14px',
                                paddingLeft: '40px'
                            }}>{intl.get('SIDEBAR.BILLING_CENTER.TOP_UP')}</Typography>}/>
                        </ListItemButton>
                        {/*}*/}
                    </List>
                </Collapse>
                <ListItemButton sx={{padding: 0, display: 'flex', justifyContent: 'space-between'}}
                                onClick={handleSetting}>
                    <ListItemIcon className={classes.position}>
                        <SettingsLogo width="20" height="20" fill={openTab === 'settings' ? '#1D8B45' : '#454545'}/>
                        <ListItemText primary={<Typography variant="body2" style={{
                            color: openTab === 'settings' ? '#1D8B45' : '#454545',
                            fontSize: '14px',
                        }}>{intl.get('SIDEBAR.SETTINGS.SETTINGS')}</Typography>}/>
                    </ListItemIcon>
                    {openTab === 'settings' ? <ExpandLess/> : <ExpandMore/>}
                </ListItemButton>
                <Collapse in={openTab === 'settings' ? true : false} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton
                            sx={{padding: 0}}
                            onClick={() => {
                                history.push(`${ownerRole === 1 ? '/' : '/business/'}my-boxes`);
                                isMobile && cbHandleOpenMenu();
                            }}
                        >
                            <ListItemText primary={<Typography variant="body2" style={{
                                color: '#454545',
                                fontSize: '14px',
                                paddingLeft: '40px'
                            }}>{intl.get('SIDEBAR.SETTINGS.BOXES')}</Typography>}/>
                        </ListItemButton>
                        <ListItemButton
                            sx={{padding: 0}}
                            onClick={() => {
                                history.push(`${ownerRole === 1 ? '/' : '/business/'}address-book`);
                                isMobile && cbHandleOpenMenu();
                            }}
                        >
                            <ListItemText primary={<Typography variant="body2" style={{
                                color: '#454545',
                                fontSize: '14px',
                                paddingLeft: '40px'
                            }}>{intl.get('SIDEBAR.SETTINGS.ADDRESS_BOOK')}</Typography>}/>
                        </ListItemButton>
                        {
                            ownerRole === 3 &&
                            <ListItemButton
                                sx={{padding: 0}}
                                onClick={() => {
                                    history.push(`${ownerRole === 1 ? '/' : '/business/'}packing-list`);
                                    isMobile && cbHandleOpenMenu();
                                }}
                            >
                                <ListItemText primary={<Typography variant="body2" style={{
                                    color: '#454545',
                                    fontSize: '14px',
                                    paddingLeft: '40px'
                                }}>{intl.get('SIDEBAR.SETTINGS.PACKING_LIST')}</Typography>}/>
                            </ListItemButton>
                        }
                    </List>
                </Collapse>
            </Box>
            <Box>
                <ListItemButton
                    sx={{padding: '0', display: 'flex', justifyContent: 'space-between'}}
                    onClick={signOut}
                >
                    <ListItemIcon className={classes.position}>
                        <LogoutLogo width="20" height="20"/>
                        <ListItemText primary={<Typography variant="body2" style={{
                            color: '#454545',
                            fontSize: '14px'
                        }}>{intl.get('SIDEBAR.LOGOUT')}</Typography>}/>
                    </ListItemIcon>
                </ListItemButton>
            </Box>
        </CustomNav>
    )
}
