import Grid from "@mui/material/Grid";
import React, {useEffect, useState} from "react";
import MuiAlert from "@mui/material/Alert";
import {useParams} from "react-router-dom";
import {IconButton, Modal, Snackbar} from "@mui/material";
import Box from "@mui/material/Box";
import {CardImages} from "../Images/cards";
import Typography from "@mui/material/Typography";
import {formatCurrency} from "../../Utils/Helper";
import LoadingButton from "@mui/lab/LoadingButton";
import {getAccessToken} from "../../Utils/doToken";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import {CreditCardList} from "../CreditCard/CreditCardList";
import CreditCardIcon from "../../images/creditCardIcon.png";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {CreditCardSetup} from "../CreditCard/CreditCardSetup";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    MovingOrderDashboardCreditCardRoot: {
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        justifyContent: 'center'
    }
}

export const MovingOrderDashboardCreditCard = ({orderDetails, cbGetCreditCard}) => {

    const storedToken = getAccessToken("access_token");

    const [cardList, setCardList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [addNewCard, setAddNewCard] = useState(false);
    const [selectCardId, setSelectCardId] = useState(null);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleClickOpen = () => {
        setAddNewCard(true);
    };

    const handleClose = () => {
        setAddNewCard(false);
    };

    const getValidCardList = async () => {
        const requestURL = `${NODE_ROUTE_URI}/stripe/validCreditCardList`;
        setIsLoading(true);
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {Authorization: `Bearer ${storedToken}`},
            })

            console.log('result', result);
            setCardList(result?.data);
            setIsLoading(false);
            setError(false);
        } catch (e) {
            console.log('error', e.response);
            setError(true);
        }
    }

    const cbSelectCardId = (cardId) => {
        setSelectCardId(cardId);
        cbGetCreditCard(cardId);
    }

    const cbGetValidCardList = () => {
        getValidCardList();
    }

    useEffect(() => {
        getValidCardList()
    }, []);

    if (isLoading) {
        return <Box sx={{
            display: 'flex',
            justifyContent: 'center'
        }}>
            <CircularProgress/>
        </Box>
    }

    if (error && !isLoading) {
        return <>
            <h3>Sorry We currently encounter some issues, please try again later.</h3>
        </>
    }

    return (
        <Grid container spacing={2}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item xs={12}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%'
                }}>
                    <Typography style={{fontWeight: '600'}}>
                        Select Card to Check Out
                    </Typography>
                    {
                        cardList?.length > 0 ? <LoadingButton
                                variant='contained'
                                sx={{
                                    backgroundColor: '#F2BE22',
                                    "&:hover": {
                                        backgroundColor: '#F2BE22',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                // size='small'
                                onClick={handleClickOpen}
                                disabled={!selectCardId}
                            >
                                <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                                    Update
                                </Typography>
                            </LoadingButton>
                            :
                            <LoadingButton
                                variant='contained'
                                sx={{
                                    backgroundColor: '#F2BE22',
                                    "&:hover": {
                                        backgroundColor: '#F2BE22',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                onClick={handleClickOpen}
                            >
                                <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                                    Add New
                                </Typography>
                            </LoadingButton>
                    }
                </Box>
            </Grid>
            <Grid item xs={12}>
                {
                    cardList?.length > 0 ?
                        <CreditCardList cardList={cardList} cbSelectCardId={cbSelectCardId}/>
                        :
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            border: '1px solid #000000',
                            flexDirection: 'column',
                            borderRadius: '5px',
                            gap: '10px',
                            padding: '10px',
                            alignItems: 'center',
                            width: '70%'
                        }}>
                            <Box sx={{position: 'relative'}}>
                                <img src={CreditCardIcon} alt="creditCardLogo" width={100}/>
                                <IconButton
                                    sx={{
                                        position: 'absolute',
                                        left: '50%',
                                        top: '50%',
                                    }}
                                    onClick={handleClickOpen}
                                >
                                    <AddCircleIcon
                                        sx={{
                                            backgroundColor: '#ffffff',
                                            color: '#1D8B45',
                                            borderRadius: '50%',
                                            fontSize: '20px'
                                        }}
                                    />
                                </IconButton>
                                <Typography style={{fontSize: '16px', fontWeight: '600'}}>
                                    Add New Card
                                </Typography>
                            </Box>
                            <Box sx={{display: 'flex', gap: '2px'}}>
                                <Typography style={{fontSize: '14px', textAlign: 'left', color: 'red'}}>
                                    *
                                </Typography>
                                <Box>
                                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                        Your credit card information is securely stored by our payment provider. UUCargo
                                        does not have access to this information.
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                }
            </Grid>
            <Modal onClose={handleClose} open={addNewCard}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: '1px solid #000000',
                    width: 400,
                    boxShadow: 24,
                    backgroundColor: '#ffffff',
                    borderRadius: '10px',
                }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <Box sx={{backgroundColor: '#1D8B45', borderRadius: '9px 9px 0 0', padding: '20px'}}>
                            <Typography style={{fontSize: '20px', color: '#ffffff'}}>
                                Add Credit Card
                            </Typography>
                        </Box>
                        <Box>
                            <CreditCardSetup handleClose={handleClose} cbGetValidCardList={cbGetValidCardList}
                                             selectCardId={selectCardId}/>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </Grid>
    )
}