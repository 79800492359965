import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useSelector} from "react-redux";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import {RatingEstimateEach} from "./RatingEstimateEach";
import React, {useEffect, useState} from "react";
import LoadingContainer from "../shared/loadingContainer";

const style = {
    RatingEstimateRoot: {
        width: '1000px',
        margin: '20px auto',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',

    },
    RatingEstimateBox: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        margin: "20px",
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
        padding: '10px',
        borderRadius: '15px'
    },
    RatingEstimateGridContainer: {
        width: '100%',

    }
}

export const RatingEstimate = () => {

    let shipRates = useSelector(state => state?.rating?.shipRates);
    let isLoading = useSelector(state => state?.rating?.isLoadingShipRate);

    const [shipRateList, setShipRateList] = useState([]);

    useEffect(() => {
        // const sortedShipRates = [...shipRates].sort((current, next) => current?.rateData[0]?.totalCharge?.value - next?.rateData[0]?.totalCharge?.value)
        // setShipRateList(sortedShipRates)
        const flattenedShipRates = shipRates.flatMap(object => object.rateData);
        const sortedShipRates = [...flattenedShipRates].sort((current, next) => current?.totalCharge?.value - next?.totalCharge?.value);
        setShipRateList(sortedShipRates)
        // console.log('flatten array', flattenedShipRates);
        // console.log('sort array', sortedShipRates);
    }, [shipRates])

    return (
        <Box sx={style.RatingEstimateRoot}>
            <Typography variant="h4">
                Shipping Service
            </Typography>
            <Box sx={style.RatingEstimateBox}>
                <Grid container spacing={2} sx={style.RatingEstimateGridContainer}>
                    <Grid item xs={2}>
                        <Typography sx={{textAlign: 'center'}} style={{fontWeight: '600'}}>
                            Shipping Service
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography sx={{textAlign: 'center'}} style={{fontWeight: '600'}}>
                            Estimate Shipping Service
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography sx={{textAlign: 'center'}} style={{fontWeight: '600'}}>
                            Expected Delivered Date
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography sx={{textAlign: 'center'}} style={{fontWeight: '600'}}>
                            Message
                        </Typography>
                    </Grid>
                </Grid>
                <Divider sx={{width: '100%', margin: '10px'}}/>
                {
                    isLoading ? <LoadingContainer/> : shipRateList?.map((item, index) => <RatingEstimateEach key={index} estimateEach={item}/>)
                }
            </Box>
        </Box>

    )
}