import {Button, Container} from "@mui/material";
import {getAccessToken} from "../../../Utils/doToken";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {LTLCrossBorderDashboardInvoice} from "./LTLCrossBorderDashboardInvoice";
import {LTLCrossBorderDashboardInvoiceRemove} from "./LTLCrossBorderDashboardInvoiceRemove";
import {useHistory} from "react-router-dom";

export const LTLCrossBorderDashboard = () => {

    const history = useHistory();
    const storedToken = getAccessToken("access_token");
    const {order_id: orderId} = useParams();
    const [orderData, setOrderData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [specialServices, setSpecialServices] = useState([]);

    const getOrderData = async () => {
        setIsLoading(true);
        const requestURL = `${PARTNER_URI}/shippingLTL/getLTLOrderDetailsByOrderId`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    orderId: orderId
                }
            })
            // console.log('result', result);

            const serviceList = result?.data?.bolInfo?.pickup_instruction
            // console.log('special service list', serviceList);

            if (serviceList) {
                const services = serviceList.split('\n');
                // console.log('remark note', remarkNote);
                const specialServices = services?.map(element => {
                    return {serviceCode: element}
                })
                // console.log('special service', specialServices);
                setSpecialServices(specialServices);
            }

            setOrderData(result?.data);

        } catch (e) {
            console.log('e', e?.response);
        } finally {
            setIsLoading(false);
        }
    }

    const handleToOrderDashboard = () => {
        history.push(`/business/ltl-order-dashboard/${orderId}`)
    }

    useEffect(() => {
        if (orderId) {
            getOrderData();
        }
    }, [orderId]);

    console.log('order data', orderData);

    return (
        <Container maxWidth='xl'>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px'
            }}>
                <Typography style={{fontSize: '22px', fontWeight: '600', textAlign: 'left'}}>
                    Cross Border Information
                </Typography>
                <LTLCrossBorderDashboardInvoice orderData={orderData} getOrderData={getOrderData}/>
                {/*{*/}
                {/*    orderData?.commecialInvoices?.length > 0 &&*/}
                {/*    <LTLCrossBorderDashboardInvoiceRemove orderData={orderData} getOrderData={getOrderData}/>*/}
                {/*}*/}
                <Box>
                    <Button
                        variant='contained'
                        sx={{
                            backgroundColor: '#1D8B45',
                            "&:hover": {
                                backgroundColor: '#1D8B45',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        onClick={handleToOrderDashboard}
                    >
                        <Typography style={{textTransform: 'none'}}>
                            Order Dashboard
                        </Typography>
                    </Button>
                </Box>
            </Box>
        </Container>
    )
}