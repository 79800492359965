import {extractFullAddress, getCountryFromProvince, mapServiceLogo} from "../../Utils/Helper";
import {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@mui/material/Box";
import {LoadingContainer} from "../shared";
import intl from "react-intl-universal";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {Button, Divider} from "@mui/material";

const styles = {
    LandingEstimateCostContainer: {
        margin: '50px auto'
    },
    LandingEstimateCostContentRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px 60px',
        borderRadius: '10px',
        '@media (max-width: 480px)': {
            padding: 0, // for phone and above
        },
    },
    LandingEstimateCostPackageEach: {
        height: '100px',
        // border: '1px solid red',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end'

    },
    LandingEstimateCostPallet: {
        height: '50px',
    },
    LandingEstimateQueryInfo: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: '#F5F5F5',
        borderRadius: '5px',
        padding: '10px'
    },
    LandingEstimateQueryInfoHeading: {
        fontSize: '20px',
        fontWeight: '600',

    },
    LandingEstimateQueryInfoTitle: {
        fontSize: '16px',
        textAlign: 'left'
    },
    LandingEstimateQueryInfoText: {
        fontSize: '16px',
        fontWeight: '600',
        textAlign: 'left'
    },
    EstimateCostRoot: {
        margin: '50px auto'
    },
    EstimateCostPackageRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px 60px',
        borderRadius: '10px',
        '@media (max-width: 480px)': {
            padding: 0, // for phone and above
        },
    },
    EstimateCostHeading: {
        textAlign: 'left',
        // fontSize: '14px',
        padding: '20px',
        fontWeight: '600',
        '@media (max-width: 480px)': {
            padding: '0 5px',
            fontSize: '10px'// for phone and above
        },
    },
    EstimateCostText: {
        textAlign: 'left',
        // fontSize: '14px',
        padding: '20px',
        '@media (max-width: 480px)': {
            padding: 0,
            fontSize: '10px'// for phone and above
        },
    },
    EstimateCostText2: {
        textAlign: 'left',
        // fontSize: '14px',
        '@media (max-width: 480px)': {
            padding: 0,
            fontSize: '10px'// for phone and above
        },
    },
    EstimateCostPallet: {
        height: '100px',
        padding: '20px 60px 60px',
        '@media (max-width: 480px)': {
            padding: 0, // for phone and above
        },
    },
    EstimateCostImageBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '0 20px',
        height: '100%',
        '@media (max-width: 480px)': {
            padding: '0 5px',
        },
    },
    EstimateCostBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '0 20px',
        gap: '5px',
        height: '100%',
        '@media (max-width: 480px)': {
            padding: '5px 0',
        },
    },
    EstimateCostLiner: {
        '--LinearProgress-radius': '6px',
        '--LinearProgress-progressThickness': '12px',
        color: 'green',
        '@media (max-width: 480px)': {
            '--LinearProgress-progressThickness': '6px',
        },
    },
    EstimateCostButtonBox: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        paddingLeft: '20px',
        '@media (max-width: 480px)': {
            paddingLeft: 0,
        },
    },
    EstimateCostButtonText: {
        // fontSize: '14px',
        '@media (max-width: 480px)': {
            fontSize: '10px'// for phone and above
        },
    }
}

export const LandingEstimateCost = () => {

    const isMobile = useMediaQuery('(max-width: 480px)');
    const history = useHistory();

    const [estimatePackageCost, setEstimatePackageCost] = useState([]);
    const [estimatePalletCost, setEstimatePalletCost] = useState();
    const [estimateBoxCost, setEstimateBoxCost] = useState();
    const [estimatePackageCostList, setEstimatePackageCostList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [queryInfo, setQueryInfo] = useState({});
    const [hideOptions, setHideOptions] = useState(false);
    const [boxData, setBoxData] = useState();
    const [packageData, setPackageData] = useState();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const queryObject = {};
    for (let [key, value] of searchParams.entries()) {
        queryObject[key] = value;
    }

    const googleMapsPlaceAutocomplete = async (inputValue) => {
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: 'postal_code'
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log('prediction', predictions);
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            // console.log('updated prediction', updatedPredictions);

            return updatedPredictions;

        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const handleQueryFromHeavyBox = () => {

        const pickupPostalCode = queryObject?.pickupPostalCode?.replace(/\s+/g, '')?.toUpperCase();
        const deliverPostalCode = queryObject?.deliverPostalCode?.replace(/\s+/g, '')?.toUpperCase();
        const pickupWarehouseId = queryObject?.pickupWarehouseId;
        const deliverWarehouseId = queryObject?.deliverWarehouseId;
        const length = queryObject?.length < 1 ? '1' : queryObject?.length;
        const width = queryObject?.width < 1 ? '1' : queryObject?.width;
        const height = queryObject?.height < 1 ? '1' : queryObject?.height;
        const weight = queryObject?.weight < 1 ? '1' : queryObject?.weight;
        const quantity = queryObject?.quantity < 1 ? '1' : queryObject?.quantity > 10 ? '10' : queryObject?.quantity;

        setQueryInfo({
            pickupPostalCode,
            deliverPostalCode,
            pickupWarehouseId,
            deliverWarehouseId,
            length,
            width,
            height,
            weight,
            quantity,
            email: queryObject.contactEmail
        })
    }

    const handleQueryFromPackage = () => {
        const shipFromProvince = queryObject.pickupProvince;
        const shipFromCountry = getCountryFromProvince(queryObject.pickupProvince);
        const shipFromPostalCode = queryObject.pickupPostalCode.replace(/\s+/g, '').toUpperCase();
        const shipToProvince = queryObject.deliverProvince;
        const shipToCountry = getCountryFromProvince(queryObject.deliverProvince);
        const shipToPostalCode = queryObject?.deliverPostalCode.replace(/\s+/g, '').toUpperCase();
        const length = queryObject?.length < 1 ? '1' : queryObject?.length;
        const width = queryObject?.width < 1 ? '1' : queryObject?.width;
        const height = queryObject?.height < 1 ? '1' : queryObject?.height;
        const weight = queryObject?.weight < 1 ? '1' : queryObject?.weight;
        const quantity = queryObject?.quantity < 1 ? '1' : queryObject?.quantity > 10 ? '10' : queryObject?.quantity;
        const lengthUnit = queryObject?.dimensionUnit;
        const weightUnit = queryObject?.weightUnit;

        setQueryInfo({
            shipFromProvince,
            shipFromCountry,
            shipFromPostalCode,
            shipToProvince,
            shipToCountry,
            shipToPostalCode,
            length,
            width,
            height,
            weight,
            quantity,
            lengthUnit,
            weightUnit,
            email: queryObject.contactEmail
        })
    }

    useEffect(() => {
        const sortedEstimateCost = estimatePackageCost.sort((current, next) => current?.totalCharge?.value - next?.totalCharge?.value);
        setEstimatePackageCostList(sortedEstimateCost);
        estimatePackageCost.length > 3 ? setHideOptions(true) : setHideOptions(false);
    }, [estimatePackageCost])

    const handleEstimateCost = estimateCostResult => {
        console.log('handle estimate', estimateCostResult)
        estimateCostResult.forEach(item => {
            if (!item.hasOwnProperty('errorMessage')) {
                setEstimatePackageCost(prevState => ([
                    ...prevState, item
                ]))
            }
        })
    }

    const endUserGetRatingByAccountBaseWithoutToken = async (accountBase, pickupAddress, deliverAddress) => {
        setIsLoading(true);
        let requestURL = `${NODE_ROUTE_URI}/looseItem/landing/ratesByAccountBase`;
        const shipFromPostalCode = queryObject.pickupPostalCode.replace(/\s+/g, '').toUpperCase();
        const shipToPostalCode = queryObject?.deliverPostalCode.replace(/\s+/g, '').toUpperCase();

        const data = {
            rateData: {
                shipFromAddress: "",
                shipFromAddressTwo: "",
                shipFromCity: "",
                shipFromProvince: pickupAddress?.state?.code,
                shipFromPostalCode: shipFromPostalCode,
                shipFromCountry: pickupAddress?.country?.code,
                shipFromName: "",
                shipFromPhone: "",
                shipToAddress: "",
                shipToAddressTwo: "",
                shipToCity: "",
                shipToPostalCode: shipToPostalCode,
                shipToProvince: deliverAddress?.state?.code,
                shipToCountry: deliverAddress?.country?.code,
                shipToName: "",
                shipToPhone: "",
                packageData: [
                    {
                        length: queryObject?.length < 1 ? '1' : queryObject?.length,
                        width: queryObject?.width < 1 ? '1' : queryObject?.width,
                        height: queryObject?.height < 1 ? '1' : queryObject?.height,
                        weight: queryObject?.weight < 1 ? '1' : queryObject?.weight,
                        quantity: queryObject?.quantity < 1 ? '1' : queryObject?.quantity > 10 ? '10' : queryObject?.quantity,
                        lengthUnit: queryObject?.dimensionUnit,
                        weightUnit: queryObject?.weightUnit
                    }
                ]
            },
            selectedAccountBase: accountBase
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })
            setIsLoading(false);

            const ratingResult = result.data.result;
            console.log(`package rate ${accountBase}`, ratingResult);
            ratingResult.length > 0 && handleEstimateCost(ratingResult);

        } catch (e) {
            setIsLoading(false);
            console.log(e.response);
        }
    }

    const endUserGetRatingByBox = async () => {
        setIsLoading(true);
        let requestURL = `${NODE_ROUTE_URI}/movingExpressService/landing/movingExpressGetRating`;

        const pickupPredictions = await googleMapsPlaceAutocomplete(queryObject?.pickupPostalCode);
        const deliverPredictions = await googleMapsPlaceAutocomplete(queryObject?.deliverPostalCode);
        const pickupPlaceDetails = await googleMapsPlaceDetails(pickupPredictions?.[0]?.placeId);
        const deliverPlaceDetails = await googleMapsPlaceDetails(deliverPredictions?.[0]?.placeId);
        const pickupAddress = extractFullAddress(pickupPlaceDetails);
        const deliverAddress = extractFullAddress(deliverPlaceDetails);

        console.log('[endUserGetRatingByBox] pickupAddress', pickupAddress);

        const data = {
            items: [{
                description: 'Heavy Box',
                cateId: 100,
                length: queryObject?.length < 1 ? '1' : queryObject?.length,
                width: queryObject?.width < 1 ? '1' : queryObject?.width,
                height: queryObject?.height < 1 ? '1' : queryObject?.height,
                weight: queryObject?.weight < 1 ? '1' : queryObject?.weight,
                quantity: queryObject?.quantity < 1 ? '1' : queryObject?.quantity > 10 ? '10' : queryObject?.quantity,
                lengthUnit: 'in',
                weightUnit: 'lb',
            }],
            pickupWarehouseId: queryObject?.pickupServiceType === 'self' ? (queryObject?.pickupWarehouseId || 0) : 0,
            pickupType: queryInfo?.pickupOption,
            pickupCity: pickupAddress?.city,
            pickupProvince: pickupAddress?.state?.code,
            pickupCountry: pickupAddress?.country?.code,
            pickupPostalCode: pickupAddress?.zip,
            deliverWarehouseId: queryObject?.deliverServiceType === 'self' ? (queryObject?.deliverWarehouseId || 0) : 0,
            deliverType: queryInfo?.deliverOption,
            deliverCity: deliverAddress?.city,
            deliverProvince: deliverAddress?.state?.code,
            deliverCountry: deliverAddress?.country?.code,
            deliverPostalCode: deliverAddress?.zip,
            specialServices: []
        }
        setBoxData(data);

        console.log('[endUserGetRatingByBox] data', data);

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })
            console.log(result.data);
            setIsLoading(false);
            setEstimateBoxCost(result.data);
            setError(false);
        } catch (e) {
            console.log(e.response);
            setIsLoading(false);
            setError(true);
        }
    }

    const addEmail = () => {
        let requestURL = `${NODE_ROUTE_URI}/collectEmail/addEmail`;
        axios.request({
            method: 'post',
            url: requestURL,
            data: {
                senderEmail: queryObject.contactEmail
            }
        }).then((response) => {
            console.log(JSON.stringify(response.data))
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(async () => {
        addEmail();
        if (queryObject?.shipmentTypeGroupId === "40") {
            await handleQueryFromHeavyBox();
            await endUserGetRatingByBox();
        } else {
            handleQueryFromPackage();

            const pickupPredictions = await googleMapsPlaceAutocomplete(queryObject?.pickupPostalCode);
            const deliverPredictions = await googleMapsPlaceAutocomplete(queryObject?.deliverPostalCode);
            const pickupPlaceDetails = await googleMapsPlaceDetails(pickupPredictions?.[0]?.placeId);
            const deliverPlaceDetails = await googleMapsPlaceDetails(deliverPredictions?.[0]?.placeId);
            const pickupAddress = extractFullAddress(pickupPlaceDetails);
            const deliverAddress = extractFullAddress(deliverPlaceDetails);

            setPackageData(prevState => ({
                ...prevState,
                pickupAddress,
                deliverAddress
            }))

            if (getCountryFromProvince(queryObject.pickupProvince) === getCountryFromProvince(queryObject.deliverProvince)) {
                endUserGetRatingByAccountBaseWithoutToken('ups', pickupAddress, deliverAddress);
                endUserGetRatingByAccountBaseWithoutToken('canadapost', pickupAddress, deliverAddress);
                endUserGetRatingByAccountBaseWithoutToken('fedex', pickupAddress, deliverAddress);
                endUserGetRatingByAccountBaseWithoutToken('canpar', pickupAddress, deliverAddress);
            } else {
                endUserGetRatingByAccountBaseWithoutToken('ups', pickupAddress, deliverAddress)
            }
        }
    }, [])

    console.log('[LandingEstimateCost] query object', queryObject)

    return (
        <Box sx={styles.LandingEstimateCostContainer}>
            <Box sx={styles.LandingEstimateCostContentRoot}>
                <Box>
                    {
                        isLoading ? <LoadingContainer/> : (
                            queryObject?.shipmentTypeGroupId === "5" ? (
                                estimatePackageCostList.length > 0 ?
                                    <>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography
                                                    sx={{fontSize: '18px', fontWeight: '600', textAlign: 'center'}}>
                                                    Shipping Information
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography>
                                                    Dimension: {queryInfo?.length} x {queryInfo?.width} x {queryInfo?.height} {queryInfo?.lengthUnit}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography>
                                                    Weight: {queryInfo?.weight} {queryInfo?.weightUnit}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography>
                                                    Quantity: {queryInfo?.quantity}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={5} xs={12}>
                                                <Grid container spacing={1}>
                                                    <Grid item md={6} xs={12}>
                                                        <Typography>
                                                            Pickup: {packageData?.pickupAddress?.state?.code}, {queryInfo?.shipFromPostalCode}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item md={6} xs={12}>
                                                        <Typography>
                                                            Deliver: {packageData?.deliverAddress?.state?.code}, {queryInfo?.shipToPostalCode}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography sx={styles.EstimateCostHeading}>
                                                    Carrier
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={isMobile ? 5 : 4}>
                                                <Typography sx={styles.EstimateCostHeading}>
                                                    Service Type
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography sx={styles.EstimateCostHeading}>
                                                    Delivery Time
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography sx={styles.EstimateCostHeading}>
                                                    Total Cost
                                                </Typography>
                                            </Grid>
                                            {!isMobile &&
                                                <Grid item xs={1}>
                                                </Grid>
                                            }
                                        </Grid>
                                        <Divider/>
                                        {
                                            estimatePackageCostList?.map((item, index) => {
                                                return (
                                                    <Box key={index}
                                                         sx={{
                                                             display: `${index > 3 && hideOptions && 'none'}`,
                                                         }}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={2}>
                                                                <Box sx={styles.EstimateCostImageBox}>
                                                                    <img src={mapServiceLogo(item?.serviceName)}
                                                                         width={isMobile ? '20px' : '40px'}/>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={isMobile ? 5 : 4}>
                                                                <Box sx={styles.EstimateCostBox}>
                                                                    <Typography sx={styles.EstimateCostText}>
                                                                        {item?.serviceName}
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Box sx={styles.EstimateCostBox}>
                                                                    {/*{!isMobile && <LinearProgress*/}
                                                                    {/*    determinate*/}
                                                                    {/*    value={item?.serviceType === 'express' ? '20' : getDeliverTime(item?.expectedDate) === 0 ? '30' : '50'}*/}
                                                                    {/*    sx={styles.EstimateCostLiner}*/}
                                                                    {/*/>}*/}
                                                                    <Typography sx={styles.EstimateCostText2}>
                                                                        {item?.shipRange} business day(s)
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Box sx={styles.EstimateCostBox}>
                                                                    <Typography sx={styles.EstimateCostText}>
                                                                        $ {(item?.totalChargeWithTaxes.value)?.toFixed(2)}
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                            {!isMobile &&
                                                                <Grid item xs={1}>
                                                                    <Box sx={styles.EstimateCostButtonBox}>
                                                                        <Button
                                                                            variant='contained'
                                                                            onClick={() => history.push('/sign-in')}
                                                                        >
                                                                            <Typography
                                                                                sx={styles.EstimateCostButtonText}>
                                                                                ship
                                                                            </Typography>
                                                                        </Button>
                                                                    </Box>
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                        <Divider/>
                                                    </Box>
                                                )
                                            })
                                        }
                                        <Box>
                                            {hideOptions ? <Button onClick={() => setHideOptions(false)}
                                                                   sx={{
                                                                       display: 'flex',
                                                                       justifyContent: 'center',
                                                                       width: '100%'
                                                                   }}>
                                                    See More options
                                                </Button> :
                                                <Button onClick={() => setHideOptions(true)}
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            width: '100%'
                                                        }}>
                                                    Hide Options
                                                </Button>
                                            }
                                        </Box>
                                    </>
                                    :
                                    <Box>
                                        <Typography>
                                            The feature for estimating shipping costs is limited for guests.
                                        </Typography>
                                        <Typography>
                                            Please sign in to unlock the full range of features.
                                        </Typography>
                                    </Box>
                            ) : ((!error && estimatePalletCost?.finalTotal !== Infinity) ?
                                    <>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography
                                                    sx={{fontSize: '18px', fontWeight: '600', textAlign: 'center'}}>
                                                    Shipping Information
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography>
                                                    Dimension: {queryInfo?.length} x {queryInfo?.width} x {queryInfo?.height} in
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography>
                                                    Weight: {queryInfo?.weight} lb
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography>
                                                    Quantity: {queryInfo?.quantity}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={5} xs={12}>
                                                <Grid container spacing={1}>
                                                    <Grid item md={6} xs={12}>
                                                        <Typography>
                                                            Pickup: {boxData?.pickupCity}, {boxData?.pickupProvince}, {boxData?.pickupPostalCode}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item md={6} xs={12}>
                                                        <Typography>
                                                            Deliver: {boxData?.deliverCity}, {boxData?.deliverProvince}, {boxData?.deliverPostalCode}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={isMobile ? 6 : 4}>
                                                <Typography sx={styles.EstimateCostHeading}>
                                                    {intl.get('SHIPPING_RATE_CALCULATOR_COST_HEADING_2')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={isMobile ? 6 : 4}>
                                                <Typography sx={styles.EstimateCostHeading}>
                                                    {intl.get('SHIPPING_RATE_CALCULATOR_COST_HEADING_5')}
                                                </Typography>
                                            </Grid>
                                            {!isMobile &&
                                                <Grid item xs={4}>
                                                </Grid>
                                            }
                                        </Grid>
                                        <Divider/>
                                        <Box>
                                            <Grid container spacing={1}>
                                                <Grid item xs={isMobile ? 6 : 4}>
                                                    <Typography sx={styles.EstimateCostText}>
                                                        Standard
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={isMobile ? 6 : 4}>
                                                    <Typography sx={styles.EstimateCostText}>
                                                        $ {(+estimateBoxCost?.totalChargeWithTaxes).toFixed(2)}
                                                    </Typography>
                                                </Grid>
                                                {!isMobile &&
                                                    <Grid item xs={4}>
                                                        <Box sx={styles.EstimateCostButtonBox}>
                                                            <Button
                                                                variant='contained'
                                                                onClick={() => history.push('/sign-in')}
                                                            >
                                                                <Typography sx={styles.EstimateCostButtonText}>
                                                                    {intl.get('SHIPPING_RATE_CALCULATOR_COST_BTN_1')}
                                                                </Typography>
                                                            </Button>
                                                        </Box>
                                                    </Grid>
                                                }
                                            </Grid>
                                            <Divider/>
                                        </Box>
                                    </>
                                    : <Box>
                                        <Typography>
                                            The feature for estimating shipping costs is limited for guests.
                                        </Typography>
                                        <Typography>
                                            Please sign in to unlock the full range of features.
                                        </Typography>
                                    </Box>
                            )
                        )
                    }
                </Box>
                <Typography style={{textAlign: 'right', fontSize: '12px', fontStyle: 'italic'}}>
                    {intl.get('SHIPPING_RATE_CALCULATOR_COST_TEXT_3')}
                </Typography>
            </Box>
        </Box>
    )
}