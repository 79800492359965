import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import AlipayLogo from "../../images/ALIPAY_LOGO.png";
import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {Link} from "@mui/material";
import {formatCurrency} from "../../Utils/Helper";
import {getAccessToken} from "../../Utils/doToken";
import {useParams} from "react-router-dom";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";

const styles = {
    MovingOrderDashboardAliPayRoot: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },

    MovingOrderDetailsAliPayText: {
        fontSize: '20px',
        fontWeight: '600'
    },

    MovingOrderDetailsAliPayLink: {
        textDecoration: 'none'
    }
}

export const MovingOrderDashboardAliPay = ({orderDetails}) => {

    const accessToken = getAccessToken("access_token");

    const {orderId} = useParams();

    const [paymentData, setPaymentData] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);

    const getRemainPaymentData = async () => {
        let requestURL = `${NODE_ROUTE_URI}/generalWechatAlipay/initEndUserMovingRemainingAmount`;
        setIsLoading(true);
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: {
                    backendOrderId: orderId,
                    payType: "ALPHAPAY-ALIPAY"
                }
            })

            console.log('wechat result', result);
            setPaymentData(result.data);
            setIsLoading(false);
            setError(false);
        } catch (e) {
            console.log(e.response);
            setError(true);
        }
    }

    useEffect(() => {
        getRemainPaymentData();
    }, []);

    if (isLoading) {
        return <Box sx={{
            display: 'flex',
            justifyContent: 'center'
        }}>
            <CircularProgress/>
        </Box>
    }

    if (error && !isLoading) {
        return <>
            <h3>Sorry We currently encounter some issues, please try again later.</h3>
        </>
    }

    return (
        <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%'
                }}>
                    <Typography>
                        Please click the external <Link href={paymentData?.pay_url}
                                                        sx={styles.MovingOrderDetailsAliPayLink}>link</Link> to complete
                        the payment.
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={8} xs={12}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%'
                }}>
                    <Grid container spacing={0}>
                        <Grid item xs={6}>
                            <Typography>
                                Amount:
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>
                                {formatCurrency(orderDetails?.task_unpaid_amount || 0, 'USD')}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>
                                Receiver:
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>
                                UU CARGO LTD.
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>
                                Order Id:
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>
                                {orderId}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>
                                Order Number:
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>
                                {orderDetails?.order_number}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )
}