import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as React from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import {IconButton, Snackbar} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../Utils/doToken";
import {useState} from "react";
import MuiAlert from "@mui/material/Alert";
import {useDispatch} from "react-redux";
import {addAddressInfo, toggleEditAddress} from "../../actions/addressBookAction";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
    AddressBookEachRootWhite: {
        borderRadius: '5px',
        margin: '20px 0',
        alignItems: 'center',
        backgroundColor: '#ffffff',
    },
    AddressBookEachRootDark: {
        backgroundColor: '#eeeeee',
        borderRadius: '5px',
        margin: '20px 0',
        alignItems: 'center',
    },
}

export const AddressBookEach = ({addressInfo, index}) => {
    // console.log(addressInfo)
    // console.log(index)
    const accessToken = getAccessToken("access_token");
    const dispatch = useDispatch();

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleDeleteAddress = () => {
        deleteAddress();
    }

    const handleEditAddress = () => {
        dispatch(toggleEditAddress(true));
        dispatch(addAddressInfo(addressInfo));
    }

    const deleteAddress = async () => {
        let requestURL = `${NODE_ROUTE_URI}/addressBookEndUser/endUser/addressbooks`;

        try {
            await axios({
                method: 'delete',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: {
                    id: addressInfo?.id
                }
            })
            setErrorMessage('');
            setSuccessMessage('Successfully Delete Address');
            handleSuccessMessage();
            window.location.reload();

        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail Delete Address');
            handleErrorMessage();
        }
    }

    return (
        <Box sx={index % 2 === 0 ? style.AddressBookEachRootWhite : style.AddressBookEachRootDark}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid container spacing={2}>
                <Grid item xs={3} style={{textAlign: 'center'}}>
                    <Typography style={{wordWrap: 'break-word', whiteSpace: 'pre-line'}}>
                        {addressInfo?.name}
                    </Typography>
                </Grid>
                <Grid item xs={4} style={{textAlign: 'center'}}>
                    <Typography style={{wordWrap: 'break-word', whiteSpace: 'pre-line'}}>
                        {addressInfo?.address},
                    </Typography>
                    <Typography style={{wordWrap: 'break-word', whiteSpace: 'pre-line'}}>
                        {addressInfo?.city}, {addressInfo?.province}, {addressInfo?.country}, {addressInfo?.postal_code}
                    </Typography>
                </Grid>
                <Grid item xs={2} style={{textAlign: 'center'}}>
                    <Typography>
                        {addressInfo?.phone}
                    </Typography>
                </Grid>
                <Grid item xs={1} style={{textAlign: 'center'}}>
                    <IconButton onClick={handleEditAddress}>
                        <EditIcon/>
                    </IconButton>
                </Grid>
                <Grid item xs={2} style={{textAlign: 'center'}}>
                    <IconButton onClick={handleDeleteAddress}>
                        <DeleteIcon/>
                    </IconButton>
                </Grid>
            </Grid>
        </Box>
    )
}