import {useEffect, useState} from "react";
import {PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {PAGE_SIZE} from "../../Utils/consInfo";
import {getAccessToken} from "../../Utils/doToken";
import Box from "@mui/material/Box";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";
import {Container, InputAdornment} from "@mui/material";
import Grid from "@material-ui/core/Grid";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import {Pagination} from "@mui/lab";
import {ShippingOrderListLTL} from "../ShippingOrderList/Business/ShippingOrderListLTL";

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    }
});

export const MyLTLList = () => {

    const storedToken = getAccessToken("access_token");

    const [searchString, setSearchString] = useState(null);
    const [orderData, setOrderData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const classes = useStyles();

    const handleSearchString = (event) => {
        setSearchString(event.target.value);
    }

    const getLTLOrderList = async () => {
        setLoading(true);
        const requestURL = `${PARTNER_URI}/elasticsearch/shippingLTL/searchShippingLTLOrder`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    queryString: searchString || '',
                    page: 0,
                    pageSize: PAGE_SIZE,
                }
            });
            console.log('[shipping order list ltl] - result', result);
            setOrderData(result?.data?.result);
            setCurrentPage(result?.data?.pagination?.currentPage + 1);
            setLastPage(result?.data?.pagination?.lastPage + 1);
            setError(false);
            setLoading(false);
        } catch (e) {
            console.log(e.response);
            setError(true);
            setLoading(false);
        }
    }

    const getLTLOrderListByPage = async (page) => {
        setLoading(true);
        const requestURL = `${PARTNER_URI}/elasticsearch/shippingLTL/searchShippingLTLOrder`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    queryString: searchString || '',
                    page: page,
                    pageSize: PAGE_SIZE,
                }
            });
            console.log('[shipping order list package] - result', result);
            setOrderData(result?.data?.result);
            setError(false);
            setLoading(false);
        } catch (e) {
            console.log(e.response);
            setError(true);
            setLoading(false);
        }
    }

    const handleSearchShippingOrder = () => {
        getLTLOrderList();
    }

    const handleSearchShippingOrderByKeyDown = (event) => {
        if (event.key === 'Enter') {
            getLTLOrderList();
        }
    }

    const handlePageChange = (event, value) => {
        console.log('page change', value);
        setCurrentPage(value);
        const page = value;
        getLTLOrderListByPage(page - 1);
    };

    useEffect(() => {
        (async () => {
            await getLTLOrderList();
        })()
    }, [])

    return (
        <Container maxWidth='xl'>
            <Box sx={styles.ShippingOrderRoot}>
                <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'left'}}>
                    {intl.get('HOME_PAGE.SHIPPING_ORDER.LTL_ORDER')}
                </Typography>
                <Box sx={styles.ShippingOrderContent}>
                    <Grid container spacing={0}>
                        <Grid item md={10} xs={8}>
                            <TextField
                                value={searchString}
                                fullWidth
                                size='small'
                                onInput={handleSearchString}
                                onKeyDown={handleSearchShippingOrderByKeyDown}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon/>
                                        </InputAdornment>
                                    ),
                                }}
                                classes={{root: classes.customTextField}}
                                // label='Order# / REF# / Tracking# / Name / Email / Address / Phone'
                                placeholder={intl.get('HOME_PAGE.SHIPPING_ORDER.SEARCH_PLACEHOLDER')}
                            />
                        </Grid>
                        <Grid item md={2} xs={4}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                            }}>
                                <Button
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#1D8B45',
                                        height: '100%',
                                        "&:hover": {
                                            backgroundColor: '#1D8B45',
                                            filter: 'brightness(0.9)'
                                        },
                                    }}
                                    // size='small'
                                    onClick={handleSearchShippingOrder}
                                >
                                    <Typography
                                        style={{textTransform: 'none', fontSize: '16px'}}
                                    >
                                        {intl.get('HOME_PAGE.SHIPPING_ORDER.SEARCH')}
                                    </Typography>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    {
                        loading ?
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <CircularProgress/>
                            </Box>
                            :
                            error ?
                                <Box sx={styles.ShippingOrderContent}>
                                    <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                        {intl.get('HOME_PAGE.ERROR')}
                                    </Typography>
                                </Box>
                                :
                                orderData?.length === 0 ?
                                    <Box sx={styles.ShippingOrderContent}>
                                        <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                            {intl.get('HOME_PAGE.SHIPPING_ORDER.NO_RESULTS')}
                                        </Typography>
                                    </Box>
                                    :
                                    <Box sx={styles.ShippingOrderContent}>
                                        <ShippingOrderListLTL order={orderData}/>
                                        <Pagination
                                            count={lastPage}
                                            page={+currentPage}
                                            size='small'
                                            // defaultPage={6}
                                            onChange={handlePageChange}
                                            // boundaryCount={2}
                                        />
                                    </Box>

                    }
                </Box>
            </Box>
        </Container>
    )
}