import {
    ADDRESS_BOOK_ADDRESS_INFO,
    ADDRESS_BOOK_IS_ADD,
    ADDRESS_BOOK_IS_EDIT,
    ADDRESS_BOOK_UPDATE_TAB_VALUE
} from "../actions/types";

const initialState = {
    isAddAddress: false,
    isEditAddress: false,
    addressTab: 'sender',
    addressInfo: null

}

export const addressBookReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADDRESS_BOOK_IS_ADD:
            return {...state, isAddAddress: action?.payload}
        case ADDRESS_BOOK_IS_EDIT:
            return {...state, isEditAddress: action?.payload}
        case ADDRESS_BOOK_UPDATE_TAB_VALUE:
            console.log('tab value', action?.payload);
            return {...state, addressTab: action?.payload}
        case ADDRESS_BOOK_ADDRESS_INFO:
            return {...state, addressInfo: action?.payload}
        default:
            return state
    }
}