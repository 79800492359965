import {MY_BOXES_BOX_INFO, MY_BOXES_IS_ADD, MY_BOXES_IS_EDIT} from "../actions/types";

const initialState = {
    isAddMyBoxes: false,
    isEditMyBoxes: false,
    boxInfo: null
}


export const myBoxesReducer = (state = initialState, action) => {
    switch (action.type) {
        case MY_BOXES_IS_ADD:
            return {...state, isAddMyBoxes: action?.payload}
        case MY_BOXES_IS_EDIT:
            return {...state, isEditMyBoxes: action?.payload}
        case MY_BOXES_BOX_INFO:
            return {...state, boxInfo: action?.payload}
        default:
            return state
    }
}