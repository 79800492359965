import { React, useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Grid } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DeleteIcon from "@material-ui/icons/Delete";
import { debounce } from "lodash";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "25ch",
    transform: "scale(0.9)",
  },
  inputList: {
    marginTop: "10px",
    listStyleType: "none",
  },
  imgeInput: {
    display: "none",
  },
  uploadButton: {
    marginLeft: "11px",
  },
  uploadedImage: {
    border: "2px solid #D3D3D3",
    borderRadius: "2%",
    width: "80vw",
    height: "50vh",
    maxHeight: "200px",
    maxWidth: "250px",
    "&:hover": {
      border: "2px solid #FFC300",
    },
  },
  uploadImagesContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "5px",
  },
  cancelIcon: {
    position: "absolute",
    right: "-10%",
    top: "-12%",
    zIndex: 1,
  },
  imageListItem: {
    position: "relative",
  },
}));

const ConfirmationCodesInputLayout = ({ codesCallback }) => {
  const classes = useStyles();

  //storing confirmation codes
  const [codesState, setCodesState] = useState({
    inputFieldsCount: 1,
    codes: [],
  });

  const callbackDebounce = useCallback(
    debounce(() => codesCallback(codesState.codes), 500),
    []
  );

  useEffect(() => {
    callbackDebounce();
  }, [codesState]);

  //update confirmation codes inputs
  const handleChange = (index, event) => {
    let tempCodes = codesState.codes;
    if (tempCodes[index] === undefined) {
      tempCodes.push(event.target.value);
    } else {
      tempCodes[index] = event.target.value;
    }

    setCodesState({ ...codesState, codes: tempCodes });
  };

  const handleAddCodeInputField = () => {
    setCodesState({
      ...codesState,
      inputFieldsCount: codesState.inputFieldsCount + 1,
    });
  };

  const removeCode = prop => {
    const tempCodes = codesState.codes;
    tempCodes.splice(prop, 1);
    setCodesState({
      ...codesState,
      codes: tempCodes,
      inputFieldsCount: codesState.inputFieldsCount - 1,
    });
  };

  const createCodeInputFields = () => {
    let elements = [];
    for (let i = 0; i < codesState.inputFieldsCount; i++) {
      elements.push(
        <li key={i} className={classes.inputList}>
          <Grid container driection="row" alignItems="center">
            <Grid item>
              <FormControl className={classes.textField} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Confirmation Code
                </InputLabel>
                <OutlinedInput
                  id={`code-${i}`}
                  type={codesState.showPassword ? "text" : "password"}
                  value={codesState.codes[i] ? codesState.codes[i] : ""}
                  onChange={event => {
                    handleChange(i, event);
                  }}
                  labelWidth={135}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <div>
                {codesState.inputFieldsCount > 1 && (
                  <IconButton
                    aria-label="delete"
                    onClick={() => removeCode(i)}
                    className={classes.removeButton}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                )}
                {/*{i === codesState.inputFieldsCount - 1 && (*/}
                {/*  <IconButton*/}
                {/*    aria-label="add button"*/}
                {/*    onClick={handleAddCodeInputField}*/}
                {/*    className={classes.addButton}*/}
                {/*  >*/}
                {/*    <AddBoxIcon />*/}
                {/*  </IconButton>*/}
                {/*)}*/}
              </div>
            </Grid>
          </Grid>
        </li>
      );
    }

    return elements;
  };

  return (
    <>
      <div>{createCodeInputFields()}</div>
    </>
  );
};

export default ConfirmationCodesInputLayout;
