import Box from "@mui/material/Box";
import {getAccessToken} from "../../Utils/doToken";
import {useParams} from "react-router-dom";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {useEffect, useState} from "react";
import {Button, Container} from "@mui/material";
import Typography from "@mui/material/Typography";
import {MovingOrderDashboardSummary} from "./MovingOrderDashboardSummary";
import Grid from "@mui/material/Grid";
import {MovingOrderDashboardItems} from "./MovingOrderDashboardItems";
import {MovingOrderDashboardPrice} from "./MovingOrderDashboardPrice";
import {MovingOrderDashboardPayment} from "./MovingOrderDashboardPayment";
import {useInterval} from "usehooks-ts";
import {MovingOrderDashboardTransaction} from "./MovingOrderDashboardTransaction";
import {MovingOrderDashboardContact} from "./MovingOrderDashboardContact";
import {MovingOrderDashboardImage} from "./MovingOrderDashboardImage";
import {MovingOrderDashboardCancel} from "./MovingOrderDashboardCancel";
import {MovingOrderDashboardApprovedPrice} from "./MovingOrderDashboardApprovedPrice";
import {useHistory} from "react-router-dom";
import {MovingOrderDashboardTransactionHistory} from "./MovingOrderDashboardTransactionHistory";
import {useSelector} from "react-redux";
import {MovingOrderDashboardDocuments} from "./MovingOrderDashboardDocuments";

const styles = {
    MovingOrderDashboardRoot: {
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        marginBottom: '40px'
    },
    MovingOrderDashboardCard: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        padding: '20px',
        borderRadius: '20px',
        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
        gap: '20px'
    },
}

export const MovingOrderDashboard = () => {

    const ownerRole = useSelector(state => state?.user?.ownerRole);

    const accessToken = getAccessToken("access_token");

    const {orderId} = useParams();

    const history = useHistory();

    const [orderDetails, setOrderDetails] = useState();
    const [downPayment, setDownPayment] = useState();
    const [paidPayment, setPaidPayment] = useState([]);
    const [declinedPayment, setDeclinedPayment] = useState([]);
    const [underReviewPayment, setUnderReviewPayment] = useState([]);
    const [paymentModalOpen, setPaymentModalOpen] = useState(false);
    const [backendOrderStatus, setBackendOrderStatus] = useState(100);
    const [delay] = useState(2000);

    const handlePaymentModalOpen = () => setPaymentModalOpen(true);
    const handlePaymentModalClose = () => setPaymentModalOpen(false);

    const getMovingOrderDetails = async () => {

        let requestURL = ownerRole === 1 ? `${NODE_ROUTE_URI}/movingService/getMovingOrderDashBoardDetails` :
            `${PARTNER_URI}/movingService/getPaidMovingOrderDetails`;

        try {
            const result = await axios({
                method: 'get',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                url: requestURL,
                params: {
                    backendOrderId: orderId
                }
            })

            console.log('order info', result);
            setOrderDetails(result.data);
            const downPayment = result?.data?.backendPayments?.filter(payment => payment?.remark_notes === "Down_Payment");
            const paidPayment = result?.data?.backendPayments?.filter(payment => payment?.payment_status === "CAPTURED_SUCCEEDED" || payment?.payment_status === "UNDER_REVIEW");
            const declinedPayment = result?.data?.backendPayments?.filter(payment => payment?.payment_status === "DECLINE");
            const underReviewPayment = result?.data?.backendPayments?.filter(payment => payment?.payment_status === "UNDER_REVIEW");
            setDownPayment(downPayment[0]);
            setPaidPayment(paidPayment);
            setDeclinedPayment(declinedPayment);
            setUnderReviewPayment(underReviewPayment);

        } catch (e) {
            console.log(e.response);
        }
    }

    const getMovingOrderStatus = async () => {
        let requestURL = `${NODE_ROUTE_URI}/looseItem/getBackendOrderStatusByOrderId/${orderId}`;
        try {
            const result = await axios({
                method: 'get',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                url: requestURL
            })

            const ordersStatus = result.data.orderStatus;
            console.log('order status', ordersStatus);
            setBackendOrderStatus(ordersStatus)

        } catch (e) {
            console.log(e.response);
        }
    }

    useEffect(() => {
        getMovingOrderDetails()
    }, [])

    useEffect(() => {
        backendOrderStatus === 1 && getMovingOrderDetails();
    }, [backendOrderStatus])

    useInterval(
        () => {
            if (orderId) {
                getMovingOrderStatus();
            }
        },
        backendOrderStatus === 100 ? delay : null,
    )

    console.log('[MovingOrderDashboard] orderDetails', orderDetails);

    return (
        <Container maxWidth='xl'>
            <Box sx={styles.MovingOrderDashboardRoot}>
                <Grid container spacing={2}>
                    <Grid item md={8} xs={12}>
                        <Grid container spacing={2}>
                            <Grid item md={12} xs={12}>
                                <MovingOrderDashboardSummary orderDetails={orderDetails}
                                                             handlePaymentOpen={handlePaymentModalOpen}
                                                             getMovingOrderDetails={getMovingOrderDetails}/>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <MovingOrderDashboardTransaction orderDetails={orderDetails}/>
                            </Grid>
                            {
                                orderDetails?.images?.length > 0 &&
                                <Grid item md={12} xs={12}>
                                    <MovingOrderDashboardImage orderDetails={orderDetails}/>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Grid container spacing={2}>
                            {
                                (orderDetails?.shipping_status_id !== 1 && orderDetails?.shipping_status_id !== 2) &&
                                <Grid item md={12} xs={12}>
                                    <MovingOrderDashboardApprovedPrice orderDetails={orderDetails}
                                                                       handlePaymentOpen={handlePaymentModalOpen}
                                                                       downPayment={downPayment}
                                                                       paidPayment={paidPayment}/>
                                </Grid>
                            }
                            {/*<Grid item md={12} xs={12}>*/}
                            {/*    <MovingOrderDashboardPrice orderDetails={orderDetails}*/}
                            {/*                               handlePaymentOpen={handlePaymentModalOpen}*/}
                            {/*                               downPayment={downPayment}/>*/}
                            {/*</Grid>*/}
                            {
                                orderDetails?.status_id === -1 &&
                                <Grid item md={12} xs={12}>
                                    <MovingOrderDashboardCancel/>
                                </Grid>
                            }
                            <Grid item md={12} xs={12}>
                                {ownerRole === 1 ?
                                    <MovingOrderDashboardTransactionHistory orderDetails={orderDetails}
                                                                            paidPayment={paidPayment}
                                                                            declinedPayment={declinedPayment}
                                                                            underReviewPayment={underReviewPayment}/>
                                    :
                                    <MovingOrderDashboardDocuments orderDetails={orderDetails}/>
                                }
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <MovingOrderDashboardContact orderDetails={orderDetails}
                                                             getMovingOrderDetails={getMovingOrderDetails}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}>
                            <Button
                                variant='contained'
                                sx={{
                                    backgroundColor: '#1D8B45',
                                    "&:hover": {
                                        backgroundColor: '#1D8B45',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                onClick={() => history.push('/')}
                            >
                                <Typography style={{
                                    textAlign: 'left',
                                    textTransform: 'none',
                                }}>
                                    Back to Order List
                                </Typography>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}
