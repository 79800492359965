import {
    ButtonBase,
    ClickAwayListener,
    Container,
    Step, StepLabel,
    Stepper,
    Tooltip,
    tooltipClasses,
    useMediaQuery
} from "@mui/material";
import Grid from "@material-ui/core/Grid";
import {useDispatch, useSelector} from "react-redux";
import {Fragment, useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {mapCarrierLogo} from "../../../Utils/Helper";
import Button from "@mui/material/Button";
import {
    clearCallAllPackageCarrier,
    clearShippingPackageRateCreateLabel,
    selectShippingPackageRateCreateLabel,
} from "../../../actions/BusinessShippingPackageAction";
import {useHistory} from "react-router-dom";
import intl from "react-intl-universal";
import HelpIcon from '@mui/icons-material/Help';
import IconButton from "@mui/material/IconButton";
import {styled} from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import {InternationalSteps, QontoConnector, QontoStepIcon, steps} from "../../../Utils/CustomComponent";
import * as React from "react";
import {
    ReactComponent as ChooseCarrierAndServicesIcon
} from "../../../images/NewDesignIcon/ChooseCarrierAndServices.svg";
import {ReactComponent as CheckIcon} from "../../../images/NewDesignIcon/CheckIcon.svg";
import {
    initMovingServiceItemList,
    updateMovingServiceAccountBase, updateMovingServiceBasicRate,
    updateMovingServiceExpressProperty,
    updateMovingServiceMetricUnit,
    updateMovingServiceMoveFromAddress,
    updateMovingServiceMoveToAddress
} from "../../../actions/movingServiceAction";
import {Divider} from "@material-ui/core";

const styles = {
    ShippingPackageCreateLabelRateRoot: {
        backgroundColor: '#FFFFFF',
        // padding: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0'
    },
    ShippingPackageCreateLabelRateHeading: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelRateSubHeading: {
        fontSize: '20px',
        fontWeight: '600',
        paddingLeft: '10px',
        marginTop: "20px"
    },
    ShippingPackageCreateLabelRateOtherBox: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        height: '100%',
        flexDirection: 'column'
    },
    ShippingPackageCreateLabelRateImageBox: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        paddingLeft: '20px',
        gap: '10px',
        flexDirection: 'column'
    },
    ShippingPackageCreateLabelRateOtherBoxMobile: {
        display: 'flex',
        alignItems: 'flex-start',
        paddingLeft: '20px',
        justifyContent: 'center',
        height: '100%',
        flexDirection: 'column'
    },
    ShippingPackageCreateLabelRateListContent: {
        backgroundColor: '#FFFFFF',
        padding: '0 40px',
        marginBottom: '40px',
        borderRadius: '10px',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
}

const LightTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

const GreenTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#1D8B45',
        color: '#FFFFFF',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

export const BusinessShippingPackageCreateLabelRate = () => {

    const dispatch = useDispatch();

    const history = useHistory();

    let metric = useSelector(state => state?.businessShippingPackage?.createLabelUnit);
    let packageList = useSelector(state => state?.businessShippingPackage?.createLabelPackageList);
    let rateList = useSelector(state => state?.businessShippingPackage?.createLabelRate);
    let isCalledAllCarrier = useSelector(state => state?.businessShippingPackage?.isCallAllCarrier);
    let shipFrom = useSelector(state => state?.businessShippingPackage?.createLabelShipFrom);
    let shipTo = useSelector(state => state?.businessShippingPackage?.createLabelShipTo);
    let boxRateList = useSelector(state => state?.movingService?.movingServiceRateList);

    const isMobile = useMediaQuery('(max-width: 600px)');

    const [selectedRate, setSelectedRate] = useState(null);
    const [loading, setLoading] = useState(false);

    const [rate, setRate] = useState([]);
    const [boxRate, setBoxRate] = useState([]);
    const [open, setOpen] = useState(-1);
    const [boxOpen, setBoxOpen] = useState(-1);

    const handleClose = (index) => {
        // console.log('click away', index);
        // console.log('open index', open);
        // setOpen(-1);
        index === open && setOpen(-1);
    };

    const handleOpen = (index) => {
        // console.log('click open', index);
        // console.log('open index', open);
        setOpen(index);
    };

    const handleBoxClose = (index) => {
        // console.log('click away', index);
        // console.log('open index', open);
        // setOpen(-1);
        index === boxOpen && setBoxOpen(-1);
    };

    const handleBoxOpen = (index) => {
        // console.log('click open', index);
        // console.log('open index', open);
        setBoxOpen(index);
    };

    const handleSelect = (rate) => {
        setSelectedRate(rate);
    }

    const handleContinuePlaceOrder = () => {
        const movingFrom = {
            streetAddress: shipFrom?.displayAddress?.text,
            apt: null,
            city: shipFrom?.city,
            province: shipFrom?.province,
            postalCode: shipFrom?.postalCode,
            country: shipFrom?.country,
            propertyType: null,
            lift: 'no',
            storey: '1',
            displayAddress: shipFrom?.displayAddress,
            option: 'schedule',
            warehouse: null,
        }
        const movingTo = {
            streetAddress: shipTo?.displayAddress?.text,
            apt: null,
            city: shipTo?.city,
            province: shipTo?.province,
            postalCode: shipTo?.postalCode,
            country: shipTo?.country,
            propertyType: null,
            lift: 'no',
            storey: '1',
            displayAddress: shipTo?.displayAddress,
            option: 'schedule',
            warehouse: null,
        }
        const updatedItemList = packageList?.map(item => ({
            boxSize: 'customBox',
            cate_id: 100,
            category: 'Box',
            categoryInfo: {category_name: 'Box'},
            description: item.description,
            length: item.length,
            width: item.width,
            height: item.height,
            weight: item.weight,
            lengthUnit: item.lengthUnit,
            weightUnit: item.weightUnit,
            quantity: item.quantity,
            type: 'box',
            itemId: item?.packageId
        }));

        const expressProperty = {
            accountBase: selectedRate?.accountBase?.toLowerCase(),
            shippingType: selectedRate?.shippingType,
            maxVolumeInCM: selectedRate?.maxVolumeInCM,
            maxWeight: selectedRate?.maxWeightKG
        };
        console.log("expressProperty: ", expressProperty);

        dispatch(updateMovingServiceMoveFromAddress(movingFrom));
        dispatch(updateMovingServiceMoveToAddress(movingTo));
        dispatch(initMovingServiceItemList(updatedItemList));
        dispatch(updateMovingServiceMetricUnit(metric));
        dispatch(updateMovingServiceAccountBase(selectedRate?.accountBase?.toLowerCase()));
        dispatch(updateMovingServiceBasicRate(expressProperty));
        dispatch(updateMovingServiceExpressProperty(expressProperty));
        history.push({pathname: '/business/transportation/express'});
        window.scrollTo(0, 0); // Scroll to the top
    }

    const handleStartOver = () => {
        history.push('/business/shipping-package/create-label');
        // dispatch(clearShippingPackageRateCreateLabel());
        // dispatch(clearCallAllPackageCarrier());
    }

    const handleOrderDetails = () => {
        if (selectedRate?.accountBase?.toLowerCase() === 'swyft' || selectedRate?.accountBase?.toLowerCase() === 'gogospeedy') {
            handleContinuePlaceOrder();
        } else {
            history.push('/business/shipping-package/create-label/order-details');
        }
    }

    const handleCrossBorderDetails = () => {
        if (selectedRate?.accountBase?.toLowerCase() === 'swyft' || selectedRate?.accountBase?.toLowerCase() === 'gogospeedy') {
            handleContinuePlaceOrder();
        } else {
            history.push('/business/shipping-package/cross-border-details');
        }
    }

    const handleBeforeUnload = (event) => {

        console.log('reload');
        // Customize the confirmation message
        const message = 'Are you sure you want to leave? Your unsaved changes may be lost.';

        // Set the confirmation message for modern browsers
        event.returnValue = message;

        // For older browsers
        return message;
    };

    useEffect(() => {
        // Add the event listener when the component mounts
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []); // Empty dependency array means this effect runs once when the component mounts

    useEffect(() => {
        dispatch(selectShippingPackageRateCreateLabel(selectedRate));
    }, [selectedRate])

    useEffect(() => {
        // Step 1: Sort the array based on 'total'
        const sortedRate = [...rateList].sort((a, b) => (a?.finalTotalAmount?.value || Infinity) - (b?.finalTotalAmount?.value || Infinity));

        // Step 2: Find the 'FedEx Ground' object
        const fedexIndex = sortedRate.findIndex((item) => item.serviceName === 'Fedex Ground');

        if (fedexIndex !== -1 && fedexIndex !== 0) {
            // Step 4: Calculate the new 'total' for 'lowest'
            const lowestTotal = sortedRate[0]?.finalTotalAmount?.value * 1.1;
            const fedexTotal = sortedRate[fedexIndex]?.finalTotalAmount?.value;

            // Step 4: Check if 'FedEx Ground' is smaller than the lowest object
            if (fedexTotal < lowestTotal) {
                // Step 5: Swap positions of 'FedEx Ground' and the lowest object
                const updatedData = [...sortedRate];
                const temp = updatedData[fedexIndex];
                updatedData.splice(fedexIndex, 1);
                updatedData.unshift(temp);
                setRate(updatedData);
                updatedData[0]?.finalTotalAmount && setSelectedRate(updatedData[0]);
            } else {
                setRate(sortedRate);
                sortedRate[0]?.finalTotalAmount && setSelectedRate(sortedRate[0]);
            }
        } else {
            setRate(sortedRate);
            sortedRate[0]?.finalTotalAmount && setSelectedRate(sortedRate[0]);
        }
    }, [rateList]);

    useEffect(() => {
        // Step 1: Sort the array based on 'total'
        const sortedRate = [...boxRateList].sort((a, b) => (a?.finalTotalAmount?.value || Infinity) - (b?.finalTotalAmount?.value || Infinity));
        setBoxRate(sortedRate);
    }, [boxRateList]);

    useEffect(() => {
        // if (shipFrom?.country === shipTo?.country) {

        if (isCalledAllCarrier) {
            if (isCalledAllCarrier === 4) {
                setLoading(false);
            } else {
                setLoading(true);
            }
        } else {
            setLoading(false);
        }

        // } else {
        //     if (isCalledAllCarrier === 1) {
        //         setLoading(false);
        //     } else {
        //         setLoading(true);
        //     }
        // }
    }, [isCalledAllCarrier])

    // console.log('rate list redux', rateList);
    console.log('[BusinessShippingPackageCreateLabelRate] rate', rate);
    console.log('[BusinessShippingPackageCreateLabelRate] boxRate', boxRate);
    console.log('[BusinessShippingPackageCreateLabelRate] boxRateList', boxRateList);
    console.log('[BusinessShippingPackageCreateLabelRate] selected rate', selectedRate);
    // console.log('shipFrom?.country?.code',shipFrom?.country?.code);
    // console.log('shipTo?.country?.code',shipTo?.country?.code);
    // console.log('is called all carrier', isCalledAllCarrier);
    // console.log('loading', loading);

    if (!shipFrom?.country) {
        return (
            <Container maxWidth='xl'>
                <Box style={styles.ShippingPackageCreateLabelRateRoot}>
                    <Box sx={{
                        display: 'flex',
                        gap: '10px',
                        padding: '20px 20px 0 20px'
                    }}>
                        <ChooseCarrierAndServicesIcon width={25} height={25}/>
                        <Typography style={styles.ShippingPackageCreateLabelRateHeading}>
                            {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.CHOOSE_CARRIER.CARRIER_TITLE')}
                        </Typography>
                    </Box>
                    <Divider/>
                    <Box sx={styles.ShippingPackageCreateLabelRateListContent}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography style={{color: 'red', textAlign: 'left', fontSize: '16px'}}>
                                    You've lost all the data. Please return to the previous page and create a new
                                    shipment.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{display: 'flex', width: '100%', justifyContent: 'flex-end', gap: '20px'}}>
                                    <Button
                                        sx={{
                                            backgroundColor: '#F2BE22',
                                            "&:hover": {
                                                backgroundColor: '#F2BE22',
                                                filter: 'brightness(0.9)'
                                            }
                                        }}
                                        size='large'
                                        variant='contained'
                                        onClick={handleStartOver}
                                        loading={loading}
                                    >
                                        <Typography sx={{textTransform: 'none'}}>
                                            Back
                                        </Typography>
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        )
    }

    if (rate?.length === 0 && loading) {
        return (
            <Container maxWidth='xl'>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography style={styles.ShippingPackageCreateLabelRateHeading}>
                            {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.CHOOSE_CARRIER.CARRIER_TITLE')}
                        </Typography>
                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                            Choose the best carrier for you based on price, delivery time and reliability
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <CircularProgress/>
                    </Grid>
                </Grid>
            </Container>
        )
    }

    return (
        <Container maxWidth='xl'>
            <Stepper alternativeLabel activeStep={1} connector={<QontoConnector/>}>
                {
                    (shipFrom?.country === shipTo?.country ? steps : InternationalSteps).map((label, index) => (
                        <Step key={label}>
                            {/*<StepButton onClick={handleStep(index)}>*/}
                            <StepLabel StepIconComponent={QontoStepIcon}>
                                <Typography sx={{color: index <= 1 ? '#1D8B45' : '#C7C7C7', fontSize: '14px'}}>
                                    {label}
                                </Typography>
                            </StepLabel>
                            {/*</StepButton>*/}
                        </Step>
                    ))
                }
            </Stepper>
            <Box sx={styles.ShippingPackageCreateLabelRateRoot}>
                <Box sx={{
                    display: 'flex',
                    gap: '10px',
                    padding: '20px 20px 0 20px'
                }}>
                    <ChooseCarrierAndServicesIcon width={25} height={25}/>
                    <Typography style={styles.ShippingPackageCreateLabelRateHeading}>
                        {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.CHOOSE_CARRIER.CARRIER_TITLE')}
                    </Typography>
                </Box>
                <Divider/>
                <Box sx={styles.ShippingPackageCreateLabelRateListContent}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}>
                                {
                                    loading ? <CircularProgress/>
                                        :
                                        <>
                                            <Box sx={{display: 'flex', gap: '5px'}}>
                                                <CheckIcon width={20} height={20}/>
                                                <Typography sx={{fontWeight: '600'}}>
                                                    All Rates Retrieved!
                                                </Typography>
                                            </Box>
                                            <Typography sx={{fontSize: '14px'}}>
                                                Choose the best carrier for you based on delivery time and reliability.
                                            </Typography>
                                        </>
                                }
                            </Box>
                        </Grid>
                        {
                            rate?.length > 0 ?
                                <>
                                    {
                                        !isMobile && <>
                                            <Grid item xs={2}>
                                                <Box sx={styles.ShippingPackageCreateLabelRateOtherBox}>
                                                    <Typography sx={styles.ShippingPackageCreateLabelRateSubHeading}>
                                                        {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.CHOOSE_CARRIER.CARRIER')}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Box sx={styles.ShippingPackageCreateLabelRateOtherBox}>
                                                    <Typography sx={styles.ShippingPackageCreateLabelRateSubHeading}>
                                                        Service
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Box sx={styles.ShippingPackageCreateLabelRateOtherBox}>
                                                    <Typography sx={styles.ShippingPackageCreateLabelRateSubHeading}>
                                                        {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.CHOOSE_CARRIER.TIME')}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Box sx={styles.ShippingPackageCreateLabelRateOtherBox}>
                                                    <Typography sx={styles.ShippingPackageCreateLabelRateSubHeading}>
                                                        {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.CHOOSE_CARRIER.PRICE')}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={2}>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                        </>
                                    }
                                    {
                                        rate?.map((each, index) => {
                                            // if (index >= 1) {
                                            return (
                                                <>
                                                    {
                                                        !each?.errorMessage ?
                                                            <Grid item key={index} xs={12}>
                                                                <Box sx={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    gap: '10px',
                                                                    marginBottom: index === 0 && "20px"
                                                                }}>
                                                                    {
                                                                        index === 0 && <Box sx={{
                                                                            display: 'flex',
                                                                            justifyContent: 'flex-start',
                                                                        }}>
                                                                            <Typography sx={{
                                                                                backgroundColor: '#1D8B45',
                                                                                color: '#FFFFFF',
                                                                                fontSize: '14px',
                                                                                padding: '5px 10px',
                                                                                borderRadius: '5px'
                                                                            }}>
                                                                                Recommendation
                                                                            </Typography>
                                                                        </Box>
                                                                    }
                                                                    <ButtonBase sx={{
                                                                        width: '100%',
                                                                        height: '100%',
                                                                        padding: '20px 0',
                                                                        borderRadius: '5px',
                                                                        border: each === selectedRate ? '3px solid #609966' : '1px solid #000000',
                                                                        backgroundColor: index === 0 && '#F3FDE8'
                                                                    }}
                                                                                onClick={() => handleSelect(each)}
                                                                                disabled={loading}
                                                                    >
                                                                        <Box sx={{
                                                                            display: 'flex',
                                                                            flexDirection: 'column',
                                                                            width: '100%',
                                                                            height: '100%',
                                                                        }}>
                                                                            <Grid container spacing={2}>
                                                                                <Grid item md={2} xs={12}>
                                                                                    <Box
                                                                                        sx={styles?.ShippingPackageCreateLabelRateImageBox}>
                                                                                        <img
                                                                                            src={mapCarrierLogo(each?.accountBase)}
                                                                                            alt="logo"
                                                                                            width='60px'/>
                                                                                    </Box>
                                                                                </Grid>
                                                                                <Grid item md={2} xs={12}>
                                                                                    <Box sx={
                                                                                        isMobile ?
                                                                                            styles?.ShippingPackageCreateLabelRateOtherBoxMobile :
                                                                                            styles?.ShippingPackageCreateLabelRateOtherBox
                                                                                    }>
                                                                                        <Typography
                                                                                            style={styles?.ShippingPackageQuickQuoteRateListText}>
                                                                                            {each?.serviceName}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </Grid>
                                                                                <Grid item md={3} xs={12}>
                                                                                    <Box sx={
                                                                                        isMobile ?
                                                                                            styles?.ShippingPackageCreateLabelRateOtherBoxMobile :
                                                                                            styles?.ShippingPackageCreateLabelRateOtherBox
                                                                                    }>
                                                                                        <Typography
                                                                                            style={styles.ShippingPackageQuickQuoteRateListText}>
                                                                                            {each?.shipRange} business
                                                                                            day(s)
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </Grid>
                                                                                <Grid item md={3} xs={12}>
                                                                                    <Box sx={
                                                                                        isMobile ?
                                                                                            styles?.ShippingPackageCreateLabelRateOtherBoxMobile :
                                                                                            styles?.ShippingPackageCreateLabelRateOtherBox
                                                                                    }>
                                                                                        <Box sx={{
                                                                                            display: 'flex',
                                                                                            gap: '5px',
                                                                                            alignItems: 'center'
                                                                                        }}>
                                                                                            <Typography style={{
                                                                                                fontSize: '16px',
                                                                                                fontWeight: '600',
                                                                                            }}>
                                                                                                $ {((+each?.totalCharge?.value) + (+each?.serviceFee?.value)).toFixed(2)}
                                                                                            </Typography>
                                                                                            <ClickAwayListener
                                                                                                onClickAway={() => handleClose(index)}>
                                                                                                <div>
                                                                                                    <LightTooltip
                                                                                                        open={open === index}
                                                                                                        PopperProps={{
                                                                                                            disablePortal: true,
                                                                                                        }}
                                                                                                        disableFocusListener
                                                                                                        disableHoverListener
                                                                                                        disableTouchListener
                                                                                                        onClose={() => handleClose(index)}
                                                                                                        // onOpen={() => handleOpen(index)}
                                                                                                        sx={{
                                                                                                            padding: '0',
                                                                                                            margin: '0'
                                                                                                        }}
                                                                                                        placement="right-start"
                                                                                                        title={
                                                                                                            <Box sx={{
                                                                                                                display: 'flex',
                                                                                                                flexDirection: 'column',
                                                                                                                // width: '350px',
                                                                                                                padding: '10px',
                                                                                                                gap: '15px'
                                                                                                            }}>
                                                                                                                <Grid
                                                                                                                    container
                                                                                                                    spacing={1}>
                                                                                                                    {
                                                                                                                        each?.shipmentCharges?.map((each, index) => {
                                                                                                                            const cost = +each?.value
                                                                                                                            if (cost !== 0) {
                                                                                                                                return (
                                                                                                                                    <Fragment
                                                                                                                                        key={index}>
                                                                                                                                        <Grid
                                                                                                                                            item
                                                                                                                                            xs={8}>
                                                                                                                                            <Typography
                                                                                                                                                style={{
                                                                                                                                                    fontSize: '12px',
                                                                                                                                                    textAlign: 'left'
                                                                                                                                                }}>
                                                                                                                                                {each?.description}
                                                                                                                                            </Typography>
                                                                                                                                        </Grid>
                                                                                                                                        <Grid
                                                                                                                                            item
                                                                                                                                            xs={4}>
                                                                                                                                            <Typography
                                                                                                                                                style={{
                                                                                                                                                    fontSize: '12px',
                                                                                                                                                    textAlign: 'right'
                                                                                                                                                }}>
                                                                                                                                                $ {(+each?.value)?.toFixed(2)}
                                                                                                                                            </Typography>
                                                                                                                                        </Grid>
                                                                                                                                    </Fragment>
                                                                                                                                )
                                                                                                                            }
                                                                                                                        })
                                                                                                                    }
                                                                                                                    {
                                                                                                                        (+each?.serviceFee?.value) > 0 &&
                                                                                                                        <>
                                                                                                                            <Grid
                                                                                                                                item
                                                                                                                                xs={8}>
                                                                                                                                <Typography
                                                                                                                                    style={{
                                                                                                                                        fontSize: '12px',
                                                                                                                                        textAlign: 'left'
                                                                                                                                    }}>
                                                                                                                                    Service
                                                                                                                                    Fee
                                                                                                                                </Typography>
                                                                                                                            </Grid>
                                                                                                                            <Grid
                                                                                                                                item
                                                                                                                                xs={4}>
                                                                                                                                <Typography
                                                                                                                                    style={{
                                                                                                                                        fontSize: '12px',
                                                                                                                                        textAlign: 'right'
                                                                                                                                    }}>
                                                                                                                                    $ {(+each?.serviceFee?.value).toFixed(2)}
                                                                                                                                </Typography>
                                                                                                                            </Grid>
                                                                                                                        </>
                                                                                                                    }
                                                                                                                    <Grid
                                                                                                                        item
                                                                                                                        xs={8}>
                                                                                                                        <Typography
                                                                                                                            style={{
                                                                                                                                fontSize: '12px',
                                                                                                                                textAlign: 'left',
                                                                                                                                color: '#1D8B45',
                                                                                                                                fontWeight: '600'
                                                                                                                            }}>
                                                                                                                            Total
                                                                                                                        </Typography>
                                                                                                                    </Grid>
                                                                                                                    <Grid
                                                                                                                        item
                                                                                                                        xs={4}>
                                                                                                                        <Typography
                                                                                                                            style={{
                                                                                                                                fontSize: '12px',
                                                                                                                                textAlign: 'right',
                                                                                                                                color: '#1D8B45',
                                                                                                                                fontWeight: '600'
                                                                                                                            }}>
                                                                                                                            $ {((+each?.totalCharge?.value) + (+each?.serviceFee?.value)).toFixed(2)}
                                                                                                                        </Typography>
                                                                                                                    </Grid>
                                                                                                                </Grid>
                                                                                                                {/*<Typography*/}
                                                                                                                {/*    style={{*/}
                                                                                                                {/*        fontSize: '12px',*/}
                                                                                                                {/*        textAlign: 'left'*/}
                                                                                                                {/*    }}>*/}
                                                                                                                {/*    For*/}
                                                                                                                {/*    domestic*/}
                                                                                                                {/*    shipments,*/}
                                                                                                                {/*    there*/}
                                                                                                                {/*    are*/}
                                                                                                                {/*    no*/}
                                                                                                                {/*    tax*/}
                                                                                                                {/*    and*/}
                                                                                                                {/*    duty*/}
                                                                                                                {/*    to*/}
                                                                                                                {/*    be*/}
                                                                                                                {/*    paid*/}
                                                                                                                {/*</Typography>*/}
                                                                                                            </Box>
                                                                                                        }
                                                                                                    >
                                                                                                        <IconButton
                                                                                                            onClick={() => handleOpen(index)}>
                                                                                                            <HelpIcon
                                                                                                                sx={{color: '#1D8B45'}}/>
                                                                                                        </IconButton>
                                                                                                    </LightTooltip>
                                                                                                </div>
                                                                                            </ClickAwayListener>
                                                                                        </Box>
                                                                                        {
                                                                                            each?.regularPriceExcludeTax?.value > ((+each?.totalCharge?.value) + (+each?.serviceFee?.value)) &&
                                                                                            <Typography style={{
                                                                                                fontSize: '14px',
                                                                                                textDecoration: 'line-through',
                                                                                            }}>
                                                                                                Reg.
                                                                                                ${each?.regularPriceExcludeTax?.value}
                                                                                            </Typography>
                                                                                        }
                                                                                    </Box>
                                                                                </Grid>
                                                                                <Grid item md={2} xs={12}>
                                                                                    <Box sx={
                                                                                        isMobile ?
                                                                                            styles?.ShippingPackageCreateLabelRateOtherBoxMobile :
                                                                                            styles?.ShippingPackageCreateLabelRateOtherBox
                                                                                    }>
                                                                                        {selectedRate === each ?
                                                                                            <Button
                                                                                                variant='contained'
                                                                                                size='large'
                                                                                                color='success'
                                                                                                disabled={loading}
                                                                                            >
                                                                                                {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.CHOOSE_CARRIER.SELECTED')}
                                                                                            </Button>
                                                                                            :
                                                                                            <Button
                                                                                                variant='outlined'
                                                                                                size='large'
                                                                                                color='success'
                                                                                                onClick={() => handleSelect(each)}
                                                                                                disabled={loading}
                                                                                            >
                                                                                                {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.CHOOSE_CARRIER.SELECT')}
                                                                                            </Button>
                                                                                        }
                                                                                    </Box>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Box>
                                                                    </ButtonBase>
                                                                </Box>
                                                            </Grid>
                                                            :
                                                            each?.statusCode === 200 &&
                                                            <Grid item key={index} xs={12}>
                                                                <Box sx={{
                                                                    padding: '20px 0',
                                                                    borderRadius: '5px',
                                                                    border: each === selectedRate ? '3px solid #609966' : '1px solid #000000',
                                                                }}>
                                                                    <Grid container spacing={4}>
                                                                        <Grid item md={2} xs={3}>
                                                                            <Box
                                                                                sx={styles?.ShippingPackageCreateLabelRateImageBox}>
                                                                                <img
                                                                                    src={mapCarrierLogo(each?.errorAccountBase)}
                                                                                    alt="logo"
                                                                                    width='60px'/>
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid item md={3} xs={9}>
                                                                            <Box
                                                                                sx={styles?.ShippingPackageCreateLabelRateOtherBox}>
                                                                                <Typography
                                                                                    style={styles?.ShippingPackageQuickQuoteRateListText}>
                                                                                    {each?.serviceName}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid item md={7} xs={12}>
                                                                            <Box
                                                                                sx={styles?.ShippingPackageCreateLabelRateOtherBox}>
                                                                                <Typography
                                                                                    style={{
                                                                                        textAlign: 'left',
                                                                                        color: '#FF0303'
                                                                                    }}>
                                                                                    {each?.errorMessage}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Box>
                                                            </Grid>
                                                    }
                                                </>
                                            )
                                            // }
                                        })
                                    }
                                </>
                                :
                                <Grid item xs={12}>
                                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                                        <Typography style={{color: 'red', textAlign: 'left', fontSize: '16px'}}>
                                            {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.ERROR_MESSAGE')}
                                        </Typography>
                                        <Box sx={{
                                            display: 'flex',
                                            width: '100%',
                                            justifyContent: 'flex-end',
                                            gap: '20px'
                                        }}>
                                            <LoadingButton
                                                size='large'
                                                variant='contained'
                                                onClick={handleStartOver}
                                                loading={loading}
                                            >
                                                <Typography sx={{textTransform: 'none'}}>
                                                    Back
                                                </Typography>
                                            </LoadingButton>
                                        </Box>
                                    </Box>
                                </Grid>
                        }
                        {/*{*/}
                        {/*    boxRate?.length > 0 &&*/}
                        {/*    <>*/}
                        {/*        <Divider />*/}
                        {/*        <Grid item xs={12} md={12}>*/}
                        {/*            <Divider sx={{ margin: "10px" }} />*/}
                        {/*            <Typography style={styles.ShippingPackageCreateLabelRateSubHeading}>*/}
                        {/*                Local Partner Carriers*/}
                        {/*            </Typography>*/}
                        {/*        </Grid>*/}
                        {/*        {*/}
                        {/*            boxRate?.map((each, index) => (*/}
                        {/*                <Grid item xs={12} key={index}>*/}
                        {/*                    <Box sx={{*/}
                        {/*                        display: 'flex',*/}
                        {/*                        flexDirection: 'column',*/}
                        {/*                        gap: '10px',*/}
                        {/*                    }}>*/}
                        {/*                        <Grid item md={12} xs={12}>*/}
                        {/*                            <Typography*/}
                        {/*                                style={styles?.ShippingPackageQuickQuoteRateListText}>*/}
                        {/*                                Shipping By {each?.shippingType || 'PIECE'}*/}
                        {/*                            </Typography>*/}
                        {/*                        </Grid>*/}
                        {/*                        <ButtonBase sx={{*/}
                        {/*                            width: '100%',*/}
                        {/*                            height: '100%',*/}
                        {/*                            // backgroundColor: index === 0 ? "#F3FDE8" : "#FFFFFF",*/}
                        {/*                            border: selectedRate === each ? '3px solid #609966' : '1px solid #000000',*/}
                        {/*                            padding: '20px 0',*/}
                        {/*                            borderRadius: '5px'*/}
                        {/*                        }}*/}
                        {/*                                    onClick={() => handleSelect(each)}*/}
                        {/*                                    disabled={loading}*/}
                        {/*                        >*/}
                        {/*                            <Box sx={{*/}
                        {/*                                display: 'flex',*/}
                        {/*                                flexDirection: 'column',*/}
                        {/*                                width: '100%',*/}
                        {/*                                height: '100%'*/}
                        {/*                            }}>*/}
                        {/*                                <Grid container spacing={2}>*/}
                        {/*                                    <Grid item md={2} xs={3}>*/}
                        {/*                                        <Box*/}
                        {/*                                            sx={styles?.ShippingPackageCreateLabelRateImageBox}>*/}
                        {/*                                            <img*/}
                        {/*                                                src={"https://storage.googleapis.com/uucargo-resource/image/icon/UUCargoLogo.png"}*/}
                        {/*                                                alt="logo"*/}
                        {/*                                                width='60px'/>*/}
                        {/*                                        </Box>*/}
                        {/*                                    </Grid>*/}
                        {/*                                    <Grid item md={2} xs={9}>*/}
                        {/*                                        <Box sx={*/}
                        {/*                                            isMobile ?*/}
                        {/*                                                styles?.ShippingPackageCreateLabelRateOtherBoxMobile :*/}
                        {/*                                                styles?.ShippingPackageCreateLabelRateOtherBox*/}
                        {/*                                        }>*/}
                        {/*                                            <Typography*/}
                        {/*                                                style={styles?.ShippingPackageQuickQuoteRateListText}>*/}
                        {/*                                                /!*{each?.accountBase}*!/*/}
                        {/*                                                UUCARGO*/}
                        {/*                                            </Typography>*/}
                        {/*                                        </Box>*/}
                        {/*                                    </Grid>*/}
                        {/*                                    <Grid item md={3} xs={12}>*/}
                        {/*                                        <Box sx={*/}
                        {/*                                            isMobile ?*/}
                        {/*                                                styles?.ShippingPackageCreateLabelRateOtherBoxMobile :*/}
                        {/*                                                styles?.ShippingPackageCreateLabelRateOtherBox*/}
                        {/*                                        }>*/}
                        {/*                                            <Typography*/}
                        {/*                                                style={styles.ShippingPackageQuickQuoteRateListText}>*/}
                        {/*                                                1 business day(s)*/}
                        {/*                                            </Typography>*/}
                        {/*                                        </Box>*/}
                        {/*                                    </Grid>*/}
                        {/*                                    <Grid item md={3} xs={12}>*/}
                        {/*                                        <Box sx={*/}
                        {/*                                            isMobile ?*/}
                        {/*                                                styles?.ShippingPackageCreateLabelRateOtherBoxMobile :*/}
                        {/*                                                styles?.ShippingPackageCreateLabelRateOtherBox*/}
                        {/*                                        }>*/}
                        {/*                                            <Box sx={{*/}
                        {/*                                                display: 'flex',*/}
                        {/*                                                gap: '5px',*/}
                        {/*                                                alignItems: 'center'*/}
                        {/*                                            }}>*/}
                        {/*                                                <Typography style={{*/}
                        {/*                                                    fontSize: '16px',*/}
                        {/*                                                    fontWeight: '600',*/}
                        {/*                                                }}>*/}
                        {/*                                                    $ {(each?.totalCharge)?.toFixed(2)}*/}
                        {/*                                                </Typography>*/}
                        {/*                                                <ClickAwayListener*/}
                        {/*                                                    onClickAway={() => handleBoxClose(index)}>*/}
                        {/*                                                    <div>*/}
                        {/*                                                        <LightTooltip*/}
                        {/*                                                            open={boxOpen === index}*/}
                        {/*                                                            PopperProps={{*/}
                        {/*                                                                disablePortal: true,*/}
                        {/*                                                            }}*/}
                        {/*                                                            disableFocusListener*/}
                        {/*                                                            disableHoverListener*/}
                        {/*                                                            disableTouchListener*/}
                        {/*                                                            onClose={() => handleBoxClose(index)}*/}
                        {/*                                                            sx={{*/}
                        {/*                                                                padding: '0',*/}
                        {/*                                                                margin: '0'*/}
                        {/*                                                            }}*/}
                        {/*                                                            placement="right-start"*/}
                        {/*                                                            title={*/}
                        {/*                                                                <Box sx={{*/}
                        {/*                                                                    display: 'flex',*/}
                        {/*                                                                    flexDirection: 'column',*/}
                        {/*                                                                    width: '250px',*/}
                        {/*                                                                    gap: '15px'*/}
                        {/*                                                                }}>*/}
                        {/*                                                                    <Grid container*/}
                        {/*                                                                          spacing={1}>*/}
                        {/*                                                                        {*/}
                        {/*                                                                            +each?.boxFreightCharge > 0 &&*/}
                        {/*                                                                            <>*/}
                        {/*                                                                                <Grid item xs={8}>*/}
                        {/*                                                                                    <Typography*/}
                        {/*                                                                                        style={{*/}
                        {/*                                                                                            fontSize: '12px',*/}
                        {/*                                                                                            textAlign: 'left'*/}
                        {/*                                                                                        }}>*/}
                        {/*                                                                                        {intl.get('COST.FREIGHT_CHARGE')}*/}
                        {/*                                                                                    </Typography>*/}
                        {/*                                                                                </Grid>*/}
                        {/*                                                                                <Grid item xs={4}>*/}
                        {/*                                                                                    <Typography*/}
                        {/*                                                                                        style={{*/}
                        {/*                                                                                            fontSize: '12px',*/}
                        {/*                                                                                            textAlign: 'right'*/}
                        {/*                                                                                        }}>*/}
                        {/*                                                                                        $ {(each?.boxFreightCharge)?.toFixed(2)}*/}
                        {/*                                                                                    </Typography>*/}
                        {/*                                                                                </Grid>*/}
                        {/*                                                                            </>*/}
                        {/*                                                                        }*/}
                        {/*                                                                        {*/}
                        {/*                                                                            +each?.boxPickupCharge > 0 &&*/}
                        {/*                                                                            <>*/}
                        {/*                                                                                <Grid item xs={8}>*/}
                        {/*                                                                                    <Typography*/}
                        {/*                                                                                        style={{*/}
                        {/*                                                                                            fontSize: '12px',*/}
                        {/*                                                                                            textAlign: 'left'*/}
                        {/*                                                                                        }}>*/}
                        {/*                                                                                        {intl.get('COST.PICKUP_CHARGE')}*/}
                        {/*                                                                                    </Typography>*/}
                        {/*                                                                                </Grid>*/}
                        {/*                                                                                <Grid item xs={4}>*/}
                        {/*                                                                                    <Typography*/}
                        {/*                                                                                        style={{*/}
                        {/*                                                                                            fontSize: '12px',*/}
                        {/*                                                                                            textAlign: 'right'*/}
                        {/*                                                                                        }}>*/}
                        {/*                                                                                        $ {(each?.boxPickupCharge)?.toFixed(2)}*/}
                        {/*                                                                                    </Typography>*/}
                        {/*                                                                                </Grid>*/}
                        {/*                                                                            </>*/}
                        {/*                                                                        }*/}
                        {/*                                                                        {*/}
                        {/*                                                                            +each?.boxDeliverCharge > 0 &&*/}
                        {/*                                                                            <>*/}
                        {/*                                                                                <Grid item xs={8}>*/}
                        {/*                                                                                    <Typography*/}
                        {/*                                                                                        style={{*/}
                        {/*                                                                                            fontSize: '12px',*/}
                        {/*                                                                                            textAlign: 'left'*/}
                        {/*                                                                                        }}>*/}
                        {/*                                                                                        {intl.get('COST.PICKUP_CHARGE')}*/}
                        {/*                                                                                    </Typography>*/}
                        {/*                                                                                </Grid>*/}
                        {/*                                                                                <Grid item xs={4}>*/}
                        {/*                                                                                    <Typography*/}
                        {/*                                                                                        style={{*/}
                        {/*                                                                                            fontSize: '12px',*/}
                        {/*                                                                                            textAlign: 'right'*/}
                        {/*                                                                                        }}>*/}
                        {/*                                                                                        $ {(each?.boxDeliverCharge)?.toFixed(2)}*/}
                        {/*                                                                                    </Typography>*/}
                        {/*                                                                                </Grid>*/}
                        {/*                                                                            </>*/}
                        {/*                                                                        }*/}
                        {/*                                                                        {*/}
                        {/*                                                                            +each?.specialServicesCharges > 0 &&*/}
                        {/*                                                                            <>*/}
                        {/*                                                                                <Grid item xs={8}>*/}
                        {/*                                                                                    <Typography*/}
                        {/*                                                                                        style={{*/}
                        {/*                                                                                            fontSize: '12px',*/}
                        {/*                                                                                            textAlign: 'left'*/}
                        {/*                                                                                        }}>*/}
                        {/*                                                                                        {intl.get('COST.ADDITIONAL_SERVICE_CHARGE')}*/}
                        {/*                                                                                    </Typography>*/}
                        {/*                                                                                </Grid>*/}
                        {/*                                                                                <Grid item xs={4}>*/}
                        {/*                                                                                    <Typography*/}
                        {/*                                                                                        style={{*/}
                        {/*                                                                                            fontSize: '12px',*/}
                        {/*                                                                                            textAlign: 'right'*/}
                        {/*                                                                                        }}>*/}
                        {/*                                                                                        $ {(each?.specialServicesCharges)?.toFixed(2)}*/}
                        {/*                                                                                    </Typography>*/}
                        {/*                                                                                </Grid>*/}
                        {/*                                                                            </>*/}
                        {/*                                                                        }*/}
                        {/*                                                                        <Grid item xs={8}>*/}
                        {/*                                                                            <Typography*/}
                        {/*                                                                                style={{*/}
                        {/*                                                                                    fontSize: '12px',*/}
                        {/*                                                                                    textAlign: 'left',*/}
                        {/*                                                                                    color: '#1D8B45',*/}
                        {/*                                                                                    fontWeight: '600'*/}
                        {/*                                                                                }}>*/}
                        {/*                                                                                Total*/}
                        {/*                                                                            </Typography>*/}
                        {/*                                                                        </Grid>*/}
                        {/*                                                                        <Grid item xs={4}>*/}
                        {/*                                                                            <Typography*/}
                        {/*                                                                                style={{*/}
                        {/*                                                                                    fontSize: '12px',*/}
                        {/*                                                                                    textAlign: 'right',*/}
                        {/*                                                                                    color: '#1D8B45',*/}
                        {/*                                                                                    fontWeight: '600'*/}
                        {/*                                                                                }}>*/}
                        {/*                                                                                $ {(each?.totalCharge)?.toFixed(2)}*/}
                        {/*                                                                            </Typography>*/}
                        {/*                                                                        </Grid>*/}
                        {/*                                                                    </Grid>*/}
                        {/*                                                                </Box>*/}
                        {/*                                                            }*/}
                        {/*                                                        >*/}
                        {/*                                                            <IconButton*/}
                        {/*                                                                onClick={() => handleBoxOpen(index)}>*/}
                        {/*                                                                <HelpIcon*/}
                        {/*                                                                    style={{color: '#1D8B45'}}/>*/}
                        {/*                                                            </IconButton>*/}
                        {/*                                                        </LightTooltip>*/}
                        {/*                                                    </div>*/}
                        {/*                                                </ClickAwayListener>*/}
                        {/*                                            </Box>*/}
                        {/*                                            /!*{each?.regularPriceExcludeTax?.value > ((+each?.totalCharge?.value) + (+each?.serviceFee?.value)) &&*!/*/}
                        {/*                                            /!*    <Typography style={{*!/*/}
                        {/*                                            /!*        fontSize: '14px',*!/*/}
                        {/*                                            /!*        textDecoration: 'line-through',*!/*/}
                        {/*                                            /!*    }}>*!/*/}
                        {/*                                            /!*        Reg.*!/*/}
                        {/*                                            /!*        ${each?.regularPriceExcludeTax?.value}*!/*/}
                        {/*                                            /!*    </Typography>*!/*/}
                        {/*                                            /!*}*!/*/}
                        {/*                                        </Box>*/}
                        {/*                                    </Grid>*/}
                        {/*                                    <Grid item md={2} xs={12}>*/}
                        {/*                                        <Box sx={*/}
                        {/*                                            isMobile ?*/}
                        {/*                                                styles?.ShippingPackageCreateLabelRateOtherBoxMobile :*/}
                        {/*                                                styles?.ShippingPackageCreateLabelRateOtherBox*/}
                        {/*                                        }>*/}
                        {/*                                            {selectedRate === each ?*/}
                        {/*                                                <Button*/}
                        {/*                                                    variant='contained'*/}
                        {/*                                                    size='large'*/}
                        {/*                                                    color='success'*/}
                        {/*                                                    disabled={loading}*/}
                        {/*                                                >*/}
                        {/*                                                    {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.CHOOSE_CARRIER.SELECTED')}*/}
                        {/*                                                </Button>*/}
                        {/*                                                :*/}
                        {/*                                                <Button*/}
                        {/*                                                    variant='outlined'*/}
                        {/*                                                    size='large'*/}
                        {/*                                                    color='success'*/}
                        {/*                                                    onClick={() => handleSelect(each)}*/}
                        {/*                                                    disabled={loading}*/}
                        {/*                                                >*/}
                        {/*                                                    {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.CHOOSE_CARRIER.SELECT')}*/}
                        {/*                                                </Button>*/}
                        {/*                                            }*/}
                        {/*                                        </Box>*/}
                        {/*                                    </Grid>*/}
                        {/*                                </Grid>*/}
                        {/*                            </Box>*/}
                        {/*                        </ButtonBase>*/}
                        {/*                        /!*{*!/*/}
                        {/*                        /!*    selectedRate === 10 &&*!/*/}
                        {/*                        /!*    <Box sx={{marginTop: '20px'}}>*!/*/}
                        {/*                        /!*        <Box sx={{display: 'flex', width: '100%', justifyContent: 'flex-end', gap: '20px'}}>*!/*/}
                        {/*                        /!*            <Button*!/*/}
                        {/*                        /!*                variant='contained'*!/*/}
                        {/*                        /!*                color='success'*!/*/}
                        {/*                        /!*                size='large'*!/*/}
                        {/*                        /!*                // onClick={handleContinuePlaceOrder}*!/*/}
                        {/*                        /!*                disabled={loading}*!/*/}
                        {/*                        /!*            >*!/*/}
                        {/*                        /!*                Continue Place Order*!/*/}
                        {/*                        /!*            </Button>*!/*/}
                        {/*                        /!*        </Box>*!/*/}
                        {/*                        /!*    </Box>*!/*/}
                        {/*                        /!*}*!/*/}
                        {/*                    </Box>*/}
                        {/*                </Grid>*/}
                        {/*            ))*/}
                        {/*        }*/}
                        {/*    </>*/}
                        {/*}*/}
                        <Grid item xs={12} md={12}>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Typography style={{color: 'red'}}>
                                    *
                                </Typography>
                                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                    {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.CHOOSE_CARRIER.MESSAGE')} Rates may be
                                    changed for the variable DG class.
                                </Typography>
                                <GreenTooltip
                                    placement="right-start"
                                    title={<Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}>
                                        <Typography
                                            style={{
                                                fontSize: '12px',
                                                textAlign: 'left',
                                                paddingBottom: '10px'
                                            }}>
                                            Be aware carriers may charge extra for:
                                        </Typography>
                                        <Typography
                                            style={{
                                                fontSize: '12px',
                                                textAlign: 'left'
                                            }}>
                                            -Unmarked residential addresses;
                                        </Typography>
                                        <Typography
                                            style={{
                                                fontSize: '12px',
                                                textAlign: 'left'
                                            }}>
                                            -Incorrect dimensions/weight;
                                        </Typography>
                                        <Typography
                                            style={{
                                                fontSize: '12px',
                                                textAlign: 'left'
                                            }}>
                                            -Wrong freight class;
                                        </Typography>
                                        <Typography
                                            style={{
                                                fontSize: '12px',
                                                textAlign: 'left'
                                            }}>
                                            -Multiple pickup tries;
                                        </Typography>
                                        <Typography
                                            style={{
                                                fontSize: '12px',
                                                textAlign: 'left'
                                            }}>
                                            -Special handling packaging
                                        </Typography>
                                    </Box>}
                                >
                                    <IconButton>
                                        <HelpIcon
                                            sx={{color: '#1D8B45', fontSize: '20px'}}/>
                                    </IconButton>
                                </GreenTooltip>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Grid container spacing={2}>
                {
                    rate?.length > 0 ?
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'flex-end',
                                gap: '20px',
                                marginBottom: '40px'
                            }}>
                                <LoadingButton
                                    size='large'
                                    variant='contained'
                                    onClick={handleStartOver}
                                    sx={{
                                        backgroundColor: '#F2BE22',
                                        "&:hover": {
                                            backgroundColor: '#F2BE22',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    loading={loading}
                                >
                                    <Typography sx={{textTransform: 'none'}}>
                                        Back
                                    </Typography>
                                </LoadingButton>
                                {
                                    selectedRate && (selectedRate?.isCrossBoarder ?
                                            <LoadingButton
                                                size='large'
                                                color="success"
                                                variant='contained'
                                                // disabled={!selectedRate}
                                                loading={loading}
                                                onClick={handleCrossBorderDetails}
                                            >
                                                <Typography sx={{textTransform: 'none'}}>
                                                    Next
                                                </Typography>
                                            </LoadingButton>
                                            :
                                            <LoadingButton
                                                size='large'
                                                color="success"
                                                variant='contained'
                                                // disabled={!selectedRate}
                                                loading={loading}
                                                onClick={handleOrderDetails}
                                            >
                                                <Typography sx={{textTransform: 'none'}}>
                                                    Next
                                                </Typography>
                                            </LoadingButton>
                                    )
                                }
                            </Box>
                        </Grid>
                        :
                        <Grid item xs={12}>
                            <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                                <Typography style={{color: 'red', textAlign: 'left', fontSize: '16px'}}>
                                    There is no available package rate based on the information you provided.
                                    Please
                                    ensure that you have provided valid and accurate information and try again.
                                    If
                                    you
                                    continue to experience issues, your account may be forbidden from accessing
                                    rates.
                                    Please contact op@uucargo.ca for assistance.
                                </Typography>
                                <Box sx={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'flex-end',
                                    gap: '20px'
                                }}>
                                    <Button
                                        sx={{
                                            backgroundColor: '#F2BE22',
                                            "&:hover": {
                                                backgroundColor: '#F2BE22',
                                                filter: 'brightness(0.9)'
                                            }
                                        }}
                                        size='large'
                                        variant='contained'
                                        onClick={handleStartOver}
                                        loading={loading}
                                    >
                                        <Typography sx={{textTransform: 'none'}}>
                                            Back
                                        </Typography>
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                }
            </Grid>
        </Container>
    )
}