import React, { useState, useEffect } from "react";
// import { useSelector } from "react-redux";
import { Button, Box } from "@material-ui/core";
import produce from "immer";
// import { Paper } from '@material-ui/core'
// import orange from '@material-ui/core/colors/orange';
// import { makeStyles } from "@material-ui/core/styles";
// import useMediaQuery from '@material-ui/core/useMediaQuery';
// import { Alert } from '@material-ui/lab';

import PropTypes from "prop-types";

import HscodeInput from "./hscodeInput";
import HscodeInputField from "../../bolInputField";

const defaultShippingItem = {
  hscode: "",
  package: "",
  weight: null,
  measurement: null,
  hscodeDescription: "",
  goodsDescription: "",
  containerType: "",
};

const BolItemInput = ({ bolItem, updateItemList, edit = false, onCancel }) => {
  // const dispatch = useDispatch();
  // const shippingItems = useSelector((state) => state.shipping.shippingItems);

  const [userInputError, setUserInputError] = useState(false);
  // const classes = useStyles();

  const [shippingItem, setShippingItem] = useState(defaultShippingItem);

  const handleItemChange = (fieldName) => (e) => {
    const value = e.target.value;
    setShippingItem(
      produce((draft) => {
        draft[fieldName] = value;
      })
    );
  };

  useEffect(() => {
    if (bolItem) {
      setShippingItem(bolItem);
    }
  }, [bolItem]);

  const handleItemChangeFromSelect = (fieldName) => (_value) => {
    setShippingItem(
      produce((draft) => {
        draft[fieldName] = _value;
      })
    );
  };

  const handleHscodeChange = (hscodeItem) => {
    handleItemChangeFromSelect("hscode")(hscodeItem.hscode || "");
    handleItemChangeFromSelect("hscodeDescription")(hscodeItem.desc || "");
  };

  const handleDataChange = () => {
    if (edit) {
      // dispatch(updateShippingItem({ newItem }));
      updateItemList(
        produce((draft) => {
          const index = draft.findIndex(
            (item) => item.hscode === shippingItem.hscode
          );
          if (index !== -1) draft[index] = shippingItem;
        })
      );
      //   setErrorMsg("");
      onCancel();
    } else {
      // dispatch(addShippingItem({ newItem }));
      updateItemList(
        produce((draft) => {
          draft.push(shippingItem);
        })
      );
      setShippingItem(defaultShippingItem);
    }
  };

  return (
    <Box>
      <Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box>
            <HscodeInput handleItemChange={handleHscodeChange} />
            {shippingItem.hscodeDescription ? (
              <>
                <Box mr={2} width="580px">
                  Hscode * : {shippingItem.hscode}
                </Box>
                <Box width="580px" mt={1}>
                  {shippingItem.hscodeDescription || ""}
                </Box>
              </>
            ) : (
              <Box mb={4} />
            )}
          </Box>
        </Box>
        <Box
          pl={1}
          color="red"
          visibility={
            userInputError && shippingItem.hscode === "" ? "visible" : "hidden"
          }
        >
          Cannot be emtpy
        </Box>
      </Box>

      <Box display="flex">
        <Box mr={4} flexBasis="50%">
          <HscodeInputField
            error={userInputError && shippingItem.package === ""}
            value={shippingItem.package}
            title="Number and Types of Package"
            mandatory={true}
            onChange={handleItemChange("package")}
            placeholderValue="E.g., 6 packages(s)"
          />
        </Box>

        <Box mr={1} flexBasis="50%">
          <HscodeInputField
            error={userInputError && shippingItem.weight === ""}
            value={shippingItem.weight}
            title="Gross Weight(kg)"
            mandatory={true}
            onChange={handleItemChange("weight")}
          />
        </Box>
      </Box>

      <Box display="flex">
        <Box mr={4} flexBasis="50%">
          <Box>
            <HscodeInputField
              error={userInputError && shippingItem.measurement === ""}
              value={shippingItem.measurement}
              title="Measurement(CBM)"
              mandatory={true}
              onChange={handleItemChange("measurement")}
            />
          </Box>
          <Box>
            <HscodeInputField
              value={shippingItem.containerType}
              title="Container Type & Number/Seal No/Service"
              onChange={handleItemChange("containerType")}
              placeholderValue="40HD/FSCU8265366/EMCMQV9631/CY-CY"
            />
          </Box>
        </Box>
        <Box mr={1} flexBasis="50%">
          <HscodeInputField
            value={shippingItem.goodsDescription}
            title="Description of Goods"
            onChange={handleItemChange("goodsDescription")}
            rows={6}
            multiline
          />
        </Box>
      </Box>

      <Box mb={2} mt={2} display="flex" justifyContent="center">
        {edit && (
          <Box mr={2}>
            <Button variant="outlined" color="default" onClick={onCancel}>
              CANCEL
            </Button>
          </Box>
        )}

        <Box ml={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDataChange}
            style={{
              textTransform: "unset",
            }}
          >
            {edit ? "UPDATE" : "Add to the list"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

// const theme = createMuiTheme({
//     palette: {
//         primary: { light: orange[300], main: orange[500], dark: orange[700] }
//     }
// })

// const useStyles = makeStyles((theme) => ({
//   paper: {
//     backgroundColor: theme.palette.background.paper,
//     borderRadius: "20px",
//     padding: theme.spacing(2, 4, 3),
//     overflow: "hidden",
//   },
//   input: {
//     color: "grey",
//     width: "90%",
//   },
//   label: {
//     flexDirection: "column",
//   },
//   icon: {
//     fontSize: "32px !important",
//     marginBottom: theme.spacing(1),
//   },
//   list: {
//     width: "100%",
//     backgroundColor: theme.palette.background.paper,
//     boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)",
//   },
//   backButton: {
//     marginRight: theme.spacing(4),
//     backgroundColor: "white",
//   },
//   subTitle: {
//     color: "darkgreen",
//     fontWeight: 600,
//     fontSize: "1.2rem",
//     fontFamily: "Montserrat",
//   },
// }));

BolItemInput.propTypes = {
  userInputError: PropTypes.bool.isRequired,
  addItem: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
};

export default BolItemInput;
