import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import produce from "immer";
import {
  makeStyles,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import LoadAndDeliverShipmentsLayout from "./LoadAndDeliverShipmentsLayout";
import ConfirmationCodesInputLayout from "./ConfirmationCodesInputLayout";
import DriverUploadImages from "./DriverUploadImages";
import axios from "axios";
import {
  DRIVER_DELIVER_SHIPMENT, DRIVER_DELIVER_SHIPMENTS_TASK_WITH_PACKAGES,
  DRIVER_UPLOAD_SHIPMENTS_IMAGES,
} from "../../Utils/apiUrl";
import { getTokenFromCookie } from "../../Utils/doToken";
import { connect } from "react-redux";
import {
  removeAllSelectedDeliverShipments,
  markDeliveredTrackingNumber,
} from "../../actions/driverLoadAndDeliverShipmentsAction";
import FeedbackDialogLayout from "./FeedbackDialogLayout";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  stepCotent: {
    paddingLeft: "10px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  missingTrackingNumberWrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "10px",
    listStyleType: "none",
  },
}));

const getSteps = () => {
  return [
    "Review and select your loaded shipments",
    "Confirm selected shipments to deliver",
    "Take pictures for the delivered shipments (Recommended)",
    "Let receiver input confirmation codes",
  ];
};

const DeliverShipmentsLayout = ({ driverDeliverPackages, reload }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [activeNext, setActiveNext] = useState([true, true]);
  const [missingTrackingNumbers, setMissingTrackingNumbers] = useState([]);
  const steps = getSteps();
  const [confirmationCodes, setConfirmationCodes] = useState([]);

  const [dialogState, setDialogState] = useState({
    openDialog: false,
    openBackdrop: false,
    title: "",
    content: [],
    loading: false,
    buttonWords: ["", ""],
    delivered: false,
    uploadImagesRes: [],
  });

  const deliverProgress = useRef("Begin");
  const selectedTrackingNumbers = useSelector(
    (state) => state.driver_deliver_packages.selectedTrackingNumber
  );

  const selectedShipmentNumbers = useSelector(
    (state) => state.driver_deliver_packages.selectedShipmentNumber
  );

  const driverUploadedImages = useSelector(
    (state) => state.driver_uploaded_images.deliverShipments
  );

  const deliverRequestData = useSelector(
    (state) => state.driver_deliver_packages.deliverRequestData
  );

  useEffect(() => {
    console.log("deliver request data: ", deliverRequestData);
  }, [deliverRequestData]);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <LoadAndDeliverShipmentsLayout />;
      case 1:
        return <LoadAndDeliverShipmentsLayout deletable />;
      case 2:
        return <DriverUploadImages type="deliver" />;
      case 3:
        return (
          <ConfirmationCodesInputLayout
            codesCallback={handleConfimationCodes}
          />
        );
      default:
        return "Unknown step";
    }
  };

  const handleConfimationCodes = (codes) => {
    setConfirmationCodes(codes);
  };

  useEffect(() => {
    setActiveNext([
      driverDeliverPackages.selectedTrackingNumber.length === 0,
      Object.keys(driverDeliverPackages.selectedPackages).length === 0,
    ]);
  }, [
    driverDeliverPackages.selectedTrackingNumber,
    driverDeliverPackages.selectedPackages,
  ]);

  const handleRetry = () => {
    dispatch(removeAllSelectedDeliverShipments());

    setActiveStep(0);
    setMissingTrackingNumbers([]);
  };

  const handleCloseDialog = () => {
    setActiveStep(0);
    setMissingTrackingNumbers([]);
  };

  const deliverShipment = async () => {
    let deliverIssue = false;

    setDialogState({
      ...dialogState,
      content: [],
      loading: true,
    });

    const header = {
      Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
    };

    //make API calls here
    if (selectedTrackingNumbers.length > 0) {
      for (const obj of deliverRequestData) {
        // const body = {
        //   // tracking_numbers: obj.tracking_numbers,
        //   receiveConfirmationCodeArray: [confirmationCodes[0]] || [],
        //   stage: obj.stage,
        //   taskId: obj.taskId,
        //   shipmentNumber: obj.shipment_number
        // };
        const formData = new FormData();
        formData.append("shipmentNumber", obj.shipment_number);
        formData.append("stage", obj.stage);
        formData.append("taskId", obj.taskId);
        formData.append("receiveConfirmationCodeArray", [confirmationCodes[0]] || []);
        for (const image of driverUploadedImages.map(v => v.raw)) {
          formData.append("shipmentTaskImages", image);
        }
        // console.log(body);
        console.log("Form Data: ", formData);
        try {
          await axios.post(`${DRIVER_DELIVER_SHIPMENTS_TASK_WITH_PACKAGES}`, formData, {
            headers: {
              Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
              "Content-Type": "multipart/form-data",
            },
          })
          console.log("response, fully delivered");
          //fully delivered tracking numbers
          let data = {
            message: `Successfully delivered shipment ${obj.shipment_number}.`,
          };
          setDialogState(
              produce((draft) => {
                draft.content.push(data);
              })
          );
        } catch (e) {
                  //deliver issues or error
                  deliverIssue = true;
                  deliverProgress.current = "Deliver error";
                  let data = {
                    shipment_number: obj.shipment_number,
                    error:
                      "Unknow error occurs when trying to deliver shipments, please retry. ",
                  };
                  setDialogState(
                    produce((draft) => {
                      draft.content.push(data);
                    })
                  );
        }

        // await axios
        //   .post(DRIVER_DELIVER_SHIPMENT, body, {
        //     headers: header,
        //   })
        //   // eslint-disable-next-line no-loop-func
        //   .then((result) => {
        //     const [res, error] = result.data;
        //
        //     if (res === 200) {
        //       console.log("response, fully delivered");
        //       //fully delivered tracking numbers
        //       let data = {
        //         message: `Successfully delivered shipment ${obj.shipment_number}.`,
        //       };
        //
        //       //prepate the content for the dialog
        //       setDialogState(
        //         produce((draft) => {
        //           draft.content.push(data);
        //         })
        //       );
        //     } else if (res?.missing?.length > 0) {
        //       deliverProgress.current = "Partially delivered";
        //       //partial delivered
        //       let data = {
        //         message: `Shipment is partially delivered but with missing items.`,
        //         shipment_number: obj.shipment_number,
        //         delivered: res.delivered,
        //         missing: res.missing,
        //       };
        //       dispatch(markDeliveredTrackingNumber(res.delivered));
        //
        //       setDialogState(
        //         produce((draft) => {
        //           draft.content.push(data);
        //         })
        //       );
        //     } else if (error) {
        //       //deliver issues or error
        //       deliverIssue = true;
        //       deliverProgress.current = "Deliver error";
        //       let data = {
        //         shipment_number: obj.shipment_number,
        //         error:
        //           "Unknow error occurs when trying to deliver shipments, please retry. ",
        //       };
        //       setDialogState(
        //         produce((draft) => {
        //           draft.content.push(data);
        //         })
        //       );
        //     }
        //   });
      }

      console.log("all delivering requests resolved", deliverProgress);
      if (deliverProgress.current === "Pending") {
        deliverProgress.current = "Delivered";
        setDialogState(
          produce((draft) => {
            draft.buttonWords = ["", "Okay"];
          })
        );
      } else {
        setDialogState(
          produce((draft) => {
            draft.buttonWords = ["Retry", "cancel"];
          })
        );
      }
    }

    // if (driverUploadedImages.length > 0) {
    //   for (let i = 0; i < driverUploadedImages.length; i++) {
    //     let formData = new FormData();
    //     formData.append("file", driverUploadedImages[i].raw);

    //     for (let i = 0; i < selectedShipmentNumbers.length; i++) {
    //       formData.append("shipments[]", selectedShipmentNumbers[i]);
    //     }

    //     axios({
    //       method: "post",
    //       url: DRIVER_UPLOAD_SHIPMENTS_IMAGES,
    //       data: formData,
    //       headers: {
    //         Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
    //         "Content-Type": "multipart/form-data;",
    //       },
    //     })
    //       .then((res) => {
    //         console.log(`response of request ${i}`, res);
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //       });
    //   }
    //   //setActiveStep(0);
    // }

    setDialogState(
      produce((draft) => {
        draft.loading = false;
      })
    );
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      let data = [];
      deliverRequestData.forEach((s) => {
        data.push(s);
      });

      setDialogState(
        produce((draft) => {
          draft.loading = false;
          draft.openBackdrop = true;
          draft.openDialog = true;
          draft.buttonWords = ["Confirm", "Cancel"];
          draft.delivered = false;
          draft.title = "Deliver shipment(s)";
          draft.content = [
            { message: "Confirm to deliver the following shipment(s)?" },
          ];
          draft.content.push(...data);
        })
      );
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCancel = () => {
    if (deliverProgress.current === "Delivered") {
      reload();
    } else {
      setDialogState({
        ...dialogState,
        openBackdrop: false,
        openDialog: false,
      });
    }
  };

  const onClickConfirm = () => {
    console.log("onClickConfirm, deliver progress: ", deliverProgress);
    if (deliverProgress.current === "Begin") {
      deliverProgress.current = "Pending";
      deliverShipment();
    } else if (deliverProgress.current === "Delivered") {
      //refresh page
    } else if (deliverProgress.current === "Partially delivered") {
      deliverProgress.current = "Begin";
      reload();
    } else if (deliverProgress.current === "Deliver error") {
      setActiveStep(0);
      setDialogState(
        produce((draft) => {
          draft.openBackdrop = false;
          draft.openDialog = false;
        })
      );
      deliverProgress.current = "Begin";
    }
  };

  const renderContent = () => {
    let tempContent = [];
    if (dialogState.content) {
      console.log("dialog state content", dialogState.content);
      dialogState.content.forEach((obj) => {
        console.log("current content obj", obj);
        if (obj.message) {
          tempContent.push(<h5>{obj.message}</h5>);
        }
        if (obj.shipment_number) {
          tempContent.push(<h6>Shipment number: {obj.shipment_number}</h6>);
        }
        if (obj.taskId) {
          tempContent.push(
              <div>
                {/*<i>Tracking number(s): {obj.tracking_numbers.toString()}</i>*/}
                <i>Task Id: {obj.taskId}</i>
              </div>
          );
        }
        if (obj.delivered?.length > 0) {
          tempContent.push(
            <div>
              <i>Delivered tracking number(s): {obj.delivered.toString()}</i>
            </div>
          );
        }

        if (obj.missing?.length > 0) {
          tempContent.push(
            <div>
              <i>Missing tracking number(s): {obj.missing.toString()}</i>
            </div>
          );
        }

        if (obj.error) {
          tempContent.push(
            <div>
              <i>Deliver error: {obj.error.toString()}</i>
            </div>
          );
        }
      });
    }

    return tempContent;
  };

  return (
    <>
      <div className={classes.root}>
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          style={{ width: "94%", maxWidth: "1000px" }}
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent className={classes.stepCotent}>
                <Typography>{getStepContent(index)}</Typography>
                <div className={classes.actionsContainer}>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                      disabled={activeNext[activeStep]}
                    >
                      {activeStep === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {
          <FeedbackDialogLayout
            openDialog={dialogState.openDialog}
            openBackDrop={dialogState.openBackdrop}
            title={dialogState.title}
            content={renderContent()}
            handleConfirm={() => onClickConfirm()}
            handleCancel={() => handleCancel()}
            loading={dialogState.loading}
            buttonWords={dialogState.buttonWords}
            uploadImagesRes={dialogState.uploadImagesRes}
          />
        }
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    driverDeliverPackages: state.driver_deliver_packages,
  };
};

export default connect(mapStateToProps)(DeliverShipmentsLayout);
