import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import axios from "axios";
import {useSelector} from "react-redux";
import dayjs from "dayjs";
import {useEffect, useState} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import {useHistory} from "react-router-dom";
import {BusinessLandingScheduledPickupList} from "./BusinessLandingScheduledPickupList";
import intl from "react-intl-universal";
import {PARTNER_URI} from "../../../Utils/apiUrl";

const styles = {
    ScheduledPickupRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ScheduledPickupContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

export const BusinessLandingScheduledPickup = () => {

    const history = useHistory();

    const id = useSelector((state) => state.user.id);
    const [accountBase, setAccountBase] = useState("");
    const [filterDateStart, setFilterDateStart] = useState(
        dayjs().hour(0).minute(0).second(0).millisecond(0).subtract(60, "day")
    );
    const [filterDateEnd, setFilterDateEnd] = useState(
        dayjs().hour(0).minute(0).second(0).millisecond(0).add(30, "day")
    );
    const [myPickups, setMyPickups] = useState([]);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const getScheduledPickupsUpdate = async () => {
        setLoading(true);
        try {
            const {status, data} = await axios.get(
                `${PARTNER_URI}/packagesPickupAppointment/getMyPickupAppointmentList`,
                {
                    params: {
                        partnerId: id,
                        accountBase: accountBase !== "" ? accountBase : undefined,
                        appointDateStart: filterDateStart.isValid() !== null && filterDateStart.isValid() ? filterDateStart.toDate() : undefined,
                        appointDateEnd: filterDateEnd.isValid() !== null && filterDateEnd.isValid() ? filterDateEnd.toDate() : undefined,
                    },
                }
            );
            console.log('home page schedule pickup', data);
            setMyPickups(data);
            setError(false);
            setLoading(false);
        } catch (e) {
            console.log(e);
            setError(true);
            setLoading(false);
        }
    };

    useEffect(() => {
        getScheduledPickupsUpdate();
    }, [filterDateStart, filterDateEnd])

    return (
        <Box sx={styles.ScheduledPickupRoot}>
            <Typography style={{fontSize: '16px', fontWeight: 600, textAlign: 'left'}}>
                {intl.get('HOME_PAGE.SCHEDULED_PICKUPS.SCHEDULED_PICKUPS')}
            </Typography>
            <Box sx={styles.ScheduledPickupContent}>
                {
                    loading ?
                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                            <CircularProgress/>
                        </Box>
                        :
                        error ?
                            <Box sx={styles.ShippingOrderContent}>
                                <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                    {intl.get('HOME_PAGE.ERROR')}
                                </Typography>
                            </Box>
                            :
                            myPickups?.length > 0 ?
                                <BusinessLandingScheduledPickupList myPickups={myPickups}/>
                                :
                                <Box sx={styles.ShippingOrderContent}>
                                    <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                        {intl.get('HOME_PAGE.SCHEDULED_PICKUPS.NO_RESULTS')}
                                    </Typography>
                                </Box>
                }
                <Button
                    sx={{width: '100%'}}
                    onClick={() => history.push('/business/MyScheduledPickups')}
                >
                    <Typography style={{
                        fontSize: '14px',
                        color: '#1D8B45',
                        fontWeight: '600',
                        textTransform: 'none',
                        width: '100%'
                    }}>
                        {intl.get('HOME_PAGE.SCHEDULED_PICKUPS.VIEW')}
                    </Typography>
                </Button>
            </Box>
        </Box>
    )
}