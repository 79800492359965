import {Button, Snackbar} from "@mui/material";
import * as React from "react";
import Grid from "@mui/material/Grid";
import {getAccessToken} from "../../../Utils/doToken";
import {useState} from "react";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import WarningIcon from "@mui/icons-material/Warning";
import {useHistory} from "react-router-dom";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingOrderDetailsCreditCardRoot: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        alignItems: 'flex-start'
    },
    ShippingOrderDetailsCreditCardTitle: {
        fontWeight: '600',
        textAlign: 'left',
        fontSize: '18px'
    },
}

export const BusinessShippingOrderDetailsAvailableCredit = ({orderDetails, orderId, availableCredit}) => {

    const storedToken = getAccessToken("access_token");
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleAddCredits = () => {
        history.push('/business/billing-center/top-up')
    }

    const handlePayBackendOrderByUUCargoCredit = async () => {
        setLoading(true);
        const requestURL = `${PARTNER_URI}/loose-item/3rd-party/placePartner3PLOrderWithCreditViaBackendOrderId`;

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {Authorization: `Bearer ${storedToken}`},
                data: {
                    backendOrderId: orderId
                }
            });
            console.log('[handlePayBackendOrderByUUCargoCredit] result', result);
            setErrorMessage('');
            setSuccessMessage('The order has been paid successfully.');
            handleSuccessMessage();
        } catch (e) {
            console.log('error', e.response);
            setErrorMessage('The order has not been paid successfully.');
            handleErrorMessage();
        } finally {
            setLoading(false);
        }
    }

    return (
        <Grid container spacing={1}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            {
                (+availableCredit < +orderDetails?.prices?.unpaidAmount) ?
                    <>
                        <Grid item xs={10}>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <WarningIcon style={{color: '#F2BE22', fontSize: '18px'}}/>&nbsp;
                                <Typography sx={{color: '#454545'}}>
                                    <span style={{fontWeight: '600'}}>Insufficient Credits.</span> Please top up your
                                    account, or select another payment method.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                                <Button
                                    size='small'
                                    onClick={handleAddCredits}
                                >
                                    <Typography style={{textTransform: 'none', fontSize: '16px', color: '#1D8B45'}}>
                                        Add Credits
                                    </Typography>
                                </Button>
                            </Box>
                        </Grid>
                    </>
                    :
                    <Grid item xs={12}>
                        <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: '10px'}}>
                            <LoadingButton
                                variant='contained'
                                sx={{
                                    backgroundColor: '#1D8B45',
                                    "&:hover": {
                                        backgroundColor: '#1D8B45',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                onClick={handlePayBackendOrderByUUCargoCredit}
                                loading={loading}
                            >
                                <Typography style={{textTransform: 'none', fontSize: '16px'}}>
                                    Place Order
                                </Typography>
                            </LoadingButton>
                        </Box>
                    </Grid>
            }
        </Grid>
    )
}