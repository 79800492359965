import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@mui/material/Button";
import {ReactComponent as PlatformIcon} from "../../../images/welcome/Platform.svg";
import {ReactComponent as ParcelIcon} from "../../../images/welcome/Parcel.svg";
import {useMediaQuery} from "@mui/material";

export const WelcomeInto = ({handlePageNum}) => {

    const isMobile = useMediaQuery('(max-width: 480px)');

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <Typography style={{fontSize: '14px', textAlign: 'center'}}>
                    We're thrilled to have you onboard. As you embark on a seamless shipping experience with us,
                    here are a few things to keep in mind:
                </Typography>
            </Box>
            <Grid container spacing={isMobile ? 1 : 4}>
                {
                    !isMobile &&
                    <Grid item md={3} xs={3}>
                        <PlatformIcon width="75" height="75"/>
                    </Grid>
                }
                <Grid item md={9} xs={12}>
                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <Typography style={{fontSize: '14px', textAlign: 'left', color: '#1D8B45'}}>
                            All-in-one Shipping Platform
                        </Typography>
                        <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                            Our platform allows you to quote, book, track, and completely manage shipments with
                            exclusive rates from an extensive carrier network all in one place.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={3} xs={3}>
                    {
                        !isMobile &&
                        <Grid>
                            <ParcelIcon width="75" height="75"/>
                        </Grid>
                    }
                </Grid>
                <Grid item md={9} xs={12}>
                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <Typography style={{fontSize: '14px', textAlign: 'left', color: '#1D8B45'}}>
                            From Parcels to Pallets
                        </Typography>
                        <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                            No matter the size or scale of your product, we have you covered. Ship everything from
                            small parcels to large pallets with utmost ease and efficiency.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end'
            }}>
                <Button
                    variant='contained'
                    sx={{
                        backgroundColor: '#1D8B45',
                        "&:hover": {
                            backgroundColor: '#1D8B45',
                            filter: 'brightness(0.9)'
                        },
                        padding: '5px 25px',
                    }}
                    onClick={() => handlePageNum(2)}
                >
                    <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                        Next
                    </Typography>
                </Button>
            </Box>
        </Box>
    )
}