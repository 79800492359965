import React, {useEffect, useState} from "react";
import {useLocation, Link} from "react-router-dom";
import {Button, Box} from "@material-ui/core";
import {Alert, AlertTitle} from "@material-ui/lab";
import axios from "axios";
import {CustomInput} from "../shared";
import {errHandling} from "../../Utils/fetchUtil";
import {FORGOT_PASSWORD, RESET_PASSWORD} from "../../Utils/apiUrl";
import {deleteToken, getAccessToken} from "../../Utils/doToken";
import {deleteUserInfo, updateUserInfo} from "../../slices";
import {useDispatch} from "react-redux";
import {Container, IconButton, InputAdornment} from "@mui/material";
import Grid from "@mui/material/Grid";
import LandingImage from "../../images/CoverImage.png";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import intl from "react-intl-universal";
import LoadingButton from "@mui/lab/LoadingButton";
import {useHistory} from "react-router-dom";
import {LandingMenuBar} from "../Landing/LandingMenuBar";
import {LandingIntro} from "../Landing/LandingIntro";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const styles = {
    LandingSignUpRoot: {
        display: 'flex',
        margin: '0 auto',
        '@media (max-width: 480px)': {
            flexDirection: "column", // for phone and above
        },
    },
    LandingIntroImage: {
        width: '95%',
        padding: '5%'
    },
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ResetPasswordPage = () => {
    const query = useQuery();
    const history = useHistory();
    const isMobile = useMediaQuery('(max-width: 480px)');

    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");

    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [error, setError] = useState("");
    const [rstSuccess, setSuccessState] = useState(false);
    const dispatch = useDispatch();

    const handleClickShowPassword1 = () => {
        setShowPassword1(!showPassword1);
    };

    const handleMouseDownPassword1 = (event) => {
        event.preventDefault();
    };

    const handlePassword1 = (event) => {
        setPassword1(event?.target?.value);
    }

    const handleClickShowPassword2 = () => {
        setShowPassword2(!showPassword2);
    };

    const handleMouseDownPassword2 = (event) => {
        event.preventDefault();
    };

    const handlePassword2 = (event) => {
        setPassword2(event?.target?.value);
    }

    const handleSetPassword = () => {
        if (password1 !== password2) {
            setError("Password mismatch");
            return;
        }
        setError("");
        const email = query.get("email");
        const encrypt = query.get("encrypt");
        const seq = query.get("seq");
        axios({
            method: "put",
            url: RESET_PASSWORD,
            data: JSON.stringify({
                password: password1,
                email: email,
                encrypt: encrypt,
                seq: seq,
            }),
            headers: {
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
            },
        })
            .then((response) => {
                setSuccessState(true);
                setTimeout(() => history.push('/sign-in'), 2000);
            })
            .catch((error) => {
                errHandling(error, setError);
            });
    };

    const handleBack = () => {
        history.push('/sign-in');
    }

    const handleResend = () => {
        const url = FORGOT_PASSWORD;
        const email = query.get("email");
        const putObj = {
            email_to: email,
        };
        axios({
            method: "post",
            url: url,
            data: JSON.stringify(putObj),
            headers: {
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
            },
        })
            .then((response) => {
                history.push("/receive-email?status_code=200");
            })
            .catch((error) => {
                history.push("/receive-email?status_code=403");
            });
    };

    useEffect(() => {
        const storedToken = getAccessToken("access_token");
        if (storedToken) {
            deleteToken("access_token");
            dispatch(deleteUserInfo());
            dispatch(updateUserInfo({field: "login", value: false}));
            dispatch(updateUserInfo({field: "signIn", value: false}));
        }
    }, []);

    console.log('[ResetPasswordPage] queryString', query.get("email"));

    return (
        // <Slide down duration ={500}>
        <Container maxWidth={false} style={{padding: '0px', marginTop: '-75px'}}>
            <Grid container spacing={0}>
                <Grid item xs={12} md={5}>
                    <Box
                        sx={{
                            display: 'flex',
                            padding: '20px',
                            flexDirection: 'column',
                            height: isMobile ? '100vh' : '100%',
                            gap: isMobile ? '10vh' : '15vh'
                        }}
                    >
                        <LandingMenuBar/>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            height: '100%'
                        }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography
                                        style={{
                                            fontSize: '20px',
                                            fontWeight: '600',
                                            textAlign: 'left',
                                            WebkitTextStroke: '0.5px black', // For Webkit browsers
                                        }}
                                    >
                                        Create a new password
                                    </Typography>
                                    <Typography style={{textAlign: 'left', color: '#454545', fontSize: '14px'}}>
                                        Enter a new password to reset the password on your account.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        value={password1}
                                        label='New Password'
                                        type={showPassword1 ? 'text' : 'password'}
                                        required
                                        fullWidth
                                        size='small'
                                        variant="outlined"
                                        inputProps={{style: {fontSize: 14}}}
                                        InputLabelProps={{style: {fontSize: 14}}}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={handleClickShowPassword1}
                                                        onMouseDown={handleMouseDownPassword1}
                                                        edge="end"
                                                    >
                                                        {showPassword1 ? <Visibility/> : <VisibilityOff/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        onInput={handlePassword1}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        value={password2}
                                        label='Confirm Password'
                                        type={showPassword2 ? 'text' : 'password'}
                                        required
                                        fullWidth
                                        size='small'
                                        variant="outlined"
                                        inputProps={{style: {fontSize: 14}}}
                                        InputLabelProps={{style: {fontSize: 14}}}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={handleClickShowPassword2}
                                                        onMouseDown={handleMouseDownPassword2}
                                                        edge="end"
                                                    >
                                                        {showPassword2 ? <Visibility/> : <VisibilityOff/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        onInput={handlePassword2}
                                    />
                                </Grid>
                                {
                                    rstSuccess &&
                                    <Grid item xs={12}>
                                        <Alert severity="success">
                                            <AlertTitle>Success</AlertTitle>
                                            Your password has been successfully reset.
                                        </Alert>
                                    </Grid>
                                }
                                {
                                    error &&
                                    <Grid item xs={12}>
                                        <Alert severity="error">
                                            <AlertTitle>Error</AlertTitle>
                                            {error}
                                        </Alert>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <LoadingButton
                                        variant="contained"
                                        onClick={handleSetPassword}
                                        size='small'
                                        fullWidth
                                        sx={{
                                            backgroundColor: '#1D8B45',
                                            "&:hover": {
                                                backgroundColor: '#1D8B45',
                                                filter: 'brightness(0.9)'
                                            }
                                        }}
                                        // className={classes.ResetButton}
                                    >
                                        <Typography sx={{textTransform: 'none', fontSize: '14px'}}>
                                            Reset Password
                                        </Typography>
                                    </LoadingButton>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        gap: '10px',
                                        alignItems: 'center'
                                    }}>
                                        <Button
                                            onClick={handleBack}
                                        >
                                            <Typography sx={{
                                                fontSize: '14px',
                                                color: '#1D8B45',
                                                fontWeight: '600',
                                                textAlign: 'center',
                                                textTransform: 'none'
                                            }}>
                                                Back
                                            </Typography>
                                        </Button>
                                        <Button
                                            onClick={handleResend}
                                        >
                                            <Typography sx={{
                                                fontSize: '14px',
                                                color: '#1D8B45',
                                                fontWeight: '600',
                                                textAlign: 'center',
                                                textTransform: 'none'
                                            }}>
                                                Resend
                                            </Typography>
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
                <LandingIntro/>
                {/*<Grid item xs={12} md={7}>*/}
                {/*    <Box sx={{*/}
                {/*        display: 'flex',*/}
                {/*        backgroundColor: '#1D8B45',*/}
                {/*        minHeight: '100vh',*/}
                {/*        height: '100%',*/}
                {/*        width: '100%',*/}
                {/*        justifyContent: 'center',*/}
                {/*        alignItems: 'flex-start'*/}
                {/*    }}>*/}
                {/*        <img src={LandingImage} alt="landing image" style={styles.LandingIntroImage}/>*/}
                {/*    </Box>*/}
                {/*</Grid>*/}
            </Grid>
        </Container>
    );
};

export default ResetPasswordPage;
