import React, { useState, useEffect } from "react";
// import {createMuiTheme } from '@material-ui/core/';
import { useDispatch, useSelector } from "react-redux";
import {
  // TextField,
  Button,
  Typography,
  Box,
  Tabs,
  Tab,
  Select,
  Grid,
  Modal,
  Tooltip,
  // Chip,
} from "@material-ui/core";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Alert } from "@material-ui/lab";
import AddCircleIcon from "@mui/icons-material/AddCircle";

// import Fade from 'react-reveal';
import PropTypes from "prop-types";
import produce from "immer";
import { v4 as uuidv4 } from "uuid";

import CustomInput from "./customInput";
import { inputChecker } from "../../../Utils/inputChecker";

import { clearInitOrder } from "../../../slices/ordersSlice";
import { MenuItem } from "@mui/material";
import { defaultShippingItem } from "../../../slices";
import { shipmentTypesDetails } from "../../shared";
import MyBoxesListItem from "./myBoxesListItem";
import AddMyBoxesItem from "./addMyBoxesItem";
import { NODE_ROUTE_URI } from "../../../Utils/apiUrl";
import { getTokenFromCookie } from "../../../Utils/doToken";
import axios from "axios";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

const getTabNumber = (typeID) => {
  if (!typeID || !shipmentTypesDetails[typeID]) return 0;
  if (parseInt(typeID) < 8) return 0;
  if (shipmentTypesDetails[typeID].label === "flatbed") return 2;
  return 1;
};

const ShippingItemInput = ({
  edit = false,
  item,
  onCancel,
  errorMsg,
  setErrorMsg,
  small = false,
  guest = false,
  changeItem,
  setShippingItems,
  setEmtpyItemList,
  selectedLooseItem,
  setSelectedLooseItem,
  palletContainerLoose,
  setPalletContainerLoose,
  defaultPackageId,
  editShippingItem,
}) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user);

  const [shippingItem, setShippingItem] = useState(
    editShippingItem || defaultShippingItem
  );

  const [weightUnitText, setWeightUnitText] = useState("KG");
  const [lengthUnitText, setLengthUnitText] = useState("CM");

  useEffect(() => {
    if (shippingItem.weightUnit === "kg") {
      setWeightUnitText("KG");
      return;
    }
    setWeightUnitText("LB");
  }, [shippingItem.weightUnit]);

  useEffect(() => {
    if (shippingItem.lengthUnit === "cm") {
      setLengthUnitText("CM");
      return;
    }
    setLengthUnitText("In");
  }, [shippingItem.lengthUnit]);

  useEffect(() => {
    console.log("Default Shipping Item: ", shippingItem);
  }, [shippingItem]);

  const [selectedType, setSelectedType] = useState(21);

  const [userInputError, setUserInputError] = useState({
    emtpyError: false,
    heightError: "",
    weightError: "",
    quantityError: "",
    lengthError: "",
    widthError: "",
    unitError: "",
    looseUnitError: "",
  });
  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  const [containerGroup, setContainerGroup] = useState("Normal");

  // My Boxes
  const [showModal, setShowModal] = useState(false);
  const [myBoxes, setMyBoxes] = useState([]);
  const [myBoxesErrorMsg, setMyBoxesErrorMsg] = useState("");
  const [showAddBoxItem, setShowAddBoxItem] = useState(false);

  useEffect(() => {
    if (item) {
      setShippingItem(item);
      setSelectedType(item?.shipmentType || "");
      if (item?.shipmentType) {
        setValue(getTabNumber(item.shipmentType));
        setContainerGroup(
          item.shipmentType !== "16" &&
            item.shipmentType !== "17" &&
            item.shipmentType !== "18"
            ? "Normal"
            : "Small"
        );
      }
    }
  }, [item]);

  const handleRadioButtonChange = (e) => {
    setContainerGroup(e.target.value);
    setSelectedType("");
  };

  const handleChange = (event, newValue) => {
    setShippingItem(defaultShippingItem);
    if (newValue === 2) {
      // const typeValue = "12";
      const typeValue = "21";
      setSelectedType(typeValue);
      // setShippingItem(
      //   produce((draft) => {
      //     draft.length = shipmentTypesDetails[typeValue].length;
      //     draft.width = shipmentTypesDetails[typeValue].width;
      //   })
      // );
    } else setSelectedType("");
    setValue(newValue);
  };

  // const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const matches = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    const emptyField =
      shippingItem.description === null ||
      shippingItem.height === null ||
      shippingItem.length === null ||
      shippingItem.width === null ||
      shippingItem.weight === null ||
      // shippingItem.quantity === null ||
      selectedType === "";

    if (!emptyField) {
      setUserInputError((prev) => {
        return { ...prev, emtpyError: false };
      });
    }

    let weightLimit;
    if (selectedType)
      weightLimit = shipmentTypesDetails[selectedType]?.max_weight || null;

    let heightLimit;
    if (selectedType)
      heightLimit = shipmentTypesDetails[selectedType]?.max_height || null;

    const hasError =
      Boolean(inputChecker(shippingItem.height, heightLimit)) ||
      Boolean(inputChecker(shippingItem.weight, weightLimit)) ||
      // Boolean(inputChecker(shippingItem.quantity)) ||
      Boolean(inputChecker(shippingItem.length)) ||
      Boolean(inputChecker(shippingItem.width));

    setUserInputError((prev) => {
      return {
        ...prev,
        heightError: inputChecker(shippingItem.height, heightLimit),
        weightError: inputChecker(shippingItem.weight, weightLimit),
        // quantityError: inputChecker(shippingItem.quantity),
        lengthError: inputChecker(shippingItem.length),
        widthError: inputChecker(shippingItem.width),
      };
    });

    if (!emptyField && !hasError) {
      setErrorMsg("");
    }
  }, [shippingItem, selectedType, setErrorMsg, setUserInputError]);

  const handleItemChange = (fieldName) => (e) => {
    let value = e.target.value;
    if (fieldName === "looseUnit") {
      value = parseInt(value);
      // if (value === "" || isNaN(value) || value <= 1) {
      //   value = 1;
      // }
    }

    if (fieldName === "hazardous" || fieldName === "tempSensitive") {
      value = e.target.checked;
    }
    if (fieldName === "quantity") value = parseInt(value);
    e.preventDefault();
    setShippingItem(
      produce((draft) => {
        draft[fieldName] = value;
      })
    );
  };

  const handleListItemSelect = (e) => {
    const typeValue = e.target.value;
    setSelectedType(typeValue);
    setShippingItem(
      produce((draft) => {
        draft.length = shipmentTypesDetails[typeValue].length || 48;
        draft.width = shipmentTypesDetails[typeValue].width || 48;
        if (value === 1) draft.height = shipmentTypesDetails[typeValue].height;
        // draft.weight = shipmentTypesDetails[typeValue].weight;
        draft.quantity = draft.quantity || null;
        if (typeValue === "6" || typeValue === "7") {
          draft.quantity = 1;
        }
      })
    );
  };

  // Disable the input area based on the type chosen
  const disableInput = (_selectedType, _shipmentTypesDetails, _field) => {
    if (!_selectedType) return true;
    return Boolean(_shipmentTypesDetails[_selectedType][_field]);
  };

  //Checks if pallet weight,height and num pallets is not a number and if they aren't, return some error
  //Sets the Redux dispatch pallet state
  const handlePalletData = (itemType) => {
    const emptyField =
      shippingItem.description === "" ||
      shippingItem.height === null ||
      shippingItem.length === null ||
      shippingItem.width === null ||
      shippingItem.weight === null ||
      shippingItem.looseUnit === null ||
      shippingItem.looseUnit <= 0 ||
      // shippingItem.quantity === null ||
      selectedType === "";

    console.log("Description", shippingItem.description);
    console.log("Height", shippingItem.height);
    console.log("Length", shippingItem.length);
    console.log("Width", shippingItem.width);
    console.log("Weight", shippingItem.weight);
    console.log("Loose Unit", shippingItem.looseUnit);

    if (emptyField) {
      setErrorMsg(
        "Fields with * cannot be empty. All Numbers should be non negative"
      );
      setUserInputError((prev) => {
        return { ...prev, emtpyError: true };
      });
    } else if (
      userInputError.heightError === "" &&
      userInputError.weightError === "" &&
      userInputError.quantityError === "" &&
      userInputError.lengthError === "" &&
      userInputError.widthError === ""
    ) {
      const newItem = {
        ...shippingItem,
        shipmentType: selectedType,
        packageID: defaultPackageId || uuidv4(),
        color: "orange",
      };
      if (edit) {
        setShippingItems((prevItems) => {
          console.log("Prev Item:", prevItems);
          const prevCopy = [...prevItems];
          for (let index = 0; index < prevCopy.length; index++) {
            console.log("index: ", prevCopy[index]);
            console.log("new item", newItem);
            if (prevCopy[index].packageID === newItem.packageID) {
              prevCopy[index] = newItem;
              console.log("Prev Copy:", prevCopy);
              return prevCopy;
            }
          }
          return prevCopy;
        });
        setErrorMsg("");
        onCancel();
      } else if (guest) {
        setErrorMsg("");
        changeItem(newItem);
      } else {
        // dispatch(addShippingItem({ newItem }));
        setShippingItems(
          produce((draft) => {
            draft.push(newItem);
          })
        );
        if (itemType === "LooseItem") {
          setSelectedLooseItem(itemType);
          setPalletContainerLoose(itemType);
        } else if (itemType === "Container") {
          setPalletContainerLoose(itemType);
        } else {
          setPalletContainerLoose(itemType);
        }

        setEmtpyItemList(false);
        setShippingItem(defaultShippingItem);
        if (selectedType === "12") {
          const typeValue = "12";
          setSelectedType(typeValue);
          setShippingItem(
            produce((draft) => {
              draft.length = shipmentTypesDetails[typeValue].length;
              draft.width = shipmentTypesDetails[typeValue].width;
            })
          );
        }
        setErrorMsg("");
        dispatch(clearInitOrder());
      }
    }
  };

  const tabPanelDisableCondition = () => {
    switch (palletContainerLoose) {
      case "LooseItem":
        return false;
      case "Pallet":
        return false;
      case "Container":
        return false;
      case "All":
        return false;
      default:
        return true;
    }
  };

  //* My Boxes
  const handleOpen = () => {
    getMySavedBoxes();
    setShowAddBoxItem(false);
    setShowModal(true);
  };
  const handleClose = () => setShowModal(false);

  const getMySavedBoxes = async () => {
    try {
      setMyBoxesErrorMsg("");
      const { data, status } = await axios({
        method: "get",
        url: `${NODE_ROUTE_URI}/myboxes/getMyBoxesListForEndUser`,
        // params: { userId: userInfo },
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
        },
      });
      if (status === 200) {
        console.log("My Boxes:", data);
        setMyBoxes(data);
      } else {
        throw new Error();
      }
    } catch (e) {
      setMyBoxesErrorMsg("Failed to get Saved Boxes");
    }
  };

  const changeBoxByIndex = (index, newData) => {
    setMyBoxes((prev) => {
      prev[index] = { ...prev[index], ...newData };
      console.log("Previous:", [...prev]);
      return [...prev];
    });
  };

  const showCreateBox = () => {
    setShowAddBoxItem(true);
    console.log("simulate creating box");
  };

  const handleCloseAddBoxItem = () => {
    setShowAddBoxItem(false);
  };

  return (
    <Box>
      <Modal
        open={showModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          width={matches ? "90%" : 600}
          sx={{ 
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // width: 664,
            height: 664,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            overflowY: "scroll",
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {!showAddBoxItem ? <>My Saved Boxes</> : <>Save New Box</>}
          </Typography>
          <Typography
            id="modal-modal-description"
            classes={{ root: classes.warningText }}
          >
            {myBoxesErrorMsg}
          </Typography>
          <Box width={"100%"}>
            <List>
              {!showAddBoxItem ? (
                <ListItemButton
                  width="100%"
                  onClick={() => setShowAddBoxItem((prev) => !prev)}
                >
                  <AddCircleIcon sx={{ width: "100%", height: "25px" }} />
                </ListItemButton>
              ) : undefined}
              {showAddBoxItem ? (
                <AddMyBoxesItem
                  setShowAddBoxItem={setShowAddBoxItem}
                  getMySavedBoxes={getMySavedBoxes}
                  handleClose={handleCloseAddBoxItem}
                />
              ) : (
                myBoxes.map((item, index) => {
                  return (
                    <MyBoxesListItem
                      key={index}
                      index={index}
                      boxId={item.box_id}
                      boxName={item.box_name}
                      partnerId={item.partner_id}
                      ownerRole={item.owner_role}
                      itemName={item.item_name}
                      shipmentTypeId={item.shipment_type_id}
                      weightUnit={item.weight_unit}
                      dimensionUnit={item.dimension_unit}
                      length={item.length}
                      width={item.width}
                      height={item.height}
                      weight={item.weight}
                      createdAt={item.created_at}
                      updatedAt={item.updated_at}
                      setShippingItem={setShippingItem}
                      handleClose={handleClose}
                      changeBoxByIndex={changeBoxByIndex}
                      matches={matches}
                    />
                  );
                })
              )}
            </List>
            {myBoxes.length === 0 ? <>Nothing to show</> : undefined}
          </Box>
        </Box>
      </Modal>
      <Box className={classes.root} ml={1} mr={1}>
        {/* <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        > */}
        <Tabs
          value={value}
          // onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          {/*<Tab label="Pallets"  {...a11yProps(0)} />*/}
          {/*<Tab label="Containers"  {...a11yProps(1)} />*/}
          <Tab label="Small packages / boxes" {...a11yProps(0)} />
        </Tabs>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        // heigh="100%"
        m={!guest ? "0" : "0 3vw"}
      >
        <Box>
          <TabPanel value={value} index={0}>
            <Box display="flex" flexDirection="column">
              <Box display="flex" flexDirection="column">
                <Box display="flex" flexDirection="column" pb={1} mt={1}>
                  <Box display="flex">
                    <Box minWidth={"18vw"} mr={1} flexGrow="1">
                      <div
                        className={
                          small && matches
                            ? classes.smSubTitle
                            : classes.subTitle
                        }
                      >
                        Item name / SKU *
                      </div>
                      <CustomInput
                        // className={classes.textfield}
                        value={shippingItem.description}
                        onChange={handleItemChange("description")}
                      />
                    </Box>
                    <Box minWidth={"18vw"} flexGrow="1">
                      <div
                        className={
                          small && matches
                            ? classes.smSubTitle
                            : classes.subTitle
                        }
                      >
                        Quantity *
                      </div>
                      <CustomInput
                        value={shippingItem.looseUnit}
                        onChange={handleItemChange("looseUnit")}
                        // disabled={disableInput(selectedType, shipmentTypesDetails, 'height')}
                        // disabled={!selectedType}
                        error={
                          userInputError.looseUnitError ||
                          (userInputError.emtpyError &&
                            shippingItem.looseUnit <= 0)
                        }
                        onKeyDown={(evt) => {
                          (evt.key === "e" ||
                            evt.key === "-" ||
                            evt.key === "E") &&
                            evt.preventDefault();
                        }}
                        inputProps={{ min: 0 }}
                        type="number"
                        placeholderValue={
                          selectedType &&
                          shipmentTypesDetails[selectedType].max_height
                            ? `Maximum: ${shipmentTypesDetails[selectedType].max_height}`
                            : ""
                        }
                      />
                      <Box
                        pl={1}
                        color="red"
                        visibility={
                          userInputError.heightError ? "visible" : "hidden"
                        }
                      >
                        {userInputError.heightError}
                      </Box>
                    </Box>
                  </Box>
                  <Box display="flex" mb={1}>
                    <Box width={"10vw"} mr={1} flexGrow="1">
                      <div
                        className={
                          small && matches
                            ? classes.smSubTitle
                            : classes.subTitle
                        }
                        style={{ visibility: "hidden" }}
                      >
                        {/* Length */}
                        Dimensions *
                      </div>
                      <CustomInput
                        // className={classes.textfield}
                        value={shippingItem.length}
                        onChange={handleItemChange("length")}
                        // disabled={disableInput(
                        //   selectedType,
                        //   shipmentTypesDetails,
                        //   "length"
                        // )}
                        placeholderValue="Length"
                        type="weight"
                      />
                    </Box>
                    <Box width={"10vw"} mr={1} flexGrow="1">
                      <div
                        className={
                          small && matches
                            ? classes.smSubTitle
                            : classes.subTitle
                        }
                      >
                        {/*Width ({lengthUnitText}) **/}
                        Dimensions *
                      </div>
                      <CustomInput
                        value={shippingItem.width}
                        onChange={handleItemChange("width")}
                        // disabled={disableInput(
                        //   selectedType,
                        //   shipmentTypesDetails,
                        //   "width"
                        // )}
                        placeholderValue="Width"
                        type="weight"
                      />
                    </Box>
                    <Box width={"10vw"} mr={1} flexGrow="1">
                      <div
                        className={
                          small && matches
                            ? classes.smSubTitle
                            : classes.subTitle
                        }
                        style={{ visibility: "hidden" }}
                      >
                        {/* Height */}
                        Dimensions *
                      </div>
                      <CustomInput
                        value={shippingItem.height}
                        onChange={handleItemChange("height")}
                        // disabled={disableInput(selectedType, shipmentTypesDetails, 'height')}
                        // disabled={!selectedType}
                        error={
                          userInputError.heightError ||
                          (userInputError.emtpyError &&
                            shippingItem.height === null)
                        }
                        onKeyDown={(evt) => {
                          (evt.key === "e" ||
                            evt.key === "-" ||
                            evt.key === "E") &&
                            evt.preventDefault();
                        }}
                        type="weight"
                        placeholderValue="Height"
                      />
                      <Box
                        pl={1}
                        color="red"
                        visibility={
                          userInputError.heightError ? "visible" : "hidden"
                        }
                      >
                        {userInputError.heightError}
                      </Box>
                    </Box>
                    <Box width={"5vw"} flexGrow="1" display="flex">
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-evenly"
                      >
                        <div
                          className={
                            small && matches
                              ? classes.smSubTitle
                              : classes.subTitle
                          }
                          style={{ visibility: "hidden" }}
                        >
                          {/*Length Unit*/}
                          Dimensions *
                        </div>
                        <Select
                          value={shippingItem.lengthUnit}
                          label="Unit"
                          onChange={handleItemChange("lengthUnit")}
                          className={classes.textfield}
                          classes={{ root: classes.selectRoot }}
                          MenuProps={{ classes: { paper: classes.menuPaper } }}
                        >
                          <MenuItem value="cm">CM</MenuItem>
                          <MenuItem value="in">IN</MenuItem>
                        </Select>
                      </Box>
                    </Box>
                  </Box>
                  <Box display="flex">
                    <Box width={"35vw"} mr={1} flexGrow="1">
                      <div
                        className={
                          small && matches
                            ? classes.smSubTitle
                            : classes.subTitle
                        }
                      >
                        Weight *
                      </div>
                      <CustomInput
                        // className={classes.textfield}
                        value={shippingItem.weight}
                        onChange={handleItemChange("weight")}
                        // disabled={!selectedType}
                        error={
                          userInputError.weightError ||
                          (userInputError.emtpyError &&
                            shippingItem.weight === null)
                        }
                        type="weight"
                        weightLabel="kg"
                        onKeyDown={(evt) => {
                          (evt.key === "e" ||
                            evt.key === "-" ||
                            evt.key === "E") &&
                            evt.preventDefault();
                        }}
                        placeholderValue={
                          selectedType &&
                          shipmentTypesDetails[selectedType].max_weight
                            ? `Maximum: ${shipmentTypesDetails[selectedType].max_weight}`
                            : ""
                        }
                      />
                      <Box
                        pl={1}
                        color="red"
                        visibility={
                          userInputError.weightError ? "visible" : "hidden"
                        }
                      >
                        {userInputError.weightError}
                      </Box>
                    </Box>
                    <Box width={"5vw"} flexGrow="1" display="flex">
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-evenly"
                      >
                        <div
                          className={
                            small && matches
                              ? classes.smSubTitle
                              : classes.subTitle
                          }
                          style={{ visibility: "hidden" }}
                        >
                          {/*Weight Unit*/}
                          Dimensions *
                        </div>
                        <Select
                          value={shippingItem.weightUnit}
                          label="Unit"
                          onChange={handleItemChange("weightUnit")}
                          className={classes.textfield}
                          classes={{ root: classes.selectRoot }}
                          MenuProps={{ classes: { paper: classes.menuPaper } }}
                        >
                          <MenuItem value="kg">KG</MenuItem>
                          <MenuItem value="lb">LB</MenuItem>
                        </Select>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box
                      // width={matches ? "60vw" : "21vw"}
                      // mb={2}
                      // mt={1}
                      // minWidth={matches ? "" : "360px"}
                      style={{ textAlign: "left" }}
                    >
                      <div className={classes.subTitle}>Volume</div>
                      {/* <CustomInput
                        // className={classes.textfield}
                        value={shippingItem.quantity}
                        onChange={handleItemChange("quantity")}
                        disabled={selectedType === "6" || selectedType === "7"}
                        type="number"
                      /> */}
                      {(() => {
                        let calculateVolume =
                          shippingItem.height *
                          shippingItem.width *
                          shippingItem.length *
                          shippingItem.looseUnit;
                        if (isNaN(calculateVolume)) {
                          calculateVolume = 0;
                        }
                        if (lengthUnitText === "CM") {
                          if (calculateVolume >= 1e6) {
                            return (
                              <span>
                                {(calculateVolume * 0.000001).toFixed(1)} m
                                <sup>3</sup>
                              </span>
                            );
                          }
                          return (
                            <span>
                              {calculateVolume.toFixed(1)} cm<sup>3</sup>
                            </span>
                          );
                        }
                        if (calculateVolume >= 1728) {
                          return (
                            <span>
                              {(calculateVolume * 0.000578704).toFixed(1)} foot
                              <sup>3</sup>
                            </span>
                          );
                        }
                        return (
                          <span>
                            {calculateVolume.toFixed(1)} inch<sup>3</sup>
                          </span>
                        );
                      })()}
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                  <Box
                      width="100%"
                      height="100%"
                      display="flex"
                      justifyContent="end"
                      alignItems="center"
                    >
                      {/* <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpen}
                      >
                        Select from my boxes
                      </Button> */}
                      <Tooltip title="Select from my boxes" placement="top">
                        {/* <Button
                          variant="contained"
                          // color="primary"
                          sx={{
                            backgroundColor: "#000000",
                          }}
                          onClick={handleOpen}
                          mr={2}
                        > */}
                        <Box
                          height="100%"
                          display="flex"
                          mr={2}
                          alignItems="center"
                          onClick={handleOpen}
                        >
                          <img
                            // src="/box.png"
                            src="/box1.png"
                            style={{ width: "30px", height: "30px", cursor: "pointer" }}
                            alt="image"
                          />
                        </Box>
                        {/* </Button> */}
                      </Tooltip>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box>
                {errorMsg ? (
                  <Alert severity="error" classes={{ root: classes.alertRoot }}>
                    {errorMsg}
                  </Alert>
                ) : (
                  ""
                )}
              </Box>
              <Box mb={2} mt={2} display="flex" justifyContent="center">
                {edit && (
                  <Box mr={2}>
                    <Button
                      variant="outlined"
                      color="default"
                      onClick={onCancel}
                    >
                      CANCEL
                    </Button>
                  </Box>
                )}

                <Box ml={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handlePalletData("LooseItem")}
                  >
                    {edit || guest ? "UPDATE" : "ADD"}
                  </Button>
                </Box>
              </Box>
            </Box>
          </TabPanel>
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    borderBottom: "1px solid rgba(0,0,0,0.1)",
  },
  // textfield: {
  //   width: "100%",
  //   backgroundColor: "white",
  //   borderRadius: "5px",
  //   // boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
  //   // paddingTop: "0.4vw",
  //   // paddingBottom: "0.4vw",
  // },
  // selectRoot: {
  //   paddingLeft: theme.spacing(1),
  //   paddingTop: theme.spacing(1),
  //   paddingBottom: theme.spacing(1),
  // },
  menuPaper: {
    maxWidth: 150,
  },
  input: {
    color: "grey",
    width: "90%",
  },
  icon: {
    fontSize: "32px !important",
    marginBottom: theme.spacing(1),
  },
  subTitle: {
    color: "darkgreen",
    fontWeight: 600,
    fontSize: "1.2rem",
    fontFamily: "Montserrat",
  },
  smSubTitle: {
    color: "darkgreen",
    fontWeight: 600,
    fontSize: "1rem",
    fontFamily: "Montserrat",
  },
  alertRoot: {
    width: "80%",
    minWidth: "200px",
    margin: "10px auto",
  },
  warningText: {
    color: "red",
    fontWeight: "bold",
  },
}));

ShippingItemInput.propTypes = {
  item: PropTypes.object,
  edit: PropTypes.bool,
  onCancel: PropTypes.func,
  small: PropTypes.bool,
  guest: PropTypes.bool,
  changeItem: PropTypes.func,
  setShippingItems: PropTypes.func,
};

export default ShippingItemInput;
