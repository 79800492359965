import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";
import Divider from "@mui/material/Divider";
import {LatestShippingOrderTableRow} from "./LatestShippingOrderTableRow";
import {LatestMovingOrderTableRow} from "./LatestMovingOrderTableRow";
import {useSelector} from "react-redux";

export const LatestMovingOrderTable = ({order, getMovingOrderList}) => {

    const isMobile = useMediaQuery('(max-width: 480px)');
    const ownerRole = useSelector(state => state?.user?.ownerRole);

    return (
        <Grid container spacing={1}>
            <Grid item md={2} xs={4}>
                <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                    Order
                </Typography>
            </Grid>
            {!isMobile &&
                <Grid item md={2}>
                    <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'left'}}>
                        Move From
                    </Typography>
                </Grid>
            }
            {!isMobile &&
                <Grid item md={2}>
                    <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'left'}}>
                        Move To
                    </Typography>
                </Grid>
            }
            <Grid item md={2} xs={3}>
                <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                    {
                        ownerRole === 1 ? 'Order Status' : 'Invoice'
                    }
                </Typography>
            </Grid>
            {!isMobile &&
                <Grid item md={2} xs={3}>
                    <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                        Shipping Status
                    </Typography>
                </Grid>
            }
            <Grid item md={1} xs={3}>
                <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                    Charge
                </Typography>
            </Grid>
            <Grid item md={1} xs={2}>
                <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                    Action
                </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
                <Divider/>
            </Grid>
            {
                order?.map((orderEach, index) =>
                    <Grid item xs={12} key={index}>
                        {/*<LatestShippingOrderTableRow order={orderEach} index={index}/>*/}
                        <LatestMovingOrderTableRow order={orderEach} index={index}
                                                   getMovingOrderList={getMovingOrderList}/>
                    </Grid>
                )
            }
        </Grid>
    )
}