import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Box, makeStyles} from "@material-ui/core";
import PayWithCreditCard from "./PayWithCreditCard";
import PayWithPayCheckWechat from "./PayWithPayCheckWechat";
import PayWithWechatGeneral from "./PayWithWechatGeneral";
import PayWithAliPayGeneral from "./PayWithAliPayGeneral";
import {Button, Snackbar} from "@mui/material";
import TextField from "@mui/material/TextField";
import {useState} from "react";
import InputLabel from "@material-ui/core/InputLabel";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import LoadingButton from "@mui/lab/LoadingButton";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
    rootExpanded: {
        background: "blue",
        flexGrow: 1
    },
    accordion: {
        width: "100%"
    }
}));

const PaymentsAccordion = ({orderId, orderNumber}) => {

    const classes = useStyles();

    const [referenceNumber, setReferenceNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [referenceOrderNumber, setReferenceOrderNumber] = useState('');

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleReferenceNumber = (event) => {
        setReferenceNumber(event.target.value);
    }

    const EndUserPayViaEmailTransfer = async () => {
        setLoading(true);
        const requestURL = `${NODE_ROUTE_URI}/looseItem/placeBackendShippingOrderByEmailReferenceNumber`

        const data = {
            referenceNumber: referenceNumber,
            backendOrderId: orderId
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })
            console.log('result', result);
            setErrorMessage('');
            setLoading(false);
        } catch (e) {
            console.log(e.response)
            setErrorMessage(e?.response?.data?.error);
            handleErrorMessage();
            setLoading(false);
        }
    }

    // const EndUserPayViaEmailTransfer = () => {
    //     setLoading(true);
    //     const requestURL = `${NODE_ROUTE_URI}/looseItem/placeBackendShippingOrderByEmailReferenceNumber`;
    //
    //     const data = {
    //         referenceNumber: referenceNumber,
    //         backendOrderId: orderId
    //     };
    //
    //     axios
    //         .post(requestURL, data)
    //         .then((result) => {
    //             console.log('result', result);
    //             setErrorMessage('');
    //             setLoading(false);
    //         })
    //         .catch((error) => {
    //             console.log(error.response);
    //             setErrorMessage(error?.response?.data?.error);
    //             handleErrorMessage();
    //             setLoading(false);
    //         });
    // };

    const handleEmailPayment = () => {
        EndUserPayViaEmailTransfer()
    }

    return (
        <Box display="flex" width="100%" flexDirection="column">
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Accordion className={classes.accordion} defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                >
                    <Typography>E-TRANSFER</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
                        <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                            <Typography>
                                <span style={{color: '#1D8B45', fontWeight: '600'}}>Step 1:</span> Transfer the total
                                payment to the email address below with a specific message
                            </Typography>
                            <Box style={{paddingLeft: '10px'}}>
                                <Typography>
                                    e-Transfer to <strong>pay@uucargo.ca</strong>
                                </Typography>
                                <Typography>
                                    Message should be <strong># {orderNumber}</strong>
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                            <Typography>
                                <span style={{color: '#1D8B45', fontWeight: '600'}}>Step 2:</span> After making the
                                payment, you will get an email with a Reference Number.
                            </Typography>
                            <Box>
                                <InputLabel>Please input your Reference Number</InputLabel>
                                <TextField
                                    size='small'
                                    onChange={handleReferenceNumber}
                                    sx={{width: '300px'}}
                                />
                            </Box>
                        </Box>
                        <Box>
                            <LoadingButton
                                variant='contained'
                                onClick={handleEmailPayment}
                                color='success'
                                loading={loading}
                                disabled={referenceNumber ? false : true}
                            >
                                Confirm Payment
                            </LoadingButton>
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accordion} defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>CREDIT CARD</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <PayWithCreditCard orderId={orderId} orderNumber={orderNumber}/>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accordion}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography>WECHAT PAY</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        {/*<PayWithPayCheckWechat orderId={orderId} orderNumber={orderNumber} />*/}
                        <PayWithWechatGeneral orderId={orderId} orderNumber={orderNumber}/>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accordion}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography>AliPay</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        {/*<PayWithPayCheckWechat orderId={orderId} orderNumber={orderNumber} />*/}
                        <PayWithAliPayGeneral orderId={orderId} orderNumber={orderNumber}/>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}

export default PaymentsAccordion;