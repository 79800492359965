import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  alertRoot: {
    width: "90%",
    maxWidth: "50rem",
  },
}));
const ALertMessageLayout = ({ message, onClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.root} onClick={onClick}>
      <Alert severity="info" classes={{ standardInfo: classes.alertRoot }}>
        {message}
      </Alert>
    </div>
  );
};

ALertMessageLayout.propTypes = {
  message: PropTypes.string.isRequired,
};

export default ALertMessageLayout;
