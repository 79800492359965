import {useState} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as React from "react";
import FormControl from "@mui/material/FormControl";
import {MenuItem, Select} from "@mui/material";
import {AdjustOrderDashboardInvoiceCreditCard} from "./AdjustOrderDashboardInvoiceCreditCard";
import {AdjustOrderDashboardInvoiceWeChat} from "./AdjustOrderDashboardInvoiceWeChat";
import {AdjustOrderDashboardInvoiceAliPay} from "./AdjustOrderDashboardInvoiceAliPay";

export const AdjustOrderDashboardInvoicePayment = ({orderDetails, invoiceStatus}) => {

    const [selectPayment, setSelectPayment] = useState('card');

    const handleSelectPayment = (event) => {
        setSelectPayment(event?.target?.value);
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography sx={{fontWeight: '600'}}>
                    Payment Methods
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <Select
                        value={selectPayment}
                        size='small'
                        onChange={handleSelectPayment}
                    >
                        <MenuItem value='card'>
                            Credit Card
                        </MenuItem>
                        <MenuItem value='wechatPay'>
                            WeChat Pay
                        </MenuItem>
                        <MenuItem value='aliPay'>
                            Ali Pay
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                {
                    selectPayment === 'card' && <AdjustOrderDashboardInvoiceCreditCard orderDetails={orderDetails}/>
                }
                {
                    selectPayment === 'wechatPay' && <AdjustOrderDashboardInvoiceWeChat orderDetails={orderDetails}/>
                }
                {
                    selectPayment === 'aliPay' && <AdjustOrderDashboardInvoiceAliPay orderDetails={orderDetails}/>
                }
            </Grid>
        </Grid>
    )
}