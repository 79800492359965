import {Fragment, useEffect, useState} from "react";
import {LABEL_DOWNLOAD_URL_PREFIX, PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {deleteToken, getAccessToken} from "../../../Utils/doToken";
import {useSelector} from "react-redux";
import MuiAlert from "@mui/material/Alert";
import * as React from "react";
import Box from "@mui/material/Box";
import {Divider, Snackbar} from "@mui/material";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import LoadingContainer from "../../shared/loadingContainer";
import {TIME_OUT} from "../../../Utils/consInfo";
import {
    ReactComponent as ChooseCarrierAndServicesIcon
} from "../../../images/NewDesignIcon/ChooseCarrierAndServices.svg";
import intl from "react-intl-universal";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingLTLQuickQuoteRateHeading: {
        textAlign: 'center',
        fontWeight: '600',
        fontSize: '18px'
    },
    ShippingLTLQuickQuoteRateBox: {
        border: '1px solid #609966',
        padding: '20px 0',
        borderRadius: '5px'
    },
    ShippingLTLQuickQuoteRateText: {
        textAlign: 'left',
        fontSize: '16px',
    },
    ShippingLTLQuickQuoteRateCarrierBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100%',
        paddingLeft: '20px'
    },
    ShippingPackageCreateLabelRateHeading: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelRateListContent: {
        backgroundColor: '#FFFFFF',
        padding: '0 40px',
        marginBottom: '40px',
        borderRadius: '10px',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
}

export const ShippingLTLCreateLabelRateAverage = ({
                                                      allService,
                                                      calledAllCarrier,
                                                      availableService,
                                                      cbHandleLoading
                                                  }) => {

    const token = getAccessToken("access_token");

    let shipFrom = useSelector(state => state?.shippingLTL?.createLabelShipFrom);
    let shipTo = useSelector(state => state?.shippingLTL?.createLabelShipTo);
    let rateRedux = useSelector(state => state?.shippingLTL?.createLabelRate);

    const [otherRate, setOtherRate] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sortedRate, setSortedRate] = useState([]);
    const [isCallOtherRate, setIsCallOtherRate] = useState(0);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const getLTLRatingByOneServiceWithMaxQuantity = async (serviceName) => {
        let requestURL = `${PARTNER_URI}/shippingLTL/LTLShippingAverageRatingByServiceName`;
        let data = {
            shipperCity: shipFrom?.parentCity,
            shipperSubCity: shipFrom?.city,
            shipperProvince: shipFrom?.province?.code,
            shipperCountry: shipFrom?.province?.countryCode,
            shipperPostalCode: shipFrom?.postalCode,
            consigneeCity: shipTo?.parentCity,
            consigneeSubCity: shipTo?.city,
            consigneeProvince: shipTo?.province?.code,
            consigneeCountry: shipTo?.province?.countryCode,
            consigneePostalCode: shipTo?.postalCode,
            serviceName: serviceName,
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data,
                timeout: TIME_OUT
            })
            const LTLRate = result?.data;
            console.log('ltl rating', LTLRate);
            setOtherRate(prevState => {
                return prevState?.map(rateItem => {
                    if (rateItem?.serviceName === LTLRate?.serviceName) {
                        return LTLRate;
                    }
                    return rateItem;
                })
            })
            return true;
        } catch (e) {
            // setError(prevState => [...prevState, service?.account_base]);
            // console.log('error', e);
            if (e.code === 'ECONNABORTED') {
                console.log('Request timed out');
            } else if (e?.response?.status === 440) {
                setErrorMessage('The authentication token has expired.');
                deleteToken("access_token");
                setTimeout(() => window.location.reload(), 2000);
                handleErrorMessage();
            } else {
                return false;
            }
        } finally {
            setIsCallOtherRate(prevState => prevState + 1);
            setOtherRate(prevRate => {
                return prevRate.map(rateItem => {
                    if (rateItem?.serviceName.toUpperCase() === serviceName?.toUpperCase()) {
                        return {...rateItem, loading: false};
                    }
                    return rateItem;
                });
            });
        }
    }

    useEffect(async () => {
        if (calledAllCarrier === availableService?.length) {
            const unCalledService = allService?.filter(carrier => !rateRedux?.some(rateCarrier => carrier?.account_base?.toUpperCase() === rateCarrier?.serviceName?.toUpperCase()));
            const notRatingService = rateRedux?.filter(carrier => carrier?.finalTotalBeforeTax === 0);
            const unavailableService = [...unCalledService, ...notRatingService];
            console.log('[getLTLRatingByOneServiceWithMaxQuantity] unavailable service', unavailableService);
            const updateRate = unavailableService.map(item => ({
                ...item,
                serviceName: item?.account_base?.toLowerCase(),
                loading: true,
                averageTotalCharge: 0
            }));
            setOtherRate(updateRate);
            for (const service of unavailableService) {
                await getLTLRatingByOneServiceWithMaxQuantity(service?.account_base);
            }
            setLoading(false);
            cbHandleLoading();
        }
    }, [calledAllCarrier, availableService, allService])

    useEffect(() => {
        const sortedRate = [...otherRate].sort((a, b) => (a?.averageTotalCharge || Infinity) - (b?.averageTotalCharge || Infinity));
        // console.log('[ShippingLTLQuickQuoteOtherRate] sort rate', sortedRate);
        setSortedRate(sortedRate);
    }, [otherRate]);

    if (loading) {
        return (
            <Box sx={{
                backgroundColor: '#FFFFFF',
                // padding: '20px',
                marginBottom: '40px',
                borderRadius: '10px',
                boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                gap: '20px',
                display: 'flex',
                flexDirection: 'column',
                margin: '20px 0'
            }}>
                <Box sx={{
                    display: 'flex',
                    gap: '10px',
                    padding: '20px 20px 0 20px'
                }}>
                    <Typography style={styles.ShippingPackageCreateLabelRateHeading}>
                        Average Quote
                    </Typography>
                </Box>
                <Divider/>
                <Box style={styles.ShippingPackageCreateLabelRateListContent}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography sx={{textAlign: 'left', fontWeight: '600'}}>
                                Below is the average reference quotation for a standard pallet.
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{textAlign: 'left'}}>
                                From: {shipFrom?.city}, {shipFrom?.province?.code}, {shipFrom?.country}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{textAlign: 'left'}}>
                                To: {shipTo?.city}, {shipTo?.province?.code}, {shipTo?.country}
                            </Typography>
                        </Grid>
                        <Grid>
                            <LoadingContainer/>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        )
    }

    if ((isCallOtherRate === sortedRate?.length) && (sortedRate?.length === 0 || sortedRate?.every(obj => obj?.averageTotalCharge === 0))) {
        return <></>
    }

    console.log('[getLTLRatingByOneServiceWithMaxQuantity] sortedRate', sortedRate);
    console.log('[getLTLRatingByOneServiceWithMaxQuantity] isCallOtherRate', isCallOtherRate);
    console.log('[getLTLRatingByOneServiceWithMaxQuantity] allService', allService);
    console.log('[getLTLRatingByOneServiceWithMaxQuantity] ship from', shipFrom);

    return (
        <Box sx={{
            backgroundColor: '#FFFFFF',
            // padding: '20px',
            marginBottom: '40px',
            borderRadius: '10px',
            boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
            gap: '20px',
            display: 'flex',
            flexDirection: 'column',
            margin: '20px 0'
        }}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box sx={{
                display: 'flex',
                gap: '10px',
                padding: '20px 20px 0 20px'
            }}>
                <Typography style={styles.ShippingPackageCreateLabelRateHeading}>
                    Average Quote
                </Typography>
            </Box>
            <Divider/>
            <Box style={styles.ShippingPackageCreateLabelRateListContent}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography sx={{textAlign: 'left', fontWeight: '600'}}>
                            Below is the average reference quotation for a standard pallet.
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography sx={{textAlign: 'left'}}>
                            From: {shipFrom?.city}, {shipFrom?.province?.code}, {shipFrom?.country}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography sx={{textAlign: 'left'}}>
                            To: {shipTo?.city}, {shipTo?.province?.code}, {shipTo?.country}
                        </Typography>
                    </Grid>
                    {
                        sortedRate?.map((each, index) => (
                            (each.loading || each.averageTotalCharge !== 0) && <Fragment key={index}>
                                <Grid item xs={1}>
                                    <Box sx={styles.ShippingLTLQuickQuoteRateCarrierBox}>
                                        <img
                                            src={`${LABEL_DOWNLOAD_URL_PREFIX}${each?.icon_uri}`}
                                            alt="logo"
                                            width={60}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={5}>
                                    <Box sx={styles.ShippingLTLQuickQuoteRateCarrierBox}>
                                        <Typography style={styles.ShippingLTLQuickQuoteRateText}>
                                            {
                                                each?.serviceName.toUpperCase() === "DAYANDROSS" ? "DAY && ROSS" : each?.serviceName.toUpperCase() === "TFORCE" ? "TForce" : each?.serviceName.toUpperCase()
                                            }
                                        </Typography>
                                    </Box>
                                </Grid>
                                {each.loading ?
                                    <Grid item xs={6}>
                                        <LoadingContainer/>
                                    </Grid> : <>
                                        <Grid item md={6} xs={12}>
                                            <Box sx={styles.ShippingLTLQuickQuoteRateCarrierBox}>
                                                <Typography
                                                    style={styles.ShippingLTLQuickQuoteRateHeading}>
                                                    ${(each?.averageTotalCharge)?.toFixed(2)} / per standard pallet
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </>
                                }
                            </Fragment>
                        ))
                    }
                </Grid>
            </Box>
        </Box>
    )
}