import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Snackbar
} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import {ShipSummaryShipFrom} from "./ShipSummaryShipFrom";
import {ShipSummaryShipTo} from "./ShipSummaryShipTo";
import {ShipSummaryItemList} from "./ShipSummaryItemList";
import {ShipSummaryService} from "./ShipSummaryService";
import * as React from "react";
import {useState} from "react";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import {getAccessToken} from "../../Utils/doToken";
import axios from "axios";
import getProvinceCodeByName from "../../Utils/getProvinceCode";
import {checkAllPropertiesHaveValueFalse, getCountryCodeByName, getMissingKeys} from "../../Utils/Helper";
import {updateShipFromError, updateShipToError} from "../../actions/ratingAction";
import MuiAlert from "@mui/material/Alert";
import {Link} from "@mui/joy";
import LoadingButton from "@mui/lab/LoadingButton";

// todo
// ship address validate input, name and phone cannot empty, phone must be digits

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
    ShipSummaryRoot: {
        width: '1000px',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px'
    },
    ShipSummaryButtonSection: {
        backgroundColor: '#2F58CD',
        width: '200px',
        height: '60px',
        borderRadius: '15px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: '0.2s ease-out',
        ':hover': {
            opacity: 0.9
        }
    },
    ShipSummaryButton: {
        width: '100%',
        height: '100%',
        color: '#FFFFFF',
        fontSize: '16px',
        fontWeight: '600',
    },
    ShipSummaryStartOverSection: {
        // backgroundColor: '#EEEEEE',
        width: '100%',
        height: '60px',
        borderRadius: '15px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: '0.2s ease-out',
        ':hover': {
            opacity: 0.9
        }
    },
    Link: {
        color: 'green',
        ':hover': {
            color: 'green'
        }
    },
    DisclosureButton: {
        backgroundColor: 'green',
        color: '#FFFFFF',
        padding: '0 40px',
        margin: '0 0 20px',
        "&:hover": {
            backgroundColor: 'green',
            color: '#FFFFFF',
            filter: 'brightness(85%)'
        },
    },
}

export const ShipSummary = (props) => {

    let history = useHistory();
    let summaryData = props.location.state.data;
    let shipFromAddress = useSelector(state => state?.rating?.shippingFrom);
    let shipToAddress = useSelector(state => state?.rating?.shippingTo);
    let crossborder = useSelector(state => state?.rating?.shippingCrossBorder);
    let itemList = useSelector(state => state?.rating?.itemList);
    let shipAddressError = useSelector(state => state?.rating?.shippingAddressErrors);

    const dispatch = useDispatch();

    console.log('shipFrom from address', shipFromAddress);
    console.log('shipFrom to address', shipToAddress);
    console.log('ship summary', summaryData);
    console.log('cross border', crossborder);
    console.log('item list', itemList);

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [startOverDialogOpen, setStartOverDialogOpen] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [dialogDisclosure, setDialogDisclosure] = useState(false);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleConfirmDialogClose = () => {
        setConfirmDialogOpen(false);
    };

    const handleConfirmDialogOpen = () => {
        if (validation()) {
            setConfirmDialogOpen(true);
        }
    };

    const handleStartOverDialogClose = () => {
        setStartOverDialogOpen(false);
    };

    const handleStartOverDialogOpen = () => {
        setStartOverDialogOpen(true);
    };

    const handleDialogDisclosureClose = () => {
        setDialogDisclosure(false);
    };

    const validateShipFrom = () => {
        const missingKeys = getMissingKeys(shipFromAddress);
        if (missingKeys.length === 0) {
            dispatch(updateShipFromError(missingKeys))
            return false
        } else {
            dispatch(updateShipFromError(missingKeys))
            return true
        }
    }

    const validateShipTo = () => {
        const missingKeys = getMissingKeys(shipToAddress);
        if (missingKeys.length === 0) {
            dispatch(updateShipToError(missingKeys))
            return false
        } else {
            dispatch(updateShipToError(missingKeys))
            return true
        }
    }

    const validation = () => {
        setErrorMessage('');
        setToastOpen(false);
        validateShipFrom() && setErrorMessage(prevState => prevState + ' Shipping from address is missing one or more required fields.');
        validateShipTo() && setErrorMessage(prevState => prevState + ' Shipping to address is missing one or more required fields.');
        !checkAllPropertiesHaveValueFalse(shipAddressError) && setErrorMessage(prevState => prevState + ' The shipping address contains invalid values.');

        if (validateShipFrom() ||
            validateShipTo() ||
            !checkAllPropertiesHaveValueFalse(shipAddressError)
        ) {
            handleErrorMessage();
            return false
        } else {
            return true
        }
    }

    const initBackendOrder = async () => {
        setLoading(true);
        let requestURL = `${NODE_ROUTE_URI}/looseItem/endUser/init3PLOrder`;
        const accessToken = getAccessToken("access_token");

        const packageData = itemList?.map(obj => ({
            description: obj?.name,
            length: obj?.length,
            height: obj?.height,
            width: obj?.width,
            quantity: obj?.quantity,
            weight: obj?.weight,
            lengthUnit: obj?.dimensionUnit,
            weightUnit: obj?.weightUnit,
            hscode: obj?.hsCode,
            originCountryCode: obj?.originCountry,
            currency: obj?.currency,
            priceEach: obj?.unitPrice,
            UOM: obj?.uom
        }))

        const data = {
            accountBaseCombinedName: summaryData?.accountBaseCombineName,
            serviceType: summaryData?.serviceType,
            shipFromName: shipFromAddress?.name,
            shipFromCompanyName: shipFromAddress?.company || shipFromAddress?.name,
            shipFromPhone: shipFromAddress?.phone,
            shipFromEmail: shipFromAddress?.email,
            shipFromAddress: shipFromAddress?.address,
            shipFromCity: shipFromAddress?.city,
            shipFromProvince: getProvinceCodeByName(shipFromAddress?.province),
            shipFromCountry: getCountryCodeByName(shipFromAddress?.country),
            shipFromPostalCode: shipFromAddress?.postalCode.replace(/\s+/g, ''),
            shipToName: shipToAddress?.name,
            shipToCompanyName: shipToAddress?.company || shipToAddress?.name,
            shipToPhone: shipToAddress?.phone,
            shipToEmail: shipToAddress?.email,
            shipToAddress: shipToAddress?.address,
            shipToAddressTwo: shipToAddress?.address2,
            shipToCity: shipToAddress?.city,
            shipToProvince: getProvinceCodeByName(shipToAddress?.province),
            shipToCountry: getCountryCodeByName(shipToAddress?.country),
            shipToPostalCode: shipToAddress?.postalCode.replace(/\s+/g, ''),
            cargoControlNumber: "",
            refOrderNumber: "",
            shipmentType: shipFromAddress.country === shipToAddress.country ? null : crossborder?.shipmentType,
            transMode: shipFromAddress.country === shipToAddress.country ? null : crossborder?.transMode,
            packageData
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: data
            })
            console.log('api init backend order', result);
            setSuccessMessage('Successfully place order');
            handleSuccessMessage();
            handleConfirmDialogClose();
            setLoading(false);
            setDisabled(true);
            // Go to order detail page after 2 second
            setTimeout(() => {
                history.push(`/shippingOrderDetails/${result?.data?.id}`);
            }, 2000);
        } catch (e) {
            console.log(e.response);
            setLoading(false);
        }
    }

    return (
        <Box sx={style.ShipSummaryRoot}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Dialog
                open={confirmDialogOpen}
                onClose={handleConfirmDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Place Order</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This process will place your Shipping Order. Are you sure to
                        continue?
                        <hr/>
                        Click <b>Confirm</b> to place an order
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleConfirmDialogClose}
                        variant="outlined"
                        autoFocus
                        type="button"
                    >
                        No
                    </Button>
                    <LoadingButton
                        onClick={initBackendOrder}
                        variant="outlined"
                        autoFocus
                        type="button"
                        loading={loading}
                        disabled={disabled}
                    >
                        Confirm
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Dialog
                open={startOverDialogOpen}
                onClose={handleStartOverDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Start Over</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This process will start over and enter shipping addresses and items again. Are you sure to
                        continue?
                        <hr/>
                        Click <b>Confirm</b> to start over
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleStartOverDialogClose}
                        variant="outlined"
                        autoFocus
                        type="button"
                    >
                        No
                    </Button>
                    <Button
                        onClick={() => history.push('/rating')}
                        variant="outlined"
                        autoFocus
                        type="button"
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={dialogDisclosure}
                onClose={handleDialogDisclosureClose}
            >
                <DialogContent sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                    <DialogContentText>
                        <span style={{color: 'green'}}> A shipping adjustment </span> occurs when an incorrect shipping
                        label is generated due to
                        inaccurate
                        shipment information. This causes the shipping carrier to readjust the shipping information to
                        ensure the package reaches its final destination and the correct shipping costs are paid.
                    </DialogContentText>
                    <DialogContentText>
                        If there is a difference between what they measure and what you declared on UUCargo, you may be
                        charged extra according to the changes in shipping costs. If the parcel is bigger/heavier than
                        declared, you will be charged to make up for the difference. This works the other way too; if
                        the parcel is smaller/lighter than declared, you will receive an appropriate partial refund.
                    </DialogContentText>
                    <DialogContentText>
                        <span style={{color: 'green', textDecoration: 'underline green'}}>*The bill from the carriers determines the final price for a package.</span> We
                        will return credit to
                        your account balance or charge an additional fee from your payment method depending on the bill.
                    </DialogContentText>
                    <DialogContentText>
                        Should you have any questions or concerns regarding shipping adjustments or billing, our
                        customer support team is available to assist you. We value your business and are committed to
                        providing a reliable and cost-effective shipping experience with UUCargo.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <Button
                            sx={style.DisclosureButton}
                            onClick={handleDialogDisclosureClose}
                        >
                            Accept
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <Typography variant="h4">
                Shipping Summary
            </Typography>
            {/*<Box>*/}
            <ShipSummaryShipFrom/>
            <ShipSummaryShipTo/>
            <ShipSummaryItemList/>
            <ShipSummaryService summaryData={summaryData}/>
            {/*<Box sx={{display: 'flex', justifyContent: 'flex-start', width: '100%'}}>*/}
            {/*    <Typography>*/}
            {/*        *This may not be your final price.*/}
            {/*        <Link*/}
            {/*            sx={style.Link}*/}
            {/*            onClick={() => setDialogDisclosure(true)}*/}
            {/*        >*/}
            {/*            Learn more*/}
            {/*        </Link>*/}
            {/*    </Typography>*/}
            {/*</Box>*/}
            <Box sx={style.ShipSummaryButtonSection}>
                <Button
                    onClick={handleConfirmDialogOpen}
                    sx={style.ShipSummaryButton}
                    disabled={disabled}
                >
                    Place Order
                </Button>
            </Box>
            <Box sx={style.ShipSummaryStartOverSection}>
                <Typography>
                    Start over shipping address and add item.
                </Typography>
                <Button onClick={handleStartOverDialogOpen}>
                    Start over
                </Button>
            </Box>
        </Box>
    )
}