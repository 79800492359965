import React, {useState} from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { List, ListItem, ListItemIcon, ListItemText, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import '../../styles/myOrders.css'

import store from '../../store/index'
import { useHistory } from 'react-router-dom'
import { getOrders, selectOrder } from '../../actions/orderActions';
// import { getPackages } from '../../actions/packageAction';
// import { getOrderShipments } from '../../actions/orderShipmentAction';
import { connect, useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
    paper: {
        // backgroundColor: theme.palette.background.paper,
        maxWidth: 360,
        width: '100%',
        padding: theme.spacing(2, 4, 3),
        overflow: 'hidden',
        backgroundColor: 'rgba(255, 166, 0, 0.705)'
    },
    button: {
        borderRadius: 20,
        fontSize: "25pt",
        backgroundColor:'#ffa726',
        color: "white"
    },
}))

const RequestCancel = (props) => {
    const [selectedIndex, setSelectedIndex] = useState(0)
    const history = useHistory();
    const classes = useStyles();

    const order_num = useSelector(state => state.orders.selected_order_number)
    const path = `/order-details/${order_num}`

    const handleListItemclick = (e, index) => {
        e.preventDefault()
        setSelectedIndex(index)
    }

    const goOrderDetailPage = (orderNumber) => {
        console.log(orderNumber)
        let path = `/order-details/${orderNumber}`
        store.dispatch(selectOrder(orderNumber))
        history.push(path)
      }
    
    return(
        <div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <div className="cancel-container">
                <div id="cancel-content0">
                    <h1 >
                        Cancel
                    </h1> 
                    <p className="bodytext">
                            Order #123-321
                    </p>
                </div>
                <div id="cancel-content1">
                    <p className="bodytext">Price</p>
    
                </div>
                <div id="cancel-content2">
                    
                </div>
                <div id="cancel-content3">
                    <p className="bodytext">Default fine</p>
                </div>
                <div id="cancel-content4">
                    <p>$0.00</p>
                </div>
                <div id="cancel-content5">
                    <p className="bodytext">Refund</p>
                </div>
                <div id="cancel-content6">
                    <p>$125.00</p>
                </div>
                <div id ="cancel-content7">
                    <p>Your refund is automatically determined by our
                        <p>
                        <Link>
                            Cancellation Policy
                        </Link>
                        </p>
                    </p>
                </div>
                <div id="cancel-content9">
                    <p className="title">
                        Tell Us Why!
                    </p>
                </div>
                <div id="cancel-content10">
                    <p className="bodytext">
                        Please select a reason
                    </p>
                    <div>
                    <List component="nav" className={classes.paper}>
                                <ListItem
                                    selected={selectedIndex === 0}
                                    onClick={(e) => handleListItemclick(e, 0)}
                                    button
                                >
                                    <ListItemText primary="I don't want this order anymore." />
                                </ListItem>
                                <ListItem
                                    selected={selectedIndex === 1}
                                    onClick={(e) => handleListItemclick(e, 1)}
                                    button
                                >
                                    <ListItemText primary="Too expensive to ship." />
                                </ListItem>
                                <ListItem
                                    selected={selectedIndex === 2}
                                    onClick={(e) => handleListItemclick(e, 2)}
                                    button
                                >
                                    <ListItemText primary="No Reason."/>
                                </ListItem>
                            </List>
                    </div>
                </div>
                
                <div id ="cancel-content11">
                    <Link to = {path}>
                        <Button className={classes.button}>
                            Continue
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default connect()(RequestCancel);