import React from "react";

import {Route, Switch, Redirect} from "react-router-dom";

import UserProfile from "../components/Profiles/userProfile";
import AddCreditCardPage from "../components/Profiles/addCreditCardPage";
import {
    // UserSignUpPage,
    UserSignInPage,
    ActiveResultPage,
    // JoinCompany,
    ReceiveEmailPage,
    ResetPasswordPage,
} from "../components/Auth";

// import ActiveResultPage from './components/Auth/ActiveResultPage/activeResultPage';
import ForgotPasswordPage from "../components/Auth/forgotPasswordPage";
import ChangePasswordPage from "../components/Auth/changePassword";
// import ReceiveEmailPage from './components/Auth/receiveEmail';
import LandingPage from "../components/LandingPage/landingPage";
import GuestCheckout from "../components/Shipping/guestCheckoutPage";
import UserSettings from "../components/userSettingsPage";
import {AddressBook} from "../components/AddressBook/AddressBook";
import AddressInput from "../components/AddressBook/addressInputPage";

import StartQuote from "../components/SmallPackages/startQuote";
import PaymentPage from "../components/Shipping/paymentPage";
import OrderConfirm from "../components/Shipping/orderConfirmed";
import OrderDetails from "../components/MyOrders/orderDetails";
import ControlNumber from "../components/ControlNumber/controlNumber";
import ShipmentDetails from "../components/ControlNumber/shipmentDetails";
import RequestCancel from "../components/MyOrders/requestCancel";
import OrderList from "../components/MyOrders/orderList";

import DriverLandingPage from "../components/DriverLandingPage";
import ShipmentMainPage from "../components/Shipments/ShipmentMainPage";
import SearchSettingPage from "../components/Shipments/SearchSettingPage";
import ShipmentDetailPage from "../components/Shipments/ShipmentDetailPage";
import ClaimedShipmentsMainPage from "../components/Shipments/ClaimedShipmentsMainPage";
import ClaimedShipmentDetailPage from "../components/Shipments/ClaimedShipmentDetailPage";
import LoadShipmentsPage from "../components/Shipments/LoadShipmentsPage";
import LoadedShipmentsDetailPage from "../components/Shipments/LoadedShipmentsDetailPage";
import DeliverShipmentsPage from "../components/Shipments/DeliverShipmentsPage";
import DeliveredShipmentsDetailPage from "../components/Shipments/DeliveredShipmentsDetailPage";

import SaftRouter from "../components/safeRouter";
import ClearTokenRouter from "../components/tokenClearRouter";
import UserRouter from "../components/userRouter";
import SettlementsList from "../components/Bookkeeper/settlementsList";
import SettlementDetails from "../components/Bookkeeper/settlementDetails";
import BookkeeperHome from "../components/Bookkeeper/bookkeeperHome";
import ConfirmShipments from "../components/MyOrders/confirmShipments";
import ShipmentDetail from "../components/MyOrders/shipmentDetail";
import ConfirmInfo from "../components/MyOrders/confirmInfoPage";

import HscodePage from "../components/Hscode/hscodePage";
import BolListPage from "../components/Hscode/bolListPage";
import LooseItemRate from "../components/Shipping/looseItemRates";

import FFGuestCheckOut from "../components/FreightForward/ffGuestCheckOut";
import ShipmentTaskDetailPage from "../components/Drivers/ShipmentTaskDetailPage";
import ShippingOrderDetailsPage from "../components/ShippingOrderDetails/shippingOrderDetailsPage";
import ShippingOrderListPage from "../components/ShippingOrderList/ShippingOrderListPage";
import AllEndUserShippingOrderPaymentList
    from "../components/Shipping/SearchShippingOrders/All3PLShippingOrderList/OrderPaymentList";
import ShippingOrderPaymentDetails from "../components/Shipping/SearchShippingOrders/ShippingOrderPaymentDetails";
import {Rating} from "../components/Rating/Rating";
import {RatingEstimate} from "../components/Rating/RatingEstimate";
import {ShipSummary} from "../components/Ship/ShipSummary";
import {ShipOrderDetails} from "../components/Ship/ShipOrderDetails";
import {MyBoxes} from "../components/MyBoxes/MyBoxes";
import {OrderDashBoard} from "../components/OrderDashBoard/OrderDashBoard";
import CommercialInvoicePage from "../components/CommercialInvoice/commercialInvoicePage";
import {LandingEstimate} from "../components/LandingPage/LandingEstimate";
import {useGaTracker} from "../components/shared/Hooks/useGaTracker";
import {MyOrders} from "../components/MyOrders/MyOrders";
import {LandingShippingRateCalculator} from "../components/Landing/LandingShippingRateCalculator";
import {getAccessToken} from "../Utils/doToken";
import {LandingUserSignUp} from "../components/Landing/LandingUserSignUp";
import {LandingSignUp} from "../components/Landing/LandingSignUp";
import BillingCenter from "../components/BillingCenter/BilingCenter";
import {LandingReferralCodePage} from "../components/Landing/LandingReferralCodePage";
import {useSelector} from "react-redux";
import {MovingService} from "../components/Moving/MovingService";
import {MovingServiceSummary} from "../components/Moving/MovingServiceSummary";
import {MovingServiceReview} from "../components/Moving/MovingServiceReview";
import {MovingOrderDetails} from "../components/MovingOrderDetails/MovingOrderDetails";
import {MovingOrderDashboard} from "../components/MovingOrderDashboard/MovingOrderDashboard";
import {ShippingPackageQuickQuote} from "../components/ShippingPackage/ShippingPackageQuickQuote";
import {ShippingPackageCreateLabel} from "../components/ShippingPackage/ShippingPackageCreateLabel";
import {ShippingPackageCreateLabelRateList} from "../components/ShippingPackage/ShippingPackageCreateLabelRateList";
import {
    ShippingPackageCreateLabelOrderDetails
} from "../components/ShippingPackage/ShippingPackageCreateLabelOrderDetails";
import {Tracking} from "../components/Tracking/Tracking";
import {ShippingOrderDetails} from "../components/ShippingOrderDetails/NewDesigin/ShippingOrderDetails";
import {Billing} from "../components/Billing/Billing";
import {SchedulePickup} from "../components/SchedulePickup/SchedulePickup";
import {MySchedulePickup} from "../components/SchedulePickup/MySchedulePickup";
import {ShippingPackageCrossBorder} from "../components/ShippingPackage/ShippingPackageCrossBorder";
import {CrossBorderDashboard} from "../components/CrossBorder/CrossBorderDashboard";
import {PackageOrderDashboard} from "../components/OrderDashBoard/NewDesign/PackageOrderDashboard";
import {ShippingPackageInternationShipment} from "../components/ShippingPackage/ShippingPackageInternationalShipment";
import {
    ShippingPackageInternationShipmentQuote
} from "../components/ShippingPackage/ShippingPackageInternationShipmentQuote";
import {
    ShippingPackageInternationShipmentOrder
} from "../components/ShippingPackage/ShippingPackageInternationShipmentOrder";
import {
    ShippingPackageInternationShipmentRate
} from "../components/ShippingPackage/ShippingPackageInternationShipmentRate";
import {MovingOrderDashboardPayment} from "../components/MovingOrderDashboard/MovingOrderDashboardPayment";
import {AccountSetting} from "../components/Profiles/AccountSetting";
import {ReturnLabel} from "../components/ReturnLabel/ReturnLabel";
import {ReturnLabelOrderDetails} from "../components/ReturnLabel/ReturnLabelOrderDetails";
import BusinessLandingPage from "../components/LandingPage/Business/BusinessLandingPage";
import OceanShippingPage from "../components/OceanShipping/Business/OceanShippingPage";
import {Brokerage} from "../components/Brokerage/Business/Brokerage";
import {BrokeragePackingList} from "../components/Brokerage/Business/BrokeragePackingList";
import {ErrorPage} from "../components/ErrorPage/ErrorPage";
import {ComingSoonPage} from "../components/ErrorPage/CommingSoon";
import {ShippingLTLQuickQuote} from "../components/ShippingLTL/Business/ShippingLTLQuickQuote";
import {ShippingLTLCreateLabel} from "../components/ShippingLTL/Business/ShippingLTLCreateLabel";
import {ShippingLTLCreateLabelRateList} from "../components/ShippingLTL/Business/ShippingLTLCreateLabelRateList";
import {
    ShippingLTLCreateLabelOrderDetails
} from "../components/ShippingLTL/Business/ShippingLTLCreateLabelOrderDetails";
import {ShippingLTLCreateLabelCrossBorder} from "../components/ShippingLTL/Business/ShippingLTLCreateLabelCrossBorder";
import {MyLTLList} from "../components/ShippingLTLList/MyLTLList";
import ShippingLTLListPage from "../components/ShippingLTLList/ShippingLTLListPage";
import {QuoteLTL} from "../components/QuoteLTL/QuoteLTL";
import {QuoteLTLDetails} from "../components/QuoteLTL/QuoteLTLDetails";
import {TrackingLTL} from "../components/Tracking/Business/TrackingLTL";
import {BusinessBilling} from "../components/Billing/Business/BusinessBilling";
import {LTLOrderDashboard} from "../components/OrderDashBoard/Business/LTLOrderDashboard";
import {TopUp} from "../components/TopUp/TopUp";
import {BusinessMyBoxes} from "../components/MyBoxes/Business/BusinessMyBoxes";
import {AddressList} from "../components/AddressBook/Business/AddressList";
import {PackingList} from "../components/PackingList/Business/PackingList";
import {BusinessAccountSetting} from "../components/Profiles/Business/BusinessAccountSetting";
import {
    BusinessShippingPackageQuickQuote
} from "../components/ShippingPackage/Business/BusinessShippingPackageQuickQuote";
import {
    BusinessShippingPackageCreateLabel
} from "../components/ShippingPackage/Business/BusinessShippingPackageCreateLabel";
import {
    BusinessShippingPackageCreateLabelRate
} from "../components/ShippingPackage/Business/BusinessShippingPackageCreateLabelRate";
import {
    BusinessShippingPackageCreateLabelOrderDetails
} from "../components/ShippingPackage/Business/BusinessShippingPackageCreateLabelOrderDetails";
import {
    BusinessShippingPackageInternationShipment
} from "../components/ShippingPackage/Business/BusinessShippingPackageInternationShipment";
import {
    BusinessShippingPackageInternationShipmentQuote
} from "../components/ShippingPackage/Business/BusinessShippingPackageInternationShipmentQuote";
import {
    BusinessShippingPackageInternationShipmentOrder
} from "../components/ShippingPackage/Business/BusinessShippingPackageInternationShipmentOrder";
import {
    BusinessShippingPackageInternationShipmentRate
} from "../components/ShippingPackage/Business/BusinessShippingPackageInternationShipmentRate";
import {
    BusinessShippingPackageCrossBorder
} from "../components/ShippingPackage/Business/BusinessShippingPackageCrossBorder";
import {BusinessPackageOrderDashboard} from "../components/OrderDashBoard/Business/BusinessPackageOrderDashboard";
import {BusinessCrossBorderDashboard} from "../components/CrossBorder/Business/BusinessCrossBorderDashboard";
import {LTLCrossBorderDashboard} from "../components/CrossBorder/Business/LTLCrossBorderDashboard";
import {BusinessReturnLabel} from "../components/ReturnLabel/Business/BusinessReturnLabel";
import {BusinessReturnLabelOrderDetails} from "../components/ReturnLabel/Business/BusinessReturnLabelOrderDetails";
import {AdjustOrderDashboard} from "../components/AdjustOrderDashboard/AdjustOrderDashboard";
import {ShippingPackageImportShipment} from "../components/ShippingViaExcel/ShippingPackageImportShipment";
import SearchShippingTransactions from "../components/SearchShippingTransactions/SearchShippingTransactions";
import {PackageOrderList} from "../components/ShippingOrderList/Business/PackageOrderList";
import ShippingExportsTransaction from "../components/ExportTransaction/ShippingExportsTransaction";
import BusinessSchedulePickup from "../components/SchedulePickup/Business/BusinessSchedulePickup";
import BusinessMyScheduledPickupItem from "../components/SchedulePickup/Business/BusinessMyScheduledPickupItem";
import BusinessMyScheduledPickups from "../components/SchedulePickup/Business/BusinessMyScheduledPickups";
import TrackPackages from "../components/Tracking/Business/TrackPackages";
import {BusinessShippingOrderDetails} from "../components/ShippingOrderDetails/Business/BusinessShippingOrderDetails";
import {
    BusinessLTLShippingOrderDetails
} from "../components/ShippingOrderDetails/Business/BusinessLTLShippingOrderDetails";
import {EndUserSchedulePickup} from "../components/SchedulePickup/NewDesign/EndUserSchedulePickup";
import {MovingServiceList} from "../components/Moving/MovingServiceList";
import {MovingServiceOther} from "../components/Moving/MovingServiceOther";
import {PartnerSchedulePickup} from "../components/SchedulePickup/NewDesign/PartnerSchedulePickup";
// import BusinessMyScheduledPickups from "../components/BusinessSchedulePickup/BusinessMyScheduledPickups";

const Routes = () => {
    useGaTracker();

    const ownerRole = useSelector((state) => state.user.ownerRole);

    return (
        <Switch>
            {/*<UserRouter exact path="/" landing component={LandingPage}/>*/}
            {/*<ClearTokenRouter*/}
            {/*    exact*/}
            {/*    path={["/sign-in", "/sign-up"]}*/}
            {/*    component={UserSignInPage}*/}
            {/*/>*/}
            <Route exact path="/">
                {Boolean(getAccessToken("access_token")) ? (
                    <Redirect to={`${ownerRole === 3 ? "/business" : ""}/home`}/>
                ) : (
                    <Redirect to="/sign-in"/>
                )}
            </Route>
            <UserRouter
                exact
                path="/home"
                component={LandingPage}
            />
            <Route
                exact
                path="/sign-in"
                component={UserSignInPage}
            />
            <Route
                exact
                path="/sign-up"
                component={LandingSignUp}
            />
            <Route
                exact
                path="/business/sign-up"
                component={LandingSignUp}
            />
            {/*<ClearTokenRouter*/}
            {/*    exact*/}
            {/*    path={["/sign-in", "/sign-up"]}*/}
            {/*    component={UserSignInPage}*/}
            {/*/>*/}
            <Route exact path="/email/activate-result" component={ActiveResultPage}/>
            <Route exact path="/forgot-password" component={ForgotPasswordPage}/>
            <Route exact path="/receive-email" component={ReceiveEmailPage}/>
            <Route exact path="/reset-password" component={ResetPasswordPage}/>
            <Route exact path="/guest-checkout" component={GuestCheckout}/>
            <Route exact path="/confirm-info/:tab" component={ConfirmInfo}/>
            <Route
                exact
                path="/freight-forwarding/guest-get-rates"
                component={FFGuestCheckOut}
            />
            <UserRouter
                exact
                path="/freight-forwarding/hs-code"
                component={HscodePage}
            />
            <UserRouter
                exact
                path="/freight-forwarding/bol-list/:tab"
                component={BolListPage}
            />
            <UserRouter
                exact
                path="/freight-forwarding/bol-details/:number"
                render={() => <HscodePage edit={true}/>}
            />
            <Route
                exact
                path="/guest-payment"
                render={() => <AddCreditCardPage addCard={false}/>}
            />
            <Route
                exact
                path="/guest-order-details/"
                render={() => <OrderDetails guest={true}/>}
            />
            <Route
                exact
                path="/driver/sign-in"
                render={() => <UserSignInPage driver={true}/>}
            />
            {/*<UserRouter exact path="/profile" component={UserProfile}/>*/}
            <UserRouter exact path="/profile" component={AccountSetting}/>
            <UserRouter exact path="/settings" component={UserSettings}/>
            <UserRouter exact path="/address-book" component={AddressBook}/>
            <UserRouter exact path="/address-book/add" component={AddressInput}/>
            <Route
                exact
                path="/address-book/edit/:id"
                render={() => <AddressInput edit={true}/>}
            />
            <Route exact path="/change-password" component={ChangePasswordPage}/>
            <UserRouter
                exact
                path="/profile/add-card"
                component={AddCreditCardPage}
            />
            {/* <UserRouter exact path="/join-company" component={JoinCompany} /> */}
            {/* <SaftRouter exact path="/manage-company" component={CompanyProfile} /> */}
            <UserRouter exact path="/shipping/:tab" component={StartQuote}/>
            {/*<UserRouter exact path="/shippingOrderDetails/:orderId" component={ShippingOrderDetailsPage}/>*/}
            <UserRouter exact path="/payment" component={PaymentPage}/>
            <UserRouter
                exact
                path="/order-confirmed/:number"
                component={OrderConfirm}
            />
            <UserRouter exact path="/order-confirmed/" component={OrderConfirm}/>
            {/* <SaftRouter exact path="/start-a-quote2" component={AdditionalServices} /> */}
            {/* <Route exact path = "/order-confirmed" component = {OrderConfirm}/> */}
            <UserRouter exact path="/cancel-order" component={RequestCancel}/>
            <UserRouter exact path="/my-orders/:tab" component={OrderList}/>
            <UserRouter exact path="/my-orders">
                <Redirect to="/my-orders/active"/>
            </UserRouter>
            <UserRouter
                exact
                path="/confirm-shipments/:state"
                component={ConfirmShipments}
            />
            <UserRouter exact path="/order-details/:id" component={OrderDetails}/>
            <UserRouter
                exact
                path="/shipment-details/:id"
                component={ShipmentDetail}
            />
            <Route
                exact
                path="/cargo-control-tracking/query"
                component={ControlNumber}
            />
            <Route
                exact
                path="/cargo-control-tracking/info/:ids"
                component={ShipmentDetails}
            />
            <Route
                exact
                path="/cargo-control-tracking/transactions/:id"
                render={() => <ShipmentDetails transaction={true}/>}
            />
            <SaftRouter exact path="/driver" component={DriverLandingPage}/>
            <Route
                exact
                path="/driver/available_shipment_tasks"
                component={ShipmentMainPage}
            />
            <SaftRouter
                exact
                path="/driver/search-setting"
                component={SearchSettingPage}
            />
            <Route
                exact
                path="/driver/available_shipment_tasks/shipment_number=:id&stage=:stage"
                component={ShipmentTaskDetailPage}
            />
            <Route
                exact
                path="/driver/claimed_shipment_tasks"
                component={ClaimedShipmentsMainPage}
            />
            <Route
                exact
                path="/driver/claimed_shipment_tasks/shipment_number=:id&stage=:stage"
                component={ClaimedShipmentDetailPage}
            />
            <SaftRouter
                exact
                path="/driver/delivered_shipment_tasks/shipment_number=:id&stage=:stage"
                component={DeliveredShipmentsDetailPage}
            />
            <SaftRouter
                exact
                path="/driver/pickup_shipment_tasks"
                component={LoadShipmentsPage}
            />
            <Route
                exact
                path="/driver/pickedup_shipment_tasks/shipment_number=:id&stage=:stage"
                component={LoadedShipmentsDetailPage}
            />
            <Route
                exact
                path="/driver/deliver_shipment_tasks"
                component={DeliverShipmentsPage}
            />
            <SaftRouter exact path="/driver/bookkeeper" component={BookkeeperHome}/>
            <SaftRouter
                exact
                path="/driver/settlements-list"
                component={SettlementsList}
            />
            <SaftRouter
                exact
                path="/driver/settlement-details/:id"
                component={SettlementDetails}
            />
            <Route exact path="/loose_item_rates" component={LooseItemRate}/>
            <Route
                exact
                path="/shippingOrderList/"
                component={ShippingOrderListPage}
            />
            <Route exact path="/loose_item_rates" component={LooseItemRate}/>
            <Route
                exact
                path="/searchShippingOrder/"
                component={AllEndUserShippingOrderPaymentList}
            />
            <Route
                exact
                path="/ShippingOrderPaymentDetails/:id"
                component={ShippingOrderPaymentDetails}
            />
            <Route
                exact
                path="/shippingOrderList/:tab"
                component={ShippingOrderListPage}
            />
            <UserRouter
                exact
                path="/rating"
                component={Rating}
            />
            <UserRouter
                exact
                path="/rating/estimate"
                component={RatingEstimate}
            />
            <UserRouter
                exact
                path="/ship/summary"
                component={ShipSummary}
            />
            <UserRouter
                exact
                path="/my-boxes"
                component={MyBoxes}
            />
            <UserRouter
                exact
                path="/order-dash-board/:orderId"
                component={OrderDashBoard}
            />
            <Route
                exact
                path="/commercial-invoice/:order_id"
                component={CommercialInvoicePage}
            />
            <Route
                exact
                path="/landing-estimate/"
                component={LandingEstimate}
            />
            <UserRouter
                exact
                path="/my-shipping-orders"
                component={MyOrders}
            />
            <Route
                exact
                path="/shipping-rate-calculator"
                component={LandingShippingRateCalculator}
            >
            </Route>
            <Route
                exact
                path='/referral-agent/:referralCode'
                component={LandingReferralCodePage}
            />
            <UserRouter
                exact
                path="/transportation/:tab"
                component={MovingService}
            />
            <UserRouter
                exact
                path="/transportation/:tab/list"
                component={MovingServiceList}
            />
            <UserRouter
                exact
                path="/transportation/:tab/other"
                component={MovingServiceOther}
            />
            <UserRouter
                exact
                path="/transportation/:tab/rate"
                component={MovingServiceSummary}
            />
            <UserRouter
                exact
                path="/transportation/:tab/review"
                component={MovingServiceReview}
            />
            <UserRouter
                exact
                path="/transportation/orderDetails/:orderId"
                component={MovingOrderDetails}
            />
            <UserRouter
                exact
                path="/transportation/orderDashboard/:orderId"
                component={MovingOrderDashboard}
            />
            <UserRouter
                exact
                path="/shipping-package/quick-quote"
                component={ShippingPackageQuickQuote}
            />
            <UserRouter
                exact
                path="/shipping-package/create-label"
                component={ShippingPackageCreateLabel}
            />
            <UserRouter
                exact
                path="/shipping-package/create-label/rate-list"
                component={ShippingPackageCreateLabelRateList}
            />
            <UserRouter
                exact
                path="/shipping-package/create-label/order-details"
                component={ShippingPackageCreateLabelOrderDetails}
            />
            <UserRouter
                exact
                path="/tracking"
                component={Tracking}
            />
            <UserRouter
                exact
                path="/shippingOrderDetails/:orderId"
                component={ShippingOrderDetails}
            />
            <UserRouter
                exact
                path="/billing-center"
                component={Billing}
            />
            <UserRouter
                exact
                path="/billing-center/top-up"
                component={TopUp}
            />
            <UserRouter
                exact
                path="/schedulepickup"
                component={EndUserSchedulePickup}
                // component={SchedulePickup}
            />
            <UserRouter
                exact
                path="/MyScheduledPickups"
                component={MySchedulePickup}
            />
            <UserRouter
                exact
                path="/shipping-package/cross-border-details"
                component={ShippingPackageCrossBorder}
            />
            <UserRouter
                exact
                path="/cross-border-dashboard/:order_id"
                component={CrossBorderDashboard}
            />
            <UserRouter
                exact
                path="/package-order-dashboard/:orderId"
                component={PackageOrderDashboard}
            />
            <UserRouter
                exact
                path="/shipping-package/international-shipment"
                component={ShippingPackageInternationShipment}
            />
            <UserRouter
                exact
                path="/shipping-package/international-shipment/quote-list"
                component={ShippingPackageInternationShipmentQuote}
            />
            <UserRouter
                exact
                path="/shipping-package/international-shipment/place-order"
                component={ShippingPackageInternationShipmentOrder}
            />
            <UserRouter
                exact
                path="/shipping-package/international-shipment/rate-list"
                component={ShippingPackageInternationShipmentRate}
            />
            <UserRouter
                exact
                path="/transportation/orderPay/:orderId"
                component={MovingOrderDashboardPayment}
            />
            <UserRouter
                exact
                path="/packageOrder/returnLabel/:tranId"
                component={ReturnLabel}
            />
            <UserRouter
                exact
                path="/packageOrder/returnLabelDetails"
                component={ReturnLabelOrderDetails}
            />
            {/*    business path*/}
            <UserRouter
                exact
                path="/business/home"
                component={BusinessLandingPage}
            />
            <UserRouter
                exact
                path="/business/ocean-shipping"
                component={OceanShippingPage}
            />
            <UserRouter
                exact
                path="/business/brokerage/estimate"
                component={Brokerage}
            />
            <UserRouter
                exact
                path="/business/brokerage/packing-list"
                component={BrokeragePackingList}
            />
            <UserRouter
                exact
                path="/business/shipping-package/quick-quote"
                component={BusinessShippingPackageQuickQuote}
            />
            <UserRouter
                exact
                path="/business/shipping-package/create-label"
                component={BusinessShippingPackageCreateLabel}
            />
            <UserRouter
                exact
                path="/business/shipping-package/create-label/rate-list"
                component={BusinessShippingPackageCreateLabelRate}
            />
            <UserRouter
                exact
                path="/business/shipping-package/create-label/order-details"
                component={BusinessShippingPackageCreateLabelOrderDetails}
            />
            <UserRouter
                exact
                path="/business/shipping-package/international-shipment"
                component={BusinessShippingPackageInternationShipment}
            />
            <UserRouter
                exact
                path="/business/shipping-package/international-shipment/quote-list"
                component={BusinessShippingPackageInternationShipmentQuote}
            />
            <UserRouter
                exact
                path="/business/shipping-package/international-shipment/place-order"
                component={BusinessShippingPackageInternationShipmentOrder}
            />
            <UserRouter
                exact
                path="/business/shipping-package/international-shipment/rate-list"
                component={BusinessShippingPackageInternationShipmentRate}
            />
            <UserRouter
                exact
                path="/business/shipping-package/cross-border-details"
                component={BusinessShippingPackageCrossBorder}
            />
            <UserRouter
                exact
                path="/business/shipping-ltl/quick-quote"
                component={ShippingLTLQuickQuote}
            />
            <UserRouter
                exact
                path="/business/shipping-ltl/create-label"
                component={ShippingLTLCreateLabel}
            />
            <UserRouter
                exact
                path="/business/shipping-ltl/create-label/rate-list"
                component={ShippingLTLCreateLabelRateList}
            />
            <UserRouter
                exact
                path="/business/shipping-ltl/create-label/order-details"
                component={ShippingLTLCreateLabelOrderDetails}
            />
            <UserRouter
                exact
                path="/business/shipping-ltl/create-label/cross-border-order-details"
                component={ShippingLTLCreateLabelCrossBorder}
            />
            <UserRouter
                exact
                path="/business/myLTLs"
                component={MyLTLList}
            />
            <UserRouter
                exact
                path="/business/myLTLs/:tab"
                component={ShippingLTLListPage}
            />
            <UserRouter
                exact
                path="/business/myLTLQuote"
                component={QuoteLTL}
            />
            <UserRouter
                exact
                path="/business/LTLQuoteDetails/:quoteId"
                component={QuoteLTLDetails}
            />
            <UserRouter
                exact
                path="/business/tracking-ltl"
                component={TrackingLTL}
            />
            <UserRouter
                exact
                path="/business/billing-center"
                component={BusinessBilling}
            />
            <UserRouter
                exact
                path="/business/billing-center/top-up"
                component={TopUp}
            />
            <UserRouter
                exact
                path="/business/ltl-order-dashboard/:order_id"
                component={LTLOrderDashboard}
            />
            <UserRouter
                exact
                path="/business/my-boxes"
                component={BusinessMyBoxes}
            />
            <UserRouter
                exact
                path="/business/address-book"
                component={AddressList}
            />
            <UserRouter
                exact
                path="/business/packing-list"
                component={PackingList}
            />
            <UserRouter
                exact
                path="/business/profile"
                component={BusinessAccountSetting}
            />
            <UserRouter
                exact
                path="/business/package-order-dashboard/:order_id"
                component={BusinessPackageOrderDashboard}
            />
            <UserRouter
                exact
                path="/business/cross-border-dashboard/:order_id"
                component={BusinessCrossBorderDashboard}
            />
            <UserRouter
                exact
                path="/business/ltl-cross-border-dashboard/:order_id"
                component={LTLCrossBorderDashboard}
            />
            <UserRouter
                exact
                path="/business/packageOrder/returnLabel/:tranId"
                component={BusinessReturnLabel}
            />
            <UserRouter
                exact
                path="/business/packageOrder/returnLabelDetails"
                component={BusinessReturnLabelOrderDetails}
            />
            <UserRouter
                exact
                path="/business/packageOrder/cloneLabel/:tranId"
                component={ComingSoonPage}
            />
            <UserRouter
                exact
                path="/business/packageOrder/cloneLabelDetails"
                component={ComingSoonPage}
            />
            <UserRouter
                exact
                path="/business/adjustOrderDashboard/:orderId"
                component={AdjustOrderDashboard}
            />
            <UserRouter
                exact
                path="/business/shipping-package/import-shipment"
                component={ShippingPackageImportShipment}
            />
            <UserRouter
                exact
                path="/business/loose-item/3rd-party/shipping/searchTransactions"
                component={SearchShippingTransactions}
            />
            <UserRouter
                exact
                path="/business/package/order-list"
                component={PackageOrderList}
            />
            <UserRouter
                exact
                path="/business/loose-item/3rd-party/shipping/exportsTransactions"
                component={ShippingExportsTransaction}
            />
            <UserRouter
                exact
                path="/business/MyScheduledPickups"
                component={BusinessMyScheduledPickups}
            />
            <UserRouter
                exact
                path="/business/schedulepickup"
                // component={BusinessSchedulePickup}
                component={PartnerSchedulePickup}
            />
            <UserRouter
                exact
                path="/business/tracking"
                component={TrackPackages}
            />
            <UserRouter
                exact
                path="/business/shippingOrderDetails/:orderId"
                component={BusinessShippingOrderDetails}
            />
            <UserRouter
                exact
                path="/business/LTLShippingOrderDetails/:orderId"
                component={BusinessLTLShippingOrderDetails}
            />
            <UserRouter
                exact
                path="/business/transportation/:tab"
                component={MovingService}
            />
            <UserRouter
                exact
                path="/business/transportation/:tab/rate"
                component={MovingServiceSummary}
            />
            <UserRouter
                exact
                path="/business/transportation/:tab/review"
                component={MovingServiceReview}
            />
            <UserRouter
                exact
                path="/business/transportation/orderDetails/:orderId"
                component={MovingOrderDetails}
            />
            <UserRouter
                exact
                path="/business/transportation/orderDashboard/:orderId"
                component={MovingOrderDashboard}
            />
            <Route component={ErrorPage}/>
        </Switch>
    );
};

export default Routes;
