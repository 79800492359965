import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {Divider} from "@mui/material";

const styles = {
    BillingAddressRoot: {
        backgroundColor: '#FFFFFF',
        // padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '35vh',
        height: '100%'
    },
    BillingAddressContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        padding: '20px',
    },
    BillingAddressTitle: {
        fontWeight: '600',
        textAlign: 'left',
    },
    BillingAddressSubTitle: {
        fontWeight: '600',
        textAlign: 'left',
        fontSize: '14px'
    }
}

export const BillingAddress = ({userInfo}) => {
    return (
        <Box sx={styles.BillingAddressRoot}>
            <Box sx={{padding: '20px 20px 0 20px'}}>
                <Typography style={styles.BillingAddressTitle}>
                    Billing Information
                </Typography>
            </Box>
            <Divider/>
            <Box sx={styles.BillingAddressContent}>
                <Grid container spacing={4}>
                    <Grid item xs={4}>
                        <Typography style={{textAlign: 'left'}}>
                            Name
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography style={{textAlign: 'left'}}>
                            {userInfo?.firstname} {userInfo?.lastname}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography style={{textAlign: 'left'}}>
                            Address
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography style={{textAlign: 'left'}}>
                            {userInfo?.address}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Box>

    )
}