import {Box, FormGroup, IconButton, MenuItem, Select, Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    extractAddress,
    extractFullAddress,
    isValidPostalCode, isValidZipCode,
    loadAsyncScript,
    property_type,
    provinces_states
} from "../../Utils/Helper";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@mui/material/FormControl";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {updateMovingServiceMoveFromAddress, updateMovingServiceMoveToAddress} from "../../actions/movingServiceAction";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import Paper from "@mui/material/Paper";
import {makeStyles} from "@material-ui/core/styles";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import intl from "react-intl-universal";
import {Checkbox, Divider, FormControlLabel} from "@material-ui/core";
import FmdBadOutlinedIcon from '@mui/icons-material/FmdBadOutlined';
// import { ReactComponent as MovingItemTitle } from "../../images/icons/movingItemTitle.svg";
import MovingItemTitle from "../../images/icons/movingItemTitle.png"
import MovingServiceProgressBar from "./MovingServiceProgressBar";
import {ReactComponent as LocationIcon} from "../../images/icons/Location.svg"
import {ReactComponent as QuestionIcon} from "../../images/icons/question-circle-fill.svg"
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const styles = {
    MovingServiceAddressRoot: {
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
    },

    MovingServiceShipFromAndShipToRoot: {
        // display: 'flex',
        // flexDirection: 'row',
        // gap: '30px'
        display: 'grid',
        gap: '30px',
        gridTemplateColumns: '1fr',
        '@media (min-width: 1024px)': {
            gridTemplateColumns: '1fr 1fr',
        },
    },

    MovingServiceAddressSection: {
        backgroundColor: '#FFFFFF',
        paddingBottom: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0'
    },

    MovingServiceAddressContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        height: '100%'
    },

    MovingServiceAddressHeading: {
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '20px'
    },
    MovingServiceAddressErrorText: {
        fontSize: '12px',
        color: "#FF0303"
    },
    MovingServiceExpressHeading: {
        fontSize: '24px',
        fontWeight: '600'
    },
}

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "4px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

const Lift = [0, 1]

const Storey = ['1', '2', '3', '4', '5', '6']
const STAIRCASE_OPTIONS = ["A staircase consists of 3 or more steps", "A staircase connects 2 stories together", "It is a single staircase even if it is interrupted by a landing", "Do not include the basement of your residence"];
const DISTANCE_FROM_TRUCK_TO_DOOR_OPTIONS = ["0 - 50m", "50 - 100m", ">100m"];
const FLOOR_LEVEL_OPTIONS = [
    '1',
    '2',
    '3',
];

export const house_type = [
    {propertyType: 'Residential', houseType: 'House'},
    {propertyType: 'Residential', houseType: 'Townhouse'},
    {propertyType: 'Residential', houseType: 'Apartment'},
    {propertyType: 'Residential', houseType: 'Curb Side'},
]

export const MovingServiceAddress = ({tab}) => {

    const dispatch = useDispatch();
    const classes = useStyles();

    let movingFromAddress = useSelector(state => state?.movingService?.movingFromAddress);
    let movingToAddress = useSelector(state => state?.movingService?.movingToAddress);
    let movingFromAddressMissing = useSelector(state => state?.movingService?.movingFromAddressMissing);
    let movingToAddressMissing = useSelector(state => state?.movingService?.movingToAddressMissing);

    const [movingFromAddressPredictions, setMovingFromAddressPredictions] = useState([]);
    const [movingToAddressPredictions, setMovingToAddressPredictions] = useState([]);

    const [movingFrom, setMovingFrom] = useState({
        streetAddress: null,
        apt: null,
        city: null,
        province: null,
        postalCode: null,
        country: null,
        propertyType: null,
        floorLevel: '1',
        lift: 0,
        storey: '1',
        displayAddress: {
            description: null,
            placeId: null,
            text: null
        },
        option: 'schedule',
        warehouse: null,
        distanceFromTruckToDoor: "0 - 50m",
        // staircase: "Do not include the basement of your residence",
        staircaseNumber: 0,
        isNarrowStreet: false,
        isSteepDriveway: false,
        drivewaySteepLevel: 0
    });

    const [movingTo, setMovingTo] = useState({
        streetAddress: null,
        apt: null,
        city: null,
        province: null,
        postalCode: null,
        country: null,
        propertyType: null,
        floorLevel: '1',
        lift: 0,
        storey: '1',
        displayAddress: {
            description: null,
            placeId: null,
            text: null
        },
        option: 'schedule',
        warehouse: null,
        distanceFromTruckToDoor: "0 - 50m",
        // staircase: "Do not include the basement of your residence",
        staircaseNumber: 0,
        isNarrowStreet: false,
        isSteepDriveway: false,
        drivewaySteepLevel: 0
    });

    const googleMapsPlaceAutocomplete = async (inputValue, type) => {
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log('prediction', predictions);
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            console.log('updated prediction', updatedPredictions);

            return updatedPredictions;

        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const handleMovingFromApt = (event) => {
        setMovingFrom(prevState => ({
            ...prevState, apt: event.target.value
        }))
    }

    const handleMovingFromCity = (event) => {
        setMovingFrom(prevState => ({
            ...prevState, city: event.target.value
        }))
    }

    const handleMovingFromProvince = (event, value) => {
        console.log(value);
        if (value) {
            setMovingFrom(prevState => ({
                ...prevState,
                province: {
                    ...prevState.province,
                    code: value?.code,
                    name: value?.name
                },
                country: value?.countryCode
            }))
        } else {
            setMovingFrom(prevState => ({
                ...prevState,
                province: null
            }))
        }
    }

    const handleMovingFromCountry = event => {
        setMovingFrom(prevState => ({
            ...prevState,
            country: event?.target?.value
        }))
    }

    const handleMovingFromPostalCode = (event) => {
        setMovingFrom(prevState => ({
            ...prevState, postalCode: event.target.value
        }))
    }

    const handleMovingFromPropertyType = (event, value) => {
        if (value?.houseType === 'House' || value?.houseType === 'Townhouse') {
            setMovingFrom(prevState => ({
                ...prevState,
                propertyType: value,
                lift: 0
            }))
        } else if (value?.houseType === 'Curb Side') {
            setMovingFrom(prevState => ({
                ...prevState,
                propertyType: value,
                lift: 0,
                floorLevel: "1"
            }))
        } else {
            setMovingFrom(prevState => ({
                ...prevState,
                propertyType: value,
            }))
        }
    }

    const handleMovingFromFloorLevel = (event, value) => {
        setMovingFrom(prevState => ({
            ...prevState, floorLevel: value
        }))
    }

    const handleMovingFromLift = (event) => {
        setMovingFrom(prevState => ({
            ...prevState, lift: event?.target.value
        }))
    }

    const increaseMovingFromStaircase = () => {
        const currentStairCase = movingFrom.staircaseNumber;
        setMovingFrom(prevState => ({
            ...prevState,
            staircaseNumber: currentStairCase + 1
        }))
    }

    const decreaseMovingFromStaircase = () => {
        const currentStairCase = movingFrom.staircaseNumber;
        if (currentStairCase <= 0) {
            return;
        }
        setMovingFrom(prevState => ({
            ...prevState,
            staircaseNumber: currentStairCase - 1
        }))
    }

    const increaseMovingToStaircase = () => {
        const currentStairCase = movingTo.staircaseNumber;
        setMovingTo(prevState => ({
            ...prevState,
            staircaseNumber: currentStairCase + 1
        }))
    }

    const decreaseMovingToStaircase = () => {
        const currentStairCase = movingTo.staircaseNumber;
        if (currentStairCase <= 0) {
            return;
        }
        setMovingTo(prevState => ({
            ...prevState,
            staircaseNumber: currentStairCase - 1
        }))
    }

    const handleMovingDistanceFromTruckToDoor = (event, value) => {
        setMovingFrom(prevState => ({
            ...prevState, distanceFromTruckToDoor: value
        }))
    }

    const handleMovingFromIsNarrowStreet = (event, value) => {
        setMovingFrom(prevState => ({
            ...prevState, isNarrowStreet: value
        }))
    }

    const handleMovingFromIsSteepDrivewayStreet = (event, value) => {
        setMovingFrom(prevState => ({
            ...prevState,
            isSteepDriveway: value,
            drivewaySteepLevel: value ? 1 : 0
        }))
    }

    const handleMovingToApt = (event) => {
        setMovingTo(prevState => ({
            ...prevState, apt: event.target.value
        }))
    }

    const handleMovingToCity = (event) => {
        setMovingTo(prevState => ({
            ...prevState, city: event.target.value
        }))
    }

    const handleMovingToProvince = (event, value) => {
        if (value) {
            setMovingTo(prevState => ({
                ...prevState,
                province: {
                    ...prevState.province,
                    code: value?.code,
                    name: value?.name
                },
                country: value?.countryCode
            }))
        } else {
            setMovingTo(prevState => ({
                ...prevState,
                province: null
            }))
        }
    }

    const handleMovingToCountry = event => {
        setMovingTo(prevState => ({
            ...prevState,
            country: event?.target?.value
        }))
    }

    const handleMovingToPostalCode = (event) => {
        setMovingTo(prevState => ({
            ...prevState, postalCode: event.target.value
        }))
    }

    const handleMovingToPropertyType = (event, value) => {
        if (value?.houseType === 'House' || value?.houseType === 'Townhouse') {
            setMovingTo(prevState => ({
                ...prevState,
                propertyType: value,
                lift: 0
            }))
        } else if (value?.houseType === 'Curb Side') {
            setMovingTo(prevState => ({
                ...prevState,
                propertyType: value,
                lift: 0,
                floorLevel: "1"
            }))
        } else {
            setMovingTo(prevState => ({
                ...prevState,
                propertyType: value,
            }))
        }
    }

    const handleMovingToFloorLevel = (event, value) => {
        setMovingTo(prevState => ({
            ...prevState, floorLevel: value
        }))
    }

    const handleMovingToLift = (event) => {
        setMovingTo(prevState => ({
            ...prevState, lift: event.target.value
        }))
    }

    const handleMovingToDistanceFromTruckToDoor = (event, value) => {
        setMovingTo(prevState => ({
            ...prevState, distanceFromTruckToDoor: value
        }))
    }

    const handleMovingToIsNarrowStreet = (event, value) => {
        setMovingTo(prevState => ({
            ...prevState, isNarrowStreet: value
        }))
    }

    const handleMovingToIsSteepDrivewayStreet = (event, value) => {
        setMovingTo(prevState => ({
            ...prevState,
            isSteepDriveway: value,
            drivewaySteepLevel: value ? 1 : 0
        }))
    }

    // const handleChangeMovingToIsSteepDriveway = (event) => {
    //     setMovingTo(prevState => ({
    //         ...prevState, drivewaySteepLevel: event.target.value
    //     }))
    // }

    const validateMovingFromPostalCode = () => {
        if (movingFrom?.postalCode) {
            if (movingFrom?.country === 'CA') {
                const result = isValidPostalCode(movingFrom?.postalCode);
                return result;
            } else if (movingFrom?.province?.countryCode === 'US') {
                const result = isValidZipCode(movingFrom?.postalCode);
                return result;
            } else {
                return true
            }
        } else {
            return true
        }
    }

    const validateMovingToPostalCode = () => {
        if (movingTo?.postalCode) {
            if (movingTo?.country === 'CA') {
                const result = isValidPostalCode(movingTo?.postalCode);
                return result;
            } else if (movingTo?.province?.countryCode === 'US') {
                const result = isValidZipCode(movingTo?.postalCode);
                return result;
            } else {
                return true
            }
        } else {
            return true
        }
    }

    const handleInputChangeMovingFromAddress = async (event, value) => {
        setMovingFrom(prevState => ({
            ...prevState,
            streetAddress: value,
            displayAddress: {
                description: '',
                placeId: '',
                text: value
            }
        }));
        const predictionsAddress = await googleMapsPlaceAutocomplete(value, 'address');
        setMovingFromAddressPredictions(predictionsAddress);
    }

    const handleChangeMovingFromAddress = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            setMovingFrom(prevState => ({
                ...prevState,
                postalCode: address?.zip,
                city: address?.city,
                province: address?.state,
                country: address?.country?.code,
                displayAddress: value
            }))
        } else {
            setMovingFrom(prevState => ({
                ...prevState,
                streetAddress: '',
                displayAddress: {
                    description: '',
                    placeId: '',
                    text: ''
                },
            }))
        }
    }

    const handleInputChangeMovingToAddress = async (event, value) => {
        setMovingTo(prevState => ({
            ...prevState,
            streetAddress: value,
            displayAddress: {
                description: '',
                placeId: '',
                text: value
            }
        }));
        const predictionsAddress = await googleMapsPlaceAutocomplete(value, 'address');
        setMovingToAddressPredictions(predictionsAddress);
    }

    const handleChangeMovingToAddress = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            setMovingTo(prevState => ({
                ...prevState,
                postalCode: address?.zip,
                city: address?.city,
                province: address?.state,
                country: address?.country?.code,
                displayAddress: value
            }))
        } else {
            setMovingTo(prevState => ({
                ...prevState,
                streetAddress: '',
                displayAddress: {
                    description: '',
                    placeId: '',
                    text: ''
                },
            }))
        }
    }

    useEffect(() => {

        // console.log('[MovingServiceAddress] moving from address -useEffect', movingFromAddress);
        if (movingFromAddress?.streetAddress || movingFromAddress?.city) {
            setMovingFrom(prevState => ({
                ...prevState,
                streetAddress: movingFromAddress?.streetAddress,
                city: movingFromAddress?.city,
                province: movingFromAddress?.province,
                postalCode: movingFromAddress?.postalCode,
                country: movingFromAddress?.country,
                displayAddress: {
                    ...prevState?.displayAddress,
                    text: movingFromAddress?.streetAddress
                },
                propertyType: movingFromAddress?.propertyType,
                lift: movingFromAddress?.lift,
                floorLevel: movingFromAddress?.floorLevel
            }))
        }
        if (movingToAddress?.streetAddress || movingToAddress?.city) {
            setMovingTo(prevState => ({
                ...prevState,
                streetAddress: movingToAddress?.streetAddress,
                city: movingToAddress?.city,
                province: movingToAddress?.province,
                postalCode: movingToAddress?.postalCode,
                country: movingToAddress?.country,
                displayAddress: {
                    ...prevState?.displayAddress,
                    text: movingToAddress?.streetAddress
                },
                propertyType: movingToAddress?.propertyType,
                lift: movingToAddress?.lift,
                floorLevel: movingToAddress?.floorLevel
            }))
        }
    }, [])

    useEffect(() => {
        dispatch(updateMovingServiceMoveFromAddress(movingFrom));
    }, [movingFrom]);

    useEffect(() => {
        dispatch(updateMovingServiceMoveToAddress(movingTo));
    }, [movingTo]);

    console.log('[MovingServiceAddress] moving from', movingFrom);
    console.log('[MovingServiceAddress] moving from address', movingFromAddress);

    // console.log('[MovingServiceAddress] moving from missing', movingFromAddressMissing);
    // console.log('[MovingServiceAddress] moving to missing', movingToAddressMissing);

    return (
        <Box sx={styles.MovingServiceAddressRoot}>
            <Box display="flex" alignItems="center" p={2}>
                <img src={MovingItemTitle} alt="Moving items"
                     style={{width: '150px', height: 'auto', marginRight: '16px'}}/>
                <Box>
                    <Typography variant="h5" component="div" fontWeight="bold">
                        {tab === 'item' ? 'Local & Long-distance Moving' : 'Box Moving Service'}
                    </Typography>
                    <Typography variant="body1">
                        {tab === 'item' ? "Move your residence locally or long-distance, whether it's a small apartment or a large house." : "Limited to Greater Vancouver, Toronto, Calgary, and Montreal Areas"}
                    < /Typography>
                </Box>
            </Box>
            <MovingServiceProgressBar progress={25}/>
            <Box sx={styles.MovingServiceShipFromAndShipToRoot}>
                <Box sx={styles.MovingServiceAddressSection}>
                    <Box sx={{
                        display: 'flex',
                        gap: '10px',
                        padding: '20px 20px 0 20px'
                    }}>
                        <LocationIcon width={25} height={25}/>
                        <Typography style={styles.MovingServiceAddressHeading}>
                            {intl.get('MOVING.MOVING_FROM')}
                        </Typography>
                    </Box>
                    <Divider/>
                    <Box sx={{
                        backgroundColor: '#FFFFFF',
                        padding: '0 40px',
                        gap: '40px',
                        display: 'flex',
                    }}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={12}>
                                <Grid container spacing={1.5}>
                                    <Grid item xs={12} md={8}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                {intl.get('MOVING.STREET_ADDRESS')}
                                            </FormLabel>
                                            <Autocomplete
                                                freeSolo
                                                value={movingFrom?.displayAddress}
                                                fullWidth
                                                options={movingFromAddressPredictions}
                                                filterOptions={(options, state) => options}
                                                getOptionLabel={option => option?.text}
                                                onInputChange={handleInputChangeMovingFromAddress}
                                                onChange={handleChangeMovingFromAddress}
                                                PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                                renderInput={params => <TextField
                                                    {...params}
                                                    className={classes.smallInput}
                                                    variant="outlined"
                                                    error={movingFromAddressMissing.includes('streetAddress')}
                                                    helperText={movingFromAddressMissing.includes('streetAddress') && 'Required'}
                                                />}
                                                renderOption={option => (
                                                    <Typography style={{fontSize: '12px'}}>
                                                        {option.description}
                                                    </Typography>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl fullWidth>
                                            <FormLabel>
                                                {intl.get('MOVING.APT')}
                                            </FormLabel>
                                            <TextField
                                                value={movingFrom.apt}
                                                onInput={handleMovingFromApt}
                                                fullWidth
                                                variant="outlined"
                                                size='small'
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                {intl.get('MOVING.CITY')}
                                            </FormLabel>
                                            <TextField
                                                value={movingFrom.city}
                                                onInput={handleMovingFromCity}
                                                fullWidth
                                                variant="outlined"
                                                InputProps={{
                                                    style: {padding: '2px 0'},
                                                }}
                                                size='small'
                                                error={movingFromAddressMissing.includes('city')}
                                                helperText={movingFromAddressMissing.includes('city') && 'Required'}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                {intl.get('MOVING.PROVINCE')}
                                            </FormLabel>
                                            <Autocomplete
                                                value={movingFrom.province}
                                                groupBy={(option) => option.country}
                                                fullWidth
                                                options={provinces_states}
                                                getOptionLabel={(option) => option.code}
                                                onChange={handleMovingFromProvince}
                                                renderInput={(params) => <TextField
                                                    variant="outlined"
                                                    className={classes.smallInput}
                                                    {...params}
                                                    error={movingFromAddressMissing.includes('province')}
                                                    helperText={movingFromAddressMissing.includes('province') && 'Required'}
                                                />}
                                                renderOption={(option) => (
                                                    <div>
                                                        {option.name}
                                                    </div>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                {intl.get('MOVING.COUNTRY')}
                                            </FormLabel>
                                            <FormControl fullWidth>
                                                <Select
                                                    value={movingFrom?.country}
                                                    size='small'
                                                    onChange={handleMovingFromCountry}
                                                    error={movingFromAddressMissing.includes('country')}
                                                >
                                                    <MenuItem value='CA'>Canada</MenuItem>
                                                    <MenuItem value='US'>Unites States</MenuItem>
                                                </Select>
                                                <FormHelperText
                                                    sx={{
                                                        color: "error.main",
                                                    }}
                                                >
                                                    {movingFromAddressMissing.includes('country') && 'Required'}
                                                </FormHelperText>
                                            </FormControl>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                {intl.get('MOVING.POSTAL_CODE')}
                                            </FormLabel>
                                            <TextField
                                                value={movingFrom.postalCode}
                                                onInput={handleMovingFromPostalCode}
                                                fullWidth
                                                variant="outlined"
                                                InputProps={{
                                                    style: {padding: '2px 0'},
                                                }}
                                                size='small'
                                                error={movingFromAddressMissing.includes('postalCode') || !validateMovingFromPostalCode()}
                                                helperText={movingFromAddressMissing.includes('postalCode') ? 'Required' : !validateMovingFromPostalCode() && "Invalid"}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                {intl.get('MOVING.PROPERTY_TYPE')}
                                            </FormLabel>
                                            <Autocomplete
                                                value={movingFrom.propertyType}
                                                fullWidth
                                                groupBy={(option) => option.propertyType}
                                                options={house_type}
                                                getOptionLabel={(option) => option.houseType}
                                                onChange={handleMovingFromPropertyType}
                                                renderInput={(params) => <TextField
                                                    variant="outlined"
                                                    className={classes.smallInput}
                                                    {...params}
                                                    error={movingFromAddressMissing.includes('propertyType')}
                                                    helperText={movingFromAddressMissing.includes('propertyType') && 'Required'}
                                                />}
                                                renderOption={(option) => (
                                                    <div>
                                                        {option.houseType}
                                                    </div>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                Elevator
                                            </FormLabel>
                                            <Select
                                                value={movingFrom.lift}
                                                size='small'
                                                // placeholder='Select Partner'
                                                onChange={handleMovingFromLift}
                                                style={{textAlign: 'left'}}
                                                // error={validation && validation?.carrierMissing}
                                                disabled={movingFrom?.propertyType?.houseType !== 'Apartment'}
                                            >
                                                <MenuItem value={0}>
                                                    No
                                                </MenuItem>
                                                <MenuItem value={1}>
                                                    Yes
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                {intl.get('MOVING.FLOOR_LEVEL')}
                                            </FormLabel>
                                            <Autocomplete
                                                value={movingFrom.floorLevel}
                                                fullWidth
                                                options={FLOOR_LEVEL_OPTIONS}
                                                onChange={handleMovingFromFloorLevel}
                                                disabled={movingFrom?.lift || movingFrom?.propertyType?.houseType === 'Curb Side'}
                                                renderInput={(params) => <TextField
                                                    variant="outlined"
                                                    className={classes.smallInput}
                                                    {...params}
                                                    error={movingFromAddressMissing.includes('floorLevel')}
                                                    helperText={movingFromAddressMissing.includes('floorLevel') && 'Required'}
                                                />}
                                            />
                                        </FormControl>
                                    </Grid>
                                    {/*<Grid item xs={12} md={12}>*/}
                                    {/*    <FormControl fullWidth>*/}
                                    {/*        <FormLabel required>*/}
                                    {/*            {intl.get('MOVING.DISTANCE_FROM_TRUCK_TO_DOOR')}*/}
                                    {/*        </FormLabel>*/}
                                    {/*        <Autocomplete*/}
                                    {/*            value={movingFrom.distanceFromTruckToDoor}*/}
                                    {/*            fullWidth*/}
                                    {/*            options={DISTANCE_FROM_TRUCK_TO_DOOR_OPTIONS}*/}
                                    {/*            onChange={handleMovingDistanceFromTruckToDoor}*/}
                                    {/*            onInputChange={handleMovingDistanceFromTruckToDoor}*/}
                                    {/*            renderInput={(params) => <TextField*/}
                                    {/*                variant="outlined"*/}
                                    {/*                className={classes.smallInput}*/}
                                    {/*                {...params}*/}
                                    {/*                error={movingFromAddressMissing.includes('distanceFromTruckToDoor')}*/}
                                    {/*                helperText={movingFromAddressMissing.includes('distanceFromTruckToDoor') && 'Required'}*/}
                                    {/*            />}*/}
                                    {/*        />*/}
                                    {/*    </FormControl>*/}
                                    {/*</Grid>*/}
                                    {/*<Grid item xs={12} md={12}>*/}
                                    {/*    <FormControl fullWidth style={{*/}
                                    {/*        display: "flex",*/}
                                    {/*        flexDirection: "row",*/}
                                    {/*        justifyContent: "space-between",*/}
                                    {/*        gap: "3px"*/}
                                    {/*    }}>*/}
                                    {/*        <FormLabel>*/}
                                    {/*            {intl.get('MOVING.STAIRCASE')}*/}
                                    {/*            <Tooltip*/}
                                    {/*                placement="top"*/}
                                    {/*                arrow*/}
                                    {/*                componentsProps={{*/}
                                    {/*                    tooltip: {*/}
                                    {/*                        sx: {*/}
                                    {/*                            bgcolor: 'white',*/}
                                    {/*                            color: 'black',*/}
                                    {/*                            boxShadow: 1,*/}
                                    {/*                            border: '1px solid #ddd',*/}
                                    {/*                            borderRadius: '8px', // Added border radius*/}
                                    {/*                        },*/}
                                    {/*                    },*/}
                                    {/*                    arrow: {*/}
                                    {/*                        sx: {*/}
                                    {/*                            color: 'white',*/}
                                    {/*                        },*/}
                                    {/*                    },*/}
                                    {/*                }}*/}
                                    {/*                title={*/}
                                    {/*                    <Box sx={{backgroundColor: "#ffffff", p: 1}}>*/}
                                    {/*                        <ul style={{*/}
                                    {/*                            paddingBlock: "1.5px",*/}
                                    {/*                            margin: 0,*/}
                                    {/*                            paddingInline: "3px"*/}
                                    {/*                        }}>*/}
                                    {/*                            <li>*/}
                                    {/*                                A staircase consists of 3 or more steps.*/}
                                    {/*                            </li>*/}
                                    {/*                            <li>*/}
                                    {/*                                A staircase connects 2 stories together.*/}
                                    {/*                            </li>*/}
                                    {/*                            <li>*/}
                                    {/*                                It is a single staircase even if it is*/}
                                    {/*                                interrupted by a*/}
                                    {/*                                landing.*/}
                                    {/*                            </li>*/}
                                    {/*                            <li>*/}
                                    {/*                                Do not include the basement of your*/}
                                    {/*                                residence.*/}
                                    {/*                            </li>*/}
                                    {/*                        </ul>*/}
                                    {/*                    </Box>*/}
                                    {/*                }>*/}
                                    {/*                <IconButton>*/}
                                    {/*                    <FmdBadOutlinedIcon sx={{color: '#1D8B45'}}/>*/}
                                    {/*                </IconButton>*/}
                                    {/*            </Tooltip>*/}
                                    {/*        </FormLabel>*/}
                                    {/*        <Box sx={{*/}
                                    {/*            display: 'flex',*/}
                                    {/*            alignItems: 'center',*/}
                                    {/*            border: '1px solid #ddd',*/}
                                    {/*            borderRadius: '10px',*/}
                                    {/*            padding: "7px"*/}
                                    {/*        }}>*/}
                                    {/*            <IconButton size="small"*/}
                                    {/*                        onClick={decreaseMovingFromStaircase}><RemoveCircleOutlineIcon/></IconButton>*/}
                                    {/*            <Typography variant="body1"*/}
                                    {/*                        mx={1}>{movingFrom.staircaseNumber}</Typography>*/}
                                    {/*            <IconButton size="small"*/}
                                    {/*                        onClick={increaseMovingFromStaircase}><AddCircleOutlineIcon/></IconButton>*/}
                                    {/*        </Box>*/}
                                    {/*    </FormControl>*/}
                                    {/*</Grid>*/}
                                    {/*<Grid item xs={12} md={12}>*/}
                                    {/*    <FormControl fullWidth>*/}
                                    {/*        <FormLabel required>*/}
                                    {/*            {intl.get('MOVING.RESTRICTIONA_AT_COLLECTION')}*/}
                                    {/*        </FormLabel>*/}
                                    {/*        <FormGroup>*/}
                                    {/*            <FormControlLabel*/}
                                    {/*                control={*/}
                                    {/*                    <Checkbox*/}
                                    {/*                        checked={movingFrom.isNarrowStreet}*/}
                                    {/*                        onChange={handleMovingFromIsNarrowStreet}*/}
                                    {/*                        name="narrow street"*/}
                                    {/*                        style={{*/}
                                    {/*                            color: 'green',*/}
                                    {/*                            '&.Mui-checked': {*/}
                                    {/*                                color: 'green',*/}
                                    {/*                            }*/}
                                    {/*                        }}*/}
                                    {/*                    />*/}
                                    {/*                }*/}
                                    {/*                label={*/}
                                    {/*                    <>*/}
                                    {/*                        Narrow Street:&nbsp;*/}
                                    {/*                        <span style={{fontSize: "12px"}}>Tight, Space, Difficult for Trucks or Movers.</span>*/}
                                    {/*                    </>*/}
                                    {/*                }*/}
                                    {/*            />*/}
                                    {/*            <FormControlLabel*/}
                                    {/*                control={*/}
                                    {/*                    <Checkbox*/}
                                    {/*                        checked={movingFrom.isSteepDriveway}*/}
                                    {/*                        onChange={handleMovingFromIsSteepDrivewayStreet}*/}
                                    {/*                        name="steep driveway"*/}
                                    {/*                        style={{*/}
                                    {/*                            color: 'green',*/}
                                    {/*                            '&.Mui-checked': {*/}
                                    {/*                                color: 'green',*/}
                                    {/*                            }*/}
                                    {/*                        }}*/}
                                    {/*                    />*/}
                                    {/*                }*/}
                                    {/*                label={*/}
                                    {/*                    <>*/}
                                    {/*                        Steep Driveway:&nbsp;*/}
                                    {/*                        <span style={{fontSize: "12px"}}>Trucks & Movers may struggle with slope.*/}
                                    {/*                </span>*/}
                                    {/*                    </>*/}
                                    {/*                }*/}
                                    {/*            />*/}
                                    {/*        </FormGroup>*/}
                                    {/*    </FormControl>*/}
                                    {/*</Grid>*/}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Box sx={styles.MovingServiceAddressSection}>
                    <Box sx={{
                        display: 'flex',
                        gap: '10px',
                        padding: '20px 20px 0 20px'
                    }}>
                        <LocationIcon width={25} height={25}/>
                        <Typography style={styles.MovingServiceAddressHeading}>
                            {intl.get('MOVING.MOVING_TO')}
                        </Typography>
                    </Box>
                    <Divider/>
                    <Box sx={{
                        backgroundColor: '#FFFFFF',
                        padding: '0 40px',
                        gap: '40px',
                        display: 'flex',
                    }}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Grid container spacing={1.5}>
                                    <Grid item xs={12} md={8}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                {intl.get('MOVING.STREET_ADDRESS')}
                                            </FormLabel>
                                            <Autocomplete
                                                freeSolo
                                                value={movingTo?.displayAddress}
                                                fullWidth
                                                options={movingToAddressPredictions}
                                                filterOptions={(options, state) => options}
                                                getOptionLabel={option => option?.text}
                                                onInputChange={handleInputChangeMovingToAddress}
                                                onChange={handleChangeMovingToAddress}
                                                PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                                renderInput={params => <TextField
                                                    {...params}
                                                    className={classes.smallInput}
                                                    variant="outlined"
                                                    error={movingToAddressMissing.includes('streetAddress')}
                                                    helperText={movingToAddressMissing.includes('streetAddress') && 'Required'}
                                                />}
                                                renderOption={option => (
                                                    <Typography style={{fontSize: '12px'}}>
                                                        {option.description}
                                                    </Typography>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl fullWidth>
                                            <FormLabel>
                                                {intl.get('MOVING.APT')}
                                            </FormLabel>
                                            <TextField
                                                value={movingTo.apt}
                                                onInput={handleMovingToApt}
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                {intl.get('MOVING.CITY')}
                                            </FormLabel>
                                            <TextField
                                                value={movingTo.city}
                                                onInput={handleMovingToCity}
                                                fullWidth
                                                variant="outlined"
                                                InputProps={{
                                                    style: {padding: '2px 0'},
                                                }}
                                                size="small"
                                                error={movingToAddressMissing.includes('city')}
                                                helperText={movingToAddressMissing.includes('city') && 'Required'}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                {intl.get('MOVING.PROVINCE')}
                                            </FormLabel>
                                            <Autocomplete
                                                value={movingTo.province}
                                                groupBy={(option) => option.country}
                                                fullWidth
                                                options={provinces_states}
                                                getOptionLabel={(option) => option.code}
                                                onChange={handleMovingToProvince}
                                                renderInput={(params) => <TextField
                                                    variant="outlined"
                                                    className={classes.smallInput}
                                                    {...params}
                                                    error={movingToAddressMissing.includes('province')}
                                                    helperText={movingToAddressMissing.includes('province') && 'Required'}
                                                />}
                                                renderOption={(option) => (
                                                    <div>
                                                        {option.name}
                                                    </div>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                {intl.get('MOVING.COUNTRY')}
                                            </FormLabel>
                                            <FormControl fullWidth>
                                                <Select
                                                    value={movingTo?.country}
                                                    size='small'
                                                    onChange={handleMovingToCountry}
                                                    error={movingToAddressMissing.includes('country')}
                                                    helperText={movingToAddressMissing.includes('country') && 'Required'}
                                                >
                                                    <MenuItem value='CA'>Canada</MenuItem>
                                                    <MenuItem value='US'>Unites States</MenuItem>
                                                </Select>
                                                <FormHelperText
                                                    sx={{
                                                        color: "error.main",
                                                    }}
                                                >
                                                    {movingToAddressMissing.includes('country') && 'Required'}
                                                </FormHelperText>
                                            </FormControl>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                {intl.get('MOVING.POSTAL_CODE')}
                                            </FormLabel>
                                            <TextField
                                                value={movingTo.postalCode}
                                                onInput={handleMovingToPostalCode}
                                                fullWidth
                                                variant="outlined"
                                                InputProps={{
                                                    style: {padding: '2px 0'},
                                                }}
                                                size='small'
                                                error={movingToAddressMissing.includes('postalCode') || !validateMovingToPostalCode()}
                                                helperText={movingToAddressMissing.includes('postalCode') ? 'Required' : !validateMovingToPostalCode() && "Invalid"}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                {intl.get('MOVING.PROPERTY_TYPE')}
                                            </FormLabel>
                                            <Autocomplete
                                                value={movingTo.propertyType}
                                                fullWidth
                                                groupBy={(option) => option.propertyType}
                                                options={house_type}
                                                getOptionLabel={(option) => option.houseType}
                                                onChange={handleMovingToPropertyType}
                                                renderInput={(params) => <TextField
                                                    variant="outlined"
                                                    className={classes.smallInput}
                                                    {...params}
                                                    error={movingToAddressMissing.includes('propertyType')}
                                                    helperText={movingToAddressMissing.includes('propertyType') && 'Required'}
                                                />}
                                                renderOption={(option) => (
                                                    <div>
                                                        {option.houseType}
                                                    </div>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                Elevator
                                            </FormLabel>
                                            <Select
                                                value={movingTo.lift}
                                                size='small'
                                                // placeholder='Select Partner'
                                                onChange={handleMovingToLift}
                                                style={{textAlign: 'left'}}
                                                disabled={movingTo?.propertyType?.houseType !== 'Apartment'}
                                                // error={validation && validation?.carrierMissing}
                                            >
                                                <MenuItem value={0}>
                                                    No
                                                </MenuItem>
                                                <MenuItem value={1}>
                                                    Yes
                                                </MenuItem>
                                            </Select>
                                            {/*<FormHelperText*/}
                                            {/*    sx={{*/}
                                            {/*        color: "error.main",*/}
                                            {/*    }}*/}
                                            {/*>*/}
                                            {/*    {(validation && validation?.carrierMissing) && 'Required field'}*/}
                                            {/*</FormHelperText>*/}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                {intl.get('MOVING.FLOOR_LEVEL')}
                                            </FormLabel>
                                            <Autocomplete
                                                value={movingTo.floorLevel}
                                                fullWidth
                                                options={FLOOR_LEVEL_OPTIONS}
                                                onChange={handleMovingToFloorLevel}
                                                disabled={movingTo?.lift || movingTo?.propertyType?.houseType === 'Curb Side'}
                                                renderInput={(params) => <TextField
                                                    variant="outlined"
                                                    className={classes.smallInput}
                                                    {...params}
                                                    error={movingToAddressMissing.includes('floorLevel')}
                                                    helperText={movingToAddressMissing.includes('floorLevel') && 'Required'}
                                                />}
                                            />
                                        </FormControl>
                                    </Grid>
                                    {/*<Grid item xs={12} md={12}>*/}
                                    {/*    <FormControl fullWidth>*/}
                                    {/*        <FormLabel required>*/}
                                    {/*            {intl.get('MOVING.DISTANCE_FROM_TRUCK_TO_DOOR')}*/}
                                    {/*        </FormLabel>*/}
                                    {/*        <Autocomplete*/}
                                    {/*            value={movingTo.distanceFromTruckToDoor}*/}
                                    {/*            fullWidth*/}
                                    {/*            options={DISTANCE_FROM_TRUCK_TO_DOOR_OPTIONS}*/}
                                    {/*            onChange={handleMovingToDistanceFromTruckToDoor}*/}
                                    {/*            onInputChange={handleMovingToDistanceFromTruckToDoor}*/}
                                    {/*            renderInput={(params) => <TextField*/}
                                    {/*                variant="outlined"*/}
                                    {/*                className={classes.smallInput}*/}
                                    {/*                {...params}*/}
                                    {/*                error={movingToAddressMissing.includes('distanceFromTruckToDoor')}*/}
                                    {/*                helperText={movingToAddressMissing.includes('distanceFromTruckToDoor') && 'Required'}*/}
                                    {/*            />}*/}
                                    {/*        />*/}
                                    {/*    </FormControl>*/}
                                    {/*</Grid>*/}
                                    {/*<Grid item xs={12} md={12}>*/}
                                    {/*    <FormControl fullWidth style={{*/}
                                    {/*        display: "flex",*/}
                                    {/*        flexDirection: "row",*/}
                                    {/*        justifyContent: "space-between",*/}
                                    {/*        gap: "3px"*/}
                                    {/*    }}>*/}
                                    {/*        <FormLabel>*/}
                                    {/*            {intl.get('MOVING.STAIRCASE')}*/}
                                    {/*            <Tooltip*/}
                                    {/*                placement="top"*/}
                                    {/*                arrow*/}
                                    {/*                componentsProps={{*/}
                                    {/*                    tooltip: {*/}
                                    {/*                        sx: {*/}
                                    {/*                            bgcolor: 'white',*/}
                                    {/*                            color: 'black',*/}
                                    {/*                            boxShadow: 1,*/}
                                    {/*                            border: '1px solid #ddd',*/}
                                    {/*                            borderRadius: '8px', // Added border radius*/}
                                    {/*                        },*/}
                                    {/*                    },*/}
                                    {/*                    arrow: {*/}
                                    {/*                        sx: {*/}
                                    {/*                            color: 'white',*/}
                                    {/*                        },*/}
                                    {/*                    },*/}
                                    {/*                }}*/}
                                    {/*                title={*/}
                                    {/*                    <Box sx={{backgroundColor: "#ffffff", p: 1}}>*/}
                                    {/*                        <ul style={{*/}
                                    {/*                            paddingBlock: "1.5px",*/}
                                    {/*                            margin: 0,*/}
                                    {/*                            paddingInline: "3px"*/}
                                    {/*                        }}>*/}
                                    {/*                            <li>*/}
                                    {/*                                A staircase consists of 3 or more steps.*/}
                                    {/*                            </li>*/}
                                    {/*                            <li>*/}
                                    {/*                                A staircase connects 2 stories together.*/}
                                    {/*                            </li>*/}
                                    {/*                            <li>*/}
                                    {/*                                It is a single staircase even if it is*/}
                                    {/*                                interrupted by a*/}
                                    {/*                                landing.*/}
                                    {/*                            </li>*/}
                                    {/*                            <li>*/}
                                    {/*                                Do not include the basement of your*/}
                                    {/*                                residence.*/}
                                    {/*                            </li>*/}
                                    {/*                        </ul>*/}
                                    {/*                    </Box>*/}
                                    {/*                }>*/}
                                    {/*                <IconButton>*/}
                                    {/*                    <FmdBadOutlinedIcon sx={{color: '#1D8B45'}}/>*/}
                                    {/*                </IconButton>*/}
                                    {/*            </Tooltip>*/}
                                    {/*        </FormLabel>*/}
                                    {/*        <Box sx={{*/}
                                    {/*            display: 'flex',*/}
                                    {/*            alignItems: 'center',*/}
                                    {/*            border: '1px solid #ddd',*/}
                                    {/*            borderRadius: '10px',*/}
                                    {/*            padding: "7px"*/}
                                    {/*        }}>*/}
                                    {/*            <IconButton size="small"*/}
                                    {/*                        onClick={decreaseMovingToStaircase}><RemoveCircleOutlineIcon/></IconButton>*/}
                                    {/*            <Typography variant="body1"*/}
                                    {/*                        mx={1}>{movingTo.staircaseNumber}</Typography>*/}
                                    {/*            <IconButton size="small"*/}
                                    {/*                        onClick={increaseMovingToStaircase}><AddCircleOutlineIcon/></IconButton>*/}
                                    {/*        </Box>*/}
                                    {/*    </FormControl>*/}
                                    {/*</Grid>*/}
                                    {/*<Grid item xs={12} md={12}>*/}
                                    {/*    <FormControl fullWidth>*/}
                                    {/*        <FormLabel required>*/}
                                    {/*            {intl.get('MOVING.RESTRICTIONA_AT_COLLECTION')}*/}
                                    {/*        </FormLabel>*/}
                                    {/*        <FormGroup>*/}
                                    {/*            <FormControlLabel*/}
                                    {/*                control={*/}
                                    {/*                    <Checkbox*/}
                                    {/*                        checked={movingTo.isNarrowStreet}*/}
                                    {/*                        onChange={handleMovingToIsNarrowStreet}*/}
                                    {/*                        name="narrow street"*/}
                                    {/*                        sx={{*/}
                                    {/*                            color: 'green',*/}
                                    {/*                            '&.Mui-checked': {*/}
                                    {/*                                color: 'green',*/}
                                    {/*                            },*/}
                                    {/*                        }}*/}
                                    {/*                        style={{*/}
                                    {/*                            color: 'green',*/}
                                    {/*                            '&.Mui-checked': {*/}
                                    {/*                                color: 'green',*/}
                                    {/*                            }*/}
                                    {/*                        }}*/}
                                    {/*                    />*/}
                                    {/*                }*/}
                                    {/*                label={*/}
                                    {/*                    <>*/}
                                    {/*                        Narrow Street:&nbsp;*/}
                                    {/*                        <span style={{fontSize: "12px"}}>Tight, Space, Difficult for Trucks or Movers.</span>*/}
                                    {/*                    </>*/}
                                    {/*                }*/}
                                    {/*            />*/}
                                    {/*            <FormControlLabel*/}
                                    {/*                control={*/}
                                    {/*                    <Checkbox*/}
                                    {/*                        checked={movingTo.isSteepDriveway}*/}
                                    {/*                        onChange={handleMovingToIsSteepDrivewayStreet}*/}
                                    {/*                        name="steep driveway"*/}
                                    {/*                        style={{*/}
                                    {/*                            color: 'green',*/}
                                    {/*                            '&.Mui-checked': {*/}
                                    {/*                                color: 'green',*/}
                                    {/*                            }*/}
                                    {/*                        }}*/}
                                    {/*                    />*/}
                                    {/*                }*/}
                                    {/*                label={*/}
                                    {/*                    <>*/}
                                    {/*                        Steep Driveway:&nbsp;*/}
                                    {/*                        <span style={{fontSize: "12px"}}>Trucks & Movers may struggle with slope.*/}
                                    {/*                </span>*/}
                                    {/*                    </>*/}
                                    {/*                }*/}
                                    {/*            />*/}
                                    {/*        </FormGroup>*/}
                                    {/*    </FormControl>*/}
                                    {/*</Grid>*/}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Box>

    )
}