import React from "react";
import { Box } from "@material-ui/core";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import { shipmentTypesDetails } from "../constInfo";
import { ordinal_suffix_of } from "../../../Utils/addOrdinalSuffix";

const findUnit = (labelId, fieldName) => {
  const itemUnit = shipmentTypesDetails[labelId];
  return itemUnit ? itemUnit[fieldName] : "";
};

const OrderDetailsHeader = ({ packageList, orderInfo }) => {
  const classes = useStyles();

  const getDate = (date) => {
    return date.split(" ")[0];
  };

  return (
    <Box>
      <div id="quote-order-content1" className="subtitle">
        <p className={classes.summaryHeader}>Order Number</p>
      </div>
      <Box id="quote-order-content2" pl={1}>
        <p className={classes.summaryBodyText}>{orderInfo.order_number}</p>
      </Box>
      <div id="quote-order-content3" className="subtitle">
        <p className={classes.summaryHeader}>Pickup</p>
      </div>
      <Box id="quote-order-content2" pl={1}>
        <p className={classes.summaryBodyText}>
          Phone number: {orderInfo.pickup_phone}
          <br />
          {orderInfo.pickup_address}
          <p>Requested for {getDate(orderInfo.scheduled_pickup_date)}</p>
        </p>
      </Box>
      <div id="quote-order-content3" className="subtitle">
        <p className={classes.summaryHeader}>Delivery</p>
      </div>
      <Box id="quote-order-content4" pl={1}>
        <p className={classes.summaryBodyText}>
          Phone number: {orderInfo.deliver_phone || ""}
          <br />
          {orderInfo.deliver_address}
        </p>
      </Box>
      <div id="quote-order-content5" className="subtitle">
        <p className={classes.summaryHeader}>Delivery Items</p>
      </div>
      <Box id="quote-summary-content6" pl={1}>
        <Box className={classes.summaryBodyText} pb={1}>
          {packageList.map((item, key) => {
            const shipmentTypeID = Object.keys(shipmentTypesDetails).find(
              (key) => shipmentTypesDetails[key].id === item.shipment_type_id
            );
            const palletsNum =
              shipmentTypesDetails[shipmentTypeID || 1].unit_number *
              item.number;
            return (
              <div key={key}>
                <Box component="span" fontWeight="700">
                  {ordinal_suffix_of(key + 1)} Package:
                </Box>{" "}
                <Box component="span" fontWeight="400">
                  {item.item_name} - {palletsNum}{" "}
                  {findUnit(item.shipment_type_id, "label")}(s) &times;{" "}
                  {item.weight} {findUnit(item.shipment_type_id, "weightUnit")}
                </Box>
              </div>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  summaryHeader: {
    fontSize: "1.2rem",
    color: "seagreen",
    fontFamily: "Montserrat",
    // borderBottom: '1px, solid rgb(141, 173, 155)',
  },
  summaryBodyText: {
    fontSize: "1rem",
    // color: "rgb(141, 173, 155)",
    fontFamily: "Montserrat",
  },
}));

OrderDetailsHeader.propTypes = {
  packageList: PropTypes.array.isRequired,
  orderInfo: PropTypes.object.isRequired,
};

export default OrderDetailsHeader;
