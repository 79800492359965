import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ScanBarcode from "../ScanComponents/ScanBarcode";
import {
  Button,
  Grid,
  TextField,
  makeStyles,
  Divider,
  Typography,
  IconButton,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Paper, Box,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import axios from "axios";
import {
  GET_TRACKING_INFO_BY_TRACKING_NUMBER,
  GET_PACKAGES_BY_ID_API,
  DRIVER_LOAD_SHIPMENTS,
  DRIVER_UPLOAD_SHIPMENTS_IMAGES,
  DRIVER_PICKUP_SHIPMENTS_TASK,
  GET_SHIPMENT_TASK_BY_TRACKING_NUMBER,
  DRIVER_PICKUP_SHIPMENTS_TASK_WITH_PACKAGES,
} from "../../Utils/apiUrl";
import { getHeaders } from "../../Utils/fetchUtil";
import LoadingCircle from "../LoadingCircle";
import { removeLoadedShipments } from "../../actions/driverLoadAndDeliverShipmentsAction";
import {
  addLoadedShipments,
  cleanLoadedShipmentsData,
} from "../../actions/driverLoadAndDeliverShipmentsAction";
import { driverClearImages } from "../../actions/driverDeliverUploadImagesAction";
import { useDispatch, connect } from "react-redux";
import { getTokenFromCookie } from "../../Utils/doToken";
import DriverUploadImages from "../Layouts/DriverUploadImages";
import AlertMessageLayout from "../Layouts/AlertMessageLayout";
import ConfirmationCodesInputLayout from "../Layouts/ConfirmationCodesInputLayout";
import FeedbackDialogLayout from "../Layouts/FeedbackDialogLayout";
import { useSelector } from "react-redux";
import { pageTitle } from "../../css/MUIStyle";

const useStyles = makeStyles((theme) => ({
  scanInput: {
    justifyContent: "center",
    margin: "5px auto",
    gap: "5px",
  },
  scanButton: {
    marginLeft: "5px",
  },
  confirmButton: {
    color: "white",
  },
  centering: {
    display: "flex",
    justifyContent: "center",
  },
  paper: {
    margin: "5px 2%",
    width: "96%",
    maxWidth: "1000px",
    backgroundColor: "#fdf5e8",
    padding: "3px",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    display: "flex",
    justifyContent: "space-around",
  },
  blocks: {
    backgroundColor: "#fbfbf8",
    border: "1px solid #ded47c",
    borderRadius: "5px",
    margin: "5px",
  },
}));

const getSteps = () => {
  return [
    "Review shipments that you are going to pickup.",
    "Take pictures before confirming pickup shipments (Recommended).",
    "Input confirmation codes.",
  ];
};

const LoadShipmentsPage = ({ loadedPackages }) => {
  const history = useHistory();
  const dataFlow = loadedPackages.driver_Loaded_packages;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [scanState, setScanState] = useState({
    activeScan: false,
    buttonWord: "Scan Tracking Number",
  });

  const [scannedTrackingNumbers, setScannedTrackingNumbers] = useState([]);
  const [textInput, setTextInput] = useState("");
  const [loadInfo, setLoadInfo] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const [activeNext, setActiveNext] = useState([
    Object.keys(dataFlow).length === 0,
    false,
  ]);
  const [confirmationCodes, setConfirmationCodes] = useState([]);
  const [dialogState, setDialogState] = useState({
    openDialog: false,
    openBackdrop: false,
    feedbackTarget: [],
    title: "",
    content: "",
    loading: false,
    buttonWords: ["", ""],
    picked_up: false,
    uploadImagesRes: [],
  });

  useEffect(() => {
    setActiveNext([
      Object.keys(dataFlow).length === 0,
      false,
    ])
  }, [dataFlow])

  useEffect(() => {
    console.log("data flow", dataFlow);
  }, [dataFlow]);

  const driverUploadedImages = useSelector(
    (state) => state.driver_uploaded_images.loadShipments
  );

  const LoadedShipmentNumbers = useSelector(
    (state) => state.driver_loaded_packages.loaded_shipment_numbers
  );

  const handleRedirect = () => {
    history.push("/driver/claimed_shipment_tasks");
  };

  const handleRemove = (pKey, sKey, t) => {
    console.log("clicked to remove tracking number", pKey, sKey, t);
    const payload = {
      package_number: pKey,
      shipment_number: sKey,
      tracking_number: t,
    };

    dispatch(removeLoadedShipments(payload));
  };

  useEffect(() => {
    console.log("Upload Images: ", driverUploadedImages.map(v => v.raw));
  }, [driverUploadedImages])

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            {dataFlow &&
              Object.keys(dataFlow).length > 0 &&
              Object.keys(dataFlow).map((pKey, index) => {
                const p = dataFlow[pKey];
                const s = p.shipment_numbers;
                let tracking_count = 0;
                for (let key in s) {
                  tracking_count += Object.keys(s[key]).length;
                }
                return (
                  <>
                    <li
                      key={p.package_number}
                      style={{ listStyleType: "none" }}
                      className={classes.center}
                    >
                      <Paper className={classes.paper}>
                        <Typography variant="subtitle1" align="center">
                          {p.item_name}
                        </Typography>
                        {p.cover_image !== "" && (
                          <img
                            className={classes.img}
                            alt="package"
                            src={p.cover_image}
                          />
                        )}
                        <Divider
                          variant="middle"
                          style={{ margin: "5px auto" }}
                        />
                        <Grid container className={classes.container}>
                          <Grid item>
                            <Typography variant="subtitle2">
                              Total Shipments: {Object.keys(s).length}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="subtitle2" align="center">
                              Total Tracking Number(s): {tracking_count}
                            </Typography>
                          </Grid>
                          {Object.keys(s).map((sKey, index) => {
                            return (
                              <Grid
                                item
                                container
                                className={classes.blocks}
                                key={sKey}
                              >
                                <Grid item xs={12}>
                                  <Typography
                                    variant="subtitle1"
                                    align="center"
                                  >
                                    Shipment #: <i>{sKey}</i>{" "}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography
                                    variant="subtitle2"
                                    align="center"
                                  >
                                    Total Tracking Number(s):{" "}
                                    {Object.keys(s[sKey]).length}
                                  </Typography>
                                </Grid>
                                {Object.keys(s[sKey]).map((t) => {
                                  return (
                                    <Grid
                                      container
                                      className={classes.row}
                                      key={t}
                                    >
                                      <Grid
                                        item
                                        xs={6}
                                        className={classes.center}
                                      >
                                        <Typography variant="subtitle2">
                                          {t}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <IconButton
                                          aria-label="delete"
                                          onClick={() =>
                                            handleRemove(pKey, sKey, t)
                                          }
                                        >
                                          <DeleteIcon fontSize="small" />
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Paper>
                    </li>
                  </>
                );
              })}
          </>
        );
      case 1:
        return <DriverUploadImages type="load" />;
      case 2:
        return (
          <ConfirmationCodesInputLayout
            codesCallback={handleConfirmationCodes}
          />
        );
      default:
        return "Unknown step";
    }
  };

  const handleConfirmationCodes = (codes) => {
    setConfirmationCodes(codes);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const loadShipment = async () => {
    //call API to load shipments
    setDialogState({
      ...dialogState,
      loading: true,
    });
    let uploadImagesRes = [];

    try {
      // let res = await axios.put(
      //   DRIVER_LOAD_SHIPMENTS,
      //   {},
      //   {
      //     params: {
      //       shipment_numbers: loadedPackages.loaded_shipment_numbers,
      //       codes: confirmationCodes,
      //     },
      //     headers: getHeaders(),
      //   }
      // );
      console.log("loaded packages", loadedPackages);
      const loadedShipmentNumbers = loadedPackages.loaded_shipment_numbers;
      console.log("loaded shipment numbers", loadedShipmentNumbers);
      console.log("confirmation code: ", confirmationCodes);
      console.log("Upload Images:", driverUploadedImages);

      for (let index = 0; index < loadedShipmentNumbers.length; index++) {
        console.log("Loaded Shipment Number: ", loadedShipmentNumbers[index]);
        const formData = new FormData();
        formData.append("shipmentNumber", loadedShipmentNumbers[index].shipment_number);
        formData.append("stage", loadedShipmentNumbers[index].stage);
        formData.append("taskId", loadedShipmentNumbers[index].taskId);
        formData.append("receiveConfirmationCodeArray", [confirmationCodes[0]] || []);
        for (const image of driverUploadedImages.map(v => v.raw)) {
          formData.append("shipmentTaskImages", image);
        }
        const { data } = await axios.post(`${DRIVER_PICKUP_SHIPMENTS_TASK_WITH_PACKAGES}`, formData, {
          headers: {
            Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("Pickup result:", data);
      }
      console.log("pickup shipment success.");
      setDialogState({
        ...dialogState,
        title: "Awesome",
        content: "You have successfully picked-up the shipment(s).",
        loading: false,
        buttonWords: ["", "Okay"],
        picked_up: true,
        uploadImagesRes: uploadImagesRes,
      });
    } catch (err) {
      console.log("pickup shipment error");
      console.log("current state", dialogState);
      console.log("pickup shipment issue");
      setDialogState({
        ...dialogState,
        title: "Failed to pickup shipment(s)",
        content: "Pick up shipment(s) failed, please retry later.",
        loading: false,
        buttonWords: ["Retry", "Cencel"],
        picked_up: false,
        uploadImagesRes: uploadImagesRes,
      });

      setDialogState({
        ...dialogState,
        title: "Failed to pickup shipment(s)",
        content: "Pick up shipment(s) failed, please retry later.",
        loading: false,
        buttonWords: ["Retry", "Cencel"],
        picked_up: false,
        uploadImagesRes: uploadImagesRes,
      });
    }
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      setDialogState({
        title: "Pick up shipment(s)",
        content: "Confirm to pick up the following shipment(s)?",
        loading: false,
        buttonWords: ["Confirm", "Cencel"],
        openDialog: true,
        openBackdrop: true,
      });
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleClickScan = () => {
    if (!scanState.activeScan) {
      setScanState({
        activeScan: true,
        buttonWord: "Close Scan",
      });
    } else {
      setScanState({
        activeScan: false,
        buttonWord: "Scan Tracking Number",
      });
    }
  };

  const fetchInfo = async (tNums) => {
    setLoadInfo(true);

    //if get numbers from the scanner
    if (tNums) {
      console.log("tracking numbers from the scanner", tNums);

      //make API calls
      const header = {
        headers: {
          Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
        },
      };

      for (let i = 0; i < tNums.length; i++) {

        const header = {
          headers: {
            Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
          },
        };
        // const trackingInfo = await axios.get(
        //   GET_TRACKING_INFO_BY_TRACKING_NUMBER(textInput),
        //   header
        // );
        const { data } = await axios.get(`${GET_SHIPMENT_TASK_BY_TRACKING_NUMBER}/${tNums[i]}`, header);

        //get package info and shipment info
        // const packageInfo = await axios.get(
        //   GET_PACKAGES_BY_ID_API(packageNum),
        //   header
        // );
        // console.log("package infor", packageInfo);
        // const p = packageInfo.data;
        const payload = {
          package_number: data.package_number,
          item_name: data.item_name,
          cover_image: data.cover_image,
          shipment_number: data.shipment_number,
          tracking_number: data.tracking_numbers,
          taskId: data.id,
          stage: data.stage
        };
        dispatch(addLoadedShipments(payload));

        // const trackingInfo = await axios.get(
        //   GET_TRACKING_INFO_BY_TRACKING_NUMBER(tNums[i]),
        //   header
        // );
        //
        // //returning nothing, the data property is empty, go next
        // if (!trackingInfo.data || Object.keys(trackingInfo.data).length === 0) {
        //   continue;
        // }
        //
        // if (trackingInfo) {
        //   console.log("tracking infor from API call", trackingInfo);
        //   const shipmentNum = trackingInfo.data.shipment_number;
        //   const packageNum = trackingInfo.data.package_number;
        //
        //   //get package info and shipment info
        //   const packageInfo = await axios.get(
        //     GET_PACKAGES_BY_ID_API(packageNum),
        //     header
        //   );
        //   console.log("package infor", packageInfo);
        //   if (packageInfo !== null) {
        //     const p = packageInfo.data;
        //     const payload = {
        //       package_number: p.package_number,
        //       item_name: p.item_name,
        //       cover_image: p.cover_image,
        //       shipment_number: shipmentNum,
        //       tracking_number: [trackingInfo.data.tracking_number],
        //     };
        //     dispatch(addLoadedShipments(payload));
        //   }
        // }
      }
      console.log("fetch tracking info of scanned nums DONE");
      setLoadInfo(false);
    } else {
      //numbers not come from scanner
      try {
        const header = {
          headers: {
            Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
          },
        };
        // const trackingInfo = await axios.get(
        //   GET_TRACKING_INFO_BY_TRACKING_NUMBER(textInput),
        //   header
        // );
        const { data } = await axios.get(`${GET_SHIPMENT_TASK_BY_TRACKING_NUMBER}/${textInput}`, header);

        //get package info and shipment info
        // const packageInfo = await axios.get(
        //   GET_PACKAGES_BY_ID_API(packageNum),
        //   header
        // );
        // console.log("package infor", packageInfo);
        // const p = packageInfo.data;
        const payload = {
          package_number: data.package_number,
          item_name: data.item_name,
          cover_image: data.cover_image,
          shipment_number: data.shipment_number,
          tracking_number: data.tracking_numbers,
          taskId: data.id,
          stage: data.stage
        };
        dispatch(addLoadedShipments(payload));
        // setActiveNext([true, true]);
        setLoadInfo(false);
      } catch (error) {
        setLoadInfo(false);
      }
    }
  };

  const handleEnter = () => {
    if (textInput.length === 0) return;

    fetchInfo();
  };

  useEffect(() => {
    return () => {
      dispatch(cleanLoadedShipmentsData());
      dispatch(driverClearImages("load"));
    };
  }, []);

  //input for tracking number
  const handleChange = (e) => {
    setTextInput(e.target.value);
  };

  const handleCancel = () => {
    //pick success
    if (dialogState.picked_up) {
      dispatch(cleanLoadedShipmentsData());
      dispatch(driverClearImages("load"));
      setActiveStep(0);
      setActiveNext([true, true]);
    }
    setDialogState({
      ...dialogState,
      openBackdrop: false,
      openDialog: false,
    });
  };

  return (
    <>
      <Typography variant="h6" align="center" style={pageTitle}>
        Pickup Shipments
      </Typography>
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12} container className={classes.scanInput}>
          <form>
            <TextField
              label="Tracking Number"
              variant="outlined"
              fullWidth
              onChange={(e) => handleChange(e)}
            />
          </form>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleEnter()}
            className={classes.scanButton}
          >
            Enter
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleClickScan()}
            className={classes.scanButton}
          >
            {scanState.buttonWord}
          </Button>
        </Grid>
        <Box textAlign="center" fontSize="5">
          Please enter multiple tracking numbers within the same Shipment
        </Box>
        {scanState.activeScan && (
          <Grid item xs={12}>
            <ScanBarcode
              onGetBarcode={(nums) => {
                fetchInfo(nums);
              }}
            />
          </Grid>
        )}
      </Grid>
      <Divider variant="middle" />
      {
        loadInfo ? (
          <LoadingCircle />
        ) : (
          <>
            <div>
              {LoadedShipmentNumbers.length < 1 ? (
                <AlertMessageLayout
                  message="Click here to select your shipments to pickup."
                  onClick={handleRedirect}
                />
              ) : null}
            </div>
            <div className={classes.root}>
              <Stepper
                activeStep={activeStep}
                orientation="vertical"
                style={{ width: "94%", maxWidth: "1000px" }}
              >
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                    <StepContent className={classes.stepCotent}>
                      <Typography>{getStepContent(index)}</Typography>
                      <div className={classes.actionsContainer}>
                        <div>
                          <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            className={classes.button}
                          >
                            Back
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            className={classes.button}
                            disabled={activeNext[activeStep]}
                          >
                            {activeStep === steps.length - 1
                              ? "Submit"
                              : "Next"}
                          </Button>
                        </div>
                      </div>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </div>
          </>
        ) //type can be 'load' or 'deliver'
      }

      <FeedbackDialogLayout
        openDialog={dialogState.openDialog}
        openBackDrop={dialogState.openBackdrop}
        title={dialogState.title}
        content={dialogState.content}
        shipmentNumbers={loadedPackages.loaded_shipment_numbers}
        handleConfirm={() => loadShipment()}
        handleCancel={() => handleCancel()}
        loading={dialogState.loading}
        buttonWords={dialogState.buttonWords}
        uploadImagesRes={dialogState.uploadImagesRes}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loadedPackages: state.driver_loaded_packages,
  };
};

export default connect(mapStateToProps)(LoadShipmentsPage);
