import {useSelector} from "react-redux";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {BusinessMyBoxesItem} from './BusinessMyBoxesItem';
import Typography from "@mui/material/Typography";
import {Divider} from "@mui/material";
import Grid from "@mui/material/Grid";
import {BusinessMyBoxItemEdit} from "./BusinessMyBoxItemEdit";

export const BusinessMyBoxesList = () => {

    const id = useSelector((state) => state.user.id);

    const [myBoxesList, setMyBoxesList] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [boxItemDetail, setBoxItemDetail] = useState({});

    const getMyBoxesListForPartner = async () => {

        let requestURL = `${PARTNER_URI}/myboxes/getMyBoxesListForPartner`

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {partnerId: id},
            })
            const {data} = result;
            setMyBoxesList(data);
            console.log(data);
        } catch (e) {
            console.log(e)
        }
    }

    const handleEditBox = () => {
        setIsEdit(true);
    }

    const handleCancelBox = () => {
        setIsEdit(false);
    }

    const handleBoxItemDetail = (data) => {
        setBoxItemDetail(data);
    }

    useEffect(() => {
        id && getMyBoxesListForPartner()
    }, []);

    if (isEdit) {
        return (
            <BusinessMyBoxItemEdit editBoxItem={boxItemDetail}
                                   handleCancelBox={handleCancelBox}
                                   getMyBoxesListForPartner={getMyBoxesListForPartner}/>
        )
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={3.5}>
                <Typography sx={{fontWeight: '600'}}>
                    Name
                </Typography>
            </Grid>
            <Grid item xs={2.5}>
                <Typography sx={{fontWeight: '600'}}>
                    Description
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography sx={{fontWeight: '600'}}>
                    Dimension
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography sx={{fontWeight: '600'}}>
                    Weight
                </Typography>
            </Grid>
            <Grid item xs={2}>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            {
                myBoxesList?.length > 0 ? myBoxesList.map((item, index) => {
                        return <BusinessMyBoxesItem key={index}
                                                    boxItem={item}
                                                    handleBoxItemDetail={handleBoxItemDetail}
                                                    handleEditBox={handleEditBox}
                                                    getMyBoxesListForPartner={getMyBoxesListForPartner}/>
                    })
                    : 'No boxes'
            }
        </Grid>
    )
}