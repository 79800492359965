import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Grid,
  TextField,
  makeStyles,
  Divider,
  Typography, Box,
} from "@material-ui/core";
import ScanBarcode from "../ScanComponents/ScanBarcode";
import DeliverShipmentsLayout from "../Layouts/DeliverShipmentsLayout";
import axios from "axios";
import {
  GET_TRACKING_INFO_BY_TRACKING_NUMBER,
  GET_PACKAGES_BY_ID_API,
  GET_DRIVER_LOADED_SHIPMENTS, GET_PICKUP_SHIPMENT_TASK_LIST, GET_SHIPMENT_TASK_BY_TRACKING_NUMBER,
} from "../../Utils/apiUrl";
import {addDeliverShipments, addLoadedShipments} from "../../actions/driverLoadAndDeliverShipmentsAction";
import store from "../../store/index";
import { useDispatch, useSelector } from "react-redux";
import { cleanDeliverShipmentsData } from "../../actions/driverLoadAndDeliverShipmentsAction";
import {
  driverClearImages,
  driverSetImageFetchStatus,
} from "../../actions/driverDeliverUploadImagesAction";
import { getHeaders } from "../../Utils/fetchUtil";
import { pageTitle } from "../../css/MUIStyle";
import LoadingCircle from "../LoadingCircle";

const useStyles = makeStyles((theme) => ({
  scanInput: {
    justifyContent: "center",
    margin: "5px auto",
  },
  scanButton: {
    marginLeft: "5px",
  },
  confirmButton: {
    color: "white",
  },
  centering: {
    display: "flex",
    justifyContent: "center",
  },
}));

const DeliverShipmentsPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [openCamera, setOpenCamera] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonWord, setButtonWord] = useState("Scan");
  const [textInput, setTextInput] = useState("");
  const [trackingNum, setTrackingNum] = useState([]);
  const [fetchedTrackingNums, setFetchedTrackingNums] = useState([]);

  const selectedShipments = useSelector((state) => {
    return state.driver_deliver_packages.selectedShipmentNumber;
  });

  const constructData = async (
    pNum,
    sNum,
    tNum,
    itemName,
    coverImage,
    stage,
    taskId
  ) => {
    const payload = {
      package_number: pNum,
      item_name: itemName,
      cover_image: coverImage,
      shipment_number: sNum,
      tracking_number: tNum,
      checked: false,
      stage: stage,
      taskId: taskId
    };
    store.dispatch(addDeliverShipments(payload));
  };

  // new Function
  const getLoadedShipmentTasks = async () => {
    try {
      setLoading(true);
      dispatch(cleanDeliverShipmentsData());
      const { data } = await axios.post(`${GET_PICKUP_SHIPMENT_TASK_LIST}`, {}, {
        headers: getHeaders()
      });
      console.log("Pick up shipment task list: ", data);
      for (const shipment of data.results) {
        console.log(shipment);
        const itemName = shipment.item_name;
        const coverImage = shipment.cover_image;
        const trackingNum = [{
          tracking_number: shipment.tracking_numbers
        }];
        const shipmentNum = shipment.shipment_number;
        const packageNum = shipment.package_number;
        const stage = shipment.stage;
        const taskId = shipment.id;
        constructData(
            packageNum,
            shipmentNum,
            trackingNum,
            itemName,
            coverImage,
            stage,
            taskId
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  const fetchLoadedShipments = async () => {
    setLoading(true);
    dispatch(cleanDeliverShipmentsData());

    try {
      const loadedShipments = await axios.get(GET_DRIVER_LOADED_SHIPMENTS, {
        headers: getHeaders(),
      });
      if (loadedShipments) {
        console.log("loaded shipments:", loadedShipments);
        loadedShipments.data.data.forEach((s) => {
          const itemName = s.item_name;
          const coverImage = s.cover_image;
          const trackingNum = s.tracking_numbers;
          const shipmentNum = s.shipment_number;
          const packageNum = s.package_number;
          const stage = s.stage;
          constructData(
            packageNum,
            shipmentNum,
            trackingNum,
            itemName,
            coverImage,
            stage
          );
        });
        setLoading(false);
      } else {
        setLoading(false);
        return;
      }
    } catch (error) {
      console.log("Error when fetching tracking numbers", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    //fetch tracking numbers related to the driver
    // fetchLoadedShipments();
    getLoadedShipmentTasks();
    return () => {
      dispatch(cleanDeliverShipmentsData());
      dispatch(driverClearImages("deliver"));
      dispatch(driverSetImageFetchStatus(false, "deliver"));
    };
  }, []);

  useEffect(() => {
    dispatch(driverClearImages("deliver"));
    dispatch(driverSetImageFetchStatus(false, "deliver"));
  }, [selectedShipments.length]);

  const handleReload = useCallback(() => {
    // fetchLoadedShipments();
    getLoadedShipmentTasks();
  }, []);

  const handleClickScan = () => {
    if (!openCamera) {
      setOpenCamera(true);
      setButtonWord("Close Scan");
    } else {
      setOpenCamera(false);
      setButtonWord("Scan");
    }
  };

  const fetchInfo = async () => {
    setLoading(true);

    const header = {
      headers: getHeaders(),
    };

    try {

      const { data } = await axios.get(`${GET_SHIPMENT_TASK_BY_TRACKING_NUMBER}/${textInput}`, header);
      console.log("Shipment Task:", data);
      const payload = {
        package_number: data.package_number,
        item_name: data.item_name,
        cover_image: data.cover_image,
        shipment_number: data.shipment_number,
        tracking_number: data.tracking_numbers,
        taskId: data.id,
        stage: data.stage
      };
      // dispatch(addLoadedShipments(payload));
      constructData(
          payload.package_number,
          payload.shipment_number,
          [{
            tracking_number: payload.tracking_number
          }],
          payload.item_name,
          payload.cover_image,
          payload.stage,
          payload.taskId
      );
      setLoading(false);

      // const trackingInfo = await axios.get(
      //   GET_TRACKING_INFO_BY_TRACKING_NUMBER(textInput),
      //   header
      // );
      // if (trackingInfo) {
      //   const shipmentNum = trackingInfo.data.shipment_number;
      //   const packageNum = trackingInfo.data.package_number;
      //
      //   //get package info and shipment info
      //   const packageInfo = await axios.get(
      //     GET_PACKAGES_BY_ID_API(packageNum),
      //     header
      //   );
      //
      //   if (packageInfo !== null) {
      //     const p = packageInfo.data;
      //     const payload = {
      //       package_number: p.package_number,
      //       item_name: p.item_name,
      //       cover_image: p.cover_image,
      //       shipment_number: shipmentNum,
      //       tracking_number: [trackingInfo.data.tracking_number],
      //     };
      //     //dispatch(addLoadedShipments(payload));
      //   }
      //
      //   setLoading(false);
      // }
    } catch (error) {
      return setLoading(false);
    }
  };

  const handleEnter = () => {
    if (textInput.length === 0 || fetchedTrackingNums.includes(textInput))
      return;

    fetchInfo();
  };

  //input for tracking number
  const handleChange = (e) => {
    setTextInput(e.target.value);
  };

  return (
    <>
      <Typography variant="h6" align="center" style={pageTitle}>
        Deliver Shipment
      </Typography>
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12} container className={classes.scanInput}>
          <form>
            <TextField
              label="Tracking Number"
              variant="outlined"
              onChange={(e) => handleChange(e)}
            />
          </form>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleEnter()}
            className={classes.scanButton}
          >
            Enter
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleClickScan()}
            className={classes.scanButton}
          >
            {buttonWord}
          </Button>
        </Grid>
        <Box textAlign="center" fontSize="5">
          Please enter multiple tracking numbers within the same Shipment
        </Box>
        {openCamera && (
          <Grid item xs={12}>
            <ScanBarcode onGetBarcode={(nums) => setTrackingNum(nums)} />
          </Grid>
        )}
      </Grid>
      <Divider variant="middle" />
      {loading ? (
        <LoadingCircle />
      ) : (
        <DeliverShipmentsLayout reload={() => handleReload()} />
      )}
    </>
  );
};

export default DeliverShipmentsPage;
