import {UPDATE_TRACKING_NUMBER, UPDATE_TRACKING_NUMBER_BOL} from "./types";

export const updateTrackingNumber = trackingNumber => ({
    type: UPDATE_TRACKING_NUMBER,
    payload: trackingNumber
})

export const updateTrackingNumberBOL = trackingNumber => ({
    type: UPDATE_TRACKING_NUMBER_BOL,
    payload: trackingNumber
})