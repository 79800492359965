import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";
import OrderPaymentListItem from "./OrderPaymentListItem";
import {Pagination} from "@mui/lab";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
    },
    buttonFilesWrapper: {
        border: "2px dashed #d3d3d3",
        borderRadius: "2px",
        background: "#fdf5e8",
        padding: "5px",
        wordBreak: "break-word",
    },
    hscodeListWrapper: {
        border: "2px solid lightgray",
        borderRadius: "5px",
        width: "98%",
        maxHeight: "45vh",
        height: "fit-content",
        margin: "10px auto",
        overflowY: "auto",
        background: "#fff",
    },
    table: {
        width: "100%",
    },
    tbody: {
        "& tr:nth-child(odd)": {
            backgroundColor: "rgba(222, 222, 222, 0.4)",
        },
    },
    nameTH: {
        width: "100px",
    },
    idTH: {
        width: "100px",
        fontSize: '14px'
    },
    pagination: {
        "& > *": {
            marginTop: theme.spacing(2),
        },
        display: "flex",
        justifyContent: "center",
    },
}));

const OrderPaymentTable = ({orderList}) => {

    console.log('order list', orderList);

    const classes = useStyles();
    return (
        <div>
            <Box maxWidth="100%" margin="0 auto">
                <Box mt={2} textAlign={"center"}>
                    <table className={classes.table}>
                        <thead>
                        <tr>
                            <th className={classes.idTH}>Order #</th>
                            <th className={classes.idTH}>Ref Order Number</th>
                            <th className={classes.idTH}>Carrier</th>
                            <th className={classes.idTH}>Total Charge inc.tax</th>
                            <th className={classes.idTH}>Sender Information</th>
                            <th className={classes.idTH}>Delivery Information</th>
                            <th className={classes.idTH}>Order Status</th>
                            <th className={classes.idTH}>Action</th>
                        </tr>
                        </thead>
                        <tbody className={classes.tbody}>
                        {orderList.map((v, index) => {
                            return (
                                <OrderPaymentListItem key={index} orderEach={v}/>
                            );
                        })}
                        </tbody>
                    </table>
                    {
                        orderList.length === 0 ? <>No rows to display</> : undefined
                    }
                </Box>
            </Box>
        </div>
    );
};

export default OrderPaymentTable;