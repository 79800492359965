import {PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {deleteToken, getAccessToken} from "../../../Utils/doToken";
import {Fragment, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";
import Box from "@mui/material/Box";
import {ButtonBase, ClickAwayListener, Tooltip, tooltipClasses, useMediaQuery} from "@mui/material";
import {mapCarrierLogo} from "../../../Utils/Helper";
import IconButton from "@mui/material/IconButton";
import HelpIcon from "@mui/icons-material/Help";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import {styled} from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import {useHistory} from "react-router-dom";
import {BusinessReturnLabelCrossBorder} from "./BusinessReturnLabelCrossBorder";

const styles = {
    ShippingPackageCreateLabelRateRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelRateHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelRateSubHeading: {
        fontSize: '16px',
        fontWeight: '600',
        paddingLeft: '10px'
    },
    ShippingPackageCreateLabelRateOtherBox: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        height: '100%',
        flexDirection: 'column'
    },
    ShippingPackageCreateLabelRateImageBox: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        paddingLeft: '20px',
        gap: '10px',
        flexDirection: 'column'
    },
    ShippingPackageCreateLabelRateOtherBoxMobile: {
        display: 'flex',
        alignItems: 'flex-start',
        paddingLeft: '20px',
        justifyContent: 'center',
        height: '100%',
        flexDirection: 'column'
    },
}

const LightTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

const GreenTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#1D8B45',
        color: '#FFFFFF',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

export const BusinessReturnLabelRating = ({orderData, tranId}) => {

    const history = useHistory();

    const storedToken = getAccessToken("access_token");
    const isMobile = useMediaQuery('(max-width: 600px)');

    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [rateData, setRateData] = useState([]);
    const [rate, setRate] = useState([]);
    const [selectedRate, setSelectedRate] = useState(null);
    const [open, setOpen] = useState(-1);
    const [isCallAllService, setIsCallAllService] = useState(0);
    const [crossBorderDetails, setCrossBorderDetails] = useState(false);

    const handleClose = (index) => {
        // console.log('click away', index);
        // console.log('open index', open);
        // setOpen(-1);
        index === open && setOpen(-1);
    };

    const handleOpen = (index) => {
        // console.log('click open', index);
        // console.log('open index', open);
        setOpen(index);
    };
    const handleSelect = (rate) => {
        setSelectedRate(rate);
        setCrossBorderDetails(false);
    }

    const handleOrderDetails = () => {
        history.push({
            pathname: '/business/packageOrder/returnLabelDetails',
            state: {
                orderData: orderData,
                selectedRate: selectedRate,
                tranId: tranId
            }
        });
    }

    const handleCrossBorderDetails = () => {
        setCrossBorderDetails(true);
    }

    const handleStartOver = () => {
        history.push('/business/home');
    }

    const getShippingPackageRate = async (selectedAccountBase) => {

        setLoading(true);

        let requestURL = `${PARTNER_URI}/loose-item/3rd-party/partner/rates/all`;

        let packageData = orderData?.packageData.map(element => {
            const updatedElement = {...element};
            updatedElement.packageSpecialServices = {
                ...updatedElement.packageSpecialServices,
                batteryDetails: {
                    material: null,
                    packing: null
                },
                declaredValue: {
                    value: 0,
                    currency: 'CAD'
                },
                signatureRequired: null
            };
            return updatedElement;
        });

        let data = {
            rateData: {
                shipFromAddress: orderData?.shipFromAddress,
                shipFromAddressTwo: "",
                shipFromCity: orderData?.shipFromCity,
                shipFromProvince: orderData?.shipFromProvince,
                shipFromPostalCode: orderData?.shipFromPostalCode?.replace(/\s+/g, '').toUpperCase(),
                shipFromCountry: orderData?.shipFromCountry,
                shipFromName: orderData?.shipFromName,
                shipFromPhone: orderData?.shipFromPhone,
                shipToAddress: orderData?.shipToAddress,
                shipToAddressTwo: orderData?.shipToAddressTwo,
                shipToCity: orderData?.shipToCity,
                shipToPostalCode: orderData?.shipToPostalCode.replace(/\s+/g, '').toUpperCase(),
                shipToProvince: orderData?.shipToProvince,
                shipToCountry: orderData?.shipToCountry,
                shipToName: orderData?.shipToName,
                shipToPhone: orderData?.shipToPhone,
                shipmentSpecialServices: {
                    returnShipment: true
                },
                packageData: packageData,
            },
            selectedAccountBase: selectedAccountBase
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`
                },
                data: data
            });

            const rateData = result?.data?.result;
            console.log('rate data', rateData);

            const updatedRateData = rateData.map((each) => ({
                ...each,
                errorAccountBase: selectedAccountBase?.toUpperCase()
            }));

            setRateData(prevState => [...prevState, ...updatedRateData]);

            setErrorMessage('');
        } catch (e) {
            console.log('error', e.response);
            if (e.message === 'Timeout') {
                console.error('API request timed out');
                // Handle timeout error here
            } else if (e.response.status === 403) {
                setErrorMessage(`${e.response.data.error}. Please contact op@uucargo.ca.`);
            } else if (e.response.status === 440) {
                setErrorMessage('The authentication token has expired.');
                deleteToken("access_token");
                setTimeout(() => window.location.reload(), 2000);
            }
        } finally {
            setLoading(false);
            setIsCallAllService(prevState => prevState + 1);
        }
    }

    const getRating = () => {

        try {
            getShippingPackageRate('canpar');
            getShippingPackageRate('ups');
            getShippingPackageRate('fedex');
            getShippingPackageRate('canadapost');
        } catch (e) {
            console.log('error ', e.response);
        }
    }

    useEffect(() => {
        if (orderData) {
            getRating();
        }
    }, [orderData]);

    useEffect(() => {
        // Step 1: Sort the array based on 'total'
        const sortedRate = [...rateData].sort((a, b) => (a?.finalTotalAmount?.value || Infinity) - (b?.finalTotalAmount?.value || Infinity));

        // Step 2: Find the 'FedEx Ground' object
        const fedexIndex = sortedRate.findIndex((item) => item.serviceName === 'Fedex Ground');

        if (fedexIndex !== -1 && fedexIndex !== 0) {
            // Step 4: Calculate the new 'total' for 'lowest'
            const lowestTotal = sortedRate[0]?.finalTotalAmount?.value * 1.1;
            const fedexTotal = sortedRate[fedexIndex]?.finalTotalAmount?.value;

            // Step 4: Check if 'FedEx Ground' is smaller than the lowest object
            if (fedexTotal < lowestTotal) {
                // Step 5: Swap positions of 'FedEx Ground' and the lowest object
                const updatedData = [...sortedRate];
                const temp = updatedData[fedexIndex];
                updatedData.splice(fedexIndex, 1);
                updatedData.unshift(temp);
                setRate(updatedData);
                updatedData[0]?.finalTotalAmount && setSelectedRate(updatedData[0]);
            } else {
                setRate(sortedRate);
                sortedRate[0]?.finalTotalAmount && setSelectedRate(sortedRate[0]);
            }
        } else {
            setRate(sortedRate);
            sortedRate[0]?.finalTotalAmount && setSelectedRate(sortedRate[0]);
        }
    }, [rateData]);

    useEffect(() => {
        // if (shipFrom?.country === shipTo?.country) {

        if (isCallAllService) {
            if (isCallAllService === 4) {
                setLoading(false);
            } else {
                setLoading(true);
            }
        } else {
            setLoading(false);
        }

    }, [isCallAllService])

    console.log('[BusinessReturnLabelRating] orderData', orderData);
    console.log('[BusinessReturnLabelRating] rateData', rateData);
    console.log('[BusinessReturnLabelRating] rate', rate);
    console.log('[BusinessReturnLabelRating] loading', loading);
    console.log('[BusinessReturnLabelRating] is call all service', isCallAllService);
    console.log('[BusinessReturnLabelRating] select rate', selectedRate);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box sx={{display: 'flex', width: '100%', justifyContent: 'flex-end', gap: '20px'}}>
                    <LoadingButton
                        size='large'
                        variant='contained'
                        sx={{
                            backgroundColor: '#F2BE22',
                            "&:hover": {
                                backgroundColor: '#F2BE22',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        onClick={handleStartOver}
                        loading={isCallAllService !== 4}
                    >
                        Back
                    </LoadingButton>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={styles.ShippingPackageCreateLabelRateRoot}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography style={styles.ShippingPackageCreateLabelRateHeading}>
                                {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.CHOOSE_CARRIER.CARRIER_TITLE')}
                            </Typography>
                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                Choose the best carrier for you based on price, delivery time and reliability
                            </Typography>
                        </Grid>
                        {
                            rate?.length > 0 ?
                                <>
                                    {
                                        !isMobile && <>
                                            <Grid item xs={2}>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Box sx={styles.ShippingPackageCreateLabelRateOtherBox}>
                                                    <Typography sx={styles.ShippingPackageCreateLabelRateSubHeading}>
                                                        {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.CHOOSE_CARRIER.CARRIER')}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Box sx={styles.ShippingPackageCreateLabelRateOtherBox}>
                                                    <Typography sx={styles.ShippingPackageCreateLabelRateSubHeading}>
                                                        {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.CHOOSE_CARRIER.TIME')}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Box sx={styles.ShippingPackageCreateLabelRateOtherBox}>
                                                    <Typography sx={styles.ShippingPackageCreateLabelRateSubHeading}>
                                                        {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.CHOOSE_CARRIER.PRICE')}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={2}>
                                            </Grid>
                                        </>
                                    }
                                    {
                                        rate?.map((each, index) => {
                                            // if (index >= 1) {
                                            return (
                                                <>
                                                    {
                                                        !each?.errorMessage ?
                                                            <Grid item key={index} xs={12}>
                                                                <Box sx={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    gap: '10px',
                                                                    marginBottom: index === 0 && "20px"
                                                                }}>
                                                                    {
                                                                        index === 0 && <Box sx={{
                                                                            display: 'flex',
                                                                            justifyContent: 'flex-start',
                                                                        }}>
                                                                            <Typography sx={{
                                                                                backgroundColor: '#1D8B45',
                                                                                color: '#FFFFFF',
                                                                                fontSize: '14px',
                                                                                padding: '5px 10px',
                                                                                borderRadius: '5px'
                                                                            }}>
                                                                                Recommendation
                                                                            </Typography>
                                                                        </Box>
                                                                    }
                                                                    <ButtonBase sx={{
                                                                        width: '100%',
                                                                        height: '100%',
                                                                        padding: '20px 0',
                                                                        borderRadius: '5px',
                                                                        border: each === selectedRate ? '3px solid #609966' : '1px solid #000000',
                                                                        backgroundColor: index === 0 && '#F3FDE8'
                                                                    }}
                                                                                onClick={() => handleSelect(each)}
                                                                                disabled={loading}
                                                                    >
                                                                        <Box sx={{
                                                                            display: 'flex',
                                                                            flexDirection: 'column',
                                                                            width: '100%',
                                                                            height: '100%',
                                                                        }}>
                                                                            <Grid container spacing={2}>
                                                                                <Grid item md={2} xs={12}>
                                                                                    <Box
                                                                                        sx={styles?.ShippingPackageCreateLabelRateImageBox}>
                                                                                        <img
                                                                                            src={mapCarrierLogo(each?.accountBase)}
                                                                                            alt="logo"
                                                                                            width='60px'/>
                                                                                    </Box>
                                                                                </Grid>
                                                                                <Grid item md={2} xs={12}>
                                                                                    <Box sx={
                                                                                        isMobile ?
                                                                                            styles?.ShippingPackageCreateLabelRateOtherBoxMobile :
                                                                                            styles?.ShippingPackageCreateLabelRateOtherBox
                                                                                    }>
                                                                                        <Typography
                                                                                            style={styles?.ShippingPackageQuickQuoteRateListText}>
                                                                                            {each?.serviceName}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </Grid>
                                                                                <Grid item md={3} xs={12}>
                                                                                    <Box sx={
                                                                                        isMobile ?
                                                                                            styles?.ShippingPackageCreateLabelRateOtherBoxMobile :
                                                                                            styles?.ShippingPackageCreateLabelRateOtherBox
                                                                                    }>
                                                                                        <Typography
                                                                                            style={styles.ShippingPackageQuickQuoteRateListText}>
                                                                                            {each?.shipRange} business
                                                                                            day(s)
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </Grid>
                                                                                <Grid item md={3} xs={12}>
                                                                                    <Box sx={
                                                                                        isMobile ?
                                                                                            styles?.ShippingPackageCreateLabelRateOtherBoxMobile :
                                                                                            styles?.ShippingPackageCreateLabelRateOtherBox
                                                                                    }>
                                                                                        <Box sx={{
                                                                                            display: 'flex',
                                                                                            gap: '5px',
                                                                                            alignItems: 'center'
                                                                                        }}>
                                                                                            <Typography style={{
                                                                                                fontSize: '16px',
                                                                                                fontWeight: '600',
                                                                                            }}>
                                                                                                $ {((+each?.totalCharge?.value) + (+each?.serviceFee?.value)).toFixed(2)}
                                                                                            </Typography>
                                                                                            <ClickAwayListener
                                                                                                onClickAway={() => handleClose(index)}>
                                                                                                <div>
                                                                                                    <LightTooltip
                                                                                                        open={open === index}
                                                                                                        PopperProps={{
                                                                                                            disablePortal: true,
                                                                                                        }}
                                                                                                        disableFocusListener
                                                                                                        disableHoverListener
                                                                                                        disableTouchListener
                                                                                                        onClose={() => handleClose(index)}
                                                                                                        // onOpen={() => handleOpen(index)}
                                                                                                        sx={{
                                                                                                            padding: '0',
                                                                                                            margin: '0'
                                                                                                        }}
                                                                                                        placement="right-start"
                                                                                                        title={
                                                                                                            <Box sx={{
                                                                                                                display: 'flex',
                                                                                                                flexDirection: 'column',
                                                                                                                width: '300px',
                                                                                                                gap: '15px'
                                                                                                            }}>
                                                                                                                <Grid
                                                                                                                    container
                                                                                                                    spacing={1}>
                                                                                                                    {
                                                                                                                        each?.shipmentCharges?.map((each, index) => {
                                                                                                                            const cost = +each?.value
                                                                                                                            if (cost !== 0) {
                                                                                                                                return (
                                                                                                                                    <Fragment
                                                                                                                                        key={index}>
                                                                                                                                        <Grid
                                                                                                                                            item
                                                                                                                                            xs={8}>
                                                                                                                                            <Typography
                                                                                                                                                style={{
                                                                                                                                                    fontSize: '12px',
                                                                                                                                                    textAlign: 'left'
                                                                                                                                                }}>
                                                                                                                                                {each?.description}
                                                                                                                                            </Typography>
                                                                                                                                        </Grid>
                                                                                                                                        <Grid
                                                                                                                                            item
                                                                                                                                            xs={4}>
                                                                                                                                            <Typography
                                                                                                                                                style={{
                                                                                                                                                    fontSize: '12px',
                                                                                                                                                    textAlign: 'right'
                                                                                                                                                }}>
                                                                                                                                                $ {(+each?.value)?.toFixed(2)}
                                                                                                                                            </Typography>
                                                                                                                                        </Grid>
                                                                                                                                    </Fragment>
                                                                                                                                )
                                                                                                                            }
                                                                                                                        })
                                                                                                                    }
                                                                                                                    {
                                                                                                                        (+each?.serviceFee?.value) > 0 &&
                                                                                                                        <>
                                                                                                                            <Grid
                                                                                                                                item
                                                                                                                                xs={8}>
                                                                                                                                <Typography
                                                                                                                                    style={{
                                                                                                                                        fontSize: '12px',
                                                                                                                                        textAlign: 'left'
                                                                                                                                    }}>
                                                                                                                                    Service
                                                                                                                                    Fee
                                                                                                                                </Typography>
                                                                                                                            </Grid>
                                                                                                                            <Grid
                                                                                                                                item
                                                                                                                                xs={4}>
                                                                                                                                <Typography
                                                                                                                                    style={{
                                                                                                                                        fontSize: '12px',
                                                                                                                                        textAlign: 'right'
                                                                                                                                    }}>
                                                                                                                                    $ {(+each?.serviceFee?.value).toFixed(2)}
                                                                                                                                </Typography>
                                                                                                                            </Grid>
                                                                                                                        </>
                                                                                                                    }
                                                                                                                    <Grid
                                                                                                                        item
                                                                                                                        xs={8}>
                                                                                                                        <Typography
                                                                                                                            style={{
                                                                                                                                fontSize: '12px',
                                                                                                                                textAlign: 'left',
                                                                                                                                color: '#1D8B45',
                                                                                                                                fontWeight: '600'
                                                                                                                            }}>
                                                                                                                            Total
                                                                                                                        </Typography>
                                                                                                                    </Grid>
                                                                                                                    <Grid
                                                                                                                        item
                                                                                                                        xs={4}>
                                                                                                                        <Typography
                                                                                                                            style={{
                                                                                                                                fontSize: '12px',
                                                                                                                                textAlign: 'right',
                                                                                                                                color: '#1D8B45',
                                                                                                                                fontWeight: '600'
                                                                                                                            }}>
                                                                                                                            $ {((+each?.totalCharge?.value) + (+each?.serviceFee?.value)).toFixed(2)}
                                                                                                                        </Typography>
                                                                                                                    </Grid>
                                                                                                                </Grid>
                                                                                                                {/*<Typography*/}
                                                                                                                {/*    style={{*/}
                                                                                                                {/*        fontSize: '12px',*/}
                                                                                                                {/*        textAlign: 'left'*/}
                                                                                                                {/*    }}>*/}
                                                                                                                {/*    For*/}
                                                                                                                {/*    domestic*/}
                                                                                                                {/*    shipments,*/}
                                                                                                                {/*    there*/}
                                                                                                                {/*    are*/}
                                                                                                                {/*    no*/}
                                                                                                                {/*    tax*/}
                                                                                                                {/*    and*/}
                                                                                                                {/*    duty*/}
                                                                                                                {/*    to*/}
                                                                                                                {/*    be*/}
                                                                                                                {/*    paid*/}
                                                                                                                {/*</Typography>*/}
                                                                                                            </Box>
                                                                                                        }
                                                                                                    >
                                                                                                        <IconButton
                                                                                                            onClick={() => handleOpen(index)}>
                                                                                                            <HelpIcon
                                                                                                                sx={{color: '#1D8B45'}}/>
                                                                                                        </IconButton>
                                                                                                    </LightTooltip>
                                                                                                </div>
                                                                                            </ClickAwayListener>
                                                                                        </Box>
                                                                                        {
                                                                                            each?.regularPriceExcludeTax?.value > ((+each?.totalCharge?.value) + (+each?.serviceFee?.value)) &&
                                                                                            <Typography style={{
                                                                                                fontSize: '14px',
                                                                                                textDecoration: 'line-through',
                                                                                            }}>
                                                                                                Reg.
                                                                                                ${each?.regularPriceExcludeTax?.value}
                                                                                            </Typography>
                                                                                        }
                                                                                    </Box>
                                                                                </Grid>
                                                                                <Grid item md={2} xs={12}>
                                                                                    <Box sx={
                                                                                        isMobile ?
                                                                                            styles?.ShippingPackageCreateLabelRateOtherBoxMobile :
                                                                                            styles?.ShippingPackageCreateLabelRateOtherBox
                                                                                    }>
                                                                                        {selectedRate === each ?
                                                                                            <Button
                                                                                                variant='contained'
                                                                                                size='large'
                                                                                                color='success'
                                                                                                disabled={loading}
                                                                                            >
                                                                                                {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.CHOOSE_CARRIER.SELECTED')}
                                                                                            </Button>
                                                                                            :
                                                                                            <Button
                                                                                                variant='outlined'
                                                                                                size='large'
                                                                                                color='success'
                                                                                                onClick={() => handleSelect(each)}
                                                                                                disabled={loading}
                                                                                            >
                                                                                                {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.CHOOSE_CARRIER.SELECT')}
                                                                                            </Button>
                                                                                        }
                                                                                    </Box>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Box>
                                                                    </ButtonBase>
                                                                </Box>
                                                            </Grid>
                                                            :
                                                            each?.statusCode === 200 &&
                                                            <Grid item key={index} xs={12}>
                                                                <Box sx={{
                                                                    padding: '20px 0',
                                                                    borderRadius: '5px',
                                                                    border: each === selectedRate ? '3px solid #609966' : '1px solid #000000',
                                                                }}>
                                                                    <Grid container spacing={4}>
                                                                        <Grid item md={2} xs={3}>
                                                                            <Box
                                                                                sx={styles?.ShippingPackageCreateLabelRateImageBox}>
                                                                                <img
                                                                                    src={mapCarrierLogo(each?.errorAccountBase)}
                                                                                    alt="logo"
                                                                                    width='60px'/>
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid item md={3} xs={9}>
                                                                            <Box
                                                                                sx={styles?.ShippingPackageCreateLabelRateOtherBox}>
                                                                                <Typography
                                                                                    style={styles?.ShippingPackageQuickQuoteRateListText}>
                                                                                    {each?.errorAccountBase}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid item md={7} xs={12}>
                                                                            <Box
                                                                                sx={styles?.ShippingPackageCreateLabelRateOtherBox}>
                                                                                <Typography
                                                                                    style={{
                                                                                        textAlign: 'left',
                                                                                        color: '#FF0303'
                                                                                    }}>
                                                                                    {each?.errorMessage}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Box>
                                                            </Grid>
                                                    }
                                                </>
                                            )
                                            // }
                                        })
                                    }
                                    <Grid item xs={12} md={12}>
                                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                                            <Typography style={{color: 'red'}}>
                                                *
                                            </Typography>
                                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.CHOOSE_CARRIER.MESSAGE')}
                                            </Typography>
                                            <GreenTooltip
                                                placement="right-start"
                                                title={<Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                    }}>
                                                    <Typography
                                                        style={{
                                                            fontSize: '12px',
                                                            textAlign: 'left',
                                                            paddingBottom: '10px'
                                                        }}>
                                                        Be aware carriers may charge extra for:
                                                    </Typography>
                                                    <Typography
                                                        style={{
                                                            fontSize: '12px',
                                                            textAlign: 'left'
                                                        }}>
                                                        -Unmarked residential addresses;
                                                    </Typography>
                                                    <Typography
                                                        style={{
                                                            fontSize: '12px',
                                                            textAlign: 'left'
                                                        }}>
                                                        -Incorrect dimensions/weight;
                                                    </Typography>
                                                    <Typography
                                                        style={{
                                                            fontSize: '12px',
                                                            textAlign: 'left'
                                                        }}>
                                                        -Wrong freight class;
                                                    </Typography>
                                                    <Typography
                                                        style={{
                                                            fontSize: '12px',
                                                            textAlign: 'left'
                                                        }}>
                                                        -Multiple pickup tries;
                                                    </Typography>
                                                    <Typography
                                                        style={{
                                                            fontSize: '12px',
                                                            textAlign: 'left'
                                                        }}>
                                                        -Special handling packaging
                                                    </Typography>
                                                </Box>}
                                            >
                                                <IconButton>
                                                    <HelpIcon
                                                        sx={{color: '#1D8B45', fontSize: '20px'}}/>
                                                </IconButton>
                                            </GreenTooltip>
                                        </Box>
                                    </Grid>
                                </>
                                :
                                (isCallAllService !== 4) ?
                                    <Grid item xs={12}>
                                        <Box>
                                            <CircularProgress/>
                                        </Box>
                                    </Grid>
                                    :
                                    <Grid item xs={12}>
                                        <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                                            <Typography style={{color: 'red', textAlign: 'left', fontSize: '16px'}}>
                                                {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.ERROR_MESSAGE')}
                                            </Typography>
                                        </Box>
                                    </Grid>
                        }
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{display: 'flex', width: '100%', justifyContent: 'flex-end', gap: '20px'}}>
                    {
                        selectedRate && (selectedRate?.isCrossBoarder ?
                                <LoadingButton
                                    size='large'
                                    color="success"
                                    variant='contained'
                                    // disabled={!selectedRate}
                                    loading={isCallAllService !== 4}
                                    onClick={handleCrossBorderDetails}
                                >
                                    {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.CROSS_BORDER_DETAILS')}
                                </LoadingButton>
                                :
                                <LoadingButton
                                    size='large'
                                    color="success"
                                    variant='contained'
                                    // disabled={!selectedRate}
                                    loading={isCallAllService !== 4}
                                    onClick={handleOrderDetails}
                                >
                                    {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.ORDER_DETAILS')}
                                </LoadingButton>
                        )
                    }
                </Box>
            </Grid>
            {
                crossBorderDetails &&
                <Grid item xs={12}>
                    <BusinessReturnLabelCrossBorder orderData={orderData} selectedRate={selectedRate} tranId={tranId}/>
                </Grid>
            }
        </Grid>

    )
}