import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {useEffect, useState} from "react";
import Switch from "@mui/material/Switch";
import {styled} from '@mui/material/styles';
import {useDispatch, useSelector} from "react-redux";
import {updateMovingServiceAdditionalService} from "../../actions/movingServiceAction";
import intl from "react-intl-universal";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import {InputAdornment} from "@mui/material";
import {FormHelperText, Input} from "@material-ui/core";
import FormLabel from "@mui/material/FormLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@mui/material/TextField";

const styles = {
    MovingServiceAdditionalServiceRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '10px',
        display: 'flex',
        flexDirection: 'column',
    },
    MovingServiceAdditionalServiceHeading: {
        fontSize: '20px',
        fontWeight: '600'
    },
    MovingServiceAdditionalFeeHeading: {
        fontSize: '20px',
        fontWeight: '600'
    },

    MovingServiceAdditionalServiceSectionHeading: {
        fontSize: '16px',
        fontWeight: '600'
    },

    MovingServiceAdditionalServiceSection: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
    },

    MovingServiceAdditionalServiceSectionItem: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 30px',
        borderRadius: '10px'
    },
    smallInput: {
        "& .MuiInputBase-input": {
            height: "4px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
}

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({theme}) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

export const MovingServiceAdditionalService = () => {

    let additionalServiceReducer = useSelector(state => state?.movingService?.additionalService);

    console.log('additional service reducer', additionalServiceReducer);

    const [additionalService, setAdditionalService] = useState({
        scheduleDelivery: false,
        whiteGlove: false,
        packingService: false,
        wrappingService: false,
        assemblyService: false,
        disassemblyService: false,
        storageService: {
            cubicMeterSpace: 0,
            storageDays: 0
        }
    })
    const [scheduledDelivery, setScheduledDelivery] = useState(false);
    const [whiteGlove, setWhiteGlove] = useState(false);
    const [packingService, setPackingService] = useState(false);
    const [wrappingService, setWrappingService] = useState(false);
    const [assemblyService, setAssemblyService] = useState(false);
    const [disassemblyService, setDisassemblyService] = useState(false);

    const [isStorageService, setIsStorageService] = useState(false);
    const [storageService, setStorageService] = useState({
        cubicMeterSpace: 0,
        storageDays: 0
    });

    const dispatch = useDispatch();

    const handleScheduledDelivery = (event) => {
        setScheduledDelivery(event.target.checked);
        setAdditionalService(prevState => ({
            ...prevState, scheduleDelivery: event.target.checked
        }))
    }

    const handleWhiteGlove = (event) => {
        setWhiteGlove(event.target.checked);
        setAdditionalService(prevState => ({
            ...prevState, whiteGlove: event.target.checked
        }))
    }

    const handlePackingService = (event) => {
        setPackingService(event.target.checked);
        setAdditionalService(prevState => ({
            ...prevState, packingService: event.target.checked
        }))
    }

    const handleWrappingService = (event) => {
        setWrappingService(event.target.checked);
        setAdditionalService(prevState => ({
            ...prevState, wrappingService: event.target.checked
        }))
    }

    const handleAssemblyService = (event) => {
        setAssemblyService(event.target.checked);
        setAdditionalService(prevState => ({
            ...prevState, assemblyService: event.target.checked
        }))
    }

    const handleDisassemblyService = (event) => {
        setDisassemblyService(event.target.checked);
        setAdditionalService(prevState => ({
            ...prevState, disassemblyService: event.target.checked
        }))
    }

    const handleIsStorageService = (event) => {
        setIsStorageService(event.target.checked);

        if (!event.target.checked) {
            setStorageService({
                cubicMeterSpace: 0,
                storageDays: 0
            })
        }

    }
    const handleStorageSpace = (event) => {
        setStorageService(prevState => ({
            ...prevState, cubicMeterSpace: event.target.value
        }))
        setAdditionalService(prevState => ({
            ...prevState, storageService: {
                ...storageService,
                cubicMeterSpace: event.target.value
            }
        }))
    }

    const handleStorageDays = (event) => {
        setStorageService(prevState => ({
            ...prevState, storageDays: event.target.value
        }))
        setAdditionalService(prevState => ({
            ...prevState, storageService: {
                ...storageService,
                storageDays: event.target.value
            }
        }))
    }


    useEffect(() => {
        setScheduledDelivery(additionalServiceReducer.scheduleDelivery);
        setWhiteGlove(additionalServiceReducer.whiteGlove);
        setPackingService(additionalServiceReducer.packingService);
        setWrappingService(additionalServiceReducer.wrappingService);
        setAssemblyService(additionalServiceReducer.assemblyService);
        setDisassemblyService(additionalServiceReducer.disassemblyService);
        setStorageService(additionalServiceReducer.storageService);
        setAdditionalService(additionalServiceReducer);
    }, []);

    useEffect(() => {
        dispatch(updateMovingServiceAdditionalService(additionalService));
    }, [additionalService])

    console.log('additional service', additionalService);

    return (
        <Box sx={styles.MovingServiceAdditionalServiceRoot}>
            <Typography sx={styles.MovingServiceAdditionalServiceHeading}>
                Additional Service
            </Typography>
            <Grid container spacing={0}>
                <>
                    <Grid item xs={6}>
                        <Typography sx={styles.MovingServiceAdditionalServiceSectionHeading}>
                            Scheduled Delivery:&nbsp;
                            <span style={{ fontSize: "12px" }}>You can pickup at our warehouse by yourself.</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <IOSSwitch
                            sx={{m: 1}}
                            checked={scheduledDelivery}
                            onChange={handleScheduledDelivery}
                        />
                    </Grid>
                </>

                <>
                    <Grid item xs={6}>
                        <Typography sx={styles.MovingServiceAdditionalServiceSectionHeading}>
                            White Glove
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <IOSSwitch
                            sx={{m: 1}}
                            checked={whiteGlove}
                            onChange={handleWhiteGlove}
                        />
                    </Grid>
                </>

                <>
                    <Grid item xs={6}>
                        <Typography sx={styles.MovingServiceAdditionalServiceSectionHeading}>
                            Packing Service
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <IOSSwitch
                            sx={{m: 1}}
                            checked={packingService}
                            onChange={handlePackingService}
                        />
                    </Grid>
                </>
                <>
                    <Grid item xs={6}>
                        <Typography sx={styles.MovingServiceAdditionalServiceSectionHeading}>
                            Wrapping Service
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <IOSSwitch
                            sx={{m: 1}}
                            checked={wrappingService}
                            onChange={handleWrappingService}
                        />
                    </Grid>
                </>

                <>
                    <Grid item xs={6}>
                        <Typography sx={styles.MovingServiceAdditionalServiceSectionHeading}>
                            Assembly Service
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <IOSSwitch
                            sx={{m: 1}}
                            checked={assemblyService}
                            onChange={handleAssemblyService}
                        />
                    </Grid>
                </>

                <>
                    <Grid item xs={6}>
                        <Typography sx={styles.MovingServiceAdditionalServiceSectionHeading}>
                            Disassembly Service
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <IOSSwitch
                            sx={{m: 1}}
                            checked={disassemblyService}
                            onChange={handleDisassemblyService}
                        />
                    </Grid>
                </>

                <>
                    <Grid item xs={6}>
                        <Typography sx={styles.MovingServiceAdditionalServiceSectionHeading}>
                            Storage Service
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <IOSSwitch
                            sx={{m: 1}}
                            checked={isStorageService}
                            onChange={handleIsStorageService}
                        />
                    </Grid>
                </>
                {
                    isStorageService && (
                        <>
                            <Grid item xs={6}>
                                <Typography sx={styles.MovingServiceAdditionalServiceSectionHeading}>
                                    How much storage space do you need?
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl sx={{ marginLeft: 2, maxWidth: "10ch" }} size="small">
                                    <Input
                                        id="outlined-adornment-weight"
                                        endAdornment={<InputAdornment position="end">m³</InputAdornment>}
                                        aria-describedby="outlined-weight-helper-text"
                                        inputProps={{
                                        }}
                                        type="number"
                                        className={styles.smallInput}
                                        defaultValue={0}
                                        value={storageService.cubicMeterSpace}
                                        onChange={handleStorageSpace}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography sx={styles.MovingServiceAdditionalServiceSectionHeading}>
                                    How long do you need your storage for?
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl sx={{ marginLeft: 2, maxWidth: "10ch" }} size="small">
                                    <Input
                                        id="outlined-adornment-weight"
                                        endAdornment={<InputAdornment position="end">days</InputAdornment>}
                                        aria-describedby="outlined-weight-helper-text"
                                        inputProps={{
                                        }}
                                        type="number"
                                        className={styles.smallInput}
                                        defaultValue={0}
                                        value={storageService.storageDays}
                                        onChange={handleStorageDays}
                                    />
                                </FormControl>
                            </Grid>
                        </>
                    )
                }

                {
                    (packingService || wrappingService || assemblyService || disassemblyService) &&
                    <Grid item xs={12} md={12}>
                        <Typography style={{fontSize: '14px'}}>
                            {intl.get('COST.LIFT_FEE')}
                        </Typography>
                    </Grid>
                }
            </Grid>
        </Box>

    )
}