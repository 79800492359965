import { DRIVER_FETCHING_PACKAGES, DRIVER_SELECT_PACKAGE, DRIVER_STACK_PACKAGES } from '../actions/types'

const initialState = {
  driver_packageData: [],
  fetching: false,
  selected_package_number:''
}

export default function driver_packageReducer(state = initialState, action){
  switch(action.type){
    case DRIVER_STACK_PACKAGES:
      return {
        ...state,
        driver_packageData:[...state.driver_packageData, ...action.payload]
      }
    case DRIVER_SELECT_PACKAGE:
      return {
        ...state,
        selected_package_number: action.selected_package_number
      }
    case DRIVER_FETCHING_PACKAGES:
      return {
        ...state,
        fetching: action.fetching
      }
    default:
      {
        return state
      }
  }
}