import {useState} from "react";
import {generateRandomString} from "../../../Utils/Helper";
import {
    addShippingLTLCrossBorderItemList,
    clearShippingLTLCrossBorderItemList
} from "../../../actions/shippingLTLAction";
import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import {ReactComponent as CrossBorderIcon} from "../../../images/NewDesignIcon/CustomsDeclaration.svg";
import Typography from "@mui/material/Typography";
import {Divider, Snackbar, Stack, Switch} from "@mui/material";
import intl from "react-intl-universal";
import IconButton from "@mui/material/IconButton";
import addPackage from "../../../images/Create Label.png";
import {LTLCrossBorderDashboardItemListEach} from "./LTLCrossBorderDashboardItemListEach";
import Button from "@mui/material/Button";
import * as React from "react";
import {styled} from "@mui/material/styles";
import {useDispatch, useSelector} from "react-redux";
import {getAccessToken} from "../../../Utils/doToken";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {TIME_OUT} from "../../../Utils/consInfo";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingPackageCrossBorderRoot: {
        backgroundColor: '#FFFFFF',
        // padding: '20px',
        // marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        // margin: '20px 0'
    },

    ShippingPackageCrossBorderHeading: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'left'
    },

    ShippingPackageCrossBorderSubHeading: {
        fontSize: '16px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelPackageSection: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '20px 20px 0 20px',
        alignItems: 'center'
    },
    ShippingPackageCreateLabelPackageSubSection: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    ShippingPackageCreateLabelRateListContent: {
        backgroundColor: '#FFFFFF',
        padding: '0 40px',
        marginBottom: '40px',
        borderRadius: '10px',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
}

const AntSwitch = styled(Switch)(({theme}) => ({
    width: 32,
    height: 20,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 20,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#609966' : '#609966',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 16,
        height: 16,
        borderRadius: 8,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 20 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

const requiredProperties = ["quantity", "weight", "weightUnit", "UOM", "currency", "priceEach", "hscode", "originCountryCode"];
const validateRequiredProperties = ["quantity", "weight", "priceEach"];

export const LTLCrossBorderDashboardPackingForm = ({
                                                       closeCreatePacking,
                                                       orderData,
                                                       getOrderData
                                                   }) => {
    const dispatch = useDispatch();
    const token = getAccessToken("access_token");

    let itemListRedux = useSelector(state => state?.shippingLTL?.crossBorderItemList);

    const [validation, setValidation] = useState(true);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [itemId, setItemId] = useState(1);
    const [metric, setMetric] = useState(false);

    const generatePackingListWithPackagesForBackendOrder = async () => {
        setLoading(true);

        let requestURL = `${PARTNER_URI}/packingList/addPackingListWithPackages`;
        const itemId = generateRandomString(4);

        let data = {
            packingListName: `${itemId}-Cross Border LTL Order`,
            packingListFileURI: "",
            backendOrderId: orderData.id,
            packageData: itemListRedux
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data,
                timeout: TIME_OUT
            })
            console.log('[generatePackingListWithPackagesForBackendOrder] result', result);
            setErrorMessage('');
            setSuccessMessage('Successfully create packing list');
            handleSuccessMessage();
            setTimeout(() => {
                handleClosePacking();
                getOrderData();
                // window.location.reload();
            }, 1000);
        } catch (e) {
            console.log('error', e?.response);
            setErrorMessage('Fail to create packing list');
            handleErrorMessage();
        } finally {
            setLoading(false);
        }
    }

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleMetric = (event) => {
        setMetric(event.target.checked);
    }

    const handleAddItem = () => {

        const itemId = generateRandomString(8);
        const newItem = {
            description: `item-${itemId}`,
            sku: '',
            weight: '',
            weightUnit: metric ? 'kg' : 'lb',
            UOM: 'BOX',
            hscode: null,
            originCountryCode: 'US',
            currency: 'USD',
            priceEach: '',
            quantity: '1',
            itemId: itemId
        }
        dispatch(addShippingLTLCrossBorderItemList(newItem));
        setItemId(prevState => prevState + 1);
    }

    const handleClosePacking = () => {
        closeCreatePacking();
        dispatch(clearShippingLTLCrossBorderItemList());
    }

    const validate = () => {
        setErrorMessage('');
        setToastOpen(false);
        const hasMissingValues = itemListRedux.some(object => requiredProperties.some((property) => !object[property]));
        const hasValidateValues = itemListRedux.some(object => validateRequiredProperties.some((property) => (object[property]) <= 0));

        itemListRedux?.length === 0 && setErrorMessage(prevState => prevState + intl.get('CROSS_BORDER.VALIDATION.ITEM_LIST_EMPTY'));
        hasMissingValues && setErrorMessage(prevState => prevState + intl.get('CROSS_BORDER.VALIDATION.ITEM_LIST_MISSING'));
        hasValidateValues && setErrorMessage(prevState => prevState + intl.get('CROSS_BORDER.VALIDATION.ITEM_LIST_ERROR'));

        if (!hasMissingValues && !hasValidateValues && itemListRedux.length !== 0) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const handleCreatePacking = async () => {
        const validationResult = validate();
        setValidation(validationResult);
        if (validationResult) {
            await generatePackingListWithPackagesForBackendOrder();
        }
    }

    return (
        <Box style={styles.ShippingPackageCrossBorderRoot}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={styles.ShippingPackageCreateLabelPackageSection}>
                        <Box sx={{
                            display: 'flex',
                            gap: '10px',
                        }}>
                            <CrossBorderIcon width={25} height={25}/>
                            <Typography style={styles.ShippingPackageCrossBorderHeading}>
                                Custom Declaration
                            </Typography>
                        </Box>
                        <Box sx={styles.ShippingPackageCreateLabelPackageSubSection}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <AntSwitch
                                    checked={metric}
                                    onChange={handleMetric}
                                />
                                <Typography>
                                    {intl.get('CROSS_BORDER.METRIC')}
                                </Typography>
                            </Stack>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <IconButton
                                    sx={{color: '#609966'}}
                                    onClick={handleAddItem}
                                >
                                    <img src={addPackage} width='35px'/>
                                </IconButton>
                                <Typography>
                                    {intl.get('CROSS_BORDER.ADD_NEW_BTN')}
                                </Typography>
                            </Stack>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={styles.ShippingPackageCreateLabelRateListContent}>
                        <Grid container spacing={2}>
                            {
                                itemListRedux?.length > 0 ? itemListRedux?.map((eachItem, index) =>
                                    <Grid item xs={12} key={index}>
                                        <LTLCrossBorderDashboardItemListEach
                                            itemInfo={eachItem}
                                            index={index}
                                            metric={metric}
                                            validation={validation}
                                        />
                                    </Grid>
                                ) : <Grid item xs={12}>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        border: '1px solid #B7B7B7',
                                        padding: '20px',
                                        borderRadius: '10px'
                                    }}>
                                        <Typography
                                            style={{
                                                fontSize: '20px',
                                                fontWeight: '600',
                                                color: '#7F8487',
                                                marginBottom: '10px'
                                            }}>
                                            {intl.get('CROSS_BORDER.EMPTY_ITEM_LIST')}
                                        </Typography>
                                        <IconButton
                                            sx={{color: '#609966'}}
                                            onClick={handleAddItem}
                                        >
                                            <img src={addPackage} width='35px'/>
                                        </IconButton>
                                        <Typography style={{fontSize: '12px', fontWeight: '600', color: '#609966'}}>
                                            {intl.get('CROSS_BORDER.ADD_NEW_TEXT')}
                                        </Typography>
                                    </Box>
                                </Grid>
                            }
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{display: 'flex', justifyContent: 'center', gap: '10px', paddingBottom: '10px'}}>
                        <Button
                            onClick={handleCreatePacking}
                            variant='contained'
                            sx={{
                                backgroundColor: '#1D8B45',
                                "&:hover": {
                                    backgroundColor: '#1D8B45',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                            // size='small'
                            loading={loading}
                        >
                            <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                                Create
                            </Typography>
                        </Button>
                        <Button
                            onClick={handleClosePacking}
                            variant='contained'
                            sx={{
                                backgroundColor: '#F2BE22',
                                "&:hover": {
                                    backgroundColor: '#F2BE22',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                        >
                            <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                                Close
                            </Typography>
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )

}