import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: "sans-serif",
  },
  "@keyframes loading": {
    "0%": {
      maxWidth: "0%",
    },
  },
  textContainer: {
    position: "relative",
    fontSize: "1rem",
    color: "rgba(0, 0, 0, .3)",
    textAlign: "right",
    "&::before": {
      content: "attr(data-text)",
      position: "absolute",
      overflow: "hidden",
      whiteSpace: "nowrap",
      color: "green",
      maxWidth: "100%",
      animation: `$loading 3s infinite`,
    },
  },
}));

export default function TextLoadingAnimation({ text }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <i>
        <h1 data-text={text} className={classes.textContainer}>
          {text}
        </h1>
      </i>
    </div>
  );
}
