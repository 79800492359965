import React, {useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {Button, Box, CircularProgress} from "@material-ui/core";
import {Alert, AlertTitle} from "@material-ui/lab";

import axios from "axios";
import {useSelector} from "react-redux";
import {CustomInput, PasswordChecker} from "../shared";
import {CHANGE_PASSWORD_API} from "../../Utils/apiUrl";
import {getHeaders, errHandling} from "../../Utils/fetchUtil";

const ChangePasswordPage = () => {
    const history = useHistory();
    const email = useSelector((state) => state.user.email);

    const [oldPassword, setOldPassword] = useState("");
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const [show, setShowStatus] = useState(false);
    const [passwordValid, setTotalValid] = useState(false);

    const [error, setError] = useState("");
    const [rstSuccess, setSuccessState] = useState(false);
    const [isChanging, setChangingStatus] = useState(false);

    const handleSetPassword = () => {
        if (!passwordValid) return;
        if (!oldPassword) {
            setError("Current password value is not valid");
            return;
        }
        if (password1 !== password2) {
            setError("Password mismatch");
            return;
        }
        setError("");
        const url = CHANGE_PASSWORD_API;
        const putObj = {
            old: oldPassword,
            new: password1,
            email: email,
        };
        setChangingStatus(true);
        axios({
            method: "put",
            url: url,
            data: JSON.stringify(putObj),
            headers: {
                ...getHeaders(),
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
            },
        })
            .then((response) => {
                setSuccessState(true);
                setChangingStatus(false);
            })
            .catch((error) => {
                // setError("Change failed");
                errHandling(error, setError);
                setChangingStatus(false);
            });
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            fontFamily="Montserrat"
            justifyContent="center"
            alignItems="center"
            mt={10}
            letterSpacing="1.5px"
        >
            <Box fontSize="3rem" color="seagreen" mb="5vh" textAlign="center">
                Change Password
            </Box>
            {rstSuccess ? (
                <Box fontSize="2rem" color="rgb(141, 173, 155)">
                    Successful change
                </Box>
            ) : (
                <>
                    <Box width="25vw" minWidth="300px">
                        <CustomInput
                            placeholderValue="Current Password"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                            type="password"
                        />
                    </Box>
                    <Box width="25vw" pt={4} minWidth="300px">
                        <CustomInput
                            placeholderValue="New Password"
                            value={password1}
                            onChange={(e) => setPassword1(e.target.value)}
                            type="password"
                            onFocus={() => !show && setShowStatus(true)}
                        />
                    </Box>
                    <Box minWidth="300px" width="25vw" p="0 14px" pt={1}>
                        {/* <Typography color="error">{error}</Typography> */}
                        <PasswordChecker
                            show={show}
                            inputValue={password1}
                            setTotalValid={setTotalValid}
                        />
                    </Box>
                    <Box width="25vw" pt={4} minWidth="300px" pb={4}>
                        <CustomInput
                            placeholderValue="Confirm New Password"
                            value={password2}
                            onChange={(e) => setPassword2(e.target.value)}
                            type="password"
                        />
                    </Box>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSetPassword}
                        disabled={isChanging}
                        style={{color: "white", fontFamily: "Montserrat", width: "15rem"}}
                    >
                        {isChanging ? <CircularProgress size={24}/> : "Save changes"}
                    </Button>
                </>
            )}
            {rstSuccess && (
                <Box pt={4}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => history.push("/profile")}
                        style={{color: "white", fontFamily: "Montserrat"}}
                    >
                        Go to previous page
                    </Button>
                </Box>
            )}
            <Box fontSize={rstSuccess ? "1.5rem" : "1rem"} mt="3vh">
                <Link to="/">Go to sign in page</Link>
            </Box>
            <Box pt="1vh">
                {error ? (
                    <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {error}
                    </Alert>
                ) : (
                    ""
                )}
            </Box>
        </Box>
    );
};

export default ChangePasswordPage;
