import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import {getAccessToken} from "../../Utils/doToken";
import axios from "axios";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Box from "@mui/material/Box";
import {MyBoxesListEach} from "./MyBoxesListEach";
import Typography from "@mui/material/Typography";

export const MyBoxesList =() => {

    const accessToken = getAccessToken("access_token");
    const [myBoxesList, setMyBoxesList] = useState([]);

    const getMyBoxesList = async () => {
        let requestURL = `${NODE_ROUTE_URI}/myBoxes/getMyBoxesListForEndUser`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            // console.log(result.data)
            setMyBoxesList(result.data)

        } catch (e) {
            console.log(e.response);

        }
    }

    useEffect(() => {
        getMyBoxesList();
    },[])

    // console.log('my boxes list', myBoxesList);

    return (
        <Box>
            {
                myBoxesList?.length === 0 ?
                    <Box>
                        <Typography variant='h5' style={{textAlign: 'center'}}>
                            Your box list of is empty
                        </Typography>
                    </Box>
                :

                myBoxesList?.map((box, index) => <MyBoxesListEach boxInfo={box} key={index}/>)}
        </Box>
    )
}