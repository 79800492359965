import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Paper,
  Grid,
  Typography,
  ButtonBase,
  TextField,
} from "@material-ui/core";
import {
  driverAddShipmentsToSummary,
  driverRemoveShipmentsFromSummary,
  driverClaimShipmentsFromSummary,
} from "../../actions/driverClaimShipmentAction";
import { useSelector } from "react-redux";
import store from "../../store/index";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  title: {
    color: "#111",
    fontFamily: "Helvetica Neue, sans-serif",
    "font-size": "30px",
    "font-weight": "bold",
    "letter-spacing": "-1px",
    "line-height": 1,
    margin: "5px",
  },
  paper: {
    margin: "5px 2%",
    width: "96%",
    maxWidth: "750px",
    backgroundColor: "#fdf5e8",
    padding: "3px",
    margin: "3px auto",
  },
  img: {
    margin: "auto",
    display: "block",
    maxHeight: "150px",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
  },
  blocks: {
    backgroundColor: "#fbfbf8",
    border: "1px solid #ded47c",
    borderRadius: "5px",
    margin: "5px",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
  },
  shipmentInfoWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

export default function DeliveredShipmentDetailLayout({
  sectionTitle,
  packageInfo,
  shipmentInfo,
}) {
  const classes = useStyles();
  const cardActive = useSelector(state => {
    return state.user.activeCard === "active";
  });
  const [disableTextField, setDisableTextField] = useState(false);
  const [quantity, setQuantity] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);

  const {
    available_count,
    height,
    cover_image,
    item_description,
    item_name,
    item_number,
    length,
    number,
    shipment_type,
    unit_price,
    weight,
    width,
    package_number,
  } = packageInfo;

  console.log("delivered shipments detail page, package", packageInfo);
  const {
    shipment_number,
    sender,
    sender_name,
    schedule_pickup,
    deliver_address,
    deliver_area,
    deliver_city,
    deliver_postal_code,
    deliver_province,
    driver_name,
    order_number,
    pickup_address,
    pickup_area,
    pickup_city,
    pickup_postal_code,
    pickup_province,
    shipping_status,
    status,
    tracking_number_count,
  } = shipmentInfo;

  const pickupDate = schedule_pickup.split(" ");

  useEffect(() => {
    if (available_count === 0) {
      setDisableTextField(true);
    } else {
      setDisableTextField(false);
    }
  }, [available_count]);

  const handleChange = (e, shipmentId, packageId, itemName, unitPrice) => {
    if (e.target.value === "") {
      setQuantity();
    }
    let number = Number(e.target.value);
    if (number > available_count) {
      number = available_count;
    } else if (number < 0) {
      number = 0;
    }

    setQuantity(number.toString());

    //calculate total price
    setTotalPrice(number * unit_price);

    store.dispatch(
      driverAddShipmentsToSummary(
        shipmentId,
        packageId,
        number,
        itemName,
        unitPrice
      )
    );
  };

  return (
    <>
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.title}>{sectionTitle}</Typography>
          </Grid>
          <Grid item container xs={12} className={classes.blocks}>
            <Grid item xs={12} className={classes.center}>
              <Typography variant="h6" style={{ textAlign: "center" }}>
                #{shipment_number}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                gutterBottom
                style={{ padding: "2px" }}
              >
                Pick up: {cardActive ? pickup_address : pickup_city}
              </Typography>
              <Typography variant="subtitle2" style={{ padding: "2px" }}>
                Delivery: {cardActive ? deliver_address : deliver_city}
              </Typography>
              <Typography variant="subtitle2" style={{ padding: "2px" }}>
                Pickup date: {pickupDate[0]}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            container
            xs={12}
            className={classes.blocks}
            style={{ justifyContent: "space-around" }}
          >
            <Grid item xs={12} className={classes.center}>
              <div style={{}}>
                <ButtonBase className={classes.image}>
                  <img
                    className={classes.img}
                    alt="package"
                    src={cover_image}
                  />
                </ButtonBase>
                <Typography variant="h6" style={{ textAlign: "center" }}>
                  {item_name}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} className={classes.shipmentInfoWrapper}>
              <Typography variant="subtitle2" gutterBottom>
                {item_description}
              </Typography>
              <Typography variant="subtitle2">
                length: {length}, width: {width}, height: {height}
              </Typography>
              <Typography variant="subtitle2">Weight: {weight}lb</Typography>
              <Typography variant="subtitle2">
                {shipment_type} x {tracking_number_count}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
