import React, { useState } from "react";
import {
  makeStyles,
  Button,
  Dialog,
  Backdrop,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  backdrop: {
    zIndex: 101,
    color: "#fff",
  },
}));

export default function FeedbackDialogLayout({
  openDialog,
  openBackDrop,
  title,
  content,
  shipmentNumbers,
  handleConfirm,
  handleCancel,
  buttonWords,
  loading,
  uploadImagesRes,
}) {
  const classes = useStyles();

  const handleYes = () => {
    handleConfirm();
  };

  const handleNo = () => {
    handleCancel();
  };

  return (
    <>
      {
        <>
          <Backdrop className={classes.backdrop} open={openBackDrop}></Backdrop>
          <Dialog
            open={openDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
          >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {content}
                {shipmentNumbers?.map((obj) => {
                  return (
                    <li key={obj.shipment_number} style={{ listStyle: "none" }}>
                      <i>{obj.shipment_number}</i>
                    </li>
                  );
                })}
              </DialogContentText>
              {/* {uploadImagesRes?.length !== 0 &&
                uploadImagesRes?.map(element => {
                  return (
                    <li key={element.name} style={{ listStyle: "none" }}>
                      Upload image: <i>{element.name} - </i>
                      {element.status === "success" ? (
                        <i style={{ color: "green" }}>{element.status}</i>
                      ) : (
                        <i style={{ color: "red" }}>{element.status}</i>
                      )}
                    </li>
                  );
                })} */}
              {loading && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress color="primary" />
                  <i>Processing.</i>
                </div>
              )}
            </DialogContent>
            {!loading && (
              <DialogActions>
                <Button onClick={() => handleYes()} color="primary" autoFocus>
                  {buttonWords[0]}
                </Button>
                <Button onClick={() => handleNo()} color="primary">
                  {buttonWords[1]}
                </Button>
              </DialogActions>
            )}
          </Dialog>
        </>
      }
    </>
  );
}
