import {ADDRESS_LIST_ADD, ADDRESS_LIST_EDIT, ADDRESS_LIST_PASS_ADDRESS_INFO, ADDRESS_LIST_SELECTED_TAB} from "./types";

export const BusinessSelectTab = tabValue => ({
    type: ADDRESS_LIST_SELECTED_TAB,
    payload: tabValue
})

export const BusinessAddAddress = (openModel) => ({
    type: ADDRESS_LIST_ADD,
    payload: openModel
})

export const BusinessEditAddress = (editModel) => ({
    type: ADDRESS_LIST_EDIT,
    payload: editModel
})

export const BusinessPassAddressInfo = (addressInfo) => ({
    type: ADDRESS_LIST_PASS_ADDRESS_INFO,
    payload: addressInfo
})
