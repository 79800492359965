import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import {Snackbar, TextField, useMediaQuery} from "@mui/material";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import {useDispatch, useSelector} from "react-redux";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {extractFullAddress, getMissingKeysForQuickQuotePalletContact, isValidatePhone} from "../../../Utils/Helper";
import Paper from "@mui/material/Paper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {useState} from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import {getAccessToken} from "../../../Utils/doToken";
import MuiAlert from "@mui/material/Alert";
import * as React from "react";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import intl from "react-intl-universal";
import Button from "@mui/material/Button";
import {
    updateShipFromPackageCreateLabelPackage,
    updateShippingPackageCreateLabelBatteryDetails,
    updateShippingPackageCreateLabelSignatureOptions, updateShipToPackageCreateLabelPackage
} from "../../../actions/BusinessShippingPackageAction";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingPackageQuickQuotePlaceOrderSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    ShippingPackageQuickQuotePlaceOrderRoot: {
        marginTop: '20px'
    },
    ShippingPackageQuickQuotePlaceOrderTitle: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageQuickQuotePlaceHolderHeading: {
        fontSize: '20px',
        textAlign: 'left'
    },
    ShippingPackageQuickQuotePlaceOrderBox: {
        padding: '20px',
        // border: '1px solid #000000',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    },
    ShippingPackageQuickQuotePlaceOrderErrorText: {
        fontSize: '12px',
        color: "#FF0303"
    }
}

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "4px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

const requiredProperties = ["UOM", "currency", "priceEach", "hscode", "originCountryCode"];

export const BusinessShippingPackageQuickQuotePlaceOrder = ({rateInfo, loadingSelection}) => {

    const dispatch = useDispatch();

    const token = getAccessToken("access_token");
    const classes = useStyles();
    const history = useHistory();
    const isMobile = useMediaQuery('(max-width: 600px)');

    let signatureOptions = useSelector(state => state?.businessShippingPackage?.quickQuoteSignatureOptions);
    let batteryDetails = useSelector(state => state?.businessShippingPackage?.quickQuoteBatteryDetails);
    let packageListRedux = useSelector(state => state?.businessShippingPackage?.quickQuotePackageList);
    let shipFromRedux = useSelector(state => state?.businessShippingPackage?.quickQuoteShipFrom);
    let shipToRedux = useSelector(state => state?.businessShippingPackage?.quickQuoteShipTo);
    let crossBorder = useSelector(state => state?.businessShippingPackage?.quickQuoteCrossBorder);
    const userInfo = useSelector((state) => state.user);

    const [loading, setLoading] = useState(false);
    const [loadingLabel, setLoadingLabel] = useState(false);
    const [shipFrom, setShipFrom] = useState(shipFromRedux);
    const [shipTo, setShipTo] = useState(shipToRedux);
    const [shipFromMatch, setShipFromMatch] = useState(true);
    const [shipToMatch, setShipToMatch] = useState(true);

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const [shipFromAddressTwo, setShipFromAddressTwo] = useState('');
    const [shipToAddressTwo, setShipToAddressTwo] = useState('');
    const [refOrderNumber, setRefOrderNumber] = useState('');

    const [shipFromContact, setShipFromContact] = useState({
        name: '',
        company: '',
        phone: null,
        email: ''
    })

    const [shipToContact, setShipToContact] = useState({
        name: '',
        company: '',
        phone: null,
        email: ''
    })

    const [shipFromContactError, setShipFromContactError] = useState([]);
    const [shipToContactError, setShipToContactError] = useState([]);

    const [movingFromAddressPredictions, setMovingFromAddressPredictions] = useState([]);
    const [movingToAddressPredictions, setMovingToAddressPredictions] = useState([]);

    const [labelImage, setLabelImage] = useState();

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const googleMapsPlaceAutocomplete = async (inputValue, types, province) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: types
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log(predictions);

            const filterPredictions = predictions.filter(address => address?.structured_formatting?.secondary_text.includes(province));
            const updatedPredictions = filterPredictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            return updatedPredictions;
        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const handleInputShipFromAddress = async (event, value) => {
        setShipFrom(prevState => ({
            ...prevState,
            address: value
        }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'address', shipFromRedux?.province?.code);
        setMovingFromAddressPredictions(predictions);
    }

    const handleInputShipToAddress = async (event, value) => {
        setShipTo(prevState => ({
            ...prevState,
            address: value
        }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'address', shipToRedux?.province?.code);
        setMovingToAddressPredictions(predictions);
    }

    const handleChangeShipFromAddress = async (event, value) => {
        console.log('select', value);
        const place = await googleMapsPlaceDetails(value?.placeId);

        if (value) {
            const address = extractFullAddress(place);
            console.log('address', address);
            // if (address?.zip === shipFrom?.postalCode) {
            setShipFrom(prevState => ({
                ...prevState,
                address: value?.description,
                displayAddress: value
            }))
            // setShipFromMatch(true);
            // } else {
            //     setShipFromMatch(false);
            // }
        } else {
            setShipFrom(prevState => ({
                ...prevState,
                address: '',
                displayAddress: {
                    description: '',
                    placeId: '',
                    text: ''
                }
            }))
        }
    }

    const handleChangeShipToAddress = async (event, value) => {
        console.log('select', value);

        const place = await googleMapsPlaceDetails(value?.placeId);

        if (value) {
            const address = extractFullAddress(place);
            console.log('address', address);
            if (address?.zip === shipTo?.postalCode) {
                setShipTo(prevState => ({
                    ...prevState,
                    address: value?.description,
                    displayAddress: value
                }))
                setShipToMatch(true);
            } else {
                setShipToMatch(false);
            }
        } else {
            setShipTo(prevState => ({
                ...prevState,
                address: '',
                displayAddress: {
                    description: '',
                    placeId: '',
                    text: ''
                }
            }))
        }
    }

    const handleShipFromAddressTwo = (event) => {
        setShipFromAddressTwo(event.target.value);
    }

    const handleShipFromName = (event) => {
        setShipFromContact(prevState => ({
            ...prevState,
            name: event.target.value
        }))
    }

    const handleShipFromCompany = (event) => {
        setShipFromContact(prevState => ({
            ...prevState,
            company: event.target.value
        }))
    }

    const handleShipFromPhone = (event) => {
        const trimmedValue = event.target.value.replace(/\s/g, '');
        setShipFromContact(prevState => ({
            ...prevState,
            phone: trimmedValue
        }))
    }

    const handleShipFromEmail = (event) => {
        setShipFromContact(prevState => ({
            ...prevState,
            email: event.target.value
        }))
    }

    const handleShipToAddressTwo = (event) => {
        setShipToAddressTwo(event.target.value);
    }

    const handleShipToName = (event) => {
        setShipToContact(prevState => ({
            ...prevState,
            name: event.target.value
        }))
    }

    const handleShipToCompany = (event) => {
        setShipToContact(prevState => ({
            ...prevState,
            company: event.target.value
        }))
    }

    const handleShipToPhone = (event) => {
        const trimmedValue = event.target.value.replace(/\s/g, '');
        setShipToContact(prevState => ({
            ...prevState,
            phone: trimmedValue
        }))
    }

    const handleShipToEmail = (event) => {
        setShipToContact(prevState => ({
            ...prevState,
            email: event.target.value
        }))
    }

    const handleRefOrderNumber = (event) => {
        setRefOrderNumber(event.target.value)
    }

    const partnerGetLabel = async () => {
        setLoadingLabel(true);
        let requestURL = `${PARTNER_URI}/loose-item/3rd-party/getSampleLabelByAccountBaseAndService`;

        let data = {
            accountBase: rateInfo?.accountBase,
            serviceType: rateInfo?.serviceType,
            shipFromName: shipFromContact?.name,
            shipFromCompanyName: shipFromContact?.company,
            shipFromPhone: shipFromContact?.phone?.replace(/[^a-zA-Z0-9]/g, ''),
            shipFromEmail: shipFromContact?.email,
            shipFromAddress: shipFrom?.displayAddress?.text,
            shipFromCity: shipFrom?.city,
            shipFromProvince: shipFrom?.province?.code,
            shipFromCountry: shipFrom?.country?.code,
            shipFromPostalCode: shipFrom?.postalCode.replace(/\s+/g, '').toUpperCase(),
            shipToName: shipToContact?.name,
            shipToCompanyName: shipToContact?.company,
            shipToPhone: shipToContact?.phone?.replace(/[^a-zA-Z0-9]/g, ''),
            shipToEmail: shipToContact?.email,
            shipToAddress: shipTo?.displayAddress?.text,
            shipToAddressTwo: shipToAddressTwo,
            shipToCity: shipTo?.city,
            shipToProvince: shipTo?.province?.code,
            shipToPostalCode: shipTo?.postalCode?.replace(/\s+/g, '').toUpperCase(),
            shipToCountry: shipTo?.country?.code,
            cargoControlNumber: "",
            refOrderNumber: refOrderNumber,
            packageData: packageListRedux
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            })

            console.log('result', result?.data?.result);

            setLabelImage(result?.data?.result);

            setLoadingLabel(false);

        } catch (e) {

            console.log('error', e);
            setLoadingLabel(false);

        }

        console.log('data', data)

    }

    const partnerBuyShipping = async () => {

        setLoading(true);
        let requestURL = `${PARTNER_URI}/loose-item/3rd-party/partner/shipping`;

        let data = {
            account_base_combine_name: rateInfo?.accountBaseCombineName,
            service_type: rateInfo?.serviceType,
            user_email: userInfo?.email,
            pickup_address: shipFrom?.displayAddress?.text,
            pickup_area: shipFrom?.city,
            deliver_address: shipTo?.displayAddress?.text,
            deliver_area: shipTo?.city,
            cargo_control_no: "",
            ref_order_no: refOrderNumber,
            deliver_address_two: shipToAddressTwo,
            schedule_pickup: "2021-09-27 09:29",
            pickup_address_obj: {
                address: shipFrom?.displayAddress?.text,
                addressLineTwo: shipFromAddressTwo,
                city: shipFrom?.city,
                postal_code: shipFrom?.postalCode.replace(/\s+/g, '').toUpperCase(),
                province: shipFrom?.province?.code,
                companyName: shipFromContact?.company,
                country: shipFrom?.country?.code,
                name: shipFromContact?.name,
                email: shipFromContact?.email
            },
            pickup_phone: shipFromContact?.phone?.replace(/[^a-zA-Z0-9]/g, ''),
            pickup_email: shipFromContact?.email,
            deliver_address_obj: {
                address: shipTo?.displayAddress?.text,
                city: shipTo?.city,
                addressLineTwo: shipToAddressTwo,
                postal_code: shipTo?.postalCode.replace(/\s+/g, '').toUpperCase(),
                province: shipTo?.province?.code,
                companyName: shipToContact?.company,
                country: shipTo?.country?.code,
                name: shipToContact?.name,
                email: shipToContact?.email
            },
            delivery_phone: shipToContact?.phone?.replace(/[^a-zA-Z0-9]/g, ''),
            deliver_email: shipToContact?.email,
            shipmentType: shipFrom.country?.code === shipTo.country?.code ? null : crossBorder?.shipmentType,
            transMode: shipFrom.country?.code === shipTo.country?.code ? null : crossBorder?.tranMode,
            package_data: packageListRedux
        }

        try {

            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            })

            console.log('result', result.data);
            setLoading(false);

            if (result?.data?.shippingResData[0]?.tranStatus === 1) {
                setErrorMessage('');
                handleSuccessMessage();
                setSuccessMessage('Successfully place order.');
                setTimeout(() => {
                    history.push(`/package-order-dashboard/${result?.data?.shippingOrderData?.orderId}`);

                }, 2000);
            } else {
                handleErrorMessage();
                setErrorMessage(`Fail to place order.${result?.data?.shippingResData[0]?.tranMessage}`);
            }

        } catch (e) {
            console.log(e.response);
            setLoading(false);
            handleErrorMessage();
            setErrorMessage(`Fail to place order. ${e.response?.data?.message || 'Please try again later.'}`);
        }
    }

    const CrossBorderHasMissingValues = () => {
        if (shipFrom?.country?.code === shipTo?.country?.code) {
            return false
        } else {
            const result = packageListRedux.some(object => requiredProperties.some((property) => !object[property]));
            console.log('result', result);
            return result
        }
    }

    const validate = () => {
        setErrorMessage('');
        setToastOpen(false);
        const hasMissingValues = CrossBorderHasMissingValues();
        const shipFromContactMissing = getMissingKeysForQuickQuotePalletContact(shipFromContact);
        const shipToContactMissing = getMissingKeysForQuickQuotePalletContact(shipToContact);
        const shipFromPhoneValidate = isValidatePhone(shipFromContact?.phone);
        const shipToPhoneValidate = isValidatePhone(shipToContact?.phone);

        setShipFromContactError(shipFromContactMissing);
        setShipToContactError(shipToContactMissing);

        shipFromContactMissing?.length !== 0 && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.SHIP_FROM_CONTACT_MISSING'));
        shipToContactMissing?.length !== 0 && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.SHIP_TO_CONTACT_MISSING'));
        hasMissingValues && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.PACKAGE_LIST_CROSS_BORDER'));
        !shipFromPhoneValidate && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.SHIP_FROM_PHONE'));
        !shipToPhoneValidate && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.SHIP_TO_PHONE'));

        if (shipFromContactMissing.length === 0 &&
            shipToContactMissing.length === 0 &&
            shipFrom?.address &&
            shipTo?.address &&
            shipFromPhoneValidate &&
            shipToPhoneValidate &&
            !hasMissingValues
        ) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const handleGetLabel = () => {
        const validationResult = validate();
        if (validationResult) {
            console.log('call api');
            setLabelImage(null);
            partnerGetLabel();
        }
    }

    const handlePlaceOrder = () => {
        const validationResult = validate();
        if (validationResult) {
            console.log('call api');
            partnerBuyShipping();
        }
    }

    const handlePlaceCrossBorderOrder = () => {

        dispatch(updateShipFromPackageCreateLabelPackage({
            postalCode: shipFromRedux?.postalCode,
            province: shipFromRedux?.province,
            city: shipFromRedux?.city || shipFromRedux?.region,
            address: shipFromRedux?.address,
            country: shipFromRedux?.country,
            region: shipFromRedux?.region,
            displayPostalCode: shipFromRedux?.displayPostalCode,
            displayAddress: shipFromRedux?.displayAddress
        }));
        dispatch(updateShipToPackageCreateLabelPackage({
            postalCode: shipToRedux?.postalCode,
            province: shipToRedux?.province,
            city: shipToRedux?.city || shipToRedux?.region,
            address: shipToRedux?.address,
            country: shipToRedux?.country,
            region: shipToRedux?.region,
            displayPostalCode: shipToRedux?.displayPostalCode,
            displayAddress: shipToRedux?.displayAddress
        }));
        dispatch(updateShippingPackageCreateLabelSignatureOptions(signatureOptions));
        dispatch(updateShippingPackageCreateLabelBatteryDetails(batteryDetails));

        history.push({
            pathname: '/business/shipping-package/create-label',
            state: {
                // shipFrom: shipFromRedux,
                // shipTo: shipToRedux,
                packageList: packageListRedux,
                // batteryDetails: batteryDetails,
                // signatureOptions: signatureOptions
            }
        });
        window.scrollTo(0, 0); // Scroll to the top
    }

    console.log('ship from', shipFrom);
    // console.log('ship from two', shipFromAddressTwo);
    // console.log('ship from contact', shipFromContact);
    // console.log('ship to', shipTo);
    // console.log('ship to two', shipToAddressTwo);
    // console.log('ship to contact', shipToContact);

    console.log('rate info', rateInfo);
    // console.log('user info', userInfo);

    // console.log('cross border', crossBorder);

    return (
        <Box sx={styles.ShippingPackageQuickQuotePlaceOrderRoot}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            {/*{*/}
            {/*    rateInfo?.isCrossBoarder ?*/}
            <Box sx={{display: 'flex', width: '100%', justifyContent: 'flex-end', gap: '20px'}}>
                <LoadingButton
                    variant='contained'
                    color='success'
                    size='large'
                    onClick={handlePlaceCrossBorderOrder}
                    disabled={loadingSelection}
                    // // disabled={packageListRedux.length === 0 ? true : false}
                >
                    {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.PLACE_CROSS_BORDER_ORDER')}
                </LoadingButton>
            </Box>
            {/*        :*/}
            {/*        <>*/}
            {/*            <Grid container spacing={2}>*/}
            {/*                <Grid item xs={12}>*/}
            {/*                    <Typography sx={styles.ShippingPackageQuickQuotePlaceOrderTitle}>*/}
            {/*                        {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDITION_INFO.ADDITIONAL_INFORMATION')}*/}
            {/*                    </Typography>*/}
            {/*                </Grid>*/}
            {/*                <Grid item xs={12}>*/}
            {/*                    <Box sx={styles.ShippingPackageQuickQuotePlaceOrderBox}>*/}
            {/*                        <Grid container spacing={2}>*/}
            {/*                            <Typography style={styles.ShippingPackageQuickQuotePlaceHolderHeading}>*/}
            {/*                                {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDITION_INFO.REFERENCE')}*/}
            {/*                            </Typography>*/}
            {/*                            <Grid item xs={12}>*/}
            {/*                                <Box sx={styles.ShippingPackageQuickQuotePlaceOrderSection}>*/}
            {/*                                    <InputLabel>*/}
            {/*                                        {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDITION_INFO.REFERENCE')}*/}
            {/*                                    </InputLabel>*/}
            {/*                                    <TextField*/}
            {/*                                        value={refOrderNumber}*/}
            {/*                                        variant="outlined"*/}
            {/*                                        onInput={handleRefOrderNumber}*/}
            {/*                                        size='small'*/}
            {/*                                    />*/}
            {/*                                </Box>*/}
            {/*                            </Grid>*/}
            {/*                        </Grid>*/}
            {/*                    </Box>*/}
            {/*                </Grid>*/}
            {/*                <Grid item xs={12} md={6}>*/}
            {/*                    <Box sx={styles.ShippingPackageQuickQuotePlaceOrderBox}>*/}
            {/*                        <Grid container spacing={2}>*/}
            {/*                            <Typography style={styles.ShippingPackageQuickQuotePlaceHolderHeading}>*/}
            {/*                                {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDITION_INFO.SHIP_FROM')}*/}
            {/*                            </Typography>*/}
            {/*                            <Grid item xs={12}>*/}
            {/*                                <Box sx={styles.ShippingPackageQuickQuotePlaceOrderSection}>*/}
            {/*                                    <InputLabel required>*/}
            {/*                                        {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDITION_INFO.ADDRESS')}*/}
            {/*                                    </InputLabel>*/}
            {/*                                    <Autocomplete*/}
            {/*                                        value={shipFrom?.displayAddress}*/}
            {/*                                        options={movingFromAddressPredictions}*/}
            {/*                                        getOptionLabel={option => option?.description}*/}
            {/*                                        onInputChange={handleInputShipFromAddress}*/}
            {/*                                        onChange={handleChangeShipFromAddress}*/}
            {/*                                        fullWidth*/}
            {/*                                        PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}*/}
            {/*                                        renderInput={params => <TextField*/}
            {/*                                            {...params}*/}
            {/*                                            variant='outlined'*/}
            {/*                                            className={classes.smallInput}*/}
            {/*                                        />}*/}
            {/*                                        renderOption={(option) => (*/}
            {/*                                            <Typography*/}
            {/*                                                style={{fontSize: '12px'}}*/}
            {/*                                            >*/}
            {/*                                                /!*<Typography>*!/*/}
            {/*                                                {option.description}*/}
            {/*                                                /!*</Typography>*!/*/}
            {/*                                            </Typography>*/}
            {/*                                        )}*/}
            {/*                                    />*/}
            {/*                                    <Typography style={styles.ShippingPackageQuickQuotePlaceOrderErrorText}>*/}
            {/*                                        {*/}
            {/*                                            !shipFrom?.address ? intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDITION_INFO.REQUIRED') : ""*/}
            {/*                                            // shipFromMatch ? "" : 'The address provided does not match the provided postal code.'*/}
            {/*                                        }*/}
            {/*                                    </Typography>*/}
            {/*                                </Box>*/}
            {/*                            </Grid>*/}
            {/*                            <Grid item xs={12}>*/}
            {/*                                <Box sx={styles.ShippingPackageQuickQuotePlaceOrderSection}>*/}
            {/*                                    <InputLabel>*/}
            {/*                                        {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDITION_INFO.ADDRESS_2')}*/}
            {/*                                    </InputLabel>*/}
            {/*                                    <TextField*/}
            {/*                                        value={shipFromAddressTwo}*/}
            {/*                                        fullWidth*/}
            {/*                                        variant="outlined"*/}
            {/*                                        onInput={handleShipFromAddressTwo}*/}
            {/*                                        size='small'*/}
            {/*                                    />*/}
            {/*                                </Box>*/}
            {/*                            </Grid>*/}
            {/*                            <Grid item md={6} xs={12}>*/}
            {/*                                <Box sx={styles.ShippingPackageQuickQuotePlaceOrderSection}>*/}
            {/*                                    <InputLabel required>*/}
            {/*                                        {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDITION_INFO.NAME')}*/}
            {/*                                    </InputLabel>*/}
            {/*                                    <TextField*/}
            {/*                                        value={shipFromContact?.name}*/}
            {/*                                        fullWidth*/}
            {/*                                        variant="outlined"*/}
            {/*                                        onInput={handleShipFromName}*/}
            {/*                                        size='small'*/}
            {/*                                    />*/}
            {/*                                    <Typography style={styles.ShippingPackageQuickQuotePlaceOrderErrorText}>*/}
            {/*                                        {*/}
            {/*                                            shipFromContactError.includes('name') ? intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDITION_INFO.REQUIRED') : ""*/}
            {/*                                        }*/}
            {/*                                    </Typography>*/}
            {/*                                </Box>*/}
            {/*                            </Grid>*/}
            {/*                            <Grid item md={6} xs={12}>*/}
            {/*                                <Box sx={styles.ShippingPackageQuickQuotePlaceOrderSection}>*/}
            {/*                                    <InputLabel required>*/}
            {/*                                        {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDITION_INFO.PHONE')}*/}
            {/*                                    </InputLabel>*/}
            {/*                                    <TextField*/}
            {/*                                        value={shipFromContact?.phone}*/}
            {/*                                        fullWidth*/}
            {/*                                        variant="outlined"*/}
            {/*                                        onInput={handleShipFromPhone}*/}
            {/*                                        size='small'*/}
            {/*                                    />*/}
            {/*                                    <Typography style={styles.ShippingPackageQuickQuotePlaceOrderErrorText}>*/}
            {/*                                        {*/}
            {/*                                            shipFromContactError.includes('phone') ? intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDITION_INFO.REQUIRED') :*/}
            {/*                                                shipFromContact?.phone === null ? "" :*/}
            {/*                                                    isValidatePhone(shipFromContact?.phone) ? "" :*/}
            {/*                                                        intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDITION_INFO.VALID_PHONE')*/}
            {/*                                        }*/}
            {/*                                    </Typography>*/}
            {/*                                </Box>*/}
            {/*                            </Grid>*/}
            {/*                            <Grid item md={6} xs={12}>*/}
            {/*                                <Box sx={styles.ShippingPackageQuickQuotePlaceOrderSection}>*/}
            {/*                                    <InputLabel>*/}
            {/*                                        {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDITION_INFO.COMPANY')}*/}
            {/*                                    </InputLabel>*/}
            {/*                                    <TextField*/}
            {/*                                        value={shipFromContact?.company}*/}
            {/*                                        fullWidth*/}
            {/*                                        variant="outlined"*/}
            {/*                                        onInput={handleShipFromCompany}*/}
            {/*                                        size='small'*/}
            {/*                                    />*/}
            {/*                                </Box>*/}
            {/*                            </Grid>*/}
            {/*                            <Grid item md={6} xs={12}>*/}
            {/*                                <Box sx={styles.ShippingPackageQuickQuotePlaceOrderSection}>*/}
            {/*                                    <InputLabel>*/}
            {/*                                        {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDITION_INFO.EMAIL')}*/}
            {/*                                    </InputLabel>*/}
            {/*                                    <TextField*/}
            {/*                                        value={shipFromContact?.email}*/}
            {/*                                        fullWidth*/}
            {/*                                        variant="outlined"*/}
            {/*                                        onInput={handleShipFromEmail}*/}
            {/*                                        placeholder={intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDITION_INFO.RECOMMEND')}*/}
            {/*                                        size='small'*/}
            {/*                                    />*/}
            {/*                                </Box>*/}
            {/*                            </Grid>*/}
            {/*                        </Grid>*/}
            {/*                    </Box>*/}
            {/*                </Grid>*/}
            {/*                <Grid item xs={12} md={6}>*/}
            {/*                    <Box sx={styles.ShippingPackageQuickQuotePlaceOrderBox}>*/}
            {/*                        <Grid container spacing={2}>*/}
            {/*                            <Typography style={styles.ShippingPackageQuickQuotePlaceHolderHeading}>*/}
            {/*                                {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDITION_INFO.SHIP_TO')}*/}
            {/*                            </Typography>*/}
            {/*                            <Grid item xs={12}>*/}
            {/*                                <Box sx={styles.ShippingPackageQuickQuotePlaceOrderSection}>*/}
            {/*                                    <InputLabel required>*/}
            {/*                                        {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDITION_INFO.ADDRESS')}*/}
            {/*                                    </InputLabel>*/}
            {/*                                    <Autocomplete*/}
            {/*                                        value={shipTo?.displayAddress}*/}
            {/*                                        options={movingToAddressPredictions}*/}
            {/*                                        getOptionLabel={option => option?.description}*/}
            {/*                                        onInputChange={handleInputShipToAddress}*/}
            {/*                                        onChange={handleChangeShipToAddress}*/}
            {/*                                        fullWidth*/}
            {/*                                        PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}*/}
            {/*                                        renderInput={params => <TextField*/}
            {/*                                            {...params}*/}
            {/*                                            variant='outlined'*/}
            {/*                                            className={classes.smallInput}*/}
            {/*                                        />}*/}
            {/*                                        renderOption={(option) => (*/}
            {/*                                            <Typography*/}
            {/*                                                style={{fontSize: '12px'}}*/}
            {/*                                            >*/}
            {/*                                                /!*<Typography>*!/*/}
            {/*                                                {option.description}*/}
            {/*                                                /!*</Typography>*!/*/}
            {/*                                            </Typography>*/}
            {/*                                        )}*/}
            {/*                                    />*/}
            {/*                                    <Typography style={styles.ShippingPackageQuickQuotePlaceOrderErrorText}>*/}
            {/*                                        {*/}
            {/*                                            !shipTo?.address ? intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDITION_INFO.REQUIRED') : ""*/}
            {/*                                            // shipToMatch ? "" : 'The address provided does not match the provided postal code.'*/}
            {/*                                        }*/}
            {/*                                    </Typography>*/}
            {/*                                </Box>*/}
            {/*                            </Grid>*/}
            {/*                            <Grid item xs={12}>*/}
            {/*                                <Box sx={styles.ShippingPackageQuickQuotePlaceOrderSection}>*/}
            {/*                                    <InputLabel>*/}
            {/*                                        {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDITION_INFO.ADDRESS')}*/}
            {/*                                    </InputLabel>*/}
            {/*                                    <TextField*/}
            {/*                                        value={shipToAddressTwo}*/}
            {/*                                        fullWidth*/}
            {/*                                        variant="outlined"*/}
            {/*                                        onInput={handleShipToAddressTwo}*/}
            {/*                                        size='small'*/}
            {/*                                    />*/}
            {/*                                </Box>*/}
            {/*                            </Grid>*/}
            {/*                            <Grid item md={6} xs={12}>*/}
            {/*                                <Box sx={styles.ShippingPackageQuickQuotePlaceOrderSection}>*/}
            {/*                                    <InputLabel required>*/}
            {/*                                        {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDITION_INFO.NAME')}*/}
            {/*                                    </InputLabel>*/}
            {/*                                    <TextField*/}
            {/*                                        value={shipToContact?.name}*/}
            {/*                                        fullWidth*/}
            {/*                                        variant="outlined"*/}
            {/*                                        onInput={handleShipToName}*/}
            {/*                                        size='small'*/}
            {/*                                    />*/}
            {/*                                    <Typography style={styles.ShippingPackageQuickQuotePlaceOrderErrorText}>*/}
            {/*                                        {*/}
            {/*                                            shipToContactError.includes('name') ? intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDITION_INFO.REQUIRED') : ""*/}
            {/*                                        }*/}
            {/*                                    </Typography>*/}
            {/*                                </Box>*/}
            {/*                            </Grid>*/}
            {/*                            <Grid item md={6} xs={12}>*/}
            {/*                                <Box sx={styles.ShippingPackageQuickQuotePlaceOrderSection}>*/}
            {/*                                    <InputLabel required>*/}
            {/*                                        {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDITION_INFO.PHONE')}*/}
            {/*                                    </InputLabel>*/}
            {/*                                    <TextField*/}
            {/*                                        value={shipToContact?.phone}*/}
            {/*                                        fullWidth*/}
            {/*                                        variant="outlined"*/}
            {/*                                        onInput={handleShipToPhone}*/}
            {/*                                        size='small'*/}
            {/*                                    />*/}
            {/*                                    <Typography style={styles.ShippingPackageQuickQuotePlaceOrderErrorText}>*/}
            {/*                                        {*/}
            {/*                                            shipToContactError.includes('phone') ? intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDITION_INFO.REQUIRED') :*/}
            {/*                                                shipToContact?.phone === null ? "" :*/}
            {/*                                                    isValidatePhone(shipToContact?.phone) ? "" :*/}
            {/*                                                        intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDITION_INFO.VALID_PHONE')*/}
            {/*                                        }*/}
            {/*                                    </Typography>*/}
            {/*                                </Box>*/}
            {/*                            </Grid>*/}
            {/*                            <Grid item md={6} xs={12}>*/}
            {/*                                <Box sx={styles.ShippingPackageQuickQuotePlaceOrderSection}>*/}
            {/*                                    <InputLabel>*/}
            {/*                                        {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDITION_INFO.COMPANY')}*/}
            {/*                                    </InputLabel>*/}
            {/*                                    <TextField*/}
            {/*                                        value={shipToContact?.company}*/}
            {/*                                        fullWidth*/}
            {/*                                        variant="outlined"*/}
            {/*                                        onInput={handleShipToCompany}*/}
            {/*                                        size='small'*/}
            {/*                                    />*/}
            {/*                                </Box>*/}
            {/*                            </Grid>*/}
            {/*                            <Grid item md={6} xs={12}>*/}
            {/*                                <Box sx={styles.ShippingPackageQuickQuotePlaceOrderSection}>*/}
            {/*                                    <InputLabel>*/}
            {/*                                        {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDITION_INFO.EMAIL')}*/}
            {/*                                    </InputLabel>*/}
            {/*                                    <TextField*/}
            {/*                                        value={shipToContact?.email}*/}
            {/*                                        fullWidth*/}
            {/*                                        variant="outlined"*/}
            {/*                                        onInput={handleShipToEmail}*/}
            {/*                                        placeholder={intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDITION_INFO.RECOMMEND')}*/}
            {/*                                        size='small'*/}
            {/*                                    />*/}
            {/*                                </Box>*/}
            {/*                            </Grid>*/}
            {/*                        </Grid>*/}
            {/*                    </Box>*/}
            {/*                </Grid>*/}
            {/*                <Grid item xs={12}>*/}
            {/*                    <Box sx={{display: 'flex', width: '100%', justifyContent: 'flex-end', gap: '20px'}}>*/}
            {/*                        <LoadingButton*/}
            {/*                            variant='contained'*/}
            {/*                            color='success'*/}
            {/*                            size='large'*/}
            {/*                            loading={loadingLabel}*/}
            {/*                            onClick={handleGetLabel}*/}
            {/*                            // // disabled={packageListRedux.length === 0 ? true : false}*/}
            {/*                        >*/}
            {/*                            {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.SAMPLE_LABEL')}*/}
            {/*                        </LoadingButton>*/}
            {/*                        <LoadingButton*/}
            {/*                            variant='contained'*/}
            {/*                            color='success'*/}
            {/*                            size='large'*/}
            {/*                            loading={loading}*/}
            {/*                            onClick={handlePlaceOrder}*/}
            {/*                            // // disabled={packageListRedux.length === 0 ? true : false}*/}
            {/*                        >*/}
            {/*                            {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.PLACE_ORDER')}*/}
            {/*                        </LoadingButton>*/}
            {/*                    </Box>*/}
            {/*                </Grid>*/}
            {/*            </Grid>*/}
            {/*            <Grid item xs={12}>*/}
            {/*                {*/}
            {/*                    labelImage &&*/}
            {/*                    <Box sx={{display: 'flex', justifyContent: 'center', margin: '40px 0'}}>*/}
            {/*                        <img*/}
            {/*                            src={`data:image/jpeg;base64,${labelImage}`}*/}
            {/*                            alt="label"*/}
            {/*                            width={isMobile ? 300 : 700}*/}
            {/*                            height="auto"*/}
            {/*                        />*/}
            {/*                    </Box>*/}
            {/*                }*/}
            {/*            </Grid>*/}
            {/*        </>*/}
            {/*}*/}
        </Box>
    )
}