import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Button} from "@mui/material";
import Divider from "@mui/material/Divider";
import * as React from "react";
import Box from "@mui/material/Box";
import {generateRandomString} from "../../Utils/Helper";
import {addBoxListItem, toggleBoxList, toggleBoxListItem} from "../../actions/ratingAction";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";

const style = {
    RatingBoxListEachRoot: {
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 40px',
    },
    RatingBoxListEachItem: {
        marginBottom: '10px',
    },
    RatingBoxListEachItemDetail: {
        display: 'flex',
        flexDirection: 'column'
    },
    RatingBoxListEachItemButton: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
    }
}

export const RatingBoxListEach = ({boxInfo}) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const handleUseIt = () => {
        dispatch(toggleBoxList(false));
        dispatch(toggleBoxListItem(true));
        dispatch(addBoxListItem(boxInfo));
    }

    const handleEdit = () => {
        history.push('/my-boxes');
    }


    return (
        <Box sx={style.RatingBoxListEachRoot}>
            <Grid container spacing={2} sx={style.RatingBoxListEachItem}>
                <Grid item xs={8} sx={style.RatingBoxListEachItemDetail}>
                    <Typography style={{wordWrap: 'break-word', whiteSpace: 'pre-line'}}
                                sx={{fontSize: '25px', fontWeight: '600'}}>
                        {boxInfo?.box_name}
                    </Typography>
                    <Typography style={{wordWrap: 'break-word', whiteSpace: 'pre-line'}} sx={{fontSize: '20px'}}>
                        {boxInfo?.item_name}
                    </Typography>
                    <Typography sx={{fontSize: '15px'}}>
                        Weight: {boxInfo.weight} {boxInfo.weight_unit}
                    </Typography>
                    <Typography style={{wordWrap: 'break-word', whiteSpace: 'pre-line', textAlign: 'left'}}>
                        Dimension (L x W x
                        H): {boxInfo.length || 0} x {boxInfo.length || 0} x {boxInfo.length || 0} {boxInfo.dimension_unit}
                    </Typography>
                    {/*{itemDetail.itemId}*/}
                </Grid>
                <Grid item xs={4} sx={style.RatingBoxListEachItemButton}>
                    <Button onClick={handleUseIt}>Use It</Button>
                    <Button color="warning" onClick={handleEdit}>Edit</Button>
                </Grid>
            </Grid>
            <Divider/>
        </Box>
    )

}