import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
// import store from '../../store/index'
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { Alert } from "@material-ui/lab";

import { GET_UNSETTLED_TRANSACTION, DRIVER_SUMMARY } from "../../Utils/apiUrl";
import { getHeaders, errHandling } from "../../Utils/fetchUtil";
import LoadingContainer from "../shared/loadingContainer";

const BookkeeperHome = () => {
  const history = useHistory();
  // const dispatch = useDispatch();
  const { firstname } = useSelector(state => state.user);
  const { email } = useSelector(state => state.user);
  const [listData, setListData] = useState(null);
  const [summaryData, setSummaryData] = useState(null);

  const [summaryErr, setSummaryErr] = useState("");
  const [summaryLoading, setSummaryLoadingState] = useState(true);

  const [unsettledErr, setUnsettledErr] = useState("");
  const [unsettledLoading, setUnsettledLoadingStatus] = useState(true);

  const classes = useStyles();

  const fetchSummaryTimeOut = () => {
    setSummaryErr(
      "Request timeout. Please check network connection and retry."
    );
    setSummaryLoadingState(false);
  };

  useEffect(() => {
    axios
      .get(GET_UNSETTLED_TRANSACTION(email), {
        headers: getHeaders(),
      })
      .then(response => {
        setUnsettledLoadingStatus(false);
        if (response.data) {
          setListData(response.data);
        }
      })
      .catch(err => {
        setUnsettledLoadingStatus(false);
        errHandling(err, setUnsettledErr);
      });
  }, []);

  useEffect(() => {
    const fetchSummaryTimer = setTimeout(() => {
      fetchSummaryTimeOut();
    }, 1000 * 10);

    axios
      .get(DRIVER_SUMMARY, {
        headers: getHeaders(),
      })
      .then(response => {
        clearTimeout(fetchSummaryTimer);
        setSummaryLoadingState(false);
        setSummaryData(response.data);
      })
      .catch(err => {
        clearTimeout(fetchSummaryTimer);
        setSummaryLoadingState(false);
        errHandling(err, setSummaryErr);
      });
    return () => {
      clearTimeout(fetchSummaryTimer);
    };
  }, []);

  const handleRedirection = () => {
    history.push("/driver/settlements-list");
  };

  // Render list view for the unsettlements
  const listView = (_data, type) => {
    const inType = type === "in";
    const types = inType ? _data.direction_in.types : _data.direction_out.types;
    const length = types.length;
    return types.map((item, index) => {
      return item.subtotal_final_amount === "0.00" ? (
        ""
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          key={item.type}
        >
          <Box maxWidth="500px" width="100%">
            <Box className={classes.subTitle}>{item.type}</Box>
            <Box className={classes.itemWrapper}>
              {item.data.map(shipment => {
                return (
                  <Box display="flex" justifyContent="space-between">
                    <Box>{shipment.shipment_number}</Box>
                    <Box>C$ {shipment.final_amount}</Box>
                  </Box>
                );
              })}
              <hr />
              <Box display="flex" justifyContent="space-between">
                <Box>Subtotal</Box>
                <Box>C$ {item.subtotal_final_amount}</Box>
              </Box>
            </Box>
            {(length !== index + 1 || inType) && (
              <Box mt={4} mb={4}>
                <hr />
              </Box>
            )}
          </Box>
        </Box>
      );
    });
  };

  return (
    <Box ml={1} mr={1}>
      <Box display="flex" justifyContent="center">
        <Box>
          <Box className={classes.title}>Hi, {firstname}</Box>
          <Box className={classes.paper}>
            <Box className={classes.container}>
              {" "}
              Your have delivered{" "}
              <Box component="span" fontWeight="700">
                {summaryLoading ? (
                  <CircularProgress size={16} />
                ) : (
                  summaryData?.delivered_shipments || "0"
                )}
              </Box>{" "}
              shipments. Good job. <br />
              You have earned{" "}
              <Box component="span" fontWeight="700">
                {summaryLoading ? (
                  <CircularProgress size={16} />
                ) : (
                  `C$${summaryData?.total_earnings || "0"}`
                )}
              </Box>{" "}
              till now.
            </Box>
          </Box>
          {summaryErr ? (
            <Box pt="1vh" width="100%">
              <Alert severity="error" classes={{ root: classes.alertRoot }}>
                {summaryErr}
              </Alert>
            </Box>
          ) : (
            ""
          )}
        </Box>
      </Box>

      <Box maxWidth="500px" width="100%" margin="0 auto">
        <hr />
      </Box>

      <Box display="flex" justifyContent="center" mb={3} mt={3}>
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleRedirection}
            style={{
              textTransform: "unset",
              fontSize: "1rem",
            }}
          >
            Check completed settlements
          </Button>
        </Box>
      </Box>

      <Box maxWidth="500px" width="100%" margin="0 auto">
        <hr />
      </Box>

      <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
        <Box maxWidth="500px" width="100%" className={classes.title}>
          Unsettled transactions
        </Box>
      </Box>

      {unsettledLoading ? (
        <LoadingContainer />
      ) : (
        <>
          {listData && listView(listData, "in")}
          {listData && listView(listData, "out")}
        </>
      )}

      {unsettledErr ? (
        <Box pt="1vh" width="100%">
          <Alert severity="error" classes={{ root: classes.alertRoot }}>
            {unsettledErr}
          </Alert>
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    maxWidth: "360px",
    padding: theme.spacing(0.5),
    backgroundColor: "#fdf5e8",
    width: "100%",
  },
  container: {
    // textAlign: "center",
    backgroundColor: "#fbfbf8",
    border: "1px solid #ded47c",
    borderRadius: "5px",
    margin: "0px auto",
  },
  title: {
    fontSize: "1.3rem",
    fontWeight: "600",
    color: "seagreen",
    fontFamily: "Montserrat",
  },
  subTitle: {
    fontSize: "1.1rem",
    fontWeight: "400",
    color: "seagreen",
    fontFamily: "Montserrat",
  },
  itemWrapper: {
    backgroundColor: "#fbfbf8",
    padding: theme.spacing(0.5),
    borderRadius: "5px",
  },
  alertRoot: {
    width: "80%",
    minWidth: "200px",
    margin: "10px auto",
  },
}));

// SettlementsList.propTypes = {
//   guest: PropTypes.bool,
// };

// export default connect(mapStateToProps, {getOrders, getPackages, getOrderShipments})(OrderList)
export default BookkeeperHome;
