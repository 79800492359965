import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Box, makeStyles} from "@material-ui/core";
import PayWithCreditCard from "./PayWithCreditCard";
import PayWithPayCheckWechat from "./PayWithPayCheckWechat";
import AddCreditCardAccordion from "./AddCreditCardAccordion";

const useStyles = makeStyles((theme) => ({
  rootExpanded: {
    background: "blue",
    flexGrow: 1
  },
  accordion: {
    width: "100%"
  }
}));

const AddCreditCardSection = ({ cardInfo, setCardInfo, selectedCard, setSelectedCard, cardLoading, setCardLoading, cardStatus, setReload }) => {

  const classes = useStyles();

  return (
      <Box display="flex" width="100%" flexDirection="column">
        <Accordion className={classes.accordion} defaultExpanded>
          <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
          >
            <Typography>CREDIT CARD</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <AddCreditCardAccordion
                  cardInfo={cardInfo}
                  setCardInfo={setCardInfo}
                  cardLoading={cardLoading}
                  setSelectedCard={setSelectedCard}
                  setCardLoading={setCardLoading}
                  selectedCard={selectedCard}
                  cardStatus={cardStatus}
                  setReload={setReload}
              />
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
  );
}

export default AddCreditCardSection;