import React from "react";
import { Grid, Paper, makeStyles, Typography } from "@material-ui/core";
import ShipmentOptionsLayout from "../Layouts/ShipmentOptionsLayout";
import { success_tag } from "../../css/MUIStyle";
import { shipmentTypesDetails } from "../shared";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    margin: "5px 2%",
    width: "96%",
    backgroundColor: "#fdf5e8",
    padding: "3px",
    cursor: "pointer",
    "&:hover": {
      boxShadow:
        "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
    },
  },
  img: {
    margin: "5px auto",
    borderRadius: "5px",
    display: "block",
    maxHeight: "150px",
    maxWidth: "200px",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
  },
  container: {
    width: "100%",
    textAlign: "center",
    backgroundColor: "#fbfbf8",
    border: "1px solid #ded47c",
    borderRadius: "5px",
    margin: "0px auto",
    display: "flex",
    justifyContent: "space-around",
  },
  description: {},
  deliveryInfo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "2px auto",
    background: "white",
    borderRadius: "5px",
    border: "1px solid orange",
    width: "fit-content",
    padding: "5px 10px",
    fontFamily: "inherit",
  },
  availableTag: success_tag,
}));

const ShipmentList = ({ shipment: s, handleClick }) => {
  const classes = useStyles();
  const image_src = s.cover_image;
  const item_name = s.item_name;
  const item_unit_price = s.unit_price;

  const puDate = s.schedule_pickup.split(" ");

  return (
    <>
      <Paper
        className={classes.paper}
        onClick={() =>
          handleClick(s.shipment_number, s.package_number, s.stage)
        }
        id={"shipment"}
      >
        <Grid container className={classes.container}>
          <Grid xs={12} sm={3} item>
            <img className={classes.img} src={image_src} alt="package"></img>

            <b>{item_name}</b>
          </Grid>
          <Grid container item xs={12} sm={4}>
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  fontWeight: "500",
                  flexWrap: "wrap",
                }}
              >
                <span>
                  Length:{" " + s.length}
                  {shipmentTypesDetails[s.shipment_type_id].sizeUnit}
                </span>
                <span>
                  Width:{" " + s.weight}
                  {shipmentTypesDetails[s.shipment_type_id].sizeUnit}
                </span>
                <span>
                  Height:{" " + s.height}
                  {shipmentTypesDetails[s.shipment_type_id].sizeUnit}
                </span>
                <span>
                  {shipmentTypesDetails[s.shipment_type_id].label}
                  {"(" +
                    shipmentTypesDetails[s.shipment_type_id].type_name +
                    ")"}
                  {" x "}
                  {s.tracking_number_count}
                </span>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.deliveryInfo}>
                <Typography align="left" style={{ fontFamily: "inherit" }}>
                  Pickup from:{" "}
                  <b style={{ fontSize: "1.1rem" }}>{s.pickup_city}</b>
                </Typography>
                <Typography align="left" style={{ fontFamily: "inherit" }}>
                  Deliver to:{" "}
                  <b style={{ fontSize: "1.1rem" }}>{s.deliver_city}</b>
                </Typography>
                <Typography align="left" style={{ fontFamily: "inherit" }}>
                  Pickup Date: <b style={{ fontSize: "1.1rem" }}>{puDate[0]}</b>
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={1}>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-evenly",
                width: "100%",
                height: "100%",
                alignContent: "space-evenly",
              }}
            >
              <ShipmentOptionsLayout optionList={s.optionList} />
              <div>
                <span style={{ fontWeight: "650" }}>${item_unit_price}</span>
                /Unit
              </div>
              <div className={classes.availableTag}>
                {s.available_count > 0 ? `Available: ${s.available_count}` : ""}
              </div>
              <div style={{ whiteSpace: "nowrap" }}>
                {s.available_count > 0 ? (
                  <>
                    <span>Total: </span>
                    <span style={{ fontWeight: "650" }}>
                      ${s.available_count * item_unit_price}
                    </span>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default ShipmentList;
