import Box from "@mui/material/Box";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import {getAccessToken} from "../../Utils/doToken";
import axios from "axios";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {AddressBookEach} from "./AddressBookEach";
import Typography from "@mui/material/Typography";

export const AddressBookList = ({addressListAPI}) => {

    const accessToken = getAccessToken("access_token");
    let addressTab = useSelector(state => state?.addressBook?.addressTab);

    const [addressList, setAddressList] = useState([]);

    const getSenderAddress = async () => {
        let requestURL = `${NODE_ROUTE_URI}/addressBookEndUser/endUser/addressbooks/sender`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            // console.log(result.data)
            setAddressList(result.data.result)
        } catch (e) {
            console.log(e.response)
        }
    }

    const getDeliverAddress = async () => {
        let requestURL = `${NODE_ROUTE_URI}/addressBookEndUser/endUser/addressbooks/deliver`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            // console.log(result.data)
            setAddressList(result.data.result)
        } catch (e) {
            console.log(e.response)
        }
    }

    useEffect(() => {
        if (addressTab === "sender") {
            getSenderAddress()
        } else {
            getDeliverAddress()
        }
    }, [addressTab])

    useEffect(() => {
        setAddressList(addressListAPI);
    }, [addressListAPI])

    return (
        <Box>
            {
                addressList.length === 0 ?
                    <Box>
                        <Typography variant='h5' style={{textAlign: 'center'}}>
                            Your address list of {addressTab} is empty
                        </Typography>
                    </Box>
                    :
                    addressList?.map((each, index) => <AddressBookEach key={index}
                                                                       addressInfo={each}
                                                                       index={index}/>)
            }
        </Box>

    )
}