import {TableCell, TableRow} from "@mui/material";
import Typography from "@mui/material/Typography";

export const OrderDashBoardAdjustmentsEach = ({adjustmentsEach}) => {
    return (
        <TableRow>
            <TableCell>
                <Typography>
                    {adjustmentsEach?.invoice_no}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography>
                    {adjustmentsEach?.tracking_no}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography>
                    {adjustmentsEach?.length}x{adjustmentsEach?.width}x{adjustmentsEach?.height} {adjustmentsEach?.dimension_unit}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography>
                    {adjustmentsEach?.weight} {adjustmentsEach?.weight_unit}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography>
                    {adjustmentsEach?.unit_price && `CAD $${adjustmentsEach?.unit_price}`}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography>
                    {/*{adjustmentsEach?.invoice_no}*/}
                </Typography>
            </TableCell>
        </TableRow>

    )
}