import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import {StripeInput} from "./stripeInput";
import {CardCvcElement, CardExpiryElement, CardNumberElement} from "@stripe/react-stripe-js";
import TextField from "@mui/material/TextField";
import Visa from "../../../images/cards/Visa.png";
import Mastercard from "../../../images/cards/Mastercard.png";
import InputLabel from "@mui/material/InputLabel";

export const CreditCardSection = () => {
    return (
        <Box sx={{
            display: 'flex',
            gap: '10px',
            flexDirection: 'column',
            margin: '20px 5px',
            position: 'relative'
        }}>
            <Box sx={{
                display: 'flex',
                gap: '5px',
                justifyContent: 'flex-end',
                alignItems: 'center',
                position: 'absolute',
                right: '0'
            }}>
                <Box sx={{borderRadius: '5px', backgroundColor: '#F79327', padding: '5px 10px'}}>
                    <Typography style={{fontSize: '10px', fontWeight: '600'}}>
                        Powered by Stripe
                    </Typography>
                </Box>
                <img src={Visa} alt="VisaLogo" height={20}/>
                <img src={Mastercard} alt="MasterCardLogo" height={20}/>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px'
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px'
                }}>
                    <InputLabel>
                        <Typography style={{fontSize: '14px', fontWeight: '600'}}>
                            Card Number
                        </Typography>
                    </InputLabel>
                    <TextField
                        name="ccnumber"
                        variant="outlined"
                        required
                        fullWidth
                        InputLabelProps={{shrink: true}}
                        InputProps={{
                            inputComponent: StripeInput,
                            inputProps: {
                                component: CardNumberElement
                            },
                        }}
                    />
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px'
                }}>
                    <InputLabel>
                        <Typography style={{fontSize: '14px', fontWeight: '600'}}>
                            Expiration Date (MM/YY)
                        </Typography>
                    </InputLabel>
                    <TextField
                        name="ccexp"
                        variant="outlined"
                        required
                        fullWidth
                        InputLabelProps={{shrink: true}}
                        InputProps={{
                            inputComponent: StripeInput,
                            inputProps: {
                                component: CardExpiryElement
                            },
                        }}
                    />
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px'
                }}>
                    <InputLabel>
                        <Typography style={{fontSize: '14px', fontWeight: '600'}}>
                            CVV
                        </Typography>
                    </InputLabel>
                    <TextField
                        name="cvc"
                        variant="outlined"
                        required
                        fullWidth
                        InputLabelProps={{shrink: true}}
                        InputProps={{
                            inputComponent: StripeInput,
                            inputProps: {
                                component: CardCvcElement
                            },
                        }}
                    />
                </Box>
            </Box>
        </Box>
    )
}
