import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";
import CircularProgress from "@mui/material/CircularProgress";
import {useEffect, useState} from "react";
import {getAccessToken} from "../../Utils/doToken";
import {makeStyles} from "@material-ui/core/styles";
import {PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {PAGE_SIZE} from "../../Utils/consInfo";
import {useSelector} from "react-redux";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {InputAdornment} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import {Pagination} from "@mui/lab";
import {BusinessLandingAdjustOrderList} from "./BusinessLandingAdjustOrderList";

const styles = {
    BusinessLandingAdjustOrderListRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    BusinessLandingAdjustOrderListContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    }
});

export const BusinessLandingAdjustOrder = () => {

    const storedToken = getAccessToken("access_token");
    const {firstname, id, isLoading} = useSelector((state) => state.user);
    const classes = useStyles();

    const [searchString, setSearchString] = useState(null);
    const [orderData, setOrderData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const handleSearchString = (event) => {
        setSearchString(event.target.value);
    }

    const getPackageOrderList = async () => {
        setLoading(true);
        const requestURL = `${PARTNER_URI}/elasticsearch/shippingPackageOrder/searchShippingPackageOrder`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    queryString: searchString || "",
                    page: 0,
                    pageSize: PAGE_SIZE,
                    isAdjust: 1
                }
            });
            console.log('[shipping order list package] - result', result);
            setOrderData(result?.data?.result);
            setCurrentPage(result?.data?.pagination?.currentPage + 1);
            setLastPage(result?.data?.pagination?.lastPage + 1);
            setError(false);
            setLoading(false);
        } catch (e) {
            console.log(e.response);
            setError(true);
            setLoading(false);
        }
    }

    const getPackageOrderListByPage = async (page) => {
        setLoading(true);
        const requestURL = `${PARTNER_URI}/elasticsearch/shippingPackageOrder/searchShippingPackageOrder`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    queryString: searchString || "",
                    page: page,
                    pageSize: PAGE_SIZE,
                    isAdjust: 1
                }
            });
            console.log('[shipping order list package] - result', result);
            setOrderData(result?.data?.result);
            setError(false);
            setLoading(false);
        } catch (e) {
            console.log(e.response);
            setError(true);
            setLoading(false);
        }
    }

    const handleSearchShippingOrder = () => {
        getPackageOrderList();
    }

    const handleSearchShippingOrderByKeyDown = (event) => {
        if (event.key === 'Enter') {
            getPackageOrderList();
        }
    }

    const handlePageChange = (event, value) => {
        console.log('page change', value);
        setCurrentPage(value);
        const page = value;
        getPackageOrderListByPage(page);
    };

    useEffect(() => {
        if (id) {
            (async () => {
                await getPackageOrderList();
            })()
        }
    }, [id])

    return (
        <Box sx={styles.BusinessLandingAdjustOrderListRoot}>
            <Typography style={{fontSize: '16px', fontWeight: 600, textAlign: 'left'}}>
                {intl.get('ORDER_DASHBOARD.ADJUSTMENT_ORDERS.ADJUSTMENT_ORDERS')}
            </Typography>
            <Box sx={styles.BusinessLandingAdjustOrderListContent}>
                <Grid container spacing={2}>
                    <Grid item xs={9}>
                        <TextField
                            value={searchString}
                            fullWidth
                            size='small'
                            onInput={handleSearchString}
                            onKeyDown={handleSearchShippingOrderByKeyDown}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon/>
                                    </InputAdornment>
                                ),
                            }}
                            classes={{root: classes.customTextField}}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            height: '100%',
                        }}>
                            <Button
                                variant='contained'
                                sx={{
                                    backgroundColor: '#1D8B45',
                                    height: '100%',
                                    "&:hover": {
                                        backgroundColor: '#1D8B45',
                                        filter: 'brightness(0.9)'
                                    },
                                }}
                                size='small'
                                onClick={handleSearchShippingOrder}
                            >
                                <Typography
                                    style={{textTransform: 'none', fontSize: '14px'}}
                                >
                                    {intl.get('HOME_PAGE.SHIPPING_ORDER.SEARCH')}
                                </Typography>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                {
                    loading ?
                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                            <CircularProgress/>
                        </Box>
                        :
                        error ?
                            <Box sx={styles.BusinessLandingAdjustOrderListContent}>
                                <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                    {intl.get('HOME_PAGE.ERROR')}
                                </Typography>
                            </Box>
                            :
                            orderData?.length > 0 ?
                                <Box sx={styles.BusinessLandingAdjustOrderListContent}>
                                    <BusinessLandingAdjustOrderList order={orderData}/>
                                    <Pagination
                                        count={lastPage}
                                        page={+currentPage}
                                        size='small'
                                        onChange={handlePageChange}
                                    />
                                </Box>
                                :
                                <Box sx={styles.BusinessLandingAdjustOrderListContent}>
                                    <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                        {intl.get('ORDER_DASHBOARD.ADJUSTMENT_ORDERS.NO_RESULTS')}
                                    </Typography>
                                </Box>
                }
            </Box>
        </Box>
    )
}