//To-do move all the API calls in this file
export const BACKEND_ROUTE_URI = "https://api.uucargo.ca/api";
export const NODE_ROUTE_URI =
    process.env.NODE_ENV === "development"
        ? "http://localhost:9000"
        : "https://api.uucargo.ca/v2";
export const SERVICE_NODE =
    process.env.NODE_ENV === "development"
        ? "http://localhost:9003"
        : "https://api.uucargo.ca";

export const SERVICE_NODE_ROUTE_URI =
    process.env.NODE_ENV === "development"
        ? "http://localhost:9003/service"
        : "https://api.uucargo.ca/service";

// export const NODE_ROUTE_URI = "http://localhost:9000";
// export const NODE_ROUTE_URI = "https://api.uucargo.ca/v2";

// Authentication
// export const MIDDLEWARE_LOGIN_API = function (email, password) {
//   return `${BACKEND_ROUTE_URI}/userprofile/signin/?email=${email}&source=Email&password=${password}`;
// };

export const MIDDLEWARE_LOGIN_API = `${NODE_ROUTE_URI}/login_middleware`;
export const MIDDLEWARE_MICROSOFT_LOGIN_API = `${NODE_ROUTE_URI}/login_middleware/microsoft`;

export const BACKEND_LOGIN_API = `${BACKEND_ROUTE_URI}/userprofile/signin/`;
export const BACKEND_DRIVER_LOGIN_API = `${BACKEND_ROUTE_URI}/driver/signin/`;
export const CHANGE_PASSWORD_API = `${NODE_ROUTE_URI}/login_middleware/change_password/`;

export const USER_SIGN_UP_API = `${NODE_ROUTE_URI}/login_middleware/signup/`;

// export const BACKEND_DRIVER_SIGN_UP_API = `${BACKEND_ROUTE_URI}/driver/signup/`;

// Profile

// export const BACKEND_ME_API = `${BACKEND_ROUTE_URI}/me`;

// New Me API
export const BACKEND_ME_API = `${NODE_ROUTE_URI}/login_middleware/me`;

export const PROFILE_API = `${NODE_ROUTE_URI}/user_profile/`;

export const FORGOT_PASSWORD = `${NODE_ROUTE_URI}/email/reset_user_password`;
export const RESET_PASSWORD = `${NODE_ROUTE_URI}/login_middleware/reset_password/`;

export const BACKEND_UPDATE_TRACCAR_API = `${BACKEND_ROUTE_URI}/driver_traccar_info/set/`;

export const BACKEND_CHANGE_ROLE_API = (email) =>
    `${BACKEND_ROUTE_URI}/role/${email}`;

export const BACKEND_LICENSE_API = (email) =>
    `${BACKEND_ROUTE_URI}/license/${email}`;

// Payment
export const CREDIT_CARD_API = `${BACKEND_ROUTE_URI}/creditcard/`;
// export const LIST_CREDIT_CARD_API = `${BACKEND_ROUTE_URI}/pay/creditcards/`;
export const LIST_CREDIT_CARD_API = `${NODE_ROUTE_URI}/pay/creditcards/`;

export const BACKEND_CREATE_PAYMENT_INTENT = `${BACKEND_ROUTE_URI}/guest/checkout`;

export const BACKEND_PAYMENT_CONFIRM = `${BACKEND_ROUTE_URI}/guest/checkout/succeed`;

export const GET_SETUP_SECRET_API = `${NODE_ROUTE_URI}/pay/setup_secret`;
export const SAVE_CREDITCARD_INFO_API = `${NODE_ROUTE_URI}/pay/creditCard`;

// export const BACKEND_ORDERS_API = `${BACKEND_ROUTE_URI}/companyorders/info@jlinkcargo.ca`

// Address book
export const BACKEND_GET_ADDRESSBOOK = `${BACKEND_ROUTE_URI}/addressbooks`;

// export const BACKEND_ADD_TO_ADDRESSBOOK = `${BACKEND_ROUTE_URI}/addressbook`;
export const BACKEND_ADDRESSBOOK = `${NODE_ROUTE_URI}/address_book`;

export const BACKEND_UPDATE_USER_ADDRESS = (id) => {
    return `${BACKEND_ROUTE_URI}/addressbook/${id}`;
};

// Orders
// export const BACKEND_ORDERS_API = `${BACKEND_ROUTE_URI}/orders/`;

export const BACKEND_ORDERS_API = `${NODE_ROUTE_URI}/order_management/`;
export const BACKEND_GUEST_ORDERS_API = `${NODE_ROUTE_URI}/order_management/guest`;
export const BACKEND_GUEST_ORDER_BY_ID_API = `${BACKEND_ROUTE_URI}/guestorder/`;

export const BACKEND_GET_ORDERS_API = `${NODE_ROUTE_URI}/order_management/userOrders`;

// Testing bol_list replace to BACKEND_ROUTE_URI after.
// export const BOL_LIST = `http://localhost:9000/hbl/list-hbl`;

export const BACKEND_PACKAGES_API = `${BACKEND_ROUTE_URI}/packages/`;
export const BACKEND_GUEST_PACKAGES_API = `${BACKEND_ROUTE_URI}/guestpackages/`;

// export const BACKEND_PRICE_ESTIMATE_API = `${BACKEND_ROUTE_URI}/estimate/`;
export const BACKEND_PRICE_ESTIMATE_API = `${SERVICE_NODE_ROUTE_URI}/service/routeprice/pallet`;

export const BACKEND_GUEST_PRICE_ESTIMATE_API = `${BACKEND_ROUTE_URI}/guestestimate/`;

export const BACKEND_ORDER_PRICE_API = `${BACKEND_ROUTE_URI}/orderprice/`;

export const BACKEND_GUEST_ORDER_PRICE_API = `${BACKEND_ROUTE_URI}/guestorderprice/`;

export const BACKEND_ORDER_CANCEL_API = `${BACKEND_ROUTE_URI}/cancelorder/`;

export const BACKEND_GUEST_ORDER_CANCEL_API = `${BACKEND_ROUTE_URI}/cancelguestorder/`;

export const BACKEND_ORDER_LABELS_API = `${BACKEND_ROUTE_URI}/shippinglabels/`;

export const BACKEND_REMOVE_PACKAGE_IMAGE_API = `${BACKEND_ROUTE_URI}/undoimage/`;

export const BACKEND_CLAIMED_SHIPMENTS_API = `${BACKEND_ROUTE_URI}/shipments/`;

export const BACKEND_ORDERS_BY_ORDERNUMBER_API = (orderNumber) => {
    return `${BACKEND_ROUTE_URI}/orders/${orderNumber}`;
};

export const BACKEND_ORDERS_BY_ORDERNUMBER_NEW_API = (orderNumber) => {
    return `${NODE_ROUTE_URI}/order_management/orderDetails?orderNumber=${orderNumber}`;
};

export const BACKEND_ADDON_INFO_API = (id) => {
    return `${BACKEND_ROUTE_URI}/extraoptions/${id}`;
};

export const BACKEND_ADDON_INFO_TEST_API = (name) => {
    return `${SERVICE_NODE_ROUTE_URI}/service/extraoptions?option_name=${name}`;
};

export const CRM_ORDER_WITHOUT_PRICE = `${NODE_ROUTE_URI}/crm/lead`;

// Shipments
// export const GET_AVAILABLE_SHIPMENTS = `${BACKEND_ROUTE_URI}/available_shipment_tasks`;
export const GET_AVAILABLE_SHIPMENTS = `${NODE_ROUTE_URI}/shipment_task/available_shipment_task_list_withfFilter`;

// export const GET_UNCONFIRMED_DELIVERED_SHIPMENTS = `${BACKEND_ROUTE_URI}/unconfirm_delivered_shipment_tasks/`;
export const GET_UNCONFIRMED_DELIVERED_SHIPMENTS = `${NODE_ROUTE_URI}/shipment_task/unconfirmDeliveredShipmentTasks/`;
// export const GET_UNCONFIRMED_PICKEDUP_SHIPMENTS = `${BACKEND_ROUTE_URI}/unconfirm_pickedup_shipments/`;
// export const GET_UNCONFIRMED_PICKEDUP_SHIPMENTS = `${BACKEND_ROUTE_URI}/unconfirm_pickedup_shipment_tasks/`;
export const GET_UNCONFIRMED_PICKEDUP_SHIPMENTS = `${NODE_ROUTE_URI}/shipment_task/unconfirmedPickedupShipmentTasks`;

// export const CONFIRM_SHIPMENTS_PICKUP = `${BACKEND_ROUTE_URI}/confirm_pickedup_shipment_tasks`;
export const CONFIRM_SHIPMENT_TASKS = `${NODE_ROUTE_URI}/shipment_task/confirmShipmentTasks`;

export const GET_SHIPMENTS_BY_ID_API = `${BACKEND_ROUTE_URI}/shipment_task`;

export const MIDDLEWARE_GET_SHIPMENTS_BY_ID_API = `${NODE_ROUTE_URI}/drivers/shipment_task`;

export const GET_PACKAGES_BY_ID_API = (packageID) => {
    return `${BACKEND_ROUTE_URI}/packages/${packageID}`;
};

// export const GET_DRIVER_CLAIMED_SHIPMENTS = `${BACKEND_ROUTE_URI}/claimed_shipment_tasks`;
export const GET_DRIVER_CLAIMED_SHIPMENTS = `${NODE_ROUTE_URI}/shipment_task/claimed_shipment_tasks`;

export const GET_DRIVER_LOADED_SHIPMENTS = `${BACKEND_ROUTE_URI}/pickedup_shipment_tasks`;
// export const GET_SERVER_DRIVER_LOADED_SHIPMENTS = `${NODE_ROUTE_URI}/shipment_task/pickedup_shipment_tasks`;
export const GET_SERVER_DRIVER_LOADED_SHIPMENTS = `${NODE_ROUTE_URI}/shipment_task/getPickupShipmentTaskList`;

export const DRIVER_LOAD_SHIPMENTS = `${NODE_ROUTE_URI}/shipment/load`;

export const DRIVER_PICKUP_SHIPMENTS_TASK = `${NODE_ROUTE_URI}/shipment_task/pickupShipmentTask`;

export const DRIVER_PICKUP_SHIPMENTS_TASK_WITH_PACKAGES = `${NODE_ROUTE_URI}/shipment_task/pickupShipmentTaskWithImages`;

export const DRIVER_DELIVER_SHIPMENTS_TASK = `${NODE_ROUTE_URI}/shipment_task/deliverShipmentTask`;

export const GET_DRIVER_DELIVERED_SHIPMENTS = `${BACKEND_ROUTE_URI}/shipment_task/delivered_shipment_tasks`;
export const GET_SERVER_DRIVER_DELIVERED_SHIPMENTS = `${NODE_ROUTE_URI}/shipment_task/getDeliverShipmentTaskList`;

export const GET_PICKUP_SHIPMENT_TASK_LIST = `${NODE_ROUTE_URI}/shipment_task/getPickupShipmentTaskList`;

// export const CREATE_NEW_MESSAGE_API = `https://admin.uucargo.ca/api/messages/O-P8X60Isj-0000000002?message=$`;

export const UPDATE_MESSAGE_API = (order_num) =>
    `https://admin.uucargo.ca/api/messages/${order_num}`;

export const DRIVER_GET_ALL_TRACKING_NUMBERS = `${BACKEND_ROUTE_URI}/trackingnumbers`;

export const GET_TRACKING_INFO_BY_TRACKING_NUMBER = (trackingNumber) => {
    return `${BACKEND_ROUTE_URI}/trackingnumber/${trackingNumber}`;
};

export const DRIVER_CLAIM_SHIPMENTS_BY_SHIPMENT_ID = `${NODE_ROUTE_URI}/shipment_task/claim`;

export const GET_SHIPMENT_TASK_BY_TRACKING_NUMBER = `${NODE_ROUTE_URI}/shipment_task/getShipmentTaskByTrackingNumber`;

export const DRIVER_CONFIRM_UNCLAIM = (id) => {
    return `${BACKEND_ROUTE_URI}/unclaim/${id}`;
};

// export const DRIVER_UNCLAIM_SHIPMENT_BY_SHIPMENT_ID = (id) => {
//   return `${BACKEND_ROUTE_URI}/unclaim/${id}`;
// };

export const DRIVER_UNCLAIM_SHIPMENT_BY_SHIPMENT_ID = `${NODE_ROUTE_URI}/shipment/unclaim`;

export const DRIVER_LOAD_SHIPMENTS_BY_SHIPMENT_ID = (id) => {
    return `${NODE_ROUTE_URI}/shipment/loadshipment/${id}`;
};

export const ADD_SHIPMENTS_TIPS = (id) => {
    return `${BACKEND_ROUTE_URI}/order/tips/${id}`;
};

export const DRIVER_DELIVER_SHIPMENT = `${NODE_ROUTE_URI}/shipment_task/deliverShipmentTask`;

export const DRIVER_DELIVER_SHIPMENTS_TASK_WITH_PACKAGES = `${NODE_ROUTE_URI}/shipment_task/deliverShipmentTaskWithImages`;

//shipment static
export const SHIPMENT_STATIC = `${BACKEND_ROUTE_URI}/shipment/statistics`;

//shipment get bonus
export const CHECK_BONUS = ({
                                shipmentNumber,
                                stageId,
                                quantity,
                                packageId,
                            }) => {
    return `${NODE_ROUTE_URI}/shipment_task/getAvailableShipmentTaskCount`;
};

// Settlements && Bookkeeper
export const GET_SETTLEMENTS = (userEmail) => {
    return `${BACKEND_ROUTE_URI}/driver/settlements/${userEmail}`;
};

export const GET_UNSETTLED_TRANSACTION = (userEmail) => {
    return `${BACKEND_ROUTE_URI}/driver/transactions/unsettled/${userEmail}`;
};

export const GET_SETTLEMENT_DETAIL = (userEmail, settlementNumber) => {
    return `${BACKEND_ROUTE_URI}/driver/settlement/${userEmail}/${settlementNumber}`;
};

export const SETTLEMENT_DISPUTE = (userEmail, settlementNumber) => {
    return `${BACKEND_ROUTE_URI}/driver/disputesettlement/${userEmail}/`;
};

export const DRIVER_SUMMARY = `${BACKEND_ROUTE_URI}/driver/settlement/summary/`;

// General email API

export const GENERAL_EMAIL = `${BACKEND_ROUTE_URI}/notify/routeprice`;

// Package images
// export const GET_PACKAGE_IMAGE = `${BACKEND_ROUTE_URI}/images`;

export const GET_PACKAGE_IMAGE = `${NODE_ROUTE_URI}/order_management/imagesList`;

// export const UPLOAD_PACKAGE_IMAGE = `${BACKEND_ROUTE_URI}/uploadimage`;
export const UPLOAD_PACKAGE_IMAGE = `${NODE_ROUTE_URI}/order_management/image`;

//deliver shipments with upload images
export const DRIVER_UPLOAD_SHIPMENTS_IMAGES = `${BACKEND_ROUTE_URI}/uploadshipmentsimage`;

/**
 *
 * @param {string} sNum
 * @param {string} type loaded | delivered
 */
export const DRIVER_FETCH_UPLOADED_IMAGES = (sNum, type) => {
    return `${BACKEND_ROUTE_URI}/shipmentimages?shipment_number=${sNum}&shipping_status=${type}`;
};

export const DRIVER_REMOVE_UPLOADED_IMAGES = (sNum, ImgId) => {
    return `${BACKEND_ROUTE_URI}/undoshipmentimage?shipment_number=${sNum}&image_id=${ImgId}`;
};

//create or update shipment appointment
export const DRIVER_UPDATE_SHIPMENT_APPOINTMENT = (shipmentID) => {
    return `${BACKEND_ROUTE_URI}/shipmentappointment/${shipmentID}`;
};

// Cargo tracking
export const GET_CARGO_CONTROL_TRACKING = `${NODE_ROUTE_URI}/cargo_control_tracking/list`;
export const GET_CARGO_CONTROL_TRANSACTION = `${NODE_ROUTE_URI}/cargo_control_transaction/list`;

//AreaGroup
export const AREA_GROUP = `${BACKEND_ROUTE_URI}/areagroups`;

//GoogleGeoCode
export const GOOGLE_GEOCODE = `${NODE_ROUTE_URI}/maps_apis/geocode`;

// Hscode
export const HSCODE_SEARCH = `${NODE_ROUTE_URI}/elasticsearch/search`;

export const HSCODE_GET_NODE = `${NODE_ROUTE_URI}/elasticsearch/node`;

export const BOL_LIST = `${NODE_ROUTE_URI}/bol/list-bol`;

export const BOL_DETAILS = `${NODE_ROUTE_URI}/bol/bol-details`;

export const ADD_BOL_RECORD = `${NODE_ROUTE_URI}/bol/add-bol`;

export const UPDATE_BOL_RECORD = `${NODE_ROUTE_URI}/bol/update-bol-ref-user`;

export const SERVICE_SERVICE_URL = `${SERVICE_NODE}/service/service`;
export const SERVICE_API = `${SERVICE_NODE}/api`;

export const PARTNER_URI =
    process.env.NODE_ENV === "production"
        ? "https://partner.uucargo.ca/api"
        : "http://localhost:9001/api";

export const LABEL_DOWNLOAD_URL_PREFIX = "https://storage.googleapis.com/";
export const LABEL_DOWNLOAD_URL_PREFIX_ALTERNATE = "https://storage.googleapis.com";

export const GET_PARTNER_INFO_API = `${PARTNER_URI}/partner/partnerInfo`

// Hscode partner
export const HSCODE_SEARCH_PARTNER = `${PARTNER_URI}/elasticsearch/search`;

export const BACKEND_IS_PARTNER_API = `${PARTNER_URI}/partner/isPartner`;

export const PRIVACY_POLICY = "https://uucargo.ca/privacy-policy/";
export const TERMS_OF_USE = "https://uucargo.ca/terms-of-use/";
export const FACEBOOK = "https://www.facebook.com/uucargo";
export const LINKEDIN = "https://www.linkedin.com/company/uucargo";
export const INSTAGRAM = "https://www.instagram.com/uucargo/";
export const HSCode = "https://uucargo.ca/blog/cross-border-shipping-guide-2024-expert-tips-for-canada-usa-logistics/";
