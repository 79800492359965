import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";
import Button from "@mui/material/Button";
import {getAccessToken} from "../../Utils/doToken";
import {PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {triggerBase64Download} from "common-base64-downloader-react";
import {AdjustOrderDashboardInvoicePayment} from "./AdjustOrderDashboardInvoicePayment";
import {useEffect, useState} from "react";
import {useInterval} from "usehooks-ts";

export const AdjustOrderDashboardInvoice = ({orderDetails, getAdjustOrderDetails}) => {

    const token = getAccessToken("access_token");

    const storedToken = getAccessToken("access_token");

    const [delay] = useState(3000);
    const [invoiceStatus, setInvoiceStatus] = useState(0);
    const [invoiceType, setInvoiceType] = useState('');

    const getInvoiceStatus = async () => {
        let requestURL = `${PARTNER_URI}/invoices/getPartnerShippingAdjustOrderDetailsByShippingOrderId`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    orderId: orderDetails?.order_id,
                    // partnerId: 248
                }
            })
            const invoiceType = result?.data?.invoiceInfo?.invoiceType;
            setInvoiceStatus(invoiceType === 'Paid' ? 1 : 0);
            setInvoiceType(invoiceType);
        } catch (e) {
            console.log('error', e.response);
        }
    }

    const downloadPDFByInvoiceId = async () => {
        try {
            const requestURL = `${PARTNER_URI}/billingCenter/downloadInvoice`;
            const {data} = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    invoiceId: orderDetails?.crm_invoice_no
                }
            })
            const content = data.content;
            const contentType = data["content-type"];
            const pdfContent = `data:${contentType};base64,${content}`;
            const fileName = data.filename?.length > 4 ? data.filename.slice(0, data.filename.length - 4) : data.filename;
            triggerBase64Download(pdfContent, fileName);
        } catch (e) {
            console.log(e.response);
        }
    }

    const handleDownInvoice = () => {
        downloadPDFByInvoiceId();
    }

    useEffect(() => {
        const invoiceType = orderDetails?.invoiceInfo?.invoiceType;
        setInvoiceStatus(invoiceType === 'Paid' ? 1 : 0);
        setInvoiceType(invoiceType);
    }, [orderDetails?.invoiceInfo?.invoiceType]);

    useInterval(
        () => {
            if (orderDetails) {
                getInvoiceStatus();
            }
        },
        // Delay in milliseconds or null to stop it
        invoiceStatus === 0 ? delay : null,
    );

    console.log('[AdjustOrderDashboardInvoice] invoiceStatus', invoiceStatus);

    return (
        <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography sx={{
                            textAlign: 'left',
                            fontWeight: '600'
                        }}>
                            Invoice Information
                        </Typography>
                        <Typography sx={{fontSize: '14px'}}>
                            The invoice includes an adjusted order amount.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={2.4}>
                        <Typography sx={{fontSize: '14px', fontWeight: '600'}}>
                            {intl.get('BILLING_CENTER.INVOICE_NO')}
                        </Typography>
                        <Typography sx={{fontSize: '14px'}}>
                            {orderDetails?.invoiceInfo?.invoiceRefNumber}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={2.4}>
                        <Typography sx={{fontSize: '14px', fontWeight: '600'}}>
                            {intl.get('BILLING_CENTER.INVOICE_DATE')}
                        </Typography>
                        <Typography sx={{fontSize: '14px'}}>
                            {orderDetails?.invoiceInfo?.invoiceDate}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={2.4}>
                        <Typography sx={{fontSize: '14px', fontWeight: '600'}}>
                            {intl.get('BILLING_CENTER.AMOUNT')}
                        </Typography>
                        <Typography sx={{fontSize: '14px'}}>
                            ${(+orderDetails?.invoiceInfo?.totalChargeIncludeTax)?.toFixed(2)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={2.4}>
                        <Typography sx={{fontSize: '14px', fontWeight: '600'}}>
                            {intl.get('BILLING_CENTER.STATUS')}
                        </Typography>
                        <Typography sx={{
                            fontWeight: '600',
                            fontSize: '14px',
                            color: (!invoiceType) ? '#B2B2B2' : (invoiceType) === 'Unpaid' ? '#F2BE22' : (invoiceType) === "Paid" ? '#1D8B45' : "#384559"
                        }}>
                            {invoiceType || 'Not Billed'}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={2.4}>
                        <Typography sx={{fontSize: '14px', fontWeight: '600'}}>
                            {intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.INVOICE_DETAILS')}
                        </Typography>
                        <Button
                            sx={{padding: '0px'}}
                            onClick={handleDownInvoice}
                        >
                            <Typography style={{
                                textTransform: 'none',
                                fontSize: '14px',
                                color: '#1D8B45',
                            }}>
                                {intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.DOWNLOAD')}
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            {
                invoiceType === 'Unpaid' &&
                <Grid item xs={12} md={12}>
                    <AdjustOrderDashboardInvoicePayment orderDetails={orderDetails}/>
                </Grid>
            }
        </Grid>
    )
}