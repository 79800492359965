import Box from "@mui/material/Box";
import {getAccessToken} from "../../Utils/doToken";
import axios from "axios";
import {PARTNER_URI} from "../../Utils/apiUrl";
import CircularProgress from "@mui/material/CircularProgress";
import {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import aliPay from "../../images/alipay.svg";
import Typography from "@mui/material/Typography";
import {Link} from "@mui/material";
import intl from "react-intl-universal";

const styles = {
    TopUpAliPayRoot: {
        border: '1px solid #000000',
        display: 'flex',
        borderRadius: '10px',
        padding: '20px 0'
    }
}

export const TopUpAliPay = ({
                                isLoading,
                                payCheckLink,
                                amount,
                            }) => {

    if (isLoading) {
        return <>
            <CircularProgress/>
        </>
    }

    if (payCheckLink === "#" && !isLoading) {
        return <>
            <h3>Sorry We currently encounter some issues, please try again later.</h3>
        </>
    }

    return (
        <Box sx={styles.TopUpAliPayRoot}>
            <Grid container spacing={4}>
                <Grid item md={4} xs={12}>
                    <Box sx={{
                        padding: '5px 15px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        height: '100%',
                        gap: '5px'
                    }}>
                        <Typography style={{fontSize: '16px'}}>
                            {intl.getHTML('BILLING_CENTER.PAYMENT.ALI.LINK_TOP_UP', {payCheckLink})}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={8} xs={12}>
                    <Box sx={{
                        padding: '5px 15px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        height: '100%',
                        gap: '5px'
                    }}>
                        <Box sx={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                            <img src={aliPay} alt="wechatLogo" width={35}/>
                            <Typography style={{fontSize: '14px'}}>
                                {intl.get('BILLING_CENTER.PAYMENT.ALI.ALI_PAY')}
                            </Typography>
                        </Box>
                        <Grid container spacing={0}>
                            <Grid item xs={6}>
                                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                    {intl.get('BILLING_CENTER.PAYMENT.ALI.TOTAL_AMOUNT')}:
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                    $ {amount.toFixed(2)}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                    {intl.get('BILLING_CENTER.PAYMENT.ALI.RECEIVER')}:
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                    UU CARGO LTD.
                                </Typography>
                            </Grid>
                            {/*<Grid item xs={6}>*/}
                            {/*    <Typography style={{fontSize: '12px', textAlign: 'left'}}>*/}
                            {/*        {intl.get('BILLING_CENTER.TOP_UP_PAGE.ORDER_ID')}:*/}
                            {/*    </Typography>*/}
                            {/*</Grid>*/}
                            {/*<Grid item xs={6}>*/}
                            {/*    <Typography style={{fontSize: '12px', textAlign: 'left'}}>*/}
                            {/*        {crmInvoiceNumber}*/}
                            {/*    </Typography>*/}
                            {/*</Grid>*/}
                            {/*<Grid item xs={6}>*/}
                            {/*    <Typography style={{fontSize: '12px', textAlign: 'left'}}>*/}
                            {/*        {intl.get('BILLING_CENTER.TOP_UP_PAGE.ORDER_NUMBER')}:*/}
                            {/*    </Typography>*/}
                            {/*</Grid>*/}
                            {/*<Grid item xs={6}>*/}
                            {/*    <Typography style={{fontSize: '12px', textAlign: 'left'}}>*/}
                            {/*        {crmInvoiceInfo?.invoiceRefNumber}*/}
                            {/*    </Typography>*/}
                            {/*</Grid>*/}
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}