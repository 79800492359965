import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import {IconButton, InputAdornment, MenuItem, Select} from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import {
    duplicateMovingServiceItemList,
    removeMovingServiceItemList,
    updateMovingServiceItemList, updateMovingServiceMetricUnit
} from "../../actions/movingServiceAction";
import {convertCMToIN, convertINToCM, convertKGToLB, convertLBToKG, generateRandomString} from "../../Utils/Helper";
import duplicate from "../../images/Duplicate.png";
import remove from "../../images/Delete.png";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {makeStyles} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import intl from "react-intl-universal";

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "4px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

export const MovingServiceItemList = ({itemInfo, index, tab}) => {

    const isMobile = useMediaQuery('(max-width: 480px)');
    const dispatch = useDispatch();
    let metric = useSelector(state => state?.movingService?.movingServiceMetric);
    const classes = useStyles();

    const [itemDetail, setItemDetail] = useState(itemInfo);
    const [categoryList, setCategoryList] = useState([]);
    const [commonBoxesList, setCommonBoxesList] = useState([
        {box_name: 'XXXL Box', length: '48', width: '40', height: '60', weight: '1100'},
        {box_name: 'XXL Box', length: '36', width: '24', height: '24', weight: '80'},
        {box_name: 'XL Box', length: '23.622', width: '23.622', height: '23.622', weight: '65'},
        {box_name: 'Large Box', length: '18', width: '18', height: '24', weight: '65'},
        {box_name: 'Medium Box', length: '18.125', width: '18', height: '16', weight: '65'},
        {box_name: 'Small Box', length: '16.375', width: '12.625', height: '12.625', weight: '65'}
    ]);
    const [commonBoxesListMetric, setCommonBoxesListMetric] = useState([
        {box_name: 'XXXL Box', length: '121.92', width: '101.6', height: '152.4', weight: '498.95'},
        {box_name: 'XXL Box', length: '91.44', width: '60.96', height: '60.96', weight: '36.28'},
        {box_name: 'XL Box', length: '60', width: '60', height: '60', weight: '29.49'},
        {box_name: 'Large Box', length: '45.72', width: '45.72', height: '60.96', weight: '29.49'},
        {box_name: 'Medium Box', length: '46.038', width: '45.72', height: '40.64', weight: '29.49'},
        {box_name: 'Small Box', length: '41.593', width: '32.068', height: '32.068', weight: '29.49'}
    ]);

    const getCategory = async (queryString) => {
        let requestURL = `${NODE_ROUTE_URI}/movingService/searchCategories`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    queryString,
                    pageSize: 10,
                    page: 0
                }
            })
            // console.log(result.data);
            console.log(result);
            let categoryListAPI = result.data.data;
            const updateList = categoryListAPI.filter(
                item => +item?.cate_id !== 100 &&
                    +item?.standard_height_ins !== 0 &&
                    +item?.standard_length_ins !== 0 &&
                    +item?.standard_weight_lbs !== 0 &&
                    +item?.standard_width_ins !== 0
            );

            setCategoryList(updateList);

        } catch (e) {
            console.log(e.response);
        }
    }

    const handleInputCategory = (event, value) => {
        console.log('[handleInputCategory] item type', itemDetail?.type);
        if (itemDetail?.type !== 'box') {
            setItemDetail(prevState => ({
                ...prevState,
                category: value,
                categoryInfo: {
                    ...prevState.categoryInfo,
                    category_name: value
                },
                cate_id: 0
            }));
            getCategory(value);
        }
    }

    const handleCategory = (event, value) => {
        console.log(value);
        if (value) {
            setItemDetail(prevState => ({
                ...prevState,
                category: value?.category_name,
                weight: metric ? convertLBToKG(value?.standard_weight_lbs) : value?.standard_weight_lbs,
                length: metric ? convertINToCM(value?.standard_length_ins) : value?.standard_length_ins,
                width: metric ? convertINToCM(value?.standard_width_ins) : value?.standard_width_ins,
                height: metric ? convertINToCM(value?.standard_height_ins) : value?.standard_height_ins,
                cate_id: value?.cate_id,
                categoryInfo: value,
            }));
        }
    }

    const handleDescription = event => {
        setItemDetail(prevState => ({
            ...prevState,
            description: event.target.value
        }))
    }

    const handleLength = event => {

        const {value} = event.target;

        if (/^\d*\.?\d*$/.test(value)) {
            const length = metric ? 121.92 : 48
            setItemDetail(prevState => ({
                ...prevState,
                length: value > (+length) ? length : value
            }))
        }
    }

    const handleWidth = event => {
        const {value} = event.target;

        if (/^\d*\.?\d*$/.test(value)) {
            const width = metric ? 101.6 : 40
            setItemDetail(prevState => ({
                ...prevState,
                width: value > (+width) ? width : value
            }))
        }
    }

    const handleHeight = event => {
        const {value} = event.target;

        if (/^\d*\.?\d*$/.test(value)) {
            const height = metric ? 152.4 : 60
            setItemDetail(prevState => ({
                ...prevState,
                height: value > (+height) ? height : value
            }))
        }
    }

    const handleWeight = event => {
        const {value} = event.target;

        if (/^\d*\.?\d*$/.test(value)) {
            const weight = metric ? 1360.78 : 3000
            setItemDetail(prevState => ({
                ...prevState,
                weight: value > (+weight) ? weight : value
            }))
        }
    }

    const handleQuantity = event => {

        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            setItemDetail(prevState => ({
                ...prevState,
                quantity: value
            }))
        }
    }

    const handleSelectBoxSize = (event) => {
        const boxSize = event.target.value;
        if (boxSize === 'customBox') {
            setItemDetail(prevState => ({
                ...prevState,
                boxSize: boxSize
            }));
        } else {
            const find = metric ? commonBoxesListMetric?.find((item) => item?.box_name?.replace(/\s+/g, '') === boxSize) : commonBoxesList?.find((item) => item?.box_name?.replace(/\s+/g, '') === boxSize);
            setItemDetail(prevState => ({
                ...prevState,
                boxSize: boxSize,
                height: find?.height,
                length: find?.length,
                width: find?.width,
                weight: find?.weight
            }))
        }
    }

    const handleRemove = () => {
        dispatch(removeMovingServiceItemList(itemDetail));
    }

    const handleDuplicate = () => {
        const itemId = generateRandomString(8);
        const duplicatedItem = {...itemDetail}
        duplicatedItem.itemId = itemId;
        duplicatedItem.description = `${duplicatedItem.description}-copy`;
        dispatch(duplicateMovingServiceItemList(duplicatedItem));
    }

    useEffect(() => {
        getCategory('');
    }, [])

    useEffect(() => {
        setItemDetail(prevState => ({
            ...prevState,
            lengthUnit: metric ? 'cm' : 'in',
            weightUnit: metric ? 'kg' : 'lb',
            weight: metric ? convertLBToKG(itemDetail?.weight) : convertKGToLB(itemDetail?.weight),
            length: metric ? convertINToCM(itemDetail?.length) : convertCMToIN(itemDetail?.length),
            width: metric ? convertINToCM(itemDetail?.width) : convertCMToIN(itemDetail?.width),
            height: metric ? convertINToCM(itemDetail?.height) : convertCMToIN(itemDetail?.height),
        }))
    }, [metric]);

    useEffect(() => {
        setItemDetail(itemInfo);
    }, [itemInfo?.itemId]);

    useEffect(() => {
        dispatch(updateMovingServiceItemList(itemDetail));
    }, [itemDetail])

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '15px'
        }}>
            <Box>
                <Typography style={{fontSize: '16px', textAlign: 'left'}}>
                    # {index + 1}
                </Typography>
            </Box>
            <Grid container spacing={1}>
                {
                    tab === 'express' &&
                    <Grid item md={4} xs={12}>
                        <FormControl fullWidth>
                            <FormLabel required={itemDetail?.cate_id === 100}>
                                {intl.get('ITEM_LIST.SIZE')}
                            </FormLabel>
                            <Select
                                value={itemDetail?.boxSize}
                                fullWidth
                                onChange={handleSelectBoxSize}
                                size='small'
                            >
                                <MenuItem value='customBox' data-value='customBox'>Custom Box</MenuItem>
                                {
                                    metric ? commonBoxesListMetric?.map((each, index) => <MenuItem
                                            key={index}
                                            value={each?.box_name?.replace(/\s+/g, '')}
                                        >{each?.box_name}</MenuItem>)
                                        : commonBoxesList?.map((each, index) => <MenuItem
                                            key={index}
                                            value={each?.box_name?.replace(/\s+/g, '')}
                                        >{each?.box_name}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                }
                <Grid item md={3} xs={12}>
                    <FormControl fullWidth>
                        <FormLabel required={itemDetail?.cate_id === 0 || itemDetail?.cate_id === 100}>
                            {intl.get('ITEM_LIST.DESCRIPTION')}
                        </FormLabel>
                        <TextField
                            value={itemDetail?.description}
                            onInput={handleDescription}
                            fullWidth
                            variant='outlined'
                            placeholder={tab === 'express' ? intl.get('ITEM_LIST.DESCRIPTION_PLACEHOLDER') : intl.get('ITEM_LIST.DESCRIPTION')}
                            size='small'
                            InputProps={{
                                inputProps: {
                                    min: 1
                                },
                                style: {fontSize: 14} // font size of input text
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Grid container spacing={1}>
                        <Grid item md={3} xs={4}>
                            <FormControl fullWidth>
                                <FormLabel required>
                                    {intl.get('ITEM_LIST.LENGTH')}
                                </FormLabel>
                                <TextField
                                    value={itemDetail?.length}
                                    onInput={handleLength}
                                    fullWidth
                                    variant='outlined'
                                    placeholder={intl.get('ITEM_LIST.LENGTH')}
                                    type='number'
                                    size='small'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {itemDetail?.lengthUnit}
                                            </InputAdornment>
                                        ),
                                        inputProps: {
                                            min: 1
                                        },
                                        style: {fontSize: 14} // font size of input text
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} xs={4}>
                            <FormControl fullWidth>
                                <FormLabel required>
                                    {intl.get('ITEM_LIST.WIDTH')}
                                </FormLabel>
                                <TextField
                                    value={itemDetail?.width}
                                    onInput={handleWidth}
                                    fullWidth
                                    variant='outlined'
                                    placeholder={intl.get('ITEM_LIST.WIDTH')}
                                    type='number'
                                    size='small'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {itemDetail?.lengthUnit}
                                            </InputAdornment>
                                        ),
                                        inputProps: {
                                            min: 1
                                        },
                                        style: {fontSize: 14} // font size of input text
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} xs={4}>
                            <FormControl fullWidth>
                                <FormLabel required>
                                    {intl.get('ITEM_LIST.HEIGHT')}
                                </FormLabel>
                                <TextField
                                    value={itemDetail?.height}
                                    onInput={handleHeight}
                                    fullWidth
                                    variant='outlined'
                                    placeholder={intl.get('ITEM_LIST.HEIGHT')}
                                    type='number'
                                    size='small'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {itemDetail?.lengthUnit}
                                            </InputAdornment>
                                        ),
                                        inputProps: {
                                            min: 1
                                        },
                                        style: {fontSize: 14} // font size of input text
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} xs={4}>
                            <FormControl fullWidth>
                                <FormLabel required>
                                    {intl.get('ITEM_LIST.WEIGHT')}
                                </FormLabel>
                                <TextField
                                    value={itemDetail?.weight}
                                    onInput={handleWeight}
                                    fullWidth
                                    variant='outlined'
                                    placeholder={intl.get('ITEM_LIST.WEIGHT')}
                                    type='number'
                                    size='small'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {itemDetail?.weightUnit}
                                            </InputAdornment>
                                        ),
                                        inputProps: {
                                            min: 1
                                        },
                                        style: {fontSize: 14} // font size of input text
                                    }}
                                />
                            </FormControl>
                        </Grid>

                    </Grid>
                </Grid>
                <Grid item md={2} xs={12}>
                    <Grid container spacing={1}>
                        <Grid item md={4} xs={4}>
                            <Box sx={{
                                display: 'flex',
                                height: '100%',
                                alignItems: 'flex-end',
                                justifyContent: 'center'
                            }}>
                                <CloseIcon style={{
                                    fontSize: '35px',
                                    color: '#1D8B45'
                                }}/>
                            </Box>
                        </Grid>
                        <Grid item md={8} xs={4}>
                            <FormControl fullWidth>
                                <FormLabel required>
                                    {intl.get('ITEM_LIST.QUANTITY')}
                                </FormLabel>
                                <TextField
                                    value={itemDetail?.quantity}
                                    onInput={handleQuantity}
                                    fullWidth
                                    variant='outlined'
                                    placeholder={intl.get('ITEM_LIST.QUANTITY')}
                                    type='number'
                                    size='small'
                                    InputProps={{
                                        inputProps: {
                                            min: 1
                                        },
                                        style: {fontSize: 14} // font size of input text
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={1} xs={12}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: isMobile ? 'flex-end' : 'flex-start',
                        justifyContent: isMobile ? 'flex-end' : 'flex-start',
                        height: '100%',
                        width: '100%',
                        gap: '10px'
                    }}>
                        <IconButton
                            color='success'
                            onClick={handleDuplicate}
                            sx={{padding: 0}}
                        >
                            <img src={duplicate} width='30px'/>
                        </IconButton>
                        <IconButton
                            color='error'
                            onClick={handleRemove}
                            sx={{padding: 0}}
                        >
                            <img src={remove} width='30px'/>
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}