import Grid from "@mui/material/Grid";
import MuiAlert from "@mui/material/Alert";
import * as React from "react";
import {Button, Snackbar} from "@mui/material";
import {getAccessToken} from "../../Utils/doToken";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import {useState} from "react";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {
    extractFullAddress,
    getCountryCodeByName,
    getMissingKeysForQuickQuotePalletContact,
    isValidatePhone
} from "../../Utils/Helper";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@mui/material/TextField";
import getProvinceCodeByName from "../../Utils/getProvinceCode";
import intl from "react-intl-universal";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingPackageQuickQuotePlaceOrderSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    ShippingPackageQuickQuotePlaceOrderRoot: {
        marginTop: '20px'
    },
    ShippingPackageQuickQuotePlaceOrderTitle: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageQuickQuotePlaceHolderHeading: {
        fontSize: '20px',
        textAlign: 'left'
    },
    ShippingPackageQuickQuotePlaceOrderBox: {
        padding: '20px',
        // border: '1px solid #000000',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    },
    ShippingPackageQuickQuotePlaceOrderErrorText: {
        fontSize: '12px',
        color: "#FF0303"
    }
}
const requiredProperties = ["UOM", "currency", "priceEach", "hscode", "originCountryCode"];

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "4px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

export const ShippingPackageQuickQuotePlaceOrder = ({rateInfo, loadingSelection}) => {

    const token = getAccessToken("access_token");

    const classes = useStyles();

    const history = useHistory();

    const isMobile = useMediaQuery('(max-width: 600px)');

    let signatureOptions = useSelector(state => state?.shippingPackage?.quickQuoteSignatureOptions);
    let batteryDetails = useSelector(state => state?.shippingPackage?.quickQuoteBatteryDetails);
    let packageListRedux = useSelector(state => state?.shippingPackage?.quickQuotePackageList);
    let shipFromRedux = useSelector(state => state?.shippingPackage?.quickQuoteShipFrom);
    let shipToRedux = useSelector(state => state?.shippingPackage?.quickQuoteShipTo);
    let crossBorder = useSelector(state => state?.shippingPackage?.quickQuoteCrossBorder);
    const userInfo = useSelector((state) => state.user);

    const [loading, setLoading] = useState(false);
    const [loadingLabel, setLoadingLabel] = useState(false);
    const [shipFrom, setShipFrom] = useState(shipFromRedux);
    const [shipTo, setShipTo] = useState(shipToRedux);
    const [shipFromMatch, setShipFromMatch] = useState(true);
    const [shipToMatch, setShipToMatch] = useState(true);

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const [shipFromAddressTwo, setShipFromAddressTwo] = useState('');
    const [shipToAddressTwo, setShipToAddressTwo] = useState('');
    const [refOrderNumber, setRefOrderNumber] = useState('');

    const [shipFromContact, setShipFromContact] = useState({
        name: '',
        company: '',
        phone: null,
        email: ''
    })

    const [shipToContact, setShipToContact] = useState({
        name: '',
        company: '',
        phone: null,
        email: ''
    })

    const [shipFromContactError, setShipFromContactError] = useState([]);
    const [shipToContactError, setShipToContactError] = useState([]);

    const [movingFromAddressPredictions, setMovingFromAddressPredictions] = useState([]);
    const [movingToAddressPredictions, setMovingToAddressPredictions] = useState([]);

    const [labelImage, setLabelImage] = useState();

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const googleMapsPlaceAutocomplete = async (inputValue, types, city) => {
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: types
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log(predictions);

            const filterPredictions = predictions.filter(address => address?.structured_formatting?.secondary_text.includes(city));
            const updatedPredictions = filterPredictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            return updatedPredictions;
        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const handleInputShipFromAddress = async (event, value) => {
        setShipFrom(prevState => ({
            ...prevState,
            address: value
        }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'address', shipFromRedux?.city);
        setMovingFromAddressPredictions(predictions);
    }

    const handleInputShipToAddress = async (event, value) => {
        setShipTo(prevState => ({
            ...prevState,
            address: value
        }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'address', shipToRedux?.city);
        setMovingToAddressPredictions(predictions);
    }

    const handleChangeShipFromAddress = async (event, value) => {
        console.log('select', value);
        const place = await googleMapsPlaceDetails(value?.placeId);

        if (value) {
            const address = extractFullAddress(place);
            console.log('address', address);
            if (address?.zip === shipFrom?.postalCode) {
                setShipFrom(prevState => ({
                    ...prevState,
                    address: value?.description,
                    displayAddress: value
                }))
                setShipFromMatch(true);
            } else {
                setShipFromMatch(false);
            }
        } else {
            setShipFrom(prevState => ({
                ...prevState,
                address: '',
                displayAddress: {
                    description: '',
                    placeId: '',
                    text: ''
                }
            }))
        }
    }

    const handleChangeShipToAddress = async (event, value) => {
        console.log('select', value);

        const place = await googleMapsPlaceDetails(value?.placeId);

        if (value) {
            const address = extractFullAddress(place);
            console.log('address', address);
            if (address?.zip === shipTo?.postalCode) {
                setShipTo(prevState => ({
                    ...prevState,
                    address: value?.description,
                    displayAddress: value
                }))
                setShipToMatch(true);
            } else {
                setShipToMatch(false);
            }
        } else {
            setShipTo(prevState => ({
                ...prevState,
                address: '',
                displayAddress: {
                    description: '',
                    placeId: '',
                    text: ''
                }
            }))
        }
    }

    const handleShipFromAddressTwo = (event) => {
        setShipFromAddressTwo(event.target.value);
    }

    const handleShipFromName = (event) => {
        setShipFromContact(prevState => ({
            ...prevState,
            name: event.target.value
        }))
    }

    const handleShipFromCompany = (event) => {
        setShipFromContact(prevState => ({
            ...prevState,
            company: event.target.value
        }))
    }

    const handleShipFromPhone = (event) => {
        const trimmedValue = event.target.value.replace(/\s/g, '');
        setShipFromContact(prevState => ({
            ...prevState,
            phone: trimmedValue
        }))
    }

    const handleShipFromEmail = (event) => {
        setShipFromContact(prevState => ({
            ...prevState,
            email: event.target.value
        }))
    }

    const handleShipToAddressTwo = (event) => {
        setShipToAddressTwo(event.target.value);
    }

    const handleShipToName = (event) => {
        setShipToContact(prevState => ({
            ...prevState,
            name: event.target.value
        }))
    }

    const handleShipToCompany = (event) => {
        setShipToContact(prevState => ({
            ...prevState,
            company: event.target.value
        }))
    }

    const handleShipToPhone = (event) => {
        const trimmedValue = event.target.value.replace(/\s/g, '');
        setShipToContact(prevState => ({
            ...prevState,
            phone: trimmedValue
        }))
    }

    const handleShipToEmail = (event) => {
        setShipToContact(prevState => ({
            ...prevState,
            email: event.target.value
        }))
    }

    const handleRefOrderNumber = (event) => {
        setRefOrderNumber(event.target.value)
    }

    const CrossBorderHasMissingValues = () => {
        if (shipFrom?.country?.code === shipTo?.country?.code) {
            return false
        } else {
            const result = packageListRedux.some(object => requiredProperties.some((property) => !object[property]));
            console.log('result', result);
            return result
        }
    }

    const validate = () => {
        setErrorMessage('');
        setToastOpen(false);
        const hasMissingValues = CrossBorderHasMissingValues();
        const shipFromContactMissing = getMissingKeysForQuickQuotePalletContact(shipFromContact);
        const shipToContactMissing = getMissingKeysForQuickQuotePalletContact(shipToContact);
        const shipFromPhoneValidate = isValidatePhone(shipFromContact?.phone);
        const shipToPhoneValidate = isValidatePhone(shipToContact?.phone);

        setShipFromContactError(shipFromContactMissing);
        setShipToContactError(shipToContactMissing);

        shipFromContactMissing?.length !== 0 && setErrorMessage(prevState => prevState + ' Ship From Contact is missing one or more required fields.');
        shipToContactMissing?.length !== 0 && setErrorMessage(prevState => prevState + ' Ship To Contact is missing one or more required fields.');
        hasMissingValues && setErrorMessage(prevState => prevState + ' Your package list has missing one or more required fields in cross border section.');
        !shipFromPhoneValidate && setErrorMessage(prevState => prevState + ' Shipper phone number is invalid.');
        !shipToPhoneValidate && setErrorMessage(prevState => prevState + ' Consignee phone number is invalid.');

        if (shipFromContactMissing.length === 0 &&
            shipToContactMissing.length === 0 &&
            shipFrom?.address &&
            shipTo?.address &&
            shipFromPhoneValidate &&
            shipToPhoneValidate &&
            !hasMissingValues
        ) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const initBackendOrder = async () => {
        setLoading(true);
        let requestURL = `${NODE_ROUTE_URI}/looseItem/endUser/init3PLOrder`;

        const data = {
            accountBaseCombinedName: rateInfo?.accountBaseCombineName,
            serviceType: rateInfo?.serviceType,
            shipFromName: shipFromContact?.name,
            shipFromCompanyName: shipFromContact?.company || shipFromContact?.name,
            shipFromPhone: shipFromContact?.phone,
            shipFromEmail: shipFromContact?.email,
            shipFromAddress: shipFrom?.displayAddress?.text,
            shipFromCity: shipFrom?.city,
            shipFromProvince: shipFrom?.province?.code,
            shipFromCountry: shipFrom?.country?.code,
            shipFromPostalCode: shipFrom?.postalCode.replace(/\s+/g, ''),
            shipToName: shipToContact?.name,
            shipToCompanyName: shipToContact?.company || shipToContact?.name,
            shipToPhone: shipToContact?.phone,
            shipToEmail: shipToContact?.email,
            shipToAddress: shipTo?.displayAddress?.text,
            shipToAddressTwo: shipTo?.address2,
            shipToCity: shipTo?.city,
            shipToProvince: shipTo?.province?.code,
            shipToCountry: shipTo?.country?.code,
            shipToPostalCode: shipTo?.postalCode.replace(/\s+/g, ''),
            cargoControlNumber: "",
            refOrderNumber: refOrderNumber,
            shipmentType: shipFrom?.country?.code === shipTo?.country?.code ? null : crossBorder?.shipmentType,
            transMode: shipFrom?.country?.code === shipTo?.country?.code ? null : crossBorder?.tranMode,
            packageData: packageListRedux,
            packingList: []
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            })
            console.log('api init backend order', result);
            setErrorMessage('');

            setSuccessMessage('Successfully place order');
            handleSuccessMessage();
            // handleConfirmDialogClose();
            setLoading(false);
            // setDisabled(true);
            // Go to order detail page after 2 second
            setTimeout(() => {
                history.push(`/shippingOrderDetails/${result?.data?.id}`);
            }, 2000);
        } catch (e) {
            console.log(e.response);
            handleErrorMessage();
            setErrorMessage(`Fail to place order.`);
            setLoading(false);
        }
    }

    const handlePlaceOrder = () => {
        const validationResult = validate();
        if (validationResult) {
            console.log('call api');
            initBackendOrder();
        }
    }

    const handlePlaceCrossBorderOrder = () => {
        history.push({
            pathname: '/shipping-package/create-label',
            state: {
                shipFrom: shipFromRedux,
                shipTo: shipToRedux,
                packageList: packageListRedux,
                batteryDetails: batteryDetails,
                signatureOptions: signatureOptions
            }
        });
    }

    // console.log('package redux', packageListRedux);

    return (
        <Box sx={styles.ShippingPackageQuickQuotePlaceOrderRoot}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box sx={{display: 'flex', width: '100%', justifyContent: 'flex-end', gap: '20px'}}>
                <Button
                    variant='contained'
                    color='success'
                    size='large'
                    onClick={handlePlaceCrossBorderOrder}
                    disabled={loadingSelection}
                    // // disabled={packageListRedux.length === 0 ? true : false}
                >
                    Continue Place Order
                </Button>
            </Box>
            {/*<Grid container spacing={2}>*/}
            {/*    <Grid item xs={12}>*/}
            {/*        <Typography sx={styles.ShippingPackageQuickQuotePlaceOrderTitle}>*/}
            {/*            Additional Information*/}
            {/*        </Typography>*/}
            {/*    </Grid>*/}
            {/*    <Grid item xs={12}>*/}
            {/*        <Box sx={styles.ShippingPackageQuickQuotePlaceOrderBox}>*/}
            {/*            <Grid container spacing={2}>*/}
            {/*                <Typography style={styles.ShippingPackageQuickQuotePlaceHolderHeading}>*/}
            {/*                    Reference Order Number*/}
            {/*                </Typography>*/}
            {/*                <Grid item xs={12}>*/}
            {/*                    <Box sx={styles.ShippingPackageQuickQuotePlaceOrderSection}>*/}
            {/*                        <InputLabel>Reference Order Number</InputLabel>*/}
            {/*                        <TextField*/}
            {/*                            value={refOrderNumber}*/}
            {/*                            variant="outlined"*/}
            {/*                            onInput={handleRefOrderNumber}*/}
            {/*                            size='small'*/}
            {/*                        />*/}
            {/*                    </Box>*/}
            {/*                </Grid>*/}
            {/*            </Grid>*/}
            {/*        </Box>*/}
            {/*    </Grid>*/}
            {/*    <Grid item xs={12} md={6}>*/}
            {/*        <Box sx={styles.ShippingPackageQuickQuotePlaceOrderBox}>*/}
            {/*            <Grid container spacing={2}>*/}
            {/*                <Typography style={styles.ShippingPackageQuickQuotePlaceHolderHeading}>*/}
            {/*                    Ship From*/}
            {/*                </Typography>*/}
            {/*                <Grid item xs={12}>*/}
            {/*                    <Box sx={styles.ShippingPackageQuickQuotePlaceOrderSection}>*/}
            {/*                        <InputLabel required>Address</InputLabel>*/}
            {/*                        <Autocomplete*/}
            {/*                            value={shipFrom?.displayAddress}*/}
            {/*                            options={movingFromAddressPredictions}*/}
            {/*                            getOptionLabel={option => option?.description}*/}
            {/*                            onInputChange={handleInputShipFromAddress}*/}
            {/*                            onChange={handleChangeShipFromAddress}*/}
            {/*                            fullWidth*/}
            {/*                            PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}*/}
            {/*                            renderInput={params => <TextField*/}
            {/*                                {...params}*/}
            {/*                                variant='outlined'*/}
            {/*                                className={classes.smallInput}*/}
            {/*                            />}*/}
            {/*                            renderOption={(option) => (*/}
            {/*                                <Typography*/}
            {/*                                    style={{fontSize: '12px'}}*/}
            {/*                                >*/}
            {/*                                    /!*<Typography>*!/*/}
            {/*                                    {option.description}*/}
            {/*                                    /!*</Typography>*!/*/}
            {/*                                </Typography>*/}
            {/*                            )}*/}
            {/*                        />*/}
            {/*                        <Typography style={styles.ShippingPackageQuickQuotePlaceOrderErrorText}>*/}
            {/*                            {*/}
            {/*                                !shipFrom?.address ? "This is required field." :*/}
            {/*                                    shipFromMatch ? "" : 'The address provided does not match the provided postal code.'*/}
            {/*                            }*/}
            {/*                        </Typography>*/}
            {/*                    </Box>*/}
            {/*                </Grid>*/}
            {/*                <Grid item xs={12}>*/}
            {/*                    <Box sx={styles.ShippingPackageQuickQuotePlaceOrderSection}>*/}
            {/*                        <InputLabel>Address Two</InputLabel>*/}
            {/*                        <TextField*/}
            {/*                            value={shipFromAddressTwo}*/}
            {/*                            fullWidth*/}
            {/*                            variant="outlined"*/}
            {/*                            onInput={handleShipFromAddressTwo}*/}
            {/*                            size='small'*/}
            {/*                        />*/}
            {/*                    </Box>*/}
            {/*                </Grid>*/}
            {/*                <Grid item md={6} xs={12}>*/}
            {/*                    <Box sx={styles.ShippingPackageQuickQuotePlaceOrderSection}>*/}
            {/*                        <InputLabel required>Name</InputLabel>*/}
            {/*                        <TextField*/}
            {/*                            value={shipFromContact?.name}*/}
            {/*                            fullWidth*/}
            {/*                            variant="outlined"*/}
            {/*                            onInput={handleShipFromName}*/}
            {/*                            size='small'*/}
            {/*                        />*/}
            {/*                        <Typography style={styles.ShippingPackageQuickQuotePlaceOrderErrorText}>*/}
            {/*                            {*/}
            {/*                                shipFromContactError.includes('name') ? "This is required field." : ""*/}
            {/*                            }*/}
            {/*                        </Typography>*/}
            {/*                    </Box>*/}
            {/*                </Grid>*/}
            {/*                <Grid item md={6} xs={12}>*/}
            {/*                    <Box sx={styles.ShippingPackageQuickQuotePlaceOrderSection}>*/}
            {/*                        <InputLabel required>Phone</InputLabel>*/}
            {/*                        <TextField*/}
            {/*                            value={shipFromContact?.phone}*/}
            {/*                            fullWidth*/}
            {/*                            variant="outlined"*/}
            {/*                            onInput={handleShipFromPhone}*/}
            {/*                            size='small'*/}
            {/*                        />*/}
            {/*                        <Typography style={styles.ShippingPackageQuickQuotePlaceOrderErrorText}>*/}
            {/*                            {*/}
            {/*                                shipFromContact?.phone === null ? "" :*/}
            {/*                                    shipFromContactError.includes('phone') ? "This is required field." :*/}
            {/*                                        isValidatePhone(shipFromContact?.phone) ? "" :*/}
            {/*                                            "Please Enter a Validate Phone Number."*/}
            {/*                            }*/}
            {/*                        </Typography>*/}
            {/*                    </Box>*/}
            {/*                </Grid>*/}
            {/*                <Grid item md={6} xs={12}>*/}
            {/*                    <Box sx={styles.ShippingPackageQuickQuotePlaceOrderSection}>*/}
            {/*                        <InputLabel>Company Name</InputLabel>*/}
            {/*                        <TextField*/}
            {/*                            value={shipFromContact?.company}*/}
            {/*                            fullWidth*/}
            {/*                            variant="outlined"*/}
            {/*                            onInput={handleShipFromCompany}*/}
            {/*                            size='small'*/}
            {/*                        />*/}
            {/*                    </Box>*/}
            {/*                </Grid>*/}
            {/*                <Grid item md={6} xs={12}>*/}
            {/*                    <Box sx={styles.ShippingPackageQuickQuotePlaceOrderSection}>*/}
            {/*                        <InputLabel>Email</InputLabel>*/}
            {/*                        <TextField*/}
            {/*                            value={shipFromContact?.email}*/}
            {/*                            fullWidth*/}
            {/*                            variant="outlined"*/}
            {/*                            onInput={handleShipFromEmail}*/}
            {/*                            placeholder='Recommended'*/}
            {/*                            size='small'*/}
            {/*                        />*/}
            {/*                    </Box>*/}
            {/*                </Grid>*/}
            {/*            </Grid>*/}
            {/*        </Box>*/}
            {/*    </Grid>*/}
            {/*    <Grid item xs={12} md={6}>*/}
            {/*        <Box sx={styles.ShippingPackageQuickQuotePlaceOrderBox}>*/}
            {/*            <Grid container spacing={2}>*/}
            {/*                <Typography style={styles.ShippingPackageQuickQuotePlaceHolderHeading}>*/}
            {/*                    Ship To*/}
            {/*                </Typography>*/}
            {/*                <Grid item xs={12}>*/}
            {/*                    <Box sx={styles.ShippingPackageQuickQuotePlaceOrderSection}>*/}
            {/*                        <InputLabel required>Address</InputLabel>*/}
            {/*                        <Autocomplete*/}
            {/*                            value={shipTo?.displayAddress}*/}
            {/*                            options={movingToAddressPredictions}*/}
            {/*                            getOptionLabel={option => option?.description}*/}
            {/*                            onInputChange={handleInputShipToAddress}*/}
            {/*                            onChange={handleChangeShipToAddress}*/}
            {/*                            fullWidth*/}
            {/*                            PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}*/}
            {/*                            renderInput={params => <TextField*/}
            {/*                                {...params}*/}
            {/*                                variant='outlined'*/}
            {/*                                className={classes.smallInput}*/}
            {/*                            />}*/}
            {/*                            renderOption={(option) => (*/}
            {/*                                <Typography*/}
            {/*                                    style={{fontSize: '12px'}}*/}
            {/*                                >*/}
            {/*                                    /!*<Typography>*!/*/}
            {/*                                    {option.description}*/}
            {/*                                    /!*</Typography>*!/*/}
            {/*                                </Typography>*/}
            {/*                            )}*/}
            {/*                        />*/}
            {/*                        <Typography style={styles.ShippingPackageQuickQuotePlaceOrderErrorText}>*/}
            {/*                            {*/}
            {/*                                !shipTo?.address ? "This is required field." :*/}
            {/*                                    shipToMatch ? "" : 'The address provided does not match the provided postal code.'*/}
            {/*                            }*/}
            {/*                        </Typography>*/}
            {/*                    </Box>*/}
            {/*                </Grid>*/}
            {/*                <Grid item xs={12}>*/}
            {/*                    <Box sx={styles.ShippingPackageQuickQuotePlaceOrderSection}>*/}
            {/*                        <InputLabel>Address Two</InputLabel>*/}
            {/*                        <TextField*/}
            {/*                            value={shipToAddressTwo}*/}
            {/*                            fullWidth*/}
            {/*                            variant="outlined"*/}
            {/*                            onInput={handleShipToAddressTwo}*/}
            {/*                            size='small'*/}
            {/*                        />*/}
            {/*                    </Box>*/}
            {/*                </Grid>*/}
            {/*                <Grid item md={6} xs={12}>*/}
            {/*                    <Box sx={styles.ShippingPackageQuickQuotePlaceOrderSection}>*/}
            {/*                        <InputLabel required>Name</InputLabel>*/}
            {/*                        <TextField*/}
            {/*                            value={shipToContact?.name}*/}
            {/*                            fullWidth*/}
            {/*                            variant="outlined"*/}
            {/*                            onInput={handleShipToName}*/}
            {/*                            size='small'*/}
            {/*                        />*/}
            {/*                        <Typography style={styles.ShippingPackageQuickQuotePlaceOrderErrorText}>*/}
            {/*                            {*/}
            {/*                                shipToContactError.includes('name') ? "This is required field." : ""*/}
            {/*                            }*/}
            {/*                        </Typography>*/}
            {/*                    </Box>*/}
            {/*                </Grid>*/}
            {/*                <Grid item md={6} xs={12}>*/}
            {/*                    <Box sx={styles.ShippingPackageQuickQuotePlaceOrderSection}>*/}
            {/*                        <InputLabel required>Phone</InputLabel>*/}
            {/*                        <TextField*/}
            {/*                            value={shipToContact?.phone}*/}
            {/*                            fullWidth*/}
            {/*                            variant="outlined"*/}
            {/*                            onInput={handleShipToPhone}*/}
            {/*                            size='small'*/}
            {/*                        />*/}
            {/*                        <Typography style={styles.ShippingPackageQuickQuotePlaceOrderErrorText}>*/}
            {/*                            {*/}
            {/*                                shipToContact?.phone === null ? "" :*/}
            {/*                                    shipToContactError.includes('phone') ? "This is required field." :*/}
            {/*                                        isValidatePhone(shipToContact?.phone) ? "" :*/}
            {/*                                            "Please Enter a Validate Phone Number."*/}
            {/*                            }*/}
            {/*                        </Typography>*/}
            {/*                    </Box>*/}
            {/*                </Grid>*/}
            {/*                <Grid item md={6} xs={12}>*/}
            {/*                    <Box sx={styles.ShippingPackageQuickQuotePlaceOrderSection}>*/}
            {/*                        <InputLabel>Company Name</InputLabel>*/}
            {/*                        <TextField*/}
            {/*                            value={shipToContact?.company}*/}
            {/*                            fullWidth*/}
            {/*                            variant="outlined"*/}
            {/*                            onInput={handleShipToCompany}*/}
            {/*                            size='small'*/}
            {/*                        />*/}
            {/*                    </Box>*/}
            {/*                </Grid>*/}
            {/*                <Grid item md={6} xs={12}>*/}
            {/*                    <Box sx={styles.ShippingPackageQuickQuotePlaceOrderSection}>*/}
            {/*                        <InputLabel>Email</InputLabel>*/}
            {/*                        <TextField*/}
            {/*                            value={shipToContact?.email}*/}
            {/*                            fullWidth*/}
            {/*                            variant="outlined"*/}
            {/*                            onInput={handleShipToEmail}*/}
            {/*                            placeholder='Recommended'*/}
            {/*                            size='small'*/}
            {/*                        />*/}
            {/*                    </Box>*/}
            {/*                </Grid>*/}
            {/*            </Grid>*/}
            {/*        </Box>*/}
            {/*    </Grid>*/}
            {/*    <Grid item xs={12}>*/}
            {/*        <Box sx={{display: 'flex', width: '100%', justifyContent: 'flex-end', gap: '20px'}}>*/}
            {/*            /!*<LoadingButton*!/*/}
            {/*            /!*    variant='contained'*!/*/}
            {/*            /!*    color='success'*!/*/}
            {/*            /!*    size='large'*!/*/}
            {/*            /!*    loading={loadingLabel}*!/*/}
            {/*            /!*    // onClick={handleGetLabel}*!/*/}
            {/*            /!*    // // disabled={packageListRedux.length === 0 ? true : false}*!/*/}
            {/*            /!*>*!/*/}
            {/*            /!*    Sample Label*!/*/}
            {/*            /!*</LoadingButton>*!/*/}
            {/*            <LoadingButton*/}
            {/*                variant='contained'*/}
            {/*                color='success'*/}
            {/*                size='large'*/}
            {/*                loading={loading}*/}
            {/*                onClick={handlePlaceOrder}*/}
            {/*                // // disabled={packageListRedux.length === 0 ? true : false}*/}
            {/*            >*/}
            {/*                Place Order*/}
            {/*            </LoadingButton>*/}
            {/*        </Box>*/}
            {/*    </Grid>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={12}>*/}
            {/*    {*/}
            {/*        labelImage &&*/}
            {/*        <Box sx={{display: 'flex', justifyContent: 'center', margin: '40px 0'}}>*/}
            {/*            <img*/}
            {/*                src={`data:image/jpeg;base64,${labelImage}`}*/}
            {/*                alt="label"*/}
            {/*                width={isMobile ? 300 : 700}*/}
            {/*                height="auto"*/}
            {/*            />*/}
            {/*        </Box>*/}
            {/*    }*/}
            {/*</Grid>*/}
        </Box>

    )
}