import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import intl from "react-intl-universal";
import {getAccessToken} from "../../Utils/doToken";
import {PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {triggerBase64Download} from "common-base64-downloader-react";
import Button from "@mui/material/Button";
import {useHistory} from "react-router-dom";

export const MovingOrderDashboardDocuments = ({orderDetails}) => {

    const history = useHistory();

    const [invoiceDetail, setInvoiceDetail] = useState(null);
    const [invoiceLoading, setInvoiceLoading] = useState(true);

    const getInvoiceNumber = async () => {
        setInvoiceLoading(true);
        const storedToken = getAccessToken("access_token");
        const requestURI = `${PARTNER_URI}/billingCenter/getInvoiceInfoByInvoiceId`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    invoiceId: orderDetails?.crm_invoice_no
                }
            })
            console.log('result', result);
            setInvoiceDetail(result?.data);
        } catch (e) {
            console.log('e', e?.response);
        } finally {
            setInvoiceLoading(false);
        }
    }

    const downloadPDFByInvoiceId = async () => {
        try {
            const storedToken = getAccessToken("access_token");
            const requestURL = `${PARTNER_URI}/billingCenter/downloadInvoice`;
            const {data} = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    invoiceId: orderDetails?.crm_invoice_no
                }
            })
            const content = data.content;
            const contentType = data["content-type"];
            const pdfContent = `data:${contentType};base64,${content}`;
            const fileName = data.filename?.length > 4 ? data.filename.slice(0, data.filename.length - 4) : data.filename;
            triggerBase64Download(pdfContent, fileName);
        } catch (e) {
            console.log(e.response);
        }
    }

    const handleDownInvoice = () => {
        downloadPDFByInvoiceId();
    }

    const handlePayInvoice = () => {
        history.push('/business/billing-center')
    }

    useEffect(() => {
        orderDetails?.crm_invoice_no ? getInvoiceNumber() : setInvoiceLoading(false);
    }, [orderDetails]);

    return (
        <Box sx={{
            border: '1px solid #B2B2B2',
            borderRadius: '10px',
            padding: '10px 0'
        }}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '20px',
                        borderBottom: '1px solid #B2B2B2',
                        alignItems: 'center'
                    }}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            Invoice
                        </Typography>
                    </Box>
                </Grid>
                {
                    invoiceDetail &&
                    <>
                        <Grid item xs={7}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                justifyContent: 'flex-start',
                                padding: '0 10px'
                            }}>
                                <Typography>
                                    {intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.INVOICE_NO')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography style={{
                                textTransform: 'none',
                                color: '#B454545',
                            }}>
                                {invoiceDetail?.invoiceRefNumber}
                            </Typography>
                        </Grid>
                    </>
                }
                <Grid item xs={7}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-start',
                        padding: '0 10px'
                    }}>
                        <Typography>
                            {intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.INVOICE_STATUS')}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    <Typography style={{
                        textTransform: 'none',
                        color: !orderDetails?.crm_invoice_no ? '#454545' : invoiceDetail?.status === "2" ? '#1D8B45' : '#FF0303',
                    }}>
                        {
                            !orderDetails?.crm_invoice_no ? intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.NOT_BILLED') : invoiceDetail?.status === "2" ? intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.PAID') : intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.UNPAID')
                        }
                    </Typography>
                </Grid>
                {
                    invoiceDetail &&
                    <>
                        <Grid item xs={7}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                justifyContent: 'flex-start',
                                padding: '0 10px'
                            }}>
                                <Typography>
                                    {intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.INVOICE_DETAILS')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={5}>
                            <Button
                                variant='outlined'
                                sx={{
                                    border: '2px solid #1D8B45',
                                    "&:hover": {
                                        border: '2px solid #1D8B45',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                onClick={handleDownInvoice}
                            >
                                <Typography style={{
                                    textTransform: 'none',
                                    fontSize: '14px',
                                    color: '#1D8B45',
                                }}>
                                    {intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.DOWNLOAD')}
                                </Typography>
                            </Button>
                        </Grid>
                    </>
                }
                {
                    invoiceDetail?.status === "1" &&
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <Button
                                variant='contained'
                                sx={{
                                    backgroundColor: '#1D8B45',
                                    "&:hover": {
                                        backgroundColor: '#1D8B45',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                size='small'
                                onClick={handlePayInvoice}
                            >
                                <Typography sx={{textTransform: 'none', fontSize: '14px'}}>
                                    Pay Invoice
                                </Typography>
                            </Button>
                        </Box>
                    </Grid>
                }
            </Grid>
        </Box>

    )
}