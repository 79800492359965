import {
  DRIVER_ADD_SHIPMENTS_TO_SUMMARY,
  DRIVER_REMOVE_SHIPMENTS_FROM_SUMMARY,
  DRIVER_UPDATE_SHIPMENTS_IN_SUMMARY,
  DRIVER_CLAIM_SHIPMENTS_FROM_SUMMARY,
  DRIVER_CLEAN_ALL_SHIPMENTS_FROM_SUMMARY,
} from "./types";

export const driverAddShipmentsToSummary = (
  shipmentId,
  packageNumber,
  packageId,
  count,
  itemName,
  unitPrice,
  availableCount,
  shipmentType,
  weight,
  stage
) => {
  return {
    type: DRIVER_ADD_SHIPMENTS_TO_SUMMARY,
    payload: {
      itemName: itemName,
      shipmentId: shipmentId,
      packageId: packageId,
      unitPrice: unitPrice,
      count: count,
      availableCount: availableCount,
      shipmentType: shipmentType,
      weight: weight,
      stage: stage,
    },
  };
};

export const driverRemoveShipmentsFromSummary = (
  shipmentId,
  packageId,
  count
) => {
  return {
    type: DRIVER_REMOVE_SHIPMENTS_FROM_SUMMARY,
    payload: {
      shipmentId: shipmentId,
      packageId: packageId,
      count: count,
    },
  };
};

export const driverUpdateShipmentsInSummary = (
  shipmentId,
  packageId,
  count,
  itemName,
  unitPrice,
  availableCount,
  shipmentType,
  weight,
  stage
) => {
  return {
    type: DRIVER_UPDATE_SHIPMENTS_IN_SUMMARY,
    payload: {
      itemName: itemName,
      shipmentId: shipmentId,
      packageId: packageId,
      unitPrice: unitPrice,
      count: count,
      availableCount: availableCount,
      shipmentType: shipmentType,
      weight: weight,
      stage: stage,
    },
  };
};

export const driverClaimShipmentsFromSummary = (shipments) => {
  return {
    type: DRIVER_CLAIM_SHIPMENTS_FROM_SUMMARY,
    payload: {
      claimedShipments: shipments,
    },
  };
};

export const driverClearAllShipmentsFromSummary = () => {
  return {
    type: DRIVER_CLEAN_ALL_SHIPMENTS_FROM_SUMMARY,
  };
};
