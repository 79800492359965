import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddBoxIcon from '@mui/icons-material/AddBox';
import {Stack, Switch} from "@mui/material";
import {styled} from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import {useEffect, useState} from "react";
import {ShippingLTLQuickQuotePalletEach} from "./ShippingLTLQuickQuotePalletEach";
import {generateRandomString, getAmountOfFTLCount} from "../../../Utils/Helper";
import {useDispatch, useSelector} from "react-redux";
import {
    addShippingLTLQuickQuotePalletList,
    updateShippingLTLQuickQuoteFTLCount,
    updateShippingLTLQuickQuoteUnit
} from "../../../actions/shippingLTLAction";
import addPallet from "../../../images/Create Label.png";
import intl from "react-intl-universal";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";

const styles = {
    ShippingLTLQuickQuotePalletHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingLTLQuickQuotePalletSection: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    ShippingLTLQuickQuotePalletBox: {
        display: 'flex',
        justifyContent: 'space-between',
    }
}

const AntSwitch = styled(Switch)(({theme}) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#609966' : '#609966',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

export const ShippingLTLQuickQuotePallet = ({palletListRedux, isGetRate, hasMissingValue}) => {

    const dispatch = useDispatch();

    let metric = useSelector(state => state?.shippingLTL?.quickQuoteUnit);

    // let palletListRedux = useSelector(state => state?.shippingLTL?.quickQuotePalletList);

    const [palletList, setPalletList] = useState([]);
    const [itemId, setItemId] = useState(1);
    const [FTL, setFTL] = useState(0);

    const handleFTL = (event) => {
        const value = event?.target?.value;
        if (/^\d*\.?\d*$/.test(value)) {
            setFTL(value);
        }
    }

    const handleMetric = (event) => {
        dispatch(updateShippingLTLQuickQuoteUnit(event.target.checked))
    }

    const handleAddPallet = () => {

        const palletId = generateRandomString(8);
        const newPallet = {
            description: '',
            packagingType: metric ? {name: '122cmx102cm', length: '122', width: '102'} : {
                name: '48"x40"',
                length: '48',
                width: '40'
            },
            length: metric ? '122' : '48',
            width: metric ? '102' : '40',
            height: metric ? '152' : '60',
            weight: metric ? '180' : '400',
            lengthUnit: metric ? 'cm' : 'in',
            weightUnit: metric ? 'kg' : 'lb',
            UOM: '',
            hscode: '',
            originCountryCode: '',
            currency: '',
            priceEach: '',
            quantity: '1',
            palletId: palletId
        }
        dispatch(addShippingLTLQuickQuotePalletList(newPallet));
        setItemId(prevState => prevState + 1);
    }

    useEffect(() => {
        setPalletList(palletListRedux);
    }, [palletListRedux]);

    useEffect(() => {
        if (palletList?.length > 0) {
            const FTLCount = getAmountOfFTLCount(palletList);
            setFTL(FTLCount);
        }
    }, [palletList]);

    useEffect(() => {
        dispatch(updateShippingLTLQuickQuoteFTLCount(FTL));
    }, [FTL]);

    console.log('pallet list redux', palletListRedux)

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Box sx={styles.ShippingLTLQuickQuotePalletSection}>
                    <Typography sx={styles.ShippingLTLQuickQuotePalletHeading}>
                        {intl.get('ADD_PALLET.PALLET_INFORMATION')}
                    </Typography>
                    <Box sx={styles.ShippingLTLQuickQuotePalletBox}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <AntSwitch
                                checked={metric}
                                onChange={handleMetric}
                                inputProps={{'aria-label': 'ant design'}}
                                disabled={isGetRate}
                            />
                            <Typography>
                                {intl.get('ADD_PALLET.METRIC')}
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <IconButton
                                sx={{color: '#609966'}}
                                onClick={handleAddPallet}
                                disabled={isGetRate}
                            >
                                <img src={addPallet} width='35px'/>
                            </IconButton>
                            <Typography>
                                {intl.get('ADD_PALLET.ADD_NEW_BTN')}
                            </Typography>
                        </Stack>
                    </Box>
                </Box>
            </Grid>
            {
                palletList?.length > 0
                    ?
                    <>
                        {
                            palletList?.map((eachPallet, index) =>
                                <Grid item xs={12} key={index}>
                                    <ShippingLTLQuickQuotePalletEach
                                        palletInfo={eachPallet}
                                        isGetRate={isGetRate}
                                        hasMissingValue={hasMissingValue}
                                    />
                                </Grid>
                            )
                        }
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    <Typography sx={{fontWeight: '600'}}>
                                        FTL
                                    </Typography>
                                </FormLabel>
                                <TextField
                                    value={FTL}
                                    onInput={handleFTL}
                                    type='number'
                                    fullWidth
                                    variant="outlined"
                                    size='small'
                                />
                            </FormControl>
                        </Grid>
                    </>
                    : <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: '1px solid #B7B7B7',
                            padding: '20px',
                            borderRadius: '10px'
                        }}>
                            <Typography
                                style={{fontSize: '20px', fontWeight: '600', color: '#7F8487', marginBottom: '10px'}}>
                                {intl.get('ADD_PALLET.EMPTY_PACKAGE_LIST')}
                            </Typography>
                            <IconButton
                                sx={{color: '#609966'}}
                                onClick={handleAddPallet}
                            >
                                <img src={addPallet} width='35px'/>
                            </IconButton>
                            <Typography style={{fontSize: '12px', fontWeight: '600', color: '#609966'}}>
                                {intl.get('ADD_PALLET.ADD_NEW_TEXT')}
                            </Typography>
                        </Box>
                    </Grid>
            }
        </Grid>
    )
}