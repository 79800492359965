import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Snackbar} from "@mui/material";
import {
    movingOrderPriceDisplay,
    movingOrderStatus, movingOrderToCancel, movingOrderToPay,
    movingShippingStatus
} from "../../Utils/Helper";
import {useHistory, useParams} from "react-router-dom";
import * as React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import {useState} from "react";
import MuiAlert from "@mui/material/Alert";
import {
    LABEL_DOWNLOAD_URL_PREFIX,
    LABEL_DOWNLOAD_URL_PREFIX_ALTERNATE,
    NODE_ROUTE_URI,
    PARTNER_URI
} from "../../Utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../Utils/doToken";
import dayjs from "dayjs";
import {saveAs} from 'file-saver';
import {useSelector} from "react-redux";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    MovingOrderDashboardSummaryCard: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative'
    },
    MovingOrderDashboardSummaryHeading: {
        fontSize: '18px',
        fontWeight: '600'
    },
    MovingOrderDashboardSummaryPaymentOptions: {
        position: 'absolute',
        backgroundColor: '#FFFFFF',
        borderRadius: '5px',
        top: '0px',
        left: '50%'

    }

}

export const MovingOrderDashboardSummary = ({orderDetails, handlePaymentOpen, getMovingOrderDetails}) => {

    const accessToken = getAccessToken("access_token");
    const history = useHistory();
    const ownerRole = useSelector(state => state?.user?.ownerRole);

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [cancelOrderDialogIsOpen, setCancelOrderDialogIsOpen] = useState(false);
    const [isLoadingCancelOrder, setIsLoadingCancelOrder] = useState(false);
    const [acceptDialog, setAcceptDialog] = useState(false);
    const [declineDialog, setDeclineDialog] = useState(false);
    const [pickupConfirmLoading, setPickupConfirmLoading] = useState(false);
    const [deliverConfirmLoading, setDeliverConfirmLoading] = useState(false);
    const [pickupConfirmDialog, setPickupConfirmDialog] = useState(false);
    const [deliverConfirmDialog, setDeliverConfirmDialog] = useState(false);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleCancelOrder = () => {
        setCancelOrderDialogIsOpen(!cancelOrderDialogIsOpen);
    };

    const handleCancelOrderClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            setCancelOrderDialogIsOpen(!cancelOrderDialogIsOpen);
        }
    }

    const cancelOrder = async () => {
        setIsLoadingCancelOrder(true);
        let requestURL = ownerRole === 1 ? `${NODE_ROUTE_URI}/movingService/cancelMovingOrder` :
            `${PARTNER_URI}/movingService/cancelMovingOrder`;
        // console.log('order info', orderInfo);
        try {
            const result = await axios({
                method: 'delete',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                params: {
                    backendOrderId: orderDetails?.order_id
                }
            })
            console.log(result.data)
            setErrorMessage('');
            setSuccessMessage('Successful to cancel the order. Redirect to home page');
            handleSuccessMessage();
            setTimeout(() => {
                history.push(`/`);
            }, 2000);
        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail to cancel the order');
            handleErrorMessage();
        } finally {
            setIsLoadingCancelOrder(false);
        }
    }

    const acceptApproved = async () => {
        let requestURL = `${NODE_ROUTE_URI}/movingService/acceptMovingShippingRequest`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: {
                    backendOrderId: orderDetails?.order_id
                }
            })
            console.log(result.data);
            setErrorMessage('');
            setSuccessMessage('Successful to accept');
            handleSuccessMessage();
            getMovingOrderDetails();
        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail to accept');
            handleErrorMessage();
        } finally {
            // setIsLoadingCancelOrder(false);
        }
    }

    const declineApproved = async () => {
        let requestURL = `${NODE_ROUTE_URI}/movingService/declineMovingShippingRequest`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: {
                    backendOrderId: orderDetails?.order_id
                }
            })
            console.log(result.data)
            setErrorMessage('');
            setSuccessMessage('Successful to decline');
            handleSuccessMessage();
            getMovingOrderDetails();
        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail to decline');
            handleErrorMessage();
        } finally {
            // setIsLoadingCancelOrder(false);
        }
    }

    const handleAccept = () => {
        acceptApproved();
    }

    const handleDecline = () => {
        declineApproved();
    }

    const confirmPickup = async () => {
        setPickupConfirmLoading(true);

        let requestURL = `${NODE_ROUTE_URI}/movingService/confirmPickup`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: {
                    backendOrderId: orderDetails?.order_id
                }
            })
            console.log(result.data)
            setErrorMessage('');
            setSuccessMessage('Successful to confirm pickup');
            handleSuccessMessage();
            handleConfirmPickupDialogClose();
            getMovingOrderDetails();
        } catch (e) {
            setErrorMessage('Fail to confirm pickup');
            handleErrorMessage();
        } finally {
            setPickupConfirmLoading(false);
        }
    }

    const confirmDeliver = async () => {
        setDeliverConfirmLoading(true);
        let requestURL = `${NODE_ROUTE_URI}/movingService/confirmDeliver`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: {
                    backendOrderId: orderDetails?.order_id
                }
            })
            console.log(result.data)
            setErrorMessage('');
            setSuccessMessage('Successful to confirm delivery');
            handleSuccessMessage();
            handleConfirmDeliverDialogClose();
            getMovingOrderDetails();
        } catch (e) {
            setErrorMessage('Fail to confirm delivery');
            handleErrorMessage();
        } finally {
            setDeliverConfirmLoading(false);
        }
    }

    const handleConfirmPickupDialogOpen = () => {
        setPickupConfirmDialog(true);
    }

    const handleConfirmPickupDialogClose = () => {
        setPickupConfirmDialog(false);
    }

    const handleConfirmDeliverDialogOpen = () => {
        setDeliverConfirmDialog(true);
    }

    const handleConfirmDeliverDialogClose = () => {
        setDeliverConfirmDialog(false);
    }

    const handleConfirmPickup = () => {
        confirmPickup();
    }

    const handleConfirmDeliver = () => {
        confirmDeliver();
    }

    const handlePrintLabel = () => {
        const fileURL = `${LABEL_DOWNLOAD_URL_PREFIX}${orderDetails?.label_pdf_uri}`;
        // const fileURL = 'https://storage.googleapis.com/uucargo-resource/tmp/upload/shipping/1714-shipping-package-list-sample_13_.xlsx';
        saveAs(fileURL, 'downloaded-label-file.pdf');
    }

    console.log('[MovingOrderDashboardSummary] order details', orderDetails);

    return (
        <Box sx={{
            border: '1px solid #B2B2B2',
            borderRadius: '10px',
            padding: '10px 0'
        }}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Dialog
                open={cancelOrderDialogIsOpen}
                onClose={handleCancelOrderClose}
            >
                <DialogTitle>Cancel Request Confirmation</DialogTitle>
                <DialogContent>
                    Are you sure you want to cancel your order? This action cannot be undone. Please confirm if you
                    would like to proceed with the request.
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCancelOrder}
                        variant="contained"
                        autoFocus
                    >
                        CLOSE
                    </Button>
                    <LoadingButton
                        onClick={cancelOrder}
                        variant="contained"
                        autoFocus
                        loading={isLoadingCancelOrder}
                    >
                        CONFIRM
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '20px',
                        borderBottom: '1px solid #B2B2B2',
                        alignItems: 'center'
                    }}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            Order Summary
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{padding: '20px'}}>
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    height: '100%',
                                    gap: '5px'
                                }}>
                                    <Typography style={{fontWeight: '600'}}>
                                        Order #:
                                    </Typography>
                                    <Typography style={{textAlign: 'left'}}>
                                        {orderDetails?.order_number}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    height: '100%',
                                    gap: '5px'
                                }}>
                                    <Typography style={{fontWeight: '600'}}>
                                        Order Status:
                                    </Typography>
                                    <Typography style={{textAlign: 'left'}}>
                                        {movingOrderStatus(orderDetails?.status_id)}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    height: '100%',
                                    gap: '5px'
                                }}>
                                    <Typography style={{fontWeight: '600'}}>
                                        Order Type:
                                    </Typography>
                                    <Typography style={{textAlign: 'left'}}>
                                        {orderDetails?.order_package_type}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    height: '100%',
                                    gap: '5px'
                                }}>
                                    <Typography style={{fontWeight: '600'}}>
                                        Shipping Status:
                                    </Typography>
                                    <Typography style={{textAlign: 'left'}}>
                                        {movingShippingStatus(orderDetails?.shipping_status_id)}
                                    </Typography>
                                </Box>
                            </Grid>
                            {
                                orderDetails?.invoiceInfo?.id &&
                                <Grid item md={6} xs={12}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        height: '100%',
                                        gap: '5px'
                                    }}>
                                        <Typography style={{fontWeight: '600'}}>
                                            Invoice #:
                                        </Typography>
                                        <Typography style={{textAlign: 'left'}}>
                                            {orderDetails?.invoiceInfo?.invoiceRefNumber}
                                        </Typography>
                                    </Box>
                                </Grid>
                            }
                            {
                                orderDetails?.bol_no &&
                                <Grid item md={6} xs={12}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        height: '100%',
                                        gap: '5px'
                                    }}>
                                        <Typography style={{fontWeight: '600'}}>
                                            BOL #:
                                        </Typography>
                                        <Link
                                            href={`${LABEL_DOWNLOAD_URL_PREFIX_ALTERNATE}${orderDetails?.bol_doc_uri}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            sx={{
                                                color: '#1D8B45',
                                                textDecoration: 'none', // Ensure no underline initially
                                                '&:hover': {
                                                    textDecoration: 'underline',
                                                    color: '#1D8B45', // Ensure no underline on hover
                                                },
                                            }}>
                                            <Typography style={{
                                                textAlign: 'left',
                                                width: '100%',
                                                color: '#1D8B45',
                                            }}>
                                                {orderDetails?.bol_no}
                                            </Typography>
                                        </Link>
                                    </Box>
                                </Grid>
                            }
                            {
                                (orderDetails?.shipping_status_id !== 1 && orderDetails?.shipping_status_id !== 2) &&
                                <Grid item md={6} xs={12}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        height: '100%',
                                        gap: '5px'
                                    }}>
                                        <Typography style={{fontWeight: '600'}}>
                                            Approved Price:
                                        </Typography>
                                        <Typography style={{textAlign: 'left'}}>
                                            $ {orderDetails?.price?.toFixed(2)}
                                        </Typography>
                                    </Box>
                                </Grid>
                            }
                            <Grid item md={6} xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    height: '100%',
                                    gap: '5px'
                                }}>
                                    <Typography style={{
                                        fontWeight: '600',
                                        textDecoration: (orderDetails?.shipping_status_id !== 1 && orderDetails?.shipping_status_id !== 2) && 'line-through'
                                    }}>
                                        Original Estimate:
                                    </Typography>
                                    <Typography style={{textAlign: 'left'}}>
                                        $ {orderDetails?.orderPrice?.final_total?.toFixed(2)}
                                    </Typography>
                                </Box>
                            </Grid>
                            {
                                orderDetails?.pending_amount > 0 &&
                                <Grid item md={6} xs={12}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        height: '100%',
                                        gap: '5px'
                                    }}>
                                        <Typography style={{fontWeight: '600'}}>
                                            Pending Amount:
                                        </Typography>
                                        <Typography style={{textAlign: 'left'}}>
                                            $ {orderDetails?.pending_amount?.toFixed(2)}
                                        </Typography>
                                    </Box>
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    gap: '10px',
                                    justifyContent: 'flex-end',
                                    padding: '0 20px'
                                }}>
                                    {
                                        movingOrderToCancel(orderDetails?.status_id, orderDetails?.shipping_status_id, orderDetails?.order_sub_type) &&
                                        <Button
                                            variant='contained'
                                            sx={{
                                                backgroundColor: '#454545',
                                                "&:hover": {
                                                    backgroundColor: '#454545',
                                                    filter: 'brightness(0.9)'
                                                }
                                            }}
                                            onClick={handleCancelOrder}
                                        >
                                            <Typography style={{
                                                textTransform: 'none',
                                                fontSize: '14px',
                                                color: '#FFFFFF',
                                            }}>
                                                Cancel
                                            </Typography>
                                        < /Button>
                                    }
                                    <Button
                                        variant='contained'
                                        sx={{
                                            backgroundColor: '#1D8B45',
                                            "&:hover": {
                                                backgroundColor: '#1D8B45',
                                                filter: 'brightness(0.9)'
                                            }
                                        }}
                                        onClick={handlePrintLabel}
                                    >
                                        <Typography style={{
                                            textTransform: 'none',
                                            fontSize: '14px',
                                            color: '#FFFFFF',
                                        }}>
                                            Print Label
                                        </Typography>
                                    </Button>
                                </Box>
                            </Grid>
                            {
                                orderDetails?.shipping_status_id === 20 &&
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography style={{fontWeight: '600'}}>
                                                Approval Needed for Order Details
                                            </Typography>
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <Typography>
                                                Cost: $ {orderDetails?.price?.toFixed(2)}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <Typography>
                                                Pickup
                                                Date: {orderDetails?.schedule_pickup ? dayjs(orderDetails?.schedule_pickup).format("YYYY-MM-DD") : 'To be determined'}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <Typography>
                                                Pickup
                                                Time: {orderDetails?.schedule_pickup ? dayjs(orderDetails?.schedule_pickup).format("HH:mm") : 'To be determined'}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <Typography>
                                                Deliver
                                                Date: {orderDetails?.schedule_deliver ? dayjs(orderDetails?.schedule_deliver).format("YYYY-MM-DD") : 'To be determined'}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <Typography>
                                                Deliver
                                                Time: {orderDetails?.schedule_deliver ? dayjs(orderDetails?.schedule_deliver).format("HH:mm") : 'To be determined'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                gap: '5px'
                                            }}>
                                                <Button
                                                    variant='contained'
                                                    sx={{
                                                        backgroundColor: '#454545',
                                                        "&:hover": {
                                                            backgroundColor: '#454545',
                                                            filter: 'brightness(0.9)'
                                                        }
                                                    }}
                                                    size='small'
                                                    onClick={() => {
                                                        setDeclineDialog(true);
                                                        setAcceptDialog(false);
                                                    }}
                                                >
                                                    <Typography sx={{textTransform: 'none', fontSize: '14px'}}>
                                                        Decline
                                                    </Typography>
                                                </Button>
                                                <Button
                                                    variant='contained'
                                                    sx={{
                                                        backgroundColor: '#1D8B45',
                                                        "&:hover": {
                                                            backgroundColor: '#1D8B45',
                                                            filter: 'brightness(0.9)'
                                                        }
                                                    }}
                                                    size='small'
                                                    onClick={() => {
                                                        setAcceptDialog(true);
                                                        setDeclineDialog(false);
                                                    }}
                                                >
                                                    <Typography sx={{textTransform: 'none', fontSize: '14px'}}>
                                                        Accept
                                                    </Typography>
                                                </Button>
                                            </Box>
                                        </Grid>
                                        {
                                            acceptDialog &&
                                            <>
                                                <Grid item xs={12}>
                                                    <Typography>
                                                        Are you sure you want to accept approval? Please confirm if you
                                                        would like to proceed with the request.
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-end',
                                                        gap: '10px'
                                                    }}>
                                                        <Button onClick={() => setAcceptDialog(false)}>
                                                            <Typography sx={{textTransform: 'none', fontSize: '14px'}}>
                                                                Cancel
                                                            </Typography>
                                                        </Button>
                                                        <Button
                                                            onClick={handleAccept}
                                                        >
                                                            <Typography sx={{textTransform: 'none', fontSize: '14px'}}>
                                                                Confirm
                                                            </Typography>
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                            </>
                                        }
                                        {
                                            declineDialog &&
                                            <>
                                                <Grid item xs={12}>
                                                    <Typography>
                                                        Are you sure you want to decline approval? Please confirm if you
                                                        would like to proceed with the request.
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-end',
                                                        gap: '10px'
                                                    }}>
                                                        <Button onClick={() => setDeclineDialog(false)}>
                                                            <Typography sx={{textTransform: 'none', fontSize: '14px'}}>
                                                                Cancel
                                                            </Typography>
                                                        </Button>
                                                        <Button
                                                            onClick={handleDecline}
                                                        >
                                                            <Typography sx={{textTransform: 'none', fontSize: '14px'}}>
                                                                Confirm
                                                            </Typography>
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                            </>
                                        }
                                    </Grid>
                                </Grid>
                            }
                            {
                                orderDetails?.shipping_status_id === 30 &&
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography style={{fontWeight: '600'}}>
                                                Confirmation for Order Pickup
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>
                                                Your items were picked up
                                                on {dayjs(orderDetails?.task_pickedup_at).format("YYYY-MM-DD")}.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                gap: '5px'
                                            }}>
                                                <Button
                                                    variant='contained'
                                                    sx={{
                                                        backgroundColor: '#1D8B45',
                                                        "&:hover": {
                                                            backgroundColor: '#1D8B45',
                                                            filter: 'brightness(0.9)'
                                                        }
                                                    }}
                                                    size='small'
                                                    onClick={handleConfirmPickupDialogOpen}
                                                >
                                                    <Typography style={{
                                                        textAlign: 'left',
                                                        textTransform: 'none',
                                                        fontSize: '14px'
                                                    }}>
                                                        Confirm Pickup
                                                    </Typography>
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            {
                                pickupConfirmDialog &&
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography style={{fontWeight: '600'}}>
                                                Are you sure you want to confirm the pickup?
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                gap: '10px'
                                            }}>
                                                <Button onClick={handleConfirmPickupDialogClose}>
                                                    <Typography sx={{textTransform: 'none', fontSize: '14px'}}>
                                                        Cancel
                                                    </Typography>
                                                </Button>
                                                <Button
                                                    onClick={handleConfirmPickup}
                                                >
                                                    <Typography sx={{textTransform: 'none', fontSize: '14px'}}>
                                                        Confirm
                                                    </Typography>
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            {
                                orderDetails?.shipping_status_id === 40 &&
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography style={{fontWeight: '600'}}>
                                                Confirmation for Order Delivered
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>
                                                Your items were delivered
                                                on {dayjs(orderDetails?.task_delivered_at).format("YYYY-MM-DD")}.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                gap: '5px'
                                            }}>
                                                <Button
                                                    variant='contained'
                                                    sx={{
                                                        backgroundColor: '#1D8B45',
                                                        "&:hover": {
                                                            backgroundColor: '#1D8B45',
                                                            filter: 'brightness(0.9)'
                                                        }
                                                    }}
                                                    size='small'
                                                    onClick={handleConfirmDeliverDialogOpen}
                                                >
                                                    <Typography style={{
                                                        textAlign: 'left',
                                                        textTransform: 'none',
                                                        fontSize: '14px'
                                                    }}>
                                                        Confirm Delivery
                                                    </Typography>
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            {
                                deliverConfirmDialog &&
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography style={{fontWeight: '600'}}>
                                                Are you sure you want to confirm the delivery?
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                gap: '10px'
                                            }}>
                                                <Button onClick={handleConfirmDeliverDialogClose}>
                                                    <Typography sx={{textTransform: 'none', fontSize: '14px'}}>
                                                        Cancel
                                                    </Typography>
                                                </Button>
                                                <Button
                                                    onClick={handleConfirmDeliver}
                                                >
                                                    <Typography sx={{textTransform: 'none', fontSize: '14px'}}>
                                                        Confirm
                                                    </Typography>
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}