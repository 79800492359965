import {useSelector} from "react-redux";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@mui/material/Button";
import {useHistory} from "react-router-dom";

const styles = {
    modal: {
        // position: 'absolute',
        // top: '50%',
        // left: '50%',
        // transform: 'translate(-50%, -50%)',
        bgcolor: '#ffffff',
        // border: '2px solid #000',
        // boxShadow: 24,
        // p: 2,
        padding: '10px',
        maxHeight: '80vh',
        overflow: 'auto',
        width: '100%',
        borderRadius: '10px'
    },
    PackingListTitle: {
        flexGrow: 1,
        margin: '15px auto',
        width: '100%',
        borderBottom: '2px solid #000000'
    },
    PackingListWhite: {
        flexGrow: 1,
        backgroundColor: '#ffffff',
        margin: '15px auto',
        borderRadius: '5px',
        width: '100%'
    },

    PackingListDark: {
        flexGrow: 1,
        backgroundColor: '#eeeeee',
        margin: '15px auto',
        borderRadius: '5px',
        width: '100%'
    },
    listItem: {
        display: 'flex',
        marginLeft: '10px'
    },
    noPackingList: {
        display: 'flex',
        margin: '10px 0',
        flexDirection: 'column'
    },
    packingListButton: {
        width: '150px',
        margin: '10px 0'
    }
}

export const PackingListSelect = ({handlePackingList}) => {

    const id = useSelector((state) => state.user.id);

    const [open, setOpen] = useState(false)

    const [show, setShow] = useState('hidden');

    const history = useHistory();

    const handleOpen = () => {
        setOpen(true);
        setShow('hidden');
    }

    const handleClose = () => setOpen(false);

    const handleOpenShow = () => setShow('visible');

    const handleCloseShow = () => setShow('hidden');

    const [packingList, setPackingList] = useState([]);

    const getPackingListArrayByPartnerId = async () => {
        let requestURI = `${PARTNER_URI}/packingList/getPackingListArrayByPartnerId`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURI,
                params: {partnerId: id}
            })
            const {data} = result;
            setPackingList(data)

        } catch (e) {
            console.log(e.response)
        }
    }

    useEffect(() => {
        id && getPackingListArrayByPartnerId()
    }, [])

    // console.log(packingList)

    return (
        <>
            {
                // open ?
                    // <Modal
                    //     open={open}
                    //     onClose={handleClose}
                    // >
                        <Box sx={styles.modal}>
                            {/*<IconButton sx={{position: 'absolute', right: '0'}} onClick={handleClose}>*/}
                            {/*    <CloseIcon/>*/}
                            {/*</IconButton>*/}
                            <Typography variant="h5">
                                My Packing List
                            </Typography>
                            {packingList.length > 0 ? packingList?.map((item, index) => {
                                    return <div key={index}>
                                        <Box
                                            sx={index % 2 === 0 ? styles.PackingListWhite : styles.PackingListDark}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <Box sx={styles.listItem}>
                                                        <Typography>
                                                            {item.packinglist_name}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Box>
                                                        <Typography>
                                                            {item.updated_at}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Button
                                                        onClick={() => {
                                                            handlePackingList(item)
                                                            handleClose()
                                                        }}>
                                                        <Typography>
                                                            Use It
                                                        </Typography>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </div>
                                })
                                : <Box sx={styles.noPackingList}>
                                    <Typography>
                                        Please add the packing list first. You can access the packing list page by
                                        clicking the button below.
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        sx={styles.packingListButton}
                                        onClick={() => history.push("/settings/packing-list")}
                                    >
                                        Packing List
                                    </Button>
                                </Box>
                            }
                        </Box>
                    // </Modal>
                    // :
                    // <Box sx={{display: 'flex', justifyContent: 'flex-end', position: 'relative'}}>
                    //     <IconButton
                    //         onClick={handleOpen}
                    //         onMouseEnter={handleOpenShow}
                    //         onMouseLeave={handleCloseShow}>
                    //         <img
                    //             src={packingListIcon}
                    //             style={{width: "30px", height: "30px", cursor: "pointer"}}
                    //             alt="image"
                    //         />
                    //     </IconButton>
                    //     <Typography
                    //         sx={{
                    //             visibility: `${show}`,
                    //             backgroundColor: '#424242',
                    //             width: '180px',
                    //             height: '20px',
                    //             borderRadius: '5px',
                    //             color: '#ffffff',
                    //             fontSize: '12px',
                    //             position: 'absolute',
                    //             top: '-25%',
                    //             right: '5%'
                    //         }}
                    //     >Select From My Packing List</Typography>
                    // </Box>
            }
        </>

    )
}