import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Divider} from "@mui/material";

export const CrossBorderDashboardItemList = ({itemList}) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            border: '1px solid #B2B2B2',
            borderRadius: '5px',
            padding: '15px'
        }}>
            <Typography style={{fontWeight: '600'}}>
                Customs Items
            </Typography>
            <Divider/>
            {
                itemList?.map((item, index) => (
                    <Box key={index}>
                        <Typography style={{fontSize: '14px', color: '#1D8B45', fontWeight: '600'}}>
                            {item?.description} * {item?.quantity}
                        </Typography>
                        <Typography style={{fontSize: '14px'}}>
                            {item?.weight} {item?.weightUnit} per unit | $ {item?.unitPrice} per unit | {item?.hsCode}
                        </Typography>
                    </Box>
                ))
            }
        </Box>
    )
}