import React, { useState, useEffect } from "react";
import { Box, makeStyles, Container } from "@material-ui/core";
import { useParams } from "react-router-dom";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import { Alert, AlertTitle } from "@material-ui/lab";
import axios from "axios";

import { GET_SHIPMENTS_BY_ID_API } from "../../Utils/apiUrl";
import LoadingContainer from "../shared/loadingContainer";
import { errHandling, getURLParams, getHeaders } from "../../Utils/fetchUtil";

const getTime = (_time) => {
  if (!_time) return null;
  const res = _time.split(" ");
  const newTimeObj = { date: null, time: null };
  if (res[0]) {
    newTimeObj.date = res[0].split("-").slice(1, 3).join("-");
    newTimeObj.year = res[0].split("-")[0];
  }
  if (res[1]) newTimeObj.time = res[1].split(":").slice(0, 2).join(":");
  return newTimeObj;
};

const ShipmentDetail = () => {
  const classes = useStyles();

  const { id } = useParams();

  const [shipmentDetails, setShipmentDetails] = useState([]);

  const [fetchingState, setFetchingState] = useState(true);

  const [fetchingErr, setFetchingErr] = useState("");

  useEffect(() => {
    axios
      .get(
        getURLParams(`${GET_SHIPMENTS_BY_ID_API}`, {
          shipment_number: id,
          stage: "1",
        }),
        {
          headers: getHeaders(),
        }
      )
      .then((response) => {
        if (response.data) {
          setShipmentDetails(response.data);
        }
        setFetchingState(false);
      })
      .catch((err) => {
        errHandling(err, setFetchingErr);
        setFetchingState(false);
      });
  }, []);

  return (
    <Container maxWidth="md">
      {fetchingErr && (
        <Alert severity="error" classes={{ root: classes.alertRoot }}>
          <AlertTitle>Error</AlertTitle>
          {fetchingErr}
        </Alert>
      )}
      {fetchingState ? (
        <LoadingContainer />
      ) : (
        <>
          <Box
            fontWeight="600"
            textAlign="center"
            mt={2}
            mb={2}
            fontSize="1.5rem"
          >
            Shipment details
          </Box>
          {shipmentDetails.length !== 0 && (
            <>
              <Box fontSize="1.2rem">
                <b>Order #:</b> {shipmentDetails[0].order_number}
              </Box>
              <Box fontSize="1.2rem" mb={1}>
                <b>Status:</b> {shipmentDetails[0].shipping_status}
              </Box>
            </>
          )}
          <Timeline align="left" style={{ paddingLeft: 0, paddingRight: 0 }}>
            {shipmentDetails.map((shipmentItem) => {
              return (
                <TimelineItem key={shipmentItem?.schedule_pickup}>
                  <TimelineOppositeContent
                    style={{
                      flex: 0,
                      paddingLeft: 0,
                      color: "rgba(0,0,0,0.6)",
                    }}
                  >
                    <Box whiteSpace="nowrap">
                      {getTime(shipmentItem?.schedule_pickup)?.time || ""}
                      <br />
                      {getTime(shipmentItem?.schedule_pickup)?.date || ""}{" "}
                      <br />
                      {getTime(shipmentItem?.schedule_pickup)?.year || ""}
                    </Box>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot color="secondary">
                      <LocalShippingIcon />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent style={{ padding: "8px 16px 24px 16px" }}>
                    <Box className={classes.paper}>
                      {/* <b>Picked up: </b> <br /> */}
                      {shipmentItem?.pickup_address?.split(",")[0] || ""} <br />
                      {shipmentItem?.pickup_city || ""},{" "}
                      {shipmentItem?.pickup_province || ""}
                    </Box>
                  </TimelineContent>
                </TimelineItem>
              );
            })}
          </Timeline>
        </>
      )}
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "white",
    borderRadius: "5px",
    padding: theme.spacing(1),
    minHeight: "80px",
  },
  alertRoot: {
    width: "80%",
    minWidth: "200px",
    margin: "10px auto",
  },
}));

export default ShipmentDetail;
