import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";
import {Divider} from "@mui/material";
import {ShippingOrderListEach} from "../ShippingOrderList/Business/ShippingOrderListEach";
import {BusinessLandingAdjustOrderListEach} from "./BusinessLandingAdjustOrderListEach";

export const BusinessLandingAdjustOrderList = ({order}) => {

    return (
        <Grid container spacing={1}>
            <Grid item xs={4}>
                <Typography sx={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                    {intl.get('HOME_PAGE.SHIPPING_ORDER.ORDER')}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography sx={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                    {intl.get('HOME_PAGE.SHIPPING_ORDER.STATUS')}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography sx={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                    {intl.get('HOME_PAGE.SHIPPING_ORDER.CHARGE')}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            {
                order?.map((orderEach, index) =>
                    <Grid item xs={12} key={index}>
                        <BusinessLandingAdjustOrderListEach orderInfo={orderEach}/>
                    </Grid>
                )
            }
        </Grid>
    )
}