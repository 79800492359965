import Grid from "@mui/material/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InputLabel from "@material-ui/core/InputLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {useEffect, useState} from "react";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import {extractFullAddress, internationalShipmentTypeList, transportationList} from "../../Utils/Helper";
import axios from "axios";
import {Accordion, AccordionDetails, AccordionSummary, IconButton, MenuItem, Select} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpIcon from "@mui/icons-material/Help";
import {useDispatch, useSelector} from "react-redux";
import {
    updateQuickQuoteShippingPackageCrossBorder,
    updateQuickQuoteShippingPackageShipFrom,
    updateQuickQuoteShippingPackageShipTo
} from "../../actions/shippingPackageAction";

const styles = {
    ShippingPackageQuickQuoteLocationTitle: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageQuickQuoteLocationHeading: {
        fontSize: '20px',
        textAlign: 'left'
    },
    ShippingPackageQuickQuoteLocationSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    ShippingPackageQuickQuoteLocationBox: {
        padding: '20px',
        // border: '1px solid #000000',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    },
    ShippingPackageQuickQuoteLocationErrorText: {
        fontSize: '12px',
        color: "#FF0303"
    },
    ShippingPackageQuickQuoteLocationRow: {
        display: 'flex',
        alignItems: 'center',
        // justifyContent: 'flex-end',
        width: '100%'
    }
}

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "4px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

export const ShippingPackageQuickQuoteLocation = () => {

    const dispatch = useDispatch();

    const classes = useStyles();

    let shipFromRedux = useSelector(state => state?.shippingPackage?.quickQuoteShipFrom);
    let shipToRedux = useSelector(state => state?.shippingPackage?.quickQuoteShipTo);
    let isGetRate = useSelector(state => state?.shippingPackage?.quickQuoteIsGetRate);
    let shipFromMissing = useSelector(state => state?.shippingPackage?.quickQuoteShipFromMissing);
    let shipToMissing = useSelector(state => state?.shippingPackage?.quickQuoteShipToMissing);

    const [accordionOpen, setAccordionOpen] = useState(false);
    const [crossBorder, setCrossBorder] = useState({
        tranMode: "INT_TRUCK",
        shipmentType: "COMMERCIAL",
    })
    const [shipFrom, setShipFrom] = useState({
        postalCode: null,
        province: null,
        city: null,
        address: null,
        country: null,
        region: null,
        displayPostalCode: {
            description: null,
            placeId: null,
            text: null
        },
        displayAddress: {
            description: null,
            placeId: null,
            text: null
        }
    });

    const [shipTo, setShipTo] = useState({
        postalCode: null,
        province: null,
        city: null,
        address: null,
        country: null,
        region: null,
        displayPostalCode: {
            description: null,
            placeId: null,
            text: null
        },
        displayAddress: {
            description: null,
            placeId: null,
            text: null
        }
    });

    const [shipFromPredictions, setShipFromPredictions] = useState([]);
    const [shipToPredictions, setShipToPredictions] = useState([]);
    const [shipFromPredictionsZip, setShipFromPredictionsZip] = useState([]);
    const [shipToPredictionsZip, setShipToPredictionsZip] = useState([]);

    const toggleOpen = () => {
        setAccordionOpen(!accordionOpen);
    }

    const googleMapsPlaceAutocomplete = async (inputValue, type) => {
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log('prediction', predictions);
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            // console.log('updated prediction', updatedPredictions);

            return updatedPredictions;

        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const handleInputShipFromFullAddress = async (event, value) => {
        setShipFrom(prevState => ({
            ...prevState,
            address: value
        }));
        const predictionsAddress = await googleMapsPlaceAutocomplete(value, 'address');
        setShipFromPredictions(predictionsAddress);
    }

    const handleChangeShipFromFullAddress = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        // console.log('place', place)
        if (place) {
            const address = extractFullAddress(place);
            // console.log('address', address);
            const predictionsZip = googleMapsPlaceAutocomplete(address?.zip, 'postal_code');
            setShipFromPredictionsZip(predictionsZip);
            setShipFrom(prevState => ({
                ...prevState,
                postalCode: address?.zip,
                city: address?.sublocality || address?.city || address?.administrative_area_level_3 || address.neighborhood,
                province: address?.state,
                country: address?.country,
                region: address?.region,
                displayPostalCode: {
                    description: '',
                    placeId: '',
                    text: address?.zip
                },
                displayAddress: value
            }))
        } else {
            setShipFrom(prevState => ({
                ...prevState,
                address: '',
                postalCode: '',
                city: '',
                province: '',
                country: '',
                region: '',
                displayPostalCode: {
                    description: '',
                    placeId: '',
                    text: ''
                },
                displayAddress: {
                    description: '',
                    placeId: '',
                    text: ''
                }
            }))
        }
    }

    const handleInputShipFromPostalCode = async (event, value) => {
        setShipFrom(prevState => ({
            ...prevState,
            postalCode: value
        }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'postal_code');
        setShipFromPredictionsZip(predictions);
    }

    const handleChangeShipFromPostalCode = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        const placeFromAddress = await googleMapsPlaceDetails(shipFrom?.displayAddress?.placeId);
        // console.log('place', place);
        if (place) {
            const address = extractFullAddress(place);
            const addressFromShipFrom = extractFullAddress(placeFromAddress);
            console.log('address from ship from', addressFromShipFrom?.zip)
            console.log('address', address?.zip);
            if (address?.zip === addressFromShipFrom?.zip) {
                setShipFrom(prevState => ({
                    ...prevState,
                    displayPostalCode: value
                }))
            } else {
                setShipFrom(prevState => ({
                    ...prevState,
                    city: address?.sublocality || address?.city || address?.administrative_area_level_3 || address.neighborhood,
                    province: address?.state,
                    country: address?.country,
                    region: address?.region,
                    displayPostalCode: value,
                    displayAddress: {
                        description: '',
                        placeId: '',
                        text: ''
                    },
                }))
            }
        } else {
            setShipFrom(prevState => ({
                ...prevState,
                city: '',
                province: '',
                country: '',
                postalCode: '',
                region: '',
                displayPostalCode: {
                    description: '',
                    placeId: '',
                    text: ''
                },
            }))
        }
    }

    const handleInputShipToFullAddress = async (event, value) => {
        setShipFrom(prevState => ({
            ...prevState,
            address: value
        }));
        const predictionsAddress = await googleMapsPlaceAutocomplete(value, 'address');
        setShipToPredictions(predictionsAddress);
    }

    const handleChangeShipToFullAddress = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        // console.log('place', place)
        if (place) {
            const address = extractFullAddress(place);
            // console.log('address', address);
            const predictionsZip = googleMapsPlaceAutocomplete(address?.zip, 'postal_code');
            setShipToPredictionsZip(predictionsZip);
            setShipTo(prevState => ({
                ...prevState,
                postalCode: address?.zip,
                city: address?.sublocality || address?.city || address?.administrative_area_level_3 || address.neighborhood,
                province: address?.state,
                country: address?.country,
                region: address?.region,
                displayPostalCode: {
                    description: '',
                    placeId: '',
                    text: address?.zip
                },
                displayAddress: value
            }))
        } else {
            setShipTo(prevState => ({
                ...prevState,
                address: '',
                postalCode: '',
                city: '',
                province: '',
                country: '',
                region: '',
                displayPostalCode: {
                    description: '',
                    placeId: '',
                    text: ''
                },
                displayAddress: {
                    description: '',
                    placeId: '',
                    text: ''
                }
            }))
        }
    }

    const handleInputShipToPostalCode = async (event, value) => {
        setShipTo(prevState => ({
            ...prevState,
            postalCode: value
        }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'postal_code');
        setShipToPredictionsZip(predictions);
    }

    const handleChangeShipToPostalCode = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        const placeToAddress = await googleMapsPlaceDetails(shipTo?.displayAddress?.placeId);
        // console.log('place', place);
        if (place) {
            const address = extractFullAddress(place);
            const addressFromShipTo = extractFullAddress(placeToAddress);
            console.log('address from ship from', addressFromShipTo?.zip)
            console.log('address', address?.zip);
            if (address?.zip === addressFromShipTo?.zip) {
                setShipTo(prevState => ({
                    ...prevState,
                    displayPostalCode: value
                }))
            } else {
                setShipTo(prevState => ({
                    ...prevState,
                    city: address?.sublocality || address?.city || address?.administrative_area_level_3 || address.neighborhood,
                    province: address?.state,
                    country: address?.country,
                    region: address?.region,
                    displayPostalCode: value,
                    displayAddress: {
                        description: '',
                        placeId: '',
                        text: ''
                    },
                }))
            }
        } else {
            setShipTo(prevState => ({
                ...prevState,
                city: '',
                province: '',
                country: '',
                postalCode: '',
                region: '',
                displayPostalCode: {
                    description: '',
                    placeId: '',
                    text: ''
                },
            }))
        }
    }

    const handleTranMode = (event) => {
        setCrossBorder(prevState => ({
            ...prevState,
            tranMode: event.target.value
        }))
    }

    const handleShipmentType = (event) => {
        setCrossBorder(prevState => ({
            ...prevState,
            shipmentType: event.target.value
        }))
    }

    useEffect(() => {
        dispatch(updateQuickQuoteShippingPackageShipFrom(shipFrom));
    },[shipFrom]);

    useEffect(() => {
        dispatch(updateQuickQuoteShippingPackageShipTo(shipTo));
    },[shipTo]);

    useEffect(() => {
        dispatch(updateQuickQuoteShippingPackageCrossBorder(crossBorder));
    },[crossBorder]);

    useEffect(() => {
        if (shipFrom?.country?.code === shipTo?.country?.code) {
            setAccordionOpen(false);
        } else {
            setAccordionOpen(true);
        }
    }, [shipFrom, shipTo])

    useEffect(() => {
        if (shipFromRedux) {
            setShipFrom(shipFromRedux);
        }
        if (shipToRedux) {
            setShipTo(shipToRedux);
        }
    },[])

    console.log('ship from', shipFrom);
    console.log('ship to', shipTo);
    // console.log('cross border', crossBorder);
    // console.log('ship from redux', shipFromRedux);
    // console.log('ship to redux', shipToRedux);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography style={styles.ShippingPackageQuickQuoteLocationTitle}>
                    Address Details
                </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
                <Box sx={styles.ShippingPackageQuickQuoteLocationBox}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography style={styles.ShippingPackageQuickQuoteLocationHeading}>
                                Ship From
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Box sx={styles.ShippingPackageQuickQuoteLocationSection}>
                                <InputLabel>Address</InputLabel>
                                <Autocomplete
                                    value={shipFrom?.displayAddress}
                                    disabled={isGetRate}
                                    options={shipFromPredictions}
                                    filterOptions={(options, state) => options}
                                    getOptionLabel={option => option?.description}
                                    fullWidth
                                    PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                    onInputChange={handleInputShipFromFullAddress}
                                    onChange={handleChangeShipFromFullAddress}
                                    renderInput={params => <TextField
                                        {...params}
                                        variant='outlined'
                                        className={classes.smallInput}
                                    />}
                                    renderOption={option => (
                                        <Typography style={{fontSize: '12px'}}>
                                            {option.description}
                                        </Typography>
                                    )}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box sx={styles.ShippingPackageQuickQuoteLocationSection}>
                                <InputLabel required>Postal Code</InputLabel>
                                <Autocomplete
                                    value={shipFrom?.displayPostalCode}
                                    disabled={isGetRate}
                                    options={shipFromPredictionsZip}
                                    getOptionLabel={option => option?.text}
                                    onInputChange={handleInputShipFromPostalCode}
                                    onChange={handleChangeShipFromPostalCode}
                                    fullWidth
                                    PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                    renderInput={params => <TextField
                                        {...params}
                                        variant='outlined'
                                        className={classes.smallInput}
                                    />}
                                    renderOption={(option) => (
                                        <Typography
                                            style={{fontSize: '12px'}}
                                        >
                                            {/*<Typography>*/}
                                            {option.description}
                                            {/*</Typography>*/}
                                        </Typography>
                                    )}
                                />
                                <Typography style={styles.ShippingPackageQuickQuoteLocationErrorText}>
                                    {
                                        shipFromMissing?.length > 0 ? "This is required field or postal code invalid." : ''
                                    }
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item md={6} xs={12}>
                <Box sx={styles.ShippingPackageQuickQuoteLocationBox}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography style={styles.ShippingPackageQuickQuoteLocationHeading}>
                                Ship To
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Box sx={styles.ShippingPackageQuickQuoteLocationSection}>
                                <InputLabel>Address</InputLabel>
                                <Autocomplete
                                    value={shipTo?.displayAddress}
                                    disabled={isGetRate}
                                    options={shipToPredictions}
                                    filterOptions={(options, state) => options}
                                    getOptionLabel={option => option?.description}
                                    fullWidth
                                    PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                    onInputChange={handleInputShipToFullAddress}
                                    onChange={handleChangeShipToFullAddress}
                                    renderInput={params => <TextField
                                        {...params}
                                        variant='outlined'
                                        className={classes.smallInput}
                                    />}
                                    renderOption={option => (
                                        <Typography style={{fontSize: '12px'}}>
                                            {option.description}
                                        </Typography>
                                    )}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box sx={styles.ShippingPackageQuickQuoteLocationSection}>
                                <InputLabel required>Postal Code</InputLabel>
                                <Autocomplete
                                    value={shipTo?.displayPostalCode}
                                    disabled={isGetRate}
                                    options={shipToPredictionsZip}
                                    getOptionLabel={option => option?.text}
                                    onInputChange={handleInputShipToPostalCode}
                                    onChange={handleChangeShipToPostalCode}
                                    fullWidth
                                    PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                    renderInput={params => <TextField
                                        {...params}
                                        variant='outlined'
                                        className={classes.smallInput}
                                    />}
                                    renderOption={(option) => (
                                        <Typography
                                            style={{fontSize: '12px'}}
                                        >
                                            {/*<Typography>*/}
                                            {option.description}
                                            {/*</Typography>*/}
                                        </Typography>
                                    )}
                                />
                                <Typography style={styles.ShippingPackageQuickQuoteLocationErrorText}>
                                    {
                                        shipToMissing?.length > 0 ? "This is required field or postal code invalid." : ''
                                    }
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            {/*<Grid item md={12}>*/}
            {/*        <Accordion*/}
            {/*            expanded={accordionOpen}*/}
            {/*            onChange={toggleOpen}*/}
            {/*            sx={{width: '100%'}}*/}
            {/*        >*/}
            {/*            <AccordionSummary expandIcon={<ExpandMoreIcon/> }>*/}
            {/*                <Box sx={styles.ShippingPackageQuickQuoteLocationRow}>*/}
            {/*                    <IconButton*/}
            {/*                        onClick={() => window.location.href = 'https://uucargo.ca/support/cross-border-shipping-parcels/'}>*/}
            {/*                        <HelpIcon color="info" sx={{fontSize: 25}}/>*/}
            {/*                    </IconButton>*/}
            {/*                    <Typography>*/}
            {/*                        Cross Border Only (Mandatory for shipping Different Countries)<b>Click Here To*/}
            {/*                        Expand</b>*/}
            {/*                    </Typography>*/}
            {/*                </Box>*/}
            {/*            </AccordionSummary>*/}
            {/*            <AccordionDetails>*/}
            {/*                <Grid container spacing={4}>*/}
            {/*                    <Grid item md={6} xs={12}>*/}
            {/*                        <Box sx={styles.ShippingPackageQuickQuoteLocationSection}>*/}
            {/*                            <InputLabel required>Transportation Mode</InputLabel>*/}
            {/*                            <FormControl variant="outlined" fullWidth>*/}
            {/*                                <Select*/}
            {/*                                    value={crossBorder?.tranMode}*/}
            {/*                                    onChange={handleTranMode}*/}
            {/*                                    size='small'*/}
            {/*                                >*/}
            {/*                                    {*/}
            {/*                                        transportationList.map(value => {*/}
            {/*                                            return <MenuItem value={value.code}>{value?.name}</MenuItem>*/}
            {/*                                        })*/}
            {/*                                    }*/}
            {/*                                </Select>*/}
            {/*                            </FormControl>*/}
            {/*                        </Box>*/}
            {/*                    </Grid>*/}
            {/*                    <Grid item md={6} xs={12}>*/}
            {/*                        <Box sx={styles.ShippingPackageQuickQuoteLocationSection}>*/}
            {/*                            <InputLabel required>Shipment Type</InputLabel>*/}
            {/*                            <FormControl variant="outlined" fullWidth>*/}
            {/*                                <Select*/}
            {/*                                    value={crossBorder?.shipmentType}*/}
            {/*                                    onChange={handleShipmentType}*/}
            {/*                                    size='small'*/}
            {/*                                >*/}
            {/*                                    {internationalShipmentTypeList.map(value => {*/}
            {/*                                        return <MenuItem value={value}>{value}</MenuItem>*/}
            {/*                                    })}*/}
            {/*                                </Select>*/}
            {/*                            </FormControl>*/}
            {/*                        </Box>*/}
            {/*                    </Grid>*/}
            {/*                </Grid>*/}
            {/*            </AccordionDetails>*/}
            {/*        </Accordion>*/}
            {/*</Grid>*/}
        </Grid>
    )

}