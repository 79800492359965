import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import {Fragment} from "react";
import Box from "@mui/material/Box";
import {Divider} from "@mui/material";
import {mapCarrierLogo} from "../../Utils/Helper";
import intl from "react-intl-universal";

export const SchedulePickupList = ({myPickups}) => {

    console.log('[SchedulePickupList] myPickups', myPickups);

    return (
        <Grid container spacing={1}>
            <Grid item xs={5}>
                <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                    {intl.get('HOME_PAGE_TABLE_HEADING_8')}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                    {intl.get('HOME_PAGE_TABLE_HEADING_5')}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                    {intl.get('HOME_PAGE_TABLE_HEADING_6')}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                    {intl.get('HOME_PAGE_TABLE_HEADING_9')}
                </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
                <Divider/>
            </Grid>
            {
                myPickups?.map((each, index) =>
                    <Fragment key={index}>
                        <Grid item xs={5}>
                            <Typography style={{fontSize: '14px', textAlign: 'center'}}>
                                {each?.confirm_appoint_no || "N/A"}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%'
                            }}>
                                <img src={mapCarrierLogo(each?.account_base.toUpperCase())} alt="logo"
                                     width='25px'/>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%'
                            }}>
                                <Typography style={{fontSize: '14px', textAlign: 'center'}}>
                                    {each?.uuc_order_id ? `$ ${each?.final_total}` : 'Free'}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography style={{fontSize: '14px', textAlign: 'center'}}>
                                {each?.appoint_date}
                            </Typography>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Divider/>
                        </Grid>
                    </Fragment>)
            }
        </Grid>

    )
}