import {useSelector} from "react-redux";
import Box from "@mui/material/Box";
import {RatingItemEach} from "../Rating/RatingItemEach";
import Typography from "@mui/material/Typography";
import {ShipSummaryItemEach} from "./ShipSummaryItemEach";
import {Button} from "@mui/material";

const style = {
    ShipSummaryItemListRoot: {
        width: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        backgroundColor: '#F9F9F9',
        padding: '10px 30px',
        borderRadius: '15px'
    },
    ShipSummaryItemListEditButton: {
        position: 'absolute',
        right: '3%',
        top: '3%'
    }
}

export const ShipSummaryItemList = () => {

    let itemList = useSelector(state => state?.rating?.itemList);

    return (
        <Box sx={style.ShipSummaryItemListRoot}>
            <Typography variant="h5">
                Item List
            </Typography>
            {
                itemList?.map((item, index) => <ShipSummaryItemEach key={index}
                                                               itemDetail={item}/>)
            }
        </Box>
    )
}