import React, {useState} from "react";
import {useLocation, useHistory, Link} from "react-router-dom";
import {Button, Box} from "@material-ui/core";
import {FORGOT_PASSWORD, NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import FormHelperText from "@mui/material/FormHelperText";
import {Container} from "@mui/material";
import Grid from "@mui/material/Grid";
import LandingImage from "../../images/CoverImage.png";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import intl from "react-intl-universal";
import LoadingButton from "@mui/lab/LoadingButton";
import {makeStyles} from "@material-ui/core/styles";
import {LandingMenuBar} from "../Landing/LandingMenuBar";
import {LandingIntro} from "../Landing/LandingIntro";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const styles = {
    LandingSignUpRoot: {
        display: 'flex',
        margin: '0 auto',
        '@media (max-width: 480px)': {
            flexDirection: "column", // for phone and above
        },
    },
    LandingIntroImage: {
        width: '95%',
        padding: '5%'
    },
}

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "4px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    },
    ResetButton: {
        color: 'white',
        backgroundColor: 'green',
        width: '100%',
        margin: '0 auto',
        '&:hover': {
            backgroundColor: 'green',
            opacity: '0.8'
        }
    },
});

const ForgotPasswordPage = () => {
    //   let query = useQuery();
    const history = useHistory();
    const isMobile = useMediaQuery('(max-width: 480px)');

    const classes = useStyles();

    const [email, setEmail] = useState("");
    const [emailValid, setEmailValid] = useState(true);
    const [emailValidators, setEmailValidators] = useState(null);

    const handleEmail = (event) => {
        setEmail(event?.target?.value);
    }

    const validateEmailAddress = async (email) => {
        let requestURL = `${NODE_ROUTE_URI}/validateEmail`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: {
                    email: email,
                    validateRegex: true,
                    validateMx: true,
                    validateTypo: false,
                    validateDisposable: false,
                    validateSMTP: false
                }
            })
            console.log('[validateEmailAddress] result - ', result);
            setEmailValidators(result?.data?.validators);
            return result?.data?.valid;
        } catch (e) {
            console.log(e.response)
        }
    }

    const handleResetPassword = async () => {
        const emailValidate = await validateEmailAddress(email);
        setEmailValid(emailValidate);
        if (emailValidate) {
            handleForward();
        }
    }

    const handleForward = () => {
        const url = FORGOT_PASSWORD;
        const putObj = {
            email_to: email,
        };
        axios({
            method: "post",
            url: url,
            data: JSON.stringify(putObj),
            headers: {
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
            },
        })
            .then((response) => {
                history.push("/receive-email?status_code=200");
            })
            .catch((error) => {
                history.push("/receive-email?status_code=403");
            });
    };

    return (
        <Container maxWidth={false} style={{padding: '0px', marginTop: '-75px'}}>
            <Grid container spacing={0}>
                <Grid item xs={12} md={5}>
                    <Box sx={{
                        display: 'flex',
                        padding: '20px',
                        flexDirection: 'column',
                        height: isMobile ? '100vh' : '100%',
                        gap: isMobile ? '10vh' : '15vh'
                    }}>
                        <LandingMenuBar/>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            height: '100%'
                        }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography
                                        sx={{
                                            fontSize: '20px',
                                            fontWeight: '600',
                                            textAlign: 'left',
                                            WebkitTextStroke: '0.5px black', // For Webkit browsers
                                        }}
                                    >
                                        Forgot Your Password?
                                    </Typography>
                                    <Typography style={{textAlign: 'left', color: '#454545', fontSize: '14px'}}>
                                        Please enter the email address associated with your account, and we'll
                                        email you a link to reset your password.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        value={email}
                                        label={`${intl.get('LANDING_PAGE.SIGN_UP.EMAIL')}`}
                                        required
                                        fullWidth
                                        variant="outlined"
                                        onInput={handleEmail}
                                        inputProps={{style: {fontSize: 14}}}
                                        InputLabelProps={{style: {fontSize: 14}}}
                                        size='small'
                                        error={!emailValid || emailValidators?.regex?.reason}
                                        helperText={emailValidators?.regex?.reason || (!emailValid && 'Invalid Email Address')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <LoadingButton
                                        variant="contained"
                                        onClick={handleResetPassword}
                                        size='small'
                                        fullWidth
                                        sx={{
                                            backgroundColor: '#1D8B45',
                                            "&:hover": {
                                                backgroundColor: '#1D8B45',
                                                filter: 'brightness(0.9)'
                                            }
                                        }}
                                        // className={classes.ResetButton}
                                    >
                                        <Typography sx={{textTransform: 'none', fontSize: '14px'}}>
                                            Reset Password
                                        </Typography>
                                    </LoadingButton>
                                </Grid>
                                <Grid item xs={12}>
                                    <Link to="/sign-in" style={{color: '#1D8B45', textDecoration: 'none'}}>
                                        <Typography sx={{
                                            fontSize: '14px',
                                            color: '#1D8B45',
                                            fontWeight: '600',
                                            textAlign: 'center'
                                        }}>
                                            Back
                                        </Typography>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
                <LandingIntro/>
                {/*<Grid item xs={12} md={7}>*/}
                {/*    <Box sx={{*/}
                {/*        display: 'flex',*/}
                {/*        backgroundColor: '#1D8B45',*/}
                {/*        minHeight: '100vh',*/}
                {/*        height: '100%',*/}
                {/*        width: '100%',*/}
                {/*        justifyContent: 'center',*/}
                {/*        alignItems: 'flex-start'*/}
                {/*    }}>*/}
                {/*        <img src={LandingImage} alt="landing image" style={styles.LandingIntroImage}/>*/}
                {/*    </Box>*/}
                {/*</Grid>*/}
            </Grid>
        </Container>
    );
};

export default ForgotPasswordPage;
