import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import {Container, Link, MenuItem, Select, Snackbar} from "@mui/material";
import {getCountryCode} from "../../../Utils/getCountryCode";
import InputLabel from "@mui/material/InputLabel";
import {
    americaProvinceCodeToNameMap,
    canadaProvinceCodeToNameMap, domain,
    internationalShipmentTypeList, transportationListToCodeMap
} from "../../shared";
import {useEffect, useRef, useState} from "react";
import MuiAlert from "@mui/material/Alert";
import * as React from 'react';
import {PackingListSelect} from "../../PackingList/Business/PackingListSelect";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../../Utils/doToken";
import Button from "@mui/material/Button";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import {BrokerageEstimateItem} from "./BrokerageEstimateItem";
import ups_logo from "../../../images/UPS.png";
import uuCargo_logo from "../../../images/logo.png";
import {BrokerageEstimate} from "./BrokerageEstimate";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import ExcelJS from "exceljs";
import {saveAs} from 'file-saver';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import BoxIcon from '../../../images/boxes.png';
import {useHistory} from "react-router-dom";
import BrokerageErrorLinesTable from "./BrokerageErrorLinesTable";
import {useMediaQuery} from "@material-ui/core";
import {styled} from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
    root: {
        // width: '800px',
        // margin: 'auto',
        paddingBottom: '50px'
    },
    mainContent: {
        width: 1,
        marginTop: '100px'
    },
    transInfo: {
        flexGrow: 1,
        marginTop: '50px',
        backgroundColor: '#ffffff',
        padding: '10px',
        borderRadius: '10px',
        width: 1
    },
    BoxRow: {
        display: 'flex',
        gap: '10px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    FileUpload: {
        display: 'flex',
        gap: '10px',
        justifyContent: 'center',
        alignItems: 'center',
        border: '2px dashed #424242',
        backgroundColor: '#FFF4D2',
        // width: '200px',
        padding: '10px 20px',
        margin: '20px',
        flexDirection: 'column'
    },
    FileUploadRow: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    ButtonCost: {
        backgroundColor: '#F0A04B',
        color: '#000000',
        marginTop: '20px',
        "&:hover": {
            backgroundColor: '#F0A04B',
            color: '#000000',
            boxShadow: '0px 0px 5px 1px rgba(0, 0, 0, .3)'
        }
    },
    Error: {
        margin: '20px 50px',
        backgroundColor: '#CD0404',
        color: '#ffffff',
        borderRadius: '5px',
        // padding: '20px'
    },

    ErrorRow: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
        padding: '10px',
        borderBottom: '2px solid #FFFFFF',
        margin: '10px'

    },

    ErrorMessage: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '10px',
        padding: '0 10px',
        flexDirection: 'column'
    },

    ErrorButton: {
        color: '#CD0404',
        backgroundColor: '#FFFFFF',
        "&:hover": {
            color: '#CD0404',
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 0px 5px 1px rgba(255, 255, 255, .7)'
        }
    },
    headerIcon: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
};

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{children: <span className="MuiTabs-indicatorSpan"/>}}
    />
))({
    border: '1px solid #609966',
    borderRadius: '25px',
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        // maxWidth: 40,
        width: '100%',
        backgroundColor: 'transparent',
    },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({theme}) => ({
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        color: '#000000',
        // border: '1px solid #609966',
        // borderRadius: '25px',
        '&.Mui-selected': {
            color: '#ffffff',
            backgroundColor: '#609966',
            borderRadius: '25px'
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
    }),
);

export const BrokeragePackingList = () => {

    const logos = [
        ups_logo,
        uuCargo_logo
    ]

    const isMobile = useMediaQuery('(max-width: 600px)');

    const CanadianProvinceList = Object.entries(canadaProvinceCodeToNameMap).map(([key, value]) => [key, value]);

    const AmericanStateList = Object.entries(americaProvinceCodeToNameMap).map(([key, value]) => [key, value]);

    const TransportationModeList = Object.entries(transportationListToCodeMap).map(([key, value]) => [key, value]);

    const [brokerageData, setBrokerageData] = useState({
        importCountryCode: "CA",
        importProvince: "BC",
        exportCountryCode: "CN",
        transModes: "INT_OCEAN",
        shipmentType: "COMMERCIAL",
        shipmentItems: []
    })

    const [toastOpen, setToastOpen] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");
    const [errorLines, setErrorLines] = useState([]);

    const [successMessage, setSuccessMessage] = useState("");

    const [packingList, setPackingList] = useState({});

    const [packingListDetails, setPackingListDetails] = useState([]);

    const [brokerageEstimate, setBrokerageEstimate] = useState([]);

    const [selectFile, setSelectFile] = useState(true);

    const [isGetCost, setIsGetCost] = useState(true);

    const [emptyCell, setEmptyCell] = useState(false);

    const errorSection = useRef(null);

    const [show, setShow] = useState('hidden');

    const [tabValue, setTabValue] = useState('brokerage');

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleOpenShow = () => setShow('visible');

    const handleCloseShow = () => setShow('hidden');

    const history = useHistory();

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
        setSuccessMessage('Successfully get brokerage estimate cost.');
        setErrorMessage('');
    }

    const handleErrorMessage = (errors) => {
        setToastOpen(true);
        let errorMessage = '';
        let generalMessage = '';
        let currencyMessage = '';

        // error code 105.001
        const generalError = errors.filter(item => item.code === "105.001");

        if (generalError[0]?.message) generalMessage = generalError[0].message + '.';

        // error code 105.300
        const currencyError = errors.filter(item => item.code === "105.300");
        if (currencyError[0]?.message) {
            currencyMessage = currencyError[0].message + '.'
                + 'Please ensure that the currency column contains the same currency code in all rows. ' +
                'Otherwise, we will be unable to provide you with an accurate cost estimate.';
            setBrokerageEstimate([]);
            setEmptyCell(true);
            setIsGetCost(true);
        }

        // if (errors.length > 0) {
        //     for (let i = 0; i < errors.length; i++) {
        //         if ('index' in errors[i]) {
        //             errorMessage += ` ${errors[i].commodityId}: ${errors[i].message}. `;
        //         }
        //     }
        // }
        setErrorMessage(`${currencyMessage} ${generalMessage} ${errorMessage}`)
    }

    const getLandedCost = async () => {
        let requestURI = `${PARTNER_URI}/brokerage/landedCost`;
        const storedToken = getAccessToken("access_token");
        try {
            const result = await axios({
                method: 'post',
                url: requestURI,
                data: brokerageData,
                headers: {
                    authorization: `Bearer ${storedToken}`
                }
            })
            const {error} = result.data[0];
            console.log('error', error)
            console.log(result);
            setBrokerageEstimate(result.data);
            error === null ? handleSuccessMessage() : handleErrorMessage(error?.errors);
            if (error?.errors && error?.errors?.length > 0) {
                console.log("Error Lines:", error?.errors);
                setErrorLines(error.errors.filter(item => item?.code !== "105.001"));
            }

        } catch (e) {
            setToastOpen(true)
            setErrorMessage('Your Excel file has a problem creating brokerage costs. Please ensure that all fields have the correct values.')
            console.log(e.response)
        }
    }

    const getPackingListDetailListByPackingListId = async (id) => {
        let requestURI = `${PARTNER_URI}/packingList/getPackingListDetailListByPackingListId`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURI,
                params: {packingListId: id}
            })
            const {data} = result;
            // console.log('packing list details', data)
            setPackingListDetails(data);

            const modifiedData = data.map(obj => {
                const {
                    created_at,
                    currency_code,
                    dimension_unit,
                    hs_code,
                    id,
                    item_desc,
                    item_name,
                    packinglist_id,
                    unit_price,
                    uom,
                    updated_at,
                    weight_unit,
                    origi_country,
                    ...rest
                } = obj

                return {
                    currency: currency_code,
                    lengthUnit: dimension_unit,
                    hscode: hs_code,
                    description: item_name,
                    priceEach: unit_price,
                    UOM: uom,
                    weightUnit: weight_unit,
                    originCountryCode: origi_country,
                    ...rest
                }
            });
            // console.log(modifiedData);
            const hasNullValue = modifiedData.some((obj) => Object.values(obj).some((value) => value === null));
            if (hasNullValue) {
                setToastOpen(true);
                setEmptyCell(true);
                setIsGetCost(true);
                setErrorMessage('The Excel file you have selected contains empty fields. ' +
                    'Please ensure that all fields have been populated with the appropriate values. ' +
                    'Otherwise, we will be unable to provide you with a cost estimate.')

            } else {
                setBrokerageData(prevState => ({
                    ...prevState, shipmentItems: modifiedData
                }));
                setIsGetCost(false);
                setEmptyCell(false);
            }
        } catch (e) {
            console.log(e.response)
        }
    }

    const handlePackingList = (data) => {
        getPackingListDetailListByPackingListId(data.packinglist_id);
        setPackingList(data);
        setSelectFile(false);
        setErrorMessage('');
        setBrokerageEstimate([]);
    }

    const handleSelectedFile = (excelData) => {
        const workbook = new ExcelJS.Workbook();

        const worksheet = workbook.addWorksheet('sheet1');

        worksheet.columns = [
            {header: 'itemName', key: 'itemName'},
            {header: 'itemDescription', key: 'itemDescription'},
            {header: 'hscode', key: 'hscode'},
            {header: 'quantity', key: 'quantity'},
            {header: 'unitPrice', key: 'unitPrice'},
            {header: 'currency', key: 'currency'},
            {header: 'length', key: 'length'},
            {header: 'width', key: 'width'},
            {header: 'height', key: 'height'},
            {header: 'dimensionUnit', key: 'dimensionUnit'},
            {header: 'weight', key: 'weight'},
            {header: 'weightUnit', key: 'weightUnit'},
            {header: 'originalCountryCode', key: 'originalCountryCode'},
            {header: 'Unit of Measurement', key: 'UOM'}
        ];

        for (let i = 0; i < worksheet.columns.length; i++) {
            worksheet.getColumn(i + 1).width = 30;
        }

        // worksheet.getColumn()

        excelData?.forEach(row => {
            worksheet.addRow([
                row?.item_name,
                row?.item_desc,
                row?.hs_code,
                row?.quantity,
                row?.unit_price,
                row?.currency_code,
                row?.length,
                row?.width,
                row?.height,
                row?.dimension_unit,
                row?.weight,
                row?.weight_unit,
                row?.origi_country,
                row?.uom
            ])
        })

        worksheet.eachRow((row) => {
            row.eachCell({includeEmpty: true}, (cell) => {
                if (cell.value === null) {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {argb: 'DC0000'},
                    };
                    console.log('1')
                }
            });
        });

        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            saveAs(blob, `${packingList.packinglist_name}`);
        });
    }

    const scrollDown = (ref) => {
        // console.log(ref.current.offsetTop)
        window.scrollTo({
            top: ref?.current?.offsetTop - 100,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        scrollDown(errorSection)
    }, [errorMessage])


    useEffect(() => {
        if (tabValue === 'ltl') {
            history.push('/business/shipping-ltl/quick-quote')
        } else if (tabValue === 'package') {
            history.push('/business/shipping-package/quick-quote')
        } else {
            return;
        }
    }, [tabValue])

    return (
        <Container maxWidth='xl'>
            <Box sx={style.root}>
                <Snackbar
                    anchorOrigin={{vertical: "top", horizontal: "center"}}
                    open={toastOpen}
                    onClose={handleToastClose}
                    autoHideDuration={6000}
                    message="Submit Transaction"
                >
                    {(() => {
                        if (errorMessage !== "") {
                            return (
                                <Alert
                                    onClose={handleToastClose}
                                    severity="error"
                                    sx={{width: "100%"}}
                                >
                                    Error!
                                    <hr/>
                                    {errorMessage}
                                </Alert>
                            );
                        }
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="success"
                                sx={{width: "100%"}}
                            >
                                {successMessage}
                            </Alert>
                        );
                    })()}
                </Snackbar>
                <Box sx={style.mainContent}>
                    <Box sx={{borderBottom: '1px solid #609966', borderRadius: '0 25px'}}>
                        <Box sx={{width: isMobile ? '300px' : '600px'}}>
                            <StyledTabs
                                value={tabValue}
                                onChange={handleChangeTab}
                                variant='fullWidth'
                            >
                                <StyledTab label="Package" value="package"/>
                                <StyledTab label="LTL (Pallet)" value="ltl"/>
                                <StyledTab label="Custom Brokerage" value="brokerage"/>
                            </StyledTabs>
                        </Box>
                    </Box>
                    <Typography variant="h5" style={{textAlign: 'left', marginTop: '20px', fontWeight: '600'}}>
                        Brokerage Cost Estimate
                    </Typography>
                    <Box>
                        <Box sx={style.headerIcon}>
                            <Box sx={{display: 'flex', justifyContent: 'flex-end', position: 'relative'}}>
                                <IconButton
                                    onClick={() => {
                                        history.push("/business/brokerage/estimate");
                                    }}
                                    onMouseEnter={handleOpenShow}
                                    onMouseLeave={handleCloseShow}
                                >
                                    <img src={BoxIcon} alt="box" width='30px'/>
                                </IconButton>
                                <Typography
                                    sx={{
                                        visibility: `${show}`,
                                        backgroundColor: '#424242',
                                        width: '150px',
                                        height: '20px',
                                        borderRadius: '5px',
                                        color: '#ffffff',
                                        fontSize: '12px',
                                        position: 'absolute',
                                        top: '-25%',
                                        right: '5%'
                                    }}
                                >From My Boxes or Item
                                </Typography>
                            </Box>
                        </Box>
                        <PackingListSelect handlePackingList={handlePackingList}/>
                        {!selectFile &&
                            <Box sx={style.FileUpload}>
                                <Box sx={style.FileUploadRow}>
                                    <InsertDriveFileIcon/>
                                    <Typography>
                                        {packingList?.packinglist_name}
                                    </Typography>
                                </Box>
                            </Box>
                        }
                        <Box>
                            <Box sx={style.transInfo}>
                                <Box sx={{display: 'flex', margin: '20px 10px'}}>
                                    <Typography variant="h6" sx={{textAlign: "left", minWidth: '200px'}}>
                                        Import From
                                    </Typography>
                                    <FormControl variant="standard" fullWidth>
                                        {/*<InputLabel>Export Country*</InputLabel>*/}
                                        <Select
                                            value={brokerageData?.exportCountryCode}
                                            label="export country"
                                            onChange={e => setBrokerageData(prevState => ({
                                                ...prevState,
                                                exportCountryCode: e.target.value
                                            }))}
                                        >
                                            {
                                                getCountryCode().map(v => {
                                                    return <MenuItem value={v.code}>{v.name}</MenuItem>
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box Box sx={{display: 'flex', margin: '20px 10px'}}>
                                    <Typography variant="h6" sx={{textAlign: "left", minWidth: '200px'}}>
                                        Export To
                                    </Typography>
                                    <FormControl variant="standard" fullWidth>
                                        {/*<InputLabel>Import province*</InputLabel>*/}
                                        <Select
                                            value={brokerageData?.importProvince}
                                            label="import province or state"
                                            onChange={e => setBrokerageData(prevState => ({
                                                ...prevState,
                                                importProvince: e.target.value
                                            }))}
                                        >
                                            {brokerageData?.importCountryCode === 'CA' ? (
                                                CanadianProvinceList.map(value => {
                                                    return <MenuItem value={value[0]}>{value[1]}</MenuItem>
                                                })) : (AmericanStateList.map(value => {
                                                return <MenuItem value={value[0]}>{value[1]}</MenuItem>
                                            }))
                                            }
                                        </Select>
                                    </FormControl>
                                    <FormControl variant="standard" fullWidth>
                                        {/*<InputLabel>Import Country*</InputLabel>*/}
                                        <Select
                                            value={brokerageData?.importCountryCode}
                                            label="import province"
                                            onChange={e => setBrokerageData(prevState => ({
                                                ...prevState,
                                                importCountryCode: e.target.value
                                            }))}
                                        >
                                            <MenuItem value={'CA'}>Canada</MenuItem>
                                            <MenuItem value={'US'}>USA</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box sx={{display: 'flex', margin: '20px 10px'}}>
                                    <Typography variant="h6" sx={{textAlign: "left", minWidth: '200px'}}>
                                        Trans Mode
                                    </Typography>
                                    <FormControl variant="standard" fullWidth>
                                        <InputLabel>Tran Mode*</InputLabel>
                                        <Select
                                            value={brokerageData?.transModes}
                                            label="trasn mode"
                                            onChange={e => setBrokerageData(prevState => ({
                                                ...prevState,
                                                transModes: e.target.value
                                            }))}
                                        >
                                            {
                                                TransportationModeList.map(value => {
                                                    return <MenuItem value={value[0]}>{value[1]}</MenuItem>
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                    <FormControl variant="standard" fullWidth>
                                        <InputLabel>Shipment Type*</InputLabel>
                                        <Select
                                            value={brokerageData?.shipmentType}
                                            label="import province"
                                            onChange={e => setBrokerageData(prevState => ({
                                                ...prevState,
                                                shipmentType: e.target.value
                                            }))}
                                        >
                                            {internationalShipmentTypeList.map(value => {
                                                return <MenuItem value={value}>{value}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            <Button
                                onClick={getLandedCost}
                                disabled={isGetCost}
                                sx={style.ButtonCost}
                            >
                                Get Cost
                            </Button>
                        </Box>
                    </Box>
                    {
                        errorMessage !== '' &&
                        <Box sx={style.Error} ref={errorSection}>
                            <Box sx={style.ErrorRow}>
                                <ErrorOutlineIcon/>
                                <Typography>
                                    Errors
                                </Typography>
                            </Box>
                            <Box sx={style.ErrorMessage}>
                                <Typography>
                                    {/*{errorMessage}*/}
                                    <BrokerageErrorLinesTable errors={errorLines}/>
                                </Typography>
                                {emptyCell &&
                                    <Button
                                        onClick={() => handleSelectedFile(packingListDetails)}
                                        sx={style.ErrorButton}
                                    >
                                        Download - See Details
                                    </Button>
                                }
                            </Box>
                        </Box>
                    }
                    {
                        brokerageEstimate?.length > 0 &&
                        <BrokerageEstimate
                            brokerageEstimate={brokerageEstimate}
                            packingListDetails={packingListDetails}
                            brokerageData={brokerageData}
                            fromPackingList
                        />
                    }
                </Box>
            </Box>
        </Container>
    )
}