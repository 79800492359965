import Box from "@mui/material/Box";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    countries,
    extractFullAddress,
    isAlphaString,
    isPositiveInteger,
    loadAsyncScript,
    provinces
} from "../../Utils/Helper";
import {useDispatch, useSelector} from "react-redux";
import {updateLandingEstimatePackage} from "../../actions/landingEstimateAction";
import intl from "react-intl-universal";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";

const style = {
    EstimatePackageRoot: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '10px'
    },
    EstimatePackageHeader: {
        fontSize: '16px',
        fontWeight: '600'
    },
    EstimatePackageItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '10px',
        width: '100%',
    },
    EstimatePackageItemSection: {
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        width: '100%',
        alignItems: 'flex-end',
        '@media (max-width: 480px)': {
            flexDirection: 'column',
        }
    },
    EstimatePackageItemSectionBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        width: '100%',
    },
    EstimatePackageItemSectionWarningText: {
        fontSize: '12px',
        color: '#FF0303'
    }
}

const weightUnitOptions = ["lb", "kg"];
const dimensionUnitOptions = ["in", "cm"];

export const EstimatePackage = ({curentLocation}) => {

    const dispatch = useDispatch();

    let missingKeys = useSelector(state => state?.landingEstimate?.landingEstimatePackageMissing);
    let pickupPostalCodeValid = useSelector(state => state?.landingEstimate?.landingEstimatePickupPostalCodeValid);
    let deliverPostalCodeValid = useSelector(state => state?.landingEstimate?.landingEstimateDeliverPostalCodeValid);

    const [pickupPostalCodePredictions, setPickupPostalCodePredictions] = useState([]);
    const [deliverPostalCodePredictions, setDeliverPostalCodePredictions] = useState([]);

    const [quantity, setQuantity] = useState();
    const [length, setLength] = useState();
    const [width, setWidth] = useState();
    const [height, setHeight] = useState();
    const [weight, setWeight] = useState();
    const [weightUnit, setWeightUnit] = useState('lb');
    const [dimensionUnit, setDimensionUnit] = useState('in');

    const [packageData, setPackageData] = useState({
        weightUnit: 'lb',
        dimensionUnit: 'in'
    })

    const [quantityError, setQuantityError] = useState(null);
    const [weightError, setWeightError] = useState(null);
    const [lengthError, setLengthError] = useState(null);
    const [widthError, setWidthError] = useState(null);
    const [heightError, setHeightError] = useState(null);
    const [pickupLocation, setPickupLocation] = useState(null);
    const [deliverLocation, setDeliverLocation] = useState(null);

    const googleMapsPlaceAutocomplete = async (inputValue) => {
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: 'postal_code'
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log('prediction', predictions);
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            // console.log('updated prediction', updatedPredictions);

            return updatedPredictions;

        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }


    const handleQuantity = e => {
        const value = e.target.value;
        const isPositive = isPositiveInteger(e.target.value);
        setQuantity(value);
        setPackageData(prevState => ({
            ...prevState, quantity: value
        }));
        if (isPositive) {
            setQuantityError(false);
        } else {
            setQuantityError(true);
        }
    }

    const handleWeight = e => {
        const value = e.target.value;
        const isPositive = isPositiveInteger(e.target.value);
        setWeight(value);
        setPackageData(prevState => ({
            ...prevState, weight: value
        }));
        if (isPositive) {
            setWeightError(false);
        } else {
            setWeightError(true);
        }
    }

    const handleLength = e => {
        const value = e.target.value;
        const isPositive = isPositiveInteger(e.target.value);
        setLength(value);
        setPackageData(prevState => ({
            ...prevState, length: value
        }));
        if (isPositive) {
            setLengthError(false);
        } else {
            setLengthError(true);
        }
    }

    const handleWidth = e => {
        const value = e.target.value;
        const isPositive = isPositiveInteger(e.target.value);
        setWidth(value);
        setPackageData(prevState => ({
            ...prevState, width: value
        }));
        if (isPositive) {
            setWidthError(false);
        } else {
            setWidthError(true);
        }
    }

    const handleHeight = e => {
        const value = e.target.value;
        const isPositive = isPositiveInteger(e.target.value);
        setHeight(value);
        setPackageData(prevState => ({
            ...prevState, height: value
        }));
        if (isPositive) {
            setHeightError(false);
        } else {
            setHeightError(true);
        }
    }

    const handleWeightUnit = (event, newValue) => {
        setWeightUnit(newValue);
        setPackageData(prevState => ({
            ...prevState, weightUnit: newValue
        }))
    }

    const handleDimensionUnit = (event, newValue) => {
        setDimensionUnit(newValue);
        setPackageData(prevState => ({
            ...prevState, dimensionUnit: newValue
        }))
    }

    const handleInputPickupPostalCode = async (event, value) => {
        setPackageData(prevState => ({
            ...prevState,
            pickupPostalCode: value
        }))
        const predictions = await googleMapsPlaceAutocomplete(value);
        setPickupPostalCodePredictions(predictions);
    }

    const handleChangePickupPostalCode = async (event, value) => {
        console.log('value', value);
        setPickupLocation(value);
        const place = await googleMapsPlaceDetails(value?.placeId);
        console.log('place', place)
        if (place) {
            const address = extractFullAddress(place);
            console.log('address', address);
            setPackageData(prevState => ({
                ...prevState,
                pickupPostalCode: address?.zip,
                pickupProvince: address?.state,
                pickupCountry: address?.country,
            }))
        }
    }

    const handleInputDeliverPostalCode = async (event, value) => {
        setPackageData(prevState => ({
            ...prevState,
            deliverPostalCode: value
        }))
        const predictions = await googleMapsPlaceAutocomplete(value);
        setDeliverPostalCodePredictions(predictions);
    }

    const handleChangeDeliverPostalCode = async (event, value) => {
        console.log('value', value);
        setDeliverLocation(value);
        const place = await googleMapsPlaceDetails(value?.placeId);
        console.log('place', place)
        if (place) {
            const address = extractFullAddress(place);
            console.log('address', address);
            setPackageData(prevState => ({
                ...prevState,
                deliverPostalCode: address?.zip,
                deliverProvince: address?.state,
                deliverCountry: address?.country
            }))
        }
    }

    console.log('local state package data', packageData);
    // console.log('redux store missing keys', missingKeys);

    useEffect(() => {
        dispatch(updateLandingEstimatePackage(packageData))
    }, [packageData])

    return (
        <Box sx={style.EstimatePackageRoot}>
            <Box sx={style.EstimatePackageItem}>
                <Typography style={style.EstimatePackageHeader}>
                    {intl.get('LANDING_PAGE.CALCULATOR.SHIPPING_ITEMS')}
                </Typography>
                <Box sx={style.EstimatePackageItemSection}>
                    <Box sx={style.EstimatePackageItemSectionBox}>
                        <TextField
                            value={quantity}
                            label={intl.get('LANDING_PAGE.CALCULATOR.QUANTITY')}
                            type="number"
                            required
                            fullWidth
                            variant="standard"
                            onInput={handleQuantity}
                        />
                        <Typography sx={style.EstimatePackageItemSectionWarningText}>
                            {missingKeys.includes('quantity') ? intl.get('LANDING_PAGE.CALCULATOR.REQUIRED') : quantityError ? intl.get('LANDING_PAGE.CALCULATOR.POSITIVE_INTEGER') : ''}
                        </Typography>
                    </Box>
                    <Box sx={style.EstimatePackageItemSectionBox}>
                        <TextField
                            value={weight}
                            label={intl.get('LANDING_PAGE.CALCULATOR.WEIGHT')}
                            type="number"
                            required
                            fullWidth
                            variant="standard"
                            onInput={handleWeight}
                        />
                        <Typography sx={style.EstimatePackageItemSectionWarningText}>
                            {missingKeys.includes('weight') ? intl.get('LANDING_PAGE.CALCULATOR.REQUIRED') : weightError ? intl.get('LANDING_PAGE.CALCULATOR.POSITIVE_INTEGER') : ''}
                        </Typography>
                    </Box>
                    <Box sx={style.EstimatePackageItemSectionBox}>
                        <Autocomplete
                            value={weightUnit}
                            fullWidth
                            onChange={handleWeightUnit}
                            options={weightUnitOptions}
                            renderInput={(params) => <TextField
                                variant="standard"
                                required
                                {...params}
                                label={intl.get('LANDING_PAGE.CALCULATOR.WEIGHT_UNIT')}
                            />}
                        />
                        <Typography sx={style.EstimatePackageItemSectionWarningText}>
                            {missingKeys.includes('weightUnit') ? intl.get('LANDING_PAGE.CALCULATOR.REQUIRED') : ''}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={style.EstimatePackageItemSection}>
                    <Box sx={style.EstimatePackageItemSectionBox}>
                        <TextField
                            value={length}
                            label={intl.get('LANDING_PAGE.CALCULATOR.LENGTH')}
                            type="number"
                            required
                            fullWidth
                            variant="standard"
                            onInput={handleLength}
                        />
                        <Typography sx={style.EstimatePackageItemSectionWarningText}>
                            {missingKeys.includes('length') ? intl.get('LANDING_PAGE.CALCULATOR.REQUIRED') : lengthError ? intl.get('LANDING_PAGE.CALCULATOR.POSITIVE_INTEGER') : ''}
                        </Typography>
                    </Box>
                    <Box sx={style.EstimatePackageItemSectionBox}>
                        <TextField
                            value={width}
                            label={intl.get('LANDING_PAGE.CALCULATOR.WIDTH')}
                            type="number"
                            required
                            fullWidth
                            variant="standard"
                            onInput={handleWidth}
                        />
                        <Typography sx={style.EstimatePackageItemSectionWarningText}>
                            {missingKeys.includes('width') ? intl.get('LANDING_PAGE.CALCULATOR.REQUIRED') : widthError ? intl.get('LANDING_PAGE.CALCULATOR.POSITIVE_INTEGER') : ''}
                        </Typography>
                    </Box>
                    <Box sx={style.EstimatePackageItemSectionBox}>
                        <TextField
                            value={height}
                            label={intl.get('LANDING_PAGE.CALCULATOR.HEIGHT')}
                            type="number"
                            required
                            fullWidth
                            variant="standard"
                            onInput={handleHeight}
                        />
                        <Typography sx={style.EstimatePackageItemSectionWarningText}>
                            {missingKeys.includes('height') ? intl.get('LANDING_PAGE.CALCULATOR.REQUIRED') : heightError ? intl.get('LANDING_PAGE.CALCULATOR.POSITIVE_INTEGER') : ''}
                        </Typography>
                    </Box>
                    <Box sx={style.EstimatePackageItemSectionBox}>
                        <Autocomplete
                            value={dimensionUnit}
                            fullWidth
                            onChange={handleDimensionUnit}
                            options={dimensionUnitOptions}
                            renderInput={(params) => <TextField
                                variant="standard"
                                {...params}
                                label={intl.get('LANDING_PAGE.CALCULATOR.DIMENSION_UNIT')}
                                required
                            />
                            }
                        />
                        <Typography sx={style.EstimatePackageItemSectionWarningText}>
                            {missingKeys.includes('dimensionUnit') ? intl.get('LANDING_PAGE.CALCULATOR.REQUIRED') : ''}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                    <Box sx={style.EstimatePackageItem}>
                        <Typography style={style.EstimatePackageHeader}>
                            {intl.get('LANDING_PAGE.CALCULATOR.PICKUP')}
                        </Typography>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <Box sx={style.EstimatePackageItemSectionBox}>
                                    <Autocomplete
                                        // freeSolo
                                        options={pickupPostalCodePredictions}
                                        getOptionLabel={option => option?.text}
                                        fullWidth
                                        onChange={handleChangePickupPostalCode}
                                        onInputChange={handleInputPickupPostalCode}
                                        // PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                        renderInput={params => <TextField
                                            {...params}
                                            variant='standard'
                                            label={intl.get('LANDING_PAGE.CALCULATOR.POSTAL_CODE')}
                                            required
                                        />}
                                        renderOption={(option) => (
                                            <Typography
                                                style={{fontSize: '12px'}}
                                            >
                                                {/*<Typography>*/}
                                                {option.description}
                                                {/*</Typography>*/}
                                            </Typography>
                                        )}
                                    />
                                    <Typography sx={style.EstimatePackageItemSectionWarningText}>
                                        {missingKeys.includes('pickupPostalCode') ?
                                            intl.get('LANDING_PAGE.CALCULATOR.REQUIRED') : pickupPostalCodeValid ? ''
                                                : intl.get('LANDING_PAGE.CALCULATOR.INVALID_POSTAL_CODE')}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '100%',
                                    justifyContent: 'flex-start'
                                }}>
                                    <Typography style={{textAlign: 'left'}}>
                                        {pickupLocation?.description}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Box sx={style.EstimatePackageItem}>
                        <Typography style={style.EstimatePackageHeader}>
                            {intl.get('LANDING_PAGE.CALCULATOR.DESTINATION')}
                        </Typography>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <Box sx={style.EstimatePackageItemSectionBox}>
                                    <Autocomplete
                                        // freeSolo
                                        options={deliverPostalCodePredictions}
                                        getOptionLabel={option => option?.text}
                                        fullWidth
                                        onChange={handleChangeDeliverPostalCode}
                                        onInputChange={handleInputDeliverPostalCode}
                                        // PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                        renderInput={params => <TextField
                                            {...params}
                                            variant='standard'
                                            label={intl.get('LANDING_PAGE.CALCULATOR.POSTAL_CODE')}
                                            required
                                        />}
                                        renderOption={(option) => (
                                            <Typography
                                                style={{fontSize: '12px'}}
                                            >
                                                {/*<Typography>*/}
                                                {option.description}
                                                {/*</Typography>*/}
                                            </Typography>
                                        )}
                                    />
                                    <Typography sx={style.EstimatePackageItemSectionWarningText}>
                                        {missingKeys.includes('deliverPostalCode') ?
                                            intl.get('LANDING_PAGE.CALCULATOR.REQUIRED') : deliverPostalCodeValid ? ''
                                                : intl.get('LANDING_PAGE.CALCULATOR.INVALID_POSTAL_CODE')}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '100%',
                                    justifyContent: 'flex-start'
                                }}>
                                    <Typography style={{textAlign: 'left'}}>
                                        {deliverLocation?.description}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}