import Grid from "@material-ui/core/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const style = {
    PackageOrderDashboardTransactionPendingRoot: {
        border: '1px solid #B2B2B2',
        borderRadius: '10px',
        padding: '10px 0',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    }
}

export const BusinessPackageOrderDashboardTransactionPending = ({pendingTransactionList}) => {


    return (
        <Box sx={style.PackageOrderDashboardTransactionPendingRoot}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '10px',
                        borderBottom: '1px solid #B2B2B2'
                    }}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            Pending Transactions
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        padding: '5px 10px',
                        flexDirection: 'column',
                        gap: '10px'
                    }}>
                        <Typography
                            style={{
                                fontSize: '16px',
                                textAlign: 'center',
                            }}>
                            # Pending Transactions: {pendingTransactionList?.length}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}