import React, {useEffect, useState} from 'react';
import {Box, IconButton, Tooltip, Typography} from "@mui/material";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {
    addMovingFurnitureItemList,
    removeMovingFurnitureItemList,
    updateMovingFurnitureItemList
} from "../../actions/movingServiceAction";
import Grid from "@mui/material/Grid";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import {ReactComponent as WrappingIcon} from "../../images/movingService/wrappingService.svg";
import {ReactComponent as AssemblyIcon} from "../../images/movingService/assemblyService.svg";
import {ReactComponent as DisassemblyIcon} from "../../images/movingService/disassemblyService.svg";

const useStyles = makeStyles((theme) => ({
    itemBox: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[2],
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minWidth: "200px"
    },
    itemText: {
        fontWeight: theme.typography.fontWeightMedium,
    },
    itemCounter: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const MovingServiceRoomPlaceItemEach = ({
                                            categoryName,
                                            categoryDescription,
                                            cateId,
                                            roomPlaceName,
                                            lengthInch,
                                            widthInch,
                                            heightInch,
                                            weightLB,
                                            volumeCubicInch,
                                            roomPlace,
                                        }) => {

    const dispatch = useDispatch();

    const classes = useStyles();
    const [quantity, setQuantity] = useState(0);
    const [wrappingService, setWrappingService] = useState(false);
    const [assemblyService, setAssemblyService] = useState(false);
    const [disassemblyService, setDisassemblyService] = useState(false);
    const [isExistingItem, setIsExistingItem] = useState(false);

    let movingFurnitureListRedux = useSelector(state => state?.movingService?.movingFurnitureList);

    const handleRemoveItem = () => {
        const existingItem = movingFurnitureListRedux.find(item => item.cate_id === cateId && item?.roomPlace === roomPlace);
        if (+quantity <= 0) {
            return;
        }
        // Remove Item From Redux
        if (+quantity === 1) {
            dispatch(removeMovingFurnitureItemList({
                cate_id: cateId,
                roomPlace: roomPlace
            }));
        } else {
            dispatch(updateMovingFurnitureItemList({
                description: categoryDescription,
                category: categoryName,
                length: lengthInch,
                width: widthInch,
                height: heightInch,
                weight: weightLB,
                volume: volumeCubicInch,
                lengthUnit: "in",
                weightUnit: "lb",
                quantity: +quantity - 1,
                cate_id: cateId,
                room_place: roomPlaceName,
                roomPlace: roomPlace,
                packageSpecialServices: existingItem?.packageSpecialServices
            }))
        }
        setQuantity(+quantity - 1);
    };

    const handleAddItem = () => {
        const existingItem = movingFurnitureListRedux.find(item => item.cate_id === cateId && item?.roomPlace === roomPlace);
        if (existingItem) {
            const {quantity: originQuantity} = existingItem;
            dispatch(updateMovingFurnitureItemList({
                description: categoryDescription,
                category: categoryName,
                length: lengthInch,
                width: widthInch,
                height: heightInch,
                weight: weightLB,
                volume: volumeCubicInch,
                lengthUnit: "in",
                weightUnit: "lb",
                quantity: originQuantity + 1,
                cate_id: cateId,
                room_place: roomPlaceName,
                roomPlace: roomPlace,
                packageSpecialServices: existingItem?.packageSpecialServices
            }))
        } else {
            const newFurniture = {
                description: categoryDescription,
                category: categoryName,
                length: lengthInch,
                width: widthInch,
                height: heightInch,
                weight: weightLB,
                volume: volumeCubicInch,
                lengthUnit: "in",
                weightUnit: "lb",
                quantity: 1,
                cate_id: cateId,
                room_place: roomPlaceName,
                roomPlace: roomPlace,
                packageSpecialServices: {
                    wrappingService: wrappingService,
                    packingService: false,
                    assemblyService: assemblyService,
                    disassemblyService: disassemblyService,
                    roomPlace: roomPlace
                }
            };
            dispatch(addMovingFurnitureItemList(newFurniture));
        }
        setQuantity(quantity + 1);
    }

    const handleWrappingService = (event) => {
        console.log('[handleWrappingService]', event);
        console.log('[handleWrappingService]', event?.target?.checked);
        setWrappingService(event?.target?.checked);
        const existingItem = movingFurnitureListRedux.find(item => item.cate_id === cateId && item?.roomPlace === roomPlace);
        if (existingItem) {
            const {quantity: originQuantity} = existingItem;
            dispatch(updateMovingFurnitureItemList({
                description: categoryDescription,
                category: categoryName,
                length: lengthInch,
                width: widthInch,
                height: heightInch,
                weight: weightLB,
                volume: volumeCubicInch,
                lengthUnit: "in",
                weightUnit: "lb",
                quantity: originQuantity,
                cate_id: cateId,
                room_place: roomPlaceName,
                roomPlace: roomPlace,
                packageSpecialServices: {
                    wrappingService: event?.target?.checked,
                    packingService: false,
                    assemblyService: existingItem?.packageSpecialServices?.assemblyService,
                    disassemblyService: existingItem?.packageSpecialServices?.disassemblyService,
                    roomPlace: existingItem?.packageSpecialServices?.roomPlace
                }
            }))
        }
    }

    const handleAssemblyService = (event) => {
        setAssemblyService(event?.target?.checked);
        const existingItem = movingFurnitureListRedux.find(item => item.cate_id === cateId && item?.roomPlace === roomPlace);
        if (existingItem) {
            const {quantity: originQuantity} = existingItem;
            dispatch(updateMovingFurnitureItemList({
                description: categoryDescription,
                category: categoryName,
                length: lengthInch,
                width: widthInch,
                height: heightInch,
                weight: weightLB,
                volume: volumeCubicInch,
                lengthUnit: "in",
                weightUnit: "lb",
                quantity: originQuantity,
                cate_id: cateId,
                room_place: roomPlaceName,
                roomPlace: roomPlace,
                packageSpecialServices: {
                    wrappingService: existingItem?.packageSpecialServices?.wrappingService,
                    packingService: false,
                    assemblyService: event?.target?.checked,
                    disassemblyService: existingItem?.packageSpecialServices?.disassemblyService,
                    roomPlace: existingItem?.packageSpecialServices?.roomPlace
                }
            }))
        }
    }

    const handleDisassemblyService = (event) => {
        setDisassemblyService(event?.target?.checked);
        const existingItem = movingFurnitureListRedux.find(item => item.cate_id === cateId && item?.roomPlace === roomPlace);
        if (existingItem) {
            const {quantity: originQuantity} = existingItem;
            dispatch(updateMovingFurnitureItemList({
                description: categoryDescription,
                category: categoryName,
                length: lengthInch,
                width: widthInch,
                height: heightInch,
                weight: weightLB,
                volume: volumeCubicInch,
                lengthUnit: "in",
                weightUnit: "lb",
                quantity: originQuantity,
                cate_id: cateId,
                room_place: roomPlaceName,
                roomPlace: roomPlace,
                packageSpecialServices: {
                    wrappingService: existingItem?.packageSpecialServices?.wrappingService,
                    packingService: false,
                    assemblyService: existingItem?.packageSpecialServices?.assemblyService,
                    disassemblyService: event?.target?.checked,
                    roomPlace: existingItem?.packageSpecialServices?.roomPlace
                }
            }))
        }
    }

    useEffect(() => {
        // Get Existing Furniture From Furniture List
        console.log("movingFurnitureListRedux: ", movingFurnitureListRedux);
        console.log("cateId: ", cateId);
        const existingItem = movingFurnitureListRedux.find(item => item.cate_id === cateId && item?.roomPlace === roomPlace);
        console.log("existingItem: ", existingItem);
        if (existingItem) {
            const {quantity: originQuantity} = existingItem;
            setQuantity(originQuantity);
            setWrappingService(existingItem?.packageSpecialServices?.wrappingService);
            setAssemblyService(existingItem?.packageSpecialServices?.assemblyService);
            setDisassemblyService(existingItem?.packageSpecialServices?.disassemblyService);
        } else {
            setQuantity(0);
            setWrappingService(false);
            setAssemblyService(false);
            setDisassemblyService(false);
        }

        return () => {
            setQuantity(0);
            setWrappingService(false);
            setAssemblyService(false);
            setDisassemblyService(false);
        }

    }, [cateId]);

    useEffect(() => {
        const existingItem = movingFurnitureListRedux.some(item => item.cate_id === cateId && item?.roomPlace === roomPlace);
        setIsExistingItem(existingItem);
    }, [cateId, movingFurnitureListRedux])

    console.log('[MovingServiceRoomPlaceItemEach] isExistingItem', isExistingItem);

    return (
        <Box className={classes.itemBox} sx={{backgroundColor: isExistingItem ? `rgba(29, 139, 69, 0.1)` : ''}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="body1" className={classes.itemText}>{categoryName}</Typography>
                    {
                        roomPlace === 'Boxes' &&
                        <Typography variant="body1" className={classes.itemText}>
                            {lengthInch}x{widthInch}x{heightInch} in
                        </Typography>
                    }
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={0}>
                        <Grid item xs={4} xl={2.5}>
                            <Tooltip
                                placement="top"
                                arrow
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            bgcolor: 'white',
                                            color: 'black',
                                            boxShadow: 1,
                                            border: '1px solid #ddd',
                                            borderRadius: '8px', // Added border radius
                                        },
                                    },
                                    arrow: {
                                        sx: {
                                            color: 'white',
                                        },
                                    },
                                }}
                                title={
                                    <Box sx={{backgroundColor: "#ffffff"}}>
                                        <Typography sx={{fontSize: '12px'}}>
                                            When moving out from your home, we will wrap the items to protect them from
                                            dirt or damage.
                                        </Typography>
                                    </Box>
                                }>
                                <FormControlLabel
                                    style={{margin: 0}}
                                    sx={{backgroundColor: '#1D8B45'}}
                                    control={
                                        <Checkbox
                                            checked={wrappingService}
                                            onChange={handleWrappingService}
                                            color='default'
                                            icon={<WrappingIcon width='35' height='35' fill={'#454545'}
                                                                stroke={'#454545'}/>}
                                            checkedIcon={<WrappingIcon width='35' height='35' fill={'#1D8B45'}
                                                                       stroke={'#1D8B45'}/>}
                                        />
                                    }
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={4} xl={2.5}>
                            <Tooltip
                                placement="top"
                                arrow
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            bgcolor: 'white',
                                            color: 'black',
                                            boxShadow: 1,
                                            border: '1px solid #ddd',
                                            borderRadius: '8px', // Added border radius
                                        },
                                    },
                                    arrow: {
                                        sx: {
                                            color: 'white',
                                        },
                                    },
                                }}
                                title={
                                    <Box sx={{backgroundColor: "#ffffff"}}>
                                        <Typography sx={{fontSize: '12px'}}>
                                            We will help you assemble this item at your new location.
                                        </Typography>
                                    </Box>
                                }>
                                <FormControlLabel
                                    style={{margin: 0}}
                                    control={
                                        <Checkbox
                                            checked={assemblyService}
                                            onChange={handleAssemblyService}
                                            color='default'
                                            icon={<AssemblyIcon width='35' height='35' fill={'#454545'}
                                                                stroke={'#454545'}/>}
                                            checkedIcon={<AssemblyIcon width='35' height='35' fill={'#1D8B45'}
                                                                       stroke={'#1D8B45'}/>}
                                        />
                                    }
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={4} xl={2.5}>
                            <Tooltip
                                placement="top"
                                arrow
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            bgcolor: 'white',
                                            color: 'black',
                                            boxShadow: 1,
                                            border: '1px solid #ddd',
                                            borderRadius: '8px', // Added border radius
                                        },
                                    },
                                    arrow: {
                                        sx: {
                                            color: 'white',
                                        },
                                    },
                                }}
                                title={
                                    <Box sx={{backgroundColor: "#ffffff"}}>
                                        <Typography sx={{fontSize: '12px'}}>
                                            We will help you disassemble this item for safe and efficient transport.
                                        </Typography>
                                    </Box>
                                }>
                                <FormControlLabel
                                    style={{margin: 0}}
                                    control={
                                        <Checkbox
                                            checked={disassemblyService}
                                            onChange={handleDisassemblyService}
                                            color='default'
                                            icon={<DisassemblyIcon width='35' height='35' fill={'#454545'}
                                                                   stroke={'#454545'}/>}
                                            checkedIcon={<DisassemblyIcon width='35' height='35' fill={'#1D8B45'}
                                                                          stroke={'#1D8B45'}/>}
                                        />
                                    }
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} xl={4.5}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '100%',
                                height: '100%'
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border: '1px solid #000000',
                                    borderRadius: '5px',
                                }}>
                                    <IconButton size="small"
                                                onClick={handleAddItem}><AddCircleOutlineIcon/></IconButton>
                                    <Typography variant="body1" mx={1}
                                                sx={{fontSize: '14px', color: '#1D8B45'}}>{quantity}</Typography>
                                    <IconButton size="small"
                                                onClick={handleRemoveItem}><RemoveCircleOutlineIcon/></IconButton>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default MovingServiceRoomPlaceItemEach;
