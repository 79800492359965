import {
    ADDRESS_BOOK_ADDRESS_INFO,
    ADDRESS_BOOK_IS_ADD,
    ADDRESS_BOOK_IS_EDIT,
    ADDRESS_BOOK_UPDATE_TAB_VALUE
} from "./types";

export const toggleAddAddress = (isAdd) => ({
    type: ADDRESS_BOOK_IS_ADD,
    payload: isAdd
})

export const toggleEditAddress = (isEdit) => ({
    type: ADDRESS_BOOK_IS_EDIT,
    payload: isEdit
})

export const updateAddressBookTabValue = (tabValue) => ({
    type: ADDRESS_BOOK_UPDATE_TAB_VALUE,
    payload: tabValue
})

export const addAddressInfo = (addressInfo) => ({
    type: ADDRESS_BOOK_ADDRESS_INFO,
    payload: addressInfo
})