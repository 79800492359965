import {Button, Container, Snackbar} from "@mui/material";
import {MovingServiceRoomPlaceItemList} from "./MovingServiceRoomPlaceItemList";
import {MovingServiceItem} from "./MovingServiceItem";
import MovingServiceProgressBar from "./MovingServiceProgressBar";
import {useState} from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";
import {useHistory, useParams} from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import {MovingServiceImage} from "./MovingServiceImage";
import {MovingServicePhotosAndDetails} from "./MovingServicePhotosAndDetails";
import {useSelector} from "react-redux";
import MuiAlert from "@mui/material/Alert";
import * as React from "react";
import MovingItemTitle from "../../images/icons/movingItemTitle.png";

const styles = {
    MovingServiceAddressRoot: {
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        marginBottom: '40px',
    },
    MovingServiceSummaryButtonSection: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

    },
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const requiredItemProperties = ["length", "width", "height", "quantity", "weight", "lengthUnit", "weightUnit", "type"];
const validateRequiredItemProperties = ["length", "width", "height", "weight", "quantity"];

export const MovingServiceList = () => {

    const {tab} = useParams();

    const history = useHistory();

    let itemList = useSelector(state => state?.movingService?.itemList);
    let movingFurnitureList = useSelector(state => state?.movingService?.movingFurnitureList);

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const validateItemList = () => {
        if (itemList.length === 0 && movingFurnitureList?.length === 0) {
            return true
        } else {
            return false
        }
    }

    const validation = () => {
        setErrorMessage('');
        setToastOpen(false);

        const hasMissingValues = itemList.some(object => requiredItemProperties.some((property) => !object[property]));
        const hasValidateValues = itemList.some(object => validateRequiredItemProperties.some((property) => (object[property]) <= 0));
        const hasMissingDescription = itemList.some(item => {
            // Check if cate_id is 0 and description is missing or empty
            return item.cate_id === 0 && (item.description === undefined || item?.description?.trim() === "");
        });

        validateItemList() && setErrorMessage(prevState => prevState + 'Your item list is empty.');
        (hasMissingValues || hasMissingDescription) && setErrorMessage(prevState => prevState + ' Your item list has missing one or more required fields.');
        hasValidateValues && setErrorMessage(prevState => prevState + ' Your item list has one or more invalid fields.');

        if (validateItemList() ||
            hasMissingValues ||
            hasValidateValues ||
            hasMissingDescription
        ) {
            handleErrorMessage();
            return false
        } else {
            return true
        }
    }

    const handleNext = () => {
        if (validation()) {
            if (tab === 'item') {
                history.push('/transportation/item/other');
                window.scrollTo(0, 0); // Scroll to the top
            } else {
                history.push('/transportation/express/other');
                window.scrollTo(0, 0); // Scroll to the top
            }
        }
    }

    const handleBack = () => {
        if (tab === 'item') {
            history.push('/transportation/item');
            window.scrollTo(0, 0); // Scroll to the top
        } else {
            history.push('/transportation/express');
            window.scrollTo(0, 0); // Scroll to the top
        }
    }

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box sx={styles.MovingServiceAddressRoot}>
                <Box display="flex" alignItems="center" p={2}>
                    <img src={MovingItemTitle} alt="Moving items"
                         style={{width: '150px', height: 'auto', marginRight: '16px'}}/>
                    <Box>
                        <Typography variant="h5" component="div" fontWeight="bold">
                            {tab === 'item' ? 'Local & Long-distance Moving' : 'Box Moving Service'}
                        </Typography>
                        <Typography variant="body1">
                            {tab === 'item' ? "Move your residence locally or long-distance, whether it's a small apartment or a large house." : "Limited to Greater Vancouver, Toronto, Calgary, and Montreal Areas"}
                        < /Typography>
                    </Box>
                </Box>
                <MovingServiceProgressBar progress={50}/>
                <MovingServiceRoomPlaceItemList tab={tab}/>
                {/*<MovingServiceItem tab={'item'}/>*/}
                {/*<MovingServiceImage/>*/}
                <MovingServicePhotosAndDetails/>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={styles.MovingServiceSummaryButtonSection}>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: '#F2BE22',
                                    "&:hover": {
                                        backgroundColor: '#F2BE22',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                onClick={handleBack}
                            >
                                <Typography style={{textTransform: 'none'}}>
                                    {intl.get('BUTTON.BACK')}
                                </Typography>
                            </Button>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: '#1D8B45',
                                    "&:hover": {
                                        backgroundColor: '#1D8B45',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                onClick={handleNext}
                            >
                                <Typography style={{textTransform: 'none'}}>
                                    {intl.get('BUTTON.NEXT')}
                                </Typography>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}