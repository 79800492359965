/**
 * @param {string} inputValue Input size value
 * @param {number} limit The upper limit of the input value
 * @return {string}
 */
export const inputChecker = (inputValue, limit) => {
  if (inputValue === null || inputValue === "") return "";
  if (isNaN(inputValue)) return "Not a valid number";
  if (inputValue <= 0) return "No less than 0";
  if (limit && inputValue > limit) return `No larger than ${limit}`;
  return "";
};
