import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import intl from "react-intl-universal";
import * as React from "react";
import FormControl from "@mui/material/FormControl";
import {InputAdornment, MenuItem, Select} from "@mui/material";
import {useEffect, useState} from "react";
import InputLabel from "@mui/material/InputLabel";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {extractFullAddress} from "../../Utils/Helper";
import {updateLandingEstimateBox, updateLandingEstimatePackage} from "../../actions/landingEstimateAction";
import {useDispatch, useSelector} from "react-redux";

export const EstimateBox = () => {

    const dispatch = useDispatch();

    let missingKeys = useSelector(state => state?.landingEstimate?.landingEstimateBoxMissing);
    const [commonBoxesList, setCommonBoxesList] = useState([
        {box_name: 'XXXL Box', length: '48', width: '40', height: '60', weight: '1100'},
        {box_name: 'XXL Box', length: '36', width: '24', height: '24', weight: '80'},
        {box_name: 'XL Box', length: '24', width: '18', height: '24', weight: '65'},
        {box_name: 'Large Box', length: '18', width: '18', height: '24', weight: '65'},
        {box_name: 'Medium Box', length: '18.125', width: '18', height: '16', weight: '65'},
        {box_name: 'Small Box', length: '16.375', width: '12.625', height: '12.625', weight: '65'}
    ]);

    const [pickupPostalCodePredictions, setPickupPostalCodePredictions] = useState([]);
    const [deliverPostalCodePredictions, setDeliverPostalCodePredictions] = useState([]);
    const [pickupLocation, setPickupLocation] = useState(null);
    const [deliverLocation, setDeliverLocation] = useState(null);
    const [wareHouseLocation, setWareHouseLocation] = useState([]);

    const [boxData, setBoxData] = useState({
        weightUnit: 'lb',
        dimensionUnit: 'in',
        cateId: 100,
        type: 'XLBox',
        length: 24,
        width: 18,
        height: 24,
        weight: 65,
        quantity: 1,
        pickupOption: 'schedule',
        deliverOption: 'schedule'
    });

    const getWareHouseLocation = async () => {
        let requestURL = `${NODE_ROUTE_URI}/movingExpressService/getWarehousesList`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL
            })
            console.log('[MovingServiceExpress] - getWareHouseLocation - result', result?.data);
            setWareHouseLocation(result?.data);
        } catch (e) {
            console.log('[MovingServiceExpress] - getWareHouseLocation - error', e?.response);
        }
    }

    const googleMapsPlaceAutocomplete = async (inputValue) => {
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: 'postal_code'
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log('prediction', predictions);
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            // console.log('updated prediction', updatedPredictions);

            return updatedPredictions;

        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const handleInputPickupPostalCode = async (event, value) => {
        setBoxData(prevState => ({
            ...prevState,
            pickupPostalCode: value
        }))
        const predictions = await googleMapsPlaceAutocomplete(value);
        console.log('[handleInputPickupPostalCode] predictions', predictions);
        setPickupPostalCodePredictions(predictions);
    }

    const handleChangePickupPostalCode = async (event, value) => {
        console.log('value', value);
        setPickupLocation(value);
        const place = await googleMapsPlaceDetails(value?.placeId);
        console.log('place', place)
        if (place) {
            const address = extractFullAddress(place);
            console.log('address', address);
            setBoxData(prevState => ({
                ...prevState,
                pickupPostalCode: address?.zip,
                pickupProvince: address?.state,
                pickupCountry: address?.country,
                pickupCity: address?.city
            }))
        }
    }

    const handleInputDeliverPostalCode = async (event, value) => {
        setBoxData(prevState => ({
            ...prevState,
            deliverPostalCode: value
        }))
        const predictions = await googleMapsPlaceAutocomplete(value);
        setDeliverPostalCodePredictions(predictions);
    }

    const handleChangeDeliverPostalCode = async (event, value) => {
        console.log('value', value);
        setDeliverLocation(value);
        const place = await googleMapsPlaceDetails(value?.placeId);
        console.log('place', place)
        if (place) {
            const address = extractFullAddress(place);
            console.log('address', address);
            setBoxData(prevState => ({
                ...prevState,
                deliverPostalCode: address?.zip,
                deliverProvince: address?.state,
                deliverCountry: address?.country,
                deliverCity: address?.city
            }))
        }
    }

    const handleSelectBoxType = (event) => {
        const boxSize = event.target.value;
        if (boxSize === 'customBox') {
            setBoxData(prevState => ({
                ...prevState,
                type: boxSize
            }));
        } else {
            const find = commonBoxesList?.find((item) => item?.box_name?.replace(/\s+/g, '') === boxSize);
            setBoxData(prevState => ({
                ...prevState,
                type: boxSize,
                height: find?.height,
                length: find?.length,
                width: find?.width,
                weight: find?.weight
            }))
        }
    }

    const handleLength = event => {

        const {value} = event.target;

        if (/^\d*\.?\d*$/.test(value)) {
            const length = 48
            setBoxData(prevState => ({
                ...prevState,
                length: value > (+length) ? length : value
            }))
        }
    }

    const handleWidth = event => {

        const {value} = event.target;

        if (/^\d*\.?\d*$/.test(value)) {
            const length = 40
            setBoxData(prevState => ({
                ...prevState,
                width: value > (+length) ? length : value
            }))
        }
    }

    const handleHeight = event => {

        const {value} = event.target;

        if (/^\d*\.?\d*$/.test(value)) {
            const length = 60
            setBoxData(prevState => ({
                ...prevState,
                height: value > (+length) ? length : value
            }))
        }
    }

    const handleWeight = event => {

        const {value} = event.target;

        if (/^\d*\.?\d*$/.test(value)) {
            const length = 3000
            setBoxData(prevState => ({
                ...prevState,
                weight: value > (+length) ? length : value
            }))
        }
    }

    const handleQuantity = event => {

        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            setBoxData(prevState => ({
                ...prevState,
                quantity: value
            }))
        }
    }

    const handleSelectMovingFromService = (event) => {
        setBoxData(prevState => ({
            ...prevState,
            pickupOption: event.target.value,
            pickupWarehouse: null,
            pickupPostalCode: null,
            pickupProvince: null,
            pickupCountry: null,
            pickupCity: null
        }));
    }

    const handleSelectMovingToService = (event) => {
        setBoxData(prevState => ({
            ...prevState,
            deliverOption: event.target.value,
            deliverWarehouse: null,
            deliverPostalCode: null,
            deliverProvince: null,
            deliverCountry: null,
            deliverCity: null
        }));
    }

    const handleSelectMovingFromWareHouse = (event, value) => {
        console.log('[handleSelectMovingFromWareHouse] wareHouseInfo', value);
        const wareHouse = `${value?.name}, ${value?.city}, ${value?.province}, ${value?.postal_code}, ${value?.country}`;
        setPickupLocation(wareHouse);
        setBoxData(prevState => ({
            ...prevState,
            pickupWarehouse: value,
            pickupCity: value?.city,
            pickupCountry: {
                ...prevState.country,
                code: value?.country,
                name: value?.country
            }?.country,
            pickupProvince: {
                ...prevState.province,
                code: value?.province,
                name: value?.province
            },
            pickupPostalCode: value?.postal_code
        }));
    }

    const handleSelectMovingToWareHouse = (event, value) => {
        console.log('[handleSelectMovingFromWareHouse] wareHouseInfo', value);
        setBoxData(prevState => ({
            ...prevState,
            deliverWarehouse: value,
            deliverCity: value?.city,
            deliverCountry: {
                ...prevState.country,
                code: value?.country,
                name: value?.country
            }?.country,
            deliverProvince: {
                ...prevState.province,
                code: value?.province,
                name: value?.province
            },
            deliverPostalCode: value?.postal_code
        }));
    }

    useEffect(() => {
        dispatch(updateLandingEstimateBox(boxData))
    }, [boxData])

    useEffect(() => {
        getWareHouseLocation();
    }, [])

    console.log('[EstimateBox] boxData', boxData);
    console.log('[EstimateBox] missingKeys', missingKeys);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: '600'
                }}>
                    {intl.get('LANDING_PAGE.CALCULATOR.MOVING_BOXES')}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <InputLabel sx={{textAlign: 'left'}}
                                required>{intl.get('LANDING_PAGE.CALCULATOR.SIZE')}</InputLabel>
                    <Select
                        value={boxData.type}
                        fullWidth
                        variant="standard"
                        onChange={handleSelectBoxType}
                        size='small'
                    >
                        <MenuItem value='customBox' data-value='customBox'>Custom Box</MenuItem>
                        {
                            commonBoxesList?.map((each, index) => <MenuItem
                                key={index}
                                value={each?.box_name?.replace(/\s+/g, '')}
                            >{each?.box_name}</MenuItem>)
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <TextField
                        value={boxData?.quantity}
                        label={intl.get('LANDING_PAGE.CALCULATOR.QUANTITY')}
                        type="number"
                        required
                        fullWidth
                        variant="standard"
                        onInput={handleQuantity}
                        error={missingKeys?.includes('quantity')}
                        helperText={missingKeys?.includes('quantity') && intl.get('LANDING_PAGE.CALCULATOR.REQUIRED')}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <TextField
                        value={boxData?.weight}
                        label={intl.get('LANDING_PAGE.CALCULATOR.WEIGHT')}
                        type="number"
                        required
                        fullWidth
                        variant="standard"
                        onInput={handleWeight}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {/*{itemDetail?.lengthUnit}*/}
                                    lb
                                </InputAdornment>
                            ),
                            inputProps: {
                                min: 1
                            },
                            style: {fontSize: 16} // font size of input text
                        }}
                        error={missingKeys?.includes('weight')}
                        helperText={missingKeys?.includes('weight') && intl.get('LANDING_PAGE.CALCULATOR.REQUIRED')}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <TextField
                        value={boxData?.length}
                        label={intl.get('LANDING_PAGE.CALCULATOR.LENGTH')}
                        type="number"
                        required
                        fullWidth
                        variant="standard"
                        onInput={handleLength}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {/*{itemDetail?.lengthUnit}*/}
                                    in
                                </InputAdornment>
                            ),
                            inputProps: {
                                min: 1
                            },
                            style: {fontSize: 16} // font size of input text
                        }}
                        error={missingKeys?.includes('length')}
                        helperText={missingKeys?.includes('length') && intl.get('LANDING_PAGE.CALCULATOR.REQUIRED')}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <TextField
                        value={boxData?.width}
                        label={intl.get('LANDING_PAGE.CALCULATOR.WIDTH')}
                        type="number"
                        required
                        fullWidth
                        variant="standard"
                        onInput={handleWidth}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {/*{itemDetail?.lengthUnit}*/}
                                    in
                                </InputAdornment>
                            ),
                            inputProps: {
                                min: 1
                            },
                            style: {fontSize: 16} // font size of input text
                        }}
                        error={missingKeys?.includes('width')}
                        helperText={missingKeys?.includes('width') && intl.get('LANDING_PAGE.CALCULATOR.REQUIRED')}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <TextField
                        value={boxData?.height}
                        label={intl.get('LANDING_PAGE.CALCULATOR.HEIGHT')}
                        type="number"
                        required
                        fullWidth
                        variant="standard"
                        onInput={handleHeight}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {/*{itemDetail?.lengthUnit}*/}
                                    in
                                </InputAdornment>
                            ),
                            inputProps: {
                                min: 1
                            },
                            style: {fontSize: 16} // font size of input text
                        }}
                        error={missingKeys?.includes('height')}
                        helperText={missingKeys?.includes('height') && intl.get('LANDING_PAGE.CALCULATOR.REQUIRED')}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: '600'
                }}>
                    {intl.get('LANDING_PAGE.CALCULATOR.PICKUP')}
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                    <InputLabel required>{intl.get('LANDING_PAGE.CALCULATOR.SERVICE_TYPE')}</InputLabel>
                    <Select
                        value={boxData?.pickupOption}
                        variant='standard'
                        size='small'
                        onChange={handleSelectMovingFromService}
                        // disabled={true}
                    >
                        <MenuItem value='self'>{intl.get('MOVING.SELF_DROP_OFF')}</MenuItem>
                        <MenuItem value='schedule'>{intl.get('MOVING.SCHEDULED_PICKUP')}</MenuItem>
                    </Select>
                    <FormHelperText
                        sx={{
                            color: "error.main",
                        }}
                    >
                    </FormHelperText>
                </FormControl>
            </Grid>
            {
                boxData?.pickupOption === 'self' &&
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <Autocomplete
                            value={boxData?.pickupWarehouse}
                            fullWidth
                            filterOptions={(options, state) => options}
                            options={wareHouseLocation}
                            getOptionLabel={(option) => (`${option?.name}`)}
                            onChange={handleSelectMovingFromWareHouse}
                            renderInput={(params) => <TextField
                                variant='standard'
                                label={intl.get('LANDING_PAGE.CALCULATOR.WAREHOUSE')}
                                required
                                {...params}
                                error={missingKeys?.includes('pickupPostalCode')}
                                helperText={missingKeys?.includes('pickupPostalCode') && intl.get('LANDING_PAGE.CALCULATOR.REQUIRED')}
                            />}
                            renderOption={(option) => (
                                <div>
                                    {option?.name}, {option?.city}, {option?.province}, {option?.postal_code}, {option?.country}
                                </div>
                            )}
                        />
                    </FormControl>
                </Grid>
            }
            {
                boxData?.pickupOption === 'schedule' &&
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <Autocomplete
                            // freeSolo
                            // value={boxData?.pickupPostalCode}
                            options={pickupPostalCodePredictions}
                            filterOptions={(options, state) => options}
                            getOptionLabel={option => option?.text}
                            fullWidth
                            onChange={handleChangePickupPostalCode}
                            onInputChange={handleInputPickupPostalCode}
                            // PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                            renderInput={params => <TextField
                                {...params}
                                variant='standard'
                                label={intl.get('LANDING_PAGE.CALCULATOR.POSTAL_CODE')}
                                required
                                error={missingKeys?.includes('pickupPostalCode')}
                                helperText={missingKeys?.includes('pickupPostalCode') && intl.get('LANDING_PAGE.CALCULATOR.REQUIRED')}
                            />}
                            renderOption={(option) => (
                                <Typography
                                    style={{fontSize: '12px'}}
                                >
                                    {/*<Typography>*/}
                                    {option.description}
                                    {/*</Typography>*/}
                                </Typography>
                            )}
                        />
                    </FormControl>
                </Grid>
            }
            <Grid item xs={12}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    justifyContent: 'flex-start'
                }}>
                    <Typography style={{textAlign: 'left'}}>
                        {boxData?.pickupPostalCode && `${boxData?.pickupCity}, ${boxData?.pickupProvince?.code}, ${boxData?.pickupPostalCode}`}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: '600'
                }}>
                    {intl.get('LANDING_PAGE.CALCULATOR.DESTINATION')}
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                    <InputLabel required>{intl.get('MOVING.SERVICE_TYPE')}</InputLabel>
                    <Select
                        value={boxData?.deliverOption}
                        size='small'
                        variant='standard'
                        onChange={handleSelectMovingToService}
                        // disabled={true}
                    >
                        <MenuItem value='self'>{intl.get('MOVING.SELF_PICKUP')}</MenuItem>
                        <MenuItem value='schedule'>{intl.get('MOVING.SCHEDULED_DELIVER')}</MenuItem>
                    </Select>
                    <FormHelperText
                        sx={{
                            color: "error.main",
                        }}
                    >
                    </FormHelperText>
                </FormControl>
            </Grid>
            {
                boxData?.deliverOption === 'self' &&
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <Autocomplete
                            value={boxData?.deliverWarehouse}
                            fullWidth
                            filterOptions={(options, state) => options}
                            options={wareHouseLocation}
                            getOptionLabel={(option) => (`${option?.name}`)}
                            onChange={handleSelectMovingToWareHouse}
                            renderInput={(params) => <TextField
                                variant='standard'
                                label={intl.get('LANDING_PAGE.CALCULATOR.WAREHOUSE')}
                                required
                                {...params}
                                error={missingKeys?.includes('deliverPostalCode')}
                                helperText={missingKeys?.includes('deliverPostalCode') && intl.get('LANDING_PAGE.CALCULATOR.REQUIRED')}
                            />}
                            renderOption={(option) => (
                                <div>
                                    {option?.name}, {option?.city}, {option?.province}, {option?.postal_code}, {option?.country}
                                </div>
                            )}
                        />
                    </FormControl>
                </Grid>
            }
            {
                boxData?.deliverOption === 'schedule' &&
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <Autocomplete
                            // freeSolo
                            options={deliverPostalCodePredictions}
                            getOptionLabel={option => option?.text}
                            fullWidth
                            onChange={handleChangeDeliverPostalCode}
                            onInputChange={handleInputDeliverPostalCode}
                            // PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                            renderInput={params => <TextField
                                {...params}
                                variant='standard'
                                label={intl.get('LANDING_PAGE.CALCULATOR.POSTAL_CODE')}
                                required
                                error={missingKeys?.includes('deliverPostalCode')}
                                helperText={missingKeys?.includes('deliverPostalCode') && intl.get('LANDING_PAGE.CALCULATOR.REQUIRED')}
                            />}
                            renderOption={(option) => (
                                <Typography
                                    style={{fontSize: '12px'}}
                                >
                                    {/*<Typography>*/}
                                    {option.description}
                                    {/*</Typography>*/}
                                </Typography>
                            )}
                        />
                    </FormControl>
                </Grid>
            }
            <Grid item xs={12}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    justifyContent: 'flex-start'
                }}>
                    <Typography style={{textAlign: 'left'}}>
                        {boxData?.deliverPostalCode && `${boxData?.deliverCity}, ${boxData?.deliverProvince?.code}, ${boxData?.deliverPostalCode}`}
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    )
}