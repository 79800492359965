import {getAccessToken} from "../../Utils/doToken";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {useEffect, useState} from "react";
import {getBackendOrderStatusCode} from "../../Utils/Helper";
import {MyOrdersNotPaid} from "./MyOrdersNotPaid";
import {MyOrdersActive} from "./MyOrdersActive";
import {MyOrdersCancelled} from "./MyOrdersCancelled";
import {MyOrdersRefunded} from "./MyOrdersRefunded";
import Box from "@mui/material/Box";
import {Pagination} from '@material-ui/lab';
import TextField from "@mui/material/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {Button} from "@mui/material";
import * as React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@mui/material/Typography";

const courierList = [
    {name: 'ALL', code: ''},
    {name: 'UPS', code: 'ups'},
    {name: 'Canada Post', code: 'canadaPost'},
    {name: 'Canpar', code: 'canpar'},
    {name: 'FedEx', code: 'fedex'}
]

const styles = {
    MyOrdersSearch: {
        display: 'flex',
        gap: '10px',
        alignItems: 'flex-end',
        width: '100%',
        margin: '5px 0 10px'
    },
    MyOrdersSearchButtonBox: {
        backgroundColor: '#2192FF',
        borderRadius: '5px',
        ':hover': {
            opacity: '0.8',
        }
    },
    MyOrdersSearchButton: {
        color: '#FFFFFF'
    },
}

export const MyOrdersTable = ({tabs}) => {

    const accessToken = getAccessToken("access_token");

    const [totalPage, setTotalPage] = useState(0);
    const [pageNum, setPageNum] = useState(1);
    const [orderList, setOrderList] = useState();

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [courier, setCourier] = useState('');

    const isMobile = useMediaQuery('(max-width: 480px)');

    const fontSize = isMobile ? 10 : 14;

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };

    const handleCourier = (event, value) => {
        setCourier(value);
    }

    const getNotPaidOrCancelledOrders = async (data) => {
        let requestURL = `${NODE_ROUTE_URI}/endUserShippingOrderList/getBackendOrderViewListByEndUserIdAndOthers`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: data
            })
            const orderList = result.data.data;
            const pagination = result.data.pagination;
            console.log('not paid', orderList)
            setOrderList(orderList);
            setTotalPage(pagination.lastPage);
        } catch (e) {
            console.log(e)
        }
    }

    const getPaidOrRefundedOrders = async (data) => {
        let requestURL = `${NODE_ROUTE_URI}/endUserShippingOrderList/getBackendOrderShippingOrderViewListByEndUserIdAndOthers`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: data
            })
            const orderList = result.data.data;
            const pagination = result.data.pagination;
            console.log('paid', orderList)
            setOrderList(orderList);
            setTotalPage(pagination.lastPage);
        } catch (e) {
            console.log(e)
        }
    }

    const getBackendOrders = () => {
        setPageNum(1);
        setCourier('');
        setStartDate('');
        setEndDate('');
        const statusCode = getBackendOrderStatusCode(tabs);
        let data = {
            statusArray: statusCode,
            page: 1,
            pageSize: 5
        }
        console.log('status code', statusCode);
        if (statusCode[0] === 0 || statusCode[0] === -1) {
            getNotPaidOrCancelledOrders(data);
        } else {
            getPaidOrRefundedOrders(data);
        }
    }

    const handlePage = (event, value) => {
        setPageNum(value);
        const statusCode = getBackendOrderStatusCode(tabs);
        let data = {
            statusArray: statusCode,
            page: value,
            pageSize: 5,
            createDateBefore: startDate,
            createDateAfter: endDate,
            carrier: courier?.code
        }
        if (statusCode[0] === 0 || statusCode[0] === -1) {
            getNotPaidOrCancelledOrders(data);
        } else {
            getPaidOrRefundedOrders(data);
        }
    }

    const handleFilter = () => {
        setPageNum(1);
        const statusCode = getBackendOrderStatusCode(tabs);
        let data = {
            statusArray: statusCode,
            page: 1,
            pageSize: 5,
            createDateBefore: startDate,
            createDateAfter: endDate,
            carrier: courier?.code
        }
        if (statusCode[0] === 0 || statusCode[0] === -1) {
            getNotPaidOrCancelledOrders(data);
        } else {
            getPaidOrRefundedOrders(data);
        }
    }

    useEffect(() => {
        getBackendOrders();
    }, [tabs])

    return (
        <Box>
            <Box sx={styles.MyOrdersSearch}>
                <TextField
                    label='Start Date'
                    type='date'
                    value={startDate}
                    size={isMobile ? 'small' : 'large'}
                    fullWidth
                    variant='standard'
                    onChange={handleStartDateChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{style: {fontSize}}}
                />
                <TextField
                    label='End Date'
                    type='date'
                    value={endDate}
                    fullWidth
                    variant='standard'
                    onChange={handleEndDateChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        inputProps: {
                            min: startDate,
                        },
                        style: {fontSize}
                    }}
                />
                <Autocomplete
                    value={courier}
                    options={courierList}
                    fullWidth
                    getOptionLabel={(option) => option.name}
                    onChange={handleCourier}
                    renderInput={(params) => {
                        return (<TextField
                                ref={params.InputProps.ref}
                                {...params}
                                label="carrier"
                                variant="standard"
                                inputProps={{
                                    ...params.inputProps,
                                    style: {fontSize}
                                }}
                            />
                        )
                    }}
                    renderOption={(option) => (
                        <Typography sx={{fontSize}}>
                            {option.name}
                        </Typography>
                    )}
                />
                <Box sx={styles.MyOrdersSearchButtonBox}>
                    <Button
                        sx={styles.MyOrdersSearchButton}
                        onClick={handleFilter}
                    >
                        <Typography sx={{fontSize}}>
                            filter
                        </Typography>
                    </Button>
                </Box>
            </Box>
            {tabs === 'notPaid' && <MyOrdersNotPaid orderList={orderList}/>}
            {tabs === 'active' && <MyOrdersActive orderList={orderList}/>}
            {tabs === 'cancelled' && <MyOrdersCancelled orderList={orderList}/>}
            {tabs === 'refunded' && <MyOrdersRefunded orderList={orderList}/>}
            <Pagination count={totalPage} page={pageNum} onChange={handlePage}/>
        </Box>

    )
}