import {useState} from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {ReactComponent as CardIcon} from "../../images/welcome/Card.svg";
import {WelcomeCreditCardSetup} from "../CreditCard/WelcomeCreditCardSetup";
import {Button} from "@mui/material";
import Cookies from "js-cookie";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export const WelcomeCard = ({handlePageNum}) => {

    const isMobile = useMediaQuery('(max-width: 480px)');

    const [isAddCard, setIsAddCard] = useState(false);

    const cbHandleAddCard = () => {
        setIsAddCard(true);
    }

    const cbHandleEditCard = () => {
        setIsAddCard(false);
    }

    const handleStarted = () => {
        Cookies.set('welcome', 'yes', {expires: 30});
        window.location.reload();
    }

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
            <Grid container spacing={2}>
                {
                    !isMobile &&
                    <Grid item xs={3}>
                        <CardIcon width="75" height="75"/>
                    </Grid>
                }
                <Grid item md={9} xs={12}>
                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <Typography style={{
                            fontSize: '16px', textAlign: 'left', color: '#1D8B45', fontWeight: '600'
                        }}>
                            Credit Card Setup
                        </Typography>
                        <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                            Before placing any orders, <span style={{color: '#1D8B45', textDecoration: 'underline'}}>it's mandatory
                            to add a credit card</span>.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <WelcomeCreditCardSetup
                cbHandleAddCard={cbHandleAddCard}
                isAddCard={isAddCard}
                cbHandleEditCard={cbHandleEditCard}
            />
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px'
            }}>
                <Button
                    variant='contained'
                    sx={{
                        backgroundColor: '#F2BE22',
                        "&:hover": {
                            backgroundColor: '#F2BE22',
                            filter: 'brightness(0.9)'
                        }
                    }}
                    onClick={() => handleStarted()}
                >
                    <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                        Do It Later
                    </Typography>
                </Button>
                <Button
                    variant='contained'
                    sx={{
                        backgroundColor: '#1D8B45',
                        "&:hover": {
                            backgroundColor: '#1D8B45',
                            filter: 'brightness(0.9)'
                        },
                        padding: '5px 25px',
                    }}
                    onClick={() => handleStarted()}
                    disabled={!isAddCard}
                >
                    <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                        Get Started
                    </Typography>
                </Button>
            </Box>
        </Box>
    )
}