import React from "react";
import { connect } from "react-redux";
import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyle = makeStyles({
  root: {
    margin: "5px auto",
    width: "95%",
    lineHeight: "1.9rem",
    maxWidth: "900px",
    height: "fit-content",
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    paddingLeft: "5%",
    borderRadius: "5px",
    gap: "5px",
  },
  optionLabel: {
    fontWeight: "550",
  },
  chipWrapper: {
    margin: "2px",
    "& .MuiChip-label": {
      fontWeight: "700",
    },
    "& .MuiChip-root": {
      margin: "2px 2px",
      fontFamily: "inherit",
    },
  },
});

const shipmentSize = {
  0: { label: "All Size Pallet" },
  1: { label: "Pallet(48''x40'')" },
  2: { label: "Pallet(48''x48'')" },
  3: { label: "Oversize Pallet" },
  4: { label: "Overweight Pallet" },
  6: { label: "Full Truck(5 ton)" },
  7: { label: "Full Trailer (FTL)" },
};

const SearchOptionsDisplayLayout = ({ searchFilter, filterControll }) => {
  const classes = useStyle();

  const handleChipClick = () => {
    //open filter
    filterControll();
  };

  return (
    <div className={classes.root}>
      {Object.keys(searchFilter).map((option) => {
        //freight areas
        if (option === "area" && searchFilter[option]) {
          return (
            <div key={option} className={classes.chipWrapper}>
              <span className={classes.optionLabel}>Shipment Area:</span>
              <Chip
                variant="outlined"
                size="medium"
                label={(() => {
                  if (searchFilter.freight === "Local") {
                    return `${searchFilter[option]}`;
                  } else if (searchFilter.freight === "All") {
                    return `All Freight`;
                  } else {
                    return `Exclude ${searchFilter.area}`;
                  }
                })()}
                color="primary"
                onClick={handleChipClick}
              />
            </div>
          );
        }

        //pickup/deliver cities
        if (
          (option === "pickupCity" || option === "deliverCity") &&
          searchFilter[option].length > 0
        ) {
          console.log("looping pickup city");
          return (
            <div key={option} className={classes.chipWrapper}>
              {option === "PickCity" ? (
                <span className={classes.optionLabel}>Pickup City:</span>
              ) : (
                <span className={classes.optionLabel}>Deliver City:</span>
              )}
              {searchFilter[option].map((city, index) => {
                return (
                  <Chip
                    variant="outlined"
                    key={index}
                    size="medium"
                    label={city}
                    color="primary"
                    onClick={handleChipClick}
                  />
                );
              })}
            </div>
          );
        }

        //pickup date range
        if (
          option === "pickupDateFrom" &&
          searchFilter[option].length !== 0 &&
          searchFilter.pickupDateTo.length !== 0
        ) {
          return (
            <div key={option} className={classes.chipWrapper}>
              <span className={classes.optionLabel}>Pickup Date: </span>
              <span className={classes.optionLabel}>From </span>
              <Chip
                variant="outlined"
                size="medium"
                label={searchFilter[option]}
                color="primary"
                onClick={handleChipClick}
              />
              <span className={classes.optionLabel}> To </span>
              <Chip
                variant="outlined"
                size="medium"
                label={searchFilter.pickupDateTo}
                color="primary"
                onClick={handleChipClick}
              />
            </div>
          );
        }

        //shipment types
        if (option === "shipmentSize" && searchFilter[option].length > 0) {
          return (
            <div key={option} className={classes.chipWrapper}>
              <span className={classes.optionLabel}>Shipment Types: </span>
              {searchFilter[option].map((sSize, index) => {
                return (
                  <Chip
                    variant="outlined"
                    key={index}
                    size="medium"
                    label={shipmentSize[sSize].label}
                    color="primary"
                    onClick={handleChipClick}
                  />
                );
              })}
            </div>
          );
        }

        //options
        if (option === "pickupLoadingDock" && searchFilter[option]) {
          return (
            <div key={option} className={classes.chipWrapper}>
              <Chip
                variant="outlined"
                size="medium"
                label={`Pickup loading dock`}
                color="primary"
                onClick={handleChipClick}
              />
            </div>
          );
        }

        if (option === "deliverAppointment" && searchFilter[option]) {
          return (
            <div key={option} className={classes.chipWrapper}>
              <Chip
                variant="outlined"
                size="medium"
                label={`Deliver appointment`}
                color="primary"
                onClick={handleChipClick}
              />
            </div>
          );
        }
        if (option === "deliverLoadingDock" && searchFilter[option]) {
          return (
            <div key={option} className={classes.chipWrapper}>
              <Chip
                variant="outlined"
                size="medium"
                label={`Deliver loading dock`}
                color="primary"
                onClick={handleChipClick}
              />
            </div>
          );
        }
      })}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    searchFilter: state.searchFilter,
  };
};

export default connect(mapStateToProps)(SearchOptionsDisplayLayout);
