import {Button, Container, Snackbar} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {MovingServiceRate} from "./MovingServiceRate";
import {MovingServiceAdditionalFee} from "./MovingServiceAdditionalFee";
import Grid from "@mui/material/Grid";
import {MovingServiceInsurance} from "./MovingServiceInsurance";
import {MovingServiceContact} from "./MovingServiceContact";
import {MovingServiceDate} from "./MovingServiceDate";
import LoadingButton from "@mui/lab/LoadingButton";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {MovingServiceImage} from "./MovingServiceImage";
import {useDispatch, useSelector} from "react-redux";
import {checkAllPropertiesHaveValueFalse, getMissingKeysForMovingContact, isValidateEmail} from "../../Utils/Helper";
import {
    updateMovingServiceImageError,
    updateMovingServiceSummaryContactInfoMissing
} from "../../actions/movingServiceAction";
import {MovingServiceAdditionalService} from "./MovingServiceAdditionalService";
import MuiAlert from "@mui/material/Alert";
import {makeStyles} from "@material-ui/core";
import {useHistory, useParams} from "react-router-dom";
import intl from "react-intl-universal";
import {MovingServiceExpressRate} from "./MovingServiceExpressRate";
import {MovingServiceExpressIncludedServices} from "./MovingServiceExpressIncludedServices";
import {isValid} from "date-fns";
import MovingServiceProgressBar from "./MovingServiceProgressBar";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    MovingServiceSummaryRoot: {
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
    },
    MovingServiceSummaryRootTwoCols: {
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        // height: '370px'
    },

    MovingServiceSummaryRootTwoColsCar: {
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        // height: '305px'
    },

    MovingServiceSummaryRootRow: {
        display: 'flex',
        gap: '30px',
        width: '100%'
    },
    MovingServiceSummaryButtonSection: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

    },
    MovingServiceSummaryButton: {
        // width: '100%',
        // height: '100%',
        color: '#FFFFFF',
        fontSize: '16px',
        fontWeight: '600',
        padding: '10px 30px',
        borderRadius: '10px'
    },
}

export const MovingServiceSummary = () => {

    const ownerRole = useSelector(state => state?.user?.ownerRole);

    let basicRateInfo = useSelector(state => state?.movingService?.movingServiceBasicRateInfo);
    let contactInfo = useSelector(state => state?.movingService?.movingServiceSummaryContactInfo);
    let moveDate = useSelector(state => state?.movingService?.movingDate);
    let moveTime = useSelector(state => state?.movingService?.movingTime);
    let movingImage = useSelector(state => state?.movingService?.movingImage);
    let movingFromAddress = useSelector(state => state?.movingService?.movingFromAddress);
    let movingToAddress = useSelector(state => state?.movingService?.movingToAddress);

    const dispatch = useDispatch();

    const history = useHistory();

    const {tab} = useParams();

    const [contactValidate, setContactValidate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const validateContactInfoMissing = () => {
        const missingKeys = getMissingKeysForMovingContact(contactInfo);
        console.log('missing keys - move to', missingKeys);
        dispatch(updateMovingServiceSummaryContactInfoMissing(missingKeys));
        if (missingKeys.length === 0) {
            return false
        } else {
            return true
        }
    }

    const validateContactInfoPickupPhone = () => {

        const checkPhoneNumber = contactInfo.pickupPhone.replace(/\D/g, "");
        if (!isNaN(checkPhoneNumber) && checkPhoneNumber.length < 20 && checkPhoneNumber.length > 9) {
            return false
        } else {
            return true
        }
    }

    const validateContactInfoPickupEmail = () => {
        const result = isValidateEmail(contactInfo?.pickupEmail);
        return result
    }

    const validateContactInfoDeliverPhone = () => {
        const checkPhoneNumber = contactInfo.deliverPhone.replace(/\D/g, "");
        if (!isNaN(checkPhoneNumber) && checkPhoneNumber.length < 20 && checkPhoneNumber.length > 9) {
            return false
        } else {
            return true
        }
    }

    const validateContactInfoDeliverEmail = () => {
        const result = isValidateEmail(contactInfo?.deliverEmail);
        return result
    }

    const validateContactInfo = () => {
        const contactMissing = validateContactInfoMissing();
        const pickupPhoneError = validateContactInfoPickupPhone();
        const pickupEmailError = validateContactInfoPickupEmail();
        const deliverPhoneError = validateContactInfoDeliverPhone();
        const deliverEmailError = validateContactInfoDeliverEmail();
        setContactValidate(prevState => ({
            ...prevState,
            pickupPhoneError,
            pickupEmailError: !pickupEmailError,
            deliverPhoneError,
            deliverEmailError: !deliverEmailError
        }))
        console.log('missing', contactMissing);
        // console.log('phone', phoneError);
        // console.log('email', emailError);
        if (!contactMissing && !pickupPhoneError && pickupEmailError && !deliverPhoneError && deliverEmailError) {
            return false
        } else {
            return true
        }
    }

    const validateMoveDataAndTime = () => {
        if (isValid(moveDate?.$d) && isValid(moveTime?.movingTimeFrom?.$d)) {
            return false
        } else {
            return true
        }
    }

    const validateMovingImage = () => {
        if (tab === 'item') {
            if (movingImage.length === 0) {
                dispatch(updateMovingServiceImageError(true));
                return true
            } else {
                dispatch(updateMovingServiceImageError(false));
                return false
            }
        } else {
            dispatch(updateMovingServiceImageError(false));
            return false
        }
    }

    const validation = () => {
        setErrorMessage('');
        setToastOpen(false);
        validateContactInfo() && setErrorMessage(prevState => prevState + 'Contact Information is missing some required fields or containing invalid fields.');
        validateMoveDataAndTime() && setErrorMessage(prevState => prevState + 'Moving date or time is missing or invalid.');
        validateMovingImage() && setErrorMessage(prevState => prevState + 'Item image is missing.');
        if (validateContactInfo()
            || validateMoveDataAndTime()
            || validateMovingImage()
        ) {
            handleErrorMessage();
            return false
        } else {
            return true
        }
    }

    const handleBack = () => {
        history.push(`${ownerRole === 1 ? '/' : '/business/'}transportation/${tab}`);
    }

    // todo two options moving car or item
    const handleComplete = () => {
        if (validation()) {
            history.push(`${ownerRole === 1 ? '/' : '/business/'}transportation/${tab}/review`);
            window.scrollTo(0, 0); // Scroll to the top
        }
    }

    const handleBeforeUnload = (event) => {

        console.log('reload');
        // Customize the confirmation message
        const message = 'Are you sure you want to leave? Your unsaved changes may be lost.';

        // Set the confirmation message for modern browsers
        event.returnValue = message;

        // For older browsers
        return message;
    };

    useEffect(() => {
        // Add the event listener when the component mounts
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []); // Empty dependency array means this effect runs once when the component mounts

    console.log('[MovingServiceSummary] basicRateInfo', basicRateInfo);

    if (tab === 'item' && !basicRateInfo) { //todo: revise after able to get express rate
        return (
            <Container maxWidth='xl'>
                <Box sx={{
                    backgroundColor: '#FFFFFF',
                    padding: '20px',
                    marginBottom: '40px',
                    borderRadius: '10px',
                    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                    gap: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography style={{
                                fontSize: '20px',
                                fontWeight: '600',
                                textAlign: 'left'
                            }}>
                                Transportation Rate
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{color: 'red', textAlign: 'left', fontSize: '16px'}}>
                                You've lost all the data. Please return to the home page and create a new
                                transportation.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{display: 'flex', width: '100%', justifyContent: 'flex-end', gap: '20px'}}>
                                <Button
                                    variant='contained'
                                    onClick={handleBack}
                                >
                                    <Typography style={{textTransform: 'none'}}>
                                        Go Back Home
                                    </Typography>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        )
    }

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box sx={styles.MovingServiceSummaryRoot}>
                <MovingServiceProgressBar progress={50} />
                <Grid container spacing={4}>

                    <Grid item md={12} xs={12}>
                        <Box sx={styles.MovingServiceSummaryRoot}>
                            {
                                tab === 'item' ? <MovingServiceRate tab={tab}/> : <MovingServiceExpressRate/>
                            }
                        </Box>
                    </Grid>
                    {/*{*/}
                    {/*    tab === 'item' ?*/}
                    {/*        <>*/}
                    {/*            <Grid item md={6} xs={12}>*/}
                    {/*                <Box*/}
                    {/*                    sx={tab === 'item' ? styles.MovingServiceSummaryRootTwoCols : styles.MovingServiceSummaryRootTwoColsCar}>*/}
                    {/*                    <MovingServiceAdditionalService/>*/}
                    {/*                </Box>*/}
                    {/*            </Grid>*/}
                    {/*            <Grid item md={6} xs={12}>*/}
                    {/*                <Box*/}
                    {/*                    sx={tab === 'item' ? styles.MovingServiceSummaryRootTwoCols : styles.MovingServiceSummaryRootTwoColsCar}>*/}
                    {/*                    <MovingServiceAdditionalFee/>*/}
                    {/*                </Box>*/}
                    {/*            </Grid>*/}
                    {/*        </> :*/}
                    {/*        <Grid item xs={12}>*/}
                    {/*            <Box sx={styles.MovingServiceSummaryRootTwoCols}>*/}
                    {/*                <MovingServiceExpressIncludedServices/>*/}
                    {/*            </Box>*/}
                    {/*        </Grid>*/}
                    {/*}*/}
                    <Grid item md={12} xs={12}>
                        <Box sx={{
                            backgroundColor: '#FFFFFF',
                            padding: '20px',
                            marginBottom: '20px',
                            borderRadius: '10px',
                            boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                            gap: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                            <Typography sx={{
                                fontSize: '20px',
                                fontWeight: '600'
                            }}>
                                {intl.get('MOVING.ADDRESS_INFORMATION')}
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item md={6} xs={12}>
                                    <Box sx={{
                                        backgroundColor: '#FFFFFF',
                                        padding: '20px',
                                        marginBottom: '20px',
                                        borderRadius: '10px',
                                        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                                        gap: '10px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}>
                                        <Typography sx={{fontWeight: '600'}}>
                                            {intl.get('MOVING.MOVING_FROM')}
                                        </Typography>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12} md={movingFromAddress?.option === 'self' ? 12 : 6}>
                                                <Typography>
                                                    {movingFromAddress?.option === 'self' ? intl.get('MOVING.SELF_DROP_OFF') : intl.get('MOVING.SCHEDULED_PICKUP')}
                                                </Typography>
                                                <Typography>
                                                    {movingFromAddress?.warehouse?.name}
                                                </Typography>
                                                <Typography>
                                                    {movingFromAddress?.warehouse?.address}
                                                </Typography>
                                                <Typography>
                                                    {movingFromAddress?.apt ? `${movingFromAddress?.apt} - ${movingFromAddress?.streetAddress}` : movingFromAddress?.streetAddress}
                                                </Typography>
                                                <Typography>
                                                    {movingFromAddress?.city}, {movingFromAddress?.province?.code}
                                                </Typography>
                                                <Typography>
                                                    {movingFromAddress?.country}, {movingFromAddress?.postalCode}
                                                </Typography>
                                            </Grid>
                                            {movingFromAddress?.option !== 'self' &&
                                                <Grid item xs={12} md={6}>
                                                    <Typography>
                                                        Property Type: {movingFromAddress?.propertyType?.propertyType}
                                                    </Typography>
                                                    <Typography>
                                                        House Type: {movingFromAddress?.propertyType?.houseType}
                                                    </Typography>
                                                    <Typography>
                                                        Lift: {movingFromAddress?.lift}
                                                    </Typography>
                                                    <Typography>
                                                        Storey: {movingFromAddress?.storey}
                                                    </Typography>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Box sx={{
                                        backgroundColor: '#FFFFFF',
                                        padding: '20px',
                                        marginBottom: '20px',
                                        borderRadius: '10px',
                                        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                                        gap: '10px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}>
                                        <Typography sx={{fontWeight: '600'}}>
                                            {intl.get('MOVING.MOVING_TO')}
                                        </Typography>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12} md={movingToAddress?.option === 'self' ? 12 : 6}>
                                                <Typography>
                                                    {movingToAddress?.option === 'self' ? intl.get('MOVING.SELF_PICKUP') : intl.get('MOVING.SCHEDULED_DELIVER')}
                                                </Typography>
                                                <Typography>
                                                    {movingToAddress?.warehouse?.name}
                                                </Typography>
                                                <Typography>
                                                    {movingToAddress?.warehouse?.address}
                                                </Typography>
                                                <Typography>
                                                    {movingToAddress?.apt ? `${movingToAddress?.apt} - ${movingToAddress?.streetAddress}` : movingToAddress?.streetAddress}
                                                </Typography>
                                                <Typography>
                                                    {movingToAddress?.city}, {movingToAddress?.province?.code}
                                                </Typography>
                                                <Typography>
                                                    {movingToAddress?.country}, {movingToAddress?.postalCode}
                                                </Typography>
                                            </Grid>
                                            {movingToAddress?.option !== 'self' &&
                                                <Grid item xs={12} md={6}>
                                                    <Typography>
                                                        Property Type: {movingToAddress?.propertyType?.propertyType}
                                                    </Typography>
                                                    <Typography>
                                                        House Type: {movingToAddress?.propertyType?.houseType}
                                                    </Typography>
                                                    <Typography>
                                                        Lift: {movingToAddress?.lift}
                                                    </Typography>
                                                    <Typography>
                                                        Storey: {movingToAddress?.storey}
                                                    </Typography>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Box sx={styles.MovingServiceSummaryRoot}>
                            <MovingServiceContact contactValidate={contactValidate}/>
                        </Box>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Box sx={styles.MovingServiceSummaryRoot}>
                            <MovingServiceDate/>
                        </Box>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Box sx={styles.MovingServiceSummaryRoot}>
                            <MovingServiceAdditionalService />
                        </Box>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Box sx={styles.MovingServiceSummaryRoot}>
                            <MovingServiceImage/>
                        </Box>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Box sx={styles.MovingServiceSummaryButtonSection}>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: '#F2BE22',
                                    "&:hover": {
                                        backgroundColor: '#F2BE22',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                onClick={handleBack}
                            >
                                <Typography style={{textTransform: 'none'}}>
                                    {intl.get('BUTTON.BACK')}
                                </Typography>
                            </Button>
                            <LoadingButton
                                loading={loading}
                                variant="contained"
                                sx={{
                                    backgroundColor: '#1D8B45',
                                    "&:hover": {
                                        backgroundColor: '#1D8B45',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                onClick={handleComplete}
                            >
                                <Typography style={{textTransform: 'none'}}>
                                    {intl.get('BUTTON.NEXT')}
                                </Typography>
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}