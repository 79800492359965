import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
// import { getShipmentItems, getPickUpDetails } from '../../actions/shipmentAction';
import {useHistory} from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import LoadingButton from '@mui/lab/LoadingButton';
// import Slide from 'react-reveal';
import {
    TextField,
    Button,
    Box,
    CircularProgress,
    Dialog,
    IconButton,
    Switch,
    RadioGroup,
    FormControlLabel,
    Radio,
    Collapse,
    Snackbar,
    Checkbox,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Alert} from "@material-ui/lab";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ClearIcon from "@material-ui/icons/Clear";
import HelpIcon from "@material-ui/icons/Help";
import EventIcon from "@material-ui/icons/Event";

import PropTypes from "prop-types";
import {format} from "date-fns";
import axios from "axios";
import produce from "immer";
import AccessAlarmsIcon from "@material-ui/icons/AccessAlarms";
// import NewCredit from '../CreditCard/newCredit'
import {getHeaders} from "../../Utils/fetchUtil";
import {
    BACKEND_ORDERS_API,
    // BACKEND_PACKAGES_API,
    BACKEND_PRICE_ESTIMATE_API,
    LIST_CREDIT_CARD_API,
    BACKEND_ADDON_INFO_API,
    BACKEND_ADDON_INFO_TEST_API,
    CRM_ORDER_WITHOUT_PRICE,
    SAVE_CREDITCARD_INFO_API, NODE_ROUTE_URI,
    // BACKEND_ADD_TO_ADDRESSBOOK,
} from "../../Utils/apiUrl";
import {CustomInput} from "../shared";
import LoadingContainer from "../shared/loadingContainer";
import {redirectHome} from "../../Utils/redirect";
import {errHandling} from "../../Utils/fetchUtil";
import {CardImages} from "../Images/cards/";
import {clearInitOrder} from "../../slices";
import AddCreditCardPage from "../Profiles/addCreditCardPage";
import PriceModule from "../shared/OrderDetail/priceModule";
import {getAccessToken} from "../../Utils/doToken";
// import { getAddressBook } from "../../slices";

const PayWithCreditCard = ({orderId, orderNumber}) => {
    //   const dispatch = useDispatch();
    // const pallets = useSelector((state) => state.shipping.shippingItems);
    // const pickUpDetails = useSelector((state) => state.shipping.pickUpDetails);
    const cardStatus = useSelector((state) => state.user.activeCard);

    const [routeError, setRouteError] = useState(true);

    const [curSetupIntentId, setCurSetupIntentId] = useState("");

    const [fetchingPrice, setFetchingPriceState] = useState(true);
    const [creatingOrder, setCreatingStatus] = useState(false);
    const [shippingPrice, setShippingPrice] = useState({});

    const [priceErrMsg, setPriceErrMsg] = useState("");
    const [cardErrMsg, setCardErrMsg] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const [cardLoading, setCardLoading] = useState(true);
    const [cardInfo, setCardInfo] = useState([]);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [isVoidCreditCardWindowOpen, setVoidCreditCardWindowOpen] = useState(false);
    const [reload, setReload] = useState(false);

    const [selectedCard, setSelectedCard] = useState({});

    const [removeStatus, setRemovingStatus] = useState(false);

    // const [personalEmtpy, setPersonalEmpty] = useState(false);

    const history = useHistory();
    const [toastOpen, setToastOpen] = useState(false);

    const handleToastClick = () => {
        setToastOpen(true);
    };

    const handleToastClose = (e, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setToastOpen(false);
    };

    useEffect(() => {
        console.log(cardInfo);
    }, [cardInfo])

    // Fetches data from backend/api/me to display userprofile data
    useEffect(() => {
        console.log("card status: ", cardStatus);
        axios
            .get(LIST_CREDIT_CARD_API, {headers: getHeaders()})
            .then((response) => {
                setCardLoading(false);
                const inUseCard = [];
                for (const card of response.data.cardInfo) {
                    if (card.card_last_four_digits) {
                        if (card.is_default) {
                            setSelectedCard(card);
                            inUseCard.unshift(card);
                            continue;
                        }
                        inUseCard.push(card);
                    }
                }
                console.log(inUseCard);
                setCardInfo(inUseCard);
                setReload(false);
            })
            .catch((err) => {
                // console.log(err);
                errHandling(err, setCardErrMsg);
                setCardLoading(false);
                setReload(false);
            }).finally(() => {
            setCardLoading(false);
            setReload(false);
        });
    }, [cardStatus]);

    const fetchCards = () => {
        axios
            .get(LIST_CREDIT_CARD_API, {headers: getHeaders()})
            .then((response) => {
                console.log("fetch cards: ", response);
                setCardLoading(false);
                const inUseCard = [];
                for (const card of response.data.cardInfo) {
                    if (card.card_last_four_digits) {
                        setSelectedCard(card);
                        inUseCard.push(card);
                    }
                }
                setCardInfo(inUseCard);
            })
            .catch((err) => {
                errHandling(err, setCardErrMsg);
                setCardLoading(false);
            }).finally(() => {
            setCardLoading(false);
        });
    }

    useEffect(() => {
        fetchCards();
    }, [])

    useEffect(() => {
        if (reload) {
            fetchCards();
        }
    }, [cardStatus, reload]);

    const classes = useStyles();
    const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    const firstLetterUpper = (_string) => {
        return _string.replace(/^\S/, (s) => s.toUpperCase());
    };

    const onRedicrect = () => {
        setDialogOpen(true);
    };

    const handleSubmitOrder = async () => {
        setCreatingStatus(true);

        try {
            // const orderResponse = await axios({
            //   method: "post",
            //   url: BACKEND_ORDERS_API,
            //   data: JSON.stringify({}),
            //   headers: {
            //     ...getHeaders(),
            //     "Content-Type": "application/json",
            //     "X-Requested-With": "XMLHttpRequest",
            //   },
            // });
            const accessToken = getAccessToken("access_token");
            const {data} = await axios.post(`${NODE_ROUTE_URI}/looseItem/place3PLOrderWithCreditCardViaOrderIdAndOrderNumber`, {
                "uucOrderId": orderId,
                "uucOrderNumber": orderNumber
            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            setErrorMessage("");
            console.log(data);
            handleToastClick();
        } catch (error) {
            console.log(error);
            setErrorMessage(error?.message || error?.response?.data?.error || error?.response?.data?.message || "Error!");
            handleToastClick();
            setCreatingStatus(false);
        } finally {
            setCreatingStatus(false);
        }
    };
    const handleRemoveCard = async () => {
        setRemovingStatus(true);

        try {
            const removeResponse = await axios({
                method: "delete",
                url: SAVE_CREDITCARD_INFO_API,
                data: {
                    setupIntentId: curSetupIntentId,
                },
                headers: {
                    ...getHeaders(),
                },
            });
            setVoidCreditCardWindowOpen(false);
            fetchCards();
        } catch (error) {
            console.log(error);
            setRemovingStatus(false);
        }
    };

    const cardContainer = (_loading, _cardInfoList, _cardStatus, _errMsg) => {
        if (!_loading && _errMsg)
            return (
                <Box pt="1vh" width="100%">
                    <Alert severity="error" classes={{root: classes.alertRoot}}>
                        {_errMsg}
                    </Alert>
                </Box>
            );

        return (
            <>
                {
                    (() => {
                        if (!_loading) {
                            return _cardInfoList.map((cardInfo) => {
                                return (
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        mt={2}
                                    >
                                        <Checkbox
                                            color="primary"
                                            checked={
                                                selectedCard.stripe_card_id === cardInfo.stripe_card_id
                                            }
                                            onChange={() => {
                                                setSelectedCard(cardInfo);
                                            }}
                                            inputProps={{"aria-label": "primary checkbox"}}
                                        />
                                        <Box className={classes.bodyText}>
                                            <>
                                                <img
                                                    src={CardImages[firstLetterUpper(cardInfo.card_type)]}
                                                    alt={firstLetterUpper(cardInfo.card_type)}
                                                    width="50px"
                                                    align="bottom"
                                                    style={{padding: "0 5px"}}
                                                />
                                                {`${firstLetterUpper(cardInfo.card_type)} ending in ${
                                                    cardInfo.card_last_four_digits
                                                }`}
                                            </>
                                        </Box>
                                        <Button
                                            // className={classes.button}
                                            onClick={() => {
                                                setCurSetupIntentId(cardInfo.stripe_setupintent_id);
                                                setVoidCreditCardWindowOpen(true);
                                            }}
                                            style={{
                                                textTransform: "unset",
                                                fontSize: "1rem",
                                                // visibility: "hidden"
                                            }}
                                        >
                                            <DeleteIcon/>
                                        </Button>
                                    </Box>
                                );
                            })
                        }
                        return <LoadingContainer/>;
                    })()
                }
                <br/>
                <Box mt={2} display="flex" alignItems="center" justifyContent="center">
                  <Button
                      className={classes.button}
                      variant="contained"
                      color="primary"
                      onClick={onRedicrect}
                  >
                    Add a Card
                  </Button>
                </Box>
            </>
        );
    };

    return (
        <Box
            className={matches ? classes.smRootContainer : classes.rootContainer}
        >
            {/*Pop-up Toast*/}
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={3000}
                message="Cancel Order">
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert onClose={handleToastClose} severity="error" sx={{width: '100%'}}>
                                Place Order Failed!
                                <hr/>
                                Error: {errorMessage}
                            </Alert>
                        )
                    }
                    return (
                        <Alert onClose={handleToastClose} severity="success" sx={{width: '100%'}}>
                            Payment Success!
                        </Alert>
                    )
                })()}
            </Snackbar>
            {/*Pop-up Toast*/}
            <>
                <div className={classes.divider}/>
                {cardContainer(cardLoading, cardInfo, cardStatus, cardErrMsg)}
            </>
            <div className={classes.divider}/>
            {/* <Box pt="1vh" width="100%">
          {cardStatus !== "active" ? (
            <Alert severity="error">
              Please add one credit card before you place the order.
            </Alert>
          ) : (
            ""
          )}
        </Box> */}
            {(!cardLoading && cardInfo?.length > 0) && <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Box pt={1} display="flex" alignItems="center" justifyContent="center">
                    {/*<Button*/}
                    {/*    color="primary"*/}
                    {/*    variant="contained"*/}
                    {/*    className={matches ? classes.smButton : classes.button}*/}
                    {/*    onClick={handleSubmitOrder}*/}
                    {/*    style={{*/}
                    {/*      textTransform: "unset",*/}
                    {/*      fontSize: "1.3rem",*/}
                    {/*      height: "3rem",*/}
                    {/*      width: "20rem"*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*  {creatingOrder ? <CircularProgress size={24} /> : "Confirm Payment"}*/}
                    {/*</Button>*/}
                    <LoadingButton
                        variant="contained"
                        component="span"
                        color="primary"
                        style={{
                            textTransform: "unset",
                            fontSize: "1.3rem",
                            width: "20rem",
                        }}
                        loading={creatingOrder}
                        type="submit"
                        onClick={handleSubmitOrder}
                    >
                        Confirm Payment
                    </LoadingButton>
                </Box>
            </Box>}
            <Dialog
                maxWidth="sm"
                fullWidth
                open={dialogOpen}
                onClose={() => {
                    setReload(true);
                    setDialogOpen(false);
                }}
            >
                <div className={classes.dialogTitle}>
                    <Box fontSize="1.5rem"></Box>
                    <IconButton
                        // styles={{icon: {color: 'rgb(141, 173, 155)', fontSize: 36}}}
                        style={{fill: "green"}}
                        className={classes.closeBtn}
                        onClick={() => {
                            setReload(true);
                            setDialogOpen(false);
                        }}
                    >
                        <ClearIcon/>
                    </IconButton>
                </div>
                <AddCreditCardPage addCard={true} popUp={true}/>
            </Dialog>
            <Dialog
                maxWidth="sm"
                fullWidth
                open={isVoidCreditCardWindowOpen}
                onClose={() => setVoidCreditCardWindowOpen(false)}
            >
                <div className={classes.dialogTitle}>
                    <Box fontSize="1.5rem"></Box>
                    <IconButton
                        style={{fill: "green"}}
                        className={classes.closeBtn}
                        onClick={() => setVoidCreditCardWindowOpen(false)}
                    >
                        <ClearIcon/>
                    </IconButton>
                </div>
                <Box
                    color="seagreen"
                    fontSize="1.5rem"
                    minHeight="20vh"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    margin={2}
                >
                    Remove this card ?
                </Box>
                <Box display="flex" justifyContent="center" mb={4}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            setVoidCreditCardWindowOpen(false);
                        }}
                        className={`${classes.outLinedButton} ${classes.fixWidthButton}`}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            handleRemoveCard();
                        }}
                        className={`${classes.button} ${classes.fixWidthButton}`}
                    >
                        Yes
                    </Button>
                </Box>
            </Dialog>
        </Box>
    );
};

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    rootContainer: {
        // display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'center',
        // flexDirection: 'column',
        margin: "0 auto",
        paddingLeft: "3vw",
        paddingRight: "3vw",
    },
    smRootContainer: {
        width: "100%",
        paddingLeft: "3vw",
        paddingRight: "3vw",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    button: {
        borderRadius: 5,
        fontSize: "1.2rem",
        backgroundColor: "#ffa726",
        width: "14rem",
    },
    smButton: {
        borderRadius: 5,
        fontSize: "1rem",
        backgroundColor: "#ffa726",
        width: "12rem",
    },
    textfield: {
        width: "100%",
        backgroundColor: "white",
        // border: "1px solid white",
    },
    dockerFee: {
        width: "4rem",
    },
    feeWrapper: {
        // border: "1px solid rgba(0,0,0,0.3)",
        borderRadius: 5,
        marginBottom: theme.spacing(2),
        // backgroundColor: "#FEEAAE",
        backgroundColor: "white",
    },

    infoWrapper: {
        // border: "1px solid rgba(0,0,0,0.3)",
        borderRadius: 5,
        marginBottom: theme.spacing(2),
        backgroundColor: "#FEEAAE",
    },
    summaryHeader: {
        fontSize: "16pt",
        color: "rgb(141, 173, 155)",
        fontFamily: "Montserrat",
        // borderBottom: '1px, solid rgb(141, 173, 155)',
    },
    summaryBodyText: {
        fontSize: "13pt",
        color: "seagreen",
        fontFamily: "Montserrat",
    },
    textButton: {
        color: "blue",
    },
    bodyText: {
        fontSize: "1rem",
        color: "seagreen",
        fontFamily: "Montserrat",
    },
    dialogTitle: {
        padding: "1vh 1vw 0.5vh",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        color: "rgb(141, 173, 155)",
    },
    timePicker: {
        width: 160,
    },
    divider: {
        borderBottom: "2px solid rgba(0,0,0,0.3)",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    alertRoot: {
        width: "80%",
        minWidth: "200px",
        margin: "10px auto",
    },
    radioWrapper: {
        border: "1px solid red",
        paddingLeft: theme.spacing(1),
        borderRadius: "5px",
    },
    fixWidthButton: {
        width: "8rem",
    },
    outLinedButton: {
        marginRight: "24px",
    },
}));

// const mapStateToProps = state => ({
//     shippingItems: state.shippingItems
// })

// export default connect(mapStateToProps, {getShipmentItems, getPickUpDetails})(ReviewAndConfirm);
export default PayWithCreditCard;
