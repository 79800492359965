import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import {Divider, Stack, Switch, useMediaQuery} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import AddBoxIcon from "@mui/icons-material/AddBox";

import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {
    addShippingPackageCreateLabelPackageList, initShippingPackageCreateLabelPackageList,
    updateShippingPackageCreateLabelUnit,
} from "../../../actions/BusinessShippingPackageAction";
import {generateRandomString} from "../../../Utils/Helper";
import {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import {BusinessShippingPackageCreateLabelPackageEach} from "./BusinessShippingPackageCreateLabelPackageEach";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import BoxImage from "../../../images/boxes.png";
import addPackage from "../../../images/Create Label.png";
import intl from "react-intl-universal";
import {ReactComponent as PackageDetailsIcon} from "../../../images/NewDesignIcon/Package.svg";
import * as React from "react";

const styles = {
    ShippingPackageCreateLabelPackageDetailsRoot: {
        backgroundColor: '#FFFFFF',
        // padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        // border: '1px solid #A9A9A9',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelPackageDetailsHeading: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelPackageDetailsSection: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '20px 20px 0 20px',
        alignItems: 'center'
    },
    ShippingPackageCreateLabelPackageDetailsSubSection: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    ShippingPackageCreateLabelPackageDetailsSectionColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelPackageDetailsModalBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 500,
        maxHeight: 400,
        backgroundColor: '#FFFFFF',
        border: '2px solid #000',
        borderRadius: '10px',
        boxShadow: 24,
        padding: '15px 15px',
        overflow: 'auto'
    },
    ShippingPackageCreateLabelPackageDetailsSelectBox: {
        "&:hover": {
            cursor: "pointer",
            background: "aliceblue"
        },
    },
    ShippingPackageCreateLabelPackageDetailsSelectCommonBox: {
        display: 'flex',
        justifyContent: 'flex-start',
        "&:hover": {
            cursor: "pointer",
            background: "aliceblue"
        },
    },
    ShippingPackageCreateLabelRateListContent: {
        backgroundColor: '#FFFFFF',
        padding: '0 40px',
        marginBottom: '40px',
        borderRadius: '10px',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    }
}

const AntSwitch = styled(Switch)(({theme}) => ({
    width: 32,
    height: 20,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 20,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#609966' : '#609966',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 16,
        height: 16,
        borderRadius: 8,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 20 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

export const BusinessShippingPackageCreateLabelPackageDetails = ({isGetRate, packageListRedux, packageListHistory}) => {

    const dispatch = useDispatch();

    let metric = useSelector(state => state?.businessShippingPackage?.createLabelUnit);

    const isMobile = useMediaQuery('(max-width: 600px)');

    const history = useHistory();

    const [packageList, setPackageList] = useState([]);
    const id = useSelector((state) => state.user.id);
    const [myBoxesList, setMyBoxesList] = useState([]);
    const [commonBoxesList, setCommonBoxesList] = useState([]);
    const [updatedPackage, setUpdatedPackage] = useState();
    const [itemId, setItemId] = useState(1);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleMetric = (event) => {
        dispatch(updateShippingPackageCreateLabelUnit(event.target.checked));
    }

    const handleAddPackage = () => {

        const packageId = generateRandomString(8);
        const newPackage = {
            description: `item-${itemId}`,
            length: '10',
            width: '10',
            height: '10',
            weight: '10',
            lengthUnit: metric ? 'cm' : 'in',
            weightUnit: metric ? 'kg' : 'lb',
            UOM: '',
            hscode: '',
            originCountryCode: '',
            currency: '',
            priceEach: '',
            quantity: '1',
            packageInfoType: 'customBox',
            packageSpecialServices: {
                declaredValue: {
                    value: 0,
                    currency: "CAD"
                }
            },
            packageId: packageId
        }
        dispatch(addShippingPackageCreateLabelPackageList(newPackage));
        setItemId(prevState => prevState + 1);
    }

    const getMyBoxesListForPartner = async () => {

        let requestURL = `${PARTNER_URI}/myboxes/getMyBoxesListForPartner`

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {partnerId: id},
            })
            const {data} = result;
            setMyBoxesList(data);
            console.log(data);
        } catch (e) {
            console.log(e)
        }
    }

    const getCommonBoxesList = async () => {
        let requestURL = `${PARTNER_URI}/myBoxes/commonBoxes`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL
            })
            console.log('result', result);
            setCommonBoxesList(result?.data);
        } catch (e) {
            console.log(e.response);
        }
    }

    const handleUpdatePackage = (packageInfo) => {
        console.log('update package', packageInfo);
        setUpdatedPackage(packageInfo);
    }

    const handleSelectBox = (box) => {
        console.log('select box', box);
        setUpdatedPackage(prevState => ({
            ...prevState,
            description: box?.item_name,
            UOM: box?.uom,
            currency: box?.currency_code,
            height: box?.height,
            length: box?.length,
            width: box?.width,
            lengthUnit: box?.dimension_unit,
            originCountryCode: box?.origi_country,
            weight: box?.weight,
            weightUnit: box?.weight_unit,
            hscode: box?.hs_code,
            priceEach: box?.unit_price,
            packageInfoType: 'myBox'
        }));
        handleClose();
    }

    const handleAddBox = () => {
        history.push('/business/my-boxes')
    }

    useEffect(() => {
        id && getMyBoxesListForPartner();
        getCommonBoxesList();
    }, [])

    useEffect(() => {
        setPackageList(packageListRedux);
    }, [packageListRedux]);

    useEffect(() => {
        if (packageListHistory?.length > 0) {
            dispatch(initShippingPackageCreateLabelPackageList(packageListHistory));
        }
    }, [packageListHistory])

    // console.log('package list local', packageList);
    // console.log('package list redux', packageListRedux);
    // console.log('package list history', packageListHistory)

    return (
        <Box sx={styles.ShippingPackageCreateLabelPackageDetailsRoot}>
            <Box sx={styles.ShippingPackageCreateLabelPackageDetailsSection}>
                <Box sx={{
                    display: 'flex',
                    gap: '10px',
                }}>
                    <PackageDetailsIcon width={25} height={25}/>
                    <Typography style={styles.ShippingPackageCreateLabelPackageDetailsHeading}>
                        {intl.get('ADD_PACKAGE.PACKAGE')}
                    </Typography>
                </Box>
                <Box sx={styles.ShippingPackageCreateLabelPackageDetailsSubSection}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <AntSwitch
                            checked={metric}
                            onChange={handleMetric}
                            inputProps={{'aria-label': 'ant design'}}
                            disabled={isGetRate}
                        />
                        <Typography>
                            {intl.get('ADD_PACKAGE.METRIC')}
                        </Typography>
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <IconButton
                            sx={{color: '#609966'}}
                            onClick={handleAddPackage}
                            disabled={isGetRate}
                        >
                            <img src={addPackage} width='35px'/>
                        </IconButton>
                        <Typography>
                            {intl.get('ADD_PACKAGE.ADD_NEW_BTN')}
                        </Typography>
                    </Stack>
                </Box>
            </Box>
            <Divider/>
            <Box sx={styles.ShippingPackageCreateLabelRateListContent}>
                <Grid container spacing={2}>
                    {
                        packageList?.length > 0 ? packageList?.map((eachPackage, index) =>
                            <Grid item xs={12} key={index}>
                                <BusinessShippingPackageCreateLabelPackageEach
                                    packageInfo={eachPackage}
                                    cbHandleOpen={handleOpen}
                                    cbHandleUpdatePackage={handleUpdatePackage}
                                    updatedPackage={updatedPackage}
                                    isGetRate={isGetRate}
                                    index={index}
                                />
                            </Grid>
                        ) : <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: '1px solid #B7B7B7',
                                padding: '20px',
                                borderRadius: '10px'
                            }}>
                                <Typography
                                    style={{
                                        fontSize: '20px',
                                        fontWeight: '600',
                                        color: '#7F8487',
                                        marginBottom: '10px'
                                    }}>
                                    {intl.get('ADD_PACKAGE.EMPTY_PACKAGE_LIST')}
                                </Typography>
                                <IconButton
                                    sx={{color: '#609966'}}
                                    onClick={handleAddPackage}
                                    // disabled={isGetRate}
                                >
                                    <img src={addPackage} width='35px'/>
                                </IconButton>
                                <Typography style={{fontSize: '12px', fontWeight: '600', color: '#609966'}}>
                                    {intl.get('ADD_PACKAGE.ADD_NEW_TEXT')}
                                </Typography>
                            </Box>
                        </Grid>
                    }
                    <Modal
                        open={open}
                        onClose={handleClose}
                    >
                        <Box sx={styles.ShippingPackageCreateLabelPackageDetailsModalBox}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography style={{fontSize: '20px'}}>
                                        {intl.get('FROM_MY_BOX.BOX')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        <Typography style={{fontSize: '12px', color: '#8294C4'}}>
                                            Choose from package templates
                                        </Typography>
                                        <Divider variant="middle" sx={{width: '40%'}}/>
                                        <Button
                                            color='success'
                                            onClick={handleAddBox}
                                        >
                                            {intl.get('FROM_MY_BOX.ADD')}
                                        </Button>
                                        {/*{updatedPackage?.packageId}*/}
                                    </Box>
                                </Grid>
                                {
                                    myBoxesList?.length > 0 ? myBoxesList.map((box, index) =>
                                            (
                                                <Grid item xs={12} key={index} onClick={() => handleSelectBox(box)}>
                                                    <Box sx={styles.ShippingPackageCreateLabelPackageDetailsSelectBox}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={1}>
                                                                <Box sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    height: '100%'
                                                                }}>
                                                                    <img src={BoxImage} alt="box" width='30px'/>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={11}>
                                                                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                                                    <Typography>
                                                                        {box?.box_name}
                                                                    </Typography>
                                                                    <Typography>
                                                                        {box?.length} x {box?.width} x {box?.height} {box?.dimension_unit}, {box?.weight} {box?.weight_unit}
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Grid>

                                            ))
                                        : <Grid item xs={12}>
                                            <Typography>
                                                {intl.get('FROM_MY_BOX.NO_BOXES')}
                                            </Typography>
                                        </Grid>
                                }
                                <Grid item xs={12}>
                                    <Typography style={{fontSize: '12px', color: '#1D8B45'}}>
                                        {intl.get('FROM_MY_BOX.PARCELS')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>
                                {
                                    commonBoxesList?.length > 0 ? commonBoxesList.map((box, index) => (
                                        <Grid item xs={12} key={index} onClick={() => handleSelectBox(box)}>
                                            <Box sx={styles.ShippingPackageCreateLabelPackageDetailsSelectCommonBox}>
                                                <Typography>
                                                    {box?.box_name} ({box?.length} x {box?.width} x {box?.height} {box?.dimension_unit}, {box?.weight} {box?.weight_unit})
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    )) : <Grid item xs={12}>
                                        <Typography>
                                            {intl.get('FROM_MY_BOX.NO_BOXES')}
                                        </Typography>
                                    </Grid>
                                }
                            </Grid>
                        </Box>
                    </Modal>
                </Grid>
            </Box>
        </Box>
    )
}