import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import {Fragment} from "react";
import dayjs from "dayjs";
import {Divider} from "@mui/material";

export const MovingOrderDashboardTransactionHistory = ({
                                                           orderDetails,
                                                           underReviewPayment,
                                                           paidPayment,
                                                           declinedPayment
                                                       }) => {
    return (
        <Box sx={{
            border: '1px solid #B2B2B2',
            borderRadius: '10px',
            padding: '10px 0'
        }}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '20px',
                        borderBottom: '1px solid #B2B2B2',
                        alignItems: 'center'
                    }}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            Transaction Details
                        </Typography>
                    </Box>
                </Grid>
                {
                    orderDetails?.backendPatments?.length > 0 ?
                        orderDetails?.backendPayments?.map((payment, index) => (
                            <Grid item xs={12} key={index}>
                                <Box sx={{background: '#F3F3F3'}}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={7}>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                height: '100%',
                                                justifyContent: 'flex-start',
                                                padding: '0 20px'
                                            }}>
                                                <Typography>
                                                    {dayjs(payment?.created_at).format('YYYY-MM-DD')}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Typography style={{
                                                textAlign: 'left',
                                                color: payment?.payment_status === 'CAPTURED_SUCCEEDED' ? '#1D8B45' :
                                                    payment?.payment_status === 'DECLINED' ? '#FF0303' :
                                                        payment?.payment_status === 'UNDER_REVIEW' && '#F2BE22'
                                            }}>
                                                {payment?.payment_status === 'CAPTURED_SUCCEEDED' ? 'Succeed' :
                                                    payment?.payment_status === 'DECLINED' ? 'Declined' :
                                                        payment?.payment_status === 'UNDER_REVIEW' && 'Pending'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                height: '100%',
                                                justifyContent: 'flex-start',
                                                padding: '0 20px'
                                            }}>
                                                <Typography>
                                                    {payment?.remark_notes === "Down_Payment" ? "Prepaid Amount" : "Payment"}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Typography style={{textAlign: 'left'}}>
                                                $ {payment?.amount.toFixed(2)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        ))
                        :
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                justifyContent: 'flex-start',
                                padding: '0 20px'
                            }}>
                                <Typography>
                                    No Transactions
                                </Typography>
                            </Box>
                        </Grid>
                }
            </Grid>
        </Box>
    )
}