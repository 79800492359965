import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {OrderDashBoardSummary} from "./OrderDashBoardSummary";
import {OrderDashBoardTransactions} from "./OrderDashBoardTransactions";
import {OrderDashBoardCrossBorder} from "./OrderDashBoardCrossBorder";
import {OrderDashBoardAdjustments} from "./OrderDashBoardAdjustments";
import {useParams} from "react-router-dom";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {useEffect, useState} from "react";
import {getAccessToken} from "../../Utils/doToken";
import Grid from "@mui/material/Grid";
import {useSelector} from "react-redux";

const style = {
    OrderDashBoardRoot: {
        width: '95vw',
        display: 'flex',
        flexDirection: 'column',
        margin: '10px auto',
        alignItems: 'center',
        gap: '20px'
    },
    OrderDashBoardSection: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        flexDirection: 'row'
    }
}

export const OrderDashBoard = () => {

    const {orderId} = useParams();

    return (
        <Box sx={style.OrderDashBoardRoot}>
            <Typography variant='h4'>
                Order Dash Board
            </Typography>
            <OrderDashBoardSummary orderId={orderId}/>
            <Box>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <OrderDashBoardTransactions orderId={orderId}/>
                    </Grid>
                    <Grid item xs={6}>
                        <OrderDashBoardCrossBorder orderId={orderId}/>
                    </Grid>
                    <Grid item xs={6}>
                        <OrderDashBoardAdjustments orderId={orderId}/>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}