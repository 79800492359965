import {Container} from "@mui/material";
import Typography from "@mui/material/Typography";

export const ErrorPage = () => {
    return (
        <Container maxWidth='xl'>
            <Typography sx={{fontSize: '24px', fontWeight: '600'}}>
                404 - Not Found
            </Typography>
            <Typography>
                The page you are looking for does not exist.
            </Typography>
        </Container>
    )
}