import React, {useState, useEffect} from "react";
import {
    TextField,
    Button,
    Checkbox,
    Box,
    // Container,
    CircularProgress,
} from "@material-ui/core";
import {Alert, AlertTitle} from "@material-ui/lab";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
// import ReCAPTCHA from "react-google-recaptcha";
// import UserBar from '../../NavBars/userBar';
// import Slide from 'react-reveal';
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import axios from "axios";

import "../../../styles/styles.css";
import {redirectSignUpSuccessful} from "../../../Utils/redirect";
import {errHandling, getURLParams} from "../../../Utils/fetchUtil";
// import { CheckBoxOutlineBlank } from "@material-ui/icons";
import {
    BACKEND_ME_API,
    // BACKEND_LOGIN_API,
    // BACKEND_ME_API,
    // BACKEND_DRIVER_LOGIN_API,
    USER_SIGN_UP_API,
} from "../../../Utils/apiUrl";
import {getAccessToken} from "../../../Utils/doToken";
import {Redirect} from "react-router";

const submitStyle = {
    color: "green",
    maxheight: "-webkit-fill-available",
    fontFamily: "Montserrat",
    letterSpacing: "2px",
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
    flexDirection: "column",
    textAlign: "center",
};

const useStyles = makeStyles((theme) => ({
    input: {
        color: "grey",
        width: "90%",
    },
    button: {
        color: "white",
    },
    paper: {
        backgroundColor: "white",
        boxShadow: theme.shadows[1],
        padding: theme.spacing(2, 2, 3),
        justifyContent: "center",
        width: "100%",
    },
    paper2: {
        backgroundColor: "white",
        padding: theme.spacing(2, 4, 3),
        boxShadow: theme.shadows[1],
        width: "100%",
    },
    alertRoot: {
        width: "80%",
        minWidth: "200px",
        margin: "10px auto",
    },
    successWrapper: {
        backgroundColor: "white",
        padding: theme.spacing(1),
        borderRadius: "5px",
    },
    title: {
        fontSize: "1.5rem",
        color: "darkgreen",
        fontWeight: "600",
    },
}));

const UserSignUp = (props) => {
    // User Data that get saved as object
    // const [userData, setUserData] = useState();
    // const [confirmUser, setConfirmUser] = useState(false);
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [password, setPassword] = useState("");
    const [validLength, setValidLength] = useState(true);
    const [validEmail, setValidEmail] = useState(true);
    const [emptyEmail, setEmailEmptyStatus] = useState(false);
    const [emptyFirstName, setFirstNameEmptyStatus] = useState(false);
    const [emptyLastName, setLastNameEmptyStatus] = useState(false);
    const classes = useStyles();
    const history = useHistory();

    // const [signUpResponse, setSignUpResponse] = useState(0);
    const [signUpSuccess, setSignUpSuccess] = useState(false);
    // const [signUpFail, setSignUpFail] = useState(false);
    // const [shortPassword, setShortPassword] = useState(false);
    const [showSignUp, setShowSignUp] = useState(true);
    const [signUpErrorMessage, setSignUpErrorMessage] = useState("");
    const [isChecked, setCheckedState] = useState(false);
    //   const [isPrivacyChecked, setPrivacyCheckedState] = useState(false);



    const [loading, setLoading] = useState(false);
    // const [recaptchaValue, setRecaptchaValue] = useState(undefined);

    //   const [value, setValue] = React.useState("end_user");

    //   const handleChange = (event) => {
    //     setValue(event.target.value);
    //   };

    //useEffect handler which makes sure the userData state is first updated then sends the data to the MongoDB :D! FIXED
    // useEffect(() => {
    //   // if (confirmUser === true) {
    //   //     console.log(userData)
    //   //     props.dispatch(createUser(userData))
    //   //     setConfirmUser(false)
    //   // }
    //   if (signUpResponse === 200) {
    //     setSignUpSuccess(true);
    //     setShowSignUp(false);
    //     setSignUpFail(false);
    //     setShortPassword(false);
    //     return;
    //   }
    // }, [signUpResponse]);

    // Check if the input password is valid
    const checkPasswordLength = (password) => {
        const cleanPassword = password?.trim();
        if (
            cleanPassword &&
            cleanPassword.length >= 6 &&
            cleanPassword.length <= 20
        ) {
            setValidLength(true);
            // setSignUpFail(false);
            // setShortPassword(false);
        } else {
            setValidLength(false);
        }
    };

    // Check if the input email is valid
    const checkEmail = (_email) => {
        const cleanEmail = email?.trim();
        const regex = new RegExp(/^\S+@\S+$/);
        if (cleanEmail && !regex.test(cleanEmail)) {
            setValidEmail(false);
        }
    };

    const handleEmail = (e) => {
        setEmail(e.target.value);
        if (e.target.value?.trim()) setEmailEmptyStatus(false);
    };

    const handleFirstName = (e) => {
        setFirstName(e.target.value);
        if (e.target.value?.trim()) setFirstNameEmptyStatus(false);
    };

    const handleLastName = (e) => {
        setLastName(e.target.value);
        if (e.target.value?.trim()) setLastNameEmptyStatus(false);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        checkPasswordLength(e.target.value);
    };

    const handleCheck = () => {
        setCheckedState((prev) => !prev);
    };

    //Function which sends the user sign-up input to the backend sign-up API and returns a response
    const handleSubmit = (e) => {
        setSignUpErrorMessage("");
        setEmailEmptyStatus(false);
        setFirstNameEmptyStatus(false);
        setLastNameEmptyStatus(false);
        e.preventDefault();
        const cleanEmail = email?.trim();
        const cleanFirstName = firstName?.trim();
        const cleanLastName = lastName?.trim();
        let inputError = false;
        if (!password || password.length < 6 || password.length > 20) {
            // setShortPassword(true);
            // setSignUpFail(false);
            // setSignUpErrorMessage("Invalid password.");
            setValidLength(false);
            setSignUpErrorMessage("Incorrect input values.");
            inputError = true;
        }

        if (!cleanEmail) {
            setEmailEmptyStatus(true);
            setSignUpErrorMessage((prev) => {
                if (prev !== "") return `Incorrect input values.`;
                // return "email address might not be valid";
            });
            inputError = true;
        }

        if (!cleanFirstName) {
            setFirstNameEmptyStatus(true);
            setSignUpErrorMessage((prev) => {
                if (prev !== "") return `Incorrect input values.`;
                // return "email address might not be valid";
            });
            inputError = true;
        }

        if (!cleanLastName) {
            setLastNameEmptyStatus(true);
            setSignUpErrorMessage((prev) => {
                if (prev !== "") return `Incorrect input values.`;
                // return "email address might not be valid";
            });
            inputError = true;
        }

        if (inputError) return;

        setLoading(true);

        const singUpInfo = {
            firstname: cleanFirstName,
            lastname: cleanLastName,
            email: cleanEmail,
            password: password,
            driver: isChecked,
        };

        axios
            .post(USER_SIGN_UP_API, JSON.stringify(singUpInfo), {
                headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                },
            })
            .then((response) => {
                // setSignUpResponse(response.status);
                setLoading(false);
                setSignUpSuccess(true);
                setShowSignUp(false);
            })
            .catch((error) => {
                // setSignUpFail(true);
                // setShortPassword(false);
                errHandling(error, setSignUpErrorMessage);
                // setSignUpErrorMessage(error.response.data.message);
                setLoading(false);
                // }
            });
    };



    return (
        <>
            {showSignUp ? (
                <div className={classes.paper}>
                    <div>
                        <div>
                            <p className={classes.title}>E-mail</p>
                        </div>
                        <div>
                            <TextField
                                onChange={(e) => handleEmail(e)}
                                placeholder="E-mail"
                                value={email}
                                id="email"
                                type="email"
                                className={classes.input}
                                onBlur={() => checkEmail(email)}
                                onFocus={() => setValidEmail(true)}
                            />
                        </div>
                        {!validEmail || emptyEmail ? (
                            <Box color={emptyEmail ? "red" : "orange"}>
                                {emptyEmail
                                    ? "- Should not be empty"
                                    : "- Email address may not be valid"}
                            </Box>
                        ) : (
                            <Box mt="1.5rem"></Box>
                        )}
                    </div>
                    <div>
                        <div>
                            <p className={classes.title}>First Name</p>
                        </div>
                        <div>
                            <TextField
                                onChange={(e) => handleFirstName(e)}
                                className={classes.input}
                                fullWidth={true}
                                placeholder="Firstname"
                                value={firstName}
                                id="firstName"
                                type="text"
                            />
                        </div>
                        {emptyFirstName ? (
                            <Box color="red">- Should not be empty</Box>
                        ) : (
                            <Box mt="1.5rem"></Box>
                        )}
                    </div>
                    <div>
                        <div>
                            <p className={classes.title}>Last Name</p>
                        </div>
                        <div>
                            <TextField
                                onChange={(e) => handleLastName(e)}
                                className={classes.input}
                                fullWidth={true}
                                placeholder="Lastname"
                                value={lastName}
                                id="lastName"
                                type="text"
                            />
                        </div>
                        {emptyLastName ? (
                            <Box color="red">- Should not be empty</Box>
                        ) : (
                            <Box mt="1.5rem"></Box>
                        )}
                    </div>
                    <div>
                        <div>
                            <p className={classes.title}>Password</p>
                        </div>
                        <div>
                            <TextField
                                onChange={(e) => handlePasswordChange(e)}
                                onFocus={(e) => checkPasswordLength(e.target.value)}
                                placeholder="Enter Password"
                                value={password}
                                type="password"
                                className={classes.input}
                            />
                        </div>
                        {validLength ? (
                            <Box mt="1.5rem"></Box>
                        ) : (
                            <Box color="red">- Should be within 6-20 characters</Box>
                        )}
                    </div>
                    {/* <Box mt={1} mb={1} diplay="flex">
            <RadioGroup row aria-label="user role" name="user_role" value={value} onChange={handleChange}>
                            <FormControlLabel value="end_user" control={<Radio color="primary"/>} label="End User" />
                            <FormControlLabel value="driver" control={<Radio color="primary"/>} label="Driver" />
                        </RadioGroup>
            <Checkbox
              color="primary"
              checked={isChecked}
              onChange={() => handleCheck()}
              name="userOrDriver"
            />
            <span>Sign up as driver</span>
          </Box> */}
                    {/* <Box mb={2} width="100%" display="flex" justifyContent="center">
            <ReCAPTCHA
                  sitekey={process.env.REACT_APP_GOOGLE_API_RECAPTCHA_KEY}
                  onChange={(e) => setRecaptchaValue(e)}
                />
          </Box> */}
                    <Button
                        className={classes.button}
                        // disabled={!recaptchaValue}
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        style={{width: "15rem"}}
                    >
                        {loading ? (
                            <CircularProgress style={{color: "white"}} size={24}/>
                        ) : (
                            "Create Account"
                        )}
                    </Button>
                    {/* <RadioGroup row aria-label="user role" name="user_role" value={value} onChange={handleChange}>
                            <FormControlLabel value="end_user" control={<Radio color="primary"/>} label="End User" />
                            <FormControlLabel value="driver" control={<Radio color="primary"/>} label="Driver" />
                        </RadioGroup> */}
                    <Box mt={2}>
                        <Box component="span" color="rgba(0,0,0,0.6)">
                            By signing up, you agree to our
                        </Box>{" "}
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.uucargo.ca/privacy-policy/"
                        >
                            Privacy Statement
                        </a>
                    </Box>
                    <div>
                        {signUpErrorMessage && (
                            <Alert severity="error" classes={{root: classes.alertRoot}}>
                                <AlertTitle>Error</AlertTitle>
                                {signUpErrorMessage}
                            </Alert>
                        )}
                        {/* {shortPassword ? (
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                Invalid password.
              </Alert>
            ) : (
              ""
            )} */}
                    </div>
                </div>
            ) : (
                ""
            )}
            <div style={submitStyle}>
                <br/>
                <br/>
                <br/>
                <div>
                    {signUpSuccess ? (
                        <Box className={classes.successWrapper} mb={2}>
                            <Box component="strong" fontSize="1.5rem" pb={2}>
                                <CheckCircleOutlineIcon/> Thank you for registering on UUCargo!{" "}
                            </Box>
                            <Box component="p" fontSize="1.2rem">
                                We've sent you a activation link to your e-mail
                                <b>{` ${email?.trim() || ""}`}</b>, you may sign in your account
                                within 24 hours before activation.{" "}
                            </Box>
                            <Box component="p" fontSize="1.2rem">
                                However, the link will expire after 24 hours.
                            </Box>
                        </Box>
                    ) : (
                        ""
                    )}
                </div>
                <div>
                    {signUpSuccess ? (
                        <div>
                            <Button
                                className={classes.button}
                                variant="contained"
                                color="primary"
                                onClick={() => redirectSignUpSuccessful(history)}
                            >
                                Sign In
                            </Button>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </>
    );
};
//

export default UserSignUp;
