import Grid from "@material-ui/core/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {useHistory} from "react-router-dom";
import {Divider} from "@mui/material";
import intl from "react-intl-universal";

const styles = {
    BillingSummaryRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    BillingSummaryContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px'
    },
    BillingSummaryTitle: {
        fontWeight: '600',
        textAlign: 'left',
    },
    BillingSummarySubTitle: {
        fontWeight: '600',
        textAlign: 'left',
        fontSize: '14px'
    }
}

export const BusinessBillingSummary = ({partnerInfo}) => {

    console.log('partner info', partnerInfo);

    const history = useHistory();

    return (
        <Box sx={styles.BillingSummaryRoot}>
            <Typography style={styles.BillingSummaryTitle}>
                {intl.get('BILLING_CENTER.ACCOUNT_DETAILS')}
            </Typography>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Typography style={styles.BillingSummarySubTitle}>
                        {intl.get('BILLING_CENTER.NOT_BILLED_AMOUNT')}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{textAlign: 'right', fontSize: '14px'}}>
                        ${partnerInfo?.not_billed_amount.toFixed(2)}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography style={styles.BillingSummarySubTitle}>
                        {intl.get('BILLING_CENTER.NOT_PAID_AMOUNT')}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{textAlign: 'right', fontSize: '14px'}}>
                        ${partnerInfo?.not_paid_amount.toFixed(2)}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography style={styles.BillingSummarySubTitle}>
                        {intl.get('BILLING_CENTER.NOT_USED_CREDIT')}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{textAlign: 'right', fontSize: '14px'}}>
                        (${partnerInfo?.absolute_creditnote.toFixed(2)})
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={6}>
                    <Typography style={styles.BillingSummarySubTitle}>
                        {intl.get('BILLING_CENTER.CURRENT_BALANCE')}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    {
                        partnerInfo?.current_bill_balance < 0 ?
                            <Typography style={{color: '#FE0000', textAlign: 'right', fontSize: '14px'}}>
                                ${Math.abs(partnerInfo?.current_bill_balance).toFixed(2)}
                            </Typography> :
                            <Typography style={{color: '#1D8B45', textAlign: 'right', fontSize: '14px'}}>
                                -${Math.abs(partnerInfo?.current_bill_balance).toFixed(2)}
                            </Typography>
                    }
                </Grid>
                <Grid item xs={6}>
                    <Typography style={styles.BillingSummarySubTitle}>
                        {intl.get('BILLING_CENTER.OVERDRAFT_LIMIT')}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{textAlign: 'right', fontSize: '14px'}}>
                        ${partnerInfo?.overdraft_limit.toFixed(2)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={6}>
                    <Typography style={styles.BillingSummarySubTitle}>
                        {intl.get('BILLING_CENTER.AVAILABLE_CREDIT')}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    {
                        partnerInfo?.current_bill_balance + partnerInfo?.overdraft_limit < 0 ?
                            <Typography style={{color: '#FE0000', textAlign: 'right', fontSize: '14px'}}>
                                ${(partnerInfo?.current_bill_balance + partnerInfo?.overdraft_limit).toFixed(2)}
                            </Typography> :
                            <Typography style={{color: '#1D8B45', textAlign: 'right', fontSize: '14px'}}>
                                ${(partnerInfo?.current_bill_balance + partnerInfo?.overdraft_limit).toFixed(2)}
                            </Typography>
                    }
                </Grid>
                <Grid item xs={6}>
                    <Typography style={styles.BillingSummarySubTitle}>
                        {intl.get('BILLING_CENTER.GETTING_RATE')}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{textAlign: 'right', fontSize: '14px'}}>
                        {partnerInfo?.can_get_rating === 1 ? intl.get('BILLING_CENTER.ACTIVE') : intl.get('BILLING_CENTER.SUSPENDED')}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography style={styles.BillingSummarySubTitle}>
                        {intl.get('BILLING_CENTER.BUYING_SHIPPING')}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{textAlign: 'right', fontSize: '14px'}}>
                        {partnerInfo?.can_buy_shipping === 1 ? intl.get('BILLING_CENTER.ACTIVE') : intl.get('BILLING_CENTER.SUSPENDED')}
                    </Typography>
                </Grid>
            </Grid>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center'
            }}>
                <Button
                    variant='contained'
                    sx={{
                        backgroundColor: '#1D8B45',
                        "&:hover": {
                            backgroundColor: '#1D8B45',
                            filter: 'brightness(0.9)'
                        }
                    }}
                    onClick={() => history.push('/business/billing-center/top-up')}
                >
                    <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                        {intl.get('BILLING_CENTER.ADD_CREDITS')}
                    </Typography>
                </Button>
            </Box>
        </Box>
    )
}