import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  alertRoot: {
    width: "90%",
  },
}));

export default function InformationCentenceLayout({ message }) {
  const classes = useStyles();
  const [time, setTime] = useState(20);
  const [display, setDisplay] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplay(false);
    }, time * 1000);

    return () => {
      if (!timer) return;
      clearTimeout(timer);
    };
  }, []);

  return (
    display && (
      <div className={classes.root}>
        <Alert severity="info" classes={{ standardInfo: classes.alertRoot }}>
          {message}
        </Alert>
      </div>
    )
  );
}
