import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Link} from "@mui/material";
import {useEffect, useState} from "react";
import {getAccessToken} from "../../Utils/doToken";
import axios from "axios";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import {ALPHAPAY_ALIPAY} from "../../Utils/consInfo";
import aliPay from "../../images/alipay.svg";
import CircularProgress from "@mui/material/CircularProgress";

const styles = {
    ShippingOrderDetailsAliPayRoot: {
        // border: '1px solid #000000',
        display: 'flex',
        borderRadius: '10px',
        padding: '20px 0'
    }
}

export const MovingOrderDetailsAliPay = ({orderDetails, orderId}) => {

    const accessToken = getAccessToken("access_token");

    const [isLoading, setIsLoading] = useState(true);
    const [amount, setAmount] = useState(0);
    const [payCheckLink, setPayCheckLink] = useState("#");

    const getAliUrl = async () => {

        let requestURL = `${NODE_ROUTE_URI}/generalWechatAlipay/initEndUserMovingDownPayment`;

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: {
                    backendOrderId: orderId,
                    payType: ALPHAPAY_ALIPAY
                }
            });

            console.log('get qr code', result);
            const {data: {qrcode_image, amount: payAmount, pay_url, provider}} = result;
            setAmount((+payAmount / 100));
            setPayCheckLink(pay_url);
        } catch (e) {
            console.log(e.response);
            setAmount(0);
            setPayCheckLink("#");
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(async () => {
        if (orderId) {
            await getAliUrl();
        }
    }, [orderId]);

    if (isLoading) {
        return <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <CircularProgress/>
        </Box>
    }

    if (payCheckLink === "#" && !isLoading) {
        return <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <Typography sx={{fontSize: '20px', fontWeight: '600'}}>
                Sorry We currently encounter some issues, please try again later.
            </Typography>
        </Box>
    }

    return (
        <Box sx={styles.ShippingOrderDetailsAliPayRoot}>
            <Grid container spacing={4}>
                <Grid item md={4} xs={12}>
                    <Box sx={{
                        padding: '5px 15px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        height: '100%',
                        gap: '5px'
                    }}>
                        <Typography style={{fontSize: '18px'}}>
                            Please click the external <Link href={payCheckLink} target="_blank"
                                                            rel="noopener noreferrer">link</Link> to complete the
                            payment.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={8} xs={12}>
                    <Box sx={{
                        padding: '5px 15px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        height: '100%',
                        gap: '5px'
                    }}>
                        <Box sx={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                            <img src={aliPay} alt="wechatLogo" width={35}/>
                            <Typography style={{fontSize: '16px'}}>
                                Alipay
                            </Typography>
                        </Box>
                        <Grid container spacing={0}>
                            <Grid item xs={6}>
                                <Typography style={{fontSize: '16px', textAlign: 'left'}}>
                                    Total Amount:
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                    $ {amount.toFixed(2)}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                    Receiver:
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                    UU CARGO LTD.
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                    Order Number:
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                    {orderDetails?.order_number}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}