import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import store from "../../store/index";
import { driverSelectPackage } from "../../actions/driverFetchPackageAction";
import { selectShipment } from "../../actions/fetchShipmentsAction";
import ShipmentList from "./ShipmentList";
import useShipmentsFetch from "./useShipmentsFetch";
import makeStyles from "@material-ui/core/styles/makeStyles";
import BindCreditCardInfoLayout from "../Layouts/BindCreditCardInfoLayout";
import LoadingCircle from "../LoadingCircle";
import Pagination from "@material-ui/lab/Pagination";
import AlertMessageLayout from "../Layouts/AlertMessageLayout";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
    display: "flex",
    justifyContent: "center",
  },
  scrollComponent: {
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    width: "100%",
    maxWidth: "1000px",
    margin: "0px auto",
    background: "#FFFAF2",
  },
}));

const ScrollComponent = ({ type, searchOptions }) => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [bindCardProcess, setBindCardProcess] = useState({
    open: false,
    redirect: "",
  });
  const history = useHistory();

  const cardActive = useSelector((state) => {
    return state.user.activeCard === "active";
  });

  useEffect(() => {
    setPage(1);
  }, [searchOptions]);

  const { loading, error, shipments, hasMore, lastPage } = useShipmentsFetch(
    type,
    page,
    searchOptions
  );

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleClose = () => {
    setBindCardProcess({ ...bindCardProcess, open: false });
  };

  const goPackageDetailPage = (_shipmentNumber, _packageNumber, _stage) => {
    //check if current user bind with a credit card
    if (cardActive) {
      let path = `/driver/${type}/shipment_number=${_shipmentNumber}&stage=${_stage}`;
      store.dispatch(selectShipment(_shipmentNumber));
      store.dispatch(driverSelectPackage(_packageNumber));
      history.push(path);
    } else {
      //show alert to bind credit card
      setBindCardProcess({
        open: true,
        redirect: `/driver/${type}/shipment_number=${_shipmentNumber}&stage=${_stage}`,
      });
    }
  };

  const handleMessage = () => {
    let res = "";
    if (type === "claimed_shipment_tasks") {
      res = "No shipment has been claimed.";
    } else if (type === "delivered_shipment_tasks") {
      res = "No shipment has been delivered.";
    } else if (type === "available_shipment_tasks") {
      res = "No shipment found, please adjust the search filter.";
    } else if (type === "pickedup_shipment_tasks") {
      res = "No shipment has been picked-up.";
    }
    return res;
  };

  // useEffect(()=>{
  //   if(shipments.length > 0){
  //   }
  // },[shipments])

  //implementing infinite scrolling with intersection observer
  // const lastShipment = useCallback(node => {
  //   console.log('node',node)
  //   if(loading) return
  //   if (observer.current) { observer.current.disconnect() }
  //   observer.current = new IntersectionObserver(entries => {
  //     if(entries[0].isIntersecting && hasMore){
  //       console.log('is intersecting')
  //       setPage(prePage => prePage + 1)
  //     }
  //   })
  //   if(node) observer.current.observe(node)
  // },[loading, hasMore])
  return (
    <div className={classes.scrollComponent} id="shipment-list">
      {!loading ? (
        shipments.length > 0 ? (
          shipments.map((s, index) => {
            return (
              <ShipmentList
                key={s.shipment_number}
                id={`${s.shipment_number}`}
                shipment={s}
                handleClick={(sNum, pNum, stage) => {
                  goPackageDetailPage(sNum, pNum, stage);
                }}
              />
            );
          })
        ) : (
          <AlertMessageLayout message={handleMessage()} />
        )
      ) : (
        <LoadingCircle />
      )}
      {!loading && lastPage > 1 && (
        <div className={classes.pagination}>
          <Pagination
            className={classes.pagination}
            count={lastPage}
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
          />
        </div>
      )}
      <BindCreditCardInfoLayout
        open={bindCardProcess.open}
        handleClose={handleClose}
        redirect={bindCardProcess.redirect}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    searchOptions: state.searchFilter,
  };
};
export default connect(mapStateToProps)(ScrollComponent);
