import Grid from "@material-ui/core/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Divider, Link, Snackbar, useMediaQuery} from "@mui/material";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import {CSVLink} from "react-csv";
import {PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../Utils/doToken";
import {useRef, useState} from "react";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const AdjustOrderDashboardEach = ({adjustment, carrier}) => {

    const isMobile = useMediaQuery('(max-width: 480px)');

    const token = getAccessToken("access_token");

    const [invoiceData, setInvoiceData] = useState([]);
    const csvLink = useRef();

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const getInvoiceData = async () => {
        let requestURL = `${PARTNER_URI}/invoices/exportInvoiceDataForTracking`;
        let data = {
            trackingNumber: adjustment?.tracking_no,
            invoiceNumber: adjustment?.invoice_no
        }
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            })
            console.log('result get invoice data', result);
            if (result?.data?.headData?.length === 0) {
                setErrorMessage('There is no invoice data available for download.');
                handleErrorMessage();
            } else {
                setErrorMessage('');
                setSuccessMessage('Invoice data downloaded successfully.');
                handleSuccessMessage();
                setInvoiceData([result?.data?.headData, ...result?.data?.lineData])
                csvLink.current.link.click();
            }
        } catch (e) {
            setErrorMessage('There is an issue with downloading invoice data.');
            handleErrorMessage();
            console.log('error', e.response);
        } finally {
            setInvoiceData([]);
        }
    }

    // console.log('invoice data', invoiceData);

    return (
        <Grid container spacing={isMobile ? 0 : 2}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item md={3} xs={12}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                        {isMobile && 'Tracking No: '}{adjustment?.tracking_no}
                    </Typography>
                    <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                        Customer Entered
                        Dimension: {adjustment?.length}x{adjustment?.width}x{adjustment?.height} {adjustment?.dimension_unit}
                    </Typography>
                    <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                        Customer Entered Weight: {adjustment?.weight} {adjustment?.weight_unit}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={2} xs={12}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                            Adjust For Order:
                        </Typography>
                        <Link
                            href={`/business/package-order-dashboard/${adjustment?.originalShippingOrderInfo?.order_id}`}
                            underline="hover"
                            sx={{
                                color: '#1D8B45',
                            }}>
                            <Typography style={{
                                fontWeight: '600',
                                width: '100%',
                                fontSize: '12px',
                                color: '#1D8B45',
                                textAlign: 'left'
                            }}>
                                {adjustment?.originalShippingOrderInfo?.order_id}
                            </Typography>
                        </Link>
                    </Box>
                    <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                        Original Amount:
                        $ {(+adjustment?.originalShippingOrderInfo?.total_amount_include_tax)?.toFixed(2)}
                    </Typography>
                    <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                        {isMobile && 'Order Date: '}{dayjs(adjustment?.originalShippingOrderInfo?.created_at).format("YYYY-MM-DD")}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={2} xs={12}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                        {isMobile && 'Sender: '}{adjustment?.originalShippingOrderInfo?.send_from_addr}, {adjustment?.originalShippingOrderInfo?.send_from_city}, {adjustment?.originalShippingOrderInfo?.send_from_province}, {adjustment?.originalShippingOrderInfo?.send_from_postal_code}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={2} xs={12}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                        {isMobile && 'Recipient: '}{adjustment?.originalShippingOrderInfo?.send_to_addr}, {adjustment?.originalShippingOrderInfo?.send_to_city}, {adjustment?.originalShippingOrderInfo?.send_to_province}, {adjustment?.originalShippingOrderInfo?.send_to_postal_code}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={3} xs={12}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                        Adjustment Amount: $ {(+adjustment?.shipping_fee_include_tax)?.toFixed(2)}
                    </Typography>
                    <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                        Adjustment Reason: {adjustment?.invoice_section || 'N/A'}
                    </Typography>
                    {/*{carrier.toUpperCase() !== "UPS" &&*/}
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-start'
                        }}>
                            <Button
                                sx={{
                                    textTransform: 'none',
                                    padding: '0'
                                }}
                                onClick={getInvoiceData}
                            >
                                <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                    Download
                                </Typography>
                            </Button>
                            <CSVLink
                                data={invoiceData}
                                filename={`Adjust-Invoice-${adjustment?.invoice_no}.csv`}
                                className='hidden'
                                ref={csvLink}
                                target='_blank'
                            />
                        </Box>
                    {/*}*/}
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
        </Grid>
    )
}