import React, { useState } from "react";
// import { useSelector } from "react-redux";
import {
  // TextField,
  Box,
} from "@material-ui/core";
import produce from "immer";
// import { Paper } from '@material-ui/core'
// import orange from '@material-ui/core/colors/orange';
// import { makeStyles } from "@material-ui/core/styles";
// import useMediaQuery from '@material-ui/core/useMediaQuery';
// import { Alert } from '@material-ui/lab';

// import PropTypes from "prop-types";

import BolItemInput from "./components/bolItemInput";
import BolItemEditor from "./components/bolItemEditor";

const BolItems = ({ itemList, updateItemList }) => {
  // const dispatch = useDispatch();
  // const shippingItems = useSelector((state) => state.shipping.shippingItems);

  // const [showError, setShowErrorState] = useState(false);
  // const classes = useStyles();

  //   const [shippingItem, setShippingItem] = useState({
  //     hscode: "",
  //     package: "",
  //     weight: null,
  //     measurement: null,
  //     hscodeDescription: "",
  //     goodsDescription: "",
  //     containerType: "",
  //   });

  //   const handleItemChange = (fieldName) => (e) => {
  //     const value = e.target.value;
  //     setShippingItem(
  //       produce((draft) => {
  //         draft[fieldName] = value;
  //       })
  //     );
  //   };

  //   const handleItemChangeFromSelect = (fieldName) => (_value) => {
  //     setShippingItem(
  //       produce((draft) => {
  //         draft[fieldName] = _value;
  //       })
  //     );
  //   };

  //   const handleHscodeChange = (hscodeItem) => {
  //     handleItemChangeFromSelect("hscode")(hscodeItem.hscode || "");
  //     handleItemChangeFromSelect("hscodeDescription")(hscodeItem.desc || "");
  //   };

  const showItems = () => {
    return itemList.map((item, i) => {
      return (
        <BolItemEditor
          key={item.selectedTypeID}
          bolItem={item}
          updateItemList={updateItemList}
          index={i}
        />
      );
    });
  };

  return (
    <Box>
      <Box color="orange" mb="1vh" textAlign="center">
        <h1>What items are you shipping?</h1>
      </Box>

      <Box mb="2vh" display="flex" flexDirection="column">
        {showItems()}
      </Box>

      <BolItemInput updateItemList={updateItemList} />
    </Box>
  );
};

// const theme = createMuiTheme({
//     palette: {
//         primary: { light: orange[300], main: orange[500], dark: orange[700] }
//     }
// })

// const useStyles = makeStyles((theme) => ({
//   paper: {
//     backgroundColor: theme.palette.background.paper,
//     borderRadius: "20px",
//     padding: theme.spacing(2, 4, 3),
//     overflow: "hidden",
//   },
//   input: {
//     color: "grey",
//     width: "90%",
//   },
//   label: {
//     flexDirection: "column",
//   },
//   icon: {
//     fontSize: "32px !important",
//     marginBottom: theme.spacing(1),
//   },
//   list: {
//     width: "100%",
//     backgroundColor: theme.palette.background.paper,
//     boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)",
//   },
//   backButton: {
//     marginRight: theme.spacing(4),
//     backgroundColor: "white",
//   },
//   subTitle: {
//     color: "darkgreen",
//     fontWeight: 600,
//     fontSize: "1.2rem",
//     fontFamily: "Montserrat",
//   },
// }));

// AddressInput.propTypes = {
//   backToThird: PropTypes.func.isRequired,
//   toSeventh: PropTypes.func.isRequired,
//   shippingItems: PropTypes.array.isRequired,
//   setShippingItems: PropTypes.func.isRequired,
//   itemInfo: PropTypes.object,
// };

export default BolItems;
