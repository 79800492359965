import Box from "@mui/material/Box";
import {getAccessToken} from "../../Utils/doToken";
import axios from "axios";
import {PARTNER_URI} from "../../Utils/apiUrl";
import {useEffect, useState} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@material-ui/core/Grid";
import wechatPay from "../../images/wechatPay.svg";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";

const styles = {
    TopUpWeChatPayRoot: {
        border: '1px solid #000000',
        display: 'flex',
        borderRadius: '10px'
    }
}

export const TopUpWeChatPay = ({
                                   isLoading,
                                   qrCodeImage,
                                   amount,
                               }) => {


    if (isLoading) {
        return <>
            <CircularProgress/>
        </>
    }

    if (qrCodeImage === "" && !isLoading) {
        return <>
            <h3>Sorry We currently encounter some issues, please try again later.</h3>
        </>
    }

    return (

        <Box sx={styles.TopUpWeChatPayRoot}>
            <Grid container spacing={4}>
                <Grid item md={4} xs={12}>
                    <Box sx={{padding: '5px 15px'}}>
                        <img src={qrCodeImage} alt="qrCodeLogo" width={170}/>
                    </Box>
                </Grid>
                <Grid item md={8} xs={12}>
                    <Box sx={{
                        padding: '5px 15px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        height: '100%',
                        gap: '5px'
                    }}>
                        <Box sx={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                            <img src={wechatPay} alt="wechatLogo" width={35}/>
                            <Typography style={{fontSize: '14px'}}>
                                {intl.get('BILLING_CENTER.PAYMENT.WECHAT.WECHAT_PAY')}
                            </Typography>
                        </Box>
                        <Grid container spacing={0}>
                            <Grid item xs={6}>
                                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                    {intl.get('BILLING_CENTER.PAYMENT.WECHAT.TOTAL_AMOUNT')}:
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                    $ {amount.toFixed(2)}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                    {intl.get('BILLING_CENTER.PAYMENT.WECHAT.RECEIVER')}:
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                    UU CARGO LTD.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}