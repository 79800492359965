import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import {Button, Collapse, IconButton, MenuItem, Select} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {internationalShipmentTypeList, transportationList} from "../../Utils/Helper";
import {useEffect, useState} from "react";
import {updateShipCrossBorder} from "../../actions/ratingAction";
import {useDispatch} from "react-redux";
import {styled} from "@mui/material/styles";
import HelpIcon from "@mui/icons-material/Help";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Divider from "@mui/material/Divider";

const ExpandMore = styled((props) => {
    const {expand, ...other} = props;
    return <IconButton {...other} />;
})(({theme, expand}) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    // marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const style = {
    RatingShippingCrossBorderRoot: {
        display: 'flex',
        flexDirection: 'column'
    },
    RatingShippingCrossBorderHead: {
        display: 'flex',
        margin: '15px',
        gap: '20px',
        justifyContent: 'flex-start'
    },
    RatingShippingCrossBorderCollapse: {
        display: 'flex',
        flexWrap: 'none',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '20px',
        margin: '15px',
    }
}

export const RatingShippingCrossBorder = () => {

    const [expanded, setExpanded] = useState(false);

    const [shippingCrossBroder, setShippingCrossBroder] = useState({
        transMode: "INT_TRUCK",
        shipmentType: "COMMERCIAL"
    });

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(updateShipCrossBorder(shippingCrossBroder));
    }, [shippingCrossBroder])

    return (
        <Box sx={style.RatingShippingCrossBorderRoot}>
            <Box sx={style.RatingShippingCrossBorderHead}>
                {/*<IconButton>*/}
                {/*    <HelpIcon style={{color: '#537FE7'}}*/}
                {/*              onClick={() => window.open("https://uucargo.ca/support/cross-border-shipping-parcels/")}/>*/}
                {/*</IconButton>*/}
                <Box onClick={handleExpandClick}>
                    <Button sx={{fontWeight: '600', fontStyle: 'italic', color: '#000000'}}>
                        Cross Border Use Only
                    </Button>
                    <ExpandMore
                        expand={expanded}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon/>
                    </ExpandMore>
                </Box>
            </Box>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Box sx={style.RatingShippingCrossBorderCollapse}>
                    <FormControl fullWidth variant="standard">
                        <InputLabel>Transportation Mode</InputLabel>
                        <Select
                            value={shippingCrossBroder?.transMode}
                            onChange={e => setShippingCrossBroder(prevState => ({
                                ...prevState, transMode: e.target.value
                            }))}
                        >
                            {transportationList.map(v => {
                                return <MenuItem value={v.code} key={v.code}>{v.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth variant="standard">
                        <InputLabel>International Shipment Type</InputLabel>
                        <Select
                            value={shippingCrossBroder?.shipmentType}
                            onChange={e => setShippingCrossBroder(prevState => ({
                                ...prevState, shipmentType: e.target.value
                            }))}
                        >
                            {internationalShipmentTypeList.map(v => {
                                return <MenuItem value={v} key={v}>{v}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Box>
            </Collapse>
        </Box>
    )
}