import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Button, Snackbar} from "@mui/material";
import {RatingItemEdit} from "../Rating/RatingItemEdit";
import Divider from "@mui/material/Divider";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../Utils/doToken";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {useState} from "react";
import {useDispatch} from "react-redux";
import {addBoxInfo, toggleEditMyBoxes} from "../../actions/myBoxesAction";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
    MyBoxesListEachRoot: {
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 40px',
    },
    MyBoxesListEachItem: {
        marginBottom: '10px',
    },
    MyBoxesListEachItemDetail: {
        display: 'flex',
        flexDirection: 'column'
    },
    MyBoxesListEachItemQuantity: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    MyBoxesListEachItemButton: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
    }
}

export const MyBoxesListEach = ({boxInfo}) => {

    const accessToken = getAccessToken("access_token");
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const dispatch = useDispatch();

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const deleteMyBoxById = async () => {
        let requestURL = `${NODE_ROUTE_URI}/myBoxes/deleteMyBoxById`;
        try {
            await axios({
                method: 'delete',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: {boxId: boxInfo.box_id}
            })
            // console.log(result.data)
            setErrorMessage('');
            setSuccessMessage('Successfully Delete Box');
            handleSuccessMessage();

        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail Delete Box');
            handleErrorMessage();
        }
    }

    const handleRemoveBox = () => {
        deleteMyBoxById();
    }

    const handleEditBox = () => {
        dispatch(toggleEditMyBoxes(true));
        dispatch(addBoxInfo(boxInfo));
    }

    return (
        <Box sx={style.MyBoxesListEachRoot}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid container spacing={2} sx={style.MyBoxesListEachItem}>
                <Grid item xs={8} sx={style.MyBoxesListEachItemDetail}>
                    <Typography style={{wordWrap: 'break-word', whiteSpace: 'pre-line'}}
                                sx={{fontSize: '25px', fontWeight: '600'}}>
                        {boxInfo?.box_name}
                    </Typography>
                    <Typography style={{wordWrap: 'break-word', whiteSpace: 'pre-line'}} sx={{fontSize: '20px'}}>
                        {boxInfo?.item_name}
                    </Typography>
                    <Typography sx={{fontSize: '15px'}}>
                        Weight: {boxInfo.weight} {boxInfo.weight_unit}
                    </Typography>
                    <Typography style={{wordWrap: 'break-word', whiteSpace: 'pre-line', textAlign: 'left'}}>
                        Dimension (L x W x
                        H): {boxInfo.length || 0} x {boxInfo.length || 0} x {boxInfo.length || 0} {boxInfo.dimension_unit}
                    </Typography>
                    {/*{itemDetail.itemId}*/}
                </Grid>
                <Grid item xs={4} sx={style.MyBoxesListEachItemButton}>
                    <Button onClick={handleEditBox}>Edit</Button>
                    <Button color='error' onClick={handleRemoveBox}>Remove</Button>
                </Grid>
            </Grid>
            {/*{openEditForm.status && openEditForm.itemId === itemDetail.itemId &&*/}
            {/*    <RatingItemEdit itemDetail={itemDetail}/>}*/}
            <Divider/>
        </Box>
    )
}