import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {Button, Collapse, IconButton, MenuItem, Select} from "@mui/material";
import {
    checkAllPropertiesHaveValueFalse,
    countryListAllIsoData,
    generateRandomString, isPositiveInteger,
    UOMList
} from "../../Utils/Helper";
import CloseIcon from '@mui/icons-material/Close';
import {useDispatch} from "react-redux";
import {addItem, openRatingAddItemForm} from "../../actions/ratingAction";
import Divider from "@mui/material/Divider";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {styled} from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpIcon from '@mui/icons-material/Help';
import {useHistory} from "react-router-dom";

const options = [
    {label: 'Option 1', value: 1},
    {label: 'Option 2', value: 2},
    {label: 'Option 3', value: 3},
    {label: 'Option 4', value: 4},
];

const ExpandMore = styled((props) => {
    const {expand, ...other} = props;
    return <IconButton {...other} />;
})(({theme, expand}) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    // marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const style = {
    RatingItemFormRoot: {
        backgroundColor: '#FFFFFF',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        borderRadius: '15px'
    },
    RatingItemFormContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    RatingItemFormSection: {
        display: 'flex',
        flexWrap: 'none',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '20px',
        margin: '15px'
    },
    RatingItemFormCrossBorder: {
        display: 'flex',
        margin: '15px',
        gap: '20px',
        justifyContent: 'flex-start'
    },
    RatingItemFormSubmit: {
        display: 'flex',
        justifyContent: 'center'
    },
    RatingItemFormSectionItem: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    RatingItemFormCloseButton: {
        position: 'absolute',
        right: 0

    }
}

export const RatingItemForm = () => {

    const [itemInfo, setItemInfo] = useState({
        currency: 'CAD',
        dimensionUnit: 'cm',
        weightUnit: 'kg',
        uom: 'BOX',
        originCountry: 'CA',
        name: '',
        unitPrice: '',
        quantity: '',
        length: '',
        width: '',
        height: '',
        weight: '',
        hsCode: '',
        itemId: ''
    })

    const [validateInput, setValidateInput] = useState({})

    const [expanded, setExpanded] = useState(false);

    const dispatch = useDispatch();

    const handleCloseItemFrom = () => {
        dispatch(openRatingAddItemForm(false));
    }

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const validateInputValue = () => {

        let validateResult = {};

        if (itemInfo.name === "") {
            setValidateInput(prevState => ({
                ...prevState, nameEmpty: true
            }))
            validateResult.nameEmpty = true;
        } else {
            setValidateInput(prevState => ({
                ...prevState, nameEmpty: false
            }))
            validateResult.nameEmpty = false;
        }

        if (itemInfo.quantity === "") {
            setValidateInput(prevState => ({
                ...prevState, quantityEmpty: true
            }))
            validateResult.quantityEmpty = true;
        } else {
            setValidateInput(prevState => ({
                ...prevState, quantityEmpty: false
            }))
            validateResult.quantityEmpty = false;
        }

        if (itemInfo.length === "") {
            setValidateInput(prevState => ({
                ...prevState, lengthEmpty: true
            }))
            validateResult.lengthEmpty = true;
        } else {
            setValidateInput(prevState => ({
                ...prevState, lengthEmpty: false
            }))
            validateResult.lengthEmpty = false;
        }

        if (itemInfo.width === "") {
            setValidateInput(prevState => ({
                ...prevState, widthEmpty: true
            }))
            validateResult.widthEmpty = true;
        } else {
            setValidateInput(prevState => ({
                ...prevState, widthEmpty: false
            }))
            validateResult.widthEmpty = false;
        }

        if (itemInfo.height === "") {
            setValidateInput(prevState => ({
                ...prevState, heightEmpty: true
            }))
            validateResult.heightEmpty = true;
        } else {
            setValidateInput(prevState => ({
                ...prevState, heightEmpty: false
            }))
            validateResult.heightEmpty = false;
        }

        if (itemInfo.weight === "") {
            setValidateInput(prevState => ({
                ...prevState, weightEmpty: true
            }))
            validateResult.weightEmpty = true;
        } else {
            setValidateInput(prevState => ({
                ...prevState, weightEmpty: false
            }))
            validateResult.weightEmpty = false;
        }

        if (isPositiveInteger(itemInfo.quantity)) {
            setValidateInput(prevState => ({
                ...prevState, quantityValidate: false
            }))
            validateResult.quantityValidate = false
        } else {
            setValidateInput(prevState => ({
                ...prevState, quantityValidate: true
            }))
            validateResult.quantityValidate = true;
        }

        if (isPositiveInteger(itemInfo.length)) {
            setValidateInput(prevState => ({
                ...prevState, lengthValidate: false
            }))
            validateResult.lengthValidate = false
        } else {
            setValidateInput(prevState => ({
                ...prevState, lengthValidate: true
            }))
            validateResult.lengthValidate = true;
        }

        if (isPositiveInteger(itemInfo.width)) {
            setValidateInput(prevState => ({
                ...prevState, widthValidate: false
            }))
            validateResult.widthValidate = false
        } else {
            setValidateInput(prevState => ({
                ...prevState, widthValidate: true
            }))
            validateResult.widthValidate = true;
        }

        if (isPositiveInteger(itemInfo.height)) {
            setValidateInput(prevState => ({
                ...prevState, heightValidate: false
            }))
            validateResult.heightValidate = false
        } else {
            setValidateInput(prevState => ({
                ...prevState, heightValidate: true
            }))
            validateResult.heightValidate = true;
        }

        if (isPositiveInteger(itemInfo.weight)) {
            setValidateInput(prevState => ({
                ...prevState, weightValidate: false
            }))
            validateResult.weightValidate = false
        } else {
            setValidateInput(prevState => ({
                ...prevState, weightValidate: true
            }))
            validateResult.weightValidate = true;
        }

        return checkAllPropertiesHaveValueFalse(validateResult);

    }

    const getHSCode = async (queryString) => {

        let requestURL = `${NODE_ROUTE_URI}/elasticsearch/search`;
        console.log('query String', queryString);
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    query_string: queryString
                }
            })
            const {data: {hits}} = result;
            const hsCodeList = hits?.map(item => {
                // console.log(item["_source"])
                return {
                    hscode: item["_source"].hscode,
                    desc: item["_source"].desc
                }
            })
            console.log('hs code list', hsCodeList);
            setHScodeList(hsCodeList);
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (itemInfo.itemId) {
            dispatch(addItem(itemInfo));
            handleCloseItemFrom();
        }
    }, [itemInfo.itemId])

    const handleAddItem = () => {

        if (validateInputValue()) {
            const randomId = generateRandomString(8);
            setItemInfo(prevState => ({
                ...prevState, itemId: randomId
            }))
        }
    }

    // console.log(itemInfo);

    const [HScodeList, setHScodeList] = useState([]);

    // console.log(validateInput);
    //
    // console.log(Object.keys(validateInput).length);

    return (
        <Box sx={style.RatingItemFormRoot}>
            <Typography variant='h5' sx={{textAlign: 'center'}}>
                Item Information
            </Typography>
            <IconButton
                onClick={handleCloseItemFrom}
                sx={style.RatingItemFormCloseButton}>
                <CloseIcon/>
            </IconButton>
            <Box sx={style.RatingItemFormContainer}>
                <Box sx={style.RatingItemFormSection}>
                    <TextField
                        value={itemInfo.name}
                        label={validateInput.nameEmpty ? "Name is required fields" : "Name"}
                        required
                        fullWidth
                        variant="standard"
                        onInput={e => setItemInfo(prevState => ({
                            ...prevState, name: e.target.value
                        }))}
                        sx={{border: validateInput.nameEmpty ? "2px solid red" : ""}}
                    />
                    <TextField
                        value={itemInfo.quantity}
                        label={validateInput.quantityEmpty ? "Quantity is required fields" : validateInput.quantityValidate ? "Quantity should be positive integer" : "Quantity"}
                        fullWidth
                        required
                        variant="standard"
                        onInput={e => setItemInfo(prevState => ({
                            ...prevState, quantity: e.target.value
                        }))}
                        sx={{border: validateInput.quantityEmpty || validateInput.quantityValidate ? "2px solid red" : ""}}
                    />
                </Box>
                <Box sx={style.RatingItemFormSection}>
                    <TextField
                        value={itemInfo?.length}
                        label={validateInput.lengthEmpty ? "Length is required fields" : validateInput.lengthValidate ? "Length should be positive integer" : "Length"}
                        required
                        fullWidth
                        variant="standard"
                        onInput={e => setItemInfo(prevState => ({
                            ...prevState, length: e.target.value
                        }))}
                        sx={{
                            paddingTop: '24px',
                            border: validateInput.lengthEmpty || validateInput.lengthValidate ? "2px solid red" : ""
                        }}
                    />
                    <TextField
                        value={itemInfo?.width}
                        label={validateInput.widthEmpty ? "Width is required fields" : validateInput.widthValidate ? "Width should be positive integer" : "Width"}
                        required
                        variant="standard"
                        fullWidth
                        onInput={e => setItemInfo(prevState => ({
                            ...prevState, width: e.target.value
                        }))}
                        sx={{
                            paddingTop: '24px',
                            border: validateInput.widthEmpty || validateInput.widthValidate ? "2px solid red" : ""
                        }}
                    />
                    <TextField
                        value={itemInfo?.height}
                        label={validateInput.heightEmpty ? "Height is required fields" : validateInput.heightValidate ? "Height should be positive integer" : "Height"}
                        required
                        fullWidth
                        variant="standard"
                        onInput={e => setItemInfo(prevState => ({
                            ...prevState, height: e.target.value
                        }))}
                        sx={{
                            paddingTop: '24px',
                            border: validateInput.heightEmpty || validateInput.heightValidate ? "2px solid red" : ""
                        }}
                    />
                    <FormControl variant="standard" fullWidth>
                        <InputLabel>Length Unit*</InputLabel>
                        <Select
                            value={itemInfo?.dimensionUnit}
                            label="length unit"
                            onChange={e => setItemInfo(prevState => ({
                                ...prevState, dimensionUnit: e.target.value
                            }))}
                        >
                            <MenuItem value={'cm'}>cm</MenuItem>
                            <MenuItem value={'in'}>in</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={style.RatingItemFormSection}>
                    <TextField
                        value={itemInfo?.weight}
                        label={validateInput.weightEmpty ? "Weight is required fields" : validateInput.weightValidate ? "Weight should be positive integer" : "Weight"}
                        fullWidth
                        variant="standard"
                        required
                        onInput={e => setItemInfo(prevState => ({
                            ...prevState, weight: e.target.value
                        }))}
                        sx={{
                            paddingTop: '24px',
                            border: validateInput.weightEmpty || validateInput.weightValidate ? "2px solid red" : ""
                        }}
                    />
                    <FormControl variant="standard" fullWidth>
                        <InputLabel>Weight Unit*</InputLabel>
                        <Select
                            value={itemInfo?.weightUnit}
                            label="weight unit"
                            onChange={e => setItemInfo(prevState => ({
                                ...prevState, weightUnit: e.target.value
                            }))}
                        >
                            <MenuItem value={'lb'}>lb</MenuItem>
                            <MenuItem value={'kg'}>kg</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={style.RatingItemFormCrossBorder}  >
                    <IconButton>
                        <HelpIcon style={{color: '#537FE7'}} onClick={()=> window.open("https://uucargo.ca/support/cross-border-shipping-parcels/")}/>
                    </IconButton>
                    <Box onClick={handleExpandClick}>
                        <Button sx={{fontWeight: '600', fontStyle: 'italic', color: '#000000'}}>
                            Cross Border Use Only
                        </Button>
                        <ExpandMore
                            expand={expanded}
                            aria-expanded={expanded}
                            aria-label="show more"
                        >

                            <ExpandMoreIcon/>
                        </ExpandMore>
                    </Box>
                    <Divider/>
                </Box>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Box sx={style.RatingItemFormSection}>
                        <Autocomplete
                            disablePortal
                            options={HScodeList}
                            fullWidth
                            freeSolo
                            filterOptions={(options, state) => options}
                            getOptionLabel={(option) => `${option.hscode}`}
                            renderOption={(option) => <span><b>{option?.hscode}</b> | {option.desc}</span>}
                            onInputChange={async (e, value) => {
                                setItemInfo(prevState => ({
                                    ...prevState, hsCode: value
                                }))
                                getHSCode(value);

                            }}
                            renderInput={(params) => <TextField
                                multiline
                                id="outlined-basic"
                                label='Serach HSCode'
                                variant="standard"
                                fullWidth
                                required
                                {...params}
                            />}
                        />
                        <TextField
                            value={itemInfo.unitPrice}
                            required
                            fullWidth
                            label="Unit Price"
                            variant="standard"
                            onInput={e => setItemInfo(prevState => ({
                                ...prevState, unitPrice: e.target.value
                            }))}
                            // sx={{paddingTop: '24px'}}
                        />
                    </Box>
                    <Box sx={style.RatingItemFormSection}>
                        <FormControl fullWidth variant="standard">
                            <InputLabel>Origin Country*</InputLabel>
                            <Select
                                value={itemInfo?.originCountry}
                                label="origin country"
                                onChange={e => setItemInfo(prevState => ({
                                    ...prevState, originCountry: e.target.value
                                }))}
                            >
                                {
                                    countryListAllIsoData.map(v => {
                                        return <MenuItem value={v.code} key={v.code}>{v.name}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                        <FormControl fullWidth variant="standard">
                            <InputLabel>Unit of Measure*</InputLabel>
                            <Select
                                value={itemInfo?.uom}
                                label="unit of measure"
                                onChange={e => setItemInfo(prevState => ({
                                    ...prevState, uom: e.target.value
                                }))}
                            >
                                {UOMList.map(v => {
                                    return <MenuItem value={v} key={v}>{v}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                    {/*<Box sx={style.RatingItemFormSection}>*/}
                    {/*    <TextField*/}
                    {/*        value={itemInfo.unitPrice}*/}
                    {/*        required*/}
                    {/*        fullWidth*/}
                    {/*        label="Unit Price"*/}
                    {/*        variant="standard"*/}
                    {/*        onInput={e => setItemInfo(prevState => ({*/}
                    {/*            ...prevState, unitPrice: e.target.value*/}
                    {/*        }))}*/}
                    {/*        sx={{paddingTop: '24px'}}*/}
                    {/*    />*/}
                    {/*</Box>*/}
                </Collapse>
                <Box sx={style.RatingItemFormSubmit}>
                    <Button
                        onClick={handleAddItem}
                    >
                        Add
                    </Button>
                </Box>
            </Box>
        </Box>
    )

}
