import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import {useSelector} from "react-redux";
import {convertCubicInToCubicMeter, convertCubicMeterToCubicIn, convertKGToLB, convertLBToKG} from "../../Utils/Helper";
import intl from "react-intl-universal";

const styles = {
    MovingServiceRateRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '10px',
        display: 'flex',
        flexDirection: 'column',
    },
    MovingServiceRateHeading: {
        fontSize: '20px',
        fontWeight: '600'
    },
    MovingServiceRateSubHeading: {
        fontSize: '18px',
        fontWeight: '600'
    },
}

export const MovingServiceExpressRate = () => {

    let basicRateInfo = useSelector(state => state?.movingService?.movingServiceBasicRateInfo);
    let movingToAddress = useSelector(state => state?.movingService?.movingToAddress);

    console.log('[MovingServiceExpressRate] basicRateInfo', basicRateInfo);

    return (
        <Box sx={styles.MovingServiceRateRoot}>
            <Typography sx={styles.MovingServiceRateHeading}>
                {intl.get('TRANSPORTATION_PAGE.ESTIMATE_BASIC_RATE')}
            </Typography>
            {
                basicRateInfo?.orderSubType?.toLowerCase() === "express" &&
                basicRateInfo?.shippingType?.toLowerCase() !== "piece" &&
                <>
                    <Typography sx={styles.MovingServiceRateSubHeading}>
                        Carried by Full Vehicle
                    </Typography>
                    <Grid container spacing={0}>
                        <Grid item md={12} xs={12}>
                            <Typography sx={{fontSize: '14px'}}>
                                {intl.get('TRANSPORTATION_PAGE.SHIPPING_TYPE')}: {(basicRateInfo?.shippingType)}
                            </Typography>
                            <Typography sx={{fontSize: '14px'}}>
                                {/*Max Weight xxxx, occupied by current shipments yyyy, still has zzz (xxx-yyy)  or xyz% left*/}
                                {intl.get('TRANSPORTATION_PAGE.MAX_WEIGHT')}:
                                {(+convertKGToLB((+basicRateInfo?.maxWeightKG)))?.toFixed(2)} lb / {((+(+basicRateInfo?.maxWeightKG))).toFixed(2)} kg,
                                {/*occupied by current shipments {(+basicRateInfo?.boxWeights)?.toFixed(2)} {basicRateInfo?.weightUnit} / {(+convertLBToKG(+basicRateInfo?.boxWeights)).toFixed(2)} kg,*/}
                                &nbsp;still has {(+(+convertKGToLB(basicRateInfo?.maxWeightKG)) - (+basicRateInfo?.boxWeights)).toFixed(2)} lb / {(+basicRateInfo?.maxWeightKG - +convertLBToKG(+basicRateInfo?.boxWeights)).toFixed(2)} kg,
                                or {Math.floor(((+(+convertKGToLB(basicRateInfo?.maxWeightKG)) - (+basicRateInfo?.boxWeights))) / +convertKGToLB(basicRateInfo?.maxWeightKG) * 100)}% left
                            </Typography>
                            <Typography sx={{fontSize: '14px'}}>
                                {/*Max Volume xxxx, occupied by current shipments yyyy, still has zzz (xxx-yyy)  or xyz% left*/}
                                {intl.get('TRANSPORTATION_PAGE.MAX_VOLUME')}:
                                {((+(convertCubicMeterToCubicIn(+basicRateInfo?.maxVolumeInCM / 1_000_000))).toFixed(2))} in{'\u00B3'} / {((+basicRateInfo?.maxVolumeInCM / 1_000_000)).toFixed(2)} m{'\u00B3'},
                                {/*occupied by current shipments {(+basicRateInfo?.boxVolumes)?.toFixed(2)} {basicRateInfo?.dimensionUnit}{'\u00B3'} / {(+convertCubicInToCubicMeter(+basicRateInfo?.boxVolumes)).toFixed(2)} m{'\u00B3'},*/}
                                &nbsp;still has {+(+convertCubicMeterToCubicIn(+basicRateInfo?.maxVolumeInCM / 1_000_000) - +basicRateInfo?.boxVolumes).toFixed(2)} {basicRateInfo?.dimensionUnit}{'\u00B3'} / {+(((+basicRateInfo?.maxVolumeInCM / 1_000_000)) - +convertCubicInToCubicMeter(+basicRateInfo?.boxVolumes)).toFixed(2)} m{'\u00B3'},
                                or {Math.floor(+(+convertCubicMeterToCubicIn(+basicRateInfo?.maxVolumeInCM / 1_000_000) - +basicRateInfo?.boxVolumes) / +convertCubicMeterToCubicIn(+basicRateInfo?.maxVolumeInCM / 1_000_000) * 100)}% left
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider/>
                </>
            }
            {
                basicRateInfo?.itemCharges &&
                <>
                    <Typography sx={styles.MovingServiceRateSubHeading}>
                        Ordinary Item
                    </Typography>
                    {
                        basicRateInfo?.itemCharges?.map((element, index) => {
                                return (
                                    <>
                                        <Grid container spacing={2} key={index}>
                                            <Grid item xs={6}>
                                                <Typography style={{wordWrap: 'break-word', whiteSpace: 'pre-line'}}
                                                            sx={{fontSize: '14px', fontWeight: '600'}}>
                                                    {element?.description}
                                                </Typography>
                                                <Typography sx={{fontSize: '14px'}}>
                                                    Weight: {element.weight} {element.weightUnit}
                                                </Typography>
                                                <Typography sx={{fontSize: '14px'}}>
                                                    Dimension (L W
                                                    H): {element.length} {element.width} {element.height} {element.lengthUnit}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                                    {element.quantity}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography sx={{fontSize: '16px', fontWeight: '600', textAlign: 'right'}}>
                                                    $ {element.totalCharge.toFixed(2)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Divider/>
                                    </>
                                )
                            }
                        )
                    }
                </>
            }
            {
                basicRateInfo?.boxShippingCharge > 0 &&
                <>
                    <Typography sx={styles.MovingServiceRateSubHeading}>
                        Heavy Box
                    </Typography>
                    <Grid container spacing={0}>
                        <Grid item md={12} xs={12}>
                            <Typography style={{wordWrap: 'break-word', whiteSpace: 'pre-line'}}
                                        sx={{fontSize: '14px', fontWeight: '600'}}>
                                {basicRateInfo?.freightCharge?.description}
                            </Typography>
                            <Typography sx={{fontSize: '14px'}}>
                                {intl.get('TRANSPORTATION_PAGE.TOTAL_WEIGHT')}: {(+basicRateInfo?.boxWeights)?.toFixed(2)} {basicRateInfo?.weightUnit} / {(+convertLBToKG(+basicRateInfo?.boxWeights)).toFixed(2)} kg
                            </Typography>
                            <Typography sx={{fontSize: '14px'}}>
                                {intl.get('TRANSPORTATION_PAGE.TOTAL_VOLUME')}: {(+basicRateInfo?.boxVolumes)?.toFixed(2)} {basicRateInfo?.dimensionUnit}{'\u00B3'} / {(+convertCubicInToCubicMeter(+basicRateInfo?.boxVolumes)).toFixed(2)} m{'\u00B3'}
                            </Typography>
                            <Typography sx={{fontSize: '14px'}}>
                                {intl.get('ITEM_LIST.QUANTITY')}: {basicRateInfo?.boxQuantity}
                            </Typography>
                        </Grid>
                        {/*<Grid item xs={4}>*/}
                        {/*    <Typography sx={{fontSize: '16px', fontWeight: '600', textAlign: 'right'}}>*/}
                        {/*        $ {(+basicRateInfo?.shippingChargeDetails?.boxFreightCharge + +basicRateInfo?.shippingChargeDetails?.boxPickupCharge + +basicRateInfo?.shippingChargeDetails?.boxDeliverCharge)?.toFixed(2)}*/}
                        {/*    </Typography>*/}
                        {/*</Grid>*/}
                        {
                            +basicRateInfo?.boxFreightCharge > 0 &&
                            <>
                                <Grid item xs={8}>
                                    <Typography>
                                        {intl.get('COST.FREIGHT_CHARGE')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography style={{textAlign: 'right'}}>
                                        $ {(basicRateInfo?.boxFreightCharge)?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        {
                            +basicRateInfo?.boxPickupCharge > 0 &&
                            <>
                                <Grid item xs={8}>
                                    <Typography>
                                        {intl.get('COST.PICKUP_CHARGE')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography style={{textAlign: 'right'}}>
                                        $ {(basicRateInfo?.boxPickupCharge)?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        {
                            +basicRateInfo?.boxDeliverCharge > 0 &&
                            <>
                                <Grid item xs={8}>
                                    <Typography>
                                        {
                                            movingToAddress?.option === 'self' ? intl.get('COST.OPERATION_CHARGE') : intl.get('COST.DELIVER_CHARGE')
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography style={{textAlign: 'right'}}>
                                        $ {(basicRateInfo?.boxDeliverCharge)?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography>
                                {intl.get('COST.BOX_SHIPPING_CHARGE')}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography style={{textAlign: 'right'}}>
                                $ {(+basicRateInfo?.shippingChargeDetails?.boxFreightCharge + +basicRateInfo?.shippingChargeDetails?.boxPickupCharge + +basicRateInfo?.shippingChargeDetails?.boxDeliverCharge)?.toFixed(2)}
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            }
            {
                basicRateInfo?.specialServicesCharges > 0 &&

                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Typography>
                            {intl.get('COST.ADDITIONAL_SERVICE_CHARGE')}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography style={{textAlign: 'right'}}>
                            $ {(basicRateInfo?.specialServicesCharges)?.toFixed(2)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                </Grid>
            }
            {
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Typography>
                            {intl.get('COST.SUBTOTAL')}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography style={{textAlign: 'right'}}>
                            $ {(basicRateInfo?.totalCharge)?.toFixed(2)}
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography>
                            {intl.get('COST.TAX')}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography style={{textAlign: 'right'}}>
                            $ {(basicRateInfo?.taxCharge)?.toFixed(2)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography>
                            {intl.get('COST.TOTAL')}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography sx={{fontSize: '20px', fontWeight: '600', textAlign: 'right'}}>
                            $ {(basicRateInfo?.totalChargeWithTaxes)?.toFixed(2)}
                        </Typography>
                    </Grid>
                </Grid>
            }
        </Box>
    )
}
