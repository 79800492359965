import {Button, Container, Snackbar} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {useSelector} from "react-redux";
import {MovingServiceRate} from "./MovingServiceRate";
import Divider from "@mui/material/Divider";
import * as React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import {getAccessToken} from "../../Utils/doToken";
import axios from "axios";
import {updateMovingServiceBasicRate} from "../../actions/movingServiceAction";
import {Fragment, useEffect, useState} from "react";
import MuiAlert from "@mui/material/Alert";
import {useHistory, useParams} from "react-router-dom";
import {MovingServiceCreditCard} from "./MovingServiceCreditCard";
import {MovingServiceExpressRate} from "./MovingServiceExpressRate";
import {MovingServiceExpressIncludedServices} from "./MovingServiceExpressIncludedServices";
import intl from "react-intl-universal";
import dayjs from "dayjs";
import {ReactComponent as ShipmentOverviewIcon} from "../../images/NewDesignIcon/ShipmentOverview.svg";
import {ReactComponent as ShippingFromAndToIcon} from "../../images/NewDesignIcon/ShippingLocation.svg";
import {ReactComponent as PackageDetailIcon} from "../../images/NewDesignIcon/Package.svg";
import {ReactComponent as SchedulePickupIcon} from "../../images/NewDesignIcon/SchedulePickup.svg";
import {ReactComponent as RateDetailsIcon} from "../../images/NewDesignIcon/RateDetails.svg";
import {ReactComponent as ContactIcon} from "../../images/NewDesignIcon/AddressBook.svg";
import {ReactComponent as PackageTypeIcon} from "../../images/NewDesignIcon/AdditionalDetails.svg";
import {ReactComponent as PaidRuleIcon} from "../../images/NewDesignIcon/ChooseCarrierAndServices.svg";
import {ReactComponent as MapIcon} from "../../images/earth.svg";
import {ReactComponent as NoteIcon} from "../../images/NewDesignIcon/OtherInfo.svg";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import ImageIcon from '@mui/icons-material/Image';
import {
    convertCubicInToCubicMeter,
    convertCubicMeterToCubicIn,
    convertKGToLB,
    convertLBToKG,
    formatCurrency
} from "../../Utils/Helper";
import {selectCreateLabelShippingPackageRate} from "../../actions/shippingPackageAction";
import Carousel from "react-material-ui-carousel";
import Paper from "@mui/material/Paper";
import MovingServiceMapOverview from "./MovingServiceMapOverview";
import MovingServiceProgressBar from "./MovingServiceProgressBar";
import MovingItemTitle from "../../images/icons/movingItemTitle.png";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    MovingServiceSummaryRoot: {
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        marginBottom: '40px',
    },
    MovingServiceSummaryButtonSection: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

    },
    MovingServiceReviewRoot: {
        backgroundColor: '#FFFFFF',
        paddingBottom: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0'
    },
    MovingServiceReviewSubHeading: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'left'
    },
    MovingServiceReviewCard: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '10px',
        display: 'flex',
        flexDirection: 'column',
    },
    MovingServiceReviewCardThreeCol: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '10px',
        display: 'flex',
        flexDirection: 'column',
    },
    MovingServiceReviewHeading: {
        fontSize: '20px',
        fontWeight: '600'
    },
    MovingServiceReviewButtonSection: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    MovingServiceReviewButton: {
        // width: '100%',
        // height: '100%',
        color: '#FFFFFF',
        fontSize: '16px',
        fontWeight: '600',
        padding: '10px 30px',
        borderRadius: '10px'
    },
    MovingServiceReviewImage: {
        objectFit: 'contain',
        width: '200px',
        height: '200px'
    },
    MovingServiceReviewImageBox: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        gap: '15px',
    },
    MovingServiceReviewImageCard: {
        borderRadius: '10px',
        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.5)',
        padding: '10px',
    },
    MovingServiceReviewImageSection: {
        display: 'flex',
        flexDirection: 'column',
        width: '220px',
        position: 'relative'
    },
    MovingServiceReviewButtonStartOver: {
        display: 'flex',
    },
    MovingServiceAdditionalFeeSectionHeadingCost: {
        fontWeight: '600',
        textAlign: 'right'
    },
    ShippingPackageCreateLabelOrderDetailsTextLeft: {
        fontSize: '16px',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsTextRight: {
        fontSize: '16px',
        textAlign: 'right'
    },
}

export const MovingServiceReview = () => {

    const {tab} = useParams();
    const history = useHistory();

    const ownerRole = useSelector(state => state?.user?.ownerRole);

    let movingRemarkNotes = useSelector(state => state?.movingService?.movingRemarkNotes);
    let movingFromAddress = useSelector(state => state?.movingService?.movingFromAddress);
    let movingToAddress = useSelector(state => state?.movingService?.movingToAddress);
    let basicRateInfo = useSelector(state => state?.movingService?.movingServiceBasicRateInfo);
    let contactInfo = useSelector(state => state?.movingService?.movingServiceSummaryContactInfo);
    let moveDate = useSelector(state => state?.movingService?.movingDate);
    let moveTime = useSelector(state => state?.movingService?.movingTime);
    let moveDeliverDate = useSelector(state => state?.movingService?.deliverDate);
    let moveDeliverTime = useSelector(state => state?.movingService?.deliverTime);
    let deliverDate = useSelector(state => state?.movingService?.deliverDate);
    let deliverTime = useSelector(state => state?.movingService?.deliverTime);
    let additionalService = useSelector(state => state?.movingService?.additionalService);
    let movingImage = useSelector(state => state?.movingService?.movingImage);
    let itemList = useSelector(state => state?.movingService?.itemList);
    let movingFurnitureList = useSelector(state => state?.movingService?.movingFurnitureList);
    // let movingSelectAccountBase = useSelector(state => state?.movingService?.movingSelectAccountBase);

    let movingFromCarAddress = useSelector(state => state?.movingService?.movingFromCarAddress);
    let movingToCarAddress = useSelector(state => state?.movingService?.movingToCarAddress);
    let carList = useSelector(state => state?.movingService?.carList);

    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [creditCard, setCreditCard] = useState(null);
    const [confirmationProceed, setConfirmationProceed] = useState(false);
    const [additionalServiceFee, setAdditionalServiceFee] = useState();

    const getAdditionServiceFee = async () => {
        let requestURL = `${NODE_ROUTE_URI}/movingService/getMovingCategoryAreaPriceByCategoriesAndAreas`;

        const cateIdArray = [];

        itemList?.forEach(item => {
            cateIdArray.push(item?.cate_id);
        })

        const data = {
            cateIdArray,
            pickupCity: movingFromAddress?.city,
            pickupProvince: movingFromAddress?.province?.code,
            deliverCity: movingToAddress?.city,
            deliverProvince: movingToAddress?.province?.code
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })

            console.log('addition service', result);
            setAdditionalServiceFee(result.data);
        } catch (e) {
            console.log(e.response);
        }
    }

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const cbGetCreditCard = (cardId) => {
        setCreditCard(cardId);
    }

    const handleError = (errorCode) => {
        switch (errorCode) {
            case 400:
                setErrorMessage("Bad Request: The server cannot process the request.");
                break;
            case 401:
                setErrorMessage("Unauthorized: Authentication is required to access the resource.");
                break;
            case 404:
                setErrorMessage("Not Found: The requested resource could not be found.");
                break;
            case 500:
                setErrorMessage("Internal Server Error: The server encountered an unexpected condition.");
                break;
            default:
                setErrorMessage("Unknown Error: An unknown error occurred.");
                break;
        }
    }

    const initBackendOrder = async () => {
        setLoading(true);
        const accessToken = getAccessToken("access_token");
        // let requestURL = `${NODE_ROUTE_URI}/movingService/initMovingOrder`;
        let requestURL = tab === 'item' ? `${NODE_ROUTE_URI}/movingService/initMovingOrderAndPayDeposit` :
            ownerRole === 1 ? `${NODE_ROUTE_URI}/movingExpressService/initMovingExpressOrder` :
                `${PARTNER_URI}/movingExpressService/initMovingExpressOrder`;

        const now = new Date();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const currentTime = `$${hours}:${minutes}`;
        const scheduledTime = moveTime?.movingTimeFrom || '00:00';

        const updatedItemList = itemList?.map(item => ({
            description: item.description,
            cateId: item.cate_id,
            length: item.length,
            width: item.width,
            height: item.height,
            weight: item.weight,
            volume: item.volume,
            lengthUnit: item.lengthUnit,
            weightUnit: item.weightUnit,
            quantity: item.quantity,
            packageSpecialServices: item.packageSpecialServices
        }));

        const updatedFurnitureList = movingFurnitureList?.map(item => ({
            description: item.category,
            cateId: item.cate_id,
            length: item.length || 0,
            width: item.width || 0,
            height: item.height || 0,
            weight: item.weight || 0,
            volume: item.volume || 0,
            lengthUnit: item?.lengthUnit,
            weightUnit: item?.weightUnit,
            quantity: item.quantity,
            packageSpecialServices: item.packageSpecialServices
        }));

        const additionalServiceExpress = {
            storageDailyService: true
        }
        const items = [...updatedItemList, ...updatedFurnitureList];

        const messages = {
            additionalService: tab === 'item' ? additionalService : additionalServiceExpress,
            movingFromAddress: {
                ...movingFromAddress
            },
            movingToAddress: {
                ...movingToAddress
            },
            movingRemarkNotes
        }

        const data = {
            items: items,
            senderName: contactInfo.pickupName,
            senderEmail: contactInfo.pickupEmail,
            senderPhone: contactInfo.pickupPhone.replace(/\D/g, ""),
            deliverName: contactInfo.deliverName,
            deliverEmail: contactInfo.deliverEmail,
            deliverPhone: contactInfo.deliverPhone.replace(/\D/g, ""),
            pickupWarehouseId: movingFromAddress?.warehouse?.id || 0,
            pickupAddress: movingFromAddress?.streetAddress ? (movingFromAddress?.apt ? `${movingFromAddress?.apt} - ${movingFromAddress?.streetAddress}` : movingFromAddress?.streetAddress) : movingFromAddress?.warehouse?.address,
            pickupCity: movingFromAddress?.city,
            pickupProvince: movingFromAddress?.province?.code,
            pickupCountry: movingFromAddress?.province?.countryCode,
            pickupPostalCode: movingFromAddress?.postalCode,
            deliverWarehouseId: movingToAddress?.warehouse?.id || 0,
            deliverAddress: movingToAddress?.streetAddress ? (movingToAddress?.apt ? `${movingToAddress?.apt} - ${movingToAddress?.streetAddress}` : movingToAddress?.streetAddress) : movingToAddress?.warehouse?.address,
            deliverCity: movingToAddress?.city,
            deliverProvince: movingToAddress?.province?.code,
            deliverCountry: movingToAddress?.province?.countryCode,
            deliverPostalCode: movingToAddress?.postalCode,
            imageUrls: movingImage,
            scheduledPickupDate: `${dayjs(moveDate).format('YYYY-MM-DD')} ${moveTime?.movingTimeFrom}`,
            messages: JSON.stringify(messages),
            specialServices: [],
            accountBase: basicRateInfo?.accountBase || "",
            pickupHouseFeatures: {
                houseType: movingFromAddress?.propertyType?.houseType === 'Apartment' ? 'Condo' : movingFromAddress?.propertyType?.houseType,
                floorLevel: movingFromAddress?.floorLevel,
                staircaseNumber: "0",
                isNarrowStreet: movingFromAddress?.isNarrowStreet,
                drivewaySteepLevel: movingFromAddress?.drivewaySteepLevel,
                hasLift: !!movingFromAddress?.lift
            },
            deliverHouseFeatures: {
                houseType: movingToAddress?.propertyType?.houseType === 'Apartment' ? 'Condo' : movingToAddress?.propertyType?.houseType,
                floorLevel: movingToAddress?.floorLevel,
                staircaseNumber: "0",
                isNarrowStreet: movingToAddress?.isNarrowStreet,
                drivewaySteepLevel: movingToAddress?.drivewaySteepLevel,
                hasLift: !!movingToAddress?.lift
            }
        }

        try {
            const result = await axios({
                method: 'post',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                url: requestURL,
                data: data,
            })
            // console.log(result.data);
            // setCategoryList(result.data);
            console.log('place order', result);
            setErrorMessage('');
            handleSuccessMessage();
            setSuccessMessage('Successfully place order.')
            setLoading(false);
            setDisabled(true);

            if (ownerRole === 1) {
                setTimeout(() => {
                    history.push(`/transportation/orderDetails/${result.data.id}`);
                }, 1000);
            } else {
                setTimeout(() => {
                    history.push(`/business/transportation/orderDashboard/${result.data.id}`);
                }, 1000);
            }
        } catch (e) {
            console.log(e.response);
            const errorCode = e?.response?.data?.error?.status;
            console.log('error code', errorCode);
            handleErrorMessage();
            handleError(errorCode);
            setLoading(false);
        }
    }

    const handleBeforeUnload = (event) => {

        console.log('reload');
        // Customize the confirmation message
        const message = 'Are you sure you want to leave? Your unsaved changes may be lost.';

        // Set the confirmation message for modern browsers
        event.returnValue = message;

        // For older browsers
        return message;
    };

    const handleContinueProceed = () => {
        setConfirmationProceed(true);
    }

    const handleCancelProceed = () => {
        setConfirmationProceed(false);
    }

    const handleStartOver = () => {
        history.push(`${ownerRole === 1 ? '/' : '/business/'}transportation/${tab}`);
    }

    const handleEditList = () => {
        if (tab === 'item') {
            history.push(`${ownerRole === 1 ? '/' : '/business/'}transportation/${tab}/list`);
        } else {
            history.push(`${ownerRole === 1 ? '/' : '/business/'}transportation/${tab}`);
        }
    }

    const handleBack = () => {
        if (tab === 'item') {
            history.push('/transportation/item/other');
        } else {
            history.push(`${ownerRole === 1 ? '/' : '/business/'}transportation/${tab}/rate`);
        }
    }

    const handlePlaceOrder = () => {
        initBackendOrder();
        // history.push(`/transportation/orderDetails/${order}`)
        // history.push(`/transportation/orderDetails/2860`)
    }

    useEffect(() => {

        getAdditionServiceFee();

        // Add the event listener when the component mounts
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []); // Empty dependency array means this effect runs once when the component mounts

    console.log('[MovingServiceSummary] basicRateInfo', basicRateInfo);

    if ((tab === 'item' || tab === 'express') && !basicRateInfo) { //todo: revise after able to get express rate
        return (
            <Container maxWidth='xl'>
                <Box sx={{
                    backgroundColor: '#FFFFFF',
                    padding: '20px',
                    marginBottom: '40px',
                    borderRadius: '10px',
                    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                    gap: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant='h4' sx={{textAlign: 'center'}}>
                                Transportation Service Review
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{color: 'red', textAlign: 'left', fontSize: '16px'}}>
                                You've lost all the data. Please return to the home page and create a new
                                transportation.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{display: 'flex', width: '100%', justifyContent: 'flex-end', gap: '20px'}}>
                                <Button
                                    variant='contained'
                                    onClick={() => history.push(`${ownerRole === 1 ? '/' : '/business/'}transportation/${tab}`)}
                                >
                                    <Typography style={{textTransform: 'none'}}>
                                        Go Back Home
                                    </Typography>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        )
    }

    console.log('[MovingServiceReview] creditCard', creditCard);
    console.log('[MovingServiceReview] itemList', itemList);
    console.log('[MovingServiceReview] moving date', moveDate);
    console.log('[MovingServiceReview] moving time', moveTime);
    console.log('[MovingServiceReview] moving from', movingFromAddress);
    console.log('[MovingServiceReview] moving to', movingToAddress);
    console.log('[MovingServiceReview] additional service', additionalService);

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box sx={styles.MovingServiceSummaryRoot}>
                <Box display="flex" alignItems="center" p={2}>
                    <img src={MovingItemTitle} alt="Moving items"
                         style={{width: '150px', height: 'auto', marginRight: '16px'}}/>
                    <Box>
                        <Typography variant="h5" component="div" fontWeight="bold">
                            {tab === 'item' ? 'Local & Long-distance Moving' : 'Box Moving Service'}
                        </Typography>
                        <Typography variant="body1">
                            {tab === 'item' ? "Move your residence locally or long-distance, whether it's a small apartment or a large house." : "Limited to Greater Vancouver, Toronto, Calgary, and Montreal Areas"}
                        < /Typography>
                    </Box>
                </Box>
                <MovingServiceProgressBar progress={100}/>
                <Box sx={styles.MovingServiceReviewRoot}>
                    <Box sx={{
                        display: 'flex',
                        gap: '10px',
                        padding: '20px 20px 0 20px'
                    }}>
                        <ShipmentOverviewIcon width={25} height={25}/>
                        <Typography sx={styles.MovingServiceReviewSubHeading}>
                            {intl.get('TRANSPORTATION_PAGE.TRANSPORTATION_SERVICE_REVIEW')}
                        </Typography>
                    </Box>
                    <Divider/>
                    <Box sx={{
                        backgroundColor: '#FFFFFF',
                        padding: '0 40px',
                        gap: '40px',
                        display: 'flex',
                    }}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={6}>
                                <Box sx={{display: 'flex', width: '100%', flexDirection: 'column', gap: '60px'}}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={1}>
                                            <ShippingFromAndToIcon width={20} height={20}/>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Typography sx={{fontWeight: '600'}}>
                                                Moving From
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Button
                                                onClick={handleStartOver}
                                                sx={{padding: 0, justifyContent: 'right', width: '100%'}}>
                                                <Typography sx={{color: '#1D8B45', textTransform: 'none'}}>
                                                    Edit
                                                </Typography>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider/>
                                        </Grid>
                                        {
                                            (tab === 'item' || tab === 'express') ?
                                                <Grid item xs={12} md={movingFromAddress?.option === 'self' ? 12 : 6}>
                                                    <Typography>
                                                        {movingFromAddress?.option === 'self' ? intl.get('MOVING.SELF_DROP_OFF') : intl.get('MOVING.SCHEDULED_PICKUP')}
                                                    </Typography>
                                                    <Typography>
                                                        {movingFromAddress?.warehouse?.name}
                                                    </Typography>
                                                    <Typography>
                                                        {movingFromAddress?.warehouse?.address}
                                                    </Typography>
                                                    <Typography>
                                                        {movingFromAddress?.apt ? `${movingFromAddress?.apt} - ${movingFromAddress?.streetAddress}` : movingFromAddress?.streetAddress}
                                                    </Typography>
                                                    <Typography>
                                                        {movingFromAddress?.city}, {movingFromAddress?.province?.code}
                                                    </Typography>
                                                    <Typography>
                                                        {movingFromAddress?.country}, {movingFromAddress?.postalCode}
                                                    </Typography>
                                                </Grid>
                                                :
                                                <Grid item xs={12} md={12}>
                                                    <Typography>
                                                        City: {movingFromCarAddress?.city}
                                                    </Typography>
                                                    <Typography>
                                                        Province: {movingFromCarAddress?.province?.code}
                                                    </Typography>
                                                </Grid>
                                        }
                                        {
                                            (() => {
                                                if (movingFromAddress?.option !== 'self') {
                                                    if (tab === "express") {
                                                        return (
                                                            <Grid item xs={12} md={6}>
                                                                <Typography>
                                                                    Property
                                                                    Type: {movingFromAddress?.propertyType?.propertyType}
                                                                </Typography>
                                                                <Typography>
                                                                    House
                                                                    Type: {movingFromAddress?.propertyType?.houseType}
                                                                </Typography>
                                                                <Typography>
                                                                    Lift: {movingFromAddress?.lift ? 'Yes' : 'No'}
                                                                </Typography>
                                                                {/*<Typography>*/}
                                                                {/*    Storey: {movingFromAddress?.storey}*/}
                                                                {/*</Typography>*/}
                                                            </Grid>
                                                        )
                                                    } else {
                                                        return (
                                                            <Grid item xs={12} md={6}>
                                                                <Typography>
                                                                    Property
                                                                    Type: {movingFromAddress?.propertyType?.houseType}
                                                                </Typography>
                                                                <Typography>
                                                                    Floor Level: {movingFromAddress?.floorLevel}
                                                                </Typography>
                                                                <Typography>
                                                                    Lift: {movingFromAddress?.lift ? 'Yes' : 'No'}
                                                                </Typography>
                                                                {/*<Typography>*/}
                                                                {/*    Distance from Truck to*/}
                                                                {/*    Door: {movingFromAddress?.distanceFromTruckToDoor}*/}
                                                                {/*</Typography>*/}
                                                                {/*<Typography>*/}
                                                                {/*    Staircase*/}
                                                                {/*    Number: {movingFromAddress?.staircaseNumber}*/}
                                                                {/*</Typography>*/}
                                                                {/*<Typography>*/}
                                                                {/*    Narrow*/}
                                                                {/*    Street: {movingFromAddress?.isNarrowStreet ? "YES" : "NO"}*/}
                                                                {/*</Typography>*/}
                                                                {/*<Typography>*/}
                                                                {/*    Steep*/}
                                                                {/*    Driveway: {movingFromAddress?.isSteepDriveway ? "YES" : "NO"}*/}
                                                                {/*</Typography>*/}
                                                            </Grid>
                                                        )
                                                    }
                                                }
                                            })()
                                        }
                                        {/*{movingFromAddress?.option !== 'self' && tab === 'express' &&*/}
                                        {/*    <Grid item xs={12} md={6}>*/}
                                        {/*        <Typography>*/}
                                        {/*            Property Type: {movingFromAddress?.propertyType?.propertyType}*/}
                                        {/*        </Typography>*/}
                                        {/*        <Typography>*/}
                                        {/*            House Type: {movingFromAddress?.propertyType?.houseType}*/}
                                        {/*        </Typography>*/}
                                        {/*        <Typography>*/}
                                        {/*            Lift: {movingFromAddress?.lift}*/}
                                        {/*        </Typography>*/}
                                        {/*        <Typography>*/}
                                        {/*            Storey: {movingFromAddress?.storey}*/}
                                        {/*        </Typography>*/}
                                        {/*    </Grid>*/}
                                        {/*}*/}
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Grid item xs={1}>
                                            <ShippingFromAndToIcon width={20} height={20}/>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Typography sx={{fontWeight: '600'}}>
                                                Moving To
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Button
                                                onClick={handleStartOver}
                                                sx={{padding: 0, justifyContent: 'right', width: '100%'}}>
                                                <Typography sx={{color: '#1D8B45', textTransform: 'none'}}>
                                                    Edit
                                                </Typography>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider/>
                                        </Grid>
                                        {tab === 'item' || tab === 'express' ?
                                            <Grid item xs={12} md={movingToAddress?.option === 'self' ? 12 : 6}>
                                                <Typography>
                                                    {movingToAddress?.option === 'self' ? intl.get('MOVING.SELF_PICKUP') : intl.get('MOVING.SCHEDULED_DELIVER')}
                                                </Typography>
                                                <Typography>
                                                    {movingToAddress?.warehouse?.name}
                                                </Typography>
                                                <Typography>
                                                    {movingToAddress?.warehouse?.address}
                                                </Typography>
                                                <Typography>
                                                    {movingToAddress?.apt ? `${movingToAddress?.apt} - ${movingToAddress?.streetAddress}` : movingToAddress?.streetAddress}
                                                </Typography>
                                                <Typography>
                                                    {movingToAddress?.city}, {movingToAddress?.province?.code}
                                                </Typography>
                                                <Typography>
                                                    {movingToAddress?.country}, {movingToAddress?.postalCode}
                                                </Typography>
                                            </Grid>
                                            :
                                            <Grid item xs={12} md={6}>
                                                <Typography>
                                                    City: {movingToCarAddress?.city}
                                                </Typography>
                                                <Typography>
                                                    Province: {movingToCarAddress?.province?.code}
                                                </Typography>
                                            </Grid>
                                        }
                                        {
                                            (() => {
                                                if (movingToAddress?.option !== 'self') {
                                                    if (tab === "express") {
                                                        return (
                                                            <Grid item xs={12} md={6}>
                                                                <Typography>
                                                                    Property
                                                                    Type: {movingToAddress?.propertyType?.propertyType}
                                                                </Typography>
                                                                <Typography>
                                                                    House
                                                                    Type: {movingToAddress?.propertyType?.houseType}
                                                                </Typography>
                                                                <Typography>
                                                                    Lift: {movingToAddress?.lift ? 'Yes' : 'No'}
                                                                </Typography>
                                                                {/*<Typography>*/}
                                                                {/*    Storey: {movingToAddress?.storey}*/}
                                                                {/*</Typography>*/}
                                                            </Grid>
                                                        )
                                                    } else {
                                                        return (
                                                            <Grid item xs={12} md={6}>
                                                                <Typography>
                                                                    Property
                                                                    Type: {movingToAddress?.propertyType?.houseType}
                                                                </Typography>
                                                                <Typography>
                                                                    Floor Level: {movingToAddress?.floorLevel}
                                                                </Typography>
                                                                <Typography>
                                                                    Lift: {movingToAddress?.lift ? 'Yes' : 'No'}
                                                                </Typography>
                                                                {/*<Typography>*/}
                                                                {/*    Distance from Truck to*/}
                                                                {/*    Door: {movingToAddress?.distanceFromTruckToDoor}*/}
                                                                {/*</Typography>*/}
                                                                {/*<Typography>*/}
                                                                {/*    Staircase Number: {movingToAddress?.staircaseNumber}*/}
                                                                {/*</Typography>*/}
                                                                {/*<Typography>*/}
                                                                {/*    Narrow*/}
                                                                {/*    Street: {movingToAddress?.isNarrowStreet ? "YES" : "NO"}*/}
                                                                {/*</Typography>*/}
                                                                {/*<Typography>*/}
                                                                {/*    Steep*/}
                                                                {/*    Driveway: {movingToAddress?.isSteepDriveway ? "YES" : "NO"}*/}
                                                                {/*</Typography>*/}
                                                            </Grid>
                                                        )
                                                    }
                                                }
                                            })()
                                        }
                                        {/*{movingToAddress?.option !== 'self' &&*/}
                                        {/*    <Grid item xs={12} md={6}>*/}
                                        {/*        <Typography>*/}
                                        {/*            Property Type: {movingToAddress?.propertyType?.propertyType}*/}
                                        {/*        </Typography>*/}
                                        {/*        <Typography>*/}
                                        {/*            House Type: {movingToAddress?.propertyType?.houseType}*/}
                                        {/*        </Typography>*/}
                                        {/*        <Typography>*/}
                                        {/*            Lift: {movingToAddress?.lift}*/}
                                        {/*        </Typography>*/}
                                        {/*        <Typography>*/}
                                        {/*            Storey: {movingToAddress?.storey}*/}
                                        {/*        </Typography>*/}
                                        {/*    </Grid>*/}
                                        {/*}*/}
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Grid item xs={1}>
                                            <ContactIcon width={20} height={20}/>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Typography sx={{fontWeight: '600'}}>
                                                {intl.get('CONTACT.PICKUP')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Button
                                                onClick={handleBack}
                                                sx={{padding: 0, justifyContent: 'right', width: '100%'}}>
                                                <Typography sx={{color: '#1D8B45', textTransform: 'none'}}>
                                                    Edit
                                                </Typography>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>
                                                {contactInfo.pickupName}
                                            </Typography>
                                            <Typography>
                                                {contactInfo.pickupPhone}
                                            </Typography>
                                            <Typography>
                                                {contactInfo.pickupEmail}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Grid item xs={1}>
                                            <ContactIcon width={20} height={20}/>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Typography sx={{fontWeight: '600'}}>
                                                {intl.get('CONTACT.DELIVER')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Button
                                                onClick={handleBack}
                                                sx={{padding: 0, justifyContent: 'right', width: '100%'}}>
                                                <Typography sx={{color: '#1D8B45', textTransform: 'none'}}>
                                                    Edit
                                                </Typography>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>
                                                {contactInfo.deliverName}
                                            </Typography>
                                            <Typography>
                                                {contactInfo.deliverPhone}
                                            </Typography>
                                            <Typography>
                                                {contactInfo.deliverEmail}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    {
                                        movingImage?.length > 0 &&
                                        <Grid container spacing={1}>
                                            <Grid item xs={1}>
                                                <ImageIcon width={20} height={20}/>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Typography sx={{fontWeight: '600'}}>
                                                    {intl.get('IMAGE.MOVING_IMAGE')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Button
                                                    onClick={handleEditList}
                                                    sx={{padding: 0, justifyContent: 'right', width: '100%'}}>
                                                    <Typography sx={{color: '#1D8B45', textTransform: 'none'}}>
                                                        Edit
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Carousel
                                                    navButtonsAlwaysVisible={true}
                                                    // indicators={false}
                                                    fullHeightHover={false}     // We want the nav buttons wrapper to only be as big as the button element is
                                                    NextIcon={<NavigateNextIcon/>}
                                                    PrevIcon={<NavigateBeforeIcon/>}
                                                    autoPlay={false}
                                                >
                                                    {
                                                        movingImage?.map((image, index) => (
                                                            <Paper key={index} sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                height: 300
                                                            }}>
                                                                <img src={`https://storage.googleapis.com/${image}`}
                                                                     alt={`image${index}`}
                                                                     style={{
                                                                         maxWidth: '100%',
                                                                         maxHeight: '100%',
                                                                         objectFit: 'contain',
                                                                         display: 'block'
                                                                     }}
                                                                />
                                                            </Paper>
                                                        ))
                                                    }
                                                </Carousel>
                                            </Grid>
                                        </Grid>
                                    }
                                    {
                                        movingRemarkNotes &&
                                        <Grid container spacing={1}>
                                            <Grid item xs={1}>
                                                <NoteIcon width={20} height={20}/>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Typography sx={{fontWeight: '600'}}>
                                                    Remark Notes
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Button
                                                    onClick={handleEditList}
                                                    sx={{padding: 0, justifyContent: 'right', width: '100%'}}>
                                                    <Typography sx={{color: '#1D8B45', textTransform: 'none'}}>
                                                        Edit
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography>
                                                    {movingRemarkNotes}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{display: 'flex', width: '100%', flexDirection: 'column', gap: '60px'}}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={1}>
                                            <SchedulePickupIcon width={20} height={20}/>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Typography sx={{fontWeight: '600'}}>
                                                {intl.get('DATE.MOVING_DATE_AND_TIME')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Button
                                                onClick={handleBack}
                                                sx={{padding: 0, justifyContent: 'right', width: '100%'}}>
                                                <Typography sx={{color: '#1D8B45', textTransform: 'none'}}>
                                                    Edit
                                                </Typography>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>
                                                {movingFromAddress?.option === 'self' ? intl.get('DATE.SELF_DROP_OFF_DATE') : intl.get('DATE.SCHEDULED_PICKUP_DATE')}: {dayjs(moveDate)?.format('MMMM D, YYYY')}
                                            </Typography>
                                            <Typography>
                                                {movingFromAddress?.option === 'self' ? intl.get('DATE.SELF_DROP_OFF_TIME') : intl.get('DATE.SCHEDULED_PICKUP_TIME')}: {dayjs(`2024-01-01${moveTime?.movingTimeFrom}`).format('h:mm a') || 'N/A'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Grid item xs={1}>
                                            <PackageDetailIcon width={20} height={20}/>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Typography sx={{fontWeight: '600'}}>
                                                {tab === 'item' ? 'Item Details' : 'Box Details'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Button
                                                onClick={handleEditList}
                                                sx={{padding: 0, justifyContent: 'right', width: '100%'}}>
                                                <Typography sx={{color: '#1D8B45', textTransform: 'none'}}>
                                                    Edit
                                                </Typography>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider/>
                                        </Grid>
                                        {
                                            movingFurnitureList.length > 0 && movingFurnitureList?.map((each, index) => (
                                                <Fragment key={index}>
                                                    <Grid item xs={2}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                            {`#${index + 1}`}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                            {/*{each?.length} x {each?.width} x {each?.height} {each?.lengthUnit}*/}
                                                            {each?.category}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                            {/*{each?.weight} {each?.weightUnit}*/}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                            x {each?.quantity}
                                                        </Typography>
                                                    </Grid>
                                                </Fragment>
                                            ))
                                        }
                                        {/*{*/}
                                        {/*    itemList.length > 0 && itemList?.map((each, index) => (*/}
                                        {/*        <Fragment key={index}>*/}
                                        {/*            <Grid item xs={2}>*/}
                                        {/*                <Typography*/}
                                        {/*                    style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>*/}
                                        {/*                    {`#${index + 1 + movingFurnitureList?.length}`}*/}
                                        {/*                </Typography>*/}
                                        {/*            </Grid>*/}
                                        {/*            <Grid item xs={6}>*/}
                                        {/*                <Typography*/}
                                        {/*                    style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>*/}
                                        {/*                    {each?.length} x {each?.width} x {each?.height} {each?.lengthUnit}*/}
                                        {/*                </Typography>*/}
                                        {/*            </Grid>*/}
                                        {/*            <Grid item xs={2}>*/}
                                        {/*                <Typography*/}
                                        {/*                    style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>*/}
                                        {/*                    {each?.weight} {each?.weightUnit}*/}
                                        {/*                </Typography>*/}
                                        {/*            </Grid>*/}
                                        {/*            <Grid item xs={2}>*/}
                                        {/*                <Typography*/}
                                        {/*                    style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>*/}
                                        {/*                    x {each?.quantity}*/}
                                        {/*                </Typography>*/}
                                        {/*            </Grid>*/}
                                        {/*        </Fragment>*/}
                                        {/*    ))*/}
                                        {/*}*/}
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Grid item xs={1}>
                                            <MapIcon width={20} height={20}/>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Typography sx={{fontWeight: '600'}}>
                                                Map Overview
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <MovingServiceMapOverview
                                                apiKey={"AIzaSyDxbjdueUQWI1WFfiEviIkvspt8S95L-bQ"}
                                                shipFrom={`${movingFromAddress?.streetAddress ? (movingFromAddress?.apt ? `${movingFromAddress?.apt} - ${movingFromAddress?.streetAddress}` : movingFromAddress?.streetAddress) : movingFromAddress?.warehouse?.address}, ${movingFromAddress?.city}, ${movingFromAddress?.province?.code}, ${movingFromAddress?.country}, ${movingFromAddress?.postalCode}`}
                                                shipTo={`${movingToAddress?.streetAddress ? (movingToAddress?.apt ? `${movingToAddress?.apt} - ${movingToAddress?.streetAddress}` : movingToAddress?.streetAddress) : movingToAddress?.warehouse?.address}, ${movingToAddress?.city}, ${movingToAddress?.province?.code}, ${movingToAddress?.country}, ${movingToAddress?.postalCode}`}
                                            />
                                        </Grid>
                                    </Grid>
                                    {/*{*/}
                                    {/*    tab === "item" && (*/}
                                    {/*        <>*/}
                                    {/*            <Grid container spacing={1}>*/}
                                    {/*                <Grid item xs={1}>*/}
                                    {/*                    <PackageTypeIcon width={20} height={20}/>*/}
                                    {/*                </Grid>*/}
                                    {/*                <Grid item xs={11}>*/}
                                    {/*                    <Typography sx={{fontWeight: '600'}}>*/}
                                    {/*                        {tab === 'item' ? 'Additional Services' : 'Included Services'}*/}
                                    {/*                    </Typography>*/}
                                    {/*                </Grid>*/}
                                    {/*                /!*<Grid item xs={2}>*!/*/}
                                    {/*                /!*    <Button*!/*/}
                                    {/*                /!*        // onClick={handleBackToShippingInfo}*!/*/}
                                    {/*                /!*        sx={{padding: 0, justifyContent: 'right', width: '100%'}}>*!/*/}
                                    {/*                /!*        <Typography sx={{color: '#1D8B45', textTransform: 'none'}}>*!/*/}
                                    {/*                /!*            Edit*!/*/}
                                    {/*                /!*        </Typography>*!/*/}
                                    {/*                /!*    </Button>*!/*/}
                                    {/*                /!*</Grid>*!/*/}
                                    {/*                <Grid item xs={12}>*/}
                                    {/*                    <Divider/>*/}
                                    {/*                </Grid>*/}
                                    {/*                {*/}
                                    {/*                    tab === 'item' ?*/}
                                    {/*                        <Grid item md={12} xs={12}>*/}
                                    {/*                            <Typography>*/}
                                    {/*                                Scheduled Delivery*/}
                                    {/*                                Service: {additionalService.scheduleDelivery ? 'Yes' : 'No'}*/}
                                    {/*                            </Typography>*/}
                                    {/*                            <Typography>*/}
                                    {/*                                White Glove*/}
                                    {/*                                Service: {additionalService.whiteGlove ? 'Yes' : 'No'}*/}
                                    {/*                            </Typography>*/}
                                    {/*                            <Typography>*/}
                                    {/*                                Packing*/}
                                    {/*                                Service: {additionalService.packingService ? 'Yes' : 'No'}*/}
                                    {/*                            </Typography>*/}
                                    {/*                            <Typography>*/}
                                    {/*                                Wrapping*/}
                                    {/*                                Service: {additionalService.wrappingService ? 'Yes' : 'No'}*/}
                                    {/*                            </Typography>*/}
                                    {/*                            <Typography>*/}
                                    {/*                                Assembly*/}
                                    {/*                                Service: {additionalService.assemblyService ? 'Yes' : 'No'}*/}
                                    {/*                            </Typography>*/}
                                    {/*                            <Typography>*/}
                                    {/*                                Disassembly*/}
                                    {/*                                Service: {additionalService.disassemblyService ? 'Yes' : 'No'}*/}
                                    {/*                            </Typography>*/}
                                    {/*                            {*/}
                                    {/*                                additionalService.storageService.storageDays > 0 && (*/}
                                    {/*                                    <>*/}
                                    {/*                                        <Typography>*/}
                                    {/*                                            Storage Service*/}
                                    {/*                                            Storage Days: {additionalService.storageService.storageDays} day(s), Storage Space: {additionalService.storageService.cubicMeterSpace} m³.*/}
                                    {/*                                        </Typography>*/}
                                    {/*                                    </>*/}
                                    {/*                                )*/}
                                    {/*                            }*/}
                                    {/*                        </Grid> :*/}
                                    {/*                        <Grid item md={12} xs={12}>*/}
                                    {/*                            <Typography>*/}
                                    {/*                                {intl.get('ADDITIONAL_SERVICE.STORAGE_SERVICE')}*/}
                                    {/*                            </Typography>*/}
                                    {/*                        </Grid>*/}
                                    {/*                }*/}
                                    {/*            </Grid>*/}
                                    {/*            {*/}
                                    {/*                tab === 'express' &&*/}
                                    {/*                <Grid container spacing={1}>*/}
                                    {/*                    <Grid item xs={1}>*/}
                                    {/*                        <PaidRuleIcon width={20} height={20}/>*/}
                                    {/*                    </Grid>*/}
                                    {/*                    <Grid item xs={11}>*/}
                                    {/*                        <Typography sx={{fontWeight: '600'}}>*/}
                                    {/*                            {intl.get('COST.FEE_RULE')}*/}
                                    {/*                        </Typography>*/}
                                    {/*                    </Grid>*/}
                                    {/*                    /!*<Grid item xs={2}>*!/*/}
                                    {/*                    /!*    <Button*!/*/}
                                    {/*                    /!*        // onClick={handleBackToShippingInfo}*!/*/}
                                    {/*                    /!*        sx={{padding: 0, justifyContent: 'right', width: '100%'}}>*!/*/}
                                    {/*                    /!*        <Typography sx={{color: '#1D8B45', textTransform: 'none'}}>*!/*/}
                                    {/*                    /!*            Edit*!/*/}
                                    {/*                    /!*        </Typography>*!/*/}
                                    {/*                    /!*    </Button>*!/*/}
                                    {/*                    /!*</Grid>*!/*/}
                                    {/*                    <Grid item xs={12}>*/}
                                    {/*                        <Divider/>*/}
                                    {/*                    </Grid>*/}
                                    {/*                    <Grid item xs={12}>*/}
                                    {/*                        <Grid container spacing={0}>*/}
                                    {/*                            <Grid item md={6} xs={6}>*/}
                                    {/*                                <Typography>*/}
                                    {/*                                    {intl.get('COST.LABOUR_HOUR_COST')}*/}
                                    {/*                                </Typography>*/}
                                    {/*                            </Grid>*/}
                                    {/*                            <Grid item md={6} xs={6}>*/}
                                    {/*                                <Typography*/}
                                    {/*                                    sx={styles.MovingServiceAdditionalFeeSectionHeadingCost}>*/}
                                    {/*                                    {formatCurrency(additionalServiceFee?.labour_hour_cost || 0, 'USD')}*/}
                                    {/*                                </Typography>*/}
                                    {/*                            </Grid>*/}
                                    {/*                            <Grid item md={6} xs={6}>*/}
                                    {/*                                <Typography>*/}
                                    {/*                                    {intl.get('COST.PACKING_UNIT_COST')}*/}
                                    {/*                                </Typography>*/}
                                    {/*                            </Grid>*/}
                                    {/*                            <Grid item md={6} xs={6}>*/}
                                    {/*                                <Typography*/}
                                    {/*                                    sx={styles.MovingServiceAdditionalFeeSectionHeadingCost}>*/}
                                    {/*                                    {formatCurrency(additionalServiceFee?.packing_unit_cost || 0, 'USD')}*/}
                                    {/*                                </Typography>*/}
                                    {/*                            </Grid>*/}
                                    {/*                            <Grid item md={6} xs={6}>*/}
                                    {/*                                <Typography>*/}
                                    {/*                                    {intl.get('COST.WRAPPING_UNIT_COST')}*/}
                                    {/*                                </Typography>*/}
                                    {/*                            </Grid>*/}
                                    {/*                            <Grid item md={6} xs={6}>*/}
                                    {/*                                <Typography*/}
                                    {/*                                    sx={styles.MovingServiceAdditionalFeeSectionHeadingCost}>*/}
                                    {/*                                    {formatCurrency(additionalServiceFee?.wrapping_unit_cost || 0, 'USD')}*/}
                                    {/*                                </Typography>*/}
                                    {/*                            </Grid>*/}
                                    {/*                            <Grid item md={6} xs={6}>*/}
                                    {/*                                <Typography>*/}
                                    {/*                                    {intl.get('COST.SELF_PICK_UP_CHARGE')}*/}
                                    {/*                                </Typography>*/}
                                    {/*                            </Grid>*/}
                                    {/*                            <Grid item md={6} xs={6}>*/}
                                    {/*                                <Typography*/}
                                    {/*                                    sx={styles.MovingServiceAdditionalFeeSectionHeadingCost}>*/}
                                    {/*                                    {formatCurrency(additionalServiceFee?.self_pickup_charge || 0, 'USD')}*/}
                                    {/*                                </Typography>*/}
                                    {/*                            </Grid>*/}
                                    {/*                            <Grid item md={6} xs={6}>*/}
                                    {/*                                <Typography>*/}
                                    {/*                                    {intl.get('COST.PICK_UP_MINIMUM_CHARGE')}*/}
                                    {/*                                </Typography>*/}
                                    {/*                            </Grid>*/}
                                    {/*                            <Grid item md={6} xs={6}>*/}
                                    {/*                                <Typography*/}
                                    {/*                                    sx={styles.MovingServiceAdditionalFeeSectionHeadingCost}>*/}
                                    {/*                                    {formatCurrency(additionalServiceFee?.pickup_min_charge || 0, 'USD')}*/}
                                    {/*                                </Typography>*/}
                                    {/*                            </Grid>*/}
                                    {/*                            <Grid item md={6} xs={6}>*/}
                                    {/*                                <Typography>*/}
                                    {/*                                    {intl.get('COST.DELIVER_MINIMUM_CHARGE')}*/}
                                    {/*                                </Typography>*/}
                                    {/*                            </Grid>*/}
                                    {/*                            <Grid item md={6} xs={6}>*/}
                                    {/*                                <Typography*/}
                                    {/*                                    sx={styles.MovingServiceAdditionalFeeSectionHeadingCost}>*/}
                                    {/*                                    {formatCurrency(additionalServiceFee?.deliver_min_charge || 0, 'USD')}*/}
                                    {/*                                </Typography>*/}
                                    {/*                            </Grid>*/}
                                    {/*                            <Grid item md={6} xs={6}>*/}
                                    {/*                                <Typography>*/}
                                    {/*                                    {intl.get('COST.STORAGE_DAILY_COST')}*/}
                                    {/*                                </Typography>*/}
                                    {/*                            </Grid>*/}
                                    {/*                            <Grid item md={6} xs={6}>*/}
                                    {/*                                <Typography*/}
                                    {/*                                    sx={styles.MovingServiceAdditionalFeeSectionHeadingCost}>*/}
                                    {/*                                    {formatCurrency(additionalServiceFee?.storage_daily_unit_cost || 0, 'USD')}*/}
                                    {/*                                </Typography>*/}
                                    {/*                            </Grid>*/}
                                    {/*                            <Grid item md={6} xs={6}>*/}
                                    {/*                                <Typography>*/}
                                    {/*                                    {intl.get('COST.STORAGE_WEEKLY_COST')}*/}
                                    {/*                                </Typography>*/}
                                    {/*                            </Grid>*/}
                                    {/*                            <Grid item md={6} xs={6}>*/}
                                    {/*                                <Typography*/}
                                    {/*                                    sx={styles.MovingServiceAdditionalFeeSectionHeadingCost}>*/}
                                    {/*                                    {formatCurrency(additionalServiceFee?.storage_weekly_unit_cost || 0, 'USD')}*/}
                                    {/*                                </Typography>*/}
                                    {/*                            </Grid>*/}
                                    {/*                            <Grid item md={6} xs={6}>*/}
                                    {/*                                <Typography>*/}
                                    {/*                                    {intl.get('COST.STORAGE_MONTHLY_COST')}*/}
                                    {/*                                </Typography>*/}
                                    {/*                            </Grid>*/}
                                    {/*                            <Grid item md={6} xs={6}>*/}
                                    {/*                                <Typography*/}
                                    {/*                                    sx={styles.MovingServiceAdditionalFeeSectionHeadingCost}>*/}
                                    {/*                                    {formatCurrency(additionalServiceFee?.storage_monthly_unit_cost || 0, 'USD')}*/}
                                    {/*                                </Typography>*/}
                                    {/*                            </Grid>*/}
                                    {/*                        </Grid>*/}
                                    {/*                    </Grid>*/}
                                    {/*                </Grid>*/}
                                    {/*            }*/}
                                    {/*        </>*/}
                                    {/*    )*/}
                                    {/*}*/}
                                    <Grid container spacing={1}>
                                        <Grid item xs={1}>
                                            <RateDetailsIcon width={20} height={20}/>
                                        </Grid>
                                        <Grid item xs={11}>
                                            <Typography sx={{fontWeight: '600'}}>
                                                Rate Details
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider/>
                                        </Grid>
                                        {
                                            tab === 'item' ? <MovingServiceRate tab={tab}/> :
                                                <Grid item xs={12}>
                                                    <Grid container spacing={1}>
                                                        {
                                                            +basicRateInfo?.boxFreightCharge > 0 &&
                                                            <>
                                                                <Grid item xs={8}>
                                                                    <Typography>
                                                                        {intl.get('COST.FREIGHT_CHARGE')}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <Typography style={{textAlign: 'right'}}>
                                                                        $ {(basicRateInfo?.boxFreightCharge)?.toFixed(2)}
                                                                    </Typography>
                                                                </Grid>
                                                            </>
                                                        }
                                                        {
                                                            +basicRateInfo?.boxPickupCharge > 0 &&
                                                            <>
                                                                <Grid item xs={8}>
                                                                    <Typography>
                                                                        {intl.get('COST.PICKUP_CHARGE')}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <Typography style={{textAlign: 'right'}}>
                                                                        $ {(basicRateInfo?.boxPickupCharge)?.toFixed(2)}
                                                                    </Typography>
                                                                </Grid>
                                                            </>
                                                        }
                                                        {
                                                            +basicRateInfo?.boxDeliverCharge > 0 &&
                                                            <>
                                                                <Grid item xs={8}>
                                                                    <Typography>
                                                                        {
                                                                            movingToAddress?.option === 'self' ? intl.get('COST.OPERATION_CHARGE') : intl.get('COST.DELIVER_CHARGE')
                                                                        }
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <Typography style={{textAlign: 'right'}}>
                                                                        $ {(basicRateInfo?.boxDeliverCharge)?.toFixed(2)}
                                                                    </Typography>
                                                                </Grid>
                                                            </>
                                                        }
                                                        <Grid item xs={12}>
                                                            <Divider/>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <Typography>
                                                                {intl.get('COST.BOX_SHIPPING_CHARGE')}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography style={{textAlign: 'right'}}>
                                                                $ {(+basicRateInfo?.shippingChargeDetails?.boxFreightCharge + +basicRateInfo?.shippingChargeDetails?.boxPickupCharge + +basicRateInfo?.shippingChargeDetails?.boxDeliverCharge)?.toFixed(2)}
                                                            </Typography>
                                                        </Grid>
                                                        {
                                                            basicRateInfo?.specialServicesCharges > 0 &&
                                                            <>
                                                                <Grid item xs={8}>
                                                                    <Typography>
                                                                        {intl.get('COST.ADDITIONAL_SERVICE_CHARGE')}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <Typography style={{textAlign: 'right'}}>
                                                                        $ {(basicRateInfo?.specialServicesCharges)?.toFixed(2)}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Divider/>
                                                                </Grid>
                                                            </>
                                                        }
                                                        <Grid item xs={8}>
                                                            <Typography>
                                                                {intl.get('COST.SUBTOTAL')}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography style={{textAlign: 'right'}}>
                                                                $ {(basicRateInfo?.totalCharge)?.toFixed(2)}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <Typography>
                                                                {intl.get('COST.TAX')}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography style={{textAlign: 'right'}}>
                                                                $ {(basicRateInfo?.taxCharge)?.toFixed(2)}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Divider/>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <Typography>
                                                                {intl.get('COST.TOTAL')}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography sx={{
                                                                fontSize: '20px',
                                                                fontWeight: '600',
                                                                textAlign: 'right'
                                                            }}>
                                                                $ {(basicRateInfo?.totalChargeWithTaxes)?.toFixed(2)}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                        }
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Grid container spacing={2}>
                    {
                        confirmationProceed ?
                            <>
                                <Grid item xs={12}>
                                    <Typography>
                                        Would you like to start over? Please select 'Confirm' to begin again or 'Cancel'
                                        to
                                        continue with your current session.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'flex-end',
                                        gap: '20px'
                                    }}>
                                        <Button
                                            size='large'
                                            variant='contained'
                                            onClick={handleCancelProceed}
                                            sx={{
                                                backgroundColor: '#F2BE22',
                                                "&:hover": {
                                                    backgroundColor: '#F2BE22',
                                                    filter: 'brightness(0.9)'
                                                }
                                            }}
                                        >
                                            <Typography sx={{textTransform: 'none'}}>
                                                Cancel
                                            </Typography>
                                        </Button>
                                        <Button
                                            size='large'
                                            variant='contained'
                                            color='success'
                                            // loading={loadingPlaceOrder}
                                            onClick={handleStartOver}
                                        >
                                            <Typography sx={{textTransform: 'none'}}>
                                                Confirm
                                            </Typography>
                                        </Button>
                                    </Box>
                                </Grid>
                            </>
                            :
                            <>
                                {
                                    basicRateInfo?.downPaymentAmount > 0 && (
                                        <>
                                            <Grid item xs={12}>
                                                <Typography sx={{fontSize: '18px'}}>
                                                    {
                                                        basicRateInfo?.orderSubType === 'EXPRESS' ? `Proceed to checkout to pay $ ${(basicRateInfo?.downPaymentAmount).toFixed(2) || 50} CAD.` : `Proceed to checkout to pay $ ${basicRateInfo?.downPaymentAmount || 50} CAD deposit and secure your order.`
                                                    }
                                                </Typography>
                                                <Typography sx={{fontSize: '18px'}}>
                                                    The payment is fully refundable before the order is picked up for
                                                    moving.
                                                </Typography>
                                            </Grid>
                                        </>
                                    )
                                }
                                <Grid item md={12}>
                                    <Box sx={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'flex-end',
                                        gap: '20px'
                                    }}>
                                        <Button
                                            variant='contained'
                                            onClick={handleBack}
                                            sx={{
                                                backgroundColor: '#F2BE22',
                                                "&:hover": {
                                                    backgroundColor: '#F2BE22',
                                                    filter: 'brightness(0.9)'
                                                }
                                            }}
                                        >
                                            <Typography sx={{textTransform: 'none'}}>
                                                Back
                                            </Typography>
                                        </Button>
                                        <LoadingButton
                                            variant='contained'
                                            color='success'
                                            loading={loading}
                                            onClick={handlePlaceOrder}
                                        >
                                            <Typography sx={{textTransform: 'none'}}>
                                                Check Out
                                            </Typography>
                                        </LoadingButton>
                                    </Box>
                                </Grid>
                            </>
                    }
                </Grid>
            </Box>
        </Container>
    )
}