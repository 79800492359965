import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@mui/material/Grid";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    updateMovingServiceDate,
    updateMovingServiceDeliverDate,
    updateMovingServiceDeliverTime,
    updateMovingServiceMoveFromAddress,
    updateMovingServiceMoveToAddress,
    updateMovingServiceTime
} from "../../actions/movingServiceAction";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import intl from "react-intl-universal";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {isValid} from "date-fns";
import * as React from "react";
import dayjs from "dayjs";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import {getDateFromDaysLater, timeSlot} from "../../Utils/Helper";
import {DateCalendar} from "@mui/x-date-pickers";
import {ReactComponent as SchedulePickupIcon} from "../../images/NewDesignIcon/SchedulePickup.svg";
import {ReactComponent as ContactIcon} from "../../images/NewDesignIcon/AddressBook.svg";
import {Divider} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {makeStyles} from "@material-ui/core/styles";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {Button, IconButton, Tooltip} from "@mui/material";
import FmdBadOutlinedIcon from "@mui/icons-material/FmdBadOutlined";
import HelpIcon from '@mui/icons-material/Help';

const styles = {
    MovingServiceDateRoot: {
        backgroundColor: '#FFFFFF',
        // padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '10px',
        display: 'flex',
        flexDirection: 'column',
    },
    MovingServiceDateSection: {
        backgroundColor: '#FFFFFF',
        paddingBottom: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0'
    },
    MovingServiceDateHeading: {
        fontSize: '20px',
        fontWeight: '600'
    },
    MovingServiceMovingDateErrorText: {
        fontSize: '12px',
        color: "#FF0303"
    }
}

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "4px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

export const MovingServiceDate = () => {

    const dispatch = useDispatch();
    const classes = useStyles();

    let moveDate = useSelector(state => state?.movingService?.movingDate);
    let moveTime = useSelector(state => state?.movingService?.movingTime);
    let moveDeliverDate = useSelector(state => state?.movingService?.deliverDate);
    let moveDeliverTime = useSelector(state => state?.movingService?.deliverTime);
    let movingFromAddress = useSelector(state => state?.movingService?.movingFromAddress);
    let movingToAddress = useSelector(state => state?.movingService?.movingToAddress);

    const [wareHouseLocation, setWareHouseLocation] = useState([]);

    const [movingFrom, setMovingFrom] = useState(null);
    const [movingTo, setMovingTo] = useState(null);

    const [movingDate, setMovingDate] = useState(dayjs(new Date()));
    const [movingTime, setMovingTime] = useState({
        movingTimeFrom: null,
        movingTimeTo: null,
        pickupFlexible: false
    });
    const [deliverDate, setDeliverDate] = useState(dayjs(new Date()));
    const [deliverTime, setDeliverTime] = useState({
        deliverTimeFrom: null,
        deliverTimeTo: null,
        deliverFlexible: false
    });

    const handlePickupFlexible = (event) => {
        setMovingTime(prevState => ({
            ...prevState,
            pickupFlexible: event?.target?.checked
        }))
    }

    const handleDeliverFlexible = (event) => {
        setDeliverTime(prevState => ({
            ...prevState,
            deliverFlexible: event?.target?.checked
        }))
    }

    const handleIsSelfPickup = (event) => {
        setMovingTo(prevState => ({
            ...prevState,
            option: event.target.checked ? 'self' : 'schedule',
            warehouse: null
        }))
    }

    const handleIsSelfDropOff = (event) => {
        setMovingFrom(prevState => ({
            ...prevState,
            option: event.target.checked ? 'self' : 'schedule',
            warehouse: null
        }))
    }

    const handleMovingDateChange = (date) => {
        setMovingDate(date);
    };

    const handleDeliverDateChange = (date) => {
        setDeliverDate(date);
    };

    const getWareHouseLocation = async () => {
        let requestURL = `${NODE_ROUTE_URI}/movingExpressService/getWarehousesList`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL
            })
            // console.log('[MovingServiceDate] - getWareHouseLocation - result', result?.data);
            setWareHouseLocation(result?.data);
        } catch (e) {
            console.log('[MovingServiceDate] - getWareHouseLocation - error', e?.response);
        }
    }

    const handleSelectMovingFromWareHouse = (event, value) => {
        console.log('[handleSelectMovingFromWareHouse] wareHouseInfo', value);
        setMovingFrom(prevState => ({
            ...prevState,
            warehouse: value,
        }));
    }

    const handleSelectMovingToWareHouse = (event, value) => {
        console.log('[handleSelectMovingFromWareHouse] wareHouseInfo', value);
        setMovingTo(prevState => ({
            ...prevState,
            warehouse: value,
        }));
    }

    const handleSelectPickupTime = (time) => {
        console.log('[handleSelectPickupTime] time', time);
        setMovingTime(prevState => ({
            ...prevState,
            movingTimeFrom: time?.from,
            movingTimeTo: time?.to
        }))
    }

    const handleSelectDeliverTime = (time) => {
        console.log('[handleSelectDeliverTime] time', time);
        setDeliverTime(prevState => ({
            ...prevState,
            deliverTimeFrom: time?.from,
            deliverTimeTo: time?.to
        }))
    }

    useEffect(() => {

        getWareHouseLocation();

        if (moveTime) {
            setMovingTime(moveTime);
        }

        if (moveDeliverTime) {
            setDeliverTime(moveDeliverTime);
        }

        // Get the current time components
        const currentTime = new Date();
        const currentHour = currentTime.getHours();
        const currentMinute = currentTime.getMinutes();

        if ((currentHour > 15) || (currentHour === 15 && currentMinute > 0)) {
            const nextDate = getDateFromDaysLater(1);
            setMovingDate(nextDate);
        } else {
            setMovingDate(moveDate || dayjs(currentTime));
        }

        if (movingFromAddress?.streetAddress || movingFromAddress?.warehouse) {
            setMovingFrom(movingFromAddress);
        }
        if (movingToAddress?.streetAddress || movingToAddress?.warehouse) {
            setMovingTo(movingToAddress);
        }

    }, []);

    useEffect(() => {
        dispatch(updateMovingServiceDate(movingDate))
    }, [movingDate])

    useEffect(() => {
        dispatch(updateMovingServiceTime(movingTime))
    }, [movingTime])

    useEffect(() => {
        dispatch(updateMovingServiceDeliverDate(deliverDate))
    }, [deliverDate])

    useEffect(() => {
        dispatch(updateMovingServiceDeliverTime(deliverTime))
    }, [deliverTime])

    useEffect(() => {
        dispatch(updateMovingServiceMoveFromAddress(movingFrom));
    }, [movingFrom]);

    useEffect(() => {
        dispatch(updateMovingServiceMoveToAddress(movingTo));
    }, [movingTo]);

    // console.log('moving time', movingTime);
    // console.log('moving time - redux', moveTime);
    // console.log('deliver time', deliverTime);
    // console.log('deliver time - redux', moveDeliverTime);

    return (
        <Box sx={styles.MovingServiceDateRoot}>
            <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                    <Box sx={styles.MovingServiceDateSection}>
                        <Box sx={{
                            display: 'flex',
                            gap: '10px',
                            padding: '20px 20px 0 20px'
                        }}>
                            <SchedulePickupIcon width={25} height={25}/>
                            <Typography sx={{fontWeight: '600'}}>
                                Pickup Date
                            </Typography>
                        </Box>
                        <Divider/>
                        <Box sx={{
                            backgroundColor: '#FFFFFF',
                            padding: '0 20px',
                            gap: '40px',
                            display: 'flex',
                        }}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography sx={{fontWeight: '600'}}>
                                        When can your shipment be picked up?
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                // shouldDisableDate={isWeekend}
                                                value={movingDate}
                                                onChange={handleMovingDateChange}
                                                disablePast
                                                renderInput={(props) =>
                                                    <TextField
                                                        {...props}
                                                        fullWidth
                                                    />}
                                                slotProps={{
                                                    textField: {
                                                        helperText: !isValid(movingDate?.$d) && 'Invalid Pickup Date',
                                                        size: 'small'
                                                    },
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <Typography sx={{fontWeight: '600'}}>
                                            Select Time
                                        </Typography>
                                        <Tooltip
                                            placement="top"
                                            arrow
                                            componentsProps={{
                                                tooltip: {
                                                    sx: {
                                                        bgcolor: 'white',
                                                        color: 'black',
                                                        boxShadow: 1,
                                                        border: '1px solid #ddd',
                                                        borderRadius: '8px', // Added border radius
                                                    },
                                                },
                                                arrow: {
                                                    sx: {
                                                        color: 'white',
                                                    },
                                                },
                                            }}
                                            title={
                                                <Box sx={{backgroundColor: "#ffffff"}}>
                                                    <Typography sx={{fontSize: '12px'}}>
                                                        While we strive to accommodate your chosen time, it may be
                                                        subjected to change based on the mover's availability.
                                                    </Typography>
                                                </Box>
                                            }>
                                            <IconButton>
                                                <HelpIcon sx={{color: '#1D8B45'}}/>
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        {
                                            timeSlot?.map((slot, index) => (
                                                <Grid item xs={3} key={index}>
                                                    <Button sx={{
                                                        padding: 0,
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        width: '100%',
                                                        border: slot?.from === movingTime?.movingTimeFrom ? '2px solid #1D8B45' : '1.5px solid #454545'
                                                    }}
                                                            onClick={() => handleSelectPickupTime(slot)}
                                                    >
                                                        <Typography sx={{
                                                            textTransform: 'none',
                                                            fontSize: '14px',
                                                            color: slot?.from === movingTime?.movingTimeFrom ? '#1D8B45' : '#454545'
                                                        }}>
                                                            {slot.text}
                                                        </Typography>
                                                    </Button>
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={movingTime?.pickupFlexible}
                                                onChange={handlePickupFlexible}
                                                style={{
                                                    color: 'green',
                                                    '&.Mui-checked': {
                                                        color: 'green',
                                                    }
                                                }}
                                            />
                                        }
                                        label={
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}>
                                                <Typography>
                                                    I'm Flexible
                                                </Typography>
                                                <Tooltip
                                                    placement="top"
                                                    arrow
                                                    componentsProps={{
                                                        tooltip: {
                                                            sx: {
                                                                bgcolor: 'white',
                                                                color: 'black',
                                                                boxShadow: 1,
                                                                border: '1px solid #ddd',
                                                                borderRadius: '8px', // Added border radius
                                                            },
                                                        },
                                                        arrow: {
                                                            sx: {
                                                                color: 'white',
                                                            },
                                                        },
                                                    }}
                                                    title={
                                                        <Box sx={{backgroundColor: "#ffffff"}}>
                                                            <Typography sx={{fontSize: '12px'}}>
                                                                Your flexibility helps optimize our operations and
                                                                saves your money.
                                                            </Typography>
                                                        </Box>
                                                    }>
                                                    <IconButton>
                                                        <HelpIcon sx={{color: '#1D8B45'}}/>
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={movingFrom?.option === 'self'}
                                                onChange={handleIsSelfDropOff}
                                                name="narrow street"
                                                style={{
                                                    color: 'green',
                                                    '&.Mui-checked': {
                                                        color: 'green',
                                                    }
                                                }}
                                            />
                                        }
                                        label={
                                            <>
                                                Drop off at our warehouse by yourself
                                            </>
                                        }
                                    />
                                </Grid>
                                {
                                    movingFrom?.option === 'self' &&
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                {intl.get('MOVING.WAREHOUSE_LOCATION')}
                                            </FormLabel>
                                            <Autocomplete
                                                value={movingFrom?.warehouse}
                                                fullWidth
                                                options={wareHouseLocation}
                                                getOptionLabel={(option) => (`${option?.name}, ${option?.city}, ${option?.province}, ${option?.postal_code}, ${option?.country}`)}
                                                onChange={handleSelectMovingFromWareHouse}
                                                renderInput={(params) => <TextField
                                                    variant="outlined"
                                                    className={classes.smallInput}
                                                    {...params}
                                                    error={movingFrom?.option === 'self' && !movingFrom?.warehouse}
                                                    helperText={(movingFrom?.option === 'self' && !movingFrom?.warehouse) && 'Required'}
                                                />}
                                                renderOption={(option) => (
                                                    <div>
                                                        {option?.name}, {option?.city}, {option?.province}, {option?.postal_code}, {option?.country}
                                                    </div>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                }
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Box sx={styles.MovingServiceDateSection}>
                        <Box sx={{
                            display: 'flex',
                            gap: '10px',
                            padding: '20px 20px 0 20px'
                        }}>
                            <SchedulePickupIcon width={25} height={25}/>
                            <Typography sx={{fontWeight: '600'}}>
                                Delivery Date
                            </Typography>
                        </Box>
                        <Divider/>
                        <Box sx={{
                            backgroundColor: '#FFFFFF',
                            padding: '0 20px',
                            gap: '40px',
                            display: 'flex',
                        }}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography>
                                        When do you need your shipment delivery?
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                // shouldDisableDate={isWeekend}
                                                value={deliverDate}
                                                onChange={handleDeliverDateChange}
                                                disablePast
                                                renderInput={(props) =>
                                                    <TextField
                                                        {...props}
                                                        fullWidth
                                                    />}
                                                slotProps={{
                                                    textField: {
                                                        helperText: !isValid(deliverDate?.$d) && 'Invalid Pickup Date',
                                                        size: 'small'
                                                    },
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <Typography sx={{fontWeight: '600'}}>
                                            Select Time
                                        </Typography>
                                        <Tooltip
                                            placement="top"
                                            arrow
                                            componentsProps={{
                                                tooltip: {
                                                    sx: {
                                                        bgcolor: 'white',
                                                        color: 'black',
                                                        boxShadow: 1,
                                                        border: '1px solid #ddd',
                                                        borderRadius: '8px', // Added border radius
                                                    },
                                                },
                                                arrow: {
                                                    sx: {
                                                        color: 'white',
                                                    },
                                                },
                                            }}
                                            title={
                                                <Box sx={{backgroundColor: "#ffffff"}}>
                                                    <Typography sx={{fontSize: '12px'}}>
                                                        While we strive to accommodate your chosen time, it may be
                                                        subjected to change based on the mover's availability.
                                                    </Typography>
                                                </Box>
                                            }>
                                            <IconButton>
                                                <HelpIcon sx={{color: '#1D8B45'}}/>
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        {
                                            timeSlot?.map((slot, index) => (
                                                <Grid item xs={3} key={index}>
                                                    <Button sx={{
                                                        padding: 0,
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        width: '100%',
                                                        border: slot?.from === deliverTime?.deliverTimeFrom ? '2px solid #1D8B45' : '1.5px solid #454545'
                                                    }}
                                                            onClick={() => handleSelectDeliverTime(slot)}
                                                    >
                                                        <Typography sx={{
                                                            textTransform: 'none',
                                                            fontSize: '14px',
                                                            color: slot?.from === deliverTime?.deliverTimeFrom ? '#1D8B45' : '#454545'
                                                        }}>
                                                            {slot.text}
                                                        </Typography>
                                                    </Button>
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={deliverTime?.deliverFlexible}
                                                onChange={handleDeliverFlexible}
                                                style={{
                                                    color: 'green',
                                                    '&.Mui-checked': {
                                                        color: 'green',
                                                    }
                                                }}
                                            />
                                        }
                                        label={
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}>
                                                <Typography>
                                                    I'm Flexible
                                                </Typography>
                                                <Tooltip
                                                    placement="top"
                                                    arrow
                                                    componentsProps={{
                                                        tooltip: {
                                                            sx: {
                                                                bgcolor: 'white',
                                                                color: 'black',
                                                                boxShadow: 1,
                                                                border: '1px solid #ddd',
                                                                borderRadius: '8px', // Added border radius
                                                            },
                                                        },
                                                        arrow: {
                                                            sx: {
                                                                color: 'white',
                                                            },
                                                        },
                                                    }}
                                                    title={
                                                        <Box sx={{backgroundColor: "#ffffff"}}>
                                                            <Typography sx={{fontSize: '12px'}}>
                                                                Your flexibility helps optimize our operations and
                                                                saves your money.
                                                            </Typography>
                                                        </Box>
                                                    }>
                                                    <IconButton>
                                                        <HelpIcon sx={{color: '#1D8B45'}}/>
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={movingTo?.option === 'self'}
                                                onChange={handleIsSelfPickup}
                                                name="narrow street"
                                                style={{
                                                    color: 'green',
                                                    '&.Mui-checked': {
                                                        color: 'green',
                                                    }
                                                }}
                                            />
                                        }
                                        label={
                                            <>
                                                Self Pickup at our warehouse by yourself
                                            </>
                                        }
                                    />
                                </Grid>
                                {
                                    movingTo?.option === 'self' &&
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                {intl.get('MOVING.WAREHOUSE_LOCATION')}
                                            </FormLabel>
                                            <Autocomplete
                                                value={movingTo?.warehouse}
                                                fullWidth
                                                options={wareHouseLocation}
                                                getOptionLabel={(option) => (`${option?.name}, ${option?.city}, ${option?.province}, ${option?.postal_code}, ${option?.country}`)}
                                                onChange={handleSelectMovingToWareHouse}
                                                renderInput={(params) => <TextField
                                                    variant="outlined"
                                                    className={classes.smallInput}
                                                    {...params}
                                                    error={movingTo?.option === 'self' && !movingTo?.warehouse}
                                                    helperText={(movingTo?.option === 'self' && !movingTo?.warehouse) && 'Required'}
                                                />}
                                                renderOption={(option) => (
                                                    <div>
                                                        {option?.name}, {option?.city}, {option?.province}, {option?.postal_code}, {option?.country}
                                                    </div>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                }
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}