import {Container} from "@mui/material";
import Box from "@mui/material/Box";
import {LandingIntro} from "./LandingIntro";
import {LandingUserSignUp} from "./LandingUserSignUp";
import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {setTokeninCookie} from "../../Utils/doToken";
import Grid from "@mui/material/Grid";
import {useLocation} from "react-router-dom";
import {BusinessUserSignUp} from "./BusinessUserSignUp";
import LandingImage from "../../images/CoverImage.png";
import {LandingMenuBar} from "./LandingMenuBar";
import {useMediaQuery} from "@material-ui/core";

const styles = {
    LandingSignUpRoot: {
        display: 'flex',
        margin: '0 auto',
        '@media (max-width: 480px)': {
            flexDirection: "column", // for phone and above
        },
    },
    LandingIntroImage: {
        width: '95%',
        padding: '5%'
    },
}

export const LandingSignUp = () => {

    const isMobile = useMediaQuery('(max-width: 480px)');

    let referralCode = useSelector(state => state?.referral?.referralCode);
    const {pathname} = useLocation();
    const startsWithBusiness = pathname.startsWith('/business');

    const getTReferralTokenByReferralAgentCode = async () => {
        let requestURL = `${NODE_ROUTE_URI}/referralAgent/getInitAccessTokenByReferralAgentCode`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    referralAgentCode: referralCode
                }
            })
            const token = result?.data?.accessToken;
            setTokeninCookie("access_token", token, 30);
            console.log(token);
        } catch (e) {
            console.log(e.response);
        }
    }

    // useEffect(() => {
    //     referralCode && getTReferralTokenByReferralAgentCode();
    //     document.body.classList.add('white-background');
    //     return () => {
    //         document.body.classList.remove('white-background');
    //     };
    // }, []);

    return (
        <Container maxWidth={false} style={{padding: '0px', marginTop: '-75px'}}>
            <Grid container spacing={0}>
                <Grid item xs={12} md={5}>
                    <Box sx={{
                        display: 'flex',
                        padding: '20px',
                        flexDirection: 'column',
                        height: isMobile ? '100vh' : '100%',
                        gap: isMobile ? '10vh' : '15vh'
                    }}>
                        <LandingMenuBar/>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            height: '100%'
                        }}>
                            {
                                startsWithBusiness ? <BusinessUserSignUp/> : <LandingUserSignUp/>
                            }
                        </Box>
                    </Box>
                </Grid>
                <LandingIntro/>
                {/*<Grid item xs={12} md={7}>*/}
                {/*    <Box sx={{*/}
                {/*        display: 'flex',*/}
                {/*        backgroundColor: '#1D8B45',*/}
                {/*        minHeight: '100vh',*/}
                {/*        height: '100%',*/}
                {/*        width: '100%',*/}
                {/*        justifyContent: 'center',*/}
                {/*        alignItems: 'flex-start',*/}
                {/*    }}>*/}
                {/*        <img src={LandingImage} alt="landing image" style={styles.LandingIntroImage}/>*/}
                {/*    </Box>*/}
                {/*</Grid>*/}
            </Grid>
        </Container>
    )
}