export const actionType = {
    'ADDRESS_LIST_SELECTED_TAB': 'ADDRESS_LIST_SELECTED_TAB',
    'ADDRESS_LIST_ADD': 'ADDRESS_LIST_ADD',
    'ADDRESS_LIST_EDIT': 'ADDRESS_LIST_EDIT',
    'ADDRESS_LIST_PASS_ADDRESS_INFO': 'ADDRESS_LIST_PASS_ADDRESS_INFO',
    'LANDING_ESTIMATE_UPDATE_PACKAGE_DATA': 'LANDING_ESTIMATE_UPDATE_PACKAGE_DATA',
    'LANDING_ESTIMATE_UPDATE_PALLET_DATA': 'LANDING_ESTIMATE_UPDATE_PALLET_DATA',
    'LANDING_ESTIMATE_UPDATE_PACKAGE_MISSING': 'LANDING_ESTIMATE_UPDATE_PACKAGE_MISSING',
    'LANDING_ESTIMATE_UPDATE_PALLET_MISSING': 'LANDING_ESTIMATE_UPDATE_PALLET_MISSING',
    'LANDING_ESTIMATE_UPDATE_PACKAGE_COST': 'LANDING_ESTIMATE_UPDATE_PACKAGE_COST',
    'LANDING_ESTIMATE_UPDATE_PALLET_COST': 'LANDING_ESTIMATE_UPDATE_PALLET_COST',
    'LANDING_ESTIMATE_CLEAR_PACKAGE_COST': 'LANDING_ESTIMATE_CLEAR_PACKAGE_COST',
    'LANDING_ESTIMATE_CLEAR_PALLET_COST': 'LANDING_ESTIMATE_CLEAR_PALLET_COST',
    'LANDING_UPDATE_CLICK_SIGN_IN': 'LANDING_UPDATE_CLICK_SIGN_IN',
    'LANDING_UPDATE_CLICK_JOIN_US': 'LANDING_UPDATE_CLICK_JOIN_US',
    'LANDING_ESTIMATE_UPDATE_PICKUP_POSTAL_CODE_VALIDATION': 'LANDING_ESTIMATE_UPDATE_PICKUP_POSTAL_CODE_VALIDATION',
    'LANDING_ESTIMATE_UPDATE_DELIVER_POSTAL_CODE_VALIDATION': 'LANDING_ESTIMATE_UPDATE_DELIVER_POSTAL_CODE_VALIDATION',
    'LANDING_PAGE_LATEST_ORDER_UPDATE_OPEN_INDEX': 'LANDING_PAGE_LATEST_ORDER_UPDATE_OPEN_INDEX',
    'LANDING_ESTIMATE_UPDATE_TYPE_SERVICE': 'LANDING_ESTIMATE_UPDATE_TYPE_SERVICE',
    'LANDING_ESTIMATE_UPDATE_COST_ERROR': 'LANDING_ESTIMATE_UPDATE_COST_ERROR',
    'LANDING_ESTIMATE_UPDATE_COST_LOADING': 'LANDING_ESTIMATE_UPDATE_COST_LOADING',
    'LANDING_ESTIMATE_UPDATE_CLICK_ESTIMATE': 'LANDING_ESTIMATE_UPDATE_CLICK_ESTIMATE',
    'LANDING_ESTIMATE_CLEAR_TYPE_SERVICE': 'LANDING_ESTIMATE_CLEAR_TYPE_SERVICE',
    'COMMERCIAL_INVOICE_UPDATE_TRACKING_NO_LIST': 'COMMERCIAL_INVOICE_UPDATE_TRACKING_NO_LIST',
    'UPDATE_COMMERCIAL_INVOICE_TAB_VALUE': 'UPDATE_COMMERCIAL_INVOICE_TAB_VALUE',
    'UPDATE_ACTION_INDEX_IN_CROSS_BORDER_INVOICE': 'UPDATE_ACTION_INDEX_IN_CROSS_BORDER_INVOICE',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_UNIT': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_UNIT',
    'ADD_SHIPPING_LTL_QUICK_QUOTE_PALLET_LIST': 'ADD_SHIPPING_LTL_QUICK_QUOTE_PALLET_LIST',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_PALLET_LIST': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_PALLET_LIST',
    'REMOVE_SHIPPING_LTL_QUICK_QUOTE_PALLET_LIST': 'REMOVE_SHIPPING_LTL_QUICK_QUOTE_PALLET_LIST',
    'DUPLICATE_SHIPPING_LTL_QUICK_QUOTE_PALLET_LIST': 'DUPLICATE_SHIPPING_LTL_QUICK_QUOTE_PALLET_LIST',
    'CLEAR_SHIPPING_LTL_QUICK_QUOTE_PALLET_LIST': 'CLEAR_SHIPPING_LTL_QUICK_QUOTE_PALLET_LIST',

    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_FROM_LOCATION': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_FROM_LOCATION',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_TO_LOCATION': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_TO_LOCATION',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_FROM_LOCATION_MISSING': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_FROM_LOCATION_MISSING',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_TO_LOCATION_MISSING': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_TO_LOCATION_MISSING',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_CROSS_BORDER': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_CROSS_BORDER',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_RATE': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_RATE',
    'SELECT_SHIPPING_LTL_QUICK_QUOTE_RATE': 'SELECT_SHIPPING_LTL_QUICK_QUOTE_RATE',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_FROM_ADDITIONAL_LOCATION': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_FROM_ADDITIONAL_LOCATION',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_TO_ADDITIONAL_LOCATION': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_TO_ADDITIONAL_LOCATION',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_SHIPPER_INFO': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_SHIPPER_INFO',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_CONSIGNEE_INFO': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_CONSIGNEE_INFO',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_SHIP_AND_PICKUP_DATA_TIME': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_SHIP_AND_PICKUP_DATA_TIME',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_PLACE_ORDER_VALIDATE': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_PLACE_ORDER_VALIDATE',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_SPECIAL_SERVICE': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_SPECIAL_SERVICE',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_REMARK': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_REMARK',
    'SAVE_SHIPPING_LTL_QUICK_QUOTE_SPECIAL_SERVICE': 'SAVE_SHIPPING_LTL_QUICK_QUOTE_SPECIAL_SERVICE',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_REFERENCE_NUMBER': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_REFERENCE_NUMBER',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_FTL_COUNT': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_FTL_COUNT',

    'UPDATE_SHIPPING_LTL_CREATE_LABEL_MOVE_FROM_LOCATION': 'UPDATE_SHIPPING_LTL_CREATE_LABEL_MOVE_FROM_LOCATION',
    'UPDATE_SHIPPING_LTL_CREATE_LABEL_MOVE_TO_LOCATION': 'UPDATE_SHIPPING_LTL_CREATE_LABEL_MOVE_TO_LOCATION',
    'UPDATE_SHIPPING_LTL_CREATE_LABEL_UNIT': 'UPDATE_SHIPPING_LTL_CREATE_LABEL_UNIT',
    'INIT_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST': 'INIT_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST',
    'ADD_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST': 'ADD_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST',
    'UPDATE_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST': 'UPDATE_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST',
    'REMOVE_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST': 'REMOVE_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST',
    'DUPLICATE_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST': 'DUPLICATE_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST',
    'CLEAR_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST': 'CLEAR_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST',
    'UPDATE_SHIPPING_LTL_CREATE_LABEL_SPECIAL_SERVICE': 'UPDATE_SHIPPING_LTL_CREATE_LABEL_SPECIAL_SERVICE',
    'UPDATE_SHIPPING_LTL_CREATE_LABEL_VALIDATION': 'UPDATE_SHIPPING_LTL_CREATE_LABEL_VALIDATION',
    'UPDATE_SHIPPING_LTL_CREATE_LABEL_RATE': 'UPDATE_SHIPPING_LTL_CREATE_LABEL_RATE',
    'SELECT_SHIPPING_LTL_CREATE_LABEL_RATE': 'SELECT_SHIPPING_LTL_CREATE_LABEL_RATE',
    'CLEAR_SHIPPING_LTL_CREATE_LABEL_RATE': 'CLEAR_SHIPPING_LTL_CREATE_LABEL_RATE',
    'UPDATE_CALLED_ALL_LTL_CARRIER': 'UPDATE_CALLED_ALL_LTL_CARRIER',
    'CLEAR_CALLED_ALL_LTL_CARRIER': 'CLEAR_CALLED_ALL_LTL_CARRIER',
    'UPDATE_SHIPPING_LTL_CREATE_LABEL_REFERENCE_NUMBER': 'UPDATE_SHIPPING_LTL_CREATE_LABEL_REFERENCE_NUMBER',
    'CLEAR_SHIPPING_LTL_CREATE_LABEL_REFERENCE_NUMBER': 'CLEAR_SHIPPING_LTL_CREATE_LABEL_REFERENCE_NUMBER',
    'UPDATE_SHIPPING_LTL_CREATE_LABEL_COMMENT': 'UPDATE_SHIPPING_LTL_CREATE_LABEL_COMMENT',
    'CLEAR_SHIPPING_LTL_CREATE_LABEL_COMMENT': 'CLEAR_SHIPPING_LTL_CREATE_LABEL_COMMENT',
    'UPDATE_SHIPPING_LTL_CREATE_LABEL_DATE_AND_TIME': 'UPDATE_SHIPPING_LTL_CREATE_LABEL_DATE_AND_TIME',
    'CLEAR_SHIPPING_LTL_CREATE_LABEL_DATE_AND_TIME': 'CLEAR_SHIPPING_LTL_CREATE_LABEL_DATE_AND_TIME',
    'UPDATE_SHIPPING_LTL_CREATE_LABEL_FTL_COUNT': 'UPDATE_SHIPPING_LTL_CREATE_LABEL_FTL_COUNT',

    'ADD_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST': 'ADD_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST',
    'UPDATE_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST': 'UPDATE_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST',
    'REMOVE_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST': 'REMOVE_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST',
    'DUPLICATE_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST': 'DUPLICATE_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST',
    'CLEAR_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST': 'CLEAR_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST',
    'INIT_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST': 'INIT_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST',
    'UPDATE_SHIPPING_LTL_CROSS_BORDER_RATE': 'UPDATE_SHIPPING_LTL_CROSS_BORDER_RATE',
    'UPDATE_SHIPPING_LTL_CROSS_BORDER_TRANS_AND_TYPE': 'UPDATE_SHIPPING_LTL_CROSS_BORDER_TRANS_AND_TYPE',


    'UPDATE_SHIPPING_PACKAGE_QUICK_QUOTE_UNIT': 'UPDATE_SHIPPING_PACKAGE_QUICK_QUOTE_UNIT',
    'UPDATE_SHIPPING_PACKAGE_CREATE_LABEL_UNIT': 'UPDATE_SHIPPING_PACKAGE_CREATE_LABEL_UNIT',
    'ADD_SHIPPING_PACKAGE_QUICK_QUOTE_PACKAGE_LIST': 'ADD_SHIPPING_PACKAGE_QUICK_QUOTE_PACKAGE_LIST',
    'ADD_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST': 'ADD_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST',
    'ADD_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST': 'ADD_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST',
    'UPDATE_SHIPPING_PACKAGE_QUICK_QUOTE_PACKAGE_LIST': 'UPDATE_SHIPPING_PACKAGE_QUICK_QUOTE_PACKAGE_LIST',
    'UPDATE_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST': 'UPDATE_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST',
    'UPDATE_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST': 'UPDATE_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST',
    'INIT_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST': 'INIT_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST',
    'REMOVE_SHIPPING_PACKAGE_QUICK_QUOTE_PACKAGE_LIST': 'REMOVE_SHIPPING_PACKAGE_QUICK_QUOTE_PACKAGE_LIST',
    'REMOVE_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST': 'REMOVE_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST',
    'REMOVE_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST': 'REMOVE_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST',
    'DUPLICATE_SHIPPING_PACKAGE_QUICK_QUOTE_PACKAGE_LIST': 'DUPLICATE_SHIPPING_PACKAGE_QUICK_QUOTE_PACKAGE_LIST',
    'DUPLICATE_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST': 'DUPLICATE_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST',
    'DUPLICATE_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST': 'DUPLICATE_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST',
    'CLEAR_SHIPPING_PACKAGE_QUICK_QUOTE_PACKAGE_LIST': 'CLEAR_SHIPPING_PACKAGE_QUICK_QUOTE_PACKAGE_LIST',
    'CLEAR_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST': 'CLEAR_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST',
    'CLEAR_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST': 'CLEAR_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST',
    'UPDATE_SHIPPING_QUICK_QUOTE_SHIP_FROM_LOCATION': 'UPDATE_SHIPPING_QUICK_QUOTE_SHIP_FROM_LOCATION',
    'UPDATE_SHIPPING_QUICK_QUOTE_SHIP_TO_LOCATION': 'UPDATE_SHIPPING_QUICK_QUOTE_SHIP_TO_LOCATION',
    'UPDATE_SHIPPING_CREATE_LABEL_SHIP_FROM_LOCATION': 'UPDATE_SHIPPING_CREATE_LABEL_SHIP_FROM_LOCATION',
    'UPDATE_SHIPPING_CREATE_LABEL_SHIP_TO_LOCATION': 'UPDATE_SHIPPING_CREATE_LABEL_SHIP_TO_LOCATION',
    'UPDATE_SHIPPING_QUICK_QUOTE_SHIP_FROM_LOCATION_MISSING': 'UPDATE_SHIPPING_QUICK_QUOTE_SHIP_FROM_LOCATION_MISSING',
    'UPDATE_SHIPPING_QUICK_QUOTE_SHIP_TO_LOCATION_MISSING': 'UPDATE_SHIPPING_QUICK_QUOTE_SHIP_TO_LOCATION_MISSING',
    'UPDATE_SHIPPING_QUICK_QUOTE_CROSS_BORDER': 'UPDATE_SHIPPING_QUICK_QUOTE_CROSS_BORDER',
    'UPDATE_SHIPPING_CREATE_LABEL_CROSS_BORDER': 'UPDATE_SHIPPING_CREATE_LABEL_CROSS_BORDER',
    'UPDATE_SHIPPING_CREATE_LABEL_REFERENCE_ORDER_NUMBER': 'UPDATE_SHIPPING_CREATE_LABEL_REFERENCE_ORDER_NUMBER',
    'UPDATE_SHIPPING_CREATE_LABEL_VALIDATION': 'UPDATE_SHIPPING_CREATE_LABEL_VALIDATION',
    'UPDATE_SHIPPING_PACKAGE_QUICK_QUOTE_RATE': 'UPDATE_SHIPPING_PACKAGE_QUICK_QUOTE_RATE',
    'UPDATE_SHIPPING_PACKAGE_CREATE_LABEL_RATE': 'UPDATE_SHIPPING_PACKAGE_CREATE_LABEL_RATE',
    'CLEAR_SHIPPING_PACKAGE_CREATE_LABEL_RATE': 'CLEAR_SHIPPING_PACKAGE_CREATE_LABEL_RATE',
    'SELECT_SHIPPING_PACKAGE_QUICK_QUOTE_RATE': 'SELECT_SHIPPING_PACKAGE_QUICK_QUOTE_RATE',
    'SELECT_SHIPPING_PACKAGE_CREATE_LABEL_RATE': 'SELECT_SHIPPING_PACKAGE_CREATE_LABEL_RATE',
    'TOGGLE_SHIPPING_PACKAGE_QUICK_QUOTE_IS_GET_RATE': 'TOGGLE_SHIPPING_PACKAGE_QUICK_QUOTE_IS_GET_RATE',
    'UPDATE_SHIPPING_PACKAGE_RETURN_LABEL_STATUS': 'UPDATE_SHIPPING_PACKAGE_RETURN_LABEL_STATUS',
    'UPDATE_TRACKING_NUMBER': 'UPDATE_TRACKING_NUMBER',
    'UPDATE_TRACKING_NUMBER_BOL': 'UPDATE_TRACKING_NUMBER_BOL',
    'UPDATE_SHIPPING_PACKAGE_CROSS_BORDER_RATE': 'UPDATE_SHIPPING_PACKAGE_CROSS_BORDER_RATE',
    'ADD_COMMERCIAL_INVOICE_LIST': 'ADD_COMMERCIAL_INVOICE_LIST',
    'REMOVE_COMMERCIAL_INVOICE_LIST': 'REMOVE_COMMERCIAL_INVOICE_LIST',
    'CLEAR_COMMERCIAL_INVOICE_LIST': 'CLEAR_COMMERCIAL_INVOICE_LIST',
    'IS_CALLED_ALL_PACKAGE_CARRIER': 'IS_CALLED_ALL_PACKAGE_CARRIER',
    'CLEAR_CALLED_ALL_PACKAGE_CARRIER': 'CLEAR_CALLED_ALL_PACKAGE_CARRIER',
    'UPDATE_SHIPPING_QUICK_QUOTE_BATTERY_DETAILS': 'UPDATE_SHIPPING_QUICK_QUOTE_BATTERY_DETAILS',
    'UPDATE_SHIPPING_CREATE_LABEL_BATTERY_DETAILS': 'UPDATE_SHIPPING_CREATE_LABEL_BATTERY_DETAILS',
    'UPDATE_SHIPPING_QUICK_QUOTE_SIGNATURE_OPTIONS': 'UPDATE_SHIPPING_QUICK_QUOTE_SIGNATURE_OPTIONS',
    'UPDATE_SHIPPING_CREATE_LABEL_SIGNATURE_OPTIONS': 'UPDATE_SHIPPING_CREATE_LABEL_SIGNATURE_OPTIONS',
    'UPDATE_SHIPPING_PACKAGE_INTERNATIONAL_SHIPMENT_SHIP_FROM': 'UPDATE_SHIPPING_PACKAGE_INTERNATIONAL_SHIPMENT_SHIP_FROM',
    'UPDATE_SHIPPING_PACKAGE_INTERNATIONAL_SHIPMENT_SHIP_TO': 'UPDATE_SHIPPING_PACKAGE_INTERNATIONAL_SHIPMENT_SHIP_TO',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_BATTERY_DETAILS': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_BATTERY_DETAILS',
    'UPDATE_SHIPPING_LTL_CREATE_LABEL_BATTERY_DETAILS': 'UPDATE_SHIPPING_LTL_CREATE_LABEL_BATTERY_DETAILS',
}
