import {useState} from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import {Divider, InputAdornment, MenuItem, Select} from "@mui/material";
import SearchIcon from "@material-ui/icons/Search";
import FormControl from "@mui/material/FormControl";
import {BillingInvoicesList} from "./BillingInvoicesList";

const styles = {
    BillingInvoicesRoot: {
        backgroundColor: '#FFFFFF',
        // padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    BillingInvoicesContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        padding: '0 20px 20px 20px'
    },
    BillingInvoicesTitle: {
        fontWeight: '600',
        textAlign: 'left'
    }
}

export const BillingInvoices = () => {

    return (
        <Box sx={styles.BillingInvoicesRoot}>
            <Box sx={{padding: '20px 20px 0 20px'}}>
                <Typography style={styles.BillingInvoicesTitle}>
                    Invoices
                </Typography>
            </Box>
            <Divider/>
            <Box sx={styles.BillingInvoicesContent}>
                <BillingInvoicesList/>
            </Box>
        </Box>

    )
}