import React, {useState, useEffect, useRef} from "react";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import AddIcon from '@mui/icons-material/Add';
import {
    Select,
    MenuItem,
    Switch,
    FormLabel,
    FormControlLabel,
    Grid,
    ToggleButtonGroup,
    ToggleButton, Button,
} from "@mui/material";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {orange, red, green} from "@mui/material/colors";
import {Controller, useForm} from "react-hook-form";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import LoadingButton from "@mui/lab/LoadingButton";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import BusinessMyScheduledPickupItem from "./BusinessMyScheduledPickupItem";

import {useSelector} from "react-redux";
import axios from "axios";
import {PARTNER_URI} from "../../../Utils/apiUrl";

import dayjs from "dayjs";
import Upload from "@mui/icons-material/Upload";
import {IconButton, Tooltip} from "@material-ui/core";
import HelpIcon from "@mui/icons-material/Help";
import {useHistory} from "react-router-dom";
import intl from "react-intl-universal";

const theme = createTheme({
    palette: {
        primary: orange,
        danger: red,
        success: green,
        warning: red,
        secondary: green,
    },
});

const BusinessMyScheduledPickups = () => {
    const id = useSelector((state) => state.user.id);
    const [myPickups, setMyPickups] = useState([]);
    const [accountBase, setAccountBase] = useState("");
    const [filterDateStart, setFilterDateStart] = useState(
        dayjs().hour(0).minute(0).second(0).millisecond(0).subtract(30, "day")
    );
    const [filterDateEnd, setFilterDateEnd] = useState(
        dayjs().hour(0).minute(0).second(0).millisecond(0).add(30, "day")
    );
    const [isValidDateRange, setIsValidDateRange] = useState(true)

    const matches = useMediaQuery("(max-width:800px)");

    const firstUpdate = useRef(true);
    useEffect(() => {
        if (firstUpdate.current || !id) {
            firstUpdate.current = false;
            return;
        }
        getScheduledPickupsUpdate();
    }, [accountBase, filterDateStart, filterDateEnd]);

    useEffect(() => {
        if (!id) return;
        getAllScheduledPickups();
    }, [id]);

    const getAllScheduledPickups = async () => {
        try {
            const {status, data} = await axios.get(
                `${PARTNER_URI}/packagesPickupAppointment/getMyPickupAppointmentList`,
                {
                    params: {
                        partnerId: id,
                    },
                }
            );
            console.log(data)
            setMyPickups(data);
        } catch (e) {
            console.log(e);
        }
    };

    const cancelPickupByIndex = (index) => {
        setMyPickups((prev) => {
            prev[index].status = -1;
            return {...prev}
        })
    }

    const getScheduledPickupsUpdate = async () => {
        try {
            const {status, data} = await axios.get(
                `${PARTNER_URI}/packagesPickupAppointment/getMyPickupAppointmentList`,
                {
                    params: {
                        partnerId: id,
                        accountBase: accountBase !== "" ? accountBase : undefined,
                        appointDateStart: filterDateStart.isValid() !== null && filterDateStart.isValid() ? filterDateStart.toDate() : undefined,
                        appointDateEnd: filterDateEnd.isValid() !== null && filterDateEnd.isValid() ? filterDateEnd.toDate() : undefined,
                    },
                }
            );
            setMyPickups(data);
        } catch (e) {
            console.log(e);
        }
    };

    const onChangeFilterDateStart = (value) => {
        setFilterDateStart(value)
    }

    const onChangeFilterDateEnd = (value) => {
        setFilterDateEnd(value,)
    }
    let history = useHistory();

    // const formatTime = (timeString) => {
    //   const timeComponents = timeString.split(":");
    //   const [hours, minutes, seconds] = timeComponents
    //   console.log("hours, minutes, seconds", hours, minutes, seconds)
    //   return timeString
    // }

    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    width: "100%",
                    textAlign: "start",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Box
                    sx={{
                        textAlign: "center",
                    }}
                >
                    <Typography variant="h3">{intl.get('PICKUP_LIST.MY_SCHEDULED_PICKUPS')}</Typography>
                    <Grid
                        container
                        sx={
                            {
                                width: !matches ? "800px" : "100%",
                                mt: 2,
                            }
                        }
                    >
                        <Grid item xs={6}>
                            <Box
                                sx={{
                                    width: "100%",
                                    textAlign: "start",
                                }}
                            >
                                <ToggleButtonGroup
                                    value={accountBase}
                                    exclusive
                                    onChange={(e) => setAccountBase(e.target.value)}
                                    size="small"
                                    aria-label="text alignment"
                                >
                                    <ToggleButton value="" aria-label="left aligned">
                                        All
                                    </ToggleButton>
                                    <ToggleButton value="UPS" aria-label="centered">
                                        UPS
                                    </ToggleButton>
                                    <ToggleButton value="Canpar" aria-label="right aligned">
                                        Canpar
                                    </ToggleButton>
                                    <ToggleButton value="FedEx" aria-label="justified">
                                        Fedex
                                    </ToggleButton>
                                </ToggleButtonGroup>
                                <Tooltip title="Schedule A New Pickup Appointment" placement="top">
                                    <IconButton
                                        onClick={() => {
                                            history.push("/business/schedulepickup")
                                        }}
                                    >
                                        <AddIcon color="info" sx={{fontSize: 30}}/>
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{
                                display: "flex",
                            }}>
                                {/* <Typography>Filter:</Typography> */}
                                <LocalizationProvider error dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label={intl.get('PICKUP_LIST.START')}
                                        inputFormat="MM/DD/YYYY"
                                        value={filterDateStart}
                                        onChange={onChangeFilterDateStart}
                                        // disablePast
                                        size="small"
                                        maxDate={filterDateEnd}
                                        renderInput={(params) => (
                                            <TextField error size="small" {...params} />
                                        )}
                                        // error={formErrors.pickupDate}
                                    />
                                </LocalizationProvider>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label={intl.get('PICKUP_LIST.END')}
                                        inputFormat="MM/DD/YYYY"
                                        value={filterDateEnd}
                                        onChange={onChangeFilterDateEnd}
                                        // disablePast
                                        size="small"
                                        // shouldDisableDate={disableWeekends}
                                        minDate={filterDateStart}
                                        renderInput={(params) => (
                                            <TextField size="small" {...params} />
                                        )}
                                        // error={formErrors.pickupDate}
                                        // helperText={
                                        //   formErrors.pickupDate
                                        //     ? "Required Valid Date"
                                        //     : undefined
                                        // }
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box
                        sx={{
                            maxWidth: "800px",
                        }}
                    >
                        {myPickups.length > 0 ? (
                            <>
                                {myPickups.map((pickup, index) => {
                                    return (
                                        <BusinessMyScheduledPickupItem
                                            key={index}
                                            index={index}
                                            accountBase={pickup.account_base}
                                            accountNumber={pickup.account_number}
                                            appointDate={pickup.appoint_date}
                                            appointId={pickup.appoint_id}
                                            appointTimeEnd={pickup.appoint_time_end}
                                            appointTimeStart={pickup.appoint_time_start}
                                            confirmAppointNo={pickup.confirm_appoint_no}
                                            createdAt={pickup.created_at}
                                            estimateVolume={pickup.estimate_volume}
                                            estimateWeight={pickup.estimate_weight}
                                            numberOfCount={pickup.number_of_count}
                                            ownerRole={pickup.owner_role}
                                            partnerId={pickup.partner_id}
                                            pickupAddr={pickup.pickup_addr}
                                            pickupCity={pickup.pickup_city}
                                            pickupCountry={pickup.pickup_country}
                                            pickupEmail={pickup.pickup_email}
                                            pickupLabelUri={pickup.pickup_label_uri}
                                            pickupName={pickup.pickup_name}
                                            pickupPostalCode={pickup.pickup_postal_code}
                                            pickupProvince={pickup.pickup_province}
                                            pickupTel={pickup.pickup_tel}
                                            status={pickup.status}
                                            trackingNos={pickup.tracking_nos}
                                            updatedAt={pickup.updated_at}
                                            volumeUnit={pickup.volume_unit}
                                            weightUnit={pickup.weight_unit}
                                            cancelPickupByIndex={cancelPickupByIndex}
                                            trackingNumbers={pickup.tracking_nos}
                                            pickupLocation={pickup.pickup_location}
                                            remarkNotes={pickup.remark_notes}
                                            finalTotal={pickup?.final_total}
                                            crmInvoice={pickup?.crm_invoice_no}
                                            total={pickup?.total}
                                        />
                                    );
                                })}
                            </>
                        ) : (
                            <Box
                                sx={{
                                    maxWidth: "800px",
                                    // backgroundColor: "grey"
                                }}
                            >
                                <Typography>{intl.get('PICKUP_LIST.NO_PICKUPS')}</Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default BusinessMyScheduledPickups;
