import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    MenuItem,
    Select,
    useMediaQuery
} from "@mui/material";
import Button from "@mui/material/Button";
import {InputAdornment} from "@material-ui/core";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    duplicateShippingLTLQuickQuotePalletList,
    removeShippingLTLQuickQuotePalletList,
    updateShippingLTLQuickQuotePalletList
} from "../../../actions/shippingLTLAction";
import {convertCMToIN, convertINToCM, convertKGToLB, convertLBToKG, generateRandomString} from "../../../Utils/Helper";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import {HSCODE_SEARCH_PARTNER} from "../../../Utils/apiUrl";
import {Autocomplete} from "@material-ui/lab";
import {makeStyles} from "@material-ui/core/styles";
import remove from "../../../images/Delete.png";
import duplicate from "../../../images/Duplicate.png";
import intl from "react-intl-universal";
import CloseIcon from "@mui/icons-material/Close";

const palletType = [
    {name: '48"x40"', length: '48', width: '40'},
    {name: '48"x48"', length: '48', width: '48'},
    {name: 'Custom', length: null, width: null}
]

const palletTypeMetric = [
    {name: '122cmx102cm', length: '122', width: '102'},
    {name: '122cmx122cm', length: '122', width: '122'},
    {name: 'Custom', length: null, width: null}
]

const styles = {
    ShippingLTLQuickQuotePalletEachSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },

    ShippingLTLQuickQuotePalletEachActionSection: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },

    ShippingLTLQuickQuotePalletEachActionSectionMobile: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        justifyContent: 'flex-end',
    },
    ShippingLTLQuickQuotePalletEachSectionRow: {
        display: 'flex',
        alignItems: 'center'
        // justifyContent: 'flex-end',
    },
    ShippingPackageCreateLabelPackageEachWarningText: {
        fontSize: '12px',
        color: "#FF0303"
    },
}

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "0px", // Adjust the height as per your preference
            padding: "0px", // Adjust the padding as per your preference
            fontSize: "14px", // Adjust the font size as per your preference
        }
    }
});

export const ShippingLTLQuickQuotePalletEach = ({palletInfo, isGetRate, hasMissingValue}) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const isMobile = useMediaQuery('(max-width: 600px)');

    const metric = useSelector(state => state?.shippingLTL?.quickQuoteUnit);

    let moveFrom = useSelector(state => state?.shippingLTL?.quickQuoteMoveFrom);
    let moveTo = useSelector(state => state?.shippingLTL?.quickQuoteMoveTo);

    const [pallet, setPallet] = useState(palletInfo);
    const [HSCodeList, setHSCodeList] = useState([]);
    const [accordionOpen, setAccordionOpen] = useState(false);

    const toggleOpen = () => {
        setAccordionOpen(!accordionOpen);
    }

    const searchHSCode = async (value) => {

        try {
            const {data} = await axios.get(`${HSCODE_SEARCH_PARTNER}?query_string=${value}`);
            console.log('data', data);
            const hits = data.hits;
            const levelSixRecords = hits.filter(hit => {
                return hit["_source"].level === "6";
            })
            const filterResult = levelSixRecords?.map(record => {
                return record["_source"]
            })
            setHSCodeList(filterResult);

        } catch (e) {
            console.log(e);
        }
    }

    const handleDescription = event => {
        if (event.target.value) {
            setPallet(prevState => ({
                ...prevState,
                description: event.target.value
            }))
        }
    }

    const handlePackageType = (event, value) => {
        if (value) {
            if (value?.name === 'Custom') {
                setPallet(prevState => ({
                    ...prevState,
                    packagingType: value
                }))
            } else {
                setPallet(prevState => ({
                    ...prevState,
                    packagingType: value,
                    length: value?.length,
                    width: value?.width,
                    height: metric ? '152' : '60'
                }))
            }
        }
    }

    const handleLength = event => {
        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            setPallet(prevState => ({
                ...prevState,
                length: value
            }))
        }
    }

    const handleWidth = event => {
        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            setPallet(prevState => ({
                ...prevState,
                width: value
            }))
        }
    }

    const handleHeight = event => {
        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            if (pallet?.packagingType?.name === 'Custom') {
                setPallet(prevState => ({
                    ...prevState,
                    height: value
                }))
            } else {
                const height = metric ? '229' : '90';
                setPallet(prevState => ({
                    ...prevState,
                    height: value > (+height) ? height : value
                }))
            }
        }
    }

    const handleWeight = event => {
        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            const weight = metric ? '1360' : '3000';
            setPallet(prevState => ({
                ...prevState,
                weight: value > (+weight) ? weight : value
            }))
        }
    }

    const handleQuantity = event => {
        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            setPallet(prevState => ({
                ...prevState,
                quantity: value
            }))
        }
    }

    const handlePrice = event => {
        setPallet(prevState => ({
            ...prevState,
            priceEach: event.target.value < 1 ? '1' : event.target.value
        }))
    }

    const handleCurrency = event => {
        setPallet(prevState => ({
            ...prevState,
            currency: event.target.value
        }))
    }

    const handleCountryCode = event => {
        setPallet(prevState => ({
            ...prevState,
            originCountryCode: event.target.value
        }))
    }

    const handleUOM = event => {
        setPallet(prevState => ({
            ...prevState,
            UOM: event.target.value
        }))
    }

    const handleOnInputChangeHSCode = async (event, value) => {
        await searchHSCode(value);
        setPallet(prevState => ({
            ...prevState,
            hscode: value
        }))
    }

    const handleRemove = () => {
        dispatch(removeShippingLTLQuickQuotePalletList(pallet));
    }

    const handleDuplicate = () => {
        const palletId = generateRandomString(8);
        const duplicatedPallet = {...pallet};
        duplicatedPallet.palletId = palletId;
        duplicatedPallet.description = `${duplicatedPallet.description}-copy`;
        dispatch(duplicateShippingLTLQuickQuotePalletList(duplicatedPallet));
    }

    const updatePackagingType = (metric, pallet) => {
        if (metric) {
            if (pallet?.name === '48"x40"') {
                return {name: '122cmx102cm', length: '122', width: '102'}
            } else if (pallet?.name === '48"x48"') {
                return {name: '122cmx122cm', length: '122', width: '122'}
            } else {
                return pallet;
            }
        } else {
            if (pallet?.name === '122cmx102cm') {
                return {name: '48"x40"', length: '48', width: '40'}

            } else if (pallet?.name === '122cmx122cm') {
                return {name: '48"x48"', length: '48', width: '48'}
            } else {
                return pallet;
            }
        }
    }

    useEffect(() => {

        const newPackagingType = updatePackagingType(metric, palletInfo?.packagingType);

        console.log('new packaging type', newPackagingType);

        if (newPackagingType?.name === 'Custom') {
            setPallet(prevState => ({
                ...prevState,
                lengthUnit: metric ? 'cm' : 'in',
                weightUnit: metric ? 'kg' : 'lb',
                packagingType: newPackagingType,
                weight: metric ? convertLBToKG(pallet?.weight) : convertKGToLB(pallet?.weight),
                length: metric ? convertINToCM(pallet?.length) : convertCMToIN(pallet?.length),
                width: metric ? convertINToCM(pallet?.width) : convertCMToIN(pallet?.width),
                height: metric ? convertINToCM(pallet?.height) : convertCMToIN(pallet?.height),

            }))
        } else {
            setPallet(prevState => ({
                ...prevState,
                lengthUnit: metric ? 'cm' : 'in',
                weightUnit: metric ? 'kg' : 'lb',
                packagingType: newPackagingType,
                length: newPackagingType?.length,
                width: newPackagingType?.width,
                height: metric ? convertINToCM(pallet?.height) : convertCMToIN(pallet?.height),
                weight: metric ? convertLBToKG(pallet?.weight) : convertKGToLB(pallet?.weight)
            }))
        }

    }, [metric]);

    useEffect(() => {
        setPallet(palletInfo);
    }, [palletInfo?.palletId])

    useEffect(() => {
        dispatch(updateShippingLTLQuickQuotePalletList(pallet));
    }, [pallet]);

    useEffect(() => {
        if (moveFrom?.province?.countryCode === moveTo?.province?.countryCode) {
            setAccordionOpen(false);
        } else {
            setAccordionOpen(true);
        }
    }, [moveFrom, moveTo])

    // console.log('pallet info from parent', palletInfo);
    // console.log('pallet info from child', pallet);

    return (
        <Grid container spacing={1}>
            <Grid item md={2} xs={12}>
                {/*<Grid container spacing={1}>*/}
                {/*<Grid item md={5} xs={6}>*/}
                {/*    <Box sx={styles.ShippingLTLQuickQuotePalletEachSection}>*/}
                {/*        <InputLabel required sx={{fontSize: '14px'}}>*/}
                {/*            {intl.get('ADD_PALLET.PALLET_NAME')}*/}
                {/*        </InputLabel>*/}
                {/*        <TextField*/}
                {/*            disabled={isGetRate}*/}
                {/*            value={pallet?.description}*/}
                {/*            onInput={handleDescription}*/}
                {/*            fullWidth*/}
                {/*            variant='outlined'*/}
                {/*            placeholder='description'*/}
                {/*            size='small'*/}
                {/*            inputProps={{style: {fontSize: 14}}} // font size of input text*/}
                {/*        />*/}
                {/*        <Typography style={styles.ShippingPackageCreateLabelPackageEachWarningText}>*/}
                {/*            {*/}
                {/*                hasMissingValue && (!pallet?.description && intl.get('SHIPPING_INFORMATION.REQUIRED'))*/}
                {/*            }*/}
                {/*        </Typography>*/}
                {/*    </Box>*/}
                {/*</Grid>*/}
                {/*<Grid item md={7} xs={6}>*/}
                <Box sx={styles.ShippingLTLQuickQuotePalletEachSection}>
                    <InputLabel required sx={{fontSize: '14px'}}>
                        {intl.get('ADD_PALLET.PALLET_TYPE')}
                    </InputLabel>
                    <Autocomplete
                        disabled={isGetRate}
                        value={pallet?.packagingType}
                        fullWidth
                        options={metric ? palletTypeMetric : palletType}
                        getOptionLabel={option => option.name}
                        onChange={handlePackageType}
                        renderInput={(params) => <TextField
                            variant="outlined"
                            {...params}
                            className={classes.smallInput}
                        />}
                    />
                </Box>
                {/*</Grid>*/}
                {/*</Grid>*/}
            </Grid>
            <Grid item md={5} xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <Box sx={styles.ShippingLTLQuickQuotePalletEachSection}>
                            <InputLabel required sx={{fontSize: '14px'}}>
                                {intl.get('ADD_PALLET.LENGTH')}
                            </InputLabel>
                            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                <TextField
                                    disabled={isGetRate || (pallet?.packagingType?.name !== 'Custom')}
                                    value={pallet?.length}
                                    onInput={handleLength}
                                    fullWidth
                                    variant='outlined'
                                    type='number'
                                    placeholder='length'
                                    size='small'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {pallet?.lengthUnit}
                                            </InputAdornment>
                                        ),
                                        inputProps: {
                                            min: 1
                                        },
                                        style: {fontSize: 14}
                                    }}
                                />
                                <Typography style={styles.ShippingPackageCreateLabelPackageEachWarningText}>
                                    {
                                        pallet?.length > 0 ? "" : intl.get('ADD_PALLET.MISSING_INVALID')
                                    }
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box sx={styles.ShippingLTLQuickQuotePalletEachSection}>
                            <InputLabel required sx={{fontSize: '14px'}}>
                                {intl.get('ADD_PALLET.WIDTH')}
                            </InputLabel>
                            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                <TextField
                                    disabled={isGetRate || (pallet?.packagingType?.name !== 'Custom')}
                                    value={pallet?.width}
                                    onInput={handleWidth}
                                    fullWidth
                                    variant='outlined'
                                    type='number'
                                    placeholder='width'
                                    size='small'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {pallet?.lengthUnit}
                                            </InputAdornment>
                                        ),
                                        inputProps: {
                                            min: 1
                                        },
                                        style: {fontSize: 14}
                                    }}
                                />
                                <Typography style={styles.ShippingPackageCreateLabelPackageEachWarningText}>
                                    {
                                        pallet?.width > 0 ? "" : intl.get('ADD_PALLET.MISSING_INVALID')
                                    }
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box sx={styles.ShippingLTLQuickQuotePalletEachSection}>
                            <InputLabel required sx={{fontSize: '14px'}}>
                                {intl.get('ADD_PALLET.HEIGHT')}
                            </InputLabel>
                            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                <TextField
                                    disabled={isGetRate}
                                    value={pallet?.height}
                                    onInput={handleHeight}
                                    fullWidth
                                    variant='outlined'
                                    type='number'
                                    placeholder='height'
                                    size='small'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {pallet?.lengthUnit}
                                            </InputAdornment>
                                        ),
                                        inputProps: {
                                            min: 1
                                        },
                                        style: {fontSize: 14}
                                    }}
                                />
                                <Typography style={styles.ShippingPackageCreateLabelPackageEachWarningText}>
                                    {
                                        pallet?.height > 0 ? "" : intl.get('ADD_PALLET.MISSING_INVALID')
                                    }
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={4} xs={12}>
                <Grid container spacing={1}>
                    <Grid item md={5} xs={6}>
                        <Box sx={styles.ShippingLTLQuickQuotePalletEachSection}>
                            <InputLabel required sx={{fontSize: '14px'}}>
                                {intl.get('ADD_PALLET.WEIGHT')}
                            </InputLabel>
                            <TextField
                                disabled={isGetRate}
                                value={pallet?.weight}
                                onInput={handleWeight}
                                fullWidth
                                variant='outlined'
                                type='number'
                                size='small'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {pallet?.weightUnit}
                                        </InputAdornment>
                                    ),
                                    inputProps: {
                                        min: 1
                                    },
                                    style: {fontSize: 14}
                                }}
                            />
                            <Typography style={styles.ShippingPackageCreateLabelPackageEachWarningText}>
                                {
                                    pallet?.weight > 0 ? "" : intl.get('ADD_PALLET.MISSING_INVALID')
                                }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item md={2} xs={2}>
                        <Box sx={{
                            display: 'flex',
                            height: '100%',
                            alignItems: 'flex-end',
                            justifyContent: 'center'
                        }}>
                            <CloseIcon sx={{
                                fontSize: '35px',
                                color: '#1D8B45'
                            }}/>
                        </Box>
                    </Grid>
                    <Grid item md={5} xs={4}>
                        <Box sx={styles.ShippingLTLQuickQuotePalletEachSection}>
                            <InputLabel required sx={{fontSize: '14px'}}>
                                {intl.get('ADD_PALLET.QUANTITY')}
                            </InputLabel>
                            <TextField
                                disabled={isGetRate}
                                value={pallet?.quantity}
                                onInput={handleQuantity}
                                fullWidth
                                variant='outlined'
                                type='number'
                                size='small'
                                InputProps={{
                                    inputProps: {
                                        min: 1
                                    },
                                    style: {fontSize: 14}
                                }}
                            />
                            <Typography style={styles.ShippingPackageCreateLabelPackageEachWarningText}>
                                {
                                    pallet?.quantity > 0 ? "" : intl.get('ADD_PALLET.MISSING_INVALID')
                                }
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={1} xs={12}>
                <Box sx={{
                    display: 'flex',
                    // flexDirection: isMobile ? 'row' : 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    height: '100%',
                    width: '100%',
                    gap: '10px'
                }}>
                    <IconButton
                        color='success'
                        onClick={handleDuplicate}
                        disabled={isGetRate}
                        sx={{padding: 0}}
                    >
                        <img src={duplicate} width='30px'/>
                    </IconButton>
                    <IconButton
                        color='error'
                        onClick={handleRemove}
                        disabled={isGetRate}
                        sx={{padding: 0}}
                    >
                        <img src={remove} width='30px'/>
                    </IconButton>
                </Box>
            </Grid>
            {/*<Grid item md={12}>*/}
            {/*    <Accordion*/}
            {/*        expanded={accordionOpen}*/}
            {/*        onChange={toggleOpen}*/}
            {/*    >*/}
            {/*        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>*/}
            {/*            <Box sx={styles.ShippingLTLQuickQuotePalletEachSectionRow}>*/}
            {/*                <IconButton*/}
            {/*                    onClick={() => window.location.href = 'https://uucargo.ca/support/cross-border-shipping-parcels/'}>*/}
            {/*                    <HelpIcon color="info" sx={{fontSize: 25}}/>*/}
            {/*                </IconButton>*/}
            {/*                <Typography>*/}
            {/*                    {intl.getHTML('CROSS_BORDER.CROSS_BORDER_TITLE')}*/}
            {/*                </Typography>*/}
            {/*            </Box>*/}
            {/*        < /AccordionSummary>*/}
            {/*        <AccordionDetails>*/}
            {/*            <Grid container spacing={1}>*/}
            {/*                <Grid item md={2} xs={6}>*/}
            {/*                    <Box sx={styles.ShippingLTLQuickQuotePalletEachSection}>*/}
            {/*                        <InputLabel required sx={{fontSize: '14px'}}>*/}
            {/*                            {intl.get('CROSS_BORDER.PRICE')}*/}
            {/*                        </InputLabel>*/}
            {/*                        <TextField*/}
            {/*                            value={pallet?.priceEach}*/}
            {/*                            onInput={handlePrice}*/}
            {/*                            fullWidth*/}
            {/*                            variant='outlined'*/}
            {/*                            type='number'*/}
            {/*                            size='small'*/}
            {/*                            InputProps={{*/}
            {/*                                inputProps: {*/}
            {/*                                    min: 1*/}
            {/*                                },*/}
            {/*                                style: {fontSize: 14}*/}
            {/*                            }}*/}
            {/*                        />*/}
            {/*                    </Box>*/}
            {/*                </Grid>*/}
            {/*                <Grid item md={2} xs={6}>*/}
            {/*                    <Box sx={styles.ShippingLTLQuickQuotePalletEachSection}>*/}
            {/*                        <InputLabel required sx={{fontSize: '14px'}}>*/}
            {/*                            {intl.get('CROSS_BORDER.CURRENCY')}*/}
            {/*                        </InputLabel>*/}
            {/*                        <FormControl fullWidth>*/}
            {/*                            <Select*/}
            {/*                                value={pallet?.currency}*/}
            {/*                                onChange={handleCurrency}*/}
            {/*                                size='small'*/}
            {/*                                sx={{fontSize: 14}}*/}
            {/*                            >*/}
            {/*                                <MenuItem value={'CAD'}>CAD</MenuItem>*/}
            {/*                                <MenuItem value={'USD'}>USD</MenuItem>*/}
            {/*                            </Select>*/}
            {/*                        </FormControl>*/}
            {/*                    </Box>*/}
            {/*                </Grid>*/}
            {/*                <Grid item md={2} xs={6}>*/}
            {/*                    <Box sx={styles.ShippingLTLQuickQuotePalletEachSection}>*/}
            {/*                        <InputLabel required sx={{fontSize: '14px'}}>*/}
            {/*                            {intl.get('CROSS_BORDER.COUNTRY')}*/}
            {/*                        </InputLabel>*/}
            {/*                        <FormControl fullWidth>*/}
            {/*                            <Select*/}
            {/*                                value={pallet?.originCountryCode}*/}
            {/*                                onChange={handleCountryCode}*/}
            {/*                                size='small'*/}
            {/*                                sx={{fontSize: 14}}*/}
            {/*                            >*/}
            {/*                                {*/}
            {/*                                    getCountryCode().map(v => {*/}
            {/*                                        return <MenuItem value={v.code}>{v.name}</MenuItem>*/}
            {/*                                    })*/}
            {/*                                }*/}
            {/*                            </Select>*/}
            {/*                        </FormControl>*/}
            {/*                    </Box>*/}
            {/*                </Grid>*/}
            {/*                <Grid item md={2} xs={6}>*/}
            {/*                    <Box sx={styles.ShippingLTLQuickQuotePalletEachSection}>*/}
            {/*                        <InputLabel required sx={{fontSize: '14px'}}>*/}
            {/*                            {intl.get('CROSS_BORDER.UOM')}*/}
            {/*                        </InputLabel>*/}
            {/*                        <FormControl fullWidth>*/}
            {/*                            <Select*/}
            {/*                                value={pallet?.UOM}*/}
            {/*                                onChange={handleUOM}*/}
            {/*                                size='small'*/}
            {/*                                sx={{fontSize: 14}}*/}
            {/*                            >*/}
            {/*                                {UOMList.map(v => {*/}
            {/*                                    return <MenuItem value={v}>{v}</MenuItem>*/}
            {/*                                })}*/}
            {/*                            </Select>*/}
            {/*                        </FormControl>*/}
            {/*                    </Box>*/}
            {/*                </Grid>*/}
            {/*                <Grid item md={4} xs={12}>*/}
            {/*                    <Box sx={styles.ShippingLTLQuickQuotePalletEachSection}>*/}
            {/*                        <InputLabel required sx={{fontSize: '14px'}}>*/}
            {/*                            {intl.get('CROSS_BORDER.HS_CODE')}*/}
            {/*                        </InputLabel>*/}
            {/*                        <Autocomplete*/}
            {/*                            disablePortal*/}
            {/*                            freeSolo*/}
            {/*                            options={HSCodeList}*/}
            {/*                            getOptionLabel={option => option.hscode}*/}
            {/*                            filterOptions={(options, state) => options}*/}
            {/*                            fullWidth*/}
            {/*                            onInputChange={handleOnInputChangeHSCode}*/}
            {/*                            renderInput={(params) => <TextField*/}
            {/*                                {...params}*/}
            {/*                                placeholder={pallet?.hscode}*/}
            {/*                                className={classes.smallInput}*/}
            {/*                            />}*/}
            {/*                            renderOption={(option) => (*/}
            {/*                                <Typography*/}
            {/*                                    style={{fontSize: '12px'}}*/}
            {/*                                >*/}
            {/*                                    /!*<Typography>*!/*/}
            {/*                                    {option.hscode} | {option.desc}*/}
            {/*                                    /!*</Typography>*!/*/}
            {/*                                </Typography>*/}
            {/*                            )}*/}
            {/*                        />*/}
            {/*                    </Box>*/}
            {/*                </Grid>*/}
            {/*            </Grid>*/}
            {/*        </AccordionDetails>*/}
            {/*    </Accordion>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={12}>*/
            }
            {/*    {pallet?.palletId}*/
            }
            {/*</Grid>*/
            }
        </Grid>
    )
}