import Grid from "@material-ui/core/Grid";
import {Divider, Pagination, useMediaQuery} from "@mui/material";
import Typography from "@mui/material/Typography";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Box from "@mui/material/Box";
import {getAccessToken} from "../../../Utils/doToken";
import axios from "axios";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import {useEffect, useState} from "react";
import {BusinessBillingInvoicesListEach} from "./BusinessBillingInvoicesListEach";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import {triggerBase64Download} from "common-base64-downloader-react";
import Modal from "@mui/material/Modal";
import {BusinessBillingPayment} from "./BusinessBillingPayment";
import intl from "react-intl-universal";
import {greenButtonContained, yellowButtonContained} from "../../../Utils/styleConfig";

const styles = {
    BillingInvoicesListTextLeft: {
        fontSize: '14px',
        textAlign: 'left'
    },
    BillingInvoicesListTextCenter: {
        fontSize: '14px',
        textAlign: 'center'
    },
    BillingInvoicesListTextRight: {
        fontSize: '14px',
        textAlign: 'right'
    }
}

export const BusinessBillingInvoicesList = ({invoiceNumber, status}) => {

    const isMobile = useMediaQuery('(max-width: 600px)');

    const storedToken = getAccessToken("access_token");

    const [invoiceList, setInvoiceList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [selectInvoice, setSelectInvoice] = useState([]);
    const [paymentOpen, setPaymentOpen] = useState(false);

    const handlePaymentOpen = () => setPaymentOpen(true);
    const handlePaymentClose = () => setPaymentOpen(false);

    const cbSelectInvoice = (invoice) => {
        console.log('invoice', invoice);

        const invoiceIndex = selectInvoice.findIndex(eachInvoice => eachInvoice?.id === invoice?.id);

        if (invoiceIndex !== -1) {
            const updatedInvoices = [...selectInvoice];
            updatedInvoices.splice(invoiceIndex, 1);
            setSelectInvoice(updatedInvoices);
        } else {
            setSelectInvoice([...selectInvoice, invoice])
        }
    }

    const downloadPDFByInvoiceId = async (invoiceId) => {
        try {
            const storedToken = getAccessToken("access_token");
            const requestURL = `${PARTNER_URI}/billingCenter/downloadInvoice`;
            const {data} = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    invoiceId: invoiceId
                }
            })
            console.log('data', data);
            const content = data.content;
            const contentType = data["content-type"];
            const pdfContent = `data:${contentType};base64,${content}`;
            const fileName = data.filename?.length > 4 ? data.filename.slice(0, data.filename.length - 4) : data.filename;
            console.log('pdf content', pdfContent);
            console.log('file name', fileName);
            triggerBase64Download(pdfContent, fileName);
        } catch (e) {
            console.log(e.response);
        }
    }

    const handleDownInvoice = () => {
        if (selectInvoice.length > 0) {
            selectInvoice.forEach(invoiceEach => {
                downloadPDFByInvoiceId(invoiceEach?.id);
            })
        }
    }

    const getInvoicesList = async () => {
        // setSelectInvoice([]);
        setLoading(true);
        const requestURL = `${PARTNER_URI}/billingCenter/getAllInvoiceListFromCrmForThirdParty`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    page: 0,
                    pageSize: 10,
                    invoiceType: status,
                    ref: invoiceNumber
                }
            });
            console.log('result', result);
            setInvoiceList(result?.data?.result);
            setCurrentPage(result?.data?.pagination?.currentPage + 1);
            setLastPage(result?.data?.pagination?.lastPage + 1);
            setError(false);
            setLoading(false);
        } catch (e) {
            console.log(e);
            setError(true);
            setLoading(false);
        }
    }

    const getInvoicesListByPage = async (page) => {
        setLoading(true);
        const requestURL = `${PARTNER_URI}/billingCenter/getAllInvoiceListFromCrmForThirdParty`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    page: page,
                    pageSize: 10,
                    invoiceType: status,
                    ref: invoiceNumber
                }
            });
            console.log('result', result);
            setInvoiceList(result?.data?.result);
            setError(false);
            setLoading(false);
        } catch (e) {
            console.log(e);
            setError(true);
            setLoading(false);
        }
    }

    const handlePageChange = (event, value) => {
        console.log('page change', value);
        setCurrentPage(value);
        const page = value - 1;
        getInvoicesListByPage(page);
    };

    useEffect(() => {
        setInvoiceList([]);
        getInvoicesList();
    }, [invoiceNumber, status]);

    if (loading) {
        return (
            <Box>
                <CircularProgress/>
            </Box>
        )
    }

    if (error && !loading) {
        return <>
            <h3>Sorry We currently encounter some issues, please try again later.</h3>
        </>
    }

    console.log('select invoice', selectInvoice);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item md={4} xs={5}>
                <Box sx={{display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'flex-start'}}>
                    <CheckBoxOutlineBlankIcon sx={{width: '20px'}}/>
                    <Typography style={styles.BillingInvoicesListTextLeft}>
                        {intl.get('BILLING_CENTER.INVOICE_NO')}
                    </Typography>
                </Box>
            </Grid>
            {
                !isMobile &&
                <Grid item xs={2}>
                    <Box sx={{display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'flex-start'}}>
                        <Typography style={styles.BillingInvoicesListTextLeft}>
                            {intl.get('BILLING_CENTER.INVOICE_DATE')}
                        </Typography>
                    </Box>
                </Grid>
            }
            <Grid item md={4} xs={4}>
                <Box sx={{display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'center'}}>
                    <Typography style={styles.BillingInvoicesListTextLeft}>
                        {intl.get('BILLING_CENTER.AMOUNT')}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={2} xs={3}>
                <Box sx={{display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'center'}}>
                    <Typography style={styles.BillingInvoicesListTextLeft}>
                        {intl.get('BILLING_CENTER.STATUS')}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            {
                invoiceList?.length > 0 ? invoiceList?.map((invoice, index) =>
                    <Grid item xs={12} key={index}>
                        <BusinessBillingInvoicesListEach index={index} invoice={invoice} cbSelectInvoice={cbSelectInvoice}
                                                         selectInvoice={selectInvoice}/>
                    </Grid>
                ) : <Grid item xs={12}>
                    <Box sx={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                        <Typography>
                            {intl.get('BILLING_CENTER.NO_RESULT')}
                        </Typography>
                    </Box>
                </Grid>
            }
            {invoiceList?.length > 0 &&
                <>
                    <Grid item xs={12}>
                        <Box sx={{marginTop: '30px'}}>
                            <Pagination
                                count={lastPage}
                                page={+currentPage}
                                onChange={handlePageChange}
                                size='small'
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{display: 'flex', justifyContent: 'flex-end', marginTop: '20px', gap: '10px'}}>
                            {/*{status === 'unpaid' &&*/}
                            {selectInvoice?.every((invoice) => invoice.invoiceType === 'Unpaid') &&
                                <Button
                                    variant='contained'
                                    sx={greenButtonContained}
                                    onClick={handlePaymentOpen}
                                    disabled={selectInvoice?.length === 0}
                                >
                                    <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                                        {intl.get('BILLING_CENTER.PAY_SELECTED')}
                                    </Typography>
                                </Button>
                            }
                            {/*{selectInvoice?.every((invoice) => invoice.invoiceType !== 'Unpaid') &&*/}
                            <LoadingButton
                                variant='contained'
                                sx={yellowButtonContained}
                                onClick={handleDownInvoice}
                                disabled={selectInvoice?.length === 0}
                            >
                                <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                                    {intl.get('BILLING_CENTER.DOWNLOAD_SELECTED')}
                                </Typography>
                            </LoadingButton>
                            {/*}*/}
                        </Box>
                    </Grid>
                </>
            }
            <Modal
                open={paymentOpen}
                onClose={handlePaymentClose}
            >
                <BusinessBillingPayment handlePaymentClose={handlePaymentClose} selectInvoice={selectInvoice}/>
            </Modal>
        </Grid>
    )
}