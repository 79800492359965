import React from 'react';
import { object } from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

const LoadingContainer = ({ classes }) => (
  <div className={classes.root}>
    <CircularProgress />
  </div>
);

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 50,
    width: '100%',
    height: '100%',
  },
};

LoadingContainer.propTypes = {
  classes: object.isRequired,
};

export default withStyles(styles)(LoadingContainer);
