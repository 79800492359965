import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";

export const MovingOrderDashboardCancel = () => {

    return (
        <Box sx={{
            border: '1px solid #B2B2B2',
            borderRadius: '10px',
            padding: '10px 0'
        }}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '20px',
                        borderBottom: '1px solid #B2B2B2',
                        alignItems: 'center'
                    }}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            Refund Details
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={7}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-start',
                        padding: '0 20px'
                    }}>
                        <Typography>
                            Refund
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    <Typography style={{textAlign: 'left'}}>
                        $ 50.00
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}