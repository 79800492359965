import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Button, IconButton} from "@mui/material";
import Divider from "@mui/material/Divider";
import AddIcon from "@material-ui/icons/Add";
import * as React from "react";
import {MyBoxesList} from "./MyBoxesList";
import {useDispatch, useSelector} from "react-redux";
import {toggleAddMyBoxes} from "../../actions/myBoxesAction";
import {MyBoxesAdd} from "./MyBoxesAdd";
import {MyBoxesEdit} from "./MyBoxesEdit";

const style = {
    MyBoxesRoot: {
        display: 'flex',
        flexDirection: 'column',
        width: '1000px',

        margin: 'auto',
        borderRadius: '15px'
    },
    MyBoxesSection: {
        display: 'flex',
        flexDirection: 'column',
        margin: '10px 20px'
    },
    MyBoxesListHeader: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    MyBoxesList: {
        backgroundColor: '#F9F9F9',
        display: 'flex',
        flexDirection: 'column',
        margin: '10px 20px'
    }
}

export const MyBoxes = () => {

    let isAddMyBoxes = useSelector(state => state?.myBoxes?.isAddMyBoxes);
    let isEditMyBoxes = useSelector(state => state?.myBoxes?.isEditMyBoxes);
    const dispatch = useDispatch();

    const handleAddMyBox = () => {
        dispatch(toggleAddMyBoxes(true));
    }

    console.log('add boxes', isAddMyBoxes);

    return (
        <Box sx={style.MyBoxesRoot}>
            <Box sx={style.MyBoxesSection}>
                <Typography variant="h4" sx={{textAlign: 'center'}}>
                    My Boxes List
                </Typography>
            </Box>
            {/*<Box sx={style.MyBoxesSection}>*/}
            {/*    {openItemForm && <RatingItemForm/>}*/}
            {/*</Box>*/}
            {!isAddMyBoxes && !isEditMyBoxes && <Box sx={style.MyBoxesList}>
                <Box sx={style.MyBoxesListHeader}>
                    <Button onClick={handleAddMyBox}>
                        <IconButton>
                            <AddIcon/>
                        </IconButton>
                        Add Box
                    </Button>
                </Box>
                <Divider/>
                <Box>
                    <MyBoxesList/>
                </Box>
            </Box>}
            {isAddMyBoxes && <MyBoxesAdd/>}
            {isEditMyBoxes && <MyBoxesEdit/>}
        </Box>
    )
}