import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@material-ui/core/Grid";
import intl from "react-intl-universal";

const styles = {
    BillingAddressRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    BillingAddressContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px'
    },
    BillingAddressTitle: {
        fontWeight: '600',
        textAlign: 'left',
    },
    BillingAddressSubTitle: {
        fontWeight: '600',
        textAlign: 'left',
        fontSize: '14px'
    }
}

export const BusinessBillingAddress = ({partnerInfo}) => {
    return (

        <Box sx={styles.BillingAddressRoot}>
            <Box sx={styles.BillingAddressContent}>
                <Typography style={styles.BillingAddressTitle}>
                    {intl.get('BILLING_CENTER.BILLING_ADDRESS')}
                </Typography>
            </Box>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                        {intl.get('BILLING_CENTER.NAME')}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                        {partnerInfo?.firstname} {partnerInfo?.lastname}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                        {intl.get('BILLING_CENTER.COMPANY_NAME')}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                        {partnerInfo?.business_name}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                        {intl.get('BILLING_CENTER.ADDRESS')}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                        {partnerInfo?.address}, {partnerInfo?.city}, {partnerInfo?.province}, {partnerInfo?.postal_code}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}