import {Container, Snackbar} from "@mui/material";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getAccessToken} from "../../Utils/doToken";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useEffect, useState} from "react";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import {ShippingPackageInternationShipmentLocation} from "./ShippingPackageInternationShipmentLocation";
import intl from "react-intl-universal";
import {isValidPostalCode} from "../../Utils/Helper";
import {ShippingPackageQuickQuotePackage} from "./ShippingPackageQuickQuotePackage";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {
    toggleQuickQuoteShippingPackageIsGetRate,
    updateQuickQuoteShippingPackageRateList
} from "../../actions/shippingPackageAction";
import Checkbox from "@mui/material/Checkbox";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import {ReactComponent as USAIcon} from "../../images/homepageIcon/USA.svg";
import {ReactComponent as CanadaIcon} from "../../images/homepageIcon/CA.svg";
import PublicIcon from "@mui/icons-material/Public";
import {ReactComponent as NorthAmericanIcon} from "../../images/icons/Canada&USA.svg";
import {ReactComponent as InternationalIcon} from "../../images/icons/International.svg";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingPackageQuickQuoteRoot: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        // margin: '100px 0'
    },
    ShippingPackageQuickQuoteSection: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '10px',
        display: 'flex',
        flexDirection: 'column'
    },
    ShippingPackageQuickQuoteButtonSection: {
        // backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        // borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '10px',
        display: 'flex',
        flexDirection: 'column'
    },
    ShippingPackageQuickQuoteHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
}

const requiredProperties = ["length", "width", "height", "quantity", "weight", "lengthUnit", "weightUnit"];
const validateRequiredProperties = ["length", "width", "height", "weight"];

export const ShippingPackageInternationShipment = () => {

    const dispatch = useDispatch();

    const token = getAccessToken("access_token");

    const history = useHistory();

    const isMobile = useMediaQuery('(max-width: 600px)');

    let packageListRedux = useSelector(state => state?.shippingPackage?.quickQuotePackageList);
    let shipFromRedux = useSelector(state => state?.shippingPackage?.createLabelShipFrom);
    let shipToRedux = useSelector(state => state?.shippingPackage?.createLabelShipTo);

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [locationValidation, setLocationValidation] = useState(null);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const getShippingPackageRate = async (selectedAccountBase) => {
        setLoading(true);
        let requestURL = `${NODE_ROUTE_URI}/looseItem/ratesByAccountBase`;
        let data = {
            rateData: {
                shipFromAddress: "",
                shipFromAddressTwo: "",
                shipFromCity: shipFromRedux?.city,
                shipFromProvince: shipFromRedux?.province?.code,
                shipFromPostalCode: shipFromRedux?.postalCode,
                shipFromCountry: shipFromRedux?.country,
                shipFromName: "",
                shipFromPhone: "",
                shipToAddress: "",
                shipToAddressTwo: "",
                shipToCity: shipToRedux?.city,
                shipToPostalCode: shipToRedux?.postalCode,
                shipToProvince: shipToRedux?.province?.code,
                shipToCountry: shipToRedux?.country,
                shipToName: "",
                shipToPhone: "",
                packageData: packageListRedux,
            },
            selectedAccountBase: selectedAccountBase
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            });
            const rateData = result?.data?.result;
            const statusCode = result?.data?.statusCode;
            // console.log('result data', result.data);

            if (statusCode === 200) {
                const updatedRateData = rateData.map((each) => ({
                    ...each,
                    errorAccountBase: selectedAccountBase?.toUpperCase()
                }));

                dispatch(updateQuickQuoteShippingPackageRateList(updatedRateData));
                history.push('/shipping-package/international-shipment/quote-list');
                setLocationValidation(prevState => ({
                    ...prevState,
                    missingPostalCode: false
                }))
            } else if (statusCode === 400) {
                setErrorMessage('Please including the all postal code, and try again.');
                handleErrorMessage();
                setLocationValidation(prevState => ({
                    ...prevState,
                    missingPostalCode: true
                }))
            } else {
                setErrorMessage('There is no available package rate based on the information you provided. Please ensure that you have provided valid and accurate information and try again. If you continue to experience issues, your account may be forbidden from accessing rates. Please contact op@uucargo.ca for assistance.');
                handleErrorMessage();
                setLocationValidation(prevState => ({
                    ...prevState,
                    missingPostalCode: false
                }))
            }
        } catch (e) {
            console.log('error', e.response);
        } finally {
            setLoading(false);
        }
    }

    const validate = () => {
        setErrorMessage('');
        setToastOpen(false);
        const hasMissingValues = packageListRedux.some(object => requiredProperties.some((property) => !object[property]));
        const hasValidateValues = packageListRedux.some(object => validateRequiredProperties.some((property) => (object[property]) <= 0));
        // const hasMissingValuesCrossBorder = CrossBorderHasMissingValues();
        const hasOverweightObject = packageListRedux.some(obj => {
            if (obj.weightUnit === 'kg') {
                const weightInLbs = obj.weight * 2.20462; // Convert kg to lbs
                return weightInLbs > 150;
            } else {
                return obj.weight > 150;
            }
        });
        const hasOverlengthItemName = packageListRedux?.some(obj => {
            return obj?.description?.length > 30;
        })

        const shipFromPostalCodeValid = shipFromRedux?.country?.toLowerCase() === 'ca' ? isValidPostalCode(shipFromRedux?.postalCode) : true;
        const shipToPostalCodeValid = shipToRedux?.country?.toLowerCase() === 'ca' ? isValidPostalCode(shipToRedux?.postalCode) : true;

        packageListRedux?.length === 0 && setErrorMessage(prevState => prevState + 'Your pallet list is empty.');
        (!shipFromRedux?.country || ((shipFromRedux?.country?.toLowerCase() === 'ca' || shipFromRedux?.country?.toLowerCase() === 'us') && !shipFromRedux?.postalCode)) && setErrorMessage(prevState => prevState + 'Ship from address is missing one or more required fields.');
        (!shipToRedux?.country || ((shipToRedux?.country?.toLowerCase() === 'ca' || shipToRedux?.country?.toLowerCase() === 'us') && !shipToRedux?.postalCode)) && setErrorMessage(prevState => prevState + 'Ship to address is missing one or more required fields.');
        // hasMissingValues && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.PACKAGE_LIST_MISSING'));
        hasValidateValues && setErrorMessage(prevState => prevState + 'Your package list has one or more invalid fields.');
        hasOverweightObject && setErrorMessage(prevState => prevState + 'In your package list, there is at least one package that weighs over 150 pounds.');
        // hasOverlengthItemName && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.OVER_CHARACTER'));
        !shipFromPostalCodeValid && setErrorMessage(prevState => prevState + 'Ship From postal code is invalid.');
        !shipToPostalCodeValid && setErrorMessage(prevState => prevState + 'Ship To postal code is invalid.');

        const validation = {
            shipFromCountry: !shipFromRedux?.country,
            shipFromPostalCode: ((shipFromRedux?.country?.toLowerCase() === 'ca' || shipFromRedux?.country?.toLowerCase() === 'us') && !shipFromRedux?.postalCode),
            shipToCountry: !shipToRedux?.country,
            shipToPostalCode: ((shipToRedux?.country?.toLowerCase() === 'ca' || shipToRedux?.country?.toLowerCase() === 'us') && !shipToRedux?.postalCode),
            shipFromPostalCodeValid: !shipFromPostalCodeValid,
            shipToPostalCodeValid: !shipToPostalCodeValid
        }

        setLocationValidation(validation);

        if (
            shipFromRedux?.country &&
            shipToRedux?.country &&
            !((shipFromRedux?.country?.toLowerCase() === 'ca' || shipFromRedux?.country?.toLowerCase() === 'us') && !shipFromRedux?.postalCode) &&
            !((shipToRedux?.country?.toLowerCase() === 'ca' || shipToRedux?.country?.toLowerCase() === 'us') && !shipToRedux?.postalCode) &&
            packageListRedux.length !== 0 &&
            !hasMissingValues &&
            !hasValidateValues &&
            !hasOverweightObject &&
            !hasOverlengthItemName &&
            shipFromPostalCodeValid &&
            shipToPostalCodeValid
        ) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const handleGetRate = () => {

        if ((shipFromRedux?.country?.toLowerCase() === 'ca' || shipFromRedux?.country?.toLowerCase() === 'us') && (shipToRedux?.country?.toLowerCase() === 'ca' || shipToRedux?.country?.toLowerCase() === 'us')) {
            setErrorMessage("In accordance with the information provided, please utilize the 'North America Package' instead of the 'International Package'.");
            handleErrorMessage();
        } else {
            const validationResult = validate();
            if (validationResult) {
                console.log('[ShippingPackageInternationShipment] call api');
                getShippingPackageRate('ups');
            }
        }
    }

    useEffect(() => {
        dispatch(toggleQuickQuoteShippingPackageIsGetRate(false));
    },[])

    console.log('[ShippingPackageInternationShipment] shipFromRedux', shipFromRedux);
    console.log('[ShippingPackageInternationShipment] shipToRedux', shipToRedux);
    console.log('[ShippingPackageInternationShipment] packageListRedux', packageListRedux);

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box sx={styles.ShippingPackageQuickQuoteRoot}>
                <Box sx={styles.ShippingPackageQuickQuoteSection}>
                    <Typography style={styles.ShippingPackageQuickQuoteHeading}>
                        Select Your Packaging Type / Service
                    </Typography>
                    <Box sx={{
                        width: isMobile ? '300px' : '100%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        flexWrap: 'wrap',
                        gap: '10px'
                    }}>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Checkbox
                                icon={<RadioButtonUncheckedIcon/>}
                                checkedIcon={<CheckCircleIcon/>}
                                color="success"
                                onClick={() => history.push('/shipping-package/quick-quote')}
                            />
                            <NorthAmericanIcon width="40" height="40"/>
                            <Typography style={{color: '#545454', padding: '0 5px'}}>
                                North America Shipment
                            </Typography>
                        </Box>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Checkbox
                                icon={<RadioButtonUncheckedIcon/>}
                                checkedIcon={<CheckCircleIcon/>}
                                color="success"
                                checked
                            />
                            <InternationalIcon width="40" height="40"/>
                            <Typography style={{color: '#545454', padding: '0 5px'}}>
                                International Shipment
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box sx={styles.ShippingPackageQuickQuoteSection}>
                    <ShippingPackageInternationShipmentLocation locationValidation={locationValidation}/>
                </Box>
                <Box sx={styles.ShippingPackageQuickQuoteSection}>
                    <ShippingPackageQuickQuotePackage packageListRedux={packageListRedux}/>
                </Box>
                {
                    errorMessage && (((shipFromRedux?.country?.toLowerCase() === 'ca' || shipFromRedux?.country?.toLowerCase() === 'us') && !shipFromRedux?.postalCode) || ((shipToRedux?.country?.toLowerCase() === 'ca' || shipToRedux?.country?.toLowerCase() === 'us') && !shipToRedux?.postalCode)) &&
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '20px'
                    }}>
                        <Typography style={{
                            color: '#FF0303'
                        }}>
                            {errorMessage}
                        </Typography>
                    </Box>
                }
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginBottom: '50px'
                }}>
                    <LoadingButton
                        variant='contained'
                        sx={{
                            backgroundColor: '#1D8B45',
                            "&:hover": {
                                backgroundColor: '#1D8B45',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        onClick={handleGetRate}
                        loading={loading}
                    >
                        <Typography style={{textTransform: 'none'}}>
                            Get Rates
                        </Typography>
                    </LoadingButton>
                </Box>
            </Box>
        </Container>
    )
}