import React, {useEffect, useRef, useState} from 'react';
import {
  Box,
  Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar,
  TextField,
  useMediaQuery,
} from "@material-ui/core";
import dayjs from 'dayjs';
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { Alert, Pagination } from "@material-ui/lab";
import axios from "axios";
import { useSelector } from "react-redux";
import InputLabel from "@material-ui/core/InputLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import OceanShippingTable from "./OceanShippingTable";
import DateRangeCalendar from "./Calendar/DateRangeCalendar";
import {addDays} from "date-fns";
import {PARTNER_URI} from "../../../Utils/apiUrl"; // theme css file

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  buttonFilesWrapper: {
    border: "2px dashed #d3d3d3",
    borderRadius: "2px",
    background: "#fdf5e8",
    padding: "5px",
    wordBreak: "break-word",
  },
  hscodeListWrapper: {
    border: "2px solid lightgray",
    borderRadius: "5px",
    width: "98%",
    maxHeight: "45vh",
    height: "fit-content",
    margin: "10px auto",
    overflowY: "auto",
    background: "#fff",
  },
  table: {
    width: "100%",
  },
  tbody: {
    "& tr:nth-child(odd)": {
      backgroundColor: "rgba(222, 222, 222, 0.4)",
    },
  },
  nameTH: {
    width: "100px",
  },
  idTH: {
    width: "100px",
  },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
    display: "flex",
    justifyContent: "center",
  },
  calendarContainer: {
    display: "flex",
    justifyContent: "center"
  },
  container: {
    overflowX: "auto"
  }
}));

// const defaultStartPortList = [
//   {
//     label: "SHANGHAI",
//     value: "SHANGHAI"
//   }
// ]
//
// const defaultEndPortList = [
//   {
//     label: "SINGAPORE",
//     value: "SINGAPORE"
//   }
// ]

const defaultShippingData = [{
  carrier: "RCL",
  "start_port": "SHANGHAI",
  "end_port": "SINGAPORE",
  "sell_20gp": "1110.000",
  "sell_40gp": "2200.000",
  "sell_40hq": "2200.000",
  "route_code": "RKI",
  "date": "2022-08-29",
  surcharge: [
    {
      "price_20": 656,
      "price_40": 998,
      "price_40hq": 998,
      "CurrencyName": "CNY",
      "SurchargeName": "码头操作费",
      "SurchargeNameEn": "Dock Handling Fee",
    },
    {
      "price_20": 656,
      "price_40": 998,
      "price_40hq": 998,
      "CurrencyName": "CNY",
      "SurchargeName": "封条费",
      "SurchargeNameEn": "Container Seal Charge",
    }
  ]
}]

const ShippingListTracking = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:600px)");
  const pageSize = 100;
  const MAX_DATE = dayjs().add(1, 'month')
  const [oceanShippingData, setOceanShippingData] = useState([]);


  // const partnerInfo = getPartnerInfo();
  const partnerInfo = useSelector((state) => state.user);

  const { register, handleSubmit, control } = useForm();
  const [page, setPage] = useState(1);
  const [totalNumber, setTotalNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [is20GP, setIs20GP] = useState(true);
  const [is40GP, setIs40GP] = useState(true);
  const [is40HQ, setIs40HQ] = useState(true);
  const [date, setDate] = useState(dayjs());
  const [highLightDates, setHighLightDates] = useState([]);
  const [isHighLightDatesLoading, setIsHighLightDatesLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [availableDates, setAvailableDates] = useState([]);

  const [startPortList, setStartPortList] = useState([]);
  const [endPortList, setEndPortList] = useState([]);


  const [dateRange, setDateRange] = useState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }
      ]
  );
  const [startPort, setStartPort] = useState("");
  const [endPort, setEndPort] = useState("");
  const [startPortCode, setStartPortCode] = useState("");

  const getHighLightDatesFromAvailableDatesByMonth = (month, availableDateArray) => {
    const result = [0];
    for (const availableDate of availableDateArray) {
      if (availableDate.month() === month) {
        result.push(availableDate.date());
      }
    }
    return result;
  }

  const handleMonthChange = (selectedDate) => {
    const selectedMonth = selectedDate.month();
    setHighLightDates(getHighLightDatesFromAvailableDatesByMonth(selectedMonth, availableDates));
  };

  const getAvailableDates = async ({ startPort, endPort }) => {
    try {
      setIsHighLightDatesLoading(true);
      const { data } = await axios.post(`${PARTNER_URI}/ocean-freight/date`, {
        startPort,
        endPort,
      });
      const result = data?.result;
      console.log("result:", result);
      const filterDates = result.filter(v => {
        return v["20gp"] !== null;
      })
      const mapAvailableDates = filterDates.map(v => {
        return dayjs(v?.time2).toDate();
      })
      setAvailableDates(mapAvailableDates);
      // setHighLightDates(getHighLightDatesFromAvailableDatesByMonth(date.month(), mapAvailableDates));
    } catch (e) {
      console.log(e);
      // setOceanShippingData([]);
      // setErrorMessage(e?.message || "Error!");
    } finally {
      // setIsHighLightDatesLoading(false);
    }
  }

  const onGetDatesSubmit = data => {
    const { startPort, endPort } = data;
    if (!startPort || !endPort) {
      setErrorMessage("Please enter Start Port and End Port");
      return;
    }
    setErrorMessage("");
    getAvailableDates({ startPort, endPort });
  }


  const getData = async ({ startPort, endPort, time }) => {
    try {
      setIsLoading(true);
      const {data} = await axios.post(`${PARTNER_URI}/ocean-freight/sea`, {
        startPort,
        endPort,
        time
      });
      const result = data?.result;
      console.log(result);

      const shippingDataArray = result.data;
      const surChargeArray = result.surcharge;

      for (const shippingData of shippingDataArray) {
        const carrier = shippingData.carrier;
        shippingData.surcharge = surChargeArray[carrier];
      }
      const resultTotalNumber = shippingDataArray.length;
      setTotalNumber(resultTotalNumber);
      setOceanShippingData(shippingDataArray);
    }
    catch (e) {
      // setTotalNumber(0);
      console.log(e);
      // setOceanListData([]);
      setOceanShippingData([]);
      setTotalNumber(0);
      setErrorMessage(e?.message || "Error!");
    } finally {
      setIsLoading(false);
    }
  }

  const onSubmit = data => {
    console.log(data);
    const { startPort, endPort } = data;

    if (!startPort || !endPort) {
      setErrorMessage("Please enter Start Port and End Port");
      return;
    }

    const startDate = dayjs(dateRange[0].startDate);
    let endDate = dayjs(dateRange[0].endDate);
    const afterSevenDays = startDate.add(7, "days");
    if (endDate.isAfter(afterSevenDays)) {
      console.log("After!")
      endDate = afterSevenDays;
      setDateRange([{
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
        key: 'selection'
      }])
    }
    const startDateString = startDate.format("YYYY-MM-DD");
    const endDateString = endDate.format("YYYY-MM-DD");
    getData({ startPort, endPort, time: [startDateString, endDateString] });
  }

  const afterDateChange = (startDate, endDate) => {
    if (startPort !== "" && endPort !== "") {
      const afterSevenDays = startDate.add(7, "days");
      if (endDate.isAfter(afterSevenDays)) {
        console.log("After!")
        endDate = afterSevenDays;
        setDateRange([{
          startDate: startDate.toDate(),
          endDate: endDate.toDate(),
          key: 'selection'
        }])
      }
      const startDateString = startDate.format("YYYY-MM-DD");
      const endDateString = endDate.format("YYYY-MM-DD");
      getData({ startPort, endPort, time: [startDateString, endDateString] });
    }
  }

  useEffect(() => {
    if (startPort !== "" && endPort !== "") {
      getAvailableDates({ startPort, endPort });
    }

  }, [startPort, endPort]);

  const getModalPortSearchResult = async (portName, isStart) => {
    try {
      const { data } = await axios.get(`${PARTNER_URI}/elasticsearch/oceanPorts?query_string=${portName}`);
      const result = [];
      for (const hit of data?.hits) {
        const source = hit._source;
        const portData = {
          label: `${source?.port_en_name} | ${source?.port_code}`,
          value: source?.port_en_name
        }
        if (isStart) {
          setModalStartCode(source?.port_code);
        } else {
          setModalEndCode(source?.port_code);
        }

        result.push(portData);
      }
      return result;
    } catch (e) {
      console.log(e);
    }
  }


  const getPortSearchResult = async (portName) => {
    try {
      const { data } = await axios.get(`${PARTNER_URI}/elasticsearch/oceanPorts?query_string=${portName}`);
      const result = [];
      for (const hit of data?.hits) {
        const source = hit._source;
        const portData = {
          label: `${source?.port_en_name} | ${source?.port_code}`,
          value: source?.port_en_name
        }
        setStartPortCode(source?.port_code);
        result.push(portData);
      }
      return result;
    } catch (e) {
      console.log(e);
    }
  }


  // const handlePageChange = (e, value) => {
  //   if (value !== page) {
  //     console.log(value);
  //     setPage(value);
  //     const { order_id, product_id , ref_other_no, account_base, cargo_control_no, send_to_name, send_to_addr, send_to_tel } = cacheInput.current;
  //     getData({ order_id, product_id, ref_other_no, account_base, currentPage: value, cargo_control_no, send_to_name, send_to_addr, send_to_tel });
  //   }
  // }

  const [toastOpen, setToastOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [placeOrderLoading, setPlaceOrderLoading] = useState(false);
  const { id, firstname, lastname, email, phone } = useSelector((state) => state.user);
  const [twentyGPQuantity, setTwentyGPQuantity] = useState(0);
  const [fortyGPQuantity, setFortyGPQuantity] = useState(0);
  const [fortyHQQuantity, setFortyHQQuantity] = useState(0);
  const [modalDateRange, setModalDateRange] = useState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }
      ]
  );
  const [modalStartPort, setModalStartPort] = useState("");
  const [modalEndPort, setModalEndPort] = useState("");
  const [modalStartPortList, setModalStartPortList] = useState([]);
  const [modalEndPortList, setModalEndPortList] = useState([]);
  const [modalStartCode, setModalStartCode] = useState("");
  const [modalEndCode, setModalEndCode] = useState("");
  const [comment, setComment] = useState("");


  const handleToastClick = () => {
    setToastOpen(true);
  };

  const handleToastClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  const handleConfirmDialogOpen = () => {
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const confirmPlaceOrder = async (e) => {
    e.preventDefault();
    const modalStartDate = dayjs(modalDateRange[0].startDate).format("YYYY-MM-DD")
    const modalEndDate = dayjs(modalDateRange[0].endDate).format("YYYY-MM-DD")
    const requestBody = {
      partner_info: {
        partner_email: email,
        partner_id: id,
        firstname: firstname,
        lastname: lastname,
        phone: phone
      },
      route_info: {
        start_port: startPort,
        end_port: endPort,
        start_port_code: modalStartCode,
        end_port_code: modalEndCode,
        sailing_date_range: [modalStartDate, modalEndDate],
        remark_note: comment
      },
      shipping_containers: [
        {
          container_type: "20GP",
          quantity: twentyGPQuantity
        },
        {
          container_type: "40GP",
          quantity: fortyGPQuantity
        },
        {
          container_type: "40HQ",
          quantity: fortyHQQuantity
        }
      ]
    }
    console.log(requestBody);
    try {
      setPlaceOrderLoading(true);
      const { data } = await axios.post(`${PARTNER_URI}/ocean-freight/customize`, requestBody);
      console.log(data);
      setErrorMessage("");
      handleToastClick();
      handleConfirmDialogClose();
    } catch (e) {
      setErrorMessage(e?.response?.data?.errorMessage || "Error!");
      console.log(e);
    } finally {
      setPlaceOrderLoading(false);
    }
  }

  return (
      <Box className={`${classes.container} oceanPage`}>
        {/*Pop-up Toast*/}
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={toastOpen}
            onClose={handleToastClose}
            autoHideDuration={3000}
            message="Cancel Order">
          {(() => {
            if (errorMessage !== "") {
              return (
                  <Alert onClose={handleToastClose} severity="error" sx={{ width: '100%' }}>
                    Place Order Failed!
                    <hr />
                    Error: {errorMessage}
                  </Alert>
              )
            }
            return (
                <Alert onClose={handleToastClose} severity="success" sx={{ width: '100%' }}>
                  Submit Success! We would contact you soon.
                </Alert>
            )
          })()}

        </Snackbar>
        {/*Pop-up Toast*/}


        {/*Confirm Place Order Dialog*/}
        <Dialog
            open={confirmDialogOpen}
            onClose={handleConfirmDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Request quotes
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Please select the route information, we will contact you soon after submission.
              <hr />
              <br />
              <Box display="flex" flexDirection="column" sx={{ gap: "0.5rem" }}>
                <Box display="flex" flexWrap="wrap" sx={{ gap: "2rem" }}>
                  <Box width="100%">
                    <Autocomplete
                        disablePortal
                        options={modalStartPortList}
                        fullWidth
                        filterOptions={(options, state) => options}
                        getOptionLabel={(option) => option.label}
                        {...register("modalStartPort")}
                        onChange={(e, value) => {
                          setModalStartPort(value.value);
                        }}
                        onInputChange={async (e, value) => {
                          setModalStartPort("");
                          const result = await getModalPortSearchResult(value, true);
                          console.log(result);
                          setModalStartPortList(result);
                        }}
                        renderInput={(params) => <TextField id="outlined-basic" label="Start Port&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;e.g. Shanghai | 上海" variant="outlined" {...register("modalStartPort")} fullWidth {...params} required />}
                    />
                  </Box>
                  <Box width="100%">
                    <Autocomplete
                        disablePortal
                        options={modalEndPortList}
                        filterOptions={(options, state) => options}
                        fullWidth
                        getOptionLabel={(option) => option.label}
                        {...register("modalEndPort")}
                        onChange={(e, value) => {
                          console.log("End Port", value);
                          setModalEndPort(value.value);
                        }}
                        onInputChange={async (e, value) => {
                          setModalEndPort("");
                          const result = await getModalPortSearchResult(value, false);
                          setModalEndPortList(result);
                        }}
                        renderInput={(params) => (
                            <TextField id="outlined-basic" label="End Port&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;e.g. Singapore | 新加坡" variant="outlined" {...register("modalEndPort")} fullWidth {...params} required />
                        )
                    }
                    />
                  </Box>
                  {/*<Box width="100%" className={classes.calendarContainer}>*/}
                  {/*  <ServerRequestDatePicker maxDate={MAX_DATE} value={date} setValue={setDate} isLoading={isHighLightDatesLoading} setIsLoading={isHighLightDatesLoading} highLightDays={highLightDates} onMonthChange={handleMonthChange} />*/}
                  {/*</Box>*/}

                  <Box width="100%" textAlign="center">
                    <DateRangeCalendar dateRange={modalDateRange} setDateRange={setModalDateRange} afterDateChange={() => {}} availableDates={[]} />
                  </Box>
                </Box>
                <TextField
                    id="outlined-basic"
                    label="20GP"
                    variant="outlined"
                    defaultValue={twentyGPQuantity}
                    onChange={e => {
                      setTwentyGPQuantity(+e.target.value);
                    }}
                    type="number"
                    value={twentyGPQuantity}
                    inputProps={{ min: 0 }}
                />
                <TextField
                    id="outlined-basic"
                    label="40GP"
                    variant="outlined"
                    defaultValue={fortyGPQuantity}
                    onChange={e => {
                      setFortyGPQuantity(+e.target.value);
                    }}
                    type="number"
                    inputProps={{ min: 0 }}
                />
                <TextField
                    id="outlined-basic"
                    label="40HQ"
                    variant="outlined"
                    defaultValue={fortyHQQuantity}
                    onChange={e => {
                      setFortyHQQuantity(+e.target.value);
                    }}
                    type="number"
                    inputProps={{ min: 0 }}
                />
                <br />
                <TextField
                    id="outlined-basic"
                    label="Remark notes"
                    variant="outlined"
                    defaultValue={comment}
                    onChange={e => {
                      setComment(e.target.value);
                    }}
                />
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleConfirmDialogClose} variant="outlined" autoFocus type="button" >No</Button>
            <Button onClick={confirmPlaceOrder} variant="outlined" type="button" disabled={placeOrderLoading} >
              {placeOrderLoading && <CircularProgress size={14} />}
              {!placeOrderLoading && "Confirm"}
            </Button>
          </DialogActions>
        </Dialog>
        {/*Confirm Place Order Dialog*/}


        <Box>
          <Box component="form" width={"1000px"} margin="0 auto" sx={{
            display: "flex", flexDirection: "column", justifyContent: "space-evenly", gap: "2rem",
            alignItems: "center", alignContent: "center"
          }}>
            <h1>Ocean Shipping</h1>
            <Box display="flex" flexWrap="wrap" sx={{ gap: "2rem" }}>
              <Box width="100%">
                <Autocomplete
                    disablePortal
                    options={startPortList}
                    fullWidth
                    filterOptions={(options, state) => options}
                    getOptionLabel={(option) => option.label}
                    {...register("startPort")}
                    onChange={(e, value) => {
                      setStartPort(value.value);
                    }}
                    onInputChange={async (e, value) => {
                      setStartPort("");
                      const result = await getPortSearchResult(value);
                      console.log(result);
                      setStartPortList(result);
                    }}
                    renderInput={(params) => <TextField id="outlined-basic" label="Start Port&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;e.g. Shanghai | 上海" variant="outlined" {...register("startPort")} fullWidth {...params} required />}
                />
              </Box>
              <Box width="100%">
                <Autocomplete
                    disablePortal
                    options={endPortList}
                    filterOptions={(options, state) => options}
                    fullWidth
                    getOptionLabel={(option) => option.label}
                    {...register("endPort")}
                    onChange={(e, value) => {
                      console.log("End Port", value);
                      setEndPort(value.value);
                    }}
                    onInputChange={async (e, value) => {
                      setEndPort("");
                      const result = await getPortSearchResult(value);
                      setEndPortList(result);
                    }}
                    renderInput={(params) => (
                        <TextField id="outlined-basic" label="End Port&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;e.g. Singapore | 新加坡" variant="outlined" {...register("endPort")} fullWidth {...params} required />
                    )
                }
                />
              </Box>
              {/*<Box width="100%" className={classes.calendarContainer}>*/}
              {/*  <ServerRequestDatePicker maxDate={MAX_DATE} value={date} setValue={setDate} isLoading={isHighLightDatesLoading} setIsLoading={isHighLightDatesLoading} highLightDays={highLightDates} onMonthChange={handleMonthChange} />*/}
              {/*</Box>*/}

              <Box width="100%">
                <DateRangeCalendar dateRange={dateRange} setDateRange={setDateRange} afterDateChange={afterDateChange} availableDates={availableDates} />
              </Box>
            </Box>
          </Box>

          <br />
          {
            isLoading ? (
                <>
                  <Box>
                    <h1>Loading...</h1>
                  </Box>
                </>
            ) : (
                <>
                  <Box>
                    <Box display="flex" flexDirection="row" justifyContent="center" alignContent="center" alignItems="center" sx={{gap: "2rem"}}>
                      <h2>Found {totalNumber} results</h2>
                      <Box justifyContent="center" alignContent="center" alignItems="center" width="400px">
                        <Button onClick={handleConfirmDialogOpen} style={{
                          borderRadius: 35
                        }}>Don't find your routes? Request quotes</Button>
                      </Box>
                    </Box>
                    <hr width="100%" />
                    <Box>
                      <Box mt={2}>
                        <table className={classes.table}>
                          <thead>
                          <tr>
                            <th className={classes.idTH}>Start Port &nbsp;&nbsp;&nbsp;--> &nbsp;&nbsp;&nbsp;End Port</th>
                            <th className={classes.nameTH}>
                              {/*<Box display="flex" justifyContent="center" alignContent="center" flexDirection="column">*/}
                              {/*  <span>20 GP</span>*/}
                              {/*  <span>40 GP</span>*/}
                              {/*  <span>40 HQ</span>*/}
                              {/*</Box>*/}
                              Price
                            </th>
                            <th className={classes.nameTH}>Voyage</th>
                            <th className={classes.nameTH}>Ship Company & Route Code</th>
                            <th className={classes.nameTH}>Sailing Date</th>
                            <th className={classes.nameTH}>Operation</th>
                          </tr>
                          </thead>
                          <tbody className={classes.tbody}>
                          {oceanShippingData?.map((v, index) => {
                            return <OceanShippingTable date={v.sailing_date}
                                                       startPortCode={startPortCode}
                                                       endPort={v.end_port}
                                                       endPortCode={v.endPortCode}
                                                       routeCode={v.route_code}
                                                       startPort={v.start_port}
                                                       fortyGP={v.sell_40gp}
                                                       fortyHQ={v.sell_40hq}
                                                       shipCompany={v.carrier}
                                                       twentyGP={v.sell_20gp}
                                                       voyage={v.voyage}
                                                       surcharge={v.surcharge}
                                                       booking={() => {
                                                         console.log("Booking!")}
                            }
                            />
                          })}
                          </tbody>
                        </table>
                      </Box>
                    </Box>
                    <br />
                    {/*<Box margin="auto" sx={{ display: "flex", width: "100%", justifyContent: "center" }}>*/}
                    {/*  <Pagination count={Math.floor(totalNumber / pageSize) + 1}*/}
                    {/*              page={page}*/}
                    {/*              onChange={handlePageChange}*/}
                    {/*              className={classes.pagination}*/}
                    {/*              variant="outlined"*/}
                    {/*              shape="rounded" />*/}
                    {/*</Box>*/}
                  </Box>
                </>
            )
          }
        </Box>
      </Box>
  );
};

export default ShippingListTracking;
