import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Button} from "@mui/material";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import {useDispatch, useSelector} from "react-redux";
import {openRatingEditItemForm, removeItem} from "../../actions/ratingAction";
import {RatingItemEdit} from "./RatingItemEdit";

const style = {
    RatingItemEachRoot: {
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 10px',
    },
    RatingItemEachItem: {
        marginBottom: '10px',
    },
    RatingItemEachItemDetail: {
        display: 'flex',
        flexDirection: 'column'
    },
    RatingItemEachItemQuantity: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    RatingItemEachItemButton: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
    }
}

export const RatingItemEach = ({itemDetail}) => {

    const dispatch = useDispatch();

    let openEditForm = useSelector(state => state?.rating?.openItemEdit)

    const handleRemoveItem = () => {
        dispatch(removeItem(itemDetail.itemId));
    }

    const handleOpenEditItemForm = () => {
        dispatch(openRatingEditItemForm(true, itemDetail.itemId));
    }

    return (
        <Box sx={style.RatingItemEachRoot}>
            <Grid container spacing={2} sx={style.RatingItemEachItem}>
                <Grid item xs={8} sx={style.RatingItemEachItemDetail}>
                    <Typography  style={{ wordWrap: 'break-word', whiteSpace: 'pre-line'}} sx={{fontSize: '25px', fontWeight: '600'}}>
                        {itemDetail.name}
                    </Typography>
                    <Box>
                        <Typography sx={{fontSize: '15px'}}>
                            Weight: {itemDetail.weight} {itemDetail.weightUnit}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography>
                            Dimension (L x W x
                            H): {itemDetail.length || 0} x {itemDetail.length || 0} x {itemDetail.length || 0} {itemDetail.dimensionUnit}
                        </Typography>
                    </Box>
                    {itemDetail.itemId}
                </Grid>
                <Grid item xs={2} sx={style.RatingItemEachItemQuantity}>
                    <Typography sx={{fontSize: '25px', fontWeight: '600'}}>
                        {itemDetail.quantity}
                    </Typography>
                </Grid>
                <Grid item xs={2} sx={style.RatingItemEachItemButton}>
                    <Button onClick={handleOpenEditItemForm}>Edit</Button>
                    <Button color='error' onClick={handleRemoveItem}>Remove</Button>
                </Grid>
            </Grid>
            {openEditForm.status && openEditForm.itemId === itemDetail.itemId &&
                <RatingItemEdit itemDetail={itemDetail}/>}
            <Divider/>
        </Box>
    )
}