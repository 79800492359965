import {
  DRIVER_ADD_DELIVER_SHIPMENTS,
  DRIVER_SELECT_DELIVER_SHIPMENTS,
  DRIVER_REMOVE_SELECTED_DELIVER_SHIPMENTS,
  DRIVER_CLEAN_DELIVER_SHIPMENTS_DATA,
  DRIVER_REMOVE_ALL_SELECTED_DELIVER_SHIPMENTS,
  DRIVER_MARK_DELIVERED_TRACKING_NUMBER,
} from "../actions/types";
import produce from "immer";

const initialState = {
  deliverPackages: {},
  selectedPackages: {},
  selectedTrackingNumber: [],
  selectedShipmentNumber: [],
  deliverRequestData: [],
  deliveredTrackingNumbers: [],
};

export const driverDeliverShipmentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case DRIVER_ADD_DELIVER_SHIPMENTS:
      const {
        package_number,
        item_name,
        cover_image,
        shipment_number,
        tracking_number,
        checked,
        stage,
        taskId
      } = action.payload;

      const temp = {};
      temp[`${tracking_number[0].tracking_number}`] = checked;
      return produce(state, (draft) => {
        draft["deliverPackages"][taskId] = {
          item_name: item_name,
          cover_image: cover_image,
          shipment_numbers: {
            [shipment_number]: { ...temp },
          },
          // shipment_number: shipment_number,
          stage: stage,
          taskId: taskId
        };
      });
      // return state;

    case DRIVER_SELECT_DELIVER_SHIPMENTS:
      return produce(state, (draft) => {
        draft["deliverPackages"][
          action.payload.taskId
        ].shipment_numbers[action.payload.shipment_number][
          action.payload.tracking_number
        ] = action.payload.checked;
        if (
          draft.selectedPackages[action.payload.taskId] === undefined
        ) {
          draft["selectedPackages"][action.payload.taskId] = {
            item_name: action.payload.item_name,
            cover_image: action.payload.cover_image,
            shipment_numbers: {
              [action.payload.shipment_number]: {
                [action.payload.tracking_number]: action.payload.checked,
              },
            },
            stage: action.payload.stage,
            taskId: action.payload.taskId
          };
        } else if (
          draft.selectedPackages[action.payload.taskId]
            .shipment_numbers[action.payload.taskId] === undefined
        ) {
          //selecting tracking number, same package but diff shipment number
          draft.selectedPackages[
            action.payload.taskId
          ].shipment_numbers[action.payload.shipment_number] = {
            [action.payload.tracking_number]: action.payload.checked,
          };
        } else {
          //selecting tracking number, same package number, same shipment number
          draft["selectedPackages"][
            action.payload.taskId
          ].shipment_numbers[action.payload.shipment_number][
            action.payload.tracking_number
          ] = action.payload.checked;
        }
        draft.selectedTrackingNumber.push(action.payload.tracking_number);

        const isShipmentNumberExisted = state.selectedShipmentNumber.findIndex(
          (ele) => ele.shipment_number === action.payload.shipment_number
        );
        if (isShipmentNumberExisted < 0) {
          draft.selectedShipmentNumber.push({
            shipment_number: action.payload.shipment_number,
          });
        }

        //prepare request data for deliver shipment
        const target = draft.deliverRequestData.find(
          (ele) => ele.taskId === action.payload.taskId
        );
        if (!target) {
          draft.deliverRequestData.push({
            shipment_number: action.payload.shipment_number,
            tracking_numbers: [action.payload.tracking_number],
            stage: action.payload.stage,
            taskId: action.payload.taskId
          });
        } else {
          target.tracking_numbers.push(action.payload.tracking_number);
        }
      });
    case DRIVER_REMOVE_SELECTED_DELIVER_SHIPMENTS:
      //change deliver packages's tracking number to false
      return produce(state, (draft) => {
        draft["deliverPackages"][action.payload.package_number][
          "shipment_numbers"
        ][action.payload.shipment_number][
          action.payload.tracking_number
        ] = false;

        //remove tracking number in selected packages
        delete draft["selectedPackages"][action.payload.package_number][
          "shipment_numbers"
        ][action.payload.shipment_number][action.payload.tracking_number];

        //remove shipment if shipment has no tracking numbers
        if (
          Object.keys(
            draft.selectedPackages[action.payload.package_number]
              .shipment_numbers[action.payload.shipment_number]
          ).length === 0
        ) {
          delete draft["selectedPackages"][action.payload.package_number][
            "shipment_numbers"
          ][action.payload.shipment_number];

          const index = draft.selectedShipmentNumber.findIndex(
            (num) => num.shipment_number === action.payload.shipment_number
          );
          if (index !== -1) draft.selectedShipmentNumber.splice(index, 1);
        }

        //remove package if package has no shipments
        if (
          Object.keys(
            draft.selectedPackages[action.payload.package_number]
              .shipment_numbers
          ).length === 0
        ) {
          delete draft["selectedPackages"][action.payload.package_number];
        }

        //removing tracking number
        const index = draft.selectedTrackingNumber.findIndex(
          (num) => num === action.payload.tracking_number
        );
        if (index !== -1) draft.selectedTrackingNumber.splice(index, 1);

        //prepare request data for deliver shipment
        const targetS = draft.deliverRequestData.findIndex(
          (ele) => ele.shipment_number === action.payload.shipment_number
        );
        if (targetS !== -1) {
          const targetT = draft.deliverRequestData[
            targetS
          ].tracking_numbers.findIndex(
            (ele) => ele === action.payload.tracking_number
          );
          if (targetT !== -1) {
            draft.deliverRequestData[targetS].tracking_numbers.splice(
              targetT,
              1
            );
          }
        }

        if (draft.deliverRequestData[targetS].tracking_numbers.length === 0) {
          draft.deliverRequestData.splice(targetS, 1);
        }
      });

    case DRIVER_CLEAN_DELIVER_SHIPMENTS_DATA:
      return produce(state, (draft) => {
        draft.deliverPackages = {};
        draft.selectedPackages = {};
        draft.selectedTrackingNumber = [];
        draft.selectedShipmentNumber = [];
        draft.deliverRequestData = [];
      });

    case DRIVER_REMOVE_ALL_SELECTED_DELIVER_SHIPMENTS:
      return produce(state, (draft) => {
        for (const p in state.deliverPackages) {
          for (const s in state.deliverPackages[p].shipment_numbers) {
            for (const t in state.deliverPackages[p].shipment_numbers[s]) {
              if (state.deliverPackages[p].shipment_numbers[s][t] === true) {
                draft.deliverPackages[p].shipment_numbers[s][t] = false;
              }
            }
          }
        }

        draft.selectedPackages = {};
        draft.selectedTrackingNumber = [];
        draft.selectedShipmentNumber = [];
        draft.deliverRequestData = [];
        draft.deliveredTrackingNumbers = [];
      });

    case DRIVER_MARK_DELIVERED_TRACKING_NUMBER:
      return produce(state, (draft) => {
        draft.deliveredTrackingNumbers.push(...action.payload);
      });
    default:
      return state;
  }
};
