import Grid from "@material-ui/core/Grid";
import intl from "react-intl-universal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import {
    SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE,
    SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_PICKUP_CODE
} from "../../shared";
import {extractFullAddress, isValidatePhone} from "../../../Utils/Helper";
import {getAccessToken} from "../../../Utils/doToken";
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import {useEffect, useState} from "react";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {
    updateShippingLTLQuickQuoteConsigneeInfo,
    updateShippingLTLQuickQuoteMoveFromAddition,
    updateShippingLTLQuickQuoteMoveToAddition,
    updateShippingLTLQuickQuoteShipperInfo,
    updateShippingLTLQuickQuoteShipPickupDateTime
} from "../../../actions/shippingLTLAction";

const styles = {
    ShippingLTLQuickQuoteSelectRateHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingLTLQuickQuoteSelectRateSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    ShippingLTLQuickQuoteAddressErrorText: {
        fontSize: '12px',
        color: "#FF0303"
    },
    ShippingLTLQuickQuoteAddressWarnText: {
        fontSize: '12px',
        color: "#B2B2B2"
    },
    ShippingLTLQuickQuoteSelectRateText: {
        textAlign: 'left',
        fontSize: '14px'
    },
    ShippingLTLAddressModalBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 500,
        maxHeight: 400,
        backgroundColor: '#FFFFFF',
        border: '2px solid #000',
        borderRadius: '10px',
        boxShadow: 24,
        padding: '40px 20px',
        overflow: 'auto'
    },
    ShippingLTLAddressModalSelectBox: {
        border: '1px solid #000000',
        borderRadius: '5px',
        padding: '10px',
        "&:hover": {
            cursor: "pointer",
            background: "aliceblue"
        },
    },
    ShippingLTLAddressModalText: {
        fontSize: '14px'
    }
}

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "4px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

export const ShippingLTLQuickQuoteEmailRate = ({cbHandleCommodity}) => {

    const classes = useStyles();
    const token = getAccessToken("access_token");
    const {id: partnerId, email} = useSelector((state) => state.user);

    const dispatch = useDispatch();

    let specialService = useSelector(state => state?.shippingLTL?.quickQuoteSpecialService);
    let moveFrom = useSelector(state => state?.shippingLTL?.quickQuoteMoveFrom);
    let moveTo = useSelector(state => state?.shippingLTL?.quickQuoteMoveTo);

    const currentDate = new Date().toISOString().split('T')[0];

    const [commodity, setCommodity] = useState('');
    const [searchMovingFromAddressList, setSearchMovingFromAddressList] = useState([]);
    const [searchMovingToAddressList, setSearchMovingToAddressList] = useState([]);
    const [movingFromAddressPredictions, setMovingFromAddressPredictions] = useState([]);
    const [movingToAddressPredictions, setMovingToAddressPredictions] = useState([]);
    const [shipperInfo, setShipperInfo] = useState({
        name: null,
        phone: null,
        email: null,
        company: null,
        addressList: {
            name: null,
            phone: null,
            email: null,
            address_email: null,
            company_name: null,
            city: null,
            province: null,
            country: null
        }
    });
    const [consigneeInfo, setConsigneeInfo] = useState({
        name: null,
        phone: null,
        email: null,
        company: null,
        addressList: {
            name: null,
            phone: null,
            email: null,
            address_email: null,
            company_name: null,
            city: null,
            province: null,
            country: null
        }
    });
    const [shipFrom, setShipFrom] = useState({
        address: null,
        address2: null,
        postalCode: null,
        displayAddress: {
            description: null,
            placeId: null,
            text: null
        },
    });
    const [shipTo, setShipTo] = useState({
        address: null,
        address2: null,
        postalCode: null,
        displayAddress: {
            description: null,
            placeId: null,
            text: null
        },
    });

    const [shipDataAndTime, setShipDateAndTime] = useState({
        shipDate: null,
        pickupDate: null,
        availablePickupTime: '',
        earliestPickupTime: '',
        latestPickupTime: '',
    });

    const getNextWeekday = () => {
        const today = new Date();
        const tomorrow = new Date(today.getTime() + 48 * 60 * 60 * 1000); // Adding two day

        // Skip the weekend (Saturday and Sunday)
        if (tomorrow.getDay() === 0) {
            tomorrow.setDate(tomorrow.getDate() + 2); // Adjust to Tuesday
        } else if (tomorrow.getDay() === 6) {
            tomorrow.setDate(tomorrow.getDate() + 3); // Adjust to Tuesday
        }

        return tomorrow.toISOString().split('T')[0];
    };

    const getMinDate = () => {
        const today = new Date();
        const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000); // Adding two day

        // Skip the weekend (Saturday and Sunday)
        if (tomorrow.getDay() === 0) {
            tomorrow.setDate(tomorrow.getDate() + 2); // Adjust to Tuesday
        } else if (tomorrow.getDay() === 6) {
            tomorrow.setDate(tomorrow.getDate() + 3); // Adjust to Tuesday
        }

        return tomorrow.toISOString().split('T')[0];
    };

    const searchAddress = async (searchString, roleType) => {
        let requestURL = `${PARTNER_URI}/elasticsearch/addressBook/searchPartnerAddressBook`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    role: roleType,
                    queryString: searchString
                }
            })

            console.log('result', result?.data);
            const searchAddressResult = result?.data;
            // setSearchNameList(result?.data);
            return searchAddressResult;

        } catch (e) {
            console.log('error', e.response)
        }
    }

    const googleMapsPlaceAutocomplete = async (inputValue, types, city) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: types
                }
            })
            const {data: {data: {predictions}}} = result;

            // console.log('predictions', predictions);
            // console.log('city', city);

            const filterPredictions = predictions.filter(address => address?.structured_formatting?.secondary_text.includes(city));
            const updatedPredictions = filterPredictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })

            return updatedPredictions;
        } catch (e) {
            console.log(e.response)
        }
    }

    const handleInputShipFromAddress = async (event, value) => {
        setShipFrom(prevState => ({
            ...prevState,
            address: value,
            displayAddress: {
                description: '',
                placeId: '',
                text: value
            }
        }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'address', moveFrom?.city);
        setMovingFromAddressPredictions(predictions);
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const handleChangeShipFromAddress = async (event, value) => {
        console.log('select', value);
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            console.log('address', address);
            if (address?.city)
                setShipFrom(prevState => ({
                    ...prevState,
                    address: `${address?.streetNumber} ${address?.streetName}`,
                    postalCode: address?.zip?.replace(/\s*/g, ""),
                    displayAddress: value
                }))
        } else {
            setShipFrom(prevState => ({
                ...prevState,
                address: '',
                displayAddress: {
                    description: '',
                    placeId: '',
                    text: ''
                },
            }))
        }
    }

    const handleShipFromAddress2 = (event) => {
        setShipFrom(prevState => ({
            ...prevState,
            address2: event.target.value
        }))
    }

    const handleShipFromPostalCode = (event) => {
        setShipFrom(prevState => ({
            ...prevState,
            postalCode: event.target.value
        }))
    }

    const handleInputChangeShipperName = async (event, value) => {
        console.log('value', value);
        setShipperInfo(prevState => ({
            ...prevState,
            name: value,
            addressList: {
                ...prevState.addressList,
                name: value,
            }
        }))
        if (value) {
            const result = await searchAddress(value, 'SENDER');
            const filterResult = result.filter(item => item?.name?.toLowerCase().includes(value.toLowerCase()) && item?.city?.toLowerCase() === moveFrom?.city?.toLowerCase());
            console.log('filter data', filterResult);
            setSearchMovingFromAddressList(filterResult);
        }
    }

    const handleChangeShipperName = (event, value) => {
        console.log('value', value);
        if (value) {
            setShipperInfo(prevState => ({
                ...prevState,
                name: value?.name,
                phone: value?.phone,
                email: value?.address_email,
                company: value?.company_name,
                addressList: value
            }))
            setShipFrom(prevState => ({
                ...prevState,
                address: value?.address,
                address2: value?.address_2,
                postalCode: value?.postal_code?.replace(/\s*/g, ""),
                displayAddress: {
                    description: `${value?.address}, ${value?.city}, ${value?.province}, ${value?.country}`,
                    placeId: null,
                    text: value?.address
                }
            }))
        } else {
            setShipFrom(prevState => ({
                ...prevState,
                name: ""
            }))
        }
    }

    const handleInputShipperPhone = (event) => {
        // console.log('phone input', event?.target?.value);
        setShipperInfo(prevState => ({
            ...prevState,
            phone: event?.target?.value
        }))
    }

    const handleInputChangeShipperCompany = async (event, value) => {
        console.log('value', value);
        setShipperInfo(prevState => ({
            ...prevState,
            company: value,
            addressList: {
                ...prevState.addressList,
                company_name: value,
            }
        }))
        if (value) {
            const result = await searchAddress(value, 'SENDER');
            const filterResult = result.filter(item => item?.company_name?.toLowerCase().includes(value.toLowerCase()) && item?.city?.toLowerCase() === moveFrom?.city?.toLowerCase());
            console.log('filter data', filterResult);
            setSearchMovingFromAddressList(filterResult);
        }
    }

    const handleChangeShipperCompany = (event, value) => {
        console.log('value', value);
        if (value) {
            setShipperInfo(prevState => ({
                ...prevState,
                name: value?.name,
                phone: value?.phone,
                email: value?.address_email,
                company: value?.company_name,
                addressList: value
            }))
            setShipFrom(prevState => ({
                ...prevState,
                address: value?.address,
                address2: value?.address_2,
                postalCode: value?.postal_code?.replace(/\s*/g, ""),
                displayAddress: {
                    description: `${value?.address}, ${value?.city}, ${value?.province}, ${value?.country}`,
                    placeId: null,
                    text: value?.address
                }
            }))
        } else {
            setShipFrom(prevState => ({
                ...prevState,
                company: ""
            }))
        }
    }

    const handleInputChangeShipperEmail = async (event, value) => {
        console.log('value', value);
        setShipperInfo(prevState => ({
            ...prevState,
            email: value,
            addressList: {
                ...prevState.addressList,
                address_email: value,
            }
        }))
        if (value) {
            const result = await searchAddress(value, 'SENDER');
            const filterResult = result.filter(item => item?.address_email?.toLowerCase().includes(value.toLowerCase()) && item?.city?.toLowerCase() === moveFrom?.city?.toLowerCase());
            console.log('filter data', filterResult);
            setSearchMovingFromAddressList(filterResult);
        }
    }

    const handleChangeShipperEmail = (event, value) => {
        console.log('value', value);
        if (value) {
            setShipperInfo(prevState => ({
                ...prevState,
                name: value?.name,
                phone: value?.phone,
                email: value?.address_email,
                company: value?.company_name,
                addressList: value
            }))
            setShipFrom(prevState => ({
                ...prevState,
                address: value?.address,
                address2: value?.address_2,
                postalCode: value?.postal_code?.replace(/\s*/g, ""),
                displayAddress: {
                    description: `${value?.address}, ${value?.city}, ${value?.province}, ${value?.country}`,
                    placeId: null,
                    text: value?.address
                }
            }))
        } else {
            setShipFrom(prevState => ({
                ...prevState,
                email: ""
            }))
        }
    }

    const handleInputShipToAddress = async (event, value) => {
        setShipTo(prevState => ({
            ...prevState,
            address: value,
            displayAddress: {
                description: '',
                placeId: '',
                text: value
            }
        }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'address', moveTo?.city);
        setMovingToAddressPredictions(predictions);
    }

    const handleChangeShipToAddress = async (event, value) => {
        console.log('select', value);
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            console.log('address', address);
            setShipTo(prevState => ({
                ...prevState,
                address: `${address?.streetNumber} ${address?.streetName}`,
                postalCode: address?.zip?.replace(/\s*/g, ""),
                displayAddress: value
            }))
        } else {
            setShipTo(prevState => ({
                ...prevState,
                address: '',
                displayAddress: {
                    description: '',
                    placeId: '',
                    text: ''
                },
            }))
        }
    }

    const handleShipToAddress2 = (event) => {
        setShipTo(prevState => ({
            ...prevState,
            address2: event.target.value
        }))
    }

    const handleShipToPostalCode = (event) => {
        setShipTo(prevState => ({
            ...prevState,
            postalCode: event.target.value
        }))
    }

    const handleInputChangeConsigneeName = async (event, value) => {
        console.log('value', value);
        setConsigneeInfo(prevState => ({
            ...prevState,
            name: value,
            addressList: {
                ...prevState.addressList,
                name: value,
            }
        }))
        if (value) {
            const result = await searchAddress(value, 'RECEIVER');
            const filterResult = result.filter(item => item?.name?.toLowerCase().includes(value.toLowerCase()) && item?.city?.toLowerCase() === moveTo?.city?.toLowerCase());
            console.log('filter data', filterResult);
            setSearchMovingToAddressList(filterResult);
        }
    }

    const handleChangeConsigneeName = (event, value) => {
        console.log('value', value);
        if (value) {
            setConsigneeInfo(prevState => ({
                ...prevState,
                name: value?.name,
                phone: value?.phone,
                email: value?.address_email,
                company: value?.company_name,
                addressList: value
            }))
            setShipTo(prevState => ({
                ...prevState,
                address: value?.address,
                address2: value?.address_2,
                postalCode: value?.postal_code?.replace(/\s*/g, ""),
                displayAddress: {
                    description: `${value?.address}, ${value?.city}, ${value?.province}, ${value?.country}`,
                    placeId: null,
                    text: value?.address
                }
            }))
        } else {
            setShipTo(prevState => ({
                ...prevState,
                name: ""
            }))
        }
    }

    const handleInputChangeConsigneeEmail = async (event, value) => {
        console.log('value', value);
        setConsigneeInfo(prevState => ({
            ...prevState,
            email: value,
            addressList: {
                ...prevState.addressList,
                address_email: value,
            }
        }))
        if (value) {
            const result = await searchAddress(value, 'RECEIVER');
            const filterResult = result.filter(item => item?.address_email?.toLowerCase().includes(value.toLowerCase()) && item?.city?.toLowerCase() === moveTo?.city?.toLowerCase());
            console.log('filter data', filterResult);
            setSearchMovingToAddressList(filterResult);
        }
    }

    const handleChangeConsigneeEmail = (event, value) => {
        console.log('value', value);
        if (value) {
            setConsigneeInfo(prevState => ({
                ...prevState,
                name: value?.name,
                phone: value?.phone,
                email: value?.address_email,
                company: value?.company_name,
                addressList: value
            }))
            setShipTo(prevState => ({
                ...prevState,
                address: value?.address,
                address2: value?.address_2,
                postalCode: value?.postal_code?.replace(/\s*/g, ""),
                displayAddress: {
                    description: `${value?.address}, ${value?.city}, ${value?.province}, ${value?.country}`,
                    placeId: null,
                    text: value?.address
                }
            }))
        } else {
            setShipTo(prevState => ({
                ...prevState,
                email: ""
            }))
        }
    }

    const handleInputConsigneePhone = (event) => {
        // console.log('phone input', event?.target?.value);
        setConsigneeInfo(prevState => ({
            ...prevState,
            phone: event?.target?.value
        }))
    }

    const handleInputChangeConsigneeCompany = async (event, value) => {
        console.log('value', value);
        setConsigneeInfo(prevState => ({
            ...prevState,
            company: value,
            addressList: {
                ...prevState.addressList,
                company_name: value,
            }
        }))
        if (value) {
            const result = await searchAddress(value, 'RECEIVER');
            const filterResult = result.filter(item => item?.company_name?.toLowerCase().includes(value.toLowerCase()) && item?.city?.toLowerCase() === moveTo?.city?.toLowerCase());
            console.log('filter data', filterResult);
            setSearchMovingToAddressList(filterResult);
        }
    }

    const handleChangeConsigneeCompany = (event, value) => {
        console.log('value', value);
        if (value) {
            setConsigneeInfo(prevState => ({
                ...prevState,
                name: value?.name,
                phone: value?.phone,
                email: value?.address_email,
                company: value?.company_name,
                addressList: value
            }))
            setShipTo(prevState => ({
                ...prevState,
                address: value?.address,
                address2: value?.address_2,
                postalCode: value?.postal_code?.replace(/\s*/g, ""),
                displayAddress: {
                    description: `${value?.address}, ${value?.city}, ${value?.province}, ${value?.country}`,
                    placeId: null,
                    text: value?.address
                }
            }))
        } else {
            setShipTo(prevState => ({
                ...prevState,
                company: ""
            }))
        }
    }

    const handlePickupDate = (event) => {
        const inputDate = event.target.value;
        if (inputDate <= currentDate) {
            setShipDateAndTime(prevState => ({
                ...prevState,
                pickupDate: currentDate,
                shipDate: currentDate
            }));
        } else {
            setShipDateAndTime(prevState => ({
                ...prevState,
                pickupDate: inputDate,
                shipDate: inputDate
            }))
        }
    }

    const handlePickupEarliestTime = (event) => {
        const inputTime = event.target.value;
        console.log('time', inputTime);
        setShipDateAndTime(prevState => ({
            ...prevState,
            earliestPickupTime: inputTime
        }))
    }

    const handleCommodity = (event) => {
        setCommodity(event?.target?.value);
    }

    const handlePickupLatestTime = (event) => {
        const inputTime = event.target.value;
        console.log('time', inputTime);
        setShipDateAndTime(prevState => ({
            ...prevState,
            latestPickupTime: inputTime
        }))
    }

    useEffect(() => {
        const nextWeekday = getNextWeekday();
        setShipDateAndTime(prevState => ({
            ...prevState,
            shipDate: nextWeekday,
            pickupDate: nextWeekday,
            availablePickupTime: "12:00",
            earliestPickupTime: "11:00",
            latestPickupTime: "16:00"
        }));
        // console.log('next week day', nextWeekday);
        // console.log('current date', currentDate);
    }, [])

    useEffect(() => {
        dispatch(updateShippingLTLQuickQuoteMoveFromAddition(shipFrom));
    }, [shipFrom]);

    useEffect(() => {
        dispatch(updateShippingLTLQuickQuoteMoveToAddition(shipTo));
    }, [shipTo]);

    useEffect(() => {
        dispatch(updateShippingLTLQuickQuoteShipperInfo(shipperInfo));
    }, [shipperInfo]);

    useEffect(() => {
        dispatch(updateShippingLTLQuickQuoteConsigneeInfo(consigneeInfo));
    }, [consigneeInfo]);

    useEffect(() => {
        dispatch(updateShippingLTLQuickQuoteShipPickupDateTime(shipDataAndTime));
    }, [shipDataAndTime]);

    useEffect(() => {
        cbHandleCommodity(commodity);
    }, [commodity])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography sx={styles.ShippingLTLQuickQuoteSelectRateHeading}>
                    {intl.get('SHIPPING_INFORMATION.SHIP_FROM')}
                </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
                <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                    <InputLabel>
                        {intl.get('SHIPPING_INFORMATION.ADDRESS')}
                    </InputLabel>
                    <Autocomplete
                        freeSolo
                        value={shipFrom?.displayAddress}
                        options={movingFromAddressPredictions}
                        filterOptions={(options, state) => options}
                        getOptionLabel={option => option?.text}
                        onInputChange={handleInputShipFromAddress}
                        onChange={handleChangeShipFromAddress}
                        fullWidth
                        PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                        renderInput={params => <TextField
                            {...params}
                            variant='outlined'
                            className={classes.smallInput}
                        />}
                        renderOption={(option) => (
                            <Typography
                                style={{fontSize: '12px'}}
                            >
                                {/*<Typography>*/}
                                {option?.description}
                                {/*</Typography>*/}
                            </Typography>
                        )}
                    />
                    <Typography style={styles.ShippingLTLQuickQuoteAddressWarnText}>
                        {
                            specialService?.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_PICKUP_CODE) && intl.get('SHIPPING_INFORMATION.RESIDENCY_ADDRESS')
                        }
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={6} xs={12}>
                <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                    <InputLabel>
                        {intl.get('SHIPPING_INFORMATION.ADDRESS_2')}
                    </InputLabel>
                    <TextField
                        value={shipFrom?.address2}
                        fullWidth
                        variant="outlined"
                        onInput={handleShipFromAddress2}
                        sx={{padding: '2px'}}
                        size='small'
                    />
                </Box>
            </Grid>
            <Grid item md={6} xs={12}>
                <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                    <InputLabel>
                        {intl.get('SHIPPING_INFORMATION.POSTAL_CODE')}
                    </InputLabel>
                    <TextField
                        value={shipFrom?.postalCode}
                        fullWidth
                        variant="outlined"
                        onInput={handleShipFromPostalCode}
                        sx={{padding: '2px'}}
                        size='small'
                    />
                    <Typography style={styles.ShippingLTLQuickQuoteAddressErrorText}>
                        {/*{*/}
                        {/*    moveFromMissing.includes('city') ? "This is required field." : ''*/}
                        {/*}*/}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={6} xs={12}>
                <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                    <InputLabel>
                        {intl.get('SHIPPING_INFORMATION.NAME')}
                    </InputLabel>
                    <Autocomplete
                        freeSolo
                        value={shipperInfo?.addressList}
                        fullWidth
                        options={searchMovingFromAddressList}
                        filterOptions={(options, state) => options}
                        getOptionLabel={option => option?.name}
                        onInputChange={handleInputChangeShipperName}
                        onChange={handleChangeShipperName}
                        PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                        renderInput={params => <TextField
                            {...params}
                            className={classes.smallInput}
                            variant="outlined"
                        />}
                        renderOption={option => (
                            <Typography style={{fontSize: '12px'}}>
                                {option?.name} | {`${option?.address_2}-`}{option?.address}, {option?.city}, {option?.province}, {option?.country}
                            </Typography>
                        )}
                    />
                </Box>
            </Grid>
            <Grid item md={6} xs={12}>
                <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                    <InputLabel>
                        {intl.get('SHIPPING_INFORMATION.PHONE')}
                    </InputLabel>
                    <TextField
                        value={shipperInfo?.phone}
                        size='small'
                        fullWidth
                        variant="outlined"
                        onInput={handleInputShipperPhone}
                    />
                </Box>
            </Grid>
            <Grid item md={6} xs={12}>
                <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                    <InputLabel>
                        {intl.get('SHIPPING_INFORMATION.COMPANY')}
                    </InputLabel>
                    <Autocomplete
                        freeSolo
                        value={shipperInfo?.addressList}
                        fullWidth
                        options={searchMovingFromAddressList}
                        filterOptions={(options, state) => options}
                        getOptionLabel={option => option?.company_name}
                        onInputChange={handleInputChangeShipperCompany}
                        onChange={handleChangeShipperCompany}
                        PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                        renderInput={params => <TextField
                            {...params}
                            className={classes.smallInput}
                            variant="outlined"
                        />}
                        renderOption={option => (
                            <Typography style={{fontSize: '12px'}}>
                                {option?.company_name} | {`${option?.address_2}-`}{option?.address}, {option?.city}, {option?.province}, {option?.country}
                            </Typography>
                        )}
                    />
                </Box>
            </Grid>
            <Grid item md={6} xs={12}>
                <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                    <InputLabel>
                        {intl.get('SHIPPING_INFORMATION.EMAIL')}
                    </InputLabel>
                    <Autocomplete
                        freeSolo
                        value={shipperInfo?.addressList}
                        fullWidth
                        options={searchMovingFromAddressList}
                        filterOptions={(options, state) => options}
                        getOptionLabel={option => option?.address_email}
                        onInputChange={handleInputChangeShipperEmail}
                        onChange={handleChangeShipperEmail}
                        PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                        renderInput={params => <TextField
                            {...params}
                            className={classes.smallInput}
                            variant="outlined"
                            placeholder={intl.get('SHIPPING_INFORMATION.RECOMMEND')}
                        />}
                        renderOption={option => (
                            <Typography style={{fontSize: '12px'}}>
                                {option?.address_email} | {`${option?.address_2}-`}{option?.address}, {option?.city}, {option?.province}, {option?.country}
                            </Typography>
                        )}
                    />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Typography sx={styles.ShippingLTLQuickQuoteSelectRateHeading}>
                    {intl.get('SHIPPING_INFORMATION.SHIP_TO')}
                </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
                <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                    <InputLabel>
                        {intl.get('SHIPPING_INFORMATION.ADDRESS')}
                    </InputLabel>
                    <Autocomplete
                        freeSolo
                        value={shipTo?.displayAddress}
                        options={movingToAddressPredictions}
                        filterOptions={(options, state) => options}
                        getOptionLabel={option => option?.text}
                        onInputChange={handleInputShipToAddress}
                        onChange={handleChangeShipToAddress}
                        fullWidth
                        PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                        renderInput={params => <TextField
                            {...params}
                            variant='outlined'
                            className={classes.smallInput}
                        />}
                        renderOption={(option) => (
                            <Typography
                                style={{fontSize: '12px'}}
                            >
                                {/*<Typography>*/}
                                {option?.description}
                                {/*</Typography>*/}
                            </Typography>
                        )}
                    />
                    <Typography style={styles.ShippingLTLQuickQuoteAddressWarnText}>
                        {
                            specialService?.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE) && intl.get('SHIPPING_INFORMATION.RESIDENCY_ADDRESS')
                        }
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={6} xs={12}>
                <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                    <InputLabel>
                        {intl.get('SHIPPING_INFORMATION.ADDRESS_2')}
                    </InputLabel>
                    <TextField
                        value={shipTo?.address2}
                        fullWidth
                        variant="outlined"
                        onInput={handleShipToAddress2}
                        sx={{padding: '2px'}}
                        size='small'
                    />
                </Box>
            </Grid>
            <Grid item md={6} xs={12}>
                <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                    <InputLabel>
                        {intl.get('SHIPPING_INFORMATION.POSTAL_CODE')}
                    </InputLabel>
                    <TextField
                        value={shipTo?.postalCode}
                        fullWidth
                        variant="outlined"
                        onInput={handleShipToPostalCode}
                        sx={{padding: '2px'}}
                        size='small'
                    />
                </Box>
            </Grid>
            <Grid item md={6} xs={12}>
                <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                    <InputLabel>
                        {intl.get('SHIPPING_INFORMATION.NAME')}
                    </InputLabel>
                    <Autocomplete
                        freeSolo
                        value={consigneeInfo?.addressList}
                        fullWidth
                        options={searchMovingToAddressList}
                        filterOptions={(options, state) => options}
                        getOptionLabel={option => option?.name}
                        onInputChange={handleInputChangeConsigneeName}
                        onChange={handleChangeConsigneeName}
                        PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                        renderInput={params => <TextField
                            {...params}
                            className={classes.smallInput}
                            variant="outlined"
                        />}
                        renderOption={option => (
                            <Typography style={{fontSize: '12px'}}>
                                {option?.name} | {`${option?.address_2}-`}{option?.address}, {option?.city}, {option?.province}, {option?.country}
                            </Typography>
                        )}
                    />
                </Box>
            </Grid>
            <Grid item md={6} xs={12}>
                <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                    <InputLabel>
                        {intl.get('SHIPPING_INFORMATION.PHONE')}
                    </InputLabel>
                    <TextField
                        value={consigneeInfo?.phone}
                        size='small'
                        fullWidth
                        variant="outlined"
                        onInput={handleInputConsigneePhone}
                    />
                </Box>
            </Grid>
            <Grid item md={6} xs={12}>
                <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                    <InputLabel>
                        {intl.get('SHIPPING_INFORMATION.COMPANY')}
                    </InputLabel>
                    <Autocomplete
                        freeSolo
                        value={consigneeInfo?.addressList}
                        fullWidth
                        options={searchMovingToAddressList}
                        filterOptions={(options, state) => options}
                        getOptionLabel={option => option?.company_name}
                        onInputChange={handleInputChangeConsigneeCompany}
                        onChange={handleChangeConsigneeCompany}
                        PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                        renderInput={params => <TextField
                            {...params}
                            className={classes.smallInput}
                            variant="outlined"
                        />}
                        renderOption={option => (
                            <Typography style={{fontSize: '12px'}}>
                                {option?.company_name} | {`${option?.address_2}-`}{option?.address}, {option?.city}, {option?.province}, {option?.country}
                            </Typography>
                        )}
                    />
                </Box>
            </Grid>
            <Grid item md={6} xs={12}>
                <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                    <InputLabel>
                        {intl.get('SHIPPING_INFORMATION.EMAIL')}
                    </InputLabel>
                    <Autocomplete
                        freeSolo
                        value={consigneeInfo?.addressList}
                        fullWidth
                        options={searchMovingToAddressList}
                        filterOptions={(options, state) => options}
                        getOptionLabel={option => option?.address_email}
                        onInputChange={handleInputChangeConsigneeEmail}
                        onChange={handleChangeConsigneeEmail}
                        PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                        renderInput={params => <TextField
                            {...params}
                            className={classes.smallInput}
                            variant="outlined"
                            placeholder={intl.get('SHIPPING_INFORMATION.RECOMMEND')}
                        />}
                        renderOption={option => (
                            <Typography style={{fontSize: '12px'}}>
                                {option?.address_email} | {`${option?.address_2}-`}{option?.address}, {option?.city}, {option?.province}, {option?.country}
                            </Typography>
                        )}
                    />
                </Box>
            </Grid>
            <Grid item md={12} xs={12}>
                <Typography sx={styles.ShippingLTLQuickQuoteSelectRateHeading}>
                    {intl.get('ADD_PALLET.PALLET_INFORMATION')}
                </Typography>
            </Grid>
            <Grid item md={4} xs={12}>
                <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                    <InputLabel>
                        {intl.get('ADD_PALLET.PALLET_NAME')}
                    </InputLabel>
                    <TextField
                        value={commodity}
                        onInput={handleCommodity}
                        fullWidth
                        variant='outlined'
                        placeholder='description'
                        size='small'
                        // inputProps={{style: {fontSize: 14}}} // font size of input text
                    />
                </Box>
            </Grid>
            <Grid item md={12} xs={12}>
                <Typography sx={styles.ShippingLTLQuickQuoteSelectRateHeading}>
                    {intl.get('SHIPPING_LTL_QUICK_QUOTE.PICKUP_DATE_TIME')}
                </Typography>
            </Grid>
            <Grid item md={4} xs={12}>
                <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                    <InputLabel>
                        {intl.get('PICKUP_DATE_TIME.DATE')}
                    </InputLabel>
                    <TextField
                        fullWidth
                        type='date'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={shipDataAndTime?.pickupDate}
                        onChange={handlePickupDate}
                        inputProps={{
                            min: getMinDate(),
                        }}
                        size='small'
                    />
                    <Typography style={styles.ShippingLTLQuickQuoteAddressErrorText}>
                        {/*{*/}
                        {/*    moveFromMissing.includes('city') ? "This is required field." : ''*/}
                        {/*}*/}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={4} xs={12}>
                <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                    <InputLabel>
                        {intl.get('PICKUP_DATE_TIME.EARLIEST_TIME')}
                    </InputLabel>
                    <TextField
                        fullWidth
                        type='time'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={shipDataAndTime?.earliestPickupTime}
                        onChange={handlePickupEarliestTime}
                        // inputProps={{
                        //     min: currentDate,
                        // }}
                        size='small'
                    />
                    <Typography style={styles.ShippingLTLQuickQuoteAddressErrorText}>
                        {/*{*/}
                        {/*    moveFromMissing.includes('city') ? "This is required field." : ''*/}
                        {/*}*/}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={4} xs={12}>
                <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                    <InputLabel>
                        {intl.get('PICKUP_DATE_TIME.LATEST_TIME')}
                    </InputLabel>
                    <TextField
                        fullWidth
                        type='time'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={shipDataAndTime?.latestPickupTime}
                        onChange={handlePickupLatestTime}
                        // inputProps={{
                        //     min: currentDate,
                        // }}
                        size='small'
                    />
                    <Typography style={styles.ShippingLTLQuickQuoteAddressErrorText}>
                        {/*{*/}
                        {/*    moveFromMissing.includes('city') ? "This is required field." : ''*/}
                        {/*}*/}
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    )
}