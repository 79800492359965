import React, { useState, useMemo, useEffect } from "react";
import {
  makeStyles,
  Paper,
  Grid,
  Typography,
  Button,
  Divider,
} from "@material-ui/core";
import {
  DRIVER_CLAIM_SHIPMENTS_BY_SHIPMENT_ID,
  CHECK_BONUS, NODE_ROUTE_URI,
} from "../../Utils/apiUrl";
import {useDispatch, useSelector} from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { getTokenFromCookie } from "../../Utils/doToken";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FeedbackDialogLayout from "../Layouts/FeedbackDialogLayout";
import { driverClearAllShipmentsFromSummary } from "../../actions/driverClaimShipmentAction";
import TextLoadingAnimation from "../Layouts/TextLoadingAnimation";
import { pageTitle, general_info_label } from "../../css/MUIStyle";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    color: "#111",
    fontFamily: "Helvetica Neue, sans-serif",
    "font-size": "30px",
    "font-weight": "bold",
    "letter-spacing": "-1px",
    "line-height": 1,
    margin: "5px",
  },
  paper: {
    margin: "5px auto",
    width: "96%",
    maxWidth: "720px",
    backgroundColor: "#fdf5e8",
    padding: "8px",
  },
  blocks: {
    backgroundColor: "#fbfbf8",
    border: "1px solid #ded47c",
    borderRadius: "5px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export const SummarySection = ({ sectionTitle, packages, reflash }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [total, setTotal] = useState(0);
  const [bonus, setBonus] = useState(null);
  const [loadingBonus, setLoadingBonus] = useState(false);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [claimed, setClaimed] = useState(false);

  const user = useSelector((state) => state.user);

  useEffect(() => {
    console.log("Driver Id: ", user);
  }, [user])

  const [dialogFields, setDialogFields] = useState({
    title: "",
    content: "",
    buttons: ["", ""],
    loading: false,
  });

  useEffect(() => {
    if (packages[0].count === packages[0].availableCount) {
      //if getting the last shipment or taking all the shipments
      //check if driver can get bonus
      setLoadingBonus(true);
      const header = {
        headers: {
          Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
        },
      };
      console.log("packages: ", packages[0]);
      const { shipment_number, stage, count, package_number } = packages[0];
      axios
        .post(`${NODE_ROUTE_URI}/shipment_task/estimateBonusOfPackage`, {
          shipmentNumber: shipment_number,
          stageId: stage,
          quantity: count,
          packageId: package_number
        }, header)
        .then((res) => {
          console.log("bonus res", res);
          setBonus(res.data.result.bonus);

          //for testing set canGetBonus always true
          //setCanGetBonus(true)
          setLoadingBonus(false);
        })
        .catch((error) => {
          setBonus(0);
          setLoadingBonus(false);
        });
    } else {
      //do not display get bonus and clean bonus and canGetBouns
      setBonus(0);
    }
  }, [packages[0].count]);

  const handleClaim = () => {
    setDialogFields({
      title: "Claim shipment",
      content: "Confirm to claim the following shipment(s)?",
      buttons: ["Confirm", "Cancel"],
      loading: false,
    });
    setOpenBackDrop(true);
    setOpenDialog(true);
  };

  const claimShipment = () => {
    setDialogFields({
      ...dialogFields,
      loading: true,
    });
    console.log(packages[0]);
    const { shipment_number, stage, count } = packages[0];
    // userId: driverId,
    //     quantity,
    //     shipmentNumber,
    //     stageId,
    //     taskId,

    axios
      .post(
        DRIVER_CLAIM_SHIPMENTS_BY_SHIPMENT_ID,
        {
          quantity: count,
          shipmentNumber: shipment_number,
          stageId: stage
        },
        {
          headers: {
            Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
          }
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setDialogFields({
            title: "Awesome",
            content: "You have successfully claimed the shipment(s).",
            buttons: ["", "Okay"],
            loading: false,
          });
          setClaimed(true);
          setTimeout(() => {
            history.push("/driver/claimed_shipment_tasks");

          }, 2000);
        } else {
          console.log("claim failed");
          setDialogFields({
            title: "Failed to claim shipment(s)",
            content: "Claim shipment(s) failed, please retry later.",
            buttons: ["Retry", "Close"],
            loading: false,
          });
          setClaimed(false);
        }
      })
      .catch((error) => {
        console.log("claim error");
        setDialogFields({
          title: "Failed to claim shipment(s)",
          content: "Claim shipment(s) failed, please retry later.",
          buttons: ["Retry", "Close"],
          loading: false,
        });
        setClaimed(false);
      });
  };

  useMemo(() => {
    let result = 0;
    packages.map((p) => {
      result += p.count * p.unit_price;
    });
    result += bonus;
    setTotal(result);
  }, [packages, bonus]);

  const handleCancel = () => {
    setOpenBackDrop(false);
    setOpenDialog(false);
    if (claimed) {
      dispatch(driverClearAllShipmentsFromSummary());
      reflash(true);
    }
  };

  return (
    <>
      <Paper className={classes.paper}>
        <Grid container className={classes.blocks}>
          <Grid item xs={12}>
            <Typography style={pageTitle} align="center">
              {sectionTitle}
            </Typography>
          </Grid>
          {packages.map((p, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <Typography variant="subtitle1" style={{ paddingLeft: "5px" }}>
                  {p.item_name}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ paddingLeft: "5px" }}
                >
                  {p.shipmentType}/{p.weight}lb
                </Typography>
              </div>
              <div style={{ display: "flex", alignItems: "flex-end" }}>
                <Typography variant="subtitle1"> X {p.count}</Typography>
              </div>
              <div style={{ display: "flex", alignItems: "flex-end" }}>
                <div style={{ paddingRight: "5px" }}>
                  <span style={general_info_label}>Sub-total: </span>
                  <span>${p.count * p.unit_price}</span>
                </div>
              </div>
            </div>
          ))}

          <Grid item xs={12}>
            {loadingBonus ? (
              <TextLoadingAnimation text="Checking bonus..." />
            ) : (
              bonus !== 0 && (
                <div
                  style={{
                    textAlign: "right",
                    paddingRight: "5px",
                  }}
                >
                  <span style={general_info_label}>Bonus: </span>
                  <span>${bonus}</span>
                </div>
              )
            )}
            <Divider />
            <div
              style={{
                paddingRight: "5px",
                textAlign: "right",
              }}
            >
              <span style={general_info_label}>Total: </span>
              <span>${total}</span>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              color: "white",
              display: "flex",
              justifyContent: "center",
              margin: "5px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{ color: "white" }}
              onClick={() => handleClaim()}
            >
              Claim
            </Button>
          </Grid>
        </Grid>
      </Paper>
      {openDialog && (
        <FeedbackDialogLayout
          openDialog={openBackDrop}
          openBackDrop={openDialog}
          title={dialogFields.title}
          content={dialogFields.content}
          shipmentNumbers={[packages[0].shipment_number]}
          handleConfirm={() => claimShipment()}
          handleCancel={() => handleCancel()}
          buttonWords={dialogFields.buttons}
          loading={dialogFields.loading}
        />
      )}
    </>
  );
};
