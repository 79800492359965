import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TableBody,
    TableCell,
    TableRow
} from "@mui/material";
import {useEffect, useState} from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {OrderDashBoardTransactionsTracking} from "./OrderDashBoardTransactionsTracking";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../Utils/doToken";
import {OrderDashBoardTransactionsTrackingInfo} from "./OrderDashBoardTransactionsTrackingInfo";

const style = {
    OrderDashBoardTransactionsEachLink: {
        display: 'flex',
        justifyContent: 'space-between'
    }
}

export const OrderDashBoardTransactionsEach = ({transactionsEach}) => {

    const accessToken = getAccessToken("access_token");

    const [trackingExpand, setTrackingExpand] = useState(false);

    const [trackingInfoDialogIsOpen, setTrackingInfoDialogIsOpen] = useState(false);

    const [trackedPackagesList, setTrackedPackagesList] = useState([]);

    //handle tracking information dialog open and close
    const handleTrackingInfo = () => {
        setTrackingInfoDialogIsOpen(!trackingInfoDialogIsOpen);
    };

    const handleTrackingInfoClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            setTrackingInfoDialogIsOpen(!trackingInfoDialogIsOpen);
        }
    }

    const getLabelURL = (pdf, zip) => {
        if (pdf) {
            return pdf;
        }
        if (zip) {
            return zip;
        }
        return "";
    };

    const getTrackedPackageList = async () => {
        try {

            const trackingNumbers = transactionsEach.tran_tracking_nos.replace(/\n/g, '');
            const result = await axios({
                method: "get",
                url: `${NODE_ROUTE_URI}/shippingOrderDetailsDashboard/endUser/multipleTrackingForWeb?trackingNumbers=${trackingNumbers}`,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },

            });

            setTrackedPackagesList(result.data)

        } catch (e) {
            console.log(e.response)
            // setErrorMessage("Failed to Schedule Pickup. Try again another time.");
        }
    };

    useEffect(() => {
        getTrackedPackageList();
    },[])

    console.log('trackingPagedlist', trackedPackagesList)

    return (
        <>
            <Dialog
                open={trackingInfoDialogIsOpen}
                onClose={handleTrackingInfoClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Tracking Details</DialogTitle>
                <DialogContent>
                    {trackedPackagesList.length === 0 || trackedPackagesList[0]?.trackingResponse === null ? <Box>No Trackng Information Found</Box> :
                        trackedPackagesList.map((trackedPackage, index) => {
                            return (
                                <OrderDashBoardTransactionsTrackingInfo
                                    key={index}
                                    activity={
                                        trackedPackage.trackingResponse
                                            ? trackedPackage.trackingResponse.activity
                                            : undefined
                                    }
                                    trackingNo={trackedPackage?.trackingNumber}
                                    courier={trackedPackage?.accountBase}
                                    deliverStatus={trackedPackage?.trackingResponse?.status}
                                    transaction={trackedPackage?.transaction}
                                />
                            );
                        })}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleTrackingInfo}
                        variant="outlined"
                        autoFocus
                        type="button"
                    >
                        CLOSE
                    </Button>
                </DialogActions>
            </Dialog>
            <TableRow>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setTrackingExpand(!trackingExpand)}
                    >
                        {trackingExpand ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
                <TableCell>
                    <Typography>
                        {transactionsEach?.tran_id}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography>
                        {transactionsEach?.send_to_name}
                    </Typography>
                    <Typography>
                        {transactionsEach?.send_to_tel}
                    </Typography>
                    <Typography>
                        {transactionsEach?.send_to_addr}
                    </Typography>
                    <Typography>
                        {transactionsEach?.send_to_city}, {transactionsEach?.send_to_province}
                    </Typography>
                    <Typography>
                        {transactionsEach?.send_to_country}, {transactionsEach?.send_to_postal_code}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography>
                        excel. tax: CAD ${transactionsEach?.amount_gross}
                    </Typography>
                    <Typography>
                        inc. tax: CAD ${transactionsEach?.amount}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography>
                        {transactionsEach?.account_base}
                    </Typography>
                    <Typography>
                        {transactionsEach?.tran_tracking_nos}
                    </Typography>
                    <Box sx={style.OrderDashBoardTransactionsEachLink}>
                        <a
                            href={`https://storage.googleapis.com/${getLabelURL(transactionsEach?.label_pdf_uri, transactionsEach?.zip_label_uri)}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{color: '#537FE7', textDecoration: 'none'}}
                        >
                            {getLabelURL(transactionsEach?.label_pdf_uri, transactionsEach?.zip_label_uri) ? "Label" : ""}
                        </a>
                        <a onClick={handleTrackingInfo} style={{color: '#537FE7'}}>
                            Tracking Info
                        </a>
                    </Box>
                </TableCell>
            </TableRow>
            {trackingExpand && <TableRow style={{width: '100%'}}>
                <TableCell style={{paddingBottom: 0, paddingTop: 0,}} colSpan={12}>
                    <OrderDashBoardTransactionsTracking transactionsEach={transactionsEach}/>
                </TableCell>
            </TableRow>}
        </>

    )
}