import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import {MenuItem, Select} from "@mui/material";
import {getCountryCode} from "../../../Utils/getCountryCode";
import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {useDispatch, useSelector} from "react-redux";
import {
    updateShipFromPackageCreateLabelPackage,
    updateShippingPackageInternationalShipmentShipFrom,
    updateShippingPackageInternationalShipmentShipTo,
    updateShipToPackageCreateLabelPackage
} from "../../../actions/BusinessShippingPackageAction";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import {makeStyles} from "@material-ui/core/styles";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {extractFullAddress} from "../../../Utils/Helper";
import FormHelperText from "@mui/material/FormHelperText";

const styles = {
    ShippingPackageQuickQuoteLocationTitle: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageQuickQuoteLocationHeading: {
        fontSize: '20px',
        textAlign: 'left'
    },
    ShippingPackageQuickQuoteLocationSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    ShippingPackageQuickQuoteLocationBox: {
        padding: '20px',
        // border: '1px solid #000000',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    },
    ShippingPackageQuickQuoteLocationErrorText: {
        fontSize: '12px',
        color: "#FF0303"
    },
    ShippingPackageQuickQuoteLocationRow: {
        display: 'flex',
        alignItems: 'center'
        // justifyContent: 'flex-end',
    }
}

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "4px", // Adjust the height as per your preference
            padding: "0px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

export const BusinessShippingPackageInternationShipmentLocation = ({locationValidation}) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    let shipFromRedux = useSelector(state => state?.businessShippingPackage?.createLabelShipFrom);
    let shipToRedux = useSelector(state => state?.businessShippingPackage?.createLabelShipTo);
    const [shipFromPredictionsZip, setShipFromPredictionsZip] = useState([]);
    const [shipToPredictionsZip, setShipToPredictionsZip] = useState([]);

    const [shipFrom, setShipFrom] = useState({
        postalCode: null,
        province: null,
        city: null,
        address: null,
        country: null,
        displayPostalCode: {
            description: null,
            placeId: null,
            text: null
        },
        displayAddress: {
            description: null,
            placeId: null,
            text: null
        }
    });

    const [shipTo, setShipTo] = useState({
        postalCode: null,
        province: null,
        city: null,
        address: null,
        country: null,
        displayPostalCode: {
            description: null,
            placeId: null,
            text: null
        },
        displayAddress: {
            description: null,
            placeId: null,
            text: null
        }
    });

    const handleShipFromCountry = event => {
        setShipFrom(prevState => ({
            ...prevState,
            country: event?.target?.value
        }))
    }

    const handleShipToCountry = event => {
        setShipTo(prevState => ({
            ...prevState,
            country: event?.target?.value
        }))
    }

    const googleMapsPlaceAutocomplete = async (inputValue, type, country) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeAutocompleteFromCountry`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type,
                    country: country
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log('prediction', predictions);
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            console.log('updated prediction', updatedPredictions);

            return updatedPredictions;

        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const handleChangeShipFromPostalCode = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        console.log('place', place);
        if (place) {
            const address = extractFullAddress(place);
            setShipFrom(prevState => ({
                ...prevState,
                // city: address?.sublocality || address?.city || address?.administrative_area_level_3,
                city: address?.sublocality || address?.city || address?.administrative_area_level_3 || address.neighborhood,
                province: address?.state,
                displayPostalCode: value,
                displayAddress: {
                    description: '',
                    placeId: '',
                    text: ''
                },
            }))
        } else {
            setShipFrom(prevState => ({
                ...prevState,
                city: '',
                province: '',
                postalCode: '',
                displayPostalCode: {
                    description: '',
                    placeId: '',
                    text: ''
                },
            }))
        }
    }

    const handleInputShipFromPostalCode = async (event, value) => {
        setShipFrom(prevState => ({
            ...prevState,
            postalCode: value
        }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'postal_code', shipFrom?.country);
        setShipFromPredictionsZip(predictions);
    }

    const handleChangeShipToPostalCode = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        console.log('place', place);
        if (place) {
            const address = extractFullAddress(place);
            setShipTo(prevState => ({
                ...prevState,
                // city: address?.sublocality || address?.city || address?.administrative_area_level_3,
                city: address?.sublocality || address?.city || address?.administrative_area_level_3 || address.neighborhood,
                province: address?.state,
                displayPostalCode: value,
                displayAddress: {
                    description: '',
                    placeId: '',
                    text: ''
                },
            }))
        } else {
            setShipTo(prevState => ({
                ...prevState,
                city: '',
                province: '',
                postalCode: '',
                displayPostalCode: {
                    description: '',
                    placeId: '',
                    text: ''
                },
            }))
        }
    }

    const handleInputShipToPostalCode = async (event, value) => {
        setShipTo(prevState => ({
            ...prevState,
            postalCode: value
        }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'postal_code', shipTo?.country);
        setShipToPredictionsZip(predictions);
    }

    useEffect(() => {
        setShipFrom(prevState => ({
            ...prevState,
            city: '',
            province: '',
            postalCode: '',
            displayPostalCode: {
                description: '',
                placeId: '',
                text: ''
            },
        }))
    }, [shipFrom?.country])

    useEffect(() => {
        setShipTo(prevState => ({
            ...prevState,
            city: '',
            province: '',
            postalCode: '',
            displayPostalCode: {
                description: '',
                placeId: '',
                text: ''
            },
        }))
    }, [shipTo?.country])

    useEffect(() => {
        dispatch(updateShipFromPackageCreateLabelPackage(shipFrom));
    }, [shipFrom])

    useEffect(() => {
        dispatch(updateShipToPackageCreateLabelPackage(shipTo));
    }, [shipTo])

    useEffect(() => {
        if (shipFromRedux) {
            setShipFrom(shipFromRedux);
        }
        if (shipToRedux) {
            setShipTo(shipToRedux);
        }
    }, [])

    // console.log('[BusinessShippingPackageInternationShipmentLocation] shippingCountry', shippingLocationRedux);

    // console.log('[BusinessShippingPackageInternationShipmentLocation] shipFrom', shipFrom);
    // console.log('[BusinessShippingPackageInternationShipmentLocation] shipFromRedux', shipFromRedux);
    // console.log('[BusinessShippingPackageInternationShipmentLocation] shipFrom', shipTo);
    // console.log('[BusinessShippingPackageInternationShipmentLocation] location validation', locationValidation);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography style={styles.ShippingPackageQuickQuoteLocationTitle}>
                    {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDRESS.ADDRESS_DETAILS')}
                </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
                <Box sx={styles.ShippingPackageQuickQuoteLocationBox}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography style={styles.ShippingPackageQuickQuoteLocationHeading}>
                                {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDRESS.SHIP_FROM')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box style={styles.ShippingPackageQuickQuoteLocationSection}>
                                <InputLabel required sx={{fontSize: '14px'}}>
                                    {intl.get('SHIPPING_INFORMATION.COUNTRY')}
                                </InputLabel>
                                <FormControl fullWidth>
                                    <Select
                                        value={shipFrom?.country}
                                        size='small'
                                        onChange={handleShipFromCountry}
                                        error={locationValidation && locationValidation?.shipFromCountry}
                                    >
                                        {
                                            getCountryCode().map(v => {
                                                return <MenuItem value={v.code}>{v.name}</MenuItem>
                                            })
                                        }
                                    </Select>
                                    <FormHelperText
                                        sx={{
                                            color: "error.main",
                                        }}
                                    >
                                        {(locationValidation && locationValidation?.shipFromCountry) && "Required"}
                                    </FormHelperText>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box style={styles.ShippingPackageQuickQuoteLocationSection}>
                                <InputLabel
                                    required={(shipFrom?.country?.toLowerCase() === 'ca' || shipFrom?.country?.toLowerCase() === 'us') && true}
                                    sx={{fontSize: '14px'}}>{intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDRESS.POSTAL_CODE')}</InputLabel>
                                <Autocomplete
                                    // freeSolo
                                    value={shipFrom?.displayPostalCode}
                                    options={shipFromPredictionsZip}
                                    getOptionLabel={option => option?.text}
                                    onInputChange={handleInputShipFromPostalCode}
                                    onChange={handleChangeShipFromPostalCode}
                                    fullWidth
                                    PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                    renderInput={params => <TextField
                                        {...params}
                                        variant='outlined'
                                        className={classes.smallInput}
                                        error={locationValidation && (locationValidation?.shipFromPostalCode || locationValidation?.shipFromPostalCodeValid)}
                                        helperText={locationValidation && (locationValidation?.shipFromPostalCode ? 'Required' : locationValidation?.shipFromPostalCodeValid && 'Invalid')}
                                    />}
                                    renderOption={(option) => (
                                        <Typography
                                            style={{fontSize: '12px'}}
                                        >
                                            {/*<Typography>*/}
                                            {option.description}
                                            {/*</Typography>*/}
                                        </Typography>
                                    )}
                                />
                                {
                                    (!shipFrom?.postalCode && locationValidation?.missingPostalCode) &&
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        color: '#FF0303'
                                    }}>
                                        <Typography style={{fontSize: '14px'}}>
                                            Please include postal code.
                                        </Typography>
                                    </Box>
                                }
                            </Box>
                        </Grid>
                        {
                            shipFrom?.postalCode &&
                            <Grid item xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start'
                                }}>
                                    <Typography>
                                        {shipFrom?.city}, {shipFrom?.province?.name}, {shipFrom?.country}, {shipFrom?.postalCode}
                                    </Typography>
                                </Box>
                            </Grid>
                        }
                    </Grid>
                </Box>
            </Grid>
            <Grid item md={6} xs={12}>
                <Box sx={styles.ShippingPackageQuickQuoteLocationBox}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography style={styles.ShippingPackageQuickQuoteLocationHeading}>
                                {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDRESS.SHIP_TO')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box style={styles.ShippingPackageQuickQuoteLocationSection}>
                                <InputLabel required sx={{fontSize: '14px'}}>
                                    {intl.get('SHIPPING_INFORMATION.COUNTRY')}
                                </InputLabel>
                                <FormControl fullWidth>
                                    <Select
                                        value={shipTo?.country}
                                        size='small'
                                        onChange={handleShipToCountry}
                                        error={locationValidation && locationValidation?.shipToCountry}
                                    >
                                        {
                                            getCountryCode().map(v => {
                                                return <MenuItem value={v.code}>{v.name}</MenuItem>
                                            })
                                        }
                                    </Select>
                                    <FormHelperText
                                        sx={{
                                            color: "error.main",
                                        }}
                                    >
                                        {(locationValidation && locationValidation?.shipToCountry) && "Required"}
                                    </FormHelperText>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box style={styles.ShippingPackageQuickQuoteLocationSection}>
                                <InputLabel
                                    required={(shipTo?.country?.toLowerCase() === 'ca' || shipTo?.country?.toLowerCase() === 'us') && true}
                                    sx={{fontSize: '14px'}}>{intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDRESS.POSTAL_CODE')}</InputLabel>
                                <Autocomplete
                                    // freeSolo
                                    value={shipTo?.displayPostalCode}
                                    options={shipToPredictionsZip}
                                    getOptionLabel={option => option?.text}
                                    onInputChange={handleInputShipToPostalCode}
                                    onChange={handleChangeShipToPostalCode}
                                    fullWidth
                                    PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                    renderInput={params => <TextField
                                        {...params}
                                        variant='outlined'
                                        className={classes.smallInput}
                                        error={locationValidation && (locationValidation?.shipToPostalCode || locationValidation?.shipToPostalCodeValid)}
                                        helperText={locationValidation && (locationValidation?.shipToPostalCode ? 'Required' : locationValidation?.shipToPostalCodeValid && 'Invalid')}
                                    />}
                                    renderOption={(option) => (
                                        <Typography
                                            style={{fontSize: '12px'}}
                                        >
                                            {/*<Typography>*/}
                                            {option.description}
                                            {/*</Typography>*/}
                                        </Typography>
                                    )}
                                />
                                {
                                    (!shipTo?.postalCode && locationValidation?.missingPostalCode) &&
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        color: '#FF0303'
                                    }}>
                                        <Typography style={{fontSize: '14px'}}>
                                            Please include postal code.
                                        </Typography>
                                    </Box>
                                }
                            </Box>
                        </Grid>
                        {
                            shipTo?.postalCode &&
                            <Grid item xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start'
                                }}>
                                    <Typography>
                                        {shipTo?.city}, {shipTo?.province?.name}, {shipTo?.country}, {shipTo?.postalCode}
                                    </Typography>
                                </Box>
                            </Grid>
                        }
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )
}
