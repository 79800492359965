import Typography from "@mui/material/Typography";
import {Divider} from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export const BusinessCrossBorderDashboardItemList = ({itemList, loadingGetItemList, errorGetItemList}) => {

    console.log('item list', itemList);

    if (loadingGetItemList) {
        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                border: '1px solid #B2B2B2',
                borderRadius: '5px',
                padding: '15px',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Typography style={{fontWeight: '600'}}>
                    Customs Items
                </Typography>
                <Divider/>
                <CircularProgress/>
            </Box>
        )
    }

    if (!loadingGetItemList && errorGetItemList) {
        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                border: '1px solid #B2B2B2',
                borderRadius: '5px',
                padding: '15px',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Typography style={{fontWeight: '600'}}>
                    Customs Items
                </Typography>
                <Divider/>
                <Typography style={{fontSize: '14px'}}>
                    Sorry We currently encounter some issues, please try again later.
                </Typography>
            </Box>
        )
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            border: '1px solid #B2B2B2',
            borderRadius: '5px',
            padding: '15px'
        }}>
            <Typography style={{fontWeight: '600'}}>
                Customs Items
            </Typography>
            <Divider/>
            {
                itemList?.map((item, index) => (
                    <Box key={index}>
                        <Typography style={{fontSize: '14px', color: '#1D8B45', fontWeight: '600', textAlign: 'left'}}>
                            {item?.description} * {item?.quantity}
                        </Typography>
                        <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                            {item?.weight} {item?.weightUnit} per unit | $ {item?.unitPrice} per unit | {item?.hsCode}
                        </Typography>
                    </Box>
                ))
            }
        </Box>
    )
}
