import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {RatingShippingCrossBorder} from "./RatingShippingCrossBorder";
import {extractAddress, loadAsyncScript} from "../../Utils/Helper";
import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    toggleShippingListFrom, toggleShippingListTo,
    updateShipAddressError,
    updateShipFrom,
    updateShipFromError,
    updateShipTo
} from "../../actions/ratingAction";
import Grid from "@mui/material/Grid";
import {RatingShippingShipFrom} from "./RatingShippingShipFrom";
import {RatingShippingShipTo} from "./RatingShippingShipTo";
import {IconButton} from "@mui/material";
import {RatingShippingListFrom} from "./RatingShippingListFrom";
import {RatingShippingListTo} from "./RatingShippingListTo";

const style = {
    RatingShippingRoot: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        backgroundColor: '#F9F9F9',
        margin: '20px',
        borderRadius: '15px'
    },
    RatingShippingSection: {
        display: 'flex',
        flexDirection: 'column',
        margin: '10px 20px'
    },
    RatingShippingSectionHeader: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    RatingShippingShipFromAddressSection: {
        width: '100%'
    },
    RatingShippingShipToAddressSection: {
        width: '100%'
    },
    RatingShippingShipErrorText: {
        fontSize: '12px',
        color: "#FF0303"
    }
}

export const RatingShipping = () => {

    const [shippingFromAddress, setShippingFromAddress] = useState({});
    const [shippingToAddress, setShippingToAddress] = useState({});
    const [addressFrom, setAddressFrom] = useState("");
    const [addressTo, setAddressTo] = useState("");
    const [shipAddressErrors, setShipAddressErrors] = useState({});

    const searchInputFrom = useRef(null);
    const searchInputTo = useRef(null);

    const dispatch = useDispatch();

    let shipFrom = useSelector(state => state?.rating?.shippingFromErrors);
    let shipTo = useSelector(state => state?.rating?.shippingToErrors);
    let shipError = useSelector(state => state?.rating?.shippingAddressErrors);
    let shipFromCountryAndProvince = useSelector(state => state?.rating?.shipFromCountryAndProvince);
    let shipListFromAddress = useSelector(state => state?.rating?.shippingListFromAddress);
    let shipListToAddress = useSelector(state => state?.rating?.shippingListToAddress);
    // console.log('ship from country and province', shipFromCountryAndProvince);

    // console.log('ship error', shipError);

    // console.log('ship from', shipFrom)
    // console.log('ship to', shipTo)
    console.log('ship list from address', shipListFromAddress);
    console.log('ship list to address', shipListToAddress);

    useEffect(() => {
        if (Object.keys(shipListFromAddress).length !== 0) {
            setShippingFromAddress(shipListFromAddress);
        }
    }, [shipListFromAddress])

    useEffect(() => {
        if (Object.keys(shipListToAddress).length !== 0) {
            setShippingToAddress(shipListToAddress);
        }
    }, [shipListToAddress])

    const handleShipFromList = () => {
        dispatch(toggleShippingListFrom(true));
    }

    const handleShipFromTo = () => {
        dispatch(toggleShippingListTo(true));
    }

    useEffect(() => {
        dispatch(updateShipFrom(shippingFromAddress));
        validateShipFromAddress();
    }, [shippingFromAddress])

    useEffect(() => {
        dispatch(updateShipTo(shippingToAddress));
        validateShipToAddress();
    }, [shippingToAddress])

    // init map script
    const initMapScript = () => {
        // if script already loaded
        if (window.google) {
            console.log('include');
            return Promise.resolve();
        }
        const src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_API_KEY}&libraries=places&v=weekly`;
        return loadAsyncScript(src)
    }

    // onchange addressFrom
    const onChangeAddressFrom = (autocomplete) => {
        const place = autocomplete.getPlace();
        setAddressFrom(extractAddress(place));
    }

    // onchange addressFrom
    const onChangeAddressTo = (autocomplete) => {
        const place = autocomplete.getPlace();
        setAddressTo(extractAddress(place));
    }

    // init autocomplete
    const initAutocomplete = () => {
        if (!searchInputFrom.current) return;
        if (!searchInputTo.current) return;
        const autocompleteFrom = new window.google.maps.places.Autocomplete(searchInputFrom.current);
        const autocompleteTo = new window.google.maps.places.Autocomplete(searchInputTo.current);

        // const autocomplete = new window.google.maps.places.Autocomplete(address1);
        autocompleteFrom.setFields(["address_component", "geometry"]);
        autocompleteFrom.addListener("place_changed", () => onChangeAddressFrom(autocompleteFrom));

        autocompleteTo.setFields(["address_component", "geometry"]);
        autocompleteTo.addListener("place_changed", () => onChangeAddressTo(autocompleteTo));
    }

    // load map script after amounted
    useEffect(() => {
        initMapScript().then(() => {
            return initAutocomplete()
        })
    }, []);

    useEffect(() => {
        searchInputFrom.current.value = addressFrom.streetName !== undefined ? `${addressFrom.streetNumber} ${addressFrom.streetName}` : ""

        if (addressFrom) {
            setShippingFromAddress(prevState => ({
                ...prevState,
                address: `${addressFrom?.streetNumber} ${addressFrom?.streetName} `,
                city: addressFrom?.city,
                province: addressFrom?.state,
                country: addressFrom?.country,
                postalCode: addressFrom?.zip
            }))
        }
    }, [addressFrom])

    useEffect(() => {
        searchInputTo.current.value = addressTo.streetName !== undefined ? `${addressTo.streetNumber} ${addressTo.streetName}` : ""

        if (addressTo) {
            setShippingToAddress(prevState => ({
                ...prevState,
                address: `${addressTo?.streetNumber} ${addressTo?.streetName} `,
                city: addressTo?.city,
                province: addressTo?.state,
                country: addressTo?.country,
                postalCode: addressTo?.zip
            }))
        }
    }, [addressTo])

    const validateShipFromAddress = () => {
        if (!isNaN(shippingFromAddress.phone) && shippingFromAddress.phone.length < 16 && shippingFromAddress.phone.length > 9) {
            shippingFromAddress?.phone && dispatch(updateShipAddressError({
                errorType: "shipFromPhoneError",
                value: false
            }));
        } else {
            shippingFromAddress?.phone && dispatch(updateShipAddressError({
                errorType: "shipFromPhoneError",
                value: true
            }));
        }
    }

    const validateShipToAddress = () => {
        if (!isNaN(shippingToAddress.phone) && shippingToAddress.phone.length < 16 && shippingToAddress.phone.length > 9) {
            shippingToAddress?.phone && dispatch(updateShipAddressError({
                errorType: 'shipToPhoneError',
                value: false
            }));
        } else {
            shippingToAddress?.phone && dispatch(updateShipAddressError({
                errorType: 'shipToPhoneError',
                value: true
            }));
        }
    }

    return (
        <Box sx={style.RatingShippingRoot}>
            <Box sx={style.RatingShippingSection}>
                <Typography variant="h4" sx={{textAlign: 'center'}}>
                    Shipping Address
                </Typography>
            </Box>
            <Box sx={style.RatingShippingSection}>
                <Box sx={style.RatingShippingSectionHeader}>
                    <Typography sx={{fontSize: '20px', fontWeight: '600'}}>
                        Shipping From
                    </Typography>
                    <IconButton onClick={handleShipFromList}>
                        <img src="/box1.png" alt="" width="25px"/>
                    </IconButton>
                </Box>
                <RatingShippingListFrom/>
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <label htmlFor="name">Name*: </label>
                        </Grid>
                        <Grid item xs={4}>
                            <Box>
                                <input
                                    style={style.RatingShippingShipFromAddressSection}
                                    id="name"
                                    value={shippingFromAddress.name}
                                    onInput={e => setShippingFromAddress(prevState => ({
                                        ...prevState, name: e.target.value
                                    }))}
                                />
                                {shipFrom.includes('name') ?
                                    <p style={style.RatingShippingShipErrorText}>This is a required field.</p> : ""}
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <label htmlFor="company">Company: </label>
                        </Grid>
                        <Grid item xs={4}>
                            <Box>
                                <input
                                    style={style.RatingShippingShipFromAddressSection}
                                    id="company"
                                    value={shippingFromAddress.company}
                                    onInput={e => setShippingFromAddress(prevState => ({
                                        ...prevState, company: e.target.value
                                    }))}
                                />
                                {/*{shipFrom.includes('company') ?*/}
                                {/*    <p style={style.RatingShippingShipErrorText}>This is a required field.</p> : ""}*/}
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <label htmlFor="phone">Phone*:</label>
                        </Grid>
                        <Grid item xs={4}>
                            <Box>
                                <input
                                    style={style.RatingShippingShipFromAddressSection}
                                    id="phone"
                                    type="tel"
                                    value={shippingFromAddress.phone}
                                    onInput={e => setShippingFromAddress(prevState => ({
                                        ...prevState, phone: e.target.value
                                    }))}
                                />
                                {shipFrom.includes('phone') ?
                                    <p style={style.RatingShippingShipErrorText}>This is a required field.</p> :
                                    shipError.shipFromPhoneError ?
                                        <p style={style.RatingShippingShipErrorText}>Phone numbers should consist only
                                            of digits and be between 10 to 15 digits long.</p> :
                                        ""
                                }
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <label htmlFor="email">Email:</label>
                        </Grid>
                        <Grid item xs={4}>
                            <Box>
                                <input
                                    style={style.RatingShippingShipFromAddressSection}
                                    id="email"
                                    type="email"
                                    value={shippingFromAddress.emailAddress}
                                    onInput={e => setShippingFromAddress(prevState => ({
                                        ...prevState, emailAddress: e.target.value
                                    }))}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <label htmlFor="address">Address*:</label>
                        </Grid>
                        <Grid item xs={10}>
                            <Box>
                                <input
                                    style={style.RatingShippingShipFromAddressSection}
                                    ref={searchInputFrom}
                                    type="text"
                                    value={shippingFromAddress.address}
                                    onInput={e => setShippingFromAddress(prevState => ({
                                        ...prevState, address: e.target.value
                                    }))}
                                />
                                {shipFrom.includes('address') ?
                                    <p style={style.RatingShippingShipErrorText}>This is a required field.</p> : ""}
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <label htmlFor="address2">Address2:</label>
                        </Grid>
                        <Grid item xs={10}>
                            <Box>
                                <input
                                    style={style.RatingShippingShipFromAddressSection}
                                    type="text"
                                    value={shippingFromAddress.address2}
                                    onInput={e => setShippingFromAddress(prevState => ({
                                        ...prevState, address2: e.target.value
                                    }))}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <label htmlFor="city">City*:</label>
                        </Grid>
                        <Grid item xs={4}>
                            <Box>
                                <input
                                    style={style.RatingShippingShipFromAddressSection}
                                    id="city"
                                    type="text"
                                    value={shippingFromAddress.city}
                                    onInput={e => setShippingFromAddress(prevState => ({
                                        ...prevState, city: e.target.value
                                    }))}
                                />
                                {shipFrom.includes('city') ?
                                    <p style={style.RatingShippingShipErrorText}>This is a required field.</p> : ""}
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <label htmlFor="province">Province*:</label>
                        </Grid>
                        <Grid item xs={4}>
                            <Box>
                                <input
                                    style={style.RatingShippingShipFromAddressSection}
                                    id="province"
                                    type="text"
                                    value={shippingFromAddress.province}
                                    onInput={e => setShippingFromAddress(prevState => ({
                                        ...prevState, province: e.target.value
                                    }))}
                                />
                                {shipFrom.includes('province') ?
                                    <p style={style.RatingShippingShipErrorText}>This is a required field.</p> :
                                    shipFromCountryAndProvince === null ? "" :
                                        shipFromCountryAndProvince ? "" :
                                            <p style={style.RatingShippingShipErrorText}>
                                                The shipping from country must be
                                                either Canada or United States, and
                                                a valid state or province must be
                                                provided.
                                            </p>}
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <label htmlFor="country">Country*:</label>
                        </Grid>
                        <Grid item xs={4}>
                            <Box>
                                <input
                                    style={style.RatingShippingShipFromAddressSection}
                                    id="country"
                                    type="text"
                                    value={shippingFromAddress.country}
                                    onInput={e => setShippingFromAddress(prevState => ({
                                        ...prevState, country: e.target.value
                                    }))}
                                />
                                {shipFrom.includes('country') ?
                                    <p style={style.RatingShippingShipErrorText}>This is a required field.</p> :
                                    shipFromCountryAndProvince === null ? "" :
                                        shipFromCountryAndProvince ? "" :
                                            <p style={style.RatingShippingShipErrorText}>
                                                The shipping from country must be
                                                either Canada or United States, and
                                                a valid state or province must be
                                                provided.
                                            </p>}
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <label htmlFor="postalCode">Postal Code*:</label>
                        </Grid>
                        <Grid item xs={4}>
                            <Box>
                                <input
                                    style={style.RatingShippingShipFromAddressSection}
                                    id="postalCode"
                                    type="text"
                                    value={shippingFromAddress.postalCode}
                                    onInput={e => setShippingFromAddress(prevState => ({
                                        ...prevState, postalCode: e.target.value
                                    }))}
                                />
                                {shipFrom.includes('postalCode') ?
                                    <p style={style.RatingShippingShipErrorText}>This is a required field.</p> : ""}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box sx={style.RatingShippingSection}>
                <Box sx={style.RatingShippingSectionHeader}>
                    <Typography sx={{fontSize: '20px', fontWeight: '600'}}>
                        Shipping To
                    </Typography>
                    <IconButton onClick={handleShipFromTo}>
                        <img src="/box1.png" alt="" width='25px'/>
                    </IconButton>
                </Box>
                <RatingShippingListTo/>
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <label htmlFor="name">Name*: </label>
                        </Grid>
                        <Grid item xs={4}>
                            <Box>
                                <input
                                    style={style.RatingShippingShipToAddressSection}
                                    id="name"
                                    value={shippingToAddress.name}
                                    onInput={e => setShippingToAddress(prevState => ({
                                        ...prevState, name: e.target.value
                                    }))}
                                />
                                {shipTo.includes('name') ?
                                    <p style={style.RatingShippingShipErrorText}>This is a required field.</p> : ""}
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <label htmlFor="company">Company: </label>
                        </Grid>
                        <Grid item xs={4}>
                            <Box>
                                <input
                                    style={style.RatingShippingShipToAddressSection}
                                    id="company"
                                    value={shippingToAddress.company}
                                    onInput={e => setShippingToAddress(prevState => ({
                                        ...prevState, company: e.target.value
                                    }))}
                                />
                                {/*{shipTo.includes('company') ?*/}
                                {/*    <p style={style.RatingShippingShipErrorText}>This is a required field.</p> : ""}*/}
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <label htmlFor="phone">Phone*:</label>
                        </Grid>
                        <Grid item xs={4}>
                            <Box>
                                <input
                                    style={style.RatingShippingShipToAddressSection}
                                    id="phone"
                                    type="tel"
                                    value={shippingToAddress.phone}
                                    onInput={e => setShippingToAddress(prevState => ({
                                        ...prevState, phone: e.target.value
                                    }))}
                                />
                                {shipTo.includes('phone') ?
                                    <p style={style.RatingShippingShipErrorText}>This is a required field.</p> :
                                    shipError.shipToPhoneError ?
                                        <p style={style.RatingShippingShipErrorText}>Phone numbers should consist only
                                            of digits and be between 10 to 15 digits long.</p> :
                                        ""
                                }
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <label htmlFor="email">Email:</label>
                        </Grid>
                        <Grid item xs={4}>
                            <Box>
                                <input
                                    style={style.RatingShippingShipToAddressSection}
                                    id="email"
                                    type="email"
                                    value={shippingToAddress.emailAddress}
                                    onInput={e => setShippingToAddress(prevState => ({
                                        ...prevState, emailAddress: e.target.value
                                    }))}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <label htmlFor="address">Address*:</label>
                        </Grid>
                        <Grid item xs={10}>
                            <Box>
                                <input
                                    style={style.RatingShippingShipToAddressSection}
                                    ref={searchInputTo}
                                    type="text"
                                    value={shippingToAddress.address}
                                    onInput={e => setShippingToAddress(prevState => ({
                                        ...prevState, address: e.target.value
                                    }))}
                                />
                            </Box>
                            {shipTo.includes('address') ?
                                <p style={style.RatingShippingShipErrorText}>This is a required field.</p> : ""}
                        </Grid>
                        <Grid item xs={2}>
                            <label htmlFor="address2">Address2:</label>
                        </Grid>
                        <Grid item xs={10}>
                            <Box>
                                <input
                                    style={style.RatingShippingShipToAddressSection}
                                    type="text"
                                    value={shippingToAddress.address2}
                                    onInput={e => setShippingToAddress(prevState => ({
                                        ...prevState, address2: e.target.value
                                    }))}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <label htmlFor="city">City*:</label>
                        </Grid>
                        <Grid item xs={4}>
                            <Box>
                                <input
                                    style={style.RatingShippingShipToAddressSection}
                                    id="city"
                                    type="text"
                                    value={shippingToAddress.city}
                                    onInput={e => setShippingToAddress(prevState => ({
                                        ...prevState, city: e.target.value
                                    }))}
                                />
                                {shipTo.includes('city') ?
                                    <p style={style.RatingShippingShipErrorText}>This is a required field.</p> : ""}
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <label htmlFor="province">Province*:</label>
                        </Grid>
                        <Grid item xs={4}>
                            <Box>
                                <input
                                    style={style.RatingShippingShipToAddressSection}
                                    id="province"
                                    type="text"
                                    value={shippingToAddress.province}
                                    onInput={e => setShippingToAddress(prevState => ({
                                        ...prevState, province: e.target.value
                                    }))}
                                />
                                {shipTo.includes('province') ?
                                    <p style={style.RatingShippingShipErrorText}>This is a required field.</p> : ""}
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <label htmlFor="country">Country*:</label>
                        </Grid>
                        <Grid item xs={4}>
                            <Box>
                                <input
                                    style={style.RatingShippingShipToAddressSection}
                                    id="country"
                                    type="text"
                                    value={shippingToAddress.country}
                                    onInput={e => setShippingToAddress(prevState => ({
                                        ...prevState, country: e.target.value
                                    }))}
                                />
                                {shipTo.includes('country') ?
                                    <p style={style.RatingShippingShipErrorText}>This is a required field.</p> : ""}
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <label htmlFor="postalCode">Postal Code*:</label>
                        </Grid>
                        <Grid item xs={4}>
                            <Box>
                                <input
                                    style={style.RatingShippingShipToAddressSection}
                                    id="postalCode"
                                    type="text"
                                    value={shippingToAddress.postalCode}
                                    onInput={e => setShippingToAddress(prevState => ({
                                        ...prevState, postalCode: e.target.value
                                    }))}
                                />
                                {shipTo.includes('postalCode') ?
                                    <p style={style.RatingShippingShipErrorText}>This is a required field.</p> : ""}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <RatingShippingCrossBorder/>
        </Box>
    )
}