import {MovingOrderDashboardETransfer} from "./MovingOrderDashboardETransfer";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {useState} from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import EmailIcon from "../../images/emailTransferIcon.png";
import CreditIcon from "../../images/creditCardIcon.png";
import wechatPay from "../../images/wechatPay.svg";
import aliPay from "../../images/alipay.svg";
import {MovingOrderDashboardCreditCard} from "./MovingOrderDashboardCreditCard";
import {MovingOrderDashboardWeChatPay} from "./MovingOrderDashboardWeChatPay";
import {MovingOrderDashboardAliPay} from "./MovingOrderDashboardAliPay";

export const MovingOrderDashboardPayUUCargo = ({
                                                   orderDetails,
                                                   cbHandleMethod,
                                                   cbHandleReferenceNumber,
                                                   cbGetCreditCard
                                               }) => {

    console.log('[MovingOrderDashboardPayUUCargo] order details', orderDetails);

    const [selectPayment, setSelectPayment] = useState('email');

    const handleSelectPayment = (method) => {
        setSelectPayment(method);
        cbHandleMethod(method);
    }

    return (
        <Box sx={{
            border: '1px solid #B2B2B2',
            borderRadius: '10px',
            padding: '20px',
        }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography sx={{fontWeight: '600', textAlign: 'left'}}>
                        Choose A Payment Method
                    </Typography>
                </Grid>
                <Grid item md={3} xs={6}>
                    <Box sx={{
                        position: 'relative',
                        height: '100px'
                    }}>
                        {
                            selectPayment === 'email' &&
                            <CheckCircleIcon
                                style={{
                                    position: 'absolute',
                                    right: '-5%',
                                    top: '-10%',
                                    zIndex: 1,
                                    color: '#1D8B45',
                                    backgroundColor: '#ffffff',
                                    borderRadius: '50%'
                                }}
                            />
                        }
                        <LoadingButton
                            sx={{
                                border: selectPayment === 'email' ? '2px solid #1D8B45' : '2px solid #A6A6A6',
                                width: '100%',
                                height: '100%',
                                padding: '15px 0px',
                                overflow: 'visible',
                                position: 'relative',
                                borderRadius: '10px',
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                            onClick={() => handleSelectPayment('email')}
                        >
                            <img src={EmailIcon} alt="email-icon" width={60}/>
                            <Typography sx={{
                                color: '#000000',
                                fontSize: '12px',
                                textTransform: 'none',
                                fontWeight: '600'
                            }}>
                                Email Transfer
                            </Typography>
                        </LoadingButton>
                    </Box>
                </Grid>
                <Grid item md={3} xs={6}>
                    <Box sx={{
                        position: 'relative',
                        height: '100px'
                    }}>
                        {
                            selectPayment === 'credit' &&
                            <CheckCircleIcon
                                style={{
                                    position: 'absolute',
                                    right: '-5%',
                                    top: '-10%',
                                    zIndex: 1,
                                    color: '#1D8B45',
                                    backgroundColor: '#ffffff',
                                    borderRadius: '50%'
                                }}
                            />
                        }
                        <LoadingButton
                            sx={{
                                border: selectPayment === 'credit' ? '2px solid #1D8B45' : '2px solid #A6A6A6',
                                width: '100%',
                                height: '100%',
                                padding: '15px 0px',
                                overflow: 'visible',
                                position: 'relative',
                                borderRadius: '10px',
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                            onClick={() => handleSelectPayment('credit')}
                        >
                            <img src={CreditIcon} alt="credit-icon" width={60}/>
                            <Typography sx={{
                                color: '#000000',
                                fontSize: '12px',
                                textTransform: 'none',
                                fontWeight: '600'
                            }}>
                                Credit Card
                            </Typography>
                        </LoadingButton>
                    </Box>
                </Grid>
                <Grid item md={3} xs={6}>
                    <Box sx={{
                        position: 'relative',
                        height: '100px'
                    }}>
                        {
                            selectPayment === 'wechatPay' &&
                            <CheckCircleIcon
                                style={{
                                    position: 'absolute',
                                    right: '-5%',
                                    top: '-10%',
                                    zIndex: 1,
                                    color: '#1D8B45',
                                    backgroundColor: '#ffffff',
                                    borderRadius: '50%'
                                }}
                            />
                        }
                        <LoadingButton
                            sx={{
                                border: selectPayment === 'wechatPay' ? '2px solid #1D8B45' : '2px solid #A6A6A6',
                                width: '100%',
                                height: '100%',
                                padding: '15px 0px',
                                overflow: 'visible',
                                position: 'relative',
                                borderRadius: '10px',
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                            onClick={() => handleSelectPayment('wechatPay')}
                        >
                            <img src={wechatPay} alt="wechatPayLogo" width={50}/>
                            <Typography sx={{
                                color: '#000000',
                                fontSize: '12px',
                                textTransform: 'none',
                                fontWeight: '600'
                            }}>
                                WeChat Pay
                            </Typography>
                        </LoadingButton>
                    </Box>
                </Grid>
                <Grid item md={3} xs={6}>
                    <Box sx={{
                        position: 'relative',
                        height: '100px'
                    }}>
                        {
                            selectPayment === 'aliPay' &&
                            <CheckCircleIcon
                                style={{
                                    position: 'absolute',
                                    right: '-5%',
                                    top: '-10%',
                                    zIndex: 1,
                                    color: '#1D8B45',
                                    backgroundColor: '#ffffff',
                                    borderRadius: '50%'
                                }}
                            />
                        }
                        <LoadingButton
                            sx={{
                                border: selectPayment === 'aliPay' ? '2px solid #1D8B45' : '2px solid #A6A6A6',
                                width: '100%',
                                height: '100%',
                                padding: '15px 0px',
                                overflow: 'visible',
                                position: 'relative',
                                borderRadius: '10px',
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                            onClick={() => handleSelectPayment('aliPay')}
                        >
                            <img src={aliPay} alt="aliPayLogo" width={50}/>
                            <Typography sx={{
                                color: '#000000',
                                fontSize: '12px',
                                textTransform: 'none',
                                fontWeight: '600'
                            }}>
                                Ali Pay
                            </Typography>
                        </LoadingButton>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    {
                        selectPayment === 'email' && <MovingOrderDashboardETransfer orderDetails={orderDetails}
                                                                                    cbHandleReferenceNumber={cbHandleReferenceNumber}/>
                    }
                    {
                        selectPayment === 'credit' &&
                        <MovingOrderDashboardCreditCard orderDetails={orderDetails} cbGetCreditCard={cbGetCreditCard}/>
                    }
                    {
                        selectPayment === 'wechatPay' && <MovingOrderDashboardWeChatPay orderDetails={orderDetails}/>
                    }
                    {
                        selectPayment === 'aliPay' && <MovingOrderDashboardAliPay orderDetails={orderDetails}/>
                    }
                </Grid>
            </Grid>
        </Box>
    )
}