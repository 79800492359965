const cmToInch = (value) => {
  return value * 0.393701;
}
const calculateVolumeByCubicFoot = ({
  length,
  width,
  height,
  dimensionUnit = "in",
  quantity = 1
}) => {
  if (dimensionUnit?.toLowerCase() === "cm") {
    length = cmToInch(length);
    width = cmToInch(width);
    height = cmToInch(height);
  }
  const calculateCube = +length * +width * +height * +quantity;
  return +(calculateCube * 0.000578704).toFixed(0);
};

const inchesToCubicMeters = ({ length, width, height }) => {
  // Conversion factor: 1 cubic inch = 0.0000163871 cubic meters
  const cubicMeterConversionFactor = 0.0000163871;

  // Calculate the volume in cubic inches
  const volumeInCubicInches = length * width * height;

  // Convert the volume to cubic meters
  const volumeInCubicMeters = volumeInCubicInches * cubicMeterConversionFactor;

  return volumeInCubicMeters;
};

const getAmountOfStandardSizePallets = ({ length, width, height, dimensionUnit = "in" }) => {
  try {
    if (dimensionUnit?.toLowerCase() !== "in") {
      length = cmToInch(length);
      width = cmToInch(width);
      height = cmToInch(height);
    }
    if (+length <= 48 && +width <= 48 && +height <= 90) {
      return 1;
    }

    // Non-standard size pallets

    let lengthNumber = 0;
    let widthNumber = 0;

    const ratioArray = [1, 1.5, 2, 2.5, 3];

    // For Length
    for (const ratio of ratioArray) {
      if (+length < 48 * ratio) {
        lengthNumber += ratio;

        break;
      }
    }

    // For Width
    for (const ratio of ratioArray) {
      if (+width < 48 * ratio) {
        widthNumber += ratio;
        break;
      }
    }
    // let number = Math.max(lengthNumber, widthNumber);
    let number = 0;
    if (lengthNumber > 1) {
      number = lengthNumber;
    }
    if (widthNumber > 1) {
      number += widthNumber;
    }
    if (number < 1) {
      number = 1;
    }

    return number;
  } catch (e) {
    throw e;
  }
};

module.exports = {
  calculateVolumeByCubicFoot,
  getAmountOfStandardSizePallets,
  inchesToCubicMeters
};
