import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  // Snackbar,
  // DialogTitle,
  // CircularProgress,
  Box,
} from "@material-ui/core";
// import { Alert } from "@material-ui/lab";

// import PropTypes from "prop-types";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// import EventIcon from "@material-ui/icons/Event";

import { GET_CARGO_CONTROL_TRANSACTION } from "../../../Utils/apiUrl";
import {
  //   getHeaders,
  getURLParams,
  //   errHandling,
} from "../../../Utils/fetchUtil";
import LoadingContainer from "../../shared/loadingContainer";

const courier = "J-LINK";

const ShipmentDetailsTransaction = () => {
  // const matches = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const matches = useMediaQuery("(max-width:800px)");

  const classes = useStyles();

  const { id } = useParams();

  const [shipmentData, setShipmentData] = useState([]);
  const [loading, setDataLoading] = useState(true);

  useEffect(() => {
    // setLastestShippingLoading(true);

    axios
      .get(
        getURLParams(GET_CARGO_CONTROL_TRANSACTION, {
          ccn: id,
        })
      )
      .then((response) => {
        setShipmentData(response.data);
        setDataLoading(false);
        // setLastestShippingLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setDataLoading(false);
      });
  }, [id]);

  return loading ? (
    <LoadingContainer />
  ) : (
    <>
      {shipmentData.map((shipment, i, list) => (
        <Box
          className={matches ? classes.smLayout : classes.wideLayout}
          mb={i + 1 !== list.length ? 6 : 0}
          key={shipment.transaction_no}
        >
          <Box
            className={
              matches
                ? classes.smLeftColumn
                : `${classes.leftColumn} ${classes.firstColumn}`
            }
          >
            <Box mb={2}>
              <Box className={classes.sessionHeader}>Transaction</Box>
              <div className={classes.divider} />
              <Box display="flex" justifyContent="space-between">
                <Box className={classes.priceBodyText}>Transaction #</Box>
                <Box className={classes.priceBodyText}>
                  {shipment.transaction_no}
                </Box>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Box className={classes.priceBodyText}>Container #</Box>
                <Box className={classes.priceBodyText}>
                  {shipment.container_no}
                </Box>
              </Box>
            </Box>

            <Box mb={2}>
              <Box className={classes.sessionHeader}>Status</Box>
              <div className={classes.divider} />
              <Box display="flex" justifyContent="space-between">
                <Box className={classes.priceBodyText}>Status</Box>
                <div>
                  <Box className={classes.priceBodyText}>
                    {shipment.release_code}
                  </Box>
                </div>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Box className={classes.priceBodyText}>Message Date</Box>
                <Box className={classes.priceBodyText}>
                  {shipment.cusres_date?.split("T")[0] || ""}
                </Box>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Box
                  className={classes.priceBodyText}
                  flexBasis="125px"
                  flexShrink="0"
                >
                  Message Code
                </Box>
                <Box className={classes.priceBodyText} textAlign="right">
                  {shipment.code_text}
                </Box>
              </Box>
            </Box>
          </Box>

          <Box flexGrow="1">
            <Box mb={2}>
              <Box className={classes.sessionHeader}>Courier</Box>
              <div className={classes.divider} />
              <Box>
                <Box className={classes.priceBodyText}>{courier}</Box>
              </Box>
            </Box>
          </Box>
        </Box>
      ))}

      <Box className={classes.getCodeButton}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => {
            window.print();
            return false;
          }}
          style={{
            textTransform: "unset",
            fontSize: "1rem",
          }}
        >
          Print this page
        </Button>
      </Box>
      <br />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  smLayout: {
    display: "block",
    "@media print": {
      display: "flex",
    },
  },
  wideLayout: {
    display: "flex",
  },
  smLeftColumn: {
    "@media print": {
      marginRight: theme.spacing(4),
      flexBasis: "70%",
    },
  },
  leftColumn: {
    marginRight: theme.spacing(4),
  },
  rootContainer: {
    margin: "0 auto",
    // width: '40vw',
    width: "600px",
    paddingLeft: "3vw",
    paddingRight: "3vw",
  },
  smRootContainer: {
    width: "100%",
    paddingLeft: "3vw",
    paddingRight: "3vw",
  },
  fixWidthButton: {
    width: "8rem",
  },
  outLinedButton: {
    fontSize: "12pt",
    margin: "7px",
    marginRight: "24px",
  },
  textfield: {
    width: "100%",
  },
  calendar: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "70%",
  },
  backDrop: {
    timeout: 500,
    backdropFilter: "blur(3px)",
    backgroundColor: "rgba(0,0,30,0.4)",
  },
  getCodeButton: {
    display: "flex",
    justifyContent: "center",
  },
  dialogTitle: {
    padding: "1vh 1vw 0.5vh",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "rgb(141, 173, 155)",
  },
  orderContainer: {
    backgroundColor: "rgba(255, 255, 255, 0.788)",
    borderRadius: "5px",
  },
  feeWrapper: {
    // border: "1px solid rgba(0,0,0,0.3)",
    borderRadius: 5,
    marginBottom: theme.spacing(2),
    backgroundColor: "#FEEAAE",
  },
  inputBorder: {
    border: "1px solid black",
    borderRadius: "5px",
  },
  alertRoot: {
    width: "80%",
    minWidth: "200px",
    margin: "10px auto",
  },
  summaryHeader: {
    fontSize: "16pt",
    color: "seagreen",
    fontFamily: "Montserrat",
    // borderBottom: '1px, solid rgb(141, 173, 155)',
  },
  sessionHeader: {
    fontSize: "1rem",
    fontWeight: "700",
    fontFamily: "Montserrat",
    // borderBottom: '1px, solid rgb(141, 173, 155)',
  },
  summaryBodyText: {
    fontSize: "13pt",
    color: "rgb(141, 173, 155)",
    fontFamily: "Montserrat",
  },
  priceBodyText: {
    fontSize: "1rem",
    // color: "seagreen",
    fontFamily: "Montserrat",
  },
  alignRight: {
    textAlign: "right",
  },
  divider: {
    borderBottom: "1px solid rgba(0,0,0,0.3)",
    marginBottom: theme.spacing(1),
  },
  firstColumn: {
    flexBasis: "70%",
  },
}));

// ShipmentDetailsTransaction.propTypes = {
//   guest: PropTypes.bool,
// };

export default ShipmentDetailsTransaction;
