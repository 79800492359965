import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {getAccessToken} from "../../Utils/doToken";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {useParams} from "react-router-dom";
import {useState} from "react";
import MuiAlert from "@mui/material/Alert";
import {Snackbar} from "@mui/material";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const MovingOrderDashboardMakePayment = ({method, referenceNumber, creditCard}) => {

    const accessToken = getAccessToken("access_token");
    const {orderId} = useParams();

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [loadingCard, setLoadingCard] = useState(false);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const payCreditCard = async () => {
        let requestURL = `${NODE_ROUTE_URI}/movingService/payMovingRemainingAmountViaCreditCard`;
        setLoadingCard(true);
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: {
                    backendOrderId: orderId,
                    cardId: creditCard
                }
            })
            setErrorMessage('');
            setSuccessMessage('Successfully pay order');
            handleSuccessMessage();
        } catch (e) {
            console.log('error', e.response);
            handleErrorMessage();
            setErrorMessage(`Fail to pay order.`);
        } finally {
            setLoadingCard(false);
        }
    }

    const handlePayCredit = () => {
        payCreditCard();
    }

    console.log('[MovingOrderDashboardMakePayment] creditCard', creditCard);


    return (
        <>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            {
                method === 'email' &&
                <Grid item md={6} xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        <LoadingButton
                            variant='contained'
                            sx={{
                                backgroundColor: '#1D8B45',
                                "&:hover": {
                                    backgroundColor: '#1D8B45',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                            // onClick={handleSend}
                        >
                            <Typography sx={{textTransform: 'none'}}>
                                Pay
                            </Typography>
                        </LoadingButton>
                    </Box>
                </Grid>
            }
            {
                method === 'credit' &&
                <Grid item md={6} xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        <LoadingButton
                            variant='contained'
                            sx={{
                                backgroundColor: '#1D8B45',
                                "&:hover": {
                                    backgroundColor: '#1D8B45',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                            loading={loadingCard}
                            onClick={handlePayCredit}
                        >
                            <Typography sx={{textTransform: 'none'}}>
                                Pay
                            </Typography>
                        </LoadingButton>
                    </Box>
                </Grid>
            }
        </>
    )
}