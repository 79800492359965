import {Button, Container, Snackbar} from "@mui/material";
import Box from "@mui/material/Box";
import {MovingServiceAddress} from "./MovingServiceAddress";
import {MovingServiceItem} from "./MovingServiceItem";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {
    checkAllPropertiesHaveValueFalse,
    getLocation,
    getMissingKeysForMovingAddress, getMissingKeysForMovingAddressFromItem,
    getMissingKeysForMovingCarAddress,
    getMissingKeysForMovingLocation,
    isValidPostalCode,
    isValidZipCode,
    loadAsyncScript
} from "../../Utils/Helper";
import {
    updateMovingServiceBasicRate, updateMovingServiceExpressProperty,
    updateMovingServiceMoveFromAddressMissing, updateMovingServiceMoveFromCarAddressMissing,
    updateMovingServiceMoveToAddressMissing, updateMovingServiceMoveToCarAddressMissing
} from "../../actions/movingServiceAction";
import '@fontsource/roboto';
import {NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {useHistory, useParams} from "react-router-dom";
import {
    updateLandingEstimateDeliverPostalCodeValid,
    updateLandingEstimatePickupPostalCodeValid
} from "../../actions/landingEstimateAction";
import {MovingServiceAddressCar} from "./MovingServiceAddressCar";
import {MovingServiceCar} from "./MovingServiceCar";
import Checkbox from "@mui/material/Checkbox";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {ReactComponent as USAIcon} from "../../images/homepageIcon/USA.svg";
import {ReactComponent as CanadaIcon} from "../../images/homepageIcon/CA.svg";
import PublicIcon from "@mui/icons-material/Public";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import {MovingServiceExpress} from "./MovingServiceExpress";
import {getAccessToken} from "../../Utils/doToken";
import intl from "react-intl-universal";
import {MovingServiceRequestQuote} from "./MovingServiceRequestQuote";
import {MovingServiceRoomPlaceItemList} from "./MovingServiceRoomPlaceItemList";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    MovingServiceRoot: {
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        marginBottom: '40px',
    },
    MovingServiceButtonSection: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',

    },
    MovingServiceButton: {
        // width: '100%',
        // height: '100%',
        color: '#FFFFFF',
        fontSize: '16px',
        fontWeight: '600',
        padding: '10px 30px',
        borderRadius: '10px'
    },
}

const requiredItemProperties = ["length", "width", "height", "quantity", "weight", "lengthUnit", "weightUnit", "type"];
const validateRequiredItemProperties = ["length", "width", "height", "weight", "quantity"];

export const MovingService = () => {

    const {tab} = useParams();

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    let movingFromAddress = useSelector(state => state?.movingService?.movingFromAddress);
    let movingToAddress = useSelector(state => state?.movingService?.movingToAddress);

    const dispatch = useDispatch();

    const history = useHistory();

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const validateMovingFromPostalCode = () => {
        if (movingFromAddress?.country === 'CA') {
            const result = isValidPostalCode(movingFromAddress?.postalCode);
            return result;
        } else {
            const result = isValidZipCode(movingFromAddress?.postalCode);
            console.log(result);
            return result;
        }
    }

    const validateMovingToPostalCode = () => {
        if (movingToAddress?.country === 'CA') {
            const result = isValidPostalCode(movingToAddress?.postalCode);
            console.log(result);
            return result;
        } else {
            const result = isValidZipCode(movingToAddress?.postalCode);
            return result;
        }
    }

    const validatePostalCode = () => {
        const movingFromPostalCode = validateMovingFromPostalCode();
        const movingToPostalCode = validateMovingToPostalCode();
        return (movingFromPostalCode && movingToPostalCode);
    }

    const validationItem = () => {
        setErrorMessage('');
        setToastOpen(false);

        const validateMovingFromMissing = getMissingKeysForMovingAddressFromItem(movingFromAddress);
        const validateMovingToMissing = getMissingKeysForMovingAddressFromItem(movingToAddress);

        console.log('[validationItem] validateMovingFromMissing', validateMovingFromMissing)

        !validatePostalCode() && setErrorMessage(prevState => prevState + ' Postal code is invalid.');
        validateMovingFromMissing.length > 0 && setErrorMessage(prevState => prevState + ' Move from is missing one or more required fields.');
        validateMovingToMissing.length > 0 && setErrorMessage(prevState => prevState + ' Move to is missing one or more required fields.');

        dispatch(updateMovingServiceMoveFromAddressMissing(validateMovingFromMissing));
        dispatch(updateMovingServiceMoveToAddressMissing(validateMovingToMissing));

        if (!validatePostalCode() ||
            validateMovingFromMissing.length > 0 ||
            validateMovingToMissing.length > 0
        ) {
            handleErrorMessage();
            return false
        } else {
            return true
        }

    }

    const handleNext = () => {
        if (validationItem()) {
            if (tab === 'item') {
                history.push('/transportation/item/list');
                window.scrollTo(0, 0); // Scroll to the top
            } else {
                history.push('/transportation/express/list');
                window.scrollTo(0, 0); // Scroll to the top
            }
        }
    }

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            {(tab === 'item' || tab === 'vehicle' || tab === 'express') &&
                <Box sx={styles.MovingServiceRoot}>
                    <MovingServiceAddress tab={tab}/>
                    <Box sx={styles.MovingServiceButtonSection}>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: '#1D8B45',
                                "&:hover": {
                                    backgroundColor: '#1D8B45',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                            onClick={handleNext}
                        >
                            <Typography style={{textTransform: 'none'}}>
                                {intl.get('BUTTON.NEXT')}
                            </Typography>
                        </Button>
                    </Box>
                    {
                        errorMessage &&
                        <MovingServiceRequestQuote errorMessage={errorMessage}/>
                    }
                </Box>
            }
        </Container>
    )
}