export const separateTaxes = ({ excludeTaxAmount, includeTaxAmount}) => {
  // $taxes = ($includeTaxAmount - $excludeTaxAmount) > 0 ? ($includeTaxAmount - $excludeTaxAmount) : 0;
  //
  // $gstTax = $includeTaxAmount * 0.05;
  //
  // $pstTax = ($taxes - $gstTax) > 0 ? ($taxes - $gstTax) : 0;
  //
  // return ($excludeTaxAmount, $includeTaxAmount, $taxes, $gstTax, $pstTax);
  includeTaxAmount = +includeTaxAmount;
  excludeTaxAmount = +excludeTaxAmount;
  const taxes = (includeTaxAmount - excludeTaxAmount) > 0 ? (includeTaxAmount - excludeTaxAmount) : 0;
  const gstTax = taxes <= excludeTaxAmount * 0.051 ? taxes : 0;
  // const pstTax = (taxes - gstTax) > 0 ? (taxes - gstTax) : 0;
  const qstTax = (taxes >= excludeTaxAmount * 0.0970 && taxes <= excludeTaxAmount * 0.0980) ? taxes : 0;
  const hstTax = ((taxes > excludeTaxAmount * 0.051 && taxes < excludeTaxAmount * 0.0970) || taxes > excludeTaxAmount * 0.0980) ? taxes : 0;
  return {
    excludeTaxAmount,
    includeTaxAmount,
    taxes: taxes.toFixed(2),
    // gstTax: gstTax.toFixed(2),
    // pstTax: pstTax.toFixed(2),
    gstTax: gstTax.toFixed(2),
    pstTax: 0,
    qstTax: qstTax.toFixed(2),
    hstTax: hstTax.toFixed(2),
    "GST/HST": taxes.toFixed(2)
  }
}
