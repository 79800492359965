export const setTokenToLocal = (token) => {
    localStorage.setItem('token', token)
}

export const setToken = (token, type, ttl) => {
    const now = new Date()
    const newToken = {
        value: token,
        expiry: now.getTime() + ttl
    }

    if (type === 'local'){
        setTokenToLocal(token)
    }
}

export const setTokeninCookie = (tokenName, token, expDays) => {
    let date = new Date()
    date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000))
    const expires = "expires=" + date.toUTCString()
    const domain = process.env.NODE_ENV === 'production' ? 'domain=.uucargo.ca;' : '';
    console.log('[setTokeninCookie] domain', domain);
    // document.cookie = tokenName + "=" + token + "; " + expires + "; path=/"
    document.cookie = `${tokenName}=${encodeURIComponent(token)}; ${expires}; path=/; ${domain}`
}

export const getAccessToken = (cName) => {
    const name = cName + "="
    const cDecoded = decodeURIComponent(document.cookie) //to be careful
    const cArr = cDecoded.split('; ')
    let res
    cArr.forEach(val => {
        if (val.indexOf(name) === 0) res = val.substring(name.length)
    })
    return res
}

export const deleteToken = (tokenName) => {
    let date = new Date();
    date.setTime(date.getTime() - (7 * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    if (process.env.NODE_ENV === 'production') {
        // Set domain based on environment
        const rootDomain = 'domain=.uucargo.ca;';
        const subDomain = 'domain=app.uucargo.ca;';
        // Delete cookie for the root domain
        document.cookie = `${tokenName}=; ${expires}; path=/; ${rootDomain}`;

        // Delete cookie for the specified subdomain
        document.cookie = `${tokenName}=; ${expires}; path=/; ${subDomain}`;

        // Delete cookie for the local host
        document.cookie = `${tokenName}=; ${expires}; path=/`;
    } else {
        // Delete cookie for the local host
        document.cookie = `${tokenName}=; ${expires}; path=/`;
    }
}

export const getTokenFromLocal = () => {
    return localStorage.getItem('token')
}

export const getToken = () => {
    return getTokenFromLocal()
}


export const getTokenFromCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length)
        }
    }
    return "";
}

//to-do create a function to get current user

//to-do create a function to get current driver

