import {useHistory} from "react-router-dom";
import {useEffect, useState} from "react";
import intl from "react-intl-universal";
import {getAccessToken} from "../../Utils/doToken";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import LoadingButton from "@mui/lab/LoadingButton";
import {Divider} from "@mui/material";

const styles = {
    ShippingPackageCrossBorderRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        // margin: '100px 0'
    },

    ShippingPackageCrossBorderHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },

    ShippingPackageCrossBorderSubHeading: {
        fontSize: '16px',
        fontWeight: '600',
        textAlign: 'left'
    },
}

export const ReturnLabelCrossBorder = ({orderData, selectedRate, tranId}) => {

    const history = useHistory();

    const [crossBorderFee, setCrossBorderFee] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [error, setError] = useState(false);

    const getCrossBorderFee = async () => {

        setLoading(true);
        let requestURL = `${NODE_ROUTE_URI}/brokerage/package/landedCostByServiceName`;
        const token = getAccessToken("access_token");

        let packingListData = orderData?.packingList.map(element => {
            const updatedElement = {
                description: element?.item_desc,
                sku: element?.item_sku,
                weight: element?.weight,
                weightUnit: element?.weight_unit,
                UOM: element?.uom,
                hscode: element?.hs_code,
                originCountryCode: element?.origi_country,
                currency: element?.currency_code,
                priceEach: element?.unit_price,
                quantity: element?.quantity,
            };
            return updatedElement;
        });

        let data = {
            serviceName: selectedRate?.accountBase,
            importCountryCode: orderData?.shipToCountry,
            importProvince: orderData?.shipToPostalCode,
            exportCountryCode: orderData?.shipFromCountry,
            transModes: orderData?.transMode,
            shipmentType: orderData?.shipmentType,
            shipmentItems: packingListData
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            })
            console.log('result', result);
            if (result?.data?.error?.errors?.length > 0) {
                setErrorMessage(`Fail to get cross border fees. ${result?.data?.error?.errors[0]?.message}`);
                setError(true);
            } else {
                setErrorMessage('');
                setCrossBorderFee(result?.data);
                setError(false);
            }
            setLoading(false);
        } catch (e) {
            console.log('error', e.response);
            setLoading(false);
            setErrorMessage('Fail to get cross border fees.');
            setError(true);
        }
    }

    const handleOrderDetails = () => {
        history.push({
            pathname: '/packageOrder/returnLabelDetails',
            state: {
                orderData: orderData,
                selectedRate: selectedRate,
                crossBorderFee: crossBorderFee,
                tranId: tranId
            }
        });
    }

    useEffect(() => {
        if (orderData && selectedRate) {
            getCrossBorderFee();
        }
    }, [orderData, selectedRate]);

    if (loading) {
        return (
            <Box sx={styles.ShippingPackageCrossBorderRoot}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography style={styles.ShippingPackageCrossBorderHeading}>
                            Packing List For Cross Border
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <CircularProgress/>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    if (error) {
        return (
            <Box sx={styles.ShippingPackageCrossBorderRoot}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography style={styles.ShippingPackageCrossBorderHeading}>
                            Packing List For Cross Border
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={styles.ShippingPackageCrossBorderSubHeading}>
                            Sorry We currently encounter some issues, please try again later.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box sx={styles.ShippingPackageCrossBorderRoot}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography style={styles.ShippingPackageCrossBorderHeading}>
                                Packing List For Cross Border
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={styles.ShippingPackageCrossBorderSubHeading}>
                                Cost Details
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <Typography style={{textAlign: 'right'}}>
                                Total Brokerage Fees
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <Typography style={{textAlign: 'right'}}>
                                $ {crossBorderFee?.totalBrokerageFees?.toFixed(2)}
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <Typography style={{textAlign: 'right'}}>
                                Total Duty And Taxes
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <Typography style={{textAlign: 'right'}}>
                                $ {crossBorderFee?.totalDutyandTax?.toFixed(2)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <Typography style={{textAlign: 'right', fontSize: '16px', fontWeight: '600'}}>
                                Total
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <Typography style={{textAlign: 'right', fontSize: '16px', fontWeight: '600'}}>
                                $ {crossBorderFee?.grandTotal?.toFixed(2)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{display: 'flex', width: '100%', justifyContent: 'flex-end', gap: '20px'}}>
                    <LoadingButton
                        size='large'
                        color="success"
                        variant='contained'
                        onClick={handleOrderDetails}
                    >
                        Order Details
                    </LoadingButton>
                </Box>
            </Grid>
        </Grid>
    )
}