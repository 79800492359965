import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useEffect, useState} from "react";
import {getAccessToken} from "../../Utils/doToken";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import LoadingButton from "@mui/lab/LoadingButton";
import {Divider, IconButton, Modal} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CreditCardIcon from "../../images/creditCardIcon.png";
import {CreditCardList} from "../CreditCard/CreditCardList";
import {CreditCardSetup} from "../CreditCard/CreditCardSetup";
import {ReactComponent as CreditCardSampleIcon} from "../../images/icons/CreditCardSample.svg";
import {ReactComponent as StripeIcon} from "../../images/icons/Stripe.svg";
import Grid from "@mui/material/Grid";
import CreditCard from "../../images/icons/CreditCardSample.svg";

const styles = {
    BillingPaymentRoot: {
        backgroundColor: '#FFFFFF',
        // padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '35vh',
        height: '100%'
    },
    BillingPaymentContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px'
    },
    BillingPaymentTitle: {
        fontWeight: '600',
        textAlign: 'left',
    },
    BillingPaymentSubTitle: {
        fontWeight: '600',
        textAlign: 'left',
        fontSize: '14px'
    }
}

export const BillingCreditCard = () => {

    const storedToken = getAccessToken("access_token");

    const [cardList, setCardList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [addNewCard, setAddNewCard] = useState(false);
    const [selectCardId, setSelectCardId] = useState(null);

    const handleClickOpen = () => {
        setAddNewCard(true);
    };

    const handleClose = () => {
        setAddNewCard(false);
    };

    const cbSelectCardId = (cardId) => {
        setSelectCardId(cardId);
    }

    const getValidCardList = async () => {
        const requestURL = `${NODE_ROUTE_URI}/stripe/validCreditCardList`;
        setIsLoading(true);
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {Authorization: `Bearer ${storedToken}`},
            })

            console.log('result', result);
            setCardList(result?.data);
            setIsLoading(false);
            setError(false);
        } catch (e) {
            console.log('error', e.response);
            setError(true);
        }
    }

    const cbGetValidCardList = () => {
        getValidCardList();
    }

    useEffect(() => {
        getValidCardList()
    }, []);

    return (
        <Box sx={styles.BillingPaymentRoot}>
            <Box sx={{
                padding: '20px 20px 0 20px',
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <Typography style={styles.BillingPaymentTitle}>
                    Credit Card
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center'
                    }}>
                    {
                        cardList?.length > 0 ? <LoadingButton
                                variant='contained'
                                sx={{
                                    backgroundColor: '#1D8B45',
                                    "&:hover": {
                                        backgroundColor: '#1D8B45',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                onClick={handleClickOpen}
                                disabled={!selectCardId}
                            >
                                <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                                    Update
                                </Typography>
                            </LoadingButton>
                            :
                            <LoadingButton
                                variant='contained'
                                sx={{
                                    backgroundColor: '#1D8B45',
                                    "&:hover": {
                                        backgroundColor: '#1D8B45',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                onClick={handleClickOpen}
                            >
                                <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                                    Add New
                                </Typography>
                            </LoadingButton>
                    }
                </Box>
            </Box>
            <Divider/>
            {
                cardList?.length > 0 ? <CreditCardList cardList={cardList} cbSelectCardId={cbSelectCardId}/> :
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    backgroundImage: `url(${CreditCard})`,
                                    width: '200px',
                                    height: '125px',
                                    backgroundSize: 'cover',
                                    margin: 'auto',
                                    position: 'relative'
                                }}
                            >
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{
                                width: '70%',
                                justifyContent: 'center',
                                display: 'flex',
                                margin: 'auto'
                            }}>
                                <Typography style={{textAlign: 'center'}}>
                                    Your credit card information is securely stored by our payment provider.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <StripeIcon width='100' height='35'/>
                            </Box>
                        </Grid>
                    </Grid>
            }
            <Modal onClose={handleClose} open={addNewCard}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: '1px solid #000000',
                    width: 400,
                    boxShadow: 24,
                    backgroundColor: '#ffffff',
                    borderRadius: '10px',
                    zIndex: '7000'
                }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Box
                            sx={{backgroundColor: '#1D8B45', borderRadius: '9px 9px 0 0', padding: '20px'}}>
                            <Typography style={{fontSize: '20px', color: '#ffffff'}}>
                                Add Credit Card
                            </Typography>
                        </Box>
                        <Box>
                            <CreditCardSetup handleClose={handleClose}
                                             cbGetValidCardList={cbGetValidCardList}
                                             selectCardId={selectCardId}/>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </Box>
    )
}