import {
    MOVING_SERVICE_OPEN_ITEM_INFO,
    MOVING_SERVICE_EDIT_ITEM_INFO,
    MOVING_SERVICE_ADD_ITEM_LIST,
    MOVING_SERVICE_UPDATE_ITEM_LIST,
    MOVING_SERVICE_REMOVE_ITEM_LIST,
    MOVING_SERVICE_UPDATE_MOVE_TO_ADDRESS,
    MOVING_SERVICE_UPDATE_MOVE_FROM_ADDRESS,
    MOVING_SERVICE_UPDATE_MOVE_FROM_ADDRESS_MISSING,
    MOVING_SERVICE_UPDATE_MOVE_TO_ADDRESS_MISSING,
    MOVING_SERVICE_SUMMARY_UPDATE_CONTACT_INFO,
    MOVING_SERVICE_SUMMARY_UPDATE_CONTACT_INFO_MISSING,
    MOVING_SERVICE_UPDATE_BASIC_RATE,
    MOVING_SERVICE_UPDATE_MOVING_DATE,
    MOVING_SERVICE_UPDATE_MOVING_TIME,
    MOVING_SERVICE_UPDATE_MOVING_IMAGE,
    MOVING_SERVICE_UPDATE_MOVING_IMAGE_ERROR,
    MOVING_SERVICE_UPDATE_ADDITIONAL_SERVICE,
    MOVING_SERVICE_UPDATE_DELIVER_TIME,
    MOVING_SERVICE_UPDATE_DELIVER_DATE,
    MOVING_SERVICE_EDIT_CAR_INFO,
    MOVING_SERVICE_ADD_CAR_LIST,
    MOVING_SERVICE_OPEN_CAR_INFO,
    MOVING_SERVICE_UPDATE_CAR_LIST,
    MOVING_SERVICE_REMOVE_CAR_LIST,
    MOVING_SERVICE_UPDATE_MOVE_FROM_CAR_ADDRESS,
    MOVING_SERVICE_UPDATE_MOVE_TO_CAR_ADDRESS,
    MOVING_SERVICE_UPDATE_MOVE_FROM_CAR_ADDRESS_MISSING,
    MOVING_SERVICE_UPDATE_MOVE_TO_CAR_ADDRESS_MISSING,
    UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_METRIC_UNIT,
    MOVING_SERVICE_UPDATE_METRIC,
    MOVING_SERVICE_DUPLICATE_ITEM_LIST,
    MOVING_SERVICE_CLEAR_ITEM_LIST,
    MOVING_SERVICE_INIT_ITEM_LIST,
    MOVING_SERVICE_UPDATE_RATE,
    MOVING_SERVICE_ADD_RATE,
    MOVING_SERVICE_CLEAR_RATE,
    MOVING_SERVICE_UPDATE_ACCOUNT_BASE,
    MOVING_SERVICE_UPDATE_EXPRESS_PROPERTY,
    MOVING_SERVICE_ADD_FURNITURE_ITEM_LIST,
    MOVING_SERVICE_UPDATE_FURNITURE_ITEM_LIST,
    MOVING_SERVICE_REMOVE_FURNITURE_ITEM_LIST, MOVING_SERVICE_UPDATE_REMARK_NOTES,
} from "./types";

export const toggleMovingServiceAddItem = status => ({
    type: MOVING_SERVICE_OPEN_ITEM_INFO,
    payload: status
})

export const toggleMovingServiceEditItem = (status, itemId) => ({
    type: MOVING_SERVICE_EDIT_ITEM_INFO,
    payload: {status, itemId}
})

export const initMovingServiceItemList = item => ({
    type: MOVING_SERVICE_INIT_ITEM_LIST,
    payload: item
})

export const addMovingServiceItemList = item => ({
    type: MOVING_SERVICE_ADD_ITEM_LIST,
    payload: item
})

export const updateMovingServiceItemList = item => ({
    type: MOVING_SERVICE_UPDATE_ITEM_LIST,
    payload: item
})

export const removeMovingServiceItemList = item => ({
    type: MOVING_SERVICE_REMOVE_ITEM_LIST,
    payload: item
})

export const duplicateMovingServiceItemList = item => ({
    type: MOVING_SERVICE_DUPLICATE_ITEM_LIST,
    payload: item
})

export const clearMovingServiceItemList = () => ({
    type: MOVING_SERVICE_CLEAR_ITEM_LIST
})

export const addMovingFurnitureItemList = item => ({
    type: MOVING_SERVICE_ADD_FURNITURE_ITEM_LIST,
    payload: item
})

export const updateMovingFurnitureItemList = item => ({
    type: MOVING_SERVICE_UPDATE_FURNITURE_ITEM_LIST,
    payload: item
})

export const removeMovingFurnitureItemList = item => ({
    type: MOVING_SERVICE_REMOVE_FURNITURE_ITEM_LIST,
    payload: item
})

export const updateMovingServiceMoveFromAddress = address => ({
    type: MOVING_SERVICE_UPDATE_MOVE_FROM_ADDRESS,
    payload: address
})

export const updateMovingServiceMoveToAddress = address => ({
    type: MOVING_SERVICE_UPDATE_MOVE_TO_ADDRESS,
    payload: address
})

export const updateMovingServiceMoveFromAddressMissing = missing => ({
    type: MOVING_SERVICE_UPDATE_MOVE_FROM_ADDRESS_MISSING,
    payload: missing
})

export const updateMovingServiceMoveToAddressMissing = missing => ({
    type: MOVING_SERVICE_UPDATE_MOVE_TO_ADDRESS_MISSING,
    payload: missing
})

export const updateMovingServiceSummaryContactInfo = contactInfo => ({
    type: MOVING_SERVICE_SUMMARY_UPDATE_CONTACT_INFO,
    payload: contactInfo
})

export const updateMovingServiceSummaryContactInfoMissing = missing => ({
    type: MOVING_SERVICE_SUMMARY_UPDATE_CONTACT_INFO_MISSING,
    payload: missing
})

export const updateMovingServiceBasicRate = basicRateInfo => ({
    type: MOVING_SERVICE_UPDATE_BASIC_RATE,
    payload: basicRateInfo
})

export const updateMovingServiceRate = rate => ({
    type: MOVING_SERVICE_UPDATE_RATE,
    payload: rate
})

export const addMovingServiceRate = rate => ({
    type: MOVING_SERVICE_ADD_RATE,
    payload: rate
})

export const clearMovingServiceRate = () => ({
    type: MOVING_SERVICE_CLEAR_RATE
})

export const updateMovingServiceDate = date => ({
    type: MOVING_SERVICE_UPDATE_MOVING_DATE,
    payload: date
})

export const updateMovingServiceTime = time => ({
    type: MOVING_SERVICE_UPDATE_MOVING_TIME,
    payload: time
})

export const updateMovingServiceDeliverDate = date => ({
    type: MOVING_SERVICE_UPDATE_DELIVER_DATE,
    payload: date
})

export const updateMovingServiceDeliverTime = time => ({
    type: MOVING_SERVICE_UPDATE_DELIVER_TIME,
    payload: time
})

export const updateMovingServiceRemarkNotes = notes => ({
    type: MOVING_SERVICE_UPDATE_REMARK_NOTES,
    payload: notes
})

export const updateMovingServiceImage = image => ({
    type: MOVING_SERVICE_UPDATE_MOVING_IMAGE,
    payload: image
})

export const updateMovingServiceImageError = imageError => ({
    type: MOVING_SERVICE_UPDATE_MOVING_IMAGE_ERROR,
    payload: imageError
})

export const updateMovingServiceAdditionalService = service => ({
    type: MOVING_SERVICE_UPDATE_ADDITIONAL_SERVICE,
    payload: service
})

export const toggleMovingServiceAddCar = status => ({
    type: MOVING_SERVICE_OPEN_CAR_INFO,
    payload: status
})

export const toggleMovingServiceEditCar = (status, carId) => ({
    type: MOVING_SERVICE_EDIT_CAR_INFO,
    payload: {status, carId}
})

export const addMovingServiceCarList = car => ({
    type: MOVING_SERVICE_ADD_CAR_LIST,
    payload: car
})

export const updateMovingServiceCarList = car => ({
    type: MOVING_SERVICE_UPDATE_CAR_LIST,
    payload: car
})

export const removeMovingServiceCarList = carId => ({
    type: MOVING_SERVICE_REMOVE_CAR_LIST,
    payload: carId
})

export const updateMovingServiceMoveFromCarAddress = address => ({
    type: MOVING_SERVICE_UPDATE_MOVE_FROM_CAR_ADDRESS,
    payload: address
})

export const updateMovingServiceMoveToCarAddress = address => ({
    type: MOVING_SERVICE_UPDATE_MOVE_TO_CAR_ADDRESS,
    payload: address
})

export const updateMovingServiceMoveFromCarAddressMissing = missing => ({
    type: MOVING_SERVICE_UPDATE_MOVE_FROM_CAR_ADDRESS_MISSING,
    payload: missing
})

export const updateMovingServiceMoveToCarAddressMissing = missing => ({
    type: MOVING_SERVICE_UPDATE_MOVE_TO_CAR_ADDRESS_MISSING,
    payload: missing
})

export const updateMovingServiceMetricUnit = metric => ({
    type: MOVING_SERVICE_UPDATE_METRIC,
    payload: metric
})

export const updateMovingServiceAccountBase = accountBase => ({
    type: MOVING_SERVICE_UPDATE_ACCOUNT_BASE,
    payload: accountBase
})

export const updateMovingServiceExpressProperty = expressProperty => ({
    type: MOVING_SERVICE_UPDATE_EXPRESS_PROPERTY,
    payload: expressProperty
})