import {UPDATE_SEARCH_FILTER, FETCH_SEARCH_FILTER_STATIC, REFRESH_ORDERS_IN_SEARCH_PAGE} from './types'

export const updateSearchFilterSetting = (newState) => {
    return {
        type: UPDATE_SEARCH_FILTER,
        payload: newState
    }
}

export const fetchSearchFilterStatic = (shipmentStatic) => {
    return {
        type: FETCH_SEARCH_FILTER_STATIC,
        payload: shipmentStatic
    }
}

export const refreshOrderInSearchPage = (isRefresh) => ({
    type: REFRESH_ORDERS_IN_SEARCH_PAGE,
    payload: isRefresh
})