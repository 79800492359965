import {Button, Container} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {getAccessToken} from "../../Utils/doToken";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {useHistory, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {MovingOrderDetailsContent} from "./MovingOrderDetailsContent";
import {MovingOrderDetailsPayment} from "./MovingOrderDetailsPayment";
import {useInterval} from "usehooks-ts";
import * as React from "react";
import {ShippingOrderDetailsPayment} from "../ShippingOrderDetails/NewDesigin/ShippingOrderDetailsPayment";
import {useSelector} from "react-redux";

const styles = {
    MovingOrderDetailsRoot: {
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
    },
}

export const MovingOrderDetails = () => {

    const ownerRole = useSelector(state => state?.user?.ownerRole);
    let basicRateInfo = useSelector(state => state?.movingService?.movingServiceBasicRateInfo);

    const history = useHistory();

    const accessToken = getAccessToken("access_token");

    const {orderId} = useParams();

    const [orderDetails, setOrderDetails] = useState();
    const [backendOrderStatus, setBackendOrderStatus] = useState(0);
    const [delay] = useState(2000);

    const getMovingOrderDetails = async () => {

        let requestURL = ownerRole === 1 ? `${NODE_ROUTE_URI}/movingService/getMovingOrderDetails` :
            `${PARTNER_URI}/movingService/getMovingOrderDetails`;

        try {
            const result = await axios({
                method: 'get',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                url: requestURL,
                params: {
                    backendOrderId: orderId
                }
            })

            const backendOrders = result.data;

            setOrderDetails(backendOrders);
            setBackendOrderStatus(backendOrders?.status_id);

        } catch (e) {
            console.log(e.response);
        }
    }

    const getOrderStatus = async () => {

        let requestURL = ownerRole === 1 ? `${NODE_ROUTE_URI}/movingExpressService/getMovingOrderStatus` :
            `${PARTNER_URI}/movingExpressService/getMovingOrderStatus`;

        try {
            const result = await axios({
                method: 'get',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                url: requestURL,
                params: {
                    backendOrderId: orderId
                }
            })
            const {data: {status_id}} = result;
            setBackendOrderStatus(status_id);
        } catch (e) {
            setBackendOrderStatus(0);
            console.log(e);
        }
    }

    const handleSeeOrderDetails = () => {
        history.push(`${ownerRole === 1 ? '/' : '/business/'}transportation/orderDashboard/${orderId}`);
    }

    useInterval(
        () => {
            if (orderId) {
                getOrderStatus();
            }
        },
        // Delay in milliseconds or null to stop it
        backendOrderStatus === 0 ? delay : null,
    )

    useEffect(() => {
        getMovingOrderDetails();
    }, []);

    return (
        <Container maxWidth='xl'>
            {/*{*/}
            {/*    orderDetails &&*/}
            <MovingOrderDetailsContent orderDetails={orderDetails}/>
            {/*}*/}
            <Grid container spacing={2}>
                {
                    backendOrderStatus === 1 ?
                        <>
                            <Grid item xs={12}>
                                <Typography sx={{
                                    fontSize: '20px',
                                    fontWeight: '600',
                                    textAlign: 'center'
                                }}>
                                    Thank you! Your order has been placed.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '10px'
                                }}>
                                    <Button onClick={handleSeeOrderDetails}>
                                        <Typography sx={{textTransform: 'none', color: '#1D8B45'}}>
                                            See Details of Order
                                        </Typography>
                                    </Button>
                                </Box>
                            </Grid>
                        </>
                        :
                        backendOrderStatus === 100 ?
                            <>
                                <Grid item xs={12}>
                                    <Typography sx={{
                                        fontSize: '20px',
                                        fontWeight: '600',
                                        textAlign: 'center'
                                    }}>
                                        {basicRateInfo?.totalCharge ? "Thank you! You have successfully paid the deposit." : "Thank you! You have successfully placed the order."}
                                        {/*Thank you! You have successfully paid the deposit.*/}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '10px'
                                    }}>
                                        <Button onClick={handleSeeOrderDetails}>
                                            <Typography sx={{textTransform: 'none', color: '#1D8B45'}}>
                                                See Details of Order
                                            </Typography>
                                        </Button>
                                    </Box>
                                </Grid>
                            </>
                            :
                            backendOrderStatus === 0 ?
                                <Grid item xs={12}>
                                    <MovingOrderDetailsPayment orderDetails={orderDetails} orderId={orderId}/>
                                </Grid>
                                :
                                <>
                                    <Grid item xs={12}>
                                        <Typography sx={{
                                            fontSize: '20px',
                                            fontWeight: '600',
                                            textAlign: 'center'
                                        }}>
                                            The Order Has Been Cancelled.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            gap: '10px'
                                        }}>
                                            <Button
                                                variant='contained'
                                                sx={{
                                                    backgroundColor: '#1D8B45',
                                                    "&:hover": {
                                                        backgroundColor: '#1D8B45',
                                                        filter: 'brightness(0.9)'
                                                    }
                                                }}
                                                onClick={() => history.push(`/home`)}
                                            >
                                                <Typography sx={{textTransform: 'none'}}>
                                                    Back To Home Page
                                                </Typography>
                                            </Button>
                                        </Box>
                                    </Grid>
                                </>
                }
            </Grid>
        </Container>
    )
}