import { 
    NEW_SHIPPING_ITEM, 
    GET_SHIPPING_ITEM,
    NEW_PICKUP_DETAILS,
    GET_PICKUP_DETAILS,
    DELETE_SHIPPING_ITEM
} from '../actions/types';

const initialState = {
    shippingItem: [],
    pickUpDetail: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case NEW_SHIPPING_ITEM:
            return {
                ...state,
                shippingItem: [action.payload, ...state.shippingItem]
            }
        case GET_SHIPPING_ITEM:
            return {
                ...state
            }
        case NEW_PICKUP_DETAILS:
            return{
                ...state,
                pickUpDetail: action.payload
            }
        case GET_PICKUP_DETAILS:
            return {
                ...state
            }
        case DELETE_SHIPPING_ITEM:
            return {
                ...state,
                shippingItem: state.shippingItem.filter((_pallet, index) =>  index !== action.payload)
            }
        default:
            return state;
    }

}
