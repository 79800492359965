import intl from "react-intl-universal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {useState} from "react";
import {useSelector} from "react-redux";
import {isValidateEmail} from "../../Utils/Helper";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../Utils/doToken";
import MuiAlert from "@mui/material/Alert";
import {Snackbar} from "@mui/material";
import * as React from "react";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingPackageCreateLabelRateRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelRateHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelRateSubHeading: {
        fontSize: '16px',
        fontWeight: '600',
        paddingLeft: '10px'
    },
    ShippingPackageCreateLabelRateOtherBox: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        height: '100%',
        flexDirection: 'column'
    },
    ShippingPackageCreateLabelRateImageBox: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        paddingLeft: '20px',
        gap: '10px',
        flexDirection: 'column'
    },
    ShippingPackageCreateLabelRateOtherBoxMobile: {
        display: 'flex',
        alignItems: 'flex-start',
        paddingLeft: '20px',
        justifyContent: 'center',
        height: '100%',
        flexDirection: 'column'
    },
}

export const MovingServiceRequestQuote = ({errorMessage}) => {

    const accessToken = getAccessToken("access_token");

    let itemList = useSelector(state => state?.movingService?.itemList);
    let movingFurnitureList = useSelector(state => state?.movingService?.movingFurnitureList);
    let movingFromAddress = useSelector(state => state?.movingService?.movingFromAddress);
    let movingToAddress = useSelector(state => state?.movingService?.movingToAddress);

    const userInfo = useSelector((state) => state.user);
    const [contactEmail, setContactEmail] = useState(userInfo?.email);
    const [contactEmailValidate, setContactEmailValidate] = useState(true);
    const [loading, setLoading] = useState(false);

    const [toastOpen, setToastOpen] = useState(false);
    const [failMessage, setFailMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleContactEmail = (event) => {
        const emailAddress = event.target.value;
        const validateEmail = isValidateEmail(emailAddress);
        setContactEmail(event.target.value);
        setContactEmailValidate(validateEmail);
    }

    const movingRequestQuote = async () => {
        setLoading(true);
        let requestURL = `${NODE_ROUTE_URI}/movingService/requestQuote`;

        const updatedItemList = itemList?.map(item => ({
            description: item.category,
            cateId: item.cate_id,
            length: item.length,
            width: item.width,
            height: item.height,
            weight: item.weight,
            lengthUnit: item.lengthUnit,
            weightUnit: item.weightUnit,
            quantity: item.quantity
        }));
        const updatedFurnitureList = movingFurnitureList?.map(item => ({
            description: item.category,
            cateId: item.cate_id,
            length: item.length || 0,
            width: item.width || 0,
            height: item.height || 0,
            weight: item.weight || 0,
            lengthUnit: item?.lengthUnit,
            weightUnit: item?.weightUnit,
            quantity: item.quantity
        }));
        const items = [...updatedItemList, ...updatedFurnitureList];

        const data = {
            items: items,
            pickupAddress: movingFromAddress?.streetAddress ? (movingFromAddress?.apt ? `${movingFromAddress?.apt} - ${movingFromAddress?.streetAddress}` : movingFromAddress?.streetAddress) : movingFromAddress?.warehouse?.address,
            pickupCity: movingFromAddress?.city,
            pickupProvince: movingFromAddress?.province?.code,
            pickupCountry: movingFromAddress?.country,
            pickupPostalCode: movingFromAddress?.postalCode,
            deliverAddress: movingToAddress?.streetAddress ? (movingToAddress?.apt ? `${movingToAddress?.apt} - ${movingToAddress?.streetAddress}` : movingToAddress?.streetAddress) : movingToAddress?.warehouse?.address,
            deliverCity: movingToAddress?.city,
            deliverProvince: movingToAddress?.province?.code,
            deliverCountry: movingToAddress?.country,
            deliverPostalCode: movingToAddress?.postalCode,
            email: contactEmail,
            phone: userInfo?.phone?.replace(/\D/g, "")
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: data
            });
            console.log('[MovingServiceRequestQuote] movingRequestQuote - api', result);
            setFailMessage('');
            handleSuccessMessage();
            setSuccessMessage(result?.data?.message);
        } catch (e) {
            console.log('[MovingServiceRequestQuote] movingRequestQuote - api', e);
            handleErrorMessage();
            setFailMessage('Fail to send quote request.')
        } finally {
            setLoading(false);
        }
    }

    const handleGetRateFromEmail = () => {
        movingRequestQuote();
    }

    return (
        <Box sx={styles.ShippingPackageCreateLabelRateRoot}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (failMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {failMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                        <Typography style={{textAlign: 'left'}}>
                            {errorMessage}
                        </Typography>
                        <Typography style={{textAlign: 'left'}}>
                            To receive a quote via email, kindly click on the 'Request Rate via Email'
                            button, and the
                            operator will
                            promptly assist you with the information you need.
                        </Typography>
                        <Typography style={{
                            fontSize: '20px',
                            fontWeight: '600',
                            textAlign: 'left'
                        }}>
                            Contact Email
                        </Typography>
                        <TextField
                            // defaultValue={userInfo?.email}
                            value={contactEmail}
                            onInput={handleContactEmail}
                            fullWidth
                            size='small'
                        />
                        <Typography style={{fontSize: '12px', color: '#FF0303'}}>
                            {!contactEmailValidate && "Please enter a valid email address"}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'flex-end',
                        gap: '20px'
                    }}>
                        <LoadingButton
                            variant='contained'
                            onClick={handleGetRateFromEmail}
                            disabled={!contactEmail || contactEmail.replace(/\s+/g, '') === ""}
                            sx={{
                                backgroundColor: '#1D8B45',
                                "&:hover": {
                                    backgroundColor: '#1D8B45',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                            loading={loading}
                        >
                            <Typography style={{textTransform: 'none', fontSize: '16px'}}>
                                Request Rate Via Email
                            </Typography>
                        </LoadingButton>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}
