import {Checkbox, Container, Divider, Snackbar, Step, StepLabel, Stepper} from "@mui/material";
import Box from "@mui/material/Box";
import {ShippingLTLCreateLabelShipFrom} from "./ShippingLTLCreateLabelShipFrom";
import {ShippingLTLCreateLabelShipTo} from "./ShippingLTLCreateLabelShipTo";
import {ShippingLTLCreateLabelPallet} from "./ShippingLTLCreateLabelPallet";
import {useDispatch, useSelector} from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {ShippingLTLCreateLabelAdditionalService} from "./ShippingLTLCreateLabelAdditionalService";
import LoadingButton from "@mui/lab/LoadingButton";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {getMissingKeysForCreateLabelLocation, getMissingKeysForCreateLTLLabelLocation} from "../../../Utils/Helper";
import {
    clearCallAllLTLCarrier,
    clearShippingLTLCreateLabelRate, clearShippingLTLCrossBorderItemList, initShippingLTLCreateLabelPalletList,
    updateCallAllLTLCarrier,
    updateShippingLTLCreateLabelComment,
    updateShippingLTLCreateLabelDateAndTime, updateShippingLTLCreateLabelMoveFrom, updateShippingLTLCreateLabelMoveTo,
    updateShippingLTLCreateLabelRate,
    updateShippingLTLCreateLabelReferenceNumber,
    updateShippingLTLCreateLabelValidation, updateShippingLTLCrossBorderRate,
} from "../../../actions/shippingLTLAction";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {
    SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE,
    SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_PICKUP_CODE
} from "../../shared";
import {deleteToken, getAccessToken} from "../../../Utils/doToken";
import {useHistory} from "react-router-dom";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import packageIcon from "../../../images/homepageIcon/Package.svg";
import PublicIcon from "@mui/icons-material/Public";
import ltlIcon from "../../../images/homepageIcon/Pallet.svg";
import brokerageIcon from "../../../images/homepageIcon/CustomBrokerage.svg";
import {useMediaQuery} from "@material-ui/core";
import {ShippingLTLCreateLabelShippingOptions} from "./ShippingLTLCreateLabelShippingOptions";
import {TIME_OUT} from "../../../Utils/consInfo";
import {InternationalSteps, QontoConnector, QontoStepIcon, steps} from "../../../Utils/CustomComponent";
import {ReactComponent as SchedulePickupIcon} from "../../../images/NewDesignIcon/SchedulePickup.svg";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingLTLCreateLabelRoot: {
        backgroundColor: '#FFFFFF',
        // padding: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0 100px'
    },
    ShippingPackageCreateLabelRow: {
        display: 'flex',
        alignItems: 'center'
        // justifyContent: 'flex-end',
    },
    ShippingPackageCreateLabelRowRight: {
        display: 'flex',
        justifyContent: 'flex-end'
        // justifyContent: 'flex-end',
    },
    ShippingPackageCreateLabelSection: {
        backgroundColor: '#FFFFFF',
        // padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        // border: '1px solid #A9A9A9',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelHeading: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelSectionBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    ShippingPackageCreateLabelRateListContent: {
        backgroundColor: '#FFFFFF',
        padding: '0 40px',
        marginBottom: '40px',
        borderRadius: '10px',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
}

const requiredProperties = ["description", "length", "width", "height", "quantity", "weight", "lengthUnit", "weightUnit"];
const validateRequiredProperties = ["length", "width", "height", "weight", "quantity"];

export const ShippingLTLCreateLabel = (props) => {

    const isMobile = useMediaQuery('(max-width: 600px)');

    const shipFromHistory = props?.location?.state?.shipFrom;
    const shipToHistory = props?.location?.state?.shipTo;
    const palletListHistory = props?.location?.state?.palletList;
    const metricHistory = props?.location?.state?.metric
    const batteryDetailsHistory = props?.location?.state?.batteryDetails;
    const specialServiceHistory = props?.location?.state?.specialService;
    const ftlCountHistory = props?.location?.state?.ftlCount;

    // console.log('ship from history', shipFromHistory);
    // console.log('ship to history', shipToHistory);
    // console.log('pallet history', palletListHistory);
    // console.log('metric history', metricHistory);
    console.log('[ShippingLTLCreateLabel] specialServiceHistory', specialServiceHistory);

    const dispatch = useDispatch();
    const token = getAccessToken("access_token");
    const history = useHistory();

    const {id: partnerId, email} = useSelector((state) => state.user);
    let palletListRedux = useSelector(state => state?.shippingLTL?.createLabelPalletList);
    let shipFrom = useSelector(state => state?.shippingLTL?.createLabelShipFrom);
    let shipTo = useSelector(state => state?.shippingLTL?.createLabelShipTo);
    let metric = useSelector(state => state?.shippingLTL?.createLabelUnit);
    let specialService = useSelector(state => state?.shippingLTL?.createLabelSpecialService);
    let referenceNumberRedux = useSelector(state => state?.shippingLTL?.createLabelReferenceNumber);
    let commentRedux = useSelector(state => state?.shippingLTL?.createLabelComment);
    let dateAndTimeRedux = useSelector(state => state?.shippingLTL?.createLabelDateAndTime);
    let batteryDetails = useSelector(state => state?.shippingLTL?.createLabelBatteryDetails);
    let ftlCountRedux = useSelector(state => state?.shippingLTL?.createLabelFTLCount);
    // const [availableService, setAvailableService] = useState([]);

    const currentDate = new Date().toISOString().split('T')[0];

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const [comments, setComments] = useState('');
    const [referenceNumber, setReferenceNumber] = useState('');
    const [shipDataAndTime, setShipDateAndTime] = useState({
        shipDate: null,
        pickupDate: null,
        availablePickupTime: '',
        earliestPickupTime: '',
        latestPickupTime: '',
    });

    const [addressVerifyDialog, setAddressVerifyDialog] = useState(false);
    const [addressErrorMessageShipFrom, setAddressErrorMessageShiFrom] = useState('');
    const [addressErrorMessageShipTo, setAddressErrorMessageShipTo] = useState('');
    const [verifyAddressShipFrom, setVerifyAddressShipFrom] = useState(null);
    const [verifyAddressShipTo, setVerifyAddressShipTo] = useState(null);
    const [loading, setLoading] = useState(false);
    const [shipFromOriginal, setShipFromOriginal] = useState();
    const [shipToOriginal, setShipToOriginal] = useState();
    const [shipFromResidential, setShipFromResidential] = useState(false);
    const [shipToResidential, setShipToResidential] = useState(false);

    const cbHandleShipFromResidential = (value) => {
        setShipFromResidential(value);
    }

    const cbHandleShipToResidential = (value) => {
        setShipToResidential(value);
    }

    const handleAddressVerifyDialogOpen = () => {
        setAddressVerifyDialog(true);
    }

    const handleAddressVerifyDialogClose = () => {
        setAddressVerifyDialog(false);
    }

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const getNextWeekday = () => {
        const today = new Date();
        const tomorrow = new Date(today.getTime() + 48 * 60 * 60 * 1000); // Adding two day

        // Skip the weekend (Saturday and Sunday)
        if (tomorrow.getDay() === 0) {
            tomorrow.setDate(tomorrow.getDate() + 2); // Adjust to Tuesday
        } else if (tomorrow.getDay() === 6) {
            tomorrow.setDate(tomorrow.getDate() + 3); // Adjust to Tuesday
        }

        return tomorrow.toISOString().split('T')[0];
    };

    const getMinDate = () => {
        const today = new Date();
        // const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000); // Adding one day
        const tomorrow = new Date(today.getTime()); // today

        // Skip the weekend (Saturday and Sunday)
        if (tomorrow.getDay() === 0) {
            tomorrow.setDate(tomorrow.getDate() + 2); // Adjust to Tuesday
        } else if (tomorrow.getDay() === 6) {
            tomorrow.setDate(tomorrow.getDate() + 3); // Adjust to Tuesday
        }

        return tomorrow.toISOString().split('T')[0];
    };

    const handleReferenceNumber = (event) => {
        setReferenceNumber(event?.target?.value);
    }

    const handleInputComments = (event) => {
        // console.log('phone input', event?.target?.value);
        setComments(event?.target?.value);
    }

    const handlePickupDate = (event) => {
        const inputDate = event.target.value;
        if (inputDate <= currentDate) {
            setShipDateAndTime(prevState => ({
                ...prevState,
                pickupDate: currentDate,
                shipDate: currentDate
            }));
        } else {
            setShipDateAndTime(prevState => ({
                ...prevState,
                pickupDate: inputDate,
                shipDate: inputDate
            }))
        }
    }

    const handlePickupEarliestTime = (event) => {
        const inputTime = event.target.value;
        console.log('time', inputTime);
        setShipDateAndTime(prevState => ({
            ...prevState,
            earliestPickupTime: inputTime
        }))
    }

    const handlePickupLatestTime = (event) => {
        const inputTime = event.target.value;
        console.log('time', inputTime);
        setShipDateAndTime(prevState => ({
            ...prevState,
            latestPickupTime: inputTime
        }))
    }

    const validateCityProvinceCountry = async (city, province, country) => {
        let requestURI = `${PARTNER_URI}/googleMaps/validateCityProvinceCountry`;
        let data = {city, province, country};
        try {
            const result = await axios({
                method: 'post',
                url: requestURI,
                data: data
            })
            console.log('validateCityProvinceCountry result', result);
            return result?.data?.validation;
        } catch (e) {
            console.log(e.response)
            const errorCode = e?.response?.data?.error?.status;
            console.log('error code', errorCode);
        }
    }

    const getLTLRatingByOneService = async (serviceName) => {
        setLoading(true);
        let requestURL = `${PARTNER_URI}/shippingLTL/LTLShippingRatingByServiceName`;

        // todo edit battery details
        let packageData = palletListRedux?.map(element => {
            const updatedElement = {...element};
            updatedElement.packageSpecialServices = {
                ...updatedElement.packageSpecialServices,
                batteryDetails: batteryDetails,
            };
            return updatedElement
        });

        let exchangedPackageData = packageData.map(item => {
            let {length, width} = item;
            if (parseInt(length) < parseInt(width)) {
                return {
                    ...item,
                    length: width,
                    width: length
                }
            }
            return item;
        });

        dispatch(initShippingLTLCreateLabelPalletList(exchangedPackageData));

        let data = {
            shipperAddress: shipFrom?.address,
            shipperAddressType: specialService.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_PICKUP_CODE) ? "RESIDENTIAL" : "WAREHOUSE",
            shipperCity: shipFrom?.parentCity,
            shipperSubCity: shipFrom?.city,
            shipperProvince: shipFrom?.province?.code,
            shipperPostalCode: shipFrom?.postalCode,
            shipperCountry: shipFrom?.country,
            consigneeAddress: shipTo?.address,
            consigneeAddressType: specialService.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE) ? "RESIDENTIAL" : "WAREHOUSE",
            consigneeCity: shipTo?.parentCity,
            consigneeSubCity: shipTo?.city,
            consigneeProvince: shipTo?.province?.code,
            consigneePostalCode: shipTo?.postalCode,
            consigneeCountry: shipTo?.country,
            packageData: exchangedPackageData,
            serviceName: serviceName,
            specialServices: specialService,
            ftlCount: ftlCountRedux || 0
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data,
                timeout: TIME_OUT
            })
            const LTLRate = result?.data;
            console.log('ltl rating', LTLRate);
            console.log('result', result);
            dispatch(updateShippingLTLCreateLabelRate(LTLRate));
            setLoading(false);
        } catch (e) {
            console.log('service name', serviceName);
            if (e.response.status === 440) {
                setErrorMessage('The authentication token has expired.');
                deleteToken("access_token");
            }
            handleErrorMessage();
            setLoading(false);
        } finally {
            dispatch(updateCallAllLTLCarrier(1));
            window.scrollTo(0, 0); // Scroll to the top
            history.push('/business/shipping-ltl/create-label/rate-list');
        }
    }

    const getLTLRatingByOneServiceRecommend = async (serviceName) => {
        setLoading(true);
        let requestURL = `${PARTNER_URI}/shippingLTL/LTLShippingRatingByServiceName`;

        // todo edit battery details
        let packageData = palletListRedux?.map(element => {
            const updatedElement = {...element};
            updatedElement.packageSpecialServices = {
                ...updatedElement.packageSpecialServices,
                batteryDetails: batteryDetails,
            };
            return updatedElement
        });

        let exchangedPackageData = packageData.map(item => {
            let {length, width} = item;
            if (parseInt(length) < parseInt(width)) {
                return {
                    ...item,
                    length: width,
                    width: length
                }
            }
            return item;
        });

        dispatch(initShippingLTLCreateLabelPalletList(exchangedPackageData));

        let data = {
            shipperAddress: verifyAddressShipFrom?.address || shipFrom?.displayAddress?.text,
            shipperAddressType: specialService.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_PICKUP_CODE) ? "RESIDENTIAL" : "WAREHOUSE",
            shipperCity: shipFrom?.parentCity,
            shipperSubCity: verifyAddressShipFrom?.city || shipFrom?.city,
            shipperProvince: verifyAddressShipFrom?.province || shipFrom?.province?.code,
            shipperPostalCode: verifyAddressShipFrom?.postalCode.replace(/\s+/g, '').toUpperCase() || shipFrom?.postalCode.replace(/\s+/g, '').toUpperCase(),
            shipperCountry: verifyAddressShipFrom?.country || shipFrom?.country,
            consigneeAddress: verifyAddressShipTo?.address || shipTo?.displayAddress?.text,
            consigneeAddressType: specialService.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE) ? "RESIDENTIAL" : "WAREHOUSE",
            consigneeCity: shipTo?.parentCity,
            consigneeSubCity: verifyAddressShipTo?.city || shipTo?.city,
            consigneeProvince: verifyAddressShipTo?.province || shipTo?.province?.code,
            consigneePostalCode: verifyAddressShipTo?.postalCode.replace(/\s+/g, '').toUpperCase() || shipTo?.postalCode?.replace(/\s+/g, '').toUpperCase(),
            consigneeCountry: verifyAddressShipTo?.country || shipTo?.country,
            packageData: exchangedPackageData,
            serviceName: serviceName,
            specialServices: specialService,
            ftlCount: ftlCountRedux || 0
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data,
                timeout: TIME_OUT
            })
            const LTLRate = result?.data;
            console.log('ltl rating', LTLRate);
            console.log('result', result);
            dispatch(updateShippingLTLCreateLabelRate(LTLRate));
            setLoading(false);
        } catch (e) {
            setLoading(false);
            console.log('service name', serviceName);
            if (e.response.status === 440) {
                setErrorMessage('The authentication token has expired.');
                deleteToken("access_token");
            }
            handleErrorMessage();
        } finally {
            dispatch(updateCallAllLTLCarrier(1));
            window.scrollTo(0, 0); // Scroll to the top
            history.push('/business/shipping-ltl/create-label/rate-list');
        }
    }

    const addAddressShipFrom = async () => {
        let requestURL = `${PARTNER_URI}/loose-item/3rd-party/partner/addressbooks/sender`;
        let data = {
            partnerId: partnerId.toString(),
            address: shipFrom?.displayAddress?.text,
            address_2: shipFrom?.address2,
            city: shipFrom?.city,
            email: shipFrom?.email,
            province: shipFrom?.province?.code,
            postalCode: shipFrom?.postalCode,
            country: shipFrom?.country,
            phone: shipFrom?.phone,
            name: shipFrom?.name,
            addressEmail: shipFrom?.email,
            companyName: shipFrom?.company
        }

        try {
            const result = await axios({
                method: 'put',
                url: requestURL,
                data: data
            });
            console.log(result);
            console.log('successfully add ship from address')
        } catch (e) {
            console.log(e);
        }
    }

    const addAddressShipTo = async () => {
        let requestURL = `${PARTNER_URI}/loose-item/3rd-party/partner/addressbooks/deliver`;
        let data = {
            partnerId: partnerId.toString(),
            address: shipTo?.displayAddress?.text,
            address_2: shipTo?.address2,
            city: shipTo?.city,
            email: shipTo?.email,
            province: shipTo?.province?.code,
            postalCode: shipTo?.postalCode,
            country: shipTo?.country,
            phone: shipTo?.phone,
            name: shipTo?.name,
            addressEmail: shipTo?.email,
            companyName: shipTo?.company
        }

        try {
            const result = await axios({
                method: 'put',
                url: requestURL,
                data: data
            });
            console.log(result);
            console.log('successfully add ship to address')
        } catch (e) {
            console.log(e);
        }
    }

    const verifyAddress = async (address, address2, city, province, country, postalCode) => {
        setLoading(true);
        let requestURL = `${PARTNER_URI}/verifyAddress/forWeb`;
        let data = {
            addressLines: [address, address2],
            city: city,
            province: province,
            postalCode: postalCode,
            country: country
        }
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data,
                headers: {
                    Authorization: `Bearer ${token}`
                },
            })
            console.log('result verify address', result?.data);
            // setVerifyAddressAPI(false);
            return result?.data
        } catch (e) {
            console.log('error', e?.response);
            if (e.response.status === 440) {
                setErrorMessage('The authentication token has expired.');
                deleteToken("access_token");
                handleErrorMessage();
            }
            // setVerifyAddressAPI(true);
            return false
        } finally {
            setLoading(false);
        }
    }

    const getAllAvailableServiceNames = async () => {

        const totalQty = palletListRedux?.reduce((acc, item) => acc + (+item?.quantity), 0);

        if (totalQty > 25) {
            return [];
        } else {

            let requestURL = `${PARTNER_URI}/shippingLTL/getAllAvailableServiceNames`;
            try {
                const result = await axios({
                    method: 'get',
                    url: requestURL,
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    params: {
                        count: 1
                    }
                })
                console.log('result', result);
                // setAvailableService(result?.data);
                return result?.data;
            } catch (e) {
                console.log('error', e);
                if (e.response.status === 440) {
                    setErrorMessage('The authentication token has expired.');
                    deleteToken("access_token");
                }
                handleErrorMessage();
            }
        }
    }

    const handleCallLTLCarrier = (availableService) => {

        if (availableService?.length > 0) {
            for (const service of availableService) {
                getLTLRatingByOneService(service?.account_base);
            }
        } else {
            history.push('/business/shipping-ltl/create-label/rate-list');
        }
    }

    const handleCallLTLCarrierRecommend = (availableService) => {
        if (availableService?.length > 0) {
            for (const service of availableService) {
                getLTLRatingByOneServiceRecommend(service?.account_base);
            }
        } else {
            history.push('/business/shipping-ltl/create-label/rate-list');
        }
    }

    const validateEmailAddress = async (email) => {
        let requestURL = `${NODE_ROUTE_URI}/validateEmail`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: {
                    email: email,
                    validateRegex: true,
                    validateMx: true,
                    validateTypo: false,
                    validateDisposable: false,
                    validateSMTP: false
                }
            })
            console.log('[validateEmailAddress] result - ', result);
            return result?.data?.valid;
        } catch (e) {
            console.log(e.response)
        }
    }

    const validate = async () => {
        setErrorMessage('');
        setToastOpen(false);
        const shipFromMissing = getMissingKeysForCreateLTLLabelLocation({
            name: shipFrom?.name,
            phone: shipFrom?.phone,
            email: shipFrom?.email,
            address: shipFrom?.address,
            city: shipFrom?.city,
            province: shipFrom?.province?.code,
            country: shipFrom?.country,
            postalCode: shipFrom?.postalCode
        });
        const shipToMissing = getMissingKeysForCreateLabelLocation({
            name: shipTo?.name,
            phone: shipTo?.phone,
            address: shipTo?.address,
            city: shipTo?.city,
            province: shipTo?.province?.code,
            country: shipTo?.country,
            postalCode: shipTo?.postalCode
        });
        const hasMissingValues = palletListRedux.some(object => requiredProperties.some((property) => !object[property]));
        const hasValidateValues = palletListRedux.some(object => validateRequiredProperties.some((property) => object[property] <= 0));
        const validateMovingFromCityProvinceCountry = await validateCityProvinceCountry(shipFrom?.city, shipFrom?.province?.code, shipFrom?.country);
        const validateMovingToCityProvinceCountry = await validateCityProvinceCountry(shipTo?.city, shipTo?.province?.code, shipTo?.country);
        const shipFromEmailCheck = await validateEmailAddress(shipFrom?.email);

        palletListRedux?.length === 0 && setErrorMessage(prevState => prevState + intl.get('ADD_PALLET.VALIDATION.PALLET_LIST_EMPTY'));
        shipFromMissing?.length !== 0 && setErrorMessage(prevState => prevState + intl.get('ADD_PALLET.VALIDATION.SHIP_FROM_MISSING'));
        shipToMissing?.length !== 0 && setErrorMessage(prevState => prevState + intl.get('ADD_PALLET.VALIDATION.SHIP_TO_MISSING'));
        hasMissingValues && setErrorMessage(prevState => prevState + intl.get('ADD_PALLET.VALIDATION.PALLET_LIST_MISSING'));
        hasValidateValues && setErrorMessage(prevState => prevState + intl.get('ADD_PALLET.VALIDATION.PALLET_LIST_ERROR'));
        !validateMovingFromCityProvinceCountry && setErrorMessage(prevState => prevState + 'Ship from city and province are not validated.');
        !validateMovingToCityProvinceCountry && setErrorMessage(prevState => prevState + 'Ship To city and province are not validated.');
        !shipFromEmailCheck && setErrorMessage(prevState => prevState + ' Ship From Email is invalid.');

        const validation = {
            shipFromMissing,
            shipToMissing,
            hasMissingValues,
            validateMovingFromCityProvinceCountry,
            validateMovingToCityProvinceCountry,
            shipFromEmailCheck
        }

        dispatch(updateShippingLTLCreateLabelValidation(validation));

        if (shipFromMissing.length === 0 &&
            shipToMissing.length === 0 &&
            palletListRedux.length !== 0 &&
            !hasMissingValues &&
            !hasValidateValues &&
            validateMovingToCityProvinceCountry &&
            validateMovingFromCityProvinceCountry &&
            shipFromEmailCheck
        ) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const handleGetRating = async () => {
        dispatch(clearShippingLTLCreateLabelRate());
        dispatch(clearCallAllLTLCarrier());
        dispatch(updateShippingLTLCrossBorderRate(null));
        dispatch(clearShippingLTLCrossBorderItemList());
        const hasCustom = palletListRedux.some(each => each?.packagingType?.name === 'Custom');
        const maxWeight = metric ? 680 : 1500;
        const maxLength = metric ? 122 : 48;
        const maxWidth = metric ? 122 : 48;
        const maxHeight = metric ? 203 : 80;
        const availableService = await getAllAvailableServiceNames();

        try {
            await handleCallLTLCarrier(availableService);
        } catch (e) {
            console.log('error', e.response);
        } finally {
            if (shipFrom.saveAddress) {
                addAddressShipFrom();
            }
            if (shipTo.saveAddress) {
                addAddressShipTo();
            }
        }
    }

    const handleAddressVerification = async () => {
        const verifyAddressResultShiFrom = await verifyAddress(shipFrom?.address, shipFrom?.address2, shipFrom?.city, shipFrom?.province?.code, shipFrom?.country, shipFrom?.postalCode);
        const verifyAddressResultShiTo = await verifyAddress(shipTo?.address, shipTo?.address2, shipTo?.city, shipTo?.province?.code, shipTo?.country, shipTo?.postalCode);
        let result = true;
        // console.log('verifyAddressResultShiFrom', verifyAddressResultShiFrom);
        if (verifyAddressResultShiFrom) {
            if (verifyAddressResultShiFrom?.customerMessages?.length > 0) {
                console.log('error length')
                setAddressVerifyDialog(true);
                setAddressErrorMessageShiFrom('Your ship from address is invalid.');
                setVerifyAddressShipFrom(null);
                result = false;
            } else if (
                (!verifyAddressResultShiFrom?.correction?.addressMatch ||
                    !verifyAddressResultShiFrom?.correction?.cityMatch ||
                    !verifyAddressResultShiFrom?.correction?.provinceMatch ||
                    !verifyAddressResultShiFrom?.correction?.countryMatch ||
                    !verifyAddressResultShiFrom?.correction?.postalCodeMatch) &&
                verifyAddressResultShiFrom?.missingKeys?.length === 0
            ) {
                console.log('match')
                setAddressVerifyDialog(true);
                setVerifyAddressShipFrom(prevState => ({
                    ...prevState,
                    address: verifyAddressResultShiFrom?.streetLinesToken[0],
                    address2: verifyAddressResultShiFrom?.streetLinesToken[1],
                    city: verifyAddressResultShiFrom?.city,
                    province: verifyAddressResultShiFrom?.stateOrProvinceCode,
                    country: verifyAddressResultShiFrom?.countryCode,
                    postalCode: verifyAddressResultShiFrom?.postalCode
                }));
                setAddressErrorMessageShiFrom('');
                result = false;
            } else {
                setAddressErrorMessageShiFrom('');
                setVerifyAddressShipFrom(null);
            }
        }

        if (verifyAddressResultShiTo) {
            if (verifyAddressResultShiTo?.customerMessages?.length > 0) {
                setAddressVerifyDialog(true);
                setAddressErrorMessageShipTo('Your ship to address is invalid.');
                setVerifyAddressShipTo(null);
                result = false;
            } else if (
                (!verifyAddressResultShiTo?.correction?.addressMatch ||
                    !verifyAddressResultShiTo?.correction?.cityMatch ||
                    !verifyAddressResultShiTo?.correction?.provinceMatch ||
                    !verifyAddressResultShiTo?.correction?.countryMatch ||
                    !verifyAddressResultShiTo?.correction?.postalCodeMatch) &&
                verifyAddressResultShiTo?.missingKeys?.length === 0
            ) {
                setAddressVerifyDialog(true);
                setVerifyAddressShipTo(prevState => ({
                    ...prevState,
                    address: verifyAddressResultShiTo?.streetLinesToken[0],
                    address2: verifyAddressResultShiTo?.streetLinesToken[1],
                    city: verifyAddressResultShiTo?.city,
                    province: verifyAddressResultShiTo?.stateOrProvinceCode,
                    country: verifyAddressResultShiTo?.countryCode,
                    postalCode: verifyAddressResultShiTo?.postalCode
                }));
                setAddressErrorMessageShipTo('');
                result = false;
            } else {
                setAddressErrorMessageShipTo('');
                setVerifyAddressShipTo(null);
            }
        }

        if (result) {
            console.log('call api');
            handleGetRating();
        }
    }

    const handleGetRate = async () => {
        const validationResult = await validate();
        if (validationResult) {
            setShipFromOriginal(shipFrom);
            setShipToOriginal(shipTo)
            await handleAddressVerification();
        }
    }

    const handleRecommendAddress = async () => {
        dispatch(clearShippingLTLCreateLabelRate());
        dispatch(clearCallAllLTLCarrier());
        dispatch(updateShippingLTLCrossBorderRate(null));
        dispatch(clearShippingLTLCrossBorderItemList());
        dispatch(updateShippingLTLCreateLabelMoveFrom({
            postalCode: verifyAddressShipFrom?.postalCode || shipFrom?.postalCode,
            province: {
                code: verifyAddressShipFrom?.province || shipFrom?.province?.code,
                name: verifyAddressShipFrom?.province || shipFrom?.province?.code,
            },
            city: verifyAddressShipFrom?.city || shipFrom?.city,
            address: verifyAddressShipFrom?.address || shipFrom?.address,
            address2: verifyAddressShipFrom?.address2 || shipFrom?.address2,
            country: verifyAddressShipFrom?.country || shipFrom?.country,
            displayAddress: {
                description: null,
                placeId: null,
                text: verifyAddressShipFrom?.address || shipFrom?.address
            },
            name: shipFrom?.name,
            phone: shipFrom?.phone,
            email: shipFrom?.email,
            company: shipFrom?.company,
            saveAddress: shipFrom?.saveAddress,
            addressList: {
                name: shipFrom?.name,
                phone: shipFrom?.phone,
                email: null,
                address_email: shipFrom?.email,
                company_name: shipFrom?.company,
                city: verifyAddressShipFrom?.city || shipFrom?.city,
                province: verifyAddressShipFrom?.province || shipFrom?.province?.code,
                country: verifyAddressShipFrom?.country || shipFrom?.country
            }
        }));
        dispatch(updateShippingLTLCreateLabelMoveTo({
            postalCode: verifyAddressShipTo?.postalCode || shipTo?.postalCode,
            province: {
                code: verifyAddressShipTo?.province || shipTo?.province?.code,
                name: verifyAddressShipTo?.province || shipTo?.province?.code
            },
            city: verifyAddressShipTo?.city || shipTo?.city,
            address: verifyAddressShipTo?.address || shipTo?.address,
            address2: verifyAddressShipTo?.address2 || shipTo?.address2,
            country: verifyAddressShipTo?.country || shipTo?.country,
            displayAddress: {
                description: null,
                placeId: null,
                text: verifyAddressShipTo?.address || shipTo?.address
            },
            name: shipTo?.name,
            phone: shipTo?.phone,
            email: shipTo?.email,
            company: shipTo?.company,
            saveAddress: shipTo?.saveAddress,
            addressList: {
                name: shipTo?.name,
                phone: shipTo?.phone,
                email: null,
                address_email: shipTo?.email,
                company_name: shipTo?.company,
                city: verifyAddressShipTo?.city || shipTo?.city,
                province: verifyAddressShipTo?.province || shipTo?.province?.code,
                country: verifyAddressShipTo?.country || shipTo?.country
            }
        }))
        const hasCustom = palletListRedux.some(each => each?.packagingType?.name === 'Custom');
        const maxWeight = metric ? 680 : 1500;
        const maxLength = metric ? 122 : 48;
        const maxWidth = metric ? 122 : 48;
        const maxHeight = metric ? 203 : 80;
        const availableService = await getAllAvailableServiceNames();

        try {
            await handleCallLTLCarrierRecommend(availableService);
        } catch (e) {
            console.log('error', e.response);
        } finally {
            if (shipFrom.saveAddress) {
                addAddressShipFrom();
            }
            if (shipTo.saveAddress) {
                addAddressShipTo();
            }
        }

    }

    useEffect(() => {
        const nextWeekday = getNextWeekday();
        setShipDateAndTime(prevState => ({
            ...prevState,
            shipDate: nextWeekday,
            pickupDate: nextWeekday,
            availablePickupTime: "12:00",
            earliestPickupTime: "10:00",
            latestPickupTime: "16:00"
        }));
        // console.log('next week day', nextWeekday);
        // console.log('current date', currentDate);
    }, []);

    useEffect(() => {
        dispatch(updateShippingLTLCreateLabelReferenceNumber(referenceNumber));
    }, [referenceNumber])

    useEffect(() => {
        dispatch(updateShippingLTLCreateLabelComment(comments));
    }, [comments])

    useEffect(() => {
        dispatch(updateShippingLTLCreateLabelDateAndTime(shipDataAndTime));
    }, [shipDataAndTime])

    useEffect(() => {
        if (referenceNumberRedux) {
            setReferenceNumber(referenceNumberRedux)
        }
        if (commentRedux) {
            setComments(commentRedux)
        }
        if (dateAndTimeRedux) {
            setShipDateAndTime(dateAndTimeRedux)
        }
    }, [])

    useEffect(() => {
        const shipFromString = JSON.stringify(shipFrom);
        const shipToString = JSON.stringify(shipTo);
        const shipFromOriginalString = JSON.stringify(shipFromOriginal);
        const shipToOriginalString = JSON.stringify(shipToOriginal);
        if (shipFromString !== shipFromOriginalString || shipToString !== shipToOriginalString) {
            setAddressVerifyDialog(false);
        }
    }, [shipFrom, shipTo])

    // console.log('pallet list redux', palletListRedux);
    // console.log('available service', availableService);

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Stepper alternativeLabel activeStep={0} connector={<QontoConnector/>}>
                {
                    steps.map((label, index) => (
                        // (shipFrom?.country === shipTo?.country ? steps : InternationalSteps).map((label, index) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={QontoStepIcon}>
                                <Typography sx={{color: index <= 0 ? '#1D8B45' : '#C7C7C7', fontSize: '14px'}}>
                                    {label}
                                </Typography>
                            </StepLabel>
                        </Step>
                    ))
                }
            </Stepper>
            <Box style={styles.ShippingLTLCreateLabelRoot}>
                {/*<Box sx={styles.ShippingPackageCreateLabelSection}>*/}
                {/*    <Typography style={styles.ShippingPackageCreateLabelHeading}>*/}
                {/*        {intl.get('QUICK_QUOTE_NAV.SELECT_TYPE')}*/}
                {/*    </Typography>*/}
                {/*    <Box*/}
                {/*        sx={{*/}
                {/*            width: isMobile ? '300px' : '100%',*/}
                {/*            display: 'flex',*/}
                {/*            justifyContent: 'space-between',*/}
                {/*            flexWrap: 'wrap',*/}
                {/*            gap: '10px'*/}
                {/*        }}>*/}
                {/*        <Box sx={{display: 'flex', alignItems: 'center'}}>*/}
                {/*            <Checkbox*/}
                {/*                icon={<RadioButtonUncheckedIcon/>}*/}
                {/*                checkedIcon={<CheckCircleIcon/>}*/}
                {/*                color="success"*/}
                {/*                onClick={() => history.push('/business/shipping-package/quick-quote')}*/}
                {/*            />*/}
                {/*            <img src={packageIcon} alt="packageIcon" width='40px'/>*/}
                {/*            <Typography style={{color: '#545454', padding: '0 5px'}}>*/}
                {/*                {intl.get('QUICK_QUOTE_NAV.PACKAGE')}*/}
                {/*            </Typography>*/}
                {/*        </Box>*/}
                {/*        <Box sx={{display: 'flex', alignItems: 'center'}}>*/}
                {/*            <Checkbox*/}
                {/*                icon={<RadioButtonUncheckedIcon/>}*/}
                {/*                checkedIcon={<CheckCircleIcon/>}*/}
                {/*                color="success"*/}
                {/*                onClick={() => history.push('/business/shipping-package/international-shipment')}*/}
                {/*            />*/}
                {/*            <PublicIcon sx={{*/}
                {/*                fontSize: '40px'*/}
                {/*            }}/>*/}
                {/*            <Typography style={{color: '#545454', padding: '0 5px'}}>*/}
                {/*                {intl.get('QUICK_QUOTE_NAV.INTERNATION')}*/}
                {/*            </Typography>*/}
                {/*        </Box>*/}
                {/*        <Box sx={{display: 'flex', alignItems: 'center'}}>*/}
                {/*            <Checkbox*/}
                {/*                icon={<RadioButtonUncheckedIcon/>}*/}
                {/*                checkedIcon={<CheckCircleIcon/>}*/}
                {/*                color="success"*/}
                {/*                checked*/}
                {/*            />*/}
                {/*            <img src={ltlIcon} alt="ltlIcon" width='40px'/>*/}
                {/*            <Typography style={{color: '#545454', padding: '0 5px'}}>*/}
                {/*                {intl.get('QUICK_QUOTE_NAV.LTL')}*/}
                {/*            </Typography>*/}
                {/*        </Box>*/}
                {/*        <Box sx={{display: 'flex', alignItems: 'center'}}>*/}
                {/*            <Checkbox*/}
                {/*                icon={<RadioButtonUncheckedIcon/>}*/}
                {/*                checkedIcon={<CheckCircleIcon/>}*/}
                {/*                color="success"*/}
                {/*                onClick={() => history.push('/business/brokerage/estimate')}*/}
                {/*            />*/}
                {/*            <img src={brokerageIcon} alt="brokerageIcon" width='40px'/>*/}
                {/*            <Typography style={{color: '#545454', padding: '0 5px'}}>*/}
                {/*                {intl.get('QUICK_QUOTE_NAV.BROKERAGE')}*/}
                {/*            </Typography>*/}
                {/*        </Box>*/}
                {/*    </Box>*/}
                {/*</Box>*/}
                <ShippingLTLCreateLabelShipFrom shipFromHistory={shipFromHistory} isResidential={shipFromResidential}/>
                <ShippingLTLCreateLabelShipTo shipToHistory={shipToHistory} isResidential={shipToResidential}/>
                <ShippingLTLCreateLabelPallet palletListRedux={palletListRedux}
                                              palletListHistory={palletListHistory}
                                              metricHistory={metricHistory}
                                              ftlCountHistory={ftlCountHistory}
                                              ftlCountRedux={ftlCountRedux}/>
                <ShippingLTLCreateLabelShippingOptions batteryDetailsHistory={batteryDetailsHistory}/>
                <Box sx={styles.ShippingPackageCreateLabelSection}>
                    <Box sx={{
                        display: 'flex',
                        gap: '10px',
                        padding: '20px 20px 0 20px'
                    }}>
                        <Typography sx={styles.ShippingPackageCreateLabelHeading}>
                            {intl.get('SHIPPING_LTL_QUICK_QUOTE.REFERENCE')}
                        </Typography>
                    </Box>
                    <Divider/>
                    <Box sx={styles.ShippingPackageCreateLabelRateListContent}>
                        <Grid container spacing={2}>
                            <Grid item md={12} xs={12}>
                                <Box sx={styles.ShippingPackageCreateLabelSectionBox}>
                                    <InputLabel>
                                        {intl.get('SHIPPING_LTL_QUICK_QUOTE.REFERENCE')}
                                    </InputLabel>
                                    <TextField
                                        value={referenceNumber}
                                        variant="outlined"
                                        size='small'
                                        onInput={handleReferenceNumber}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Box sx={styles.ShippingPackageCreateLabelSection}>
                    <Box sx={{
                        display: 'flex',
                        gap: '10px',
                        padding: '20px 20px 0 20px'
                    }}>
                        <SchedulePickupIcon width={25} height={25}/>
                        <Typography sx={styles.ShippingPackageCreateLabelHeading}>
                            {intl.get('SHIPPING_LTL_QUICK_QUOTE.PICKUP_DATE_TIME')}
                        </Typography>
                    </Box>
                    <Divider/>
                    <Box sx={styles.ShippingPackageCreateLabelRateListContent}>
                        <Grid container spacing={2}>
                            <Grid item md={4} xs={12}>
                                <Box sx={styles.ShippingPackageCreateLabelSectionBox}>
                                    <InputLabel>
                                        {intl.get('PICKUP_DATE_TIME.DATE')}
                                    </InputLabel>
                                    <TextField
                                        fullWidth
                                        type='date'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={shipDataAndTime?.pickupDate}
                                        onChange={handlePickupDate}
                                        inputProps={{
                                            min: getMinDate(),
                                        }}
                                        size='small'
                                    />
                                    <Typography style={styles.ShippingLTLQuickQuoteAddressErrorText}>
                                        {/*{*/}
                                        {/*    moveFromMissing.includes('city') ? "This is required field." : ''*/}
                                        {/*}*/}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Box sx={styles.ShippingPackageCreateLabelSectionBox}>
                                    <InputLabel>
                                        {intl.get('PICKUP_DATE_TIME.EARLIEST_TIME')}
                                    </InputLabel>
                                    <TextField
                                        fullWidth
                                        type='time'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={shipDataAndTime?.earliestPickupTime}
                                        onChange={handlePickupEarliestTime}
                                        // inputProps={{
                                        //     min: currentDate,
                                        // }}
                                        size='small'
                                    />
                                    <Typography style={styles.ShippingLTLQuickQuoteAddressErrorText}>
                                        {/*{*/}
                                        {/*    moveFromMissing.includes('city') ? "This is required field." : ''*/}
                                        {/*}*/}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Box sx={styles.ShippingPackageCreateLabelSectionBox}>
                                    <InputLabel>
                                        {intl.get('PICKUP_DATE_TIME.LATEST_TIME')}
                                    </InputLabel>
                                    <TextField
                                        fullWidth
                                        type='time'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={shipDataAndTime?.latestPickupTime}
                                        onChange={handlePickupLatestTime}
                                        // inputProps={{
                                        //     min: currentDate,
                                        // }}
                                        size='small'
                                    />
                                    <Typography style={styles.ShippingLTLQuickQuoteAddressErrorText}>
                                        {/*{*/}
                                        {/*    moveFromMissing.includes('city') ? "This is required field." : ''*/}
                                        {/*}*/}
                                    </Typography>
                                </Box>
                            </Grid>
                            {
                                shipDataAndTime?.pickupDate === currentDate &&
                                <Grid item xs={12}>
                                    <Typography sx={{fontSize: '14px', textAlign: 'left'}}>
                                        {intl.get('PICKUP_DATE_TIME.NOTE')}
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                    </Box>
                </Box>
                <ShippingLTLCreateLabelAdditionalService specialServiceHistory={specialServiceHistory}
                                                         cbHandleShipFromResidential={cbHandleShipFromResidential}
                                                         cbHandleShipToResidential={cbHandleShipToResidential}
                />
                <Box sx={styles.ShippingPackageCreateLabelSection}>
                    <Box sx={{
                        display: 'flex',
                        gap: '10px',
                        padding: '20px 20px 0 20px'
                    }}>
                        <Typography sx={styles.ShippingPackageCreateLabelHeading}>
                            {intl.get('ADDITIONAL_SERVICE.REMARK')}
                        </Typography>
                    </Box>
                    <Divider/>
                    <Box sx={styles.ShippingPackageCreateLabelRateListContent}>
                        <Grid container spacing={2}>
                            <Grid item md={12} xs={12}>
                                <Box sx={styles.ShippingPackageCreateLabelSectionBox}>
                                    {/*<InputLabel>*/}
                                    {/*    {intl.get('SHIPPING_LTL_QUICK_QUOTE.REFERENCE')}*/}
                                    {/*</InputLabel>*/}
                                    <TextField
                                        value={comments}
                                        variant="outlined"
                                        size='small'
                                        fullWidth
                                        multiline
                                        rows={4}
                                        onInput={handleInputComments}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                {
                    addressVerifyDialog ? <>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography style={{
                                        fontWeight: '600',
                                        color: '#1D8B45',
                                        textAlign: 'center'
                                    }}>
                                        Verify Your Shipping Address
                                    </Typography>
                                </Grid>
                                {
                                    (addressErrorMessageShipFrom || addressErrorMessageShipTo) ?
                                        <>
                                            <Grid item xs={12}>
                                                <Typography style={{textAlign: 'center'}}>
                                                    While your address did not fully match with Canada Post AddressComplete,
                                                    it’s possible
                                                    your shipment can still proceed normally.
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    gap: '10px',
                                                }}>
                                                    <LoadingButton
                                                        onClick={handleGetRating}
                                                        variant='contained'
                                                        sx={{
                                                            backgroundColor: '#1D8B45',
                                                            "&:hover": {
                                                                backgroundColor: '#1D8B45',
                                                                filter: 'brightness(0.9)'
                                                            }
                                                        }}
                                                        autoFocus
                                                        type="button"
                                                        loading={loading}
                                                    >
                                                        Continue Your Address
                                                    </LoadingButton>
                                                    {/*<LoadingButton*/}
                                                    {/*    onClick={handleAddressVerifyDialogClose}*/}
                                                    {/*    variant='contained'*/}
                                                    {/*    sx={{*/}
                                                    {/*        backgroundColor: '#1D8B45',*/}
                                                    {/*        "&:hover": {*/}
                                                    {/*            backgroundColor: '#1D8B45',*/}
                                                    {/*            filter: 'brightness(0.9)'*/}
                                                    {/*        }*/}
                                                    {/*    }}*/}
                                                    {/*    autoFocus type="button"*/}
                                                    {/*    loading={loading}*/}
                                                    {/*>*/}
                                                    {/*    Edit Your Address*/}
                                                    {/*</LoadingButton>*/}
                                                </Box>
                                            </Grid>
                                        </>
                                        :
                                        <>
                                            <Grid item xs={12}>
                                                <Typography style={{textAlign: 'center'}}>
                                                    Based on Canada Post AddressComplete, we've detected some discrepancies
                                                    in the address you provided. For accurate shipping rates and timely
                                                    delivery, consider our recommended address.
                                                </Typography>
                                            </Grid>
                                            {
                                                verifyAddressShipFrom &&
                                                <>
                                                    <Grid item xs={12}>
                                                        <Typography style={{
                                                            fontWeight: '600',
                                                            textAlign: 'center'
                                                        }}>
                                                            Ship From Address
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Box>
                                                            <Typography style={{textAlign: 'center'}}>
                                                                Original Address
                                                            </Typography>
                                                            <Typography style={{textAlign: 'center'}}>
                                                                {shipFrom?.address2} {shipFrom?.displayAddress?.text}
                                                            </Typography>
                                                            <Typography style={{textAlign: 'center'}}>
                                                                {shipFrom?.city} {shipFrom?.province?.code}
                                                            </Typography>
                                                            <Typography style={{textAlign: 'center'}}>
                                                                {shipFrom?.country} {shipFrom?.postalCode}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Box>
                                                            <Typography style={{textAlign: 'center'}}>
                                                                Recommend Address
                                                            </Typography>
                                                            <Typography style={{textAlign: 'center'}}>
                                                                {verifyAddressShipFrom?.address2} {verifyAddressShipFrom?.address}
                                                            </Typography>
                                                            <Typography style={{textAlign: 'center'}}>
                                                                {verifyAddressShipFrom?.city} {verifyAddressShipFrom?.province}
                                                            </Typography>
                                                            <Typography style={{textAlign: 'center'}}>
                                                                {verifyAddressShipFrom?.country} {verifyAddressShipFrom?.postalCode}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Divider/>
                                                    </Grid>
                                                </>
                                            }
                                            {
                                                verifyAddressShipTo &&
                                                <>
                                                    <Grid item xs={12}>
                                                        <Typography style={{
                                                            fontWeight: '600',
                                                            textAlign: 'center'
                                                        }}>
                                                            Ship To Address
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Box>
                                                            <Typography style={{textAlign: 'center'}}>
                                                                Original Address
                                                            </Typography>
                                                            <Typography style={{textAlign: 'center'}}>
                                                                {shipTo?.address2} {shipTo?.displayAddress?.text}
                                                            </Typography>
                                                            <Typography style={{textAlign: 'center'}}>
                                                                {shipTo?.city} {shipTo?.province?.code}
                                                            </Typography>
                                                            <Typography style={{textAlign: 'center'}}>
                                                                {shipTo?.country} {shipTo?.postalCode}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Box>
                                                            <Typography style={{textAlign: 'center'}}>
                                                                Recommend Address
                                                            </Typography>
                                                            <Typography style={{textAlign: 'center'}}>
                                                                {verifyAddressShipTo?.address2} {verifyAddressShipTo?.address}
                                                            </Typography>
                                                            <Typography style={{textAlign: 'center'}}>
                                                                {verifyAddressShipTo?.city} {verifyAddressShipTo?.province}
                                                            </Typography>
                                                            <Typography style={{textAlign: 'center'}}>
                                                                {verifyAddressShipTo?.country} {verifyAddressShipTo?.postalCode}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                </>
                                            }
                                            <Grid item xs={12}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    gap: '10px'
                                                }}>
                                                    <LoadingButton
                                                        onClick={handleGetRating}
                                                        variant='contained'
                                                        sx={{
                                                            backgroundColor: '#454545',
                                                            "&:hover": {
                                                                backgroundColor: '#454545',
                                                                filter: 'brightness(0.9)'
                                                            }
                                                        }}
                                                        autoFocus
                                                        type="button"
                                                        loading={loading}
                                                    >
                                                        Continue Your Address
                                                    </LoadingButton>
                                                    <LoadingButton
                                                        onClick={handleRecommendAddress}
                                                        variant='contained'
                                                        sx={{
                                                            backgroundColor: '#1D8B45',
                                                            "&:hover": {
                                                                backgroundColor: '#1D8B45',
                                                                filter: 'brightness(0.9)'
                                                            }
                                                        }}
                                                        autoFocus type="button"
                                                        loading={loading}
                                                    >
                                                        Use Recommend Address
                                                    </LoadingButton>
                                                </Box>
                                            </Grid>
                                        </>

                                }
                            </Grid>
                        </>
                        :
                        <Box sx={styles.ShippingPackageCreateLabelRowRight}>
                            <LoadingButton
                                variant='contained'
                                color='success'
                                size='large'
                                loading={loading}
                                onClick={handleGetRate}
                            >
                                <Typography sx={{textTransform: 'none'}}>
                                    {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.GET_RATE')}
                                </Typography>
                            </LoadingButton>
                        </Box>
                }
            </Box>
        </Container>
    )
}
