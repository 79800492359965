import React, {Fragment, useEffect, useState} from 'react';
import {
    Grid,
    Typography,
    IconButton,
    Box,
    List,
    ListItem,
    ListItemText,
    Paper,
    Tooltip,
    Button,
    Stack
} from '@mui/material';
import {makeStyles} from "@material-ui/core/styles";
import intl from "react-intl-universal";
import ListItemButton from "@mui/material/ListItemButton";
import {Divider, ListItemIcon} from "@material-ui/core";
import {useSelector} from "react-redux";
import MovingServiceRoomPlaceItemEach from "./MovingServiceRoomPlaceItemEach";
import axios from "axios";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import {inchesToCubicMeters} from "../../Utils/calculateVolume";
import {mapRoomPlaceIcon} from "../../Utils/Helper";
import {ReactComponent as Inventory} from "../../images/NewDesignIcon/InventoryList.svg";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
        // height: '80vh',
        // backgroundColor: theme.palette.background.default,
    },
    menu: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        // padding: theme.spacing(1),
        padding: theme.spacing(2),
    },
    itemBox: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[2],
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    itemText: {
        fontWeight: theme.typography.fontWeightMedium,
    },
    itemCounter: {
        display: 'flex',
        alignItems: 'center',
    },
    MovingServiceItemRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '10px',
        display: 'flex',
        flexDirection: 'column',
    },
    MovingServiceItemHeading: {
        fontSize: '20px',
        fontWeight: '600'
    },
    selectedMenuItem: {
        display: "flex",
        justifyContent: "space-between",
        gap: "1px",
        borderLeft: `4px solid green`,
        backgroundColor: theme.palette.action.hover,
        '& .MuiTypography-root': {
            color: 'green',
            fontWeight: theme.typography.fontWeightBold,
        },
    },
    menuItem: {
        display: "flex",
        justifyContent: "space-between",
        gap: "1px"
    },
}));

const styles = {
    MovingServiceAddressSection: {
        backgroundColor: '#FFFFFF',
        paddingBottom: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0'
    },
    MovingServiceAddressHeading: {
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '20px'
    },
}

export const MovingServiceRoomPlaceItemList = ({tab}) => {
        const classes = useStyles();

        let movingFurnitureListRedux = useSelector(state => state?.movingService?.movingFurnitureList);
        let movingFromAddress = useSelector(state => state?.movingService?.movingFromAddress);
        let movingToAddress = useSelector(state => state?.movingService?.movingToAddress);

        const [roomPlaces, setRoomPlaces] = useState([]); // { roomPlaceName, iconURI }
        const [furnitureItemsList, setFurnitureItemsList] = useState([]);
        const [selectRoomPlaceIndex, setSelectRoomPlaceIndex] = useState(0);
        const [selectFurnitureItems, setSelectFurnitureItems] = useState([]);
        const [roomPlaceCount, setRoomPlaceCount] = useState([]);
        const [furnitureItemVolume, setFurnitureItemVolume] = useState(0);
        const [furnitureItemQuantity, setFurnitureItemQuantity] = useState(0);

        useEffect(() => {
            (async () => {
                try {
                    const requestURL = `${NODE_ROUTE_URI}/movingService/getMovingItemRoomPlaces`;
                    const {data: roomPlacesList} = await axios.get(requestURL);
                    const newRoomPlaces = [];
                    const newFurnitureItemsList = [];
                    console.log('[getMovingItemRoomPlaces] roomPlacesList', roomPlacesList);
                    if (tab === 'item') {
                        for (const roomPlaces of roomPlacesList) {
                            const {
                                roomPlace: roomPlaceName,
                                categories: categoriesList,
                                icon_uri: roomPlaceIcon
                            } = roomPlaces;
                            newRoomPlaces.push({
                                roomPlaceName,
                                iconURI: roomPlaceIcon,
                                count: 0
                            });
                            newFurnitureItemsList.push(categoriesList);
                        }
                    } else {
                        const boxesRoomPlace = roomPlacesList?.filter((roomPlace) => (
                            roomPlace?.roomPlace?.toUpperCase() === 'BOXES'
                        ));
                        for (const roomPlaces of boxesRoomPlace) {
                            const {
                                roomPlace: roomPlaceName,
                                categories: categoriesList,
                                icon_uri: roomPlaceIcon
                            } = roomPlaces;
                            newRoomPlaces.push({
                                roomPlaceName,
                                iconURI: roomPlaceIcon,
                                count: 0
                            });
                            newFurnitureItemsList.push(categoriesList);
                        }
                    }
                    setRoomPlaces(newRoomPlaces);
                    setFurnitureItemsList(newFurnitureItemsList);
                } catch (e) {
                    console.log(e);
                }
            })()
        }, [])

        useEffect(() => {
            setSelectFurnitureItems(furnitureItemsList?.[selectRoomPlaceIndex] || []);

        }, [selectRoomPlaceIndex, furnitureItemsList]);

        useEffect(() => {
                if (roomPlaces?.length > 0) {
                    const updatedRoomPlaces = [...roomPlaces];
                    for (const existingFurniture of movingFurnitureListRedux) {
                        const {roomPlace: furnitureRoomPlaceString = "", quantity} = existingFurniture;
                        if (furnitureRoomPlaceString) {
                            updatedRoomPlaces.forEach((roomEach, index) => {
                                if (roomEach?.roomPlaceName?.toLowerCase() === furnitureRoomPlaceString?.toLowerCase()) {
                                    updatedRoomPlaces[index] = {
                                        ...roomEach,
                                        count: (roomEach.count || 0) + quantity,
                                    };
                                }
                            });
                        }
                    }
                    setRoomPlaceCount(updatedRoomPlaces);
                }
            }, [roomPlaces, movingFurnitureListRedux]
        )

        useEffect(() => {
            let totalVolume = 0;
            let totalQuantity = 0;
            for (const existingFurniture of movingFurnitureListRedux) {
                const {length, width, height, quantity, lengthUnit} = existingFurniture;
                const volumeCubicMeter = (inchesToCubicMeters({
                    length,
                    height,
                    width
                }) || 0) * quantity;
                console.log("volumeCubicMeter", volumeCubicMeter);
                totalVolume += volumeCubicMeter;
                totalQuantity += quantity;
            }
            setFurnitureItemVolume(totalVolume.toFixed(2));
            setFurnitureItemQuantity(totalQuantity);
        }, [movingFurnitureListRedux])

        console.log('[MovingServiceRoomPlaceItemList] roomPlaces', roomPlaces);
        console.log('[MovingServiceRoomPlaceItemList] selectFurnitureItems', selectFurnitureItems);
        console.log('[MovingServiceRoomPlaceItemList] movingFurnitureListRedux', movingFurnitureListRedux);
        console.log('[MovingServiceRoomPlaceItemList] roomPlaceCount', roomPlaceCount);
        console.log('[MovingServiceRoomPlaceItemList] tab', tab);

        return (
            <Box sx={styles.MovingServiceAddressSection}>
                <Box sx={{
                    display: 'flex',
                    gap: '10px',
                    padding: '20px 20px 0 20px',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <Box sx={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center'
                    }}>
                        <Inventory width={25} height={25}/>
                        <Typography style={styles.MovingServiceAddressHeading}>
                            {intl.get('ITEM_LIST.FURNITURE_LIST')}
                        </Typography>
                    </Box>
                    <Tooltip
                        placement="top"
                        arrow
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    bgcolor: 'white',
                                    color: 'black',
                                    boxShadow: 1,
                                    border: '1px solid #ddd',
                                    borderRadius: '8px', // Added border radius
                                },
                            },
                            arrow: {
                                sx: {
                                    color: 'white',
                                },
                            },
                        }}
                        title={
                            <Box sx={{backgroundColor: "#ffffff", p: 1}}>
                                We calculate your move cost by estimating the total volume of items to be moved.
                                This volume helps determine the required number of movers and the appropriate truck
                                size, ensuring a smooth and efficient move.
                            </Box>
                        }>
                        <Typography sx={{
                            fontSize: '20px',
                            fontWeight: '600',
                            color: "green",
                            cursor: "pointer"
                        }}>
                            Total Quantity: {furnitureItemQuantity}, Estimating {furnitureItemVolume} m³
                        </Typography>
                    </Tooltip>
                </Box>
                <Divider/>
                <Box sx={{
                    backgroundColor: '#FFFFFF',
                    padding: '0 40px',
                    gap: '40px',
                    display: 'flex',
                }}>
                    <Grid container spacing={2}>
                        <Grid container spacing={2} className={classes.container}>
                            {
                                (movingFromAddress?.propertyType?.houseType === 'Curb Side' && movingToAddress?.propertyType?.houseType === 'Curb Side') &&
                                <Grid item xs={12}>
                                    <Typography>
                                        Moving From Curb Side To Curb Side. Wrapping, assembly, and disassembly are not
                                        applicable.
                                    </Typography>
                                </Grid>
                            }
                            <Grid item xs={3}>
                                {/*<MovingRoomPlacesMenu roomPlaces={ROOM_PLACES} />*/}
                                <List className={classes.menu}>
                                    {roomPlaces.map((roomPlace, index) => (
                                        <Fragment key={index}>
                                            <ListItemButton key={index}
                                                            selected={selectRoomPlaceIndex === index}
                                                            className={selectRoomPlaceIndex === index ? classes.selectedMenuItem : classes.menuItem}
                                                            sx={{
                                                                borderLeft: selectRoomPlaceIndex === index ? `4px solid green` : "",
                                                            }}
                                                            onClick={() => {
                                                                console.log(index);
                                                                setSelectRoomPlaceIndex(index);
                                                            }}
                                            >
                                                <>
                                                    <ListItemIcon>
                                                        {mapRoomPlaceIcon(roomPlace.roomPlaceName, selectRoomPlaceIndex === index ? '#1D8B45' : '#454545', '35')}
                                                    </ListItemIcon>
                                                    <ListItemText primary={roomPlace.roomPlaceName}/>
                                                </>
                                                <Fragment key={index}>
                                                    {
                                                        (() => {
                                                            if (roomPlaceCount?.[index]?.count > 0) {
                                                                return (
                                                                    <div style={{
                                                                        width: 25,
                                                                        height: 25,
                                                                        borderRadius: '50%',
                                                                        backgroundColor: 'green',
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                    }}
                                                                    >
                                                                        <Typography
                                                                            style={{
                                                                                color: "white",
                                                                                fontSize: '12px'
                                                                            }}>
                                                                            {roomPlaceCount?.[index].count}
                                                                        </Typography>
                                                                    </div>
                                                                )
                                                            }
                                                        })()
                                                    }
                                                </Fragment>
                                            </ListItemButton>
                                            <Divider/>
                                        </Fragment>
                                    ))}
                                </List>
                            </Grid>
                            <Grid item xs={9}>
                                <Grid container spacing={2} paddingLeft={3} paddingRight={3} paddingTop={1}>
                                    {selectFurnitureItems.map((item, index) => (
                                        <Grid item xs={12} sm={6} md={4} key={index}>
                                            <MovingServiceRoomPlaceItemEach
                                                categoryName={item.category_name}
                                                cateId={item.cate_id}
                                                categoryDescription={item.category_desc}
                                                heightInch={item.standard_height_ins}
                                                lengthInch={item.standard_length_ins}
                                                widthInch={item.standard_width_ins}
                                                weightLB={item.standard_weight_lbs}
                                                volumeCubicInch={item.standard_volume_cubic_in}
                                                roomPlaceName={item?.room_place}
                                                roomPlace={item?.roomPlace}
                                                packageSpecialServices={item?.packageSpecialServices}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

        );
    }
;