import React, {useEffect, useRef, useState} from "react";
import {
    Box,
    TextField,
    Snackbar,
} from "@material-ui/core";
import LoadingButton from "@mui/lab/LoadingButton";
import {useForm, Controller} from "react-hook-form";
import {Alert, Pagination} from "@material-ui/lab";
import axios from "axios";
import OrderPaymentTable from "./OrderPaymentTable";
import {SERVICE_DAO_URL, NODE_ROUTE_URI} from "../../../../Utils/apiUrl";
import {getTokenFromCookie, getAccessToken} from "../../../../Utils/doToken";

import {useDispatch, useSelector} from "react-redux";
import {getBackendOrderStatusCode} from "../../../../Utils/Helper";
import {Container} from "@mui/joy";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Typography from "@mui/material/Typography";
import {Button} from "@mui/material";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {refreshOrderInSearchPage} from "../../../../actions/searchFilterAction";

const courierList = [
    {name: 'ALL', code: ''},
    {name: 'UPS', code: 'ups'},
    {name: 'Canada Post', code: 'canadaPost'},
    {name: 'Canpar', code: 'canpar'},
    {name: 'FedEx', code: 'fedex'}
]

const styles = {
    SearchOrdersSearch: {
        display: 'flex',
        gap: '10px',
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
        width: '100%',
        margin: '5px 0 10px'
    },
    OrdersSearchButtonBox: {
        backgroundColor: '#2192FF',
        borderRadius: '5px',
        ':hover': {
            opacity: '0.8',
        }
    },
    OrdersSearchButton: {
        color: '#FFFFFF'
    },
}

const AllEndUserShippingOrderPaymentList = () => {

    const [courier, setCourier] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const {register, handleSubmit, control} = useForm();
    const [isLoading, setIsLoading] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [searchString, setSearchString] = useState('package');

    const [orderList, setOrderList] = useState([]);
    const [toastOpen, setToastOpen] = useState(false);
    const accessToken = getAccessToken("access_token");

    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const dispatch = useDispatch();

    let isRefresh = useSelector(state => state?.searchFilter?.isRefresh);

    const isMobile = useMediaQuery('(max-width: 480px)');

    const fontSize = isMobile ? 10 : 14;

    const handleToastClick = () => {
        setToastOpen(true);
    };

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleCourier = (event, value) => {
        setCourier(value);
    };

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };

    const handleFilter = () => {
        console.log('filter');
        console.log('carrier', courier);
        console.log('date', startDate, endDate);
        setCurrentPage(1);
        const searchValue = (searchString.trim() === "") ? 'package' : searchString;

        let data = {
            page: 0,
            packSize: 20,
            queryString: searchValue,
            createDateBefore: startDate,
            createDateAfter: endDate,
            carrier: courier?.code
        }
        searchBackendOrders(data);
    }

    const searchBackendOrders = async (data) => {
        let requestURL = `${NODE_ROUTE_URI}/endUserShippingOrderList/searchEndUserBackendOrderShippingOrder`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                params: data
            })

            const orders = result?.data?.result;
            const totalNums = result?.data?.total
            setOrderList(orders);
            const total_page = Math.ceil(totalNums / 20);
            setTotalPage(total_page);

            // console.log(orders);

        } catch (e) {
            console.log(e.response)
        }
    }
    console.log('is refresh', isRefresh);

    useEffect(() => {
        const data = {
            page: 0,
            packSize: 20,
            queryString: 'package'
        }
        searchBackendOrders(data);

    }, [])

    const handlePage = (event, value) => {
        setCurrentPage(value);
        const data = {
            page: value - 1,
            packSize: 20,
            queryString: searchString,
            createDateBefore: startDate,
            createDateAfter: endDate,
            carrier: courier?.code
        }
        searchBackendOrders(data);
    };

    // Search for specific order
    const onSubmit = async (submitData) => {
        console.log(submitData)
        const searchValue = (submitData.search.trim() === "") ? 'package' : submitData.search;
        console.log(searchValue);
        const data = {
            page: 0,
            packSize: 20,
            queryString: searchValue
        }
        searchBackendOrders(data);
        setCurrentPage(1);
    };

    return (
        <Box paddingBottom="3rem">
            {/*Pop-up Toast*/}
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={3000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Container maxWidth='xl'>
                <Box
                    component="form"
                    width={"1000px"}
                    margin="0 auto"
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        gap: "2rem",
                        alignItems: "center",
                        alignContent: "center",
                    }}
                >
                    <h1>Search Shipping Orders</h1>
                    {/* <Link className={classes.link} to={`/v2/Partneradd`}>
            <Button color="secondary" variant="outlined">
              Add Partner
            </Button>
          </Link> */}
                    <Box flex="45%" width="100%">
                        <TextField
                            id="outlined-basic"
                            label="Search by name, address, order ID, order # and tracking #"
                            variant="outlined"
                            {...register("search")}
                            fullWidth
                        />
                    </Box>
                    <Box display="flex" sx={{gap: "3rem"}}>
                        <LoadingButton
                            variant="contained"
                            component="span"
                            color="primary"
                            style={{
                                textTransform: "unset",
                                fontSize: "1rem",
                                width: "7rem",
                            }}
                            loading={isLoading}
                            type="submit"
                            onClick={handleSubmit(onSubmit)}
                        >
                            Search
                        </LoadingButton>
                    </Box>
                </Box>
                <hr style={{marginBlock: "3rem"}}/>
                {isLoading ? (
                    <>
                        <Box>
                            <h1>Loading...</h1>
                        </Box>
                    </>
                ) : (
                    <>
                        <Box>
                            <Box>
                                <Box mt={2}>
                                    <Box sx={styles.SearchOrdersSearch}>
                                        <TextField
                                            label='Start Date'
                                            type='date'
                                            value={startDate}
                                            size={isMobile ? 'small' : 'large'}
                                            fullWidth
                                            variant='standard'
                                            onChange={handleStartDateChange}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{style: {fontSize}}}
                                        />
                                        <TextField
                                            label='End Date'
                                            type='date'
                                            value={endDate}
                                            fullWidth
                                            variant='standard'
                                            onChange={handleEndDateChange}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    min: startDate,
                                                },
                                                style: {fontSize}
                                            }}
                                        />
                                        <Autocomplete
                                            value={courier}
                                            options={courierList}
                                            fullWidth
                                            getOptionLabel={(option) => option.name}
                                            onChange={handleCourier}
                                            renderInput={(params) => {
                                                return (<TextField
                                                        ref={params.InputProps.ref}
                                                        {...params}
                                                        label="carrier"
                                                        variant="standard"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            style: {fontSize}
                                                        }}
                                                    />
                                                )
                                            }}
                                            renderOption={(option) => (
                                                <Typography sx={{fontSize}}>
                                                    {option.name}
                                                </Typography>
                                            )}
                                        />
                                        <Box sx={styles.OrdersSearchButtonBox}>
                                            <Button
                                                sx={styles.OrdersSearchButton}
                                                onClick={handleFilter}
                                            >
                                                <Typography sx={{fontSize}}>
                                                    filter
                                                </Typography>
                                            </Button>
                                        </Box>
                                    </Box>
                                    <OrderPaymentTable orderList={orderList}/>
                                    <Pagination count={totalPage} page={currentPage} onChange={handlePage}/>
                                </Box>
                            </Box>
                        </Box>
                    </>
                )}
            </Container>
        </Box>
    )
}

export default AllEndUserShippingOrderPaymentList