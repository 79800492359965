import {Checkbox, Container, Snackbar} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import packageIcon from "../../../images/homepageIcon/Package.svg";
import ltlIcon from "../../../images/homepageIcon/Pallet.svg";
import brokerageIcon from "../../../images/homepageIcon/CustomBrokerage.svg";
import PublicIcon from "@mui/icons-material/Public";
import {useHistory} from "react-router-dom";
import {useMediaQuery} from "@material-ui/core";
import {BusinessShippingPackageInternationShipmentLocation} from "./BusinessShippingPackageInternationShipmentLocation";
import {BusinessShippingPackageQuickQuotePackageInformation} from "./BusinessShippingPackageQuickQuotePackageInformation";
import {useDispatch, useSelector} from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {updateShippingPackageRateQuickQuote} from "../../../actions/BusinessShippingPackageAction";
import {isValidPostalCode} from "../../../Utils/Helper";
import MuiAlert from "@mui/material/Alert";
import * as React from "react";
import {useEffect, useState} from "react";
import {getAccessToken} from "../../../Utils/doToken";
import {ReactComponent as NorthAmericanIcon} from "../../../images/icons/Canada&USA.svg";
import {ReactComponent as InternationalIcon} from "../../../images/icons/International.svg";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingPackageQuickQuoteRoot: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        // margin: '100px 0'
    },
    ShippingPackageQuickQuoteSection: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '10px',
        display: 'flex',
        flexDirection: 'column'
    },
    ShippingPackageQuickQuoteButtonSection: {
        // backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        // borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '10px',
        display: 'flex',
        flexDirection: 'column'
    },
    ShippingPackageQuickQuoteHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
}

const requiredProperties = ["length", "width", "height", "quantity", "weight", "lengthUnit", "weightUnit"];
const validateRequiredProperties = ["length", "width", "height", "weight"];

export const BusinessShippingPackageInternationShipment = () => {

    const dispatch = useDispatch();

    const token = getAccessToken("access_token");

    const history = useHistory();

    const isMobile = useMediaQuery('(max-width: 600px)');

    let packageListRedux = useSelector(state => state?.businessShippingPackage?.quickQuotePackageList);
    let shipFromRedux = useSelector(state => state?.businessShippingPackage?.createLabelShipFrom);
    let shipToRedux = useSelector(state => state?.businessShippingPackage?.createLabelShipTo);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [locationValidation, setLocationValidation] = useState(null);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const getShippingPackageRate = async (selectedAccountBase) => {
        setLoading(true);
        let requestURL = `${PARTNER_URI}/loose-item/3rd-party/partner/rates/all`;
        let data = {
            rateData: {
                shipFromAddress: "",
                shipFromAddressTwo: "",
                shipFromCity: shipFromRedux?.city,
                shipFromProvince: shipFromRedux?.province?.code,
                shipFromPostalCode: shipFromRedux?.postalCode,
                shipFromCountry: shipFromRedux?.country,
                shipFromName: "",
                shipFromPhone: "",
                shipToAddress: "",
                shipToAddressTwo: "",
                shipToCity: shipToRedux?.city,
                shipToPostalCode: shipToRedux?.postalCode,
                shipToProvince: shipToRedux?.province?.code,
                shipToCountry: shipToRedux?.country,
                shipToName: "",
                shipToPhone: "",
                packageData: packageListRedux,
            },
            selectedAccountBase: selectedAccountBase
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            });
            const rateData = result?.data?.result;
            const statusCode = result?.data?.statusCode;
            // console.log('result data', result.data);

            if (statusCode === 200) {
                const updatedRateData = rateData.map((each) => ({
                    ...each,
                    errorAccountBase: selectedAccountBase?.toUpperCase()
                }));

                dispatch(updateShippingPackageRateQuickQuote(updatedRateData));
                history.push('/business/shipping-package/international-shipment/quote-list');
                setLocationValidation(prevState => ({
                    ...prevState,
                    missingPostalCode: false
                }))
            } else if (statusCode === 400) {
                setErrorMessage('Please including the all postal code, and try again.');
                handleErrorMessage();
                setLocationValidation(prevState => ({
                    ...prevState,
                    missingPostalCode: true
                }))
            } else {
                setErrorMessage('There is no available package rate based on the information you provided. Please ensure that you have provided valid and accurate information and try again. If you continue to experience issues, your account may be forbidden from accessing rates. Please contact op@uucargo.ca for assistance.');
                handleErrorMessage();
                setLocationValidation(prevState => ({
                    ...prevState,
                    missingPostalCode: false
                }))
            }

        } catch (e) {
            console.log('error', e.response);
        } finally {
            setLoading(false);
        }
    }

    const validate = () => {
        setErrorMessage('');
        setToastOpen(false);
        const hasMissingValues = packageListRedux.some(object => requiredProperties.some((property) => !object[property]));
        const hasValidateValues = packageListRedux.some(object => validateRequiredProperties.some((property) => (object[property]) <= 0));
        // const hasMissingValuesCrossBorder = CrossBorderHasMissingValues();
        const hasOverweightObject = packageListRedux.some(obj => {
            if (obj.weightUnit === 'kg') {
                const weightInLbs = obj.weight * 2.20462; // Convert kg to lbs
                return weightInLbs > 150;
            } else {
                return obj.weight > 150;
            }
        });
        const hasOverlengthItemName = packageListRedux?.some(obj => {
            return obj?.description?.length > 30;
        })

        const shipFromPostalCodeValid = shipFromRedux?.country?.toLowerCase() === 'ca' ? isValidPostalCode(shipFromRedux?.postalCode) : true;
        const shipToPostalCodeValid = shipToRedux?.country?.toLowerCase() === 'ca' ? isValidPostalCode(shipToRedux?.postalCode) : true;

        packageListRedux?.length === 0 && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.PACKAGE_LIST_EMPTY'));
        (!shipFromRedux?.country || ((shipFromRedux?.country?.toLowerCase() === 'ca' || shipFromRedux?.country?.toLowerCase() === 'us') && !shipFromRedux?.postalCode)) && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.SHIP_FROM_MISSING'));
        (!shipToRedux?.country || ((shipToRedux?.country?.toLowerCase() === 'ca' || shipToRedux?.country?.toLowerCase() === 'us') && !shipToRedux?.postalCode)) && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.SHIP_TO_MISSING'));
        // hasMissingValues && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.PACKAGE_LIST_MISSING'));
        hasValidateValues && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.PACKAGE_LIST_ERROR'));
        hasOverweightObject && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.OVER_WEIGHT'));
        hasOverlengthItemName && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.OVER_CHARACTER'));
        !shipFromPostalCodeValid && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.SHIP_FROM_POSTAL_CODE'));
        !shipToPostalCodeValid && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.SHIP_TO_POSTAL_CODE'));

        const validation = {
            shipFromCountry: !shipFromRedux?.country,
            shipFromPostalCode: ((shipFromRedux?.country?.toLowerCase() === 'ca' || shipFromRedux?.country?.toLowerCase() === 'us') && !shipFromRedux?.postalCode),
            shipToCountry: !shipToRedux?.country,
            shipToPostalCode: ((shipToRedux?.country?.toLowerCase() === 'ca' || shipToRedux?.country?.toLowerCase() === 'us') && !shipToRedux?.postalCode),
            shipFromPostalCodeValid: !shipFromPostalCodeValid,
            shipToPostalCodeValid: !shipToPostalCodeValid
        }

        setLocationValidation(validation);

        if (
            shipFromRedux?.country &&
            shipToRedux?.country &&
            !((shipFromRedux?.country?.toLowerCase() === 'ca' || shipFromRedux?.country?.toLowerCase() === 'us') && !shipFromRedux?.postalCode) &&
            !((shipToRedux?.country?.toLowerCase() === 'ca' || shipToRedux?.country?.toLowerCase() === 'us') && !shipToRedux?.postalCode) &&
            packageListRedux.length !== 0 &&
            !hasMissingValues &&
            !hasValidateValues &&
            !hasOverweightObject &&
            !hasOverlengthItemName &&
            shipFromPostalCodeValid &&
            shipToPostalCodeValid
        ) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const handleGetRate = () => {

        if ((shipFromRedux?.country?.toLowerCase() === 'ca' || shipFromRedux?.country?.toLowerCase() === 'us') && (shipToRedux?.country?.toLowerCase() === 'ca' || shipToRedux?.country?.toLowerCase() === 'us')) {
            setErrorMessage("In accordance with the information provided, please utilize the 'North America Package' instead of the 'International Package'.");
            handleErrorMessage();
        } else {
            const validationResult = validate();
            if (validationResult) {
                console.log('[BusinessShippingPackageInternationShipment] call api');
                getShippingPackageRate('ups');
            }
        }
    }

    console.log('[BusinessShippingPackageInternationShipment] shipFromRedux', shipFromRedux);
    console.log('[BusinessShippingPackageInternationShipment] shipToRedux', shipToRedux);
    console.log('[BusinessShippingPackageInternationShipment] packageListRedux', packageListRedux);

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box sx={styles.ShippingPackageQuickQuoteRoot}>
                <Box sx={styles.ShippingPackageQuickQuoteSection}>
                    <Typography style={styles.ShippingPackageQuickQuoteHeading}>
                        {intl.get('QUICK_QUOTE_NAV.SELECT_TYPE')}
                    </Typography>
                    <Box sx={{
                        width: isMobile ? '300px' : '100%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        flexWrap: 'wrap',
                        gap: '10px'
                    }}>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Checkbox
                                icon={<RadioButtonUncheckedIcon/>}
                                checkedIcon={<CheckCircleIcon/>}
                                color="success"
                                onClick={() => history.push('/business/shipping-package/quick-quote')}
                            />
                            <NorthAmericanIcon width="40" height="40"/>
                            <Typography style={{color: '#545454', padding: '0 5px'}}>
                                {intl.get('QUICK_QUOTE_NAV.PACKAGE')}
                            </Typography>
                        </Box>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Checkbox
                                icon={<RadioButtonUncheckedIcon/>}
                                checkedIcon={<CheckCircleIcon/>}
                                color="success"
                                checked
                            />
                            <InternationalIcon width="40" height="40"/>
                            <Typography style={{color: '#545454', padding: '0 5px'}}>
                                {intl.get('QUICK_QUOTE_NAV.INTERNATION')}
                            </Typography>
                        </Box>
                        {/*<Box sx={{display: 'flex', alignItems: 'center'}}>*/}
                        {/*    <Checkbox*/}
                        {/*        icon={<RadioButtonUncheckedIcon/>}*/}
                        {/*        checkedIcon={<CheckCircleIcon/>}*/}
                        {/*        color="success"*/}
                        {/*        onClick={() => history.push('/business/shipping-ltl/quick-quote')}*/}
                        {/*    />*/}
                        {/*    <img src={ltlIcon} alt="ltlIcon" width='40px'/>*/}
                        {/*    <Typography style={{color: '#545454', padding: '0 5px'}}>*/}
                        {/*        {intl.get('QUICK_QUOTE_NAV.LTL')}*/}
                        {/*    </Typography>*/}
                        {/*</Box>*/}
                        {/*<Box sx={{display: 'flex', alignItems: 'center'}}>*/}
                        {/*    <Checkbox*/}
                        {/*        icon={<RadioButtonUncheckedIcon/>}*/}
                        {/*        checkedIcon={<CheckCircleIcon/>}*/}
                        {/*        color="success"*/}
                        {/*        onClick={() => history.push('/business/brokerage/estimate')}*/}
                        {/*    />*/}
                        {/*    <img src={brokerageIcon} alt="brokerageIcon" width='40px'/>*/}
                        {/*    <Typography style={{color: '#545454', padding: '0 5px'}}>*/}
                        {/*        {intl.get('QUICK_QUOTE_NAV.BROKERAGE')}*/}
                        {/*    </Typography>*/}
                        {/*</Box>*/}
                    </Box>
                </Box>
                <Box sx={styles.ShippingPackageQuickQuoteSection}>
                    <BusinessShippingPackageInternationShipmentLocation locationValidation={locationValidation}/>
                </Box>
                <Box sx={styles.ShippingPackageQuickQuoteSection}>
                    <BusinessShippingPackageQuickQuotePackageInformation packageListRedux={packageListRedux}
                                                                         isGetRate={false}/>
                </Box>
            </Box>
            {
                errorMessage && (((shipFromRedux?.country?.toLowerCase() === 'ca' || shipFromRedux?.country?.toLowerCase() === 'us') && !shipFromRedux?.postalCode) || ((shipToRedux?.country?.toLowerCase() === 'ca' || shipToRedux?.country?.toLowerCase() === 'us') && !shipToRedux?.postalCode)) &&
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '20px'
                }}>
                    <Typography style={{
                        color: '#FF0303'
                    }}>
                        {errorMessage}
                    </Typography>
                </Box>
            }
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '50px'
            }}>
                <LoadingButton
                    variant='contained'
                    sx={{
                        backgroundColor: '#1D8B45',
                        "&:hover": {
                            backgroundColor: '#1D8B45',
                            filter: 'brightness(0.9)'
                        }
                    }}
                    onClick={handleGetRate}
                    loading={loading}
                >
                    <Typography style={{textTransform: 'none'}}>
                        {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.GET_RATES')}
                    </Typography>
                </LoadingButton>
            </Box>
        </Container>
    )
}