import {useHistory} from "react-router-dom";
import {useState} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import {Button} from "@mui/material";
import wechatPay from "../../images/wechatPay.svg";
import aliPay from "../../images/alipay.svg";
import {BillingPaymentWeChat} from "./BillingPaymentWeChat";
import {BillingPaymentAliPay} from "./BillingPaymentAliPay";

const styles = {
    BillingPaymentOtherRoot: {
        backgroundColor: '#FFFFFF',
        borderRadius: '0 0 8px 8px',
        padding: '15px 30px 40px',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    }
}

export const BillingPaymentOther = ({cbResetPaymentMethod, totalAmount, selectInvoice}) => {

    const history = useHistory();

    const [selectOtherPayment, setSelectOtherPayment] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    const handleSelectOtherPayment = (method) => {
        setSelectOtherPayment(method);
    }

    if (isLoading) {
        return <>
            <CircularProgress/>
        </>
    }

    if (error && !isLoading) {
        return <>
            <h3>Sorry We currently encounter some issues, please try again later.</h3>
        </>
    }

    console.log('select invoice', selectInvoice);

    return (
        <Box sx={styles.BillingPaymentOtherRoot}>
            <Grid container spacing={4}>
                <Grid item md={4} xs={6}>
                    <Box sx={{
                        position: 'relative',
                        height: '100px',
                        width: '120px'
                    }}>
                        {
                            selectOtherPayment === 'wechatPay' &&
                            <CheckCircleIcon
                                style={{
                                    position: 'absolute',
                                    right: '-5%',
                                    top: '-10%',
                                    zIndex: 1,
                                    color: '#1D8B45',
                                    backgroundColor: '#ffffff',
                                    borderRadius: '50%'
                                }}
                            />
                        }
                        <LoadingButton
                            sx={{
                                border: selectOtherPayment === 'wechatPay' ? '2px solid #1D8B45' : '2px solid #A6A6A6',
                                width: '100%',
                                height: '100%',
                                padding: '15px 0px',
                                overflow: 'visible',
                                position: 'relative',
                                borderRadius: '10px',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '10px'
                            }}
                            onClick={() => handleSelectOtherPayment('wechatPay')}
                        >
                            <img src={wechatPay} alt="wechatPayLogo" width={50}/>
                            <Typography sx={{
                                color: '#000000',
                                fontSize: '12px',
                                textTransform: 'none',
                            }}>
                                WeChat Pay
                            </Typography>
                        </LoadingButton>
                    </Box>
                </Grid>
                <Grid item md={4} xs={6}>
                    <Box sx={{
                        position: 'relative',
                        height: '100px',
                        width: '120px'
                    }}>
                        {
                            selectOtherPayment === 'aliPay' &&
                            <CheckCircleIcon
                                style={{
                                    position: 'absolute',
                                    right: '-5%',
                                    top: '-10%',
                                    zIndex: 1,
                                    color: '#1D8B45',
                                    backgroundColor: '#ffffff',
                                    borderRadius: '50%'
                                }}
                            />
                        }
                        <LoadingButton
                            sx={{
                                border: selectOtherPayment === 'aliPay' ? '2px solid #1D8B45' : '2px solid #A6A6A6',
                                width: '100%',
                                height: '100%',
                                padding: '15px 0px',
                                overflow: 'visible',
                                position: 'relative',
                                borderRadius: '10px',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '10px'
                            }}
                            onClick={() => handleSelectOtherPayment('aliPay')}
                        >
                            <img src={aliPay} alt="wechatPayLogo" width={50}/>
                            <Typography sx={{
                                color: '#000000',
                                fontSize: '12px',
                                textTransform: 'none',
                            }}>
                                Ali Pay
                            </Typography>
                        </LoadingButton>
                    </Box>
                </Grid>
                {/*<Grid item md={4} xs={6}>*/}
                {/*    <Box sx={{*/}
                {/*        position: 'relative',*/}
                {/*        height: '100px',*/}
                {/*        width: '120px'*/}
                {/*    }}>*/}
                {/*        {*/}
                {/*            selectOtherPayment === 'paypal' &&*/}
                {/*            <CheckCircleIcon*/}
                {/*                sx={{*/}
                {/*                    position: 'absolute',*/}
                {/*                    right: '-5%',*/}
                {/*                    top: '-10%',*/}
                {/*                    zIndex: 1,*/}
                {/*                    color: '#1D8B45',*/}
                {/*                    backgroundColor: '#ffffff',*/}
                {/*                    borderRadius: '50%'*/}
                {/*                }}*/}
                {/*            />*/}
                {/*        }*/}
                {/*        <LoadingButton*/}
                {/*            sx={{*/}
                {/*                border: selectOtherPayment === 'paypal' ? '2px solid #1D8B45' : '2px solid #A6A6A6',*/}
                {/*                width: '100%',*/}
                {/*                height: '100%',*/}
                {/*                padding: '15px 0px',*/}
                {/*                overflow: 'visible',*/}
                {/*                position: 'relative',*/}
                {/*                borderRadius: '10px',*/}
                {/*                display: 'flex',*/}
                {/*                flexDirection: 'column',*/}
                {/*                gap: '10px'*/}
                {/*            }}*/}
                {/*            onClick={() => handleSelectOtherPayment('paypal')}*/}
                {/*        >*/}
                {/*            <img src={'https://storage.googleapis.com/uucargo-resource/image/icon/paypal.svg'}*/}
                {/*                 alt="PayPalLogo" width={50}/>*/}
                {/*            <Typography sx={{*/}
                {/*                color: '#000000',*/}
                {/*                fontSize: '12px',*/}
                {/*                textTransform: 'none',*/}
                {/*            }}>*/}
                {/*                PayPal*/}
                {/*            </Typography>*/}
                {/*        </LoadingButton>*/}
                {/*    </Box>*/}
                {/*</Grid>*/}
            </Grid>
            {
                selectOtherPayment === 'wechatPay' && <BillingPaymentWeChat selectInvoice={selectInvoice}
                                                                            totalAmount={totalAmount}/>
            }
            {
                selectOtherPayment === 'aliPay' && <BillingPaymentAliPay selectInvoice={selectInvoice}
                                                                         totalAmount={totalAmount}/>
            }
            {/*{*/}
            {/*    selectOtherPayment === 'paypal' &&*/}
            {/*    <BusinessBillingPaymentPayPal totalAmount={totalAmount} selectInvoice={selectInvoice}/>*/}
            {/*}*/}
            {
                selectOtherPayment &&
                <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: '10px'}}>
                    <Button
                        variant='contained'
                        sx={{
                            backgroundColor: '#F2BE22',
                            "&:hover": {
                                backgroundColor: '#F2BE22',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        onClick={cbResetPaymentMethod}
                    >
                        <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                            Back
                        </Typography>
                    </Button>
                    <LoadingButton
                        variant='contained'
                        sx={{
                            backgroundColor: '#1D8B45',
                            "&:hover": {
                                backgroundColor: '#1D8B45',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        onClick={() => window.location.reload()}
                    >
                        <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                            Confirm Already Paid
                        </Typography>
                    </LoadingButton>
                </Box>
            }
        </Box>
    )
}