import React, { useEffect, useState } from "react";
import ShipmentDetailLayout from "../Layouts/ShipmentDetailLayout";
import { SummarySection } from "./SummarySection";
import { useParams } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import { driverClearAllShipmentsFromSummary } from "../../actions/driverClaimShipmentAction";
import { makeStyles } from "@material-ui/core";
import LoadingCircle from "../LoadingCircle";
import axios from "axios";
import { getTokenFromCookie } from "../../Utils/doToken";
import { GET_SHIPMENTS_BY_ID_API } from "../../Utils/apiUrl";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#111",
    fontFamily: "Helvetica Neue, sans-serif",
    fontSize: "30px",
    fontWeight: "bold",
    letterSpacing: "-1px",
    lineHeight: 1,
    margin: "5px 2%",
  },
}));

const ShipmentDetailPage = ({ claimSummary }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loadShipments, setloadShipments] = useState(true);
  const [displaySummary, setDisplaySummary] = useState(false);

  const [shipmentInfo, setShipmentInfo] = useState([]);

  const shipment_number = useParams().id;
  const stage = useParams().stage;

  const reflashCallback = (action) => {
    if (action === true) {
      fetchShipmentInfo();
    }
  };

  const fetchShipmentInfo = () => {
    //fetch shipment and its package
    setloadShipments(true);
    try {
      axios
        .get(GET_SHIPMENTS_BY_ID_API, {
          params: {
            shipment_number: shipment_number,
            stage: stage,
          },
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
          },
        })
        .then((res) => {
          setShipmentInfo(res.data);
          //setOrderNumber(res.data.order_number)
          setloadShipments(false);
        });
    } catch (error) {
      setShipmentInfo([]);
      setloadShipments(false);
    }
  };

  useEffect(() => {
    fetchShipmentInfo();

    //clean up summary
    return () => {
      dispatch(driverClearAllShipmentsFromSummary());
    };
  }, [shipment_number]);

  return (
    <>
      {!loadShipments ? (
        <ShipmentDetailLayout shipmentInfo={shipmentInfo} />
      ) : (
        <LoadingCircle />
      )}
      {/* {
      (!loadOtherShipments && otherShipments.length > 0) && <Typography className={classes.title}>Other Shipments</Typography>
    }
    {
      !loadOtherShipments ?
        otherShipments.map(shipment => <ShipmentDetailLayout packageInfo={shipment.package} shipmentInfo={shipment}/>)
      :
        <LoadingCircle/>
    }   */}
      {claimSummary.driver_claimed_shipments.length > 0 && (
        <SummarySection
          sectionTitle={"Summary"}
          packages={claimSummary.driver_claimed_shipments}
          reflash={(action) => reflashCallback(action)}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    claimSummary: state.driver_claim_summary,
  };
};

export default connect(mapStateToProps)(ShipmentDetailPage);
