import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {BrokerageEstimateItem} from "./BrokerageEstimateItem";
import uuCargo_logo from "../../../images/logo.png";
import {useEffect, useRef, useState} from "react";
import Grid from "@material-ui/core/Grid";
import ExcelJS from 'exceljs';
import {saveAs} from 'file-saver';
import Button from "@mui/material/Button";

export const BrokerageEstimate = ({brokerageEstimate, packingListDetails, brokerageData, fromPackingList=false}) => {

    const splitString = (value) => {
        const parts = value.split("_");
        return parts[0];
    }

    const currencyFormat = (num) => {
        return '$' + num?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    const createExcelData = () => {

        // console.log(brokerageEstimate)

        // Create a new workbook
        const workbook = new ExcelJS.Workbook();

        // Create worksheet and data
        brokerageEstimate.forEach((element) => {

            // Create a worksheet

            if (element.serviceName !== 'PDT') {

                const worksheetSummary = workbook.addWorksheet(`${element.serviceName} Summary`);
                const worksheetDetails = workbook.addWorksheet(`${element.serviceName} Details`);

                const data = [
                    ['Duty and Tax', element?.totalDutyandTax],
                    ['Brokerage', element?.totalBrokerageFees]
                ];

                data.forEach(row => {
                    worksheetSummary.addRow(row);
                });

                element?.surcharges?.forEach(row => {
                    worksheetSummary.addRow([row?.chargeName, row?.chargeAmount])
                })

                // Add the data to the worksheet
                worksheetDetails.columns = [
                    {header: 'Item Name', key: 'ItemName'},
                    {header: 'Commodity Duty', key: 'CommodityDuty'},
                    {header: 'Total Commodity Taxes And Fess', key: 'totalCommodityTaxesAndFees'},
                    {header: 'Error', key: 'error'}
                ];

                // Set the column width
                worksheetSummary.getColumn(1).width = 30;
                worksheetSummary.getColumn(2).width = 30;
                worksheetDetails.getColumn(1).width = 30;
                worksheetDetails.getColumn(2).width = 30;
                worksheetDetails.getColumn(3).width = 30;
                worksheetDetails.getColumn(4).width = 30;

                // Set the number format of the columns to currency format
                worksheetSummary.getColumn(2).numFmt = '$#,##0.00';
                worksheetDetails.getColumn(2).numFmt = '$#,##0.00';
                worksheetDetails.getColumn(3).numFmt = '$#,##0.00'

                element?.shipmentItems?.forEach(row => {
                    worksheetDetails.addRow([splitString(row?.commodityId), row?.totalCommodityDutyandTax, row?.totalCommodityTaxesAndFees, 'N/A'])
                })

                element?.error !== null && element?.error?.errors.forEach(row => {
                    row?.code !== '105.001' && worksheetDetails.addRow([row?.commodityId, 'N/A', 'N/A', row?.message])
                })
            }

        })

        console.log(packingListDetails);

        // add shipmentData worksheet
        const worksheet = workbook.addWorksheet('shipmentData');

        // add columns
        worksheet.columns = [
            {header: 'itemName', key: 'itemName'},
            {header: 'itemDescription', key: 'itemDescription'},
            {header: 'hscode', key: 'hscode'},
            {header: 'quantity', key: 'quantity'},
            {header: 'unitPrice', key: 'unitPrice'},
            {header: 'currency', key: 'currency'},
            {header: 'length', key: 'length'},
            {header: 'width', key: 'width'},
            {header: 'height', key: 'height'},
            {header: 'dimensionUnit', key: 'dimensionUnit'},
            {header: 'weight', key: 'weight'},
            {header: 'weightUnit', key: 'weightUnit'},
            {header: 'originalCountryCode', key: 'originalCountryCode'},
            {header: 'Unit of Measurement', key: 'UOM'}
        ];

        // set columns width
        for (let i = 0; i < worksheet.columns.length; i++) {
            worksheet.getColumn(i + 1).width = 30;
        }

        // add values
        packingListDetails?.forEach(row => {
            worksheet.addRow([
                row?.item_name,
                row?.item_desc,
                Number(row?.hs_code),
                row?.quantity,
                row?.unit_price,
                row?.currency_code,
                row?.length,
                row?.width,
                row?.height,
                row?.dimension_unit,
                row?.weight,
                row?.weight_unit,
                row?.origi_country,
                row?.uom
            ])
        })

        // Generate the Excel file
        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            saveAs(blob, 'BrokerageCostEstimate.xlsx');
        });
    }

    const handleDownload = () => {
        // Call the createExcelData function to generate the Excel file
        createExcelData();
    };

    const logos = [
        uuCargo_logo,
        uuCargo_logo
    ]

    const CostSection = useRef(null);

    const scrollDown = (ref) => {
        // console.log(ref.current.offsetTop)
        window.scrollTo({
            top: ref.current.offsetTop - 100,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        scrollDown(CostSection)
    }, [brokerageEstimate])

    console.log(brokerageEstimate);

    return (
        <Box sx={{margin: '50px auto', backgroundColor: '#ffffff', borderRadius: '10px', padding: '20px 0'}}
             ref={CostSection}>
            <Typography variant="h6">
                Estimate Cost Detail
            </Typography>
            {packingListDetails && <Button onClick={handleDownload}>Download Cost</Button>}
            <Box sx={{flexGrow: 1, marginTop: '20px'}}>
                <Grid container spacing={1}>
                    <Grid item xs={2}>
                        <Typography variant="h8">
                        </Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                            <Typography variant="h8">
                                Estimate Fee
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                            <Typography variant="h8">
                                Action
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            {brokerageEstimate.map((value, index) => {
                return <BrokerageEstimateItem
                    data={value}
                    key={index}
                    logo={logos[index]}
                    brokerageData={brokerageData}
                    fromPackingList={fromPackingList}
                />

            })}
        </Box>
    )

}