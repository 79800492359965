import React, { useState } from "react";
// import { useSelector } from "react-redux";
import { Box, makeStyles } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
// import useMediaQuery from '@material-ui/core/useMediaQuery';
// import { Alert } from '@material-ui/lab';

import PropTypes from "prop-types";

// import nextId from "react-id-generator";
import { CustomInput } from "../../../shared";
import HscodeInputField from "../bolInputField";

const HscodeAddressInput = ({ shippingInfo, emptyError, handleItemChange }) => {
  // const matches = useMediaQuery("(max-width:600px)");
  // const shippingItems = useSelector((state) => state.shipping.shippingItems);

  // const [showError, setShowErrorState] = useState(false);
  const classes = useStyles();

  // const matches = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const {
    shipperName,
    shipperAddress,
    carrierAgent,
    consigneeName,
    consigneeAddress,
    deliveryAgent,
    placeOfAcceptance,
    preCarriage,
    numberOfOriginal,
    payableAt,
    excessValueDeclaration,
    vessel,
    portOfLoading,
    portOfDischarge,
    placeOfDelivery,
    onCarriageto,
    onBoardDate,
    issueDate,
    issuePlace,
    nofifyPartyName,
    nofifyPartyAddress,
  } = shippingInfo;

  return (
    <Box>
      <Box color="orange" mb="1vh" textAlign="center">
        <h1>Where are you shipping?</h1>
      </Box>

      <Box display="flex" className={classes.addressComponent}>
        <Box pr={1} flexGrow="1" className={classes.leftAddress}>
          <Box mr={1}>
            <HscodeInputField
              error={emptyError && shipperName === ""}
              value={shipperName}
              title="Shipper Name"
              mandatory={true}
              onChange={handleItemChange("shipperName")}
            />
          </Box>
          <Box mr={1}>
            <HscodeInputField
              error={emptyError && shipperAddress === ""}
              value={shipperAddress}
              title="Shipper Addresse"
              mandatory={true}
              onChange={handleItemChange("shipperAddress")}
              placeholderValue="Full Address"
              rows={3}
              multiline
            />
          </Box>
          <Box mr={1}>
            <HscodeInputField
              error={emptyError && carrierAgent === ""}
              value={carrierAgent}
              title="Carrier Agent"
              mandatory={true}
              onChange={handleItemChange("carrierAgent")}
            />
          </Box>
        </Box>

        <Box pl={2} flexGrow="1">
          <Box mr={1}>
            <HscodeInputField
              error={emptyError && consigneeName === ""}
              value={consigneeName}
              title="Consignee Name"
              mandatory={true}
              onChange={handleItemChange("consigneeName")}
            />
          </Box>
          <Box mr={1}>
            <HscodeInputField
              error={emptyError && consigneeAddress === ""}
              value={consigneeAddress}
              title="Consignee Address"
              mandatory={true}
              onChange={handleItemChange("consigneeAddress")}
              placeholderValue="Full Address"
              rows={3}
              multiline
            />
          </Box>
          <Box mr={1}>
            <HscodeInputField
              error={emptyError && deliveryAgent === ""}
              value={deliveryAgent}
              title="Delivery Agent"
              mandatory={true}
              onChange={handleItemChange("deliveryAgent")}
            />
          </Box>
        </Box>
      </Box>

      <Box display="flex" mt={3} className={classes.addressComponent}>
        <Box flexBasis="50%">
          <HscodeInputField
            value={nofifyPartyName}
            title="Notify Party Name"
            onChange={handleItemChange("nofifyPartyName")}
          />
        </Box>
        <Box ml={4} mr={1} flexBasis="50%">
          <HscodeInputField
            value={nofifyPartyAddress}
            title="Notify Party Address"
            onChange={handleItemChange("nofifyPartyAddress")}
            placeholderValue="Full Address"
            rows={3}
            multiline
          />
        </Box>
      </Box>

      <Box display="flex" mt={3}>
        <Box flexBasis="50%">
          <HscodeInputField
            value={preCarriage}
            title="Pre-carriage by"
            onChange={handleItemChange("preCarriage")}
          />
        </Box>
        <Box ml={4} mr={1} flexBasis="50%">
          <div>Place of Acceptance</div>
          <CustomInput
            value={placeOfAcceptance}
            onChange={handleItemChange("placeOfAcceptance")}
          />
        </Box>
      </Box>

      <Box display="flex" mt={2}>
        <Box flexBasis="50%" mr={4}>
          <HscodeInputField
            value={numberOfOriginal}
            title="Number of original"
            onChange={handleItemChange("numberOfOriginal")}
          />
        </Box>
        <Box mr={1} flexBasis="50%">
          <div>Freight & charges payable at</div>
          <CustomInput
            value={payableAt}
            onChange={handleItemChange("payableAt")}
            // error={userInputError.emtpyError && payableAt === ""}
          />
        </Box>
      </Box>

      <Box display="flex" mt={2}>
        <Box mr={1} flexBasis="50%">
          <div>Excess Value Declaration</div>
          <CustomInput
            value={excessValueDeclaration}
            onChange={handleItemChange("excessValueDeclaration")}
          />
        </Box>

        <Box mr={1} ml={4} flexGrow="1" flexBasis="50%">
          <div>Vessel / Voyage</div>
          <CustomInput
            value={vessel}
            onChange={handleItemChange("vessel")}
            // error={userInputError.emtpyError && vessel === ""}
          />
        </Box>
      </Box>

      <Box display="flex" mt={2}>
        <Box flexBasis="50%" mr={4}>
          <div>Port of Loading</div>
          <CustomInput
            value={portOfLoading}
            onChange={handleItemChange("portOfLoading")}
            // error={userInputError.emtpyError && portOfLoading === ""}
            placeholderValue="E.g., Yantian, China"
          />
        </Box>

        <Box mr={1} flexBasis="50%">
          <div>Port of Discharge</div>
          <CustomInput
            value={portOfDischarge}
            onChange={handleItemChange("portOfDischarge")}
            // error={userInputError.emtpyError && portOfDischarge === ""}
            placeholderValue="E.g., Vancouver, BC"
          />
        </Box>
      </Box>

      <Box display="flex" mt={2}>
        <Box flexBasis="50%" mr={4}>
          <div>Place of Delivery</div>
          <CustomInput
            value={placeOfDelivery}
            onChange={handleItemChange("placeOfDelivery")}
            // error={userInputError.emtpyError && placeOfDelivery === ""}
            placeholderValue="E.g., Vancouver, BC"
          />
        </Box>

        <Box mr={1} flexBasis="50%">
          <div>On Carriage to</div>
          <CustomInput
            value={onCarriageto}
            onChange={handleItemChange("onCarriageto")}
            // error={userInputError.emtpyError && onCarriageto === ""}
            placeholderValue="E.g., Vancouver, BC"
          />
        </Box>
      </Box>

      <Box display="flex" mt={2}>
        <Box mr={4} flexBasis="50%">
          <div>Date of On Board</div>
          <CustomInput
            value={onBoardDate}
            onChange={handleItemChange("onBoardDate")}
            // error={userInputError.emtpyError && onBoardDate === ""}
          />
        </Box>

        <Box mr={1} flexBasis="50%">
          <Box mb={2}>
            <div>Date of Issue</div>
            <CustomInput
              value={issueDate}
              onChange={handleItemChange("issueDate")}
              // error={userInputError.emtpyError && issueDate === ""}
            />
          </Box>

          <Box>
            <div>Place of Issue</div>
            <CustomInput
              value={issuePlace}
              onChange={handleItemChange("issuePlace")}
              // error={userInputError.emtpyError && issuePlace === ""}
            />
          </Box>
        </Box>

        {/* <Box mr={1} flexBasis="50%">
          <div>Freight Details, Charges etc *</div>
          <CustomInput
            value={onBoardDate}
            onChange={handleItemChange("freightDetails")}
            error={userInputError.emtpyError && freightDetails === ""}
            multiline
            rows={3}
          />
          <Box
            pl={1}
            color="red"
            visibility={
              userInputError.emtpyError && freightDetails === ""
                ? "visible"
                : "hidden"
            }
          >
            Cannot be emtpy
          </Box>
        </Box> */}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  leftAddress: {
    borderRight: "1px solid rgba(0,0,0,0.3)",
  },
  addressComponent: {
    borderBottom: "1px solid rgba(0,0,0,0.3)",
  },
}));

HscodeAddressInput.propTypes = {
  shippingInfo: PropTypes.object.isRequired,
  emptyError: PropTypes.bool.isRequired,
  handleItemChange: PropTypes.func.isRequired,
};

export default HscodeAddressInput;
