import { STACK_SHIPMENTS, FETCHING_SHIPMENTS, SELECT_SHIPMENT } from './types'

export const stackShipments = (shipments) => {
  return{
    type:STACK_SHIPMENTS,
    payload: shipments
  }
}

export const fetchingShipments = (isFetching) => {
  return {
    type:FETCHING_SHIPMENTS,
    fetching:isFetching
  }
}

export const selectShipment = (shipmentID) => {
  return {
    type:SELECT_SHIPMENT,
    payload:{
      selected_shipment_id: shipmentID
    }
  }
}
