import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import {styled} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import axios from "axios";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import {getAccessToken} from "../../Utils/doToken";
import WechatLogo from "../../images/wechatpay_logo.png";
import {Box, CircularProgress} from "@material-ui/core";
import {useHistory} from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const {children, onClose, ...other} = props;

    return (
        <DialogTitle sx={{m: 0, p: 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                ></IconButton>
            ) : null}
        </DialogTitle>
    );
}

const WechatPaymentInvoiceDialog = ({open, handleClose, crmInvoiceNumber, crmRefNumber}) => {

    const [qrCodeImage, setQrCodeImage] = useState("");
    const [amount, setAmount] = useState(0);
    const [payCheckLink, setPayCheckLink] = useState("#");
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (open) {
            (async () => {
                try {
                    setIsLoading(true);
                    const storedToken = getAccessToken("access_token");
                    const {data} = await axios.post(`${NODE_ROUTE_URI}/billingCenter/initPayCheckByCRMInvoiceNumber`, {
                        crmInvoiceNumber,
                        payType: "wechatpay"
                    }, {
                        headers: {
                            Authorization: `Bearer ${storedToken}`,
                        },
                    });
                    console.log("init pay check by crm invoice number:", data);
                    const {qrcode_image, total_amount, pay_url} = data;
                    setQrCodeImage(qrcode_image);
                    setAmount((+total_amount / 100));
                    setPayCheckLink(pay_url);
                } catch (e) {
                    setQrCodeImage("");
                    setAmount(0);
                    setPayCheckLink("#");
                    console.log(e);
                } finally {
                    setIsLoading(false);
                }
            })();
        }
    }, [open]);

    if (isLoading) {
        return <>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                >
                    Wechat Pay
                </BootstrapDialogTitle>
                <DialogContent
                    dividers
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "540px",
                        fontSize: "20px"
                    }}
                >
                    <CircularProgress/>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Done
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </>
    }

    if (qrCodeImage === "" && !isLoading) {
        return <>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                >
                    Wechat Pay
                </BootstrapDialogTitle>
                <DialogContent
                    dividers
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "540px",
                        fontSize: "20px"
                    }}
                >
                    <h3>Sorry We currently encounter some issues, please try again later.</h3>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Done
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </>
    }

    return <>
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <BootstrapDialogTitle
                id="customized-dialog-title"
                onClose={handleClose}
            >
                Wechat Pay
            </BootstrapDialogTitle>
            <DialogContent
                dividers
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "540px",
                    fontSize: "20px"
                }}
            >
                <Box className="content" display="flex" flexDirection="column" justifyContent="center"
                     alignContent="center">
                    <Box display="flex" flexDirection="column" justifyContent="center" alignContent="center"
                         alignItems="center" width="100%">
                        <img className="title_logo" src={WechatLogo} alt="wechatpay" height={56} width={180}/>
                    </Box>
                    <Box display="flex" justifyContent="center" alignItems="center" textAlign="center">
                        <img id="chekpayPaymentQRCode"
                             src={qrCodeImage}
                             alt="chekpayPaymentQRCode"/>
                    </Box>
                    <Box className="currency_container" display="flex" justifyContent="center" alignContent="center">
                        <span className="local_currency">Amount: ${amount} CAD</span>
                    </Box>
                    <hr/>
                    <Box className="currency_container" display="flex" flexDirection="column" justifyContent="center">
                        <Box width="400px" display="flex" justifyContent="space-between" flexDirection="row">
                    <span>
                      Receiver
                    </span>
                            <span>
                      UU CARGO LTD.
                    </span>
                        </Box>
                        {/*<Box width="400px" display="flex" justifyContent="space-between" flexDirection="row">*/}
                        {/*  <span>*/}
                        {/*    CRM Invoice Number*/}
                        {/*  </span>*/}
                        {/*  <span>*/}
                        {/*    {crmInvoiceNumber}*/}
                        {/*  </span>*/}
                        {/*</Box>*/}
                        {/*<Box width="400px" display="flex" justifyContent="space-between" flexDirection="row">*/}
                        {/*  <span>*/}
                        {/*    CRM Ref Number*/}
                        {/*  </span>*/}
                        {/*  <span>*/}
                        {/*    {crmRefNumber}*/}
                        {/*  </span>*/}
                        {/*</Box>*/}
                        {/*<table className="payment_info_table">*/}
                        {/*  <tbody>*/}
                        {/*  <tr>*/}
                        {/*    <td className="row_title">Receiver</td>*/}
                        {/*    <td className="row_content">UU CARGO LTD.</td>*/}
                        {/*  </tr>*/}
                        {/*  <tr>*/}
                        {/*    <td className="row_title">CRM Invoice Number</td>*/}
                        {/*    <td className="row_content">{crmInvoiceNumber}</td>*/}
                        {/*  </tr>*/}
                        {/*  <tr>*/}
                        {/*    <td className="row_title">CRM Ref Number</td>*/}
                        {/*    <td className="row_content">{crmRefNumber}</td>*/}
                        {/*  </tr>*/}
                        {/*  </tbody>*/}
                        {/*</table>*/}
                    </Box>
                    <h5>
                        <span>Or Click this Link to pay <a href={payCheckLink} target="_blank"
                                                           rel="noopener noreferrer">CheckPay</a></span>
                    </h5>
                </Box>
            </DialogContent>
            <DialogActions>
                <Box display="flex" width="100%" justifyContent="space-between">
                    <Button variant="contained" color="success" onClick={() => {
                        window.location.reload()
                    }
                    }>
                        I already paid, refresh page
                    </Button>
                    <Button autoFocus onClick={handleClose}>
                        Cancel
                    </Button>
                </Box>
            </DialogActions>
        </BootstrapDialog>
    </>
}

export default WechatPaymentInvoiceDialog;