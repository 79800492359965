import {Divider, useMediaQuery} from "@mui/material";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {BusinessCrossBorderDashboardTrackingItem} from "./BusinessCrossBorderDashboardTrackingItem";
import CircularProgress from "@mui/material/CircularProgress";

export const BusinessCrossBorderDashboardTrackingList = ({
                                                     shippingListData,
                                                     trackingList,
                                                     cbGetTrackingList,
                                                     errorGetTrackList,
                                                     loadingGetTrackList
                                                 }) => {

    const isMobile = useMediaQuery('(max-width: 480px)');

    if (loadingGetTrackList) {
        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                border: '1px solid #B2B2B2',
                borderRadius: '5px',
                padding: '15px',
            }}>
                <Typography style={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                    Package
                </Typography>
                <Divider/>
                <Box>
                    <CircularProgress/>
                </Box>
            </Box>
        )
    }

    if (!loadingGetTrackList && errorGetTrackList) {
        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                border: '1px solid #B2B2B2',
                borderRadius: '5px',
                padding: '15px',
            }}>
                <Typography style={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                    Package
                </Typography>
                <Divider/>
                <Typography style={{fontSize: '16px'}}>
                    Sorry We currently encounter some issues, please try again later.
                </Typography>
            </Box>
        )
    }

    return (
        <Box sx={{
            border: '1px solid #B2B2B2',
            borderRadius: '10px',
            padding: '20px'
        }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography style={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                        Package
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                {
                    !isMobile &&
                    <>
                        <Grid item xs={3}>
                            <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                                Information
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                                Dimension & Weight
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                                Invoice Status
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                        </Grid>
                        <Grid item xs={1}>
                        </Grid>
                        <Grid item xs={1}>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                    </>
                }
                <Grid item xs={12}>
                    <Grid container spacing={isMobile ? 0 : 2}>
                        {
                            trackingList?.map((element, index) => <BusinessCrossBorderDashboardTrackingItem key={index}
                                                                                                            trackingItem={element}
                                                                                                            cbGetTrackingList={cbGetTrackingList}/>)
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}