import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    extractFullAddress,
    getMissingKeysForBecomePartner,
    isValidateEmail,
    isValidatePassword,
    isValidatePhone,
    provinces_states
} from "../../Utils/Helper";
import {Checkbox, InputAdornment, Link, Snackbar, useMediaQuery} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {HelpOutline} from "@material-ui/icons";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import * as React from 'react';
import MuiAlert from "@mui/material/Alert";
import {getAccessToken} from "../../Utils/doToken";
import {MuiTelInput} from 'mui-tel-input'
import intl from "react-intl-universal";
import Grid from "@mui/material/Grid";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@mui/material/Paper";
import FormHelperText from "@mui/material/FormHelperText";
import LoadingButton from "@mui/lab/LoadingButton";

const businessTypeOption = [
    "E-Commerce",
    "Logistic Transportation",
    "Warehouse",
    "Solution Provider",
    "Other"
]

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    UserSignUpRoot: {
        // width: '500px',
        // maxWidth: '500px',
        width: '50%',
        margin: '0 auto 20px',
        '@media (max-width: 1024px)': {
            width: '100%', // for tablets and above
        },
        '@media (max-width: 480px)': {
            width: '100%', // for phone and above
        },
    },
    UserSignUpContainer: {
        marginTop: '50px',
        gap: '10px',
        padding: '30px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        '@media (max-width: 480px)': {
            padding: 0, // for phone and above
        },
    },
    UserSignUpIntro: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
    },
    UserSignUpHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left',
        WebkitTextStroke: '0.5px black', // For Webkit browsers
    },
    UserSignUpText: {
        textAlign: 'left',
        fontSize: '14px',
        color: '#000000'
    },
    UserSignUpContent: {
        width: '100%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        padding: '30px',
        backgroundColor: '#FFFFFF',
        borderRadius: '10px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
    },
    UserSignUpWarningText: {
        fontSize: '12px',
        color: '#FF0303'
    },
    UserSignUpResultTextSuccess: {
        fontSize: '14px',
        color: '#539165'
    },
    UserSignUpResultTextFail: {
        fontSize: '14px',
        color: '#FF0303'
    },
    UserSignUpCoupon: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: 0
    },
    UserSignUpCouponImage: {
        height: '500px'
    },
    UserSignUpButtonBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    UserSignUpButton: {
        width: '100%',
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'green',
            opacity: '0.8'
        }
    },
    UserSignUpHaveAccount: {
        display: 'flex',
        gap: '5px',
        justifyContent: 'center'
    },
    UserSignUpPrivacyAndTerms: {
        display: 'flex',
        alignItems: 'flex-start',
        gap: '10px'
    },
    UserSignUpCheckbox: {
        padding: 0,
        color: 'green',
        '&.Mui-checked': {
            color: 'green'
        }
    },
    UserSignUpPrivacyAndTermsText: {
        textAlign: 'left',
        fontSize: '12px'
    },
    UserSignUpPrivacyAndTermsLink: {
        color: 'green',
        textDecoration: 'underline green'
    }
}

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "4px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "14px", // Adjust the font size as per your preference
        }
    },
    SignInButton: {
        color: 'white',
        backgroundColor: 'green',
        width: '100%',
        margin: '0 auto',
        '&:hover': {
            backgroundColor: 'green',
            opacity: '0.8'
        }
    },
});

export const BusinessUserSignUp = () => {

    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [sendEmail, setSendEmail] = useState();
    const [sendStatus, setSendStatus] = useState(false);
    const [loading, setLoading] = useState(false);

    const [companyEmail, setCompanyEmail] = useState();
    const [companyName, setCompanyName] = useState();
    const [companyPhone, setCompanyPhone] = useState();
    const [postalCode, setPostalCode] = useState();
    const [province, setProvince] = useState();
    const [contactName, setContactName] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();

    const [contactEmail, setContactEmail] = useState();
    const [password, setPassword] = useState();
    const [companyType, setCompanyType] = useState();
    const [coupon, setCoupon] = useState();
    const [companyAddress, setCompanyAddress] = useState({
        postalCode: null,
        province: null,
        city: null,
        address: null,
        address2: null,
        country: null,
        displayAddress: {
            description: null,
            placeId: null,
            text: null
        },
    });
    const [shipFromAddressPredictions, setShipFromAddressPredictions] = useState([]);

    const [form, setForm] = useState({});

    const [missingKeys, setMissingKeys] = useState([]);

    const [validationContactEmail, setValidationContactEmail] = useState(true);
    const [validationCompanyEmail, setValidationCompanyEmail] = useState(true);
    const [validationPhone, setValidationPhone] = useState(true);
    const [validationPassword, setValidationPassword] = useState(true);

    const [openModal, setOpenModal] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const [registerStatus, setRegisterStatus] = useState(false);

    const [check, setCheck] = useState(null);

    const isMobile = useMediaQuery('(max-width: 480px)');
    const history = useHistory();

    const googleMapsPlaceAutocomplete = async (inputValue, type) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log('prediction', predictions);
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            console.log('updated prediction', updatedPredictions);

            return updatedPredictions;

        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const validateEmailAddress = async (email) => {
        let requestURL = `${NODE_ROUTE_URI}/validateEmail`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: {
                    email: email,
                    validateRegex: true,
                    validateMx: true,
                    validateTypo: false,
                    validateDisposable: false,
                    validateSMTP: false
                }
            })
            console.log('[validateEmailAddress] result - ', result);
            return result?.data?.valid;
        } catch (e) {
            console.log(e.response)
        }
    }

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    }

    const handleModalOpen = () => {
        setOpenModal(true);
    }

    const handleModalClose = () => {
        setOpenModal(false);
    }

    const handleCompanyEmail = event => {
        const value = event.target.value;
        setCompanyEmail(value);
        setForm(prevState => ({
            ...prevState, companyEmail: value
        }))
    }

    const handleCompanyName = event => {
        const value = event.target.value;
        setCompanyName(value);
        setForm(prevState => ({
            ...prevState, companyName: value
        }))
    }

    const handleCompanyPhone = value => {
        setCompanyPhone(value);
        setForm(prevState => ({
            ...prevState, companyPhone: value
        }))
    }

    const handlePostalCode = event => {
        const value = event.target.value;
        setPostalCode(value);
        setForm(prevState => ({
            ...prevState, postalCode: value
        }))
    }

    const handleProvince = (event, value) => {
        setProvince(value);
        setForm(prevState => ({
            ...prevState, province: value
        }))
    }

    const handleContactName = event => {
        const value = event.target.value;
        setContactName(value);
        setForm(prevState => ({
            ...prevState, contactName: value
        }))
    }

    const handleFirstName = event => {
        const value = event.target.value;
        setFirstName(value);
        setForm(prevState => ({
            ...prevState, firstName: value
        }))
    }

    const handleLastName = event => {
        const value = event.target.value;
        setLastName(value);
        setForm(prevState => ({
            ...prevState, lastName: value
        }))
    }

    const handleContactEmail = event => {
        const value = event.target.value.trim();
        setContactEmail(value);
        setForm(prevState => ({
            ...prevState, contactEmail: value
        }))
    }

    const handlePassword = event => {
        const value = event.target.value.trim();
        setPassword(value);
        setForm(prevState => ({
            ...prevState, password: value
        }))
    }

    const handleBusinessType = (event, value) => {
        setCompanyType(value);
        setForm(prevState => ({
            ...prevState, companyType: value
        }))
    }

    const handleCoupon = event => {
        const value = event.target.value;
        setCoupon(value);
        setForm(prevState => ({
            ...prevState, coupon: value
        }))
    }

    const handleCheck = () => {
        setCheck(!check);
    }

    const registerPartner = async () => {
        setIsLoading(true);
        let requestURL = `${PARTNER_URI}/login_middleware/registerPartner`;

        const data = {
            companyName: companyName,
            companyEmail: companyEmail,
            companyPhone: companyPhone,
            contactPhone: companyPhone,
            contactFirstName: firstName,
            contactLastName: lastName,
            contactEmail: contactEmail?.trim(),
            password: password?.trim(),
            address: companyAddress?.address2 ? `${companyAddress?.address2}-${companyAddress?.address}` : companyAddress?.address,
            city: companyAddress?.city,
            province: companyAddress?.province?.code,
            country: companyAddress?.country,
            postalCode: companyAddress?.postalCode?.replace(/\s+/g, '').toUpperCase(),
            companyType: companyType,
            coupons: coupon,
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })
            console.log(result.data);
            setRegisterStatus(true);
            setIsLoading(false);
            handleSuccessMessage();
            setErrorMessage('');
            setSuccessMessage(intl.get('LANDING_PAGE.SIGN_UP.SUCCESS_MESSAGE'))

        } catch (e) {
            console.log(e.response);
            setRegisterStatus(false);
            const errorCode = e?.response?.data?.code
            console.log(errorCode);
            if (errorCode === 401) {
                setErrorMessage(intl.get('LANDING_PAGE.SIGN_UP.ERROR_MESSAGE_1'))
            } else if (errorCode === 403) {
                setErrorMessage(intl.get('LANDING_PAGE.SIGN_UP.ERROR_MESSAGE_3'))
            } else {
                setErrorMessage(intl.get('LANDING_PAGE.SIGN_UP.ERROR_MESSAGE_2'))
            }
            handleErrorMessage();
            setIsLoading(false);
        }
    }

    const handleInputChangeAddress = async (event, value) => {
        // setCompanyAddress(prevState => ({
        //     ...prevState,
        //     address: value,
        //     displayAddress: {
        //         description: value,
        //         placeId: '',
        //         text: value
        //     }
        // }));
        // await googleMapsGoogleAddress(value);
        const predictionsAddress = await googleMapsPlaceAutocomplete(value, 'address');
        setShipFromAddressPredictions(predictionsAddress);
    }

    const handleChangeAddress = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            console.log('address', address);
            setCompanyAddress(prevState => ({
                ...prevState,
                postalCode: address?.zip,
                city: address?.sublocality || address?.city || address?.administrative_area_level_3,
                province: address?.state,
                country: address?.country?.code,
                displayAddress: value,
                address: value?.text
            }))
            setForm(prevState => ({
                ...prevState, companyAddress: value?.text
            }))
        } else {
            setCompanyAddress(prevState => ({
                ...prevState,
                address: '',
                displayAddress: {
                    description: '',
                    placeId: '',
                    text: ''
                },
            }))
            setForm(prevState => ({
                ...prevState, companyAddress: ""
            }))
        }
    }

    const handleCompanyAddress2 = event => {
        const value = event.target.value;
        setCompanyAddress(prevState => ({
            ...prevState,
            address2: value
        }));
        // setForm(prevState => ({
        //     ...prevState, companyEmail: value
        // }))
    }

    const validateContactEmail = () => {
        const result = isValidateEmail(contactEmail);
        setValidationContactEmail(result);
        return result;
    }

    const validateCompanyEmail = () => {
        const result = isValidateEmail(companyEmail);
        setValidationCompanyEmail(result);
        return result;
    }

    const validatePhone = () => {
        const result = isValidatePhone(companyPhone);
        setValidationPhone(result);
        return result;
    }

    const validatePassword = () => {
        const result = isValidatePassword(password);
        setValidationPassword(result);
        return result;
    }

    const handleSubmit = async () => {
        console.log('form', form);
        const missingKeys = getMissingKeysForBecomePartner(form);
        setMissingKeys(missingKeys);
        // const contactEmailCheck = validateContactEmail();
        const contactEmailCheck = await validateEmailAddress(contactEmail);
        setValidationContactEmail(contactEmailCheck);
        // const companyEmailCheck = validateCompanyEmail();
        const companyEmailCheck = await validateEmailAddress(companyEmail);
        setValidationCompanyEmail(companyEmailCheck);
        const phoneCheck = validatePhone();
        const passwordCheck = validatePassword();
        check === null && setCheck(false);
        if (companyEmailCheck && contactEmailCheck && passwordCheck && phoneCheck && missingKeys.length === 0 && check) {
            registerPartner();
        }
    }

    let isClickJoinUs = useSelector(state => state?.landingEstimate?.landingIsClickJoinUs);
    const dispatch = useDispatch();

    useEffect(() => {
        if (getAccessToken("access_token")) {
            history.push("/home");
        }
    }, []);

    useEffect(() => {
        contactEmail ? validateContactEmail() : setValidationContactEmail(true);
    }, [contactEmail]);

    useEffect(() => {
        password ? validatePassword() : setValidationPassword(true);
    }, [password]);

    useEffect(() => {
        companyPhone ? validatePhone() : setValidationPhone(true);
    }, [companyPhone]);

    useEffect(() => {
        companyEmail ? validateCompanyEmail() : setValidationCompanyEmail(true);
    }, [companyEmail]);

    // console.log('company phone', companyPhone);
    console.log('shipFromAddressPredictions', shipFromAddressPredictions);
    console.log('company address', companyAddress);
    console.log('form', form);

    return (
        <Grid container spacing={2}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item xs={12}>
                <Typography sx={styles.UserSignUpHeading}>
                    {intl.get('LANDING_PAGE.SIGN_UP.CREATE')}
                </Typography>
            </Grid>
            {
                registerStatus ?
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            sx={styles.UserSignUpButton}
                            onClick={() => history.push('/sign-in')}
                            size='small'
                        >
                            <Typography sx={{textTransform: 'none', fontSize: '14px'}}>
                                {intl.get('LANDING_PAGE.SIGN_UP.SIGN_IN_ACCOUNT')}
                            </Typography>
                        </Button>
                    </Grid>
                    :
                    <Grid item xs={12}>
                        <Box sx={styles.UserSignUpContent}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <Typography sx={{fontWeight: '600'}}>
                                                Is this account for
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                                <Checkbox
                                                    icon={<RadioButtonUncheckedIcon/>}
                                                    checkedIcon={<CheckCircleIcon/>}
                                                    color="success"
                                                    onClick={() => history.push('/sign-up')}
                                                />
                                                <Typography style={{color: '#545454', padding: '0 5px'}}>
                                                    Individual
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                                <Checkbox
                                                    icon={<RadioButtonUncheckedIcon/>}
                                                    checkedIcon={<CheckCircleIcon/>}
                                                    color="success"
                                                    checked
                                                />
                                                <Typography style={{color: '#545454', padding: '0 5px'}}>
                                                    Business
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        value={contactEmail}
                                        label={intl.get('LANDING_PAGE.SIGN_UP.SIGN_EMAIL')}
                                        required
                                        fullWidth
                                        variant="outlined"
                                        onInput={handleContactEmail}
                                        size="small"
                                        inputProps={{style: {fontSize: 14}}}
                                        InputLabelProps={{style: {fontSize: 14}}}
                                        // error={!contactEmail || !validationCompanyEmail}
                                        error={(missingKeys.includes('contactEmail') && !contactEmail) || !validationContactEmail}
                                        helperText={(missingKeys.includes('contactEmail') && !contactEmail) ?
                                            intl.get('LANDING_PAGE.SIGN_UP.REQUIRED') : !validationContactEmail &&
                                            intl.get('LANDING_PAGE.SIGN_UP.INVALID_EMAIL')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        value={password}
                                        label={intl.get('LANDING_PAGE.SIGN_UP.PASSWORD')}
                                        type={showPassword ? 'text' : 'password'}
                                        required
                                        fullWidth
                                        variant="outlined"
                                        onInput={handlePassword}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        inputProps={{style: {fontSize: 14}}}
                                        InputLabelProps={{style: {fontSize: 14}}}
                                        size="small"
                                        error={(missingKeys.includes('password') && !password) || !validationPassword}
                                        helperText={(missingKeys.includes('password') && !password) ?
                                            intl.get('LANDING_PAGE.SIGN_UP.REQUIRED') : !validationPassword &&
                                            intl.get('LANDING_PAGE.SIGN_UP.INVALID_PASSWORD')}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        value={firstName}
                                        label={intl.get('LANDING_PAGE.SIGN_UP.FIRST_NAME')}
                                        required
                                        fullWidth
                                        variant="outlined"
                                        onInput={handleFirstName}
                                        inputProps={{style: {fontSize: 14}}}
                                        InputLabelProps={{style: {fontSize: 14}}}
                                        size="small"
                                        error={(missingKeys.includes('firstName') && !firstName)}
                                        helperText={
                                            ((missingKeys.includes('firstName') && !firstName) && intl.get('CROSS_BORDER.REQUIRED'))
                                        }
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        value={lastName}
                                        label={intl.get('LANDING_PAGE.SIGN_UP.LAST_NAME')}
                                        required
                                        fullWidth
                                        variant="outlined"
                                        onInput={handleLastName}
                                        size="small"
                                        inputProps={{style: {fontSize: 14}}}
                                        InputLabelProps={{style: {fontSize: 14}}}
                                        error={(missingKeys.includes('lastName') && !lastName)}
                                        helperText={
                                            ((missingKeys.includes('lastName') && !lastName) && intl.get('CROSS_BORDER.REQUIRED'))
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        value={companyName}
                                        label={intl.get('LANDING_PAGE.SIGN_UP.COMPANY_NAME')}
                                        required
                                        fullWidth
                                        variant="outlined"
                                        onInput={handleCompanyName}
                                        size="small"
                                        inputProps={{style: {fontSize: 14}}}
                                        InputLabelProps={{style: {fontSize: 14}}}
                                        error={(missingKeys.includes('companyName') && !companyName)}
                                        helperText={
                                            ((missingKeys.includes('companyName') && !companyName) && intl.get('CROSS_BORDER.REQUIRED'))
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <MuiTelInput
                                        value={companyPhone}
                                        required
                                        fullWidth
                                        label={intl.get('LANDING_PAGE.SIGN_UP.COMPANY_PHONE')}
                                        defaultCountry="CA"
                                        onChange={handleCompanyPhone}
                                        size="small"
                                        inputProps={{style: {fontSize: 14}}}
                                        InputLabelProps={{style: {fontSize: 14}}}
                                        error={(missingKeys.includes('companyPhone') && !companyPhone) || !validationPhone}
                                        helperText={(missingKeys.includes('companyPhone') && !companyPhone) ?
                                            intl.get('LANDING_PAGE.SIGN_UP.REQUIRED') : !validationPhone &&
                                            intl.get('LANDING_PAGE.SIGN_UP.INVALID_PHONE')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        value={companyEmail}
                                        label={intl.get('LANDING_PAGE.SIGN_UP.COMPANY_EMAIL')}
                                        fullWidth
                                        required
                                        variant="outlined"
                                        onInput={handleCompanyEmail}
                                        size="small"
                                        inputProps={{style: {fontSize: 14}}}
                                        InputLabelProps={{style: {fontSize: 14}}}
                                        error={(missingKeys.includes('companyEmail') && !companyEmail) || !validationCompanyEmail}
                                        helperText={(missingKeys.includes('companyEmail') && !companyEmail) ?
                                            intl.get('LANDING_PAGE.SIGN_UP.REQUIRED') : !validationCompanyEmail &&
                                            intl.get('LANDING_PAGE.SIGN_UP.INVALID_EMAIL')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        value={companyAddress?.displayAddress}
                                        fullWidth
                                        options={shipFromAddressPredictions}
                                        filterOptions={(options, state) => options}
                                        getOptionLabel={option => option?.description}
                                        onInputChange={handleInputChangeAddress}
                                        onChange={handleChangeAddress}
                                        PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                        renderInput={params => <TextField
                                            {...params}
                                            multiline={companyAddress?.displayAddress && companyAddress?.displayAddress?.description?.length > 50}
                                            className={classes.smallInput}
                                            // sx={{height: 40}}
                                            size='small'
                                            required
                                            variant="outlined"
                                            label={intl.get('LANDING_PAGE.SIGN_UP.COMPANY_ADDRESS')}
                                            InputLabelProps={{style: {fontSize: 14}}}
                                            error={(missingKeys.includes('companyAddress') && !companyAddress?.displayAddress?.description)}
                                            helperText={
                                                ((missingKeys.includes('companyAddress') && !companyAddress?.displayAddress?.description) && intl.get('CROSS_BORDER.REQUIRED'))
                                            }
                                        />}
                                        renderOption={option => (
                                            <Typography style={{fontSize: '14px'}}>
                                                {option?.description}
                                            </Typography>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        value={companyAddress?.address2}
                                        label={intl.get('LANDING_PAGE.SIGN_UP.COMPANY_ADDRESS_2')}
                                        fullWidth
                                        variant="outlined"
                                        onInput={handleCompanyAddress2}
                                        size="small"
                                        inputProps={{style: {fontSize: 14}}}
                                        InputLabelProps={{style: {fontSize: 14}}}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        value={companyType}
                                        fullWidth
                                        options={businessTypeOption}
                                        onChange={handleBusinessType}
                                        renderInput={(params) => <TextField
                                            variant="outlined"
                                            {...params}
                                            label={intl.get('LANDING_PAGE.SIGN_UP.TYPE')}
                                            className={classes.smallInput}
                                            size='small'
                                            InputLabelProps={{style: {fontSize: 14}}}
                                        />}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={styles.UserSignUpPrivacyAndTerms}>
                                        <Checkbox
                                            checked={check}
                                            sx={styles.UserSignUpCheckbox}
                                            onChange={handleCheck}
                                        />
                                        <Typography sx={styles.UserSignUpPrivacyAndTermsText}>
                                            {intl.get('LANDING_PAGE.SIGN_UP.AGREEMENT')} <Link
                                            sx={styles.UserSignUpPrivacyAndTermsLink}
                                            href={'https://uucargo.ca/terms-of-use/'}
                                            target="_blank" rel="noopener noreferrer"
                                        >{intl.get('LANDING_PAGE.SIGN_UP.TERMS')}
                                        </Link> {intl.get('LANDING_PAGE.SIGN_UP.AND')} <Link
                                            sx={styles.UserSignUpPrivacyAndTermsLink}
                                            href={'https://uucargo.ca/privacy-policy/'}
                                            target="_blank" rel="noopener noreferrer"
                                        >{intl.get('LANDING_PAGE.SIGN_UP.PRIVATE_POLICY')}</Link>.
                                        </Typography>
                                    </Box>
                                    <FormHelperText sx={{color: "error.main"}}>
                                        {check === null ? '' : check ? '' : intl.get('LANDING_PAGE.SIGN_UP.AGREEMENT_WARNING')}
                                    </FormHelperText>
                                </Grid>
                                <Grid item xs={12}>
                                    <LoadingButton
                                        disabled={loading}
                                        loading={loading}
                                        variant="contained"
                                        onClick={handleSubmit}
                                        className={classes.SignInButton}
                                        sx={{
                                            backgroundColor: '#1D8B45',
                                            "&:hover": {
                                                backgroundColor: '#1D8B45',
                                                filter: 'brightness(0.9)'
                                            }
                                        }}
                                        size='small'
                                    >
                                        <Typography sx={{textTransform: 'none', fontSize: '14px'}}>
                                            {intl.get('LANDING_PAGE.SIGN_UP.CREATE_ACCOUNT')}
                                        </Typography>
                                    </LoadingButton>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={styles.UserSignUpHaveAccount}>
                                        <Typography
                                            style={{fontSize: '14px', color: '#000000'}}
                                        >
                                            {intl.get('LANDING_PAGE.SIGN_UP.HAVE_ACCOUNT')}
                                        </Typography>
                                        <Typography
                                            style={{
                                                fontSize: '14px',
                                                color: 'green',
                                                cursor: 'pointer',
                                                fontWeight: '600'
                                            }}
                                            onClick={() => {
                                                // handleOpenSignup();
                                                history.push('/sign-in')
                                                // dispatch(updateLandingClickJoinUs(true))
                                            }}
                                        >
                                            {intl.get('LANDING_PAGE.SIGN_UP.SIGN_IN')}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
            }
        </Grid>
    )
}