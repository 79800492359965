import {Container} from "@mui/material";
import {useParams} from "react-router-dom";
import {getAccessToken} from "../../../Utils/doToken";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@mui/material/Box";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Typography from "@mui/material/Typography";
import {LTLOrderDashboardSummary} from "./LTLOrderDashboardSummary";
import {LTLOrderDashboardDocuments} from "./LTLOrderDashboardDocuments";
import {LTLOrderDashboardCrossBorder} from "./LTLOrderDashboardCrossBorder";
import {LTLOrderDashboardAdjustOrder} from "./LTLOrderDashboardAdjustOrder";
import {LTLOrderDashboardTracking} from "./LTLOrderDashboardTracking";
import intl from "react-intl-universal";

export const LTLOrderDashboard = () => {

    const storedToken = getAccessToken("access_token");
    const {order_id: orderId} = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [orderData, setOrderData] = useState({});
    const [specialServices, setSpecialServices] = useState([]);

    const getOrderData = async () => {
        setIsLoading(true);
        const requestURL = `${PARTNER_URI}/shippingLTL/getLTLOrderDetailsByOrderId`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    orderId: orderId
                }
            })
            // console.log('result', result);

            const serviceList = result?.data?.bolInfo?.pickup_instruction
            // console.log('special service list', serviceList);

            if (serviceList) {
                const services = serviceList.split('\n');
                // console.log('remark note', remarkNote);
                const specialServices = services?.map(element => {
                    return {serviceCode: element}
                })
                // console.log('special service', specialServices);
                setSpecialServices(specialServices);
            }

            setOrderData(result?.data);

        } catch (e) {
            console.log('e', e?.response);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (orderId) {
            getOrderData();
        }
    }, [orderId])

    return (
        <Container maxWidth='xl'>
            <Grid container spacing={4}>
                <Grid item md={12} xs={12}>
                    <Box sx={{display: 'flex', justifyContent: 'flex-start', gap: '5px', alignItems: 'center'}}>
                        <CheckCircleIcon sx={{color: '#1D8B45', fontSize: '30px'}}/>
                        <Typography style={{fontSize: '18px', fontWeight: '600'}}>
                            {
                                (+orderData?.status_id === 0) ? intl.get('ORDER_DASHBOARD.ORDER_STATUS.INITIAL') :
                                    (+orderData?.status_id === 1) ? intl.get('ORDER_DASHBOARD.ORDER_STATUS.CONFIRM') :
                                        (+orderData?.status_id === -1) ? intl.get('ORDER_DASHBOARD.ORDER_STATUS.CANCELLED') :
                                            (+orderData?.status_id === 2) ? intl.get('ORDER_DASHBOARD.ORDER_STATUS.PAUSED') :
                                                intl.get('ORDER_DASHBOARD.ORDER_STATUS.REFUNDED')
                            }
                            {/*<span*/}
                            {/*    style={{color: '#1D8B45'}}> Order ID: {orderId}*/}
                            {/*</span>*/}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={(+orderData?.status_id === 1) ? 8 : 12} xs={12}>
                    <Grid container spacing={2}>
                        <Grid item md={12} xs={12}>
                            <LTLOrderDashboardSummary orderData={orderData} specialServices={specialServices}/>
                        </Grid>
                    </Grid>
                </Grid>
                {(+orderData?.status_id === 1) &&
                    <Grid item md={4} xs={12}>
                        <Grid container spacing={2}>
                            <Grid item md={12} xs={12}>
                                <LTLOrderDashboardDocuments orderData={orderData}/>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <LTLOrderDashboardTracking orderData={orderData}/>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <LTLOrderDashboardCrossBorder orderData={orderData}/>
                            </Grid>
                            {/*<Grid item md={12} xs={12}>*/}
                            {/*    <LTLOrderDashboardAdjustOrder orderData={orderData}/>*/}
                            {/*</Grid>*/}
                        </Grid>
                    </Grid>
                }
            </Grid>
        </Container>
    )
}