export const getCountryCode = (accountBase = "ups") => {
    return [
        {
            name: "Argentina",
            code: "AR"
        },
        {
            name: "Australia",
            code: "AU"
        },
        {
            name: "Austria",
            code: "AT"
        },
        {
            name: "Belgium",
            code: "BE"
        },
        {
            name: "Brazil",
            code: "BR"
        },
        {
            name: "Bulgaria",
            code: "BG"
        },
        {
            name: "Cambodia",
            code: "KH"
        },
        {
            name: "Canada",
            code: "CA"
        },
        {
            name: "China",
            code: "CN"
        },
        {
            name: "Croatia",
            code: "HR"
        },
        {
            name: "Cyprus",
            code: "CY"
        },
        {
            name: "Czech Republic",
            code: "CZ"
        },
        {
            name: "Denmark",
            code: "DK"
        },
        {
            name: "Estonia",
            code: "EE"
        },
        {
            name: "Finland",
            code: "FI"
        },
        {
            name: "France",
            code: "FR"
        },
        {
            name: "Germany",
            code: "DE"
        },
        {
            name: "Greece",
            code: "GR"
        },
        {
            name: "Hong Kong",
            code: "HK"
        },
        {
            name: "Hungary",
            code: "HU"
        },
        {
            name: "India",
            code: "IN"
        },
        {
            name: "Indonesia",
            code: "ID"
        },
        {
            name: "Ireland",
            code: "IE"
        },
        {
            name: "Italy",
            code: "IT"
        },
        {
            name: "Japan",
            code: "JP"
        },
        {
            name: "Laos People's Democratic LA Republic",
            code: "LA"
        },
        {
            name: "Latvia",
            code: "LV"
        },
        {
            name: "Lithuania",
            code: "LT"
        },
        {
            name: "Luxembourg",
            code: "LU"
        },
        {
            name: "Malaysia",
            code: "MY"
        },
        {
            name: "Malta",
            code: "MT"
        },
        {
            name: "Mexico",
            code: "MX"
        },
        {
            name: "Netherlands",
            code: "NL"
        },
        {
            name: "New Zealand",
            code: "NZ"
        },
        {
            name: "Norway",
            code: "NO"
        },
        {
            name: "Philippines",
            code: "PH"
        },
        {
            name: "Poland",
            code: "PL"
        },
        {
            name: "Portugal",
            code: "PT"
        },
        {
            name: "Romania",
            code: "RO"
        },
        {
            name: "Russia",
            code: "RU"
        },
        {
            name: "Saudi Arabia",
            code: "SA"
        },
        {
            name: "Singapore",
            code: "SG"
        },
        {
            name: "Slovakia",
            code: "SK"
        },
        {
            name: "Slovenia",
            code: "SI"
        },
        {
            name: "South Africa",
            code: "ZA"
        },
        {
            name: "South Korea",
            code: "KR"
        },
        {
            name: "Spain",
            code: "ES"
        },
        {
            name: "Sweden",
            code: "SE"
        },
        {
            name: "Switzerland",
            code: "CH"
        },
        {
            name: "Taiwan",
            code: "TW"
        },
        {
            name: "Thailand",
            code: "TH"
        },
        {
            name: "Turkey",
            code: "TR"
        },
        {
            name: "United Kingdom",
            code: "GB"
        },
        {
            name: "United States",
            code: "US"
        },
        {
            name: "VietNam",
            code: "VN"
        },
        {
            name: "Venezuela",
            code: "VE"
        }
    ];
}

export const getShipFromCountryCode = (accountBase = "ups") => {
    return [
        {
            name: "Canada",
            code: "CA"
        },
        {
            name: "United States",
            code: "US"
        }
    ]
}