import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Button, Divider} from "@mui/material";
import Grid from "@mui/material/Grid";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {useEffect, useState} from "react";
import {getAccessToken} from "../../Utils/doToken";
import {green, greenButtonContained, mainHeading, red, textContent} from "../../Utils/styleConfig";
import intl from "react-intl-universal";
import {useHistory} from "react-router-dom";

const styles = {
    BillingSummaryRoot: {
        backgroundColor: '#FFFFFF',
        // padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '25vh',
        height: '100%'
    },
    BillingSummaryContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        padding: '20px',
    },
    BillingSummaryTitle: {
        fontWeight: '600',
        textAlign: 'left',
    },
    BillingSummarySubTitle: {
        fontWeight: '600',
        textAlign: 'left',
        fontSize: textContent
    }
}

export const BillingSummary = () => {

    const history = useHistory();

    const storedToken = getAccessToken("access_token");

    const [creditInfo, setCreditInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [notBillAmount, setNotBillAmount] = useState(0);
    const [notPaidAmount, setNotPaidAmount] = useState(0);
    const [notUsedCredit, setNotUseCredit] = useState(0);
    const [currentBalance, setCurrentBalance] = useState(0);
    const [overdraftLimit, setOverdraftLimit] = useState(0);
    const [availableCredit, setAvailableCredit] = useState(0);

    const getAvailableCredit = async () => {
        setLoading(true);
        let requestURL = `${NODE_ROUTE_URI}/billingCenter/accountDetail`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                }
            });
            console.log('[ShippingOrderDetailsPayment] - getAvailableCredit result', result);
            const {
                data: {
                    absolute_creditnote,
                    current_bill_balance,
                    not_billed_amount,
                    not_paid_amount,
                    overdraft_limit
                }
            } = result;
            setNotBillAmount(+not_billed_amount);
            setNotPaidAmount(+not_paid_amount);
            setNotUseCredit(+absolute_creditnote);
            setCurrentBalance(+current_bill_balance);
            setOverdraftLimit(+overdraft_limit || 0);
            const availableCredit = +current_bill_balance + (+overdraft_limit || 0);
            setAvailableCredit(+availableCredit);
        } catch (e) {
            console.log('[ShippingOrderDetailsPayment] - getAvailableCredit error', e.response);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        (async () => {
                await getAvailableCredit();
            }
        )()
    }, []);

    return (
        <Box sx={styles.BillingSummaryRoot}>
            <Box sx={{
                padding: '20px 20px 0 20px',
            }}>
                <Typography style={styles.BillingSummaryTitle}>
                    {/*{intl.get('BILLING_CENTER.ACCOUNT_DETAILS')}*/}
                    UUCargo Credits
                </Typography>
            </Box>
            <Divider/>
            <Box sx={styles.BillingSummaryContent}>
                <Grid container spacing={1}>
                    {/*<Grid item xs={6}>*/}
                    {/*    <Typography style={styles.BillingSummarySubTitle}>*/}
                    {/*        {intl.get('BILLING_CENTER.NOT_BILLED_AMOUNT')}*/}
                    {/*    </Typography>*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={6}>*/}
                    {/*    <Typography style={{textAlign: 'right', fontSize: textContent}}>*/}
                    {/*        ${(+notBillAmount)?.toFixed(2)}*/}
                    {/*    </Typography>*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={6}>*/}
                    {/*    <Typography style={styles.BillingSummarySubTitle}>*/}
                    {/*        {intl.get('BILLING_CENTER.NOT_PAID_AMOUNT')}*/}
                    {/*    </Typography>*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={6}>*/}
                    {/*    <Typography style={{textAlign: 'right', fontSize: textContent}}>*/}
                    {/*        ${(+notPaidAmount)?.toFixed(2)}*/}
                    {/*    </Typography>*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={6}>*/}
                    {/*    <Typography style={styles.BillingSummarySubTitle}>*/}
                    {/*        {intl.get('BILLING_CENTER.NOT_USED_CREDIT')}*/}
                    {/*    </Typography>*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={6}>*/}
                    {/*    <Typography style={{textAlign: 'right', fontSize: textContent}}>*/}
                    {/*        (${(+notUsedCredit)?.toFixed(2)})*/}
                    {/*    </Typography>*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={12}>*/}
                    {/*    <Divider/>*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={6}>*/}
                    {/*    <Typography style={styles.BillingSummarySubTitle}>*/}
                    {/*        {intl.get('BILLING_CENTER.CURRENT_BALANCE')}*/}
                    {/*    </Typography>*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={6}>*/}
                    {/*    {*/}
                    {/*        +currentBalance < 0 ?*/}
                    {/*            <Typography style={{color: red, textAlign: 'right', fontSize: textContent}}>*/}
                    {/*                ${Math.abs(+currentBalance)?.toFixed(2)}*/}
                    {/*            </Typography> :*/}
                    {/*            <Typography style={{color: green, textAlign: 'right', fontSize: textContent}}>*/}
                    {/*                -${Math.abs(+currentBalance)?.toFixed(2)}*/}
                    {/*            </Typography>*/}
                    {/*    }*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={6}>*/}
                    {/*    <Typography style={styles.BillingSummarySubTitle}>*/}
                    {/*        {intl.get('BILLING_CENTER.OVERDRAFT_LIMIT')}*/}
                    {/*    </Typography>*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={6}>*/}
                    {/*    <Typography style={{textAlign: 'right', fontSize: textContent}}>*/}
                    {/*        ${(+overdraftLimit)?.toFixed(2)}*/}
                    {/*    </Typography>*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={12}>*/}
                    {/*    <Divider/>*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={6}>*/}
                    {/*    <Typography style={styles.BillingSummarySubTitle}>*/}
                    {/*        {intl.get('BILLING_CENTER.AVAILABLE_CREDIT')}*/}
                    {/*    </Typography>*/}
                    {/*</Grid>*/}
                    <Grid item xs={6}>
                        <Typography sx={{
                            color: (+availableCredit) < 0 ? red : green,
                            textAlign: 'left',
                            fontSize: mainHeading,
                            fontWeight: '600'
                        }}>
                            ${(+availableCredit).toFixed(2)}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}>
                            <Button
                                // sx={greenButtonContained}
                                onClick={() => history.push('/billing-center/top-up')}
                            >
                                <Typography
                                    style={{textTransform: 'none', fontSize: '14px', color: green, fontWeight: '600'}}>
                                    + {intl.get('BILLING_CENTER.ADD_CREDITS')}
                                </Typography>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}