import Box from "@mui/material/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {Tooltip} from "@mui/material";
import Grid from "@mui/material/Grid";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import LoadingButton from "@mui/lab/LoadingButton";
import EmailIcon from "../../images/emailTransferIcon.png";
import CreditIcon from "../../images/creditCardIcon.png";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {BillingPaymentEmail} from "./BillingPaymentEmail";
import {BillingPaymentOther} from "./BillingPaymentOther";
import {BillingPaymentCard} from "./BillingPaymentCard";
import {backgroundColor} from "../../Utils/styleConfig";

const styles = {
    BillingPaymentRoot: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: 24,
        backgroundColor: backgroundColor,
        maxWidth: 800,
        border: '1px solid #000',
        borderRadius: '10px'
    },
    BillingPaymentHeader: {
        backgroundColor: '#1D8B45',
        borderRadius: '9px 9px 0 0',
        padding: '15px 30px',
    },
    BillingPaymentContent: {
        backgroundColor: '#FFFFFF',
        borderRadius: '0 0 9px 9px',
        padding: '15px 30px 50px',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    },

}


export const BillingPayment = ({selectInvoice}) => {

    const [selectPayment, setSelectPayment] = useState(null);
    const [totalAmount, setTotalAmount] = useState(0);

    const isMobile = useMediaQuery('(max-width: 480px)');

    const [show, setShow] = useState(false);
    const handleOpenShow = () => setShow(true);
    const handleCloseShow = () => setShow(false);

    const handleSelectPayment = (method) => {
        setSelectPayment(method);
    }

    const cbResetPaymentMethod = () => {
        setSelectPayment(null);
    }

    const calculateTotalAmount = selectInvoice => {
        let total = 0;
        selectInvoice.forEach(invoice => {
            total += +invoice?.remainToPay;
        });
        setTotalAmount(total);
    }

    useEffect(() => {
        calculateTotalAmount(selectInvoice);
    }, [])

    return (
        <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            boxShadow: 24,
            backgroundColor: '#FFFFFF',
            maxWidth: 600,
            width: isMobile ? '360px' : '100%',
            border: '1px solid #000',
            borderRadius: '10px'
        }}>
            <Box sx={{
                backgroundColor: '#1D8B45',
                borderRadius: '8px 8px 0 0',
                padding: '15px 30px',
                // width: !isMobile && '480px'
                display: 'flex',
                gap: '10px',
                position: 'relative'
            }}
                 onMouseEnter={handleOpenShow}
                 onMouseLeave={handleCloseShow}
            >
                <Typography style={{color: '#FFFFFF', fontSize: '18px'}}>
                    {
                        selectPayment === 'email' ? 'Email Transfer' :
                            selectPayment === 'credit' ? 'Credit Card' :
                                selectPayment === 'other' ? 'Other Methods' :
                                    'Choose a payment method'
                    }
                </Typography>
                {
                    selectPayment === 'email' &&
                    <Tooltip title="There may be a delay between receiving your Reference Number and the actual fund
                        transfer into our account. If your Reference Number doesn't work immediately, please be
                        patient. Need help? Contact our customer service at op@uucargo.ca." placement="bottom">
                        <HelpOutlineIcon sx={{color: '#FFFFFF'}}/>
                    </Tooltip>
                }
            </Box>
            {
                selectPayment === 'email' ?
                <BillingPaymentEmail cbResetPaymentMethod={cbResetPaymentMethod} totalAmount={totalAmount}
                                     selectInvoice={selectInvoice}/> :
                selectPayment === 'credit' ?
                    <BillingPaymentCard cbResetPaymentMethod={cbResetPaymentMethod} totalAmount={totalAmount}
                                        selectInvoice={selectInvoice}/> :
                    selectPayment === 'other' ? <BillingPaymentOther cbResetPaymentMethod={cbResetPaymentMethod}
                                                                     selectInvoice={selectInvoice}
                                                                     totalAmount={totalAmount}/> :
                        <Box sx={styles.BillingPaymentContent}>
                            <Typography style={{fontSize: '16px'}}>
                                Please choose your prefer payment method.
                            </Typography>
                            <Box>
                                <Grid container spacing={1}>
                                    <Grid item md={4} xs={6}>
                                        <Box sx={{
                                            position: 'relative',
                                            height: isMobile ? '80px' : '100px',
                                            width: isMobile ? '120px' : '150px'
                                        }}>
                                            {
                                                selectPayment === 'email' &&
                                                <CheckCircleIcon
                                                    style={{
                                                        position: 'absolute',
                                                        right: '-5%',
                                                        top: '-10%',
                                                        zIndex: 1,
                                                        color: '#1D8B45',
                                                        backgroundColor: '#ffffff',
                                                        borderRadius: '50%'
                                                    }}
                                                />
                                            }
                                            <LoadingButton
                                                sx={{
                                                    border: selectPayment === 'email' ? '2px solid #1D8B45' : '2px solid #A6A6A6',
                                                    width: '100%',
                                                    height: '100%',
                                                    padding: '15px 0px',
                                                    overflow: 'visible',
                                                    position: 'relative',
                                                    borderRadius: '10px',
                                                    display: 'flex',
                                                    flexDirection: 'column'
                                                }}
                                                onClick={() => handleSelectPayment('email')}
                                            >
                                                <img src={EmailIcon} alt="email-icon" width={60}/>
                                                <Typography sx={{
                                                    color: '#000000',
                                                    fontSize: '14px',
                                                    textTransform: 'none',
                                                    fontWeight: '600'
                                                }}>
                                                    Email Transfer
                                                </Typography>
                                            </LoadingButton>
                                        </Box>
                                    </Grid>
                                    <Grid item md={4} xs={6}>
                                        <Box sx={{
                                            position: 'relative',
                                            height: isMobile ? '80px' : '100px',
                                            width: isMobile ? '120px' : '150px'
                                        }}>
                                            {
                                                selectPayment === 'credit' &&
                                                <CheckCircleIcon
                                                    style={{
                                                        position: 'absolute',
                                                        right: '-5%',
                                                        top: '-10%',
                                                        zIndex: 1,
                                                        color: '#1D8B45',
                                                        backgroundColor: '#ffffff',
                                                        borderRadius: '50%'
                                                    }}
                                                />
                                            }
                                            <LoadingButton
                                                sx={{
                                                    border: selectPayment === 'credit' ? '2px solid #1D8B45' : '2px solid #A6A6A6',
                                                    width: '100%',
                                                    height: '100%',
                                                    padding: '15px 0px',
                                                    overflow: 'visible',
                                                    position: 'relative',
                                                    borderRadius: '10px',
                                                    display: 'flex',
                                                    flexDirection: 'column'
                                                }}
                                                onClick={() => handleSelectPayment('credit')}
                                            >
                                                <img src={CreditIcon} alt="credit-icon" width={60}/>
                                                <Typography sx={{
                                                    color: '#000000',
                                                    fontSize: '14px',
                                                    textTransform: 'none',
                                                    fontWeight: '600'
                                                }}>
                                                    Credit Card
                                                </Typography>
                                            </LoadingButton>
                                        </Box>
                                    </Grid>
                                    <Grid item md={4} xs={6}>
                                        <Box sx={{
                                            position: 'relative',
                                            height: isMobile ? '80px' : '100px',
                                            width: isMobile ? '120px' : '150px'
                                        }}>
                                            {
                                                selectPayment === 'other' &&
                                                <CheckCircleIcon
                                                    style={{
                                                        position: 'absolute',
                                                        right: '-5%',
                                                        top: '-10%',
                                                        zIndex: 1,
                                                        color: '#1D8B45',
                                                        backgroundColor: '#ffffff',
                                                        borderRadius: '50%'
                                                    }}
                                                />
                                            }
                                            <LoadingButton
                                                sx={{
                                                    border: selectPayment === 'other' ? '2px solid #1D8B45' : '2px solid #A6A6A6',
                                                    width: '100%',
                                                    height: '100%',
                                                    padding: '15px 0px',
                                                    overflow: 'visible',
                                                    position: 'relative',
                                                    borderRadius: '10px',
                                                    display: 'flex',
                                                    flexDirection: 'column'
                                                }}
                                                onClick={() => handleSelectPayment('other')}
                                            >
                                                <MoreHorizIcon sx={{
                                                    color: '#000000',
                                                    fontSize: '60px'
                                                }}/>
                                                <Typography sx={{
                                                    color: '#000000',
                                                    fontSize: '14px',
                                                    textTransform: 'none',
                                                    fontWeight: '600'
                                                }}>
                                                    Other Method
                                                </Typography>
                                            </LoadingButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
            }
        </Box>
    )
}