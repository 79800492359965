import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LandingImage from "../../images/CoverImage.png";
import React from "react";
import {useMediaQuery} from "@material-ui/core";

const styles = {
    LandingSignUpRoot: {
        display: 'flex',
        margin: '0 auto',
        '@media (max-width: 480px)': {
            flexDirection: "column", // for phone and above
        },
    },
    LandingIntroImage: {
        width: '95%',
        padding: '5%'
    },
}

export const LandingIntro = () => {

    const isMobile = useMediaQuery('(max-width: 480px)');

    if (isMobile) {
        return <></>
    }

    return (
        <Grid item xs={12} md={7}>
            <Box
                sx={{
                    background: 'linear-gradient(to top left, #1d8b45, #38b064, #83d595)',
                    height: '100%',
                }}
            >
                <Box sx={{
                    display: 'flex',
                    minHeight: '100vh',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <img src={LandingImage} alt="landing image" style={styles.LandingIntroImage}/>
                </Box>
            </Box>
        </Grid>
    )
}