import {
    americaProvinceCodeToNameMap,
    americaProvinceNameToCodeMap,
    canadaProvinceCodeToNameMap
} from "../components/shared";

const getProvinceCodeByName = (name) => {
    switch (name) {
        case "Newfoundland and Labrador":
            return "NL";
        case "Prince Edward Island":
            return "PE";
        case "Nova Scotia":
            return "NS";
        case "New Brunswick":
            return "NB";
        case "Quebec":
            return "QC";
        case "Québec":
            return "QC";
        case "Ontario":
            return "ON";
        case "Manitoba":
            return "MB";
        case "Saskatchewan":
            return "SK";
        case "Alberta":
            return "AB";
        case "British Columbia":
            return "BC";
        case "Yukon":
            return "YT";
        case "Northwest Territories":
            return "NT";
        case "Nunavut":
            return "NU";
        case "Alabama":
            return "AL";
        case "Alaska":
            return "AK";
        case "Arizona":
            return "AZ";
        case "Arkansas":
            return "AR";
        case "California":
            return "CA";
        case "Colorado":
            return "CO";
        case "Connecticut":
            return "CT";
        case "Delaware":
            return "DE";
        case "Florida":
            return "FL";
        case "Georgia":
            return "GA";
        case "Hawaii":
            return "HI";
        case "Idaho":
            return "ID";
        case "Illinois":
            return "IL";
        case "Indiana":
            return "IN";
        case "Iowa":
            return "IA";
        case "Kansas":
            return "KS";
        case "Kentucky":
            return "KY";
        case "Louisiana":
            return "LA";
        case "Maine":
            return "ME";
        case "Maryland":
            return "MD";
        case "Massachusetts":
            return "MA";
        case "Michigan":
            return "MI";
        case "Minnesota":
            return "MN";
        case "Mississippi":
            return "MS";
        case "Missouri":
            return "MO";
        case "Montana":
            return "MT";
        case "Nebraska":
            return "NE";
        case "Nevada":
            return "NV";
        case "New Hampshire":
            return "NH";
        case "New Jersey":
            return "NJ";
        case "New Mexico":
            return "NM";
        case "New York":
            return "NY";
        case "North Carolina":
            return "NC";
        case "North Dakota":
            return "ND";
        case "Ohio":
            return "OH";
        case "Oklahoma":
            return "OK";
        case "Oregon":
            return "OR";
        case "Pennsylvania":
            return "PA";
        case "Rhode Island":
            return "RI";
        case "South Carolina":
            return "SC";
        case "South Dakota":
            return "SD";
        case "Tennessee":
            return "TN";
        case "Texas":
            return "TX";
        case "Utah":
            return "UT";
        case "Vermont":
            return "VT";
        case "Virginia":
            return "VA";
        case "Washington":
            return "WA";
        case "West Virginia":
            return "WV";
        case "Wisconsin":
            return "WI";
        case "Wyoming":
            return "WY";
        case "NL":
            return "NL";
        case "PE":
            return "PE";
        case "NS":
            return "NS";
        case "NB":
            return "NB";
        case "QC":
            return "QC";
        case "ON":
            return "ON";
        case "MB":
            return "MB";
        case "SK":
            return "SK";
        case "AB":
            return "AB";
        case "BC":
            return "BC";
        case "YT":
            return "YT";
        case "NT":
            return "NT";
        case "NU":
            return "NU";
        default:
            return name;
    }
};

export const getUSProvinceCodeByName = (name) => {
    return americaProvinceNameToCodeMap[name] || name;
}

export const getCanadaProvinceNameByCode = (name) => {
    return canadaProvinceCodeToNameMap[name] || name;
}

export const getUSProvinceNameByCode = (code) => {
    return americaProvinceCodeToNameMap[code] || code;

}

export default getProvinceCodeByName;
