import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Divider} from "@mui/material";
import {Fragment, useEffect, useState} from "react";

const style = {
    PackageOrderDashboardCostRoot: {
        border: '1px solid #B2B2B2',
        borderRadius: '10px',
        padding: '10px 0'
    }
}

export const PackageOrderDashboardCost = ({orderInfo}) => {

    const [creditPayments, setCreditPayments] = useState([]);
    const [otherPayments, setOtherPayments] = useState([]);
    const [totalDue, setTotalDue] = useState(0);
    const [subTotal, setSubtotal] = useState(0);
    const [totalCredit, setTotalCredit] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);

    useEffect(() => {
        if (orderInfo?.backendPayments?.length > 0) {
            const filterCreditPayments = orderInfo?.backendPayments.filter(payment => payment?.type?.toUpperCase() === "INVOICE");
            const filterOtherPayments = orderInfo?.backendPayments.filter(payment => payment?.type?.toUpperCase() !== "INVOICE");

            const useCredit = filterCreditPayments.reduce((total, payment) => total + payment.amount + payment.gateway_processing_fee, 0);
            const totalCredit = +useCredit < +orderInfo?.final_total ? useCredit : +orderInfo?.final_total

            const totalOtherPayment = filterOtherPayments.reduce((total, payment) => total + payment.amount + payment.gateway_processing_fee, 0);
            const subtotal = (+totalCredit > 0 && +totalCredit < +orderInfo?.final_total) ? +orderInfo?.final_total - +totalCredit : 0;
            const totalAmount = +orderInfo?.final_total + (+orderInfo?.transactionFee || 0) - +totalCredit;
            const totalDue = Math.abs(+totalAmount - +totalOtherPayment)

            setCreditPayments(filterCreditPayments);
            setOtherPayments(filterOtherPayments);
            setTotalCredit(totalCredit);
            setSubtotal(subtotal);
            setTotalAmount(totalAmount);
            setTotalDue(+totalDue <= 0.02 ? 0 : +totalDue);
        }
    }, [orderInfo]);

    console.log('[PackageOrderDashboardCost] orderInfo', orderInfo);
    console.log('[PackageOrderDashboardCost] creditPayments', creditPayments);
    console.log('[PackageOrderDashboardCost] otherPayments', otherPayments);
    console.log('[PackageOrderDashboardCost] totalCredit', totalCredit);
    console.log('[PackageOrderDashboardCost] subTotal', subTotal);
    console.log('[PackageOrderDashboardCost] totalCredit', totalCredit);
    console.log('[PackageOrderDashboardCost] totalAmount', totalAmount);
    console.log('[PackageOrderDashboardCost] totalDue', totalDue);

    return (
        <Box sx={style.PackageOrderDashboardCostRoot}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '10px',
                        borderBottom: '1px solid #B2B2B2'
                    }}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.SHIPPING_DOCUMENTS')}*/}
                            Payment Details
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={7}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-start',
                        padding: '0 10px'
                    }}>
                        <Typography>
                            {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.TRANSACTION_DETAILS')}*/}
                            Shipping Fee
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    <Typography style={{
                        // color: '#1D8B45',
                        textAlign: 'right',
                        paddingRight: '10px'
                    }}>
                        {orderInfo?.cross_border_shipment_type && 'C'}$ {(+orderInfo?.total_amount_exclude_tax)?.toFixed(2)}
                    </Typography>
                </Grid>
                {
                    +orderInfo?.service_fee_exclude_tax > 0 &&
                    <>
                        <Grid item xs={7}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                justifyContent: 'flex-start',
                                padding: '0 10px'
                            }}>
                                <Typography>
                                    {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.TRANSACTION_DETAILS')}*/}
                                    Service Fee
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography style={{
                                // color: '#1D8B45',
                                textAlign: 'right',
                                paddingRight: '10px'
                            }}>
                                {orderInfo?.cross_border_shipment_type && 'C'}$ {(+orderInfo?.service_fee_exclude_tax)?.toFixed(2)}
                            </Typography>
                        </Grid>
                    </>
                }
                {
                    orderInfo?.discount > 0 &&
                    <>
                        <Grid item xs={7}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                justifyContent: 'flex-start',
                                padding: '0 10px'
                            }}>
                                <Typography>
                                    {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.TRANSACTION_DETAILS')}*/}
                                    Discount
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography style={{
                                // color: '#1D8B45',
                                textAlign: 'right',
                                paddingRight: '10px'
                            }}>
                                ({orderInfo?.cross_border_shipment_type && 'C'}$ {(+orderInfo?.discount)?.toFixed(2)})
                            </Typography>
                        </Grid>
                    </>
                }
                <Grid item xs={7}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-start',
                        padding: '0 10px'
                    }}>
                        <Typography>
                            {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.TRANSACTION_DETAILS')}*/}
                            Taxes
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    <Typography style={{
                        // color: '#1D8B45',
                        textAlign: 'right',
                        paddingRight: '10px'
                    }}>
                        {orderInfo?.cross_border_shipment_type && 'C'}$ {(+orderInfo?.total_tax)?.toFixed(2)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={7}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-start',
                        padding: '0 10px'
                    }}>
                        <Typography>
                            {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.TRANSACTION_DETAILS')}*/}
                            Total
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    <Typography style={{
                        // color: '#1D8B45',
                        textAlign: 'right',
                        paddingRight: '10px'
                    }}>
                        {orderInfo?.cross_border_shipment_type && 'C'}$ {(+orderInfo?.final_total)?.toFixed(2)}
                    </Typography>
                </Grid>
                {
                    orderInfo?.backendPayments?.length > 0 &&
                    <>
                        {
                            +totalCredit > 0 &&
                            <>
                                <Grid item xs={7}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: '100%',
                                        justifyContent: 'flex-start',
                                        padding: '0 10px'
                                    }}>
                                        <Typography>
                                            {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.TRANSACTION_DETAILS')}*/}
                                            UUCargo Credit
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography style={{
                                        // color: '#1D8B45',
                                        textAlign: 'right',
                                        paddingRight: '10px'
                                    }}>
                                        - {orderInfo?.cross_border_shipment_type && 'C'}$ {(+totalCredit)?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        {
                            subTotal > 0 &&
                            <>
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>
                                <Grid item xs={7}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: '100%',
                                        justifyContent: 'flex-start',
                                        padding: '0 10px'
                                    }}>
                                        <Typography>
                                            {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.TRANSACTION_DETAILS')}*/}
                                            Subtotal
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography style={{
                                        // color: '#1D8B45',
                                        textAlign: 'right',
                                        paddingRight: '10px'
                                    }}>
                                        {orderInfo?.cross_border_shipment_type && 'C'}$ {(+subTotal)?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        {
                            +orderInfo?.transactionFee > 0 &&
                            <>
                                <Grid item xs={7}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: '100%',
                                        justifyContent: 'flex-start',
                                        padding: '0 10px'
                                    }}>
                                        <Typography>
                                            {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.TRANSACTION_DETAILS')}*/}
                                            Transaction Fee
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography style={{
                                        // color: '#1D8B45',
                                        textAlign: 'right',
                                        paddingRight: '10px'
                                    }}>
                                        {orderInfo?.cross_border_shipment_type && 'C'}$ {(+orderInfo?.transactionFee)?.toFixed(2)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>
                                <Grid item xs={7}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: '100%',
                                        justifyContent: 'flex-start',
                                        padding: '0 10px'
                                    }}>
                                        <Typography>
                                            {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.TRANSACTION_DETAILS')}*/}
                                            Total Amount
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography style={{
                                        // color: '#1D8B45',
                                        textAlign: 'right',
                                        paddingRight: '10px'
                                    }}>
                                        {orderInfo?.cross_border_shipment_type && 'C'}$ {(+totalAmount)?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        {
                            otherPayments?.length > 0 &&
                            otherPayments?.map((payment, index) => (
                                <Fragment key={index}>
                                    <Grid item xs={7}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            height: '100%',
                                            justifyContent: 'flex-start',
                                            padding: '0 10px'
                                        }}>
                                            <Typography>
                                                {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.TRANSACTION_DETAILS')}*/}
                                                {payment?.type}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography style={{
                                            // color: '#1D8B45',
                                            textAlign: 'right',
                                            paddingRight: '10px'
                                        }}>
                                            - {orderInfo?.cross_border_shipment_type && 'C'}$ {(+payment?.amount)?.toFixed(2)}
                                        </Typography>
                                    </Grid>
                                    {
                                        +payment?.gateway_processing_fee > 0 &&
                                        <>
                                            <Grid item xs={7}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    height: '100%',
                                                    justifyContent: 'flex-start',
                                                    padding: '0 10px'
                                                }}>
                                                    <Typography>
                                                        {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.TRANSACTION_DETAILS')}*/}
                                                        Transaction Fee
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography style={{
                                                    // color: '#1D8B45',
                                                    textAlign: 'right',
                                                    paddingRight: '10px'
                                                }}>
                                                    - {orderInfo?.cross_border_shipment_type && 'C'}$ {(+payment?.gateway_processing_fee)?.toFixed(2)}
                                                </Typography>
                                            </Grid>
                                        </>
                                    }
                                </Fragment>
                            ))
                        }
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={7}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                justifyContent: 'flex-start',
                                padding: '0 10px'
                            }}>
                                <Typography>
                                    {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.TRANSACTION_DETAILS')}*/}
                                    Total Due
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography style={{
                                // color: '#1D8B45',
                                textAlign: 'right',
                                paddingRight: '10px'
                            }}>
                                {orderInfo?.cross_border_shipment_type && 'C'}$ {(+totalDue)?.toFixed(2)}
                            </Typography>
                        </Grid>
                    </>
                }
            </Grid>
        </Box>
    )
}