import {Container} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useState} from "react";
import {WelcomeIntro} from "./WelcomeIntro";
import {WelcomeCard} from "./WelcomeCard";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export const Welcome = () => {

    const isMobile = useMediaQuery('(max-width: 480px)');

    const [pageNum, setPageNum] = useState(1);

    const handlePageNum = (num) => {
        setPageNum(num);
    }

    return (
        <Container maxWidth='xl'>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                backgroundColor: '#FFFFFF',
                border: '1px solid #454545',
                borderRadius: '5px',
                maxWidth: '550px',
                minWidth: '350px',
                margin: 'auto',
                flexDirection: 'column',
                gap: '10px',
                padding: '25px',
                boxShadow: 24,
                overflow: 'auto'
            }}>
                <Box sx={{position: 'relative'}}>
                    <Typography
                        style={{
                            fontSize: '22px',
                            color: '#1D8B45',
                            fontWeight: '600',
                            textAlign: 'center'
                        }}>
                        Welcome to UUCargo!
                    </Typography>
                    {
                        !isMobile &&
                        <Box sx={{position: 'relative'}}>
                            <Box sx={{
                                width: '100%', /* Adjust this to your desired width */
                                height: '125px', /* Adjust this to your desired height */
                                overflow: 'hidden',
                                position: 'relative'
                            }}>
                                <img src={"https://storage.googleapis.com/uucargo-resource/image/icon/UserGuidance.png"}
                                     alt="logo"
                                     width='100%' style={{position: 'absolute', top: '-60%'}}/>
                            </Box>
                        </Box>
                    }
                </Box>
                {
                    pageNum === 1 && <WelcomeIntro handlePageNum={handlePageNum}/>
                }
                {
                    pageNum === 2 && <WelcomeCard handlePageNum={handlePageNum}/>
                }
            </Box>
        </Container>
    )

}