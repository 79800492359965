import {Container, Snackbar} from "@mui/material";
import Box from "@mui/material/Box";
import {BusinessShippingPackageCreateLabelShipFrom} from "./BusinessShippingPackageCreateLabelShipFrom";
import {useDispatch, useSelector} from "react-redux";
import {BusinessShippingPackageInternationShipmentShipFrom} from "./BusinessShippingPackageInternationShipmentShipFrom";
import {BusinessShippingPackageInternationShipmentShipTo} from "./BusinessShippingPackageInternationShipmentShipTo";
import {BusinessShippingPackageCreateLabelPackageDetails} from "./BusinessShippingPackageCreateLabelPackageDetails";
import {useHistory} from "react-router-dom";
import {getAccessToken} from "../../../Utils/doToken";
import {useMediaQuery} from "@material-ui/core";
import {useState} from "react";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {
    clearShippingPackageRateCreateLabel,
    updateShippingPackageRateCreateLabel,
    updateShippingPackageRateQuickQuote
} from "../../../actions/BusinessShippingPackageAction";
import {getMissingKeysForCreateLabelLocation, isValidatePhone, isValidPostalCode} from "../../../Utils/Helper";
import intl from "react-intl-universal";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingPackageCreateLabelRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        // margin: '100px 0'
    },
    ShippingPackageCreateLabelRow: {
        display: 'flex',
        alignItems: 'center'
        // justifyContent: 'flex-end',
    },
    ShippingPackageCreateLabelRowRight: {
        display: 'flex',
        justifyContent: 'flex-end'
        // justifyContent: 'flex-end',
    },
    ShippingPackageCreateLabelSection: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #A9A9A9',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelSectionBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
}

const requiredProperties = ["length", "width", "height", "quantity", "weight", "lengthUnit", "weightUnit"];
const validateRequiredProperties = ["length", "width", "height", "weight"];

export const BusinessShippingPackageInternationShipmentOrder = () => {

    const dispatch = useDispatch();

    const token = getAccessToken("access_token");

    const history = useHistory();

    const isMobile = useMediaQuery('(max-width: 600px)');

    const shipFromHistory = useSelector(state => state?.businessShippingPackage?.internationalShipmentShipFrom);
    const shipToHistory = useSelector(state => state?.businessShippingPackage?.internationalShipmentShipTo);
    const packageListHistory = useSelector(state => state?.businessShippingPackage?.quickQuotePackageList);
    let packageListRedux = useSelector(state => state?.businessShippingPackage?.createLabelPackageList);
    let shipFromRedux = useSelector(state => state?.businessShippingPackage?.createLabelShipFrom);
    let shipToRedux = useSelector(state => state?.businessShippingPackage?.createLabelShipTo);

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [locationValidation, setLocationValidation] = useState(null);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const getShippingPackageRate = async (selectedAccountBase) => {
        setLoading(true);
        let requestURL = `${PARTNER_URI}/loose-item/3rd-party/partner/rates/all`;
        let data = {
            rateData: {
                shipFromAddress: shipFromRedux?.address,
                shipFromAddressTwo: shipFromRedux?.address2,
                shipFromCity: shipFromRedux?.city,
                shipFromProvince: shipFromRedux?.province?.code,
                shipFromPostalCode: shipFromRedux?.postalCode,
                shipFromCountry: shipFromRedux?.country,
                shipFromName: shipFromRedux?.name,
                shipFromPhone: shipFromRedux?.phone,
                shipToAddress: shipToRedux?.address,
                shipToAddressTwo: shipToRedux?.address2,
                shipToCity: shipToRedux?.city,
                shipToPostalCode: shipToRedux?.postalCode,
                shipToProvince: shipToRedux?.province?.code,
                shipToCountry: shipToRedux?.country,
                shipToName: shipToRedux?.name,
                shipToPhone: shipToRedux?.phone,
                packageData: packageListRedux,
            },
            selectedAccountBase: selectedAccountBase
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            });
            const rateData = result?.data?.result;
            const statusCode = result?.data?.statusCode;
            // console.log('result data', result.data);

            if (statusCode === 200) {
                const updatedRateData = rateData.map((each) => ({
                    ...each,
                    errorAccountBase: selectedAccountBase?.toUpperCase()
                }));

                dispatch(updateShippingPackageRateCreateLabel(updatedRateData));
                history.push('/business/shipping-package/international-shipment/rate-list');
                setLocationValidation(prevState => ({
                    ...prevState,
                    missingPostalCode: false
                }))
            } else if (statusCode === 400) {
                setErrorMessage('Please including the all postal code, and try again.');
                handleErrorMessage();
                setLocationValidation(prevState => ({
                    ...prevState,
                    missingPostalCode: true
                }))
            } else {
                setErrorMessage('There is no available package rate based on the information you provided. Please ensure that you have provided valid and accurate information and try again. If you continue to experience issues, your account may be forbidden from accessing rates. Please contact op@uucargo.ca for assistance.');
                handleErrorMessage();
                setLocationValidation(prevState => ({
                    ...prevState,
                    missingPostalCode: false
                }))
            }

        } catch (e) {
            console.log('error', e.response);
        } finally {
            setLoading(false);
        }
    }

    const validate = () => {
        setErrorMessage('');
        setToastOpen(false);

        const shipFromMissing = getMissingKeysForCreateLabelLocation({
            name: shipFromRedux?.name,
            phone: shipFromRedux?.phone,
            address: shipFromRedux?.address,
            city: shipFromRedux?.city,
            province: (shipFromRedux?.country?.toLowerCase() === 'ca' || shipFromRedux?.country?.toLowerCase() === 'us') ? shipFromRedux?.province?.code : 'default',
            country: shipFromRedux?.country,
            postalCode: shipFromRedux?.postalCode
        });
        const shipToMissing = getMissingKeysForCreateLabelLocation({
            name: shipToRedux?.name,
            phone: shipToRedux?.phone,
            address: shipToRedux?.address,
            city: shipToRedux?.city,
            province: (shipToRedux?.country?.toLowerCase() === 'ca' || shipToRedux?.country?.toLowerCase() === 'us') ? shipToRedux?.province?.code : 'default',
            country: shipToRedux?.country,
            postalCode: shipToRedux?.postalCode
        });

        const hasMissingValues = packageListRedux.some(object => requiredProperties.some((property) => !object[property]));
        const hasValidateValues = packageListRedux.some(object => validateRequiredProperties.some((property) => (object[property]) <= 0));
        // const hasMissingValuesCrossBorder = CrossBorderHasMissingValues();
        const hasOverweightObject = packageListRedux.some(obj => {
            if (obj.weightUnit === 'kg') {
                const weightInLbs = obj.weight * 2.20462; // Convert kg to lbs
                return weightInLbs > 150;
            } else {
                return obj.weight > 150;
            }
        });
        const hasOverlengthItemName = packageListRedux?.some(obj => {
            return obj?.description?.length > 30;
        })

        const shipFromPostalCodeValid = shipFromRedux?.country.toLowerCase() === 'ca' ? isValidPostalCode(shipFromRedux?.postalCode) : true;
        const shipToPostalCodeValid = shipToRedux?.country.toLowerCase() === 'ca' ? isValidPostalCode(shipToRedux?.postalCode) : true;
        const shipFromPhoneValidate = isValidatePhone(shipFromRedux?.phone);
        const shipToPhoneValidate = isValidatePhone(shipToRedux?.phone);

        packageListRedux?.length === 0 && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.PACKAGE_LIST_EMPTY'));
        shipFromMissing?.length !== 0 && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.SHIP_FROM_MISSING'));
        shipToMissing?.length !== 0 && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.SHIP_TO_MISSING'));
        // hasMissingValues && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.PACKAGE_LIST_MISSING'));
        hasValidateValues && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.PACKAGE_LIST_ERROR'));
        hasOverweightObject && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.OVER_WEIGHT'));
        hasOverlengthItemName && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.OVER_CHARACTER'));
        !shipFromPostalCodeValid && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.SHIP_FROM_POSTAL_CODE'));
        !shipToPostalCodeValid && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.SHIP_TO_POSTAL_CODE'));
        // !shipFromPhoneValidate && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.SHIP_FROM_PHONE'));
        // !shipToPhoneValidate && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.SHIP_TO_PHONE'));

        const validation = {
            shipFromMissing,
            shipToMissing,
            shipFromPostalCodeValid: !shipFromPostalCodeValid,
            shipToPostalCodeValid: !shipToPostalCodeValid,
            // shipFromPhone: !shipFromPhoneValidate,
            // shipToPhone: !shipToPhoneValidate
        }

        setLocationValidation(validation);

        if (
            shipFromMissing.length === 0 &&
            shipToMissing.length === 0 &&
            packageListRedux.length !== 0 &&
            !hasMissingValues &&
            !hasValidateValues &&
            !hasOverweightObject &&
            !hasOverlengthItemName &&
            // shipFromPhoneValidate &&
            // shipToPhoneValidate &&
            shipFromPostalCodeValid &&
            shipToPostalCodeValid
        ) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const handleGetRate = () => {

        if ((shipFromRedux?.country?.toLowerCase() === 'ca' || shipFromRedux?.country?.toLowerCase() === 'us') && (shipToRedux?.country?.toLowerCase() === 'ca' || shipToRedux?.country?.toLowerCase() === 'us')) {
            setErrorMessage("In accordance with the information provided, please utilize the 'North America Package' instead of the 'International Package'.");
            handleErrorMessage();
        } else {
            const validationResult = validate();
            if (validationResult) {
                dispatch(clearShippingPackageRateCreateLabel());
                console.log('[BusinessShippingPackageInternationShipment] call api');
                getShippingPackageRate('ups');
            }
        }
    }

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box style={styles.ShippingPackageCreateLabelRoot}>
                <BusinessShippingPackageInternationShipmentShipFrom shipFromHistory={shipFromHistory} locationValidation={locationValidation}/>
                <BusinessShippingPackageInternationShipmentShipTo shipToHistory={shipToHistory} locationValidation={locationValidation}/>
                <BusinessShippingPackageCreateLabelPackageDetails packageListRedux={packageListRedux}
                                                                  packageListHistory={packageListHistory}/>
            </Box>
            {
                errorMessage &&
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '20px'
                }}>
                    <Typography style={{
                        color: '#FF0303'
                    }}>
                        {errorMessage}
                    </Typography>
                </Box>
            }
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '50px'
            }}>
                <LoadingButton
                    variant='contained'
                    sx={{
                        backgroundColor: '#1D8B45',
                        "&:hover": {
                            backgroundColor: '#1D8B45',
                            filter: 'brightness(0.9)'
                        }
                    }}
                    onClick={handleGetRate}
                    loading={loading}
                >
                    <Typography style={{textTransform: 'none'}}>
                        {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.GET_RATE')}
                    </Typography>
                </LoadingButton>
            </Box>
        </Container>

    )

}