import ReactToPrint from "react-to-print";
import {useRef} from "react";
import {ComponentToPrint} from "./ComponentToPrint";
import Button from "@mui/material/Button";
import {makeStyles} from "@material-ui/core/styles";
// import {ComponentToPrint} from "../Printer/Printer";

const useStyles = makeStyles((theme) => ({
    button: {
        fontSize: "12pt",
        backgroundColor: '#FFA726',
        color: '#000000',
    },
}))

const styles = {
    button: {
        backgroundColor: '#FFA726',
        color: '#000000',
        ':hover': {
            filter: 'brightness(75%)',
            backgroundColor: '#FFA726',
        }
    }
}


export const PrintComponent = () => {
    const componentRef = useRef();
    const classes = useStyles();

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center'
        }}>
            <ReactToPrint
                trigger={() => {
                    return (
                        <Button
                            sx={styles.button}
                            variant="contained"
                        >
                            Print
                        </Button>
                    )
                }}
                content={() => componentRef.current}
                pageStyle={`@page {
                    size: auto;
                    margin: 0mm;
                }
                @media print {
                   html, body {
                        height: 100vh;
                        margin: 0 !important;
                        padding: 0 !important;
                        overflow: hidden;
                        -webkit-print-color-adjust: exact;
                        }
                }`}
            />
            <ComponentToPrint ref={componentRef}/>
        </div>
    );
};


