import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {LABEL_DOWNLOAD_URL_PREFIX} from "../../Utils/apiUrl";

export const MovingOrderDashboardImage = ({orderDetails}) => {

    return (
        <Box sx={{
            border: '1px solid #B2B2B2',
            borderRadius: '10px',
            padding: '10px 0'
        }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '20px',
                        borderBottom: '1px solid #B2B2B2',
                        alignItems: 'center'
                    }}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            Image List
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{padding: '20px'}}>
                        <Grid container spacing={2}>
                            {
                                orderDetails?.images?.map((image, index) => (
                                    <Grid item xs={4} key={index}>
                                        <img
                                            src={`${LABEL_DOWNLOAD_URL_PREFIX}${(image?.file_location_uri).startsWith("/") ? image?.file_location_uri?.slice(1) : image?.file_location_uri}`}
                                            alt={`movingImage${index}`} style={{
                                            objectFit: 'contain',
                                            width: '100%',
                                        }}/>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}