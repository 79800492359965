import {styled} from "@mui/material/styles";
import {Switch} from "@mui/material";

export const green = '#1D8B45';
export const yellow = '#F2BE22';
export const grey = '#424242';
export const red = '#FF0303';
export const red2 = '#DC3545';
export const blue = '#384559';
export const white = '#FFFFFF';
export const mainHeading = '24px';
export const sectionHeading = '20px';
export const subHeading = '18px';
export const defaultText = '16px';
export const textContent = '14px';
export const backgroundColor = '#FFFFFF';

export const greenButtonContained = {
    backgroundColor: green,
    "&:hover": {
        backgroundColor: green,
        filter: 'brightness(0.9)'
    }
}

export const yellowButtonContained = {
    backgroundColor: yellow,
    "&:hover": {
        backgroundColor: yellow,
        filter: 'brightness(0.9)'
    }
}

export const greyButtonContained = {
    backgroundColor: grey,
    "&:hover": {
        backgroundColor: grey,
        filter: 'brightness(0.9)'
    }
}


export const greenButtonOutlined = {
    border: '2px solid #1D8B45',
    "&:hover": {
        border: '2px solid #1D8B45',
        filter: 'brightness(0.9)'
    }
}

export const AntSwitch = styled(Switch)(({theme}) => ({
    width: 30,
    height: 18,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 17,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#609966' : '#609966',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 14,
        height: 14,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

export const textFieldColor = {
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused:not(.Mui-error) fieldset': {
            borderColor: green, // Focused border color when not in error
        },
    },
}

