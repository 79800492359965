import {BusinessMyBoxesList} from "./BusinessMyBoxesList";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {BusinessMyBoxesForm} from "./BusinessMyBoxesForm";
import {Button, Container, Divider} from "@mui/material";
import Grid from "@mui/material/Grid";
import {green, mainHeading, sectionHeading} from "../../../Utils/styleConfig";
import {useState} from "react";

export const BusinessMyBoxes = () => {

    const [isAdd, setIsAdd] = useState(false);
    const [newBoxInformation, setNewBoxInformation] = useState(null);

    const initNewBox = () => {
        const newBox = {
            boxName: 'new box',
            itemName: 'new item',
            length: '10',
            width: '10',
            height: '10',
            weight: '10',
            lengthUnit: 'in',
            weightUnit: 'lb',
        }
        setNewBoxInformation(newBox);
    }

    const handleAddBox = () => {
        setIsAdd(true);
        initNewBox();
    }

    const handleCancelBox = () => {
        setIsAdd(false);
    }

    return (
        <Container maxWidth='xl'>
            <Grid container spacing={2}>
                <Grid item xs={10}>
                    <Typography sx={{fontSize: mainHeading, fontWeight: '600'}}>
                        My Boxes List
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Button onClick={handleAddBox}>
                        <Typography sx={{color: green, fontWeight: '600'}}>
                            Add
                        </Typography>
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={12}>
                    {
                        isAdd ?
                            <BusinessMyBoxesForm newBoxInformation={newBoxInformation}
                                                 handleCancelBox={handleCancelBox}/>
                            :
                            <BusinessMyBoxesList/>
                    }
                </Grid>
            </Grid>
        </Container>
    )
}