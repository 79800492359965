import {
    ADD_CREATE_LABEL_SHIPPING_PACKAGE_ITEM_LIST,
    ADD_CREATE_LABEL_SHIPPING_PACKAGE_PACKAGE_LIST,
    ADD_QUICK_QUOTE_SHIPPING_PACKAGE_PACKAGE_LIST,
    CLEAR_CREATE_LABEL_SHIPPING_PACKAGE_ITEM_LIST,
    CLEAR_CREATE_LABEL_SHIPPING_PACKAGE_PACKAGE_LIST,
    CLEAR_CREATE_LABEL_SHIPPING_PACKAGE_RATE_LIST,
    CLEAR_QUICK_QUOTE_SHIPPING_PACKAGE_PACKAGE_LIST,
    DUPLICATE_CREATE_LABEL_SHIPPING_PACKAGE_ITEM_LIST,
    DUPLICATE_CREATE_LABEL_SHIPPING_PACKAGE_PACKAGE_LIST,
    DUPLICATE_QUICK_QUOTE_SHIPPING_PACKAGE_PACKAGE_LIST, INIT_CREATE_LABEL_SHIPPING_PACKAGE_ITEM_LIST,
    INIT_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST,
    IS_CALLED_ALL_PACKAGE_CARRIER,
    REMOVE_CREATE_LABEL_SHIPPING_PACKAGE_ITEM_LIST,
    REMOVE_CREATE_LABEL_SHIPPING_PACKAGE_PACKAGE_LIST,
    REMOVE_QUICK_QUOTE_SHIPPING_PACKAGE_PACKAGE_LIST,
    SELECT_CREATE_LABEL_SHIPPING_PACKAGE_RATE,
    SELECT_QUICK_QUOTE_SHIPPING_PACKAGE_RATE,
    TOGGLE_QUICK_QUOTE_SHIPPING_PACKAGE_IS_GET_RATE,
    UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_CROSS_BORDER,
    UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_CROSS_BORDER_RATE,
    UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_ITEM_LIST,
    UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_METRIC_UNIT,
    UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_PACKAGE_LIST,
    UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_RATE_LIST,
    UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_REFERENCE_ORDER_NUMBER,
    UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_SHIP_FROM,
    UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_SHIP_TO,
    UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_VALIDATION,
    UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_CROSS_BORDER,
    UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_METRIC_UNIT,
    UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_PACKAGE_LIST,
    UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_RATE_LIST,
    UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_SHIP_FROM,
    UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_SHIP_FROM_MISSING,
    UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_SHIP_TO,
    UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_SHIP_TO_MISSING,
    UPDATE_SHIPPING_CREATE_LABEL_BATTERY_DETAILS,
    UPDATE_SHIPPING_CREATE_LABEL_SIGNATURE_OPTIONS,
    UPDATE_SHIPPING_PACKAGE_INTERNATIONAL_SHIPMENT_SHIP_FROM,
    UPDATE_SHIPPING_PACKAGE_INTERNATIONAL_SHIPMENT_SHIP_TO,
    UPDATE_SHIPPING_PACKAGE_RETURN_LABEL_STATUS,
    UPDATE_SHIPPING_PACKAGE_SCHEDULE_PICKUP_INFORMATION,
    UPDATE_SHIPPING_PACKAGE_SCHEDULE_PICKUP_RATE,
    UPDATE_SHIPPING_QUICK_QUOTE_BATTERY_DETAILS,
    UPDATE_SHIPPING_QUICK_QUOTE_SIGNATURE_OPTIONS
} from "./types";

export const updateQuickQuoteShippingPackageShipFrom = location => ({
    type: UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_SHIP_FROM,
    payload: location
})

export const updateQuickQuoteShippingPackageShipTo = location => ({
    type: UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_SHIP_TO,
    payload: location
})

export const updateQuickQuoteShippingPackageShipFromMissing = missing => ({
    type: UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_SHIP_FROM_MISSING,
    payload: missing
})

export const updateQuickQuoteShippingPackageShipToMissing = missing => ({
    type: UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_SHIP_TO_MISSING,
    payload: missing
})

export const updateQuickQuoteShippingPackageCrossBorder = crossBorder => ({
    type: UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_CROSS_BORDER,
    payload: crossBorder
})

export const updateQuickQuoteShippingPackageMetricUnit = metric => ({
    type: UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_METRIC_UNIT,
    payload: metric
})

export const addQuickQuoteShippingPackagePackageList = packageDetails => ({
    type: ADD_QUICK_QUOTE_SHIPPING_PACKAGE_PACKAGE_LIST,
    payload: packageDetails
})

export const updateQuickQuoteShippingPackagePackageList = packageDetails => ({
    type: UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_PACKAGE_LIST,
    payload: packageDetails
})

export const duplicateQuickQuoteShippingPackagePackageList = packageDetails => ({
    type: DUPLICATE_QUICK_QUOTE_SHIPPING_PACKAGE_PACKAGE_LIST,
    payload: packageDetails
})

export const removeQuickQuoteShippingPackagePackageList = packageDetails => ({
    type: REMOVE_QUICK_QUOTE_SHIPPING_PACKAGE_PACKAGE_LIST,
    payload: packageDetails
})

export const clearQuickQuoteShippingPackagePackageList = () => ({
    type: CLEAR_QUICK_QUOTE_SHIPPING_PACKAGE_PACKAGE_LIST,
})

export const updateQuickQuoteShippingPackageRateList = rate => ({
    type: UPDATE_QUICK_QUOTE_SHIPPING_PACKAGE_RATE_LIST,
    payload: rate
})

export const toggleQuickQuoteShippingPackageIsGetRate = isGetRate => ({
    type: TOGGLE_QUICK_QUOTE_SHIPPING_PACKAGE_IS_GET_RATE,
    payload: isGetRate
})

export const selectQuickQuoteShippingPackageRate = rate => ({
    type: SELECT_QUICK_QUOTE_SHIPPING_PACKAGE_RATE,
    payload: rate
})

export const updateCreateLabelShippingPackageMetricUnit = metric => ({
    type: UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_METRIC_UNIT,
    payload: metric
})

export const addCreateLabelShippingPackagePackageList = packageDetails => ({
    type: ADD_CREATE_LABEL_SHIPPING_PACKAGE_PACKAGE_LIST,
    payload: packageDetails
})

export const updateCreateLabelShippingPackagePackageList = packageDetails => ({
    type: UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_PACKAGE_LIST,
    payload: packageDetails
})

export const duplicateCreateLabelShippingPackagePackageList = packageDetails => ({
    type: DUPLICATE_CREATE_LABEL_SHIPPING_PACKAGE_PACKAGE_LIST,
    payload: packageDetails
})

export const removeCreateLabelShippingPackagePackageList = packageDetails => ({
    type: REMOVE_CREATE_LABEL_SHIPPING_PACKAGE_PACKAGE_LIST,
    payload: packageDetails
})

export const clearCreateLabelShippingPackagePackageList = () => ({
    type: CLEAR_CREATE_LABEL_SHIPPING_PACKAGE_PACKAGE_LIST,
})

export const updateCreateLabelShippingPackageReferenceOrderNumber = referenceOrderNumber => ({
    type: UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_REFERENCE_ORDER_NUMBER,
    payload: referenceOrderNumber
})

export const updateCreateLabelShippingPackageShipFrom = location => ({
    type: UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_SHIP_FROM,
    payload: location
})

export const updateCreateLabelShippingPackageShipTo = location => ({
    type: UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_SHIP_TO,
    payload: location
})

export const updateCreateLabelShippingPackageValidation = validation => ({
    type: UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_VALIDATION,
    payload: validation
})

export const updateCreateLabelShippingPackageRateList = rate => ({
    type: UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_RATE_LIST,
    payload: rate
})

export const clearCreateLabelShippingPackageRateList = () => ({
    type: CLEAR_CREATE_LABEL_SHIPPING_PACKAGE_RATE_LIST
})

export const selectCreateLabelShippingPackageRate = rate => ({
    type: SELECT_CREATE_LABEL_SHIPPING_PACKAGE_RATE,
    payload: rate
})

export const updateCreateLabelShippingPackageCrossBorder = crossBorder => ({
    type: UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_CROSS_BORDER,
    payload: crossBorder
})

export const initShippingPackageCrossBorderItemList = itemList => ({
    type: INIT_CREATE_LABEL_SHIPPING_PACKAGE_ITEM_LIST,
    payload: itemList
})

export const addShippingPackageCrossBorderItemList = itemList => ({
    type: ADD_CREATE_LABEL_SHIPPING_PACKAGE_ITEM_LIST,
    payload: itemList
})

export const updateShippingPackageCrossBorderItemList = itemDetail => ({
    type: UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_ITEM_LIST,
    payload: itemDetail
})

export const duplicateShippingPackageCrossBorderItemList = itemDetail => ({
    type: DUPLICATE_CREATE_LABEL_SHIPPING_PACKAGE_ITEM_LIST,
    payload: itemDetail
})

export const removeShippingPackageCrossBorderItemList = itemDetail => ({
    type: REMOVE_CREATE_LABEL_SHIPPING_PACKAGE_ITEM_LIST,
    payload: itemDetail
})

export const clearShippingPackageCrossBorderItemList = () => ({
    type: CLEAR_CREATE_LABEL_SHIPPING_PACKAGE_ITEM_LIST,
})

export const updateShippingPackageCrossBorderRate = rate => ({
    type: UPDATE_CREATE_LABEL_SHIPPING_PACKAGE_CROSS_BORDER_RATE,
    payload: rate
})

export const isCallAllPackageCarrier = index => ({
    type: IS_CALLED_ALL_PACKAGE_CARRIER,
    payload: index
})

export const initShippingPackageCreateLabelPackageList = packageList => ({
    type: INIT_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST,
    payload: packageList
})

export const updateShippingPackageQuickQuoteBatteryDetails = batteryDetails => ({
    type: UPDATE_SHIPPING_QUICK_QUOTE_BATTERY_DETAILS,
    payload: batteryDetails
})

export const updateShippingPackageCreateLabelBatteryDetails = batteryDetails => ({
    type: UPDATE_SHIPPING_CREATE_LABEL_BATTERY_DETAILS,
    payload: batteryDetails
})

export const updateShippingPackageQuickQuoteSignatureOptions = signatureOptions => ({
    type: UPDATE_SHIPPING_QUICK_QUOTE_SIGNATURE_OPTIONS,
    payload: signatureOptions
})

export const updateShippingPackageCreateLabelSignatureOptions = signatureOptions => ({
    type: UPDATE_SHIPPING_CREATE_LABEL_SIGNATURE_OPTIONS,
    payload: signatureOptions
})

export const updateShippingPackageInternationalShipmentShipFrom = address => ({
    type: UPDATE_SHIPPING_PACKAGE_INTERNATIONAL_SHIPMENT_SHIP_FROM,
    payload: address
})

export const updateShippingPackageInternationalShipmentShipTo = address => ({
    type: UPDATE_SHIPPING_PACKAGE_INTERNATIONAL_SHIPMENT_SHIP_TO,
    payload: address
})

export const updateShippingPackageReturnLabelStatus = returnLabel => ({
    type: UPDATE_SHIPPING_PACKAGE_RETURN_LABEL_STATUS,
    payload: returnLabel
})

export const updateShippingPackageSchedulePickupInformation = pickupInformation => ({
    type: UPDATE_SHIPPING_PACKAGE_SCHEDULE_PICKUP_INFORMATION,
    payload: pickupInformation
})

export const updateShippingPackageSchedulePickupRate = pickupRate => ({
    type: UPDATE_SHIPPING_PACKAGE_SCHEDULE_PICKUP_RATE,
    payload: pickupRate
})