import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as React from "react";
import dayjs from "dayjs";
import {Button, Divider, Link} from "@mui/material";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {Fragment, useEffect, useState} from "react";
import {convertCubicInToCubicMeter, convertLBToKG, movingOrderToCancel} from "../../Utils/Helper";

export const MovingOrderDashboardTransaction = ({orderDetails}) => {

    const isMobile = useMediaQuery('(max-width: 480px)');

    const [additionalData, setAdditionalData] = useState(null);
    const [additionalService, setAdditionalService] = useState([]);
    const [storageService, setStorageService] = useState({
        cubicMeterSpace: 0,
        storageDays: 0
    });

    const isValidJSON = (jsonString) => {
        try {
            JSON?.parse(jsonString);
            return true;
        } catch (error) {
            return false;
        }
    }

    const isEmptyObject = (obj) => {
        return Object.keys(obj).length === 0;
    }

    useEffect(() => {
        if (orderDetails?.messages) {
            const jsonString = orderDetails?.messages;
            const json = JSON?.parse(jsonString);

            console.log('json', json);

            if (isValidJSON(jsonString) && !isEmptyObject(json)) {
                const jsonData = JSON?.parse(jsonString);
                setAdditionalData(jsonData);
                const additionalServicesArray = Object?.keys(jsonData?.additionalService)
                    .filter(service => jsonData?.additionalService[service] === true)
                    .map(service => {
                        const capitalizedService = service.charAt(0).toUpperCase() + service.slice(1);
                        return capitalizedService.replace(/([a-z])([A-Z])/g, '$1 $2');
                    });
                console.log('[MovingOrderDashboardTransaction] additionalServicesArray', additionalServicesArray);
                setAdditionalService(additionalServicesArray);
            }

            // For Storage Service
            if (isValidJSON(jsonString)) {
                const jsonData = JSON?.parse(jsonString);
                if (jsonData?.additionalService?.storageService) {
                    const jsonStorageService = jsonData?.additionalService?.storageService;
                    setStorageService(jsonStorageService);
                }
            }

        }
    }, [orderDetails])

    console.log('[MovingOrderDashboardTransaction] additionalData', additionalData);
    // console.log('[MovingOrderDashboardTransaction] additionalService', additionalService);

    return (
        <Box sx={{
            border: '1px solid #B2B2B2',
            borderRadius: '10px',
            padding: '10px 0'
        }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '20px',
                        borderBottom: '1px solid #B2B2B2',
                        alignItems: 'center'
                    }}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            Transportation Details
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{padding: '20px'}}>
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                            Moving From
                                        </Typography>
                                    </Grid>
                                    {
                                        orderDetails?.pickup_addr_type?.toUpperCase() === 'SCHEDULE' ?
                                            <>
                                                <Grid item xs={12}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        Scheduled Pickup
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        Address:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {orderDetails?.pickup_address}, {orderDetails?.pickup_area}, {orderDetails?.pickup_postal_code}, {orderDetails?.send_from_country}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        Property Type:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {additionalData?.movingFromAddress?.propertyType?.houseType}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        Floor Level:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {additionalData?.movingFromAddress?.floorLevel}
                                                    </Typography>
                                                </Grid>
                                                {/*<Grid item xs={4}>*/}
                                                {/*    <Typography style={{textAlign: 'left', fontSize: '14px'}}>*/}
                                                {/*        Distance from Truck to Door:*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={8}>*/}
                                                {/*    <Typography style={{textAlign: 'left', fontSize: '14px'}}>*/}
                                                {/*        {additionalData?.movingFromAddress?.distanceFromTruckToDoor}*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={4}>*/}
                                                {/*    <Typography style={{textAlign: 'left', fontSize: '14px'}}>*/}
                                                {/*        Staircase Number*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={8}>*/}
                                                {/*    <Typography style={{textAlign: 'left', fontSize: '14px'}}>*/}
                                                {/*        {additionalData?.movingFromAddress?.staircaseNumber}*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={4}>*/}
                                                {/*    <Typography style={{textAlign: 'left', fontSize: '14px'}}>*/}
                                                {/*        Narrow Street*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={8}>*/}
                                                {/*    <Typography style={{textAlign: 'left', fontSize: '14px'}}>*/}
                                                {/*        {additionalData?.movingFromAddress?.isNarrowStreet ? "YES" : "NO"}*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={4}>*/}
                                                {/*    <Typography style={{textAlign: 'left', fontSize: '14px'}}>*/}
                                                {/*        Steep Driveway*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={8}>*/}
                                                {/*    <Typography style={{textAlign: 'left', fontSize: '14px'}}>*/}
                                                {/*        {additionalData?.movingFromAddress?.isSteepDriveway ? "YES" : "NO"}*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                <Grid item xs={4}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        Lift:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {additionalData?.movingFromAddress?.lift ? 'Yes' : 'No'}
                                                    </Typography>
                                                </Grid>
                                                {/*<Grid item xs={4}>*/}
                                                {/*    <Typography style={{textAlign: 'left', fontSize: '14px'}}>*/}
                                                {/*        Storey:*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={8}>*/}
                                                {/*    <Typography style={{textAlign: 'left', fontSize: '14px'}}>*/}
                                                {/*        {additionalData?.movingFromAddress?.storey}*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                            </>
                                            :
                                            <>
                                                <Grid item xs={12}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        Self Drop-Off
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        Warehouse Name:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {orderDetails?.pickup_warehouse?.name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        Warehouse Address:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {orderDetails?.pickup_warehouse?.address}, {orderDetails?.pickup_warehouse?.city}, {orderDetails?.pickup_warehouse?.province}, {orderDetails?.pickup_warehouse?.country}, {orderDetails?.pickup_warehouse?.postal_code}
                                                    </Typography>
                                                </Grid>
                                            </>
                                    }
                                </Grid>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                            Moving To
                                        </Typography>
                                    </Grid>
                                    {
                                        orderDetails?.deliver_addr_type?.toUpperCase() === 'SCHEDULE' ?
                                            <>
                                                <Grid item xs={12}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        Scheduled Delivery
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        Address:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {orderDetails?.deliver_address}, {orderDetails?.deliver_area}, {orderDetails?.deliver_postal_code}, {orderDetails?.send_to_country}
                                                    </Typography>
                                                </Grid>
                                                {/*<Grid item xs={4}>*/}
                                                {/*    <Typography style={{textAlign: 'left', fontSize: '14px'}}>*/}
                                                {/*        Property Type:*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={8}>*/}
                                                {/*    <Typography style={{textAlign: 'left', fontSize: '14px'}}>*/}
                                                {/*        {additionalData?.movingToAddress?.property?.propertyType}*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                <Grid item xs={4}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        Property Type:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {additionalData?.movingToAddress?.propertyType?.houseType}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        Floor Level:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {additionalData?.movingToAddress?.floorLevel}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        Lift:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {additionalData?.movingToAddress?.lift ? 'Yes' : 'No'}
                                                    </Typography>
                                                </Grid>
                                                {/*<Grid item xs={4}>*/}
                                                {/*    <Typography style={{textAlign: 'left', fontSize: '14px'}}>*/}
                                                {/*        Distance from Truck to Door:*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={8}>*/}
                                                {/*    <Typography style={{textAlign: 'left', fontSize: '14px'}}>*/}
                                                {/*        {additionalData?.movingToAddress?.distanceFromTruckToDoor}*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={4}>*/}
                                                {/*    <Typography style={{textAlign: 'left', fontSize: '14px'}}>*/}
                                                {/*        Staircase Number*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={8}>*/}
                                                {/*    <Typography style={{textAlign: 'left', fontSize: '14px'}}>*/}
                                                {/*        {additionalData?.movingToAddress?.staircaseNumber}*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={4}>*/}
                                                {/*    <Typography style={{textAlign: 'left', fontSize: '14px'}}>*/}
                                                {/*        Narrow Street*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={8}>*/}
                                                {/*    <Typography style={{textAlign: 'left', fontSize: '14px'}}>*/}
                                                {/*        {additionalData?.movingToAddress?.isNarrowStreet ? "YES" : "NO"}*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={4}>*/}
                                                {/*    <Typography style={{textAlign: 'left', fontSize: '14px'}}>*/}
                                                {/*        Steep Driveway*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={8}>*/}
                                                {/*    <Typography style={{textAlign: 'left', fontSize: '14px'}}>*/}
                                                {/*        {additionalData?.movingToAddress?.isSteepDriveway ? "YES" : "NO"}*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={4}>*/}
                                                {/*    <Typography style={{textAlign: 'left', fontSize: '14px'}}>*/}
                                                {/*        Lift:*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={8}>*/}
                                                {/*    <Typography style={{textAlign: 'left', fontSize: '14px'}}>*/}
                                                {/*        {additionalData?.movingToAddress?.lift}*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={4}>*/}
                                                {/*    <Typography style={{textAlign: 'left', fontSize: '14px'}}>*/}
                                                {/*        Storey:*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={8}>*/}
                                                {/*    <Typography style={{textAlign: 'left', fontSize: '14px'}}>*/}
                                                {/*        {additionalData?.movingToAddress?.storey}*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                            </>
                                            :
                                            <>
                                                <Grid item xs={12}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        Self Pickup
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        Warehouse Name:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {orderDetails?.deliver_warehouse?.name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        Warehouse Address:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {orderDetails?.deliver_warehouse?.address}, {orderDetails?.deliver_warehouse?.city}, {orderDetails?.deliver_warehouse?.province}, {orderDetails?.deliver_warehouse?.country}, {orderDetails?.deliver_warehouse?.postal_code}
                                                    </Typography>
                                                </Grid>
                                            </>
                                    }
                                </Grid>
                            </Grid>
                            {
                                orderDetails?.itemList?.length > 0 &&
                                <Grid item md={12} xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                                Item List
                                            </Typography>
                                        </Grid>
                                        {
                                            !isMobile &&
                                            <>
                                                <Grid item xs={5}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={6}>
                                                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                                Category
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                                Description
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        Tracking Number
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        Dimension
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        Weight
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider/>
                                                </Grid>
                                            </>
                                        }
                                        {
                                            orderDetails?.itemList?.map((item, index) => (
                                                <Fragment key={index}>
                                                    <Grid item md={5} xs={12}>
                                                        <Grid container spacing={1}>
                                                            <Grid item md={6} xs={12}>
                                                                <Typography
                                                                    style={{textAlign: 'left', fontSize: '14px'}}>
                                                                    {isMobile && 'Category: '}{item?.category_name}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item md={6} xs={12}>
                                                                <Typography
                                                                    style={{textAlign: 'left', fontSize: '14px'}}>
                                                                    {isMobile && 'Description: '}{item?.item_description}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item md={3} xs={12}>
                                                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                            {isMobile && 'Tracking Number: '}{item?.trackingNumber}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item md={2} xs={12}>
                                                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                            {isMobile && 'Dimension: '}{item?.length}x{item?.width}x{item?.height} {item?.dimension_unit}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item md={2} xs={12}>
                                                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                            {isMobile && 'Weight: '}{item?.weight} {item?.weight_unit}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Divider/>
                                                    </Grid>
                                                </Fragment>
                                            ))
                                        }
                                    </Grid>
                                </Grid>
                            }
                            {
                                orderDetails?.boxList?.length > 0 &&
                                <Grid item md={12} xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                                Box List
                                            </Typography>
                                        </Grid>
                                        {
                                            !isMobile &&
                                            <>
                                                <Grid item xs={orderDetails?.order_sub_type !== 'EXPRESS' ? 6 : 5}>
                                                    <Grid container spacing={1}>
                                                        <Grid item
                                                              xs={orderDetails?.order_sub_type !== 'EXPRESS' ? 3 : 5}>
                                                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                                Category
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item
                                                              xs={orderDetails?.order_sub_type !== 'EXPRESS' ? 4 : 7}>
                                                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                                Description
                                                            </Typography>
                                                        </Grid>
                                                        {
                                                            orderDetails?.order_sub_type !== 'EXPRESS' &&
                                                            <Grid item xs={5}>
                                                                <Typography
                                                                    style={{textAlign: 'left', fontSize: '14px'}}>
                                                                    Tracking Number
                                                                </Typography>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={orderDetails?.order_sub_type !== 'EXPRESS' ? 6 : 7}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={6}>
                                                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                                Dimension
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                                Weight
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                                Quantity
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider/>
                                                </Grid>
                                            </>
                                        }
                                        {
                                            orderDetails?.boxList?.map((item, index) => (
                                                <Fragment key={index}>
                                                    <Grid item xs={orderDetails?.order_sub_type !== 'EXPRESS' ? 6 : 5}>
                                                        <Grid container spacing={1}>
                                                            <Grid item
                                                                  xs={orderDetails?.order_sub_type !== 'EXPRESS' ? 3 : 5}>
                                                                <Typography
                                                                    style={{
                                                                        textAlign: 'left',
                                                                        fontSize: '14px',
                                                                        overflowWrap: 'break-word'
                                                                    }}>
                                                                    {isMobile && 'Category: '}{item?.category_name}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item
                                                                  xs={orderDetails?.order_sub_type !== 'EXPRESS' ? 4 : 7}>
                                                                <Typography
                                                                    style={{
                                                                        textAlign: 'left',
                                                                        fontSize: '14px',
                                                                        overflowWrap: 'break-word'
                                                                    }}>
                                                                    {isMobile && 'Description: '}{item?.item_description}
                                                                </Typography>
                                                            </Grid>
                                                            {
                                                                orderDetails?.order_sub_type !== 'EXPRESS' &&
                                                                <Grid item md={5} xs={12}>
                                                                    <Typography
                                                                        style={{textAlign: 'left', fontSize: '14px'}}>
                                                                        {isMobile && 'Tracking Number: '}{item?.trackingNumber}
                                                                    </Typography>
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={orderDetails?.order_sub_type !== 'EXPRESS' ? 6 : 7}>
                                                        <Grid container spacing={1}>
                                                            <Grid item md={6} xs={12}>
                                                                <Typography style={{
                                                                    textAlign: 'left',
                                                                    fontSize: '14px',
                                                                    overflowWrap: 'break-word'
                                                                }}>
                                                                    {isMobile && 'Dimension: '}{item?.length}x{item?.width}x{item?.height} {item?.dimension_unit}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item md={3} xs={12}>
                                                                <Typography
                                                                    style={{
                                                                        textAlign: 'left',
                                                                        fontSize: '14px',
                                                                        overflowWrap: 'break-word'
                                                                    }}>
                                                                    {isMobile && 'Weight: '}{item?.weight} {item?.weight_unit}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography
                                                                    style={{
                                                                        textAlign: 'left',
                                                                        fontSize: '14px',
                                                                        overflowWrap: 'break-word'
                                                                    }}>
                                                                    {isMobile && 'Quantity: '}{item?.number}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Divider/>
                                                    </Grid>
                                                </Fragment>
                                            ))
                                        }
                                    </Grid>
                                </Grid>
                            }
                            {
                                orderDetails?.boxList?.length > 0 &&
                                <Grid item md={12} xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                                Box Summary
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                Total
                                                Volume: {(+orderDetails?.boxVolumes)?.toFixed(2)} {orderDetails?.dimensionUnit}{'\u00B3'} / {(+convertCubicInToCubicMeter(+orderDetails?.boxVolumes)).toFixed(2)} m{'\u00B3'}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                Total
                                                Weight: {(+orderDetails?.boxWeights)?.toFixed(2)} {orderDetails?.weightUnit} / {(+convertLBToKG(+orderDetails?.boxWeights)).toFixed(2)} kg
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                Quantity: {+orderDetails?.boxQuantity}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            {
                                <Grid item md={6} xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                                Scheduled Date
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                Pickup Date:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            {
                                                (orderDetails?.shipping_status_id === 1 || orderDetails?.shipping_status_id === 2 || orderDetails?.shipping_status_id === 20) ?
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {orderDetails?.scheduled_pickup_date ? dayjs(orderDetails?.scheduled_pickup_date).format('YYYY-MM-DD') : 'To be determined'}
                                                    </Typography>
                                                    :
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {orderDetails?.schedule_pickup ? dayjs(orderDetails?.schedule_pickup).format("YYYY-MM-DD") : 'To be determined'}
                                                    </Typography>
                                            }
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                Deliver Date:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            {
                                                (orderDetails?.shipping_status_id === 1 || orderDetails?.shipping_status_id === 2 || orderDetails?.shipping_status_id === 20) ?
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {orderDetails?.scheduled_deliver_date ? dayjs(orderDetails?.scheduled_deliver_date).format('YYYY-MM-DD') : 'To be determined'}
                                                    </Typography>
                                                    :
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {orderDetails?.schedule_deliver ? dayjs(orderDetails?.schedule_deliver).format("YYYY-MM-DD") : 'To be determined'}
                                                    </Typography>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            {
                                <Grid item md={6} xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                                Scheduled Time
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                Pickup Time:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            {
                                                (orderDetails?.shipping_status_id === 1 || orderDetails?.shipping_status_id === 2 || orderDetails?.shipping_status_id === 20) ?
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {orderDetails?.scheduled_pickup_date ? dayjs(orderDetails?.scheduled_pickup_date).format('HH:mm') : 'To be determined'}
                                                    </Typography>
                                                    :
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {orderDetails?.task_pickedup_at ? dayjs(orderDetails?.task_pickedup_at).format("HH:mm") : 'To be determined'}
                                                    </Typography>
                                            }
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                Deliver Time:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            {
                                                (orderDetails?.shipping_status_id === 1 || orderDetails?.shipping_status_id === 2 || orderDetails?.shipping_status_id === 20) ?
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {orderDetails?.scheduled_deliver_date ? dayjs(orderDetails?.scheduled_deliver_date).format('HH:mm') : 'To be determined'}
                                                    </Typography>
                                                    :
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {orderDetails?.task_delivered_at ? dayjs(orderDetails?.task_delivered_at).format("HH:mm") : 'To be determined'}
                                                    </Typography>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            {
                                additionalService?.length > 0 &&
                                <Grid item md={12} xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                                Additional Service
                                            </Typography>
                                        </Grid>
                                        {
                                            additionalService?.map((service, index) => (
                                                <Grid item xs={3} key={index}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {service}
                                                    </Typography>
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </Grid>
                            }
                            {
                                storageService?.storageDays > 0 && (
                                    <Grid item md={12} xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                                    Storage Service
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                    Storage Space: {storageService.cubicMeterSpace} m³
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                    Storage Days: {storageService.storageDays} day(s)
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            }
                            {
                                additionalData?.movingRemarkNotes &&
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                                Remark Notes
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                {additionalData.movingRemarkNotes}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}
