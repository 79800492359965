import React, {useState, useEffect} from "react";
// import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {
    AppBar,
    Tabs,
    Tab,
    Box,
    useMediaQuery,
    Container,
    Button,
} from "@material-ui/core";
import {Alert, Pagination} from "@material-ui/lab";
// import store from '../../store/index'
import {useHistory, useLocation, useParams} from "react-router-dom";
// import { useDispatch } from 'react-redux';
import axios from "axios";
// import {BOL_LIST, PARTNER_URI} from "../../utils/apiUrl";
import {useSelector} from "react-redux";
import dayjs from "dayjs";
import {LoadingContainer} from "../shared";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import {getAccessToken} from "../../Utils/doToken";

const tabNumber = ["Initial", "Processed", "Processing", "Cancelled"];

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tabpanel-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={2} pt={1}>
                    {children}
                </Box>
            )}
        </Box>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
}

const ShippingOrderListPage = () => {
    const history = useHistory();
    // const dispatch = useDispatch();
    const {tab: defaultTab} = useParams();

    const [page, setPage] = useState(1);

    const [lastPage, setLastPage] = useState(0);

    // const [userType, setuserType] = useState('')
    // const [isLoading, setLoadingState] = useState(true)
    const [errorMsg, setErrorMsg] = useState("");
    const [userBillData, setUserBillData] = useState([]);
    const [palletListData, setPalletListData] = useState([]);
    const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    const classes = useStyles({matches});
    const theme = useTheme();
    const value =
        tabNumber.indexOf(defaultTab || "created") === -1
            ? 0
            : tabNumber.indexOf(defaultTab || "created");

    const [pageLoading, setPageLoading] = useState(false);
    const [statusValue, setStatusValue] = useState(0);

    const [tabClick, setTabClick] = useState(false);

    const getShippingListDataByPartnerId = async (status) => {
        const accessToken = getAccessToken("access_token");
        return await axios.post(`${NODE_ROUTE_URI}/looseItem/getBackendPackageOrderListByUserIdAndRequestStatusArray`, {
            requestStatusArray: status
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
    }

    const getInitRequestData = async () => {
        const {data} = await getShippingListDataByPartnerId([0]);
        const result = data.results;
        return result
    }
    const getProcessedRequestData = async () => {
        const {data} = await getShippingListDataByPartnerId([1]);
        const result = data.results;
        return result
    }
    const getProcessingRequestData = async () => {
        const {data} = await getShippingListDataByPartnerId([2]);
        const result = data.results;
        return result
    }
    const getCancelledRequestData = async () => {
        const {data} = await getShippingListDataByPartnerId([3]);
        const result = data.results;
        return result
    }

    useEffect(() => {
        (async () => {
            try {
                setPageLoading(true);
                if (value === 0) {
                    const listData = await getInitRequestData();
                    setPalletListData(listData);
                } else if (value === 1) {
                    const listData = await getProcessedRequestData();
                    setPalletListData(listData);
                } else if (value === 2) {
                    const listData = await getProcessingRequestData();
                    setPalletListData(listData);
                } else if (value === 3) {
                    const listData = await getCancelledRequestData();
                    setPalletListData(listData);
                }
            } catch (e) {
                console.log(e);
            } finally {
                setPageLoading(false);
            }
        })()
    }, [value]);

    // useEffect(() => {
    //   if (value || value === 0) {
    //     setPageLoading(true);
    //     setStatusValue(value);
    //     const getParam = (tabNum) => {
    //       // 0 = created
    //       if (tabNum === 0) return { status: 0 };
    //       // 1 = accepted
    //       if (tabNum === 1) return { status: 1 };
    //       // -1 = rejected
    //       if (tabNum === 2) return { status: -1 };
    //     };
    //     setUserBillData([]);
    //
    //     axios
    //       .get(
    //         getURLParams(BOL_LIST, {
    //           page: page,
    //           ...getParam(statusValue),
    //         }),
    //         { headers: getHeaders() }
    //       )
    //       .then((response) => {
    //         const currentData = response.data;
    //         currentData.result && setUserBillData(currentData.result);
    //         setLastPage(currentData.total_page);
    //         setPageLoading(false);
    //         setTabClick(false);
    //       })
    //       .catch((err) => {
    //         console.log(JSON.stringify(err));
    //         errHandling(err, setErrorMsg);
    //         setPageLoading(false);
    //         setTabClick(false);
    //       });
    //   }
    // }, [page, value]);

    const handleChange = (event, newValue) => {
        if (newValue !== value) {
            setPage(1);
            setTabClick(true);
        }
        // setValue(newValue);
        history.push(`/shippingOrderList/${tabNumber[newValue]}`);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const getDate = (date) => {
        return date.split(" ")[0];
    };

    // const handleChangeIndex = (index) => {
    //     setValue(index);
    // };

    const goRequestDetailsPage = (requestId) => {
        const path = `/shippingOrderDetails/${requestId}`;
        history.push(path);
    };

    useEffect(() => {
        console.log("Pallets Data: ", palletListData);
    }, [palletListData])

    const panelContent =
        palletListData?.length === 0 ? (
            <Box>
                <br/>
                <br/>
                There is no record for this type.
            </Box>
        ) : (
            <>
                {palletListData.map((request, key) => {
                    return (
                        <Box
                            key={request.request_id}
                            // borderBottom="1px solid rgba(61, 21, 21, 0.3)"
                            pt="1.5vh"
                            className={classes.itemWrapper}
                        >
                            <Box
                                className="order-list-text"
                                display="flex"
                                justifyContent="space-between"
                                flexDirection={matches ? "column" : "row"}
                            >
                                {/* <Box pr={1}><img alt={`order-${order.id}`} /></Box> */}
                                <Box
                                    display={matches ? "flex" : "block"}
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Box
                                        content="span"
                                        fontWeight="700"
                                        // className={matches ? classes.textTitle : ""}
                                    >
                                        Order #
                                    </Box>
                                    {" "}
                                    {/* {matches ? "" : <br />} */}
                                    <Box flexGrow="1">
                                        <Button
                                            variant="text"
                                            onClick={() => goRequestDetailsPage(request.id)}
                                            className={classes.linkText}
                                        >
                                            {/*{bol.bol_id} / {bol.bol_no}*/}
                                            {request.order_number}
                                        </Button>
                                    </Box>
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    textAlign="center"
                                    alignItems="center"
                                    flexDirection="column"
                                    sx={{gap: "0.4rem"}}
                                >
                                    <Box
                                        content="span"
                                        className={matches ? classes.textTitle : ""}
                                        display="flex"
                                    >
                                        {/*Shipper*/}
                                        Sender
                                    </Box>
                                    <Box style={{fontStyle: "italic"}} flexGrow="1">
                                        {/*{getDate(bol.created_at)}*/}
                                        {/*{bol.shipper_name}*/}
                                        {request.sender}
                                        <br/>
                                        {/*{bol.shipper_full_address}*/}
                                        {request.pickup_address}
                                        <br/>
                                        {request.pickup_area}
                                        <br/>
                                        {request.pickup_postal_code}
                                    </Box>
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    textAlign="center"
                                    alignItems="center"
                                    flexDirection="column"
                                    sx={{gap: "0.4rem"}}
                                >
                                    <Box
                                        content="span"
                                        className={matches ? classes.textTitle : ""}
                                    >
                                        {/*Consignee*/}
                                        Deliver
                                    </Box>{" "}
                                    {/* {matches ? "" : <br />} */}
                                    <Box style={{fontStyle: "italic"}} flexGrow="1">
                                        {request.deliver_name}
                                        <br/>
                                        {/*{bol.shipper_full_address}*/}
                                        {request.deliver_address}
                                        <br/>
                                        {request.deliver_area}
                                        <br/>
                                        {request.deliver_postal_code}
                                    </Box>
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    textAlign="center"
                                    alignItems="center"
                                    flexDirection="column"
                                    sx={{gap: "0.4rem"}}
                                >
                                    <Box
                                        content="span"
                                        className={matches ? classes.textTitle : ""}
                                        display="flex"
                                        pb={1}
                                    >
                                        Servce Type
                                    </Box>
                                    <Box flexGrow="1">
                                        {request?.account_base_combine_name?.split("-")[0]} - {request.ship_service_type}
                                    </Box>
                                </Box>
                                <Box
                                    display={matches ? "flex" : "block"}
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Box
                                        content="span"
                                        fontWeight="700"
                                    >
                                        Order Date
                                    </Box>
                                    <Box flexGrow="1">
                                        {/*<span  className={classes.linkText}>*/}
                                        {/*  {(bol?.etd_date)}*/}
                                        {/*</span>*/}
                                        <h3>
                                            {/*{(bol?.etd_date)}*/}
                                            {request.created_at ? dayjs(request.created_at).format("YYYY-MM-DD") : (
                                                <b style={{visibility: "hidden"}}>YYYY-MM-DD</b>
                                            )}
                                        </h3>
                                    </Box>
                                </Box>
                            </Box>
                            {/* <Box m={1}>
                {bill.packages.map((item, index) => {
                  return (
                    <Box
                      className={classes.pkgWrapper}
                      key={item.package_number}
                      mb="10px"
                    >
                      <Box
                        className={classes.cardContent}
                        display="flex"
                        alignItems="center"
                        role="button"
                        width="100%"
                        onClick={() =>
                          goOrderDetailPage(bill.order_number, index, true)
                        }
                      >
                        <Box
                          height="70px"
                          width="70px"
                          position="relative"
                          ml={1}
                          mr={2}
                        >
                          <img
                            src={item.cover_image}
                            alt={`bill-${bill.id}`}
                            className={classes.pkgImg}
                          />
                        </Box>
                        <Box color="seagreen" textAlign="center" flexGrow="1">
                          {item.item_name}
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Box> */}
                            <hr/>
                        </Box>
                    );
                })}
            </>
        );

    const pageSelection = lastPage > 1 && !tabClick && (
        <div className={classes.pagination}>
            <Pagination
                className={classes.pagination}
                count={lastPage}
                page={page}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
            />
        </div>
    );

    return (
        <Container>
            {/* <div className="order-list-container"> */}
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt={3}
                mb={3}
            >
            </Box>
            <h1>My Shipping Orders</h1>
            <div className={classes.root}>
                <AppBar position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        // centered
                        // scrollButtons="auto"
                    >
                        <Tab label="Not Paid" {...a11yProps(0)} />
                        <Tab label="Active" {...a11yProps(1)} />
                        <Tab label="Cancelled" {...a11yProps(2)} />
                        {/* <Tab label="Canceled by backend" {...a11yProps(3)} /> */}
                    </Tabs>
                </AppBar>
                {/* {isLoading ? <LoadingContainer /> : */}
                {/* (<> */}
                <TabPanel
                    value={value}
                    index={0}
                    dir={theme.direction}
                    classes={{root: classes.tabRoot}}
                >
                    <Box>
                        {pageLoading ? <LoadingContainer/> : panelContent}
                        {pageSelection}
                    </Box>
                </TabPanel>
                <TabPanel
                    value={value}
                    index={1}
                    dir={theme.direction}
                    classes={{root: classes.tabRoot}}
                >
                    <Box>
                        {pageLoading ? <LoadingContainer/> : panelContent}
                        {pageSelection}
                    </Box>
                </TabPanel>
                <TabPanel
                    value={value}
                    index={2}
                    dir={theme.direction}
                    classes={{root: classes.tabRoot}}
                >
                    <Box>
                        {pageLoading ? <LoadingContainer/> : panelContent}
                        {pageSelection}
                    </Box>
                </TabPanel>
                <TabPanel
                    value={value}
                    index={3}
                    dir={theme.direction}
                    classes={{root: classes.tabRoot}}
                >
                    <Box>
                        {pageLoading ? <LoadingContainer/> : panelContent}
                        {pageSelection}
                    </Box>
                </TabPanel>
                {/* </>)} */}
                <Box pt="1vh" width="100%">
                    {errorMsg ? <Alert severity="error">{errorMsg}</Alert> : ""}
                </Box>
            </div>
            {/* </div> */}
        </Container>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        // backgroundColor: theme.palette.background.paper,
        // backgroundColor: 'white',
        // border: "2px solid #000",
        // boxShadow: theme.shadows[5],
        // padding: theme.spacing(2, 4, 2),
        // width: props => props.matches ? '90vw': '80vw',
        width: "100%",
        // height: '80vh',
        // borderRadius: '20px',
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        // overflow: "scroll",
        // margin: '0 3vw'
    },
    // panel: {
    //   backgroundColor: "white",
    // },
    tabRoot: {
        // height: '100%',
        overflow: "none",
        height: "65vh",
    },
    // headerRoot: {
    //     marginTop: '0',
    // },
    pagination: {
        "& > *": {
            marginTop: theme.spacing(2),
        },
        display: "flex",
        justifyContent: "center",
    },
    linkText: {
        fontSize: "1rem !important",
        color: "blue",
    },
    pkgImg: {
        margin: "auto",
        maxWidth: "100%",
        maxHeight: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    },
    pkgWrapper: {
        backgroundColor: "#fdf5e8",
        padding: "3px",
        cursor: "pointer",
        "&:hover": {
            // border: '1px solid rgba(0,0,0,0.3)',
            backgroundColor: "rgba(0,0,0,0.1)",
            borderRadius: "5px",
        },
    },
    textTitle: {
        flexBasis: "40%",
        textAlign: "left",
    },
    activeWrapper: {
        backgroundColor: "grey",
        color: "white",
        borderRadius: 5,
        padding: theme.spacing(0.5),
        height: "40px",
    },
    cardContent: {
        backgroundColor: "#fbfbf8",
        border: "1px solid #ded47c",
        borderRadius: "5px",
    },
    itemWrapper: {
        "& hr": {
            // border: '1px solid rgba(0,0,0,0.3)',
            marginLeft: -theme.spacing(2),
            marginRight: -theme.spacing(2),
            borderTop: "3px solid rgba(0,0,0,.1)",
        },
    },
}));

// const mapStateToProps = state => ({
//     orders: state.orders,
//     packages: state.packages
// })

// export default connect(mapStateToProps, {getOrders, getPackages, getOrderShipments})(OrderList)
export default ShippingOrderListPage;
