import {Divider, useMediaQuery} from "@mui/material";
import Typography from "@mui/material/Typography";
import {ShippingOrderListEach} from "./ShippingOrderListEach";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import intl from "react-intl-universal";
import Grid from "@mui/material/Grid";

export const ShippingOrderList = ({order}) => {

    const isMobile = useMediaQuery('(max-width: 480px)');

    const {firstname, id, isLoading} = useSelector((state) => state.user);

    const [openAction, setOpenAction] = useState(null);

    const cbSetOpenAction = (openActionNum) => {
        setOpenAction(openActionNum);
    }

    console.log('package order', order);

    return (
        <Grid container spacing={1}>
            <Grid item md={2.5} xs={4}>
                <Typography sx={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                    {intl.get('HOME_PAGE.SHIPPING_ORDER.ORDER')}
                </Typography>
            </Grid>
            {!isMobile &&
                <Grid item md={2}>
                    <Typography sx={{fontSize: '14px', fontWeight: '600', textAlign: 'left'}}>
                        {intl.get('HOME_PAGE.SHIPPING_ORDER.SENDER')}
                    </Typography>
                </Grid>
            }
            {!isMobile &&
                <Grid item md={2}>
                    <Typography sx={{fontSize: '14px', fontWeight: '600', textAlign: 'left'}}>
                        {intl.get('HOME_PAGE.SHIPPING_ORDER.RECIPIENT')}
                    </Typography>
                </Grid>
            }
            <Grid item md={1} xs={3}>
                <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                    {intl.get('HOME_PAGE.SHIPPING_ORDER.STATUS')}
                </Typography>
            </Grid>
            {!isMobile &&
                <Grid item md={1} xs={3}>
                    <Typography sx={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                        {intl.get('HOME_PAGE.SHIPPING_ORDER.FREIGHT')}
                    </Typography>
                </Grid>
            }
            {!isMobile &&
                <Grid item md={1} xs={3}>
                    <Typography sx={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                        {intl.get('HOME_PAGE.SHIPPING_ORDER.CARRIER')}
                    </Typography>
                </Grid>
            }
            <Grid item md={1.5} xs={3}>
                <Typography sx={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                    {intl.get('HOME_PAGE.SHIPPING_ORDER.CHARGE')}
                </Typography>
            </Grid>
            <Grid item md={1} xs={2}>
                <Typography sx={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                    {intl.get('HOME_PAGE.SHIPPING_ORDER.ACTION.ACTION')}
                </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
                <Divider/>
            </Grid>
            {
                order?.map((orderEach, index) =>
                    <Grid item xs={12} key={index}>
                        <ShippingOrderListEach key={index}
                                               index={index}
                                               order_info={orderEach}
                                               order_id={orderEach?.order_id}
                                               ref_order_no={orderEach?.ref_order_no}
                                               partner_id={id}
                                               total_amount={orderEach?.total_amount || 0}
                                               cargo_control_no={orderEach?.cargo_control_no}
                                               account_base={orderEach?.account_base}
                                               created_time={orderEach?.created_at}
                                               origi_shipping_item_file={orderEach?.origi_shipping_item_file}
                                               currency_code={orderEach?.currency_code || "CAD"}
                                               isCrossBorder={orderEach?.cross_border_shipment_type !== null}
                                               service_fee_include_gst={orderEach?.service_fee_include_gst}
                                               total_amount_include_tax={+orderEach?.total_amount_include_tax}
                                               openActionNum={openAction}
                                               cbSetOpenAction={cbSetOpenAction}
                                               finalTotal={+orderEach?.final_total}
                        />
                    </Grid>
                )
            }
        </Grid>
    )
}