import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import {useEffect, useState} from "react";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import {getAccessToken} from "../../../Utils/doToken";
import axios from "axios";
import {getAccordionDetailsUtilityClass} from "@mui/material";
import dayjs from "dayjs";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import {TrackingLTLEach} from "../../Tracking/Business/TrackingLTLEach";
import intl from "react-intl-universal";

const style = {
    LTLOrderDashboardTrackingRoot: {
        border: '1px solid #B2B2B2',
        borderRadius: '10px',
        padding: '10px 0',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    }
}

export const LTLOrderDashboardTracking = ({orderData}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [trackingData, setTrackingData] = useState(null);
    const [error, setError] = useState("");
    const [viewDetails, setViewDetails] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const getTrackingInformation = async () => {
        setIsLoading(true);
        const requestURL = `${PARTNER_URI}/shippingLTLTracking/tracking`;
        const storedToken = getAccessToken("access_token");
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    trackingNumber: orderData?.bol_no
                    // trackingNumber: 'VAN6337994'
                }
            })
            console.log('result', result);
            setTrackingData(result?.data);
            setViewDetails(true);
        } catch (e) {
            console.log('error', e.response);
            if (e?.response?.status === 401) {
                setErrorMessage(e?.response?.data?.error);
            } else {
                setErrorMessage(e?.response?.data?.error);
            }
            setViewDetails(false);
        } finally {
            setIsLoading(false);
        }
    }

    const handleGetTrackingDetails = () => {
        getTrackingInformation();
    }

    // useEffect(() => {
    //     orderData?.bol_no && getTrackingInformation();
    // }, []);

    if (!orderData?.bol_no) {
        return (
            <Box sx={style.LTLOrderDashboardTrackingRoot}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            padding: '10px',
                            borderBottom: '1px solid #B2B2B2'
                        }}>
                            <Typography style={{fontSize: '16px', fontWeight: '600'}}>
                                Tracking Information
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '150px'
                        }}>
                            <Typography sx={{fontSize: '16px', fontWeight: '600', color: '#B2B2B2', textAlign: 'center'}}>
                                There is no available tracking information.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    // if (isLoading) {
    //     return (
    //         <Box sx={style.LTLOrderDashboardTrackingRoot}>
    //             <Grid container spacing={2}>
    //                 <Grid item xs={12}>
    //                     <Box sx={{
    //                         display: 'flex',
    //                         justifyContent: 'flex-start',
    //                         padding: '10px',
    //                         borderBottom: '1px solid #B2B2B2'
    //                     }}>
    //                         <Typography style={{fontSize: '16px', fontWeight: '600'}}>
    //                             Tracking Information
    //                         </Typography>
    //                     </Box>
    //                 </Grid>
    //                 <Grid item xs={12}>
    //                     <CircularProgress/>
    //                 </Grid>
    //             </Grid>
    //         </Box>
    //     )
    // }

    return (
        <Box sx={style.LTLOrderDashboardTrackingRoot}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '10px',
                        borderBottom: '1px solid #B2B2B2'
                    }}>
                        <Typography style={{fontSize: '16px', fontWeight: '600'}}>
                            {intl.get('ORDER_DASHBOARD.TRACKING_INFORMATION.TRACKING_INFORMATION')}
                        </Typography>
                    </Box>
                </Grid>
                {
                    orderData?.shipping_status_id === 2 ? <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                padding: '10px',
                                gap: '20px'
                            }}>
                                <img src={"https://storage.googleapis.com/uucargo-resource/image/icon/tracking_delivered.png"} alt="tacking_logo" width={60}/>
                                <Box>
                                    <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'left'}}>
                                        {intl.get('ORDER_DASHBOARD.TRACKING_INFORMATION.DELIVERED')}
                                    </Typography>
                                    <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                        {intl.get('ORDER_DASHBOARD.TRACKING_INFORMATION.DELIVERED_MESSAGE')}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        : orderData?.shipping_status_id === 1 ? <Grid item xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    padding: '10px',
                                    gap: '20px'
                                }}>
                                    <img src={"https://storage.googleapis.com/uucargo-resource/image/icon/tracking_in_transit.png"} alt="tacking_logo" width={60}/>
                                    <Box>
                                        <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'left'}}>
                                            {intl.get('ORDER_DASHBOARD.TRACKING_INFORMATION.IN_TRANSIT')}
                                        </Typography>
                                        <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                            {intl.get('ORDER_DASHBOARD.TRACKING_INFORMATION.IN_TRANSIT_MESSAGE')}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            :
                            <Grid item xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    padding: '10px',
                                    gap: '20px'
                                }}>
                                    <img src={"https://storage.googleapis.com/uucargo-resource/image/icon/tracking_ready.png"} alt="tacking_logo" width={60}/>
                                    <Box>
                                        <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'left'}}>
                                            {intl.get('ORDER_DASHBOARD.TRACKING_INFORMATION.READY')}
                                        </Typography>
                                        <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                            {intl.get('ORDER_DASHBOARD.TRACKING_INFORMATION.READY_MESSAGE')}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                }
                {
                    orderData?.bol_no && <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            padding: '0 10px'
                        }}>
                            <LoadingButton
                                variant='outlined'
                                sx={{
                                    border: '2px solid #1D8B45',
                                    "&:hover": {
                                        border: '2px solid #1D8B45',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                onClick={handleGetTrackingDetails}
                                loading={isLoading}
                                disabled={viewDetails}
                            >
                                <Typography style={{
                                    textTransform: 'none',
                                    fontSize: '14px',
                                    color: '#1D8B45',
                                }}
                                >
                                    {intl.get('ORDER_DASHBOARD.TRACKING_INFORMATION.VIEW_DETAILS')}
                                </Typography>
                            </LoadingButton>
                        </Box>
                    </Grid>
                }
                {trackingData?.trackingResponse ?
                    <TrackingLTLEach trackingData={trackingData}/> :
                    <Grid item xs={12}>
                        <Box sx={{
                            padding: '0 10px'
                        }}>
                            <Typography style={{fontSize: '16px', fontWeight: '600', color: '#B2B2B2'}}>
                                {errorMessage}
                            </Typography>
                        </Box>
                    </Grid>
                }
            </Grid>
        </Box>
    )
}
