import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import * as React from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import LoadingButton from "@mui/lab/LoadingButton";
import {useEffect, useState} from "react";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EmailIcon from "../../../images/emailTransferIcon.png";
import CreditIcon from "../../../images/creditCardIcon.png";
import wechatPay from "../../../images/wechatPay.svg";
import aliPay from "../../../images/alipay.svg";
import {ReactComponent as ShipmentOverviewIcon} from "../../../images/NewDesignIcon/ShipmentOverview.svg";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import {MenuItem, Select} from "@mui/material";
import {BusinessShippingOrderDetailsCreditCard} from "./BusinessShippingOrderDetailsCreditCard";
import {ShippingOrderDetailsWeChat} from "../NewDesigin/ShippingOrderDetailsWeChat";
import {BusinessShippingOrderDetailsWeChat} from "./BusinessShippingOrderDetailsWeChat";
import {BusinessShippingOrderDetailsAliPay} from "./BusinessShippingOrderDetailsAliPay";
import {BusinessShippingOrderDetailsAvailableCredit} from "./BusinessShippingOrderDetailsAvailableCredit";
import axios from "axios";
import {GET_PARTNER_INFO_API} from "../../../Utils/apiUrl";
import {getAccessToken} from "../../../Utils/doToken";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormLabel from "@mui/material/FormLabel";
import {calTransactionFee} from "../../../Utils/Helper";

const styles = {
    ShippingPackageCreateLabelOrderDetailsRoot: {
        backgroundColor: '#FFFFFF',
        paddingBottom: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0'
    },
    ShippingPackageCreateLabelOrderDetailsHeading: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsSubHeading: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsTitle: {
        fontSize: '16px',
        textAlign: 'center'
    },
    ShippingPackageCreateLabelOrderDetailsTextLeft: {
        fontSize: '16px',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsTextRight: {
        fontSize: '16px',
        textAlign: 'right'
    },
    ShippingPackageCreateLabelOrderDetailsTextRightColor: {
        fontSize: '16px',
        textAlign: 'right',
        color: '#1D8B45'
    },
    ShippingPackageCreateLabelOrderDetailsTitleRight: {
        fontSize: '18px',
        textAlign: 'right',
        fontWeight: '600'
    },
    ShippingPackageCreateLabelOrderDetailsSection: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #A9A9A9',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelOrderDetailsSectionPrice: {
        // backgroundColor: '#FFFFFF',
        padding: '20px',
        // borderRadius: '10px 10px 0 0',
        // border: '2px solid #609966',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelOrderDetailsOtherBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsOtherBoxRight: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsOtherBoxCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsImageBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingLeft: '20px',
        gap: '10px'
    },
    ShippingOrderDetailsPayment: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'column',
        gap: '20px'
    },
}

export const BusinessShippingOrderDetailsPayment = ({
                                                        orderDetails,
                                                        orderId,
                                                        cbHandlePayment,
                                                    }) => {

    const storedToken = getAccessToken("access_token");

    const [selectPayment, setSelectPayment] = useState('card');
    const [availableCredit, setAvailableCredit] = useState(0);
    const [useCredit, setUseCredit] = useState(false);
    const [unpaidAmount, setUnpaidAmount] = useState(0);
    const [amountDue, setAmountDue] = useState(0);
    const [orderPrice, setOrderPrice] = useState(0);
    const [transactionFee, setTransactionFee] = useState(0);

    const getAvailableCredit = async () => {
        try {
            const result = await axios({
                method: 'get',
                url: GET_PARTNER_INFO_API,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                }
            });
            console.log('[BusinessShippingOrderDetailsPayment] - getAvailableCredit result', result);
            const {data: {overdraft_limit, current_bill_balance}} = result;
            const credit = overdraft_limit + current_bill_balance
            setAvailableCredit(credit);
            // setAvailableCredit(20); // test
        } catch (e) {
            console.log('[BusinessShippingOrderDetailsPayment] - getAvailableCredit error', e.response);
        }
    }

    const handleSelectPayment = (event) => {
        setSelectPayment(event?.target?.value);
    }

    const handleUseCredit = (event) => {
        setUseCredit(event?.target?.checked);
    }

    useEffect(() => {
        (async () => await getAvailableCredit()
        )()
    }, []);

    useEffect(() => {
        if (+availableCredit > +unpaidAmount) {
            setSelectPayment('credit');
        }
    },[availableCredit]);

    useEffect(() => {
        setUnpaidAmount(+orderDetails?.prices?.unpaidAmount);
        setOrderPrice(+orderDetails?.prices?.final_total);
    }, [orderDetails]);

    useEffect(() => {
        if (useCredit) {
            const amount = +unpaidAmount - +availableCredit;
            setUnpaidAmount(amount);
        } else {
            setUnpaidAmount(+orderDetails?.prices?.unpaidAmount);
        }
    }, [useCredit])

    useEffect(() => {
        cbHandlePayment(selectPayment);
        if (selectPayment === 'card') {
            const amount = +calTransactionFee(unpaidAmount);
            setTransactionFee(amount);
        } else {
            setTransactionFee(0);
        }
    }, [selectPayment, unpaidAmount]);

    useEffect(() => {
        const amount = +transactionFee + +unpaidAmount;
        setAmountDue(+amount);
    }, [transactionFee, unpaidAmount]);

    console.log('[BusinessShippingOrderDetailsPayment] availableCredit', availableCredit);
    console.log('[BusinessShippingOrderDetailsPayment] unpaidAmount', unpaidAmount);

    return (
        <Box sx={styles.ShippingPackageCreateLabelOrderDetailsRoot}>
            <Box sx={{
                display: 'flex',
                gap: '10px',
                padding: '20px 20px 0 20px'
            }}>
                <ShipmentOverviewIcon width={25} height={25}/>
                <Typography style={styles.ShippingPackageCreateLabelOrderDetailsHeading}>
                    Payment Methods
                </Typography>
            </Box>
            <Divider/>
            <Box sx={{
                backgroundColor: '#FFFFFF',
                padding: '0 40px',
                gap: '40px',
                display: 'flex',
            }}>
                <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <FormLabel>
                                        <Typography sx={{fontWeight: '600'}}>
                                            Select your payment method
                                        </Typography>
                                    </FormLabel>
                                    <Select
                                        value={selectPayment}
                                        size='small'
                                        onChange={handleSelectPayment}
                                    >
                                        <MenuItem value='card'>
                                            Credit Card
                                        </MenuItem>
                                        <MenuItem value='wechatPay'>
                                            WeChat Pay
                                        </MenuItem>
                                        <MenuItem value='aliPay'>
                                            Ali Pay
                                        </MenuItem>
                                        <MenuItem value="credit" disabled={+availableCredit === 0}>
                                            UUCargo Credit (available credit&nbsp;<span
                                            style={{fontWeight: '600'}}>${(+availableCredit)?.toFixed(2)}</span>)
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            {
                                (+availableCredit > 0 && +availableCredit < +orderDetails?.prices?.unpaidAmount && selectPayment !== 'credit') &&
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <FormLabel>
                                            <Typography sx={{fontWeight: '600'}}>
                                                UUCargo Credit
                                            </Typography>
                                        </FormLabel>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={useCredit}
                                                    onChange={handleUseCredit}
                                                    sx={{
                                                        color: 'green',
                                                        '&.Mui-checked': {
                                                            color: 'green',
                                                        },
                                                    }}
                                                    style={{
                                                        color: 'green',
                                                        '&.Mui-checked': {
                                                            color: 'green',
                                                        }
                                                    }}
                                                />
                                            }
                                            label={
                                                <>
                                                    Use Available Credit:&nbsp;
                                                    <span
                                                        style={{fontWeight: '600'}}>${(+availableCredit)?.toFixed(2)}</span>
                                                </>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <Typography
                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                    Order Price
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                    ${(+orderPrice)?.toFixed(2)}
                                </Typography>
                            </Grid>
                            {
                                useCredit &&
                                <>
                                    <Grid item xs={6}>
                                        <Typography
                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRightColor}>
                                            UUCargo Credit
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography
                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRightColor}>
                                            -${(+availableCredit)?.toFixed(2)}
                                        </Typography>
                                    </Grid>
                                </>
                            }
                            {
                                (selectPayment === 'card') &&
                                <>
                                    <Grid item xs={6}>
                                        <Typography
                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                            Transaction Fee
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography
                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                            ${(+transactionFee)?.toFixed(2)}
                                        </Typography>
                                    </Grid>
                                </>
                            }
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    style={styles.ShippingPackageCreateLabelOrderDetailsTitleRight}>
                                    Total Amount Due
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    style={styles.ShippingPackageCreateLabelOrderDetailsTitleRight}>
                                    ${(+amountDue)?.toFixed(2)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={styles.ShippingOrderDetailsPayment}>
                            {
                                selectPayment === 'card' &&
                                <BusinessShippingOrderDetailsCreditCard orderDetails={orderDetails} orderId={orderId}
                                                                        useCredit={useCredit}/>
                            }
                            {
                                selectPayment === 'wechatPay' &&
                                <BusinessShippingOrderDetailsWeChat orderDetails={orderDetails} orderId={orderId}
                                                                    useCredit={useCredit}/>
                            }
                            {
                                selectPayment === 'aliPay' &&
                                <BusinessShippingOrderDetailsAliPay orderDetails={orderDetails} orderId={orderId}
                                                                    useCredit={useCredit}/>
                            }
                            {
                                selectPayment === 'credit' &&
                                <BusinessShippingOrderDetailsAvailableCredit orderDetails={orderDetails}
                                                                             orderId={orderId}
                                                                             availableCredit={availableCredit}/>
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>

    )
}