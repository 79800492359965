import Box from "@mui/material/Box";
import {Button, IconButton, Snackbar} from "@mui/material";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getAccessToken} from "../../Utils/doToken";
import {useHistory} from "react-router-dom";
import {extractAddress, getCountryCodeByName, getMissingKeys, loadAsyncScript} from "../../Utils/Helper";
import {toggleAddAddress, toggleEditAddress} from "../../actions/addressBookAction";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import getProvinceCodeByName from "../../Utils/getProvinceCode";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
    AddressBookEditRoot: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        margin: '20px auto',
        backgroundColor: '#FFFFFF',
        borderRadius: '15px',
        padding: '20px',
        gap: '10px'
    },
    AddressBookEditFormSection: {
        width: '100%'
    },
    AddressBookEditFormErrorText: {
        fontSize: '12px',
        color: "#FF0303"
    },
    AddressBookEditCloseIcon: {
        position: 'absolute',
        right: 0,
        top: 0
    },
    AddressBookGrid: {
        display: 'flex',
        width: '100%'
    },
}

export const AddressBookEdit = () => {

    let addressInfo = useSelector(state => state?.addressBook?.addressInfo);

    // console.log('address info', addressInfo);

    const [address, setAddress] = useState({});
    const [googleAddress, setGoogleAddress] = useState({});
    const [missingValue, setMissingValue] = useState([]);
    const [errors, setErrors] = useState({});
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const addressEditInput = useRef(null);
    const dispatch = useDispatch();
    const accessToken = getAccessToken("access_token");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    // init map script
    const initMapScript = () => {
        // if script already loaded
        if (window.google) {
            console.log('include');
            return Promise.resolve();
        }
        const src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_API_KEY}&libraries=places&v=weekly`;
        return loadAsyncScript(src)
    }

    // onchange address
    const onChangeAddressFrom = (autocomplete) => {
        const place = autocomplete.getPlace();
        setGoogleAddress(extractAddress(place));
    }

    // init autocomplete
    const initAutocomplete = () => {
        if (!addressEditInput.current) return;
        const autocomplete = new window.google.maps.places.Autocomplete(addressEditInput.current);

        autocomplete.setFields(["address_component", "geometry"]);
        autocomplete.addListener("place_changed", () => onChangeAddressFrom(autocomplete));

    }

    // load map script after amounted
    useEffect(() => {
        initMapScript().then(() => {
            return initAutocomplete()
        })
    }, []);

    useEffect(() => {
        addressEditInput.current.value = googleAddress.streetName !== undefined ? `${googleAddress.streetNumber} ${googleAddress.streetName}` : ""

        if (Object.keys(googleAddress).length !== 0) {
            setAddress(prevState => ({
                ...prevState,
                address: `${googleAddress?.streetNumber} ${googleAddress?.streetName} `,
                city: googleAddress?.city,
                province: googleAddress?.state,
                country: googleAddress?.country,
                postalCode: googleAddress?.zip
            }))
        }
    }, [googleAddress])

    const validatePhone = () => {
        if (!isNaN(address.phone) && address.phone.length < 16 && address.phone.length > 9) {
            setErrors(prevState => ({
                ...prevState,
                phoneError: false

            }))
            return false
        } else {
            setErrors(prevState => ({
                ...prevState,
                phoneError: true

            }))
            return true
        }
    }

    const validateMissingValue = () => {
        const listOfMissingValue = getMissingKeys(address);
        // console.log('list of missing value', listOfMissingValue);
        setMissingValue(listOfMissingValue);
        if (listOfMissingValue.length === 0) {
            return true
        } else {
            return false
        }
    }

    useEffect(() => {
        setAddress({
            name: addressInfo?.name,
            company: addressInfo?.company_name,
            address: addressInfo?.address,
            address2: addressInfo?.address_2,
            city: addressInfo?.city,
            province: addressInfo?.province,
            country: addressInfo?.country,
            postalCode: addressInfo?.postal_code,
            addressEmail: addressInfo?.address_email,
            email: addressInfo?.email,
            phone: addressInfo?.phone,
            addressType: addressInfo?.sender_or_receiver
        })

    }, [])

    const handleCloseIcon = () => {
        dispatch(toggleEditAddress(false));
    }

    const updateAddress = async () => {

        let requestURL = `${NODE_ROUTE_URI}/addressBookEndUser/endUser/addressbooks`;

        const data = {
            address: address?.address,
            address_2: address?.address2,
            city: address?.city,
            email: address?.email,
            province: getProvinceCodeByName(address?.province),
            postalCode: address?.postalCode,
            country: getCountryCodeByName(address?.country),
            phone: address?.phone,
            name: address?.name,
            addressEmail: address?.addressEmail,
            companyName: address?.company,
            id: addressInfo?.id
        }

        try {
            await axios({
                method: 'put',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: data
            })
            setErrorMessage('');
            setSuccessMessage('Successfully Update Address');
            handleSuccessMessage();
            setTimeout(() => dispatch(toggleEditAddress(false)), 2000);
        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail Update Address');
            handleErrorMessage();
        }
    }

    const handleEditAddress = () => {
        validateMissingValue();
        validatePhone();
        if (!validatePhone() && validateMissingValue()) {
            updateAddress();
        }
    }

    return (
        <Box sx={style.AddressBookEditRoot}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Typography variant="h5">
                Edit Address
            </Typography>
            <IconButton
                sx={style.AddressBookEditCloseIcon}
                onClick={handleCloseIcon}
            >
                <CloseIcon/>
            </IconButton>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <label htmlFor="name">Name*: </label>
                </Grid>
                <Grid item xs={4}>
                    <Box>
                        <input
                            style={style.AddressBookEditFormSection}
                            id="name"
                            value={address?.name}
                            onInput={e => setAddress(prevState => ({
                                ...prevState, name: e.target.value
                            }))}
                        />
                        {missingValue.includes('name') ?
                            <p style={style.AddressBookEditFormErrorText}>This is a required field.</p> : ""}
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <label htmlFor="company">Company: </label>
                </Grid>
                <Grid item xs={4}>
                    <Box>
                        <input
                            style={style.AddressBookEditFormSection}
                            id="company"
                            value={address?.company}
                            onInput={e => setAddress(prevState => ({
                                ...prevState, company: e.target.value
                            }))}
                        />
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <label htmlFor="phone">Phone*:</label>
                </Grid>
                <Grid item xs={4}>
                    <Box>
                        <input
                            style={style.AddressBookEditFormSection}
                            id="phone"
                            type="tel"
                            value={address?.phone}
                            onInput={e => setAddress(prevState => ({
                                ...prevState, phone: e.target.value
                            }))}
                        />
                        {missingValue.includes('phone') ?
                            <p style={style.AddressBookEditFormErrorText}>This is a required field.</p> :
                            errors.phoneError ?
                                <p style={style.AddressBookEditFormErrorText}>Phone numbers should consist only of
                                    digits
                                    and be between 10 to 15 digits long.</p> :
                                ""
                        }
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <label htmlFor="email">Email:</label>
                </Grid>
                <Grid item xs={4}>
                    <Box>
                        <input
                            style={style.AddressBookEditFormSection}
                            id="email"
                            type="email"
                            value={address?.addressEmail}
                            onInput={e => setAddress(prevState => ({
                                ...prevState, addressEmail: e.target.value
                            }))}
                        />
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <label htmlFor="address">Address*:</label>
                </Grid>
                <Grid item xs={10}>
                    <Box>
                        <input
                            style={style.AddressBookEditFormSection}
                            ref={addressEditInput}
                            value={address?.address}
                            type="text"
                            onInput={e => setAddress(prevState => ({
                                ...prevState, address: e.target.value
                            }))}
                        />
                        {missingValue.includes('address') ?
                            <p style={style.AddressBookEditFormErrorText}>This is a required field.</p> : ""}
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <label htmlFor="address2">Address2:</label>
                </Grid>
                <Grid item xs={10}>
                    <Box>
                        <input
                            style={style.AddressBookEditFormSection}
                            type="text"
                            value={address?.address2}
                            onInput={e => setAddress(prevState => ({
                                ...prevState, address2: e.target.value
                            }))}
                        />
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <label htmlFor="city">City*:</label>
                </Grid>
                <Grid item xs={4}>
                    <Box>
                        <input
                            style={style.AddressBookEditFormSection}
                            id="city"
                            type="text"
                            value={address?.city}
                            onInput={e => setAddress(prevState => ({
                                ...prevState, city: e.target.value
                            }))}
                        />
                        {missingValue.includes('city') ?
                            <p style={style.AddressBookEditFormErrorText}>This is a required field.</p> : ""}
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <label htmlFor="province">Province*:</label>
                </Grid>
                <Grid item xs={4}>
                    <Box>
                        <input
                            style={style.AddressBookEditFormSection}
                            id="province"
                            type="text"
                            value={address?.province}
                            onInput={e => setAddress(prevState => ({
                                ...prevState, province: e.target.value
                            }))}
                        />
                        {missingValue.includes('province') ?
                            <p style={style.AddressBookEditFormErrorText}>This is a required field.</p> : ""}
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <label htmlFor="country">Country*:</label>
                </Grid>
                <Grid item xs={4}>
                    <Box>
                        <input
                            style={style.AddressBookEditFormSection}
                            id="country"
                            type="text"
                            value={address?.country}
                            onInput={e => setAddress(prevState => ({
                                ...prevState, country: e.target.value
                            }))}
                        />
                        {missingValue.includes('country') ?
                            <p style={style.AddressBookEditFormErrorText}>This is a required field.</p> : ""}
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <label htmlFor="postalCode">Postal Code*:</label>
                </Grid>
                <Grid item xs={4}>
                    <Box>
                        <input
                            style={style.AddressBookEditFormSection}
                            id="postalCode"
                            type="text"
                            value={address?.postalCode}
                            onInput={e => setAddress(prevState => ({
                                ...prevState, postalCode: e.target.value
                            }))}
                        />
                        {missingValue.includes('postalCode') ?
                            <p style={style.AddressBookEditFormErrorText}>This is a required field.</p> : ""}
                    </Box>
                </Grid>
                {/*<Grid item xs={2}>*/}
                {/*    <label htmlFor="Type">Type:</label>*/}
                {/*</Grid>*/}
                {/*<Grid item xs={4}>*/}
                {/*    <Box style={style.AddressBookGrid}>*/}
                {/*        <label>*/}
                {/*            <input*/}
                {/*                id="type"*/}
                {/*                type="radio"*/}
                {/*                style={style.AddressBookEditFormSection}*/}
                {/*                value="SENDER"*/}
                {/*                checked={address.addressType === 'SENDER'}*/}
                {/*                onChange={e => setAddress(prevState => ({*/}
                {/*                    ...prevState, addressType: e.target.value*/}
                {/*                }))}*/}
                {/*            />*/}
                {/*            Sender*/}
                {/*        </label>*/}
                {/*        <label>*/}
                {/*            <input*/}
                {/*                id="type"*/}
                {/*                type="radio"*/}
                {/*                style={style.AddressBookEditFormSection}*/}
                {/*                value="RECEIVER"*/}
                {/*                checked={address.addressType === 'RECEIVER'}*/}
                {/*                onChange={e => setAddress(prevState => ({*/}
                {/*                    ...prevState, addressType: e.target.value*/}
                {/*                }))}*/}
                {/*            />*/}
                {/*            Deliver*/}
                {/*        </label>*/}
                {/*    </Box>*/}
                {/*</Grid>*/}
            </Grid>
            <Button
                onClick={handleEditAddress}
            >
                Edit
            </Button>
        </Box>

    )
}