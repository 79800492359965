import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {getAccessToken} from "../../Utils/doToken";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {useEffect, useRef, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar} from "@mui/material";
import {separateTaxes} from "../../Utils/calculateTax";
import {CSVLink} from "react-csv";
import {saveAs} from 'file-saver';
import {useDispatch, useSelector} from "react-redux";
import {getOrderDashboardCrossBorder} from "../../actions/orderDashboardAction";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {useHistory} from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
    OrderDashBoardSummaryRoot: {
        width: '100%',
        backgroundColor: '#FFFFFF',
        padding: '20px'
    },
    OrderDashBoardSummarySection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '10px'
    },
    OrderDashBoardSummarySectionHeader: {
        fontWeight: '600',
        fontSize: '20px'
    },

}

export const OrderDashBoardSummary = ({orderId}) => {

    const accessToken = getAccessToken("access_token");
    const dispatch = useDispatch();

    const history = useHistory();

    const [orderInfo, setOrderInfo] = useState();

    const csvLink = useRef();
    const csvTrackingLink = useRef();

    const [transactions, setTransactions] = useState([]);
    const [trackings, setTrackings] = useState([]);

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [cancelOrderDialogIsOpen, setCancelOrderDialogIsOpen] = useState(false);
    const [isLoadingCancelOrder, setIsLoadingCancelOrder] = useState(false);

    const handleCancelOrder = () => {
        setCancelOrderDialogIsOpen(!cancelOrderDialogIsOpen);
    };

    const handleCancelOrderClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            setCancelOrderDialogIsOpen(!cancelOrderDialogIsOpen);
        }
    }

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    // let {id} = useSelector(state => state?.user)

    const getLabelURL = (pdf, zip) => {
        if (pdf) {
            return pdf;
        }
        if (zip) {
            return zip;
        }
        return "";
    }

    const refundByOrderId = async () => {
        setIsLoadingCancelOrder(true);
        let requestURL = `${NODE_ROUTE_URI}/looseItem/refundByOrderId`;
        console.log('order info', orderInfo);
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: {
                    orderId: orderInfo?.uuc_order_id
                }
            })
            console.log(result.data)
            setErrorMessage('');
            setSuccessMessage('Successful to cancel the order. Redirect to home page');
            handleSuccessMessage();
            setIsLoadingCancelOrder(false);
            setTimeout(() => {
                history.push(`/`);
            }, 2000);
        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail to cancel the order');
            handleErrorMessage();
            setIsLoadingCancelOrder(false);
        }
    }

    const getTransactionsListByOrderId = async () => {

        let requestURL = `${NODE_ROUTE_URI}/shippingOrderDetailsDashboard/endUser/getTransactionsByOrderId`;
        const resultArray = [["Order ID", "Cargo Control Number", "Ref Order No", "Tran ID", "Account Base", "Client Number", "Product ID", "Exclude Tax", "Include Tax", "GST", "HST", "QST", "Date", "Tracking #", "Labels"],];

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                params: {
                    orderId: orderId
                }
            })
            for (const column of result.data.result) {
                const columnData = [];
                columnData.push(orderId);
                columnData.push(column.cargo_control_no);
                columnData.push(orderInfo?.ref_order_no);
                columnData.push(column.tran_id);
                columnData.push(orderInfo.account_base);
                // Client ID
                columnData.push(column.ref_other_no);
                // Product ID
                columnData.push(column.ref_product_no);
                // Swap Price
                columnData.push(column.amount_gross);
                columnData.push(column.amount);

                const taxesObject = separateTaxes({
                    excludeTaxAmount: column.amount_gross,
                    includeTaxAmount: column.amount
                })
                columnData.push(taxesObject.gstTax);
                columnData.push(taxesObject.hstTax);
                columnData.push(taxesObject.qstTax);

                columnData.push(column.tranacted_at);
                columnData.push(column.tran_tracking_nos);
                columnData.push(getLabelURL(column.label_pdf_uri, column?.label_zip_uri) ? `https://storage.googleapis.com/${getLabelURL(column.label_pdf_uri, column?.label_zip_uri)}` : "N/A");

                resultArray.push(columnData);
            }
            setTransactions(resultArray);
            csvLink.current.link.click()

        } catch (e) {
            console.log(e)
        }
    }

    const getAllTrackingsFromDBByOrderId = async () => {
        let requestURL = `${NODE_ROUTE_URI}/shippingOrderDetailsDashboard/endUser/trackings`;
        const resultArray = [["Order ID", "Ref Order No", "Cargo Control Number", "Account Base", "Tracking Number", "Transaction ID", "Ref Client No", "Ref Product No", "Labels", "Label URI", "Date"]]

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: {
                    orderId: orderId
                }
            })
            console.log(result.data)
            for (const column of result.data.result) {
                const columnData = [];
                columnData.push(orderId);
                columnData.push(orderInfo.ref_order_no);
                columnData.push(orderInfo.cargo_control_no);
                columnData.push(orderInfo.account_base);
                columnData.push(column.tracking_no);
                columnData.push(column.tran_id);
                columnData.push(column.ref_other_no);
                columnData.push(column.ref_product_no);
                columnData.push(getLabelURL(column.label_pdf_uri, column.label_zip_uri) ? `https://storage.googleapis.com/${getLabelURL(column.label_pdf_uri, column.label_zip_uri)}` : "N/A");
                columnData.push(column.label_uri ? `https://storage.googleapis.com/${column.label_uri}` : "N/A");
                columnData.push(column.tranacted_at);
                resultArray.push(columnData);
            }
            setTrackings(v => {
                const temp = [...v];
                temp.push(...resultArray);
                return temp;
            });
            console.log(resultArray);
            csvTrackingLink.current.link.click()
        } catch (e) {

        }
    }

    const getLabelZipByOrderId = async () => {
        let requestURL = `${NODE_ROUTE_URI}/shippingOrderDetailsDashboard/getLabelZipByOrderId`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                params: {
                    orderId: orderId
                },
                responseType: 'blob'
            })

            const blobData = new Blob([result.data], {
                type: "application/zip"
            });
            saveAs(blobData, `${orderId}.zip`);
        } catch (e) {
            console.log(e.response)
        }
    }

    const getOrderInfoByOrderId = async () => {

        let requestURL = `${NODE_ROUTE_URI}/shippingOrderDetailsDashboard/endUser/order`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                params: {
                    orderId: orderId
                }
            })

            // console.log(result.data.result);
            setOrderInfo(result.data.result[0]);
            dispatch(getOrderDashboardCrossBorder(!!result.data.result[0]?.cross_border_shipment_type));
        } catch (e) {
            console.log(e.response)

        }
    }

    useEffect(() => {
        getOrderInfoByOrderId();
    }, [])

    // console.log('order info', orderInfo);

    return (
        <Box sx={style.OrderDashBoardSummaryRoot}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Dialog
                open={cancelOrderDialogIsOpen}
                onClose={handleCancelOrderClose}
            >
                <DialogTitle>Refund Request Confirmation</DialogTitle>
                <DialogContent>
                    Are you sure you want to request a refund for your order? This action cannot be undone.
                    Please confirm if you'd like to proceed with the refund request.
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCancelOrder}
                        variant="contained"
                        autoFocus
                    >
                        CLOSE
                    </Button>
                    <LoadingButton
                        onClick={refundByOrderId}
                        variant="contained"
                        autoFocus
                        loading={isLoadingCancelOrder}
                    >
                        CONFIRM
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Grid container spacing={1}>
                <Grid item xs={3} sx={style.OrderDashBoardSummarySection}>
                    <Typography sx={style.OrderDashBoardSummarySectionHeader}>
                        Order # / Billed to Invoice #
                    </Typography>
                    <Typography>
                        {orderInfo?.order_id} / {orderInfo?.crm_invoice_no || 'N/A'}
                    </Typography>
                    <Typography>
                        {orderInfo?.created_at}
                    </Typography>
                </Grid>
                <Grid item xs={3} sx={style.OrderDashBoardSummarySection}>
                    <Typography sx={style.OrderDashBoardSummarySectionHeader}>
                        Ref Order # / Courier
                    </Typography>
                    <Typography>
                        {orderInfo?.ref_order_no} / {orderInfo?.account_base}
                    </Typography>
                </Grid>
                <Grid item xs={3} sx={style.OrderDashBoardSummarySection}>
                    <Typography sx={style.OrderDashBoardSummarySectionHeader}>
                        Total Charge Include Tax
                    </Typography>
                    <Typography>
                        CAD ${orderInfo?.total_amount_include_tax}
                    </Typography>
                </Grid>
                <Grid item xs={3} sx={style.OrderDashBoardSummarySection}>
                    <Typography sx={style.OrderDashBoardSummarySectionHeader}>
                        Sender Info
                    </Typography>
                    <Typography>
                        {orderInfo?.send_from_name}
                    </Typography>
                    <Typography>
                        {orderInfo?.send_from_tel}
                    </Typography>
                    <Typography>
                        {orderInfo?.send_from_email || 'N/A'}
                    </Typography>
                    <Typography>
                        {orderInfo?.send_from_addr}
                    </Typography>
                    <Typography>
                        {orderInfo?.send_from_city}, {orderInfo?.send_from_province}, {orderInfo?.send_from_postal_code}
                    </Typography>
                </Grid>
                <Grid item xs={3} sx={style.OrderDashBoardSummarySection}>
                    <Typography sx={style.OrderDashBoardSummarySectionHeader}>
                        Transactions
                    </Typography>
                    <Button onClick={getTransactionsListByOrderId}>
                        Download
                    </Button>
                    <CSVLink
                        data={transactions}
                        filename={`${orderId}-${orderInfo?.ref_order_no}-transactions.csv`}
                        className='hidden'
                        ref={csvLink}
                        target='_blank'
                    />
                </Grid>
                <Grid item xs={3} sx={style.OrderDashBoardSummarySection}>
                    <Typography sx={style.OrderDashBoardSummarySectionHeader}>
                        Tracking
                    </Typography>
                    <Button onClick={getAllTrackingsFromDBByOrderId}>
                        Download
                    </Button>
                    <CSVLink
                        data={trackings}
                        filename={`${orderId}-${orderInfo?.ref_order_no}-trackings.csv`}
                        className='hidden'
                        ref={csvTrackingLink}
                        target='_blank'
                    />
                </Grid>
                <Grid item xs={3} sx={style.OrderDashBoardSummarySection}>
                    <Typography sx={style.OrderDashBoardSummarySectionHeader}>
                        Labels
                    </Typography>
                    <Button onClick={getLabelZipByOrderId}>
                        Download
                    </Button>
                </Grid>
                <Grid item xs={3} sx={style.OrderDashBoardSummarySection}>
                    <Button
                        variant='contained'
                        // onClick={refundByOrderId}
                        onClick={handleCancelOrder}
                        color='error'
                    >
                        Refund
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}