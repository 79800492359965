import {
    Button,
    Container,
    Dialog, DialogActions, DialogContent,
    DialogTitle,
    IconButton, InputAdornment,
    MenuItem,
    Modal,
    Select,
    Snackbar,
    Stack,
    Tooltip
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import HelpIcon from "@mui/icons-material/Help";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import {useEffect, useState} from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Divider from "@mui/material/Divider";
import {getAccessToken} from "../../Utils/doToken";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {SchedulePickupTrackingItem} from "./SchedulePickupTrackingItem";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@mui/material/Paper";
import {extractFullAddress, getMissingKeysForSchedulePickupInformation, isValidatePhone} from "../../Utils/Helper";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import {isValid} from "date-fns";
import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import LoadingButton from "@mui/lab/LoadingButton";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {useInterval} from "usehooks-ts";
import {useHistory} from "react-router-dom";
import addressIcon from "../../images/Address.png";
import HomeIcon from "@mui/icons-material/Home";
import {useThrottle} from "../../Utils/CustomHook";
import {makeStyles} from "@material-ui/core/styles";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "4px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

export const SchedulePickup = () => {

    const history = useHistory();
    const classes = useStyles();
    const accessToken = getAccessToken("access_token");

    const [accountBase, setAccountBase] = useState("UPS");
    const [formErrors, setFormErrors] = useState([]);
    const [trackingList, setTrackingList] = useState([]);
    const [trackingNumbers, setTrackingNumbers] = useState("");
    const [volume, setVolume] = useState(0);
    const [volumeUnit, setVolumeUnit] = useState('cm');
    const [searchAddressList, setSearchAddressList] = useState([]);
    const [shipFromAddressPredictions, setShipFromAddressPredictions] = useState([]);
    const [selectedDate, setSelectedDate] = useState(dayjs(new Date()));
    const [earliestPickupTime, setEarliestPickupTime] = useState(dayjs('2022-04-17T11:00'));
    const [latestPickupTime, setLatestPickupTime] = useState(dayjs('2022-04-17T16:00'));
    const [dateValid, setDateValid] = useState(true);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [pickupInformationMissingKeys, setPickupInformationMissingKeys] = useState([]);
    const [trackingNumberEmpty, setTrackingNumberEmpty] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fedexPickupTime, setFedexPickupTime] = useState(null);
    const [open, setOpen] = useState(false);
    const [addressList, setAddressList] = useState([]);
    const [pickupInformation, setPickupInformation] = useState({
        postalCode: null,
        province: null,
        city: null,
        address: null,
        address2: null,
        country: null,
        displayAddress: {
            description: null,
            placeId: null,
            text: null
        },
        name: "",
        phone: null,
        email: "",
        company: "",
        saveAddress: false,
        addressList: {
            name: null,
            phone: null,
            email: null,
            address_email: null,
            company_name: null,
            city: null,
            province: null,
            country: null
        },
        pickupPoint: 'Front Door',
        isResidential: true
    });

    const [sendEmailOpen, setSendEmailOpen] = useState(false);
    const [sendEmailLoading, setSendEmailLoading] = useState(false);

    const [totalVolume, setTotalVolume] = useState(1);
    const [totalWeight, setTotalWeight] = useState(1);
    const [trackingNumberError, setTrackingNumberError] = useState([]);

    const throttledValue = useThrottle(trackingNumbers, 3000);
    const throttledAddress = useThrottle(pickupInformation?.address, 1500);

    const verifyAddressIsResidentialPickup = async (address) => {
        if (!address || address === "") {
            return false;
        }
        let requestURI = `${NODE_ROUTE_URI}/verifyAddress/isResidential`;
        let data = {
            address: address,
            city: pickupInformation?.city,
            province: pickupInformation?.province?.code,
            postalCode: pickupInformation?.postalCode,
            country: pickupInformation?.country
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: data
            })
            // console.log('result', result);
            return result?.data?.result;
        } catch (e) {
            console.log('error', e.response);
        }
    }

    const verifyTrackingNumbers = async () => {
        let requestURL = `${NODE_ROUTE_URI}/packagesPickupAppointment/verifyTrackingNumberForPickup`;
        const storedToken = getAccessToken("access_token");

        const data = {
            trackingNumbers: throttledValue,
            carrier: accountBase
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                data: data,
            })

            console.log('result', result);

            const errors = result?.data?.errors;
            const validTrackingNumbers = result?.data?.validTrackingNumbers;

            if (errors?.length > 0) {
                console.log('error');
                setTotalWeight(1);
                setTotalVolume(1);
                setTrackingNumberError(errors);

            } else if (validTrackingNumbers?.length > 0) {
                console.log('valid');
                setTotalWeight(result?.data?.totalWeight);
                setTotalVolume(result?.data?.totalWeight);
                setTrackingNumberError([]);
            } else {
                console.log('empty');
                setTotalWeight(1);
                setTotalVolume(1);
                setTrackingNumberError([]);
            }
        } catch (e) {
            console.log('error', e.response);
            setTotalWeight(1);
            setTotalVolume(1);
            setTrackingNumberError([]);
        }
    }

    const handleSendEmailDialogClose = () => {
        setSendEmailOpen(false);
        // setContactEmail('');
    };

    const handleSendEmailDialogOpen = () => {
        setSendEmailOpen(true);
        // setContactEmail();
        // setContactEmail(userInfo?.email);
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleSelectAddress = (address) => {
        console.log('select address', address);
        setPickupInformation(prevState => ({
            ...prevState,
            postalCode: address?.postal_code,
            province: {
                code: address?.province,
                name: address?.province
            },
            city: address?.city,
            address: address?.address,
            address2: address?.address_2,
            country: address?.country,
            displayAddress: {
                description: `${address?.address}, ${address?.city}, ${address?.province}, ${address?.country}`,
                placeId: null,
                text: address?.address
            },
            name: address?.name,
            phone: address?.phone,
            email: address?.address_email,
            company: address?.company_name,
            saveAddress: false,
            addressList: address
        }))
        handleClose();
    }

    const handleNewAddress = () => {
        history.push('/address-book');
    }

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const getShipFromAddress = async () => {
        let requestURL = `${NODE_ROUTE_URI}/addressBookEndUser/endUser/addressbooks/sender`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            setAddressList(result.data.result);

        } catch (e) {
            console.log(e.response);
        }
    }

    const googleMapsPlaceAutocomplete = async (inputValue, type) => {
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log('prediction', predictions);
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            console.log('updated prediction', updatedPredictions);

            return updatedPredictions;

        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const searchAddress = async (searchString) => {
        let requestURL = `${NODE_ROUTE_URI}/elasticsearch/addressBook/searchEndUserAddressBook`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                params: {
                    role: 'SENDER',
                    queryString: searchString
                }
            })

            console.log('result', result?.data);
            const searchAddressResult = result?.data;
            // setSearchNameList(result?.data);
            return searchAddressResult;

        } catch (e) {
            console.log('error', e.response)
        }
    }

    const handleTrackingNumbers = (event) => {
        setTrackingNumbers(event.target.value);
    }

    const cbHandleTrackingNumbers = (trackingNumber) => {
        if (trackingNumbers?.trim() === "") {
            setTrackingNumbers(trackingNumber)
        } else {
            setTrackingNumbers(prevState => (
                prevState?.concat(`\n${trackingNumber}`)
            ))
        }
    }

    const handleVolume = (event) => {
        setVolume(event.target.value);
    }

    const handlePickupInformationName = (event) => {
        setPickupInformation(prevState => ({
            ...prevState,
            name: event.target.value
        }))
    }

    const getTrackingList = async () => {

        let requestURL = `${NODE_ROUTE_URI}/packagesPickupAppointment/getRecentTrackingRecordsList`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
                params: {
                    accountBase: accountBase
                }
            })
            console.log('get tracking list result', result);
            setTrackingList(result?.data)
        } catch (e) {
            console.log('error', e.response);
        }
    }

    const handleInputChangeName = async (event, value) => {
        console.log('value', value);
        setPickupInformation(prevState => ({
            ...prevState,
            name: value,
            addressList: {
                ...prevState.addressList,
                name: value,
            }
        }))
        if (value) {
            const result = await searchAddress(value);
            const filterResult = result.filter(item => item.name.toLowerCase().includes(value.toLowerCase()));
            console.log('filter data', filterResult);
            setSearchAddressList(filterResult);
        }
    }

    const handleChangeName = (event, value) => {
        console.log('value', value);
        if (value) {
            setPickupInformation(prevState => ({
                ...prevState,
                postalCode: value?.postal_code,
                province: {
                    code: value?.province,
                    name: value?.province
                },
                city: value?.city,
                address: value?.address,
                address2: value?.address_2,
                country: value?.country,
                displayAddress: {
                    description: `${value?.address}, ${value?.city}, ${value?.province}, ${value?.country}`,
                    placeId: null,
                    text: value?.address
                },
                name: value?.name,
                phone: value?.phone,
                email: value?.address_email,
                company: value?.company_name,
                addressList: value
            }))
        } else {
            setPickupInformation(prevState => ({
                ...prevState,
                name: ""
            }))
        }
    }

    const handleInputChangeCompany = async (event, value) => {
        console.log('value', value);
        setPickupInformation(prevState => ({
            ...prevState,
            company: value,
            addressList: {
                ...prevState.addressList,
                company_name: value,
            }
        }))
        if (value) {
            const result = await searchAddress(value);
            const filterResult = result.filter(item => item.company_name.toLowerCase().includes(value.toLowerCase()));
            console.log('filter data', filterResult);
            setSearchAddressList(filterResult);
        }
    }

    const handleChangeCompany = (event, value) => {
        console.log('value', value);
        if (value) {
            setPickupInformation(prevState => ({
                ...prevState,
                postalCode: value?.postal_code,
                province: {
                    code: value?.province,
                    name: value?.province
                },
                city: value?.city,
                address: value?.address,
                address2: value?.address_2,
                country: value?.country,
                displayAddress: {
                    description: `${value?.address}, ${value?.city}, ${value?.province}, ${value?.country}`,
                    placeId: null,
                    text: value?.address
                },
                name: value?.name,
                phone: value?.phone,
                email: value?.address_email,
                company: value?.company_name,
                addressList: value
            }))
        } else {
            setPickupInformation(prevState => ({
                ...prevState,
                company: ""
            }))
        }
    }

    const handleInputChangePhone = async (event, value) => {
        console.log('value', value);
        setPickupInformation(prevState => ({
            ...prevState,
            phone: value,
            addressList: {
                ...prevState.addressList,
                phone: value,
            }
        }))
        if (value) {
            const result = await searchAddress(value);
            const filterResult = result.filter(item => item.phone.toLowerCase().includes(value.toLowerCase()));
            console.log('filter data', filterResult);
            setSearchAddressList(filterResult);
        }
    }

    const handleChangePhone = (event, value) => {
        console.log('value', value);
        if (value) {
            setPickupInformation(prevState => ({
                ...prevState,
                postalCode: value?.postal_code,
                province: {
                    code: value?.province,
                    name: value?.province
                },
                city: value?.city,
                address: value?.address,
                address2: value?.address_2,
                country: value?.country,
                displayAddress: {
                    description: `${value?.address}, ${value?.city}, ${value?.province}, ${value?.country}`,
                    placeId: null,
                    text: value?.address
                },
                name: value?.name,
                phone: value?.phone,
                email: value?.address_email,
                company: value?.company_name,
                addressList: value
            }))
        } else {
            setPickupInformation(prevState => ({
                ...prevState,
                phone: ""
            }))
        }
    }

    const handleInputChangeEmail = async (event, value) => {
        console.log('value', value);
        setPickupInformation(prevState => ({
            ...prevState,
            email: value,
            addressList: {
                ...prevState.addressList,
                address_email: value,
            }
        }))
        if (value) {
            const result = await searchAddress(value);
            const filterResult = result.filter(item => item.address_email.toLowerCase().includes(value.toLowerCase()));
            console.log('filter data', filterResult);
            setSearchAddressList(filterResult);
        }
    }

    const handleChangeEmail = (event, value) => {
        console.log('value', value);
        if (value) {
            setPickupInformation(prevState => ({
                ...prevState,
                postalCode: value?.postal_code,
                province: {
                    code: value?.province,
                    name: value?.province
                },
                city: value?.city,
                address: value?.address,
                address2: value?.address_2,
                country: value?.country,
                displayAddress: {
                    description: `${value?.address}, ${value?.city}, ${value?.province}, ${value?.country}`,
                    placeId: null,
                    text: value?.address
                },
                name: value?.name,
                phone: value?.phone,
                email: value?.address_email,
                company: value?.company_name,
                addressList: value
            }))
        } else {
            setPickupInformation(prevState => ({
                ...prevState,
                email: ""
            }))
        }
    }

    const handleInputChangeAddress = async (event, value) => {
        setPickupInformation(prevState => ({
            ...prevState,
            address: value,
            displayAddress: {
                description: '',
                placeId: '',
                text: value
            }
        }));
        const predictionsAddress = await googleMapsPlaceAutocomplete(value, 'address');
        setShipFromAddressPredictions(predictionsAddress);
    }

    const handleChangeAddress = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            setPickupInformation(prevState => ({
                ...prevState,
                postalCode: address?.zip,
                city: address?.city,
                province: address?.state,
                country: address?.country?.code,
                displayAddress: value
            }))
        } else {
            setPickupInformation(prevState => ({
                ...prevState,
                address: '',
                displayAddress: {
                    description: '',
                    placeId: '',
                    text: ''
                },
            }))
        }
    }

    const handleAddress2 = event => {
        setPickupInformation(prevState => ({
            ...prevState,
            address2: event?.target?.value
        }))
    }

    const handleCity = event => {
        setPickupInformation(prevState => ({
            ...prevState,
            city: event?.target?.value
        }))
    }

    const handleProvince = event => {
        setPickupInformation(prevState => ({
            ...prevState,
            province: {
                code: event?.target?.value,
                name: event?.target?.value
            }
        }))
    }

    const handleCountry = event => {
        setPickupInformation(prevState => ({
            ...prevState,
            country: event?.target?.value
        }))
    }

    const handlePostalCode = event => {
        setPickupInformation(prevState => ({
            ...prevState,
            postalCode: event?.target?.value
        }))
    }

    const handlePickupPoint = event => {
        setPickupInformation(prevState => ({
            ...prevState,
            pickupPoint: event?.target?.value
        }))
    }

    const handleIsResidential = event => {
        const isResidential = event?.target.value ? true : false;
        setPickupInformation(prevState => ({
            ...prevState,
            isResidential: isResidential
        }))
    }

    const disableWeekends = (date) => {
        return date.day() === 0 || date.day() === 6 || isTodayDisabled(date);
    };

    const isWeekend = (date) => {
        const day = date.day();
        return day === 0 || day === 6;
    };

    const isTodayDisabled = (date) => {
        const today = dayjs();
        if (
            date.year() === today.year() &&
            date.month() === today.month() &&
            date.date() === today.date()
        ) {
            return (today.hour() === 11 && today.minute() >= 0) || today.hour() > 11;
        } else {
            return false;
        }
    };

    const getNextWeekday = () => {
        const today = new Date();
        const tomorrow = new Date(today.getTime() + 48 * 60 * 60 * 1000); // Adding two day

        console.log('tomorrow', tomorrow);

        // Skip the weekend (Saturday and Sunday)
        if (tomorrow.getDay() === 0) {
            tomorrow.setDate(tomorrow.getDate() + 2); // Adjust to Tuesday
        } else if (tomorrow.getDay() === 6) {
            tomorrow.setDate(tomorrow.getDate() + 3); // Adjust to Tuesday
        }

        console.log('tomorrow', tomorrow);

        return dayjs(tomorrow);
    };

    const getMinDate = () => {
        const today = new Date();
        const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000); // Adding two day

        // Skip the weekend (Saturday and Sunday)
        if (tomorrow.getDay() === 0) {
            tomorrow.setDate(tomorrow.getDate() + 2); // Adjust to Tuesday
        } else if (tomorrow.getDay() === 6) {
            tomorrow.setDate(tomorrow.getDate() + 3); // Adjust to Tuesday
        }

        return tomorrow.toISOString().split('T')[0];
        ;
    };

    const handleDateChange = (date) => {
        // console.log('data', date);
        if (date) {
            const valid = isValid(date?.$d);
            if (valid) {
                // const dateString = date.toISOString().split('T')[0];
                setSelectedDate(date);
                setDateValid(true);
            } else {
                setSelectedDate(date);
                // const defaultData = getNextWeekday();
                // setSelectedDate(defaultData);
                setDateValid(false);
            }
        } else {
            const defaultData = getNextWeekday();
            setSelectedDate(defaultData);
            setDateValid(true);
        }
    };

    const handleEarliestPickupTime = (newValue) => {
        if (newValue) {
            setEarliestPickupTime(newValue);
        } else {
            setEarliestPickupTime(dayjs('2022-04-17T11:00'));
        }
    }

    const handleLatestPickupTime = (newValue) => {
        if (newValue) {
            setLatestPickupTime(newValue);
        } else {
            setLatestPickupTime(dayjs('2022-04-17T16:00'));
        }
    }

    const validate = () => {
        setErrorMessage('');
        setToastOpen(false);
        const pickupInformationMissing = getMissingKeysForSchedulePickupInformation(pickupInformation);
        const phoneValidate = isValidatePhone(pickupInformation?.phone);
        const validDate = isValid(selectedDate?.$d);
        const validEarliestTime = isValid(earliestPickupTime?.$d);
        const validLatestTime = isValid(latestPickupTime?.$d);

        setPickupInformationMissingKeys(pickupInformationMissing);
        setTrackingNumberEmpty(!trackingNumbers);

        console.log('validate data', validDate);

        pickupInformationMissing?.length !== 0 && setErrorMessage(prevState => prevState + 'Pickup Information is missing one or more required fields.');
        !phoneValidate && setErrorMessage(prevState => prevState + ' Ship To phone number is invalid.');
        !trackingNumbers && setErrorMessage(prevState => prevState + ' Tracking Numbers are missing.');
        !validDate && setErrorMessage(prevState => prevState + ' Invalid Pickup Date.');
        !validEarliestTime && setErrorMessage(prevState => prevState + ' Invalid Pickup Earliest Time.');
        !validLatestTime && setErrorMessage(prevState => prevState + ' Invalid Pickup Latest Time.');
        trackingNumberError?.length !== 0 && setErrorMessage(prevState => prevState + ' Invalid Tracking Numbers');

        if (pickupInformationMissing.length === 0 &&
            phoneValidate &&
            trackingNumbers &&
            validDate &&
            validEarliestTime &&
            validLatestTime &&
            trackingNumberError?.length === 0
        ) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const FedexPickupTime = async () => {

        let requestURL = `${PARTNER_URI}/packagesPickupAppointment/fedexGetPickupAvailabilityByPostalCodeAndCountry`;

        const formatDate = selectedDate.format('YYYYMMDD');

        let req = {
            postalCode: pickupInformation?.postalCode?.replace(/\s/g, ""),
            country: pickupInformation?.country,
            pickupDate: formatDate
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: req
            })
            console.log('pickup time', result);
            setFedexPickupTime(prevState => ({
                ...prevState,
                defaultReadyTime: result?.data?.defaultReadyTime,
                defaultLatestTimeOptions: result?.data?.defaultLatestTimeOptions
            }))

            const earliestTime = `${formatDate}T${result?.data?.defaultReadyTime}`;
            const latestTime = `${formatDate}T${result?.data?.defaultLatestTimeOptions}`;

            setEarliestPickupTime(dayjs(earliestTime));
            setLatestPickupTime(dayjs(latestTime));

        } catch (e) {
            console.log(e.response)
        }
    }

    const handleSchedulePickup = async () => {
        setLoading(true);
        let requestURL = `${NODE_ROUTE_URI}/packagesPickupAppointment/createPickupAppointmentEndUser`;

        let data = {
            pickupDateInfo: {
                readyTime: earliestPickupTime.format('HHmm'),
                closeTime: latestPickupTime.format('HHmm'),
                pickupDate: selectedDate.format('YYYYMMDD')
            },
            pickupAddress: {
                address: pickupInformation?.address,
                city: pickupInformation?.city,
                province: pickupInformation?.province?.code,
                country: pickupInformation?.country,
                postalCode: pickupInformation?.postalCode?.replace(/\s/g, ""),
                pickupPoint: pickupInformation?.pickupPoint,
                isResidential: pickupInformation?.isResidential
            },
            contact: {
                name: pickupInformation?.name,
                companyName: pickupInformation?.company || pickupInformation?.name,
                phone: pickupInformation?.phone,
                email: pickupInformation?.email,
                phoneExtension: ""
            },
            totalVolume: volume,
            trackingNumbers: trackingNumbers,
            accountBase: accountBase
        }
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: data
            })
            console.log(result);
            setErrorMessage('');
            setSuccessMessage('Successfully Scheduled Pickup');
            handleSuccessMessage();
            setTimeout(() => {
                history.push(`/MyScheduledPickups`);
            }, 2000);
        } catch (e) {
            console.log('e', e.response);
            setErrorMessage('Failed to Schedule Pickup. Try again another time.');
            handleErrorMessage();
            if (accountBase === 'FedEx') {
                FedexPickupTime();
            }
        } finally {
            setLoading(false);
        }
    }

    const handleSchedulePickupRequest = async () => {
        setSendEmailLoading(true);
        let requestURL = `${NODE_ROUTE_URI}/packagesPickupAppointment/canparPickup`;
        let data = {
            pickupDateInfo: {
                readyTime: earliestPickupTime.format('HHmm'),
                closeTime: latestPickupTime.format('HHmm'),
                pickupDate: selectedDate.format('YYYYMMDD')
            },
            pickupAddress: {
                address: pickupInformation?.address,
                city: pickupInformation?.city,
                province: pickupInformation?.province?.code,
                country: pickupInformation?.country,
                postalCode: pickupInformation?.postalCode?.replace(/\s/g, ""),
                pickupPoint: pickupInformation?.pickupPoint,
                isResidential: pickupInformation?.isResidential
            },
            contact: {
                name: pickupInformation?.name,
                companyName: pickupInformation?.company || pickupInformation?.name,
                phone: pickupInformation?.phone,
                email: pickupInformation?.email,
                phoneExtension: ""
            },
            trackingNumbers: trackingNumbers,
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: data
            })
            console.log(result);
            setErrorMessage('');
            setSuccessMessage('Successfully Send Pickup Request');
            handleSuccessMessage();
            setSendEmailLoading(false);
            handleSendEmailDialogClose();
        } catch (e) {
            console.log('e', e.response);
            setErrorMessage('Failed to Send Pickup Request. Try again another time.');
            handleErrorMessage();
            setSendEmailLoading(false);
        }
    }

    const handleSchedule = () => {
        const validationResult = validate();
        if (validationResult) {
            if (accountBase === 'Canpar') {
                // console.log('send email');
                handleSendEmailDialogOpen();
            } else {
                handleSchedulePickup();
            }
        }
    }

    useEffect(() => {
        (
            async () => getTrackingList()
        )()
    }, [accountBase])

    useEffect(() => {
        const defaultDate = getNextWeekday();
        // console.log(defaultDate);
        setSelectedDate(defaultDate);
    }, []);

    useEffect(() => {
        verifyTrackingNumbers();
    }, [throttledValue]);

    useEffect(async () => {
        const result = await verifyAddressIsResidentialPickup(throttledAddress);
        console.log('[verifyAddressIsResidentialPickup] is residential', result);
        setPickupInformation(prevState => ({
            ...prevState,
            isResidential: result
        }))
        // setPickupResidence(result);
    }, [throttledAddress])

    // console.log('tracking numbers', trackingNumbers);
    // console.log('pickup information', pickupInformation);
    // console.log('pickup date', selectedDate);
    // console.log('earlist pickup time', earliestPickupTime);

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Dialog
                open={sendEmailOpen}
                onClose={handleSendEmailDialogClose}
            >
                <DialogTitle>
                    Send the Request to Schedule Pickup
                </DialogTitle>
                <DialogContent>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                        <Typography>
                            UUCargo will handle your pickup request for you with Canpar. You will receive the
                            confirmation email from op@uucargo.ca. Please click 'Confirm' to proceed.
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSendEmailDialogClose}>
                        No
                    </Button>
                    <LoadingButton
                        onClick={handleSchedulePickupRequest}
                        loading={sendEmailLoading}
                    >
                        Confirm
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Box sx={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            Schedule a Pickup
                        </Typography>
                        <Tooltip title="Redirect to Pickup Parcels Appointment Support Page" placement="top">
                            <IconButton
                                // styles={{icon: {color: 'rgb(141, 173, 155)', fontSize: 36}}}
                                onClick={() => {
                                    window.open("https://uucargo.ca/support/pickup-appointment-for-parcels/", "_blank")
                                }}
                            >
                                <HelpIcon color="info" sx={{fontSize: 30}}/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Grid>
                <Grid item md={12} xs={12}>
                    <Typography style={{fontSize: '18px', fontWeight: '600'}}>
                        1. Select Carrier
                    </Typography>
                    <FormControl fullWidth>
                        <FormLabel required>
                            Carrier
                        </FormLabel>
                        <Select
                            defaultValue={"UPS"}
                            onChange={e => {
                                setAccountBase(e.target.value);
                                setTrackingNumbers("");
                            }}
                            size='small'
                            // error={formErrors.courier}
                        >
                            <MenuItem value={"UPS"}>UPS</MenuItem>
                            <MenuItem value={"Canpar"}>Canpar</MenuItem>
                            <MenuItem value={"FedEx"}>FedEx</MenuItem>
                            {/*<MenuItem value={"fedex"}>Fedex</MenuItem>*/}
                            <MenuItem value={"CanadaPost"}>CanadaPost</MenuItem> // add CanadaPost
                        </Select>
                        <FormHelperText
                            // sx={{
                            //     color: "error.main",
                            // }}
                        >
                            {/*{true ? "Required" : undefined}*/}
                        </FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Typography style={{fontSize: '18px', fontWeight: '600'}}>
                        2. Shipping Label
                    </Typography>
                    <Accordion sx={{width: '100%'}}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>
                                Add Your Existing Tracking
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{maxHeight: "400px", overflow: "scroll"}}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{fontSize: '16px', fontWeight: '600'}}>
                                        Order
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography style={{fontSize: '16px', fontWeight: '600'}}>
                                        Recipient
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography style={{fontSize: '16px', fontWeight: '600'}}>
                                        Tracking #
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{fontSize: '16px', fontWeight: '600'}}>
                                        Carrier
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{fontSize: '16px', fontWeight: '600'}}>
                                        Action
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>
                            </Grid>
                            {
                                trackingList?.map((track, index) => <SchedulePickupTrackingItem
                                    key={index}
                                    trackingInfo={track}
                                    cbHandleTrackingNumbers={cbHandleTrackingNumbers}
                                />)
                            }
                        </AccordionDetails>
                    </Accordion>
                    <FormControl fullWidth>
                        <FormLabel>
                            Enter tracking numbers without spaces, separated by commas, to check if pickup charges are
                            prepaid.
                        </FormLabel>
                        <TextField
                            value={trackingNumbers}
                            onChange={handleTrackingNumbers}
                            multiline
                            rows={4}
                            placeholder="Enter your comma seperated tracking numbers here"
                            error={trackingNumberEmpty || trackingNumberError?.length}
                            helperText={
                                trackingNumberEmpty ? "Required tracking numbers." :
                                    trackingNumberError?.length > 0 ?
                                        trackingNumberError?.map((error) => `${error?.trackingNumber}: ${error?.message}`).join('\n') : ""
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Typography style={{fontSize: '18px', fontWeight: '600'}}>
                        3. Total Volume and Weight (optional)
                    </Typography>
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel>
                                    Total Volume
                                </FormLabel>
                                <TextField
                                    disabled={true}
                                    value={totalVolume}
                                    fullWidth
                                    variant='outlined'
                                    type='number'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                in
                                            </InputAdornment>
                                        ),
                                        inputProps: {
                                            min: 1
                                        },
                                        style: {fontSize: 16}
                                    }}
                                    size='small'
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel>
                                    Total Weight
                                </FormLabel>
                                <TextField
                                    disabled={true}
                                    value={totalWeight}
                                    fullWidth
                                    variant='outlined'
                                    type='number'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                lb
                                            </InputAdornment>
                                        ),
                                        inputProps: {
                                            min: 1
                                        },
                                        style: {fontSize: 16}
                                    }}
                                    size='small'
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}>
                        <Typography style={{fontSize: '18px', fontWeight: '600'}}>
                            4. Pickup Information
                        </Typography>
                        <Stack direction='row' spacing={0} alignItems='center'>
                            <Button
                                onClick={() => {
                                    handleOpen();
                                    getShipFromAddress();
                                }}
                                startIcon={<img src={addressIcon} width='30px'/>}>
                                <Typography style={{
                                    fontSize: '14px',
                                    textAlign: 'left',
                                    color: '#609966',
                                    fontWeight: '600',
                                }}>
                                    Addresses
                                </Typography>
                            </Button>
                        </Stack>
                    </Box>
                    <Grid container spacing={4}>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel required>
                                    Name
                                </FormLabel>
                                <Autocomplete
                                    freeSolo
                                    value={pickupInformation?.addressList}
                                    fullWidth
                                    options={searchAddressList}
                                    filterOptions={(options, state) => options}
                                    getOptionLabel={option => option?.name}
                                    onInputChange={handleInputChangeName}
                                    onChange={handleChangeName}
                                    PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                    renderInput={params => <TextField
                                        {...params}
                                        variant="outlined"
                                        error={pickupInformationMissingKeys?.includes('name')}
                                        helperText={
                                            pickupInformationMissingKeys?.includes('name') && "Name Required"
                                        }
                                        className={classes.smallInput}
                                    />}
                                    renderOption={option => (
                                        <Typography style={{fontSize: '12px'}}>
                                            {option?.name} | {option?.address}, {option?.city}, {option?.province}, {option?.country}
                                        </Typography>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel required>
                                    Phone
                                </FormLabel>
                                <Autocomplete
                                    freeSolo
                                    value={pickupInformation?.addressList}
                                    fullWidth
                                    options={searchAddressList}
                                    filterOptions={(options, state) => options}
                                    getOptionLabel={option => option?.phone}
                                    onInputChange={handleInputChangePhone}
                                    onChange={handleChangePhone}
                                    PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                    renderInput={params => <TextField
                                        {...params}
                                        variant="outlined"
                                        error={pickupInformation?.phone === null ? false : pickupInformationMissingKeys?.includes('phone') ? true : !isValidatePhone(pickupInformation?.phone)}
                                        helperText={
                                            pickupInformation?.phone === null ? "" : pickupInformationMissingKeys?.includes('phone') ? "Phone Required" : isValidatePhone(pickupInformation?.phone) ? "" : 'Invalid Phone Number'
                                        }
                                        className={classes.smallInput}
                                    />}
                                    renderOption={option => (
                                        <Typography style={{fontSize: '12px'}}>
                                            {option?.phone} | {option?.address}, {option?.city}, {option?.province}, {option?.country}
                                        </Typography>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel required>
                                    Email
                                </FormLabel>
                                <Autocomplete
                                    freeSolo
                                    value={pickupInformation?.addressList}
                                    fullWidth
                                    options={searchAddressList}
                                    filterOptions={(options, state) => options}
                                    getOptionLabel={option => option?.address_email}
                                    onInputChange={handleInputChangeEmail}
                                    onChange={handleChangeEmail}
                                    PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                    renderInput={params => <TextField
                                        {...params}
                                        variant="outlined"
                                        error={pickupInformationMissingKeys?.includes('email')}
                                        helperText={
                                            pickupInformationMissingKeys?.includes('email') && "Email Required"
                                        }
                                        className={classes.smallInput}
                                    />}
                                    renderOption={option => (
                                        <Typography style={{fontSize: '12px'}}>
                                            {option?.address_email} | {option?.address}, {option?.city}, {option?.province}, {option?.country}
                                        </Typography>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel>
                                    Company
                                </FormLabel>
                                <Autocomplete
                                    freeSolo
                                    value={pickupInformation?.addressList}
                                    fullWidth
                                    options={searchAddressList}
                                    filterOptions={(options, state) => options}
                                    getOptionLabel={option => option?.company_name}
                                    onInputChange={handleInputChangeCompany}
                                    onChange={handleChangeCompany}
                                    PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                    renderInput={params => <TextField
                                        {...params}
                                        variant="outlined"
                                        // error={pickupInformationMissingKeys?.includes('company')}
                                        // helperText={
                                        //     pickupInformationMissingKeys?.includes('company') && "Company Required"
                                        // }
                                        className={classes.smallInput}
                                    />}
                                    renderOption={option => (
                                        <Typography style={{fontSize: '12px'}}>
                                            {option?.company_name} | {option?.address}, {option?.city}, {option?.province}, {option?.country}
                                        </Typography>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel required>
                                    Address
                                </FormLabel>
                                <Autocomplete
                                    freeSolo
                                    value={pickupInformation?.displayAddress}
                                    fullWidth
                                    options={shipFromAddressPredictions}
                                    filterOptions={(options, state) => options}
                                    getOptionLabel={option => option?.text}
                                    onInputChange={handleInputChangeAddress}
                                    onChange={handleChangeAddress}
                                    PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                    renderInput={params => <TextField
                                        {...params}
                                        variant="outlined"
                                        error={pickupInformationMissingKeys?.includes('address')}
                                        helperText={
                                            pickupInformationMissingKeys?.includes('address') && "Address Required"
                                        }
                                        className={classes.smallInput}
                                    />}
                                    renderOption={option => (
                                        <Typography style={{fontSize: '12px'}}>
                                            {option.description}
                                        </Typography>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel>
                                    Address Two
                                </FormLabel>
                                <TextField
                                    value={pickupInformation?.address2}
                                    fullWidth
                                    variant='outlined'
                                    onInput={handleAddress2}
                                    size='small'
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel required>
                                    City
                                </FormLabel>
                                <TextField
                                    value={pickupInformation?.city}
                                    fullWidth
                                    variant='outlined'
                                    onInput={handleCity}
                                    error={pickupInformationMissingKeys?.includes('city')}
                                    helperText={
                                        pickupInformationMissingKeys?.includes('city') && "City Required"
                                    }
                                    size='small'
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel required>
                                    Province
                                </FormLabel>
                                <TextField
                                    value={pickupInformation?.province?.code}
                                    fullWidth
                                    variant='outlined'
                                    onInput={handleProvince}
                                    error={pickupInformationMissingKeys?.includes('province')}
                                    helperText={
                                        pickupInformationMissingKeys?.includes('province') && "Province Required"
                                    }
                                    size='small'
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel required>
                                    Country
                                </FormLabel>
                                <Select
                                    value={pickupInformation?.country}
                                    onChange={handleCountry}
                                    error={pickupInformationMissingKeys?.includes('country')}
                                    size='small'
                                >
                                    <MenuItem value='CA'>Canada</MenuItem>
                                    <MenuItem value='US'>Unites States</MenuItem>
                                </Select>
                                <FormHelperText
                                    sx={{
                                        color: "error.main",
                                    }}
                                >
                                    {pickupInformationMissingKeys?.includes('country') && "Country Required"}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel required>
                                    Postal Code
                                </FormLabel>
                                <TextField
                                    value={pickupInformation?.postalCode}
                                    fullWidth
                                    variant='outlined'
                                    onInput={handlePostalCode}
                                    error={pickupInformationMissingKeys?.includes('postalCode')}
                                    helperText={
                                        pickupInformationMissingKeys?.includes('postalCode') && "Postal Code Required"
                                    }
                                    size='small'
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel required>
                                    Pickup Point
                                </FormLabel>
                                <Select
                                    value={pickupInformation?.pickupPoint}
                                    onChange={handlePickupPoint}
                                    size='small'
                                >
                                    <MenuItem value={"Front Door"}>Front Door</MenuItem>
                                    <MenuItem value={"Rear Door"}>Rear Door</MenuItem>
                                    <MenuItem value={"Side Door"}>Side Door</MenuItem>
                                    <MenuItem value={"None"}>None</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel required>
                                    Is Residential
                                </FormLabel>
                                <Select
                                    value={pickupInformation?.isResidential ? 1 : 0}
                                    onChange={handleIsResidential}
                                    size='small'
                                >
                                    <MenuItem value={1}>Yes</MenuItem>
                                    <MenuItem value={0}>No</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography style={{fontSize: '18px', fontWeight: '600'}}>
                        5. Pickup Date and Time
                    </Typography>
                    <Grid container spacing={4}>
                        <Grid item md={4} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel required>
                                    Pickup Date
                                </FormLabel>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        shouldDisableDate={isWeekend}
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                        disablePast
                                        renderInput={(props) =>
                                            <TextField
                                                {...props}
                                                fullWidth
                                            />}
                                        slotProps={{
                                            textField: {
                                                helperText: !isValid(selectedDate?.$d) && 'Invalid Pickup Date',
                                                size: 'small'
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel required>
                                    Earliest Pickup Time
                                </FormLabel>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                        onChange={handleEarliestPickupTime}
                                        value={earliestPickupTime}
                                        maxTime={dayjs().hour(16).minute(0).second(0)}
                                        minTime={dayjs().hour(7).minute(0).second(0)}
                                        renderInput={(props) =>
                                            <TextField
                                                {...props}
                                                fullWidth
                                            />}
                                        slotProps={{
                                            textField: {
                                                helperText: !isValid(earliestPickupTime?.$d) && 'Invalid Pickup Earliest Time',
                                                size: 'small'
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel required>
                                    Latest Pickup Time
                                </FormLabel>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                        onChange={handleLatestPickupTime}
                                        value={latestPickupTime}
                                        maxTime={dayjs().hour(19).minute(0).second(0)}
                                        minTime={dayjs().hour(10).minute(0).second(0)}
                                        renderInput={(props) =>
                                            <TextField
                                                {...props}
                                                fullWidth
                                            />}
                                        slotProps={{
                                            textField: {
                                                helperText: !isValid(latestPickupTime?.$d) && 'Invalid Pickup Latest Time',
                                                size: 'small'
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            {
                                accountBase === "CanadaPost" && <Box>
                                    <Typography style={{
                                        fontSize: '14px',
                                        fontStyle: 'italic'
                                    }}>
                                        Note: Please keep in mind that for on-demand pickups with CanadaPost, the earliest
                                        available pickup time is between noon (12:00) and 4:00 p.m. (16:00). Additionally,
                                        the latest pickup time must be scheduled at least one hour after your preferred
                                        time, and all pickup times are in 15-minute intervals, with options such as 00, 15,
                                        30, or 45 minutes.
                                    </Typography>
                                </Box>
                            }
                            {
                                (accountBase === 'FedEx' && fedexPickupTime) && <Box>
                                    <Typography style={{
                                        fontSize: '14px',
                                        fontStyle: 'italic'
                                    }}>
                                        Note: Please keep in mind that for on-demand pickups with FedEx, the default
                                        earliest pickup time is {fedexPickupTime?.defaultReadyTime} and the latest pickup
                                        time is {fedexPickupTime?.defaultLatestTimeOptions} based on the provided
                                        information. It is strongly recommended to use the default pickup time to ensure
                                        successful scheduling. Failure to do so may result in pickup scheduling issues.
                                    </Typography>
                                </Box>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        <LoadingButton
                            variant='contained'
                            color='success'
                            size='large'
                            loading={loading}
                            onClick={handleSchedule}
                        >
                            <Typography style={{fontSize: '16px', textTransform: 'none'}}>
                                Submit
                            </Typography>
                        </LoadingButton>
                    </Box>
                </Grid>
            </Grid>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    maxWidth: 500,
                    maxHeight: 400,
                    backgroundColor: '#FFFFFF',
                    border: '2px solid #000',
                    borderRadius: '10px',
                    boxShadow: 24,
                    padding: '40px 20px',
                    overflow: 'auto'
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', minWidth: 400}}>
                                <Typography style={{fontSize: '20px'}}>
                                    My Address List
                                </Typography>
                                <Button
                                    color='success'
                                    onClick={handleNewAddress}
                                >
                                    New Address
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '5px',
                    }}>
                        {
                            addressList?.length > 0 ? addressList.map((address, index) =>
                                    (
                                        <Box
                                            sx={{
                                                border: '1px solid #000000',
                                                borderRadius: '5px',
                                                padding: '10px',
                                                "&:hover": {
                                                    cursor: "pointer",
                                                    background: "aliceblue"
                                                },
                                            }}
                                            key={index}
                                            onClick={() => handleSelectAddress(address)}
                                        >
                                            <Grid container spacing={2}>
                                                <Grid item xs={2}>
                                                    <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
                                                        <HomeIcon color='success'/>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                                        <Typography
                                                            style={{
                                                                fontSize: '14px'
                                                            }}>
                                                            {address?.name}
                                                        </Typography>
                                                        <Typography
                                                            style={{
                                                                fontSize: '14px'
                                                            }}>
                                                            {address?.address_2 && `${address?.address_2} - `}{address?.address}
                                                        </Typography>
                                                        <Typography
                                                            style={{
                                                                fontSize: '14px'
                                                            }}>
                                                            {address?.city}, {address?.province}, {address?.country} {address?.postal_code}
                                                        </Typography>
                                                        <Typography
                                                            style={{
                                                                fontSize: '14px'
                                                            }}>
                                                            {address?.address_email}
                                                        </Typography>
                                                        <Typography
                                                            style={{
                                                                fontSize: '14px'
                                                            }}>
                                                            {address?.phone}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                {/*<Grid item xs={2}>*/}
                                                {/*    <Box sx={{display: 'flex', flexDirection: 'column'}}>*/}
                                                {/*        <Button>*/}
                                                {/*            */}
                                                {/*        </Button>*/}
                                                {/*    </Box>*/}
                                                {/*</Grid>*/}
                                            </Grid>
                                        </Box>

                                    ))
                                : <Grid item xs={12}>
                                    <Typography>
                                        No Addresses
                                    </Typography>
                                </Grid>
                        }
                    </Box>
                </Box>
            </Modal>
        </Container>
    )
}