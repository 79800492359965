import Box from "@mui/material/Box";
import MuiAlert from "@mui/material/Alert";
import * as React from 'react';
import {useState} from "react";
import {Button, IconButton, Snackbar, Tab, Tabs} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import AddIcon from "@material-ui/icons/Add";
import {useDispatch, useSelector} from "react-redux";
import {toggleAddAddress, updateAddressBookTabValue} from "../../actions/addressBookAction";
import {AddressBookAdd} from "./AddressBookAdd";
import {AddressBookList} from "./AddressBookList";
import {AddressBookEdit} from "./AddressBookEdit";
import TextField from "@mui/material/TextField";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../Utils/doToken";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
    AddressBookRoot: {
        width: '1000px',
        margin: 'auto'
    },
    AddressBookHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '10px 0'
    },
    AddressListHeader: {
        backgroundColor: '#444444',
        color: '#ffffff',
        borderRadius: '5px',
        margin: '10px 0',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
    }
}

export const AddressBook = () => {

    const accessToken = getAccessToken("access_token");

    let isAddAddress = useSelector(state => state?.addressBook?.isAddAddress);
    let addressTab = useSelector(state => state?.addressBook?.addressTab);
    let isEditAddress = useSelector(state => state?.addressBook?.isEditAddress);
    const {id: partnerId, email} = useSelector((state) => state.user);

    const dispatch = useDispatch();

    const [addressList, setAddressList] = useState([]);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleTabChange = (event, newValue) => {
        dispatch(updateAddressBookTabValue(newValue));
    }

    const handleOpenAddAddress = () => {
        dispatch(toggleAddAddress(true));
    }

    const searchAddress = async (searchString) => {
        let requestURL = `${NODE_ROUTE_URI}/elasticsearch/addressBook/searchEndUserAddressBook`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                params: {
                    role: addressTab === 'sender' ? 'SENDER' : 'RECEIVER',
                    queryString: searchString
                }
            })

            console.log('result', result?.data);
            setAddressList(result?.data);

        } catch (e) {
            console.log('error', e.response)
        }
    }

    const handleSearch = (event) => {
        const searchString = event.target.value || (+partnerId);
        searchAddress(searchString);
    }

    // console.log('is edit', isEditAddress);
    // console.log('address tap', addressTab);

    return (

        <Box sx={style.AddressBookRoot}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Typography variant="h4" style={{textAlign: "center"}}>
                My Address List
            </Typography>
            {!isAddAddress && !isEditAddress &&
                <Grid container spacing={4}>
                    <Grid item xs={3}>
                        <Tabs
                            value={addressTab}
                            onChange={handleTabChange}
                            aria-label="wrapped label tabs example"
                        >
                            <Tab value="sender" label="Sender"/>
                            <Tab value="deliver" label="Deliver"/>
                        </Tabs>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
                            <TextField
                                onInput={handleSearch}
                                fullWidth
                                placeholder='Search'
                                size="small"
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Button onClick={handleOpenAddAddress}>
                            <IconButton>
                                <AddIcon/>
                            </IconButton>
                            Add Address
                        </Button>
                    </Grid>
                </Grid>
            }
            {isAddAddress && <AddressBookAdd/>}
            {isEditAddress && <AddressBookEdit/>}
            {!isAddAddress && !isEditAddress &&
                <Box sx={style.AddressListHeader}>
                    <Grid container spacing={2}>
                        <Grid item xs={3} style={{textAlign: 'center'}}>
                            <Typography>
                                NAME
                            </Typography>
                        </Grid>
                        <Grid item xs={4} style={{textAlign: 'center'}}>
                            <Typography>
                                ADDRESS
                            </Typography>
                        </Grid>
                        <Grid item xs={2} style={{textAlign: 'center'}}>
                            <Typography>
                                PHONE
                            </Typography>
                        </Grid>
                        <Grid item xs={1} style={{textAlign: 'center'}}>
                            <Typography>
                                EDIT
                            </Typography>
                        </Grid>
                        <Grid item xs={2} style={{textAlign: 'center'}}>
                            <Typography>
                                DELETE
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            }
            {
                !isAddAddress && !isEditAddress && <AddressBookList addressListAPI={addressList}/>
            }
        </Box>

    )
}