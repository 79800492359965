import {ORDER_DASHBOARD_IS_CROSS_BORDER} from "../actions/types";

const initialState = {
    isCrossBorder: false
}

export const orderDashboardReducer = (state= initialState, action) => {
    switch (action.type) {
        case ORDER_DASHBOARD_IS_CROSS_BORDER:
            return {...state, isCrossBorder: action?.payload}

        default:
            return state
    }
}