import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import {
  fetchingShipments,
  stackShipments,
} from "../../actions/fetchShipmentsAction";
import store from "../../store/index";
import {
  GET_AVAILABLE_SHIPMENTS,
  GET_DRIVER_CLAIMED_SHIPMENTS,
  GET_DRIVER_DELIVERED_SHIPMENTS,
  GET_SERVER_DRIVER_DELIVERED_SHIPMENTS,
  GET_DRIVER_LOADED_SHIPMENTS,
  GET_SERVER_DRIVER_LOADED_SHIPMENTS,
} from "../../Utils/apiUrl";
import { getTokenFromCookie } from "../../Utils/doToken";

const useShipmentsFetch = (type, pageNumber, searchOptions) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [shipments, setShipments] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [lastPage, setLastPage] = useState(0);
  const updateFilter = useRef(false);

  const headers = {
    Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
  };

  useEffect(() => {
    setShipments([]);
  }, []);

  useEffect(() => {
    updateFilter.current = true;
  }, [searchOptions]);

  useEffect(() => {
    setLoading(true);
    store.dispatch(fetchingShipments(true));
    setError(false);
    if (type === "available_shipment_tasks") {
      if (updateFilter.current && pageNumber === 1) {
        axios
          .post(
            GET_AVAILABLE_SHIPMENTS,
            {
              page: pageNumber,
              // min_unit_price: searchOptions.unitPrice[0],
              // max_unit_price: searchOptions.unitPrice[1],
              // min_total_price: searchOptions.totalPrice[0],
              // max_total_price: searchOptions.totalPrice[1],
              // shipmentSize: searchOptions.shipmentSize,
              pickupCities: searchOptions.pickupCity,
              deliverCities: searchOptions.deliverCity,
              pickupDateFrom: searchOptions.pickupDateFrom,
              pickupDateTo: searchOptions.pickupDateTo,
              // option_pickup_loading_dock: searchOptions.pickupLoadingDock
              //   ? "y"
              //   : "",
              // option_deliver_loading_dock: searchOptions.deliverLoadingDock
              //   ? "y"
              //   : "",
              // option_deliver_appointment: searchOptions.deliverAppointment
              //   ? "y"
              //   : "",

              extraOptions: {
                optionPickupLoadingDock: searchOptions.pickupLoadingDock
                  ? "y"
                  : "",
                optionDeliverLoadingDock: searchOptions.deliverLoadingDock
                  ? "y"
                  : "",
                optionDeliverAppointment: searchOptions.deliverAppointment
                  ? "y"
                  : "",
              },
              // local_area_group_id: searchOptions.area,
              // remote_freight_flag: searchOptions.remoteFreight,
              // local_freight_flag: searchOptions.localFreight,
            },
            {
              // params: {
              //   page: pageNumber,
              //   // min_unit_price: searchOptions.unitPrice[0],
              //   // max_unit_price: searchOptions.unitPrice[1],
              //   // min_total_price: searchOptions.totalPrice[0],
              //   // max_total_price: searchOptions.totalPrice[1],
              //   shipment_size: searchOptions.shipmentSize,
              //   pickup_cities: searchOptions.pickupCity,
              //   deliver_cities: searchOptions.deliverCity,
              //   pickup_date_from: searchOptions.pickupDateFrom,
              //   pickup_date_to: searchOptions.pickupDateTo,
              //   option_pickup_loading_dock: searchOptions.pickupLoadingDock
              //     ? "y"
              //     : "",
              //   option_deliver_loading_dock: searchOptions.deliverLoadingDock
              //     ? "y"
              //     : "",
              //   option_deliver_appointment: searchOptions.deliverAppointment
              //     ? "y"
              //     : "",
              //   local_area_group_id: searchOptions.area,
              //   remote_freight_flag: searchOptions.remoteFreight,
              //   local_freight_flag: searchOptions.localFreight,
              // },
              headers: headers,
            }
          )
          .then((res) => {
            store.dispatch(stackShipments(res.data?.taskList));
            setShipments(res.data?.taskList);
            setLastPage(res.data.lastPage);
            setHasMore(res.data?.taskList?.length > 0);
            setLoading(false);
            store.dispatch(fetchingShipments(false));
          })
          .catch((err) => {
            setError(true);
            console.log("error message", err);
          });
        updateFilter.current = false;
      } else if (!updateFilter.current) {
        //normal changing page
        axios
          .post(
            GET_AVAILABLE_SHIPMENTS,
            {
              page: pageNumber,
              // min_unit_price: searchOptions.unitPrice[0],
              // max_unit_price: searchOptions.unitPrice[1],
              // min_total_price: searchOptions.totalPrice[0],
              // max_total_price: searchOptions.totalPrice[1],
              // shipmentSize: searchOptions.shipmentSize,
              pickupCities: searchOptions.pickupCity,
              deliverCities: searchOptions.deliverCity,
              pickupDateFrom: searchOptions.pickupDateFrom,
              pickupDateTo: searchOptions.pickupDateTo,
              // option_pickup_loading_dock: searchOptions.pickupLoadingDock
              //   ? "y"
              //   : "",
              // option_deliver_loading_dock: searchOptions.deliverLoadingDock
              //   ? "y"
              //   : "",
              // option_deliver_appointment: searchOptions.deliverAppointment
              //   ? "y"
              //   : "",

              extraOptions: {
                optionPickupLoadingDock: searchOptions.pickupLoadingDock
                  ? "y"
                  : "",
                optionDeliverLoadingDock: searchOptions.deliverLoadingDock
                  ? "y"
                  : "",
                optionDeliverAppointment: searchOptions.deliverAppointment
                  ? "y"
                  : "",
              },
            },
            {
              // params: {
              //   page: pageNumber,
              //   // min_unit_price: searchOptions.unitPrice[0],
              //   // max_unit_price: searchOptions.unitPrice[1],
              //   // min_total_price: searchOptions.totalPrice[0],
              //   // max_total_price: searchOptions.totalPrice[1],
              //   shipment_size: searchOptions.shipmentSize,
              //   pickup_cities: searchOptions.pickupCity,
              //   deliver_cities: searchOptions.deliverCity,
              //   pickup_date_from: searchOptions.pickupDateFrom,
              //   pickup_date_to: searchOptions.pickupDateTo,
              //   option_pickup_loading_dock: searchOptions.pickupLoadingDock
              //     ? "y"
              //     : "",
              //   option_deliver_loading_dock: searchOptions.deliverLoadingDock
              //     ? "y"
              //     : "",
              //   option_deliver_appointment: searchOptions.deliverAppointment
              //     ? "y"
              //     : "",
              //   local_area_group_id: searchOptions.area,
              //   remote_freight_flag: searchOptions.remoteFreight,
              //   local_freight_flag: searchOptions.localFreight,
              // },
              headers: headers,
            }
          )
          .then((res) => {
            console.log(
              "change page, getting available shipments: ",
              res.data?.taskList
            );
            store.dispatch(stackShipments(res.data?.taskList));
            setShipments(res.data?.taskList);
            setLastPage(res.data.lastPage);
            setHasMore(res.data?.taskList?.length > 0);
            setLoading(false);
            store.dispatch(fetchingShipments(false));
          })
          .catch((err) => {
            setError(true);
            console.log("error message", err);
          });
      }
    } else if (type === "claimed_shipment_tasks") {
      axios
        .get(GET_DRIVER_CLAIMED_SHIPMENTS, {
          headers: headers,
          params: {
            page: pageNumber,
          },
        })
        .then((res) => {
          console.log(res.data);
          setShipments(res.data.results);
          setHasMore(res.data.results.length > 0);
          setLoading(false);
          setLastPage(res.data.lastPage);
        })
        .catch((err) => {
          setError(true);
          console.log("error message", err);
        });
    } else if (type === "pickedup_shipment_tasks") {
      axios
        .get(GET_SERVER_DRIVER_LOADED_SHIPMENTS, {
          headers: headers,
          params: {
            page: pageNumber,
          },
        })
        .then((res) => {
          console.log("picked up shipment tasks are: ", res.data);
          // setShipments(res.data.data);
          // setHasMore(res.data.data.length > 0);
          setShipments(res.data.results);
          setHasMore(res.data.results.length > 0);
          setLoading(false);
          setLastPage(res.data.lastPage);
        })
        .catch((err) => {
          setError(true);
          console.log("error message", err);
        });
    } else if (type === "delivered_shipment_tasks") {
      axios
        .get(GET_SERVER_DRIVER_DELIVERED_SHIPMENTS, {
          headers: headers,
          params: {
            page: pageNumber,
          },
        })
        .then((res) => {
          console.log("delivered shipments are: ", res);
          setShipments(res.data.results);
          setHasMore(res.data.results.length > 0);
          setLoading(false);
          setLastPage(res.data.lastPage);
        })
        .catch((err) => {
          setError(true);
          console.log("error message", err);
        });
    }
  }, [pageNumber, searchOptions]);

  return { loading, error, shipments, hasMore, lastPage };
};

export default useShipmentsFetch;
