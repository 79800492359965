import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Collapse, TextField } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import PropTypes from "prop-types";
import axios from "axios";

import { SETTLEMENT_DISPUTE } from "../../../Utils/apiUrl";
import { getHeaders } from "../../../Utils/fetchUtil";

const DisputeModule = ({ settlementNum, type }) => {
  //   const [page, setPage] = useState(1);
  //   const [lastPage, setLastPage] = useState(0);

  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const [message, setMessage] = useState("");

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  // Submit dispute to the server
  const handleDisputeSubmit = (type, number, message) => {
    const postData = {
      settlement: number,
      type: type,
      reasons: message || "",
    };

    console.log(SETTLEMENT_DISPUTE("sallie.kunze@example.org"), postData);
    axios({
      method: "post",
      url: "https://api.uucargo.ca/api/driver/disputesettlement",
      data: JSON.stringify(postData),
      headers: {
        ...getHeaders(),
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    })
      .then((response) => {
        setOpen(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <Box pt={open ? 1 : 0} className={open ? classes.feeWrapper : ""}>
      <Box display="flex" justifyContent="space-between" ml={1} mr={1}>
        <Box fontSize="1.2rem">{open ? "Dispute" : ""}</Box>
        <Button
          onClick={() => setOpen((prev) => !prev)}
          style={{
            color: "grey",
            textTransform: "unset",
            fontSize: "1rem",
          }}
        >
          {open ? <ClearIcon /> : "Dispute"}
        </Button>
      </Box>
      <Collapse in={open}>
        <Box m={1}>
          <Box>
            Settlement #:{" "}
            <Box component="span" fontWeight="600">
              {settlementNum}
            </Box>
          </Box>
          <Box mb={1}>
            Type:{" "}
            <Box component="span" fontWeight="600">
              {type}
            </Box>
          </Box>
          <TextField
            className={classes.textfield}
            InputProps={{ disableUnderline: true }}
            onChange={handleChange}
            value={message}
            variant="filled"
            rows={2}
            label="Dispute description"
            multiline
          />
          <Box display="flex" justifyContent="center" mb={2} mt={2}>
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  handleDisputeSubmit(type, settlementNum, message)
                }
                style={{
                  textTransform: "unset",
                  fontSize: "1rem",
                }}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  itemWrapper: {
    backgroundColor: "#fbfbf8",
    padding: theme.spacing(0.5),
    borderRadius: "5px",
  },
  feeWrapper: {
    // border: "1px solid rgba(0,0,0,0.3)",
    borderRadius: 5,
    marginBottom: theme.spacing(2),
    backgroundColor: "#FEEAAE",
  },
  textfield: {
    width: "100%",
  },
}));

DisputeModule.propTypes = {
  settlementNum: PropTypes.string,
  type: PropTypes.string,
  message: PropTypes.string.isRequired,
  setMessage: PropTypes.func.isRequired,
};

// export default connect(mapStateToProps, {getOrders, getPackages, getOrderShipments})(OrderList)
export default DisputeModule;
