import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import {ReactComponent as CrossBorderLogo} from "../../../images/crossborderIcon/CrossBorder.svg";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {LTLCrossBorderDashboardItemList} from "./LTLCrossBorderDashboardItemList";
import {LTLCrossBorderDashboardInvoiceList} from "./LTLCrossBorderDashboardInvoiceList";
import {useRef, useState} from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import {getAccessToken} from "../../../Utils/doToken";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {Snackbar} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from "@mui/material/AccordionDetails";
import {LTLCrossBorderDashboardInvoiceRemove} from "./LTLCrossBorderDashboardInvoiceRemove";
import {LTLCrossBorderDashboardPackingListRemove} from "./LTLCrossBorderDashboardPackingListRemove";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const LTLCrossBorderDashboardInvoice = ({orderData, getOrderData}) => {

    const storedToken = getAccessToken("access_token");

    const [expanded, setExpanded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [invoiceList, setInvoiceList] = useState([]);
    const [isCreate, setIsCreate] = useState(false);
    const [isCreatePacking, setIsCreatePacking] = useState(false);
    const [invoiceMessage, setInvoiceMessage] = useState(false);

    const inputPDFFile = useRef(null);
    const inputPDFFilePacking = useRef(null);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const openCreateInvoice = () => {
        if (orderData?.packingList?.length > 0) {
            setIsCreate(true);
            setInvoiceMessage(false);
        } else {
            setIsCreate(false);
            setInvoiceMessage(true);
        }
    }

    const closeCreateInvoice = () => {
        setIsCreate(false);
    }

    const openCreatePacking = () => {
        setIsCreatePacking(true);
    }

    const closeCreatePacking = () => {
        setIsCreatePacking(false);
    }

    const addInvoiceList = (invoice) => {
        console.log('invoice data', invoice);
        setInvoiceList(prevState => [...prevState, invoice]);
    }

    const removeInvoiceList = (invoice) => {
        const invoiceIdToFind = invoice?.invoiceId;
        const filterInvoiceList = invoiceList?.filter(each => each?.invoiceId !== invoiceIdToFind);
        setInvoiceList(filterInvoiceList);
    }

    const clearInvoiceList = () => {
        setInvoiceList([]);
    }

    const uploadPDFToServer = async ({fileObj}) => {
        // console.log('file object', fileObj);
        setLoading(true);
        if (!fileObj) {
            return;
        }
        const formData = new FormData();
        formData.append('invoices', fileObj);
        formData.append('orderId', orderData?.id);
        const requestURL = `${PARTNER_URI}/shippingLTL/uploadLTLCommercialInvoice`;

        try {

            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    "Content-Type": "multipart/form-data;",
                    Authorization: `Bearer ${storedToken}`,
                },
                data: formData,
            })

            console.log('result', result);
            setErrorMessage('');
            setSuccessMessage('Successfully upload invoices');
            handleSuccessMessage();
            setTimeout(() => {
                getOrderData();
                // window.location.reload();
            }, 1000);
        } catch (e) {
            console.log('error', e?.response);
            setErrorMessage('Fail to upload invoices');
            handleErrorMessage();
        } finally {
            setLoading(false);
        }

    }

    const uploadPackingListPDFToServer = async ({fileObj}) => {
        // console.log('file object', fileObj);
        setLoading(true);
        if (!fileObj) {
            return;
        }
        const formData = new FormData();
        formData.append('files', fileObj);
        formData.append('backendOrderId', orderData?.id);
        const requestURL = `${PARTNER_URI}/packingList/uploadPackingListFile`;

        try {

            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    "Content-Type": "multipart/form-data;",
                    Authorization: `Bearer ${storedToken}`,
                },
                data: formData,
            })

            console.log('result', result);
            setErrorMessage('');
            setSuccessMessage('Successfully upload packing list file');
            handleSuccessMessage();
            setTimeout(() => {
                getOrderData();
                // window.location.reload();
            }, 1000);
        } catch (e) {
            console.log('error', e?.response);
            setErrorMessage('Fail to upload packing list file');
            handleErrorMessage();
        } finally {
            setLoading(false);
        }

    }

    const onPDFDrop = async (e) => {
        if (!(e.target.files && e.target.files[0])) {
            return;
        }
        await uploadPDFToServer({fileObj: e.target.files[0]});
    };

    const onPDFDropPacking = async (e) => {
        if (!(e.target.files && e.target.files[0])) {
            return;
        }
        await uploadPackingListPDFToServer({fileObj: e.target.files[0]});
    };

    return (
        <Box sx={{
            // border: '1px solid #B2B2B2',
            borderRadius: '10px',
            padding: '20px',
            boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        }}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid container spacing={2}>
                <Grid item md={3} xs={12}>
                    <Box sx={{
                        display: 'flex',
                        height: '150px',
                        alignItems: 'center'
                    }}>
                        <CrossBorderLogo width="100%" height="100%"/>
                    </Box>
                </Grid>
                <Grid item md={9} xs={12}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            gap: '5px'
                        }}>
                            <Typography style={{fontSize: '16px'}}>
                                1.
                            </Typography>
                            <Typography style={{fontSize: '16px', textAlign: 'left'}}>
                                <span style={{fontWeight: '600'}}>Create or Upload Packing List:</span> Create a custom packing
                                list using our system or upload your own custom packing list.
                            </Typography>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            gap: '5px'
                        }}>
                            <Typography style={{fontSize: '16px'}}>
                                2.
                            </Typography>
                            <Typography style={{fontSize: '16px', textAlign: 'left'}}>
                                <span style={{fontWeight: '600'}}>Create or Upload Invoice:</span> Create a commercial invoice
                                using our system or upload your own commercial invoice.
                            </Typography>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            gap: '5px'
                        }}>
                            <Typography style={{fontSize: '16px'}}>
                                3.
                            </Typography>
                            <Typography style={{fontSize: '16px', textAlign: 'left'}}>
                                <span style={{fontWeight: '600'}}>Prepare for Shipment:</span> Print the invoice
                                forms, place them in a small bag, and attach it next to the shipping
                                label on your pallet.
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={12} xs={12}>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography sx={{width: '50%', flexShrink: 0, fontSize: '18px'}}>
                                Packing List
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Typography sx={{fontSize: '18px'}}>
                                        {(orderData?.packingList?.length > 0 || orderData?.packingListFiles?.length > 0) ? 'Complete' : 'Incomplete'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Box sx={{
                                        // backgroundColor: '#1D8B45',
                                        width: '25px',
                                        height: '25px',
                                        borderRadius: '50%',
                                        backgroundImage: `linear-gradient(to right, rgba(29, 139, 69, 1) ${(orderData?.packingList?.length > 0 || orderData?.packingListFiles?.length > 0) ? '100%' : '50%'}, transparent 50%)`
                                    }}/>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <LTLCrossBorderDashboardItemList
                                        orderData={orderData}
                                        isCreate={isCreatePacking}
                                        getOrderData={getOrderData}
                                        closeCreatePacking={closeCreatePacking}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{
                                        display: 'flex',
                                        width: '100%',
                                        height: '100%',
                                        gap: '20px',
                                        justifyContent: 'flex-end'
                                    }}>
                                        <Button
                                            variant='contained'
                                            sx={{
                                                backgroundColor: '#1D8B45',
                                                "&:hover": {
                                                    backgroundColor: '#1D8B45',
                                                    filter: 'brightness(0.9)'
                                                }
                                            }}
                                            onClick={openCreatePacking}
                                            // disabled={invoiceStatus}
                                        >
                                            <Typography style={{textTransform: 'none'}}>
                                                Create Packing List
                                            </Typography>
                                        </Button>
                                        <input
                                            type='file' style={{display: 'none'}}
                                            ref={inputPDFFilePacking}
                                            onChange={onPDFDropPacking}
                                            accept=".pdf" name='file'
                                        />
                                        <LoadingButton
                                            variant='contained'
                                            sx={{
                                                backgroundColor: '#1D8B45',
                                                "&:hover": {
                                                    backgroundColor: '#1D8B45',
                                                    filter: 'brightness(0.9)'
                                                }
                                            }}
                                            onClick={() => {
                                                inputPDFFilePacking.current.click()
                                            }}
                                            loading={loading}
                                            // disabled={invoiceList?.length > 0}
                                        >
                                            <Typography style={{textTransform: 'none'}}>
                                                Upload Packing List
                                            </Typography>
                                        </LoadingButton>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    {
                                        orderData?.packingListFiles?.length > 0 &&
                                        <LTLCrossBorderDashboardPackingListRemove orderData={orderData}
                                                                                  getOrderData={getOrderData}/>
                                    }
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item md={12} xs={12}>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                        >
                            <Typography sx={{width: '50%', flexShrink: 0, fontSize: '18px'}}>
                                Commercial Invoice
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Typography sx={{fontSize: '18px'}}>
                                        {orderData?.commecialInvoices?.length > 0 ? 'Complete' : 'Incomplete'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Box sx={{
                                        // backgroundColor: '#1D8B45',
                                        width: '25px',
                                        height: '25px',
                                        borderRadius: '50%',
                                        backgroundImage: `linear-gradient(to right, rgba(29, 139, 69, 1) ${orderData?.commecialInvoices?.length > 0 ? '100%' : '50%'}, transparent 50%)`
                                    }}/>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <LTLCrossBorderDashboardInvoiceList
                                        orderData={orderData}
                                        invoiceList={invoiceList}
                                        isCreate={isCreate}
                                        closeCreateInvoice={closeCreateInvoice}
                                        addInvoiceList={addInvoiceList}
                                        removeInvoiceList={removeInvoiceList}
                                        clearInvoiceList={clearInvoiceList}
                                        getOrderData={getOrderData}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{
                                        display: 'flex',
                                        width: '100%',
                                        height: '100%',
                                        gap: '20px',
                                        justifyContent: 'flex-end'
                                    }}>
                                        <Button
                                            variant='contained'
                                            sx={{
                                                backgroundColor: '#1D8B45',
                                                "&:hover": {
                                                    backgroundColor: '#1D8B45',
                                                    filter: 'brightness(0.9)'
                                                }
                                            }}
                                            onClick={openCreateInvoice}
                                            // disabled={invoiceStatus}
                                        >
                                            <Typography style={{textTransform: 'none'}}>
                                                Create Invoice
                                            </Typography>
                                        </Button>
                                        <input type='file' style={{display: 'none'}} ref={inputPDFFile}
                                               onChange={onPDFDrop}
                                               accept=".pdf" name='file'/>
                                        <LoadingButton
                                            variant='contained'
                                            sx={{
                                                backgroundColor: '#1D8B45',
                                                "&:hover": {
                                                    backgroundColor: '#1D8B45',
                                                    filter: 'brightness(0.9)'
                                                }
                                            }}
                                            onClick={() => {
                                                inputPDFFile.current.click()
                                            }}
                                            loading={loading}
                                            // disabled={invoiceList?.length > 0}
                                        >
                                            <Typography style={{textTransform: 'none'}}>
                                                Upload Invoice
                                            </Typography>
                                        </LoadingButton>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    {
                                        (orderData?.packingList?.length === 0 && invoiceMessage) &&
                                        <Typography>
                                            To create an invoice in our system, please first generate a packing list
                                            using the system.
                                        </Typography>
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    {
                                        orderData?.commecialInvoices?.length > 0 &&
                                        <LTLCrossBorderDashboardInvoiceRemove orderData={orderData}
                                                                              getOrderData={getOrderData}/>
                                    }
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        </Box>
    )
}