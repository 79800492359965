import {Container} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {CrossBorderDashboardInvoice} from "./CrossBorderDashboardInvoice";
import Grid from "@mui/material/Grid";
import React, {useEffect, useState} from "react";
import {LABEL_DOWNLOAD_URL_PREFIX, NODE_ROUTE_URI} from "../../Utils/apiUrl";
import {getAccessToken} from "../../Utils/doToken";
import axios from "axios";
import {useForm} from "react-hook-form";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {CrossBorderDashboardTrackingList} from "./CrossBorderDashboardTrackingList";
import CommercialInvoiceTransactionTable from "../CommercialInvoice/commercialInvoiceTransactionTable";

export const CrossBorderDashboard = () => {

    const {order_id: default_order_id} = useParams();

    const partnerInfo = useSelector((state) => state.user);
    const token = getAccessToken("access_token");

    const {register, handleSubmit, control} = useForm();
    const [shippingListData, setShippingListData] = useState([]);
    const [page, setPage] = useState(1);
    const [totalNumber, setTotalNumber] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [selectUPS, setSelectUPS] = useState(false);
    const [selectFedEx, setSelectFedEx] = useState(false);
    const [trackingList, setTrackingList] = useState([]);

    const getTrackingList = async () => {
        let requestURI = `${NODE_ROUTE_URI}/shippingOrderDetailsDashboard/getTrackingViewByShippingOrderId/${default_order_id}`;
        try {
            const results = await axios({
                method: 'get',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${token}`
                },
            })
            const {data} = results;

            console.log('get tracking list', data);

            setTrackingList(data);

        } catch (e) {
            console.log(e)
        }
    }

    const getData = async ({
                               order_id,
                               product_id,
                               ref_other_no,
                               cargo_control_no,
                               send_to_name,
                               send_to_tel,
                               send_to_addr,
                               currentPage
                           }) => {
        let requestURI = `${NODE_ROUTE_URI}/shippingOrderDetailsDashboard/endUser/getTransactionsByOrderId?&orderId=${order_id}&productId=${product_id}&refOtherNumber=${ref_other_no}&cargoControlNumber=${cargo_control_no}&sendToName=${send_to_name}&sendToPhone=${send_to_tel}&sendToAddr=${send_to_addr}&page=${currentPage - 1}`;

        if ((selectUPS && selectFedEx) || (!selectUPS && !selectFedEx)) {
            requestURI = `${NODE_ROUTE_URI}/shippingOrderDetailsDashboard/endUser/getTransactionsByOrderId?&orderId=${order_id}&product_id=${product_id}&ref_other_no=${ref_other_no}&partner_id=${partnerInfo.id}&cargo_control_no=${cargo_control_no}&send_to_name=${send_to_name}&send_to_tel=${send_to_tel}&send_to_addr=${send_to_addr}&page=${currentPage - 1}`;
        } else if (selectUPS) {
            requestURI = `${NODE_ROUTE_URI}/shippingOrderDetailsDashboard/endUser/getTransactionsByOrderId?account_base=ups&orderId=${order_id}&product_id=${product_id}&ref_other_no=${ref_other_no}&partner_id=${partnerInfo.id}&cargo_control_no=${cargo_control_no}&send_to_name=${send_to_name}&send_to_tel=${send_to_tel}&send_to_addr=${send_to_addr}&page=${currentPage - 1}`;
        } else if (selectFedEx) {
            requestURI = `${NODE_ROUTE_URI}/shippingOrderDetailsDashboard/endUser/getTransactionsByOrderId?account_base=fedex&orderId=${order_id}&product_id=${product_id}&ref_other_no=${ref_other_no}&partner_id=${partnerInfo.id}&cargo_control_no=${cargo_control_no}&send_to_name=${send_to_name}&send_to_tel=${send_to_tel}&send_to_addr=${send_to_addr}&page=${currentPage - 1}`;
            ;
        }
        const accessToken = getAccessToken("access_token");
        const {data} = await axios.get(requestURI, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
        const result = data.result;
        const resultTotalNumber = data.totalNumber;
        setTotalNumber(resultTotalNumber);
        setShippingListData(result);
        try {
        } catch (e) {
            setTotalNumber(0);
            console.log(e);
            setShippingListData([]);
        } finally {
            setIsLoading(false);
        }
    }


    const cbGetTrackingList= () => {
        getTrackingList();
    }

    useEffect(() => {
        getTrackingList();
    }, [])

    useEffect(() => {
        if (partnerInfo?.id) {
            getData({
                order_id: default_order_id,
                currentPage: 1,
                cargo_control_no: "",
                product_id: "",
                ref_other_no: "",
                send_to_name: "",
                send_to_addr: "",
                send_to_tel: ""
            })
        }
    }, [partnerInfo?.id]);

    if (!partnerInfo?.id) {
        return <Box>Loading...</Box>
    }

    console.log('shipping list data', shippingListData);

    return (
        <Container maxWidth='xl'>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px'
            }}>
                <Typography style={{fontSize: '22px', fontWeight: '600'}}>
                    Cross Border Information
                </Typography>
                <CrossBorderDashboardInvoice shippingListData={shippingListData} cbGetTrackingList={cbGetTrackingList}/>
                <CrossBorderDashboardTrackingList shippingListData={shippingListData} trackingList={trackingList} cbGetTrackingList={cbGetTrackingList}/>
            </Box>
        </Container>
    )
}