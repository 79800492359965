import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import intl from "react-intl-universal";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {Link} from "@mui/material";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
    updateShippingPackageQuickQuoteBatteryDetails,
    updateShippingPackageQuickQuoteSignatureOptions
} from "../../actions/shippingPackageAction";

const styles = {
    ShippingPackageQuickQuoteLocationTitle: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageQuickQuoteLocationHeading: {
        fontSize: '20px',
        textAlign: 'left'
    },
    ShippingPackageQuickQuoteLocationSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    ShippingPackageQuickQuoteLocationBox: {
        padding: '20px',
        // border: '1px solid #000000',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    },
    ShippingPackageQuickQuoteLocationErrorText: {
        fontSize: '12px',
        color: "#FF0303"
    },
    ShippingPackageQuickQuoteLocationRow: {
        display: 'flex',
        alignItems: 'center'
        // justifyContent: 'flex-end',
    }
}

export const ShippingPackageQuickQuoteShippingOptions = () => {

    let isGetRate = useSelector(state => state?.shippingPackage?.quickQuoteIsGetRate);
    let shipFrom = useSelector(state => state?.shippingPackage?.quickQuoteShipFrom);
    let shipTo = useSelector(state => state?.shippingPackage?.quickQuoteShipTo);

    const dispatch = useDispatch();

    const [isBattery, setIsBattery] = useState(false);
    const [batteryDetails, setBatteryDetails] = useState({
        material: null,
        packing: null
    })
    const [isSignatureRequired, setIsSignatureRequired] = useState(false);
    const [signatureRequired, setSignatureRequired] = useState(null);

    const [isShippingUS, setIsShippingUS] = useState(false);

    const handleSignatureRequired = event => {
        setIsSignatureRequired(event?.target?.checked);
        if (event?.target?.checked) {
            setSignatureRequired('DEFAULT')
        } else {
            setSignatureRequired(null)
        }
    }

    const handleIsBattery = event => {
        setIsBattery(event?.target?.checked);
        if (event?.target?.checked) {
            setBatteryDetails(prevState => ({
                ...prevState,
                material: 'LITHIUM_ION',
                packing: 'CONTAINED_IN_EQUIPMENT'
            }))
        } else {
            setBatteryDetails(prevState => ({
                ...prevState,
                material: null,
                packing: null
            }))
        }
    }

    const handleBatteryType = (type) => {
        setBatteryDetails(prevState => ({
            ...prevState,
            material: type
        }))
    }

    const handleBatteryPackaged = (packaged) => {
        setBatteryDetails(prevState => ({
            ...prevState,
            packing: packaged
        }))
    }

    useEffect(() => {
        dispatch(updateShippingPackageQuickQuoteBatteryDetails(batteryDetails));
    }, [batteryDetails]);

    useEffect(() => {
        dispatch(updateShippingPackageQuickQuoteSignatureOptions(signatureRequired));
    },[signatureRequired]);

    useEffect(() => {
        if (shipFrom?.country?.code?.toUpperCase() !== 'CA' || shipTo?.country?.code?.toUpperCase() !== 'CA') {
            setIsShippingUS(true);
            setIsBattery(false);
            setIsSignatureRequired(false);
            setSignatureRequired(null);
            setBatteryDetails(prevState => ({
                ...prevState,
                material: null,
                packing: null
            }))
        } else {
            setIsShippingUS(false);
        }
    }, [shipFrom, shipTo]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography style={styles.ShippingPackageQuickQuoteLocationTitle}>
                    Shipping Options
                </Typography>
            </Grid>
            {
                isShippingUS &&
                <Grid item xs={12}>
                    <Typography style={{textAlign: 'left'}}>
                        The shipping option is exclusively for Canadian domestic shipping services.
                    </Typography>
                </Grid>
            }
            <Grid item xs={12}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                }}>
                    <FormControlLabel
                        value="start"
                        control={<Checkbox
                            checked={isSignatureRequired}
                            onChange={handleSignatureRequired}
                            color="success"
                            disabled={isGetRate || isShippingUS}
                        />}
                        label={
                            <Typography>
                                Signature Options
                            </Typography>
                        }
                        labelPlacement="end"
                    />
                    <Typography style={{fontSize: '14px'}}>
                        Verify delivery of this shipment
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                }}>
                    <FormControlLabel
                        value="start"
                        control={<Checkbox
                            checked={isBattery}
                            onChange={handleIsBattery}
                            color="success"
                            disabled={isGetRate || isShippingUS}
                        />}
                        label={
                            <Typography>
                                Include lithium batteries (hazardous material)
                            </Typography>
                        }
                        labelPlacement="end"
                    />
                    <Typography style={{fontSize: '14px'}}>
                        This shipment contains dangerous goods or hazardous materials
                        <Link
                            href='https://uucargo.ca/support/shipment-of-items-containing-lithium-batteries-with-uucargo/'
                            underline='none'
                            target='_blank'
                        >
                            <span style={{
                                fontWeight: '600',
                                color: '#1D8B45'
                            }}>
                                Learn More
                            </span>
                        </Link>
                    </Typography>
                </Box>
            </Grid>
            {
                isBattery &&
                <>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                        }}>
                            <Typography>
                                Battery Type
                            </Typography>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                gap: '10px'
                            }}>
                                <FormControlLabel
                                    value="start"
                                    control={<Checkbox
                                        checked={batteryDetails?.material === 'LITHIUM_ION'}
                                        onChange={() => handleBatteryType('LITHIUM_ION')}
                                        color="success"
                                        icon={<RadioButtonUncheckedIcon sx={{width: '20px'}}/>}
                                        checkedIcon={<CheckCircleIcon sx={{width: '20px'}}/>}
                                        disabled={isGetRate}
                                    />}
                                    label={
                                        <Typography>
                                            Lithium Ion
                                        </Typography>
                                    }
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value="start"
                                    control={<Checkbox
                                        checked={batteryDetails?.material === 'LITHIUM_METAL'}
                                        onChange={() => handleBatteryType('LITHIUM_METAL')}
                                        color="success"
                                        icon={<RadioButtonUncheckedIcon sx={{width: '20px'}}/>}
                                        checkedIcon={<CheckCircleIcon sx={{width: '20px'}}/>}
                                        disabled={isGetRate}
                                    />}
                                    label={
                                        <Typography>
                                            Lithium Metal
                                        </Typography>
                                    }
                                    labelPlacement="end"
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                        }}>
                            <Typography>
                                How is it packaged?
                            </Typography>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                gap: '10px'
                            }}>
                                <FormControlLabel
                                    value="start"
                                    control={<Checkbox
                                        checked={batteryDetails?.packing === 'CONTAINED_IN_EQUIPMENT'}
                                        onChange={() => handleBatteryPackaged('CONTAINED_IN_EQUIPMENT')}
                                        color="success"
                                        icon={<RadioButtonUncheckedIcon sx={{width: '20px'}}/>}
                                        checkedIcon={<CheckCircleIcon sx={{width: '20px'}}/>}
                                        disabled={isGetRate}
                                    />}
                                    label={
                                        <Typography>
                                            Contained or installed in equipment
                                        </Typography>
                                    }
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value="start"
                                    control={<Checkbox
                                        checked={batteryDetails?.packing === 'PACKED_WITH_EQUIPMENT'}
                                        onChange={() => handleBatteryPackaged('PACKED_WITH_EQUIPMENT')}
                                        color="success"
                                        icon={<RadioButtonUncheckedIcon sx={{width: '20px'}}/>}
                                        checkedIcon={<CheckCircleIcon sx={{width: '20px'}}/>}
                                        disabled={isGetRate}
                                    />}
                                    label={
                                        <Typography>
                                            Packaged separately with other equipment
                                        </Typography>
                                    }
                                    labelPlacement="end"
                                />
                            </Box>
                        </Box>
                    </Grid>
                </>
            }
        </Grid>

    )
}