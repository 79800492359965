import MuiAlert from "@mui/material/Alert";
import {Container, Link, Snackbar} from "@mui/material";
import {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import * as React from 'react';
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import {PackingListItem} from "./PackingListItem";
import Grid from "@material-ui/core/Grid";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {useSelector} from "react-redux";
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import {domain} from "../../shared";

const useStyles = makeStyles((theme) => ({
    BoxRow: {
        display: 'flex',
        gap: '10px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    BoxCol: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '20px'
    },
    FileUpload: {
        display: 'flex',
        gap: '10px',
        justifyContent: 'center',
        alignItems: 'center',
        border: '2px dashed #424242',
        backgroundColor: '#FFF4D2',
        padding: '10px 20px',
        margin: '20px',
        flexDirection: 'column'
    },
    input: {
        display: 'none',
    },

    PackingListTitle: {
        flexGrow: 1,
        margin: '15px auto',
        width: '100%',
        borderBottom: '2px solid #000000'
    },
    PackingListWhite: {
        flexGrow: 1,
        backgroundColor: '#ffffff',
        margin: '15px auto',
        borderRadius: '5px',
        width: '100%'
    },

    PackingListDark: {
        flexGrow: 1,
        backgroundColor: '#eeeeee',
        margin: '15px auto',
        borderRadius: '5px',
        width: '100%'
    },
    ListItem: {
        display: 'flex',
        justifyContent: 'flex-start',
        margin: ' 0 10px'
    }
}));

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const PackingList = () => {

    const id = useSelector((state) => state.user.id);

    const [selectedFile, setSelectedFile] = useState(null);

    const [fileName, setFileName] = useState('');

    const [excelData, setExcelData] = useState(null);

    const [toastOpen, setToastOpen] = useState(false);

    const [uploadStatus, setUploadStatus] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");

    const [successMessage, setSuccessMessage] = useState("");

    const [packingList, setPackingList] = useState([]);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const classes = useStyles();

    const uploadExcelFiles = async (data) => {

        let requestURI = `${PARTNER_URI}/packingList/uploadExcelFiles`;

        try {
            const result = await axios({
                method: 'post',
                url: requestURI,
                params: {partnerId: id},
                data: data,
            })

            const {data: {fileName}} = result;
            setFileName(fileName);
            setSuccessMessage('Successfully upload file.');
            setUploadStatus(true)
            setToastOpen(true);
            await getPackingListArrayByPartnerId()
        } catch (e) {
            console.log(e.response)
            setErrorMessage(`Failed to upload file. ${e?.response?.data}`);
            setToastOpen(true);
            setUploadStatus(false);
        }
    }

    const getPackingListArrayByPartnerId = async () => {
        let requestURI = `${PARTNER_URI}/packingList/getPackingListArrayByPartnerId`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURI,
                params: {partnerId: id}
            })
            const {data} = result;
            setPackingList(data)

        } catch (e) {
            console.log(e.response)
        }
    }

    const deletePackingListByPackingListIdAndPartnerId = async (packingListId) => {
        let requestURI = `${PARTNER_URI}/packingList/deletePackingListByPackingListIdAndPartnerId`;
        try {
            await axios({
                method: 'delete',
                url: requestURI,
                params: {
                    partnerId: id,
                    packingListId: packingListId
                }
            })
            setSuccessMessage('Successfully delete a packingList.');
            setToastOpen(true);
            await getPackingListArrayByPartnerId()
        } catch (e) {
            console.log(e.response)
            setToastOpen(true);
            setErrorMessage('Fail to delete a packingList.');
        }
    }

    useEffect(() => {
        id && getPackingListArrayByPartnerId()
    }, [])

    const handleFileInputChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleFileUpload = () => {
        if (selectedFile) {
            const formData = new FormData();
            formData.append('xlsx_file', selectedFile);
            uploadExcelFiles(formData);
        }
    };

    useEffect(() => {
        handleFileUpload();
    }, [selectedFile])

    console.log(packingList);

    return (
        <Container maxWidth='xl'>
            <Box sx={{margin: 'auto'}}>
                <Typography variant="h4">
                    Upload Packing List
                </Typography>
                <Snackbar
                    anchorOrigin={{vertical: "top", horizontal: "center"}}
                    open={toastOpen}
                    onClose={handleToastClose}
                    autoHideDuration={3000}
                    message="Submit Transaction"
                >
                    {(() => {
                        if (errorMessage !== "") {
                            return (
                                <Alert
                                    onClose={handleToastClose}
                                    severity="error"
                                    sx={{width: "100%"}}
                                >
                                    Error!
                                    <hr/>
                                    {errorMessage}
                                </Alert>
                            );
                        }
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="success"
                                sx={{width: "100%"}}
                            >
                                {successMessage}
                            </Alert>
                        );
                    })()}
                </Snackbar>
                <Box className={classes.BoxCol}>
                    <Box className={classes.BoxRow}>
                        <input
                            accept=".xls,.xlsx"
                            className={classes.input}
                            id="contained-button-file"
                            multiple={false}
                            type="file"
                            onChange={handleFileInputChange}
                        />
                        <label htmlFor="contained-button-file">
                            <Button
                                variant="contained"
                                component="span"
                                // onClick={handleFileUpload}
                            >
                                Select Excel File
                            </Button>
                        </label>
                        <Link href="https://storage.googleapis.com/uucargo-resource/samples/packing-list-sample.xlsx">
                            Download Sample
                        </Link>
                    </Box>
                    {uploadStatus && <Box className={classes.FileUpload}>
                        <Box className={classes.BoxRow}>
                            <InsertDriveFileIcon/>
                            <Typography>
                                {fileName}
                            </Typography>
                        </Box>
                    </Box>
                    }
                </Box>
                <Typography variant="h4" sx={{textAlign: 'center'}}>
                    My Packing List
                </Typography>
                <Box className={classes.PackingListTitle}>
                    <Grid container spacing={2}>
                        <Grid item xs={5}>
                            <Typography sx={{textAlign: 'center'}}>
                                Packing Name
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography sx={{textAlign: 'center'}}>
                                Date
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography sx={{textAlign: 'center'}}>
                                Download
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography sx={{textAlign: 'center'}}>
                                Delete
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                {
                    packingList?.map((item, index) => {
                        return <Box className={index % 2 === 0 ? classes.PackingListWhite : classes.PackingListDark}
                                    key={index}>
                            <Grid container spacing={2}>
                                <Grid item xs={5}>
                                    <Box className={classes.ListItem}>
                                        <Typography sx={{textAlign: 'center'}}>
                                            {item.packinglist_name}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={3}>
                                    <Box>
                                        <Typography sx={{textAlign: 'center'}}>
                                            {item.updated_at}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={2}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}>
                                        <Link href={`${domain}${item?.packinglist_file_uri}`}>
                                            <IconButton>
                                                <DownloadIcon/>
                                            </IconButton>
                                        </Link>
                                    </Box>
                                </Grid>
                                <Grid item xs={2}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}>
                                        <IconButton
                                            onClick={() => deletePackingListByPackingListIdAndPartnerId(item?.packinglist_id)}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    })
                }
            </Box>
        </Container>
    )
}