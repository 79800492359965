import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  packageData: [],
  isFetching: false,
  selected_package_number:''
}

const packagesSlice = createSlice({
  name: 'packages',
  initialState,
  reducers: {
    setPackages: (state, { payload }) => {
      state.packageData = payload.packages;
    },

    addpackages: (state, { payload }) => {
      state.packageData = [...state.packageData, payload.packages];
    },
  
    selectPackage: (state, { payload }) => {
        state.selected_package_number = payload.selected_package_number;    
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = packagesSlice;

export const {
  setPackages,
  selectPackage,
} = actions;

export const packagesReducer = reducer;

// Export selector