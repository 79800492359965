import React, { useEffect } from "react";

import { Tooltip, IconButton } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

let existFlag;

const TooltipWrapper = ({ title, children }) => {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    existFlag = true;
    return function cleanup() {
      existFlag = false;
    };
  }, []);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleClick = () => {
    setOpen(true);
    setTimeout(() => {
      if (existFlag) {
        setOpen(false);
      }
    }, [1800]);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableTouchListener
          title={title}
        >
          <IconButton
            onClick={handleClick}
            onMouseOver={handleTooltipOpen}
            style={{ backgroundColor: "transparent" }}
          >
            {children}
          </IconButton>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

export default TooltipWrapper;
