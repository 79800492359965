import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {Divider, Link} from "@mui/material";
import Box from "@mui/material/Box";
import {mapCarrierLogo} from "../../Utils/Helper";

export const BusinessLandingAdjustOrderListEach = ({orderInfo}) => {

    console.log('[BusinessLandingAdjustOrderListEach] orderInfo', orderInfo);

    return (
        <Grid container spacing={1}>
            <Grid item xs={4}>
                <Link
                    href={`/business/adjustOrderDashboard/${orderInfo?.order_id}`}
                    underline="hover"
                    sx={{
                        color: '#1D8B45',
                        '&:hover': {
                            textDecoration: 'underline',
                            textDecorationColor: '#1D8B45', // Underline color on hover
                        },
                    }}
                >
                    <Typography sx={{
                        fontSize: '16px',
                        color: '#1D8B45',
                        textAlign: 'center'
                    }}>
                        {orderInfo?.order_id}
                    </Typography>
                </Link>
            </Grid>
            <Grid item xs={4}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    justifyContent: 'center',
                    width: '100%'
                }}>
                    <Typography style={{
                        fontSize: '10px',
                        textAlign: 'center',
                        color: '#FFFFFF',
                        borderRadius: '5px',
                        padding: '5px',
                        width: '80%',
                        backgroundColor: (!orderInfo?.invoiceInfo?.invoiceType) ? '#B2B2B2' : (orderInfo?.invoiceInfo?.invoiceType) === 'Unpaid' ? '#F2BE22' : (orderInfo?.invoiceInfo?.invoiceType) === "Paid" ? '#1D8B45' : "#384559"
                    }}>
                        {
                            orderInfo?.invoiceInfo?.invoiceType || 'Not Billed'
                        }
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={4}>
                <Typography sx={{fontSize: '14px', textAlign: 'center'}}>
                    $ {orderInfo?.final_total?.toFixed(2)}
                </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
                <Divider/>
            </Grid>
        </Grid>
    )

}