import {getAccessToken} from "../../../Utils/doToken";
import {useEffect, useState} from "react";
import {NODE_ROUTE_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import LoadingButton from "@mui/lab/LoadingButton";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar} from "@mui/material";
import * as React from "react";
import {useHistory} from "react-router-dom";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
    PackageOrderDashboardTransactionRoot: {
        borderTop: '1px solid #B2B2B2',
        borderBottom: '1px solid #B2B2B2',
        padding: '15px'
    }
}

export const PackageOrderDashboardTransaction = ({orderId, orderInfo, cbHandleSelectedTransactionData}) => {

    const history = useHistory();

    const accessToken = getAccessToken("access_token");
    const [transactions, setTransactions] = useState();
    const [selectedTranId, setSelectedTranId] = useState(null);
    const [cancelOrderDialogIsOpen, setCancelOrderDialogIsOpen] = useState(false);
    const [isLoadingCancelOrder, setIsLoadingCancelOrder] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleCancelOrder = () => {
        setCancelOrderDialogIsOpen(!cancelOrderDialogIsOpen);
    };

    const handleCancelOrderClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            setCancelOrderDialogIsOpen(!cancelOrderDialogIsOpen);
        }
    }

    const refundByOrderId = async () => {
        setIsLoadingCancelOrder(true);
        let requestURL = `${NODE_ROUTE_URI}/looseItem/refundByOrderId`;
        console.log('order info', orderInfo);
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: {
                    orderId: orderInfo?.uuc_order_id
                }
            })
            console.log(result.data)
            setErrorMessage('');
            setSuccessMessage('Successful to cancel the order. Redirect to home page');
            handleSuccessMessage();
            setIsLoadingCancelOrder(false);
            setTimeout(() => {
                history.push(`/`);
            }, 2000);
        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail to cancel the order');
            handleErrorMessage();
            setIsLoadingCancelOrder(false);
        }
    }

    const getTransactionsListByOrderId = async () => {
        let requestURL = `${NODE_ROUTE_URI}/shippingOrderDetailsDashboard/endUser/getTransactionsByOrderId`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                params: {
                    orderId: orderId
                }
            })
            setTransactions(result?.data?.result);
            setSelectedTranId(result?.data?.result[0]?.tran_id);
            cbHandleSelectedTransactionData(result?.data?.result[0]);
        } catch (e) {
            console.log(e.response)
        }
    }

    const handleSelectTransaction = (transactionData) => {
        setSelectedTranId(transactionData?.tran_id);
        cbHandleSelectedTransactionData(transactionData);
    }

    useEffect(() => {
        getTransactionsListByOrderId();
    }, [orderId])

    console.log('transactions', transactions);

    return (
        <Box sx={style.PackageOrderDashboardTransactionRoot}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Dialog
                open={cancelOrderDialogIsOpen}
                onClose={handleCancelOrderClose}
            >
                <DialogTitle>Refund Request Confirmation</DialogTitle>
                <DialogContent>
                    Are you sure you want to request a refund for your order? This action cannot be undone.
                    Please confirm if you'd like to proceed with the refund request.
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCancelOrder}
                        variant="contained"
                        autoFocus
                    >
                        CLOSE
                    </Button>
                    <LoadingButton
                        onClick={refundByOrderId}
                        variant="contained"
                        autoFocus
                        loading={isLoadingCancelOrder}
                    >
                        CONFIRM
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Grid container spacing={2}>
                <Grid item md={5} xs={12}>
                    <Typography style={{textAlign: 'center'}}>
                        Transaction ID
                    </Typography>
                </Grid>
                <Grid item md={4} xs={4}>
                    <Typography>
                        Total Charge Include Tax
                    </Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                </Grid>
                <Grid item md={5} xs={12}>
                    <Box sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '10px',
                        alignItems: 'center',
                        height: '100%'
                    }}>
                        {
                            transactions?.length > 0 && transactions?.map((transaction, index) => <Box
                                key={index}>
                                <FormControlLabel
                                    value="start"
                                    control={<Checkbox
                                        checked={transaction?.tran_id === selectedTranId}
                                        onChange={() => handleSelectTransaction(transaction)}
                                        // disabled={confirm}
                                        icon={<RadioButtonUncheckedIcon/>}
                                        checkedIcon={<CheckCircleIcon/>}
                                        color="success"
                                    />}
                                    label={
                                        <Typography>
                                            {transaction?.tran_id}
                                        </Typography>
                                    }
                                    labelPlacement="end"
                                />
                            </Box>)
                        }
                    </Box>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                        <Typography>
                            $ {orderInfo?.total_amount_include_tax?.toFixed(2)} {orderInfo?.cross_border_shipment_type && 'CAD'}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={3} xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        <Button
                            variant='contained'
                            sx={{
                                backgroundColor: '#454545',
                                "&:hover": {
                                    backgroundColor: '#454545',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                            // onClick={refundByOrderId}
                            onClick={handleCancelOrder}
                            // color='error'
                        >
                            <Typography style={{textTransform: 'none'}}>
                                Refund
                            </Typography>
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}
