import Box from "@mui/material/Box";
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {getAccessToken} from "../../Utils/doToken";
import axios from "axios";
import {useEffect, useState} from "react";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DownloadIcon from '@mui/icons-material/Download';
import {getLogo} from "../../Utils/Helper";
import dayjs from "dayjs";
import {LatestShippingOrderTableRow} from "./LatestShippingOrderTableRow";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {landingPageLatestOrderShowDialog} from "../../actions/landingPageAction";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import intl from "react-intl-universal";

const tableRow = [
    'Order # / Ref No.',
    'Total Charge inc. tax',
    'Create Date',
    'Transaction Files',
    'Tracking# Files',
    'Labels'
]

const tableRowMobile = [
    'Order # / Ref No.',
    'Total Charge inc. tax',
    'Create Date',
    'Action'
]

const styles = {
    tableHeadText: {
        fontSize: {
            fontSize: '18px',
            fontWeight: '600',
            padding: '0 5px'
        },
        '@media (max-width: 480px)': {
            fontSize: '10px'
        },
    },
    tableCell: {
        padding: '5px 0',
        border: '2px solid #F0F0F0'
    },
    tableRowDark: {
        backgroundColor: '#B2B2B2',
    },
    EmptyCard: {
        backgroundColor: '#FFFFFF',
        width: '100%',
        height: '120px',
        borderRadius: '5px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    EmptyCardText: {
        fontSize: '20px',
    },
    LatestShippingOrderRootShowMore: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    LatestShippingOrderRootShowMoreText: {
        color: '#454545'
    },
    DialogHeading: {
        fontSize: '25px',
        fontWeight: '600'
    }
}

export const LatestShippingOrderTable = ({order}) => {

    const isMobile = useMediaQuery('(max-width: 480px)');

    return (
        <Grid container spacing={1}>
            <Grid item md={2.5} xs={4}>
                <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                    {intl.get('HOME_PAGE.SHIPPING_ORDER.ORDER')}
                </Typography>
            </Grid>
            {!isMobile &&
                <Grid item md={2}>
                    <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'left'}}>
                        {intl.get('HOME_PAGE.SHIPPING_ORDER.SENDER')}
                    </Typography>
                </Grid>
            }
            {!isMobile &&
                <Grid item md={2}>
                    <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'left'}}>
                        {intl.get('HOME_PAGE.SHIPPING_ORDER.RECIPIENT')}
                    </Typography>
                </Grid>
            }
            <Grid item md={1} xs={3}>
                <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                    {intl.get('HOME_PAGE.SHIPPING_ORDER.STATUS')}
                </Typography>
            </Grid>
            {!isMobile &&
                <Grid item md={1} xs={3}>
                    <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                        {intl.get('HOME_PAGE.SHIPPING_ORDER.CARRIER')}
                    </Typography>
                </Grid>
            }
            {!isMobile &&
                <Grid item md={1} xs={3}>
                    <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                        {intl.get('HOME_PAGE.SHIPPING_ORDER.FREIGHT')}
                    </Typography>
                </Grid>
            }
            <Grid item md={1.5} xs={3}>
                <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                    {intl.get('HOME_PAGE.SHIPPING_ORDER.CHARGE')}
                </Typography>
            </Grid>
            <Grid item md={1} xs={2}>
                <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                    {intl.get('HOME_PAGE.SHIPPING_ORDER.ACTION.ACTION')}
                </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
                <Divider/>
            </Grid>
            {
                order?.map((orderEach, index) =>
                    <Grid item xs={12} key={index}>
                        <LatestShippingOrderTableRow order={orderEach} index={index}/>
                    </Grid>
                )
            }
        </Grid>
    )
}
