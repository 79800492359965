import { STACK_SHIPMENTS, FETCHING_SHIPMENTS, SELECT_SHIPMENT } from '../actions/types'

const initialState = {
  data: [],
  fetching: false,
  selected_shipment_id:''
}

export default function shipmentReducer(state = initialState, action){
  switch(action.type){
    case STACK_SHIPMENTS:
      return {
        data:[...state.data, ...action.payload]
      }
    case FETCHING_SHIPMENTS:
      return {
        ...state,
        fetching: action.fetching
      }
    case SELECT_SHIPMENT:
      return {
        ...state,
        selected_shipment_id:action.payload.selected_shipment_id
      }
    default:
      {
        return state
      }
  }
}