import Grid from "@material-ui/core/Grid";
import Box from "@mui/material/Box";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Typography from "@mui/material/Typography";
import {Checkbox, useMediaQuery} from "@mui/material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {CRM_INVOICE_TYPE_PAID, CRM_INVOICE_TYPE_UNPAID} from "../../../Utils/config";
import {green, grey, red} from "../../../Utils/styleConfig";

const styles = {
    BillingInvoicesListEachTextLeft: {
        fontSize: '14px',
        textAlign: 'left'
    },
    BillingInvoicesListTextCenter: {
        fontSize: '14px',
        textAlign: 'center'
    },
    BillingInvoicesListTextRight: {
        fontSize: '14px',
        textAlign: 'right'
    }
}

export const BusinessBillingInvoicesListEach = ({invoice, index, cbSelectInvoice, selectInvoice}) => {

    const isMobile = useMediaQuery('(max-width: 600px)');

    const handleChange = (invoice) => {
        cbSelectInvoice(invoice);
    }

    console.log('select invoice', selectInvoice);

    return (
        <Box sx={{backgroundColor: index % 2 === 1 && '#E5E5E5'}}>
            <Grid container spacing={1}>
                <Grid item md={4} xs={5}>
                    <Box sx={{
                        display: 'flex',
                        gap: '5px',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        height: '100%',
                    }}>
                        <Checkbox
                            checked={selectInvoice?.some(invoiceEach => invoiceEach?.id === invoice?.id)}
                            icon={<CheckBoxOutlineBlankIcon sx={{width: '20px'}}/>}
                            checkedIcon={<CheckCircleIcon sx={{width: '20px'}}/>}
                            color="success"
                            onChange={() => handleChange(invoice)}
                            inputProps={{'aria-label': 'controlled'}}
                        />
                        <Typography style={styles.BillingInvoicesListEachTextLeft}>
                            {invoice?.invoiceRefNumber}
                        </Typography>
                    </Box>
                </Grid>
                {
                    !isMobile &&
                    <Grid item xs={2}>
                        <Box sx={{
                            display: 'flex',
                            gap: '5px',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            height: '100%'
                        }}>
                            <Typography style={styles.BillingInvoicesListEachTextLeft}>
                                {invoice?.invoiceDate}
                            </Typography>
                        </Box>
                    </Grid>
                }
                <Grid item md={4} xs={4}>
                    <Box sx={{
                        display: 'flex',
                        gap: '5px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%',
                    }}>
                        <Typography style={styles.BillingInvoicesListTextCenter}>
                            $ {(+invoice?.totalChargeIncludeTax).toFixed(2)}
                        </Typography>
                        <Typography style={styles.BillingInvoicesListTextCenter}>
                            {((invoice?.invoiceType?.toUpperCase() === CRM_INVOICE_TYPE_UNPAID) && (+invoice?.totalChargeIncludeTax).toFixed(2) !== (+invoice?.remainToPay).toFixed(2) && `/ $ ${(+invoice?.remainToPay).toFixed(2)}`)}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={2} xs={3}>
                    <Box sx={{
                        display: 'flex',
                        gap: '5px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%'
                    }}>
                        <Typography style={{
                            fontSize: '14px',
                            fontWeight: '600',
                            color: invoice?.invoiceType?.toUpperCase() === CRM_INVOICE_TYPE_PAID ? green : invoice?.invoiceType?.toUpperCase() === CRM_INVOICE_TYPE_UNPAID ? red : grey
                        }}>
                            {invoice?.invoiceType}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}