import {SEND_REFERRAL_CODE} from "../actions/types";

const initialState = {
    referralCode: null
}

export const referralPageReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEND_REFERRAL_CODE:
            return {...state, referralCode: action?.payload}
        default:
            return state
    }
}