import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import * as React from "react";
import {getLogo} from "../../Utils/Helper";

const tableRow = [
    'Order#',
    'Total Charge inc. tax',
    'Sender Information',
    'Deliver Information',
    'Service Type',
    'Refund Date',
]

const styles = {
    tableHeadText: {
        fontSize: {
            fontSize: '14px',
            fontWeight: '600',
            padding: '0 5px'
        },
        '@media (max-width: 480px)': {
            fontSize: '10px'
        },
    },
    tableBodyText: {
        fontSize: {
            fontSize: '14px',
            padding: '0 5px'
        },
        '@media (max-width: 480px)': {
            fontSize: '10px'
        },
    },
    tableCell: {
        padding: '16px 0'
    },
    tableButton: {
        padding: 0
    },
    tableImage: {
        height: '15px',
        padding: '0 5px'
    },
}

export const MyOrdersRefunded = ({orderList}) => {
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {tableRow.map((item, index) => {
                            return (
                                <TableCell key={index} sx={styles.tableCell}>
                                    <Typography sx={styles.tableHeadText}>
                                        {item}
                                    </Typography>
                                </TableCell>
                            )
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        orderList?.map((each, index) => {
                            return (
                                <TableRow
                                    key={index}
                                >
                                    <TableCell sx={styles.tableCell}>
                                        <Typography sx={styles.tableBodyText}>
                                            {each.order_number}
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={styles.tableCell}>
                                        <Typography sx={styles.tableBodyText}>
                                            {each?.currency_code} ${each?.final_total}
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={styles.tableCell}>
                                        <Box>
                                            <Typography sx={styles.tableBodyText}>
                                                {each?.sender}
                                            </Typography>
                                            <Typography sx={styles.tableBodyText}>
                                                {each.pickup_address}
                                            </Typography>
                                            <Typography sx={styles.tableBodyText}>
                                                {each?.pickup_area}
                                            </Typography>
                                            <Typography sx={styles.tableBodyText}>
                                                {each?.pickup_postal_code}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell sx={styles.tableCell}>
                                        <Box>
                                            <Typography sx={styles.tableBodyText}>
                                                {each?.deliver_name}
                                            </Typography>
                                            <Typography sx={styles.tableBodyText}>
                                                {each.deliver_address}
                                            </Typography>
                                            <Typography sx={styles.tableBodyText}>
                                                {each?.deliver_area}
                                            </Typography>
                                            <Typography sx={styles.tableBodyText}>
                                                {each?.deliver_postal_code}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell sx={styles.tableCell}>
                                        <img
                                            src={getLogo((each?.account_base_combine_name?.split("-")[0]).toLowerCase())}
                                            alt="logo"
                                            style={styles.tableImage}/>
                                        <Typography sx={styles.tableBodyText}>
                                            - {each.ship_service_type}
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={styles.tableCell}>
                                        <Typography sx={styles.tableBodyText}>
                                            {dayjs(each?.cancelled_at).format("YYYY-MM-DD")}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}