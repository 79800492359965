import {Container} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@material-ui/core/Grid";
import {BusinessBillingInvoices} from "./BusinessBillingInvoices";
import {BusinessBillingSummary} from "./BusinessBillingSummary";
import {getAccessToken} from "../../../Utils/doToken";
import axios from "axios";
import {GET_PARTNER_INFO_API, PARTNER_URI} from "../../../Utils/apiUrl";
import {useEffect, useState} from "react";
import {BusinessBillingAddress} from "./BusinessBillingAddress";
import {BusinessBillingCreditCard} from "./BusinessBillingCreditCard";
import intl from "react-intl-universal";
import {BackButton} from "../../../Utils/CustomComponent";

const styles = {
    BillingRoot: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
    },
    BillingHeading: {
        fontSize: '25px',
        fontWeight: '600',
        textAlign: 'left',
        marginTop: '10px'
    }
}

export const BusinessBilling = () => {

    const [partnerInfo, setPartnerInfo] = useState(null);

    const storedToken = getAccessToken("access_token");

    const getPartnerInfoByPartner = async () => {

        let requestURL = `${PARTNER_URI}/billingCenter/accountDetail`;

        try {
            const result = await axios({
                method: 'get',
                // url: GET_PARTNER_INFO_API,
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                }
            });
            console.log('result', result);
            setPartnerInfo(result?.data);

        } catch (e) {
            console.log(e);
            return false;
        }
    }

    useEffect(() => {
        getPartnerInfoByPartner();
    }, [])

    return (
        <Container maxWidth='xl'>
            <Box sx={styles.BillingRoot}>
                <Typography style={styles.BillingHeading}>
                    <BackButton/>{intl.get('BILLING_CENTER.BILLING_INVOICES')}
                </Typography>
                <Grid container spacing={2}>
                    <Grid item md={7} xs={12}>
                        <BusinessBillingInvoices/>
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <BusinessBillingSummary partnerInfo={partnerInfo}/>
                            </Grid>
                            <Grid item xs={12}>
                                <BusinessBillingAddress partnerInfo={partnerInfo}/>
                            </Grid>
                            <Grid item xs={12}>
                                <BusinessBillingCreditCard/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}