exports.CHECKPAY_WECHAT_PAY = "CHECKPAY-WECHATPAY";

exports.CHECKPAY_ALIPAY = "CHECKPAY-ALIPAY";

exports.ALPHAPAY_WECHATPAY = "ALPHAPAY-WECHATPAY";

exports.ALPHAPAY_ALIPAY = "ALPHAPAY-ALIPAY";

exports.PAGE_SIZE = 20;

exports.TIME_OUT = 60000;

exports.MOVING_SHIPPING_TYPE_PIECE = "piece";

exports.MOVING_ITEM_ROOM_PLACES = [
    {
        roomPlace: "Bedroom",
        iconURI: "",
        parentCategories: [
            {
                cateId: 1,
                cateName: "Bed",
                cateNameCN: "床",
                subCategories: [
                    {
                        cateId: 2,
                        cateName: "Single Bed",
                        cateNameCN: "单人",
                        length: 10,
                        width: 10,
                        height: 10,
                        weight: 10,
                        dimensionUnit: "in",
                        weightUnit: "lb"
                    },
                    {},
                    {}
                ]
            },
            {
                cateId: 3,
                cateName: "Mattress",
                cateNameCN: "床垫",
                subCategories: [
                    {}, {}, {}
                ]
            },
        ],
        // cateName: "Bed",
        // cateNameCN: "床",
        // subCategories: [
        //   {
        //
        //   },
        //   {
        //
        //   },
        //   {
        //
        //   }
        //
        // ]
    },
    {
        roomPlace: "Kitchen",
        cateId: 2,
        cateName: "Bed",
        cateNameCN: "床",
        subCategories: [
            {},
            {},
            {}

        ]
    }
]

exports.HS_CODE_LOOKUP_URL = 'https://www.canadapost-postescanada.ca/information/app/wtz/business/findHsCode?execution=e1s1';