import React, { useState } from "react";
// import { useDispatch } from "react-redux";
import {
  // TextField,
  IconButton,
  Button,
  Box,
  Collapse,
} from "@material-ui/core";
// import { Paper } from '@material-ui/core'
// import orange from '@material-ui/core/colors/orange';
import { Cancel, ExpandLess } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { shipmentTypesDetails } from "../../../../shared/constInfo";
// import Fade from 'react-reveal';
import PropTypes from "prop-types";
// import { deleteShippingItem } from "../../../slices";
import BolItemInput from "./bolItemInput";

import { ordinal_suffix_of } from "../../../../../Utils/addOrdinalSuffix";

// import nextId from "react-id-generator";

const BolItemEditor = ({ bolItem, updateItemList, index }) => {
  //   const dispatch = useDispatch();

  const [editState, setEditState] = useState(false);

  // const [errorMsg, setErrorMsg] = useState("");
  // const [showError, setShowErrorState] = useState(false);
  const classes = useStyles();

  //get called on the index of the current item selected and deletes it
  const deleteItem = (hscode) => (e) => {
    updateItemList((prev) => {
      return prev.filter((item) => item.hscode !== hscode);
    });
  };

  return (
    <Box className={editState ? classes.mainContainer : ""} mb={2}>
      <Box key={bolItem.hscode} className={classes.itemContent}>
        <Box display="flex" alignItems="center">
          <IconButton onClick={deleteItem(bolItem.packageID)}>
            <Cancel />
          </IconButton>
          <div className={classes.itemTextContent}>
            <Box component="span" fontWeight="700">
              {ordinal_suffix_of(index + 1)} Item:
            </Box>{" "}
            {bolItem.hscodeDescription}{" "}
          </div>
        </Box>
        <Box id="item-content2" className="item-content-subtitle" height="100%">
          {editState ? (
            <IconButton
              // styles={{icon: {color: 'rgb(141, 173, 155)', fontSize: 36}}}
              style={{ fill: "green" }}
              onClick={() => {
                setEditState(false);
              }}
            >
              <ExpandLess />
            </IconButton>
          ) : (
            // <div id={pallet.key}>
            <Button
              color="primary"
              style={{ fontSize: "1.2rem" }}
              onClick={() => {
                setEditState(true);
              }}
            >
              Edit
            </Button>
          )}
        </Box>
      </Box>
      <Collapse in={editState}>
        <Box mt={2}>
          <BolItemInput
            bolItem={bolItem}
            updateItemList={updateItemList}
            edit={true}
            // item={item}
            onCancel={() => {
              setEditState(false);
              // setErrorMsg("");
            }}
            // errorMsg={errorMsg}
            // setErrorMsg={setErrorMsg}
            // setShippingItems={setShippingItems}
            // small
          />
        </Box>
      </Collapse>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  input: {
    color: "grey",
    width: "90%",
  },
  icon: {
    fontSize: "32px !important",
    marginBottom: theme.spacing(1),
  },
  mainContainer: {
    border: "1px solid rgba(0,0,0,0.3)",
    borderRadius: 20,
  },
  subTitle: {
    color: "darkgreen",
    fontWeight: 600,
    fontSize: "1.2rem",
    fontFamily: "Montserrat",
  },
  itemContent: {
    fontFamily: "Montserrat",
    backgroundColor: "white",
    // fontWeight: 600,
    borderRadius: "20px",
    // borderBottomLeftRadius: '20px',
    boxShadow: "0 10px 20px rgba(255, 255, 255, 0.1)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // font-family: "Montserrat";
    // background-color:white;
    // justify-content: center;
    // border-top-right-radius: 20px;
    // border-bottom-right-radius: 20px;
    // box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1)
  },
  itemTextContent: {
    color: "rgb(40, 116, 40)",
    fontWeight: "300",
    fontSize: "1.2rem",
  },
}));

// BolItemEditor.propTypes = {
//   item: PropTypes.object.isRequired,
//   edit: PropTypes.object,
//   onCancel: PropTypes.func,
//   setShippingItems: PropTypes.func.isRequired,
// };

export default BolItemEditor;
