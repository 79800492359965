import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  AppBar,
  Tabs,
  Tab,
  Box,
  useMediaQuery,
  Container,
  Button,
} from "@material-ui/core";
import { Alert, Pagination } from "@material-ui/lab";
import AddIcon from "@material-ui/icons/Add";
// import store from '../../store/index'
import { useHistory, useParams } from "react-router-dom";
// import { useDispatch } from 'react-redux';
import axios from "axios";

import LoadingContainer from "../shared/loadingContainer";
import { BOL_LIST } from "../../Utils/apiUrl";
import { getHeaders, getURLParams, errHandling } from "../../Utils/fetchUtil";

const tabNumber = ["created", "accepted", "rejected"];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2} pt={1}>
          {children}
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const BolListPage = () => {
  const history = useHistory();
  // const dispatch = useDispatch();
  const { tab: defaultTab } = useParams();

  const [page, setPage] = useState(1);

  const [lastPage, setLastPage] = useState(0);

  // const [userType, setuserType] = useState('')
  // const [isLoading, setLoadingState] = useState(true)
  const [errorMsg, setErrorMsg] = useState("");
  const [userBillData, setUserBillData] = useState([]);
  const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const classes = useStyles({ matches });
  const theme = useTheme();
  const value =
    tabNumber.indexOf(defaultTab || "created") === -1
      ? 0
      : tabNumber.indexOf(defaultTab || "created");

  const [pageLoading, setPageLoading] = useState(false);
  const [statusValue, setStatusValue] = useState(0);

  const [tabClick, setTabClick] = useState(false);

  useEffect(() => {
    if (value || value === 0) {
      setPageLoading(true);
      setStatusValue(value);
      const getParam = (tabNum) => {
        // 0 = created
        if (tabNum === 0) return { status: 0 };
        // 1 = accepted
        if (tabNum === 1) return { status: 1 };
        // -1 = rejected
        if (tabNum === 2) return { status: -1 };
      };
      setUserBillData([]);

      axios
        .get(
          getURLParams(BOL_LIST, {
            page: page,
            ...getParam(statusValue),
          }),
          { headers: getHeaders() }
        )
        .then((response) => {
          const currentData = response.data;
          currentData.result && setUserBillData(currentData.result);
          setLastPage(currentData.total_page);
          setPageLoading(false);
          setTabClick(false);
        })
        .catch((err) => {
          console.log(JSON.stringify(err));
          errHandling(err, setErrorMsg);
          setPageLoading(false);
          setTabClick(false);
        });
    }
  }, [page, value]);

  const handleChange = (event, newValue) => {
    if (newValue !== value) {
      setPage(1);
      setTabClick(true);
    }
    // setValue(newValue);
    history.push(`/freight-forwarding/bol-list/${tabNumber[newValue]}`);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const getDate = (date) => {
    return date.split(" ")[0];
  };

  // const handleChangeIndex = (index) => {
  //     setValue(index);
  // };

  const goBolDetailPage = (bolNumber) => {
    const path = `freight-forwarding/bol-details/${bolNumber}`;
    history.push({
      pathname: path,
    });
  };

  const panelContent =
    userBillData.length === 0 ? (
      <Box>
        <br />
        <br />
        There is no bill of lading for this type.
      </Box>
    ) : (
      <>
        {userBillData.map((bill, key) => {
          return (
            <Box
              key={bill.ref_no}
              // borderBottom="1px solid rgba(61, 21, 21, 0.3)"
              pt="1.5vh"
              className={classes.itemWrapper}
            >
              <Box
                className="order-list-text"
                display="flex"
                justifyContent="space-between"
                flexDirection={matches ? "column" : "row"}
              >
                {/* <Box pr={1}><img alt={`order-${order.id}`} /></Box> */}
                <Box
                  display={matches ? "flex" : "block"}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box
                    content="span"
                    fontWeight="700"
                    // className={matches ? classes.textTitle : ""}
                  >
                    REFBOL #:
                  </Box>{" "}
                  {/* {matches ? "" : <br />} */}
                  <Box flexGrow="1">
                    <Button
                      variant="text"
                      onClick={() => goBolDetailPage(bill.ref_no)}
                      className={classes.linkText}
                    >
                      {bill.ref_no}
                    </Button>
                  </Box>
                </Box>
                <Box
                  display={matches ? "flex" : "block"}
                  justifyContent="space-between"
                >
                  <Box
                    content="span"
                    className={matches ? classes.textTitle : ""}
                  >
                    Created At:
                  </Box>{" "}
                  <Box style={{ fontStyle: "italic" }} flexGrow="1">
                    {getDate(bill.created_at)}
                  </Box>
                </Box>
                <Box
                  display={matches ? "flex" : "block"}
                  justifyContent="space-between"
                >
                  <Box
                    content="span"
                    className={matches ? classes.textTitle : ""}
                  >
                    Shipper Name:
                  </Box>{" "}
                  {/* {matches ? "" : <br />} */}
                  <Box style={{ fontStyle: "italic" }} flexGrow="1">
                    {bill.shipper_name}
                  </Box>
                </Box>
              </Box>
              {/* <Box m={1}>
                {bill.packages.map((item, index) => {
                  return (
                    <Box
                      className={classes.pkgWrapper}
                      key={item.package_number}
                      mb="10px"
                    >
                      <Box
                        className={classes.cardContent}
                        display="flex"
                        alignItems="center"
                        role="button"
                        width="100%"
                        onClick={() =>
                          goOrderDetailPage(bill.order_number, index, true)
                        }
                      >
                        <Box
                          height="70px"
                          width="70px"
                          position="relative"
                          ml={1}
                          mr={2}
                        >
                          <img
                            src={item.cover_image}
                            alt={`bill-${bill.id}`}
                            className={classes.pkgImg}
                          />
                        </Box>
                        <Box color="seagreen" textAlign="center" flexGrow="1">
                          {item.item_name}
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Box> */}
              <hr />
            </Box>
          );
        })}
      </>
    );

  const pageSelection = lastPage > 1 && !tabClick && (
    <div className={classes.pagination}>
      <Pagination
        className={classes.pagination}
        count={lastPage}
        page={page}
        variant="outlined"
        shape="rounded"
        onChange={handlePageChange}
      />
    </div>
  );

  return (
    <Container>
      {/* <div className="order-list-container"> */}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        mt={3}
        mb={3}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push("freight-forwarding/hs-code")}
          style={{
            textTransform: "unset",
            fontSize: "1.1rem",
          }}
        >
          <AddIcon />
          &nbsp; Add new &nbsp;
          <b>bill of lading</b>
        </Button>
      </Box>
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            // centered
            // scrollButtons="auto"
          >
            <Tab label="Created" {...a11yProps(0)} />
            <Tab label="Accepted" {...a11yProps(1)} />
            <Tab label="Rejected " {...a11yProps(2)} />
            {/* <Tab label="Canceled by backend" {...a11yProps(3)} /> */}
          </Tabs>
        </AppBar>
        {/* {isLoading ? <LoadingContainer /> : */}
        {/* (<> */}
        <TabPanel
          value={value}
          index={0}
          dir={theme.direction}
          classes={{ root: classes.tabRoot }}
        >
          <Box>
            {pageLoading ? <LoadingContainer /> : panelContent}
            {pageSelection}
          </Box>
        </TabPanel>
        <TabPanel
          value={value}
          index={1}
          dir={theme.direction}
          classes={{ root: classes.tabRoot }}
        >
          <Box>
            {pageLoading ? <LoadingContainer /> : panelContent}
            {pageSelection}
          </Box>
        </TabPanel>
        <TabPanel
          value={value}
          index={2}
          dir={theme.direction}
          classes={{ root: classes.tabRoot }}
        >
          <Box>
            {pageLoading ? <LoadingContainer /> : panelContent}
            {pageSelection}
          </Box>
        </TabPanel>
        {/* </>)} */}
        <Box pt="1vh" width="100%">
          {errorMsg ? <Alert severity="error">{errorMsg}</Alert> : ""}
        </Box>
      </div>
      {/* </div> */}
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.paper,
    // backgroundColor: 'white',
    // border: "2px solid #000",
    // boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 2),
    // width: props => props.matches ? '90vw': '80vw',
    width: "100%",
    // height: '80vh',
    // borderRadius: '20px',
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    // overflow: "scroll",
    // margin: '0 3vw'
  },
  // panel: {
  //   backgroundColor: "white",
  // },
  tabRoot: {
    // height: '100%',
    overflow: "none",
    height: "65vh",
  },
  // headerRoot: {
  //     marginTop: '0',
  // },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
    display: "flex",
    justifyContent: "center",
  },
  linkText: {
    fontSize: "1rem !important",
    color: "blue",
  },
  pkgImg: {
    margin: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  pkgWrapper: {
    backgroundColor: "#fdf5e8",
    padding: "3px",
    cursor: "pointer",
    "&:hover": {
      // border: '1px solid rgba(0,0,0,0.3)',
      backgroundColor: "rgba(0,0,0,0.1)",
      borderRadius: "5px",
    },
  },
  textTitle: {
    flexBasis: "40%",
    textAlign: "left",
  },
  activeWrapper: {
    backgroundColor: "grey",
    color: "white",
    borderRadius: 5,
    padding: theme.spacing(0.5),
    height: "40px",
  },
  cardContent: {
    backgroundColor: "#fbfbf8",
    border: "1px solid #ded47c",
    borderRadius: "5px",
  },
  itemWrapper: {
    "& hr": {
      // border: '1px solid rgba(0,0,0,0.3)',
      marginLeft: -theme.spacing(2),
      marginRight: -theme.spacing(2),
      borderTop: "3px solid rgba(0,0,0,.1)",
    },
  },
}));

// const mapStateToProps = state => ({
//     orders: state.orders,
//     packages: state.packages
// })

// export default connect(mapStateToProps, {getOrders, getPackages, getOrderShipments})(OrderList)
export default BolListPage;
