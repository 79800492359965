import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Container,
    Divider,
    Snackbar,
    useMediaQuery
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {Fragment, useEffect, useState} from "react";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";
import Box from "@mui/material/Box";
import {mapCarrierLogo} from "../../../Utils/Helper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import {useHistory} from "react-router-dom";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {deleteToken, getAccessToken} from "../../../Utils/doToken";
import {
    clearShippingPackageCreateLabelPackageList,
    clearShippingPackageCrossBorderItemList,
    clearShippingPackageQuickQuotePackageList,
    updateShipFromPackageCreateLabelPackage,
    updateShippingPackageInternationalShipmentShipFrom,
    updateShippingPackageInternationalShipmentShipTo,
    updateShipToPackageCreateLabelPackage
} from "../../../actions/BusinessShippingPackageAction";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingPackageCreateLabelOrderDetailsRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelRateTitle: {
        fontSize: '24px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsSubHeading: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsTitle: {
        fontSize: '16px',
        textAlign: 'center'
    },
    ShippingPackageCreateLabelOrderDetailsTextLeft: {
        fontSize: '16px',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsTextRight: {
        fontSize: '16px',
        textAlign: 'right'
    },
    ShippingPackageCreateLabelOrderDetailsTitleRight: {
        fontSize: '18px',
        textAlign: 'right',
        fontWeight: '600'
    },
    ShippingPackageCreateLabelOrderDetailsSection: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #A9A9A9',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelOrderDetailsSectionPrice: {
        // backgroundColor: '#FFFFFF',
        padding: '20px',
        // borderRadius: '10px 10px 0 0',
        // border: '2px solid #609966',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelOrderDetailsOtherBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsOtherBoxRight: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsOtherBoxCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsImageBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingLeft: '20px',
        gap: '10px'
    },
}

export const BusinessReturnLabelOrderDetails = (props) => {

    const userInfo = useSelector((state) => state.user);
    const isMobile = useMediaQuery('(max-width: 600px)');
    const history = useHistory();
    const token = getAccessToken("access_token");
    const dispatch = useDispatch();

    const orderData = props?.location?.state?.orderData;
    const selectedRate = props?.location?.state?.selectedRate;
    const crossBorderFee = props?.location?.state?.crossBorderFee;
    const tranId = props?.location?.state?.tranId;

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const [loadingPlaceOrder, setLoadingPlaceOrder] = useState(false);

    const createReturnShipment = async () => {
        setLoadingPlaceOrder(true);
        let requestURL = `${PARTNER_URI}/loose-item/3rd-party/partner/shipping`;

        let packageData = orderData?.packageData.map(element => {
            const updatedElement = {...element};
            updatedElement.packageSpecialServices = {
                ...updatedElement.packageSpecialServices,
                batteryDetails: {
                    material: null,
                    packing: null
                },
                declaredValue: {
                    value: 0,
                    currency: 'CAD'
                },
                signatureRequired: null
            };
            return updatedElement;
        });

        let packingList = [];

        if (orderData?.packingList && orderData.packingList.length !== 0) {
            packingList = orderData.packingList.map(element => {
                const updatedElement = {
                    description: element?.item_desc,
                    sku: element?.item_sku,
                    quantity: element?.quantity,
                    priceEach: element?.unit_price,
                    weight: element?.weight,
                    currency: element?.currency_code,
                    weightUnit: element?.weight_unit,
                    originCountryCode: element?.origi_country,
                    UOM: element?.uom,
                    hscode: element?.hs_code
                }
                return updatedElement;
            });
        }

        let data = {
            account_base_combine_name: selectedRate?.accountBaseCombineName,
            service_type: selectedRate?.serviceType,
            user_email: userInfo?.email,
            pickup_address: orderData?.shipFromAddress,
            pickup_area: orderData?.shipFromCity,
            deliver_address: orderData?.shipToAddress,
            deliver_area: orderData?.shipToCity,
            cargo_control_no: "",
            ref_order_no: orderData?.refOrderNumber,
            deliver_address_two: orderData?.shipToAddressTwo,
            schedule_pickup: "2021-09-27 09:29",
            pickup_address_obj: {
                address: orderData?.shipFromAddress,
                addressLineTwo: orderData?.shipFromAddressTwo,
                city: orderData?.shipFromCity,
                postal_code: orderData?.shipFromPostalCode,
                province: orderData?.shipFromProvince,
                companyName: orderData?.shipFromCompanyName,
                country: orderData?.shipFromCountry,
                name: orderData?.shipFromName,
                email: orderData?.shipFromEmail
            },
            pickup_phone: orderData?.shipFromPhone,
            pickup_email: orderData?.shipFromEmail,
            deliver_address_obj: {
                address: orderData?.shipToAddress,
                addressLineTwo: orderData?.shipToAddressTwo,
                city: orderData?.shipToCity,
                postal_code: orderData?.shipToPostalCode,
                province: orderData?.shipToProvince,
                companyName: orderData?.shipToCompanyName,
                country: orderData?.shipToCountry,
                name: orderData?.shipToName,
                email: orderData?.shipToEmail
            },
            delivery_phone: orderData?.shipToPhone,
            deliver_email: orderData?.shipToEmail,
            shipmentType: orderData?.shipmentType,
            transMode: orderData?.transMode,
            shipmentSpecialServices: {
                returnShipment: true // true | false
            },
            package_data: packageData,
            packingList: packingList
        }
        
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            })
            console.log('result', result.data);
            if (result?.data?.shippingResData[0]?.tranStatus === 1) {
                setErrorMessage('');
                handleSuccessMessage();
                setSuccessMessage('Successfully place order.');
                setTimeout(() => {
                    history.push(`/package-order-dashboard/${result?.data?.shippingOrderData?.orderId}`);

                }, 2000);
            } else {
                handleErrorMessage();
                setErrorMessage(`Fail to place order.${result?.data?.shippingResData[0]?.tranMessage}`);
            }
        } catch (e) {
            if (e.response.status === 440) {
                setErrorMessage('The authentication token has expired.');
                deleteToken("access_token");
                setTimeout(() => window.location.reload(), 2000);
            } else {
                setErrorMessage(`Fail to place order. ${e.response?.data?.message || 'Please try again later.'}`);
            }
            handleErrorMessage();
        } finally {
            setLoadingPlaceOrder(false);
        }
    }

    const initBackendOrder = async () => {
        setLoadingPlaceOrder(true);
        let requestURL = `${PARTNER_URI}/loose-item/3rd-party/init3PLOrder`;

        let packageData = orderData?.packageData.map(element => {
            const updatedElement = {...element};
            updatedElement.packageSpecialServices = {
                ...updatedElement.packageSpecialServices,
                batteryDetails: {
                    material: null,
                    packing: null
                },
                declaredValue: {
                    value: 0,
                    currency: 'CAD'
                },
                signatureRequired: null
            };
            return updatedElement;
        });

        let packingList = [];

        if (orderData?.packingList && orderData.packingList.length !== 0) {
            packingList = orderData.packingList.map(element => {
                const updatedElement = {
                    description: element?.item_desc,
                    sku: element?.item_sku,
                    quantity: element?.quantity,
                    priceEach: element?.unit_price,
                    weight: element?.weight,
                    currency: element?.currency_code,
                    weightUnit: element?.weight_unit,
                    originCountryCode: element?.origi_country,
                    UOM: element?.uom,
                    hscode: element?.hs_code
                }
                return updatedElement;
            });
        }

        let data = {
            accountBaseCombinedName: selectedRate?.accountBaseCombineName,
            accountId: selectedRate?.accountId,
            serviceType: selectedRate?.serviceType,
            shipFromName: orderData?.shipFromName,
            shipFromCompanyName: orderData?.shipFromCompanyName,
            shipFromPhone: orderData?.shipFromPhone?.replace(/\D/g, ''),
            shipFromEmail: orderData?.shipFromEmail,
            shipFromAddress: orderData?.shipFromAddress,
            shipFromAddressTwo: orderData?.shipFromAddressTwo,
            shipFromCity: orderData?.shipFromCity,
            shipFromProvince: orderData?.shipFromProvince,
            shipFromCountry: orderData?.shipFromCountry,
            shipFromPostalCode: orderData?.shipFromPostalCode?.replace(/\s+/g, '').toUpperCase(),
            shipToName: orderData?.shipToName,
            shipToCompanyName: orderData?.shipToCompanyName,
            shipToPhone: orderData?.shipToPhone?.replace(/\D/g, ''),
            shipToEmail: orderData?.shipToEmail,
            shipToAddress: orderData?.shipToAddress,
            shipToAddressTwo: orderData?.shipToAddressTwo,
            shipToCity: orderData?.shipToCity,
            shipToProvince: orderData?.shipToProvince,
            shipToCountry: orderData?.shipToCountry,
            shipToPostalCode: orderData?.shipToPostalCode.replace(/\s+/g, '').toUpperCase(),
            refOrderNumber: orderData?.refOrderNumber,
            shipmentType: orderData?.shipmentType,
            transMode: orderData?.transMode,
            shipmentSpecialServices: {
                returnShipment: true // true | false
            },
            packageData: packageData,
            packingList: packingList
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            })
            console.log('result', result.data);
            setErrorMessage('');
            dispatch(updateShipFromPackageCreateLabelPackage({
                postalCode: null,
                province: null,
                city: null,
                address: null,
                address2: null,
                country: null,
                displayAddress: {
                    description: null,
                    placeId: null,
                    text: null
                },
                name: null,
                phone: null,
                email: null,
                company: null,
                saveAddress: false,
            }))
            dispatch(updateShipToPackageCreateLabelPackage({
                postalCode: null,
                province: null,
                city: null,
                address: null,
                address2: null,
                country: null,
                displayAddress: {
                    description: null,
                    placeId: null,
                    text: null
                },
                name: null,
                phone: null,
                email: null,
                company: null,
                saveAddress: false,
            }))
            dispatch(clearShippingPackageCreateLabelPackageList());
            dispatch(clearShippingPackageQuickQuotePackageList());
            dispatch(clearShippingPackageCrossBorderItemList());
            dispatch(updateShippingPackageInternationalShipmentShipFrom(null));
            dispatch(updateShippingPackageInternationalShipmentShipTo(null));
            setTimeout(() => {
                history.push(`/business/shippingOrderDetails/${result?.data?.id}`);
            }, 2000);
        } catch (e) {
            if (e.response.status === 440) {
                setErrorMessage('The authentication token has expired.');
                deleteToken("access_token");
                setTimeout(() => window.location.reload(), 2000);
            } else {
                setErrorMessage(`Fail to place order. ${e.response?.data?.message || 'Please try again later.'}`);
            }
            handleErrorMessage();
        } finally {
            setLoadingPlaceOrder(false);
        }
    }

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleBack = () => {
        history.push(`/packageOrder/returnLabel/${tranId}`);
    }

    const handlePlaceOrder = () => {
        // createReturnShipment();
        initBackendOrder();
    }

    const handleBeforeUnload = (event) => {

        console.log('reload');
        // Customize the confirmation message
        const message = 'Are you sure you want to leave? Your unsaved changes may be lost.';

        // Set the confirmation message for modern browsers
        event.returnValue = message;

        // For older browsers
        return message;
    };

    useEffect(() => {
        // Add the event listener when the component mounts
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []); // Empty dependency array means this effect runs once when the component mounts

    if (!orderData || !selectedRate || !tranId) {
        return (
            <Container maxWidth='xl'>
                <Box sx={styles.ShippingPackageCreateLabelOrderDetailsRoot}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography style={styles.ShippingPackageCreateLabelOrderDetailsHeading}>
                                {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.SHIPPING_ORDER_DETAILS')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{color: 'red', textAlign: 'left', fontSize: '16px'}}>
                                You've lost all the data. Please return to the home page and create a new return
                                shipment.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{display: 'flex', width: '100%', justifyContent: 'flex-end', gap: '20px'}}>
                                <Button
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#F2BE22',
                                        "&:hover": {
                                            backgroundColor: '#F2BE22',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    onClick={() => history.push('/home')}
                                >
                                    <Typography style={{textTransform: 'none'}}>
                                        Back
                                    </Typography>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        )
    }

    console.log('[BusinessReturnLabelOrderDetails] orderData', orderData);
    console.log('[BusinessReturnLabelOrderDetails] selectedRate', selectedRate);
    console.log('[BusinessReturnLabelOrderDetails] crossBorderFee', crossBorderFee);
    console.log('[BusinessReturnLabelOrderDetails] tranId', tranId);

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid container spacing={4}>
                <Grid item md={12} xs={12}>
                    <Typography style={styles.ShippingPackageCreateLabelRateTitle}>
                        Return Label
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={styles.ShippingPackageCreateLabelOrderDetailsRoot}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography style={styles.ShippingPackageCreateLabelOrderDetailsHeading}>
                                    {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.SHIPPING_ORDER_DETAILS')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={styles.ShippingPackageCreateLabelOrderDetailsSection}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography style={styles.ShippingPackageCreateLabelOrderDetailsSubHeading}>
                                                {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.PACKAGE_DETAILS.PACKAGE_DETAILS')}
                                            </Typography>
                                        </Grid>
                                        {
                                            !isMobile &&
                                            <>
                                                <Grid item xs={3}>
                                                    <Typography
                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTitle}>
                                                        {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.PACKAGE_DETAILS.PACKAGE_NAME')}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Typography
                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTitle}>
                                                        {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.PACKAGE_DETAILS.DIMENSION')}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Typography
                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTitle}>
                                                        {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.PACKAGE_DETAILS.QUANTITY')}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Typography
                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTitle}>
                                                        {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.PACKAGE_DETAILS.WEIGHT')}
                                                    </Typography>
                                                </Grid>
                                            </>
                                        }
                                    </Grid>
                                    {
                                        orderData?.packageData?.length > 0 && orderData?.packageData?.map((each, index) => (
                                            <Grid container spacing={1} key={index}>
                                                <Grid item md={3} xs={12}>
                                                    <Typography
                                                        style={isMobile ? styles.ShippingPackageCreateLabelOrderDetailsTextLeft : styles.ShippingPackageCreateLabelOrderDetailsTitle}>
                                                        {isMobile && `${intl.get('SHIPPING_PACKAGE_CREATE_LABEL.PACKAGE_DETAILS.PACKAGE_NAME')}:`} {each?.description}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={3} xs={12}>
                                                    <Typography
                                                        style={isMobile ? styles.ShippingPackageCreateLabelOrderDetailsTextLeft : styles.ShippingPackageCreateLabelOrderDetailsTitle}>
                                                        {isMobile && `${intl.get('SHIPPING_PACKAGE_CREATE_LABEL.PACKAGE_DETAILS.DIMENSION')}:`} {each?.length} x {each?.width} x {each?.height} {each?.lengthUnit}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={3} xs={12}>
                                                    <Typography
                                                        style={isMobile ? styles.ShippingPackageCreateLabelOrderDetailsTextLeft : styles.ShippingPackageCreateLabelOrderDetailsTitle}>
                                                        {isMobile && `${intl.get('SHIPPING_PACKAGE_CREATE_LABEL.PACKAGE_DETAILS.QUANTITY')}:`} {each?.quantity}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={3} xs={12}>
                                                    <Typography
                                                        style={isMobile ? styles.ShippingPackageCreateLabelOrderDetailsTextLeft : styles.ShippingPackageCreateLabelOrderDetailsTitle}>
                                                        {isMobile && `${intl.get('SHIPPING_PACKAGE_CREATE_LABEL.PACKAGE_DETAILS.WEIGHT')}:`} {each?.weight} {each?.weightUnit}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider/>
                                                </Grid>
                                            </Grid>
                                        ))
                                    }
                                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        <Typography style={{textAlign: 'left', fontSize: '12px'}}>
                                    <span
                                        style={{color: 'red'}}>*</span>{intl.get('SHIPPING_PACKAGE_CREATE_LABEL.PACKAGE_DETAILS.MESSAGE')}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Box sx={styles.ShippingPackageCreateLabelOrderDetailsSection}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <Typography style={styles.ShippingPackageCreateLabelOrderDetailsSubHeading}>
                                                {intl.get('SHIPPING_INFORMATION.SHIP_FROM')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                {orderData?.shipFromName}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                {orderData.shipFromAddressTwo && `${orderData?.shipFromAddressTwo} - `}{orderData?.shipFromAddress}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                {orderData?.shipFromCity}, {orderData?.shipFromProvince}, {orderData?.shipFromCountry}, {orderData?.shipFromPostalCode}
                                            </Typography>
                                        </Grid>
                                        {orderData?.shipFromEmail &&
                                            <Grid item xs={12}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                    {orderData?.shipFromEmail}
                                                </Typography>
                                            </Grid>
                                        }
                                        <Grid item xs={12}>
                                            <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                {orderData?.shipFromPhone}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Box sx={styles.ShippingPackageCreateLabelOrderDetailsSection}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <Typography style={styles.ShippingPackageCreateLabelOrderDetailsSubHeading}>
                                                {intl.get('SHIPPING_INFORMATION.SHIP_TO')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                {orderData?.shipToName}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                {orderData.shipToAddressTwo && `${orderData?.shipToAddressTwo} - `}{orderData?.shipToAddress}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                {orderData?.shipToCity}, {orderData?.shipToProvince}, {orderData?.shipToCountry}, {orderData?.shipToPostalCode}
                                            </Typography>
                                        </Grid>
                                        {orderData?.shipToEmail &&
                                            <Grid item xs={12}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                    {orderData?.shipToEmail}
                                                </Typography>
                                            </Grid>
                                        }
                                        <Grid item xs={12}>
                                            <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                {orderData?.shipToPhone}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={styles.ShippingPackageCreateLabelOrderDetailsSection}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography style={styles.ShippingPackageCreateLabelOrderDetailsSubHeading}>
                                                {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.SHIPPING_CARRIER')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box sx={{border: '2px solid #609966', borderRadius: '10px'}}>
                                                <Box sx={styles.ShippingPackageCreateLabelOrderDetailsSectionPrice}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={3}>
                                                            <Box
                                                                sx={styles?.ShippingPackageCreateLabelOrderDetailsImageBox}>
                                                                <img src={mapCarrierLogo(selectedRate?.accountBase)}
                                                                     alt="logo"
                                                                     width='60px'/>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item md={3} xs={12}>
                                                            <Box
                                                                sx={styles.ShippingPackageCreateLabelOrderDetailsOtherBoxCenter}>
                                                                <Typography
                                                                    sx={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                                    {selectedRate?.serviceName}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item md={3} xs={12}>
                                                            <Box
                                                                sx={styles.ShippingPackageCreateLabelOrderDetailsOtherBoxCenter}>
                                                                <Typography
                                                                    sx={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                                    {selectedRate?.shipRange} business days
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item md={3} xs={12}>
                                                            <Box
                                                                sx={styles.ShippingPackageCreateLabelOrderDetailsOtherBoxRight}>
                                                                <Typography
                                                                    sx={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                    $ {selectedRate?.isCrossBoarder ?
                                                                    (+selectedRate?.finalTotalAmount?.value + crossBorderFee?.grandTotal).toFixed(2)
                                                                    :
                                                                    (+selectedRate?.finalTotalAmount?.value).toFixed(2)
                                                                }
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                <Box sx={{borderTop: '2px solid #609966', padding: '0 20px'}}>
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12}>
                                                            <Accordion
                                                                sx={{
                                                                    boxShadow: 'none',
                                                                }}
                                                            >
                                                                <AccordionSummary
                                                                    sx={{
                                                                        flexDirection: 'row-reverse'
                                                                    }}
                                                                    expandIcon={<ExpandMoreIcon/>}
                                                                >
                                                                    < Typography
                                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                                        {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.PRICE_DETAILS')}
                                                                    </Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <Grid container spacing={1}>
                                                                        {
                                                                            selectedRate?.shipmentCharges?.map((each, index) => {
                                                                                const cost = +each?.value
                                                                                if (cost !== 0) {
                                                                                    return (
                                                                                        <Fragment key={index}>
                                                                                            <Grid item xs={6}>
                                                                                                <Typography
                                                                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                                                    {each?.description}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                            <Grid item xs={6}>
                                                                                                <Typography
                                                                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                                                    $ {(+each?.value)?.toFixed(2)}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                        </Fragment>
                                                                                    )
                                                                                }
                                                                            })
                                                                        }
                                                                        {
                                                                            (+selectedRate?.serviceFee?.value) > 0 &&
                                                                            <>
                                                                                <Grid item xs={6}>
                                                                                    <Typography
                                                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                                        Service Fee
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid item xs={6}>
                                                                                    <Typography
                                                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                                        $ {selectedRate?.serviceFee?.value}
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </>
                                                                        }
                                                                        <Grid item xs={12}>
                                                                            <Divider/>
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <Typography
                                                                                style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                                Subtotal
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <Typography
                                                                                style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                                $ {((+selectedRate?.totalCharge?.value) + selectedRate?.serviceFee?.value).toFixed(2)}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <Typography
                                                                                style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                                Tax
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <Typography
                                                                                style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                                $ {((+selectedRate?.serviceFeeTax?.value) + selectedRate?.taxes?.reduce((total, item) => total + (+item?.value), 0))?.toFixed(2)}
                                                                            </Typography>
                                                                        </Grid>
                                                                        {selectedRate?.isCrossBoarder &&
                                                                            <>
                                                                                <Grid item xs={6}>
                                                                                    <Typography
                                                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                                        Total Brokerage Fee
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid item xs={6}>
                                                                                    <Typography
                                                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                                        $ {crossBorderFee?.totalBrokerageFees?.toFixed(2)}
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid item xs={6}>
                                                                                    <Typography
                                                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                                        Total Duty and Tax
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid item xs={6}>
                                                                                    <Typography
                                                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                                        $ {crossBorderFee?.totalDutyandTax?.toFixed(2)}
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </>
                                                                        }
                                                                        <Grid item xs={12}>
                                                                            <Divider/>
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <Typography
                                                                                style={styles.ShippingPackageCreateLabelOrderDetailsTitleRight}>
                                                                                Total
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <Typography
                                                                                style={styles.ShippingPackageCreateLabelOrderDetailsTitleRight}>
                                                                                $ {selectedRate?.isCrossBoarder ?
                                                                                (+selectedRate?.finalTotalAmount?.value + crossBorderFee?.grandTotal).toFixed(2)
                                                                                :
                                                                                (+selectedRate?.finalTotalAmount?.value).toFixed(2)
                                                                            }
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                                    <Button
                                        size='large'
                                        variant='contained'
                                        sx={{
                                            backgroundColor: '#F2BE22',
                                            "&:hover": {
                                                backgroundColor: '#F2BE22',
                                                filter: 'brightness(0.9)'
                                            }
                                        }}
                                        onClick={handleBack}
                                    >
                                        {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.BACK')}
                                    </Button>
                                    <LoadingButton
                                        size='large'
                                        variant='contained'
                                        color='success'
                                        loading={loadingPlaceOrder}
                                        onClick={handlePlaceOrder}
                                    >
                                        {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.PLACE_ORDER')}
                                    </LoadingButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    )
}