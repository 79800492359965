export const pageTitle = {
  fontSize: "18pt",
  color: "darkgreen",
  fontFamily: "Montserrat",
  fontWeight: 600,
};

//label style for infomation, such as: Length, Width, Height
export const general_info_label = {
  fontWeight: "650",
  fontFamily: "Montserrat",
};

//green tag with text eg. the available tag
export const success_tag = {
  backgroundColor: "limegreen",
  borderRadius: "10px",
  padding: "2px 10px",
  color: "white",
  margin: "5px 10px",
  width: "180px",
  whiteSpace: "noWrap",
  textAlign: "center",
  "&:empty": {
    display: "none",
  },
};
