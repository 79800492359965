import Box from "@mui/material/Box";
import {RatingItem} from "./RatingItem";
import {RatingShipping} from "./RatingShipping";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Snackbar
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import MuiAlert from "@mui/material/Alert";
import * as React from 'react';
import {
    checkAllPropertiesHaveValueFalse,
    getCountryCodeByName,
    getMissingKeys,
    isValidAddress
} from "../../Utils/Helper";
import {
    addShippingListFromAddress, addShippingListToAddress,
    clearRates, updateLoadingShipRate,
    updateRates,
    updateShipFromCountryAndProvince,
    updateShipFromError, updateShipTo,
    updateShipToError
} from "../../actions/ratingAction";
import getProvinceCodeByName from "../../Utils/getProvinceCode";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../Utils/doToken";
import {useHistory} from "react-router-dom";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import CloseIcon from '@mui/icons-material/Close';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
    RatingRoot: {
        display: 'flex',
        flexDirection: 'column',
        width: '1000px',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto'
    },
    RatingButtonSection: {
        backgroundColor: '#2F58CD',
        width: '200px',
        height: '60px',
        borderRadius: '15px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: '0.2s ease-out',
        ':hover': {
            opacity: 0.9
        }
    },
    RatingButton: {
        width: '100%',
        height: '100%',
        color: '#FFFFFF',
        fontSize: '16px',
        fontWeight: '600',
    },
    DialogRoot: {
        width: '800px'
    },
    DialogSection: {
        display: 'flex',
        flexDirection: 'column',
        margin: '10px 0'

    },
    DialogContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    },
    DialogBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%'
    },
    DialogHeader: {
        fontSize: '20px',
        textAlign: 'center'
    },
    DialogHeading: {
        fontSize: '25px',
        fontWeight: '600'
    },
    DialogTitle: {
        fontSize: '18px'
    },
    DialogContent: {
        fontSize: '14px'

    },
    DialogClose: {
        position: 'absolute',
        right: '2%',
        top: '2%'
    }
}

export const Rating = () => {

    let itemList = useSelector(state => state?.rating?.itemList);
    let shipFrom = useSelector(state => state?.rating?.shippingFrom);
    let shipTo = useSelector(state => state?.rating?.shippingTo);
    let crossBorder = useSelector(state => state?.rating?.shippingCrossBorder);
    let shipAddressError = useSelector(state => state?.rating?.shippingAddressErrors);
    let shipFromCountryAndProvince = useSelector(state => state?.rating?.shipFromCountryAndProvince);

    let rateData = useSelector(state => state?.rating?.shipRates);

    const history = useHistory();

    console.log('item list', itemList);

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [dialogOpen, setDialogOpen] = useState(false);

    const [recommendShipFrom, setRecommendShipFrom] = useState({});
    const [isRecommendShipFrom, setIsRecommendShipFrom] = useState(false);
    const [recommendShipTo, setRecommendShipTo] = useState({});
    const [isRecommendShipTo, setIsRecommendShipTo] = useState(false);

    const [usingRecommendShipFrom, setUsingRecommendSHipFrom] = useState(false);
    const [usingRecommendShipTo, setUsingRecommendSHipTo] = useState(false);

    const dispatch = useDispatch();

    const handleUsingRecommendShipFrom = (isUsed) => {
        setUsingRecommendSHipFrom(isUsed);
    }

    const handleUsingRecommendShipTo = (isUsed) => {
        setUsingRecommendSHipTo(isUsed);
    }

    const handleDialogClickOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const validation = () => {
        setErrorMessage('');
        setToastOpen(false);
        validateItemList() && setErrorMessage(prevState => prevState + 'Your item list is empty.');
        validateShipFrom() && setErrorMessage(prevState => prevState + ' Shipping from address is missing one or more required fields.');
        validateShipTo() && setErrorMessage(prevState => prevState + ' Shipping to address is missing one or more required fields.');
        !checkAllPropertiesHaveValueFalse(shipAddressError) && setErrorMessage(prevState => prevState + ' The shipping address contains invalid values.');
        !validaShipFromCountryAndProvince() && setErrorMessage(prevState => prevState + ' The shipping from country must be either Canada or United States, and a valid state or province must be provided.');

        if (validateItemList() ||
            validateShipFrom() ||
            validateShipTo() ||
            !checkAllPropertiesHaveValueFalse(shipAddressError) ||
            !validaShipFromCountryAndProvince()
        ) {
            handleErrorMessage();
            return false
        } else {
            return true
        }
    }

    const validateItemList = () => {
        if (itemList.length === 0) {
            return true
        } else {
            return false
        }
    }

    const validateShipFrom = () => {
        const missingKeys = getMissingKeys(shipFrom);
        if (missingKeys.length === 0) {
            dispatch(updateShipFromError(missingKeys))
            return false
        } else {
            dispatch(updateShipFromError(missingKeys))
            return true
        }
    }

    const validateShipTo = () => {
        const missingKeys = getMissingKeys(shipTo);
        if (missingKeys.length === 0) {
            dispatch(updateShipToError(missingKeys))
            return false
        } else {
            dispatch(updateShipToError(missingKeys))
            return true
        }
    }

    const validaShipFromCountryAndProvince = () => {
        // console.log('valid of country and province', (isValidAddress(shipFrom.country, getProvinceCodeByName(shipFrom.province))));
        if (isValidAddress(getCountryCodeByName(shipFrom.country), getProvinceCodeByName(shipFrom.province))) {
            dispatch(updateShipFromCountryAndProvince(true))
        } else {
            dispatch(updateShipFromCountryAndProvince(false))
        }

        return (isValidAddress(getCountryCodeByName(shipFrom.country), getProvinceCodeByName(shipFrom.province)));
    }

    const getRates = async (accountBase) => {
        let requestURL = `${NODE_ROUTE_URI}/looseItem/ratesByAccountBase`;
        const accessToken = getAccessToken("access_token");
        const packageData = itemList?.map(obj => ({
            length: obj?.length,
            height: obj?.height,
            width: obj?.width,
            quantity: obj?.quantity,
            weight: obj?.weight,
            lengthUnit: obj?.dimensionUnit,
            weightUnit: obj?.weightUnit,
            description: obj?.name,
            hscode: obj?.hsCode,
            originCountryCode: obj?.originCountry,
            currency: obj?.currency,
            priceEach: obj?.unitPrice,
            UOM: obj?.uom
        }))

        // console.log('package data', packageData);

        const data = {
            rateData: {
                shipFromAddress: usingRecommendShipFrom ? usingRecommendShipFrom.address : shipFrom?.address,
                shipFromAddressTwo: shipFrom?.address2,
                shipFromCity: usingRecommendShipFrom ? recommendShipFrom?.city : shipFrom?.city,
                shipFromProvince: usingRecommendShipFrom ? recommendShipFrom.province : getProvinceCodeByName(shipFrom?.province),
                shipFromPostalCode: usingRecommendShipFrom ? recommendShipFrom.postalCode : shipFrom?.postalCode.replace(/\s+/g, ''),
                shipFromCountry: usingRecommendShipFrom ? recommendShipFrom.country : getCountryCodeByName(shipFrom?.country),
                shipFromName: shipFrom?.name,
                shipFromPhone: shipFrom?.phone,
                shipToAddress: usingRecommendShipTo ? recommendShipTo?.address : shipTo?.address,
                shipToAddressTwo: shipTo?.address2,
                shipToCity: usingRecommendShipTo ? recommendShipTo?.city : shipTo?.city,
                shipToPostalCode: usingRecommendShipTo ? recommendShipTo?.postalCode : shipTo?.postalCode.replace(/\s+/g, ''),
                shipToProvince: usingRecommendShipTo ? recommendShipTo?.province : getProvinceCodeByName(shipTo?.province),
                shipToCountry: usingRecommendShipTo ? recommendShipTo?.country : getCountryCodeByName(shipTo?.country),
                shipToName: shipTo?.name,
                shipToPhone: shipTo?.phone,
                packageData
            },
            selectedAccountBase: accountBase
        }

        dispatch(updateLoadingShipRate(true));

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: data
            })
            // console.log(accountBase, 'data', result.data)
            dispatch(updateRates({rateData: result.data.result, service: accountBase}));
            dispatch(updateLoadingShipRate(false));
        } catch (e) {
            dispatch(updateLoadingShipRate(false));
            console.log(e.response)
        }
    }

    const validatePickupAddress = async () => {
        let requestURL = `${NODE_ROUTE_URI}/verifyAddress/forWeb`;
        const accessToken = getAccessToken("access_token");
        const data = {
            addressLines: [shipFrom?.address],
            city: shipFrom?.city,
            province: getProvinceCodeByName(shipFrom?.province),
            postalCode: shipFrom?.postalCode,
            country: getCountryCodeByName(shipFrom?.country),
        }
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: data
            })
            const recommendAddress = result.data;
            console.log(recommendAddress);
            if (recommendAddress.customerMessages.length === 0) {
                setIsRecommendShipFrom(true);
                setRecommendShipFrom({
                    address: recommendAddress.streetLinesToken[0],
                    city: recommendAddress.city,
                    province: recommendAddress.stateOrProvinceCode,
                    country: recommendAddress.countryCode,
                    postalCode: recommendAddress.postalCode
                })
            } else {
                setIsRecommendShipFrom(false)
            }
        } catch (e) {
            console.log(e.response);
            setIsRecommendShipFrom(false);
        }
    }

    const validateDeliverAddress = async () => {
        let requestURL = `${NODE_ROUTE_URI}/verifyAddress/forWeb`;
        const accessToken = getAccessToken("access_token");
        const data = {
            addressLines: [shipTo?.address],
            city: shipTo?.city,
            province: getProvinceCodeByName(shipTo?.province),
            postalCode: shipTo?.postalCode,
            country: getCountryCodeByName(shipTo?.country),
        }
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: data
            })
            const recommendAddress = result.data;
            console.log(recommendAddress);
            if (recommendAddress.customerMessages.length === 0) {
                setIsRecommendShipTo(true);
                setRecommendShipTo({
                    address: recommendAddress.streetLinesToken[0],
                    city: recommendAddress.city,
                    province: recommendAddress.stateOrProvinceCode,
                    country: recommendAddress.countryCode,
                    postalCode: recommendAddress.postalCode
                })
            } else {
                setIsRecommendShipTo(false)
            }

        } catch (e) {
            setIsRecommendShipFrom(false);
        }
    }

    const handleOpenDialog = () => {
        handleDialogClickOpen();
        validatePickupAddress();
        validateDeliverAddress();
    }

    const handleGetRate = () => {
        dispatch(clearRates());
        history.push("/rating/estimate");
        dispatch(addShippingListFromAddress(shipFrom));
        dispatch(addShippingListToAddress(shipTo));
        if (getCountryCodeByName(shipFrom?.country) === getCountryCodeByName(shipTo?.country)) {
            getRates('canadapost');
            getRates('canpar');
            getRates('fedex');
            getRates('ups');
        } else {
            getRates('ups');
        }
    }

    const handleClickGetShippingRate = () => {
        if (validation()) {
            handleOpenDialog()
        }
    }

    return (
        <Box sx={style.RatingRoot}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Dialog
                open={dialogOpen && (isRecommendShipFrom || isRecommendShipTo)}
                onClose={handleDialogClose}
                fullWidth={true}
            >
                <IconButton
                    sx={style.DialogClose}
                    onClick={handleDialogClose}
                >
                    <CloseIcon/>
                </IconButton>
                <DialogTitle>
                    <Typography sx={style.DialogHeading}>
                        Shipping Address
                    </Typography>
                </DialogTitle>
                {
                    isRecommendShipFrom &&
                    <Box sx={style.DialogSection}>
                        <Typography sx={style.DialogHeader}>
                            Ship From
                        </Typography>
                        <Box sx={style.DialogContainer}>
                            <Box sx={style.DialogBox}>
                                <DialogContent>
                                    <Typography sx={style.DialogTitle}>
                                        Provided Address
                                    </Typography>
                                    <Typography>
                                        {shipFrom?.address}
                                    </Typography>
                                    <Typography>
                                        {shipFrom?.city}, {shipFrom?.province}
                                    </Typography>
                                    <Typography>
                                        {shipFrom?.country}, {shipFrom?.postalCode}
                                    </Typography>
                                </DialogContent>
                                {usingRecommendShipFrom &&
                                    <DialogActions sx={{justifyContent: 'flex-start'}}>
                                        <Button onClick={() => handleUsingRecommendShipFrom(false)}>
                                            Use this address
                                        </Button>
                                    </DialogActions>}
                            </Box>
                            <Typography>
                                OR
                            </Typography>
                            <Box sx={style.DialogBox}>
                                <DialogContent>
                                    <Typography sx={style.DialogTitle}>
                                        Recommend Address
                                    </Typography>
                                    <Typography>
                                        {recommendShipFrom?.address}
                                    </Typography>
                                    <Typography>
                                        {recommendShipFrom?.city}, {recommendShipFrom?.province}
                                    </Typography>
                                    <Typography>
                                        {recommendShipFrom?.country}, {recommendShipFrom?.postalCode}
                                    </Typography>
                                </DialogContent>
                                {!usingRecommendShipFrom &&
                                    <DialogActions sx={{justifyContent: 'flex-start'}}>
                                        <Button onClick={() => handleUsingRecommendShipFrom(true)}>
                                            Use this address
                                        </Button>
                                    </DialogActions>
                                }
                            </Box>
                        </Box>
                    </Box>
                }
                <Divider/>
                {isRecommendShipTo && <Box sx={style.DialogSection}>
                    <Typography sx={style.DialogHeader}>
                        Ship To
                    </Typography>
                    <Box sx={style.DialogContainer}>
                        <Box sx={style.DialogBox}>
                            <DialogContent>
                                <Typography sx={style.DialogTitle}>
                                    Provided Address
                                </Typography>
                                <Typography>
                                    {shipTo?.address}
                                </Typography>
                                <Typography>
                                    {shipTo?.city}, {shipTo?.province}
                                </Typography>
                                <Typography>
                                    {shipTo?.country}, {shipTo?.postalCode}
                                </Typography>
                            </DialogContent>
                            {usingRecommendShipTo &&
                                <DialogActions sx={{justifyContent: 'flex-start'}}>
                                    <Button onClick={() => handleUsingRecommendShipTo(false)}>
                                        Use this address
                                    </Button>
                                </DialogActions>
                            }
                        </Box>
                        <Typography>
                            OR
                        </Typography>
                        <Box sx={style.DialogBox}>
                            <DialogContent>
                                <Typography sx={style.DialogTitle}>
                                    Recommend Address
                                </Typography>
                                <Typography>
                                    {recommendShipTo?.address}
                                </Typography>
                                <Typography>
                                    {recommendShipTo?.city}, {recommendShipTo?.province}
                                </Typography>
                                <Typography>
                                    {recommendShipTo?.country}, {recommendShipTo?.postalCode}
                                </Typography>
                            </DialogContent>
                            {!usingRecommendShipTo && <DialogActions sx={{justifyContent: 'flex-start'}}>
                                <Button onClick={() => handleUsingRecommendShipTo(true)}>
                                    Use this address
                                </Button>
                            </DialogActions>
                            }
                        </Box>
                    </Box>
                </Box>
                }
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={handleGetRate}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <RatingItem/>
            <RatingShipping/>
            <Box sx={style.RatingButtonSection}>
                <Button
                    // onClick={handleGetRate}
                    onClick={handleClickGetShippingRate}
                    sx={style.RatingButton}>
                    Get Shipping Rate
                </Button>
            </Box>
        </Box>
    )
}