import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import wechatPay from "../../images/wechatPay.svg";
import {useEffect, useState} from "react";
import {getAccessToken} from "../../Utils/doToken";
import axios from "axios";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import {ALPHAPAY_WECHATPAY} from "../../Utils/consInfo";
import CircularProgress from "@mui/material/CircularProgress";

const styles = {
    ShippingOrderDetailsWeChatPayRoot: {
        // border: '1px solid #000000',
        display: 'flex',
        borderRadius: '10px',
        width: '100%'
    }
}

export const MovingOrderDetailsWeChatPay = ({orderDetails, orderId}) => {

    const accessToken = getAccessToken("access_token");

    const [qrCodeImage, setQrCodeImage] = useState();
    const [amount, setAmount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    const getOrCode = async () => {

        let requestURL = `${NODE_ROUTE_URI}/generalWechatAlipay/initEndUserMovingDownPayment`;

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: {
                    backendOrderId: orderId,
                    payType: ALPHAPAY_WECHATPAY
                }
            });

            console.log('get qr code', result);
            const {data: {qrcode_image, amount: payAmount, pay_url, provider}} = result;
            setQrCodeImage(qrcode_image);
            setAmount((+payAmount / 100));
        } catch (e) {
            console.log(e.response);
            setQrCodeImage("");
            setAmount(0);
        } finally {
            setIsLoading(false);

        }
    }

    useEffect(async () => {
        if (orderId) {
            await getOrCode();
        }
    }, [orderId])

    if (isLoading) {
        return <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <CircularProgress/>
        </Box>
    }

    if (qrCodeImage === "" && !isLoading) {
        return <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <Typography sx={{fontSize: '20px', fontWeight: '600'}}>
                Sorry We currently encounter some issues, please try again later.
            </Typography>
        </Box>
    }

    return (
        <Box sx={styles.ShippingOrderDetailsWeChatPayRoot}>
            <Grid container spacing={4}>
                <Grid item md={4} xs={12}>
                    <Box sx={{padding: '5px 15px'}}>
                        <img src={qrCodeImage} alt="qrCodeLogo" width={170}/>
                    </Box>
                </Grid>
                <Grid item md={8} xs={12}>
                    <Box sx={{
                        padding: '5px 15px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        height: '100%',
                        gap: '5px'
                    }}>
                        <Box sx={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                            <img src={wechatPay} alt="wechatLogo" width={35}/>
                            <Typography style={{fontSize: '16px'}}>
                                WeChat Pay
                            </Typography>
                        </Box>
                        <Grid container spacing={0}>
                            <Grid item xs={6}>
                                <Typography style={{fontSize: '16px', textAlign: 'left'}}>
                                    Total Amount:
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{fontSize: '16px', textAlign: 'left'}}>
                                    $ {amount.toFixed(2)}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                    Receiver:
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                    UU CARGO LTD.
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                    Order Number:
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                    {orderDetails?.order_number}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}