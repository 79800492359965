import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import {getAccessToken} from "../../Utils/doToken";
import axios from "axios";
import {Snackbar} from "@mui/material";
import * as React from 'react';
import MuiAlert from "@mui/material/Alert";
import intl from "react-intl-universal";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    TopUpPromoRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '10px',
        display: 'flex',
        flexDirection: 'column',
    },
    TopUpPromoTitle: {
        fontWeight: '600',
        textAlign: 'left'
    },
    TopUpPromoText: {
        textAlign: 'left',
        fontSize: '14px'
    },

}

export const TopUpPromo = ({cbHandleCoupon, amount, startsWithBusiness}) => {

    const storedToken = getAccessToken("access_token");

    const [promoCode, setPromoCode] = useState(null);
    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [coupon, setCoupon] = useState({
        result: false,
        couponAmount: 0,
        coupon: ''
    });

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handlePromoCode = (event) => {
        setPromoCode(event.target.value);
    }

    const verifyCouponForTopUp = async () => {
        setLoading(true);
        const requestURL = startsWithBusiness ? `${PARTNER_URI}/billingCenter/verifyCouponForTopUp` : `${NODE_ROUTE_URI}/billingCenter/verifyCouponForTopUp`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                data: {
                    amount: amount,
                    coupon: promoCode
                }
            })
            console.log('result', result.data);
            setLoading(false);
            setErrorMessage('');
            setCoupon(result.data);
            if (result?.data?.result) {
                setSuccessMessage('Your coupon is valid, and you can enjoy the benefits.');
                handleSuccessMessage();
            } else {
                setErrorMessage('The coupon code you provided is not valid or has already expired.');
                handleErrorMessage();
            }
        } catch (e) {
            setLoading(false);
            console.log('error', e.response)
            setErrorMessage('The system is currently facing technical problems, and please try again later.');
            handleErrorMessage();
        }
    }

    const handleRedeem = () => {
        verifyCouponForTopUp();
    }

    useEffect(() => {
        cbHandleCoupon(coupon);
    }, [coupon])
    console.log('promo code', promoCode);

    return (
        <Box sx={styles.TopUpPromoRoot}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Typography sx={styles.TopUpPromoTitle}>
                {intl.get('BILLING_CENTER.TOP_UP_PAGE.TOP_UP_PROMO')}
            </Typography>
            <TextField
                value={promoCode}
                size='small'
                fullWidth
                onInput={handlePromoCode}
            />
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <LoadingButton
                    disabled={promoCode ? false : true}
                    variant='contained'
                    sx={{
                        backgroundColor: '#1D8B45',
                        "&:hover": {
                            backgroundColor: '#1D8B45',
                            filter: 'brightness(0.9)'
                        }
                    }}
                    onClick={handleRedeem}
                    loading={loading}
                >
                    <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                        {intl.get('BILLING_CENTER.TOP_UP_PAGE.REDEEM')}
                    </Typography>
                </LoadingButton>
            </Box>
        </Box>
    )
}