import React, { useState, useRef, useEffect } from "react";
import { makeStyles, IconButton } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CloseIcon from "@material-ui/icons/Close";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
const useStyles = makeStyles(theme => ({
  viewImagesLayout_root: {
    width: "90%",
    // maxWidth: "1000px",
    height: "90%",
    // maxHeight: "1000px",
    position: "fixed",
    top: "10%",
    left: "5%",
    zIndex: "100",
  },
  imageWrapper: {
    overFlow: "hidden",
    position: "absolute",
    // margin: "10% auto",
    width: "100%",
    height: "100%",
    zIndex: "101",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  forwardButton: {
    position: "absolute",
    top: "45%",
    right: "10px",
    zIndex: "102",
  },
  backwardButton: {
    position: "absolute",
    top: "45%",
    zIndex: "102",
    left: "10px",
  },
  closeButton: {
    position: "absolute",
    top: "20px",
    zIndex: "102",
    right: "20px",
  },
  zoomInButton: {
    position: "absolute",
    bottom: "10px",
    zIndex: "102",
    right: "60px",
  },
  zoomOutButton: {
    position: "absolute",
    bottom: "10px",
    zIndex: "102",
    right: "20px",
  },
  buttons: {
    color: "white",
  },
}));

export default function ViewImagesLayout({ images, close }) {
  const [page, setPage] = useState(0);
  const classes = useStyles();
  const imgRef = useRef();
  const [imageState, setImageState] = useState({});

  useEffect(() => {
    let isDown = false;
    let mousePosition;
    let offset = [0, 0];
    let target = document.getElementById("image");

    //for mobile device
    target.addEventListener(
      "touchstart",
      e => {
        target.style.position = "absolute";
        isDown = true;
        offset = [
          e.touches[0].clientX - target.offsetLeft,
          e.touches[0].clientY - target.offsetTop,
        ];
      },
      false
    );

    target.addEventListener(
      "mousedown",
      e => {
        target.style.position = "absolute";
        isDown = true;
        offset = [e.clientX - target.offsetLeft, e.clientY - target.offsetTop];
      },
      true
    );

    //for mobile devices
    target.addEventListener(
      "touchmove",
      e => {
        e.preventDefault();
        if (isDown) {
          mousePosition = {
            x: e.touches[0].clientX,
            y: e.touches[0].clientY,
          };
          target.style.left = mousePosition.x - offset[0] + "px";
          target.style.top = mousePosition.y - offset[1] + "px";
        }
      },
      true
    );

    target.addEventListener(
      "mousemove",
      e => {
        e.preventDefault();
        if (isDown) {
          mousePosition = {
            x: e.clientX,
            y: e.clientY,
          };
          target.style.left = mousePosition.x - offset[0] + "px";
          target.style.top = mousePosition.y - offset[1] + "px";
        }
      },
      false
    );

    target.addEventListener("mouseup", () => {
      isDown = false;
    });

    //for mobile devices
    target.addEventListener("touchend", () => {
      isDown = false;
    });
    return () => {
      //cleanup
    };
  }, [page]);

  const handleBackward = () => {
    if (page > 0) {
      setPage(pre => {
        return pre - 1;
      });
    }
  };

  const handleForward = () => {
    if (page < images.length - 1) {
      setPage(pre => {
        return pre + 1;
      });
    }
  };

  const handleCloseView = () => {
    close();
  };

  const handleZoomIn = () => {
    let height = imgRef.current.clientHeight;
    let width = imgRef.current.clientWidth;
    setImageState({
      ...imageState,
      height: height * 1.4,
      width: width * 1.4,
    });
  };

  const handleZoomOut = () => {
    let height = imgRef.current.clientHeight;
    let width = imgRef.current.clientWidth;
    setImageState({
      ...imageState,
      height: height * 0.7,
      width: width * 0.7,
    });
  };

  return (
    <div className={classes.viewImagesLayout_root}>
      <div className={classes.imageWrapper}>
        <img
          id="image"
          src={images[`${page}`].image_url}
          alt={`package-${page}`}
          ref={imgRef}
          style={{
            height: `${imageState.height}px`,
            width: `${imageState.width}px`,
          }}
        />
      </div>
      <IconButton
        className={`${classes.backwardButton} ${classes.buttons}`}
        onClick={handleBackward}
      >
        <ArrowBackIosIcon />
      </IconButton>
      <IconButton
        className={`${classes.forwardButton} ${classes.buttons}`}
        onClick={handleForward}
      >
        <ArrowForwardIosIcon />
      </IconButton>
      <IconButton
        className={`${classes.closeButton} ${classes.buttons}`}
        onClick={handleCloseView}
      >
        <CloseIcon />
      </IconButton>
      <IconButton
        className={`${classes.zoomInButton} ${classes.buttons}`}
        onClick={handleZoomIn}
      >
        <ZoomInIcon />
      </IconButton>
      <IconButton
        className={`${classes.zoomOutButton} ${classes.buttons}`}
        onClick={handleZoomOut}
      >
        <ZoomOutIcon />
      </IconButton>
    </div>
  );
}
