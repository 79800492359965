import Box from "@mui/material/Box";
import {useRef, useState} from "react";
import {NODE_ROUTE_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {separateTaxes} from "../../../Utils/calculateTax";
import {getAccessToken} from "../../../Utils/doToken";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {Button} from "@mui/material";
import {CSVLink} from "react-csv";
import * as React from "react";
import {saveAs} from 'file-saver';

const style = {
    PackageOrderDashboardDocumentsRoot: {
        border: '1px solid #B2B2B2',
        borderRadius: '10px',
        padding: '10px 0'
    }
}

export const PackageOrderDashboardDocuments = ({orderId, orderInfo}) => {

    const accessToken = getAccessToken("access_token");

    const csvLink = useRef();
    const csvTrackingLink = useRef();

    const [transactions, setTransactions] = useState([]);
    const [trackings, setTrackings] = useState([]);

    const getLabelURL = (pdf, zip) => {
        if (pdf) {
            return pdf;
        }
        if (zip) {
            return zip;
        }
        return "";
    }

    const getTransactionsListByOrderId = async () => {

        let requestURL = `${NODE_ROUTE_URI}/shippingOrderDetailsDashboard/endUser/getTransactionsByOrderId`;
        const resultArray = [["Order ID", "Cargo Control Number", "Ref Order No", "Tran ID", "Account Base", "Client Number", "Product ID", "Exclude Tax", "Include Tax", "GST", "HST", "QST", "Date", "Tracking #", "Labels"],];

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                params: {
                    orderId: orderId
                }
            })
            for (const column of result.data.result) {
                const columnData = [];
                columnData.push(orderId);
                columnData.push(column.cargo_control_no);
                columnData.push(orderInfo?.ref_order_no);
                columnData.push(column.tran_id);
                columnData.push(orderInfo.account_base);
                // Client ID
                columnData.push(column.ref_other_no);
                // Product ID
                columnData.push(column.ref_product_no);
                // Swap Price
                columnData.push(column.amount_gross);
                columnData.push(column.amount);

                const taxesObject = separateTaxes({
                    excludeTaxAmount: column.amount_gross,
                    includeTaxAmount: column.amount
                })
                columnData.push(taxesObject.gstTax);
                columnData.push(taxesObject.hstTax);
                columnData.push(taxesObject.qstTax);

                columnData.push(column.tranacted_at);
                columnData.push(column.tran_tracking_nos);
                columnData.push(getLabelURL(column.label_pdf_uri, column?.label_zip_uri) ? `https://storage.googleapis.com/${getLabelURL(column.label_pdf_uri, column?.label_zip_uri)}` : "N/A");

                resultArray.push(columnData);
            }
            setTransactions(resultArray);
            csvLink.current.link.click()

        } catch (e) {
            console.log(e)
        }
    }

    const getAllTrackingsFromDBByOrderId = async () => {
        let requestURL = `${NODE_ROUTE_URI}/shippingOrderDetailsDashboard/endUser/trackings`;
        const resultArray = [["Order ID", "Ref Order No", "Cargo Control Number", "Account Base", "Tracking Number", "Transaction ID", "Ref Client No", "Ref Product No", "Labels", "Label URI", "Date"]]

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: {
                    orderId: orderId
                }
            })
            console.log(result.data)
            for (const column of result.data.result) {
                const columnData = [];
                columnData.push(orderId);
                columnData.push(orderInfo.ref_order_no);
                columnData.push(orderInfo.cargo_control_no);
                columnData.push(orderInfo.account_base);
                columnData.push(column.tracking_no);
                columnData.push(column.tran_id);
                columnData.push(column.ref_other_no);
                columnData.push(column.ref_product_no);
                columnData.push(getLabelURL(column.label_pdf_uri, column.label_zip_uri) ? `https://storage.googleapis.com/${getLabelURL(column.label_pdf_uri, column.label_zip_uri)}` : "N/A");
                columnData.push(column.label_uri ? `https://storage.googleapis.com/${column.label_uri}` : "N/A");
                columnData.push(column.tranacted_at);
                resultArray.push(columnData);
            }
            setTrackings(v => {
                const temp = [...v];
                temp.push(...resultArray);
                return temp;
            });
            console.log(resultArray);
            csvTrackingLink.current.link.click()
        } catch (e) {
            console.log(e)
        }
    }

    const getLabelZipByOrderId = async () => {
        let requestURL = `${NODE_ROUTE_URI}/shippingOrderDetailsDashboard/getLabelZipByOrderId`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                params: {
                    orderId: orderId
                },
                responseType: 'blob'
            })

            const blobData = new Blob([result.data], {
                type: "application/zip"
            });
            saveAs(blobData, `${orderId}.zip`);
        } catch (e) {
            console.log(e.response)
        }
    }

    return (
        <Box sx={style.PackageOrderDashboardDocumentsRoot}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '10px',
                        borderBottom: '1px solid #B2B2B2'
                    }}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            Shipping Documents
                        </Typography>
                    </Box>
                </Grid>
                {/*<Grid item xs={7}>*/}
                {/*    <Box sx={{*/}
                {/*        display: 'flex',*/}
                {/*        alignItems: 'center',*/}
                {/*        height: '100%',*/}
                {/*        justifyContent: 'flex-start',*/}
                {/*        padding: '0 10px'*/}
                {/*    }}>*/}
                {/*        <Typography>*/}
                {/*            Transaction Details*/}
                {/*        </Typography>*/}
                {/*    </Box>*/}
                {/*</Grid>*/}
                {/*<Grid item xs={5}>*/}
                {/*    <Button*/}
                {/*        variant='outlined'*/}
                {/*        sx={{*/}
                {/*            border: '2px solid #1D8B45',*/}
                {/*            "&:hover": {*/}
                {/*                border: '2px solid #1D8B45',*/}
                {/*                filter: 'brightness(0.9)'*/}
                {/*            }*/}
                {/*        }}*/}
                {/*        onClick={getTransactionsListByOrderId}*/}
                {/*    >*/}
                {/*        <Typography style={{*/}
                {/*            textTransform: 'none',*/}
                {/*            fontSize: '14px',*/}
                {/*            color: '#1D8B45',*/}
                {/*        }}>*/}
                {/*            Download*/}
                {/*        </Typography>*/}
                {/*    </Button>*/}
                {/*    <CSVLink*/}
                {/*        data={transactions}*/}
                {/*        filename={`${orderId}-${orderInfo?.ref_order_no}-transactions.csv`}*/}
                {/*        className='hidden'*/}
                {/*        ref={csvLink}*/}
                {/*        target='_blank'*/}
                {/*    />*/}
                {/*</Grid>*/}
                <Grid item xs={7}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-start',
                        padding: '0 10px'
                    }}>
                        <Typography>
                            Tracking Details
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    <Button
                        variant='outlined'
                        sx={{
                            border: '2px solid #1D8B45',
                            "&:hover": {
                                border: '2px solid #1D8B45',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        onClick={getAllTrackingsFromDBByOrderId}
                    >
                        <Typography style={{
                            textTransform: 'none',
                            fontSize: '14px',
                            color: '#1D8B45',
                        }}>
                            Download
                        </Typography>
                    </Button>
                    <CSVLink
                        data={trackings}
                        filename={`${orderId}-${orderInfo?.ref_order_no}-trackings.csv`}
                        className='hidden'
                        ref={csvTrackingLink}
                        target='_blank'
                    />
                </Grid>
                <Grid item xs={7}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-start',
                        padding: '0 10px'
                    }}>
                        <Typography>
                            Shipping Label
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    <Button
                        variant='outlined'
                        sx={{
                            border: '2px solid #1D8B45',
                            "&:hover": {
                                border: '2px solid #1D8B45',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        onClick={getLabelZipByOrderId}
                    >
                        <Typography style={{
                            textTransform: 'none',
                            fontSize: '14px',
                            color: '#1D8B45',
                        }}>
                            Download
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}


