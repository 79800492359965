import Grid from "@mui/material/Grid";
import intl from "react-intl-universal";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import React, {useState} from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import {MenuItem, Select} from "@mui/material";

export const MovingOrderDashboardPayPartner = ({
                                                   orderDetails,
                                                   cbHandleDriverAmount,
                                                   cbHandleDriverMethod,
                                               }) => {

    const [amount, setAmount] = useState((+orderDetails?.task_unpaid_amount).toFixed(2));
    const [method, setMethod] = useState('cash');

    const handleAmount = (event) => {
        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            if (value > +orderDetails?.task_unpaid_amount) {
                setAmount(+orderDetails?.task_unpaid_amount);
                cbHandleDriverAmount(+orderDetails?.task_unpaid_amount);
            } else {
                setAmount(value);
                cbHandleDriverAmount(value);
            }

        }
    }

    const handleSelect = (event) => {
        const {value} = event.target;
        setMethod(value);
        cbHandleDriverMethod(value);
    }

    return (
        <Box sx={{
            border: '1px solid #B2B2B2',
            borderRadius: '10px',
            padding: '20px',
        }}>
            <Grid container spacing={2}>
                <Grid item md={2} xs={3}>
                    <Typography sx={{textAlign: 'left', color: '#1D8B45', fontWeight: '600'}}>
                        Step 1:
                    </Typography>
                </Grid>
                <Grid item md={10} xs={9}>
                    <Typography sx={{textAlign: 'left'}}>
                        Pay the amount to the driver via cash, cheque, or other methods.
                    </Typography>
                </Grid>
                <Grid item md={2} xs={3}>
                    <Typography sx={{textAlign: 'left', color: '#1D8B45', fontWeight: '600'}}>
                        Step 2:
                    </Typography>
                </Grid>
                <Grid item md={10} xs={9}>
                    <Typography sx={{textAlign: 'left'}}>
                        After making the payment, you will need to send the payment
                        to the moving company and waiting for confirmation.
                    </Typography>
                </Grid>
                <Grid item md={6} xs={6}>
                    <FormControl fullWidth>
                        <FormLabel required>Amount</FormLabel>
                        <TextField
                            value={amount}
                            onInput={handleAmount}
                            fullWidth
                            variant="outlined"
                            size='small'
                            type='number'
                            inputProps={{
                                max: +orderDetails?.task_unpaid_amount,
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={6} xs={6}>
                    <FormControl fullWidth>
                        <FormLabel required>Method</FormLabel>
                        <Select
                            value={method}
                            fullWidth
                            onChange={handleSelect}
                            size='small'
                        >
                            <MenuItem disabled value='status'>
                                <em>Method</em>
                            </MenuItem>
                            <MenuItem value='cash'>Cash</MenuItem>
                            <MenuItem value='cheque'>Cheque</MenuItem>
                            <MenuItem value='other'>Other</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    )
}