import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Button} from "@mui/material";
import {ReactComponent as CrossBorderLogo} from "../../images/crossborderIcon/CrossBorder.svg";
import React, {useEffect, useState} from "react";
import {CrossBorderDashboardInvoiceList} from "./CrossBorderDashboardInvoiceList";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {getAccessToken} from "../../Utils/doToken";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {CrossBorderDashboardItemList} from "./CrossBorderDashboardItemList";
import {generateRandomString} from "../../Utils/Helper";

export const CrossBorderDashboardInvoice = ({shippingListData, cbGetTrackingList}) => {

    const token = getAccessToken("access_token");

    const {order_id} = useParams();
    const partnerInfo = useSelector((state) => state.user);
    const [itemList, setItemList] = useState([]);
    const [invoiceStatus, setInvoiceStatus] = useState(false);
    const [trackingList, setTrackingList] = useState([]);
    const [invoiceList, setInvoiceList] = useState([]);
    const [isCreate, setIsCreate] = useState(false);

    const openCreateInvoice = () => {
        setIsCreate(true);
    }

    const closeCreateInvoice = () => {
        setIsCreate(false);
    }

    const addInvoiceList = (invoice) => {
        console.log('invoice data', invoice);
        setInvoiceList(prevState => [...prevState, invoice]);
    }

    const removeInvoiceList = (invoice) => {
        const invoiceIdToFind = invoice?.invoiceId;
        const filterInvoiceList = invoiceList?.filter(each => each?.invoiceId !== invoiceIdToFind);
        setInvoiceList(filterInvoiceList);
    }

    const clearInvoiceList = () => {
        setInvoiceList([]);
    }

    const getTrackingList = async () => {
        let requestURI = `${NODE_ROUTE_URI}/shippingOrderDetailsDashboard/getTrackingViewByShippingOrderId/${order_id}`;
        try {
            const results = await axios({
                method: 'get',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${token}`
                },
            })
            const {data} = results;

            console.log('get tracking list', data);

            const allInvoiceStatus = data.every(element => element?.doc_status === 2);

            console.log('all invoice status', allInvoiceStatus);

            setInvoiceStatus(allInvoiceStatus);

            const updatedTrackingList = [];

            data.forEach(element => {
                if (element.doc_status !== 2) {
                    const randomId = generateRandomString(8);
                    let object = {
                        trackingId: randomId,
                        isChecked: true,
                        trackingNumber: element?.tracking_no,
                        tranId: element?.tran_id,
                        shippingIdNumber: element?.shipping_id_no,
                        description: element?.item_name,
                        unitPrice: element?.unit_price,
                        quantity: 1,
                        UOM: element?.uom,
                        weight: element?.weight,
                        hsCode: element?.hs_code,
                        originalCountry: element?.origi_country,
                        currency: element?.currency_code,
                        weightUnit: element?.weight_unit,
                        length: element?.length,
                        height: element?.height,
                        width: element?.width,
                        dimensionUnit: element?.dimension_unit
                    }
                    updatedTrackingList.push(object);
                }
            })

            console.log('update tracking list', updatedTrackingList);
            setTrackingList(updatedTrackingList);

        } catch (e) {
            console.log(e)
        }
    }

    const getItemList = async () => {
        let requestURI = `${NODE_ROUTE_URI}/packingList/getPackingListAndPackingListDetailByShippingOrderIdAndUserId`;
        try {
            const results = await axios({
                method: 'get',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    shippingOrderId: order_id,
                    currency: 'CAD'
                }
            })

            const {data: {packingListDetails}} = results;
            console.log('get packing list', packingListDetails);

            const updatedPackingList = [];

            packingListDetails.forEach(element => {
                let object = {
                    packingId: element?.id,
                    isChecked: true,
                    description: element?.item_name,
                    unitPrice: element?.unit_price,
                    quantity: element?.quantity,
                    UOM: element?.uom,
                    weight: element?.weight,
                    hsCode: element?.hs_code,
                    originalCountry: element?.origi_country,
                    currency: element?.currency_code,
                    weightUnit: element?.weight_unit,
                }
                updatedPackingList.push(object);
            })
            setItemList(updatedPackingList);
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getTrackingList();
        getItemList();
    }, []);

    console.log('item list', itemList);
    console.log('invoice list', invoiceList);

    return (
        <Box sx={{
            border: '1px solid #B2B2B2',
            borderRadius: '10px',
            padding: '20px'
        }}>
            <Grid container spacing={2}>
                <Grid item md={2} xs={12}>
                    <Box sx={{
                        display: 'flex',
                        height: '125px',
                        alignItems: 'center'
                    }}>
                        <CrossBorderLogo width="100%" height="100%"/>
                    </Box>
                </Grid>
                <Grid item md={7} xs={12}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            gap: '5px'
                        }}>
                            <Typography style={{fontSize: '14px'}}>
                                1.
                            </Typography>
                            <Typography style={{fontSize: '14px'}}>
                                <span style={{fontWeight: '600'}}>Create or Upload Invoice:</span> Create an invoice
                                using our system or upload your own commercial invoice in the "Package" Section.
                                Download Sample Invoice.
                            </Typography>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            gap: '5px'
                        }}>
                            <Typography style={{fontSize: '14px'}}>
                                2.
                            </Typography>
                            <Typography style={{fontSize: '14px'}}>
                                <span style={{fontWeight: '600'}}>Attach Invoice:</span> If you've created an
                                invoice, click 'Upload Invoice' to attach it to the corresponding package
                            </Typography>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            gap: '5px'
                        }}>
                            <Typography style={{fontSize: '14px'}}>
                                3.
                            </Typography>
                            <Typography style={{fontSize: '14px'}}>
                                <span style={{fontWeight: '600'}}>Submit Invoice:</span> Ensure you've successfully
                                submitted your invoice in the "Package" Section.
                            </Typography>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            gap: '5px'
                        }}>
                            <Typography style={{fontSize: '14px'}}>
                                4.
                            </Typography>
                            <Typography style={{fontSize: '14px'}}>
                                <span style={{fontWeight: '600'}}>Prepare for Shipment:</span> Print the invoice
                                forms, place them in a small bag, and attach it next to the shipping
                                label on your package.
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={3} xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        <Button
                            variant='contained'
                            sx={{
                                backgroundColor: '#1D8B45',
                                "&:hover": {
                                    backgroundColor: '#1D8B45',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                            onClick={openCreateInvoice}
                            disabled={invoiceStatus}
                        >
                            <Typography style={{textTransform: 'none'}}>
                                + Create Invoice
                            </Typography>
                        </Button>
                    </Box>
                </Grid>
                <Grid item md={9} xs={12}>
                    <CrossBorderDashboardInvoiceList
                        itemList={itemList}
                        trackingList={trackingList}
                        invoiceStatus={invoiceStatus}
                        invoiceList={invoiceList}
                        isCreate={isCreate}
                        closeCreateInvoice={closeCreateInvoice}
                        addInvoiceList={addInvoiceList}
                        removeInvoiceList={removeInvoiceList}
                        shippingListData={shippingListData}
                        cbGetTrackingList={cbGetTrackingList}
                        clearInvoiceList={clearInvoiceList}
                        getTrackingList={getTrackingList}
                    />
                </Grid>
                <Grid item md={3} xs={12}>
                    <CrossBorderDashboardItemList itemList={itemList}/>
                </Grid>
            </Grid>
        </Box>
    )
}