import React, { useEffect, useState } from "react";
import LoadedShipmentDetailLayout from "../Layouts/LoadedShipmentDetailLayout";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import LoadingCircle from "../LoadingCircle";
import axios from "axios";
import { getTokenFromCookie } from "../../Utils/doToken";
import { GET_SHIPMENTS_BY_ID_API } from "../../Utils/apiUrl";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#111",
    fontFamily: "Helvetica Neue, sans-serif",
    fontSize: "30px",
    fontWeight: "bold",
    letterSpacing: "-1px",
    lineHeight: 1,
    margin: "5px 2%",
  },
}));

export default function LoadedShipmentsDetailPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loadPackage, setLoadPackage] = useState(true);
  const [loadShipments, setloadShipments] = useState(true);

  const [shipmentInfo, setShipmentInfo] = useState([]);

  const { id, stage } = useParams();
  const fetchShipmentInfo = async () => {
    //fetch shipment and its package
    setloadShipments(true);
    setLoadPackage(true);
    try {
      const result = await axios.get(GET_SHIPMENTS_BY_ID_API, {
        headers: {
          Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
          "Content-Type": "application/json",
        },
        params: {
          shipment_number: id,
          stage: stage,
        },
      });
      if (result.status === 200) {
        console.log("picked up shipment task detail", result.data);
        setShipmentInfo(result.data[0]);
        //setOrderNumber(res.data.order_number)
        setloadShipments(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchShipmentInfo();

    return () => {};
  }, [id, stage]);

  const refetchData = (update) => {
    if (update === false) return;

    fetchShipmentInfo();
  };

  return (
    <>
      {!loadShipments ? (
        <LoadedShipmentDetailLayout
          sectionTitle={"Shipping Task Detail"}
          shipmentInfo={shipmentInfo}
          refetch={refetchData}
        />
      ) : (
        <LoadingCircle />
      )}
    </>
  );
}
