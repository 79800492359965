import React, { useState } from "react";
import {Check, Cancel} from "@material-ui/icons";
import {
  TableBody,
  TableRow,
  TableCell,
  Button
} from "@material-ui/core";

const LooseItemServices = ({
  setCompanyName,
  companyName,
  shippingService,
  chargeAmount,
  toSeventh,
  setServiceName
}) => {

  const setAndLoadNext = (currentCompanyName, currentServiceName) => {
    setCompanyName(currentCompanyName);
    setServiceName(currentServiceName);
    toSeventh()
  };

  return (
    <TableBody>
    <TableRow>
      <TableCell align="left">
        <Button onClick={() => setAndLoadNext(companyName, shippingService)}>Select</Button>
      </TableCell>
      <TableCell align="center">{companyName}</TableCell>
      <TableCell align="center">{shippingService}</TableCell>
      <TableCell align="right">{chargeAmount}</TableCell>
    </TableRow>
  </TableBody>
  )
}

export default LooseItemServices;
