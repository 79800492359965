import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

export default function BindCreditCardInfoLayout({
  open,
  handleClose,
  redirect,
}) {
  const history = useHistory();

  const goToBindCreditCard = () => {
    history.push("/profile/add-card", { redirect: redirect });
  };

  return (
    <>
      {
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">
            {"Please bind your credit card."}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Binding a credit card allow you to access more information of
              shipments.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={goToBindCreditCard}
              variant="contained"
            >
              Bind my credit card
            </Button>
            <Button onClick={handleClose} variant="contained">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      }
    </>
  );
}

BindCreditCardInfoLayout.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  redirect: PropTypes.string.isRequired,
};
