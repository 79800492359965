import Box from "@mui/material/Box";
import React from 'react';
import {MyQRCode} from "./MyQRCode";
import {Container} from "@mui/material";
import {useSelector} from "react-redux";
import Typography from "@mui/material/Typography";
import UUCargoLogo from "../../images/logo.png";

const styles = {
    QRCodeRoot: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '10px',
        backgroundColor: '#FFFFFF',
        height: '100%',
        borderRadius: '50px'
    },
    QRCodeUpper: {
        backgroundColor: '#FCBB10',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        padding: '80px 0 100px',
        borderRadius: '50px'
    },

    QRCodeHeading: {
        fontSize: '60px',
        color: '#FFFFFF',
        fontWeight: '600'
    },

    QRCodeSubHeading: {
        fontSize: '45px',
        color: '#FFFFFF',
        fontWeight: '600'
    },

    QRCodeText: {
        fontSize: '30px',
        color: '#FFFFFF',
        fontWeight: '600'
    },

    QRCodeBox: {
        backgroundColor: '#FFFFFF',
        padding: '30px',
        marginTop: '50px',
        borderRadius: '75px'
    },

    QRCodeLogo: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '20px',
    }
}

export const ComponentToPrint = React.forwardRef((props, ref) => {

    const userInfo = useSelector((state) => state.user);

    console.log('user info', userInfo);

    return (
        <Container style={{display: "none"}}>
            <Box ref={ref} sx={styles.QRCodeRoot}>
                <Box sx={styles.QRCodeUpper}>
                    <Typography sx={styles.QRCodeHeading}>
                        Scan. Pay. Deliver
                    </Typography>
                    <Typography sx={styles.QRCodeSubHeading}>
                        Ship Like a Pro with
                    </Typography>
                    <Typography sx={styles.QRCodeText}>
                        {userInfo?.companyName} + UUCargo
                    </Typography>
                    <Box sx={styles.QRCodeBox}>
                        <MyQRCode size={400}/>
                    </Box>
                </Box>
                <Box sx={styles.QRCodeLogo}>
                    <img src={UUCargoLogo} alt="logo"/>
                </Box>
            </Box>
        </Container>
    )
});

