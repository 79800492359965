import Grid from "@material-ui/core/Grid";
import {Divider, useMediaQuery} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useState} from "react";
import {ShippingOrderListLTLEach} from "./ShippingOrderListLTLEach";
import intl from "react-intl-universal";

export const ShippingOrderListLTL = ({order}) => {

    const isMobile = useMediaQuery('(max-width: 480px)');

    const [openAction, setOpenAction] = useState(null);

    const cbSetOpenAction = (openActionNum) => {
        setOpenAction(openActionNum);
    }

    console.log('ltl order', order);

    return (
        <Grid container spacing={1}>
            <Grid item md={2} xs={5}>
                <Typography sx={{fontSize: '14px', fontWeight: '600'}}>
                    {intl.get('HOME_PAGE.SHIPPING_ORDER.ORDER')}
                </Typography>
            </Grid>
            {!isMobile &&
                <Grid item md={3}>
                    <Typography sx={{fontSize: '14px', fontWeight: '600', textAlign: 'left'}}>
                        {intl.get('HOME_PAGE.SHIPPING_ORDER.SENDER')}
                    </Typography>
                </Grid>
            }
            {!isMobile &&
                <Grid item md={3}>
                    <Typography sx={{fontSize: '14px', fontWeight: '600', textAlign: "left"}}>
                        {intl.get('HOME_PAGE.SHIPPING_ORDER.RECIPIENT')}
                    </Typography>
                </Grid>
            }
            {!isMobile &&
                <Grid item md={1}>
                    <Typography sx={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                        {intl.get('HOME_PAGE.SHIPPING_ORDER.STATUS')}
                    </Typography>
                </Grid>
            }
            <Grid item md={1} xs={3}>
                <Typography sx={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                    {intl.get('HOME_PAGE.SHIPPING_ORDER.CARRIER')}
                </Typography>
            </Grid>
            <Grid item md={1} xs={4}>
                <Typography sx={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                    {intl.get('HOME_PAGE.SHIPPING_ORDER.CHARGE')}
                </Typography>
            </Grid>
            <Grid item md={1} xs={2}>
                <Typography sx={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                    {intl.get('HOME_PAGE.SHIPPING_ORDER.ACTION.ACTION')}
                </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
                <Divider/>
            </Grid>
            {
                order?.map((orderEach, index) =>
                    <Grid item xs={12} key={index}>
                        <ShippingOrderListLTLEach
                            order_info={orderEach}
                            openActionNum={openAction}
                            cbSetOpenAction={cbSetOpenAction}
                        />
                    </Grid>
                )
            }
        </Grid>

    )
}