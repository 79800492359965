import React, { useState, useEffect } from "react";
// import Slide from 'react-reveal';
import { useHistory } from "react-router-dom";
import { Box, Button, Grid, CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
// import PropTypes from "prop-types";
import axios from "axios";

import { getHeaders, errHandling } from "../../../../Utils/fetchUtil";
import { ADD_BOL_RECORD, UPDATE_BOL_RECORD } from "../../../../Utils/apiUrl";

const BolDetails = ({
  shippingInfo,
  itemList,
  setActiveStep,
  success,
  setSuccessState,
  clearData,
  edit,
  refNo,
}) => {
  const classes = useStyles();

  const [errMsg, setErrMsg] = useState("");

  const [loading, setLoadingState] = useState(false);

  const history = useHistory();

  // const matches = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const onClick = () => {
    setLoadingState(true);
    if (edit) {
      axios({
        method: "put",
        url: UPDATE_BOL_RECORD,
        data: JSON.stringify({
          info: shippingInfo,
          itemList: itemList,
          refNo: refNo,
        }),
        headers: {
          ...getHeaders(),
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      })
        .then((res) => {
          setSuccessState(true);
          setLoadingState(false);
          clearData();
        })
        .catch((err) => {
          errHandling(err, setErrMsg);
          setLoadingState(false);
        });
    } else {
      axios({
        method: "post",
        url: ADD_BOL_RECORD,
        data: JSON.stringify({ info: shippingInfo, itemList: itemList }),
        headers: {
          ...getHeaders(),
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      })
        .then((res) => {
          setSuccessState(true);
          setLoadingState(false);
          clearData();
        })
        .catch((err) => {
          errHandling(err, setErrMsg);
          setLoadingState(false);
        });
    }
  };

  return success ? (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box>
        <Box color="seagreen" fontSize="1.2rem" pb={2} fontWeight="700">
          The bill is successfully added.
        </Box>
        <Box>
          <Button
            variant="outlined"
            onClick={() => history.push("/bol-list/created")}
          >
            Go back to list page
          </Button>
        </Box>
      </Box>
    </Box>
  ) : (
    <div>
      <br />
      <Box textAlign="center">
        <h2 className="orange-title">BOL Summary:</h2>
      </Box>
      <br />
      <Grid container spacing={3} alignContent="center">
        <Grid item xs={6}>
          <div id="summary-content2" className="bodytext">
            <Box display="flex" justifyContent="space-between">
              <Box fontWeight="700">Address and shipping</Box>
              <Button variant="outlined" onClick={() => setActiveStep(0)}>
                Edit
              </Button>
            </Box>
          </div>
          <Box id="summary-content1" className="subtitle" pl={1}>
            <p className={classes.summaryHeader}>Shipper</p>
            <p className={classes.summaryBodyText}>
              {`${shippingInfo.shipperName}`} <br />
              {`${shippingInfo.shipperAddress}`}
            </p>
          </Box>
          <Box id="summary-content3" className="subtitle" pl={1}>
            <p className={classes.summaryHeader}>Consignee</p>
            <p className={classes.summaryBodyText}>
              {`${shippingInfo.consigneeName}`} <br />
              {`${shippingInfo.consigneeAddress}`}
            </p>
          </Box>
          {(shippingInfo.nofifyPartyName ||
            shippingInfo.nofifyPartyAddress) && (
            <Box id="summary-content5" className="subtitle" pl={1}>
              <p className={classes.summaryHeader}>Notify Party</p>
              <p className={classes.summaryBodyText}>
                {`${shippingInfo.nofifyPartyName}`} <br />
                {`${shippingInfo.nofifyPartyAddress}`}
              </p>
            </Box>
          )}
          <Box id="summary-content6" className="bodytext" pl={1}>
            <p className={classes.summaryBodyText}>
              <Box component="span" color="seagreen" fontWeight="600">
                Carrier Agent
              </Box>
              : {shippingInfo.carrierAgent}
            </p>
            <p className={classes.summaryBodyText}>
              <Box component="span" color="seagreen" fontWeight="600">
                Delivery Agent
              </Box>
              : {shippingInfo.deliveryAgent}
            </p>
            {shippingInfo.placeOfAcceptance && (
              <p className={classes.summaryBodyText}>
                <Box component="span" color="seagreen" fontWeight="600">
                  Place of Acceptance
                </Box>
                : {shippingInfo.placeOfAcceptance}
              </p>
            )}
            {shippingInfo.preCarriage && (
              <p className={classes.summaryBodyText}>
                <Box component="span" color="seagreen" fontWeight="600">
                  Pre-carriage by
                </Box>
                : {shippingInfo.preCarriage}
              </p>
            )}
            {shippingInfo.numberOfOriginal && (
              <p className={classes.summaryBodyText}>
                <Box component="span" color="seagreen" fontWeight="600">
                  Number of Original
                </Box>
                : {shippingInfo.numberOfOriginal}
              </p>
            )}
            {shippingInfo.payableAt && (
              <p className={classes.summaryBodyText}>
                <Box component="span" color="seagreen" fontWeight="600">
                  Freight & Charges Payable at
                </Box>
                : {shippingInfo.payableAt}
              </p>
            )}
            {shippingInfo.excessValueDeclaration && (
              <p className={classes.summaryBodyText}>
                <Box component="span" color="seagreen" fontWeight="600">
                  Excess Value Declaration
                </Box>
                : {shippingInfo.excessValueDeclaration}
              </p>
            )}
            {shippingInfo.vessel && (
              <p className={classes.summaryBodyText}>
                <Box component="span" color="seagreen" fontWeight="600">
                  Vessel / Voyage
                </Box>
                : {shippingInfo.vessel}
              </p>
            )}
            {shippingInfo.portOfLoading && (
              <p className={classes.summaryBodyText}>
                <Box component="span" color="seagreen" fontWeight="600">
                  Port of Loading
                </Box>
                : {shippingInfo.portOfLoading}
              </p>
            )}
            {shippingInfo.portOfDischarge && (
              <p className={classes.summaryBodyText}>
                <Box component="span" color="seagreen" fontWeight="600">
                  Port of Discharge
                </Box>
                : {shippingInfo.portOfDischarge}
              </p>
            )}
            {shippingInfo.placeOfDelivery && (
              <p className={classes.summaryBodyText}>
                <Box component="span" color="seagreen" fontWeight="600">
                  Port of Delivery
                </Box>
                : {shippingInfo.placeOfDelivery}
              </p>
            )}
            {shippingInfo.onCarriageto && (
              <p className={classes.summaryBodyText}>
                <Box component="span" color="seagreen" fontWeight="600">
                  On Carriage to
                </Box>
                : {shippingInfo.onCarriageto}
              </p>
            )}
            {shippingInfo.onBoardDate && (
              <p className={classes.summaryBodyText}>
                <Box component="span" color="seagreen" fontWeight="600">
                  On Board Date
                </Box>
                : {shippingInfo.onBoardDate}
              </p>
            )}
            {shippingInfo.issueDate && (
              <p className={classes.summaryBodyText}>
                <Box component="span" color="seagreen" fontWeight="600">
                  Date of Issue
                </Box>
                : {shippingInfo.issueDate}
              </p>
            )}
            {shippingInfo.issuePlace && (
              <p className={classes.summaryBodyText}>
                <Box component="span" color="seagreen" fontWeight="600">
                  Place of Issue
                </Box>
                : {shippingInfo.issuePlace}
              </p>
            )}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box className={classes.itemSection}>
            <div id="summary-content8" className="bodytext">
              <Box display="flex" justifyContent="space-between">
                <Box fontWeight="700">Items</Box>
                <Button variant="outlined" onClick={() => setActiveStep(1)}>
                  Edit
                </Button>
              </Box>
            </div>
            <Box id="summary-content7" className="subtitle" pl={1}>
              <div className={classes.summaryBodyText}>
                {itemList.map((item, key) => {
                  return (
                    <div key={key}>
                      <p>
                        {item.hscode}, {item.hscodeDescription}
                      </p>
                    </div>
                  );
                })}
              </div>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <div></div>
      <div></div>
      <Box display="flex" justifyContent="center" alignItems="center" pt={4}>
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={onClick}
            style={{
              textTransform: "unset",
              width: "8rem",
            }}
          >
            {loading ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : edit ? (
              "update"
            ) : (
              "Submit"
            )}
          </Button>
        </Box>
        {errMsg && (
          <Box pt="1vh" width="100%">
            <Alert severity="error" classes={{ root: classes.alertRoot }}>
              {errMsg}
            </Alert>
          </Box>
        )}
      </Box>
      <br />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  button: {
    borderRadius: 20,
    fontSize: "25pt",
    backgroundColor: "#ffa726",
    color: "white",
  },
  summaryHeader: {
    fontSize: "16pt",
    color: "seagreen",
    fontFamily: "Montserrat",
    // borderBottom: '1px, solid rgb(141, 173, 155)',
  },
  summaryBodyText: {
    fontSize: "13pt",
    color: "rgb(141, 173, 155)",
    fontFamily: "Montserrat",
  },
  itemSection: {
    borderLeft: "1px solid rgba(0,0,0,0.3)",
    paddingLeft: theme.spacing(2),
  },
}));

// BolDetails.propTypes = {
//   toSeventh: PropTypes.func.isRequired,
//   toFirst: PropTypes.func.isRequired,
//   toFourth: PropTypes.func.isRequired,
// };

// export default connect(mapStateToProps, { getShipmentItems, getPickUpDetails })(QuoteSummary);
export default BolDetails;
