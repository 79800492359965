import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import * as React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Link,
    Menu,
    MenuItem,
    Snackbar,
    styled
} from "@mui/material";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import {mapCarrierLogo, movingOrderStatus, movingOrderToCancel, movingShippingStatus} from "../../Utils/Helper";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import intl from "react-intl-universal";
import {useEffect, useState} from "react";
import {alpha} from "@mui/material/styles";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../Utils/doToken";
import MuiAlert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({theme}) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(2),
        width: 200,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

export const LatestMovingOrderTableRow = ({order, index, getMovingOrderList}) => {

    const ownerRole = useSelector(state => state?.user?.ownerRole);

    const history = useHistory();

    const accessToken = getAccessToken("access_token");
    const isMobile = useMediaQuery('(max-width: 480px)');

    const [invoiceDetail, setInvoiceDetail] = useState(null);
    const [invoiceLoading, setInvoiceLoading] = useState(true);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [cancelOrderDialogIsOpen, setCancelOrderDialogIsOpen] = useState(false);
    const [isLoadingCancelOrder, setIsLoadingCancelOrder] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleCancelOrder = () => {
        setCancelOrderDialogIsOpen(!cancelOrderDialogIsOpen);
    };

    const handleCancelOrderClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            setCancelOrderDialogIsOpen(!cancelOrderDialogIsOpen);
        }
    }

    const handleHrefLink = () => {
        let href = '';
        if (+order?.status_id === 0) {
            if (ownerRole === 3) {
                return `/business/transportation/orderDetails/${order?.id}`;
            } else {
                return `/transportation/orderDetails/${order?.id}`
            }
        } else {
            if (ownerRole === 3) {
                return `/business/transportation/orderDashboard/${order?.id}`;
            } else {
                return `/transportation/orderDashboard/${order?.id}`;
            }
        }
        // return href = +order?.status_id === 0 ? `${ownerRole === 3 && '/business'}/transportation/orderDetails/${order?.id}` : `${ownerRole === 3 && '/business'}/transportation/orderDashboard/${order?.id}`;

    }

    const cancelOrder = async () => {
        setIsLoadingCancelOrder(true);

        let requestURL = ownerRole === 1 ? `${NODE_ROUTE_URI}/movingService/cancelMovingOrder` :
            `${PARTNER_URI}/movingService/cancelMovingOrder`;

        // console.log('order info', orderInfo);
        try {
            const result = await axios({
                method: 'delete',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                params: {
                    backendOrderId: order?.id
                }
            })
            console.log(result.data)
            setErrorMessage('');
            setSuccessMessage('Successful to cancel the order. Redirect to home page');
            handleSuccessMessage();
            handleCancelOrderClose();
            setTimeout(() => getMovingOrderList(), 2000);
        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail to cancel the order');
            handleErrorMessage();
        } finally {
            setIsLoadingCancelOrder(false);
        }
    }

    const getInvoiceNumber = async () => {
        setInvoiceLoading(true);
        const storedToken = getAccessToken("access_token");
        const requestURI = `${PARTNER_URI}/billingCenter/getInvoiceInfoByInvoiceId`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    invoiceId: order?.crm_invoice_no
                }
            })
            console.log('result', result);
            setInvoiceDetail(result?.data);
        } catch (e) {
            console.log('e', e?.response);
        } finally {
            setInvoiceLoading(false);
        }
    }

    const handlePayInvoice = () => {
        history.push('/business/billing-center')
    }

    useEffect(() => {
        if (order?.crm_invoice_no) {
            getInvoiceNumber();
        }
    }, [order])

    return (
        <Box>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Dialog
                open={cancelOrderDialogIsOpen}
                onClose={handleCancelOrderClose}
            >
                <DialogTitle>Cancel Request Confirmation</DialogTitle>
                <DialogContent>
                    Are you sure you want to cancel your order? This action cannot be undone. Please confirm if you
                    would like to proceed with the request.
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCancelOrder}
                        variant="contained"
                        autoFocus
                    >
                        CLOSE
                    </Button>
                    <LoadingButton
                        onClick={cancelOrder}
                        variant="contained"
                        autoFocus
                        loading={isLoadingCancelOrder}
                    >
                        CONFIRM
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Grid container spacing={1}>
                <Grid item md={2} xs={4}>
                    <Grid container spacing={1}>
                        {!isMobile &&
                            <Grid item xs={4}>
                                <img
                                    src='https://storage.googleapis.com/uucargo-resource/image/icon/UUCargoLogo.png'
                                    alt="logo"
                                    height={40}
                                />
                            </Grid>
                        }
                        <Grid item md={8} xs={12}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                height: '100%'
                            }}>
                                <Box sx={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                                    <Link
                                        href={handleHrefLink()}
                                        // href={+order?.status_id === 0 ? `/transportation/orderDetails/${order?.id}` : `/transportation/orderDashboard/${order?.id}`}
                                        style={{textDecoration: 'none'}}
                                    >
                                        <Typography style={{
                                            fontSize: '14px',
                                            fontWeight: '600',
                                            textAlign: 'left',
                                            width: '100%',
                                            color: '#1D8B45',
                                        }}>
                                            {order?.order_number}
                                        </Typography>
                                    </Link>
                                </Box>
                                <Typography style={{
                                    fontSize: '14px',
                                    textAlign: 'left',
                                    width: '100%'
                                }}>
                                    {dayjs(order?.scheduled_pickup_date).format("YYYY-MM-DD")}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    !isMobile &&
                    <Grid item md={2}>
                        <Box sx={{display: 'flex', alignItems: 'flex-start', height: '100%'}}>
                            <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                {order?.pickup_area}, {order?.pickup_postal_code}, {order?.send_from_country}
                            </Typography>
                        </Box>
                    </Grid>
                }
                {
                    !isMobile &&
                    <Grid item md={2}>
                        <Box sx={{display: 'flex', alignItems: 'flex-start', height: '100%'}}>
                            <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                {order?.deliver_area}, {order?.deliver_postal_code}, {order?.send_to_country}
                            </Typography>
                        </Box>
                    </Grid>
                }
                <Grid item md={2} xs={3}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%'
                    }}>
                        {
                            ownerRole === 1 ?
                                <Typography style={{
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    color: +order?.status_id === 0 ? '#454545' : +order?.status_id === 100 ? '#F2BE22' : +order?.status_id === 1 ? '#1D8B45' : '#FF0303'
                                }}>
                                    {movingOrderStatus(+order?.status_id)}
                                </Typography>
                                :
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%'
                                }}>
                                    <Typography style={{
                                        fontSize: '14px',
                                        fontWeight: '600',
                                        color: !order?.crm_invoice_no ? '#454545' : invoiceDetail?.status === "2" ? '#1D8B45' : '#FF0303',
                                    }}>
                                        {
                                            !order?.crm_invoice_no ? intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.NOT_BILLED') : invoiceDetail?.status === "2" ? intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.PAID') : intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.UNPAID')
                                        }
                                    </Typography>
                                    <Typography style={{
                                        fontSize: '14px',
                                        color: '#B454545',
                                    }}>
                                        {invoiceDetail?.invoiceRefNumber}
                                    </Typography>
                                </Box>

                        }
                    </Box>
                </Grid>
                <Grid item md={2} xs={3}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%'
                    }}>
                        <Typography style={{
                            fontSize: '14px',
                            fontWeight: '600',
                            color: (+order?.shipping_status_id === 1 || +order?.shipping_status_id === 2) ? '#454545' : (+order?.shipping_status_id === 20) ? '#F2BE22' : '#1D8B45'
                        }}>
                            {movingShippingStatus(+order?.shipping_status_id)}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={1} xs={3}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%'
                    }}>
                        <Typography style={{fontSize: '14px'}}>
                            $ {(+order?.price)?.toFixed(2)}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={1} xs={2}>
                    <Box sx={{
                        position: 'relative',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column'
                    }}>
                        <IconButton
                            onClick={handleClick}
                        >
                            <MoreVertIcon/>
                        </IconButton>
                        <StyledMenu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem sx={{width: '100%', height: '100%'}}
                                      onClick={() => {

                                          handleClose();
                                      }}
                            >
                                <Link
                                    href={`https://storage.googleapis.com/${order?.label_pdf_uri}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    underline="none"
                                    style={{textDecoration: 'none'}}
                                >
                                    <Typography sx={{
                                        fontSize: '14px',
                                        textAlign: 'left',
                                        width: '100%',
                                        padding: '10px',
                                        color: '#000000'
                                    }}>
                                        Print Label
                                    </Typography>
                                </Link>
                            </MenuItem>
                            {
                                movingOrderToCancel(order?.status_id, order?.shipping_status_id, order?.order_sub_type) &&
                                <MenuItem sx={{width: '100%', height: '100%'}}
                                          onClick={() => {
                                              handleCancelOrder();
                                              handleClose();
                                          }}
                                >
                                    <Typography sx={{
                                        fontSize: '14px',
                                        textAlign: 'left',
                                        width: '100%',
                                        padding: '10px'
                                    }}>
                                        Cancel
                                    </Typography>
                                </MenuItem>
                            }
                            {
                                (ownerRole === 3 && invoiceDetail?.status === "1") &&
                                <MenuItem sx={{width: '100%', height: '100%'}}
                                          onClick={() => {
                                              handlePayInvoice();
                                              handleClose();
                                          }}
                                >
                                    <Typography sx={{
                                        fontSize: '14px',
                                        textAlign: 'left',
                                        width: '100%',
                                        padding: '10px'
                                    }}>
                                        Pay Invoice
                                    </Typography>
                                </MenuItem>
                            }
                        </StyledMenu>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}