import {
    LOADING_SHIP_RATE,
    RATING_ADD_ITEM,
    RATING_BOX_LIST_BOX_INFO, RATING_CLEAR_ADDRESS_FROM, RATING_CLEAR_ADDRESS_TO,
    RATING_CLEAR_ITEM,
    RATING_OPEN_ADD_ITEM_FORM,
    RATING_OPEN_BOX_LIST,
    RATING_OPEN_BOX_LIST_ITEM,
    RATING_OPEN_EDIT_ITEM_FORM,
    RATING_REMOVE_ITEM,
    RATING_SHIP_ADDRESS_ERRORS, RATING_SHIP_ADDRESS_FROM_DATA,
    RATING_SHIP_ADDRESS_LIST_FROM, RATING_SHIP_ADDRESS_LIST_TO, RATING_SHIP_ADDRESS_TO_DATA,
    RATING_SHIP_FROM_COUNTRY_AND_PROVINCE,
    RATING_SHIP_FROM_ERRORS,
    RATING_SHIP_RATE,
    RATING_SHIP_RATE_CLEAR,
    RATING_SHIP_TO_ERRORS,
    RATING_UPDATE_CROSS_BORDER,
    RATING_UPDATE_ITEM,
    RATING_UPDATE_SHIPPING_FROM,
    RATING_UPDATE_SHIPPING_TO
} from "./types";

export const openRatingAddItemForm = (status) => ({
    type: RATING_OPEN_ADD_ITEM_FORM,
    payload: status
})

export const openRatingEditItemForm = (status, itemId) => ({
    type: RATING_OPEN_EDIT_ITEM_FORM,
    payload: {status, itemId}
})

export const addItem = (item) => ({
    type: RATING_ADD_ITEM,
    payload: item
})

export const removeItem = (itemId) => ({
    type: RATING_REMOVE_ITEM,
    payload: itemId
})

export const updateItem = (item) => ({
    type: RATING_UPDATE_ITEM,
    payload: item
})

export const clearItem = () => ({
    type: RATING_CLEAR_ITEM
})

export const updateShipFrom = (shipFromAddress) => ({
    type: RATING_UPDATE_SHIPPING_FROM,
    payload: shipFromAddress
})

export const updateShipTo = (shipFromAddress) => ({
    type: RATING_UPDATE_SHIPPING_TO,
    payload: shipFromAddress
})

export const updateShipCrossBorder = (crossBorderInfo) => ({
    type: RATING_UPDATE_CROSS_BORDER,
    payload: crossBorderInfo
})

export const updateShipFromError = (errors) => ({
    type: RATING_SHIP_FROM_ERRORS,
    payload: errors
})

export const updateShipToError = (errors) => ({
    type: RATING_SHIP_TO_ERRORS,
    payload: errors
})

export const updateShipAddressError = (errors) => ({
    type: RATING_SHIP_ADDRESS_ERRORS,
    payload: errors
})

export const updateShipFromCountryAndProvince = (status) => ({
    type: RATING_SHIP_FROM_COUNTRY_AND_PROVINCE,
    payload: status
})

export const updateRates = (rateData) => ({
    type: RATING_SHIP_RATE,
    payload: rateData
})

export const clearRates = () => ({
    type: RATING_SHIP_RATE_CLEAR,
})

export const updateLoadingShipRate = (isLoading) => ({
    type: LOADING_SHIP_RATE,
    payload: isLoading
})

export const toggleBoxList = (isOpen) => ({
    type: RATING_OPEN_BOX_LIST,
    payload: isOpen
})

export const toggleBoxListItem = (isOpen) => ({
    type: RATING_OPEN_BOX_LIST_ITEM,
    payload: isOpen
})

export const addBoxListItem = (boxInfo) => ({
    type: RATING_BOX_LIST_BOX_INFO,
    payload: boxInfo
})

export const toggleShippingListFrom = (isOpen) => ({
    type: RATING_SHIP_ADDRESS_LIST_FROM,
    payload: isOpen
})

export const toggleShippingListTo = (isOpen) => ({
    type: RATING_SHIP_ADDRESS_LIST_TO,
    payload: isOpen
})

export const addShippingListFromAddress = (fromAddress) => ({
    type: RATING_SHIP_ADDRESS_FROM_DATA,
    payload: fromAddress
})

export const addShippingListToAddress = (toAddress) => ({
    type: RATING_SHIP_ADDRESS_TO_DATA,
    payload: toAddress
})

export const clearAddressFrom = () => ({
    type: RATING_CLEAR_ADDRESS_FROM
})

export const clearAddressTo = () => ({
    type: RATING_CLEAR_ADDRESS_TO
})