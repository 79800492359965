import {
    LANDING_PAGE_LATEST_ORDER_UPDATE_OPEN_INDEX,
    LANDING_PAGE_LATEST_ORDER_UPDATE_SHOW_DIALOG
} from "../actions/types";

const initialState = {
    latestOrderOpenIndex: null,
    isShowDialog: true

}

export const landingPageReducer = (state = initialState, action) => {
    switch (action.type) {
        case LANDING_PAGE_LATEST_ORDER_UPDATE_OPEN_INDEX:
            console.log('open index', action?.payload)
            return {...state, latestOrderOpenIndex: action?.payload}
        case LANDING_PAGE_LATEST_ORDER_UPDATE_SHOW_DIALOG:
            return {...state, isShowDialog: action?.payload}
        default:
            return state
    }
}