import {MY_BOXES_BOX_INFO, MY_BOXES_IS_ADD, MY_BOXES_IS_EDIT} from "./types";

export const toggleAddMyBoxes = (isAdd) => ({
    type: MY_BOXES_IS_ADD,
    payload: isAdd
})

export const toggleEditMyBoxes = (isEdit) => ({
    type: MY_BOXES_IS_EDIT,
    payload: isEdit
})

export const addBoxInfo = (boxInfo) => ({
    type: MY_BOXES_BOX_INFO,
    payload: boxInfo
})