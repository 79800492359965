import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import {Button, Divider, IconButton, Modal, Stack, Switch, useMediaQuery} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AddBoxIcon from "@material-ui/icons/AddBox";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {useEffect, useState} from "react";
import {
    addCreateLabelShippingPackagePackageList, initShippingPackageCreateLabelPackageList,
    updateCreateLabelShippingPackageMetricUnit
} from "../../actions/shippingPackageAction";
import {generateRandomString} from "../../Utils/Helper";
import {ShippingPackageCreateLabelPackageEach} from "./ShippingPackageCreateLabelPackageEach";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../Utils/doToken";
import BoxImage from "../../images/boxes.png";
import addPackage from "../../images/Create Label.png";
import {
    ReactComponent as PackageDetailsIcon
} from "../../images/NewDesignIcon/Package.svg";
import {ReactComponent as SchedulePickupIcon} from "../../images/NewDesignIcon/SchedulePickup.svg";
import * as React from "react";

const styles = {
    ShippingPackageCreateLabelPackageRoot: {
        backgroundColor: '#FFFFFF',
        // padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        // border: '1px solid #A9A9A9',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },

    ShippingPackageCreateLabelPackageHeading: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelPackageSection: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '20px 20px 0 20px',
        alignItems: 'center'
    },
    ShippingPackageCreateLabelPackageSubSection: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    ShippingPackageCreateLabelPackageModalBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 500,
        maxHeight: 400,
        backgroundColor: '#FFFFFF',
        border: '2px solid #000',
        borderRadius: '10px',
        boxShadow: 24,
        padding: '15px 15px',
        overflow: 'auto'
    },
    ShippingPackageCreateLabelPackageSelectBox: {
        "&:hover": {
            cursor: "pointer",
            background: "aliceblue"
        },
    },
    ShippingPackageCreateLabelPackageSelectCommonBox: {
        display: 'flex',
        justifyContent: 'flex-start',
        "&:hover": {
            cursor: "pointer",
            background: "aliceblue"
        },
    },
    ShippingPackageCreateLabelRateListContent: {
        backgroundColor: '#FFFFFF',
        padding: '0 40px',
        marginBottom: '40px',
        borderRadius: '10px',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    }
}

const AntSwitch = styled(Switch)(({theme}) => ({
    width: 30,
    height: 18,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 17,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#609966' : '#609966',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 14,
        height: 14,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

export const ShippingPackageCreateLabelPackage = ({packageListRedux, packageListHistory}) => {

    const accessToken = getAccessToken("access_token");
    const dispatch = useDispatch();
    const history = useHistory();

    let metric = useSelector(state => state?.shippingPackage?.createLabelMetricUnit);
    const id = useSelector((state) => state.user.id);
    const [packageList, setPackageList] = useState([]);
    const [myBoxesList, setMyBoxesList] = useState([]);
    const [commonBoxesList, setCommonBoxesList] = useState([]);
    const [updatedPackage, setUpdatedPackage] = useState();
    const [itemId, setItemId] = useState(1);
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleMetric = (event) => {
        dispatch(updateCreateLabelShippingPackageMetricUnit(event.target.checked));
    }

    const handleAddPackage = () => {

        const packageId = generateRandomString(8);
        const newPackage = {
            description: `item-${itemId}`,
            length: '10',
            width: '10',
            height: '10',
            weight: '10',
            lengthUnit: metric ? 'cm' : 'in',
            weightUnit: metric ? 'kg' : 'lb',
            UOM: '',
            hscode: '',
            originCountryCode: '',
            currency: '',
            priceEach: '',
            quantity: '1',
            packageInfoType: 'customBox',
            packageSpecialServices: {
                declaredValue: {
                    value: 0,
                    currency: "CAD"
                }
            },
            packageId: packageId
        }
        dispatch(addCreateLabelShippingPackagePackageList(newPackage));
        setItemId(prevState => prevState + 1);
    }

    const getMyBoxesListForEndUser = async () => {
        let requestURL = `${NODE_ROUTE_URI}/myBoxes/getMyBoxesListForEndUser`

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                params: {userId: id}
            })
            const {data} = result;
            console.log('data', data);
            setMyBoxesList(data);

        } catch (e) {
            console.log(e.response);
        }
    }

    const getCommonBoxesList = async () => {
        let requestURL = `${NODE_ROUTE_URI}/myBoxes/commonBoxes`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL
            })
            console.log('result', result);
            setCommonBoxesList(result?.data);
        } catch (e) {
            console.log(e.response);
        }
    }

    const handleUpdatePackage = (packageInfo) => {
        console.log('update package', packageInfo);
        setUpdatedPackage(packageInfo);
    }

    const handleSelectBox = (box) => {
        console.log('select box', box);
        setUpdatedPackage(prevState => ({
            ...prevState,
            description: box?.item_name,
            UOM: box?.uom,
            currency: box?.currency_code,
            height: box?.height,
            length: box?.length,
            width: box?.width,
            lengthUnit: box?.dimension_unit,
            originCountryCode: box?.origi_country,
            weight: box?.weight,
            weightUnit: box?.weight_unit,
            hscode: box?.hs_code,
            priceEach: box?.unit_price,
            packageInfoType: 'myBox'
        }));
        handleClose();
    }

    const handleAddBox = () => {
        history.push('/my-boxes')
    }

    useEffect(() => {
        id && getMyBoxesListForEndUser();
        getCommonBoxesList();
    }, [])

    // console.log('id', id);

    useEffect(() => {
        setPackageList(packageListRedux);
    }, [packageListRedux]);

    useEffect(() => {
        if (packageListHistory?.length > 0) {
            dispatch(initShippingPackageCreateLabelPackageList(packageListHistory));
        }
    }, [packageListHistory])

    return (
        <Box sx={styles.ShippingPackageCreateLabelPackageRoot}>
            <Box sx={styles.ShippingPackageCreateLabelPackageSection}>
                <Box sx={{
                    display: 'flex',
                    gap: '10px',
                }}>
                    <PackageDetailsIcon width={25} height={25}/>
                    <Typography style={styles.ShippingPackageCreateLabelPackageHeading}>
                        Package Details
                    </Typography>
                </Box>
                <Box sx={styles.ShippingPackageCreateLabelPackageSubSection}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <AntSwitch
                            checked={metric}
                            onChange={handleMetric}
                            inputProps={{'aria-label': 'ant design'}}
                        />
                        <Typography>
                            Metric (cm&kg)
                        </Typography>
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <IconButton
                            sx={{color: '#609966'}}
                            onClick={handleAddPackage}
                        >
                            <img src={addPackage} width='30px'/>
                        </IconButton>
                        <Typography>
                            New Package
                        </Typography>
                    </Stack>
                </Box>
            </Box>
            <Divider/>
            <Box sx={styles.ShippingPackageCreateLabelRateListContent}>
                <Grid container spacing={2}>
                    {
                        packageList?.length > 0 ? packageList?.map((eachPackage, index) =>
                            <Grid item xs={12} key={index}>
                                <ShippingPackageCreateLabelPackageEach
                                    packageInfo={eachPackage}
                                    cbHandleOpen={handleOpen}
                                    cbHandleUpdatePackage={handleUpdatePackage}
                                    updatedPackage={updatedPackage}
                                    index={index}
                                />
                            </Grid>
                        ) : <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: '1px solid #B7B7B7',
                                padding: '20px',
                                borderRadius: '10px'
                            }}>
                                <Typography style={{fontSize: '20px', fontWeight: '600', color: '#7F8487'}}>
                                    Your package list is empty
                                </Typography>
                                <IconButton
                                    sx={{color: '#1D8B45'}}
                                    onClick={handleAddPackage}
                                >
                                    {/*<AddBoxIcon/>*/}
                                    <img src={addPackage} width='30px'/>
                                </IconButton>
                                <Typography style={{fontSize: '12px', fontWeight: '600', color: '#1D8B45'}}>
                                    Add new package to shipment
                                </Typography>
                            </Box>
                        </Grid>
                    }
                    <Modal
                        open={open}
                        onClose={handleClose}
                    >
                        <Box sx={styles.ShippingPackageCreateLabelPackageModalBox}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography style={{fontSize: '20px'}}>
                                        From My Box
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        <Typography style={{fontSize: '12px', color: '#8294C4'}}>
                                            Choose from package templates
                                        </Typography>
                                        <Divider variant="middle" sx={{width: '30%'}}/>
                                        <Button
                                            color='success'
                                            onClick={handleAddBox}
                                        >
                                            add
                                        </Button>
                                        {/*{updatedPackage?.packageId}*/}
                                    </Box>
                                </Grid>
                                {
                                    myBoxesList?.length > 0 ? myBoxesList.map((box, index) =>
                                            (
                                                <Grid item xs={12} key={index} onClick={() => handleSelectBox(box)}>
                                                    <Box sx={styles.ShippingPackageCreateLabelPackageSelectBox}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={1}>
                                                                <Box sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    height: '100%'
                                                                }}>
                                                                    <img src={BoxImage} alt="box" width='30px'/>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={11}>
                                                                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                                                    <Typography>
                                                                        {box?.box_name}
                                                                    </Typography>
                                                                    <Typography>
                                                                        {box?.length} x {box?.width} x {box?.height} {box?.dimension_unit}, {box?.weight} {box?.weight_unit}
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Grid>

                                            ))
                                        : <Grid item xs={12}>
                                            <Typography>
                                                No Boxes
                                            </Typography>
                                        </Grid>
                                }
                                <Grid item xs={12}>
                                    <Typography style={{fontSize: '12px', color: '#1D8B45'}}>
                                        Carriers provided parcels
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>
                                {
                                    commonBoxesList?.length > 0 ? commonBoxesList.map((box, index) => (
                                        <Grid item xs={12} key={index} onClick={() => handleSelectBox(box)}>
                                            <Box sx={styles.ShippingPackageCreateLabelPackageSelectCommonBox}>
                                                <Typography>
                                                    {box?.box_name} ({box?.length} x {box?.width} x {box?.height} {box?.dimension_unit}, {box?.weight} {box?.weight_unit})
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    )) : <Grid item xs={12}>
                                        <Typography>
                                            No Boxes
                                        </Typography>
                                    </Grid>
                                }
                            </Grid>
                        </Box>
                    </Modal>
                </Grid>
            </Box>
        </Box>
    )
}