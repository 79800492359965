import React from "react";
import {useLocation, useHistory} from "react-router-dom";
import {Button, Container} from "@mui/material";
import Grid from "@mui/material/Grid";
import LandingImage from "../../images/CoverImage.png";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Box from "@mui/material/Box";
import {LandingMenuBar} from "../Landing/LandingMenuBar";
import {LandingIntro} from "../Landing/LandingIntro";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const styles = {
    LandingSignUpRoot: {
        display: 'flex',
        margin: '0 auto',
        '@media (max-width: 480px)': {
            flexDirection: "column", // for phone and above
        },
    },
    LandingIntroImage: {
        width: '95%',
        padding: '5%'
    },
}

const ReceiveEmailPage = () => {
    let query = useQuery();
    const history = useHistory();
    const isMobile = useMediaQuery('(max-width: 480px)');

    const handleClick = () => {
        history.push("/sign-in");
    };

    const handleResend = () => {

    }

    return (
        <Container maxWidth={false} style={{padding: '0px', marginTop: '-75px'}}>
            <Grid container spacing={0}>
                <Grid item xs={12} md={5}>
                    <Box sx={{
                        display: 'flex',
                        padding: '20px',
                        flexDirection: 'column',
                        height: isMobile ? '100vh' : '100%',
                        gap: isMobile ? '10vh' : '15vh'
                    }}>
                        <LandingMenuBar/>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            height: '100%'
                        }}>
                            <Grid container spacing={2}>
                                {
                                    query.get("status_code") === "200" ?
                                        <Grid item xs={12}>
                                            <Typography
                                                sx={{
                                                    fontSize: '20px',
                                                    fontWeight: '600',
                                                    textAlign: 'left',
                                                    WebkitTextStroke: '0.5px black', // For Webkit browsers
                                                }}
                                            >
                                                Password reset email sent <span><CheckCircleIcon
                                                sx={{color: '#1D8B45'}}/></span>
                                            </Typography>
                                            <Typography style={{textAlign: 'left', color: '#454545', fontSize: '14px'}}>
                                                We've emailed you next steps. Check your inbox and follow the
                                                instructions for resetting your password. If you do not receive an
                                                email, please try again or contact <span
                                                style={{textDecoration: 'underline'}}>op@uucargo.ca</span>.
                                                {/*<span*/}
                                                {/*    style={{*/}
                                                {/*        color: '#1D8B45', fontWeight: '600', cursor: 'pointer',*/}
                                                {/*    }} onClick={handleResend}>Resend</span>*/}
                                            </Typography>
                                        </Grid>
                                        :
                                        <Grid item xs={12}>
                                            <Grid item xs={12}>
                                                <Typography
                                                    style={{
                                                        fontSize: '20px',
                                                        fontWeight: '600',
                                                        textAlign: 'left',
                                                    }}
                                                >
                                                    Email address not exist
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                }
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        onClick={handleClick}
                                        size='small'
                                        sx={{
                                            backgroundColor: '#1D8B45',
                                            "&:hover": {
                                                backgroundColor: '#1D8B45',
                                                filter: 'brightness(0.9)'
                                            }
                                        }}
                                    >
                                        <Typography sx={{textTransform: 'none', fontSize: '14px'}}>
                                            Back To Login
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
                <LandingIntro/>
                {/*<Grid item xs={12} md={7}>*/}
                {/*    <Box sx={{*/}
                {/*        display: 'flex',*/}
                {/*        backgroundColor: '#1D8B45',*/}
                {/*        minHeight: '100vh',*/}
                {/*        height: '100%',*/}
                {/*        width: '100%',*/}
                {/*        justifyContent: 'center',*/}
                {/*        alignItems: 'flex-start'*/}
                {/*    }}>*/}
                {/*        <img src={LandingImage} alt="landing image" style={styles.LandingIntroImage}/>*/}
                {/*    </Box>*/}
                {/*</Grid>*/}
            </Grid>
        </Container>
    );
};
export default ReceiveEmailPage;
