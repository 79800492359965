import {useEffect, useState} from "react";
import {getAccessToken} from "../../../Utils/doToken";
import axios from "axios";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Divider, Link} from "@mui/material";

const style = {
    PackageOrderDashboardAdjustmentOrderRoot: {
        border: '1px solid #B2B2B2',
        borderRadius: '10px',
        padding: '10px 0',
    }
}

export const PackageOrderDashboardAdjustOrder = ({orderId}) => {

    const [trackingList, setTrackingList] = useState([]);

    // todo adjust order api
    const getAdjustmentOrder = async () => {
        const storedToken = getAccessToken("access_token");

        // const requestURL = `${PARTNER_URI}/invoices/getAdjustOrdersByShippingOrderId`;
        try {
            const result = await axios({
                method: "get",
                // url: requestURL,
                params: {
                    orderId: orderId,
                },
                headers: {
                    authorization: `Bearer ${storedToken}`,
                },
            });

            const {data} = result;
            console.log('data', data);
            const updatedTrackingList = [];
            if (data[0]) {
                data?.forEach(element => {
                    let object = {
                        trackingNumber: element?.tracking_no,
                        weight: element?.weight,
                        weightUnit: element?.weight_unit,
                        length: element?.length,
                        height: element?.height,
                        width: element?.width,
                        dimensionUnit: element?.dimension_unit,
                        adjustmentAmount: element?.shipping_fee_include_tax,
                        adjustmentOrder: element?.order_id
                    }
                    updatedTrackingList.push(object);
                })
            }
            setTrackingList(updatedTrackingList);

        } catch (e) {
            console.log('error', e.response);
        }
    }

    useEffect(() => {
        getAdjustmentOrder();
    }, [])

    return (
        <Box sx={style.PackageOrderDashboardAdjustmentOrderRoot}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '10px',
                        borderBottom: '1px solid #B2B2B2'
                    }}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            Adjustment Orders
                        </Typography>
                    </Box>
                </Grid>
                {
                    trackingList?.length > 0 ? trackingList?.map((element, index) => (
                            <Grid item xs={12} key={index}>
                                <Box sx={{padding: '5px 10px'}}>
                                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                        Adjustment Order: <Link
                                        href={`/adjustOrderDashboard/${element.adjustmentOrder}`}
                                        underline="hover"
                                        sx={{
                                            color: '#1D8B45',
                                        }}>{element?.adjustmentOrder}
                                    </Link>
                                    </Typography>
                                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                        Adjustment Amount: $ {element?.adjustmentAmount}
                                    </Typography>
                                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                        Tracking No: {element?.trackingNumber}
                                    </Typography>
                                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                        Package
                                        Dimension: {element?.length} x {element?.width} x {element?.height} {element?.dimensionUnit}
                                    </Typography>
                                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                        Package Weight: {element?.weight} {element?.weightUnit}
                                    </Typography>
                                    <Divider/>
                                </Box>
                            </Grid>
                        )) :
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                height: '100%',
                                alignItems: 'center',
                                justifyContent: 'center',
                                minHeight: '150px',
                                padding: '10px',
                                flexDirection: 'column',
                                gap: '20px'
                            }}>
                                <Typography style={{fontSize: '14px', textAlign: 'center'}}>
                                    No adjustment charges happened in this order
                                </Typography>
                            </Box>
                        </Grid>
                }
            </Grid>
        </Box>
    )
}