import {Container} from "@mui/material";
import Typography from "@mui/material/Typography";

export const ComingSoonPage = () => {
    return (
        <Container maxWidth='xl'>
            <Typography sx={{fontSize: '24px', fontWeight: '600'}}>
                Coming Soon
            </Typography>
            <Typography>
                We apologize for the inconvenience, but the page you are attempting to access is currently under
                development and will be available soon.
            </Typography>
        </Container>
    )
}