import React, {useState} from "react";
import RoomIcon from "@material-ui/icons/Room";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    markerIcon: {
        color: "orange",
        transform: "scale(1.5) translateY(-20px)",
        "&:hover": {
            color: "orangered",
        },
        cursor: "pointer",
    },
    markerWrapper: {
        position: "relative",
    },
    infoWrapper: {
        top: "-63px",
        left: "-100px",
        width: "240px",
        position: "absolute",
        border: "1px solid #6c757d4a",
        borderRadius: "3px",
        padding: "3px",
        boxShadow: "rgb(0 0 0 / 30%) 0px 0px 12px",
        fontSize: "1rem",
        zIndex: "1001",
        background: "rgb(197 232 197)",
        color: "rgb(143 147 151)",
    },
}));

const MapMarker = ({info, title, lat, lng, handleMarkerClick}) => {
    const classes = useStyles();
    const [showInfo, setShowInfo] = useState(false);
    const handelMouseEnter = (_tNum) => {
        setShowInfo(true);
    };

    const handleMouseLeave = (_tNum) => {
        setShowInfo(false);
    };

    return (
        <div className={classes.markerWrapper}>
            {title}
            <RoomIcon
                className={classes.markerIcon}
                id={`markerIcon-${info.shipment_task_number}`}
                onClick={handleMarkerClick}
                onMouseEnter={handelMouseEnter}
                onMouseLeave={handleMouseLeave}
            />
            {showInfo && (
                <div className={classes.infoWrapper}>
                    <span>{info.nearTaskCount} shipments tasks near by</span>
                </div>
            )}
        </div>
    );
};

export default MapMarker;
