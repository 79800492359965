import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Button, IconButton, Modal} from "@mui/material";
import {useEffect, useState} from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import UploadIcon from '@mui/icons-material/Upload';
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import Grid from "@mui/material/Grid";
import {useDispatch, useSelector} from "react-redux";
import {updateMovingServiceImage} from "../../actions/movingServiceAction";
import * as React from "react";
import ClearIcon from '@mui/icons-material/Clear';
import FormHelperText from "@mui/material/FormHelperText";
import intl from "react-intl-universal";

const styles = {
    MovingServiceImageRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '10px',
        display: 'flex',
        flexDirection: 'column',
    },

    MovingServiceImageHeading: {
        fontSize: '20px',
        fontWeight: '600'
    },
    MovingServiceImageModalBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        backgroundColor: '#FFFFFF',
    },
    MovingServiceImageBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflowY: 'auto',
    },
    MovingServiceImageItem: {
        maxWidth: '100%',
        maxHeight: '400px'
    },
    MovingServiceImageUploadSection: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '10px'
    },
    MovingServiceImageWarning: {
        color: 'red',
        fontSize: '12px'
    },
    MovingServiceImageEach: {
        objectFit: 'contain',
        width: '200px',
        height: '200px',
    },
    MovingServiceImageList: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        gap: '15px',
        width: '100%'
    },
    MovingServiceImageCard: {
        borderRadius: '10px',
        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.5)',
        padding: '10px',
        "&:hover": {
            cursor: 'pointer',
            transform: 'scale(1.05)',
            transition: '0.5s ease-in-out'
        },
    },
    MovingServiceImageSection: {
        display: 'flex',
        flexDirection: 'column',
        width: '220px',
        position: 'relative'
    },
    MovingServiceImageDeleteImage: {
        position: 'absolute',
        right: '0',
        top: '0'
    }
}

export const MovingServiceImage = () => {

    let imageError = useSelector(state => state?.movingService?.movingImageError);

    let movingImage = useSelector(state => state?.movingService?.movingImage);

    const [selectedFiles, setSelectedFiles] = useState([]);

    const [exceedLimit, setExceedLimit] = useState(false);

    const [previewImageURL, setPreviewImageURL] = useState('');

    const [openModal, setOpenModal] = useState(false);

    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const handleRemoveImage = (itemToRemove) => {
        const updatedItems = selectedFiles.filter((item) => item !== itemToRemove);
        setSelectedFiles(updatedItems);
    }

    const uploadImageToServer = async (uploadImages) => {
        if (!uploadImages) {
            setLoading(false);
            return;
        }

        let requestURL = `${NODE_ROUTE_URI}/movingService/uploadImage`;
        const data = new FormData();

        uploadImages.forEach(image => {
            data.append('images', image);
        })

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    "Content-Type": "multipart/form-data;",
                },
                data: data
            })

            setSelectedFiles((prevFiles) => [...prevFiles, ...result.data.urls]);
            setLoading(false);
        } catch (e) {
            console.log(e.response);
            setLoading(false);
        }
    }

    const handleFileChange = (event) => {
        setLoading(true);
        const files = Array.from(event.target.files);
        const allowedFormats = ['image/jpeg', 'image/png'];

        // Filter out files that are not in JPG or PNG format
        const selectedImages = files.filter((file) =>
            allowedFormats.includes(file.type)
        );

        const UploadImageNum = selectedImages.length + selectedFiles.length;

        if (UploadImageNum > 5) {
            setExceedLimit(true);
            setLoading(false);
        } else {
            setExceedLimit(false);
            uploadImageToServer(selectedImages);
        }
    };

    useEffect(() => {
        setSelectedFiles(movingImage);
    }, [])

    useEffect(() => {
        dispatch(updateMovingServiceImage(selectedFiles));
    }, [selectedFiles])

    console.log(selectedFiles);

    // console.log('preview', previewImageURL);

    return (
        <Box sx={styles.MovingServiceImageRoot}>
            <Typography sx={styles.MovingServiceImageHeading}>
                {intl.get('IMAGE.MOVING_IMAGE')}
            </Typography>
            <Box sx={styles.MovingServiceImageUploadSection}>
                <Typography>
                    {intl.get('IMAGE.MOVING_IMAGE_TEXT')}
                </Typography>
                <input
                    accept="image/jpeg, image/png"
                    style={{display: 'none'}}
                    id="moving-image-upload"
                    multiple
                    type="file"
                    onChange={handleFileChange}
                    disabled={selectedFiles.length < 5 ? false : true}
                />
                <label htmlFor="moving-image-upload">
                    <LoadingButton
                        variant="contained"
                        sx={{
                            backgroundColor: '#1D8B45',
                            "&:hover": {
                                backgroundColor: '#1D8B45',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        component="span"
                        endIcon={<UploadIcon/>}
                        disabled={selectedFiles.length < 5 ? false : true}
                        loading={loading}
                        size='small'
                    >
                        <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                            {intl.get('BUTTON.UPLOAD_IMAGES')}
                        </Typography>
                    </LoadingButton>
                </label>
                <FormHelperText
                    sx={{
                        color: "error.main",
                    }}
                >
                    {exceedLimit && 'Maximum upload limit exceeded. You can only upload up to five images.'}
                </FormHelperText>
                <FormHelperText
                    sx={{
                        color: "error.main",
                    }}
                >
                    {imageError === null ? "" : imageError && 'This is required, and please upload at least one moving image.'}
                </FormHelperText>
                <Box sx={styles.MovingServiceImageList}>
                    {
                        selectedFiles.length > 0 && selectedFiles.map((image, index) => {
                            return (
                                <Box sx={styles.MovingServiceImageSection}>
                                    <Box sx={styles.MovingServiceImageCard}>
                                        <img src={`https://storage.googleapis.com/${image}`}
                                             style={styles.MovingServiceImageEach}
                                             onClick={() => {
                                                 handleOpenModal();
                                                 setPreviewImageURL(image);
                                             }}
                                        />
                                    </Box>
                                    <Typography style={{fontWeight: '600'}}>
                                        {image.split('_')[1]}
                                    </Typography>
                                    <IconButton
                                        sx={styles.MovingServiceImageDeleteImage}
                                        onClick={() => {
                                            handleRemoveImage(image);
                                        }}
                                    >
                                        <ClearIcon/>
                                    </IconButton>
                                </Box>
                            )
                        })
                    }
                </Box>
                <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                >
                    <Box sx={styles.MovingServiceImageModalBox}>
                        <Box sx={styles.MovingServiceImageBox}>
                            <img
                                src={`https://storage.googleapis.com/${previewImageURL}`}
                                style={styles.MovingServiceImageItem}
                            />
                        </Box>
                    </Box>
                </Modal>
            </Box>
        </Box>
    )
}