import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import {Divider, Link, Tooltip, tooltipClasses, useMediaQuery} from "@mui/material";
import dayjs from "dayjs";
import {getAccessToken} from "../../../Utils/doToken";
import axios from "axios";
import {LABEL_DOWNLOAD_URL_PREFIX, PARTNER_URI} from "../../../Utils/apiUrl";
import {useEffect, useState, Fragment} from "react";
import Button from "@mui/material/Button";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import TrackPackageItem from "../../Tracking/Business/TrackPackageItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import {generateRandomString, mapCarrierLogo} from "../../../Utils/Helper";
import {useHistory} from "react-router-dom";
import intl from "react-intl-universal";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import IconButton from "@mui/material/IconButton";
import {styled} from "@mui/material/styles";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import {ReactComponent as ReturnLabelIcon} from "../../../images/icons/returnLabel.svg";
import {ReactComponent as PrintLabelIcon} from "../../../images/icons/printLabel.svg";
import {ReactComponent as TrackLabelIcon} from "../../../images/icons/trackLabel.svg";
import {ReactComponent as CloneLabelIcon} from "../../../images/icons/cloneLabel.svg";
import {
    initShippingPackageCreateLabelPackageList,
    initShippingPackageCrossBorderItemList,
    updateShipFromPackageCreateLabelPackage,
    updateShippingPackageCreateLabelBatteryDetails,
    updateShippingPackageCreateLabelSignatureOptions, updateShippingPackageReturnLabelStatus,
    updateShipToPackageCreateLabelPackage
} from "../../../actions/BusinessShippingPackageAction";
import {useDispatch} from "react-redux";

const style = {
    PackageOrderDashboardTransactionDetailsRoot: {
        border: '1px solid #B2B2B2',
        borderRadius: '10px',
        padding: '10px 0',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    }
}

const GreenTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#1D8B45',
        color: '#FFFFFF',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

export const BusinessPackageOrderDashboardTransactionDetails = ({orderId, selectTransactionData}) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery('(max-width: 480px)');

    const [cancelLoading, setCancelLoading] = useState(false);
    const [trackingPackageDetailList, setTrackingPackageDetailList] = useState([]);
    const [trackedPackagesList, setTrackedPackagesList] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [toastOpen, setToastOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [trackingInfoDialogIsOpen, setTrackingInfoDialogIsOpen] = useState(false);
    const [cancelTrackingNumberErrorMessage, setCancelTrackingNumberErrorMessage,] = useState("");
    const [isCancelled, setIsCancelled] = useState(false);
    const [declaredValue, setDeclaredValue] = useState(0);
    const [batteryDetails, setBatteryDetails] = useState({});
    const [signatureOptions, setSignatureOptions] = useState(null);

    const handleToastClick = () => {
        setToastOpen(true);
    };

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const getOrderData = async () => {
        const storedToken = getAccessToken("access_token");
        try {

            const requestURI = `${PARTNER_URI}/shipping/list/getCloneShipmentInfoByTranId`;
            const result = await axios({
                method: 'get',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    tranId: selectTransactionData.tran_id
                }
            })

            return result?.data;

        } catch (e) {
            console.log('[BusinessReturnLabel] getOrderData api error', e?.response);
        }
    }

    const handleReturnOrCloneLabel = async (isReturn) => {
        const orderData = await getOrderData();
        console.log('[handleCreateCloneLabel] order data', orderData);
        const packageList = orderData?.packageData?.map(element => {
            const updatedElement = {
                ...element,
                packageInfoType: "customBox",
                packageId: generateRandomString(8)
            };
            return updatedElement;
        })
        const shipFrom = {
            postalCode: orderData?.shipFromPostalCode,
            province: {
                code: orderData?.shipFromProvince,
                name: orderData?.shipFromProvince
            },
            city: orderData?.shipFromCity,
            address: orderData?.shipFromAddress,
            address2: null,
            country: orderData?.shipFromCountry,
            displayAddress: {
                description: "",
                placeId: "",
                text: orderData?.shipFromAddress
            },
            name: orderData?.shipFromName,
            phone: orderData?.shipFromPhone,
            email: orderData?.shipFromEmail,
            company: "",
            saveAddress: false,
            addressList: {
                name: orderData?.shipFromName,
                phone: orderData?.shipFromPhone,
                email: orderData?.shipFromEmail,
                address_email: orderData?.shipFromEmail,
                company_name: "",
                city: null,
                province: null,
                country: null
            }
        };
        const shipTo = {
            postalCode: orderData?.shipToPostalCode,
            province: {
                code: orderData?.shipToProvince,
                name: orderData?.shipToProvince
            },
            city: orderData?.shipToCity,
            address: orderData?.shipToAddress,
            address2: null,
            country: orderData?.shipToCountry,
            displayAddress: {
                description: "",
                placeId: "",
                text: orderData?.shipToAddress
            },
            name: orderData?.shipToName,
            phone: orderData?.shipToPhone,
            email: orderData?.shipToEmail,
            company: "",
            saveAddress: false,
            addressList: {
                name: orderData?.shipToName,
                phone: orderData?.shipToPhone,
                email: orderData?.shipToEmail,
                address_email: orderData?.shipToEmail,
                company_name: "",
                city: null,
                province: null,
                country: null
            }
        };
        if (orderData?.packingList?.length > 0) {
            const itemList = orderData?.packingList?.map(item => ({
                description: item?.item_desc,
                sku: item?.item_sku,
                weight: item?.weight,
                weightUnit: item?.weight_unit,
                UOM: item?.uom?.toUpperCase(),
                hscode: item?.hs_code,
                originCountryCode: item?.origi_country,
                currency: item?.currency_code,
                priceEach: item?.unit_price,
                quantity: item?.quantity,
                itemId: generateRandomString(8)
            }));
            dispatch(initShippingPackageCrossBorderItemList(itemList));
        }
        dispatch(initShippingPackageCreateLabelPackageList(packageList));
        dispatch(updateShipFromPackageCreateLabelPackage(shipFrom));
        dispatch(updateShipToPackageCreateLabelPackage(shipTo));
        dispatch(updateShippingPackageCreateLabelSignatureOptions(orderData?.packageData?.[0]?.packageSpecialServices?.signatureRequired));
        dispatch(updateShippingPackageCreateLabelBatteryDetails(orderData?.packageData?.[0]?.packageSpecialServices?.batteryDetails));
        dispatch(updateShippingPackageReturnLabelStatus(isReturn));
        history.push('/business/shipping-package/create-label');
        window.scrollTo(0, 0); // Scroll to the top
    }

    const handleCreateReturnLabel = async () => {
        await handleReturnOrCloneLabel(true);
    };

    const handleCreateCloneLabel = async () => {
        await handleReturnOrCloneLabel(false);
    };

    //handle tracking information dialog open and close
    const handleTrackingInfo = () => {
        setTrackingInfoDialogIsOpen(!trackingInfoDialogIsOpen);
    };

    const handleTrackingInfoClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            setTrackingInfoDialogIsOpen(!trackingInfoDialogIsOpen);
        }
    }

    const cancelOrderByTransactionId = async () => {
        try {
            setCancelLoading(true);
            const storedToken = getAccessToken("access_token");
            const {data} = await axios.delete(
                `${PARTNER_URI}/loose-item/3rd-party/internal/cancel-shipping`,
                {
                    headers: {Authorization: `Bearer ${storedToken}`},
                    data: {
                        trackingNumber: selectTransactionData?.shipping_id_no,
                    },
                }
            );
            console.log(data);
            setCancelTrackingNumberErrorMessage("");
            setIsCancelled(true);
            setTimeout(() => {
                history.push(`/home`);
            }, 2000);
        } catch (e) {
            console.log(e);
            setCancelTrackingNumberErrorMessage(
                e?.response?.data?.errorMessage || "Error!"
            );
        } finally {
            setCancelLoading(false);
        }
    };

    const confirmCancelOrder = async (e) => {
        e.preventDefault();
        await cancelOrderByTransactionId();
        handleToastClick();
        handleDialogClose();
    };

    // Cancel Tracking Order
    const onCancelOrder = () => {
        handleDialogOpen();
    };

    console.log('transaction details', selectTransactionData);

    const getLabelURL = (pdf, zip) => {
        if (pdf) {
            return pdf;
        }
        if (zip) {
            return zip;
        }
        return "";
    };

    const getTrackedPackageList = async () => {
        try {
            if (selectTransactionData?.tran_tracking_nos?.trim() === "") {
                return;
            }

            const storedToken = getAccessToken("access_token");
            const {data, status} = await axios({
                method: "get",
                url: `${PARTNER_URI}/loose-item/3rd-party/multipleTrackingForWeb?trackingNumbers=${selectTransactionData?.tran_tracking_nos}`,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
            });
            const trackingList = data?.filter((v) => v?.trackingResponse !== null);

            if (status === 200) {
                console.log("Success:", data);
                setTrackedPackagesList(trackingList);
            } else {
                console.log("Error:", data);
                throw new Error();
            }
        } catch (e) {
            setErrorMessage("Failed to Schedule Pickup. Try again another time.");
        }
    };

    const getTrackingPackageDetailList = async () => {
        try {
            const storedToken = getAccessToken("access_token");
            const {data} = await axios({
                method: "GET",
                url: `${PARTNER_URI}/shipping/list/getValidTrackingListByTranId/${selectTransactionData?.tran_id}`,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
            });
            console.log('getTrackingPackageDetailList', data);
            setTrackingPackageDetailList(data);
            const totalDeclaredValue = data?.reduce((accumulator, currentValue) => {
                return accumulator + currentValue?.declared_value
            }, 0);
            setDeclaredValue(totalDeclaredValue);
            setBatteryDetails(prevState => ({
                ...prevState,
                material: data[0]?.battery_material,
                packing: data[0]?.battery_packing
            }))
            setSignatureOptions(data[0]?.signature_option) // todo confirm with api result
        } catch (e) {
            console.log(e);
        }
    }

    const totalOtherCharge = () => {
        let totalShippingCharge = +selectTransactionData?.freight_charge +
            +selectTransactionData?.fuel_surcharge +
            +selectTransactionData?.handling_charge +
            +selectTransactionData?.insurance_charge +
            +selectTransactionData?.over_size_charge +
            +selectTransactionData?.over_weight_charge +
            +selectTransactionData?.residential_charge +
            +selectTransactionData?.rural_charge +
            +selectTransactionData?.signature_charge +
            +selectTransactionData?.sur_charge
        totalShippingCharge = totalShippingCharge.toFixed(2);
        let otherCharge = selectTransactionData?.total_shipping_charge - +totalShippingCharge;
        // otherCharge = otherCharge?.toFixed(2);
        return otherCharge
    }

    useEffect(() => {
        if (selectTransactionData) {
            getTrackedPackageList();
            getTrackingPackageDetailList();
        }
    }, [selectTransactionData])

    // console.log('battery details', batteryDetails);
    console.log('[BusinessPackageOrderDashboardTransactionDetails] select transaction data', selectTransactionData);
    // console.log('[BusinessPackageOrderDashboardTransactionDetails] package List', trackingPackageDetailList);

    return (
        <Box sx={style.PackageOrderDashboardTransactionDetailsRoot}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={3000}
                message="Cancel Order"
            >
                {(() => {
                    if (cancelTrackingNumberErrorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Transaction can't be cancelled!
                                <hr/>
                                Error: {cancelTrackingNumberErrorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            Transaction has been cancelled!
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {intl.get('ORDER_DASHBOARD.CANCEL_ORDER_DIALOG.CANCEL_TRACKING_ORDER')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {intl.getHTML('ORDER_DASHBOARD.CANCEL_ORDER_DIALOG.CANCEL_ORDER_TEXT', {trackingNos: selectTransactionData?.tran_tracking_nos})}
                        <hr/>
                        {intl.getHTML('ORDER_DASHBOARD.CANCEL_ORDER_DIALOG.CONFIRM_TEXT')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleDialogClose}
                        variant="outlined"
                        autoFocus
                        type="button"
                    >
                        {intl.get('ORDER_DASHBOARD.CANCEL_ORDER_DIALOG.NO')}
                    </Button>
                    <Button
                        onClick={confirmCancelOrder}
                        variant="outlined"
                        type="button"
                        disabled={cancelLoading}
                    >
                        {cancelLoading && <CircularProgress size={14}/>}
                        {!cancelLoading && intl.get('ORDER_DASHBOARD.CANCEL_ORDER_DIALOG.CONFIRM')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={trackingInfoDialogIsOpen}
                onClose={handleTrackingInfoClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {intl.get('ORDER_DASHBOARD.TRACKING_DETAILS')}
                </DialogTitle>
                <DialogContent>
                    {trackedPackagesList.length === 0 ? <Box>No Trackng Information Found</Box> :
                        trackedPackagesList.map((trackedPackage, index) => {
                            return (
                                <TrackPackageItem
                                    key={index}
                                    activity={
                                        trackedPackage.trackingResponse
                                            ? trackedPackage.trackingResponse.activity
                                            : undefined
                                    }
                                    trackingNo={trackedPackage.trackingNumber}
                                    courier={trackedPackage.accountBase}
                                    deliverStatus={trackedPackage.trackingResponse.status}
                                    transaction={trackedPackage.transaction}
                                />
                            );
                        })}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleTrackingInfo}
                        variant="outlined"
                        autoFocus
                        type="button"
                    >
                        {intl.get('ORDER_DASHBOARD.CLOSE')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '20px',
                        borderBottom: '1px solid #B2B2B2',
                        alignItems: 'center'
                    }}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            {intl.get('ORDER_DASHBOARD.SHIPPING_TRANSACTIONS')} <span
                            style={{color: '#1D8B45'}}> {intl.get('ORDER_DASHBOARD.ID')}: {selectTransactionData?.tran_id}</span>
                        </Typography>
                        {
                            declaredValue > 0 && <GreenTooltip
                                placement="right-start"
                                title='Carrier Insurance Purchased'
                            >
                                <IconButton>
                                    <VerifiedUserIcon sx={{color: '#1D8B45'}}/>
                                </IconButton>
                            </GreenTooltip>
                        }
                        {
                            +selectTransactionData?.is_return_label === 1 && <Box sx={{display: 'flex'}}>
                                <RotateLeftIcon sx={{color: '#1D8B45'}}/>
                                <Typography sx={{color: '#1D8B45'}}>Return Label</Typography>
                            </Box>
                        }
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{padding: '20px'}}>
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    height: '100%',
                                    gap: '5px',
                                    flexDirection: 'column'
                                }}>
                                    <Typography><span
                                        style={{fontWeight: '600'}}>{intl.get('ORDER_DASHBOARD.PROCEED_DATE')}</span>
                                    </Typography>
                                    <Typography>
                                        {dayjs(selectTransactionData?.created_at)?.format('YYYY-MM-DD')}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    height: '100%',
                                    gap: '5px'
                                }}>
                                    {/*<Typography><span*/}
                                    {/*    style={{fontWeight: '600'}}>Carrier: </span>*/}
                                    {/*</Typography>*/}
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%'
                                    }}>
                                        <img src={mapCarrierLogo(selectTransactionData?.account_base?.toUpperCase())}
                                             alt="logo"
                                             width='40px'/>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                            {intl.get('ORDER_DASHBOARD.SHIPPING_FROM')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                            {selectTransactionData?.send_from_name}
                                        </Typography>
                                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                            {selectTransactionData?.send_from_addr_line2 && `${selectTransactionData?.send_from_addr_line2} - `}{selectTransactionData?.send_from_addr}, {selectTransactionData?.send_from_city}, {selectTransactionData?.send_from_province}, {selectTransactionData?.send_from_postal_code}, {selectTransactionData?.send_from_country}
                                        </Typography>
                                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                            {selectTransactionData?.send_from_tel}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                            {intl.get('ORDER_DASHBOARD.SHIPPING_TO')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                            {selectTransactionData?.send_to_name}
                                        </Typography>
                                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                            {selectTransactionData?.send_to_addr_line2 && `${selectTransactionData?.send_to_addr_line2} - `}{selectTransactionData?.send_to_addr}, {selectTransactionData?.send_to_city}, {selectTransactionData?.send_to_province}, {selectTransactionData?.send_to_postal_code}, {selectTransactionData?.send_to_country}
                                        </Typography>
                                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                            {selectTransactionData?.send_to_tel}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                            {intl.get('ORDER_DASHBOARD.PACKAGING_DETAILS')}
                                        </Typography>
                                    </Grid>
                                    {!isMobile &&
                                        <>
                                            <Grid item xs={2}>
                                                <Typography style={{textAlign: 'left', fontSize: '12px'}}>
                                                    {intl.get('ORDER_DASHBOARD.NAME')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography style={{textAlign: 'left', fontSize: '12px'}}>
                                                    {intl.get('ORDER_DASHBOARD.TRACKING_NO')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{textAlign: 'left', fontSize: '12px'}}>
                                                    {intl.get('ORDER_DASHBOARD.DIMENSION')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography style={{textAlign: 'left', fontSize: '12px'}}>
                                                    {intl.get('ORDER_DASHBOARD.WEIGHT')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{textAlign: 'left', fontSize: '12px'}}>
                                                    {intl.get('ORDER_DASHBOARD.DECLARE_VALUE')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{textAlign: 'left', fontSize: '12px'}}>
                                                    {intl.get('ORDER_DASHBOARD.STATUS')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                        </>
                                    }
                                    {
                                        trackingPackageDetailList?.map((element, index) => (
                                            <Fragment key={index}>
                                                <Grid item md={2} xs={12}>
                                                    <Typography style={{textAlign: 'left', fontSize: '12px'}}>
                                                        {isMobile && `${intl.get('ORDER_DASHBOARD.NAME')}: `}{element?.item_name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={3} xs={12}>
                                                    <Typography style={{textAlign: 'left', fontSize: '12px'}}>
                                                        {isMobile && `${intl.get('ORDER_DASHBOARD.TRACKING_NO')}: `}<Link
                                                        href={element?.label_uri ? `${LABEL_DOWNLOAD_URL_PREFIX}${element?.label_uri}` : '#'}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        underline="hover"
                                                        sx={{
                                                            color: '#1D8B45',
                                                        }}
                                                    >{element?.tracking_no}</Link>
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={2} xs={12}>
                                                    <Typography style={{textAlign: 'left', fontSize: '12px'}}>
                                                        {isMobile && `${intl.get('ORDER_DASHBOARD.DIMENSION')}: `}{element?.length}x{element?.width}x{element?.height} {element?.dimension_unit}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={1} xs={12}>
                                                    <Typography style={{textAlign: 'left', fontSize: '12px'}}>
                                                        {isMobile && `${intl.get('ORDER_DASHBOARD.WEIGHT')}: `}{element?.weight} {element?.weight_unit}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={2} xs={12}>
                                                    <Typography style={{textAlign: 'left', fontSize: '12px'}}>
                                                        {isMobile && `${intl.get('ORDER_DASHBOARD.DECLARE_VALUE')}: `}$ {element?.declared_value}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={2} xs={12}>
                                                    <Typography style={{textAlign: 'left', fontSize: '12px'}}>
                                                        {isMobile && `${intl.get('ORDER_DASHBOARD.STATUS')}: `}
                                                        {
                                                            (() => {
                                                                if (+element.tn_status === 1 || +element.tn_status === 0) {
                                                                    return intl.get('ORDER_DASHBOARD.ON_THE_WAY');
                                                                }
                                                                if (+element.tn_status === 2) {
                                                                    return intl.get('ORDER_DASHBOARD.DELIVERED');
                                                                }
                                                                return intl.get('ORDER_DASHBOARD.CANCELLED');
                                                            })()
                                                        }
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider/>
                                                </Grid>
                                            </Fragment>
                                        ))

                                    }
                                </Grid>
                            </Grid>
                            {
                                (declaredValue > 0 || batteryDetails?.material || signatureOptions) &&
                                <Grid item md={12} xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                                {intl.get('ORDER_DASHBOARD.SHIPPING_OPTIONS')}
                                            </Typography>
                                        </Grid>
                                        {
                                            declaredValue > 0 &&
                                            <Grid item xs={6}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            style={{
                                                                fontWeight: '600',
                                                                textAlign: 'left',
                                                                fontSize: '14px'
                                                            }}>
                                                            {intl.get('ORDER_DASHBOARD.INSURANCE')}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={0}>
                                                            <Grid item xs={6}>
                                                                <Typography
                                                                    style={{textAlign: 'left', fontSize: '12px'}}>
                                                                    {intl.get('ORDER_DASHBOARD.DECLARE_VALUE')}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Typography
                                                                    style={{textAlign: 'left', fontSize: '12px'}}>
                                                                    $ {declaredValue.toFixed(2)}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Typography
                                                                    style={{textAlign: 'left', fontSize: '12px'}}>
                                                                    {intl.get('ORDER_DASHBOARD.INSURANCE_CHARGE')}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Typography
                                                                    style={{textAlign: 'left', fontSize: '12px'}}>
                                                                    $ {(+selectTransactionData?.insurance_charge).toFixed(2)}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                        {
                                            signatureOptions &&
                                            <Grid item xs={6}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            style={{
                                                                fontWeight: '600',
                                                                textAlign: 'left',
                                                                fontSize: '14px'
                                                            }}>
                                                            {intl.get('ORDER_DASHBOARD.SIGNATURE_OPTION')}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            style={{textAlign: 'left', fontSize: '12px'}}>
                                                            {intl.get('ORDER_DASHBOARD.SIGNATURE_REQUIRED')}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                        {
                                            batteryDetails?.material &&
                                            <Grid item xs={6}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            style={{
                                                                fontWeight: '600',
                                                                textAlign: 'left',
                                                                fontSize: '14px'
                                                            }}>
                                                            {intl.get('ORDER_DASHBOARD.BATTERY')}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={0}>
                                                            <Grid item xs={6}>
                                                                <Typography
                                                                    style={{textAlign: 'left', fontSize: '12px'}}>
                                                                    {intl.get('ORDER_DASHBOARD.BATTERY_MATERIAL')}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Typography
                                                                    style={{textAlign: 'left', fontSize: '12px'}}>
                                                                    {batteryDetails?.material === 'LITHIUM_METAL' ? 'Lithium Metal' : 'Lithium Ion'}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Typography
                                                                    style={{textAlign: 'left', fontSize: '12px'}}>
                                                                    {intl.get('ORDER_DASHBOARD.BATTERY_PACKING')}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Typography
                                                                    style={{textAlign: 'left', fontSize: '12px'}}>
                                                                    {batteryDetails?.packing === 'CONTAINED_IN_EQUIPMENT' ? 'Contained in equipment' : 'Packed with equipment'}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            }
                            <Grid item xl={9} md={7} xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                            Cost Details
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Typography
                                            style={{
                                                fontSize: '12px',
                                                textAlign: 'left'
                                            }}>
                                            Freight Charge
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography style={{
                                            // color: '#1D8B45',
                                            textAlign: 'left',
                                            fontSize: '12px'
                                        }}>
                                            $ {(+selectTransactionData?.freight_charge)?.toFixed(2)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Typography
                                            style={{
                                                fontSize: '12px',
                                                textAlign: 'left'
                                            }}>
                                            Fuel Surcharge
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography style={{
                                            // color: '#1D8B45',
                                            textAlign: 'left',
                                            fontSize: '12px'
                                        }}>
                                            $ {(+selectTransactionData?.fuel_surcharge)?.toFixed(2)}
                                        </Typography>
                                    </Grid>
                                    {(+selectTransactionData?.signature_charge) > 0 &&
                                        <>
                                            <Grid item xs={7}>
                                                <Typography
                                                    style={{
                                                        fontSize: '12px',
                                                        textAlign: 'left'
                                                    }}>
                                                    Signature Charge
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography style={{
                                                    // color: '#1D8B45',
                                                    textAlign: 'left',
                                                    fontSize: '12px'
                                                }}>
                                                    $ {(+selectTransactionData?.signature_charge)?.toFixed(2)}
                                                </Typography>
                                            </Grid>
                                        </>
                                    }
                                    {(+selectTransactionData?.dangerous_charge) > 0 &&
                                        <>
                                            <Grid item xs={7}>
                                                <Typography
                                                    style={{
                                                        fontSize: '12px',
                                                        textAlign: 'left'
                                                    }}>
                                                    Dangerous Good Charge
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography style={{
                                                    // color: '#1D8B45',
                                                    textAlign: 'left',
                                                    fontSize: '12px'
                                                }}>
                                                    $ {(+selectTransactionData?.dangerous_charge)?.toFixed(2)}
                                                </Typography>
                                            </Grid>
                                        </>
                                    }
                                    {(+selectTransactionData?.residential_charge) > 0 &&
                                        <>
                                            < Grid item xs={7}>
                                                <Typography
                                                    style={{
                                                        fontSize: '12px',
                                                        textAlign: 'left'
                                                    }}>
                                                    Residential Surcharge
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography style={{
                                                    // color: '#1D8B45',
                                                    textAlign: 'left',
                                                    fontSize: '12px'
                                                }}>
                                                    $ {(+selectTransactionData?.residential_charge)?.toFixed(2)}
                                                </Typography>
                                            </Grid>
                                        </>
                                    }
                                    {(+selectTransactionData?.insurance_charge) > 0 &&
                                        <>
                                            < Grid item xs={7}>
                                                <Typography
                                                    style={{
                                                        fontSize: '12px',
                                                        textAlign: 'left'
                                                    }}>
                                                    Insurance Charge
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography style={{
                                                    // color: '#1D8B45',
                                                    textAlign: 'left',
                                                    fontSize: '12px'
                                                }}>
                                                    $ {(+selectTransactionData?.insurance_charge)?.toFixed(2)}
                                                </Typography>
                                            </Grid>
                                        </>
                                    }
                                    {(+selectTransactionData?.over_size_charge) > 0 &&
                                        <>
                                            < Grid item xs={7}>
                                                <Typography
                                                    style={{
                                                        fontSize: '12px',
                                                        textAlign: 'left'
                                                    }}>
                                                    Oversize Charge
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography style={{
                                                    // color: '#1D8B45',
                                                    textAlign: 'left',
                                                    fontSize: '12px'
                                                }}>
                                                    $ {(+selectTransactionData?.over_size_charge)?.toFixed(2)}
                                                </Typography>
                                            </Grid>
                                        </>
                                    }
                                    {(+selectTransactionData?.over_weight_charge) > 0 &&
                                        <>
                                            < Grid item xs={7}>
                                                <Typography
                                                    style={{
                                                        fontSize: '12px',
                                                        textAlign: 'left'
                                                    }}>
                                                    Overweight Charge
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography style={{
                                                    // color: '#1D8B45',
                                                    textAlign: 'left',
                                                    fontSize: '12px'
                                                }}>
                                                    $ {(+selectTransactionData?.over_weight_charge)?.toFixed(2)}
                                                </Typography>
                                            </Grid>
                                        </>
                                    }
                                    {(+selectTransactionData?.sur_charge) > 0 &&
                                        <>
                                            < Grid item xs={7}>
                                                <Typography
                                                    style={{
                                                        fontSize: '12px',
                                                        textAlign: 'left'
                                                    }}>
                                                    Surcharge
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography style={{
                                                    // color: '#1D8B45',
                                                    textAlign: 'left',
                                                    fontSize: '12px'
                                                }}>
                                                    $ {(+selectTransactionData?.sur_charge)?.toFixed(2)}
                                                </Typography>
                                            </Grid>
                                        </>
                                    }
                                    {totalOtherCharge() > 0 &&
                                        <>
                                            <Grid item xs={7}>
                                                <Typography
                                                    style={{
                                                        fontSize: '12px',
                                                        textAlign: 'left'
                                                    }}>
                                                    Other Charge
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography
                                                    style={{
                                                        // color: '#1D8B45',
                                                        textAlign: 'left',
                                                        fontSize: '12px'
                                                    }}>
                                                    $ {totalOtherCharge().toFixed(2)}
                                                </Typography>
                                            </Grid>
                                        </>
                                    }
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Typography
                                            style={{
                                                fontSize: '12px',
                                                textAlign: 'left'
                                            }}>
                                            Total Shipping Charge
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography style={{
                                            // color: '#1D8B45',
                                            textAlign: 'left',
                                            fontSize: '12px'
                                        }}>
                                            $ {(+selectTransactionData?.total_shipping_charge)?.toFixed(2)}
                                        </Typography>
                                    </Grid>
                                    {
                                        (+selectTransactionData?.service_fee_exclude_tax) > 0 &&
                                        <>
                                            <Grid item xs={7}>
                                                <Typography
                                                    style={{
                                                        fontSize: '12px',
                                                        textAlign: 'left'
                                                    }}>
                                                    Service Fee
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography style={{
                                                    // color: '#1D8B45',
                                                    textAlign: 'left',
                                                    fontSize: '12px'
                                                }}>
                                                    $ {(+selectTransactionData?.service_fee_exclude_tax)?.toFixed(2)}
                                                </Typography>
                                            </Grid>
                                        </>
                                    }
                                    {(+selectTransactionData?.discount) > 0 &&
                                        <>
                                            <Grid item xs={7}>
                                                <Typography
                                                    style={{
                                                        fontSize: '12px',
                                                        textAlign: 'left'
                                                    }}>
                                                    Brokerage Charge
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography style={{
                                                    // color: '#1D8B45',
                                                    textAlign: 'left',
                                                    fontSize: '12px'
                                                }}>
                                                    ($ {(+selectTransactionData?.discount)?.toFixed(2)})
                                                </Typography>
                                            </Grid>
                                        </>
                                    }
                                    <Grid item xs={7}>
                                        <Typography
                                            style={{
                                                fontSize: '12px',
                                                textAlign: 'left'
                                            }}>
                                            Taxes
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography style={{
                                            // color: '#1D8B45',
                                            textAlign: 'left',
                                            fontSize: '12px'
                                        }}>
                                            $ {(+selectTransactionData?.total_tax)?.toFixed(2)}
                                        </Typography>
                                    </Grid>
                                    {/*<Grid item xs={12}>*/}
                                    {/*    <Divider/>*/}
                                    {/*</Grid>*/}
                                    {(+selectTransactionData?.total_brokerage_charge) > 0 &&
                                        <>
                                            <Grid item xs={7}>
                                                <Typography
                                                    style={{
                                                        fontSize: '12px',
                                                        textAlign: 'left'
                                                    }}>
                                                    Brokerage Charge
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography style={{
                                                    // color: '#1D8B45',
                                                    textAlign: 'left',
                                                    fontSize: '12px'
                                                }}>
                                                    $ {(+selectTransactionData?.total_brokerage_charge)?.toFixed(2)}
                                                </Typography>
                                            </Grid>
                                        </>
                                    }
                                    {(+selectTransactionData?.total_duties_taxes_charge) > 0 &&
                                        <>
                                            <Grid item xs={7}>
                                                <Typography
                                                    style={{
                                                        fontSize: '12px',
                                                        textAlign: 'left'
                                                    }}>
                                                    Duty Taxes Charge
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography style={{
                                                    // color: '#1D8B45',
                                                    textAlign: 'left',
                                                    fontSize: '12px'
                                                }}>
                                                    $ {(+selectTransactionData?.total_duties_taxes_charge)?.toFixed(2)}
                                                </Typography>
                                            </Grid>
                                        </>
                                    }
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Typography
                                            style={{
                                                fontSize: '12px',
                                                textAlign: 'left'
                                            }}>
                                            Total
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography style={{
                                            // color: '#1D8B45',
                                            textAlign: 'left',
                                            fontSize: '12px'
                                        }}>
                                            $ {(+selectTransactionData?.final_total)?.toFixed(2)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xl={3} md={5} xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    height: '100%'
                                }}>
                                    <Grid container spacing={1}>
                                        {
                                            +selectTransactionData?.is_return_label === 0 &&
                                            <Grid item xs={12}>
                                                <Button
                                                    variant='outlined'
                                                    sx={{
                                                        border: '2px solid #1D8B45',
                                                        "&:hover": {
                                                            border: '2px solid #1D8B45',
                                                            filter: 'brightness(0.9)'
                                                        },
                                                        display: 'flex',
                                                        gap: '5px',
                                                        width: '100%'
                                                    }}
                                                    onClick={handleCreateReturnLabel}
                                                >
                                                    <ReturnLabelIcon width='20' height='20' fill='#1D8B45'/>
                                                    <Typography style={{
                                                        textTransform: 'none',
                                                        fontSize: '14px',
                                                        color: '#1D8B45',
                                                    }}>
                                                        Create Return Label
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                        }
                                        {
                                            +selectTransactionData?.is_return_label === 0 &&
                                            <Grid item xs={12}>
                                                <Button
                                                    variant='outlined'
                                                    sx={{
                                                        border: '2px solid #1D8B45',
                                                        "&:hover": {
                                                            border: '2px solid #1D8B45',
                                                            filter: 'brightness(0.9)'
                                                        },
                                                        width: '100%',
                                                        display: 'flex',
                                                        gap: '5px'
                                                    }}
                                                    onClick={handleCreateCloneLabel}
                                                >
                                                    <CloneLabelIcon width='20' height='20' fill='#1D8B45'/>
                                                    <Typography style={{
                                                        textTransform: 'none',
                                                        fontSize: '14px',
                                                        color: '#1D8B45',
                                                    }}>
                                                        Clone Label
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                        }
                                        <Grid item xs={12}>
                                            <Button
                                                variant='outlined'
                                                sx={{
                                                    border: '2px solid #1D8B45',
                                                    "&:hover": {
                                                        border: '2px solid #1D8B45',
                                                        filter: 'brightness(0.9)'
                                                    },
                                                    display: 'flex',
                                                    gap: '5px',
                                                    width: '100%'
                                                }}
                                                onClick={handleTrackingInfo}
                                            >
                                                <TrackLabelIcon width='20' height='20' fill='#1D8B45'/>
                                                <Typography style={{
                                                    textTransform: 'none',
                                                    fontSize: '14px',
                                                    color: '#1D8B45',
                                                }}>
                                                    {intl.get('ORDER_DASHBOARD.TRACKING_INFORMATION.TRACKING_INFORMATION')}
                                                </Typography>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                variant='outlined'
                                                sx={{
                                                    border: '2px solid #1D8B45',
                                                    "&:hover": {
                                                        border: '2px solid #1D8B45',
                                                        filter: 'brightness(0.9)'
                                                    },
                                                    display: 'flex',
                                                    gap: '5px',
                                                    width: '100%',
                                                }}
                                            >
                                                <PrintLabelIcon width='20' height='20' fill='#1D8B45'/>
                                                <Link
                                                    href={getLabelURL(`${LABEL_DOWNLOAD_URL_PREFIX}${selectTransactionData?.label_pdf_uri}`, `${LABEL_DOWNLOAD_URL_PREFIX}${selectTransactionData?.label_zip_uri}`)}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    underline="none"
                                                >
                                                    <Typography style={{
                                                        textTransform: 'none',
                                                        fontSize: '14px',
                                                        color: '#1D8B45',
                                                    }}>
                                                        {intl.get('ORDER_DASHBOARD.PRINT_LABEL')}
                                                    </Typography>
                                                </Link>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                variant='contained'
                                                sx={{
                                                    backgroundColor: '#7C7C7C',
                                                    "&:hover": {
                                                        backgroundColor: '#7C7C7C',
                                                        filter: 'brightness(0.9)'
                                                    },
                                                    width: '100%'
                                                }}
                                                onClick={onCancelOrder}
                                            >
                                                <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                                                    {intl.get('ORDER_DASHBOARD.CANCEL')}
                                                </Typography>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

// {selectTransactionData?.insurance_charge > 0 &&
// <Grid item md={6} xs={12}>
//     <Box sx={{
//         display: 'flex',
//         justifyContent: 'flex-start',
//         alignItems: 'flex-start',
//         height: '100%',
//         gap: '5px',
//         flexDirection: 'column'
//     }}>
//         <Typography><span
//             style={{fontWeight: '600'}}>{intl.get('ORDER_DASHBOARD.INSURANCE_COVERAGE')}</span>
//         </Typography>
//         <Typography><span
//             style={{fontWeight: '600'}}>{intl.get('ORDER_DASHBOARD.INSURANCE_CHARGE')}: </span>
//             $ {selectTransactionData?.insurance_charge}
//         </Typography>
//     </Box>
// </Grid>
// }