import {TableCell, TableRow} from "@mui/material";
import Typography from "@mui/material/Typography";
import {LABEL_DOWNLOAD_URL_PREFIX} from "../../Utils/apiUrl";

export const OrderDashBoardTransactionsTrackingEach = ({trackingEach}) => {

    console.log('tracking each',trackingEach)
    return (
        <TableRow>
            <TableCell>
                <Typography>
                    {trackingEach?.item_name}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography>
                    <a href={trackingEach?.label_uri ? `${LABEL_DOWNLOAD_URL_PREFIX}${trackingEach.label_uri}` : "#"}
                       target="_blank" rel="noopener noreferrer">
                        {trackingEach?.tracking_no}
                    </a>
                </Typography>
            </TableCell>
            <TableCell>
                <Typography>
                    {trackingEach?.length}x{trackingEach?.width}x{trackingEach?.height} {trackingEach?.dimension_unit}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography>
                    {trackingEach?.weight} {trackingEach?.weight_unit}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography>
                    {trackingEach?.hs_code}
                </Typography>
                <Typography>
                    CAD ${trackingEach?.unit_price}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography>
                    {trackingEach?.tran_status === 1 ? 'On the way' : trackingEach?.tran_status === 2 ? 'Delivered' : 'Cancelled'}
                </Typography>
            </TableCell>
        </TableRow>
    )
}