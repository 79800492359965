import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
// import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Popper,
  Box,
  Paper,
  ClickAwayListener,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import throttle from "lodash/throttle";

import { getURLParams, errHandling } from "../../../../../Utils/fetchUtil";
// import HscodeDetailsSection from "./hscodeDetailsSection";
import HscodeTable from "./hscodeTable";
// import HscodeCateSelectDetail from "./hscodeCateSelectDetail";
import { HSCODE_SEARCH } from "../../../../../Utils/apiUrl";
// import parse from "autosuggest-highlight/parse";

// import throttle from "lodash/throttle";

const leafLevel = "6";

const parentLeafLevel = "4";

export default function HscodeInput({ handleItemChange }) {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:600px)");

  const [value, setValue] = useState("");
  // const [inputValue, setInputValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [buckets, setBuckets] = useState([]);
  const [open, setOpen] = useState(false);

  const [selectedObj, setSelectedObj] = useState(null);

  const [subList, setSubList] = useState([]);

  const [selectedSubObj, setSelectedSubObj] = useState(null);

  const [activeCate, setActiveCate] = useState(null);

  const [activeItemList, setActiveItemList] = useState([]);

  const [optionListCate, setOptionListCate] = useState([]);

  const [optionListItem, setOptionListItem] = useState([]);

  const [fetchErr, setFetchErr] = useState("");

  const fetch = React.useMemo(
    () =>
      throttle((requestString, resHanlder) => {
        axios
          .get(
            getURLParams(HSCODE_SEARCH, {
              query_string: requestString,
            })
          )
          .then((res) => {
            const itemList = res.data;
            resHanlder(itemList);
          })
          .catch((err) => {
            errHandling(err, setFetchErr);
          });
      }, 200),
    []
  );

  useEffect(() => {
    let active = true;

    if (value === "") {
      //   setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(value, (results) => {
      if (active) {
        let newOptions = [];

        // if (value) {
        //   newOptions = [value];
        // }

        if (results.hits) {
          newOptions = results.hits.map((result) => result._source);
        }

        setOptions(newOptions);

        if (results?.buckets) {
          // setBuckets(results.buckets);
          const resObj = {};
          const resBuckets = [];
          results.buckets.forEach((bucket) => {
            // Level 4 categories
            if (bucket.key.length === 4) {
              const findItem = newOptions.find(
                (_option) => _option.hscode === bucket.key
              );
              // If this category exist in the option list
              if (findItem) {
                const findParent = newOptions.find(
                  (_option) => _option.hscode === findItem.parent
                );
                // If parent hscode not exist in resObj, create a new one
                if (findParent && !resObj.hasOwnProperty(findParent.hscode)) {
                  const newParent = { ...findParent, children: [findItem] };
                  const index = resBuckets.push(newParent);
                  resObj[findParent.hscode] = index - 1;
                }
                // If parent hscode exists in resObj, add item to its children
                else if (
                  findParent &&
                  resObj.hasOwnProperty(findParent.hscode)
                ) {
                  // const newParent = { ...findParent, children: [findItem] };
                  const index = resObj[findItem.parent];
                  resBuckets[index].children.push(findItem);
                } else {
                  resBuckets.push(findItem);
                }
              }
            }
          });
          setBuckets(resBuckets);
        }
      }
    });

    return () => {
      active = false;
    };
  }, [value, fetch]);

  useEffect(() => {
    if (options.length !== 0) {
      const _optionListCate = options.filter(
        (option) => option.level !== leafLevel
      );
      setOptionListCate(_optionListCate);

      const _optionListItem = options.filter(
        (option) => option.level === leafLevel
      );
      setOptionListItem(_optionListItem);
    }
    setActiveCate(null);
  }, [options]);

  useEffect(() => {
    if (activeCate) {
      const _optionListItem = optionListItem.filter(
        (option) => option.hscode.indexOf(activeCate.hscode) === 0
      );
      setActiveItemList(_optionListItem);
    } else setActiveItemList(optionListItem);
  }, [activeCate, optionListItem]);

  useEffect(() => {
    if (selectedObj) {
      handleItemChange(selectedObj);
    }
  }, [selectedObj, handleItemChange]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setValue(event.target.value);
    !open && setOpen(true);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <Box>
      <ClickAwayListener onClickAway={handleClickAway} mouseEvent="onMouseUp">
        <Box width={matches ? "100%" : "600px"} margin="0 auto">
          <TextField
            className={classes.textfield}
            InputProps={{
              autoComplete: "off",
              disableUnderline: true,
            }}
            onChange={(e) => handleClick(e)}
            value={value}
            onFocus={() => {
              !open && setOpen(true);
            }}
            placeholder="* Input item name, e.g., 'horse live'"
          />
          <Popper
            open={value ? open : false}
            anchorEl={anchorEl}
            placement="bottom"
            style={matches ? { width: "100%" } : { width: "600px" }}
          >
            <Paper classes={{ root: classes.paperRoot }}>
              {fetchErr ? (
                fetchErr
              ) : (
                <HscodeTable
                  buckets={buckets}
                  options={options}
                  setSelectedObj={setSelectedObj}
                  setSubList={setSubList}
                  setSelectedSubObj={setSelectedSubObj}
                  setActiveCate={setActiveCate}
                  activeItemList={activeItemList}
                  activeCate={activeCate}
                  setOpen={setOpen}
                />
              )}
            </Paper>
          </Popper>
        </Box>
      </ClickAwayListener>
      {/* {selectedObj && selectedObj?.level === leafLevel && (
        <Box margin="0 auto" width={matches ? "100%" : "600px"} pt={1}>
          <HscodeDetailsSection selectedObj={selectedObj} />
        </Box>
      )}

      {selectedObj && selectedObj?.level !== leafLevel && (
        <HscodeCateSelectDetail
          optionListItem={optionListItem}
          selectedSubObj={selectedSubObj}
          setSelectedObj={setSelectedObj}
          setSelectedSubObj={setSelectedSubObj}
          selectedObj={selectedObj}
          subList={subList}
        />
      )} */}
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  textfield: {
    width: "100%",
    backgroundColor: "white",
    padding: "0.4px",
    borderRadius: "5px",
    // boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: "0.4vw",
    paddingBottom: "0.4vw",
  },
  errorText: {
    border: "1px solid red",
    borderRadius: "5px",
  },
  imgCenter: {
    display: "block",
    margin: "auto",
  },
  paperRoot: {
    width: "100%",
    "@media (max-width: 600px)": {
      width: "93%",
      marginLeft: "20px",
      marginRight: "16px",
    },
  },
  optionItem: {
    "&:hover": {
      // backgroundColor: "seagreen",
      color: "orange",
      cursor: "pointer",
    },
  },
  optionCateItem: {
    "&:hover": {
      backgroundColor: "grey",
      color: "white",
      cursor: "pointer",
    },
  },
  optionCateItemActive: {
    backgroundColor: "grey",
    color: "white",
    cursor: "pointer",
  },
  optionCateActive: {
    // "&:hover": {
    backgroundColor: "grey",
    color: "white",
    cursor: "pointer",
    // },
  },
  selectedOptionItem: {
    backgroundColor: "grey",
    color: "white",
  },
  cateSection: {
    backgroundColor: "rgb(232,232,232)",
    height: "100%",
    overflowY: "scroll",
  },
  cateDetailTitle: {
    backgroundColor: "rgb(232,232,232)",
  },
  popperSection: {
    height: "300px",
  },
  itemSection: {
    height: "300px",
    overflowY: "scroll",
  },
}));
