import {ButtonBase, ClickAwayListener, Container, Tooltip, tooltipClasses, useMediaQuery} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState, Fragment} from "react";
import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";
import LoadingButton from "@mui/lab/LoadingButton";
import {useHistory} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import HelpIcon from "@mui/icons-material/Help";
import Button from "@mui/material/Button";
import {styled} from "@mui/material/styles";
import {mapCarrierLogo} from "../../Utils/Helper";
import {
    initShippingPackageCreateLabelPackageList,
    updateCreateLabelShippingPackageShipFrom,
    updateCreateLabelShippingPackageShipTo
} from "../../actions/shippingPackageAction";

const styles = {
    ShippingPackageCreateLabelRateRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelRateHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelRateSubHeading: {
        fontSize: '16px',
        fontWeight: '600',
        paddingLeft: '10px'
    },
    ShippingPackageCreateLabelRateOtherBox: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        height: '100%',
        flexDirection: 'column'
    },
    ShippingPackageCreateLabelRateImageBox: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        paddingLeft: '20px',
        gap: '10px',
        flexDirection: 'column'
    },
    ShippingPackageCreateLabelRateOtherBoxMobile: {
        display: 'flex',
        alignItems: 'flex-start',
        paddingLeft: '20px',
        justifyContent: 'center',
        height: '100%',
        flexDirection: 'column'
    },
}

const LightTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

const GreenTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#1D8B45',
        color: '#FFFFFF',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

export const ShippingPackageInternationShipmentQuote = () => {

    const dispatch = useDispatch();

    const history = useHistory();

    let quoteList = useSelector(state => state?.shippingPackage?.quickQuoteRate);
    let packageListRedux = useSelector(state => state?.shippingPackage?.quickQuotePackageList);

    const isMobile = useMediaQuery('(max-width: 600px)');

    const [selectedRate, setSelectedRate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [quote, setQuote] = useState([]);
    const [open, setOpen] = useState(-1);

    const handleClose = (index) => {
        // console.log('click away', index);
        // console.log('open index', open);
        // setOpen(-1);
        index === open && setOpen(-1);
    };

    const handleOpen = (index) => {
        // console.log('click open', index);
        // console.log('open index', open);
        setOpen(index);
    };

    const handleSelect = (rate) => {
        setSelectedRate(rate);
    }

    const handleStartOver = () => {
        history.push('/shipping-package/international-shipment');
    }

    useEffect(() => {
        // Step 1: Sort the array based on 'total'
        const sortedRate = [...quoteList].sort((a, b) => (a?.finalTotalAmount?.value || Infinity) - (b?.finalTotalAmount?.value || Infinity));

        // Step 2: Find the 'FedEx Ground' object
        const fedexIndex = sortedRate.findIndex((item) => item.serviceName === 'Fedex Ground');

        if (fedexIndex !== -1 && fedexIndex !== 0) {
            // Step 4: Calculate the new 'total' for 'lowest'
            const lowestTotal = sortedRate[0]?.finalTotalAmount?.value * 1.1;
            const fedexTotal = sortedRate[fedexIndex]?.finalTotalAmount?.value;

            // Step 4: Check if 'FedEx Ground' is smaller than the lowest object
            if (fedexTotal < lowestTotal) {
                // Step 5: Swap positions of 'FedEx Ground' and the lowest object
                const updatedData = [...sortedRate];
                const temp = updatedData[fedexIndex];
                updatedData.splice(fedexIndex, 1);
                updatedData.unshift(temp);
                setQuote(updatedData)
            } else {
                setQuote(sortedRate)
            }
        } else {
            setQuote(sortedRate)
        }
    }, [quoteList]);

    console.log('[ShippingPackageInternationShipmentQuote] quote list', quoteList);

    const handlePlaceOrder = () => {
        // dispatch(updateCreateLabelShippingPackageShipFrom(null));
        // dispatch(updateCreateLabelShippingPackageShipTo(null));
        // dispatch(updateShippingPackageInternationalShipmentShipFrom(null));
        // dispatch(updateShippingPackageInternationalShipmentShipTo(null));
        dispatch(initShippingPackageCreateLabelPackageList(packageListRedux));
        history.push({
            pathname: '/shipping-package/create-label',
        });
    }

    return (
        <Container maxWidth='xl'>
            <Box sx={styles.ShippingPackageCreateLabelRateRoot}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography style={styles.ShippingPackageCreateLabelRateHeading}>
                            Choose Carrier And Services
                        </Typography>
                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                            Choose the best carrier for you based on price, delivery time and reliability
                        </Typography>
                    </Grid>
                    {
                        quote?.length > 0 ?
                            <>
                                {
                                    !isMobile && <>
                                        <Grid item xs={2}>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Box sx={styles.ShippingPackageCreateLabelRateOtherBox}>
                                                <Typography sx={styles.ShippingPackageCreateLabelRateSubHeading}>
                                                    Carrier
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Box sx={styles.ShippingPackageCreateLabelRateOtherBox}>
                                                <Typography sx={styles.ShippingPackageCreateLabelRateSubHeading}>
                                                    Delivery Time
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Box sx={styles.ShippingPackageCreateLabelRateOtherBox}>
                                                <Typography sx={styles.ShippingPackageCreateLabelRateSubHeading}>
                                                    Price (Exclude Tax)
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        {/*<Grid item xs={2}>*/}
                                        {/*</Grid>*/}
                                    </>
                                }
                                {
                                    quote?.map((each, index) => {
                                        // if (index >= 1) {
                                        return (
                                            <>
                                                {
                                                    !each?.errorMessage ?
                                                        <Grid item key={index} xs={12}>
                                                            <Box sx={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                gap: '10px',
                                                                marginBottom: index === 0 && "20px"
                                                            }}>
                                                                {
                                                                    index === 0 && <Box sx={{
                                                                        display: 'flex',
                                                                        justifyContent: 'flex-start',
                                                                    }}>
                                                                        <Typography sx={{
                                                                            backgroundColor: '#1D8B45',
                                                                            color: '#FFFFFF',
                                                                            fontSize: '14px',
                                                                            padding: '5px 10px',
                                                                            borderRadius: '5px'
                                                                        }}>
                                                                            Recommendation
                                                                        </Typography>
                                                                    </Box>
                                                                }
                                                                <ButtonBase sx={{
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    padding: '20px 0',
                                                                    borderRadius: '5px',
                                                                    border: each === selectedRate ? '3px solid #609966' : '1px solid #000000',
                                                                    backgroundColor: index === 0 && '#F3FDE8'
                                                                }}
                                                                            onClick={() => handleSelect(each)}
                                                                            disabled={loading}
                                                                >
                                                                    <Box sx={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        width: '100%',
                                                                        height: '100%',
                                                                    }}>
                                                                        <Grid container spacing={2}>
                                                                            <Grid item md={2} xs={12}>
                                                                                <Box
                                                                                    sx={styles?.ShippingPackageCreateLabelRateImageBox}>
                                                                                    <img
                                                                                        src={mapCarrierLogo(each?.accountBase)}
                                                                                        alt="logo"
                                                                                        width='60px'/>
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid item md={4} xs={12}>
                                                                                <Box sx={
                                                                                    isMobile ?
                                                                                        styles?.ShippingPackageCreateLabelRateOtherBoxMobile :
                                                                                        styles?.ShippingPackageCreateLabelRateOtherBox
                                                                                }>
                                                                                    <Typography
                                                                                        style={styles?.ShippingPackageQuickQuoteRateListText}>
                                                                                        {each?.serviceName}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid item md={3} xs={12}>
                                                                                <Box sx={
                                                                                    isMobile ?
                                                                                        styles?.ShippingPackageCreateLabelRateOtherBoxMobile :
                                                                                        styles?.ShippingPackageCreateLabelRateOtherBox
                                                                                }>
                                                                                    <Typography
                                                                                        style={styles.ShippingPackageQuickQuoteRateListText}>
                                                                                        {each?.shipRange} business day(s)
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid item md={3} xs={12}>
                                                                                <Box sx={
                                                                                    isMobile ?
                                                                                        styles?.ShippingPackageCreateLabelRateOtherBoxMobile :
                                                                                        styles?.ShippingPackageCreateLabelRateOtherBox
                                                                                }>
                                                                                    <Box sx={{
                                                                                        display: 'flex',
                                                                                        gap: '5px',
                                                                                        alignItems: 'center'
                                                                                    }}>
                                                                                        <Typography style={{
                                                                                            fontSize: '16px',
                                                                                            fontWeight: '600',
                                                                                        }}>
                                                                                            $ {((+each?.totalCharge?.value) + (+each?.serviceFee?.value)).toFixed(2)}
                                                                                        </Typography>
                                                                                        <ClickAwayListener
                                                                                            onClickAway={() => handleClose(index)}>
                                                                                            <div>
                                                                                                <LightTooltip
                                                                                                    open={open === index}
                                                                                                    PopperProps={{
                                                                                                        disablePortal: true,
                                                                                                    }}
                                                                                                    disableFocusListener
                                                                                                    disableHoverListener
                                                                                                    disableTouchListener
                                                                                                    onClose={() => handleClose(index)}
                                                                                                    // onOpen={() => handleOpen(index)}
                                                                                                    sx={{
                                                                                                        padding: '0',
                                                                                                        margin: '0'
                                                                                                    }}
                                                                                                    placement="right-start"
                                                                                                    title={
                                                                                                        <Box sx={{
                                                                                                            display: 'flex',
                                                                                                            flexDirection: 'column',
                                                                                                            width: '250px',
                                                                                                            gap: '15px'
                                                                                                        }}>
                                                                                                            <Grid
                                                                                                                container
                                                                                                                spacing={1}>
                                                                                                                {
                                                                                                                    each?.shipmentCharges?.map((each, index) => {
                                                                                                                        const cost = +each?.value
                                                                                                                        if (cost !== 0) {
                                                                                                                            return (
                                                                                                                                <Fragment
                                                                                                                                    key={index}>
                                                                                                                                    <Grid
                                                                                                                                        item
                                                                                                                                        xs={8}>
                                                                                                                                        <Typography
                                                                                                                                            style={{
                                                                                                                                                fontSize: '12px',
                                                                                                                                                textAlign: 'left'
                                                                                                                                            }}>
                                                                                                                                            {each?.description}
                                                                                                                                        </Typography>
                                                                                                                                    </Grid>
                                                                                                                                    <Grid
                                                                                                                                        item
                                                                                                                                        xs={4}>
                                                                                                                                        <Typography
                                                                                                                                            style={{
                                                                                                                                                fontSize: '12px',
                                                                                                                                                textAlign: 'right'
                                                                                                                                            }}>
                                                                                                                                            $ {(+each?.value)?.toFixed(2)}
                                                                                                                                        </Typography>
                                                                                                                                    </Grid>
                                                                                                                                </Fragment>
                                                                                                                            )
                                                                                                                        }
                                                                                                                    })
                                                                                                                }
                                                                                                                <Grid
                                                                                                                    item
                                                                                                                    xs={8}>
                                                                                                                    <Typography
                                                                                                                        style={{
                                                                                                                            fontSize: '12px',
                                                                                                                            textAlign: 'left'
                                                                                                                        }}>
                                                                                                                        Service
                                                                                                                        Fee
                                                                                                                    </Typography>
                                                                                                                </Grid>
                                                                                                                <Grid
                                                                                                                    item
                                                                                                                    xs={4}>
                                                                                                                    <Typography
                                                                                                                        style={{
                                                                                                                            fontSize: '12px',
                                                                                                                            textAlign: 'right'
                                                                                                                        }}>
                                                                                                                        $ {(+each?.serviceFee?.value).toFixed(2)}
                                                                                                                    </Typography>
                                                                                                                </Grid>
                                                                                                                <Grid
                                                                                                                    item
                                                                                                                    xs={8}>
                                                                                                                    <Typography
                                                                                                                        style={{
                                                                                                                            fontSize: '12px',
                                                                                                                            textAlign: 'left',
                                                                                                                            color: '#1D8B45',
                                                                                                                            fontWeight: '600'
                                                                                                                        }}>
                                                                                                                        Total
                                                                                                                    </Typography>
                                                                                                                </Grid>
                                                                                                                <Grid
                                                                                                                    item
                                                                                                                    xs={4}>
                                                                                                                    <Typography
                                                                                                                        style={{
                                                                                                                            fontSize: '12px',
                                                                                                                            textAlign: 'right',
                                                                                                                            color: '#1D8B45',
                                                                                                                            fontWeight: '600'
                                                                                                                        }}>
                                                                                                                        $ {((+each?.totalCharge?.value) + (+each?.serviceFee?.value)).toFixed(2)}
                                                                                                                    </Typography>
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                        </Box>
                                                                                                    }
                                                                                                >
                                                                                                    <IconButton
                                                                                                        onClick={() => handleOpen(index)}>
                                                                                                        <HelpIcon
                                                                                                            sx={{color: '#1D8B45'}}/>
                                                                                                    </IconButton>
                                                                                                </LightTooltip>
                                                                                            </div>
                                                                                        </ClickAwayListener>
                                                                                    </Box>
                                                                                    {
                                                                                        each?.regularPriceExcludeTax?.value > ((+each?.totalCharge?.value) + (+each?.serviceFee?.value)) &&
                                                                                        <Typography style={{
                                                                                            fontSize: '14px',
                                                                                            textDecoration: 'line-through',
                                                                                        }}>
                                                                                            Reg.
                                                                                            ${each?.regularPriceExcludeTax?.value}
                                                                                        </Typography>
                                                                                    }
                                                                                </Box>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </ButtonBase>
                                                            </Box>
                                                        </Grid>
                                                        :
                                                        each?.statusCode === 200 &&
                                                        <Grid item key={index} xs={12}>
                                                            <Box sx={{
                                                                padding: '20px 0',
                                                                borderRadius: '5px',
                                                                border: each === selectedRate ? '3px solid #609966' : '1px solid #000000',
                                                            }}>
                                                                <Grid container spacing={4}>
                                                                    <Grid item md={2} xs={3}>
                                                                        <Box
                                                                            sx={styles?.ShippingPackageCreateLabelRateImageBox}>
                                                                            <img
                                                                                src={mapCarrierLogo(each?.errorAccountBase)}
                                                                                alt="logo"
                                                                                width='60px'/>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item md={3} xs={9}>
                                                                        <Box
                                                                            sx={styles?.ShippingPackageCreateLabelRateOtherBox}>
                                                                            <Typography
                                                                                style={styles?.ShippingPackageQuickQuoteRateListText}>
                                                                                {each?.errorAccountBase}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item md={7} xs={12}>
                                                                        <Box
                                                                            sx={styles?.ShippingPackageCreateLabelRateOtherBox}>
                                                                            <Typography
                                                                                style={{
                                                                                    textAlign: 'left',
                                                                                    color: '#FF0303'
                                                                                }}>
                                                                                {each?.errorMessage}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        </Grid>
                                                }
                                            </>
                                        )
                                        // }
                                    })
                                }
                                <Grid item xs={12} md={12}>
                                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        <Typography style={{color: 'red'}}>
                                            *
                                        </Typography>
                                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                            This may not be your final price.
                                        </Typography>
                                        <GreenTooltip
                                            placement="right-start"
                                            title={<Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                }}>
                                                <Typography
                                                    style={{
                                                        fontSize: '12px',
                                                        textAlign: 'left',
                                                        paddingBottom: '10px'
                                                    }}>
                                                    Be aware carriers may charge extra for:
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        fontSize: '12px',
                                                        textAlign: 'left'
                                                    }}>
                                                    -Unmarked residential addresses;
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        fontSize: '12px',
                                                        textAlign: 'left'
                                                    }}>
                                                    -Incorrect dimensions/weight;
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        fontSize: '12px',
                                                        textAlign: 'left'
                                                    }}>
                                                    -Wrong freight class;
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        fontSize: '12px',
                                                        textAlign: 'left'
                                                    }}>
                                                    -Multiple pickup tries;
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        fontSize: '12px',
                                                        textAlign: 'left'
                                                    }}>
                                                    -Special handling packaging
                                                </Typography>
                                            </Box>}
                                        >
                                            <IconButton>
                                                <HelpIcon
                                                    sx={{color: '#1D8B45', fontSize: '20px'}}/>
                                            </IconButton>
                                        </GreenTooltip>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{display: 'flex', width: '100%', justifyContent: 'flex-end', gap: '20px'}}>
                                        <LoadingButton
                                            size='large'
                                            variant='contained'
                                            onClick={handleStartOver}
                                            sx={{
                                                backgroundColor: '#F2BE22',
                                                "&:hover": {
                                                    backgroundColor: '#F2BE22',
                                                    filter: 'brightness(0.9)'
                                                }
                                            }}
                                            loading={loading}
                                        >
                                            Start Over
                                        </LoadingButton>
                                        <Button
                                            size='large'
                                            color="success"
                                            variant='contained'
                                            // disabled={!selectedRate}
                                            onClick={handlePlaceOrder}
                                        >
                                            Continue Place Order
                                        </Button>
                                    </Box>
                                </Grid>
                            </>
                            :
                            <Grid item xs={12}>
                                <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                                    <Typography style={{color: 'red', textAlign: 'left', fontSize: '16px'}}>
                                        {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.ERROR_MESSAGE_INTERNATIONAL_SHIPMENT')}
                                    </Typography>
                                    <Box sx={{display: 'flex', width: '100%', justifyContent: 'flex-end', gap: '20px'}}>
                                        <LoadingButton
                                            size='large'
                                            variant='contained'
                                            onClick={handleStartOver}
                                            loading={loading}
                                        >
                                            Start Over
                                        </LoadingButton>
                                    </Box>
                                </Box>
                            </Grid>
                    }
                </Grid>
            </Box>
        </Container>
    )
}