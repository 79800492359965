export const redirectSignInSuccessful = (history) => {
  history.push("/profile");
};

export const redirectSocialSignInSuccessful = (history) => {
  history.push("/profile");
};

export const redirectSignUpSuccessful = (history) => {
  history.push("/sign-in");
};

export const redirectHome = (history, role) => {
  if (role === "Driver") history.push("/driver");
  else history.push("/");
};

export const redirectOrderList = (history) => {
  history.push("/my-orders/active");
};

export const redirectShippingOrderList = (history) => {
  history.push("/shippingOrderList");
};

export const redirectSearchShippingOrder = (history) => {
  history.push("/searchShippingOrder");
};

export const redirectSettlementDetails = (number) => {
  return `/driver/settlement-details/${number}`;
};

export const redirectAddressBook = (history) => {
  history.push("/address-book");
};

export const redirectShipmentDetails = (history, id) => {
  history.push(`/shipment-details/${id}`);
};

export const redirectRating = (history) => {
  history.push(`/rating`);
};
