import {
  DRIVER_UPLOAD_IMAGES,
  DRIVER_REMOVE_IMAGES,
  DRIVER_CLEAR_IMAGES,
  DRIVER_SET_FETCH_IMAGE_STATUS,
} from "../actions/types";
import produce from "immer";
const initial = {
  fetched: {
    loaded: false,
    delivered: false,
  },
  loadShipments: [],
  deliverShipments: [],
};

export const driverUploadImagesReducer = (state = initial, action) => {
  switch (action.type) {
    case DRIVER_UPLOAD_IMAGES:
      if (action.target === "load") {
        return produce(state, (draft) => {
          draft.loadShipments.push(...action.payload);
        });
      } else if (action.target === "deliver") {
        return produce(state, (draft) => {
          draft.deliverShipments.push(...action.payload);
        });
      }
      break;
    case DRIVER_REMOVE_IMAGES:
      if (action.target === "load") {
        return produce(state, (draft) => {
          draft.loadShipments.splice(action.payload, 1);
        });
      } else if (action.target === "deliver") {
        return produce(state, (draft) => {
          draft.deliverShipments.splice(action.payload, 1);
        });
      }
      break;
    case DRIVER_CLEAR_IMAGES:
      if (action.target === "load") {
        return produce(state, (draft) => {
          draft.loadShipments = [];
          draft.fetched.loaded = false;
        });
      } else if (action.target === "deliver") {
        return produce(state, (draft) => {
          draft.deliverShipments = [];
          draft.fetched.delivered = false;
        });
      }
      break;
    case DRIVER_SET_FETCH_IMAGE_STATUS:
      if (action.target === "load") {
        return produce(state, (draft) => {
          draft.fetched.loaded = action.fetched;
        });
      } else if (action.target === "deliver") {
        return produce(state, (draft) => {
          draft.fetched.delivered = action.fetched;
        });
      }
      break;
    default:
      return state;
  }
};
