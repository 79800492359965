import React, {useEffect, useState} from "react";
import {Check, Cancel} from "@material-ui/icons";
import {
  TableBody,
  TableRow,
  TableCell,
  Button
} from "@material-ui/core";
import CurrencyFormat from "react-currency-format";

const LooseItemServices = ({
  setCompanyName,
  companyName,
  shippingService,
  chargeAmount,
  toSeventh,
  setServiceName,
  setPriceText,
  message,
  setRatingPrice,
  info,
  setSelectedAccountBaseCombinedName,
  setServiceType
}) => {

  const setAndLoadNext = (currentCompanyName, currentServiceName) => {
    setCompanyName(currentCompanyName);
    setServiceName(currentServiceName);
    setPriceText(chargeAmount);
    setRatingPrice({
      serviceFee: info.serviceFee,
      shippingFee: info.shippingFee,
      finalTotalAmount: info.finalTotalAmount,
      serviceFeeTax: info.serviceFeeTax
    })
    setSelectedAccountBaseCombinedName(info.accountBaseCombinedName);
    setServiceType(info.serviceType);
    toSeventh()
  };

  useEffect(() => {
    console.log("Charge Amount: ", chargeAmount)
  }, [chargeAmount]);


  return (
    <TableBody>
    <TableRow>
      <TableCell align="left">
        <Button onClick={() => setAndLoadNext(companyName, shippingService)} disabled={message !== ""}>Select</Button>
      </TableCell>
      <TableCell align="center">{companyName}</TableCell>
      <TableCell align="center">{shippingService}</TableCell>
      <TableCell align="right">
        {
          chargeAmount ? <CurrencyFormat value={(chargeAmount)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> : "N/A"
        }
      </TableCell>
      <TableCell align="right">{message !== "" ? `Error! ${message}` : ""}</TableCell>
    </TableRow>
  </TableBody>
  )
}

export default LooseItemServices;
