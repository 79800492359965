import {getAccessToken} from "../../Utils/doToken";
import {useEffect, useState} from "react";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import Box from "@mui/material/Box";
import {MyBoxesListEach} from "../MyBoxes/MyBoxesListEach";
import {RatingBoxListEach} from "./RatingBoxListEach";
import {Button, Modal} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import {toggleBoxList} from "../../actions/ratingAction";
import {useHistory} from "react-router-dom";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '1000px',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    maxHeight: '80vh',
};

export const RatingBoxList = () => {

    const accessToken = getAccessToken("access_token");
    const [myBoxesList, setMyBoxesList] = useState([]);

    const dispatch = useDispatch();
    let isBoxList = useSelector(state => state?.rating?.isBoxList);

    const history = useHistory();

    const handleClose = () => {
        dispatch(toggleBoxList(false));
    }

    const getMyBoxesList = async () => {
        let requestURL = `${NODE_ROUTE_URI}/myBoxes/getMyBoxesListForEndUser`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            // console.log(result.data)
            setMyBoxesList(result.data)

        } catch (e) {
            console.log(e.response);

        }
    }

    useEffect(() => {
        getMyBoxesList();
    }, [])

    return (
        <Box>
            <Modal
                open={isBoxList}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <Typography variant='h5' style={{textAlign: 'center'}}>
                        Boxes List
                    </Typography>
                    {
                        myBoxesList?.length === 0 ?
                            <Box>
                                <Typography>
                                    Your box list is empty.
                                    Please add an box by going to the my boxes List.
                                    Press the following button to go to the my boxes List.
                                </Typography>
                                <Button onClick={() => history.push('/my-boxes')}>My Boxes List</Button>
                            </Box>
                            :

                            myBoxesList?.map((box, index) => <RatingBoxListEach boxInfo={box} key={index}/>)}
                </Box>
            </Modal>
        </Box>
    )
}