import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useSelector} from "react-redux";
import {LABEL_DOWNLOAD_URL_PREFIX, NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../Utils/doToken";
import {useEffect, useState} from "react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {OrderDashBoardCrossBorderEach} from "./OrderDashBoardCrossBorderEach";
import {Alert} from "@mui/material";
import {useHistory} from "react-router-dom";

const style = {
    OrderDashBoardCrossBorderRoot: {
        width: '47vw',
        backgroundColor: '#FFFFFF',
        height: '400px',
        overflow: 'scroll'
    },
    OrderDashBoardCrossBorderHead: {
        textAlign: 'center',
        '&:hover': {
            cursor: 'pointer',
        }
    },
    OrderDashBoardCrossBorderHeadText: {
        color: '#537FE7',
        '&:hover': {
            textDecoration: 'underline'

        }
    },
    OrderDashBoardCrossBorderTable: {
        minWidth: '800px'
    }
}

const headCells = [
    {
        id: "itemName_trackingNumber",
        numeric: false,
        // disablePadding: true,
        label: "Item Name / Tracking #",
        width: '30%'
    },
    {
        id: "dimension_weight",
        numeric: false,
        // disablePadding: true,
        label: "Dimension / Weight",
        width: '20%'
    },
    {
        id: "hsCode_priceEach",
        numeric: false,
        // disablePadding: false,
        label: "HS Code / Price Each",
        width: '20%'
    },
    {
        id: "upload",
        numeric: false,
        // disablePadding: false,
        label: "Upload Invoice",
        width: '15%'
    },
    {
        id: "submit",
        numeric: false,
        // disablePadding: false,
        label: "Submit Invoice",
        width: '15%'
    },
];

export const OrderDashBoardCrossBorder = ({orderId}) => {

    let isCrossBorder = useSelector(state => state?.orderDashboard?.isCrossBorder);

    const history = useHistory();

    const accessToken = getAccessToken("access_token");

    const [trackingList, setTrackingList] = useState();
    const [borderDocList, setBorderDocList] = useState([]);
    const [hasUnUploadInvoice, setHasUnUploadInvoice] = useState(false);
    const [hasUnSubmitInvoice, setHasUnSubmitInvoice] = useState(false);

    const [orderInfo, setOrderInfo] = useState();

    const getOrderData = async () => {
        let requestURL = `${NODE_ROUTE_URI}/shippingOrderDetailsDashboard/endUser/shippingTransactionList`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                params: {
                    orderId: orderId
                }
            })
            // console.log('result from getOrderData', result.data.result[0]);
            setOrderInfo(result.data.result[0]);
        } catch (e) {
            console.log(e.response)
        }
    }

    const getTrackingListByTranId = async () => {
        let requestURL = `${NODE_ROUTE_URI}/shippingOrderDetailsDashboard/getTrackingViewByTranId/${orderInfo?.tran_id}`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
            })
            // console.log('gettrackinglistbytranid', result.data)
            setTrackingList(result.data);
        } catch (e) {
            console.log(e.response)
        }
    }

    const getDocListByTranId = async () => {
        let requestURL = `${NODE_ROUTE_URI}/commercialInvoice/getBoraderDocListByTranId/${orderInfo?.tran_id}`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })

            setBorderDocList(result.data);

        } catch (e) {
            console.log(e.response)
        }
    }

    console.log('is cross border', isCrossBorder);
    useEffect(() => {
        getOrderData();
    }, [])

    useEffect(() => {
        if (orderInfo && isCrossBorder) {
            getTrackingListByTranId();
            getDocListByTranId();
        }
    }, [orderInfo]);

    useEffect(() => {
        if (trackingList?.length > 0) {
            const unUnloadList = trackingList?.filter(tracking => tracking.doc_status === 0 || tracking.doc_status === null);
            const unSubmitList = trackingList?.filter(tracking => tracking.doc_status === 1);
            setHasUnUploadInvoice(unUnloadList.length > 0);
            setHasUnSubmitInvoice(unSubmitList.length > 0);
        }
    }, [trackingList]);

    return (

        <Box sx={style.OrderDashBoardCrossBorderRoot}>
            <Box
                sx={style.OrderDashBoardCrossBorderHead}
                onClick={() => history.push(`/commercial-invoice/${orderId}`)}
            >
                <Typography variant='h5' sx={style.OrderDashBoardCrossBorderHeadText}>
                    Cross Border Commercial Invoice
                </Typography>
            </Box>
            {isCrossBorder ?
                < TableContainer sx={style.OrderDashBoardCrossBorderTable}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        align={headCell.numeric ? "right" : "left"}
                                        padding={headCell.disablePadding ? "none" : "normal"}
                                        style={{fontWeight: "bold", width: headCell.width}}
                                    >
                                        {headCell.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                trackingList?.map(tracking => {
                                    return <OrderDashBoardCrossBorderEach
                                        itemName={tracking.item_name}
                                        tracking_no={tracking.tracking_no}
                                        length={tracking.length}
                                        width={tracking.width}
                                        height={tracking.height}
                                        lengthUnit={tracking.dimension_unit}
                                        weightUnit={tracking.weight_unit}
                                        quantity={tracking.number}
                                        unitPrice={tracking.unit_price}
                                        currency={tracking.currency_code}
                                        hscode={tracking.hs_code}
                                        label_uri={`${LABEL_DOWNLOAD_URL_PREFIX}${tracking.label_uri}`}
                                        pdfURI={tracking.file_uri ? `${LABEL_DOWNLOAD_URL_PREFIX}${tracking.file_uri}` : ""}
                                        weight={tracking.weight}
                                        getDocListByTranId={getDocListByTranId}
                                        getTrackingListByTranId={getTrackingListByTranId}
                                        orderId={orderId}
                                        tranId={orderInfo?.tran_id}
                                        shippingIdNumber={orderInfo?.shippingIdNumber}
                                        status={tracking.doc_status}
                                        documentId={tracking.ref_doc_id}
                                        docId={tracking.doc_id}
                                    />
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer> :
                <Typography style={{textAlign: 'center'}}>
                    Only Available For Cross Border Order
                </Typography>
            }
            {
                (() => {
                    if (hasUnSubmitInvoice && isCrossBorder) {
                        return <Alert variant="filled" severity="warning">
                            You still have un-uploaded invoices — check it out!
                        </Alert>
                    }
                })()
            }
            {
                (() => {
                    if (hasUnUploadInvoice && isCrossBorder) {
                        return <Alert variant="filled" severity="warning">
                            You still have un-submitted invoices — check it out!
                        </Alert>
                    }
                })()
            }
            {
                (() => {
                    if (!hasUnSubmitInvoice && !hasUnUploadInvoice && isCrossBorder) {
                        return <Alert variant="filled" severity="success">
                            Congrats! You have been upload all the invoices!
                        </Alert>
                    }
                })()
            }
        </Box>

    )
}