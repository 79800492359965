import React from "react";
import {string, func, oneOfType, number, bool, array} from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import {
    TextField,
    InputAdornment,
    IconButton,
    Select,
    MenuItem,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {grey} from "@material-ui/core/colors";

const CustomInput = React.memo(
    ({
         disabled,
         value,
         onChange,
         label,
         estimate,
         error = false,
         // defaultValue,
         onKeyDown = () => {
         },
         onFocus,
         type,
         options,
         multiline,
         rows,
         placeholderValue,
         inputWithSelect,
         freeSolo = false,
         vehicleType = false,
         // lineInput = false,
         weightLabel,
         others
     }) => {
        const classes = useStyles();

        const [values, setValues] = React.useState({
            amount: "",
            password: "",
            weight: "",
            weightRange: "",
            showPassword: false,
        });

        const handleClickShowPassword = () => {
            setValues({...values, showPassword: !values.showPassword});
        };

        const handleMouseDownPassword = (event) => {
            event.preventDefault();
        };

        const paramFromType = (type) => {
            if (type === "password")
                return {
                    disableUnderline: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {values.showPassword ? <Visibility/> : <VisibilityOff/>}
                            </IconButton>
                        </InputAdornment>
                    ),
                };
            if (type === "currency")
                return {
                    startAdornment: <InputAdornment position="start">C$</InputAdornment>,
                    disableUnderline: true,
                };
            if (type === "size" || type === "bigSize")
                return {
                    endAdornment: (
                        <InputAdornment position="end">
                            {type === "size" ? '"' : "'"}
                        </InputAdornment>
                    ),
                    disableUnderline: true,
                };
            if (type === "weight")
                return {
                    endAdornment: (
                        <InputAdornment position="end">{weightLabel}</InputAdornment>
                    ),
                    disableUnderline: true,
                };
            if (type === "line") return;
            return {disableUnderline: true};
        };

        const returnType = (_type, _values) => {
            if (_values.showPassword) return "text";
            if (_type === "currency") return "number";
            if (_type === "size") return "number";
            if (_type === "weight") return "number";
            return type;
        };

        const classTypes = (_type, _error, _disabled, _classes) => {
            let classes =
                _type === "line"
                    ? `${_classes.lineTextfield}`
                    : `${_classes.textfield}`;
            if (_disabled) classes += ` ${_classes.disabled}`;
            if (_error) classes += ` ${_classes.errorText}`;
            return classes;
        };

        const classTypesAuto = (_estimate, _error, _classes) => {
            let classes = "";
            if (_estimate) classes += `${_classes.textfield}`;
            if (_error) classes += ` ${_classes.errorText}`;
            return classes;
        };

        let inputBody = (
            <TextField
                className={classTypes(type, error, disabled, classes)}
                value={value || ""}
                disabled={disabled}
                onChange={onChange}
                multiline={multiline}
                onFocus={onFocus || null}
                rows={rows}
                placeholder={placeholderValue}
                label={label}
                type={returnType(type, values)}
                InputProps={paramFromType(type)}
                onKeyDown={(event) => {
                    if (type === "size" || type === "weight") onKeyDown(event);
                }}
                {...others}
            />
        );

        if (options.length !== 0 && !inputWithSelect) {
            inputBody = (
                <Select
                    value={value || ""}
                    onChange={onChange}
                    // classes={{
                    //   root: classNames(classes.input, classes.selectRoot),
                    //   select: classes.select,
                    // }}
                    className={classes.textfield}
                    disableUnderline={vehicleType ? false : true}
                >
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            );
        }

        if (inputWithSelect) {
            inputBody = (
                <Autocomplete
                    // defaultValue={defaultValue || ''}
                    onChange={(event, newValue) => {
                        onChange(newValue);
                    }}
                    disabled={disabled}
                    value={value}
                    freeSolo={freeSolo}
                    getOptionSelected={(option, value) => option === value}
                    options={options}
                    // renderOption={(option) => {
                    //   if (Boolean(option))
                    //     return <Typography noWrap>hah {option}</Typography>;
                    //   else return;
                    // }}
                    // renderInput={params => <TextField {...params} label={label} InputProps={{ ...params.InputProps, disableUnderline: true }} />}
                    renderInput={(params) => {
                        params.inputProps.autoComplete = "new-password";
                        return (
                            <TextField
                                autoComplete="off"
                                {...params}
                                label={label}
                                className={classTypesAuto(estimate, error, classes)}
                                InputProps={
                                    estimate
                                        ? {
                                            ...params.InputProps,
                                            autoComplete: "off",
                                            disableUnderline: true,
                                        }
                                        : {...params.InputProps, autoComplete: "off"}
                                }
                            />
                        );
                    }}
                />
            );
        }

        return <>{inputBody}</>;
    }
);

const useStyles = makeStyles((theme) => ({
    textfield: {
        width: "100%",
        backgroundColor: "white",
        padding: "0.4px",
        borderRadius: "5px",
        // boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: "0.4vw",
        paddingBottom: "0.4vw",
        boxSizing: "border-box",
        border: "1px solid white",
    },
    lineTextfield: {
        width: "50%",
        paddingRight: theme.spacing(1),
    },
    disabled: {
        backgroundColor: grey[300],
        border: `1px solid ${grey[300]}`,
    },
    errorText: {
        border: "1px solid red",
        borderRadius: "5px",
    },
}));

CustomInput.propTypes = {
    onChange: func,
    value: oneOfType([string, number]),
    //   error: object.isRequired,
    multiline: bool,
    //   margin: string,
    type: string,
    label: string,
    disabled: bool,
    inputWithSelect: bool,
    rows: number,
    placeholderValue: string,
    defaultValue: string,
    options: array,
    password: bool,
    vehicleType: bool,
    lineInput: bool,
};

CustomInput.defaultProps = {
    //   value: '', // prevent crash when input doesn't have value for optional field
    //   margin: 'normal', //  to style form base margin ['none', 'normal', 'dense']
    type: "text", // change type of input should be a valid HTML5 input type.
    disabled: false,
    rows: 1,
    multiline: false,
    onChange: () => {
    },
    placeholderValue: "",
    label: "",
    options: [],
    inputWithSelect: false,
    password: false,
};

export default CustomInput;
