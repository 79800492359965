import React, { useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import axios from "axios";

import { redirectHome } from "../../Utils/redirect";
import { BACKEND_ADDRESSBOOK } from "../../Utils/apiUrl";
import { getHeaders } from "../../Utils/fetchUtil";
import { getAddressBook } from "../../slices";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 20,
    fontSize: "1.5rem",
    backgroundColor: "#ffa726",
    color: "white",
  },
}));

const OrderConfirm = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { number } = useParams();
  const dispatch = useDispatch();

  const jumboStyle = {
    color: "green",
    maxheight: "-webkit-fill-available",
    height: "90vh",
    fontFamily: "Montserrat",
    fontSize: "20pt",
    letterSpacing: "2px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    overflow: "hidden",
  };

  useEffect(() => {
    if (location.state?.addressData) {
      axios({
        method: "post",
        url: BACKEND_ADDRESSBOOK,
        // url: `http://localhost:9000/address_book`,
        data: JSON.stringify({ address_book: location.state.addressData }),
        headers: {
          ...getHeaders(),
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      })
        .then((res) => {
          dispatch(getAddressBook(true));
        })
        .catch((error) => {
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data.message);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log(error.message);
          }
        });
    }
  }, []);

  return (
    <div style={jumboStyle}>
      {/* <LightSpeed left duration={1000} > */}
      <CheckCircleOutlineIcon style={{ fontSize: "8rem" }} />
      {/* </LightSpeed> */}
      {/* <Slide up duration={1000}> */}
      <div style={{ backgroundColor: "white", borderRadius: "20px" }}>
        {location.state?.routeErr ? (
          <p>
            Your request has been submitted.
            <br />
            We'll contact you later.
          </p>
        ) : (
          <Box color="rgba(0,0,0,0.8)">
            <p>
              {`Thank you! Your order number is `}
              <Box component="span" color="seagreen" fontWeight="600">
                {number}
              </Box>
            </p>
            <p>You'll only be charged when the driver is on the way.</p>
          </Box>
        )}

        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={() => redirectHome(history)}
        >
          Back to home
        </Button>
        <br />
        <br />
      </div>
      {/* </Slide> */}
    </div>
  );
};

export default OrderConfirm;
