import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import {useForm, Controller} from 'react-hook-form';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {
    extractAddress,
    extractFullAddress,
    getMissingKeysForCreateLabelLocation,
    isAlphaString, isValidatePhone,
    loadAsyncScript
} from "../../../Utils/Helper";
import Grid from "@material-ui/core/Grid";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {Checkbox, Snackbar} from "@mui/material";
import * as React from 'react';
import MuiAlert from "@mui/material/Alert";
import {makeStyles} from "@material-ui/core/styles";
import InputLabel from "@mui/material/InputLabel";
import Paper from "@mui/material/Paper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LoadingButton from "@mui/lab/LoadingButton";
import {BusinessAddAddress} from "../../../actions/AddressListAction";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    AddressListAddRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #A9A9A9',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    AddressListAddSectionBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    AddressListAddWarningText: {
        fontSize: '12px',
        color: "#FF0303"
    },
}

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "4px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

export const AddressListAdd = ({getSenderAddressList, getDeliverAddressList, cbHandleTabChange}) => {

    const {id: partnerId, email} = useSelector((state) => state.user);
    const classes = useStyles();
    const dispatch = useDispatch();

    const [missing, setMissing] = useState([]);

    const [addressPredictions, setAddressPredictions] = useState([]);
    const [addressBook, setAddressBook] = useState({
        postalCode: null,
        province: null,
        city: null,
        address: null,
        address2: null,
        country: null,
        displayAddress: {
            description: null,
            placeId: null,
            text: null
        },
        name: '',
        phone: null,
        email: '',
        company: '',
        type: 'sender',
    });

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const googleMapsPlaceAutocomplete = async (inputValue, type) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log('prediction', predictions);
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            console.log('updated prediction', updatedPredictions);

            return updatedPredictions;

        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const handleName = event => {
        setAddressBook(prevState => ({
            ...prevState,
            name: event?.target?.value
        }))
    }

    const handleEmail = event => {
        setAddressBook(prevState => ({
            ...prevState,
            email: event?.target?.value
        }))
    }

    const handlePhone = event => {
        setAddressBook(prevState => ({
            ...prevState,
            phone: event?.target?.value
        }))
    }

    const handleCompany = event => {
        setAddressBook(prevState => ({
            ...prevState,
            company: event?.target?.value
        }))
    }

    const handleCity = event => {
        setAddressBook(prevState => ({
            ...prevState,
            city: event?.target?.value
        }))
    }

    const handleProvince = event => {
        setAddressBook(prevState => ({
            ...prevState,
            province: {
                code: event?.target?.value,
                name: event?.target?.value
            }
        }))
    }

    const handleCountry = event => {
        setAddressBook(prevState => ({
            ...prevState,
            country: {
                code: event?.target?.value,
                name: event?.target?.value
            }
        }))
    }

    const handlePostalCode = event => {
        setAddressBook(prevState => ({
            ...prevState,
            postalCode: event?.target?.value
        }))
    }

    const handleAddress2 = event => {
        setAddressBook(prevState => ({
            ...prevState,
            address2: event?.target?.value
        }))
    }

    const handleAddressType = (addressType) => {
        setAddressBook(prevState => ({
            ...prevState,
            type: addressType
        }))
    }

    const handleInputChangeAddress = async (event, value) => {
        setAddressBook(prevState => ({
            ...prevState,
            address: value,
            displayAddress: {
                description: '',
                placeId: '',
                text: value
            }
        }));
        const predictionsAddress = await googleMapsPlaceAutocomplete(value, 'address');
        setAddressPredictions(predictionsAddress);
    }

    const handleChangeAddress = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            setAddressBook(prevState => ({
                ...prevState,
                postalCode: address?.zip,
                city: address?.city,
                province: address?.state,
                country: address?.country,
                displayAddress: value
            }))
        } else {
            setAddressBook(prevState => ({
                ...prevState,
                address: '',
                displayAddress: {
                    description: '',
                    placeId: '',
                    text: ''
                },
            }))
        }
    }

    const clearForm = () => {
        setAddressBook({
            postalCode: null,
            province: null,
            city: null,
            address: null,
            address2: null,
            country: null,
            displayAddress: {
                description: null,
                placeId: null,
                text: null
            },
            name: '',
            phone: null,
            email: '',
            company: '',
            type: 'sender',
        })
    }

    const addAddress = async () => {

        let data = {
            partnerId: partnerId.toString(),
            address: addressBook?.displayAddress?.text,
            address_2: addressBook?.address2,
            city: addressBook?.city,
            email: email,
            province: addressBook?.province?.code,
            postalCode: addressBook?.postalCode,
            country: addressBook?.country?.code,
            phone: addressBook?.phone,
            name: addressBook?.name,
            addressEmail: addressBook?.email,
            companyName: addressBook?.company
        }

        console.log(data);

        if (addressBook?.type === 'sender') {
            await addAddressSender(data);
        } else {
            await addAddressDeliver(data);
        }
    }

    const addAddressSender = async (data) => {
        let requestURL = `${PARTNER_URI}/loose-item/3rd-party/partner/addressbooks/sender`;

        try {
            const result = await axios({
                method: 'put',
                url: requestURL,
                data: data
            })
            console.log(result);
            handleSuccessMessage();
            setErrorMessage("");
            setSuccessMessage(result.data.message);
            getSenderAddressList();
            cbHandleTabChange('one');
            setTimeout(handleClose, 1000);
        } catch (e) {
            console.log('error response', e);
            handleErrorMessage();
            setErrorMessage("fail to add address");
        }
    }

    const addAddressDeliver = async (data) => {
        let requestURL = `${PARTNER_URI}/loose-item/3rd-party/partner/addressbooks/deliver`;

        try {
            const result = await axios({
                method: 'put',
                url: requestURL,
                data: data
            })
            console.log(result);
            handleSuccessMessage();
            setErrorMessage("");
            setSuccessMessage(result.data.message);
            getDeliverAddressList();
            cbHandleTabChange('two');
            setTimeout(handleClose, 1000);
        } catch (e) {
            console.log(e);
            handleErrorMessage();
            setErrorMessage("fail to add address");
        }

    }

    const handleClose = () => {
        dispatch(BusinessAddAddress(false));
    }

    const validate = () => {
        setErrorMessage('');
        setToastOpen(false);
        const addressMissing = getMissingKeysForCreateLabelLocation(addressBook);
        const addressPhoneValidate = isValidatePhone(addressBook?.phone);

        setMissing(addressMissing);
        addressMissing?.length !== 0 && setErrorMessage(prevState => prevState + ' Address is missing one or more required fields.');
        !addressPhoneValidate && setErrorMessage(prevState => prevState + ' Phone number is invalid.');

        if (addressMissing.length === 0 && addressPhoneValidate) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const handleSubmit = () => {
        const validationResult = validate();
        if (validationResult) {
            addAddress()
        }
    }

    console.log('address', addressBook);

    return (
        <Box sx={styles.AddressListAddRoot}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Box sx={styles.AddressListAddSectionBox}>
                        <InputLabel required>Name</InputLabel>
                        <TextField
                            value={addressBook?.name}
                            fullWidth
                            onInput={handleName}
                            variant='outlined'
                            size='small'
                        />
                        <Typography style={styles.AddressListAddWarningText}>
                            {
                                missing?.includes('name') ? "This is required field." : ''
                            }
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={styles.AddressListAddSectionBox}>
                        <InputLabel required>Phone</InputLabel>
                        <TextField
                            value={addressBook?.phone}
                            fullWidth
                            onInput={handlePhone}
                            variant='outlined'
                            size='small'
                        />
                        <Typography style={styles.AddressListAddWarningText}>
                            {
                                addressBook?.phone === null ? "" : missing?.includes('phone') ?
                                    "This is required field." : isValidatePhone(addressBook?.phone) ?
                                        "" : "Invalid Phone Number"
                            }
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={styles.AddressListAddSectionBox}>
                        <InputLabel>Email</InputLabel>
                        <TextField
                            value={addressBook?.email}
                            fullWidth
                            onInput={handleEmail}
                            variant='outlined'
                            size='small'
                            placeholder='recommend'
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={styles.AddressListAddSectionBox}>
                        <InputLabel>Company</InputLabel>
                        <TextField
                            value={addressBook?.company}
                            fullWidth
                            onInput={handleCompany}
                            variant='outlined'
                            size='small'
                        />
                    </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Box sx={styles.AddressListAddSectionBox}>
                        <InputLabel required>Address</InputLabel>
                        <Autocomplete
                            freeSolo
                            value={addressBook?.displayAddress}
                            fullWidth
                            options={addressPredictions}
                            filterOptions={(options, state) => options}
                            getOptionLabel={option => option?.text}
                            onInputChange={handleInputChangeAddress}
                            onChange={handleChangeAddress}
                            PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                            renderInput={params => <TextField
                                {...params}
                                className={classes.smallInput}
                                variant="outlined"
                            />}
                            renderOption={option => (
                                <Typography style={{fontSize: '12px'}}>
                                    {option?.description}
                                </Typography>
                            )}
                        />
                        <Typography style={styles.AddressListAddWarningText}>
                            {
                                missing?.includes('address') ? "This is required field." : ''
                            }
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Box sx={styles.AddressListAddSectionBox}>
                        <InputLabel>Address Two</InputLabel>
                        <TextField
                            value={addressBook?.address2}
                            fullWidth
                            variant='outlined'
                            onInput={handleAddress2}
                            size='small'
                        />
                    </Box>
                </Grid>
                <Grid item md={3} xs={12}>
                    <Box sx={styles.AddressListAddSectionBox}>
                        <InputLabel required>City</InputLabel>
                        <TextField
                            value={addressBook?.city}
                            fullWidth
                            variant='outlined'
                            onInput={handleCity}
                            size='small'
                        />
                        <Typography style={styles.AddressListAddWarningText}>
                            {
                                missing?.includes('city') ? "This is required field." : ''
                            }
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={3} xs={12}>
                    <Box sx={styles.AddressListAddSectionBox}>
                        <InputLabel required>Province/State</InputLabel>
                        <TextField
                            value={addressBook?.province?.code}
                            fullWidth
                            variant='outlined'
                            onInput={handleProvince}
                            size='small'
                            placeholder='BC'
                        />
                        <Typography style={styles.AddressListAddWarningText}>
                            {
                                missing?.includes('province') ? "This is required field." : ''
                            }
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={3} xs={12}>
                    <Box sx={styles.AddressListAddSectionBox}>
                        <InputLabel required>Country</InputLabel>
                        <TextField
                            value={addressBook?.country?.code}
                            fullWidth
                            variant='outlined'
                            onInput={handleCountry}
                            size='small'
                            placeholder='CA'
                        />
                        <Typography style={styles.AddressListAddWarningText}>
                            {
                                missing?.includes('country') ? "This is required field." : ''
                            }
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={3} xs={12}>
                    <Box sx={styles.AddressListAddSectionBox}>
                        <InputLabel required>Postal Code</InputLabel>
                        <TextField
                            value={addressBook?.postalCode}
                            fullWidth
                            variant='outlined'
                            onInput={handlePostalCode}
                            size='small'
                        />
                        <Typography style={styles.AddressListAddWarningText}>
                            {
                                missing?.includes('postalCode') ? "This is required field." : ''
                            }
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={12} xs={12}>
                    <Box sx={styles.AddressListAddSectionBox}>
                        <InputLabel required>Address Type</InputLabel>
                        <Grid container spacing={4}>
                            <Grid item md={12} xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    gap: '20px'
                                }}>
                                    <Typography>
                                        Sender
                                    </Typography>
                                    <Checkbox
                                        checked={addressBook?.type === 'sender' ? true : false}
                                        onClick={() => handleAddressType('sender')}
                                    />
                                    <Typography>
                                        Deliver
                                    </Typography>
                                    <Checkbox
                                        checked={addressBook?.type === 'deliver' ? true : false}
                                        onClick={() => handleAddressType('deliver')}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Typography style={styles.AddressListAddWarningText}>
                            {/*{*/}
                            {/*    validation?.shipFromMissing?.includes('city') ? "This is required field." : ""*/}
                            {/*}*/}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            Submit
                        </LoadingButton>
                    </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Button variant="contained" onClick={handleClose} color="error">
                            Cancel
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )

}

// <Box sx={style.AddressListAddButton}>
//     <Button type="submit" variant="contained" color="primary">
//         Submit
//     </Button>
//     <Button variant="contained" color="primary" onClick={handleClose} color="error">
//         Cancel
//     </Button>
// </Box>