import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import Divider from "@mui/material/Divider";
import {Link} from "@mui/joy";
import * as React from "react";
import {useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText} from "@mui/material";

const style = {
    ShipSummaryServiceRoot: {
        width: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        backgroundColor: '#F9F9F9',
        padding: '10px 30px',
        borderRadius: '15px'
    },
    ShipSummaryServicePriceDetails: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    },
    ShipSummaryServicePriceHeader: {
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%',
        margin: '0 15px'
    },
    Link: {
        color: 'green',
        ':hover': {
            color: 'green'
        }
    }
}

export const ShipSummaryService = ({summaryData}) => {

    console.log('summary data', summaryData);

    const [dialogDisclosure, setDialogDisclosure] = useState(false);

    const handleDialogDisclosureClose = () => {
        setDialogDisclosure(false);
    };

    return (
        <Box sx={style.ShipSummaryServiceRoot}>
            <Dialog
                open={dialogDisclosure}
                onClose={handleDialogDisclosureClose}
            >
                <DialogContent sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                    <DialogContentText>
                        <span style={{color: 'green'}}> A shipping adjustment </span> occurs when an incorrect shipping
                        label is generated due to
                        inaccurate
                        shipment information. This causes the shipping carrier to readjust the shipping information to
                        ensure the package reaches its final destination and the correct shipping costs are paid.
                    </DialogContentText>
                    <DialogContentText>
                        If there is a difference between what they measure and what you declared on UUCargo, you may be
                        charged extra according to the changes in shipping costs. If the parcel is bigger/heavier than
                        declared, you will be charged to make up for the difference. This works the other way too; if
                        the parcel is smaller/lighter than declared, you will receive an appropriate partial refund.
                    </DialogContentText>
                    <DialogContentText>
                        <span style={{color: 'green', textDecoration: 'underline green'}}>*The bill from the carriers determines the final price for a package.</span> We
                        will return credit to
                        your account balance or charge an additional fee from your payment method depending on the bill.
                    </DialogContentText>
                    <DialogContentText>
                        Should you have any questions or concerns regarding shipping adjustments or billing, our
                        customer support team is available to assist you. We value your business and are committed to
                        providing a reliable and cost-effective shipping experience with UUCargo.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <Button
                            sx={style.DisclosureButton}
                            onClick={handleDialogDisclosureClose}
                        >
                            Accept
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <Typography variant="h5">
                Service
            </Typography>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Typography>
                            Service Type
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography style={{textAlign: 'right'}}>
                            {summaryData?.accountBaseCombineName} {summaryData?.serviceType}
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography>
                            Subtotal
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography style={{textAlign: 'right'}}>
                            ${summaryData?.totalCharge?.value}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Accordion sx={{width: 1}}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                              sx={{width: 1}}>
                                <Box sx={style.ShipSummaryServicePriceHeader}>
                                    <Typography>
                                        Price Details
                                    </Typography>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                {
                                    summaryData?.shipmentCharges?.map((element, index) => <Box
                                        sx={style.ShipSummaryServicePriceDetails} key={index}>
                                        <Typography style={{fontWeight: '600'}}>
                                            {element.name}:
                                        </Typography>
                                        <Typography>
                                            {element.value}
                                        </Typography>
                                    </Box>)
                                }
                                {
                                    summaryData?.taxes[0].value !== 'NaN' && summaryData?.taxes?.map((element, index) =>
                                        <Box
                                            sx={style.ShipSummaryServicePriceDetails} key={index}>
                                            <Typography style={{fontWeight: '600'}}>
                                                {element.name}:
                                            </Typography>
                                            <Typography>
                                                {element.value}
                                            </Typography>
                                        </Box>)
                                }
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Divider sx={{width: '100%', margin: '10px'}}/>
                    <Grid item xs={8}>
                        <Typography style={{fontSize: '22px', fontWeight: '600'}}>
                            Total
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography style={{textAlign: 'right', fontSize: '22px', fontWeight: '600'}}>
                            ${summaryData?.totalChargeWithTaxes?.value === 'NaN' ? summaryData?.totalCharge?.value : summaryData?.totalChargeWithTaxes?.value}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{display: 'flex', justifyContent: 'flex-start', width: '100%'}}>
                            <Typography>
                                *This may not be your final price.
                                <Link
                                    sx={style.Link}
                                    onClick={() => setDialogDisclosure(true)}
                                >
                                    Learn more
                                </Link>
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>

    )
}